import React, { useState } from 'react'

import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { useFormik } from 'formik'
import { isString } from 'lodash'
import { resetCodeRequestSchema } from '../validation'
import { forgotPassword } from '../api'

export const CodeRequestForm: React.FC<{
  onSuccess: (email: string) => void
}> = ({ onSuccess }) => {
  const [resetCodeRequest, setResetCodeRequest] = useState({
    loading: false,
    error: '',
  })

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetCodeRequestSchema,
    onSubmit: async (values) => {
      setResetCodeRequest({ loading: true, error: '' })
      const { email } = values
      const { error } = await forgotPassword({ email })
      if (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : typeof error === 'object' &&
              'message' in error &&
              isString((error as { message: unknown }).message)
            ? (error as { message: string }).message
            : 'Unexpected error occurred'
        setResetCodeRequest({ loading: false, error: errorMessage })
      } else {
        setResetCodeRequest({ loading: false, error: '' })
        onSuccess(email)
      }
    },
  })

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <form onSubmit={form.handleSubmit}>
        {resetCodeRequest.error && resetCodeRequest.error !== '' && (
          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
            {resetCodeRequest.error}
          </Alert>
        )}
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="email"
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />
        <LoadingButton
          fullWidth
          sx={{ my: 2, py: 1 }}
          variant="contained"
          endIcon={<ChevronRight />}
          type="submit"
          loading={resetCodeRequest.loading}
        >
          Continue
        </LoadingButton>
      </form>
    </Grid>
  )
}
