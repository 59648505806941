import { useSegment } from '@/events/segment'
import React, { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Mapping of DOM page titles to set on different paths
 */
const pathTitleMap: Record<string, string | undefined> = {
  '/': 'Zoya',
  '/zakat': 'Zakat Calculator for Stocks and Funds - Zoya',
}

export const RoutesListener: React.FC<{
  children: ReactNode | ReactNode[]
}> = ({ children }) => {
  const location = useLocation()
  const { analytics } = useSegment()

  useEffect(() => {
    document.title = pathTitleMap[location.pathname] || 'Zoya'
  }, [location.pathname])

  useEffect(() => {
    if (analytics) {
      analytics?.page(`visit ${location.pathname}`)
    }
  }, [location.pathname, analytics])

  return <>{children}</>
}
