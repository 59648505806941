import React, { useState, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Divider from '@mui/material/Divider'
import { CsvImport } from '../ImportForms/CsvImport'
import { ZakatHolding } from '../../types'
import { useSegment } from '@/events/segment'
import { Statsig } from 'statsig-react'
import { LinkedAccounts } from '../ImportForms/LinkedAccounts'

const addHoldingModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 'calc(100% - 1rem)', sm: 500 },
  bgcolor: 'background.paper',
  boxShadow: 8,
  borderRadius: 1,
}

const tabProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export const ImportHoldingsModal: React.FC<{
  open: boolean
  onClose: () => void
  onImport: (holdings: Partial<ZakatHolding>[]) => void
}> = React.memo(({ open, onClose, onImport }) => {
  const [selecetedTab, setSelecetedTab] = useState(0)
  const { analytics } = useSegment()

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={addHoldingModalstyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          sx={{ py: 2, pb: 1, pl: 2 }}
        >
          Import Holdings
        </Typography>
        <Divider />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selecetedTab}
            onChange={(_, value) => setSelecetedTab(value)}
            aria-label="import options tabs "
          >
            <Tab label="From CSV" {...tabProps(0)} />
            <Tab label="From Accounts" {...tabProps(1)} />,
          </Tabs>
        </Box>
        <TabPanel value={selecetedTab} index={0}>
          <CsvImport
            onImport={(holdings) => {
              analytics?.track('Zakat import Holdings', {
                count: holdings.length,
                holdings_symbols: holdings.map((h) => h.symbol),
                type: 'CSV',
              })
              Statsig.logEvent('Zakat import Holdings', holdings.length, {
                count: JSON.stringify(holdings.length),
                holdings_symbols: JSON.stringify(holdings.map((h) => h.symbol)),
                type: 'CSV',
              })
              onImport(holdings)
              onClose()
            }}
          />
        </TabPanel>
        <TabPanel value={selecetedTab} index={1}>
          <LinkedAccounts />
        </TabPanel>
      </Box>
    </Modal>
  )
})

const TabPanel: React.FunctionComponent<{
  index: number
  value: number
  children: ReactNode
}> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
      }}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  )
}
