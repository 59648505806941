import { useRef, useState } from 'react'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'

const { VITE_SEGMENT_WRITE_KEY } = import.meta.env

export const initSegment = async (onReady?: () => void) => {
  try {
    const [analytics] = await AnalyticsBrowser.load(
      {
        writeKey: VITE_SEGMENT_WRITE_KEY,
      },
      {
        integrations: {
          'Segment.io': {
            deliveryStrategy: {
              strategy: 'batching',
              config: {
                size: 20,
                timeout: 5000,
              },
            },
          },
        },
      }
    )
    onReady && analytics.ready(onReady)
  } catch (error) {
    console.error('failed to load segment with error: ', error)
  }
}

export const useSegment = () => {
  const analyticsRef = useRef<Analytics>()
  const [analytics, setAnalytics] = useState<Analytics | undefined>(
    analyticsRef.current
  )

  if (!analytics) {
    AnalyticsBrowser.load(
      {
        writeKey: VITE_SEGMENT_WRITE_KEY,
      },
      {
        integrations: {
          'Segment.io': {
            deliveryStrategy: {
              strategy: 'batching',
              config: {
                size: 20,
                timeout: 5000,
              },
            },
          },
        },
      }
    )
      .then(([response]) => {
        analyticsRef.current = response
        setAnalytics(response)
      })
      .catch((error) => {
        console.error('failed to load segment with error: ', error)
      })
  }

  return { analytics }
}
