import {
  ComponentType,
  createContext,
  PropsWithChildren,
  useContext,
} from 'react'

import { useTypesense } from '@/search/use-typesense'
import { useLocalSearch } from '@/search/use-local-search'

export type SearchItem = {
  symbol: string
  name?: string
  region?: string
  issueType?: string
  status?: string
  exchange?: string
  currency?: string
}

export interface Search {
  loading: boolean
  error: Error | undefined
  results: SearchItem[] | undefined
  search: (query: string) => void
}

const SearchContext = createContext<Search | null>(null)

export const useSearch = () => {
  const search = useContext(SearchContext)
  if (!search) {
    throw new Error('search provider not found')
  }
  return search
}

export const SearchProvider = (props: PropsWithChildren<unknown>) => {
  // TODO: fetch the value from statsig experiment
  let searchProvider = ((): 'local' | 'typesense' => 'typesense')()

  switch (searchProvider) {
    case 'typesense': {
      return <TypesenseSearchProvider>{props.children}</TypesenseSearchProvider>
    }
    case 'local':
    default: {
      return <LocalSearchProvider>{props.children}</LocalSearchProvider>
    }
  }
}

export const withSearchProvider = <T,>(Component: ComponentType<T>) => {
  return (props: T & JSX.IntrinsicAttributes) => (
    <SearchProvider>
      <Component {...props} />
    </SearchProvider>
  )
}

const TypesenseSearchProvider = (props: PropsWithChildren<unknown>) => {
  const search = useTypesense()

  return (
    <SearchContext.Provider value={search}>
      {props.children}
    </SearchContext.Provider>
  )
}

const LocalSearchProvider = (props: PropsWithChildren<unknown>) => {
  const search = useLocalSearch()

  return (
    <SearchContext.Provider value={search}>
      {props.children}
    </SearchContext.Provider>
  )
}
