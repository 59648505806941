import React from 'react'

import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid'

import numeral from 'numeral'
import { ZakatHolding } from '../types'
import { formatCurrency } from '@/common/currencyFormatter'

export const CurrentPriceCell = (
  params: GridRenderCellParams<any, ZakatHolding>
) => {
  return params.row.dataFetch && !params.row.dataFetch.finished ? (
    <Skeleton animation="wave" width={75} height={22} />
  ) : (
    <Typography>
      {formatCurrency(params.row.unitPrice, params.row.currency)}
    </Typography>
  )
}
