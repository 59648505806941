import React, { useCallback, useState } from 'react'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { ChevronRight } from '@mui/icons-material'

import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { isString } from 'lodash'

import { signInSchema } from '../validation'
import { signIn } from '../api'

export const SignInForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // this is needed to reroute users to the original route the user tried to
  // access.
  // location.state type is unknown by design. however the library itself
  // implements this functionality as below
  // @ts-ignore next-line
  const from = location.state?.from?.pathname || '/'

  const handleNavigateToPasswordReset = useCallback(() => {
    navigate('/auth/password-reset')
  }, [])

  const [signInRequest, setSignInRequest] = useState({
    loading: false,
    error: '',
  })

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      setSignInRequest({ loading: true, error: '' })
      const { error } = await signIn(values)
      if (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : typeof error === 'object' &&
              'message' in error &&
              isString((error as { message: unknown }).message)
            ? (error as { message: string }).message
            : 'Unexpected error occurred'
        setSignInRequest({ loading: false, error: errorMessage })
      } else {
        setSignInRequest({ loading: false, error: '' })
        navigate(from, { replace: true })
      }
    },
  })

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <form onSubmit={form.handleSubmit}>
        {signInRequest.error && signInRequest.error !== '' && (
          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
            {signInRequest.error}
          </Alert>
        )}
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="email"
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="password"
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          value={form.values.password}
          onChange={form.handleChange}
          error={form.touched.password && Boolean(form.errors.password)}
          helperText={form.touched.password && form.errors.password}
        />
        <Button
          sx={{ textAlign: 'start', display: 'block' }}
          variant="text"
          onClick={handleNavigateToPasswordReset}
        >
          Forgot password ?
        </Button>
        <LoadingButton
          fullWidth
          sx={{ my: 2, py: 1 }}
          variant="contained"
          endIcon={<ChevronRight />}
          type="submit"
          loading={signInRequest.loading}
        >
          Sign In
        </LoadingButton>
      </form>
    </Grid>
  )
}
