import { useCallback, useRef, useState } from 'react'
import { z } from 'zod'
import { debounce } from 'lodash'
import { SearchClient } from 'typesense'
import { SearchParams } from 'typesense/lib/Typesense/Documents'

const VITE_TYPESENSE_HOST = import.meta.env.VITE_TYPESENSE_HOST
const VITE_TYPESENSE_KEY = import.meta.env.VITE_TYPESENSE_KEY
const VITE_TYPESENSE_COLLECTION = import.meta.env.VITE_TYPESENSE_COLLECTION

const typesenseClient = new SearchClient({
  nodes: [
    {
      host: VITE_TYPESENSE_HOST,
      port: 443,
      protocol: 'https',
    },
  ],
  apiKey: VITE_TYPESENSE_KEY,
  connectionTimeoutSeconds: 5,
})

const typesenseSearchParams: Omit<SearchParams, 'q'> = {
  query_by: ['symbol', 'name'].join(','),
  per_page: 8,
  limit_hits: 8,
  highlight_fields: 'none',
  highlight_full_fields: 'none',
}

type Entry = z.infer<typeof EntrySchema>

const EntrySchema = z.object({
  symbol: z.string(),
  name: z.string().optional(),
  region: z.string().optional(),
  issueType: z.string().optional(),
  status: z.string().optional(),
  exchange: z.string().optional(),
  currency: z.string().optional(),
})

export const useTypesense = () => {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<Entry[]>()
  const abortControllerRef = useRef<AbortController>()

  /**
   * Debounced function to call typesense and set the results into react state
   */
  const debouncedSearch = useCallback(
    debounce(async (query: string, abortSignal: AbortSignal) => {
      try {
        const results = await typesenseClient
          .collections<Entry>(VITE_TYPESENSE_COLLECTION)
          .documents()
          .search(
            {
              ...typesenseSearchParams,
              q: query,
            },
            {
              abortSignal,
              cacheSearchResultsForSeconds: 300,
            }
          )

        setResults(
          results.hits
            ? z
                .array(EntrySchema)
                .parse(results.hits.map((hit) => hit.document))
            : undefined
        )

        setLoading(false)
      } catch (error) {
        /**
         * Error thrown by typesense on abort.
         */
        if (
          error instanceof Error &&
          error.message === 'Request aborted by caller.'
        ) {
          return
        }

        setLoading(false)

        if (error instanceof Error) {
          setError(error)
        }

        setError(new Error('Something went wrong!'))

        console.error(error)
      }
    }, 150),
    []
  )

  /**
   * Exposed callback to trigger search
   */
  const search = useCallback((query: string) => {
    if (query.trim() === '') {
      setLoading(false)
      setResults(undefined)
      return
    }

    setLoading(true)
    abortControllerRef.current?.abort()
    abortControllerRef.current = new AbortController()
    debouncedSearch(query, abortControllerRef.current.signal)
  }, [])

  return { search, results, loading, error }
}
