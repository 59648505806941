import { Link } from 'react-router-dom'
import { Paper, Tab, Tabs } from '@mui/material'

import { useInvestAccountStatus } from '@/invest/hooks/useInvestAccountStatus'

export const InvestNavigation = () => {
  const { loading, error, hasAccount } = useInvestAccountStatus()

  if (error) {
    return null
  }

  if (loading) {
    return null
  }

  if (!hasAccount) {
    return null
  }

  return (
    <Paper sx={{ marginBlock: 2 }} variant="outlined">
      <Tabs value={false} centered>
        <Tab label="Home" to="/invest" component={Link} />
        <Tab label="Portfolio" to="/invest/portfolio" component={Link} />
        <Tab label="Orders" to="/invest/orders" component={Link} />
        <Tab label="Funding" to="/invest/funding" component={Link} />
        <Tab label="Account" to="/invest/account" component={Link} />
        <Tab label="Documents" to="/invest/account/documents" component={Link} />
      </Tabs>
    </Paper>
  )
}
