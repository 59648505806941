import React from 'react'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { SignInForm } from '../components/SignInForm'

import { useNavigate } from 'react-router-dom'

export const SignIn = () => {
  const navigate = useNavigate()

  const handleNavigateToSignUp = () => {
    navigate('/auth/sign-up')
  }

  return (
    <Container
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          height="100vh"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          xs={5}
        >
          <Card
            sx={{
              p: { xs: 2, md: 4 },
              my: 2,
              minWidth: 320,
              maxWidth: 500,
              alignSelf: 'center',
            }}
            elevation={0}
          >
            <Typography variant="h4">Log In</Typography>
            <Box
              sx={{ mt: 1 }}
              flexDirection={'row'}
              display="flex"
              justifyContent={'space-between'}
            >
              <Typography variant="subtitle1">
                Don&apos;t have an account ?
              </Typography>
              <ButtonBase onClick={handleNavigateToSignUp}>
                <Typography variant="subtitle1" color={'primary'}>
                  Sign up
                </Typography>
              </ButtonBase>
            </Box>
            <SignInForm />
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
