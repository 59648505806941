import React from 'react'

import Add from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  Chip,
  Divider,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  IconButton,
} from '@mui/material'

const methodolgyModalstyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 'calc(100% - 1rem)', sm: 700 },
  bgcolor: 'background.paper',
  boxShadow: 8,
  borderRadius: 1,
  py: 1,
}

export const MethodolgyModal: React.FC<{
  open: boolean
  onClose: () => void
}> = ({ open, onClose }) => {
  const theme = useTheme()
  const smAndLess = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={methodolgyModalstyle}>
        <Grid
          container
          paddingX={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="subtitle1">
            Zakat Calculation Methodology
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid
          container
          rowGap={3}
          sx={{
            mt: 2,
            px: 2,
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              for <b>Active Holdings</b>
            </Typography>
            <Grid container direction="column">
              <Grid
                container
                direction="row"
                columnGap={1}
                alignItems="center"
                justifyContent="center"
                sx={{ my: 3 }}
              >
                <HtmlTooltip
                  arrow
                  placement="top"
                  title={
                    <Typography variant="body2">
                      The cash value of a holding according to current market
                      price.
                    </Typography>
                  }
                >
                  <Chip
                    label={
                      <Typography
                        sx={{ typography: { xs: 'caption', md: 'subtitle1' } }}
                      >
                        Base Value
                      </Typography>
                    }
                    variant="outlined"
                    size={smAndLess ? 'small' : 'medium'}
                  />
                </HtmlTooltip>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  x
                </Typography>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  <b>2.5%</b>
                </Typography>
                <Typography variant="body1">=</Typography>
                <HtmlTooltip
                  arrow
                  placement="top"
                  title={
                    <Typography variant="body2">
                      The amount of Zakat due for a holding in which you are an
                      active investor.
                    </Typography>
                  }
                >
                  <Chip
                    color="primary"
                    label={
                      <Typography
                        sx={{ typography: { xs: 'caption', md: 'subtitle1' } }}
                      >
                        Zakat Amount
                      </Typography>
                    }
                    variant="filled"
                    size={smAndLess ? 'small' : 'medium'}
                  />
                </HtmlTooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Active investments are ones that you trade and adjust
                  frequently. Holding you intend to keep for less than a year
                  would fall under this category. You should treat such holdings
                  the same as cash and pay 2.5% of their value as Zakat.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              for <b>Passive Holdings</b>{' '}
            </Typography>
            <Grid container direction="column-reverse">
              <Grid item>
                <Typography variant="body2">
                  Passive holdings are investments that you do not actively
                  trade and intend to hold for more than a year. For these, you
                  calculate percent ownership of your shares and multiply that
                  by the current assets of the business to determine the Zakat
                  liable amount. ETFs and mutual funds require recursive
                  application of this calculation to their holdings with a
                  weighted aggregation at the end.
                  <br />
                  Scholars have approved the use of taking 30% of the current
                  market value of a stock or fund as an approximation for
                  current assets when the information is not readily available.
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                columnGap={1}
                rowGap={2}
                alignItems="center"
                justifyContent="center"
                sx={{ my: 3 }}
              >
                <HtmlTooltip
                  arrow
                  placement={smAndLess ? 'top' : 'left'}
                  title={
                    <Typography variant="body2">
                      Current assets are the cash, inventories, receivables, and
                      other similarly liquid assets of a company that can be
                      converted to cash within a fiscal year.
                    </Typography>
                  }
                >
                  <Chip
                    label={
                      <Typography
                        sx={{ typography: { xs: 'caption', md: 'subtitle1' } }}
                      >
                        Current Assets
                      </Typography>
                    }
                    variant="outlined"
                    size={smAndLess ? 'small' : 'medium'}
                  />
                </HtmlTooltip>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  x
                </Typography>{' '}
                <Grid item>
                  <Grid container direction="column">
                    <HtmlTooltip
                      arrow
                      placement="top"
                      title={
                        <Typography variant="body2">
                          This is the number of shares of a given stock that you
                          own.
                        </Typography>
                      }
                    >
                      <Chip
                        label={
                          <Typography
                            sx={{
                              typography: { xs: 'caption', md: 'subtitle1' },
                            }}
                          >
                            Shares Owned
                          </Typography>
                        }
                        variant="filled"
                        size={smAndLess ? 'small' : 'medium'}
                      />
                    </HtmlTooltip>
                    <Divider sx={{ my: 1, borderColor: '#333' }} />
                    <HtmlTooltip
                      arrow
                      placement="bottom"
                      title={
                        <Typography variant="body2">
                          The total number of shares that exist for a business
                          or fund.
                        </Typography>
                      }
                    >
                      <Chip
                        label={
                          <Typography
                            sx={{
                              typography: { xs: 'caption', md: 'subtitle1' },
                            }}
                          >
                            Outstanding Shares
                          </Typography>
                        }
                        variant="filled"
                        size={smAndLess ? 'small' : 'medium'}
                      />
                    </HtmlTooltip>
                  </Grid>
                </Grid>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  x
                </Typography>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  <b>2.5%</b>
                </Typography>
                <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
                  =
                </Typography>
                <Grid item>
                  <HtmlTooltip
                    arrow
                    placement={smAndLess ? 'bottom' : 'right'}
                    title={
                      <Typography variant="body2">
                        The amount of Zakat due for a holding in which you are
                        an passive investor.
                      </Typography>
                    }
                  >
                    <Chip
                      color="primary"
                      label={
                        <Typography
                          sx={{
                            typography: { xs: 'caption', md: 'subtitle1' },
                          }}
                        >
                          Zakat Amount
                        </Typography>
                      }
                      variant="filled"
                      size={smAndLess ? 'small' : 'medium'}
                    />
                  </HtmlTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection="row"
          display={'flex'}
          container
          rowGap={2}
        ></Grid>
      </Box>
    </Modal>
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))
