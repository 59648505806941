import { PropsWithChildren, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { isError } from 'lodash'
import { useStore } from '@/store'
import { User } from '../api'

export const RequireAuth: React.FC<PropsWithChildren> = ({ children }) => {
  let location = useLocation()

  const [currentUserRequest, setCurrentUserRequest] = useState<{
    user?: User
    loading: boolean
    error?: Error
  }>({
    loading: true,
  })

  const { setAuthenticatedUser } = useStore()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await Auth.currentAuthenticatedUser()
        const user: User = response.attributes
        setCurrentUserRequest({
          loading: false,
          error: undefined,
          user,
        })
        setAuthenticatedUser(user)
      } catch (e) {
        const error = isError(e)
          ? e
          : new Error('Failed to fetch current authenticated user')
        setCurrentUserRequest({
          loading: false,
          error: error,
          user: undefined,
        })
      }
    }
    fetchUser()
  }, [])

  if (currentUserRequest.loading) {
    return <div>loading</div> //TODO loading component
  }

  if (!currentUserRequest.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/log-in" state={{ from: location }} replace />
  }

  if (currentUserRequest.error) {
    return <Navigate to="/auth/log-in" replace />
  }

  return <>{children}</>
}
