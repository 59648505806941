import React from 'react'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { SignUpForm } from '../components/SignUpForm'

import { useNavigate } from 'react-router-dom'

export const SignUp = () => {
  const navigate = useNavigate()

  const handleNavigateToSignIn = () => {
    navigate('/auth/log-in')
  }

  return (
    <Container
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          height="100vh"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          xs={5}
        >
          <Card
            sx={{
              p: { xs: 2, md: 4 },
              my: 2,
              minWidth: 320,
              maxWidth: 500,
              alignSelf: 'center',
            }}
            elevation={0}
          >
            <Typography variant="h4">Sign Up</Typography>
            <Box
              sx={{ mt: 1 }}
              flexDirection={'row'}
              display="flex"
              justifyContent={'space-between'}
            >
              <Typography variant="subtitle1">
                Already have an account ?
              </Typography>
              <ButtonBase onClick={handleNavigateToSignIn}>
                <Typography variant="subtitle1" color={'primary'}>
                  Log in
                </Typography>
              </ButtonBase>
            </Box>
            <SignUpForm />
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
