import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { User } from '@/auth/api'
import { WatchlistStock } from '@/watchlist/types'

type AppStore = {
  authenticatedUser: User | null
  setAuthenticatedUser: (user: User | null) => void
  watchlist: WatchlistStock[] | null
  setWatchlist: (watchlistStocks: WatchlistStock[] | null) => void
}

export const useStore = create<AppStore>()(
  persist(
    devtools((set) => ({
      // Authentication state
      authenticatedUser: null,
      setAuthenticatedUser: (user: AppStore['authenticatedUser']) =>
        set((_) => ({ authenticatedUser: user })),
      // Watchlist state
      watchlist: null,
      setWatchlist: (watchlistStocks) =>
        set((_) => ({ watchlist: watchlistStocks })),
    })),
    { name: 'AppStore' }
  )
)
