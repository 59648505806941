import React, { useState } from 'react'

import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { ChevronRight } from '@mui/icons-material'

import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { signUpSchema } from '../validation'
import { signUp } from '../api'

export const SignUpForm = () => {
  const navigate = useNavigate()

  const [signUpRequest, setSignUpRequest] = useState({
    loading: false,
    error: '',
  })

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      setSignUpRequest({ loading: true, error: '' })
      const { error } = await signUp(values)
      if (error) {
        setSignUpRequest({ loading: false, error: error.message })
      } else {
        setSignUpRequest({ loading: false, error: '' })
        navigate('/auth/verification', { state: { values } })
      }
    },
  })

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <form onSubmit={form.handleSubmit}>
        {signUpRequest.error && signUpRequest.error !== '' && (
          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
            {signUpRequest.error}
          </Alert>
        )}
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="email"
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="password"
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          value={form.values.password}
          onChange={form.handleChange}
          error={form.touched.password && Boolean(form.errors.password)}
          helperText={form.touched.password && form.errors.password}
        />
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          variant="outlined"
          value={form.values.confirmPassword}
          onChange={form.handleChange}
          error={
            form.touched.confirmPassword && Boolean(form.errors.confirmPassword)
          }
          helperText={
            form.touched.confirmPassword && form.errors.confirmPassword
          }
        />
        <LoadingButton
          fullWidth
          sx={{ my: 2, py: 1 }}
          variant="contained"
          endIcon={<ChevronRight />}
          type="submit"
          loading={signUpRequest.loading}
        >
          Sign Up
        </LoadingButton>
      </form>
    </Grid>
  )
}
