import { isNil } from 'lodash'
import { ApolloError } from '@apollo/client'
import {
  AlpacaAccountStatus,
  useGetInvestAccountStatusQuery,
} from '@/graphQL/generatedGraphql'

export type UseInvestAccountStatus = {
  loading: boolean
  error: ApolloError | undefined
  hasAccount: boolean
  accountStatus: AlpacaAccountStatus | undefined
}

/**
 * Hook to find if current user has an alpaca account and its status
 */
export const useInvestAccountStatus = (): UseInvestAccountStatus => {
  const { data, loading, error } = useGetInvestAccountStatusQuery({
    fetchPolicy: 'cache-and-network',
  })

  const expectedError = error?.message === 'no alpaca mapping found'

  const hasAccount = !isNil(data?.invest?.account)

  return {
    loading,
    error: error && !expectedError ? error : undefined,
    hasAccount,
    accountStatus: data?.invest?.account?.status,
  }
}
