import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import { CodeRequestForm } from '../components/CodeRequestForm'
import { PasswordResetForm } from '../components/PasswordResetForm'

import { alpha, useTheme } from '@mui/material'

export const PasswordReset = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [email, setEmail] = useState<string | null>(null)

  return (
    <Container
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          height="100vh"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          xs={5}
        >
          <Card
            sx={{
              p: { xs: 2, md: 4 },
              my: 2,
              minWidth: 320,
              maxWidth: 500,
              alignSelf: 'center',
            }}
            elevation={0}
          >
            <Box>
              <Typography variant="h4">Reset your password</Typography>
              <Typography variant="subtitle2">
                Step {currentStep} of 2
              </Typography>
              {currentStep === 1 ? (
                <Typography variant="body1" sx={{ mt: 3 }}>
                  Enter the email address associated with your account then
                  check your inbox for password reset instructions.
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ mt: 3 }}>
                  Instructions to reset your password have been sent to {email}{' '}
                  successfully. Enter the code you found to continue.
                </Typography>
              )}
            </Box>
            <Box>
              {!email || currentStep === 1 ? (
                <CodeRequestForm
                  onSuccess={(email) => {
                    setEmail(email)
                    setCurrentStep(2)
                  }}
                />
              ) : (
                <PasswordResetForm email={email} />
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

const ErrorView: React.FC<{ errorTitle?: string; errorBody?: string }> = ({
  errorTitle,
  errorBody,
}) => {
  const { palette } = useTheme()

  return (
    <Collapse in={!!errorTitle || !!errorBody}>
      <Box
        sx={{
          px: 4,
          py: 1,
          mt: 2,
          backgroundColor: alpha(palette.error.main, 0.15),
        }}
      >
        {/* <Typography variant="subtitle2" color={palette.error.dark}>
          {errorTitle}
        </Typography> */}
        <Typography variant="body1" color={palette.error.dark}>
          {errorBody}
        </Typography>
      </Box>
    </Collapse>
  )
}
