import React from 'react'

import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MoreIcon from '@mui/icons-material/MoreHoriz'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { useStore } from '@/store'
import { Link } from 'react-router-dom'
import { AccountMenu } from './AccountMenu'
import { MobileNavMenu } from './MobileNavMenu'
import ZoyaLogo from '@/minaret/assets/zoya-logo.svg'

const routes: Array<{
  label: string
  path: string
  icon?: React.ReactElement
}> = [
  {
    label: 'Invest',
    path: '/invest',
  },
  {
    label: 'Zakat',
    path: '/zakat',
  },
]

export const Header: React.FC = () => {
  const [mobileMoreAnchorElement, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [accountAnchorElement, setAccountAnchorElement] =
    React.useState<null | HTMLElement>(null)

  const { authenticatedUser } = useStore()

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleAccountMenuClose = () => {
    setAccountAnchorElement(null)
  }

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorElement(event.currentTarget)
  }

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
    target: window,
  })

  return (
    <Box marginBottom={12}>
      <AppBar
        position="fixed"
        elevation={0}
        color={scrollTrigger ? 'inherit' : 'transparent'}
        sx={{
          pt: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Toolbar sx={{ maxWidth: 'lg', width: '100%' }}>
          <img width="70" src={ZoyaLogo} />

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {routes.map((route, index) => {
              return (
                <Button
                  key={`${index}-${route.label}`}
                  component={Link}
                  color="inherit"
                  to={route.path}
                  startIcon={route.icon}
                >
                  <Typography>{route.label}</Typography>
                </Button>
              )
            })}
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </Box>

          <Box>
            <IconButton
              size="large"
              edge="end"
              aria-label="user-account"
              aria-haspopup="true"
              color="inherit"
              onClick={handleAccountMenuOpen}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  fontSize: 16,
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ transform: 'translate(0, 1px)', fontWeight: 600 }}
                >
                  {authenticatedUser?.email.slice(0, 1).toUpperCase() || '?'}
                </Typography>
              </Avatar>
            </IconButton>
          </Box>
        </Toolbar>
        <MobileNavMenu
          routes={routes}
          anchorElement={mobileMoreAnchorElement}
          onClose={handleMobileMenuClose}
        />
        <AccountMenu
          anchorElement={accountAnchorElement}
          onClose={handleAccountMenuClose}
        />
      </AppBar>
    </Box>
  )
}
