import { memoize } from 'lodash'

const resolveCurrencyFormatter = memoize(
  (currency: string, notation: 'compact' | 'standard' = 'standard') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      notation: notation,
    })
  },
  (...args) => args.join('_')
)

export const formatCurrency = (
  value?: number | null,
  currency?: string | null,
  notation: 'compact' | 'standard' = 'standard'
) => {
  if (value == null) {
    return '-'
  }
  // TODO: Record an error/warning if an unrecgonized currency is found
  const formatter = resolveCurrencyFormatter(currency || 'USD', notation)
  return formatter.format(value)
}
