import React, { useEffect, useRef, useState } from 'react'

import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { AddHoldingsStep } from '../components/AddHoldingsStep'
import { ZakatSummary } from '../components/ZakatSummary'
import { ZakatHolding } from '../types'
import { isZakatCalculation, ZakatCalculation } from '../calculation'
import { isError } from 'lodash'
import { useSegment } from '@/events/segment'
import { Statsig } from 'statsig-react'
import { Alert, AlertTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Bugsnag from '@bugsnag/js'

const { VITE_ZAKAT_API_ENDPOINT } = import.meta.env


type ZakatCalculationResponse = {
  calculationDate: string ,
  zakatLiableAmount: number, 
  zakatableAmount: number,
  zakatDue: number ,
  currency: string,
  holdingsMarketValue: number,
  holdingsCount: number,
  holdings:[{
    symbol:string,
    name:string,
    strategy: "PASSIVE" | "ACTIVE",
    treatAsCash: boolean,
    quantity: number,
    unitPrice: number,
    currency: string,
    outstandingShares: number,
    currentAssets: number, 
    marketValue: number,
    zakatLiableAmount: number,
    zakatableAmount: number,
    zakatDue: number,
    calculationMethod: string}]}

const fetchZakatCalculation = async (
  holdings: Partial<ZakatHolding>[]
): Promise<ZakatCalculation | null> => {
  try {
    const response = await fetch(`${VITE_ZAKAT_API_ENDPOINT}/zakat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ holdings: holdings }),
    })
    const data = await response.json()
    const zakatResponse = data as ZakatCalculationResponse
    const formattedHoldings = zakatResponse.holdings.map(holding => {
      return {
        ...holding,
        type: holding.strategy === 'PASSIVE' ? 'Passive' : 'Active',
        shares: holding.quantity,

      }
    })


    const activeHoldings = zakatResponse.holdings.filter(holding => holding.strategy === 'ACTIVE')
    const passiveHoldings = zakatResponse.holdings.filter(holding => holding.strategy === 'PASSIVE')

    const activeSummary = activeHoldings.reduce((curValue, acc) =>  {
      return {
        baseValue: curValue.baseValue + acc.marketValue,
        zakatableAmount: curValue.zakatableAmount + acc.zakatableAmount,
        zakatDue: curValue.zakatDue + acc.zakatDue
      }
    }, {
      baseValue: 0,
      zakatableAmount: 0,
      zakatDue: 0
    })

    const passiveSummary = passiveHoldings.reduce((curValue, acc) =>  {
      return {
        baseValue: curValue.baseValue + acc.marketValue,
        zakatableAmount: curValue.zakatableAmount + acc.zakatableAmount,
        zakatDue: curValue.zakatDue + acc.zakatDue
      }
    }, {
      baseValue: 0,
      zakatableAmount: 0,
      zakatDue: 0
    })

    const formattedData = {
      zakatDue: zakatResponse.zakatDue,
      holdings: formattedHoldings,
      summary: {
        active: activeSummary,
        passive: passiveSummary,
        successCount: zakatResponse.holdingsCount,
        fallbackCount: 0,
        failCount: 0
      }
    }
    if (isZakatCalculation(formattedData)) {

      return formattedData
    } else {
      throw Error("response doesn't match expected type, recieved: ", data)
    }
  } catch (error) {
    throw new Error('failed to fetch zakat calculation', { cause: error })
  }
}

export const Calculator: React.FC = (props) => {
  const [holdings, setHoldings] = useState<Partial<ZakatHolding>[]>([])
  const [holdingsDiff, setHoldingsDiff] = useState<Partial<ZakatHolding>[]>([])
  const [zakatCalculation, setZakatCalculation] = useState<{
    result: ZakatCalculation | null
    error?: Error
    loading: boolean
  }>({
    result: null,
    loading: false,
  })

  const zakatSummaryRef = useRef<HTMLDivElement>(null)

  const { analytics } = useSegment()

  useEffect(() => {
    if (zakatSummaryRef.current && zakatCalculation.result) {
      zakatSummaryRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [zakatCalculation.result])

  useEffect(() => {
    if (
      holdings.length > 0 &&
      !zakatCalculation.loading &&
      !!zakatCalculation.result
    ) {
      const diff = holdings.filter((object1) => {
        return !zakatCalculation.result?.holdings.some((object2) => {
          return (
            object1.symbol === object2.symbol &&
            object1.shares === object2.shares &&
            object1.unitPrice === object2.unitPrice
          )
        })
      })
      setHoldingsDiff(diff)
    }
  }, [holdings])

  const calculateZakat = () => {
    if (holdings.length > 0) {
      setZakatCalculation((state) => ({ ...state, loading: true }))
      const zakatHoldings  = holdings.map((hold) => {return{...hold, unitPrice: hold.unitPrice ?? 0, currency: hold.currency ?? 'USD', quantity: hold.shares ?? 0, strategy: hold.type?.toUpperCase()}})

      fetchZakatCalculation(zakatHoldings as ZakatHolding[])
        .then((result) => {
          analytics?.track('Zakat Calculation', {
            holdingsCount: holdings.length,
            zakatTotal: result?.zakatDue,
            failuresCount: result?.summary.failCount,
          })
          Statsig.logEvent('Zakat Calculation', result?.zakatDue, {
            holdingsCount: JSON.stringify(holdings.length),
            zakatTotal: JSON.stringify(result?.zakatDue),
            failuresCount: JSON.stringify(result?.summary.failCount),
          })
          setZakatCalculation({ result, loading: false })
          setHoldingsDiff([])
        })
        .catch((err) => {
          const error = isError(err)
            ? err
            : new Error('Failed to calculate zakat for an unexpected reason')
          Bugsnag.notify(error, (report) => {
            report.addMetadata('ZakatCalculationAPI', 'errorType', error)
            report.addMetadata('ZakatCalculationAPI', 'message', error.message)
          })
          setZakatCalculation({ result: null, error, loading: false })
        })
    } else {
      setZakatCalculation({ result: null, loading: false })
    }
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <Grid container>
        <Grid item xs={12} zeroMinWidth>
          <Card
            variant="outlined"
            sx={{
              marginY: 2,
              p: { xs: 2, md: 3 },
              paddingTop: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {zakatCalculation.error && (
              <Alert
                sx={{ marginBottom: 2, alignItems: 'center' }}

                severity="error"
                action={
                  <LoadingButton
                    color="inherit"
                    size="small"
                    variant="outlined"
                    loading={zakatCalculation.loading}
                    onClick={calculateZakat}
                  >
                    Retry
                  </LoadingButton>
                }
              >
                <AlertTitle>Failed</AlertTitle>
                {zakatCalculation.error?.message}. please try again later
              </Alert>
            )}
            <AddHoldingsStep
              onHoldingsChange={(newHoldings) => setHoldings(newHoldings)}
              onCalculateClick={calculateZakat}
              loadingCalculation={zakatCalculation.loading}
            />
          </Card>
          {zakatCalculation.result ? (
            <Card
              ref={zakatSummaryRef}
              variant="outlined"
              sx={{
                my: 3,
                p: 3,
              }}
            >
              <ZakatSummary
                calculation={zakatCalculation}
                holdingsDiff={holdingsDiff}
                onReCalculateClick={calculateZakat}
              />
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  )
}
