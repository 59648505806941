import React from 'react'

// Higher order component that wraps a given react component with React.Suspense
export const withSuspense = <T extends Record<string, unknown>>(
  Component: React.FunctionComponent<T>,
  Fallback: React.FunctionComponent<Record<string, never>> = () => null
) => {
  return (props: T) => (
    <React.Suspense fallback={<Fallback />}>
      <Component {...props} />
    </React.Suspense>
  )
}
