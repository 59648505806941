import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { Container } from '@mui/material'

import { RequireAuth } from '@/auth/components/RequireAuth'
import { Header } from '@/common/components/Header'
import { Search } from '@/search/search'
import { InvestNavigation } from '@/invest/components/invest-navigation/invest-navigation'

export const AppFrame: React.FC = () => {
  const { pathname } = useLocation()

  if (pathname === '/') {
    return <Navigate to="zakat" />
  }

  return (
    <RequireAuth>
      <Container maxWidth="lg">
        <Header />
        <Search />
        <InvestNavigation />
        <Outlet />
      </Container>
    </RequireAuth>
  )
}
