import React, {
  useRef,
  useContext,
  useEffect,
  createContext,
  forwardRef,
  useMemo,
  useCallback,
  useState,
} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import { useTheme } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Typography from '@mui/material/Typography'
import { Grid, Stack } from '@mui/material'
import { SearchableEntry } from '@/data/API'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { matchSorter } from 'match-sorter'
import { useConfig } from 'statsig-react'
import { useTypesenseSearch } from '@/data/hooks/useTypesenseSearch'
import { isEmpty, isString, noop, identity } from 'lodash'
import { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import { ComplianceStatus } from '@/graphQL/generatedGraphql'

type AutoCompleteOnInputChange = NonNullable<
  Parameters<typeof Autocomplete>[0]['onInputChange']
>

const TYPESENSE_HOST = import.meta.env.VITE_TYPESENSE_HOST
const TYPESENSE_KEY = import.meta.env.VITE_TYPESENSE_KEY
const TYPESENSE_COLLECTION = import.meta.env.VITE_TYPESENSE_COLLECTION

const LISTBOX_PADDING = 8 // px
const sxPercent100Width = { width: '100%' }
const fontWeight600 = { fontWeight: 600 }

const renderRow: React.FC<ListChildComponentProps> = ({
  data,
  index,
  style,
}) => {
  const dataSet = data[index]
  const option = dataSet[1]
  const state = dataSet[2]
  const inlineStyle = {
    ...style,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    top: (style.top as number) + LISTBOX_PADDING,
  } as React.CSSProperties

  if (dataSet.group) {
    return (
      <ListSubheader key={dataSet.key} style={inlineStyle}>
        <b>{dataSet.group}</b>
      </ListSubheader>
    )
  }

  const symbolMatches = match(option.symbol, state.inputValue, {
    insideWords: true,
  })
  const nameMatches = match(option.name, state.inputValue, {
    findAllOccurrences: true,
  })
  const symbolParts = parse(option.symbol, symbolMatches)
  const nameParts = parse(option.name, nameMatches)

  return (
    <Grid {...dataSet[0]} style={inlineStyle}>
      <Stack direction="row">
        {symbolParts.map((part, index) => (
          <Typography
            key={String(index)}
            variant="body1"
            fontWeight={part.highlight ? 'bold' : 'normal'}
          >
            {part.text}
          </Typography>
        ))}
      </Stack>
      <Stack direction="row" width="95%">
        <Typography noWrap variant="body2">
          {nameParts.map((part, index) => {
            return part.highlight ? (
              <span key={index} style={fontWeight600}>
                {part.text}
              </span>
            ) : (
              `${part.text}`
            )
          })}
        </Typography>
      </Stack>
    </Grid>
  )
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null)
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    },
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 56 : 64

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 36
    }
    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const env = import.meta.env
export const isNonEmptyString = (value: unknown): value is string => {
  return isString(value) && !isEmpty(value)
}

export interface StockSummary {
  symbol: string
  name: string
  companyName: string
  status: ComplianceStatus
  region: string
  exchange: string
  currency?: string
  issueType?: string
}

const filterOptions = (
  options: SearchableEntry[],
  { inputValue }: { inputValue: string },
) =>
  inputValue?.length
    ? matchSorter(options, inputValue, { keys: ['symbol', 'name'] })
    : options

const queryBy = ['symbol', 'name'] as const

export const SymbolField: React.FC<{
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | SearchableEntry | null,
  ) => void
}> = React.memo(({ onChange }) => {
  const [query, setQuery] = useState('')
  const [options, setOptions] = useState<StockSummary[]>([])
  const { config } = useConfig('search_config')

  const typesenseOptions = useMemo(() => {
    return {
      host: config.get('host', TYPESENSE_HOST, isNonEmptyString),
      apiKey: config.get(
        'zakatOverrideApiKey',
        config.get('apiKey', TYPESENSE_KEY, isNonEmptyString),
        isNonEmptyString,
      ),
      collection: config.get(
        'zakatOverrideCollection',
        config.get('collection', TYPESENSE_COLLECTION, isNonEmptyString),
        isNonEmptyString,
      ),
    }
  }, [config])

  const parseResults = useCallback((results: any[]) => {
    return results.map((result) => {
      // TODO: Use yup/zod here in the future
      return {
        ...result?.document,
        companyName: result?.document?.name,
      }
    })
  }, [])

  const onSearchSuccess = useCallback(
    (
      newResults: StockSummary[],
      metadata: {
        found: number
        query: string
        filterBy?: string
        sortBy?: string
        page?: number
        facets?: SearchResponseFacetCountSchema<StockSummary>[]
      },
    ) => {
      setOptions(newResults)
    },
    [],
  )

  useTypesenseSearch({
    query,
    queryBy,
    options: typesenseOptions,
    onSearchSuccess,
    onSearchError: noop,
    debounceWait: 150,
    parseResults,
  })

  const getOptionLabel = useCallback((option: SearchableEntry | string) => {
    return typeof option === 'string' ? option : option.symbol
  }, [])
  const onInputChange = useCallback<AutoCompleteOnInputChange>(
    (_, newInputValue) => {
      setQuery(newInputValue)
    },
    [],
  )
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField variant="standard" {...params} label="Symbol" />
    ),
    [],
  )
  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: SearchableEntry,
      state: AutocompleteRenderOptionState,
    ) => {
      return [props, option, state]
    },
    [],
  )
  const renderGroup = identity

  return (
    <Autocomplete
      sx={sxPercent100Width}
      disableListWrap
      freeSolo
      autoHighlight
      onChange={onChange}
      inputMode="text"
      includeInputInList
      ListboxComponent={ListboxComponent}
      options={options}
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      // WHY IS THIS HERE?!?
      // @ts-ignore
      renderOption={renderOption}
      // WHY IS THIS HERE?!?
      // @ts-ignore
      renderGroup={renderGroup}
    />
  )
})
