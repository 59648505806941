import React, { useCallback, useEffect } from 'react'

import { Hub, HubCallback } from '@aws-amplify/core'
import Bugsnag from '@bugsnag/js'
import { useNavigate } from 'react-router-dom'
import { User } from '../api'
import { useStore } from '@/store'

// TODO Emit segement events here maybe
export const AuthEventsListener = () => {
  const navigate = useNavigate()
  const { setAuthenticatedUser } = useStore()

  const eventHandler: HubCallback = useCallback(
    async (event) => {
      const { event: eventType, data, message } = event.payload
      switch (eventType) {
        case 'signIn':
          const user: User = data.attributes
          setAuthenticatedUser(user)
          break
        case 'signIn_failure':
          Bugsnag.notify(new Error('User failed to sign up'), (report) => {
            data.code && report.addMetadata('ERROR', 'code', data.code)
            data.message && report.addMetadata('ERROR', 'message', data.message)
          })
          break
        case 'signUp':
          break
        case 'signUp_failure':
          Bugsnag.notify(new Error('User failed to sign up'), (report) => {
            data.code && report.addMetadata('ERROR', 'code', data.code)
            data.message && report.addMetadata('ERROR', 'message', data.message)
          })
          break
        case 'signOut':
          setAuthenticatedUser(null)
          navigate('/')
          break
        case 'autoSignIn':
          break
        case 'autoSignIn_failure':
          break
        case 'configured':
          break
        case 'tokenRefresh':
          break
        case 'tokenRefresh_failure':
          break
      }
    },
    [navigate]
  )

  useEffect(() => Hub.listen('auth', eventHandler), [eventHandler])

  useEffect(() => () => Hub.remove('auth', eventHandler), [])

  return null
}
