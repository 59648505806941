import React, { useCallback } from 'react'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses'
import { TooltipProps } from '@mui/material/Tooltip/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { useLocation } from 'react-router-dom'
import { useSegment } from '@/events/segment'

import appLogo from '../../assets/zoya-logo-background.jpg'
import mobileAppScreenShot from '../../assets/zakat-calculator-mobile-app.jpg'
import downloadQRCode from '../../assets/download-qr-code.jpg'

export const LinkedAccounts = () => {
  const { analytics } = useSegment()
  const location = useLocation()

  const onDownloadZoyaClick = useCallback(() => {
    if (analytics) {
      analytics.track('Download Zoya', {
        current_path: location.pathname,
        scope: 'import accounts', // FIXME @bilalq halp
      })
    }
  }, [analytics, location.pathname])

  return (
    <Grid container>
      <Grid item xs={6}>
        <Stack
          direction="column"
          justifyContent="center"
          height="100%"
          width="90%"
        >
          <img
            src={appLogo}
            style={{ width: 40, borderRadius: 8, marginBottom: 16 }}
          />

          <Typography variant="subtitle1" fontWeight="bold">
            Use the Zoya mobile app for this feature.
          </Typography>
          <Typography variant="subtitle1" sx={{}}>
            Link your brokerage account, track your portfolio, and calculate
            Zakat easily.
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          height="100%"
        >
          <img
            src={mobileAppScreenShot}
            style={{ width: '100%', marginTop: 20 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{}}>
        <Divider sx={{ marginBlock: 0.5 }} />
        <HtmlTooltip
          placement="top"
          title={
            <Stack>
              <img src={downloadQRCode} style={{ width: '100%' }} />
              <Typography variant="body1" textAlign="center" sx={{ px: 1 }}>
                Point your camera here to open or download the app
              </Typography>
            </Stack>
          }
        >
          <Link
            underline="none"
            target="_blank"
            rel="noopener"
            href="http://onelink.to/d23j9g"
          >
            <Button
              variant="contained"
              fullWidth
              disableElevation
              sx={{ marginTop: 1 }}
              onClick={onDownloadZoyaClick}
            >
              Download APP
            </Button>
          </Link>
        </HtmlTooltip>
      </Grid>
    </Grid>
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 280,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid rgba(51, 51, 51, 0.08)',
    boxShadow: '0px 2px 16px rgba(51, 51, 51, 0.08)',
  },
}))
