import { Routes, Route, Navigate } from 'react-router-dom'
import { Landing } from './pages/Landing'

export const ZakatRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<Navigate to="calculator" />} />
    </Routes>
  )
}
