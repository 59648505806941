import React from 'react'

import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid'

import numeral from 'numeral'
import { formatCurrency } from '@/common/currencyFormatter'

export const ValueCell = (params: GridRenderCellParams) => {
  return params.row.dataFetch && !params.row.dataFetch.finished ? (
    <Skeleton animation="wave" width={75} height={22} />
  ) : !params.row.shares || !params.row.unitPrice || !params.row.currency ? (
    <Typography>-</Typography>
  ) : (
    <Typography>
      {formatCurrency(
        params.row.unitPrice * params.row.shares,
        params.row.currency
      )}
    </Typography>
  )
}
