import React, { useCallback, useLayoutEffect, useState } from 'react'
import Add from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { createFilterOptions } from '@mui/material/Autocomplete'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { isSearchableEntry, SearchableEntry } from '@/data/API'
import { ZakatHolding } from '../../types'
import { FormHelperText } from '@mui/material'
import { SymbolField } from '../SymbolField'
import { useSegment } from '@/events/segment'
import { Statsig } from 'statsig-react'

const addHoldingModalstyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 'calc(100% - 1rem)', sm: 450 },
  bgcolor: 'background.paper',
  boxShadow: 8,
  borderRadius: 1,
  px: 3,
  py: 2,
}

const filterOptions = createFilterOptions<SearchableEntry>({
  matchFrom: 'start',
  ignoreAccents: false,
  limit: 200,
  stringify: (option) => `${option.symbol} | ${option.name}`,
})

export const AddHoldingModal: React.FC<{
  open: boolean
  onClose: () => void
  onAddHolding: (
    holding: Partial<ZakatHolding & { assetType: string }>[],
  ) => void
}> = React.memo(({ open, onClose, onAddHolding }) => {
  const [holding, setHolding] = useState<{
    symbol?: string
    region?: string
    shares?: number
    type?: ZakatHolding['type']
    assetType?: string
  }>({
    type: 'Passive',
  })
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    SearchableEntry[]
  >([])

  const { analytics } = useSegment()
  const theme = useTheme()
  const smAndLess = useMediaQuery(theme.breakpoints.down('sm'))

  const handleAddHolding = () => {
    if (holding.symbol && holding.shares && holding.type) {
      analytics?.track('Zakat Add Holding', {
        symbol: holding.symbol,
        region: holding.region,
        source: 'manual',
        assetType: holding.assetType && { assetType: holding.assetType },
      })
      Statsig.logEvent('Zakat Add Holding', holding.symbol, {
        symbol: holding.symbol,
        region: JSON.stringify(holding.region),
        source: 'manual',
        assetType: holding.assetType ? holding.assetType : '',
      })
      onAddHolding([
        {
          symbol: holding.symbol,
          shares: holding.shares,
          type: holding.type,
          assetType: holding.assetType,
          dataFetch: {
            missingFields: [],
            finished: false,
            error: '',
          },
        },
      ])
      setHolding({ type: 'Passive' })
    }

    onClose()
  }
  type SymbolFieldOnChangeFn = Parameters<typeof SymbolField>[0]['onChange']
  const onSymbolFieldChange = useCallback<SymbolFieldOnChangeFn>(
    (_, newValue) =>
      setHolding((holding) => {
        const isValid = isSearchableEntry(newValue)
        return {
          ...holding,
          symbol: isValid
            ? newValue.symbol
            : typeof newValue === 'string'
              ? newValue
              : '',
          assetType:
            isValid && newValue.issueType === 'mutual'
              ? 'mutual fund'
              : undefined,
          region: isValid ? newValue.region : undefined,
        }
      }),
    [],
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={addHoldingModalstyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Holding
        </Typography>
        <Box paddingTop={2}>
          <Typography variant="caption">
            Stocks and funds available in the US, UK, and Canada can be searched
            below. For international symbols, please use the import CSV feature
            to add them to your holdings.
          </Typography>
        </Box>
        <Grid
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection="row"
          display={'flex'}
          container
          rowGap={3}
          sx={{ my: 1 }}
        >
          <Grid item xs={12}>
            <SymbolField onChange={onSymbolFieldChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Shares Count"
              type="number"
              variant="standard"
              onChange={(e) =>
                setHolding((holding) => ({
                  ...holding,
                  shares: parseFloat(e.target.value),
                }))
              }
              sx={{ width: '100%' }}
            />
            <FormHelperText id="component-helper-text">
              number of shares you hold
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={holding.type}
              onChange={(event) =>
                setHolding((holding) => ({
                  ...holding,
                  type: event.target.value as Partial<ZakatHolding>['type'],
                }))
              }
              select
              variant="standard"
              label="Type"
              sx={{ width: '100%' }}
            >
              <MenuItem value="Passive">Long-Term (Passive)</MenuItem>
              <MenuItem value="Active">Short-Term (Active)</MenuItem>
            </TextField>
            <FormHelperText id="component-helper-text">
              select whether your intention is to hold them long-term (&gt; 12
              months) or short-term (&lt; 12 months).
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems={'center'}
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          display={'flex'}
          rowGap={1}
          sx={{ mt: 4, flexWrap: 'wrap' }}
        >
          <Button fullWidth={smAndLess} variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            fullWidth={smAndLess}
            variant="contained"
            endIcon={<Add />}
            onClick={handleAddHolding}
            disabled={!holding.symbol || !holding.shares || !holding.type}
          >
            Add Holding
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
})
