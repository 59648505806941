import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Add from '@mui/icons-material/Add'
import AddCircleOutline from '@mui/icons-material/AddCircleOutlineRounded'
import ImportExport from '@mui/icons-material/ImportExport'
import UploadFile from '@mui/icons-material/UploadFileOutlined'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import Slide from '@mui/material/Slide'
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid'
import { AddHoldingModal } from './Modals/AddHoldingModal'
import { ImportHoldingsModal } from './Modals/ImportHoldingsModal'
import { CurrentPriceCell } from './CurrentPriceCell'
import { ValueCell } from './ValueCell'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ZakatHolding } from '../types'
import { IconButton } from '@mui/material'
import { fetchMutualFundData } from '@/data/API'
import { useSegment } from '@/events/segment'
import { Statsig } from 'statsig-react'
import { chunk } from 'lodash'
import { getQuote, getBatchQuotes, PseudoIexQuote } from '@/data/quote'

const fillHoldings = async (
  holdings: Partial<ZakatHolding & { assetType: string }>[],
) => {
  const holdingsWithData = holdings.filter(
    (holding) => holding.dataFetch?.finished,
  )

  const newMutualFundSymbols = holdings.reduce(
    (acc, holding, index) => {
      if (holding.assetType === 'mutual fund' && holding.symbol) {
        acc.push({ symbol: holding.symbol, index })
      }
      return acc
    },
    [] as { symbol: string; index: number }[],
  )

  for (const mutualFund of newMutualFundSymbols) {
    try {
      const mutualFundData = await fetchMutualFundData(mutualFund.symbol)
      const { name, price } = mutualFundData
      if (holdings[mutualFund.index]) {
        holdings[mutualFund.index].name = name ?? undefined
        holdings[mutualFund.index].unitPrice = price ?? undefined
        holdings[mutualFund.index].currency = 'USD'
      }
    } catch (error) {
      holdings[mutualFund.index].dataFetch!.missingFields.push(
        'name',
        'unitPrice',
        'currency',
      )
      holdings[mutualFund.index].dataFetch!.error = (error as Error).message
      console.error(error)
    } finally {
      holdings[mutualFund.index].dataFetch!.finished = true
    }
  }

  const holdingsSymbols = holdings.reduce(
    (acc, holding) => {
      if (
        holding.symbol &&
        !holding.dataFetch?.finished &&
        holding.assetType !== 'mutual fund'
      ) {
        const existingHoldingData = holdingsWithData.find(
          (_) => _.symbol === holding.symbol,
        )
        if (existingHoldingData) {
          holding.unitPrice = existingHoldingData.unitPrice
          holding.currency = existingHoldingData.currency
          holding.name = existingHoldingData.name
          holding.dataFetch!.finished = true
        } else {
          acc.push({
            symbol: holding.symbol.toUpperCase(),
            type: holding.type,
          })
        }
      }
      return acc
    },
    [] as { symbol: string; type: string | undefined }[],
  )

  if (holdingsSymbols.length === 1) {
    const holdingIndex = holdings.findIndex((holding) => {
      return (
        holding.symbol?.toUpperCase() === holdingsSymbols[0].symbol &&
        holding.type === holdingsSymbols[0].type
      )
    })
    let quote: PseudoIexQuote | null
    try {
      const quoteSymbol = holdingsSymbols[0].symbol
      quote = await getQuote(quoteSymbol)
    } catch (error) {
      holdings[holdingIndex].dataFetch!.error = (error as Error).message
      holdings[holdingIndex].dataFetch!.finished = true
      quote = null
    }
    if (!quote) {
      holdings[holdingIndex].dataFetch?.missingFields.push(
        'unitPrice',
        'currency',
        'name',
      )
    } else {
      holdings[holdingIndex].unitPrice = quote.latestPrice ?? undefined
      holdings[holdingIndex].currency = quote.currency ?? undefined
      holdings[holdingIndex].name = quote.companyName ?? undefined
    }
    holdings[holdingIndex].dataFetch!.finished = true
  } else if (holdingsSymbols.length > 1) {
    let data: {
      [key: string]:
        | {
            quote?: PseudoIexQuote
          }
        | undefined
    } | null

    const holdingBatches = chunk(holdingsSymbols, 100)
    data = {}
    for (const batch of holdingBatches) {
      try {
        const result = await getBatchQuotes(
          batch.map((holding) => holding.symbol),
        )
        debugger
        data = { ...data, ...result }
      } catch (error) {
        console.error(error)
      }
    }

    for (const holding of holdings) {
      if (Object.keys(data).length > 0) {
        if (holding.symbol && data[holding.symbol]) {
          const quote = data[holding.symbol]!.quote

          if (!quote) {
            holding.dataFetch?.missingFields.push(
              'unitPrice',
              'currency',
              'name',
            )
            holding.dataFetch!.error = 'Response 404 - Unknown symbol'
          } else {
            holding.unitPrice = quote.latestPrice ?? undefined
            holding.currency = quote.currency ?? undefined
            holding.name = quote.companyName ?? undefined
          }
          holding.dataFetch!.finished = true
        } else {
          holding.dataFetch?.missingFields.push('unitPrice', 'currency', 'name')
          holding.dataFetch!.error = 'Response 404 - Unknown symbol'
          holding.dataFetch!.finished = true
        }
      } else {
        holding.dataFetch?.missingFields.push('unitPrice', 'currency', 'name')
        holding.dataFetch!.error = 'Response 404 - Unknown symbol'
        holding.dataFetch!.finished = true
      }
    }
  }
  return Promise.resolve(holdings)
}

export const AddHoldingsStep: React.FC<{
  onHoldingsChange: (holdings: Partial<ZakatHolding>[]) => void
  onCalculateClick: () => void
  loadingCalculation: boolean
}> = ({ onHoldingsChange, onCalculateClick, loadingCalculation }) => {
  const [rows, setRows] = useState<
    Partial<ZakatHolding & { assetType: string }>[]
  >([])
  const [addHoldingModalOpen, setAddHoldingModalOpen] = useState(false)
  const [importHoldingsModalOpen, setImportHoldingsModalOpen] = useState(false)

  const { analytics } = useSegment()

  const theme = useTheme()
  const smAndLess = useMediaQuery(theme.breakpoints.down('sm'))

  const columns: GridColumns = [
    {
      field: 'symbol',
      headerName: 'Holding',
      width: 180,
      minWidth: 180,
      flex: 1,
      editable: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Grid minWidth={170}>
          <Slide in direction="down">
            <Typography variant="subtitle1">{params.value}</Typography>
          </Slide>
          {params.row.name && (
            <Slide in direction="up">
              <Typography noWrap variant="body2">
                {params.row.name}
              </Typography>
            </Slide>
          )}
          {!!params.row.dataFetch &&
            !!params.row.dataFetch.error &&
            !params.row.unitPrice && (
              <Slide in direction="up">
                <Typography noWrap variant="body2" color="#f44336">
                  Sorry, this symbol is not yet supported.
                </Typography>
              </Slide>
            )}
        </Grid>
      ),
    },

    {
      field: 'type',
      headerName: 'Type',
      valueOptions: [
        { label: 'Not Set', value: '' },
        { label: 'Passive (Long-term)', value: 'Passive' },
        { label: 'Active (Short-term )', value: 'Active' },
      ],
      type: 'singleSelect',
      width: 110,
      editable: true,
      sortable: true,
    },
    {
      field: 'shares',
      headerName: 'Shares',
      type: '',
      width: 110,
      editable: true,
      sortable: true,
      hideable: false,
    },
    {
      field: 'currentPrice',
      headerName: 'Current Price',
      type: 'number',
      width: 130,
      editable: false,
      sortable: true,
      hideable: false,
      renderCell: CurrentPriceCell,
    },
    {
      field: 'value',
      headerName: 'Value',
      type: 'number',
      width: 130,
      editable: false,
      sortable: true,
      hideable: false,
      renderCell: ValueCell,
    },
    {
      field: 'actions',
      type: 'actions',
      headerAlign: 'right',
      width: 60,
      editable: false,
      sortable: false,
      align: 'right',
      getActions: (params: GridRowParams<Partial<ZakatHolding>>) => [
        <GridActionsCellItem
          icon={<DeleteOutlined fontSize="small" />}
          onClick={(_) => handleDeleteRow(params.row)}
          label="Delete"
        />,
        // <GridActionsCellItem icon={<EditOutlined fontSize="small" />} onClick={() => 0} label="Edit" showInMenu />,
      ],
    },
  ]

  const handleAddRows = useCallback(
    async (holdings: Partial<ZakatHolding & { assetType: string }>[]) => {
      //The holdings from csv can contain duplicate, this will combine them.
      const uniqueHoldings = holdings.reduce(
        function (accumulator, cur) {
          const symbol = cur.symbol
          const found = accumulator.find(function (elem) {
            return elem.symbol == symbol
          })
          if (found) {
            const foundShare = found.shares ?? 0
            const curShare = cur.shares ?? 0
            found.shares = foundShare + curShare
          } else accumulator.push(cur)
          return accumulator
        },
        [] as Partial<ZakatHolding & { assetType: string }>[],
      )

      //Get the existing row while adding the duplicate holdings.
      const existingRow = rows.map((holding) => {
        const duplicateHolding = uniqueHoldings.find(
          (rHolding) =>
            holding.symbol === rHolding.symbol &&
            holding.type === rHolding.type,
        )

        if (duplicateHolding) {
          const newShare = duplicateHolding.shares ?? 0
          const existingShare = holding.shares ?? 0
          const aggHolding = {
            ...holding,
            shares: newShare + existingShare,
          }
          return aggHolding
        }
        return holding
      })

      //Get only the unique new holdings.
      const newUniqueHoldings = uniqueHoldings.filter((item) => {
        return !rows.some(
          (holding) =>
            holding.symbol === item.symbol && holding.type === item.type,
        )
      })

      const newRows = [...existingRow, ...newUniqueHoldings]
      setRows(newRows)
      const filledHolding = await fillHoldings(newRows)
      setRows(filledHolding)
      onHoldingsChange(filledHolding)
    },
    [rows],
  )

  const handleDeleteRow = (rowToBeDeleted: Partial<ZakatHolding>) => {
    analytics?.track('Zakat Remove Holding', {
      symbol: rowToBeDeleted.symbol,
      missingFields: rowToBeDeleted.dataFetch?.missingFields ?? [],
    })
    Statsig.logEvent('Zakat Remove Holding', rowToBeDeleted.symbol, {
      symbol: JSON.stringify(rowToBeDeleted.symbol),
      missingFields: JSON.stringify(
        rowToBeDeleted.dataFetch?.missingFields ?? [],
      ),
    })
    setRows((rows) => {
      const index = rows.findIndex(
        (r) =>
          r.symbol === rowToBeDeleted.symbol &&
          r.type === rowToBeDeleted.type &&
          r.shares === rowToBeDeleted.shares,
      )
      const newRows = [...rows]
      newRows.splice(index, 1)
      onHoldingsChange(newRows)
      return newRows
    })
  }

  const onCloseAddHoldingModal = useCallback(
    () => setAddHoldingModalOpen(false),
    [],
  )
  const onCloseImportHoldingsModal = useCallback(
    () => setImportHoldingsModalOpen(false),
    [],
  )

  return (
    <>
      <Grid
        container
        direction="row"
        rowGap={1}
        sx={{
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Grid item>
          <Typography sx={{ typography: { xs: 'h6', md: 'h5' } }}>
            {rows.length
              ? rows.length === 1
                ? '1 Holding'
                : `${rows.length} Holdings`
              : 'Holdings'}
          </Typography>
        </Grid>
        <Grid
          item
          gap={smAndLess ? 0 : 1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <Grid item>
            {smAndLess ? (
              <IconButton
                onClick={() => setAddHoldingModalOpen(true)}
                color="primary"
                size="small"
              >
                <AddCircleOutline />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setAddHoldingModalOpen(true)}
                size="small"
              >
                Add Holding
              </Button>
            )}
          </Grid>
          <Grid item>
            {smAndLess ? (
              <IconButton
                onClick={() => setImportHoldingsModalOpen(true)}
                color="primary"
                size="small"
              >
                <UploadFile />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                startIcon={<ImportExport />}
                onClick={() => setImportHoldingsModalOpen(true)}
                size="small"
              >
                Import
              </Button>
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              size="small"
              disableElevation
              disabled={!rows.length}
              loading={loadingCalculation}
              onClick={onCalculateClick}
              sx={{ ml: { xs: 1, sm: 0 } }}
            >
              Calculate
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid height={{ sm: '65vh' }}>
        <DataGrid
          autoHeight={smAndLess}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          editMode="row"
          getRowId={(row) => `${row.symbol}${row.type}`}
          // checkboxSelection
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Add or import your holdings to get started.
              </Stack>
            ),
          }}
        />
      </Grid>
      <AddHoldingModal
        open={addHoldingModalOpen}
        onClose={onCloseAddHoldingModal}
        onAddHolding={handleAddRows}
      />
      <ImportHoldingsModal
        open={importHoldingsModalOpen}
        onClose={onCloseImportHoldingsModal}
        onImport={handleAddRows}
      />
    </>
  )
}
