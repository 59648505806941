import React from 'react'

import Divider from '@mui/material/Divider'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountIcon from '@mui/icons-material/PermIdentity'

import { Auth } from 'aws-amplify'
import { useStore } from '@/store'
import { useNavigate } from 'react-router-dom'

interface AccountMenuProps {
  anchorElement: Element | null
  onClose: () => void
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  anchorElement,
  onClose,
}) => {
  const navigate = useNavigate()
  const { authenticatedUser } = useStore()

  const navigateToAccount = () => {
    navigate('invest/account')
  }

  const handleSignOut = async () => {
    await Auth.signOut()
  }

  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={!!anchorElement}
      onClose={onClose}
    >
      <MenuItem key="email" sx={{ flexDirection: 'column' }}>
        <Typography width="100%" variant="overline">
          Signed in as
        </Typography>
        <Typography variant="subtitle1" textTransform="capitalize">
          {authenticatedUser?.email}
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem key="account" onClick={navigateToAccount}>
        <ListItemIcon>
          <AccountIcon fontSize="small" />
        </ListItemIcon>
        <Typography textAlign="center"> Account</Typography>
      </MenuItem>
      <MenuItem key="sign-out" onClick={handleSignOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <Typography textAlign="center">Sign out</Typography>
      </MenuItem>
    </Menu>
  )
}
