import { Amplify } from 'aws-amplify'

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
      region: import.meta.env.VITE_COGNITO_REGION,
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolWebClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      mandatorySignIn: false,
    },
    API: {
      endpoints: [
        {
          name: 'GetComplianceStatus',
          endpoint:
            'https://lambda.us-east-2.amazonaws.com/2015-03-31/functions/ShariahCompliance-GetComplianceStatus/invocations',
          service: 'lambda',
          region: 'us-east-2',
        },
        {
          name: 'WatchListGet',
          endpoint:
            'https://lambda.us-east-2.amazonaws.com/2015-03-31/functions/WatchList-Get/invocations',
          service: 'lambda',
          region: 'us-east-2',
        },
        {
          name: 'WatchListAdd',
          endpoint:
            'https://lambda.us-east-2.amazonaws.com/2015-03-31/functions/WatchList-Add/invocations',
          service: 'lambda',
          region: 'us-east-2',
        },
        {
          name: 'WatchListRemove',
          endpoint:
            'https://lambda.us-east-2.amazonaws.com/2015-03-31/functions/WatchList-Remove/invocations',
          service: 'lambda',
          region: 'us-east-2',
        },
        {
          name: 'FinancialsIEXProxy',
          endpoint:
            'https://lambda.us-east-2.amazonaws.com/2015-03-31/functions/Financials-IEXProxy/invocations',
          service: 'lambda',
          region: 'us-east-2',
        },
        {
          name: 'UserPreferencesGet',
          endpoint:
            'https://lambda.us-east-2.amazonaws.com/2015-03-31/functions/UserPreferences-Get/invocations',
          service: 'lambda',
          region: 'us-east-2',
        },
      ],
    },
  })
}
