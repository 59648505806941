import React from 'react'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import { Link } from 'react-router-dom'

interface MobileNavMenuProps {
  routes: Array<{
    label: string
    path: string
    icon?: React.ReactElement
  }>
  anchorElement: Element | null
  onClose: () => void
}

export const MobileNavMenu: React.FC<MobileNavMenuProps> = ({
  routes,
  anchorElement,
  onClose,
}) => {
  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      open={!!anchorElement}
      onClose={onClose}
    >
      {routes.map((route) => {
        return (
          <MenuItem key={`${route.label}-route`}>
            <Button
              component={Link}
              color="inherit"
              to={route.path}
              startIcon={route.icon}
            >
              <Typography>{route.label}</Typography>
            </Button>
          </MenuItem>
        )
      })}
    </Menu>
  )
}
