import React, { useEffect, useMemo, useState } from 'react'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FacebookIcon from '@mui/icons-material/FacebookRounded'
import TwitterIcon from '@mui/icons-material/Twitter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PrintIcon from '@mui/icons-material/LocalPrintshopRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import { ZakatHolding, isZakatHolding } from '../types'
import {
  CalculationResult,
  CalculationSummary,
  FailedCalculation,
  isZakatCalculation,
  ZakatCalculation,
} from '../calculation'
import numeral from 'numeral'
import {
  Alert,
  AlertTitle,
  ButtonBase,
  Chip,
  CircularProgress,
  Link,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSegment } from '@/events/segment'
import { Statsig, useGate } from 'statsig-react'
import { formatCurrency } from '@/common/currencyFormatter'

const { VITE_ZAKAT_API_ENDPOINT } = import.meta.env

const calculateZakat = async (
  holdings: Partial<ZakatHolding>[]
): Promise<ZakatCalculation | null> => {
  try {
    const response = await fetch(`${VITE_ZAKAT_API_ENDPOINT}/zakat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ holdings: holdings }),
    })
    const data = await response.json()
    if (isZakatCalculation(data)) {
      return data
    } else {
      console.error("response doesn't match expected type, recieved: ", data)
      return null
    }
  } catch (error) {
    console.error('failed to fetch zakat calculation with error: ', error)
    return null
  }
}

export const ZakatSummary: React.FC<{
  calculation: { result: ZakatCalculation | null; loading: boolean }
  holdingsDiff: Partial<ZakatHolding>[]
  onReCalculateClick: () => void
}> = ({ calculation, holdingsDiff, onReCalculateClick }) => {
  const [expandedBreakdown, setExpandedBreakdown] = useState(true)

  const { analytics } = useSegment()

  const shareAction = (medium: string) => {
    analytics?.track('Zakat Share', { medium })
    Statsig.logEvent('Zakat Share', medium, { medium })
  }

  const { passive, active, chart, failure } = useMemo(() => {
    const backgroundColors = [
      '#009556',
      '#1aae6f',
      '#4dc08f',
      '#00a55f',
      '#007343',
    ]
    if (calculation.result) {
      const longtermHoldings = calculation.result.holdings.filter(
        (holding): holding is Exclude<CalculationResult, FailedCalculation> =>
          holding.type === 'Passive' && holding.status !== 'FAIL'
      )
      const shorttermHoldings = calculation.result.holdings.filter(
        (holding): holding is Exclude<CalculationResult, FailedCalculation> =>
          holding.type === 'Active' && holding.status !== 'FAIL'
      )

      const FailedCalculationHoldings = calculation.result.holdings.filter(
        (holding): holding is FailedCalculation => holding.status === 'FAIL'
      )
      return {
        chart: {
          labels: calculation.result.holdings.map((holding) => holding.symbol),
          datasets: {
            data: [...shorttermHoldings, ...longtermHoldings].map(
              (holding) => holding.zakatDue
            ),
            backgroundColor: backgroundColors,
          },
        },
        passive: {
          holdings: longtermHoldings,
          summary: calculation.result.summary.passive,
        },
        active: {
          holdings: shorttermHoldings,
          summary: calculation.result.summary.active,
        },
        failure: {
          summary: {
            baseValue: 0,
            zakatableAmount: 0,
            zakatDue: 0,
          },
          holdings: FailedCalculationHoldings,
        },
      }
    } else {
      return {
        chart: {
          labels: [],
          datasets: {
            data: [],
            backgroundColor: [],
          },
        },
        failure: {
          holdings: [],
          summary: {
            baseValue: 0,
            zakatableAmount: 0,
            zakatDue: 0,
          },
        },
        passive: {
          holdings: [],
          summary: {
            baseValue: 0,
            zakatableAmount: 0,
            zakatDue: 0,
          },
        },
        active: {
          holdings: [],
          summary: {
            baseValue: 0,
            zakatableAmount: 0,
            zakatDue: 0,
          },
        },
      }
    }
  }, [calculation])

  return (
    <Grid container id="printableContainer">
      {calculation ? (
        <Grid container xs={12} justifyContent="center">
          {holdingsDiff.length > 0 && (
            <Grid item xs={12} marginBottom={2}>
              <Alert
                severity="warning"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                action={
                  <LoadingButton
                    color="inherit"
                    size="small"
                    variant="outlined"
                    loading={calculation.loading}
                    onClick={onReCalculateClick}
                  >
                    Calculate
                  </LoadingButton>
                }
              >
                <AlertTitle sx={{ fontWeight: 600 }}>
                  Zakat Report is outdated
                </AlertTitle>
                You have updated your holdings. Please recalculate your zakat
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={10}>
            <Grid container alignItems="center">
              <Grid item xs={12} paddingX={4} textAlign="center">
                <Typography variant="h4">Total zakat due </Typography>
                <Typography variant="h1">
                  <b>
                    {calculation.result
                      ? formatCurrency(calculation.result.zakatDue, 'USD')
                      : null}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              marginY={4}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Share this calculator with your friends
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://api.whatsapp.com/send?text=Check%20out%20this%20free%20tool%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately.%20https%3A%2F%2Fzoya.finance%2Fzakat"
                >
                  <IconButton onClick={() => shareAction('WhatsApp')}>
                    <WhatsAppIcon />
                  </IconButton>
                </Link>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fzoya.finance%2Fzakat&quote=Check%20out%20this%20free%20tool%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately."
                >
                  <IconButton onClick={() => shareAction('FaceBook')}>
                    <FacebookIcon />
                  </IconButton>
                </Link>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://twitter.com/intent/tweet?source=https%3A%2F%2Fapp.zoya.finance%2Fzakat&text=Check%20out%20this%20free%20tool%20by%20%40zoyafinance%20that%20helps%20you%20calculate%20how%20much%20zakat%20you%20owe%20on%20your%20stock%20portfolio%20instantly%20and%20accurately.&url=https%3A%2F%2Fzoya.finance%2Fzakat"
                >
                  <IconButton onClick={() => shareAction('Twitter')}>
                    <TwitterIcon />
                  </IconButton>
                </Link>
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fzoya.finance/zakat"
                >
                  <IconButton onClick={() => shareAction('LinkedIn')}>
                    <LinkedInIcon />
                  </IconButton>
                </Link>
                <IconButton onClick={window.print}>
                  <PrintIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <ButtonBase
                onClick={() => setExpandedBreakdown(!expandedBreakdown)}
              >
                <Typography variant="subtitle1">
                  {expandedBreakdown ? 'Hide Breakdown' : 'Show Breakdown'}
                </Typography>
                <ExpandMore />
              </ButtonBase>
            </Grid>
            <Collapse in={expandedBreakdown && !calculation.loading}>
              {active.holdings.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    <b>Active Holdings</b>
                  </Typography>
                  <ZakatBreakDownTable
                    holdings={active.holdings}
                    summary={active.summary}
                  />
                </>
              )}
              {passive.holdings.length > 0 && (
                <>
                  <Divider />
                  <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>
                    <b>Passive Holdings</b>
                  </Typography>
                  <ZakatBreakDownTable
                    holdings={passive.holdings}
                    summary={passive.summary}
                  />
                </>
              )}
              {failure.holdings.length > 0 && (
                <>
                  <Divider />
                  <Typography variant="h6" sx={{ ml: 2, mt: 2 }}>
                    <b>Failed to calculate zakat for the following holdings</b>
                  </Typography>
                  <ZakatBreakDownTable
                    holdings={failure.holdings}
                    summary={failure.summary}
                  />
                </>
              )}
            </Collapse>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            my: 6,
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          <Typography variant="h6">
            Add holdings to see your zakat summary
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

const ZakatBreakDownTable: React.FC<{
  holdings: CalculationResult[]
  summary: CalculationSummary
}> = ({ holdings, summary }) => {
  const { value: showExplanationFlag, isLoading } = useGate(
    'holding_explanation'
  )

  if (isLoading) {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ m: 2, justifyContent: 'center', display: 'flex' }}
        >
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <TableContainer elevation={0} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {showExplanationFlag && <TableCell width={8} />}
            <TableCell>Symbol</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Zakat Liable Amount</TableCell>
            <TableCell align="right">Zakat Due</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {holdings
            .sort((a, b) => {
              if (a.status === 'FAIL' || b.status === 'FAIL') {
                return 1
              } else {
                return b.zakatDue - a.zakatDue
              }
            })
            .map((row) => (
              <ZakatSummaryRow
                row={row}
                showExplanation={showExplanationFlag}
              />
            ))}
          <TableRow
            key={'totals'}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            {showExplanationFlag && <TableCell width={8} />}
            <TableCell component="th" scope="row">
              Total
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">
                {formatCurrency(summary.baseValue, 'USD')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">
                {formatCurrency(summary.zakatableAmount, 'USD')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subtitle2">
                {formatCurrency(summary.zakatDue, 'USD')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ZakatSummaryRow: React.FC<{
  row: CalculationResult
  showExplanation: boolean
}> = ({ row, showExplanation }) => {
  const [expanded, setExpanded] = useState(false)

  const ZakatDiagram = useMemo(() => {
    switch (row.status) {
      case 'SUCCESS':
        if (row.type === 'Active') {
          return <ZakatCalculationDiagramActive result={row} />
        } else if (row.zakatableAmount === row.baseValue) {
          return <ZakatCalculationDiagramActive result={row} />
        } else if (row.type === 'Passive') {
          return <ZakatCalculationDiagramPassive result={row} />
        } else {
          return null
        }
      case 'FALLBACK30':
        return <ZakatCalculationDiagramFallback30 result={row} />
      case 'FAIL':
      default:
        null
    }
  }, [row])

  return (
    <>
      <TableRow
        key={row.name}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          '& > *': { border: 'unset' },
        }}
      >
        {showExplanation && (
          <TableCell width={8}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          <b>{row.symbol}</b>
          <br />
          {row.name}
        </TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">
          {formatCurrency(row.shares! * row.unitPrice!, row.currency)}
        </TableCell>
        <TableCell align="right">
          {row.status !== 'FAIL'
            ? formatCurrency(row.zakatableAmount, row.currency)
            : 'N/A'}
        </TableCell>
        <TableCell align="right">
          {row.status !== 'FAIL'
            ? formatCurrency(row.zakatDue, row.currency)
            : 'N/A'}
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: '1px solid #e0e0e0' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded}>{ZakatDiagram}</Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const ZakatCalculationDiagramPassive: React.FC<{
  result: CalculationResult
}> = ({ result }) => {
  return (
    <Grid container justifyContent="center" direction="row-reverse">
      <Grid item xs={8}>
        <Grid
          container
          direction="row"
          columnGap={1}
          rowGap={2}
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <HtmlTooltip
            arrow
            placement="top"
            title={<Typography variant="body2">Current Assets</Typography>}
          >
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              {result.status !== 'FAIL' && !!result.currentAssets
                ? formatCurrency(
                    result.currentAssets * 1_000_000,
                    result.currency
                  )
                : '--'}
            </Typography>
          </HtmlTooltip>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>{' '}
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <HtmlTooltip
                arrow
                placement="top"
                title={<Typography variant="body2">Shares you own</Typography>}
              >
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                    py: 0,
                  }}
                >
                  {result.status !== 'FAIL' && !!result.shares
                    ? formatCurrency(result.shares, result.currency)
                    : '--'}
                </Typography>
              </HtmlTooltip>

              <Divider sx={{ borderColor: '#333', width: '100%' }} />
              <HtmlTooltip
                arrow
                placement="bottom"
                title={
                  <Typography variant="body2">Outstanding Shares</Typography>
                }
              >
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                  }}
                >
                  {result.status !== 'FAIL' && !!result.outstandingShares
                    ? formatCurrency(
                        result.outstandingShares * 1_000_000,
                        result.currency
                      )
                    : '--'}
                </Typography>
              </HtmlTooltip>
            </Grid>
          </Grid>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            2.5%
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            =
          </Typography>
          <Grid item>
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              <b>
                {result.status !== 'FAIL' && !!result.zakatDue
                  ? formatCurrency(result.zakatDue, result.currency)
                  : '--'}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="space-evenly"
          my={2}
          pl={9}
          direction="column"
        >
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  Current assets are the cash, inventories, receivables, and
                  other similarly liquid assets of a company that can be
                  converted to cash within a fiscal year.
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Current Assets</b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                  }}
                >
                  {result.status !== 'FAIL' && !!result.currentAssets
                    ? formatCurrency(
                        result.currentAssets * 1_000_000,
                        result.currency
                      )
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  The total number of shares that exist for a business or fund.
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Outstanding Shares</b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                  }}
                >
                  {result.status !== 'FAIL' && !!result.outstandingShares
                    ? formatCurrency(
                        result.outstandingShares * 1_000_000,
                        result.currency
                      )
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  This is the number of shares of a given stock that you own.
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Number of Shares </b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                    py: 0,
                  }}
                >
                  {result.status !== 'FAIL' && !!result.shares
                    ? formatCurrency(result.shares, result.currency)
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export const ZakatCalculationDiagramActive: React.FC<{
  result: CalculationResult
}> = ({ result }) => {
  return (
    <Grid container justifyContent="center" direction="row-reverse">
      <Grid item xs={8}>
        <Grid
          container
          direction="row"
          columnGap={1}
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <HtmlTooltip
            arrow
            placement="top"
            title={<Typography variant="body2">Shares you own</Typography>}
          >
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              {result.status !== 'FAIL' && !!result.shares
                ? formatCurrency(result.shares, result.currency)
                : '--'}
            </Typography>
          </HtmlTooltip>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>{' '}
          <HtmlTooltip
            arrow
            placement="top"
            title={<Typography variant="body2">Latest Price</Typography>}
          >
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              {result.status !== 'FAIL' && !!result.unitPrice
                ? formatCurrency(result.unitPrice, result.currency)
                : '--'}
            </Typography>
          </HtmlTooltip>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            2.5%
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            =
          </Typography>
          <Grid item>
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              <b>
                {result.status !== 'FAIL' && !!result.zakatDue
                  ? formatCurrency(result.zakatDue, result.currency)
                  : '--'}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="space-evenly"
          my={2}
          pl={9}
          direction="column"
        >
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  The latest price of this symbol
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Latest Price</b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                  }}
                >
                  {result.status !== 'FAIL' && !!result.unitPrice
                    ? formatCurrency(result.unitPrice, result.currency)
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  This is the number of shares of a given stock that you own.
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Number of Shares </b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                    py: 0,
                  }}
                >
                  {result.status !== 'FAIL' && !!result.shares
                    ? formatCurrency(result.shares, result.currency)
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ZakatCalculationDiagramFallback30: React.FC<{
  result: CalculationResult
}> = ({ result }) => {
  return (
    <Grid container justifyContent="center" direction="row-reverse">
      <Grid item xs={8}>
        <Grid
          container
          direction="row"
          columnGap={1}
          rowGap={2}
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <HtmlTooltip
            arrow
            placement="top"
            title={<Typography variant="body2">Shares you own</Typography>}
          >
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              {result.status !== 'FAIL' && !!result.shares
                ? formatCurrency(result.shares, result.currency)
                : '--'}
            </Typography>
          </HtmlTooltip>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>{' '}
          <HtmlTooltip
            arrow
            placement="top"
            title={<Typography variant="body2">Latest Price</Typography>}
          >
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              {result.status !== 'FAIL' && !!result.unitPrice
                ? formatCurrency(result.unitPrice, result.currency)
                : '--'}
            </Typography>
          </HtmlTooltip>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            30%
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            x
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            2.5%
          </Typography>
          <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>
            =
          </Typography>
          <Grid item>
            <Typography
              sx={{
                typography: { xs: 'caption', md: 'subtitle1' },
              }}
            >
              <b>
                {result.status !== 'FAIL' && !!result.zakatDue
                  ? formatCurrency(result.zakatDue, result.currency)
                  : '--'}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          justifyContent="space-evenly"
          my={2}
          pl={9}
          direction="column"
        >
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  The latest price of this symbol
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Latest Price</b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                  }}
                >
                  {result.status !== 'FAIL' && !!result.unitPrice
                    ? formatCurrency(result.unitPrice, result.currency)
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
          <Grid item>
            <HtmlTooltip
              arrow
              placement="bottom"
              title={
                <Typography variant="body2">
                  This is the number of shares of a given stock that you own.
                </Typography>
              }
            >
              <div>
                <Typography sx={{ typography: { xs: 'body2' } }}>
                  <b>Number of Shares </b>
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: 'caption', md: 'subtitle1' },
                    py: 0,
                  }}
                >
                  {result.status !== 'FAIL' && !!result.shares
                    ? formatCurrency(result.shares, result.currency)
                    : '--'}
                </Typography>
              </div>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))
