import React, { PropsWithChildren, ReactNode } from 'react'
import { useGate } from 'statsig-react'

interface RequireGateProps extends PropsWithChildren {
  gate: string
  gateFailContent?: ReactNode
  gateloadingContent?: ReactNode
}

export const RequireGate: React.FunctionComponent<RequireGateProps> = ({
  gate,
  gateFailContent,
  gateloadingContent,
  children,
}) => {
  const { isLoading, value } = useGate(gate)

  if (isLoading) {
    return <>{gateloadingContent}</> ?? <div>Loading...</div> //TODO replace with a default loading component
  }

  if (!value) {
    return <>{gateFailContent}</> ?? <div>Not accessible</div> //TODO replace with a default fallback component
  }

  return <div>{children}</div>
}
