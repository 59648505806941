import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { withSuspense } from '@/common/hoc/withSuspense'
import { RequireGate } from '@/common/components/RequireGate'
import { RegisterInvestBanner } from '@/invest/components/register-invest-banner/register-invest-banner'

const DetailScreen = withSuspense(
  React.lazy(() => import('./pages/DetailScreen'))
)

export const StocksRouter = () => {
  return (
    <RequireGate gate="mvp_web_app" gateFailContent={<Navigate to={'/'} />}>
      <RegisterInvestBanner />

      <Routes>
        <Route index element={<DetailScreen />} />
        <Route path=":symbol/*" element={<DetailScreen />} />
      </Routes>
    </RequireGate>
  )
}
