import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { theme } from '@/app/theme'
import { initAmplify } from '@/amplify'
import { initSegment } from '@/events/segment'
import { generateClient } from '@/graphQL'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { StatsigProvider } from 'statsig-react'
import { statsigOptions, statsigUserBaseConfig } from '@/events/statsig'
import { BugSnagErrorBoundary } from './Bugsnag'
import { noop } from 'lodash'
import { RoutesListener } from './RoutesListener'
import { AppFrame } from '@/common/components/AppFrame'
import { AuthEventsListener } from '@/auth/components/AuthEventsListener'
import { merge } from 'lodash'

// Routers
import { AuthRouter } from '@/auth/router'
import { ZakatRouter } from '@/zakat/router'
import { StocksRouter } from '@/stocks/router'
import { InvestRouter } from '@/invest/router'
import { useStore } from '@/store'
import { useMemo } from 'react'

initAmplify()
initSegment().catch(noop)
const client = generateClient()

export const App = () => {
  const user = useStore((_) => _.authenticatedUser)
  const statsigUser = useMemo(() => {
    return merge({}, statsigUserBaseConfig, {
      email: user?.email,
      userID: user?.sub,
    })
  }, [user?.email, user?.sub])

  return (
    <BugSnagErrorBoundary>
      <StatsigProvider
        user={statsigUser}
        options={statsigOptions}
        sdkKey="client-5KBiBaFfdhKQkV9HJdVStSw7cOKJ5zyiUj9OUFVgMCI"
        waitForInitialization={false}
      >
        <ApolloProvider client={client}>
          <BrowserRouter>
            <AuthEventsListener />
            <ThemeProvider theme={theme}>
              <CssBaseline enableColorScheme />
              <RoutesListener>
                <Routes>
                  <Route path="auth/*" element={<AuthRouter />} />
                  <Route path="zakat/*" element={<ZakatRouter />} />
                  <Route path="/" element={<AppFrame />}>
                    <Route path="invest/*" element={<InvestRouter />} />
                    <Route path="stocks/*" element={<StocksRouter />} />
                  </Route>
                  <Route path="*" element={<div>404</div>} />
                </Routes>
              </RoutesListener>
            </ThemeProvider>
          </BrowserRouter>
        </ApolloProvider>
      </StatsigProvider>
    </BugSnagErrorBoundary>
  )
}
