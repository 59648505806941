import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: '9883eebcad255acdc873a45fde4106e2',
  plugins: [new BugsnagPluginReact()],
})

const reactBugsnagPlugin = Bugsnag.getPlugin('react')

export const BugSnagErrorBoundary = reactBugsnagPlugin
  ? reactBugsnagPlugin.createErrorBoundary(React)
  : (props: { children?: React.ReactNode }) => <>{props.children}</>
