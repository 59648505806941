import { memo } from 'react'
import { Link } from 'react-router-dom'
import { Paper, styled, Typography } from '@mui/material'

import { useInvestAccountStatus } from '@/invest/hooks/useInvestAccountStatus'

export const RegisterInvestBanner = memo(() => {
  const { loading, error, hasAccount } = useInvestAccountStatus()

  if (error) {
    return null
  }

  if (loading) {
    return null
  }

  if (hasAccount) {
    return null
  }

  return (
    <Banner>
      <Typography variant="h4" align="center" fontWeight="bold">
        <Link to="/invest/register">
          Sign up for Zoya Invest to start investing in US stocks without any
          fees.
        </Link>
      </Typography>
    </Banner>
  )
})

const Banner = styled(Paper)(({ theme }) => ({
  marginBlock: '16px',
  padding: '64px',
  width: 'max(100%, 80ch)',
  minHeight: '32px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,

  '& a': {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
