import { bseMapping } from './bse-mapping'

interface IexSuffixMapping {
  /**
   * The Market Identifier Code (MIC) used by the ISO 10383 standard to
   * uniquely identify a market or trading venue.
   */
  mic: string
  /**
   * The exchange identifier used by Twelvedata to uniquely identify an asset.
   * We're using a short name here to avoid taking up too many bytes when
   * deployed in the AppSync JS runtime for direct resolvers.
   */
  tde?: string | null
  /**
   * The ISO 3166-1 alpha-2 country code region where the exchange is located.
   */
  region: string
}

/**
 * Object with IEX suffixes as keys and objects containing the MIC code,
 * Twelvedata exchange identifier, and ISO country code region as values.
 */
export const iexSuffixMappings: Record<string, IexSuffixMapping> = {
  '4AXE': { mic: '4AXE', region: 'ZA' },
  AB: { mic: 'XSAU', tde: 'Tadawul', region: 'SA' },
  AF: { mic: 'XBUE', tde: 'BCBA', region: 'AR' },
  AG: { mic: 'XALG', region: 'DZ' },
  AH: { mic: 'CHIA', region: 'AU' },
  AJ: { mic: 'A2XX', region: 'ZA' },
  AO: { mic: 'XNEC', region: 'AU' },
  AQSE: { mic: 'AQSE', region: 'GB' },
  // AT is not a real IEX suffix, but we use it to denote the Australian market
  // we pulled from FMP/EOD and use AT as a pseudo IEX suffix
  AT: { mic: 'XASX', tde: 'ASX', region: 'AU' },
  AY: { mic: 'XARM', region: 'AM' },
  BA: { mic: 'XBAB', region: 'BB' },
  BB: { mic: 'XBRU', tde: 'Euronext', region: 'BE' },
  BC: { mic: 'XBRV', region: 'CI' },
  BCXE: { mic: 'BCXE', region: 'GB' },
  BD: { mic: 'XDHA', region: 'BD' },
  BG: { mic: 'XBOT', tde: 'BSE', region: 'BW' },
  BH: { mic: 'XBDA', region: 'BM' },
  BI: { mic: 'XBAH', region: 'BH' },
  BIVA: { mic: 'BIVA', region: 'MX' },
  BJSE: { mic: 'BJSE', region: 'CN' },
  BK: { mic: 'XBLB', region: 'BA' },
  BM: { mic: 'XBAA', region: 'BS' },
  BS: { mic: 'BVMF', tde: 'Bovespa', region: 'BR' },
  BSEX: { mic: 'BSEX', region: 'AZ' },
  BU: { mic: 'XBUL', region: 'BG' },
  C1: { mic: 'XSSC', region: 'CN' },
  C2: { mic: 'XSEC', region: 'CN' },
  C3: { mic: 'XCHG', region: 'BD' },
  CCXE: { mic: 'CCXE', region: 'NL' },
  CE: { mic: 'XBCL', region: 'CL' },
  CF: { mic: 'XCNQ', tde: 'CSE', region: 'CA' },
  CG: { mic: 'XSHG', tde: 'SSE', region: 'CN' },
  CK: { mic: 'XPRA', tde: 'PSE', region: 'CZ' },
  CR: { mic: 'XBNV', region: 'CR' },
  CS: { mic: 'XSHE', tde: 'SZSE', region: 'CN' },
  CT: { mic: 'XTSE', tde: 'TSX', region: 'CA' },
  CV: { mic: 'XTSX', tde: 'TSXV', region: 'CA' },
  CX: { mic: 'XBOG', tde: 'BVC', region: 'CO' },
  CY: { mic: 'XCYS', region: 'CY' },
  DB: { mic: 'XDFM', tde: 'DFM', region: 'AE' },
  DC: { mic: 'XCSE', tde: 'OMXC', region: 'DK' },
  DCSX: { mic: 'DCSX', region: 'CW' },
  DH: { mic: 'XADS', tde: 'ADX', region: 'AE' },
  DU: { mic: 'DIFX', region: 'AE' },
  EC: { mic: 'XCAI', tde: 'EGX', region: 'EG' },
  EG: { mic: 'XGUA', region: 'EC' },
  EL: { mic: 'XSVA', region: 'SV' },
  EQ: { mic: 'XQUI', region: 'EC' },
  ET: { mic: 'XTAL', tde: 'OMXT', region: 'EE' },
  FH: { mic: 'XHEL', tde: 'OMXH', region: 'FI' },
  FP: { mic: 'XPAR', tde: 'Euronext', region: 'FR' },
  FS: { mic: 'XSPS', region: 'FJ' },
  GA: { mic: 'ASEX', tde: 'ASE', region: 'GR' },
  GB: { mic: 'XBER', tde: 'XBER', region: 'DE' },
  GD: { mic: 'XDUS', tde: 'XDUS', region: 'DE' },
  GF: { mic: 'XFRA', tde: 'FSX', region: 'DE' },
  GG: { mic: 'XGSE', region: 'GE' },
  GH: { mic: 'XHAM', tde: 'XHAM', region: 'DE' },
  GI: { mic: 'XHAN', tde: 'XHAN', region: 'DE' },
  GM: { mic: 'XMUN', tde: 'Munich', region: 'DE' },
  GN: { mic: 'XGHA', region: 'GH' },
  GROW: { mic: 'GROW', region: 'ES' },
  GS: { mic: 'XSTU', tde: 'XSTU', region: 'DE' },
  GSCI: { mic: 'GSCI', region: 'GY' },
  GU: { mic: 'XCIE', region: 'GG' },
  GY: { mic: 'XETR', tde: 'XETR', region: 'DE' },
  H1: { mic: 'SHSC', region: 'HK' },
  H2: { mic: 'SZSC', region: 'HK' },
  HB: { mic: 'XBUD', tde: 'BSE', region: 'HU' },
  HK: { mic: 'XHKG', tde: 'HKEX', region: 'HK' },
  IB: { mic: 'XBOM', tde: 'BSE', region: 'IN' },
  ID: { mic: 'XDUB', tde: 'ISE', region: 'IE' },
  IE: { mic: 'XTEH', region: 'IR' },
  IFBX: { mic: 'IFBX', region: 'IR' },
  IG: { mic: 'MCXX', region: 'IN' },
  IJ: { mic: 'XIDX', tde: 'IDX', region: 'ID' },
  IM: { mic: 'XMIL', tde: 'MTA', region: 'IT' },
  IQ: { mic: 'XIQS', region: 'IQ' },
  IS: { mic: 'XNSE', tde: 'NSE', region: 'IN' },
  IT: { mic: 'XTAE', tde: 'TASE', region: 'IL' },
  JA: { mic: 'XJAM', tde: 'JSE', region: 'JM' },
  JF: { mic: 'XFKA', region: 'JP' },
  JN: { mic: 'XNGO', region: 'JP' },
  JR: { mic: 'XAMM', region: 'JO' },
  JS: { mic: 'XSAP', tde: 'XSAP', region: 'JP' },
  KA: {
    mic: 'XSAT',
    tde: 'Spotlight Stock Market',
    region: 'SE',
  },
  KB: { mic: 'XKSE', region: 'KG' },
  KE: { mic: 'XKON', tde: 'KONEX', region: 'KR' },
  KH: { mic: 'XCSX', region: 'KH' },
  KK: { mic: 'XKUW', tde: 'XKUW', region: 'KW' },
  KN: { mic: 'XNAI', region: 'KE' },
  KP: { mic: 'XKRX', tde: 'KRX', region: 'KR' },
  KQ: { mic: 'XKOS', tde: 'KOSDAQ', region: 'KR' },
  KX: { mic: 'AIXK', region: 'KZ' },
  KY: { mic: 'XCAY', region: 'KY' },
  LB: { mic: 'XBEY', region: 'LB' },
  LG: { mic: 'XRIS', tde: 'OMXR', region: 'LV' },
  LH: { mic: 'XLIT', tde: 'OMXV', region: 'LT' },
  LN: { mic: 'XLON', tde: 'LSE', region: 'GB' },
  LS: { mic: 'XLAO', region: 'LA' },
  LX: { mic: 'XLUX', region: 'LU' },
  LY: { mic: 'XLSM', region: 'LY' },
  MALX: { mic: 'MALX', region: 'MV' },
  MB: { mic: 'XMOL', region: 'MD' },
  MC: { mic: 'XCAS', region: 'MA' },
  ME: { mic: 'XMNX', region: 'ME' },
  MM: { mic: 'XMEX', tde: 'BMV', region: 'MX' },
  MO: { mic: 'XULA', region: 'MN' },
  MP: { mic: 'XMAU', region: 'MU' },
  MS: { mic: 'XMAE', region: 'MK' },
  MV: { mic: 'XMAL', region: 'MT' },
  MW: { mic: 'XMSW', region: 'MW' },
  MZ: { mic: 'XBVM', region: 'MZ' },
  NA: { mic: 'XAMS', tde: 'Euronext', region: 'NL' },
  NG: { mic: 'XNGM', region: 'SE' },
  NK: { mic: 'XNEP', region: 'NP' },
  NL: { mic: 'XNSA', region: 'NG' },
  NO: { mic: 'XOSL', tde: 'OSE', region: 'NO' },
  NS: { mic: 'NOTC', region: 'NO' },
  NW: { mic: 'XNAM', region: 'NA' },
  OM: { mic: 'XMUS', region: 'OM' },
  PB: { mic: 'XPOM', region: 'PG' },
  PE: { mic: 'XLIM', tde: 'BVL', region: 'PE' },
  PF: { mic: 'APXL', region: 'AU' },
  PK: { mic: 'XKAR', tde: 'PSX', region: 'PK' },
  PL: { mic: 'XLIS', tde: 'Euronext', region: 'PT' },
  PN: { mic: 'XVPA', region: 'PY' },
  PP: { mic: 'XPTY', region: 'PA' },
  PS: { mic: 'XPAE', region: 'PS' },
  PW: { mic: 'XWAR', tde: 'GPW', region: 'PL' },
  QD: { mic: 'DSMD', tde: 'QE', region: 'QA' },
  QH: { mic: 'NEOE', tde: 'NEO', region: 'CA' },
  RB: { mic: 'BCSE', region: 'BY' },
  RF: { mic: 'XICE', tde: 'ICEX', region: 'IS' },
  RR: { mic: 'RTSX', region: 'RU' },
  RW: { mic: 'RSEX', region: 'RW' },
  SA: { mic: 'XVAL', region: 'ES' },
  SB: { mic: 'XBAR', region: 'ES' },
  SD: { mic: 'XSWA', region: 'SZ' },
  SE: { mic: 'XSWX', tde: 'SIX', region: 'CH' },
  SEPE: { mic: 'SEPE', region: 'UA' },
  SG: { mic: 'XBEL', region: 'RS' },
  SK: { mic: 'XBRA', region: 'SK' },
  SL: { mic: 'XCOL', region: 'LK' },
  SN: { mic: 'XMAD', tde: 'BME', region: 'ES' },
  SO: { mic: 'XBIL', region: 'ES' },
  SR: { mic: 'XBRN', region: 'CH' },
  SS: { mic: 'XSTO', tde: 'OMX', region: 'SE' },
  SV: { mic: 'XLJU', region: 'SI' },
  SY: { mic: 'XDSE', region: 'SY' },
  SZ: { mic: 'TRPX', region: 'SC' },
  TI: { mic: 'XIST', tde: 'BIST', region: 'TR' },
  TP: { mic: 'XTRN', region: 'TT' },
  TT: { mic: 'XTAI', tde: 'TWSE', region: 'TW' },
  TU: { mic: 'XTUN', region: 'TN' },
  TZ: { mic: 'XDAR', region: 'TZ' },
  UG: { mic: 'XUGA', region: 'UG' },
  UK: { mic: 'UKEX', region: 'UA' },
  UY: { mic: 'XMNT', region: 'UY' },
  UZ: { mic: 'PFTS', region: 'UA' },
  VB: { mic: 'XBOL', region: 'BO' },
  VFEX: { mic: 'VFEX', region: 'ZW' },
  VH: { mic: 'HSTC', region: 'VN' },
  VM: { mic: 'XSTC', region: 'VN' },
  VR: { mic: 'XBVC', region: 'CV' },
  VS: { mic: 'BVCA', tde: 'BVCC', region: 'VE' },
  XBDV: { mic: 'XBDV', region: 'AO' },
  XDRF: { mic: 'XDRF', region: 'ES' },
  XPIC: { mic: 'XPIC', region: 'RU' },
  ZA: { mic: 'XZAG', region: 'HR' },
  ZH: { mic: 'XZIM', region: 'ZW' },
  ZL: { mic: 'XLUS', region: 'ZM' },
  ZU: { mic: 'XSTE', region: 'UZ' },
}

export interface ParsedIexSymbol {
  /**
   * The canonical symbol we use to identify an instrument/asset across our
   * systems. At the time of this library authoring, we're still using IEX
   * symbols as canonical.
   *
   * The format is `exchangeMicCode:symbol` for international symbols and
   * just `symbol` for US listed symbols.
   */
  zSymbol: string
  /**
   * The base symbol of the instrument/asset without any suffixes or prefixes.
   */
  rawSymbol: string
  /**
   * The symbol with international suffixes included used by IEX to uniquely
   * identify a instrument/asset by symbol. For US listed symbols, this is
   * the same as `rawSymbol`. For international symbols, it will be suffixed
   * with a `-` and a two character exchange code used by IEX (e.g. `AAPL` and
   * `SHOP-CT`).
   */
  iexSymbol: string
  /**
   * A uniquely identifying symbol for the instrument/asset used by Twelvedata.
   * US symbols are the same as `rawSymbol`. International symbols are suffixed
   * with a `:` and an exchange identifier that is proprietary to Twelvedata.
   * The proprietary identifier can be resolved by looking up a match based on
   * MIC code from the list exchanges endpoint in Twelvedata.
   */
  twelvedataSymbol: string | null
}

export const parseIexSymbol = (iexSymbol: string): ParsedIexSymbol => {
  // We first strip out trailing white space and make sure the symbol is all
  // uppercase to avoid issues with inconsistent inputs.
  const cleanedUpSymbol = iexSymbol.trim().toUpperCase()

  // Next, we'll parse the raw symbol and the suffix using a regex.
  const suffixSeparator = '-'
  const separatorLocation = cleanedUpSymbol.lastIndexOf(suffixSeparator)

  // If there is no suffix in IEX, then there should be no suffix in TwelveData
  // either. We can just use the symbol as-is.
  if (separatorLocation === -1) {
    return {
      zSymbol: cleanedUpSymbol,
      rawSymbol: cleanedUpSymbol,
      iexSymbol: cleanedUpSymbol,
      twelvedataSymbol: cleanedUpSymbol,
    }
  }

  // The raw symbol is everything before the suffix separator, and the IEX
  // suffix is everything after it.
  const rawSymbol = cleanedUpSymbol.slice(0, separatorLocation)
  const iexSuffix = cleanedUpSymbol.slice(separatorLocation + 1)

  // IEX and Twelvedata use different raw symbols for Indian stocks on the
  // Bombay Stock Exchange. This file provides a hardcoded mapping between the
  // two. Any symbols outside this mapping will fail to resolve to a valid
  // Twelvedata symbol.
  if (iexSuffix === 'IB') {
    const bseMatch = bseMapping[rawSymbol]
    const twelvedataSymbol = bseMatch ? `${bseMatch}:BSE` : null
    const canonicalRawSymbol = bseMatch ?? rawSymbol
    return {
      zSymbol: `XBOM:${canonicalRawSymbol}`,
      rawSymbol: canonicalRawSymbol,
      iexSymbol: cleanedUpSymbol,
      twelvedataSymbol,
    }
  }

  // If we don't have a mapping for the IEX suffix, we'll log a warning and
  // default to using the symbol as-is.
  const mappedValue = iexSuffixMappings[iexSuffix]
  if (!mappedValue) {
    return {
      zSymbol: cleanedUpSymbol,
      rawSymbol,
      iexSymbol: cleanedUpSymbol,
      twelvedataSymbol: cleanedUpSymbol,
    }
  }

  // If we successfully found mapped values, we'll return an object with all
  // the possible symbol formats.
  return {
    zSymbol: `${mappedValue.mic}:${rawSymbol}`,
    rawSymbol,
    iexSymbol: cleanedUpSymbol,
    twelvedataSymbol: mappedValue.tde
      ? `${rawSymbol}:${mappedValue.tde}`
      : null,
  }
}
