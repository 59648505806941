import { Auth } from 'aws-amplify'
import { S3, GetObjectRequest } from '@aws-sdk/client-s3'
import { ok } from 'assert'

export interface SearchableEntry {
  symbol: string
  name: string
  status: string
  region: string
  exchange: string
  currency: string
}

const s3Bucket = import.meta.env.VITE_S3_UNIVERSE_BUCKET
ok(s3Bucket, 'S3 bucket name missing from env')

const universeObject = 'stock_and_fund_universe.json'

const universe: SearchableEntry[] = []

const getFileFromS3 = async (fileKey: string) => {
  const credentials = await Auth.currentUserCredentials()
  const s3: S3 = new S3({
    region: 'us-east-2',
    credentials: credentials,
    maxAttempts: 4,
  })
  const params: GetObjectRequest = {
    Bucket: s3Bucket,
    Key: fileKey,
  }
  return s3.getObject(params)
}

export const getSearchableUniverse = async () => {
  if (universe.length < 1) {
    try {
      const data = await getFileFromS3(universeObject)
      const stocks: SearchableEntry[] = JSON.parse(
        // @ts-ignore next-line
        await new Response(data.Body, {}).text()
      )
      universe.push(...stocks)
      return stocks
    } catch (error) {
      console.log(error)
      return []
    }
  } else {
    return universe
  }
}


export const zakatUniverse = [
  {
    symbol: 'A',
    name: 'Agilent Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'AA',
    name: 'Alcoa Corp',
    region: 'US',
  },
  {
    symbol: 'AAA',
    name: 'Listed Funds Trust - AAF First Priority CLO Bond ETF',
    region: 'US',
  },
  {
    symbol: 'AAAU',
    name: 'Goldman Sachs Physical Gold ETF Trust - Goldman Sachs Physical Gold ETF',
    region: 'US',
  },
  {
    symbol: 'AAC',
    name: 'Ares Acquisition Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'AACG',
    name: 'ATA Creativity Global - ADR',
    region: 'US',
  },
  {
    symbol: 'AACI',
    name: 'Armada Acquisition Corp I',
    region: 'US',
  },
  {
    symbol: 'AACIU',
    name: 'Armada Acquisition Corp I - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AADI',
    name: 'Aadi Bioscience Inc',
    region: 'US',
  },
  {
    symbol: 'AADR',
    name: 'Advisorshares Trust - AdvisorShares Dorsey Wright ADR ETF',
    region: 'US',
  },
  {
    symbol: 'AAL',
    name: 'American Airlines Group Inc',
    region: 'US',
  },
  {
    symbol: 'AAME',
    name: 'Atlantic American Corp.',
    region: 'US',
  },
  {
    symbol: 'AAOI',
    name: 'Applied Optoelectronics Inc',
    region: 'US',
  },
  {
    symbol: 'AAON',
    name: 'AAON Inc.',
    region: 'US',
  },
  {
    symbol: 'AAP',
    name: 'Advance Auto Parts Inc',
    region: 'US',
  },
  {
    symbol: 'AAPL',
    name: 'Apple Inc',
    region: 'US',
  },
  {
    symbol: 'AAQC',
    name: 'Accelerate Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AASCX',
    name: 'Thrivent Mutual Funds: Thrivent Mid Cap Stock Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AASMX',
    name: 'Thrivent Mutual Funds: Thrivent Small Cap Stock Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AATC',
    name: 'Autoscope Technologies Corporation',
    region: 'US',
  },
  {
    symbol: 'AAU',
    name: 'Almaden Minerals Ltd',
    region: 'US',
  },
  {
    symbol: 'AAUKF',
    name: 'Anglo American plc',
    region: 'US',
  },
  {
    symbol: 'AAWW',
    name: 'Atlas Air Worldwide Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'AAXJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI All Country Asia ex Japan ETF',
    region: 'US',
  },
  {
    symbol: 'ABASX',
    name: 'AB Trust: AB Discovery Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABB',
    name: 'ABB Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'ABBV',
    name: 'Abbvie Inc',
    region: 'US',
  },
  {
    symbol: 'ABC',
    name: 'Amerisource Bergen Corp.',
    region: 'US',
  },
  {
    symbol: 'ABCAX',
    name: 'American Beacon Funds: American Beacon The London Company Income Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABCB',
    name: 'Ameris Bancorp',
    region: 'US',
  },
  {
    symbol: 'ABCIX',
    name: 'American Beacon Funds: American Beacon The London Company Income Equity Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABCL',
    name: 'AbCellera Biologics Inc',
    region: 'US',
  },
  {
    symbol: 'ABCM',
    name: 'Abcam - ADR (Sponsored)',
    region: 'US',
  },
  {
    symbol: 'ABCRX',
    name: 'American Beacon Funds: American Beacon The London Company Income Equity Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABCVX',
    name: 'American Beacon Funds: American Beacon The London Company Income Equity Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABCYX',
    name: 'American Beacon Funds: American Beacon The London Company Income Equity Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABEO',
    name: 'Abeona Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ABEQ',
    name: 'Unified Series Trust - Absolute Core Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'ABG',
    name: 'Asbury Automotive Group Inc',
    region: 'US',
  },
  {
    symbol: 'ABGI',
    name: 'ABG Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'ABIO',
    name: 'ARCA biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'ABM',
    name: 'ABM Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'ABMD',
    name: 'Abiomed Inc.',
    region: 'US',
  },
  {
    symbol: 'ABNB',
    name: 'Airbnb Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ABOS',
    name: 'Acumen Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ABPRX',
    name: 'AB Large Cap Growth Fund, Inc; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABSI',
    name: 'Absci Corp',
    region: 'US',
  },
  {
    symbol: 'ABSIX',
    name: 'AB Trust: AB Discovery Value Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABSKX',
    name: 'AB Trust: AB Discovery Value Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABSRX',
    name: 'AB Trust: AB Discovery Value Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABST',
    name: 'Absolute Software Corp',
    region: 'US',
  },
  {
    symbol: 'ABSZX',
    name: 'AB Trust: AB Discovery Value Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ABT',
    name: 'Abbott Laboratories',
    region: 'US',
  },
  {
    symbol: 'ABTX',
    name: 'Allegiance Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'ABUS',
    name: 'Arbutus Biopharma Corp',
    region: 'US',
  },
  {
    symbol: 'ABVC',
    name: 'ABVC BioPharma Inc',
    region: 'US',
  },
  {
    symbol: 'ABYSX',
    name: 'AB Trust: AB Discovery Value Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACA',
    name: 'Arcosa Inc',
    region: 'US',
  },
  {
    symbol: 'ACAAX',
    name: 'Alger Funds: Alger Capital Appreciation Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACAB',
    name: 'Atlantic Coastal Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ACABU',
    name: 'Atlantic Coastal Acquisition Corp II - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ACAD',
    name: 'Acadia Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ACAH',
    name: 'Atlantic Coastal Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ACAHU',
    name: 'Atlantic Coastal Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ACAQ',
    name: 'Athena Consumer Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ACAX',
    name: 'Alset Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ACAXU',
    name: 'Alset Capital Acquisition Corp - Units (1 Ord Class A & 1/2 War & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'ACAYX',
    name: 'Alger Institutional Funds: Alger Capital Appreciation Institutional Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACAZX',
    name: 'Alger Funds: Alger Capital Appreciation Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACB',
    name: 'Aurora Cannabis Inc',
    region: 'US',
  },
  {
    symbol: 'ACBA',
    name: 'Ace Global Business Acquisition Ltd',
    region: 'US',
  },
  {
    symbol: 'ACBAU',
    name: 'Ace Global Business Acquisition Ltd - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'ACCD',
    name: 'Accolade Inc',
    region: 'US',
  },
  {
    symbol: 'ACCO',
    name: 'Acco Brands Corporation',
    region: 'US',
  },
  {
    symbol: 'ACDI',
    name: 'Ascendant Digital Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'ACEL',
    name: 'Accel Entertainment Inc - Class A1',
    region: 'US',
  },
  {
    symbol: 'ACER',
    name: 'Acer Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ACES',
    name: 'ALPS Fund Services - ALPS Clean Energy ETF',
    region: 'US',
  },
  {
    symbol: 'ACET',
    name: 'Adicet Bio Inc',
    region: 'US',
  },
  {
    symbol: 'ACEV',
    name: 'ACE Convergence Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ACEVU',
    name: 'ACE Convergence Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ACFCX',
    name: 'American Century Growth Funds, Inc: Focused Dynamic Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACFDX',
    name: 'American Century Growth Funds, Inc: Focused Dynamic Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACFNX',
    name: 'American Century Growth Funds, Inc: Focused Dynamic Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACFOX',
    name: 'American Century Growth Funds, Inc: Focused Dynamic Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACFSX',
    name: 'American Century Growth Funds, Inc: Focused Dynamic Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACGL',
    name: 'Arch Capital Group Ltd',
    region: 'US',
  },
  {
    symbol: 'ACH',
    name: 'Aluminum Corporation Of China Limited. - ADR',
    region: 'US',
  },
  {
    symbol: 'ACHC',
    name: 'Acadia Healthcare Company Inc',
    region: 'US',
  },
  {
    symbol: 'ACHL',
    name: 'Achilles Therapeutics Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'ACHR',
    name: 'Archer Aviation Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ACHV',
    name: 'Achieve Life Sciences Inc.',
    region: 'US',
  },
  {
    symbol: 'ACI',
    name: 'Albertsons Companies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ACII',
    name: 'Atlas Crest Investment Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ACIO',
    name: 'ETF Series Solutions Trust - ETF Series Solutions Aptus Collared Income Opportunity ETF',
    region: 'US',
  },
  {
    symbol: 'ACIU',
    name: 'AC Immune SA',
    region: 'US',
  },
  {
    symbol: 'ACIW',
    name: 'ACI Worldwide Inc',
    region: 'US',
  },
  {
    symbol: 'ACIZX',
    name: 'Alger Institutional Funds: Alger Capital Appreciation Institutional Fund; Class Z-2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACKIT',
    name: 'Ackrell SPAC Partners I Co - Subunits (1 Ord & 0.5 War)',
    region: 'US',
  },
  {
    symbol: 'ACKIU',
    name: 'Ackrell SPAC Partners I Co - Units ( 1Subunit & 0.5 War)',
    region: 'US',
  },
  {
    symbol: 'ACLS',
    name: 'Axcelis Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'ACLTX',
    name: 'American Century Mutual Funds, Inc: NT Growth Fund; Class G Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ACLX',
    name: 'Arcellx Inc',
    region: 'US',
  },
  {
    symbol: 'ACM',
    name: 'AECOM',
    region: 'US',
  },
  {
    symbol: 'ACMR',
    name: 'ACM Research Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ACN',
    name: 'Accenture plc - Class A',
    region: 'US',
  },
  {
    symbol: 'ACNB',
    name: 'ACNB Corp.',
    region: 'US',
  },
  {
    symbol: 'ACON',
    name: 'Aclarion Inc',
    region: 'US',
  },
  {
    symbol: 'ACOR',
    name: 'Acorda Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ACQR',
    name: 'Independence Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ACQRU',
    name: 'Independence Holdings Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'ACRO',
    name: 'Acropolis Infrastructure Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ACRS',
    name: 'Aclaris Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ACRX',
    name: 'Acelrx Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ACSI',
    name: 'Tidal ETF Trust - American Customer Satisfaction ETF',
    region: 'US',
  },
  {
    symbol: 'ACST',
    name: 'Acasti Pharma Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ACT',
    name: 'Enact Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ACTD',
    name: 'ArcLight Clean Transition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ACTDU',
    name: 'ArcLight Clean Transition Corp II - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'ACTG',
    name: 'Acacia Research Corp',
    region: 'US',
  },
  {
    symbol: 'ACTV',
    name: 'Two Roads Shared Trust - LeaderShares Activist Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'ACU',
    name: 'Acme United Corp.',
    region: 'US',
  },
  {
    symbol: 'ACVA',
    name: 'ACV Auctions Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ACVF',
    name: 'ETF Opportunities Trust - American Conservative Values ETF',
    region: 'US',
  },
  {
    symbol: 'ACWF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'ACWI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI ACWI ETF',
    region: 'US',
  },
  {
    symbol: 'ACWV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Min Vol Factor ETF',
    region: 'US',
  },
  {
    symbol: 'ACWX',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI ACWI ex US ETF',
    region: 'US',
  },
  {
    symbol: 'ACXP',
    name: 'Acurx Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ADAG',
    name: 'Adagene Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'ADAL',
    name: 'Anthemis Digital Acquisitions I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ADALU',
    name: 'Anthemis Digital Acquisitions I Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ADAP',
    name: 'Adaptimmune Therapeutics Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'ADBE',
    name: 'Adobe Inc',
    region: 'US',
  },
  {
    symbol: 'ADCT',
    name: 'Adc Therapeutics SA',
    region: 'US',
  },
  {
    symbol: 'ADDDF',
    name: 'Adidas AG',
    region: 'US',
  },
  {
    symbol: 'ADDYY',
    name: 'Adidas AG - ADR - Level I',
    region: 'US',
  },
  {
    symbol: 'ADER',
    name: '26 Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ADERU',
    name: '26 Capital Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ADES',
    name: 'Advanced Emissions Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'ADEX',
    name: 'Adit Edtech Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ADFI',
    name: 'Two Roads Shared Trust - Anfield Dynamic Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'ADGI',
    name: 'Adagio Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ADI',
    name: 'Analog Devices Inc.',
    region: 'US',
  },
  {
    symbol: 'ADIL',
    name: 'Adial Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ADIV',
    name: 'Guinness Atkinson Funds - SmartETFs Asia Pacific Dividend Builder ETF',
    region: 'US',
  },
  {
    symbol: 'ADM',
    name: 'Archer Daniels Midland Co.',
    region: 'US',
  },
  {
    symbol: 'ADMA',
    name: 'Adma Biologics Inc',
    region: 'US',
  },
  {
    symbol: 'ADME',
    name: 'ETF Series Solutions Trust - Aptus Drawdown Managed Equity ETF',
    region: 'US',
  },
  {
    symbol: 'ADMP',
    name: 'Adamis Pharmaceuticals Corp',
    region: 'US',
  },
  {
    symbol: 'ADN',
    name: 'Advent Technologies Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ADNAX',
    name: 'American Beacon Funds: American Beacon ARK Transformational Innovation Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ADNCX',
    name: 'American Beacon Funds: American Beacon ARK Transformational Innovation Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ADNIX',
    name: 'American Beacon Funds: American Beacon ARK Transformational Innovation Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ADNPX',
    name: 'American Beacon Funds: American Beacon ARK Transformational Innovation Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ADNRX',
    name: 'American Beacon Funds: American Beacon ARK Transformational Innovation Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ADNT',
    name: 'Adient plc',
    region: 'US',
  },
  {
    symbol: 'ADNYX',
    name: 'American Beacon Funds: American Beacon ARK Transformational Innovation Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ADOC',
    name: 'Edoc Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ADP',
    name: 'Automatic Data Processing Inc.',
    region: 'US',
  },
  {
    symbol: 'ADPT',
    name: 'Adaptive Biotechnologies Corp',
    region: 'US',
  },
  {
    symbol: 'ADRA',
    name: 'Adara Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ADRE',
    name: 'Invesco Capital Management LLC - Invesco BLDRS Emerging Markets 50 ADR Index Fund',
    region: 'US',
  },
  {
    symbol: 'ADRNY',
    name: 'Koninklijke Ahold Delhaize N.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'ADRT',
    name: 'Ault Disruptive Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'ADSE',
    name: 'Ads-Tec Energy Plc',
    region: 'US',
  },
  {
    symbol: 'ADSK',
    name: 'Autodesk Inc.',
    region: 'US',
  },
  {
    symbol: 'ADT',
    name: 'ADT Inc',
    region: 'US',
  },
  {
    symbol: 'ADTH',
    name: 'AdTheorent Holding Company Inc',
    region: 'US',
  },
  {
    symbol: 'ADTN',
    name: 'Adtran Inc.',
    region: 'US',
  },
  {
    symbol: 'ADTX',
    name: 'Aditxt Inc',
    region: 'US',
  },
  {
    symbol: 'ADUS',
    name: 'Addus HomeCare Corporation',
    region: 'US',
  },
  {
    symbol: 'ADV',
    name: 'Advantage Solutions Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'ADVM',
    name: 'Adverum Biotechnologies Inc',
    region: 'US',
  },
  {
    symbol: 'ADXN',
    name: 'Addex Therapeutics Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'AE',
    name: 'Adams Resources & Energy Inc.',
    region: 'US',
  },
  {
    symbol: 'AEAC',
    name: 'Authentic Equity Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AEACU',
    name: 'Authentic Equity Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AEAE',
    name: 'AltEnergy Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AEAEU',
    name: 'AltEnergy Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AEE',
    name: 'Ameren Corp.',
    region: 'US',
  },
  {
    symbol: 'AEG',
    name: 'Aegon N. V. - New York Shares',
    region: 'US',
  },
  {
    symbol: 'AEHA',
    name: 'Aesther Healthcare Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AEHAU',
    name: 'Aesther Healthcare Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AEHL',
    name: 'Antelope Enterprise Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'AEHR',
    name: 'Aehr Test Systems',
    region: 'US',
  },
  {
    symbol: 'AEIS',
    name: 'Advanced Energy Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'AEL',
    name: 'American Equity Investment Life Holding Co',
    region: 'US',
  },
  {
    symbol: 'AEM',
    name: 'Agnico Eagle Mines Ltd',
    region: 'US',
  },
  {
    symbol: 'AEMB',
    name: 'American Century ETF Trust - American Century Emerging Markets Bond ETF',
    region: 'US',
  },
  {
    symbol: 'AEMD',
    name: 'Aethlon Medical Inc',
    region: 'US',
  },
  {
    symbol: 'AENZ',
    name: 'Aenza S.A.A - ADR',
    region: 'US',
  },
  {
    symbol: 'AEO',
    name: 'American Eagle Outfitters Inc.',
    region: 'US',
  },
  {
    symbol: 'AEP',
    name: 'American Electric Power Company Inc.',
    region: 'US',
  },
  {
    symbol: 'AER',
    name: 'Aercap Holdings N.V.',
    region: 'US',
  },
  {
    symbol: 'AERC',
    name: 'AeroClean Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AERI',
    name: 'Aerie Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AES',
    name: 'AES Corp.',
    region: 'US',
  },
  {
    symbol: 'AESE',
    name: 'Allied Esports Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'AESR',
    name: 'Two Roads Shared Trust - Anfield U.S. Equity Sector Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'AEVA',
    name: 'Aeva Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AEY',
    name: 'Addvantage Technologies Group',
    region: 'US',
  },
  {
    symbol: 'AEYE',
    name: 'AudioEye Inc',
    region: 'US',
  },
  {
    symbol: 'AEZS',
    name: 'Aeterna Zentaris Inc.',
    region: 'US',
  },
  {
    symbol: 'AFAC',
    name: 'Arena Fortify Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AFACU',
    name: 'Arena Fortify Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AFAQ',
    name: 'AF Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AFAQU',
    name: 'AF Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'AFBI',
    name: 'Affinity Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'AFDAX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFDGX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFDIX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFDRX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFEGX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Class G Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFEIX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFG',
    name: 'American Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'AFGFX',
    name: 'Virtus Investment Trust: Virtus AllianzGI Focused Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFI',
    name: 'Armstrong Flooring Inc',
    region: 'US',
  },
  {
    symbol: 'AFIB',
    name: 'Acutus Medical Inc',
    region: 'US',
  },
  {
    symbol: 'AFIF',
    name: 'Two Roads Shared Trust - Anfield Universal Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'AFK',
    name: 'VanEck ETF Trust - VanEck Africa Index ETF',
    region: 'US',
  },
  {
    symbol: 'AFL',
    name: 'Aflac Inc.',
    region: 'US',
  },
  {
    symbol: 'AFLG',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Active Factor Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'AFMC',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Active Factor Mid Cap ETF',
    region: 'US',
  },
  {
    symbol: 'AFMD',
    name: 'Affimed N.V.',
    region: 'US',
  },
  {
    symbol: 'AFMFX',
    name: 'American Mutual Fund; Class F3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AFRM',
    name: 'Affirm Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AFSM',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Active Factor Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'AFTR',
    name: 'AfterNext HealthTech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AFTY',
    name: 'CSOP Asset Management Limited - Pacer CSOP FTSE China A50 ETF',
    region: 'US',
  },
  {
    symbol: 'AFYA',
    name: 'Afya Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'AFYDX',
    name: 'American Century Mutual Funds, Inc: Sustainable Equity Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AG',
    name: 'First Majestic Silver Corporation',
    region: 'US',
  },
  {
    symbol: 'AGAC',
    name: 'African Gold Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AGBA',
    name: 'Agba Acquisition Ltd',
    region: 'US',
  },
  {
    symbol: 'AGBAU',
    name: 'Agba Acquisition Ltd - Unit (1 Ordinary share ,1 Wrt , & 1 Rts )',
    region: 'US',
  },
  {
    symbol: 'AGCB',
    name: 'Altimeter Growth Corp 2 - Class A',
    region: 'US',
  },
  {
    symbol: 'AGCO',
    name: 'AGCO Corp.',
    region: 'US',
  },
  {
    symbol: 'AGE',
    name: 'AgeX Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AGEN',
    name: 'Agenus Inc',
    region: 'US',
  },
  {
    symbol: 'AGFIX',
    name: 'AB Portfolios: AB Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGFKX',
    name: 'AB Portfolios: AB Growth Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGFRX',
    name: 'AB Portfolios: AB Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGFS',
    name: 'AgroFresh Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'AGFY',
    name: 'Agrify Corp',
    region: 'US',
  },
  {
    symbol: 'AGG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'AGGH',
    name: 'Simplify Exchange Traded Funds - Simplify Aggregate Bond PLUS Credit Hedge ETF',
    region: 'US',
  },
  {
    symbol: 'AGGR',
    name: 'Agile Growth Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AGGRU',
    name: 'Agile Growth Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'AGGY',
    name: 'WisdomTree Trust - WisdomTree Yield Enhanced U.S. Aggregate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'AGI',
    name: 'Alamos Gold Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'AGIL',
    name: 'AgileThought Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AGIO',
    name: 'Agios Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AGL',
    name: 'Agilon Health Inc',
    region: 'US',
  },
  {
    symbol: 'AGLE',
    name: 'Aeglea BioTherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AGMH',
    name: 'AGM Group Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AGNG',
    name: 'Global X Funds - Global X Aging Population ETF',
    region: 'US',
  },
  {
    symbol: 'AGO',
    name: 'Assured Guaranty Ltd',
    region: 'US',
  },
  {
    symbol: 'AGOV',
    name: 'Exchange Listed Funds Trust - ETC Gavekal Asia Pacific Government Bond ETF',
    region: 'US',
  },
  {
    symbol: 'AGOX',
    name: 'Starboard Investment Trust - Adaptive Alpha Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'AGOZX',
    name: 'Alger Funds: Alger Small Cap Focus Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGQ',
    name: 'ProShares Trust - ProShares Ultra Silver',
    region: 'US',
  },
  {
    symbol: 'AGR',
    name: 'Avangrid Inc',
    region: 'US',
  },
  {
    symbol: 'AGRDX',
    name: 'American Century Mutual Funds, Inc: Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGRFX',
    name: 'AB Portfolios: AB Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGRI',
    name: 'AgriFORCE Growing Systems ltd',
    region: 'US',
  },
  {
    symbol: 'AGRO',
    name: 'Adecoagro S.A.',
    region: 'US',
  },
  {
    symbol: 'AGRX',
    name: 'Agile Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AGRYX',
    name: 'AB Portfolios: AB Growth Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGS',
    name: 'PlayAGS Inc',
    region: 'US',
  },
  {
    symbol: 'AGT',
    name: 'iShares Trust - iShares MSCI Argentina and Global Exposure ETF',
    region: 'US',
  },
  {
    symbol: 'AGTC',
    name: 'Applied Genetic Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'AGTI',
    name: 'Agiliti Inc',
    region: 'US',
  },
  {
    symbol: 'AGX',
    name: 'Argan, Inc.',
    region: 'US',
  },
  {
    symbol: 'AGYS',
    name: 'Agilysys, Inc',
    region: 'US',
  },
  {
    symbol: 'AGYWX',
    name: 'American Century Mutual Funds, Inc: Growth Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AGZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Agency Bond ETF',
    region: 'US',
  },
  {
    symbol: 'AGZD',
    name: 'WisdomTree Trust - WisdomTree Interest Rate Hedged U.S. Aggregate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'AHCO',
    name: 'AdaptHealth Corp',
    region: 'US',
  },
  {
    symbol: 'AHHX',
    name: 'Starboard Investment Trust - Adaptive High Income ETF',
    region: 'US',
  },
  {
    symbol: 'AHI',
    name: 'Advanced Human Imaging Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'AHODF',
    name: 'Koninklijke Ahold Delhaize N.V.',
    region: 'US',
  },
  {
    symbol: 'AHPA',
    name: 'Avista Public Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'AHPAU',
    name: 'Avista Public Acquisition Corp II - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'AHPI',
    name: 'Allied Healthcare Product Inc.',
    region: 'US',
  },
  {
    symbol: 'AHRN',
    name: 'Ahren Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AHRNU',
    name: 'Ahren Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AHYB',
    name: 'American Century ETF Trust - American Century Select High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'AI',
    name: 'C3.ai Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AIA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Asia 50 ETF',
    region: 'US',
  },
  {
    symbol: 'AIB',
    name: 'AIB Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AIBBU',
    name: 'AIB Acquisition Corp - Units (1 Ord Share Class A & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'AIEQ',
    name: 'ETF Managers Group LLC - AI Powered Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AIG',
    name: 'American International Group Inc',
    region: 'US',
  },
  {
    symbol: 'AIH',
    name: 'Aesthetic Medical International Holdings Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'AIIQ',
    name: 'ETF Series Solutions Trust - AI Powered International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AIKI',
    name: 'AIkido Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'AILG',
    name: 'Listed Funds Trust - Alpha Intelligent - Large Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'AILV',
    name: 'Listed Funds Trust - Alpha Intelligent - Large Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'AIM',
    name: 'AIM ImmunoTech Inc',
    region: 'US',
  },
  {
    symbol: 'AIMC',
    name: 'Altra Industrial Motion Corp',
    region: 'US',
  },
  {
    symbol: 'AIN',
    name: 'Albany International Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'AINC',
    name: 'Ashford Inc',
    region: 'US',
  },
  {
    symbol: 'AIP',
    name: 'Arteris Inc',
    region: 'US',
  },
  {
    symbol: 'AIQ',
    name: 'Global X Funds - Global X Artificial Intelligence & Technology ETF',
    region: 'US',
  },
  {
    symbol: 'AIR',
    name: 'AAR Corp.',
    region: 'US',
  },
  {
    symbol: 'AIRG',
    name: 'Airgain Inc',
    region: 'US',
  },
  {
    symbol: 'AIRI',
    name: 'Air Industries Group',
    region: 'US',
  },
  {
    symbol: 'AIRR',
    name: 'First Trust Exchange-Traded Fund III - First Trust RBA American Industrial Renaissance ETF',
    region: 'US',
  },
  {
    symbol: 'AIRS',
    name: 'Airsculpt Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AIRT',
    name: 'Air T Inc',
    region: 'US',
  },
  {
    symbol: 'AIT',
    name: 'Applied Industrial Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'AIVI',
    name: 'WisdomTree Trust - WisdomTree International AI Enhanced Value Fund',
    region: 'US',
  },
  {
    symbol: 'AIVL',
    name: 'WisdomTree Trust - WisdomTree U.S. AI Enhanced Value Fund',
    region: 'US',
  },
  {
    symbol: 'AIZ',
    name: 'Assurant Inc',
    region: 'US',
  },
  {
    symbol: 'AJG',
    name: 'Arthur J. Gallagher & Co.',
    region: 'US',
  },
  {
    symbol: 'AJRD',
    name: 'Aerojet Rocketdyne Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AKA',
    name: 'a.k.a. Brands Holding Corp',
    region: 'US',
  },
  {
    symbol: 'AKAM',
    name: 'Akamai Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AKBA',
    name: 'Akebia Therapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'AKIC',
    name: 'Sports Ventures Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AKICU',
    name: 'Sports Ventures Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'AKO.A',
    name: 'Embotelladora Andina S.A. - ADR (Class A)',
    region: 'US',
  },
  {
    symbol: 'AKO.B',
    name: 'Embotelladora Andina S.A. - ADR (Class B)',
    region: 'US',
  },
  {
    symbol: 'AKREX',
    name: 'Professionally Managed Portfolios: Akre Focus Fund; Retail Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AKRIX',
    name: 'Professionally Managed Portfolios: Akre Focus Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AKRO',
    name: 'Akero Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AKRSX',
    name: 'Professionally Managed Portfolios: Akre Focus Fund; Supra Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AKTS',
    name: 'Akoustis Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AKTX',
    name: 'Akari Therapeutics Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'AKU',
    name: 'Akumin Inc',
    region: 'US',
  },
  {
    symbol: 'AKUS',
    name: 'Akouos Inc',
    region: 'US',
  },
  {
    symbol: 'AKYA',
    name: 'Akoya Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'AKZOF',
    name: 'Akzo Nobel N.V.',
    region: 'US',
  },
  {
    symbol: 'AKZOY',
    name: 'Akzo Nobel N.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'AL',
    name: 'Air Lease Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ALAFX',
    name: 'Alger Institutional Funds: Alger Focus Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALARX',
    name: 'Alger Institutional Funds: Alger Capital Appreciation Institutional Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALB',
    name: 'Albemarle Corp.',
    region: 'US',
  },
  {
    symbol: 'ALBO',
    name: 'Albireo Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'ALC',
    name: 'Alcon Inc. - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'ALCC',
    name: 'AltC Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ALCFX',
    name: 'Alger Institutional Funds: Alger Focus Equity Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALCKX',
    name: 'AB Large Cap Growth Fund, Inc; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALCO',
    name: 'Alico Inc.',
    region: 'US',
  },
  {
    symbol: 'ALDX',
    name: 'Aldeyra Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ALE',
    name: 'Allete, Inc.',
    region: 'US',
  },
  {
    symbol: 'ALEC',
    name: 'Alector Inc',
    region: 'US',
  },
  {
    symbol: 'ALEX',
    name: 'Alexander & Baldwin Inc.',
    region: 'US',
  },
  {
    symbol: 'ALF',
    name: 'ALFI Inc',
    region: 'US',
  },
  {
    symbol: 'ALFA',
    name: 'ETF Series Solutions Trust - ESS AlphaClone Alternative Alpha ETF',
    region: 'US',
  },
  {
    symbol: 'ALG',
    name: 'Alamo Group Inc.',
    region: 'US',
  },
  {
    symbol: 'ALGM',
    name: 'Allegro Microsystems Inc.',
    region: 'US',
  },
  {
    symbol: 'ALGN',
    name: 'Align Technology, Inc.',
    region: 'US',
  },
  {
    symbol: 'ALGRX',
    name: 'Alger Institutional Funds: Alger Focus Equity Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALGS',
    name: 'Aligos Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ALGT',
    name: 'Allegiant Travel',
    region: 'US',
  },
  {
    symbol: 'ALGYX',
    name: 'Alger Institutional Funds: Alger Focus Equity Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALHC',
    name: 'Alignment Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'ALIM',
    name: 'Alimera Sciences Inc.',
    region: 'US',
  },
  {
    symbol: 'ALIT',
    name: 'Alight Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'ALJJ',
    name: 'Alj Regional Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ALK',
    name: 'Alaska Air Group Inc.',
    region: 'US',
  },
  {
    symbol: 'ALKS',
    name: 'Alkermes plc',
    region: 'US',
  },
  {
    symbol: 'ALKT',
    name: 'Alkami Technology Inc',
    region: 'US',
  },
  {
    symbol: 'ALL',
    name: 'Allstate Corp (The)',
    region: 'US',
  },
  {
    symbol: 'ALLE',
    name: 'Allegion plc',
    region: 'US',
  },
  {
    symbol: 'ALLIX',
    name: 'AB Large Cap Growth Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALLK',
    name: 'Allakos Inc',
    region: 'US',
  },
  {
    symbol: 'ALLO',
    name: 'Allogene Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ALLR',
    name: 'Allarity Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ALLT',
    name: 'Allot Ltd',
    region: 'US',
  },
  {
    symbol: 'ALLY',
    name: 'Ally Financial Inc',
    region: 'US',
  },
  {
    symbol: 'ALMAX',
    name: 'Alger Funds: Alger Weatherbie Specialized Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALNA',
    name: 'Allena Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ALNY',
    name: 'Alnylam Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ALOR',
    name: 'ALSP Orchid Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'ALORU',
    name: 'ALSP Orchid Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ALOT',
    name: 'AstroNova Inc',
    region: 'US',
  },
  {
    symbol: 'ALPA',
    name: 'Alpha Healthcare Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'ALPAU',
    name: 'Alpha Healthcare Acquisition Corp III - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'ALPN',
    name: 'Alpine Immune Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'ALPP',
    name: 'Alpine 4 Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ALR',
    name: 'AlerisLife Inc',
    region: 'US',
  },
  {
    symbol: 'ALRM',
    name: 'Alarm.com Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ALRN',
    name: 'Aileron Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ALRS',
    name: 'Alerus Financial Corp',
    region: 'US',
  },
  {
    symbol: 'ALSA',
    name: 'Alpha Star Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ALSAU',
    name: 'Alpha Star Acquisition Corp - Units (1 Ordinary Shares, 1 Right, 1 War)',
    region: 'US',
  },
  {
    symbol: 'ALSN',
    name: 'Allison Transmission Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ALT',
    name: 'Altimmune Inc',
    region: 'US',
  },
  {
    symbol: 'ALTG',
    name: 'Alta Equipment Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ALTL',
    name: 'Pacer Funds Trust - Pacer Lunt Large Cap Alternator ETF',
    region: 'US',
  },
  {
    symbol: 'ALTO',
    name: 'Alto Ingredients Inc',
    region: 'US',
  },
  {
    symbol: 'ALTR',
    name: 'Altair Engineering Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ALTS',
    name: 'ProShares Trust - ProShares Morningstar Alternatives Solution ETF',
    region: 'US',
  },
  {
    symbol: 'ALTU',
    name: 'Altitude Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ALTUU',
    name: 'Altitude Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ALTY',
    name: 'Global X Funds - Global X Alternative Income ETF',
    region: 'US',
  },
  {
    symbol: 'ALV',
    name: 'Autoliv Inc.',
    region: 'US',
  },
  {
    symbol: 'ALVR',
    name: 'AlloVir Inc',
    region: 'US',
  },
  {
    symbol: 'ALX',
    name: 'Alexander`s Inc.',
    region: 'US',
  },
  {
    symbol: 'ALXO',
    name: 'Alx Oncology Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ALYA',
    name: 'Alithya Group inc - Class A (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'ALZFX',
    name: 'Alger Institutional Funds: Alger Focus Equity Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ALZN',
    name: 'Alzamend Neuro Inc',
    region: 'US',
  },
  {
    symbol: 'AM',
    name: 'Antero Midstream Corp',
    region: 'US',
  },
  {
    symbol: 'AMAGX',
    name: 'Amana Mutual Funds Trust: Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMAL',
    name: 'Amalgamated Financial Corp',
    region: 'US',
  },
  {
    symbol: 'AMAM',
    name: 'Ambrx Biopharma Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'AMANX',
    name: 'Amana Mutual Funds Trust: Income Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMAO',
    name: 'American Acquisition Opportunity Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMAOU',
    name: 'American Acquisition Opportunity Inc - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AMAT',
    name: 'Applied Materials Inc.',
    region: 'US',
  },
  {
    symbol: 'AMAX',
    name: 'Starboard Investment Trust - RH Hedged Multi-Asset Income ETF',
    region: 'US',
  },
  {
    symbol: 'AMBA',
    name: 'Ambarella Inc',
    region: 'US',
  },
  {
    symbol: 'AMBC',
    name: 'AMBAC Financial Group Inc.',
    region: 'US',
  },
  {
    symbol: 'AMBO',
    name: 'Ambow Education Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'AMBP',
    name: 'Ardagh Metal Packaging S.A.',
    region: 'US',
  },
  {
    symbol: 'AMC',
    name: 'AMC Entertainment Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMCI',
    name: 'AMCI Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'AMCIU',
    name: 'AMCI Acquisition Corp II - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'AMCR',
    name: 'Amcor Plc',
    region: 'US',
  },
  {
    symbol: 'AMCX',
    name: 'AMC Networks Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMD',
    name: 'Advanced Micro Devices Inc.',
    region: 'US',
  },
  {
    symbol: 'AME',
    name: 'Ametek Inc',
    region: 'US',
  },
  {
    symbol: 'AMED',
    name: 'Amedisys Inc.',
    region: 'US',
  },
  {
    symbol: 'AMEH',
    name: 'Apollo Medical Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AMER',
    name: 'Emles Trust - Emles Made in America ETF',
    region: 'US',
  },
  {
    symbol: 'AMFCX',
    name: 'American Mutual Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMFFX',
    name: 'American Mutual Fund; Class F1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMGN',
    name: 'AMGEN Inc.',
    region: 'US',
  },
  {
    symbol: 'AMIGX',
    name: 'Amana Mutual Funds Trust: Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMINX',
    name: 'Amana Mutual Funds Trust: Income Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMK',
    name: 'Assetmark Financial Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AMKR',
    name: 'AMKOR Technology Inc.',
    region: 'US',
  },
  {
    symbol: 'AMLP',
    name: 'ALPS Fund Services - Alerian MLP ETF',
    region: 'US',
  },
  {
    symbol: 'AMLX',
    name: 'Amylyx Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AMN',
    name: 'AMN Healthcare Services Inc.',
    region: 'US',
  },
  {
    symbol: 'AMNB',
    name: 'American National Bankshares Inc.',
    region: 'US',
  },
  {
    symbol: 'AMOM',
    name: 'Exchange Listed Funds Trust - QRAFT AI Enhanced U.S. Large Cap Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'AMOT',
    name: 'Allied Motion Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AMOV',
    name: 'America Movil S.A.B.DE C.V. - ADR - Class A',
    region: 'US',
  },
  {
    symbol: 'AMPE',
    name: 'Ampio Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AMPG',
    name: 'Amplitech Group Inc',
    region: 'US',
  },
  {
    symbol: 'AMPH',
    name: 'Amphastar Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AMPI',
    name: 'Advanced Merger Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMPL',
    name: 'Amplitude Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMPY',
    name: 'Amplify Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'AMR',
    name: 'Alpha Metallurgical Resources Inc',
    region: 'US',
  },
  {
    symbol: 'AMRC',
    name: 'Ameresco Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'AMRFX',
    name: 'American Mutual Fund; Class F2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMRK',
    name: 'A-Mark Precious Metals Inc',
    region: 'US',
  },
  {
    symbol: 'AMRMX',
    name: 'American Mutual Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AMRN',
    name: 'Amarin Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'AMRS',
    name: 'Amyris Inc',
    region: 'US',
  },
  {
    symbol: 'AMRX',
    name: 'Amneal Pharmaceuticals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMS',
    name: 'American Shared Hospital Services',
    region: 'US',
  },
  {
    symbol: 'AMSC',
    name: 'American Superconductor Corp.',
    region: 'US',
  },
  {
    symbol: 'AMSF',
    name: 'Amerisafe Inc',
    region: 'US',
  },
  {
    symbol: 'AMST',
    name: 'Amesite Inc',
    region: 'US',
  },
  {
    symbol: 'AMSWA',
    name: 'American Software Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'AMT',
    name: 'American Tower Corp.',
    region: 'US',
  },
  {
    symbol: 'AMTB',
    name: 'Amerant Bancorp Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AMTI',
    name: 'Applied Molecular Transport Inc',
    region: 'US',
  },
  {
    symbol: 'AMTX',
    name: 'Aemetis Inc',
    region: 'US',
  },
  {
    symbol: 'AMWD',
    name: 'American Woodmark Corp.',
    region: 'US',
  },
  {
    symbol: 'AMWL',
    name: 'American Well Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'AMX',
    name: 'America Movil S.A.B.DE C.V. - ADR - Series L',
    region: 'US',
  },
  {
    symbol: 'AMYT',
    name: 'Amryt Pharma Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'AMZA',
    name: 'ETFis Series Trust I - InfraCap MLP ETF',
    region: 'US',
  },
  {
    symbol: 'AMZN',
    name: 'Amazon.com Inc.',
    region: 'US',
  },
  {
    symbol: 'AN',
    name: 'Autonation Inc.',
    region: 'US',
  },
  {
    symbol: 'ANAB',
    name: 'AnaptysBio Inc',
    region: 'US',
  },
  {
    symbol: 'ANAC',
    name: 'Arctos NorthStar Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ANAT',
    name: 'American National Group Inc',
    region: 'US',
  },
  {
    symbol: 'ANDE',
    name: 'Andersons Inc.',
    region: 'US',
  },
  {
    symbol: 'ANEB',
    name: 'Anebulo Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ANEFX',
    name: 'New Economy Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANET',
    name: 'Arista Networks Inc',
    region: 'US',
  },
  {
    symbol: 'ANEW',
    name: 'ProShares Trust - ProShares MSCI Transformational Changes ETF',
    region: 'US',
  },
  {
    symbol: 'ANF',
    name: 'Abercrombie & Fitch Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'ANFCX',
    name: 'New Economy Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANFFX',
    name: 'New Economy Fund; Class F1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANGH',
    name: 'Anghami Inc',
    region: 'US',
  },
  {
    symbol: 'ANGI',
    name: 'Angi Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ANGL',
    name: 'VanEck ETF Trust - VanEck Fallen Angel High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ANGN',
    name: 'Angion Biomedica Corp',
    region: 'US',
  },
  {
    symbol: 'ANGO',
    name: 'Angiodynamic Inc',
    region: 'US',
  },
  {
    symbol: 'ANIK',
    name: 'Anika Therapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'ANIP',
    name: 'ANI Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ANIX',
    name: 'Anixa Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'ANNX',
    name: 'Annexon Inc',
    region: 'US',
  },
  {
    symbol: 'ANOAX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANOCX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANODX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANOGX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANOIX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANONX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANORX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANOYX',
    name: 'American Century Mutual Funds, Inc: Small Cap Growth Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ANPC',
    name: 'AnPac Bio-Medical Science Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ANSS',
    name: 'Ansys Inc. - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'ANTE',
    name: 'AirNet Technology Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'ANTM',
    name: 'Anthem Inc',
    region: 'US',
  },
  {
    symbol: 'ANTX',
    name: 'AN2 Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ANVS',
    name: 'Annovis Bio Inc',
    region: 'US',
  },
  {
    symbol: 'ANY',
    name: 'Sphere 3D Corp',
    region: 'US',
  },
  {
    symbol: 'ANZU',
    name: 'Anzu Special Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'ANZUU',
    name: 'Anzu Special Acquisition Corp I - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'AOA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core Aggressive Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'AOFAX',
    name: 'Alger Funds: Alger Small Cap Focus Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AOFCX',
    name: 'Alger Funds: Alger Small Cap Focus Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AOFIX',
    name: 'Alger Funds: Alger Small Cap Focus Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AOGO',
    name: 'Arogo Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AOGOU',
    name: 'Arogo Capital Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'AOGPX',
    name: 'Virtus Investment Trust: Virtus AllianzGI Focused Growth Fund; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AOK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core Conservative Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'AOM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core Moderate Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'AOR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core Growth Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'AORT',
    name: 'Artivion Inc',
    region: 'US',
  },
  {
    symbol: 'AOS',
    name: 'A.O. Smith Corp.',
    region: 'US',
  },
  {
    symbol: 'AOSL',
    name: 'Alpha & Omega Semiconductor Ltd',
    region: 'US',
  },
  {
    symbol: 'AOUT',
    name: 'American Outdoor Brands Inc',
    region: 'US',
  },
  {
    symbol: 'AP',
    name: 'Ampco-Pittsburgh Corp.',
    region: 'US',
  },
  {
    symbol: 'APA',
    name: 'APA Corporation',
    region: 'US',
  },
  {
    symbol: 'APAC',
    name: 'StoneBridge Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'APACU',
    name: 'StoneBridge Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'APCX',
    name: 'AppTech Payments Corp',
    region: 'US',
  },
  {
    symbol: 'APD',
    name: 'Air Products & Chemicals Inc.',
    region: 'US',
  },
  {
    symbol: 'APDN',
    name: 'Applied Dna Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'APEI',
    name: 'American Public Education Inc',
    region: 'US',
  },
  {
    symbol: 'APEN',
    name: 'Apollo Endosurgery Inc',
    region: 'US',
  },
  {
    symbol: 'APG',
    name: 'APi Group Corporation',
    region: 'US',
  },
  {
    symbol: 'APGAX',
    name: 'AB Large Cap Growth Fund, Inc; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'APGB',
    name: 'Apollo Strategic Growth Capital II - Class A',
    region: 'US',
  },
  {
    symbol: 'APGCX',
    name: 'AB Large Cap Growth Fund, Inc; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'APGYX',
    name: 'AB Large Cap Growth Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'APGZX',
    name: 'AB Large Cap Growth Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'APH',
    name: 'Amphenol Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'API',
    name: 'Agora Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'APLD',
    name: 'Applied Blockchain Inc',
    region: 'US',
  },
  {
    symbol: 'APLS',
    name: 'Apellis Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'APLT',
    name: 'Applied Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'APM',
    name: 'Aptorum Group Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'APMI',
    name: 'AxonPrime Infrastructure Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'APMIU',
    name: 'AxonPrime Infrastructure Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'APN',
    name: 'Apeiron Capital Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'APOG',
    name: 'Apogee Enterprises Inc.',
    region: 'US',
  },
  {
    symbol: 'APP',
    name: 'Applovin Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'APPF',
    name: 'Appfolio Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'APPH',
    name: 'AppHarvest Inc',
    region: 'US',
  },
  {
    symbol: 'APPN',
    name: 'Appian Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'APPS',
    name: 'Digital Turbine Inc',
    region: 'US',
  },
  {
    symbol: 'APRE',
    name: 'Aprea Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'APRN',
    name: 'Blue Apron Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'APRZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (April) ETF',
    region: 'US',
  },
  {
    symbol: 'APSG',
    name: 'Apollo Strategic Growth Capital - Class A',
    region: 'US',
  },
  {
    symbol: 'APT',
    name: 'Alpha Pro Tech Ltd.',
    region: 'US',
  },
  {
    symbol: 'APTM',
    name: 'Alpha Partners Technology Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'APTMU',
    name: 'Alpha Partners Technology Merger Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'APTO',
    name: 'Aptose Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'APTV',
    name: 'Aptiv PLC',
    region: 'US',
  },
  {
    symbol: 'APTX',
    name: 'Aptinyx Inc',
    region: 'US',
  },
  {
    symbol: 'APVO',
    name: 'Aptevo Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'APWC',
    name: 'Asia Pacific Wire & Cable',
    region: 'US',
  },
  {
    symbol: 'APXH',
    name: 'Two Roads Shared Trust - APEX HealthCare ETF',
    region: 'US',
  },
  {
    symbol: 'APXI',
    name: 'APx Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'APXIU',
    name: 'APx Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'APYX',
    name: 'Apyx Medical Corp',
    region: 'US',
  },
  {
    symbol: 'AQB',
    name: 'AquaBounty Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AQGX',
    name: 'Starboard Investment Trust - AI Quality Growth ETF',
    region: 'US',
  },
  {
    symbol: 'AQMS',
    name: 'Aqua Metals Inc',
    region: 'US',
  },
  {
    symbol: 'AQN',
    name: 'Algonquin Power & Utilities Corp',
    region: 'US',
  },
  {
    symbol: 'AQST',
    name: 'Aquestive Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AQUA',
    name: 'Evoqua Water Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'AQWA',
    name: 'Global X Funds - Global X Clean Water ETF',
    region: 'US',
  },
  {
    symbol: 'AR',
    name: 'Antero Resources Corp',
    region: 'US',
  },
  {
    symbol: 'ARAV',
    name: 'Aravive Inc',
    region: 'US',
  },
  {
    symbol: 'ARAY',
    name: 'Accuray Inc',
    region: 'US',
  },
  {
    symbol: 'ARB',
    name: 'AltShares Trust - AltShares Merger Arbitrage ETF',
    region: 'US',
  },
  {
    symbol: 'ARBE',
    name: 'Arbe Robotics Ltd',
    region: 'US',
  },
  {
    symbol: 'ARBG',
    name: 'Aequi Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ARBGU',
    name: 'Aequi Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ARBK',
    name: 'Argo Blockchain Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'ARC',
    name: 'ARC Document Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'ARCB',
    name: 'ArcBest Corp',
    region: 'US',
  },
  {
    symbol: 'ARCE',
    name: 'Arco Platform Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'ARCH',
    name: 'Arch Resources Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ARCK',
    name: 'Arbor Rapha Capital Bioholdings Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'ARCKU',
    name: 'Arbor Rapha Capital Bioholdings Corp I - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ARCM',
    name: 'Arrow Investments Trust - Arrow Reserve Capital Management ETF',
    region: 'US',
  },
  {
    symbol: 'ARCO',
    name: 'Arcos Dorados Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ARCT',
    name: 'Arcturus Therapeutics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ARDS',
    name: 'Aridis Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ARDX',
    name: 'Ardelyx Inc',
    region: 'US',
  },
  {
    symbol: 'AREB',
    name: 'American Rebel Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AREC',
    name: 'American Resources Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'AREN',
    name: 'Arena Group Holdings Inc (The)',
    region: 'US',
  },
  {
    symbol: 'ARGO',
    name: 'Argo Group International Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'ARGT',
    name: 'Global X Funds - Global X MSCI Argentina ETF',
    region: 'US',
  },
  {
    symbol: 'ARGU',
    name: 'Argus Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ARGUU',
    name: 'Argus Capital Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ARGX',
    name: 'Argen X SE - ADR',
    region: 'US',
  },
  {
    symbol: 'ARHS',
    name: 'Arhaus Inc Class A',
    region: 'US',
  },
  {
    symbol: 'ARIS',
    name: 'Aris Water Solutions Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ARIZ',
    name: 'Arisz Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ARIZU',
    name: 'Arisz Acquisition Corp - Units (1 Ord Share & 1Right & 1 War)',
    region: 'US',
  },
  {
    symbol: 'ARKF',
    name: 'ARK ETF Trust - ARK Fintech Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'ARKG',
    name: 'ARK Investment Management LLC - ARK Genomic Revolution ETF',
    region: 'US',
  },
  {
    symbol: 'ARKK',
    name: 'ARK Investment Management LLC - ARK Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'ARKO',
    name: 'ARKO Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ARKQ',
    name: 'ARK Investment Management LLC - ARK Autonomous Technology & Robotics ETF',
    region: 'US',
  },
  {
    symbol: 'ARKR',
    name: 'Ark Restaurants Corp.',
    region: 'US',
  },
  {
    symbol: 'ARKW',
    name: 'ARK Investment Management LLC - ARK Next Generation Internet ETF',
    region: 'US',
  },
  {
    symbol: 'ARKX',
    name: 'ARK ETF Trust - ARK Space Exploration & Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'ARLO',
    name: 'Arlo Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'ARLP',
    name: 'Alliance Resource Partners, LP - Unit',
    region: 'US',
  },
  {
    symbol: 'ARMK',
    name: 'Aramark',
    region: 'US',
  },
  {
    symbol: 'ARMP',
    name: 'Armata Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ARMR',
    name: 'Exchange Listed Funds Trust - Armor US Equity Index ETF',
    region: 'US',
  },
  {
    symbol: 'ARNC',
    name: 'Arconic Corporation',
    region: 'US',
  },
  {
    symbol: 'AROC',
    name: 'Archrock Inc',
    region: 'US',
  },
  {
    symbol: 'AROW',
    name: 'Arrow Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'ARQQ',
    name: 'Arqit Quantum Inc',
    region: 'US',
  },
  {
    symbol: 'ARQT',
    name: 'Arcutis Biotherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ARR',
    name: 'ARMOUR Residential REIT Inc',
    region: 'US',
  },
  {
    symbol: 'ARRW',
    name: 'Arrowroot Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ARRWU',
    name: 'Arrowroot Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ARRY',
    name: 'Array Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'ARTA',
    name: 'Artisan Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ARTAU',
    name: 'Artisan Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ARTE',
    name: 'Artemis Strategic Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ARTEU',
    name: 'Artemis Strategic Investment Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ARTL',
    name: 'Artelo Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'ARTNA',
    name: 'Artesian Resources Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'ARTW',
    name: 'Art`s-way Manufacturing Co. Inc.',
    region: 'US',
  },
  {
    symbol: 'ARVL',
    name: 'Arrival',
    region: 'US',
  },
  {
    symbol: 'ARVN',
    name: 'Arvinas Inc',
    region: 'US',
  },
  {
    symbol: 'ARVR',
    name: 'First Trust Exchange-Traded Fund II - First Trust IndxxMetaverse ETF',
    region: 'US',
  },
  {
    symbol: 'ARW',
    name: 'Arrow Electronics Inc.',
    region: 'US',
  },
  {
    symbol: 'ARWR',
    name: 'Arrowhead Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'ARYD',
    name: 'Arya Sciences Acquisition Corp IV - Class A',
    region: 'US',
  },
  {
    symbol: 'ARYE',
    name: 'ARYA Sciences Acquisition Corp V - Class A',
    region: 'US',
  },
  {
    symbol: 'ASAI',
    name: 'Sendas Distribuidora S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'ASAN',
    name: 'Asana Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ASAQ',
    name: 'Atlantic Avenue Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ASAX',
    name: 'Astrea Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ASAXU',
    name: 'Astrea Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ASB',
    name: 'Associated Banc-Corp.',
    region: 'US',
  },
  {
    symbol: 'ASC',
    name: 'Ardmore Shipping Corp',
    region: 'US',
  },
  {
    symbol: 'ASCA',
    name: 'ASPAC I Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ASCAU',
    name: 'ASPAC I Acquisition Corp - Units (1 Ord Share Class A ,3/4 War & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'ASDEX',
    name: 'American Century Mutual Funds, Inc: Select Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ASEA',
    name: 'Global X Funds - Global X FTSE Southeast Asia ETF',
    region: 'US',
  },
  {
    symbol: 'ASET',
    name: 'FlexShares Trust - FlexShares Real Assets Allocation Index Fund',
    region: 'US',
  },
  {
    symbol: 'ASGN',
    name: 'ASGN Inc',
    region: 'US',
  },
  {
    symbol: 'ASH',
    name: 'Ashland Global Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ASHR',
    name: 'DBX ETF Trust - Xtrackers Harvest CSI 300 China A-Shares ETF',
    region: 'US',
  },
  {
    symbol: 'ASHS',
    name: 'DBX ETF Trust - Xtrackers Harvest CSI 500 China A-Shares Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'ASHX',
    name: 'DBX ETF Trust - Xtrackers MSCI China A-Inclusion Equity ETF',
    region: 'US',
  },
  {
    symbol: 'ASIMX',
    name: 'Alger Funds: Alger Weatherbie Specialized Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ASIX',
    name: 'AdvanSix Inc',
    region: 'US',
  },
  {
    symbol: 'ASLE',
    name: 'AerSale Corp',
    region: 'US',
  },
  {
    symbol: 'ASLN',
    name: 'ASLAN Pharmaceuticals Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ASLWX',
    name: 'American Century Mutual Funds, Inc: Select Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ASM',
    name: 'Avino Silver & Gold Mines Ltd.',
    region: 'US',
  },
  {
    symbol: 'ASMB',
    name: 'Assembly Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'ASMIY',
    name: 'ASM International NV - New York Shares',
    region: 'US',
  },
  {
    symbol: 'ASML',
    name: 'ASML Holding NV - New York Shares',
    region: 'US',
  },
  {
    symbol: 'ASMZX',
    name: 'Alger Funds: Alger Weatherbie Specialized Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ASND',
    name: 'Ascendis Pharma A/S - ADR',
    region: 'US',
  },
  {
    symbol: 'ASO',
    name: 'Academy Sports and Outdoors Inc',
    region: 'US',
  },
  {
    symbol: 'ASPA',
    name: 'Abri SPAC I Inc',
    region: 'US',
  },
  {
    symbol: 'ASPAU',
    name: 'Abri SPAC I Inc - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'ASPC',
    name: 'Alpha Capital Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'ASPCU',
    name: 'Alpha Capital Acquisition Co - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ASPN',
    name: 'Aspen Aerogels Inc.',
    region: 'US',
  },
  {
    symbol: 'ASPU',
    name: 'Aspen Group Inc',
    region: 'US',
  },
  {
    symbol: 'ASPY',
    name: 'ASYMmetric ETFs Trust - ASYMsharesASYMmetric S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'ASR',
    name: 'Grupo Aeroportuario Del Sureste S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'ASRT',
    name: 'Assertio Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ASRV',
    name: 'Ameriserv Financial Inc',
    region: 'US',
  },
  {
    symbol: 'ASTC',
    name: 'Astrotech Corp',
    region: 'US',
  },
  {
    symbol: 'ASTE',
    name: 'Astec Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'ASTI',
    name: 'Ascent Solar Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'ASTL',
    name: 'Algoma Steel Group Inc',
    region: 'US',
  },
  {
    symbol: 'ASTR',
    name: 'Astra Space Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ASTS',
    name: 'AST SpaceMobile Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ASUR',
    name: 'Asure Software Inc',
    region: 'US',
  },
  {
    symbol: 'ASX',
    name: 'ASE Technology Holding Co.Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ASXC',
    name: 'Asensus Surgical Inc',
    region: 'US',
  },
  {
    symbol: 'ASYMX',
    name: 'Alger Funds: Alger Weatherbie Specialized Growth Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ASYS',
    name: 'Amtech Systems Inc.',
    region: 'US',
  },
  {
    symbol: 'ASZ',
    name: 'Austerlitz Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ATA',
    name: 'Americas Technology Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ATAI',
    name: 'ATAI Life Sciences N.V.',
    region: 'US',
  },
  {
    symbol: 'ATAK',
    name: 'Aurora Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ATAKU',
    name: 'Aurora Technology Acquisition Corp - Units (1 Ord Class A, 1 War & 1 Rights)',
    region: 'US',
  },
  {
    symbol: 'ATAQ',
    name: 'Altimar Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'ATC',
    name: 'Atotech Ltd',
    region: 'US',
  },
  {
    symbol: 'ATCO',
    name: 'Atlas Corp.',
    region: 'US',
  },
  {
    symbol: 'ATCX',
    name: 'Atlas Technical Consultants Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ATEC',
    name: 'Alphatec Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ATEK',
    name: 'Athena Technology Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ATEN',
    name: 'A10 Networks Inc',
    region: 'US',
  },
  {
    symbol: 'ATER',
    name: 'Aterian Inc',
    region: 'US',
  },
  {
    symbol: 'ATEX',
    name: 'Anterix Inc',
    region: 'US',
  },
  {
    symbol: 'ATFV',
    name: 'Alger Funds - Alger 35 ETF',
    region: 'US',
  },
  {
    symbol: 'ATGE',
    name: 'Adtalem Global Education Inc',
    region: 'US',
  },
  {
    symbol: 'ATHA',
    name: 'Athira Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'ATHE',
    name: 'Alterity Therapeutics Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ATHGX',
    name: 'American Century Mutual Funds, Inc: Heritage Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ATHM',
    name: 'Autohome Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'ATHX',
    name: 'Athersys Inc',
    region: 'US',
  },
  {
    symbol: 'ATHYX',
    name: 'American Century Mutual Funds, Inc: Heritage Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ATI',
    name: 'Allegheny Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'ATIF',
    name: 'ATIF Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'ATIP',
    name: 'ATI Physical Therapy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ATKR',
    name: 'Atkore Inc',
    region: 'US',
  },
  {
    symbol: 'ATLO',
    name: 'Ames National Corp.',
    region: 'US',
  },
  {
    symbol: 'ATNF',
    name: '180 Life Sciences Corp',
    region: 'US',
  },
  {
    symbol: 'ATNI',
    name: 'ATN International Inc',
    region: 'US',
  },
  {
    symbol: 'ATNM',
    name: 'Actinium Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ATNX',
    name: 'Athenex Inc',
    region: 'US',
  },
  {
    symbol: 'ATO',
    name: 'Atmos Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'ATOM',
    name: 'Atomera Inc',
    region: 'US',
  },
  {
    symbol: 'ATOS',
    name: 'Atossa Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ATR',
    name: 'Aptargroup Inc.',
    region: 'US',
  },
  {
    symbol: 'ATRA',
    name: 'Atara Biotherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ATRC',
    name: 'Atricure Inc',
    region: 'US',
  },
  {
    symbol: 'ATRI',
    name: 'Atrion Corp.',
    region: 'US',
  },
  {
    symbol: 'ATRO',
    name: 'Astronics Corp.',
    region: 'US',
  },
  {
    symbol: 'ATRS',
    name: 'Antares Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'ATSG',
    name: 'Air Transport Services Group Inc',
    region: 'US',
  },
  {
    symbol: 'ATSPT',
    name: 'Archimedes Tech SPAC Partners Co - Subunit (1 Ord & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'ATSPU',
    name: 'Archimedes Tech SPAC Partners Co - Units (1 Subunit & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'ATTO',
    name: 'Atento S.A.',
    region: 'US',
  },
  {
    symbol: 'ATUS',
    name: 'Altice USA Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ATVC',
    name: 'Tribe Capital Growth Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'ATVCU',
    name: 'Tribe Capital Growth Corp I - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'ATVI',
    name: 'Activision Blizzard Inc',
    region: 'US',
  },
  {
    symbol: 'ATXI',
    name: 'Avenue Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ATXS',
    name: 'Astria Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ATY',
    name: 'AcuityAds Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AU',
    name: 'AngloGold Ashanti Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'AUB',
    name: 'Atlantic Union Bankshares Corp',
    region: 'US',
  },
  {
    symbol: 'AUBN',
    name: 'Auburn National Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'AUD',
    name: 'Audacy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AUDC',
    name: 'Audiocodes',
    region: 'US',
  },
  {
    symbol: 'AUGX',
    name: 'Augmedix Inc',
    region: 'US',
  },
  {
    symbol: 'AUGZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (August) ETF',
    region: 'US',
  },
  {
    symbol: 'AUID',
    name: 'Ipsidy Inc',
    region: 'US',
  },
  {
    symbol: 'AULDX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AULGX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AULNX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class G Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AULRX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AULYX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AUMN',
    name: 'Golden Minerals Co',
    region: 'US',
  },
  {
    symbol: 'AUPH',
    name: 'Aurinia Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AUR',
    name: 'Aurora Innovation Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AURA',
    name: 'Aura Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'AURC',
    name: 'Aurora Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AURCU',
    name: 'Aurora Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'AUS',
    name: 'Austerlitz Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'AUSF',
    name: 'Global X Funds - Global X Adaptive U.S. Factor ETF',
    region: 'US',
  },
  {
    symbol: 'AUTL',
    name: 'Autolus Therapeutics plc - ADR',
    region: 'US',
  },
  {
    symbol: 'AUTO',
    name: 'AutoWeb Inc',
    region: 'US',
  },
  {
    symbol: 'AUUD',
    name: 'Auddia Inc',
    region: 'US',
  },
  {
    symbol: 'AUVI',
    name: 'Applied UV Inc',
    region: 'US',
  },
  {
    symbol: 'AUY',
    name: 'Yamana Gold Inc.',
    region: 'US',
  },
  {
    symbol: 'AVA',
    name: 'Avista Corp.',
    region: 'US',
  },
  {
    symbol: 'AVAC',
    name: 'Avalon Acquisition Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AVACU',
    name: 'Avalon Acquisition Inc - Units (1 Ord Share Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'AVAH',
    name: 'Aveanna Healthcare Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AVAL',
    name: 'Grupo Aval Acciones y Valores S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'AVAN',
    name: 'Avanti Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AVAV',
    name: 'AeroVironment Inc.',
    region: 'US',
  },
  {
    symbol: 'AVCO',
    name: 'Avalon GloboCare Corp',
    region: 'US',
  },
  {
    symbol: 'AVCT',
    name: 'American Virtual Cloud Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'AVD',
    name: 'American Vanguard Corp.',
    region: 'US',
  },
  {
    symbol: 'AVDE',
    name: 'American Century ETF Trust - Avantis International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVDG',
    name: 'New Age Alpha Trust - AVDR US LargeCap ESG ETF',
    region: 'US',
  },
  {
    symbol: 'AVDL',
    name: 'Avadel Pharmaceuticals plc - ADR',
    region: 'US',
  },
  {
    symbol: 'AVDR',
    name: 'New Age Alpha Trust - AVDR US LargeCap Leading ETF',
    region: 'US',
  },
  {
    symbol: 'AVDV',
    name: 'American Century ETF Trust - Avantis International Small Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'AVDX',
    name: 'AvidXchange Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AVEM',
    name: 'American Century ETF Trust - Avantis Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVEO',
    name: 'AVEO Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AVES',
    name: 'American Century ETF Trust - Avantis Emerging Markets Value ETF',
    region: 'US',
  },
  {
    symbol: 'AVGO',
    name: 'Broadcom Inc',
    region: 'US',
  },
  {
    symbol: 'AVGR',
    name: 'Avinger Inc',
    region: 'US',
  },
  {
    symbol: 'AVHI',
    name: 'Achari Ventures Holdings Corp I',
    region: 'US',
  },
  {
    symbol: 'AVHIU',
    name: 'Achari Ventures Holdings Corp I - Units (1 Ord Share & 1 War)',
    region: 'US',
  },
  {
    symbol: 'AVID',
    name: 'Avid Technology, Inc.',
    region: 'US',
  },
  {
    symbol: 'AVIG',
    name: 'American Century ETF Trust - Avantis Core Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'AVIR',
    name: 'Atea Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AVIV',
    name: 'American Century ETF Trust - Avantis International Large Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'AVLR',
    name: 'Avalara Inc',
    region: 'US',
  },
  {
    symbol: 'AVLV',
    name: 'American Century ETF Trust - Avantis U.S. Large Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'AVMU',
    name: 'American Century ETF Trust - Avantis Core Municipal Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'AVNS',
    name: 'Avanos Medical Inc',
    region: 'US',
  },
  {
    symbol: 'AVNT',
    name: 'Avient Corp',
    region: 'US',
  },
  {
    symbol: 'AVNW',
    name: 'Aviat Networks Inc',
    region: 'US',
  },
  {
    symbol: 'AVO',
    name: 'Mission Produce Inc',
    region: 'US',
  },
  {
    symbol: 'AVPT',
    name: 'AvePoint Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AVRO',
    name: 'AvroBio Inc',
    region: 'US',
  },
  {
    symbol: 'AVSC',
    name: 'American Century ETF Trust - Avantis U.S Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVSD',
    name: 'American Century ETF Trust - Avantis Responsible International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVSE',
    name: 'American Century ETF Trust - Avantis Responsible Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVSF',
    name: 'American Century ETF Trust - Avantis Short-Term Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'AVSU',
    name: 'American Century ETF Trust - Avantis Responsible U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVT',
    name: 'Avnet Inc.',
    region: 'US',
  },
  {
    symbol: 'AVTE',
    name: 'Aerovate Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AVTR',
    name: 'Avantor Inc.',
    region: 'US',
  },
  {
    symbol: 'AVTX',
    name: 'Avalo Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AVUS',
    name: 'American Century ETF Trust - Avantis U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'AVUV',
    name: 'American Century ETF Trust - Avantis U.S. Small Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'AVXL',
    name: 'Anavex Life Sciences Corporation',
    region: 'US',
  },
  {
    symbol: 'AVY',
    name: 'Avery Dennison Corp.',
    region: 'US',
  },
  {
    symbol: 'AVYA',
    name: 'Avaya Holdings Corp.',
    region: 'US',
  },
  {
    symbol: 'AWAY',
    name: 'ETF Managers Trust - ETFMG Travel Tech ETF',
    region: 'US',
  },
  {
    symbol: 'AWEIX',
    name: "Advisors' Inner Circle Fund: CIBC Atlas Disciplined Equity Fund; Institutional Class Shares",
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'AWH',
    name: 'Aspira Women`s Health Inc',
    region: 'US',
  },
  {
    symbol: 'AWI',
    name: 'Armstrong World Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'AWK',
    name: 'American Water Works Co. Inc.',
    region: 'US',
  },
  {
    symbol: 'AWR',
    name: 'American States Water Co.',
    region: 'US',
  },
  {
    symbol: 'AWRE',
    name: 'Aware Inc.',
    region: 'US',
  },
  {
    symbol: 'AWTM',
    name: 'Tidal ETF Trust - National Investment Services Ultra-Short Duration Enhanced Income ETF',
    region: 'US',
  },
  {
    symbol: 'AWX',
    name: 'Avalon Holdings Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'AWYX',
    name: 'ETF Managers Trust - ETFMG 2X Daily Travel Tech ETF',
    region: 'US',
  },
  {
    symbol: 'AX',
    name: 'Axos Financial Inc.',
    region: 'US',
  },
  {
    symbol: 'AXAC',
    name: 'AXIOS Sustainable Growth Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'AXAHF',
    name: 'Axa',
    region: 'US',
  },
  {
    symbol: 'AXAHY',
    name: 'Axa - ADR',
    region: 'US',
  },
  {
    symbol: 'AXDX',
    name: 'Accelerate Diagnostics Inc',
    region: 'US',
  },
  {
    symbol: 'AXGN',
    name: 'Axogen Inc.',
    region: 'US',
  },
  {
    symbol: 'AXH',
    name: 'Industrial Human Capital Inc',
    region: 'US',
  },
  {
    symbol: 'AXL',
    name: 'American Axle & Manufacturing Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'AXLA',
    name: 'Axcella Health Inc',
    region: 'US',
  },
  {
    symbol: 'AXNX',
    name: 'Axonics Inc',
    region: 'US',
  },
  {
    symbol: 'AXON',
    name: 'Axon Enterprise Inc',
    region: 'US',
  },
  {
    symbol: 'AXP',
    name: 'American Express Co.',
    region: 'US',
  },
  {
    symbol: 'AXR',
    name: 'AMREP Corp.',
    region: 'US',
  },
  {
    symbol: 'AXS',
    name: 'Axis Capital Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'AXSM',
    name: 'Axsome Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'AXTA',
    name: 'Axalta Coating Systems Ltd',
    region: 'US',
  },
  {
    symbol: 'AXTI',
    name: 'AXT Inc',
    region: 'US',
  },
  {
    symbol: 'AXU',
    name: 'Alexco Resource Corp',
    region: 'US',
  },
  {
    symbol: 'AY',
    name: 'Atlantica Sustainable Infrastructure Plc',
    region: 'US',
  },
  {
    symbol: 'AYI',
    name: 'Acuity Brands, Inc.',
    region: 'US',
  },
  {
    symbol: 'AYLA',
    name: 'Ayala Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'AYRO',
    name: 'AYRO Inc',
    region: 'US',
  },
  {
    symbol: 'AYTU',
    name: 'Aytu BioPharma Inc',
    region: 'US',
  },
  {
    symbol: 'AYX',
    name: 'Alteryx Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AZ',
    name: 'A2Z Smart Technologies Corp.',
    region: 'US',
  },
  {
    symbol: 'AZAA',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer10 Apr ETF',
    region: 'US',
  },
  {
    symbol: 'AZAJ',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer10 Jan ETF',
    region: 'US',
  },
  {
    symbol: 'AZAL',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer10 Jul ETF',
    region: 'US',
  },
  {
    symbol: 'AZAO',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer10 Oct ETF',
    region: 'US',
  },
  {
    symbol: 'AZBA',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer20 Apr ETF',
    region: 'US',
  },
  {
    symbol: 'AZBJ',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer20 Jan ETF',
    region: 'US',
  },
  {
    symbol: 'AZBL',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer20 Jul ETF',
    region: 'US',
  },
  {
    symbol: 'AZBO',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap Buffer20 Oct ETF',
    region: 'US',
  },
  {
    symbol: 'AZEK',
    name: 'AZEK Company Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AZN',
    name: 'Astrazeneca plc - ADR',
    region: 'US',
  },
  {
    symbol: 'AZO',
    name: 'Autozone Inc.',
    region: 'US',
  },
  {
    symbol: 'AZPN',
    name: 'Aspen Technology Inc.',
    region: 'US',
  },
  {
    symbol: 'AZRE',
    name: 'Azure Power Global Ltd',
    region: 'US',
  },
  {
    symbol: 'AZTA',
    name: 'Azenta Inc',
    region: 'US',
  },
  {
    symbol: 'AZUL',
    name: 'Azul S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'AZYO',
    name: 'Aziyo Biologics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'AZZ',
    name: 'AZZ Inc',
    region: 'US',
  },
  {
    symbol: 'B',
    name: 'Barnes Group Inc.',
    region: 'US',
  },
  {
    symbol: 'BA',
    name: 'Boeing Co.',
    region: 'US',
  },
  {
    symbol: 'BAB',
    name: 'Invesco Capital Management LLC - Invesco Taxable Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BABA',
    name: 'Alibaba Group Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BAC',
    name: 'Bank Of America Corp.',
    region: 'US',
  },
  {
    symbol: 'BACA',
    name: 'Berenson Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'BAD',
    name: 'Listed Funds Trust - B.A.D. ETF',
    region: 'US',
  },
  {
    symbol: 'BAEIX',
    name: 'Sterling Capital Funds: Sterling Capital Equity Income Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BAERX',
    name: 'Sterling Capital Funds: Sterling Capital Equity Income Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BAFGX',
    name: 'Brown Advisory Funds: Brown Advisory Growth Equity Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BAFN',
    name: 'BayFirst Financial Corp',
    region: 'US',
  },
  {
    symbol: 'BAFWX',
    name: 'Brown Advisory Funds: Brown Advisory Sustainable Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BAGAX',
    name: 'Brown Advisory Funds: Brown Advisory Growth Equity Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BAH',
    name: 'Booz Allen Hamilton Holding Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BAK',
    name: 'Braskem S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BALT',
    name: 'Innovator ETFs Trust - Innovator Defined Wealth Shield ETF',
    region: 'US',
  },
  {
    symbol: 'BALY',
    name: 'Ballys Corporation',
    region: 'US',
  },
  {
    symbol: 'BAMR',
    name: 'Brookfield Asset Management Reinsurance Partners Ltd (Exchangeable Shares, Sub Voting) - Class A',
    region: 'US',
  },
  {
    symbol: 'BANC',
    name: 'Banc of California Inc',
    region: 'US',
  },
  {
    symbol: 'BAND',
    name: 'Bandwidth Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BANF',
    name: 'Bancfirst Corp.',
    region: 'US',
  },
  {
    symbol: 'BANR',
    name: 'Banner Corp.',
    region: 'US',
  },
  {
    symbol: 'BAOS',
    name: 'Baosheng Media Group Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'BAP',
    name: 'Credicorp Ltd',
    region: 'US',
  },
  {
    symbol: 'BAPR',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'BAR',
    name: 'GraniteShares Gold Trust - GraniteShares Gold Shares',
    region: 'US',
  },
  {
    symbol: 'BARK',
    name: 'BARK Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BASE',
    name: 'Couchbase Inc',
    region: 'US',
  },
  {
    symbol: 'BASFY',
    name: 'Basf SE - ADR',
    region: 'US',
  },
  {
    symbol: 'BATL',
    name: 'Battalion Oil Corp (New)',
    region: 'US',
  },
  {
    symbol: 'BATRA',
    name: 'Liberty Media Corp. (Tracking Stock - Braves) Series A',
    region: 'US',
  },
  {
    symbol: 'BATRK',
    name: 'Liberty Media Corp. (Tracking Stock - Braves) Series C',
    region: 'US',
  },
  {
    symbol: 'BATT',
    name: 'Amplify ETF Trust - Amplify Lithium & Battery Technology ETF',
    region: 'US',
  },
  {
    symbol: 'BAUG',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - August',
    region: 'US',
  },
  {
    symbol: 'BAWAX',
    name: 'Brown Advisory Funds: Brown Advisory Sustainable Growth Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BAX',
    name: 'Baxter International Inc.',
    region: 'US',
  },
  {
    symbol: 'BB',
    name: 'BlackBerry Ltd',
    region: 'US',
  },
  {
    symbol: 'BBAI',
    name: 'BigBear.ai Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BBAR',
    name: 'BBVA Argentina - ADR',
    region: 'US',
  },
  {
    symbol: 'BBAX',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders Developed Asia Pacific ex-Japan ETF',
    region: 'US',
  },
  {
    symbol: 'BBBY',
    name: 'Bed, Bath & Beyond Inc.',
    region: 'US',
  },
  {
    symbol: 'BBC',
    name: 'ETFis Series Trust I - Virtus LifeSci Biotech Clinical Trials ETF',
    region: 'US',
  },
  {
    symbol: 'BBCA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders Canada ETF',
    region: 'US',
  },
  {
    symbol: 'BBCP',
    name: 'Concrete Pumping Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BBD',
    name: 'Banco Bradesco S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BBDO',
    name: 'Banco Bradesco S.A. - ADR (Ord)',
    region: 'US',
  },
  {
    symbol: 'BBEU',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan BetaBuilders Europe ETF',
    region: 'US',
  },
  {
    symbol: 'BBGI',
    name: 'Beasley Broadcast Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BBH',
    name: 'VanEck ETF Trust - VanEck Biotech ETF',
    region: 'US',
  },
  {
    symbol: 'BBI',
    name: 'Brickell Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'BBIG',
    name: 'Vinco Ventures Inc',
    region: 'US',
  },
  {
    symbol: 'BBIN',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BBIO',
    name: 'BridgeBio Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'BBJP',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan BetaBuilders Japan ETF',
    region: 'US',
  },
  {
    symbol: 'BBLG',
    name: 'Bone Biologics Corp',
    region: 'US',
  },
  {
    symbol: 'BBLN',
    name: 'Babylon Holdings Limited - Class A',
    region: 'US',
  },
  {
    symbol: 'BBMC',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders U.S. Mid Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BBP',
    name: 'ETFis Series Trust I - Virtus LifeSci Biotech Products ETF',
    region: 'US',
  },
  {
    symbol: 'BBQ',
    name: 'BBQ Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BBRE',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan BetaBuilders MSCI U.S. REIT ETF',
    region: 'US',
  },
  {
    symbol: 'BBSA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders 1-5 Year U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BBSC',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders U.S. Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BBSEY',
    name: 'BB Seguridade Participacoes S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BBSI',
    name: 'Barrett Business Services Inc.',
    region: 'US',
  },
  {
    symbol: 'BBU',
    name: 'Brookfield Business Partners L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'BBUS',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan BetaBuilders U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BBVA',
    name: 'Banco Bilbao Vizcaya Argentaria. - ADR',
    region: 'US',
  },
  {
    symbol: 'BBW',
    name: 'Build A Bear Workshop Inc',
    region: 'US',
  },
  {
    symbol: 'BBWI',
    name: 'Bath & Body Works Inc',
    region: 'US',
  },
  {
    symbol: 'BBY',
    name: 'Best Buy Co. Inc.',
    region: 'US',
  },
  {
    symbol: 'BC',
    name: 'Brunswick Corp.',
    region: 'US',
  },
  {
    symbol: 'BCAB',
    name: 'BioAtla Inc',
    region: 'US',
  },
  {
    symbol: 'BCAC',
    name: 'Brookline Capital Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BCACU',
    name: 'Brookline Capital Acquisition Corp - Units (1 Ord Share & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BCBP',
    name: 'BCB Bancorp Inc (NJ)',
    region: 'US',
  },
  {
    symbol: 'BCC',
    name: 'Boise Cascade Co',
    region: 'US',
  },
  {
    symbol: 'BCD',
    name: 'abrdn ETF - abrdn Bloomberg All Comm Longer Dt Strategy K-1 Free ETF',
    region: 'US',
  },
  {
    symbol: 'BCDA',
    name: 'BioCardia Inc.',
    region: 'US',
  },
  {
    symbol: 'BCE',
    name: 'BCE Inc',
    region: 'US',
  },
  {
    symbol: 'BCEGX',
    name: 'Sterling Capital Funds: Sterling Capital Equity Income Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BCEL',
    name: 'Atreca Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BCH',
    name: 'Banco de Chile - ADR',
    region: 'US',
  },
  {
    symbol: 'BCI',
    name: 'abrdn ETF - abrdn Bloomberg All Commodity Strategy K-1 Free ETF',
    region: 'US',
  },
  {
    symbol: 'BCIM',
    name: 'abrdn ETF - abrdn Bloomberg Industrial Metals Strategy K-1 Free ETF',
    region: 'US',
  },
  {
    symbol: 'BCLI',
    name: 'Brainstorm Cell Therapeutics, Inc.',
    region: 'US',
  },
  {
    symbol: 'BCML',
    name: 'BayCom Corp',
    region: 'US',
  },
  {
    symbol: 'BCO',
    name: 'Brink`s Co.',
    region: 'US',
  },
  {
    symbol: 'BCOR',
    name: 'Blucora Inc',
    region: 'US',
  },
  {
    symbol: 'BCOV',
    name: 'Brightcove Inc',
    region: 'US',
  },
  {
    symbol: 'BCOW',
    name: '1895 Bancorp of Wisconsin Inc.',
    region: 'US',
  },
  {
    symbol: 'BCPC',
    name: 'Balchem Corp.',
    region: 'US',
  },
  {
    symbol: 'BCRX',
    name: 'Biocryst Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'BCS',
    name: 'Barclays plc - ADR',
    region: 'US',
  },
  {
    symbol: 'BCSA',
    name: 'Blockchain Coinvestors Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'BCSAU',
    name: 'Blockchain Coinvestors Acquisition Corp I - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BCTX',
    name: 'BriaCell Therapeutics Corp',
    region: 'US',
  },
  {
    symbol: 'BCYC',
    name: 'Bicycle Therapeutics Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'BDC',
    name: 'Belden Inc',
    region: 'US',
  },
  {
    symbol: 'BDEC',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'BDL',
    name: 'Flanigan`s Enterprises, Inc.',
    region: 'US',
  },
  {
    symbol: 'BDORY',
    name: 'Banco Do Brasil S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BDR',
    name: 'Blonder Tongue Laboratories Inc.',
    region: 'US',
  },
  {
    symbol: 'BDRY',
    name: 'ETF Managers Group Commodity Trust I - Breakwave Dry Bulk Shipping ETF',
    region: 'US',
  },
  {
    symbol: 'BDSAX',
    name: 'BlackRock Funds: BlackRock Advantage Small Cap Core Fund; Investor A Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BDSCX',
    name: 'BlackRock Funds: BlackRock Advantage Small Cap Core Fund; Investor C Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BDSIX',
    name: 'BlackRock Funds: BlackRock Advantage Small Cap Core Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BDSKX',
    name: 'BlackRock Funds: BlackRock Advantage Small Cap Core Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BDSX',
    name: 'Biodesix Inc',
    region: 'US',
  },
  {
    symbol: 'BDTX',
    name: 'Black Diamond Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BDX',
    name: 'Becton, Dickinson And Co.',
    region: 'US',
  },
  {
    symbol: 'BE',
    name: 'Bloom Energy Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BEAM',
    name: 'Beam Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BEAT',
    name: 'HeartBeam Inc',
    region: 'US',
  },
  {
    symbol: 'BECN',
    name: 'Beacon Roofing Supply Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BECO',
    name: 'BlackRock ETF Trust - BlackRock Future Climate and Sustainable Economy ETF',
    region: 'US',
  },
  {
    symbol: 'BEDU',
    name: 'Bright Scholar Education Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BEDZ',
    name: 'Advisorshares Trust - AdvisorShares Hotel ETF',
    region: 'US',
  },
  {
    symbol: 'BEEM',
    name: 'Beam Global',
    region: 'US',
  },
  {
    symbol: 'BEGIX',
    name: 'Sterling Capital Funds: Sterling Capital Equity Income Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BEKE',
    name: 'KE Holdings Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'BELFA',
    name: 'Bel Fuse Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'BELFB',
    name: 'Bel Fuse Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'BENE',
    name: 'Benessere Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BENEU',
    name: 'Benessere Capital Acquisition Corp - Units (1 Ord Class A , 1 Rts & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'BEP',
    name: 'Brookfield Renewable Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'BEPC',
    name: 'Brookfield Renewable Corporation - Class A (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'BERY',
    name: 'Berry Global Group Inc',
    region: 'US',
  },
  {
    symbol: 'BEST',
    name: 'BEST Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'BETZ',
    name: 'Listed Funds Trust - Roundhill Sports Betting & iGaming ETF',
    region: 'US',
  },
  {
    symbol: 'BF.A',
    name: 'Brown-Forman Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'BF.B',
    name: 'Brown-Forman Corp. - Class B',
    region: 'US',
  },
  {
    symbol: 'BFAC',
    name: 'Battery Future Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BFAM',
    name: 'Bright Horizons Family Solutions, Inc.',
    region: 'US',
  },
  {
    symbol: 'BFC',
    name: 'Bank First Corp',
    region: 'US',
  },
  {
    symbol: 'BFEB',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - February',
    region: 'US',
  },
  {
    symbol: 'BFFAF',
    name: 'Basf SE',
    region: 'US',
  },
  {
    symbol: 'BFGBX',
    name: 'BlackRock Capital Appreciation Fund, Inc; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BFH',
    name: 'Bread Financial Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BFI',
    name: 'BurgerFi International Inc',
    region: 'US',
  },
  {
    symbol: 'BFIN',
    name: 'Bankfinancial Corp',
    region: 'US',
  },
  {
    symbol: 'BFIT',
    name: 'Global X Funds - Global X Health & Wellness ETF',
    region: 'US',
  },
  {
    symbol: 'BFIX',
    name: 'Build Funds Trust - Build Bond Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'BFLY',
    name: 'Butterfly Network Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BFOR',
    name: 'ALPS Advisors Inc - Barron`s 400 ETF',
    region: 'US',
  },
  {
    symbol: 'BFRI',
    name: 'Biofrontera Inc',
    region: 'US',
  },
  {
    symbol: 'BFST',
    name: 'Business First Bancshares Inc.',
    region: 'US',
  },
  {
    symbol: 'BFTR',
    name: 'BlackRock ETF Trust - BlackRock Future Innovators ETF',
    region: 'US',
  },
  {
    symbol: 'BG',
    name: 'Bunge Ltd.',
    region: 'US',
  },
  {
    symbol: 'BGCP',
    name: 'BGC Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BGFV',
    name: 'Big 5 Sporting Goods Corp',
    region: 'US',
  },
  {
    symbol: 'BGI',
    name: 'Birks Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BGLD',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Gold Strategy Quarterly Buffer ETF',
    region: 'US',
  },
  {
    symbol: 'BGNE',
    name: 'BeiGene Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BGRN',
    name: 'iShares Trust - iShares USD Green Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BGRY',
    name: 'Berkshire Grey Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BGS',
    name: 'B&G Foods, Inc',
    region: 'US',
  },
  {
    symbol: 'BGSAX',
    name: 'BlackRock Funds: BlackRock Technology Opportunities Fund; Investor A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BGSCX',
    name: 'BlackRock Funds: BlackRock Technology Opportunities Fund; Investor C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BGSF',
    name: 'BGSF Inc',
    region: 'US',
  },
  {
    symbol: 'BGSIX',
    name: 'BlackRock Funds: BlackRock Technology Opportunities Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BGSRX',
    name: 'BlackRock Funds: BlackRock Technology Opportunities Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BGSX',
    name: 'Build Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BH',
    name: 'Biglari Holdings Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'BH.A',
    name: 'Biglari Holdings Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'BHAC',
    name: 'Crixus BH3 Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'BHACU',
    name: 'Crixus BH3 Acquisition Co - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BHAT',
    name: 'Blue Hat Interactive Entertainment Technology',
    region: 'US',
  },
  {
    symbol: 'BHB',
    name: 'Bar Harbor Bankshares Inc',
    region: 'US',
  },
  {
    symbol: 'BHC',
    name: 'Bausch Health Companies Inc',
    region: 'US',
  },
  {
    symbol: 'BHE',
    name: 'Benchmark Electronics Inc.',
    region: 'US',
  },
  {
    symbol: 'BHF',
    name: 'Brighthouse Financial Inc',
    region: 'US',
  },
  {
    symbol: 'BHG',
    name: 'Bright Health Group Inc',
    region: 'US',
  },
  {
    symbol: 'BHIL',
    name: 'Benson Hill Inc',
    region: 'US',
  },
  {
    symbol: 'BHLB',
    name: 'Berkshire Hills Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'BHP',
    name: 'BHP Group Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'BHSE',
    name: 'Bull Horn Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'BHSEU',
    name: 'Bull Horn Holdings Corp - Unit (1 Ordinary share & 1 War)',
    region: 'US',
  },
  {
    symbol: 'BHSRX',
    name: 'BlackRock Funds: BlackRock Health Sciences Opportunities Portfolio; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BHVN',
    name: 'Biohaven Pharmaceutical Holding Company Ltd',
    region: 'US',
  },
  {
    symbol: 'BIAGX',
    name: 'Brown Advisory Funds: Brown Advisory Growth Equity Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BIAWX',
    name: 'Brown Advisory Funds: Brown Advisory Sustainable Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BIB',
    name: 'ProShares Trust - ProShares Ultra Nasdaq Biotechnology',
    region: 'US',
  },
  {
    symbol: 'BIBL',
    name: 'Northern Lights Fund Trust IV - Inspire 100 ESG ETF',
    region: 'US',
  },
  {
    symbol: 'BICK',
    name: 'First Trust Exchange-Traded Fund III - First Trust BICK Index Fund',
    region: 'US',
  },
  {
    symbol: 'BIDS',
    name: 'Amplify ETF Trust - Amplify Digital & Online Trading ETF',
    region: 'US',
  },
  {
    symbol: 'BIDU',
    name: 'Baidu Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'BIG',
    name: 'Big Lots Inc',
    region: 'US',
  },
  {
    symbol: 'BIGC',
    name: 'BigCommerce Holdings Inc Series 1',
    region: 'US',
  },
  {
    symbol: 'BIGY',
    name: 'ETF Series Solutions Trust - Defiance Next Gen Big Data ETF',
    region: 'US',
  },
  {
    symbol: 'BIIB',
    name: 'Biogen Inc',
    region: 'US',
  },
  {
    symbol: 'BIL',
    name: 'SPDR Series Trust - SPDR Bloomberg 1-3 Month T-Bill ETF',
    region: 'US',
  },
  {
    symbol: 'BILI',
    name: 'Bilibili Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'BILL',
    name: 'Bill.com Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BILS',
    name: 'SPDR Series Trust - SPDR Bloomberg 3-12 Month T-Bill ETF',
    region: 'US',
  },
  {
    symbol: 'BIMI',
    name: 'BIMI International Medical Inc',
    region: 'US',
  },
  {
    symbol: 'BIO',
    name: 'Bio-Rad Laboratories Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'BIO.B',
    name: 'Bio-Rad Laboratories Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'BIOC',
    name: 'Biocept Inc',
    region: 'US',
  },
  {
    symbol: 'BIOL',
    name: 'Biolase Inc',
    region: 'US',
  },
  {
    symbol: 'BIOS',
    name: 'BioPlus Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BIOSU',
    name: 'BioPlus Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BIOT',
    name: 'Biotech Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'BIOTU',
    name: 'Biotech Acquisition Co - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BIOX',
    name: 'Bioceres Crop Solutions Corp',
    region: 'US',
  },
  {
    symbol: 'BIP',
    name: 'Brookfield Infrastructure Partners L.P - Unit',
    region: 'US',
  },
  {
    symbol: 'BIPC',
    name: 'Brookfield Infrastructure Corp - Class A (Subordinate Share)',
    region: 'US',
  },
  {
    symbol: 'BIRD',
    name: 'Allbirds Inc Class A',
    region: 'US',
  },
  {
    symbol: 'BIS',
    name: 'ProShares Trust - ProShares UltraShort Nasdaq Biotechnology',
    region: 'US',
  },
  {
    symbol: 'BITE',
    name: 'Bite Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BITF',
    name: 'Bitfarms Ltd.',
    region: 'US',
  },
  {
    symbol: 'BITO',
    name: 'ProShares Trust - ProShares Bitcoin Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'BITQ',
    name: 'Exchange Traded Concepts Trust - Bitwise Crypto Industry Innovators ETF',
    region: 'US',
  },
  {
    symbol: 'BITS',
    name: 'Global X Funds - Global X Blockchain & Bitcoin Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'BIV',
    name: 'Vanguard Group, Inc. - Vanguard Intermediate-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BIVI',
    name: 'BioVie Inc',
    region: 'US',
  },
  {
    symbol: 'BIZD',
    name: 'VanEck ETF Trust - VanEck BDC Income ETF',
    region: 'US',
  },
  {
    symbol: 'BJ',
    name: 'BJ`s Wholesale Club Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BJAN',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'BJDX',
    name: 'Bluejay Diagnostics Inc',
    region: 'US',
  },
  {
    symbol: 'BJK',
    name: 'VanEck ETF Trust - VanEck Gaming ETF',
    region: 'US',
  },
  {
    symbol: 'BJRI',
    name: 'BJ`s Restaurant Inc.',
    region: 'US',
  },
  {
    symbol: 'BJUL',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'BJUN',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'BK',
    name: 'Bank Of New York Mellon Corp',
    region: 'US',
  },
  {
    symbol: 'BKAG',
    name: 'BNY Mellon ETF Trust - BNY Mellon Core Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BKCH',
    name: 'Global X Funds - Global X Blockchain ETF',
    region: 'US',
  },
  {
    symbol: 'BKCI',
    name: 'BNY Mellon ETF Trust - BNY Mellon Concentrated International ETF',
    region: 'US',
  },
  {
    symbol: 'BKD',
    name: 'Brookdale Senior Living Inc',
    region: 'US',
  },
  {
    symbol: 'BKE',
    name: 'Buckle, Inc.',
    region: 'US',
  },
  {
    symbol: 'BKEM',
    name: 'BNY Mellon ETF Trust - BNY Mellon Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKEP',
    name: 'Blueknight Energy Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'BKES',
    name: 'BNY Mellon ETF Trust - BNY Mellon Sustainable Global Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'BKF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI BIC ETF',
    region: 'US',
  },
  {
    symbol: 'BKH',
    name: 'Black Hills Corporation',
    region: 'US',
  },
  {
    symbol: 'BKHY',
    name: 'BNY Mellon ETF Trust - BNY Mellon High Yield Beta ETF',
    region: 'US',
  },
  {
    symbol: 'BKI',
    name: 'Black Knight Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BKIE',
    name: 'BNY Mellon ETF Trust - BNY Mellon International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKIS',
    name: 'BNY Mellon ETF Trust - BNY Mellon Sustainable International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKKT',
    name: 'Bakkt Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BKLC',
    name: 'BNY Mellon ETF Trust - BNY Mellon US Large Cap Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKLN',
    name: 'Invesco Capital Management LLC - Invesco Senior Loan ETF',
    region: 'US',
  },
  {
    symbol: 'BKMC',
    name: 'BNY Mellon ETF Trust - BNY Mellon US Mid Cap Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKNG',
    name: 'Booking Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BKR',
    name: 'Baker Hughes Co - Class A',
    region: 'US',
  },
  {
    symbol: 'BKSB',
    name: 'BNY Mellon ETF Trust - BNY Mellon Short Duration Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BKSC',
    name: 'Bank Of South Carolina Corp.',
    region: 'US',
  },
  {
    symbol: 'BKSE',
    name: 'BNY Mellon ETF Trust - BNY Mellon US Small Cap Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKSY',
    name: 'BlackSky Technology Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BKTI',
    name: 'BK Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'BKU',
    name: 'BankUnited Inc',
    region: 'US',
  },
  {
    symbol: 'BKUI',
    name: 'BNY Mellon ETF Trust - BNY Mellon Ultra Short Income ETF',
    region: 'US',
  },
  {
    symbol: 'BKUS',
    name: 'BNY Mellon ETF Trust - BNY Mellon Sustainable US Equity ETF',
    region: 'US',
  },
  {
    symbol: 'BKYI',
    name: 'Bio-Key International Inc.',
    region: 'US',
  },
  {
    symbol: 'BL',
    name: 'BlackLine Inc',
    region: 'US',
  },
  {
    symbol: 'BLBD',
    name: 'Blue Bird Corp',
    region: 'US',
  },
  {
    symbol: 'BLBX',
    name: 'Blackboxstocks Inc',
    region: 'US',
  },
  {
    symbol: 'BLCM',
    name: 'Bellicum Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'BLCN',
    name: 'Siren ETF Trust - Siren Nasdaq NexGen Economy ETF',
    region: 'US',
  },
  {
    symbol: 'BLCT',
    name: 'BlueCity Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BLD',
    name: 'TopBuild Corp',
    region: 'US',
  },
  {
    symbol: 'BLDE',
    name: 'Blade Air Mobility Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BLDG',
    name: 'Cambria Investment Management LP - Cambria Global Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'BLDP',
    name: 'Ballard Power Systems Inc.',
    region: 'US',
  },
  {
    symbol: 'BLDR',
    name: 'Builders Firstsource Inc',
    region: 'US',
  },
  {
    symbol: 'BLES',
    name: 'Northern Lights Fund Trust IV - Inspire Global Hope ESG ETF',
    region: 'US',
  },
  {
    symbol: 'BLEU',
    name: 'bleuacacia ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'BLEUU',
    name: 'bleuacacia ltd - Units (1 Ord Share Class A &1Right & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BLFS',
    name: 'Biolife Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'BLFY',
    name: 'Blue Foundry Bancorp',
    region: 'US',
  },
  {
    symbol: 'BLHY',
    name: 'Virtus ETF Trust II - Virtus Newfleet High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BLI',
    name: 'Berkeley Lights Inc',
    region: 'US',
  },
  {
    symbol: 'BLIN',
    name: 'Bridgeline Digital Inc',
    region: 'US',
  },
  {
    symbol: 'BLKB',
    name: 'Blackbaud Inc',
    region: 'US',
  },
  {
    symbol: 'BLKC',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco Alerian Galaxy Blockchain Users and Decentralized Commerce ETF',
    region: 'US',
  },
  {
    symbol: 'BLL',
    name: 'Ball Corp.',
    region: 'US',
  },
  {
    symbol: 'BLMN',
    name: 'Bloomin Brands Inc',
    region: 'US',
  },
  {
    symbol: 'BLND',
    name: 'Blend Labs Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BLNG',
    name: 'Belong Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BLNGU',
    name: 'Belong Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BLNK',
    name: 'Blink Charging Co',
    region: 'US',
  },
  {
    symbol: 'BLOK',
    name: 'Amplify ETF Trust - Amplify Transformational Data Sharing ETF',
    region: 'US',
  },
  {
    symbol: 'BLPH',
    name: 'Bellerophon Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BLRX',
    name: 'Bioline Rx Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BLSA',
    name: 'BCLS Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BLTE',
    name: 'Belite Bio Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'BLTS',
    name: 'Bright Lights Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BLTSU',
    name: 'Bright Lights Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BLU',
    name: 'Bellus Health Inc',
    region: 'US',
  },
  {
    symbol: 'BLUA',
    name: 'BlueRiver Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BLUE',
    name: 'Bluebird bio Inc',
    region: 'US',
  },
  {
    symbol: 'BLV',
    name: 'Vanguard Group, Inc. - Vanguard Long-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BLX',
    name: 'Banco Latinoamericano De Comercio Exterior SA - Class E',
    region: 'US',
  },
  {
    symbol: 'BLZE',
    name: 'Backblaze Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BMA',
    name: 'Banco Macro S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BMAC',
    name: 'Black Mountain Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BMAQ',
    name: 'Blockchain Moon Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BMAQU',
    name: 'Blockchain Moon Acquisition Corp - Units (1 Ord , 1 War & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'BMAR',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'BMAY',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - May',
    region: 'US',
  },
  {
    symbol: 'BMBL',
    name: 'Bumble Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BMDIX',
    name: 'Baird Funds, Inc: Baird MidCap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BMDSX',
    name: 'Baird Funds, Inc: Baird MidCap Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BMEA',
    name: 'Biomea Fusion Inc',
    region: 'US',
  },
  {
    symbol: 'BMED',
    name: 'BlackRock ETF Trust - BlackRock Future Health ETF',
    region: 'US',
  },
  {
    symbol: 'BMGAX',
    name: 'BlackRock Funds: BlackRock Mid-Cap Growth Equity Portfolio; Investor A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BMGCX',
    name: 'BlackRock Funds: BlackRock Mid-Cap Growth Equity Portfolio; Investor C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BMGKX',
    name: 'BlackRock Funds: BlackRock Mid-Cap Growth Equity Portfolio; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BMI',
    name: 'Badger Meter Inc.',
    region: 'US',
  },
  {
    symbol: 'BMO',
    name: 'Bank of Montreal',
    region: 'US',
  },
  {
    symbol: 'BMRA',
    name: 'Biomerica Inc.',
    region: 'US',
  },
  {
    symbol: 'BMRC',
    name: 'Bank of Marin Bancorp',
    region: 'US',
  },
  {
    symbol: 'BMRN',
    name: 'Biomarin Pharmaceutical Inc. - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'BMRRX',
    name: 'BlackRock Funds: BlackRock Mid-Cap Growth Equity Portfolio; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BMTX',
    name: 'BM Technologies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BMY',
    name: 'Bristol-Myers Squibb Co.',
    region: 'US',
  },
  {
    symbol: 'BND',
    name: 'Vanguard Group, Inc. - Vanguard Total Bond Market ETF',
    region: 'US',
  },
  {
    symbol: 'BNDC',
    name: 'FlexShares Trust - FlexShares Core Select Bond Fund',
    region: 'US',
  },
  {
    symbol: 'BNDD',
    name: 'Krane Shares Trust - Quadratic Deflation ETF',
    region: 'US',
  },
  {
    symbol: 'BNDW',
    name: 'Vanguard Group, Inc. - Vanguard Total World Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BNDX',
    name: 'Vanguard Group, Inc. - Vanguard Total International Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BNE',
    name: 'ETF Series Solutions Trust - Blue Horizon BNE ETF',
    region: 'US',
  },
  {
    symbol: 'BNED',
    name: 'Barnes & Noble Education Inc',
    region: 'US',
  },
  {
    symbol: 'BNFT',
    name: 'Benefitfocus Inc',
    region: 'US',
  },
  {
    symbol: 'BNGE',
    name: 'First Trust Exchange-Traded Fund VI - First Trust S-Network Streaming and Gaming ETF',
    region: 'US',
  },
  {
    symbol: 'BNGO',
    name: 'Bionano Genomics Inc',
    region: 'US',
  },
  {
    symbol: 'BNIX',
    name: 'Bannix Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BNNR',
    name: 'Banner Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BNNRU',
    name: 'Banner Acquisition Corp - Units (1 Ord ShareClass A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BNO',
    name: 'United States Commodity Funds LLC - United States Brent Crude Oil Fund',
    region: 'US',
  },
  {
    symbol: 'BNOV',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - November',
    region: 'US',
  },
  {
    symbol: 'BNOX',
    name: 'Bionomics Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'BNPQF',
    name: 'BNP Paribas',
    region: 'US',
  },
  {
    symbol: 'BNPQY',
    name: 'BNP Paribas - ADR - Level I',
    region: 'US',
  },
  {
    symbol: 'BNR',
    name: 'Burning Rock Biotech Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BNS',
    name: 'Bank Of Nova Scotia',
    region: 'US',
  },
  {
    symbol: 'BNSO',
    name: 'Bonso Electronics International',
    region: 'US',
  },
  {
    symbol: 'BNTC',
    name: 'Benitec Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'BNTX',
    name: 'BioNTech SE - ADR',
    region: 'US',
  },
  {
    symbol: 'BOAC',
    name: 'Bluescape Opportunities Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BOAS',
    name: 'BOA Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BOAT',
    name: 'Tidal ETF Trust - SonicShares Global Shipping ETF',
    region: 'US',
  },
  {
    symbol: 'BOB',
    name: 'Alpha Architect Etf Trust - Merlyn.AI Best-of-Breed Core Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'BOC',
    name: 'Boston Omaha Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BOCT',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'BODY',
    name: 'Beachbody Company Inc (The) - Class A',
    region: 'US',
  },
  {
    symbol: 'BOH',
    name: 'Bank of Hawaii Corp.',
    region: 'US',
  },
  {
    symbol: 'BOIL',
    name: 'ProShares Trust - ProShares Ultra Bloomberg Natural Gas',
    region: 'US',
  },
  {
    symbol: 'BOKF',
    name: 'BOK Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'BOLSY',
    name: 'B3 S.A. - Brasil Bolsa Balcao - ADR',
    region: 'US',
  },
  {
    symbol: 'BOLT',
    name: 'Bolt Biotherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BON',
    name: 'Bon Natural Life Ltd',
    region: 'US',
  },
  {
    symbol: 'BOND',
    name: 'Pimco Exchange Traded Fund - PIMCO Active Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BOOM',
    name: 'DMC Global Inc',
    region: 'US',
  },
  {
    symbol: 'BOOT',
    name: 'Boot Barn Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BORR',
    name: 'Borr Drilling Ltd',
    region: 'US',
  },
  {
    symbol: 'BOSC',
    name: 'B.O.S. Better Online Solutions',
    region: 'US',
  },
  {
    symbol: 'BOSOX',
    name: 'Boston Trust Walden Funds: Boston Trust Walden Small Cap Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BOSS',
    name: 'Global X Funds - Global X Founder-Run Companies ETF',
    region: 'US',
  },
  {
    symbol: 'BOTJ',
    name: 'Bank of James Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'BOTZ',
    name: 'Global X Funds - Global X Funds Global X Robotics & Artificial Intelligence ETF',
    region: 'US',
  },
  {
    symbol: 'BOUT',
    name: 'Innovator ETFs Trust - Innovator IBD(R) Breakout Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'BOWL',
    name: 'Bowlero Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BOX',
    name: 'Box Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BOXD',
    name: 'Boxed Inc',
    region: 'US',
  },
  {
    symbol: 'BOXL',
    name: 'Boxlight Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'BP',
    name: 'BP plc - ADR',
    region: 'US',
  },
  {
    symbol: 'BPAC',
    name: 'Bullpen Parlay Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'BPACU',
    name: 'Bullpen Parlay Acquisition Co - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BPMC',
    name: 'Blueprint Medicines Corp',
    region: 'US',
  },
  {
    symbol: 'BPOP',
    name: 'Popular Inc.',
    region: 'US',
  },
  {
    symbol: 'BPRN',
    name: 'Bank of Princeton',
    region: 'US',
  },
  {
    symbol: 'BPTH',
    name: 'Bio-Path Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BPTS',
    name: 'Biophytis - ADR',
    region: 'US',
  },
  {
    symbol: 'BQ',
    name: 'Boqii Holding Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'BR',
    name: 'Broadridge Financial Solutions, Inc.',
    region: 'US',
  },
  {
    symbol: 'BRAG',
    name: 'Bragg Gaming Group Inc',
    region: 'US',
  },
  {
    symbol: 'BRBR',
    name: 'Bellring Brands Inc',
    region: 'US',
  },
  {
    symbol: 'BRBS',
    name: 'Blue Ridge Bankshares Inc (VA)',
    region: 'US',
  },
  {
    symbol: 'BRC',
    name: 'Brady Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'BRCC',
    name: 'BRC Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BRCN',
    name: 'Burcon Nutrascience Corp',
    region: 'US',
  },
  {
    symbol: 'BRD',
    name: 'Beard Energy Transition Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BRDS',
    name: 'Bird Global Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BREZ',
    name: 'Breeze Holdings Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BRF',
    name: 'VanEck ETF Trust - VanEck Brazil Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'BRFH',
    name: 'Barfresh Food Group Inc',
    region: 'US',
  },
  {
    symbol: 'BRFS',
    name: 'BRF S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BRID',
    name: 'Bridgford Foods Corporation',
    region: 'US',
  },
  {
    symbol: 'BRIV',
    name: 'B Riley Principal 250 Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BRIVU',
    name: 'B Riley Principal 250 Merger Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'BRK.A',
    name: 'Berkshire Hathaway Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'BRK.B',
    name: 'Berkshire Hathaway Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'BRKH',
    name: 'BurTech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BRKHU',
    name: 'BurTech Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'BRKL',
    name: 'Brookline Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'BRKR',
    name: 'Bruker Corp',
    region: 'US',
  },
  {
    symbol: 'BRLI',
    name: 'Brilliant Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BRLIU',
    name: 'Brilliant Acquisition Corp - Unit (1 Ordinary share 1 War and 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'BRLT',
    name: 'Brilliant Earth Group Inc Class A',
    region: 'US',
  },
  {
    symbol: 'BRMK',
    name: 'Broadmark Realty Capital Inc',
    region: 'US',
  },
  {
    symbol: 'BRN',
    name: 'Barnwell Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'BROG',
    name: 'Brooge Energy Limited',
    region: 'US',
  },
  {
    symbol: 'BROS',
    name: 'Dutch Bros Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BRPM',
    name: 'B. Riley Principal 150 Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BRPMU',
    name: 'B. Riley Principal 150 Merger Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'BRQS',
    name: 'Borqs Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'BRRLY',
    name: 'Barry Callebaut AG - ADR',
    region: 'US',
  },
  {
    symbol: 'BRTX',
    name: 'BioRestorative Therapies Inc',
    region: 'US',
  },
  {
    symbol: 'BRY',
    name: 'Berry Corp',
    region: 'US',
  },
  {
    symbol: 'BRZE',
    name: 'Braze Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BRZU',
    name: 'Direxion Shares ETF Trust - Direxion Daily MSCI Brazil Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'BSAC',
    name: 'Banco Santander Chile SA - ADR',
    region: 'US',
  },
  {
    symbol: 'BSAQ',
    name: 'Black Spade Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'BSBE',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2022 USD Emerging Markets Debt ETF',
    region: 'US',
  },
  {
    symbol: 'BSBK',
    name: 'Bogota Financial Corp',
    region: 'US',
  },
  {
    symbol: 'BSBR',
    name: 'Banco Santander (Brasil) S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BSCE',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2023 USD Emerging Markets Debt ETF',
    region: 'US',
  },
  {
    symbol: 'BSCM',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2022 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCN',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2023 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCO',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2024 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCP',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2025 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCQ',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2026 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCR',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2027 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCS',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2028 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCT',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2029 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCU',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2030 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSCV',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2031 Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSDE',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2024 USD Emerging Markets Debt ETF',
    region: 'US',
  },
  {
    symbol: 'BSEA',
    name: 'ETF Managers Trust - ETFMG Breakwave Sea Decarbonization Tech ETF',
    region: 'US',
  },
  {
    symbol: 'BSEP',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Buffer ETF - September',
    region: 'US',
  },
  {
    symbol: 'BSET',
    name: 'Bassett Furniture Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'BSFC',
    name: 'Blue Star Foods Corp',
    region: 'US',
  },
  {
    symbol: 'BSGA',
    name: 'Blue Safari Group Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BSGAU',
    name: 'Blue Safari Group Acquisition Corp - Units (1 Ord Class A & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'BSGM',
    name: 'Biosig Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'BSJM',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2022 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJN',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2023 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJO',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2024 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJP',
    name: 'Invesco Capital Management LLC - Invesco BulletShares 2025 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJQ',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2026 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJR',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2027 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJS',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2028 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSJT',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2029 High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSKY',
    name: 'Big Sky Growth Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BSKYU',
    name: 'Big Sky Growth Partners Inc - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'BSM',
    name: 'Black Stone Minerals L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'BSMM',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2022 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMN',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2023 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMO',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2024 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMP',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2025 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMQ',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2026 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMR',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2027 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMS',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2028 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMT',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2029 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMU',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2030 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMV',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco BulletShares 2031 Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSMX',
    name: 'Banco Santander Mexico S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BSQR',
    name: 'BSquare Corp',
    region: 'US',
  },
  {
    symbol: 'BSRR',
    name: 'Sierra Bancorp',
    region: 'US',
  },
  {
    symbol: 'BSTP',
    name: 'Innovator ETFs Trust - Innovator Buffer Step-Up Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'BSTSX',
    name: 'BlackRock Funds: BlackRock Technology Opportunities Fund; Service Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BSV',
    name: 'Vanguard Group, Inc. - Vanguard Short-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BSVN',
    name: 'Bank7 Corp',
    region: 'US',
  },
  {
    symbol: 'BSX',
    name: 'Boston Scientific Corp.',
    region: 'US',
  },
  {
    symbol: 'BSY',
    name: 'Bentley Systems Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'BTAI',
    name: 'BioXcel Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BTAL',
    name: 'AGF Investments Trust - AGFiQ U.S. Market Neutral Anti-Beta Fund',
    region: 'US',
  },
  {
    symbol: 'BTB',
    name: 'Bit Brother Limited - Class A',
    region: 'US',
  },
  {
    symbol: 'BTBD',
    name: 'BT Brands Inc',
    region: 'US',
  },
  {
    symbol: 'BTBT',
    name: 'Bit Digital Inc',
    region: 'US',
  },
  {
    symbol: 'BTCM',
    name: 'BIT Mining Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BTCR',
    name: 'Volt ETF Trust - Volt Crypto Industry Revolution and Tech ETF',
    region: 'US',
  },
  {
    symbol: 'BTCS',
    name: 'BTCS Inc',
    region: 'US',
  },
  {
    symbol: 'BTCY',
    name: 'Biotricity Inc',
    region: 'US',
  },
  {
    symbol: 'BTEC',
    name: 'Principal Exchange-Traded Funds - Principal Healthcare Innovators ETF',
    region: 'US',
  },
  {
    symbol: 'BTEK',
    name: 'BlackRock ETF Trust - BlackRock Future Tech ETF',
    region: 'US',
  },
  {
    symbol: 'BTF',
    name: 'Valkyrie ETF Trust II - Valkyrie Bitcoin Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'BTG',
    name: 'B2gold Corp',
    region: 'US',
  },
  {
    symbol: 'BTHM',
    name: 'BlackRock ETF Trust - BlackRock Future U.S. Themes ETF',
    region: 'US',
  },
  {
    symbol: 'BTI',
    name: 'British American Tobacco Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'BTN',
    name: 'Ballantyne Strong Inc',
    region: 'US',
  },
  {
    symbol: 'BTOG',
    name: 'China Xiangtai Food Co Ltd',
    region: 'US',
  },
  {
    symbol: 'BTRS',
    name: 'BTRS Holdings Inc - Class 1',
    region: 'US',
  },
  {
    symbol: 'BTRU',
    name: 'Tru Shrimp Companies Inc (The)',
    region: 'US',
  },
  {
    symbol: 'BTTR',
    name: 'Better Choice Company Inc',
    region: 'US',
  },
  {
    symbol: 'BTTX',
    name: 'Better Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BTU',
    name: 'Peabody Energy Corp. New',
    region: 'US',
  },
  {
    symbol: 'BTWN',
    name: 'Bridgetown Holdings Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'BTWNU',
    name: 'Bridgetown Holdings Ltd - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'BTX',
    name: 'Brooklyn ImmunoTherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'BUD',
    name: 'Anheuser-Busch In Bev SA/NV - ADR',
    region: 'US',
  },
  {
    symbol: 'BUDFF',
    name: 'Anheuser-Busch In Bev SA/NV',
    region: 'US',
  },
  {
    symbol: 'BUFB',
    name: 'Innovator ETFs Trust - Innovator Laddered Allocation Buffer ETF',
    region: 'US',
  },
  {
    symbol: 'BUFD',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Fund of Deep Buffer ETFs',
    region: 'US',
  },
  {
    symbol: 'BUFF',
    name: 'Innovator ETFs Trust II - Innovator Laddered Allocation Power Buffer ETF',
    region: 'US',
  },
  {
    symbol: 'BUFG',
    name: 'First Trust Portfolios L.P - FT Cboe Vest Buffered Allocation Growth ETF',
    region: 'US',
  },
  {
    symbol: 'BUFR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Fund of Buffer ETFs',
    region: 'US',
  },
  {
    symbol: 'BUFT',
    name: 'First Trust Portfolios L.P - FT Cboe Vest Buffered Allocation Defensive ETF',
    region: 'US',
  },
  {
    symbol: 'BUG',
    name: 'Global X Funds - Global X Cybersecurity ETF',
    region: 'US',
  },
  {
    symbol: 'BUL',
    name: 'Pacer Funds Trust - Pacer US Cash Cows Growth ETF',
    region: 'US',
  },
  {
    symbol: 'BULD',
    name: 'Pacer Funds Trust - Pacer BlueStar Engineering the Future ETF',
    region: 'US',
  },
  {
    symbol: 'BURL',
    name: 'Burlington Stores Inc',
    region: 'US',
  },
  {
    symbol: 'BUSE',
    name: 'First Busey Corp.',
    region: 'US',
  },
  {
    symbol: 'BUYZ',
    name: 'Franklin Templeton ETF Trust - Franklin Disruptive Commerce ETF',
    region: 'US',
  },
  {
    symbol: 'BUZZ',
    name: 'VanEck ETF Trust - VanEck Social Sentiment ETF',
    region: 'US',
  },
  {
    symbol: 'BV',
    name: 'BrightView Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'BVALX',
    name: 'Brown Advisory Funds: Brown Advisory - Beutel Goodman Large-Cap Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'BVH',
    name: 'Bluegreen Vacations Holding Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'BVN',
    name: 'Compania de Minas Buenaventura S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'BVS',
    name: 'Bioventus Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BVXV',
    name: 'Biondvax Pharmaceuticals Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BW',
    name: 'Babcock & Wilcox Enterprises Inc',
    region: 'US',
  },
  {
    symbol: 'BWA',
    name: 'BorgWarner Inc',
    region: 'US',
  },
  {
    symbol: 'BWAC',
    name: 'Better World Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'BWACU',
    name: 'Better World Acquisition Corp - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'BWAY',
    name: 'Brainsway Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BWB',
    name: 'Bridgewater Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'BWC',
    name: 'Blue Whale Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'BWCAU',
    name: 'Blue Whale Acquisition Corp I - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'BWEN',
    name: 'Broadwind Inc',
    region: 'US',
  },
  {
    symbol: 'BWFG',
    name: 'Bankwell Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'BWMN',
    name: 'Bowman Consulting Group Ltd',
    region: 'US',
  },
  {
    symbol: 'BWMX',
    name: 'Betterware de Mexico S.A.B. de C.V.',
    region: 'US',
  },
  {
    symbol: 'BWX',
    name: 'SPDR Series Trust - SPDR Bloomberg International Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BWXT',
    name: 'BWX Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'BWZ',
    name: 'SPDR Series Trust - SPDR Bloomberg Short Term International Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BXC',
    name: 'Bluelinx Hldgs Inc',
    region: 'US',
  },
  {
    symbol: 'BXRX',
    name: 'Baudax Bio Inc',
    region: 'US',
  },
  {
    symbol: 'BY',
    name: 'Byline Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'BYD',
    name: 'Boyd Gaming Corp.',
    region: 'US',
  },
  {
    symbol: 'BYFC',
    name: 'Broadway Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'BYLD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Yield Optimized Bond ETF',
    region: 'US',
  },
  {
    symbol: 'BYN',
    name: 'Banyan Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BYND',
    name: 'Beyond Meat Inc',
    region: 'US',
  },
  {
    symbol: 'BYNO',
    name: 'byNordic Acquisition Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'BYNOU',
    name: 'byNordic Acquisition Corporation - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BYRN',
    name: 'Byrna Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'BYSI',
    name: 'BeyondSpring Inc',
    region: 'US',
  },
  {
    symbol: 'BYTE',
    name: 'Listed Funds Trust - Roundhill IO Digital Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'BYTS',
    name: 'BYTE Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'BYTSU',
    name: 'BYTE Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'BZ',
    name: 'Kanzhun Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'BZFD',
    name: 'BuzzFeed Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'BZH',
    name: 'Beazer Homes USA Inc.',
    region: 'US',
  },
  {
    symbol: 'BZQ',
    name: 'ProShares Trust - ProShares UltraShort MSCI Brazil Capped ETF',
    region: 'US',
  },
  {
    symbol: 'BZUN',
    name: 'Baozun Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'C',
    name: 'Citigroup Inc',
    region: 'US',
  },
  {
    symbol: 'CAAP',
    name: 'Corporacion America Airports S.A.',
    region: 'US',
  },
  {
    symbol: 'CAAS',
    name: 'China Automotive Systems Inc',
    region: 'US',
  },
  {
    symbol: 'CABA',
    name: 'Cabaletta Bio Inc',
    region: 'US',
  },
  {
    symbol: 'CABDX',
    name: 'AB Relative Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CABO',
    name: 'Cable One Inc',
    region: 'US',
  },
  {
    symbol: 'CAC',
    name: 'Camden National Corp.',
    region: 'US',
  },
  {
    symbol: 'CACG',
    name: 'Legg Mason ETF Investment Trust - ClearBridge All Cap Growth ESG ETF',
    region: 'US',
  },
  {
    symbol: 'CACI',
    name: 'Caci International Inc. - Registered Shares - Class A',
    region: 'US',
  },
  {
    symbol: 'CADE',
    name: 'Cadence Bank',
    region: 'US',
  },
  {
    symbol: 'CADL',
    name: 'Candel Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CAE',
    name: 'Cae Inc.',
    region: 'US',
  },
  {
    symbol: 'CAG',
    name: 'Conagra Brands Inc',
    region: 'US',
  },
  {
    symbol: 'CAH',
    name: 'Cardinal Health, Inc.',
    region: 'US',
  },
  {
    symbol: 'CAJ',
    name: 'Canon Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'CAKE',
    name: 'Cheesecake Factory Inc.',
    region: 'US',
  },
  {
    symbol: 'CAL',
    name: 'Caleres Inc',
    region: 'US',
  },
  {
    symbol: 'CALA',
    name: 'Calithera Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CALB',
    name: 'California Bancorp',
    region: 'US',
  },
  {
    symbol: 'CALF',
    name: 'Pacer Funds Trust - Pacer US Small Cap Cash Cows 100 ETF',
    region: 'US',
  },
  {
    symbol: 'CALM',
    name: 'Cal-Maine Foods, Inc.',
    region: 'US',
  },
  {
    symbol: 'CALT',
    name: 'Calliditas Therapeutics AB - ADR',
    region: 'US',
  },
  {
    symbol: 'CALX',
    name: 'Calix Inc',
    region: 'US',
  },
  {
    symbol: 'CAMP',
    name: 'Calamp Corp.',
    region: 'US',
  },
  {
    symbol: 'CAMT',
    name: 'Camtek Ltd',
    region: 'US',
  },
  {
    symbol: 'CAN',
    name: 'Canaan Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'CANB',
    name: 'Can B Corp',
    region: 'US',
  },
  {
    symbol: 'CANE',
    name: 'Teucrium Trading, LLC - Teucrium Sugar Fund',
    region: 'US',
  },
  {
    symbol: 'CANF',
    name: 'Can-Fite Biopharma Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'CANO',
    name: 'Cano Health Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CAPL',
    name: 'CrossAmerica Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'CAPR',
    name: 'Capricor Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CAR',
    name: 'Avis Budget Group Inc',
    region: 'US',
  },
  {
    symbol: 'CARA',
    name: 'Cara Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CARE',
    name: 'Carter Bankshares Inc',
    region: 'US',
  },
  {
    symbol: 'CARG',
    name: 'CarGurus Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CARR',
    name: 'Carrier Global Corp',
    region: 'US',
  },
  {
    symbol: 'CARS',
    name: 'Cars.com',
    region: 'US',
  },
  {
    symbol: 'CARV',
    name: 'Carver Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'CARZ',
    name: 'First Trust Exchange-Traded Fund II - First Trust S-Network Future Vehicles & Technology ETF',
    region: 'US',
  },
  {
    symbol: 'CAS',
    name: 'Cascade Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CASA',
    name: 'Casa Systems Inc',
    region: 'US',
  },
  {
    symbol: 'CASH',
    name: 'Meta Financial Group Inc.',
    region: 'US',
  },
  {
    symbol: 'CASI',
    name: 'CASI Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CASS',
    name: 'Cass Information Systems Inc',
    region: 'US',
  },
  {
    symbol: 'CASY',
    name: 'Casey`s General Stores, Inc.',
    region: 'US',
  },
  {
    symbol: 'CAT',
    name: 'Caterpillar Inc.',
    region: 'US',
  },
  {
    symbol: 'CATC',
    name: 'Cambridge Bancorp',
    region: 'US',
  },
  {
    symbol: 'CATH',
    name: 'Global X Funds - Global X S&P 500 Catholic Values ETF',
    region: 'US',
  },
  {
    symbol: 'CATO',
    name: 'Cato Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'CATY',
    name: 'Cathay General Bancorp',
    region: 'US',
  },
  {
    symbol: 'CB',
    name: 'Chubb Limited',
    region: 'US',
  },
  {
    symbol: 'CBAN',
    name: 'Colony Bankcorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'CBAT',
    name: 'CBAK Energy Technology Inc',
    region: 'US',
  },
  {
    symbol: 'CBAY',
    name: 'Cymabay Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CBBIX',
    name: 'AB Relative Value Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CBBKX',
    name: 'AB Relative Value Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CBBRX',
    name: 'AB Relative Value Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CBBYX',
    name: 'AB Relative Value Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CBBZX',
    name: 'AB Relative Value Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CBD',
    name: 'Cia Brasileira De Distr-pao De Acucar - ADR (Ordinary Shares)',
    region: 'US',
  },
  {
    symbol: 'CBFV',
    name: 'CB Financial Services Inc',
    region: 'US',
  },
  {
    symbol: 'CBIO',
    name: 'Catalyst Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CBLS',
    name: 'Listed Funds Trust - Changebridge Capital Long/Short Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CBNK',
    name: 'Capital Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'CBON',
    name: 'VanEck ETF Trust - VanEck China Bond ETF',
    region: 'US',
  },
  {
    symbol: 'CBRE',
    name: 'CBRE Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CBRG',
    name: 'Chain Bridge I - Class A',
    region: 'US',
  },
  {
    symbol: 'CBRGU',
    name: 'Chain Bridge I - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CBRL',
    name: 'Cracker Barrel Old Country Store Inc',
    region: 'US',
  },
  {
    symbol: 'CBSE',
    name: 'Listed Funds Trust - Changebridge Capital Sustainable Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CBSH',
    name: 'Commerce Bancshares, Inc.',
    region: 'US',
  },
  {
    symbol: 'CBT',
    name: 'Cabot Corp.',
    region: 'US',
  },
  {
    symbol: 'CBTX',
    name: 'CBTX Inc',
    region: 'US',
  },
  {
    symbol: 'CBU',
    name: 'Community Bank System, Inc.',
    region: 'US',
  },
  {
    symbol: 'CBZ',
    name: 'Cbiz Inc',
    region: 'US',
  },
  {
    symbol: 'CC',
    name: 'Chemours Company',
    region: 'US',
  },
  {
    symbol: 'CCAI',
    name: 'Cascadia Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CCAIU',
    name: 'Cascadia Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CCALX',
    name: 'Conestoga Funds: Conestoga Small Cap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CCASX',
    name: 'Conestoga Funds: Conestoga Small Cap Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CCB',
    name: 'Coastal Financial Corp',
    region: 'US',
  },
  {
    symbol: 'CCBG',
    name: 'Capital City Bank Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'CCCC',
    name: 'C4 Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CCCS',
    name: 'CCC Intelligent Solutions Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CCEL',
    name: 'Cryo-Cell International, Inc.',
    region: 'US',
  },
  {
    symbol: 'CCEP',
    name: 'Coca-Cola Europacific Partners Plc',
    region: 'US',
  },
  {
    symbol: 'CCF',
    name: 'Chase Corp.',
    region: 'US',
  },
  {
    symbol: 'CCHRX',
    name: 'Columbia Funds Series Trust II: Columbia Seligman Global Technology Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CCI',
    name: 'Crown Castle International Corp',
    region: 'US',
  },
  {
    symbol: 'CCJ',
    name: 'Cameco Corp.',
    region: 'US',
  },
  {
    symbol: 'CCK',
    name: 'Crown Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'CCL',
    name: 'Carnival Corp. (Paired Stock)',
    region: 'US',
  },
  {
    symbol: 'CCLP',
    name: 'CSI Compressco LP - Units',
    region: 'US',
  },
  {
    symbol: 'CCM',
    name: 'Concord Medical Services - ADR',
    region: 'US',
  },
  {
    symbol: 'CCMP',
    name: 'CMC Materials Inc',
    region: 'US',
  },
  {
    symbol: 'CCNC',
    name: 'Code Chain New Continent Ltd',
    region: 'US',
  },
  {
    symbol: 'CCNE',
    name: 'CNB Financial Corp (PA)',
    region: 'US',
  },
  {
    symbol: 'CCO',
    name: 'Clear Channel Outdoor Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'CCOI',
    name: 'Cogent Communications Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CCOR',
    name: 'Listed Funds Trust - Core Alternative ETF',
    region: 'US',
  },
  {
    symbol: 'CCOYX',
    name: 'Columbia Funds Series Trust II: Columbia Seligman Communications & Information Fund; Institutional 3 Class Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CCRD',
    name: 'CoreCard Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'CCRIX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CCRN',
    name: 'Cross Country Healthcares, Inc. - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'CCRV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Commodity Curve Carry Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'CCS',
    name: 'Century Communities Inc',
    region: 'US',
  },
  {
    symbol: 'CCTRX',
    name: 'Columbia Funds Series Trust: Columbia Small Cap Value Fund II; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CCTS',
    name: 'Cactus Acquisition Corp 1 Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'CCTSU',
    name: 'Cactus Acquisition Corp 1 Ltd - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CCU',
    name: 'Compania Cervecerias Unidas S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'CCV',
    name: 'Churchill Capital Corp V - Class A',
    region: 'US',
  },
  {
    symbol: 'CCVI',
    name: 'Churchill Capital Corp VI - Class A',
    region: 'US',
  },
  {
    symbol: 'CCXI',
    name: 'ChemoCentryx Inc',
    region: 'US',
  },
  {
    symbol: 'CD',
    name: 'Chindata Group Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'CDAK',
    name: 'Codiak Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CDAQ',
    name: 'Compass Digital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CDAQU',
    name: 'Compass Digital Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CDAY',
    name: 'Ceridian HCM Holding Inc.',
    region: 'US',
  },
  {
    symbol: 'CDC',
    name: 'Victory Portfolios II - VictoryShares US EQ Income Enhanced Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CDDRX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CDDYX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CDE',
    name: 'Coeur Mining Inc',
    region: 'US',
  },
  {
    symbol: 'CDEV',
    name: 'Centennial Resource Development Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'CDIRX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CDK',
    name: 'CDK Global Inc',
    region: 'US',
  },
  {
    symbol: 'CDL',
    name: 'Victory Portfolios II - VictoryShares US Large Cap High Div Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CDLX',
    name: 'Cardlytics Inc',
    region: 'US',
  },
  {
    symbol: 'CDMO',
    name: 'Avid Bioservices Inc',
    region: 'US',
  },
  {
    symbol: 'CDNA',
    name: 'Caredx Inc',
    region: 'US',
  },
  {
    symbol: 'CDNS',
    name: 'Cadence Design Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'CDRE',
    name: 'Cadre Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CDRO',
    name: 'Codere Online Luxembourg S.A',
    region: 'US',
  },
  {
    symbol: 'CDTX',
    name: 'Cidara Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CDW',
    name: 'CDW Corp',
    region: 'US',
  },
  {
    symbol: 'CDX',
    name: 'Simplify Exchange Traded Funds - Simplify High Yield PLUS Credit Hedge ETF',
    region: 'US',
  },
  {
    symbol: 'CDXC',
    name: 'Chromadex Corp',
    region: 'US',
  },
  {
    symbol: 'CDXS',
    name: 'Codexis Inc.',
    region: 'US',
  },
  {
    symbol: 'CDZI',
    name: 'Cadiz Inc.',
    region: 'US',
  },
  {
    symbol: 'CE',
    name: 'Celanese Corp - Series A',
    region: 'US',
  },
  {
    symbol: 'CEA',
    name: 'China Eastern Airlines Corporation Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'CEAD',
    name: 'CEA Industries Inc',
    region: 'US',
  },
  {
    symbol: 'CECE',
    name: 'Ceco Environmental Corp.',
    region: 'US',
  },
  {
    symbol: 'CEFA',
    name: 'Global X Funds - Global X S&P Catholic Values Developed ex-U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'CEFS',
    name: 'Exchange Traded Concepts Trust - Saba Closed-End Funds ETF',
    region: 'US',
  },
  {
    symbol: 'CEG',
    name: 'Constellation Energy Corporation',
    region: 'US',
  },
  {
    symbol: 'CEI',
    name: 'Camber Energy Inc',
    region: 'US',
  },
  {
    symbol: 'CEIX',
    name: 'Consol Energy Inc',
    region: 'US',
  },
  {
    symbol: 'CELC',
    name: 'Celcuity Inc',
    region: 'US',
  },
  {
    symbol: 'CELH',
    name: 'Celsius Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CELP',
    name: 'Cypress Environmental Partners L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'CELU',
    name: 'Celularity Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CEMB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares J.P. Morgan EM Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'CEMI',
    name: 'Chembio Diagnostics Inc.',
    region: 'US',
  },
  {
    symbol: 'CENN',
    name: 'Cenntro Electric Group Limited',
    region: 'US',
  },
  {
    symbol: 'CENQ',
    name: 'CENAQ Energy Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CENQU',
    name: 'CENAQ Energy Corp - Units (1 Ord Class A & 0.75 War)',
    region: 'US',
  },
  {
    symbol: 'CENT',
    name: 'Central Garden & Pet Co.',
    region: 'US',
  },
  {
    symbol: 'CENTA',
    name: 'Central Garden & Pet Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'CENX',
    name: 'Century Aluminum Co.',
    region: 'US',
  },
  {
    symbol: 'CEPU',
    name: 'Central Puerto - ADR',
    region: 'US',
  },
  {
    symbol: 'CEQP',
    name: 'Crestwood Equity Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'CERE',
    name: 'Cerevel Therapeutics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CERN',
    name: 'Cerner Corp.',
    region: 'US',
  },
  {
    symbol: 'CERS',
    name: 'Cerus Corp.',
    region: 'US',
  },
  {
    symbol: 'CERT',
    name: 'Certara Inc',
    region: 'US',
  },
  {
    symbol: 'CETX',
    name: 'Cemtrex Inc.',
    region: 'US',
  },
  {
    symbol: 'CEVA',
    name: 'Ceva Inc.',
    region: 'US',
  },
  {
    symbol: 'CEW',
    name: 'WisdomTree Trust - WisdomTree Emerging Currency Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'CEY',
    name: 'Victory Portfolios II - VictoryShares Emerging Market High Div Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CF',
    name: 'CF Industries Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CFA',
    name: 'Victory Portfolios II - VictoryShares US 500 Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CFB',
    name: 'Crossfirst Bankshares Inc',
    region: 'US',
  },
  {
    symbol: 'CFBK',
    name: 'CF Bankshares Inc',
    region: 'US',
  },
  {
    symbol: 'CFCV',
    name: 'Activeshares ETF Trust - ClearBridge Focus Value ESG ETF',
    region: 'US',
  },
  {
    symbol: 'CFDRX',
    name: 'Columbia Funds Series Trust: Columbia Select Mid Cap Value Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CFFE',
    name: 'CF Acquisition Corp VIII - Class A',
    region: 'US',
  },
  {
    symbol: 'CFFEU',
    name: 'CF Acquisition Corp VIII - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'CFFI',
    name: 'C & F Financial Corp',
    region: 'US',
  },
  {
    symbol: 'CFFN',
    name: 'Capitol Federal Financial',
    region: 'US',
  },
  {
    symbol: 'CFFS',
    name: 'CF Acquisition Corp VII - Class A',
    region: 'US',
  },
  {
    symbol: 'CFFSU',
    name: 'CF Acquisition Corp VII - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CFG',
    name: 'Citizens Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'CFIV',
    name: 'CF Acquisition Corp. IV - Class A',
    region: 'US',
  },
  {
    symbol: 'CFIVU',
    name: 'CF Acquisition Corp. IV - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CFLT',
    name: 'Confluent Inc Class A',
    region: 'US',
  },
  {
    symbol: 'CFMS',
    name: 'Conformis Inc.',
    region: 'US',
  },
  {
    symbol: 'CFO',
    name: 'Victory Portfolios II - VictoryShares US 500 Enhanced Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CFR',
    name: 'Cullen Frost Bankers Inc.',
    region: 'US',
  },
  {
    symbol: 'CFRX',
    name: 'ContraFect Corp',
    region: 'US',
  },
  {
    symbol: 'CFSB',
    name: 'CFSB Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'CFVI',
    name: 'CF Acquisition Corp VI - Class A',
    region: 'US',
  },
  {
    symbol: 'CFVIU',
    name: 'CF Acquisition Corp VI - Units (1 Ord Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'CGA',
    name: 'China Green Agriculture Inc',
    region: 'US',
  },
  {
    symbol: 'CGAU',
    name: 'Centerra Gold Inc.',
    region: 'US',
  },
  {
    symbol: 'CGC',
    name: 'Canopy Growth Corporation',
    region: 'US',
  },
  {
    symbol: 'CGCP',
    name: 'Capital Group Companies Inc - Capital Group Core Plus Income ETF',
    region: 'US',
  },
  {
    symbol: 'CGDV',
    name: 'Capital Group Companies Inc - Capital Group Dividend Value ETF',
    region: 'US',
  },
  {
    symbol: 'CGEM',
    name: 'Cullinan Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'CGEN',
    name: 'Compugen Ltd',
    region: 'US',
  },
  {
    symbol: 'CGFYX',
    name: 'Columbia Funds Series Trust I: Columbia Large Cap Growth Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CGGO',
    name: 'Capital Group Companies Inc - Capital Group Global Growth Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CGGR',
    name: 'Capital Group Companies Inc - Capital Group Growth ETF',
    region: 'US',
  },
  {
    symbol: 'CGNT',
    name: 'Cognyte Software Ltd',
    region: 'US',
  },
  {
    symbol: 'CGNX',
    name: 'Cognex Corp.',
    region: 'US',
  },
  {
    symbol: 'CGOAX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CGOCX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CGRN',
    name: 'Capstone Green Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'CGTUX',
    name: 'Columbia Funds Series Trust I: Columbia Global Technology Growth Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CGTX',
    name: 'Cognition Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CGTYX',
    name: 'Columbia Funds Series Trust II: Columbia Seligman Global Technology Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CGUS',
    name: 'Capital Group Companies Inc - Capital Group Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CGW',
    name: 'Invesco Capital Management LLC - Invesco S&P Global Water Index ETF',
    region: 'US',
  },
  {
    symbol: 'CGXU',
    name: 'Capital Group Companies Inc - Capital Group International Focus Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CHAA',
    name: 'Catcha Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CHAD',
    name: 'Direxion Shares ETF Trust - Direxion Daily CSI 300 China A Share Bear 1X Shares',
    region: 'US',
  },
  {
    symbol: 'CHAU',
    name: 'Direxion Shares ETF Trust - Direxion Daily CSI 300 China A Share Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'CHB',
    name: 'Global X Funds - Global X China Biotech Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'CHCI',
    name: 'Comstock Holding Co. Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CHCO',
    name: 'City Holding Co.',
    region: 'US',
  },
  {
    symbol: 'CHD',
    name: 'Church & Dwight Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'CHDN',
    name: 'Churchill Downs, Inc.',
    region: 'US',
  },
  {
    symbol: 'CHE',
    name: 'Chemed Corp.',
    region: 'US',
  },
  {
    symbol: 'CHEF',
    name: 'Chefs` Warehouse Inc',
    region: 'US',
  },
  {
    symbol: 'CHEK',
    name: 'Check-Cap Ltd',
    region: 'US',
  },
  {
    symbol: 'CHGG',
    name: 'Chegg Inc',
    region: 'US',
  },
  {
    symbol: 'CHGX',
    name: 'Investment Managers Series Trust II - AXS Change Finance ESG ETF',
    region: 'US',
  },
  {
    symbol: 'CHH',
    name: 'Choice Hotels International, Inc.',
    region: 'US',
  },
  {
    symbol: 'CHHRX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CHIC',
    name: 'Global X Funds - Global X Funds Global X MSCI China Communication Services ETF',
    region: 'US',
  },
  {
    symbol: 'CHIE',
    name: 'Global X Funds - Global X MSCI China Energy ETF',
    region: 'US',
  },
  {
    symbol: 'CHIH',
    name: 'Global X Funds - Global X MSCI China Health Care ETF',
    region: 'US',
  },
  {
    symbol: 'CHII',
    name: 'Global X Funds - Global X MSCI China Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'CHIK',
    name: 'Global X Funds - Global X MSCI China Information Technology ETF',
    region: 'US',
  },
  {
    symbol: 'CHIM',
    name: 'Global X Funds - Global X MSCI China Materials ETF',
    region: 'US',
  },
  {
    symbol: 'CHIQ',
    name: 'Global X Funds - Global X MSCI China Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'CHIR',
    name: 'Global X Funds - Global X MSCI China Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'CHIS',
    name: 'Global X Funds - Global X MSCI China Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'CHIU',
    name: 'Global X Funds - Global X MSCI China Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'CHIX',
    name: 'Global X Funds - Global X MSCI China Financials ETF',
    region: 'US',
  },
  {
    symbol: 'CHK',
    name: 'Chesapeake Energy Corp. - New',
    region: 'US',
  },
  {
    symbol: 'CHKP',
    name: 'Check Point Software Technolgies',
    region: 'US',
  },
  {
    symbol: 'CHMG',
    name: 'Chemung Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'CHNA',
    name: 'ETF Series Solutions Trust - Loncar China BioPharma ETF',
    region: 'US',
  },
  {
    symbol: 'CHNG',
    name: 'Change Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'CHNGU',
    name: 'Change Healthcare Inc - Unit',
    region: 'US',
  },
  {
    symbol: 'CHNR',
    name: 'China Natural Resources Inc',
    region: 'US',
  },
  {
    symbol: 'CHPT',
    name: 'ChargePoint Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CHRA',
    name: 'Charah Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'CHRS',
    name: 'Coherus Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CHRW',
    name: 'C.H. Robinson Worldwide, Inc.',
    region: 'US',
  },
  {
    symbol: 'CHS',
    name: 'Chico`s Fas, Inc.',
    region: 'US',
  },
  {
    symbol: 'CHT',
    name: 'Chunghwa Telecom - ADR',
    region: 'US',
  },
  {
    symbol: 'CHTR',
    name: 'Charter Communications Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'CHUY',
    name: 'Chuy`s Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CHWA',
    name: 'CHW Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'CHWAU',
    name: 'CHW Acquisition Corp - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'CHWY',
    name: 'Chewy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CHX',
    name: 'ChampionX Corporation',
    region: 'US',
  },
  {
    symbol: 'CI',
    name: 'Cigna Corp.',
    region: 'US',
  },
  {
    symbol: 'CIA',
    name: 'Citizens, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'CIAN',
    name: 'Cian Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'CIB',
    name: 'Bancolombia S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'CIBR',
    name: 'First Trust Exchange-Traded Fund III - First Trust NASDAQ Cybersecurity ETF',
    region: 'US',
  },
  {
    symbol: 'CID',
    name: 'Victory Portfolios II - VictoryShares International High Div Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CIDM',
    name: 'Cinedigm Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CIEN',
    name: 'CIENA Corp.',
    region: 'US',
  },
  {
    symbol: 'CIFR',
    name: 'Cipher Mining Inc',
    region: 'US',
  },
  {
    symbol: 'CIG',
    name: 'Cia Energetica De Minas Gerais - ADR (Preference Shares)',
    region: 'US',
  },
  {
    symbol: 'CIG.C',
    name: 'Cia Energetica De Minas Gerais - ADR - Level II',
    region: 'US',
  },
  {
    symbol: 'CIGI',
    name: 'Colliers International Group Inc',
    region: 'US',
  },
  {
    symbol: 'CIH',
    name: 'China Index Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'CIIG',
    name: 'CIIG Capital Partners II Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CIIGU',
    name: 'CIIG Capital Partners II Inc - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CIL',
    name: 'Victory Portfolios II - VictoryShares International Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CINC',
    name: 'CinCor Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'CINF',
    name: 'Cincinnati Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'CING',
    name: 'Cingulate Inc',
    region: 'US',
  },
  {
    symbol: 'CIR',
    name: 'Circor International Inc',
    region: 'US',
  },
  {
    symbol: 'CISGX',
    name: 'Touchstone Funds Group Trust: Touchstone Sands Capital Select Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CISO',
    name: 'Cerberus Cyber Sentinel Corp',
    region: 'US',
  },
  {
    symbol: 'CITE',
    name: 'Cartica Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CITEU',
    name: 'Cartica Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CIVB',
    name: 'Civista Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'CIVI',
    name: 'Civitas Resources Inc New',
    region: 'US',
  },
  {
    symbol: 'CIX',
    name: 'Compx International, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'CIZ',
    name: 'Victory Portfolios II - VictoryShares Developed Enhanced Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CIZN',
    name: 'Citizens Holding Co',
    region: 'US',
  },
  {
    symbol: 'CKPT',
    name: 'Checkpoint Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CL',
    name: 'Colgate-Palmolive Co.',
    region: 'US',
  },
  {
    symbol: 'CLAA',
    name: 'Colonnade Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'CLAQ',
    name: 'CleanTech Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'CLAQU',
    name: 'CleanTech Acquisition Corp - Units (1 Ord Share, 1 Rts & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CLAR',
    name: 'Clarus Corp',
    region: 'US',
  },
  {
    symbol: 'CLAS',
    name: 'Class Acceleration Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLAY',
    name: 'Chavant Capital Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'CLAYU',
    name: 'Chavant Capital Acquisition Corp - Units (1 Ord Share & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'CLB',
    name: 'Core Laboratories N.V.',
    region: 'US',
  },
  {
    symbol: 'CLBK',
    name: 'Columbia Financial, Inc',
    region: 'US',
  },
  {
    symbol: 'CLBR',
    name: 'Colombier Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLBS',
    name: 'Caladrius Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CLCRX',
    name: 'Columbia Funds Series Trust: Columbia Select Large Cap Equity Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CLDL',
    name: 'Direxion Funds - Direxion Daily Cloud Computing Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'CLDS',
    name: 'Direxion Funds - Direxion Daily Cloud Computing Bear 2X Shares',
    region: 'US',
  },
  {
    symbol: 'CLDX',
    name: 'Celldex Therapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'CLEU',
    name: 'China Liberal Education Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'CLF',
    name: 'Cleveland-Cliffs Inc',
    region: 'US',
  },
  {
    symbol: 'CLFD',
    name: 'Clearfield Inc',
    region: 'US',
  },
  {
    symbol: 'CLGN',
    name: 'CollPlant Biotechnologies Ltd New',
    region: 'US',
  },
  {
    symbol: 'CLH',
    name: 'Clean Harbors, Inc.',
    region: 'US',
  },
  {
    symbol: 'CLIM',
    name: 'Climate Real Impact Solutions II Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLIN',
    name: 'Clean Earth Acquisitions Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLINU',
    name: 'Clean Earth Acquisitions Corp - Units (1 Ord Share Class A 1 Right & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CLIR',
    name: 'ClearSign Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'CLIX',
    name: 'ProShares Trust - ProShares Long Online/Short Stores ETF',
    region: 'US',
  },
  {
    symbol: 'CLLS',
    name: 'Cellectis - ADR',
    region: 'US',
  },
  {
    symbol: 'CLMA',
    name: 'Tidal ETF Trust - iClima Climate Change Solutions ETF',
    region: 'US',
  },
  {
    symbol: 'CLMT',
    name: 'Calumet Specialty Products Partners L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'CLNE',
    name: 'Clean Energy Fuels Corp',
    region: 'US',
  },
  {
    symbol: 'CLNN',
    name: 'Clene Inc',
    region: 'US',
  },
  {
    symbol: 'CLNR',
    name: 'IndexIQ ETF Trust - IQ Cleaner Transport ETF',
    region: 'US',
  },
  {
    symbol: 'CLNXF',
    name: 'Cellnex Telecom S.A.',
    region: 'US',
  },
  {
    symbol: 'CLOE',
    name: 'Clover Leaf Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLOEU',
    name: 'Clover Leaf Capital Corp - Units (1 Ord Share Class A & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'CLOU',
    name: 'Global X Funds - Global X Cloud Computing ETF',
    region: 'US',
  },
  {
    symbol: 'CLOV',
    name: 'Clover Health Investments Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLPS',
    name: 'CLPS Inc',
    region: 'US',
  },
  {
    symbol: 'CLPT',
    name: 'ClearPoint Neuro Inc',
    region: 'US',
  },
  {
    symbol: 'CLPYX',
    name: 'Columbia Funds Series Trust: Columbia Large Cap Index Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CLR',
    name: 'Continental Resources Inc (OKLA)',
    region: 'US',
  },
  {
    symbol: 'CLRB',
    name: 'Cellectar Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CLRG',
    name: 'IndexIQ ETF Trust - IQ Chaikin U.S. Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'CLRM',
    name: 'Clarim Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CLRMU',
    name: 'Clarim Acquisition Corp - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CLRO',
    name: 'ClearOne Inc',
    region: 'US',
  },
  {
    symbol: 'CLS',
    name: 'Celestica, Inc.',
    region: 'US',
  },
  {
    symbol: 'CLSA',
    name: 'Exchange Listed Funds Trust - Cabana Target Leading Sector Aggressive ETF',
    region: 'US',
  },
  {
    symbol: 'CLSC',
    name: 'Exchange Listed Funds Trust - Cabana Target Leading Sector Conservative ETF',
    region: 'US',
  },
  {
    symbol: 'CLSD',
    name: 'Clearside Biomedical Inc',
    region: 'US',
  },
  {
    symbol: 'CLSE',
    name: 'Trust For Professional Managers - Convergence Long/Short Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CLSK',
    name: 'Cleanspark Inc',
    region: 'US',
  },
  {
    symbol: 'CLSM',
    name: 'Exchange Listed Funds Trust - Cabana Target Leading Sector Moderate ETF',
    region: 'US',
  },
  {
    symbol: 'CLSN',
    name: 'Celsion Corp',
    region: 'US',
  },
  {
    symbol: 'CLST',
    name: 'Catalyst Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'CLTL',
    name: 'Invesco Capital Management LLC - Invesco Treasury Collateral ETF',
    region: 'US',
  },
  {
    symbol: 'CLURX',
    name: 'Columbia Funds Series Trust: Columbia Small Cap Value Fund II; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CLVR',
    name: 'Clever Leaves Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CLVS',
    name: 'Clovis Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'CLVT',
    name: 'Clarivate Plc',
    region: 'US',
  },
  {
    symbol: 'CLW',
    name: 'Clearwater Paper Corp',
    region: 'US',
  },
  {
    symbol: 'CLWFX',
    name: 'Columbia Funds Series Trust I: Columbia Large Cap Growth Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CLWT',
    name: 'Euro Tech Holdings Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'CLX',
    name: 'Clorox Co.',
    region: 'US',
  },
  {
    symbol: 'CLXRX',
    name: 'Columbia Funds Series Trust: Columbia Large Cap Index Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CLXT',
    name: 'Calyxt Inc',
    region: 'US',
  },
  {
    symbol: 'CM',
    name: 'Canadian Imperial Bank Of Commerce',
    region: 'US',
  },
  {
    symbol: 'CMA',
    name: 'Comerica, Inc.',
    region: 'US',
  },
  {
    symbol: 'CMAX',
    name: 'CareMax Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CMBM',
    name: 'Cambium Networks Corp',
    region: 'US',
  },
  {
    symbol: 'CMBS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares CMBS ETF',
    region: 'US',
  },
  {
    symbol: 'CMC',
    name: 'Commercial Metals Co.',
    region: 'US',
  },
  {
    symbol: 'CMCA',
    name: 'Capitalworks Emerging Markets Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CMCAU',
    name: 'Capitalworks Emerging Markets Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CMCL',
    name: 'Caledonia Mining Corporation Plc',
    region: 'US',
  },
  {
    symbol: 'CMCM',
    name: 'Cheetah Mobile Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'CMCO',
    name: 'Columbus Mckinnon Corp.',
    region: 'US',
  },
  {
    symbol: 'CMCSA',
    name: 'Comcast Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CMDY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Bloomberg Roll Select Commodity Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'CMF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares California Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'CMG',
    name: 'Chipotle Mexican Grill',
    region: 'US',
  },
  {
    symbol: 'CMGIX',
    name: 'BlackRock Funds: BlackRock Mid-Cap Growth Equity Portfolio; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMGSX',
    name: 'BlackRock Funds: BlackRock Mid-Cap Growth Equity Portfolio; Service Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMI',
    name: 'Cummins Inc.',
    region: 'US',
  },
  {
    symbol: 'CMIDX',
    name: 'Professionally Managed Portfolios: Congress Mid Cap Growth Fund; Retail Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMLAX',
    name: 'American Mutual Fund; Class 529-A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMLEX',
    name: 'American Mutual Fund; Class 529-E Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMLFX',
    name: 'American Mutual Fund; Class 529-F-1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMLS',
    name: 'Cumulus Media Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'CMNWX',
    name: 'Principal Funds, Inc: Principal Capital Appreciation Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMP',
    name: 'Compass Minerals International Inc',
    region: 'US',
  },
  {
    symbol: 'CMPI',
    name: 'Checkmate Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CMPO',
    name: 'CompoSecure Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CMPR',
    name: 'Cimpress plc',
    region: 'US',
  },
  {
    symbol: 'CMPS',
    name: 'Compass Pathways Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'CMPX',
    name: 'Compass Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CMRE',
    name: 'Costamare Inc',
    region: 'US',
  },
  {
    symbol: 'CMRX',
    name: 'Chimerix Inc',
    region: 'US',
  },
  {
    symbol: 'CMS',
    name: 'CMS Energy Corporation',
    region: 'US',
  },
  {
    symbol: 'CMSCX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMT',
    name: 'Core Molding Technologies',
    region: 'US',
  },
  {
    symbol: 'CMTFX',
    name: 'Columbia Funds Series Trust I: Columbia Global Technology Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMTL',
    name: 'Comtech Telecommunications Corp.',
    region: 'US',
  },
  {
    symbol: 'CMUAX',
    name: 'Columbia Funds Series Trust: Columbia Select Mid Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CMVYX',
    name: 'Columbia Funds Series Trust: Columbia Select Mid Cap Value Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CN',
    name: 'DBX ETF Trust - Xtrackers MSCI All China Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CNA',
    name: 'CNA Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'CNBS',
    name: 'Amplify ETF Trust - Amplify Seymour Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'CNC',
    name: 'Centene Corp.',
    region: 'US',
  },
  {
    symbol: 'CNCE',
    name: 'Concert Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CNCR',
    name: 'Exchange Traded Concepts Trust - ETCT Loncar Cancer Immunotherapy ETF',
    region: 'US',
  },
  {
    symbol: 'CND',
    name: 'Concord Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CNDA',
    name: 'Concord Acquisition Corp. II - Class A',
    region: 'US',
  },
  {
    symbol: 'CNDB',
    name: 'Concord Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'CNDT',
    name: 'Conduent Inc',
    region: 'US',
  },
  {
    symbol: 'CNET',
    name: 'ZW Data Action Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'CNEY',
    name: 'CN Energy Group Inc',
    region: 'US',
  },
  {
    symbol: 'CNFR',
    name: 'Conifer Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CNGAX',
    name: 'New Economy Fund; Class 529-A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CNGCX',
    name: 'New Economy Fund; Class 529-C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CNGEX',
    name: 'New Economy Fund; Class 529-E Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CNGFX',
    name: 'New Economy Fund; Class 529-F-1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CNGL',
    name: 'Canna Global Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CNGLU',
    name: 'Canna Global Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'CNHI',
    name: 'CNH Industrial NV',
    region: 'US',
  },
  {
    symbol: 'CNI',
    name: 'Canadian National Railway Co.',
    region: 'US',
  },
  {
    symbol: 'CNK',
    name: 'Cinemark Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CNM',
    name: 'Core & Main Inc Class A',
    region: 'US',
  },
  {
    symbol: 'CNMD',
    name: 'Conmed Corp.',
    region: 'US',
  },
  {
    symbol: 'CNNB',
    name: 'Cincinnati Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'CNNE',
    name: 'Cannae Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CNO',
    name: 'CNO Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'CNOB',
    name: 'ConnectOne Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'CNP',
    name: 'Centerpoint Energy Inc.',
    region: 'US',
  },
  {
    symbol: 'CNQ',
    name: 'Canadian Natural Resources Ltd.',
    region: 'US',
  },
  {
    symbol: 'CNR',
    name: 'Cornerstone Building Brands Inc',
    region: 'US',
  },
  {
    symbol: 'CNRG',
    name: 'SPDR Series Trust - SPDR S&P Kensho Clean Power ETF',
    region: 'US',
  },
  {
    symbol: 'CNSL',
    name: 'Consolidated Communications Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CNSP',
    name: 'Cns Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CNTA',
    name: 'Centessa Pharmaceuticals plc - ADR',
    region: 'US',
  },
  {
    symbol: 'CNTB',
    name: 'Connect Biopharma Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'CNTG',
    name: 'Centogene NV',
    region: 'US',
  },
  {
    symbol: 'CNTQ',
    name: 'Chardan NexTech Acquisition 2 Corp',
    region: 'US',
  },
  {
    symbol: 'CNTQU',
    name: 'Chardan NexTech Acquisition 2 Corp - Units (1 Ord Share & 3/4War)',
    region: 'US',
  },
  {
    symbol: 'CNTX',
    name: 'Context Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CNTY',
    name: 'Century Casinos Inc.',
    region: 'US',
  },
  {
    symbol: 'CNVY',
    name: 'Convey Health Solutions Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CNX',
    name: 'CNX Resources Corp',
    region: 'US',
  },
  {
    symbol: 'CNXC',
    name: 'Concentrix Corporation',
    region: 'US',
  },
  {
    symbol: 'CNXN',
    name: 'PC Connection, Inc.',
    region: 'US',
  },
  {
    symbol: 'CNXT',
    name: 'VanEck ETF Trust - VanEck ChiNext ETF',
    region: 'US',
  },
  {
    symbol: 'CNYA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI China A ETF',
    region: 'US',
  },
  {
    symbol: 'CO',
    name: 'Global Cord Blood Corp',
    region: 'US',
  },
  {
    symbol: 'COCO',
    name: 'Vita Coco Company Inc (The)',
    region: 'US',
  },
  {
    symbol: 'COCP',
    name: 'Cocrystal Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'CODA',
    name: 'Coda Octopus Group Inc.',
    region: 'US',
  },
  {
    symbol: 'CODI',
    name: 'Compass Diversified Holdings',
    region: 'US',
  },
  {
    symbol: 'CODX',
    name: 'Co-Diagnostics Inc',
    region: 'US',
  },
  {
    symbol: 'COE',
    name: 'China Online Education Group - ADR',
    region: 'US',
  },
  {
    symbol: 'COF',
    name: 'Capital One Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'COFS',
    name: 'Choiceone Financial Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'COGT',
    name: 'Cogent Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'COHOX',
    name: 'Managed Portfolio Series: Coho Relative Value Equity Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'COHR',
    name: 'Coherent Inc.',
    region: 'US',
  },
  {
    symbol: 'COHU',
    name: 'Cohu, Inc.',
    region: 'US',
  },
  {
    symbol: 'COIN',
    name: 'Coinbase Global Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'COKE',
    name: 'Coca-Cola Consolidated Inc',
    region: 'US',
  },
  {
    symbol: 'COLB',
    name: 'Columbia Banking System, Inc.',
    region: 'US',
  },
  {
    symbol: 'COLI',
    name: 'Colicity Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'COLIU',
    name: 'Colicity Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'COLL',
    name: 'Collegium Pharmaceutical Inc',
    region: 'US',
  },
  {
    symbol: 'COLM',
    name: 'Columbia Sportswear Co.',
    region: 'US',
  },
  {
    symbol: 'COM',
    name: 'Direxion Shares ETF Trust - Direxion Auspice Broad Commodity Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'COMB',
    name: 'GraniteShares Gold Trust - GraniteShares Bloomberg Commodity Broad Strategy No K-1 ETF',
    region: 'US',
  },
  {
    symbol: 'COMM',
    name: 'CommScope Holding Company Inc',
    region: 'US',
  },
  {
    symbol: 'COMS',
    name: 'ComSovereign Holding Corp',
    region: 'US',
  },
  {
    symbol: 'COMT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares GSCI Commodity Dynamic Roll Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'CONN',
    name: 'Conns Inc',
    region: 'US',
  },
  {
    symbol: 'CONX',
    name: 'CONX Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CONXU',
    name: 'CONX Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'COO',
    name: 'Cooper Companies, Inc.',
    region: 'US',
  },
  {
    symbol: 'COOK',
    name: 'Traeger Inc',
    region: 'US',
  },
  {
    symbol: 'COOL',
    name: 'Corner Growth Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'COOLU',
    name: 'Corner Growth Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'COOP',
    name: 'Mr. Cooper Group Inc',
    region: 'US',
  },
  {
    symbol: 'COP',
    name: 'Conoco Phillips',
    region: 'US',
  },
  {
    symbol: 'COPX',
    name: 'Global X Funds - Global X Copper Miners ETF',
    region: 'US',
  },
  {
    symbol: 'CORN',
    name: 'Teucrium Trading, LLC - Teucrium Corn Fund',
    region: 'US',
  },
  {
    symbol: 'CORP',
    name: 'Pimco Exchange Traded Fund - PIMCO Investment Grade Corporate Bond Index Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'CORS',
    name: 'Corsair Partnering Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CORT',
    name: 'Corcept Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CORZ',
    name: 'Core Scientific Inc',
    region: 'US',
  },
  {
    symbol: 'COSM',
    name: 'Cosmos Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'COST',
    name: 'Costco Wholesale Corp',
    region: 'US',
  },
  {
    symbol: 'COTY',
    name: 'Coty Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'COUP',
    name: 'Coupa Software Inc',
    region: 'US',
  },
  {
    symbol: 'COUR',
    name: 'Coursera Inc',
    region: 'US',
  },
  {
    symbol: 'COVA',
    name: 'COVA Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'COVAU',
    name: 'COVA Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'COVAX',
    name: 'Columbia Funds Series Trust: Columbia Small Cap Value Fund II; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'COWZ',
    name: 'Pacer Funds Trust - Pacer US Cash Cows 100 ETF',
    region: 'US',
  },
  {
    symbol: 'CP',
    name: 'Canadian Pacific Railway Ltd',
    region: 'US',
  },
  {
    symbol: 'CPA',
    name: 'Copa Holdings S.A. - Class A',
    region: 'US',
  },
  {
    symbol: 'CPAA',
    name: 'Conyers Park III Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CPAAU',
    name: 'Conyers Park III Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CPAC',
    name: 'Cementos Pacasmayo S.A.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'CPAR',
    name: 'Catalyst Partners Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CPARU',
    name: 'Catalyst Partners Acquisition Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'CPB',
    name: 'Campbell Soup Co.',
    region: 'US',
  },
  {
    symbol: 'CPE',
    name: 'Callon Petroleum Co.',
    region: 'US',
  },
  {
    symbol: 'CPER',
    name: 'United States Commodity Funds LLC - United States Copper Index Fund',
    region: 'US',
  },
  {
    symbol: 'CPF',
    name: 'Central Pacific Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'CPG',
    name: 'Crescent Point Energy Corp',
    region: 'US',
  },
  {
    symbol: 'CPHC',
    name: 'Canterbury Park Holding Corp',
    region: 'US',
  },
  {
    symbol: 'CPHI',
    name: 'China Pharma Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'CPI',
    name: 'IndexIQ ETF Trust - IQ Real Return ETF',
    region: 'US',
  },
  {
    symbol: 'CPIX',
    name: 'Cumberland Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'CPK',
    name: 'Chesapeake Utilities Corp',
    region: 'US',
  },
  {
    symbol: 'CPLP',
    name: 'Capital Product Partners L P - Unit',
    region: 'US',
  },
  {
    symbol: 'CPNG',
    name: 'Coupang Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CPOP',
    name: 'Pop Culture Group Co Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'CPRI',
    name: 'Capri Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'CPRT',
    name: 'Copart, Inc.',
    region: 'US',
  },
  {
    symbol: 'CPRX',
    name: 'Catalyst Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CPS',
    name: 'Cooper-Standard Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CPSH',
    name: 'CPS Technologies Corporation',
    region: 'US',
  },
  {
    symbol: 'CPSI',
    name: 'Computer Programs & Systems Inc',
    region: 'US',
  },
  {
    symbol: 'CPTK',
    name: 'Crown PropTech Acquisitions - Class A',
    region: 'US',
  },
  {
    symbol: 'CPTN',
    name: 'Cepton Inc',
    region: 'US',
  },
  {
    symbol: 'CPUH',
    name: 'Compute Health Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CQP',
    name: 'Cheniere Energy Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'CQQQ',
    name: 'Invesco Capital Management LLC - Invesco China Technology ETF',
    region: 'US',
  },
  {
    symbol: 'CR',
    name: 'Crane Co.',
    region: 'US',
  },
  {
    symbol: 'CRAI',
    name: 'CRA International Inc.',
    region: 'US',
  },
  {
    symbol: 'CRAK',
    name: 'VanEck ETF Trust - VanEck Oil Refiners ETF',
    region: 'US',
  },
  {
    symbol: 'CRBN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI ACWI Low Carbon Target ETF',
    region: 'US',
  },
  {
    symbol: 'CRBP',
    name: 'Corbus Pharmaceuticals Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CRBU',
    name: 'Caribou Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CRC',
    name: 'California Resources Corporation - New',
    region: 'US',
  },
  {
    symbol: 'CRCT',
    name: 'Cricut Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CRD.A',
    name: 'Crawford & Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'CRD.B',
    name: 'Crawford & Co. - Class B',
    region: 'US',
  },
  {
    symbol: 'CRDF',
    name: 'Cardiff Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'CRDL',
    name: 'Cardiol Therapeutics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CRDO',
    name: 'Credo Technology Group Holding Ltd',
    region: 'US',
  },
  {
    symbol: 'CREG',
    name: 'Smart Powerr Corp',
    region: 'US',
  },
  {
    symbol: 'CRESY',
    name: 'Cresud - ADR',
    region: 'US',
  },
  {
    symbol: 'CREX',
    name: 'Creative Realities Inc',
    region: 'US',
  },
  {
    symbol: 'CRGE',
    name: 'Charge Enterprises Inc',
    region: 'US',
  },
  {
    symbol: 'CRGY',
    name: 'Crescent Energy Company - Class A',
    region: 'US',
  },
  {
    symbol: 'CRH',
    name: 'CRH Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'CRHC',
    name: 'Cohn Robbins Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CRI',
    name: 'Carters Inc',
    region: 'US',
  },
  {
    symbol: 'CRIS',
    name: 'Curis Inc',
    region: 'US',
  },
  {
    symbol: 'CRIT',
    name: 'Exchange Traded Concepts Trust - Optica Rare Earths & Critical Materials ETF',
    region: 'US',
  },
  {
    symbol: 'CRK',
    name: 'Comstock Resources, Inc.',
    region: 'US',
  },
  {
    symbol: 'CRKN',
    name: 'Crown ElectroKinetics Corp',
    region: 'US',
  },
  {
    symbol: 'CRL',
    name: 'Charles River Laboratories International Inc.',
    region: 'US',
  },
  {
    symbol: 'CRM',
    name: 'Salesforce Inc',
    region: 'US',
  },
  {
    symbol: 'CRMD',
    name: 'CorMedix Inc',
    region: 'US',
  },
  {
    symbol: 'CRMT',
    name: 'Americas Car Mart, Inc.',
    region: 'US',
  },
  {
    symbol: 'CRNC',
    name: 'Cerence Inc',
    region: 'US',
  },
  {
    symbol: 'CRNT',
    name: 'Ceragon Networks Ltd',
    region: 'US',
  },
  {
    symbol: 'CRNX',
    name: 'Crinetics Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CROC',
    name: 'ProShares Trust - ProShares UltraShort Australian Dollar',
    region: 'US',
  },
  {
    symbol: 'CRON',
    name: 'Cronos Group Inc',
    region: 'US',
  },
  {
    symbol: 'CROX',
    name: 'Crocs Inc',
    region: 'US',
  },
  {
    symbol: 'CRPT',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust SkyBridge Crypto Industry and Digital Economy ETF',
    region: 'US',
  },
  {
    symbol: 'CRRRX',
    name: 'Columbia Funds Series Trust: Columbia Small Cap Value Fund II; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CRRYX',
    name: 'Columbia Funds Series Trust: Columbia Small Cap Value Fund II; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CRS',
    name: 'Carpenter Technology Corp.',
    region: 'US',
  },
  {
    symbol: 'CRSP',
    name: 'CRISPR Therapeutics AG',
    region: 'US',
  },
  {
    symbol: 'CRSR',
    name: 'Corsair Gaming Inc',
    region: 'US',
  },
  {
    symbol: 'CRTD',
    name: 'Creatd Inc',
    region: 'US',
  },
  {
    symbol: 'CRTO',
    name: 'Criteo S.A - ADR',
    region: 'US',
  },
  {
    symbol: 'CRTX',
    name: 'Cortexyme Inc',
    region: 'US',
  },
  {
    symbol: 'CRU',
    name: 'Crucible Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CRUS',
    name: 'Cirrus Logic, Inc.',
    region: 'US',
  },
  {
    symbol: 'CRUZ',
    name: 'ETF Series Solutions Trust - Defiance Hotel, Airline, and Cruise ETF',
    region: 'US',
  },
  {
    symbol: 'CRVL',
    name: 'Corvel Corp.',
    region: 'US',
  },
  {
    symbol: 'CRVS',
    name: 'Corvus Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CRWD',
    name: 'Crowdstrike Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CRWS',
    name: 'Crown Crafts, Inc.',
    region: 'US',
  },
  {
    symbol: 'CRXT',
    name: 'Clarus Therapeutics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CRYP',
    name: 'Advisorshares Trust - AdvisorShares Managed Bitcoin Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'CRZN',
    name: 'Corazon Capital V838 Monoceros Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CRZNU',
    name: 'Corazon Capital V838 Monoceros Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CS',
    name: 'Credit Suisse Group AG - ADR',
    region: 'US',
  },
  {
    symbol: 'CSA',
    name: 'Victory Portfolios II - VictoryShares US Small Cap Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CSAN',
    name: 'Cosan S.A - ADR',
    region: 'US',
  },
  {
    symbol: 'CSB',
    name: 'Victory Portfolios II - VictoryShares US Small Cap High Div Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CSBR',
    name: 'Champions Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'CSCO',
    name: 'Cisco Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'CSCRX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSCW',
    name: 'Color Star Technology Co Ltd',
    region: 'US',
  },
  {
    symbol: 'CSD',
    name: 'Invesco Capital Management LLC - Invesco S&P Spin-Off ETF',
    region: 'US',
  },
  {
    symbol: 'CSF',
    name: 'Victory Portfolios II - VictoryShares US Discovery Enhanced Volatility Wtd ETF',
    region: 'US',
  },
  {
    symbol: 'CSGP',
    name: 'Costar Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'CSGS',
    name: 'CSG Systems International Inc.',
    region: 'US',
  },
  {
    symbol: 'CSGYX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Growth Fund I; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSGZX',
    name: 'Columbia Funds Series Trust II: Columbia Seligman Global Technology Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSH',
    name: 'Listed Funds Trust - Morgan Creek - Exos Active SPAC Arbitrage ETF',
    region: 'US',
  },
  {
    symbol: 'CSII',
    name: 'Cardiovascular Systems Inc.',
    region: 'US',
  },
  {
    symbol: 'CSIQ',
    name: 'Canadian Solar Inc',
    region: 'US',
  },
  {
    symbol: 'CSL',
    name: 'Carlisle Companies Inc.',
    region: 'US',
  },
  {
    symbol: 'CSLM',
    name: 'Consilium Acquisition Corp I Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'CSLMU',
    name: 'Consilium Acquisition Corp I Ltd - Units (1 Ord Class A , 1 Right & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'CSM',
    name: 'ProShares Trust - ProShares Large Cap Core Plus',
    region: 'US',
  },
  {
    symbol: 'CSMEX',
    name: 'Carillon Series Trust: Carillon Scout Mid Cap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSML',
    name: 'IndexIQ ETF Trust - IQ Chaikin U.S. Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'CSMSX',
    name: 'Carillon Series Trust: Carillon Scout Mid Cap Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSMUX',
    name: 'Carillon Series Trust: Carillon Scout Mid Cap Fund; Class R-6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSMZX',
    name: 'Carillon Series Trust: Carillon Scout Mid Cap Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSPI',
    name: 'CSP Inc.',
    region: 'US',
  },
  {
    symbol: 'CSRYX',
    name: 'Columbia Funds Series Trust II: Columbia Select Large Cap Value Fund; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSSE',
    name: 'Chicken Soup for the Soul Entertainment Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CSTA',
    name: 'Constellation Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'CSTE',
    name: 'Caesarstone Ltd',
    region: 'US',
  },
  {
    symbol: 'CSTL',
    name: 'Castle Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'CSTM',
    name: 'Constellium SE - Class A',
    region: 'US',
  },
  {
    symbol: 'CSTR',
    name: 'CapStar Financial Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CSV',
    name: 'Carriage Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'CSVYX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Value Fund I; Institutional 3 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSVZX',
    name: 'Columbia Funds Series Trust II: Columbia Select Large Cap Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CSWI',
    name: 'CSW Industrials Inc',
    region: 'US',
  },
  {
    symbol: 'CSX',
    name: 'CSX Corp.',
    region: 'US',
  },
  {
    symbol: 'CTA',
    name: 'Simplify Exchange Traded Funds - Simplify Managed Futures Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'CTAQ',
    name: 'Carney Technology Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'CTAQU',
    name: 'Carney Technology Acquisition Corp II - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'CTAS',
    name: 'Cintas Corporation',
    region: 'US',
  },
  {
    symbol: 'CTBI',
    name: 'Community Trust Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'CTCAX',
    name: 'Columbia Funds Series Trust I: Columbia Global Technology Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CTEC',
    name: 'Global X Funds - Global X CleanTech ETF',
    region: 'US',
  },
  {
    symbol: 'CTEK',
    name: 'CynergisTek Inc',
    region: 'US',
  },
  {
    symbol: 'CTEX',
    name: 'ProShares Trust - ProShares S&P Kensho Cleantech ETF',
    region: 'US',
  },
  {
    symbol: 'CTG',
    name: 'Computer Task Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'CTGO',
    name: 'Contango Ore Inc',
    region: 'US',
  },
  {
    symbol: 'CTHCX',
    name: 'Columbia Funds Series Trust I: Columbia Global Technology Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CTHR',
    name: 'Charles & Colvard Ltd',
    region: 'US',
  },
  {
    symbol: 'CTHRX',
    name: 'Columbia Funds Series Trust I: Columbia Global Technology Growth Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CTIB',
    name: 'Yunhong CTI Ltd',
    region: 'US',
  },
  {
    symbol: 'CTIC',
    name: 'CTI BioPharma Corp',
    region: 'US',
  },
  {
    symbol: 'CTK',
    name: 'CooTek (Cayman) Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'CTKB',
    name: 'Cytek BioSciences Inc',
    region: 'US',
  },
  {
    symbol: 'CTLP',
    name: 'Cantaloupe Inc',
    region: 'US',
  },
  {
    symbol: 'CTLT',
    name: 'Catalent Inc.',
    region: 'US',
  },
  {
    symbol: 'CTMX',
    name: 'CytomX Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CTO',
    name: 'CTO Realty Growth Inc- New',
    region: 'US',
  },
  {
    symbol: 'CTOS',
    name: 'Custom Truck One Source Inc',
    region: 'US',
  },
  {
    symbol: 'CTRA',
    name: 'Coterra Energy Inc',
    region: 'US',
  },
  {
    symbol: 'CTRM',
    name: 'Castor Maritime Inc',
    region: 'US',
  },
  {
    symbol: 'CTRN',
    name: 'Citi Trends Inc',
    region: 'US',
  },
  {
    symbol: 'CTRU',
    name: 'ARK ETF Trust - ARK Transparency ETF',
    region: 'US',
  },
  {
    symbol: 'CTS',
    name: 'CTS Corp.',
    region: 'US',
  },
  {
    symbol: 'CTSH',
    name: 'Cognizant Technology Solutions Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'CTSO',
    name: 'Cytosorbents Corp',
    region: 'US',
  },
  {
    symbol: 'CTV',
    name: 'Innovid Corp',
    region: 'US',
  },
  {
    symbol: 'CTVA',
    name: 'Corteva Inc',
    region: 'US',
  },
  {
    symbol: 'CTXR',
    name: 'Citius Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CTXS',
    name: 'Citrix Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'CTYRX',
    name: 'Columbia Funds Series Trust I: Columbia Global Technology Growth Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CUBI',
    name: 'Customers Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'CUBS',
    name: 'Exchange Listed Funds Trust - Asian Growth Cubs ETF',
    region: 'US',
  },
  {
    symbol: 'CUE',
    name: 'Cue Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'CUEN',
    name: 'Cuentas Inc',
    region: 'US',
  },
  {
    symbol: 'CUK',
    name: 'Carnival plc - ADR',
    region: 'US',
  },
  {
    symbol: 'CULL',
    name: 'Cullman Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'CULP',
    name: 'Culp Inc.',
    region: 'US',
  },
  {
    symbol: 'CURE',
    name: 'Direxion Shares ETF Trust - Direxion Daily Healthcare Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'CURI',
    name: 'CuriosityStream Inc- Class A',
    region: 'US',
  },
  {
    symbol: 'CURLF',
    name: 'Curaleaf Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CURV',
    name: 'Torrid Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'CUT',
    name: 'Invesco Capital Management LLC - Invesco MSCI Global Timber ETF',
    region: 'US',
  },
  {
    symbol: 'CUTR',
    name: 'Cutera Inc',
    region: 'US',
  },
  {
    symbol: 'CUURX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Value Fund I; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CUYTF',
    name: 'Colruyt',
    region: 'US',
  },
  {
    symbol: 'CUYTY',
    name: 'Colruyt - ADR',
    region: 'US',
  },
  {
    symbol: 'CVAC',
    name: 'CureVac N.V.',
    region: 'US',
  },
  {
    symbol: 'CVAR',
    name: 'ETF Opportunities Trust - Cultivar ETF',
    region: 'US',
  },
  {
    symbol: 'CVBF',
    name: 'CVB Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'CVCO',
    name: 'Cavco Industries Inc',
    region: 'US',
  },
  {
    symbol: 'CVCY',
    name: 'Central Valley Community Bancorp',
    region: 'US',
  },
  {
    symbol: 'CVE',
    name: 'Cenovus Energy Inc',
    region: 'US',
  },
  {
    symbol: 'CVEO',
    name: 'Civeo Corp',
    region: 'US',
  },
  {
    symbol: 'CVERX',
    name: 'Columbia Funds Series Trust: Columbia Select Mid Cap Value Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CVET',
    name: 'Covetrus Inc',
    region: 'US',
  },
  {
    symbol: 'CVGI',
    name: 'Commercial Vehicle Group Inc',
    region: 'US',
  },
  {
    symbol: 'CVGW',
    name: 'Calavo Growers, Inc',
    region: 'US',
  },
  {
    symbol: 'CVI',
    name: 'CVR Energy Inc',
    region: 'US',
  },
  {
    symbol: 'CVII',
    name: 'Churchill Capital Corp VII - Class A',
    region: 'US',
  },
  {
    symbol: 'CVIRX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CVLG',
    name: 'Covenant Logistics Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CVLT',
    name: 'Commvault Systems Inc',
    region: 'US',
  },
  {
    symbol: 'CVLY',
    name: 'Codorus Valley Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'CVM',
    name: 'Cel-Sci Corp.',
    region: 'US',
  },
  {
    symbol: 'CVNA',
    name: 'Carvana Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'CVR',
    name: 'Chicago Rivet & Machine Co.',
    region: 'US',
  },
  {
    symbol: 'CVRX',
    name: 'CVRx Inc',
    region: 'US',
  },
  {
    symbol: 'CVS',
    name: 'CVS Health Corp',
    region: 'US',
  },
  {
    symbol: 'CVT',
    name: 'Cvent Holding Corp',
    region: 'US',
  },
  {
    symbol: 'CVU',
    name: 'CPI Aerostructures Inc',
    region: 'US',
  },
  {
    symbol: 'CVV',
    name: 'CVD Equipment Corp.',
    region: 'US',
  },
  {
    symbol: 'CVVRX',
    name: 'Columbia Funds Series Trust I: Columbia Small Cap Value Fund I; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CVX',
    name: 'Chevron Corp.',
    region: 'US',
  },
  {
    symbol: 'CVY',
    name: 'Invesco Capital Management LLC - Invesco Zacks Multi-Asset Income ETF',
    region: 'US',
  },
  {
    symbol: 'CW',
    name: 'Curtiss-Wright Corp.',
    region: 'US',
  },
  {
    symbol: 'CWAN',
    name: 'Clearwater Analytics Holdings Inc Class A',
    region: 'US',
  },
  {
    symbol: 'CWB',
    name: 'SPDR Series Trust - SPDR Bloomberg Convertible Securities ETF',
    region: 'US',
  },
  {
    symbol: 'CWBC',
    name: 'Community West Bancshares',
    region: 'US',
  },
  {
    symbol: 'CWBR',
    name: 'CohBar Inc',
    region: 'US',
  },
  {
    symbol: 'CWCO',
    name: 'Consolidated Water Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'CWEB',
    name: 'Direxion Shares ETF Trust - Direxion Daily CSI China Internet Index Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'CWEN',
    name: 'Clearway Energy Inc - Class C',
    region: 'US',
  },
  {
    symbol: 'CWEN.A',
    name: 'Clearway Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CWH',
    name: 'Camping World Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CWI',
    name: 'SSgA Active Trust - SPDR MSCI ACWI ex-US ETF',
    region: 'US',
  },
  {
    symbol: 'CWS',
    name: 'Advisorshares Trust - AdvisorShares Focused Equity ETF',
    region: 'US',
  },
  {
    symbol: 'CWST',
    name: 'Casella Waste Systems, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'CWT',
    name: 'California Water Service Group',
    region: 'US',
  },
  {
    symbol: 'CX',
    name: 'Cemex S.A.B. De C.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'CXAC',
    name: 'C5 Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'CXDO',
    name: 'Crexendo Inc',
    region: 'US',
  },
  {
    symbol: 'CXM',
    name: 'Sprinklr Inc Class A',
    region: 'US',
  },
  {
    symbol: 'CXSE',
    name: 'WisdomTree Trust - WisdomTree China ex-State-Owned Enterprises Fund',
    region: 'US',
  },
  {
    symbol: 'CXW',
    name: 'CoreCivic Inc',
    region: 'US',
  },
  {
    symbol: 'CYA',
    name: 'Simplify Exchange Traded Funds - Simplify Tail Risk Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'CYAD',
    name: 'Celyad Oncology - ADR',
    region: 'US',
  },
  {
    symbol: 'CYAN',
    name: 'Cyanotech Corp.',
    region: 'US',
  },
  {
    symbol: 'CYB',
    name: 'WisdomTree Trust - WisdomTree Chinese Yuan Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'CYBE',
    name: 'Cyberoptics Corp.',
    region: 'US',
  },
  {
    symbol: 'CYBN',
    name: 'Cybin Inc',
    region: 'US',
  },
  {
    symbol: 'CYBR',
    name: 'CyberArk Software Ltd',
    region: 'US',
  },
  {
    symbol: 'CYCC',
    name: 'Cyclacel Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'CYCN',
    name: 'Cyclerion Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CYD',
    name: 'China Yuchai International',
    region: 'US',
  },
  {
    symbol: 'CYH',
    name: 'Community Health Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'CYN',
    name: 'Cyngn Inc',
    region: 'US',
  },
  {
    symbol: 'CYRN',
    name: 'CYREN Ltd',
    region: 'US',
  },
  {
    symbol: 'CYRX',
    name: 'CryoPort Inc',
    region: 'US',
  },
  {
    symbol: 'CYT',
    name: 'Cyteir Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'CYTH',
    name: 'Cyclo Therapeutics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CYTK',
    name: 'Cytokinetics Inc',
    region: 'US',
  },
  {
    symbol: 'CYTO',
    name: 'Altamira Therapeutics Ltd',
    region: 'US',
  },
  {
    symbol: 'CYXT',
    name: 'Cyxtera Technologies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'CZA',
    name: 'Invesco Capital Management LLC - Invesco Zacks Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'CZMSX',
    name: 'Columbia Funds Series Trust I: Columbia Multi-Manager Small Cap Equity Strategies Fund; Institutional Cl Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CZMVX',
    name: 'Columbia Funds Series Trust II: Columbia Multi-Manager Value Strategies Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'CZNC',
    name: 'Citizens & Northern Corp',
    region: 'US',
  },
  {
    symbol: 'CZOO',
    name: 'Cazoo Group Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'CZR',
    name: 'Caesars Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'CZWI',
    name: 'Citizens Community Bancorp Inc MD',
    region: 'US',
  },
  {
    symbol: 'D',
    name: 'Dominion Energy Inc',
    region: 'US',
  },
  {
    symbol: 'DAC',
    name: 'Danaos Corporation',
    region: 'US',
  },
  {
    symbol: 'DADA',
    name: 'Dada Nexus Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'DAIO',
    name: 'Data io Corp.',
    region: 'US',
  },
  {
    symbol: 'DAKT',
    name: 'Daktronics Inc.',
    region: 'US',
  },
  {
    symbol: 'DAL',
    name: 'Delta Air Lines, Inc.',
    region: 'US',
  },
  {
    symbol: 'DALI',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Dorsey Wright DALI 1 ETF',
    region: 'US',
  },
  {
    symbol: 'DALN',
    name: 'DallasNews Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'DALS',
    name: 'DA32 Life Science Tech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DALT',
    name: 'Northern Lights Fund Trust IV - Anfield Diversified Alternatives ETF',
    region: 'US',
  },
  {
    symbol: 'DAM',
    name: 'VanEck ETF Trust - VanEck Digital Assets Mining ETF',
    region: 'US',
  },
  {
    symbol: 'DAN',
    name: 'Dana Inc',
    region: 'US',
  },
  {
    symbol: 'DANOY',
    name: 'Danone - ADR',
    region: 'US',
  },
  {
    symbol: 'DAO',
    name: 'Youdao Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'DAOO',
    name: 'Crypto 1 Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DAOOU',
    name: 'Crypto 1 Acquisition Corp - Units (1 Ord Share Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'DAPP',
    name: 'VanEck ETF Trust - VanEck Digital Transformation ETF',
    region: 'US',
  },
  {
    symbol: 'DAPR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'DAR',
    name: 'Darling Ingredients Inc',
    region: 'US',
  },
  {
    symbol: 'DARE',
    name: 'Dare Bioscience Inc',
    region: 'US',
  },
  {
    symbol: 'DASH',
    name: 'DoorDash Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DAT',
    name: 'ProShares Trust - ProShares Big Data Refiners ETF',
    region: 'US',
  },
  {
    symbol: 'DATS',
    name: 'DatChat Inc',
    region: 'US',
  },
  {
    symbol: 'DAUG',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - August',
    region: 'US',
  },
  {
    symbol: 'DAVA',
    name: 'Endava plc - ADR',
    region: 'US',
  },
  {
    symbol: 'DAVE',
    name: 'Dave Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DAWN',
    name: 'Day One Biopharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'DAX',
    name: 'Global X Funds - Global X DAX Germany ETF',
    region: 'US',
  },
  {
    symbol: 'DB',
    name: 'Deutsche Bank AG - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'DBA',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Agriculture Fund',
    region: 'US',
  },
  {
    symbol: 'DBAW',
    name: 'DBX ETF Trust - Xtrackers MSCI All World ex US Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBB',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Base Metals Fund',
    region: 'US',
  },
  {
    symbol: 'DBC',
    name: 'DB Commodity Services LLC - Invesco DB Commodity Index Tracking Fund',
    region: 'US',
  },
  {
    symbol: 'DBD',
    name: 'Diebold Nixdorf Inc',
    region: 'US',
  },
  {
    symbol: 'DBE',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Energy Fund',
    region: 'US',
  },
  {
    symbol: 'DBEF',
    name: 'DBX ETF Trust - Xtrackers MSCI EAFE Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBEH',
    name: 'Manager Directed Portfolios - iMGP DBi Hedge Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'DBEM',
    name: 'DBX ETF Trust - Xtrackers MSCI Emerging Markets Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBEU',
    name: 'DBX ETF Trust - Xtrackers MSCI Europe Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBEZ',
    name: 'DBX ETF Trust - Xtrackers MSCI Eurozone Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBGI',
    name: 'Digital Brands Group Inc',
    region: 'US',
  },
  {
    symbol: 'DBGR',
    name: 'DBX ETF Trust - Xtrackers MSCI Germany Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBI',
    name: 'Designer Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DBJA',
    name: 'Innovator ETFs Trust - Innovator Double Stacker 9 Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'DBJP',
    name: 'DBX ETF Trust - Xtrackers MSCI Japan Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBLV',
    name: 'Advisorshares Trust - AdvisorShares DoubleLine Value Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DBMAX',
    name: 'BNY Mellon Investment Funds I: BNY Mellon Small/Mid Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DBMCX',
    name: 'BNY Mellon Investment Funds I: BNY Mellon Small/Mid Cap Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DBMF',
    name: 'Manager Directed Portfolios - iMGP DBi Managed Futures Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'DBMYX',
    name: 'BNY Mellon Investment Funds I: BNY Mellon Small/Mid Cap Growth Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DBMZX',
    name: 'BNY Mellon Investment Funds I: BNY Mellon Small/Mid Cap Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DBND',
    name: 'DoubleLine ETF Trust - DoubleLine Opportunistic Bond ETF',
    region: 'US',
  },
  {
    symbol: 'DBO',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Oil Fund',
    region: 'US',
  },
  {
    symbol: 'DBOC',
    name: 'Innovator ETFs Trust - Innovator Double Stacker 9 Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'DBP',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Precious Metals Fund',
    region: 'US',
  },
  {
    symbol: 'DBS',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Silver Fund',
    region: 'US',
  },
  {
    symbol: 'DBTX',
    name: 'Decibel Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DBV',
    name: 'DB Commodity Services LLC - Invesco DB G10 Currency Harvest Fund',
    region: 'US',
  },
  {
    symbol: 'DBVT',
    name: 'DBV Technologies - ADR',
    region: 'US',
  },
  {
    symbol: 'DBX',
    name: 'Dropbox Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DCBO',
    name: 'Docebo Inc',
    region: 'US',
  },
  {
    symbol: 'DCCAX',
    name: 'Delaware Group Equity Funds V: Delaware Small Cap Core Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DCCCX',
    name: 'Delaware Group Equity Funds V: Delaware Small Cap Core Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DCCIX',
    name: 'Delaware Group Equity Funds V: Delaware Small Cap Core Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DCCRX',
    name: 'Delaware Group Equity Funds V: Delaware Small Cap Core Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DCFC',
    name: 'Tritium DCFC Limited',
    region: 'US',
  },
  {
    symbol: 'DCGO',
    name: 'DocGo Inc',
    region: 'US',
  },
  {
    symbol: 'DCI',
    name: 'Donaldson Co. Inc.',
    region: 'US',
  },
  {
    symbol: 'DCMJF',
    name: 'DCM Holdings Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'DCO',
    name: 'Ducommun Inc.',
    region: 'US',
  },
  {
    symbol: 'DCOM',
    name: 'Dime Community Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'DCP',
    name: 'DCP Midstream LP - Unit',
    region: 'US',
  },
  {
    symbol: 'DCPE',
    name: 'DoubleLine ETF Trust - DoubleLine Shiller CAPE U.S. Equities ETF',
    region: 'US',
  },
  {
    symbol: 'DCPH',
    name: 'Deciphera Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'DCRD',
    name: 'Decarbonization Plus Acquisition Corp IV - Class A',
    region: 'US',
  },
  {
    symbol: 'DCRDU',
    name: 'Decarbonization Plus Acquisition Corp IV - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DCT',
    name: 'Duck Creek Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'DCTH',
    name: 'Delcath Systems Inc',
    region: 'US',
  },
  {
    symbol: 'DCUE',
    name: 'Dominion Energy Inc - Units - 2019 Series A',
    region: 'US',
  },
  {
    symbol: 'DCZRX',
    name: 'Delaware Group Equity Funds V: Delaware Small Cap Core Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DD',
    name: 'DuPont de Nemours Inc',
    region: 'US',
  },
  {
    symbol: 'DDD',
    name: '3D Systems Corp.',
    region: 'US',
  },
  {
    symbol: 'DDEC',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'DDG',
    name: 'ProShares Trust - ProShares Short Oil & Gas',
    region: 'US',
  },
  {
    symbol: 'DDI',
    name: 'DoubleDown Interactive Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'DDIV',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dorsey Wright Momentum & Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DDL',
    name: 'Dingdong (Cayman) Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'DDLS',
    name: 'WisdomTree Trust - WisdomTree Dynamic Currency Hedged International SmallCap Equity Fund',
    region: 'US',
  },
  {
    symbol: 'DDM',
    name: 'ProShares Trust - ProShares Ultra Dow30',
    region: 'US',
  },
  {
    symbol: 'DDOG',
    name: 'Datadog Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DDS',
    name: 'Dillard`s Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'DDWM',
    name: 'WisdomTree Trust - WisdomTree Dynamic Currency Hedged International Equity Fund',
    region: 'US',
  },
  {
    symbol: 'DE',
    name: 'Deere & Co.',
    region: 'US',
  },
  {
    symbol: 'DECK',
    name: 'Deckers Outdoor Corp.',
    region: 'US',
  },
  {
    symbol: 'DECZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (December) ETF',
    region: 'US',
  },
  {
    symbol: 'DEED',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Exchange-Traded Fund VIII ETF',
    region: 'US',
  },
  {
    symbol: 'DEEF',
    name: 'DBX ETF Trust - Xtrackers FTSE Developed ex US Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'DEEP',
    name: 'ETF Series Solutions Trust - Roundhill Acquirers Deep Value ETF',
    region: 'US',
  },
  {
    symbol: 'DEF',
    name: 'Invesco Capital Management LLC - Invesco Defensive Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DEHP',
    name: 'Dimensional ETF Trust - Dimensional Emerging Markets High Profitability ETF',
    region: 'US',
  },
  {
    symbol: 'DEIF',
    name: 'Northern Lights Fund Trust IV - Sterling Capital Diverse Multi-Manager Active ETF',
    region: 'US',
  },
  {
    symbol: 'DELL',
    name: 'Dell Technologies Inc - Class C',
    region: 'US',
  },
  {
    symbol: 'DEM',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets High Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DEMZ',
    name: 'Advisors Inner Circle Fund III - Democratic Large Cap Core ETF',
    region: 'US',
  },
  {
    symbol: 'DEN',
    name: 'Denbury Inc. - New',
    region: 'US',
  },
  {
    symbol: 'DENN',
    name: 'Denny`s Corp.',
    region: 'US',
  },
  {
    symbol: 'DEO',
    name: 'Diageo plc - ADR',
    region: 'US',
  },
  {
    symbol: 'DERM',
    name: 'Journey Medical Corp',
    region: 'US',
  },
  {
    symbol: 'DES',
    name: 'WisdomTree Trust - WisdomTree U.S. SmallCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DESP',
    name: 'Despegar.com Corp',
    region: 'US',
  },
  {
    symbol: 'DEUS',
    name: 'DBX ETF Trust - Xtrackers Russell US Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'DEW',
    name: 'WisdomTree Trust - WisdomTree Global High Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DFAC',
    name: 'Dimensional ETF Trust - Dimensional U.S. Core Equity 2 ETF',
    region: 'US',
  },
  {
    symbol: 'DFAE',
    name: 'Dimensional ETF Trust - Dimensional Emerging Core Equity Market ETF',
    region: 'US',
  },
  {
    symbol: 'DFAI',
    name: 'Dimensional ETF Trust - Dimensional International Core Equity Market ETF',
    region: 'US',
  },
  {
    symbol: 'DFAR',
    name: 'Dimensional ETF Trust - Dimensional US Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'DFAS',
    name: 'Dimensional ETF Trust - Dimensional U.S. Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'DFAT',
    name: 'Dimensional ETF Trust - Dimensional U.S. Targeted Value ETF',
    region: 'US',
  },
  {
    symbol: 'DFAU',
    name: 'Dimensional ETF Trust - Dimensional US Core Equity Market ETF',
    region: 'US',
  },
  {
    symbol: 'DFAX',
    name: 'Dimensional ETF Trust - Dimensional World ex U.S. Core Equity 2 ETF',
    region: 'US',
  },
  {
    symbol: 'DFCF',
    name: 'Dimensional ETF Trust - Dimensional Core Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'DFE',
    name: 'WisdomTree Trust - WisdomTree Europe SmallCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DFEB',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - February',
    region: 'US',
  },
  {
    symbol: 'DFEM',
    name: 'Dimensional ETF Trust - Dimensional Emerging Markets Core Equity 2 ETF',
    region: 'US',
  },
  {
    symbol: 'DFEN',
    name: 'Direxion Shares ETF Trust - Direxion Daily Aerospace & Defense Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'DFEV',
    name: 'Dimensional ETF Trust - Dimensional Emerging Markets Value ETF',
    region: 'US',
  },
  {
    symbol: 'DFFN',
    name: 'Diffusion Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'DFH',
    name: 'Dream Finders Homes Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DFHY',
    name: 'TrimTabs ETF Trust - Donoghue Forlines Tactical High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'DFIC',
    name: 'Dimensional ETF Trust - Dimensional International Core Equity 2 ETF',
    region: 'US',
  },
  {
    symbol: 'DFIN',
    name: 'Donnelley Financial Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'DFIP',
    name: 'Dimensional ETF Trust - Dimensional Inflation-Protected Securities ETF',
    region: 'US',
  },
  {
    symbol: 'DFIS',
    name: 'Dimensional ETF Trust - Dimensional International Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'DFIV',
    name: 'Dimensional ETF Trust - Dimensional International Value ETF',
    region: 'US',
  },
  {
    symbol: 'DFJ',
    name: 'WisdomTree Trust - WisdomTree Japan SmallCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DFND',
    name: 'Siren ETF Trust - Siren DIVCON Dividend Defender ETF',
    region: 'US',
  },
  {
    symbol: 'DFNL',
    name: 'Davis Selected Advisers L.P - Davis Select Financial ETF',
    region: 'US',
  },
  {
    symbol: 'DFNM',
    name: 'Dimensional ETF Trust - Dimensional National Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'DFNV',
    name: 'TrimTabs ETF Trust - Donoghue Forlines Risk Managed Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'DFRA',
    name: 'TrimTabs ETF Trust - Donoghue Forlines Yield Enhanced Real Asset ETF',
    region: 'US',
  },
  {
    symbol: 'DFREX',
    name: 'DFA Investment Dimensions Group Inc: DFA Real Estate Securities Portfolio; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DFS',
    name: 'Discover Financial Services',
    region: 'US',
  },
  {
    symbol: 'DFSD',
    name: 'Dimensional ETF Trust - Dimensional Short-Duration Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'DFSIX',
    name: 'DFA Investment Dimensions Group Inc: US Sustainability Core 1 Portfolio; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DFSTX',
    name: 'DFA Investment Dimensions Group Inc: US Small Cap Portfolio; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DFSV',
    name: 'Dimensional ETF Trust - Dimensional US Small Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'DFTPX',
    name: 'DFA Investment Dimensions Group Inc: US Targeted Value Portfolio; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DFTVX',
    name: 'DFA Investment Dimensions Group Inc: US Targeted Value Portfolio; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DFUS',
    name: 'Dimensional ETF Trust - Dimensional U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DFUSX',
    name: 'Dimensional Investment Group Inc: US Large Company Portfolio; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DFVEX',
    name: 'DFA Investment Dimensions Group Inc: US Vector Equity Portfolio; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DG',
    name: 'Dollar General Corp.',
    region: 'US',
  },
  {
    symbol: 'DGAGX',
    name: 'BNY Mellon Appreciation Fund, Inc; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DGHI',
    name: 'Digihost Technology Inc (Sub voting)',
    region: 'US',
  },
  {
    symbol: 'DGICA',
    name: 'Donegal Group Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'DGICB',
    name: 'Donegal Group Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'DGIGX',
    name: 'BNY Mellon Appreciation Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DGII',
    name: 'Digi International, Inc.',
    region: 'US',
  },
  {
    symbol: 'DGIN',
    name: 'VanEck ETF Trust - VanEck Digital India ETF',
    region: 'US',
  },
  {
    symbol: 'DGL',
    name: 'Invesco DB Multi-Sector Commodity Trust - Invesco DB Gold Fund',
    region: 'US',
  },
  {
    symbol: 'DGLY',
    name: 'Digital Ally Inc.',
    region: 'US',
  },
  {
    symbol: 'DGNU',
    name: 'Dragoneer Growth Opportunities Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'DGRE',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'DGRO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core Dividend Growth ETF',
    region: 'US',
  },
  {
    symbol: 'DGRS',
    name: 'WisdomTree Trust - WisdomTree U.S. SmallCap Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'DGRW',
    name: 'WisdomTree Trust - WisdomTree U.S. Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'DGS',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets SmallCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DGT',
    name: 'SPDR Series Trust - SPDR Global Dow ETF',
    region: 'US',
  },
  {
    symbol: 'DGX',
    name: 'Quest Diagnostics, Inc.',
    region: 'US',
  },
  {
    symbol: 'DGYGX',
    name: 'BNY Mellon Appreciation Fund, Inc; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DH',
    name: 'Definitive Healthcare Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DHAC',
    name: 'Digital Health Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'DHACU',
    name: 'Digital Health Acquisition Corp - Unit (1 Ordinary share & 1 Wrt)',
    region: 'US',
  },
  {
    symbol: 'DHBC',
    name: 'DHB Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DHBCU',
    name: 'DHB Capital Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'DHCA',
    name: 'DHC Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DHCAU',
    name: 'DHC Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'DHHC',
    name: 'DiamondHead Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DHHCU',
    name: 'DiamondHead Holdings Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'DHI',
    name: 'D.R. Horton Inc.',
    region: 'US',
  },
  {
    symbol: 'DHLAX',
    name: 'Diamond Hill Funds: Diamond Hill Large Cap Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DHLRX',
    name: 'Diamond Hill Funds: Diamond Hill Large Cap Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DHLYX',
    name: 'Diamond Hill Funds: Diamond Hill Large Cap Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DHR',
    name: 'Danaher Corp.',
    region: 'US',
  },
  {
    symbol: 'DHS',
    name: 'WisdomTree Trust - WisdomTree U.S. High Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DHT',
    name: 'DHT Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'DHX',
    name: 'DHI Group Inc',
    region: 'US',
  },
  {
    symbol: 'DIA',
    name: 'SSgA Active Trust - SPDR Dow Jones Industrial Average ETF',
    region: 'US',
  },
  {
    symbol: 'DIAL',
    name: 'Columbia ETF Trust I - Columbia Diversified Fixed Income Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'DIBS',
    name: '1stdibs.com Inc',
    region: 'US',
  },
  {
    symbol: 'DICE',
    name: 'DICE Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DIDI',
    name: 'DiDi Global Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'DIG',
    name: 'ProShares Trust - ProShares Ultra Oil & Gas',
    region: 'US',
  },
  {
    symbol: 'DIGGX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco Discovery Fund; Class R5 Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DIHP',
    name: 'Dimensional ETF Trust - Dimensional International High Profitability ETF',
    region: 'US',
  },
  {
    symbol: 'DILA',
    name: 'DILA Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DILAU',
    name: 'DILA Capital Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'DIM',
    name: 'WisdomTree Trust - WisdomTree International MidCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DIN',
    name: 'Dine Brands Global Inc',
    region: 'US',
  },
  {
    symbol: 'DINO',
    name: 'HF Sinclair Corporation',
    region: 'US',
  },
  {
    symbol: 'DINT',
    name: 'Davis Selected Advisers L.P - Davis Select International ETF',
    region: 'US',
  },
  {
    symbol: 'DIOD',
    name: 'Diodes, Inc.',
    region: 'US',
  },
  {
    symbol: 'DIS',
    name: 'Walt Disney Co (The)',
    region: 'US',
  },
  {
    symbol: 'DISA',
    name: 'Disruptive Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'DISAU',
    name: 'Disruptive Acquisition Corp I - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'DISH',
    name: 'Dish Network Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DISV',
    name: 'Dimensional ETF Trust - Dimensional International Small Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'DIT',
    name: 'Amcon Distributing Company',
    region: 'US',
  },
  {
    symbol: 'DIV',
    name: 'Global X Funds - Global X SuperDividend U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'DIVA',
    name: 'AGF Investments Trust - AGFiQ Hedged Dividend Income Fund',
    region: 'US',
  },
  {
    symbol: 'DIVB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Dividend and Buyback ETF',
    region: 'US',
  },
  {
    symbol: 'DIVO',
    name: 'Amplify ETF Trust - Amplify CPW Enhanced Dividend Income ETF',
    region: 'US',
  },
  {
    symbol: 'DIVS',
    name: 'Guinness Atkinson Funds - SmartETFs Dividend Builder ETF',
    region: 'US',
  },
  {
    symbol: 'DIVZ',
    name: 'Listed Funds Trust - TrueShares Low Volatility Equity Income ETF',
    region: 'US',
  },
  {
    symbol: 'DJAN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'DJCO',
    name: 'Daily Journal Corporation',
    region: 'US',
  },
  {
    symbol: 'DJD',
    name: 'Invesco Capital Management LLC - Invesco Dow Jones Industrial Average Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DJIA',
    name: 'Global X Funds - Global X Dow 30 Covered Call ETF',
    region: 'US',
  },
  {
    symbol: 'DJUL',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'DJUN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'DK',
    name: 'Delek US Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'DKDCA',
    name: 'Data Knights Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DKDCU',
    name: 'Data Knights Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'DKL',
    name: 'Delek Logistics Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'DKNG',
    name: 'DraftKings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DKS',
    name: 'Dicks Sporting Goods, Inc.',
    region: 'US',
  },
  {
    symbol: 'DLA',
    name: 'Delta Apparel Inc.',
    region: 'US',
  },
  {
    symbol: 'DLB',
    name: 'Dolby Laboratories Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DLCA',
    name: 'Deep Lake Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DLCAU',
    name: 'Deep Lake Capital Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DLHC',
    name: 'DLH Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'DLN',
    name: 'WisdomTree Trust - WisdomTree U.S. LargeCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DLNG',
    name: 'Dynagas LNG Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'DLO',
    name: 'DLocal Limited Class A',
    region: 'US',
  },
  {
    symbol: 'DLPN',
    name: 'Dolphin Entertainment Inc.',
    region: 'US',
  },
  {
    symbol: 'DLS',
    name: 'WisdomTree Trust - WisdomTree International SmallCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DLTH',
    name: 'Duluth Holdings Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'DLTR',
    name: 'Dollar Tree Inc',
    region: 'US',
  },
  {
    symbol: 'DLX',
    name: 'Deluxe Corp.',
    region: 'US',
  },
  {
    symbol: 'DM',
    name: 'Desktop Metal Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DMAC',
    name: 'DiaMedica Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DMAQ',
    name: 'Deep Medicine Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DMAR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'DMAT',
    name: 'Global X Funds - Global X Disruptive Materials ETF',
    region: 'US',
  },
  {
    symbol: 'DMAY',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - May',
    region: 'US',
  },
  {
    symbol: 'DMCFX',
    name: 'AIM Investment Funds (Invesco Investment Funds): Invesco Discovery Mid Cap Growth Fd; Class R5 Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DMCY',
    name: 'Advisors Inner Circle Fund III - Democracy International Fund',
    region: 'US',
  },
  {
    symbol: 'DMDV',
    name: 'ETF Series Solutions Trust - AAM S&P Developed Markets High Dividend Value ETF',
    region: 'US',
  },
  {
    symbol: 'DMLP',
    name: 'Dorchester Minerals LP - Units',
    region: 'US',
  },
  {
    symbol: 'DMRC',
    name: 'Digimarc Corporation',
    region: 'US',
  },
  {
    symbol: 'DMS',
    name: 'Digital Media Solutions Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DMTK',
    name: 'DermTech Inc',
    region: 'US',
  },
  {
    symbol: 'DMXF',
    name: 'iShares Trust - iShares ESG Advanced MSCI EAFE ETF',
    region: 'US',
  },
  {
    symbol: 'DMYS',
    name: 'dMY Technology Group Inc VI - Class A',
    region: 'US',
  },
  {
    symbol: 'DNA',
    name: 'Ginkgo Bioworks Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DNAA',
    name: 'Social Capital Suvretta Holdings Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'DNAB',
    name: 'Social Capital Suvretta Holdings Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'DNAC',
    name: 'Social Capital Suvretta Holdings Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'DNAD',
    name: 'Social Capital Suvretta Holdings Corp IV - Class A',
    region: 'US',
  },
  {
    symbol: 'DNAY',
    name: 'Codex DNA Inc',
    region: 'US',
  },
  {
    symbol: 'DNB',
    name: 'Dun & Bradstreet Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'DNL',
    name: 'WisdomTree Trust - WisdomTree Global ex-U.S. Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'DNLI',
    name: 'Denali Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DNMR',
    name: 'Danimer Scientific Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DNN',
    name: 'Denison Mines Corp',
    region: 'US',
  },
  {
    symbol: 'DNOV',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - November',
    region: 'US',
  },
  {
    symbol: 'DNOW',
    name: 'NOW Inc',
    region: 'US',
  },
  {
    symbol: 'DNUT',
    name: 'Krispy Kreme Inc',
    region: 'US',
  },
  {
    symbol: 'DNZ',
    name: 'D and Z Media Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DOCN',
    name: 'DigitalOcean Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'DOCS',
    name: 'Doximity Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DOCT',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'DOCU',
    name: 'DocuSign Inc',
    region: 'US',
  },
  {
    symbol: 'DOG',
    name: 'ProShares Trust - ProShares Short Dow30',
    region: 'US',
  },
  {
    symbol: 'DOGZ',
    name: 'Dogness (International) Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DOL',
    name: 'WisdomTree Trust - WisdomTree International LargeCap Dividend Index',
    region: 'US',
  },
  {
    symbol: 'DOLE',
    name: 'Dole plc',
    region: 'US',
  },
  {
    symbol: 'DOMA',
    name: 'Doma Holdings Inc - New',
    region: 'US',
  },
  {
    symbol: 'DOMO',
    name: 'Domo Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'DON',
    name: 'WisdomTree Trust - WisdomTree U.S. MidCap Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DOOO',
    name: 'BRP Inc',
    region: 'US',
  },
  {
    symbol: 'DOOR',
    name: 'Masonite International Corp',
    region: 'US',
  },
  {
    symbol: 'DORM',
    name: 'Dorman Products Inc',
    region: 'US',
  },
  {
    symbol: 'DOV',
    name: 'Dover Corp.',
    region: 'US',
  },
  {
    symbol: 'DOW',
    name: 'Dow Inc',
    region: 'US',
  },
  {
    symbol: 'DOX',
    name: 'Amdocs Ltd',
    region: 'US',
  },
  {
    symbol: 'DOYU',
    name: 'DouYu International Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'DOZR',
    name: 'Direxion Shares ETF Trust - Direxion Daily US Infrastructure Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'DPCS',
    name: 'DP Cap Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'DPCSU',
    name: 'DP Cap Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DPRIX',
    name: 'BNY Mellon Worldwide Growth Fund, Inc; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DPRO',
    name: 'Draganfly Inc',
    region: 'US',
  },
  {
    symbol: 'DPST',
    name: 'Direxion Shares ETF Trust - Direxion Daily Regional Banks Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'DPWRX',
    name: 'BNY Mellon Worldwide Growth Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DPZ',
    name: 'Dominos Pizza Inc',
    region: 'US',
  },
  {
    symbol: 'DQ',
    name: 'Daqo New Energy Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'DQIYX',
    name: 'BNY Mellon Investment Funds III: BNY Mellon Equity Income Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DRAY',
    name: 'Macondray Capital Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'DRAYU',
    name: 'Macondray Capital Acquisition Corp I - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'DRD',
    name: 'DRDGold Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'DRI',
    name: 'Darden Restaurants, Inc.',
    region: 'US',
  },
  {
    symbol: 'DRIO',
    name: 'DarioHealth Corp',
    region: 'US',
  },
  {
    symbol: 'DRIP',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 2X Shares',
    region: 'US',
  },
  {
    symbol: 'DRIV',
    name: 'Global X Funds - Global X Autonomous & Electric Vehicles ETF',
    region: 'US',
  },
  {
    symbol: 'DRMA',
    name: 'Dermata Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DRN',
    name: 'Direxion Shares ETF Trust - Direxion Daily Real Estate Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'DRQ',
    name: 'Dril-Quip, Inc.',
    region: 'US',
  },
  {
    symbol: 'DRRX',
    name: 'Durect Corp',
    region: 'US',
  },
  {
    symbol: 'DRSK',
    name: 'ETF Series Solutions Trust - Aptus Defined Risk ETF',
    region: 'US',
  },
  {
    symbol: 'DRTT',
    name: 'DIRTT Environmental Solutions Ltd',
    region: 'US',
  },
  {
    symbol: 'DRUG',
    name: 'Bright Minds Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'DRV',
    name: 'Direxion Shares ETF Trust - Direxion Daily Real Estate Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'DRVN',
    name: 'Driven Brands Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'DS',
    name: 'Drive Shack Inc',
    region: 'US',
  },
  {
    symbol: 'DSAC',
    name: 'Duddell Street Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DSACU',
    name: 'Duddell Street Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DSAQ',
    name: 'Direct Selling Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DSCF',
    name: 'Alpha Architect Etf Trust - Discipline Fund ETF',
    region: 'US',
  },
  {
    symbol: 'DSDVF',
    name: 'DSV AS - Class B',
    region: 'US',
  },
  {
    symbol: 'DSEP',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Deep Buffer ETF - September',
    region: 'US',
  },
  {
    symbol: 'DSEY',
    name: 'Diversey Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'DSGN',
    name: 'Design Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DSGX',
    name: 'Descartes Systems Group Inc',
    region: 'US',
  },
  {
    symbol: 'DSI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI KLD 400 Social Index Fund',
    region: 'US',
  },
  {
    symbol: 'DSJA',
    name: 'Innovator ETFs Trust - Innovator Double Stacker ETF - January',
    region: 'US',
  },
  {
    symbol: 'DSKE',
    name: 'Daseke Inc',
    region: 'US',
  },
  {
    symbol: 'DSOC',
    name: 'Innovator ETFs Trust - Innovator Double Stacker ETF - October',
    region: 'US',
  },
  {
    symbol: 'DSP',
    name: 'Viant Technology Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DSPC',
    name: 'Collaborative Investment Series Trust - The De-SPAC ETF',
    region: 'US',
  },
  {
    symbol: 'DSPIX',
    name: 'BNY Mellon Investment Funds IV, Inc: BNY Mellon Institutional S&P 500 Stock Index Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'DSS',
    name: 'DSS Inc',
    region: 'US',
  },
  {
    symbol: 'DSTL',
    name: 'ETF Series Solutions Trust - Distillate U.S. Fundamental Stability & Value ETF',
    region: 'US',
  },
  {
    symbol: 'DSTX',
    name: 'ETF Series Solutions Trust - Distillate International Fundamental Stability & Value ETF',
    region: 'US',
  },
  {
    symbol: 'DSWL',
    name: 'Deswell Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'DSX',
    name: 'Diana Shipping Inc',
    region: 'US',
  },
  {
    symbol: 'DT',
    name: 'Dynatrace Inc',
    region: 'US',
  },
  {
    symbol: 'DTC',
    name: 'Solo Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DTD',
    name: 'WisdomTree Trust - WisdomTree U.S. Total Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DTE',
    name: 'DTE Energy Co.',
    region: 'US',
  },
  {
    symbol: 'DTEA',
    name: 'Davidstea Inc',
    region: 'US',
  },
  {
    symbol: 'DTEC',
    name: 'ALPS Advisors Inc - ALPS Disruptive Technologies ETF',
    region: 'US',
  },
  {
    symbol: 'DTEGF',
    name: 'Deutsche Telekom AG - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'DTEGY',
    name: 'Deutsche Telekom AG - ADR',
    region: 'US',
  },
  {
    symbol: 'DTH',
    name: 'WisdomTree Trust - WisdomTree International High Dividend Fund',
    region: 'US',
  },
  {
    symbol: 'DTIL',
    name: 'Precision Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'DTM',
    name: 'DT Midstream Inc',
    region: 'US',
  },
  {
    symbol: 'DTOC',
    name: 'Digital Transformation Opportunities Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DTOCU',
    name: 'Digital Transformation Opportunities Corp - Units (1 Ord Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'DTOX',
    name: 'Amplify ETF Trust - Amplify Cleaner Living ETF',
    region: 'US',
  },
  {
    symbol: 'DTRT',
    name: 'DTRT Health Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DTRTU',
    name: 'DTRT Health Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DTSS',
    name: 'Datasea Inc',
    region: 'US',
  },
  {
    symbol: 'DTST',
    name: 'Data Storage Corp',
    region: 'US',
  },
  {
    symbol: 'DUDE',
    name: 'Alpha Architect Etf Trust - Merlyn.AI SectorSurfer Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'DUET',
    name: 'DUET Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DUETU',
    name: 'DUET Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'DUG',
    name: 'ProShares Trust - ProShares UltraShort Oil & Gas',
    region: 'US',
  },
  {
    symbol: 'DUHP',
    name: 'Dimensional ETF Trust - Dimensional US High Profitability ETF',
    region: 'US',
  },
  {
    symbol: 'DUK',
    name: 'Duke Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'DUNE',
    name: 'Dune Acquisition Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'DUNEU',
    name: 'Dune Acquisition Corporation - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DUO',
    name: 'Fangdd Network Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'DUOL',
    name: 'Duolingo Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'DUOT',
    name: 'Duos Technologies Group Inc',
    region: 'US',
  },
  {
    symbol: 'DURA',
    name: 'VanEck ETF Trust - VanEck Morningstar Durable Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DUSA',
    name: 'Davis Selected Advisers L.P - Davis Select U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'DUSL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Industrials Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'DUST',
    name: 'Direxion Shares ETF Trust - Direxion Daily Gold Miners Index Bear 2X Shares',
    region: 'US',
  },
  {
    symbol: 'DV',
    name: 'DoubleVerify Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'DVA',
    name: 'DaVita Inc',
    region: 'US',
  },
  {
    symbol: 'DVAX',
    name: 'Dynavax Technologies Corp.',
    region: 'US',
  },
  {
    symbol: 'DVLU',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Dorsey Wright Momentum & Value ETF',
    region: 'US',
  },
  {
    symbol: 'DVN',
    name: 'Devon Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'DVOL',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Dorsey Wright Momentum & Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'DVY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Select Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DVYA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Asia/Pacific Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DVYE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Emerging Markets Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DWAC',
    name: 'Digital World Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DWACU',
    name: 'Digital World Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'DWAS',
    name: 'Invesco Capital Management LLC - Invesco DWA SmallCap Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'DWAT',
    name: 'Arrow Investments Trust - Arrow DWA Tactical: Macro ETF',
    region: 'US',
  },
  {
    symbol: 'DWAW',
    name: 'Advisorshares Trust - AdvisorShares Dorsey Wright FSM All Cap World ETF',
    region: 'US',
  },
  {
    symbol: 'DWCR',
    name: 'Arrow Investments Trust - Arrow DWA Tactical: International ETF',
    region: 'US',
  },
  {
    symbol: 'DWEQ',
    name: 'Advisorshares Trust - AdvisorShares Dorsey Wright Alpha Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'DWIN',
    name: 'Delwinds Insurance Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'DWLD',
    name: 'Davis Selected Advisers L.P - Davis Select Worldwide ETF',
    region: 'US',
  },
  {
    symbol: 'DWM',
    name: 'WisdomTree Trust - WisdomTree International Equity Fund',
    region: 'US',
  },
  {
    symbol: 'DWMC',
    name: 'Advisorshares Trust - AdvisorShares Dorsey Wright Micro-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'DWMF',
    name: 'WisdomTree Trust - WisdomTree International Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'DWPP',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dorsey Wright People`s Portfolio ETF',
    region: 'US',
  },
  {
    symbol: 'DWSH',
    name: 'Advisorshares Trust - AdvisorShares Dorsey Wright Short ETF',
    region: 'US',
  },
  {
    symbol: 'DWSN',
    name: 'Dawson Geophysical Company',
    region: 'US',
  },
  {
    symbol: 'DWUS',
    name: 'Advisorshares Trust - AdvisorShares Dorsey Wright FSM US Core ETF',
    region: 'US',
  },
  {
    symbol: 'DWX',
    name: 'SSgA Active Trust - SPDR S&P International Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'DXC',
    name: 'DXC Technology Co',
    region: 'US',
  },
  {
    symbol: 'DXCM',
    name: 'Dexcom Inc',
    region: 'US',
  },
  {
    symbol: 'DXD',
    name: 'ProShares Trust - ProShares UltraShort Dow30',
    region: 'US',
  },
  {
    symbol: 'DXF',
    name: 'Dunxin Financial Holdings Limited. - ADR',
    region: 'US',
  },
  {
    symbol: 'DXGE',
    name: 'WisdomTree Trust - WisdomTree Germany Hedged Equity Fund',
    region: 'US',
  },
  {
    symbol: 'DXJ',
    name: 'WisdomTree Trust - WisdomTree Japan Hedged Equity Fund',
    region: 'US',
  },
  {
    symbol: 'DXJS',
    name: 'WisdomTree Trust - WisdomTree Japan Hedged SmallCap Equity Fund',
    region: 'US',
  },
  {
    symbol: 'DXLG',
    name: 'Destination XL Group Inc',
    region: 'US',
  },
  {
    symbol: 'DXPE',
    name: 'DXP Enterprises, Inc.',
    region: 'US',
  },
  {
    symbol: 'DXR',
    name: 'Daxor Corp.',
    region: 'US',
  },
  {
    symbol: 'DXYN',
    name: 'Dixie Group Inc.',
    region: 'US',
  },
  {
    symbol: 'DY',
    name: 'Dycom Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'DYAI',
    name: 'Dyadic International Inc., DE',
    region: 'US',
  },
  {
    symbol: 'DYLD',
    name: 'Two Roads Shared Trust - LeaderShares Dynamic Yield ETF',
    region: 'US',
  },
  {
    symbol: 'DYN',
    name: 'Dyne Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'DYNF',
    name: 'BlackRock ETF Trust - BlackRock U.S. Equity Factor Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'DYNS',
    name: 'Dynamics Special Purpose Corp Class A',
    region: 'US',
  },
  {
    symbol: 'DYNT',
    name: 'Dynatronics Corp.',
    region: 'US',
  },
  {
    symbol: 'DZSI',
    name: 'DZS Inc',
    region: 'US',
  },
  {
    symbol: 'E',
    name: 'Eni Spa - ADR',
    region: 'US',
  },
  {
    symbol: 'EA',
    name: 'Electronic Arts, Inc.',
    region: 'US',
  },
  {
    symbol: 'EAC',
    name: 'Edify Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EACPU',
    name: 'Edify Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'EACVX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class 529A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EAF',
    name: 'GrafTech International Ltd.',
    region: 'US',
  },
  {
    symbol: 'EAFD',
    name: 'Simplify Exchange Traded Funds - Simplify Developed Ex-US PLUS Downside Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'EAGG',
    name: 'iShares Trust - iShares ESG Aware U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EAMCX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class 529A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EAOA',
    name: 'iShares Trust - iShares ESG Aware Aggressive Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'EAOK',
    name: 'iShares Trust - iShares ESG Aware Conservative Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'EAOM',
    name: 'iShares Trust - iShares ESG Aware Moderate Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'EAOR',
    name: 'iShares Trust - iShares ESG Aware Growth Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'EAPR',
    name: 'Innovator ETFs Trust - Innovator Emerging Markets Power Buffer ETF April',
    region: 'US',
  },
  {
    symbol: 'EAR',
    name: 'Eargo Inc',
    region: 'US',
  },
  {
    symbol: 'EASG',
    name: 'DBX ETF Trust - Xtrackers MSCI EAFE ESG Leaders Equity ETF',
    region: 'US',
  },
  {
    symbol: 'EAST',
    name: 'Eastside Distilling Inc',
    region: 'US',
  },
  {
    symbol: 'EAT',
    name: 'Brinker International, Inc.',
    region: 'US',
  },
  {
    symbol: 'EATV',
    name: 'Advisors Series Trust - VegTech Plant-based Innovation & Climate ETF',
    region: 'US',
  },
  {
    symbol: 'EATZ',
    name: 'Advisorshares Trust - AdvisorShares Restaurant ETF',
    region: 'US',
  },
  {
    symbol: 'EAVLX',
    name: 'MFS Series Trust I: MFS Value Fund; Class 529A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EB',
    name: 'Eventbrite Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EBAC',
    name: 'European Biotech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EBACU',
    name: 'European Biotech Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'EBAY',
    name: 'EBay Inc.',
    region: 'US',
  },
  {
    symbol: 'EBC',
    name: 'Eastern Bankshares Inc.',
    region: 'US',
  },
  {
    symbol: 'EBCGX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class 529B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EBCVX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class 529B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EBET',
    name: 'Esports Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'EBF',
    name: 'Ennis Inc.',
    region: 'US',
  },
  {
    symbol: 'EBIX',
    name: 'Ebix Inc.',
    region: 'US',
  },
  {
    symbol: 'EBIZ',
    name: 'Global X Funds - Global X E-commerce ETF',
    region: 'US',
  },
  {
    symbol: 'EBLU',
    name: 'Tortoise Index Solutions - Ecofin Global Water ESG Fund',
    region: 'US',
  },
  {
    symbol: 'EBMT',
    name: 'Eagle Bancorp Montana Inc',
    region: 'US',
  },
  {
    symbol: 'EBND',
    name: 'SPDR Series Trust - SPDR Bloomberg Emerging Markets Local Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EBON',
    name: 'Ebang International Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EBR',
    name: 'Centrais Eletricas Brasileiras S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'EBR.B',
    name: 'Centrais Eletricas Brasileiras S.A. - ADR - Pref. Shares - Class B',
    region: 'US',
  },
  {
    symbol: 'EBS',
    name: 'Emergent Biosolutions Inc',
    region: 'US',
  },
  {
    symbol: 'EBTC',
    name: 'Enterprise Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'EC',
    name: 'Ecopetrol SA - ADR',
    region: 'US',
  },
  {
    symbol: 'ECGRX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class 529C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ECH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Chile ETF',
    region: 'US',
  },
  {
    symbol: 'ECL',
    name: 'Ecolab, Inc.',
    region: 'US',
  },
  {
    symbol: 'ECLN',
    name: 'First Trust Exchange-Traded Fund IV - First Trust EIP Carbon Impact ETF',
    region: 'US',
  },
  {
    symbol: 'ECNS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI China Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'ECOL',
    name: 'US Ecology Inc.',
    region: 'US',
  },
  {
    symbol: 'ECOM',
    name: 'ChannelAdvisor Corp',
    region: 'US',
  },
  {
    symbol: 'ECON',
    name: 'Columbia ETF Trust II - Columbia Emerging Markets Consumer ETF',
    region: 'US',
  },
  {
    symbol: 'ECOR',
    name: 'ElectroCore Inc',
    region: 'US',
  },
  {
    symbol: 'ECOW',
    name: 'Pacer Funds Trust - Pacer Emerging Markets Cash Cows 100 ETF',
    region: 'US',
  },
  {
    symbol: 'ECOZ',
    name: 'Listed Funds Trust - TrueShares ESG Active Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'ECVT',
    name: 'Ecovyst Inc',
    region: 'US',
  },
  {
    symbol: 'ED',
    name: 'Consolidated Edison, Inc.',
    region: 'US',
  },
  {
    symbol: 'EDAP',
    name: 'EDAP TMS S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'EDC',
    name: 'Direxion Shares ETF Trust - Direxion Daily Emerging Markets Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'EDEN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Denmark ETF',
    region: 'US',
  },
  {
    symbol: 'EDIT',
    name: 'Editas Medicine Inc',
    region: 'US',
  },
  {
    symbol: 'EDIV',
    name: 'SSgA Active Trust - SPDR S&P Emerging Markets Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'EDN',
    name: 'Empresa Distribuidora y Comercial Norte S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'EDNC',
    name: 'Endurance Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EDNCU',
    name: 'Endurance Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'EDOC',
    name: 'Global X Funds - Global X Telemedicine & Digital Health ETF',
    region: 'US',
  },
  {
    symbol: 'EDOG',
    name: 'ALPS Fund Services - ALPS Emerging Sector Dividend Dogs ETF',
    region: 'US',
  },
  {
    symbol: 'EDOW',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dow 30 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'EDR',
    name: 'Endeavor Group Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EDRY',
    name: 'EuroDry Ltd',
    region: 'US',
  },
  {
    symbol: 'EDSA',
    name: 'Edesa Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'EDTK',
    name: 'Skillful Craftsman Education Technology Ltd',
    region: 'US',
  },
  {
    symbol: 'EDTX',
    name: 'EdtechX Holdings Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'EDTXU',
    name: 'EdtechX Holdings Acquisition Corp II - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'EDU',
    name: 'New Oriental Education & Technology Group Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'EDUC',
    name: 'Educational Development Corp.',
    region: 'US',
  },
  {
    symbol: 'EDUT',
    name: 'Global X Funds - Global X Education ETF',
    region: 'US',
  },
  {
    symbol: 'EDV',
    name: 'Vanguard Group, Inc. - Vanguard Extended Duration Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'EDZ',
    name: 'Direxion Shares ETF Trust - Direxion Daily MSCI Emerging Markets Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'EE',
    name: 'Excelerate Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EEFT',
    name: 'Euronet Worldwide Inc',
    region: 'US',
  },
  {
    symbol: 'EEIQ',
    name: 'Elite Education Group International Ltd',
    region: 'US',
  },
  {
    symbol: 'EELV',
    name: 'Invesco Capital Management LLC - Invesco S&P Emerging Markets Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'EEM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'EEMA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Emerging Markets Asia ETF',
    region: 'US',
  },
  {
    symbol: 'EEMD',
    name: 'ETF Series Solutions Trust - AAM S&P Emerging Markets High Dividend Value ETF',
    region: 'US',
  },
  {
    symbol: 'EEMO',
    name: 'Invesco Capital Management LLC - Invesco S&P Emerging Markets Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'EEMS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Emerging Markets Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'EEMV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Emerging Markets Min Vol Factor ETF',
    region: 'US',
  },
  {
    symbol: 'EEMX',
    name: 'SSgA Active Trust - SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF',
    region: 'US',
  },
  {
    symbol: 'EERN',
    name: 'Rbb Fund Inc - DriveWealth Power Saver ETF',
    region: 'US',
  },
  {
    symbol: 'EES',
    name: 'WisdomTree Trust - WisdomTree U.S. SmallCap Fund',
    region: 'US',
  },
  {
    symbol: 'EET',
    name: 'ProShares Trust - ProShares Ultra MSCI Emerging Markets',
    region: 'US',
  },
  {
    symbol: 'EEV',
    name: 'ProShares Trust - ProShares UltraShort MSCI Emerging Markets',
    region: 'US',
  },
  {
    symbol: 'EEX',
    name: 'Emerald Holding Inc',
    region: 'US',
  },
  {
    symbol: 'EFA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI EAFE ETF',
    region: 'US',
  },
  {
    symbol: 'EFAD',
    name: 'ProShares Trust - ProShares MSCI EAFE Dividend Growers ETF',
    region: 'US',
  },
  {
    symbol: 'EFAS',
    name: 'Global X Funds - Global X MSCI SuperDividend EAFE ETF',
    region: 'US',
  },
  {
    symbol: 'EFAV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI EAFE Min Vol Factor ETF',
    region: 'US',
  },
  {
    symbol: 'EFAX',
    name: 'SSgA Active Trust - SPDR MSCI EAFE Fossil Fuel Reserves Free ETF',
    region: 'US',
  },
  {
    symbol: 'EFG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI EAFE Growth ETF',
    region: 'US',
  },
  {
    symbol: 'EFIV',
    name: 'SPDR Series Trust - SPDR S&P 500 ESG ETF',
    region: 'US',
  },
  {
    symbol: 'EFIX',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust TCW Emerging Markets Debt ETF',
    region: 'US',
  },
  {
    symbol: 'EFNL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Finland ETF',
    region: 'US',
  },
  {
    symbol: 'EFO',
    name: 'ProShares Trust - ProShares Ultra MSCI EAFE',
    region: 'US',
  },
  {
    symbol: 'EFOI',
    name: 'Energy Focus Inc',
    region: 'US',
  },
  {
    symbol: 'EFSC',
    name: 'Enterprise Financial Services Corp.',
    region: 'US',
  },
  {
    symbol: 'EFTR',
    name: 'eFFECTOR Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'EFU',
    name: 'ProShares Trust - ProShares UltraShort MSCI EAFE',
    region: 'US',
  },
  {
    symbol: 'EFV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI EAFE Value ETF',
    region: 'US',
  },
  {
    symbol: 'EFX',
    name: 'Equifax, Inc.',
    region: 'US',
  },
  {
    symbol: 'EFZ',
    name: 'ProShares Trust - ProShares Short MSCI EAFE',
    region: 'US',
  },
  {
    symbol: 'EGAN',
    name: 'eGain Corp',
    region: 'US',
  },
  {
    symbol: 'EGBN',
    name: 'Eagle Bancorp Inc (MD)',
    region: 'US',
  },
  {
    symbol: 'EGGF',
    name: 'EG Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EGHT',
    name: '8X8 Inc.',
    region: 'US',
  },
  {
    symbol: 'EGIS',
    name: '2nd Vote Funds - 2ndVote Society Defended ETF',
    region: 'US',
  },
  {
    symbol: 'EGLE',
    name: 'Eagle Bulk Shipping Inc',
    region: 'US',
  },
  {
    symbol: 'EGLX',
    name: 'Enthusiast Gaming Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'EGO',
    name: 'Eldorado Gold Corp.',
    region: 'US',
  },
  {
    symbol: 'EGPT',
    name: 'VanEck ETF Trust - VanEck Egypt Index ETF',
    region: 'US',
  },
  {
    symbol: 'EGRX',
    name: 'Eagle Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'EGY',
    name: 'VAALCO Energy, Inc.',
    region: 'US',
  },
  {
    symbol: 'EH',
    name: 'EHang Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'EHC',
    name: 'Encompass Health Corp',
    region: 'US',
  },
  {
    symbol: 'EHTH',
    name: 'eHealth Inc',
    region: 'US',
  },
  {
    symbol: 'EIDO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Indonesia ETF',
    region: 'US',
  },
  {
    symbol: 'EIG',
    name: 'Employers Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EIGR',
    name: 'Eiger BioPharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'EINC',
    name: 'VanEck ETF Trust - VanEck Energy Income ETF',
    region: 'US',
  },
  {
    symbol: 'EIRL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Ireland ETF',
    region: 'US',
  },
  {
    symbol: 'EIS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Israel ETF',
    region: 'US',
  },
  {
    symbol: 'EIVFX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Classic Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EIVIX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Classic Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EIX',
    name: 'Edison International',
    region: 'US',
  },
  {
    symbol: 'EJAN',
    name: 'Innovator ETFs Trust - Innovator Emerging Markets Power Buffer ETF January',
    region: 'US',
  },
  {
    symbol: 'EJFA',
    name: 'EJF Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EJFAU',
    name: 'EJF Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'EJH',
    name: 'E-Home Household Service Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'EJUL',
    name: 'Innovator ETFs Trust - Innovator Emerging Markets Power Buffer ETF July',
    region: 'US',
  },
  {
    symbol: 'EKAR',
    name: 'Exchange Traded Concepts Trust - Capital Link Gbl Green Energy Trans & Tech Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'EKBAX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Diversified Capital Builder Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKBCX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Diversified Capital Builder Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKBDX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Diversified Capital Builder Fund; Administrator Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKBYX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Diversified Capital Builder Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKG',
    name: 'First Trust Exchange-Traded Fund II - First Trust Nasdaq Lux Digital Health Solutions ETF',
    region: 'US',
  },
  {
    symbol: 'EKOAX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Omega Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKONX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Omega Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKORX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Omega Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EKSO',
    name: 'Ekso Bionics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EL',
    name: 'Estee Lauder Cos., Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'ELA',
    name: 'Envela Corp',
    region: 'US',
  },
  {
    symbol: 'ELAN',
    name: 'Elanco Animal Health Inc',
    region: 'US',
  },
  {
    symbol: 'ELAT',
    name: 'Elanco Animal Health Inc - Units ( Purchase Contract & Senior Amortizing Note)',
    region: 'US',
  },
  {
    symbol: 'ELD',
    name: 'WisdomTree Trust - WisdomTree Emerging Market Local Debt Fund',
    region: 'US',
  },
  {
    symbol: 'ELDN',
    name: 'Eledon Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ELEV',
    name: 'Elevation Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'ELF',
    name: 'e.l.f. Beauty Inc',
    region: 'US',
  },
  {
    symbol: 'ELLO',
    name: 'Ellomay Capital Ltd',
    region: 'US',
  },
  {
    symbol: 'ELMD',
    name: 'Electromed Inc.',
    region: 'US',
  },
  {
    symbol: 'ELMS',
    name: 'Electric Last Mile Solutions Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ELOX',
    name: 'Eloxx Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ELP',
    name: 'Cia Paranaense De Energia Copel - ADR (Representing Units (1 Ord Share & 4 Pref Shares-Class B))',
    region: 'US',
  },
  {
    symbol: 'ELQD',
    name: 'iShares Trust - iShares ESG Advanced Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ELSE',
    name: 'Electro-Sensors, Inc.',
    region: 'US',
  },
  {
    symbol: 'ELTK',
    name: 'Eltek Ltd',
    region: 'US',
  },
  {
    symbol: 'ELY',
    name: 'Callaway Golf Co.',
    region: 'US',
  },
  {
    symbol: 'ELYM',
    name: 'Eliem Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ELYS',
    name: 'Elys Game Technology Corp.',
    region: 'US',
  },
  {
    symbol: 'EM',
    name: 'Smart Share Global Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'EMAN',
    name: 'EMagin Corp',
    region: 'US',
  },
  {
    symbol: 'EMB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares J.P. Morgan USD Emerging Markets Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMBD',
    name: 'Global X Funds - Global X Emerging Markets Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMBH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Interest Rate Hedged Emerging Markets Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMBK',
    name: 'Embark Technology Inc',
    region: 'US',
  },
  {
    symbol: 'EMCB',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets Corporate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'EMCF',
    name: 'Emclaire Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'EMCR',
    name: 'DBX ETF Trust - Xtrackers Emerging Markets Carbon Reduction and Climate Improvers ETF',
    region: 'US',
  },
  {
    symbol: 'EMDV',
    name: 'ProShares Trust - ProShares MSCI Emerging Markets Dividend Growers ETF',
    region: 'US',
  },
  {
    symbol: 'EME',
    name: 'Emcor Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'EMFM',
    name: 'Global X Funds - Global X MSCI Next Emerging & Frontier ETF',
    region: 'US',
  },
  {
    symbol: 'EMFQ',
    name: 'Amplify ETF Trust - Amplify Emerging Markets FinTech ETF',
    region: 'US',
  },
  {
    symbol: 'EMGD',
    name: 'Simplify Exchange Traded Funds - Simplify Emerging Markets Equity PLUS Downside Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'EMGF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Emerging Markets Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'EMHC',
    name: 'SPDR Series Trust - SPDR Bloomberg Emerging Markets USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMHY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares J.P. Morgan EM High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMIF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Emerging Markets Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'EMKR',
    name: 'Emcore Corp.',
    region: 'US',
  },
  {
    symbol: 'EML',
    name: 'Eastern Co.',
    region: 'US',
  },
  {
    symbol: 'EMLC',
    name: 'VanEck ETF Trust - VanEck J.P. Morgan EM Local Currency Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMLD',
    name: 'FTAC Emerald Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EMLDU',
    name: 'FTAC Emerald Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'EMLP',
    name: 'First Trust Exchange-Traded Fund III - First Trust North American Energy Infrastructure Fund',
    region: 'US',
  },
  {
    symbol: 'EMMF',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'EMN',
    name: 'Eastman Chemical Co',
    region: 'US',
  },
  {
    symbol: 'EMNT',
    name: 'Pimco Exchange Traded Fund - PIMCO Enhanced Short Maturity Active ESG Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'EMQQ',
    name: 'Exchange Traded Concepts Trust - ETCT EMQQ The Emerging Markets Internet and Ecommerce ETF',
    region: 'US',
  },
  {
    symbol: 'EMR',
    name: 'Emerson Electric Co.',
    region: 'US',
  },
  {
    symbol: 'EMSG',
    name: 'DBX ETF Trust - Xtrackers MSCI Emerging Markets ESG Leaders Equity ETF',
    region: 'US',
  },
  {
    symbol: 'EMSH',
    name: 'ProShares Trust - ProShares Short Term USD Emerging Markets Bond ETF',
    region: 'US',
  },
  {
    symbol: 'EMTL',
    name: 'SSgA Active Trust - SPDR DoubleLine Emerging Markets Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'EMTY',
    name: 'ProShares Trust - ProShares Decline of the Retail Store ETF',
    region: 'US',
  },
  {
    symbol: 'EMX',
    name: 'EMX Royalty Corp',
    region: 'US',
  },
  {
    symbol: 'EMXC',
    name: 'BlackRock Institutional Trust Company N.A. - IShares MSCI Emerging Markets ex China ETF',
    region: 'US',
  },
  {
    symbol: 'EMXF',
    name: 'iShares Trust - iShares ESG Advanced MSCI EM ETF',
    region: 'US',
  },
  {
    symbol: 'ENB',
    name: 'Enbridge Inc',
    region: 'US',
  },
  {
    symbol: 'ENCP',
    name: 'Energem Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ENCPU',
    name: 'Energem Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'ENDP',
    name: 'Endo International plc',
    region: 'US',
  },
  {
    symbol: 'ENFN',
    name: 'Enfusion Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ENFR',
    name: 'ALPS Fund Services - Alerian Energy Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'ENG',
    name: 'Englobal Corporation',
    region: 'US',
  },
  {
    symbol: 'ENIA',
    name: 'Enel Americas SA - ADR',
    region: 'US',
  },
  {
    symbol: 'ENIC',
    name: 'Enel Chile S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'ENJY',
    name: 'Enjoy Technology Inc',
    region: 'US',
  },
  {
    symbol: 'ENLC',
    name: 'Enlink Midstream LLC - Unit',
    region: 'US',
  },
  {
    symbol: 'ENLV',
    name: 'Enlivex Therapeutics Ltd',
    region: 'US',
  },
  {
    symbol: 'ENOB',
    name: 'Enochian Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'ENOR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Norway ETF',
    region: 'US',
  },
  {
    symbol: 'ENOV',
    name: 'Enovis Corp',
    region: 'US',
  },
  {
    symbol: 'ENPC',
    name: 'Executive Network Partnering Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'ENPH',
    name: 'Enphase Energy Inc',
    region: 'US',
  },
  {
    symbol: 'ENR',
    name: 'Energizer Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ENS',
    name: 'Enersys',
    region: 'US',
  },
  {
    symbol: 'ENSC',
    name: 'Ensysce Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'ENSG',
    name: 'Ensign Group Inc',
    region: 'US',
  },
  {
    symbol: 'ENSV',
    name: 'Enservco Corp',
    region: 'US',
  },
  {
    symbol: 'ENTA',
    name: 'Enanta Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ENTF',
    name: 'Enterprise 4.0 Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ENTFU',
    name: 'Enterprise 4.0 Technology Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ENTG',
    name: 'Entegris Inc',
    region: 'US',
  },
  {
    symbol: 'ENTR',
    name: 'Entrepreneurshares Series Trust - ERShares Entrepreneurs ETF',
    region: 'US',
  },
  {
    symbol: 'ENTX',
    name: 'Entera Bio Ltd',
    region: 'US',
  },
  {
    symbol: 'ENV',
    name: 'Envestnet Inc.',
    region: 'US',
  },
  {
    symbol: 'ENVB',
    name: 'Enveric Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'ENVX',
    name: 'Enovix Corporation',
    region: 'US',
  },
  {
    symbol: 'ENZ',
    name: 'Enzo Biochem, Inc.',
    region: 'US',
  },
  {
    symbol: 'ENZL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI New Zealand ETF',
    region: 'US',
  },
  {
    symbol: 'EOCT',
    name: 'Innovator ETFs Trust - Innovator Emerging Markets Power Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'EOCW',
    name: 'Elliott Opportunity II Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EOG',
    name: 'EOG Resources, Inc.',
    region: 'US',
  },
  {
    symbol: 'EOLS',
    name: 'Evolus Inc',
    region: 'US',
  },
  {
    symbol: 'EOMYX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Omega Growth Fund; Administrator Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EOPS',
    name: 'Emles Trust - Emles Alpha Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'EOSE',
    name: 'Eos Energy Enterprises Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EP',
    name: 'Empire Petroleum Corporation',
    region: 'US',
  },
  {
    symbol: 'EPAC',
    name: 'Enerpac Tool Group Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EPAM',
    name: 'EPAM Systems Inc',
    region: 'US',
  },
  {
    symbol: 'EPAY',
    name: 'Bottomline Technologies (Delaware) Inc',
    region: 'US',
  },
  {
    symbol: 'EPC',
    name: 'Edgewell Personal Care Co',
    region: 'US',
  },
  {
    symbol: 'EPD',
    name: 'Enterprise Products Partners L P - Unit',
    region: 'US',
  },
  {
    symbol: 'EPGAX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Equity Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EPGCX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Equity Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EPHE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Philippines ETF',
    region: 'US',
  },
  {
    symbol: 'EPHY',
    name: 'Epiphany Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EPHYU',
    name: 'Epiphany Technology Acquisition Corp - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'EPI',
    name: 'WisdomTree Trust - WisdomTree India Earnings Fund',
    region: 'US',
  },
  {
    symbol: 'EPIX',
    name: 'Essa Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'EPM',
    name: 'Evolution Petroleum Corporation',
    region: 'US',
  },
  {
    symbol: 'EPOL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Poland ETF',
    region: 'US',
  },
  {
    symbol: 'EPP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Pacific ex Japan ETF',
    region: 'US',
  },
  {
    symbol: 'EPRE',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust TCW ESG Premier Equity ETF',
    region: 'US',
  },
  {
    symbol: 'EPRF',
    name: 'Innovator ETFs Trust II - Innovator S&P Investment Grade Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'EPS',
    name: 'WisdomTree Trust - WisdomTree U.S. LargeCap Fund',
    region: 'US',
  },
  {
    symbol: 'EPSN',
    name: 'Epsilon Energy Ltd',
    region: 'US',
  },
  {
    symbol: 'EPU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Peru ETF',
    region: 'US',
  },
  {
    symbol: 'EPV',
    name: 'ProShares Trust - ProShares UltraShort FTSE Europe',
    region: 'US',
  },
  {
    symbol: 'EPWR',
    name: 'Empowerment & Inclusion Capital I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EPZM',
    name: 'Epizyme Inc',
    region: 'US',
  },
  {
    symbol: 'EQ',
    name: 'Equillium Inc',
    region: 'US',
  },
  {
    symbol: 'EQAL',
    name: 'Invesco Capital Management LLC - Invesco Russell 1000 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'EQBK',
    name: 'Equity Bancshares Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EQD',
    name: 'Equity Distribution Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EQH',
    name: 'Equitable Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EQHA',
    name: 'EQ Health Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EQIX',
    name: 'Equinix Inc',
    region: 'US',
  },
  {
    symbol: 'EQL',
    name: 'ALPS Fund Services - ALPS Equal Sector Weight ETF',
    region: 'US',
  },
  {
    symbol: 'EQNR',
    name: 'Equinor ASA - ADR',
    region: 'US',
  },
  {
    symbol: 'EQOP',
    name: 'Natixis ETF Trust II - Natixis U.S. Equity Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'EQOS',
    name: 'Eqonex Ltd',
    region: 'US',
  },
  {
    symbol: 'EQPGX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Equity Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EQRR',
    name: 'ProShares Trust - ProShares Equities for Rising Rates ETF',
    region: 'US',
  },
  {
    symbol: 'EQRX',
    name: 'EQRx Inc',
    region: 'US',
  },
  {
    symbol: 'EQT',
    name: 'EQT Corp',
    region: 'US',
  },
  {
    symbol: 'EQUL',
    name: 'IndexIQ ETF Trust - IQ Engender Equality ETF',
    region: 'US',
  },
  {
    symbol: 'EQWL',
    name: 'Invesco Capital Management LLC - Invesco Russell Top 200 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'EQX',
    name: 'Equinox Gold Corp',
    region: 'US',
  },
  {
    symbol: 'ERAS',
    name: 'Erasca Inc',
    region: 'US',
  },
  {
    symbol: 'ERES',
    name: 'East Resources Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'ERESU',
    name: 'East Resources Acquisition Co - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ERF',
    name: 'Enerplus Corporation',
    region: 'US',
  },
  {
    symbol: 'ERIC',
    name: 'Telefonaktiebolaget L M Ericsson - ADR',
    region: 'US',
  },
  {
    symbol: 'ERIE',
    name: 'Erie Indemnity Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'ERII',
    name: 'Energy Recovery Inc',
    region: 'US',
  },
  {
    symbol: 'ERJ',
    name: 'Embraer S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'ERM',
    name: 'First Trust Exchange-Traded Fund III - EquityCompass Risk Manager ETF',
    region: 'US',
  },
  {
    symbol: 'ERO',
    name: 'Ero Copper Corp',
    region: 'US',
  },
  {
    symbol: 'ERSX',
    name: 'Entrepreneurshares Series Trust - ERShares NextGen Entrepreneurs ETF',
    region: 'US',
  },
  {
    symbol: 'ERTH',
    name: 'Invesco Capital Management LLC - Invesco MSCI Sustainable Future ETF',
    region: 'US',
  },
  {
    symbol: 'ERX',
    name: 'Direxion Shares ETF Trust - Direxion Daily Energy Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'ERY',
    name: 'Direxion Shares ETF Trust - Direxion Daily Energy Bear 2X Shares',
    region: 'US',
  },
  {
    symbol: 'ERYP',
    name: 'Erytech Pharma - ADR',
    region: 'US',
  },
  {
    symbol: 'ES',
    name: 'Eversource Energy',
    region: 'US',
  },
  {
    symbol: 'ESAC',
    name: 'Esgen Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ESACU',
    name: 'Esgen Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ESBK',
    name: 'Elmira Savings Bank Elmira NY',
    region: 'US',
  },
  {
    symbol: 'ESCA',
    name: 'Escalade, Inc.',
    region: 'US',
  },
  {
    symbol: 'ESCR',
    name: 'DBX ETF Trust - Xtrackers Bloomberg US Investment Grade Corporate ESG ETF.',
    region: 'US',
  },
  {
    symbol: 'ESE',
    name: 'Esco Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'ESEA',
    name: 'Euroseas Ltd',
    region: 'US',
  },
  {
    symbol: 'ESEB',
    name: 'DBX ETF Trust - Xtrackers J.P. Morgan ESG Emerging Markets Sovereign ETF',
    region: 'US',
  },
  {
    symbol: 'ESG',
    name: 'FlexShares Trust - FlexShares STOXX US ESG Select Index Fund',
    region: 'US',
  },
  {
    symbol: 'ESGA',
    name: 'American Century ETF Trust - American Century Sustainable Equity ETF',
    region: 'US',
  },
  {
    symbol: 'ESGB',
    name: 'IndexIQ Active ETF Trust - IQ MacKay ESG Core Plus Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ESGC',
    name: 'Eros STX Global Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'ESGD',
    name: 'iShares Trust - iShares Trust iShares ESG Aware MSCI EAFE ETF',
    region: 'US',
  },
  {
    symbol: 'ESGE',
    name: 'iShares Trust - iShare Inc iShares ESG Aware MSCI EM ETF',
    region: 'US',
  },
  {
    symbol: 'ESGG',
    name: 'FlexShares Trust - FlexShares STOXX Global ESG Select Index Fund',
    region: 'US',
  },
  {
    symbol: 'ESGN',
    name: 'Columbia ETF Trust I - Columbia Sustainable International Equity Income ETF',
    region: 'US',
  },
  {
    symbol: 'ESGR',
    name: 'Enstar Group Limited',
    region: 'US',
  },
  {
    symbol: 'ESGS',
    name: 'Columbia ETF Trust I - Columbia Sustainable U.S. Equity Income ETF',
    region: 'US',
  },
  {
    symbol: 'ESGU',
    name: 'iShares Trust - iShares Trust iShares ESG Aware MSCI USA ETF',
    region: 'US',
  },
  {
    symbol: 'ESGV',
    name: 'Vanguard Group, Inc. - Vanguard ESG U.S. Stock ETF',
    region: 'US',
  },
  {
    symbol: 'ESGY',
    name: 'American Century ETF Trust - American Century Sustainable Growth ETF',
    region: 'US',
  },
  {
    symbol: 'ESHY',
    name: 'DBX ETF Trust - Xtrackers J.P. Morgan ESG USD High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ESI',
    name: 'Element Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'ESIX',
    name: 'SPDR Series Trust - SPDR S&P SmallCap 600 ESG ETF',
    region: 'US',
  },
  {
    symbol: 'ESLT',
    name: 'Elbit Systems Ltd.',
    region: 'US',
  },
  {
    symbol: 'ESM',
    name: 'ESM Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ESML',
    name: 'BlackRock Institutional Trust Company N.A. - iShares ESG Aware MSCI USA Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'ESMT',
    name: 'Engagesmart Inc',
    region: 'US',
  },
  {
    symbol: 'ESMV',
    name: 'iShares Trust - iShares ESG MSCI USA Min Vol Factor ETF',
    region: 'US',
  },
  {
    symbol: 'ESNT',
    name: 'Essent Group Ltd',
    region: 'US',
  },
  {
    symbol: 'ESOA',
    name: 'Energy Services of America Corp',
    region: 'US',
  },
  {
    symbol: 'ESP',
    name: 'Espey Manufacturing & Electronics Corp.',
    region: 'US',
  },
  {
    symbol: 'ESPO',
    name: 'VanEck ETF Trust - VanEck Video Gaming and eSports ETF',
    region: 'US',
  },
  {
    symbol: 'ESPR',
    name: 'Esperion Therapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'ESQ',
    name: 'Esquire Financial Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ESSA',
    name: 'ESSA Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'ESSC',
    name: 'East Stone Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ESSCU',
    name: 'East Stone Acquisition Corp - Units (1 Ord, 1 Right & 1 Warrant)',
    region: 'US',
  },
  {
    symbol: 'ESTA',
    name: 'Establishment Labs Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ESTC',
    name: 'Elastic N.V',
    region: 'US',
  },
  {
    symbol: 'ESTE',
    name: 'Earthstone Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ET',
    name: 'Energy Transfer LP - Unit',
    region: 'US',
  },
  {
    symbol: 'ETAC',
    name: 'E.Merge Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ETACU',
    name: 'E.Merge Technology Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ETD',
    name: 'Ethan Allen Interiors, Inc.',
    region: 'US',
  },
  {
    symbol: 'ETHO',
    name: 'ETF Managers Group LLC - FA Etho Climate Leadership U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'ETN',
    name: 'Eaton Corporation plc',
    region: 'US',
  },
  {
    symbol: 'ETNB',
    name: '89bio Inc',
    region: 'US',
  },
  {
    symbol: 'ETON',
    name: 'Eton Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ETR',
    name: 'Entergy Corp.',
    region: 'US',
  },
  {
    symbol: 'ETRN',
    name: 'Equitrans Midstream Corporation',
    region: 'US',
  },
  {
    symbol: 'ETSY',
    name: 'Etsy Inc',
    region: 'US',
  },
  {
    symbol: 'ETTX',
    name: 'Entasis Therapeutics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ETWO',
    name: 'E2open Parent Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EUCG',
    name: 'Tidal ETF Trust - Euclid Capital Growth ETF',
    region: 'US',
  },
  {
    symbol: 'EUCR',
    name: 'Eucrates Biomedical Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'EUCRU',
    name: 'Eucrates Biomedical Acquisition Corp - Units (1 Ord & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'EUDG',
    name: 'WisdomTree Trust - WisdomTree Europe Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'EUDV',
    name: 'ProShares Trust - ProShares MSCI Europe Dividend Growers ETF',
    region: 'US',
  },
  {
    symbol: 'EUFN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Europe Financials ETF',
    region: 'US',
  },
  {
    symbol: 'EUFX',
    name: 'ProShares Trust - ProShares Short Euro',
    region: 'US',
  },
  {
    symbol: 'EUM',
    name: 'ProShares Trust - ProShares Short MSCI Emerging Markets',
    region: 'US',
  },
  {
    symbol: 'EUO',
    name: 'ProShares Trust - ProShares UltraShort Euro',
    region: 'US',
  },
  {
    symbol: 'EURL',
    name: 'Direxion Shares ETF Trust - Direxion Daily FTSE Europe Bull 3x Shares',
    region: 'US',
  },
  {
    symbol: 'EURN',
    name: 'Euronav NV',
    region: 'US',
  },
  {
    symbol: 'EUSA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Equal Weighted ETF',
    region: 'US',
  },
  {
    symbol: 'EUSB',
    name: 'iShares Trust - iShares ESG Advanced Total USD Bond Market ETF',
    region: 'US',
  },
  {
    symbol: 'EUSC',
    name: 'WisdomTree Trust - WisdomTree Europe Hedged SmallCap Equity Fund',
    region: 'US',
  },
  {
    symbol: 'EVA',
    name: 'Enviva Inc',
    region: 'US',
  },
  {
    symbol: 'EVAX',
    name: 'Evaxion Biotech A/S - ADR',
    region: 'US',
  },
  {
    symbol: 'EVBG',
    name: 'Everbridge Inc',
    region: 'US',
  },
  {
    symbol: 'EVBN',
    name: 'Evans Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'EVC',
    name: 'Entravision Communications Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'EVCM',
    name: 'EverCommerce Inc',
    region: 'US',
  },
  {
    symbol: 'EVE',
    name: 'EVe Mobility Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EVEN',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 Equal Weight Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'EVER',
    name: 'EverQuote Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EVFM',
    name: 'Evofem Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'EVGBC',
    name: 'Eaton Vance Management - Eaton Vance Global Income Builder NextShares',
    region: 'US',
  },
  {
    symbol: 'EVGN',
    name: 'Evogene Ltd',
    region: 'US',
  },
  {
    symbol: 'EVGO',
    name: 'EVgo Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EVGR',
    name: 'Evergreen Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EVGRU',
    name: 'Evergreen Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'EVH',
    name: 'Evolent Health Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EVI',
    name: 'EVI Industries Inc',
    region: 'US',
  },
  {
    symbol: 'EVK',
    name: 'Ever-Glory International Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'EVLMC',
    name: 'Eaton Vance Management - Eaton Vance TABS 5-to-15 Year Laddered Municipal Bond NextShares',
    region: 'US',
  },
  {
    symbol: 'EVLO',
    name: 'Evelo Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'EVLV',
    name: 'Evolv Technologies Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EVMT',
    name: 'Invesco Capital Management LLC - Invesco Electric Vehicle Metals Commodity Strategy No K-1 ETF',
    region: 'US',
  },
  {
    symbol: 'EVNT',
    name: 'AltShares Trust - AltShares Event-Driven ETF',
    region: 'US',
  },
  {
    symbol: 'EVO',
    name: 'Evotec SE - ADR',
    region: 'US',
  },
  {
    symbol: 'EVOJ',
    name: 'Evo Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'EVOJU',
    name: 'Evo Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'EVOK',
    name: 'Evoke Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'EVOL',
    name: 'Symbolic Logic Inc',
    region: 'US',
  },
  {
    symbol: 'EVOP',
    name: 'EVO Payments Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EVR',
    name: 'Evercore Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EVRG',
    name: 'Evergy Inc',
    region: 'US',
  },
  {
    symbol: 'EVRI',
    name: 'Everi Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EVSIX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Disciplined US Core Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'EVSTC',
    name: 'Eaton Vance Management - Eaton Vance NextShares Trust Eaton Vance Stock NextShares',
    region: 'US',
  },
  {
    symbol: 'EVTC',
    name: 'Evertec Inc',
    region: 'US',
  },
  {
    symbol: 'EVTL',
    name: 'Vertical Aerospace Ltd',
    region: 'US',
  },
  {
    symbol: 'EVX',
    name: 'VanEck ETF Trust - VanEck Environmental Services ETF',
    region: 'US',
  },
  {
    symbol: 'EW',
    name: 'Edwards Lifesciences Corp',
    region: 'US',
  },
  {
    symbol: 'EWA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Australia ETF',
    region: 'US',
  },
  {
    symbol: 'EWBC',
    name: 'East West Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'EWC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Canada ETF',
    region: 'US',
  },
  {
    symbol: 'EWCO',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco S&P 500 Equal Weight Communication Services ETF',
    region: 'US',
  },
  {
    symbol: 'EWCZ',
    name: 'European Wax Center Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EWD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Sweden ETF',
    region: 'US',
  },
  {
    symbol: 'EWEB',
    name: 'Global X Funds - Global X Emerging Markets Internet & E-commerce ETF',
    region: 'US',
  },
  {
    symbol: 'EWG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Germany ETF',
    region: 'US',
  },
  {
    symbol: 'EWGS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Germany Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'EWH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Hong Kong ETF',
    region: 'US',
  },
  {
    symbol: 'EWI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Italy ETF',
    region: 'US',
  },
  {
    symbol: 'EWJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Japan ETF',
    region: 'US',
  },
  {
    symbol: 'EWJE',
    name: 'iShares Trust - iShares MSCI Japan Equal Weighted ETF',
    region: 'US',
  },
  {
    symbol: 'EWJV',
    name: 'iShares Trust - iShares MSCI Japan Value ETF',
    region: 'US',
  },
  {
    symbol: 'EWK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Belgium ETF',
    region: 'US',
  },
  {
    symbol: 'EWL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Switzerland ETF',
    region: 'US',
  },
  {
    symbol: 'EWM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Malaysia ETF',
    region: 'US',
  },
  {
    symbol: 'EWMC',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap 400 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'EWN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Netherlands ETF',
    region: 'US',
  },
  {
    symbol: 'EWO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Austria ETF',
    region: 'US',
  },
  {
    symbol: 'EWP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Spain ETF',
    region: 'US',
  },
  {
    symbol: 'EWQ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI France ETF',
    region: 'US',
  },
  {
    symbol: 'EWRE',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'EWS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Singapore ETF',
    region: 'US',
  },
  {
    symbol: 'EWSC',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap 600 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'EWT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Taiwan ETF',
    region: 'US',
  },
  {
    symbol: 'EWTX',
    name: 'Edgewise Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'EWU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI United Kingdom ETF',
    region: 'US',
  },
  {
    symbol: 'EWUS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI United Kingdom Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'EWV',
    name: 'ProShares Trust - ProShares UltraShort MSCI Japan',
    region: 'US',
  },
  {
    symbol: 'EWW',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Mexico ETF',
    region: 'US',
  },
  {
    symbol: 'EWX',
    name: 'SSgA Active Trust - S&P Emerging Markets Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'EWY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI South Korea ETF',
    region: 'US',
  },
  {
    symbol: 'EWZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Brazil ETF',
    region: 'US',
  },
  {
    symbol: 'EWZS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Brazil Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'EXAI',
    name: 'Exscientia Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'EXAS',
    name: 'Exact Sciences Corp.',
    region: 'US',
  },
  {
    symbol: 'EXC',
    name: 'Exelon Corp.',
    region: 'US',
  },
  {
    symbol: 'EXEL',
    name: 'Exelixis Inc',
    region: 'US',
  },
  {
    symbol: 'EXFY',
    name: 'Expensify Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'EXI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'EXK',
    name: 'Endeavour Silver Corp.',
    region: 'US',
  },
  {
    symbol: 'EXLS',
    name: 'Exlservice Hldgs Inc',
    region: 'US',
  },
  {
    symbol: 'EXN',
    name: 'Excellon Resources Inc',
    region: 'US',
  },
  {
    symbol: 'EXP',
    name: 'Eagle Materials Inc.',
    region: 'US',
  },
  {
    symbol: 'EXPD',
    name: 'Expeditors International Of Washington, Inc.',
    region: 'US',
  },
  {
    symbol: 'EXPE',
    name: 'Expedia Group Inc',
    region: 'US',
  },
  {
    symbol: 'EXPGF',
    name: 'Experian Plc',
    region: 'US',
  },
  {
    symbol: 'EXPGY',
    name: 'Experian Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'EXPI',
    name: 'eXp World Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EXPO',
    name: 'Exponent Inc.',
    region: 'US',
  },
  {
    symbol: 'EXPR',
    name: 'Express Inc.',
    region: 'US',
  },
  {
    symbol: 'EXTN',
    name: 'Exterran Corp',
    region: 'US',
  },
  {
    symbol: 'EXTR',
    name: 'Extreme Networks Inc.',
    region: 'US',
  },
  {
    symbol: 'EYE',
    name: 'National Vision Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EYEN',
    name: 'Eyenovia Inc',
    region: 'US',
  },
  {
    symbol: 'EYES',
    name: 'Second Sight Medical Products Inc',
    region: 'US',
  },
  {
    symbol: 'EYLD',
    name: 'Cambria Investment Management LP - Cambria Emerging Shareholder Yield ETF',
    region: 'US',
  },
  {
    symbol: 'EYPT',
    name: 'EyePoint Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'EZA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI South Africa ETF',
    region: 'US',
  },
  {
    symbol: 'EZFL',
    name: 'EzFill Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'EZGO',
    name: 'EZGO Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'EZJ',
    name: 'ProShares Trust - ProShares Ultra MSCI Japan',
    region: 'US',
  },
  {
    symbol: 'EZM',
    name: 'WisdomTree Trust - WisdomTree U.S. MidCap Fund',
    region: 'US',
  },
  {
    symbol: 'EZPW',
    name: 'EZCorp, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'EZU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI EMU ETF',
    region: 'US',
  },
  {
    symbol: 'F',
    name: 'Ford Motor Co.',
    region: 'US',
  },
  {
    symbol: 'FA',
    name: 'First Advantage Corp.',
    region: 'US',
  },
  {
    symbol: 'FAAR',
    name: 'First Trust Exchange-Traded Fund III - First Trust Alternative Absolute Return Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'FAB',
    name: 'First Trust Exchange-Traded Fund III - First Trust Multi Cap Value AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FACA',
    name: 'Figure Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'FACDX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Health Care Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FACT',
    name: 'Freedom Acquisition I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FACTX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Health Care Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FAD',
    name: 'First Trust Exchange-Traded Fund III - First Trust Multi Cap Growth AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FADTX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Technology Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FAEGX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Equity Growth Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FAF',
    name: 'First American Financial Corp',
    region: 'US',
  },
  {
    symbol: 'FAIL',
    name: 'Cambria Investment Management LP - Cambria Global Tail Risk ETF',
    region: 'US',
  },
  {
    symbol: 'FALCX',
    name: 'Fidelity Rutland Square Trust II: Strategic Advisers Large Cap Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FALN',
    name: 'iShares Trust - iShares Fallen Angels USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FAMI',
    name: 'Farmmi Inc',
    region: 'US',
  },
  {
    symbol: 'FAN',
    name: 'First Trust Exchange-Traded Fund III - First Trust Global Wind Energy ETF',
    region: 'US',
  },
  {
    symbol: 'FANG',
    name: 'Diamondback Energy Inc',
    region: 'US',
  },
  {
    symbol: 'FANH',
    name: 'Fanhua Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'FAPR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'FARM',
    name: 'Farmer Bros. Co.',
    region: 'US',
  },
  {
    symbol: 'FARO',
    name: 'Faro Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'FAS',
    name: 'Direxion Shares ETF Trust - Direxion Daily Financial Bull 3x Shares',
    region: 'US',
  },
  {
    symbol: 'FASOX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Value Strategies Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FASPX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Value Strategies Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FAST',
    name: 'Fastenal Co.',
    region: 'US',
  },
  {
    symbol: 'FAT',
    name: 'FAT Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FATBB',
    name: 'FAT Brands Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'FATE',
    name: 'Fate Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FATEX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Technology Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FATH',
    name: 'Fathom Digital Manufacturing Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'FATIX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Technology Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FATP',
    name: 'Fat Projects Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FATPU',
    name: 'Fat Projects Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'FAUG',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - August',
    region: 'US',
  },
  {
    symbol: 'FAZ',
    name: 'Direxion Shares ETF Trust - Direxion Daily Financial Bear 3x Shares',
    region: 'US',
  },
  {
    symbol: 'FB',
    name: 'Meta Platforms Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FBC',
    name: 'Flagstar Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'FBCG',
    name: 'Fidelity Covington Trust - Fidelity Blue Chip Growth ETF',
    region: 'US',
  },
  {
    symbol: 'FBCGX',
    name: 'Fidelity Securities Fund: Fidelity Blue Chip Growth K6 Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FBCV',
    name: 'Fidelity Covington Trust - Fidelity Blue Chip Value ETF',
    region: 'US',
  },
  {
    symbol: 'FBGKX',
    name: 'Fidelity Securities Fund: Fidelity Blue Chip Growth Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FBGRX',
    name: 'Fidelity Securities Fund: Fidelity Blue Chip Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FBHS',
    name: 'Fortune Brands Home & Security Inc',
    region: 'US',
  },
  {
    symbol: 'FBIO',
    name: 'Fortress Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'FBIZ',
    name: 'First Business Financial Services Inc',
    region: 'US',
  },
  {
    symbol: 'FBK',
    name: 'FB Financial Corp',
    region: 'US',
  },
  {
    symbol: 'FBMPX',
    name: 'Fidelity Select Portfolios: Communication Services Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FBMS',
    name: 'First Bancshares Inc Miss',
    region: 'US',
  },
  {
    symbol: 'FBNC',
    name: 'First Bancorp',
    region: 'US',
  },
  {
    symbol: 'FBND',
    name: 'Fidelity Covington Trust - Fidelity Total Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FBP',
    name: 'First Bancorp PR',
    region: 'US',
  },
  {
    symbol: 'FBRX',
    name: 'Forte Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'FBT',
    name: 'First Trust Exchange-Traded Fund III - First Trust NYSE Arca Biotechnology Index Fund',
    region: 'US',
  },
  {
    symbol: 'FBZ',
    name: 'First Trust Exchange-Traded Fund III - First Trust Brazil AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FC',
    name: 'Franklin Covey Co.',
    region: 'US',
  },
  {
    symbol: 'FCA',
    name: 'First Trust Exchange-Traded Fund III - First Trust China AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FCAGX',
    name: 'Fidelity Securities Fund: Fidelity Advisor Small Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCAL',
    name: 'First Trust Exchange-Traded Fund III - First Trust California Municipal High Income ETF',
    region: 'US',
  },
  {
    symbol: 'FCAP',
    name: 'First Capital Inc.',
    region: 'US',
  },
  {
    symbol: 'FCAX',
    name: 'Fortress Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FCBC',
    name: 'First Community Bankshares Inc.',
    region: 'US',
  },
  {
    symbol: 'FCCGX',
    name: 'Fidelity Securities Fund: Fidelity Advisor Small Cap Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCCO',
    name: 'First Community Corp.',
    region: 'US',
  },
  {
    symbol: 'FCDAX',
    name: 'Fidelity Capital Trust: Fidelity Advisor Stock Selector Small Cap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCDCX',
    name: 'Fidelity Capital Trust: Fidelity Advisor Stock Selector Small Cap Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCDIX',
    name: 'Fidelity Capital Trust: Fidelity Advisor Stock Selector Small Cap Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCDTX',
    name: 'Fidelity Capital Trust: Fidelity Advisor Stock Selector Small Cap Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCEF',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Income Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'FCEL',
    name: 'Fuelcell Energy Inc',
    region: 'US',
  },
  {
    symbol: 'FCF',
    name: 'First Commonwealth Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'FCFS',
    name: 'FirstCash Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'FCG',
    name: 'First Trust Exchange-Traded Fund III - First Trust Natural Gas ETF',
    region: 'US',
  },
  {
    symbol: 'FCGSX',
    name: 'Fidelity Mt Vernon Street Trust: Fidelity Series Growth Company Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCIGX',
    name: 'Fidelity Securities Fund: Fidelity Advisor Small Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCLD',
    name: 'Fidelity Covington Trust - Fidelity Cloud Computing ETF',
    region: 'US',
  },
  {
    symbol: 'FCN',
    name: 'FTI Consulting Inc.',
    region: 'US',
  },
  {
    symbol: 'FCNCA',
    name: 'First Citizens Bancshares, Inc (NC) - Class A',
    region: 'US',
  },
  {
    symbol: 'FCOM',
    name: 'Fidelity Covington Trust - Fidelity MSCI Communication Services Index ETF',
    region: 'US',
  },
  {
    symbol: 'FCOR',
    name: 'Fidelity Covington Trust - Fidelity Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FCPGX',
    name: 'Fidelity Securities Fund: Fidelity Small Cap Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCPI',
    name: 'Fidelity Covington Trust - Fidelity Stocks for Inflation ETF',
    region: 'US',
  },
  {
    symbol: 'FCSH',
    name: 'Federated Hermes ETF Trust - Federated Hermes Short Duration Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'FCTGX',
    name: 'Fidelity Securities Fund: Fidelity Advisor Small Cap Growth Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FCTR',
    name: 'First Trust Exchange-Traded Fund - First Trust Lunt U.S. Factor Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'FCUV',
    name: 'Focus Universal Inc',
    region: 'US',
  },
  {
    symbol: 'FCVT',
    name: 'First Trust Exchange-Traded Fund IV - First Trust SSI Strategic Convertible Securities ETF',
    region: 'US',
  },
  {
    symbol: 'FCX',
    name: 'Freeport-McMoRan Inc',
    region: 'US',
  },
  {
    symbol: 'FDBC',
    name: 'Fidelity D&D Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'FDD',
    name: 'First Trust Exchange-Traded Fund III - First Trust STOXX European Select Dividend Income Fund',
    region: 'US',
  },
  {
    symbol: 'FDEC',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'FDEM',
    name: 'Fidelity Covington Trust - Fidelity Emerging Markets Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'FDEV',
    name: 'Fidelity Covington Trust - Fidelity International Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'FDFIX',
    name: 'Fidelity Concord Street Trust: Fidelity Flex 500 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDG',
    name: 'American Century ETF Trust - American Century Focused Dynamic Growth ETF',
    region: 'US',
  },
  {
    symbol: 'FDGRX',
    name: 'Fidelity Mt Vernon Street Trust: Fidelity Growth Company Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDHT',
    name: 'Fidelity Covington Trust - Fidelity Digital Health ETF',
    region: 'US',
  },
  {
    symbol: 'FDHY',
    name: 'Fidelity Covington Trust - Fidelity High Yield Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FDIG',
    name: 'Fidelity Covington Trust - Fidelity Crypto Industry and Digital Payments ETF',
    region: 'US',
  },
  {
    symbol: 'FDIS',
    name: 'Fidelity Covington Trust - Fidelity MSCI Consumer Discretionary Index ETF',
    region: 'US',
  },
  {
    symbol: 'FDL',
    name: 'First Trust Exchange-Traded Fund III - First Trust Morningstar Dividend Leaders Index Fund',
    region: 'US',
  },
  {
    symbol: 'FDLO',
    name: 'Fidelity Covington Trust - Fidelity Low Volatility Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FDM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dow Jones Select Microcap Index Fund',
    region: 'US',
  },
  {
    symbol: 'FDMO',
    name: 'Fidelity Covington Trust - Fidelity Momentum Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FDMT',
    name: '4D Molecular Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FDN',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dow Jones Internet Index Fund',
    region: 'US',
  },
  {
    symbol: 'FDNI',
    name: 'First Trust Exchange-Traded Fund II - First Trust Dow Jones International Internet ETF',
    region: 'US',
  },
  {
    symbol: 'FDNRX',
    name: 'Franklin Custodian Funds: Franklin DynaTech Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDP',
    name: 'Fresh Del Monte Produce Inc',
    region: 'US',
  },
  {
    symbol: 'FDRR',
    name: 'Fidelity Covington Trust - Fidelity Dividend ETF for Rising Rates',
    region: 'US',
  },
  {
    symbol: 'FDRV',
    name: 'Fidelity Covington Trust - Fidelity Electric Vehicles and Future Transportation ETF',
    region: 'US',
  },
  {
    symbol: 'FDS',
    name: 'Factset Research Systems Inc.',
    region: 'US',
  },
  {
    symbol: 'FDSCX',
    name: 'Fidelity Capital Trust: Fidelity Stock Selector Small Cap Fund; Stock Selector Small Cap Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDSVX',
    name: 'Fidelity Hastings Street Trust: Fidelity Growth Discovery Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDT',
    name: 'First Trust Exchange-Traded Fund III - First Trust Developed Markets ex-US AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FDTRX',
    name: 'Franklin Custodian Funds: Franklin DynaTech Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDTS',
    name: 'First Trust Exchange-Traded Fund III - First Trust Developed Markets ex-US Small Cap AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FDVLX',
    name: 'Fidelity Capital Trust: Fidelity Value Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDVV',
    name: 'Fidelity Covington Trust - Fidelity High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'FDWM',
    name: 'Fidelity Covington Trust - Fidelity Women`s Leadership ETF',
    region: 'US',
  },
  {
    symbol: 'FDX',
    name: 'Fedex Corp',
    region: 'US',
  },
  {
    symbol: 'FDYNX',
    name: 'Franklin Custodian Funds: Franklin DynaTech Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FDYZX',
    name: 'Franklin Custodian Funds: Franklin DynaTech Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FE',
    name: 'Firstenergy Corp.',
    region: 'US',
  },
  {
    symbol: 'FEBZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (February) ETF',
    region: 'US',
  },
  {
    symbol: 'FEDM',
    name: 'FlexShares Trust - FlexShares ESG & Climate Developed Markets ex-US Core Index Fund',
    region: 'US',
  },
  {
    symbol: 'FEDU',
    name: 'Four Seasons Education (Cayman) Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'FEDX',
    name: 'Emles Trust - Emles Federal Contractors ETF',
    region: 'US',
  },
  {
    symbol: 'FEEM',
    name: 'FlexShares Trust - FlexShares ESG & Climate Emerging Markets Core Index Fund',
    region: 'US',
  },
  {
    symbol: 'FEHY',
    name: 'FlexShares Trust - FlexShares ESG & Climate High Yield Corporate Core Index Fund',
    region: 'US',
  },
  {
    symbol: 'FEIG',
    name: 'FlexShares Trust - FlexShares ESG & Climate Investment Grade Corporate Core Index Fund',
    region: 'US',
  },
  {
    symbol: 'FEIKX',
    name: 'Fidelity Devonshire Trust: Fidelity Equity-Income Fund; Class K',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FEIM',
    name: 'Frequency Electronics, Inc.',
    region: 'US',
  },
  {
    symbol: 'FELE',
    name: 'Franklin Electric Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'FEM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Emerging Markets AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FEMB',
    name: 'First Trust Exchange-Traded Fund III - First Trust Emerging Markets Local Currency Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FEMS',
    name: 'First Trust Exchange-Traded Fund III - First Trust Emerging Markets Small Cap AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FEMY',
    name: 'Femasys Inc',
    region: 'US',
  },
  {
    symbol: 'FENC',
    name: 'Fennec Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'FENG',
    name: 'Phoenix New Media Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'FENY',
    name: 'Fidelity Covington Trust - Fidelity MSCI Energy Index ETF',
    region: 'US',
  },
  {
    symbol: 'FEP',
    name: 'First Trust Exchange-Traded Fund III - First Trust Europe AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FEQIX',
    name: 'Fidelity Devonshire Trust: Fidelity Equity-Income Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FERG',
    name: 'Ferguson Plc.',
    region: 'US',
  },
  {
    symbol: 'FET',
    name: 'Forum Energy Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'FEUS',
    name: 'FlexShares Trust - FlexShares ESG & Climate US Large Cap Core Index Fund',
    region: 'US',
  },
  {
    symbol: 'FEUZ',
    name: 'First Trust Exchange-Traded Fund III - First Trust Eurozone AlphaDEX ETF',
    region: 'US',
  },
  {
    symbol: 'FEVR',
    name: 'Northern Lights Fund Trust IV - Inspire Faithward Large Cap Momentum ESG ETF',
    region: 'US',
  },
  {
    symbol: 'FEX',
    name: 'First Trust Exchange-Traded Fund III - First Trust Large Cap Core AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FEXD',
    name: 'Fintech Ecosystem Development Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FEXDU',
    name: 'Fintech Ecosystem Development Corp - Units (1 Ord Share Class A, 1 Right & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'FEZ',
    name: 'SSgA Active Trust - SPDR EURO Stoxx 50 ETF',
    region: 'US',
  },
  {
    symbol: 'FF',
    name: 'Futurefuel Corp',
    region: 'US',
  },
  {
    symbol: 'FFBC',
    name: 'First Financial Bancorp',
    region: 'US',
  },
  {
    symbol: 'FFBW',
    name: 'FFBW Inc.',
    region: 'US',
  },
  {
    symbol: 'FFEB',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - February',
    region: 'US',
  },
  {
    symbol: 'FFFMX',
    name: 'American Mutual Fund; Class 529-F-3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FFHG',
    name: 'Northern Lights Fund Trust IV - Formula Folios Hedged Growth ETF',
    region: 'US',
  },
  {
    symbol: 'FFHL',
    name: 'Fuwei Films (Holdings) Co',
    region: 'US',
  },
  {
    symbol: 'FFIC',
    name: 'Flushing Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'FFIE',
    name: 'Faraday Future Intelligent Electric Inc',
    region: 'US',
  },
  {
    symbol: 'FFIN',
    name: 'First Financial Bankshares, Inc.',
    region: 'US',
  },
  {
    symbol: 'FFIU',
    name: 'Spinnaker ETF Trust - UVA Unconstrained Medium-Term Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'FFIV',
    name: 'F5 Inc',
    region: 'US',
  },
  {
    symbol: 'FFMMX',
    name: 'American Mutual Fund; Class 529-F-2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FFND',
    name: 'Northern Lights Fund Trust II - The Future Fund Active ETF',
    region: 'US',
  },
  {
    symbol: 'FFNW',
    name: 'First Financial Northwest Inc',
    region: 'US',
  },
  {
    symbol: 'FFR',
    name: 'First Trust Exchange-Traded Fund II - First Trust FTSE EPRA/NAREIT Developed Markets Real Estate Index Fund',
    region: 'US',
  },
  {
    symbol: 'FFSG',
    name: 'Northern Lights Fund Trust IV - FormulaFolios Smart Growth ETF',
    region: 'US',
  },
  {
    symbol: 'FFTG',
    name: 'Northern Lights Fund Trust IV - FormulaFolios Tactical Growth ETF',
    region: 'US',
  },
  {
    symbol: 'FFTI',
    name: 'Northern Lights Fund Trust IV - Formula Folios Tactical Income ETF',
    region: 'US',
  },
  {
    symbol: 'FFTY',
    name: 'Innovator Management LLC - Innovator IBD 50 ETF',
    region: 'US',
  },
  {
    symbol: 'FFWM',
    name: 'First Foundation Inc',
    region: 'US',
  },
  {
    symbol: 'FGBI',
    name: 'First Guaranty Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'FGCKX',
    name: 'Fidelity Mt Vernon Street Trust: Fidelity Growth Company Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGD',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dow Jones Global Select Dividend Index Fund',
    region: 'US',
  },
  {
    symbol: 'FGDKX',
    name: 'Fidelity Hastings Street Trust: Fidelity Growth Discovery Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGDMX',
    name: 'Fidelity Select Portfolios: Fidelity Advisor Communication Services Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGEMX',
    name: 'Fidelity Select Portfolios: Fidelity Advisor Communication Services Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGEN',
    name: 'FibroGen Inc',
    region: 'US',
  },
  {
    symbol: 'FGF',
    name: 'FG Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'FGHMX',
    name: 'Fidelity Select Portfolios: Fidelity Advisor Communication Services Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGI',
    name: 'FGI Industries Ltd',
    region: 'US',
  },
  {
    symbol: 'FGIKX',
    name: 'Fidelity Securities Fund: Fidelity Growth & Income Portfolio; Class K',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGJMX',
    name: 'Fidelity Select Portfolios: Fidelity Advisor Communication Services Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGKMX',
    name: 'Fidelity Select Portfolios: Fidelity Advisor Communication Services Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Germany AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FGMC',
    name: 'FG Merger Corp',
    region: 'US',
  },
  {
    symbol: 'FGMCU',
    name: 'FG Merger Corp - Units (1 Ord & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'FGRIX',
    name: 'Fidelity Securities Fund: Fidelity Growth & Income Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FGRO',
    name: 'Fidelity Covington Trust - Fidelity Growth Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'FHB',
    name: 'First Hawaiian INC',
    region: 'US',
  },
  {
    symbol: 'FHCCX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Health Care Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FHCIX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Health Care Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FHLC',
    name: 'Fidelity Covington Trust - Fidelity MSCI Health Care Index ETF',
    region: 'US',
  },
  {
    symbol: 'FHN',
    name: 'First Horizon Corporation',
    region: 'US',
  },
  {
    symbol: 'FHS',
    name: 'First High-School Education Group Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'FHTX',
    name: 'Foghorn Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FHYS',
    name: 'Federated Hermes ETF Trust - Federated Hermes Short Duration High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'FIAC',
    name: 'Focus Impact Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FIACU',
    name: 'Focus Impact Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'FIBK',
    name: 'First Interstate BancSystem Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'FIBR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Fixed Income Balanced Risk Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FICO',
    name: 'Fair, Isaac Corp.',
    region: 'US',
  },
  {
    symbol: 'FICS',
    name: 'First Trust Exchange-Traded Fund VI - First Trust International Developed Capital Strength ETF',
    region: 'US',
  },
  {
    symbol: 'FICV',
    name: 'Frontier Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FICVU',
    name: 'Frontier Investment Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'FID',
    name: 'First Trust Exchange-Traded Fund VI - First Trust S&P International Dividend Aristocrats ETF',
    region: 'US',
  },
  {
    symbol: 'FIDGX',
    name: 'Fidelity Securities Fund: Fidelity Advisor Small Cap Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FIDI',
    name: 'Fidelity Covington Trust - Fidelity International High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'FIDSX',
    name: 'Fidelity Select Portfolios: Financial Services Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FIDU',
    name: 'Fidelity Covington Trust - Fidelity MSCI Industrials Index ETF',
    region: 'US',
  },
  {
    symbol: 'FIGB',
    name: 'Fidelity Covington Trust - Fidelity Investment Grade Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FIGS',
    name: 'Figs Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FIKCX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Health Care Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FIKHX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Technology Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FILL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Energy Producers ETF',
    region: 'US',
  },
  {
    symbol: 'FINM',
    name: 'Marlin Technology Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FINMU',
    name: 'Marlin Technology Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'FINW',
    name: 'FinWise Bancorp',
    region: 'US',
  },
  {
    symbol: 'FINX',
    name: 'Global X Funds - Global X Funds Global X FinTech ETF',
    region: 'US',
  },
  {
    symbol: 'FIS',
    name: 'Fidelity National Information Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'FISI',
    name: 'Financial Institutions Inc.',
    region: 'US',
  },
  {
    symbol: 'FISR',
    name: 'SSgA Active Trust - SPDR SSGA Fixed Income Sector Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'FISV',
    name: 'Fiserv, Inc.',
    region: 'US',
  },
  {
    symbol: 'FITB',
    name: 'Fifth Third Bancorp',
    region: 'US',
  },
  {
    symbol: 'FITE',
    name: 'SPDR Series Trust - SPDR S&P Kensho Future Security ETF',
    region: 'US',
  },
  {
    symbol: 'FITLX',
    name: 'Fidelity Salem Street Trust: Fidelity US Sustainability Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FIVA',
    name: 'Fidelity Covington Trust - Fidelity International Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FIVE',
    name: 'Five Below Inc',
    region: 'US',
  },
  {
    symbol: 'FIVG',
    name: 'ETF Series Solutions Trust - Defiance Next Gen Connectivity ETF',
    region: 'US',
  },
  {
    symbol: 'FIVN',
    name: 'Five9 Inc',
    region: 'US',
  },
  {
    symbol: 'FIVR',
    name: 'Strategy Shares - Strategy Shares Nasdaq 5HANDL Index ETF',
    region: 'US',
  },
  {
    symbol: 'FIW',
    name: 'First Trust Exchange-Traded Fund III - First Trust Water ETF',
    region: 'US',
  },
  {
    symbol: 'FIX',
    name: 'Comfort Systems USA, Inc.',
    region: 'US',
  },
  {
    symbol: 'FIXD',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust TCW Opportunistic Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'FIXX',
    name: 'Homology Medicines Inc',
    region: 'US',
  },
  {
    symbol: 'FIZZ',
    name: 'National Beverage Corp.',
    region: 'US',
  },
  {
    symbol: 'FJAN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'FJP',
    name: 'First Trust Exchange-Traded Fund III - First Trust Japan AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FJUL',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'FJUN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'FKAIX',
    name: 'Federated Hermes Equity Funds: Federated Hermes Kaufmann Small Cap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKALX',
    name: 'Federated Hermes Equity Funds: Federated Hermes Kaufmann Small Cap Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKASX',
    name: 'Federated Hermes Equity Funds: Federated Hermes Kaufmann Small Cap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKBSX',
    name: 'Federated Hermes Equity Funds: Federated Hermes Kaufmann Small Cap Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKCSX',
    name: 'Federated Hermes Equity Funds: Federated Hermes Kaufmann Small Cap Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKDNX',
    name: 'Franklin Custodian Funds: Franklin DynaTech Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKKSX',
    name: 'Federated Hermes Equity Funds: Federated Hermes Kaufmann Small Cap Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKMCX',
    name: 'Fidelity Concord Street Trust: Fidelity Mid-Cap Stock Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FKU',
    name: 'First Trust Exchange-Traded Fund III - First Trust United Kingdom AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FKWL',
    name: 'Franklin Wireless Corp',
    region: 'US',
  },
  {
    symbol: 'FL',
    name: 'Foot Locker Inc',
    region: 'US',
  },
  {
    symbol: 'FLAC',
    name: 'Frazier Lifesciences Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FLACU',
    name: 'Frazier Lifesciences Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'FLAG',
    name: 'First Light Acquisition Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FLAU',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Australia ETF',
    region: 'US',
  },
  {
    symbol: 'FLAX',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Asia ex Japan ETF',
    region: 'US',
  },
  {
    symbol: 'FLBL',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty Senior Loan ETF',
    region: 'US',
  },
  {
    symbol: 'FLBR',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Brazil ETF',
    region: 'US',
  },
  {
    symbol: 'FLCA',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Canada ETF',
    region: 'US',
  },
  {
    symbol: 'FLCB',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty U.S. Core Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLCEX',
    name: 'Fidelity Commonwealth Trust II: Fidelity Large Cap Core Enhanced Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FLCH',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE China ETF',
    region: 'US',
  },
  {
    symbol: 'FLCO',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty Investment Grade Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'FLCPX',
    name: 'Fidelity Salem Street Trust: Fidelity SAI US Large Cap Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FLDR',
    name: 'Fidelity Covington Trust - Fidelity Low Duration Bond Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FLDZ',
    name: 'Listed Funds Trust - RiverNorth Patriot ETF',
    region: 'US',
  },
  {
    symbol: 'FLEE',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Europe ETF',
    region: 'US',
  },
  {
    symbol: 'FLEH',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Europe Hedged ETF',
    region: 'US',
  },
  {
    symbol: 'FLEX',
    name: 'Flex Ltd',
    region: 'US',
  },
  {
    symbol: 'FLFR',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE France ETF',
    region: 'US',
  },
  {
    symbol: 'FLGB',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE United Kingdom ETF',
    region: 'US',
  },
  {
    symbol: 'FLGC',
    name: 'Flora Growth Corp',
    region: 'US',
  },
  {
    symbol: 'FLGEX',
    name: 'Fidelity Commonwealth Trust II: Fidelity Large Cap Growth Enhanced Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FLGR',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Germany ETF',
    region: 'US',
  },
  {
    symbol: 'FLGT',
    name: 'Fulgent Genetics Inc',
    region: 'US',
  },
  {
    symbol: 'FLGV',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty U.S. Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLHK',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Hong Kong ETF',
    region: 'US',
  },
  {
    symbol: 'FLHY',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty High Yield Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'FLIA',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty International Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLIC',
    name: 'First Of Long Island Corp.',
    region: 'US',
  },
  {
    symbol: 'FLIN',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE India ETF',
    region: 'US',
  },
  {
    symbol: 'FLIY',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Italy ETF',
    region: 'US',
  },
  {
    symbol: 'FLJH',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Japan Hedged ETF',
    region: 'US',
  },
  {
    symbol: 'FLJP',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Japan ETF',
    region: 'US',
  },
  {
    symbol: 'FLKR',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE South Korea ETF',
    region: 'US',
  },
  {
    symbol: 'FLL',
    name: 'Full House Resorts, Inc.',
    region: 'US',
  },
  {
    symbol: 'FLLA',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Latin America ETF',
    region: 'US',
  },
  {
    symbol: 'FLLV',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty US Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'FLM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Global Engineering and Construction ETF',
    region: 'US',
  },
  {
    symbol: 'FLMB',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty Federal Tax-Free Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLME',
    name: 'Flame Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FLMI',
    name: 'Franklin Templeton ETF Trust - Franklin Dynamic Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLMN',
    name: 'Falcon Minerals Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FLMX',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Mexico ETF',
    region: 'US',
  },
  {
    symbol: 'FLN',
    name: 'First Trust Exchange-Traded Fund III - First Trust Latin America AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FLNC',
    name: 'Fluence Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FLNG',
    name: 'Flex Lng Ltd',
    region: 'US',
  },
  {
    symbol: 'FLNT',
    name: 'Fluent Inc',
    region: 'US',
  },
  {
    symbol: 'FLO',
    name: 'Flowers Foods, Inc.',
    region: 'US',
  },
  {
    symbol: 'FLOT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Floating Rate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLQD',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ Global Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'FLQE',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'FLQG',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ Global Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FLQH',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ International Equity Hedged ETF',
    region: 'US',
  },
  {
    symbol: 'FLQL',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FLQM',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ U.S. Mid Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FLQS',
    name: 'Franklin Templeton ETF Trust - Franklin LibertyQ U.S. Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FLR',
    name: 'Fluor Corporation',
    region: 'US',
  },
  {
    symbol: 'FLRG',
    name: 'Fidelity Covington Trust - Fidelity U.S. Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'FLRN',
    name: 'SPDR Series Trust - SPDR Bloomberg Investment Grade Floating Rate ETF',
    region: 'US',
  },
  {
    symbol: 'FLRT',
    name: 'Pacer Funds Trust - Pacer Pacific Asset Floating Rate High Income ETF',
    region: 'US',
  },
  {
    symbol: 'FLS',
    name: 'Flowserve Corp.',
    region: 'US',
  },
  {
    symbol: 'FLSA',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Saudi Arabia ETF',
    region: 'US',
  },
  {
    symbol: 'FLSP',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty Systematic Style Premia ETF',
    region: 'US',
  },
  {
    symbol: 'FLSW',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Switzerland ETF',
    region: 'US',
  },
  {
    symbol: 'FLT',
    name: 'Fleetcor Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'FLTB',
    name: 'Fidelity Covington Trust - Fidelity Limited Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLTN',
    name: 'Collaborative Investment Series Trust - Rareview Inflation/Deflation ETF',
    region: 'US',
  },
  {
    symbol: 'FLTR',
    name: 'VanEck ETF Trust - VanEck Investment Grade Floating Rate ETF',
    region: 'US',
  },
  {
    symbol: 'FLTW',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE Taiwan ETF',
    region: 'US',
  },
  {
    symbol: 'FLUD',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty Ultra Short Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FLUX',
    name: 'Flux Power Holdings inc',
    region: 'US',
  },
  {
    symbol: 'FLV',
    name: 'American Century ETF Trust - American Century Focused Large Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'FLVEX',
    name: 'Fidelity Commonwealth Trust II: Fidelity Large Cap Value Enhanced Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FLWS',
    name: '1-800 Flowers.com Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'FLXS',
    name: 'Flexsteel Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'FLYA',
    name: 'SOAR Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FLYW',
    name: 'Flywire Corp',
    region: 'US',
  },
  {
    symbol: 'FLZA',
    name: 'Franklin Templeton ETF Trust - Franklin FTSE South Africa ETF',
    region: 'US',
  },
  {
    symbol: 'FM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Frontier and Select EM ETF',
    region: 'US',
  },
  {
    symbol: 'FMAG',
    name: 'Fidelity Covington Trust - Fidelity Magellan ETF',
    region: 'US',
  },
  {
    symbol: 'FMAO',
    name: 'Farmers & Merchants Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'FMAR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'FMAT',
    name: 'Fidelity Covington Trust - Fidelity MSCI Materials Index ETF',
    region: 'US',
  },
  {
    symbol: 'FMAY',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF - May',
    region: 'US',
  },
  {
    symbol: 'FMB',
    name: 'First Trust Exchange-Traded Fund III - First Trust Managed Municipal ETF',
    region: 'US',
  },
  {
    symbol: 'FMBH',
    name: 'First Mid Bancshares Inc.',
    region: 'US',
  },
  {
    symbol: 'FMC',
    name: 'FMC Corp.',
    region: 'US',
  },
  {
    symbol: 'FMCSX',
    name: 'Fidelity Concord Street Trust: Fidelity Mid-Cap Stock Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FMCX',
    name: 'Northern Lights Fund Trust IV - FMC Excelsior Focus Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FMEIX',
    name: 'Fidelity Commonwealth Trust II: Fidelity Mid Cap Enhanced Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FMET',
    name: 'Fidelity Covington Trust - Fidelity Metaverse ETF',
    region: 'US',
  },
  {
    symbol: 'FMF',
    name: 'First Trust Exchange-Traded Fund V. - First Trust Managed Futures Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'FMFMX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Series Equity Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FMGGX',
    name: 'Franklin Strategic Series: Franklin Small-Mid Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FMHI',
    name: 'First Trust Exchange-Traded Fund III - First Trust Municipal High Income ETF',
    region: 'US',
  },
  {
    symbol: 'FMIL',
    name: 'Fidelity Covington Trust - Fidelity New Millennium ETF',
    region: 'US',
  },
  {
    symbol: 'FMIV',
    name: 'Forum Merger IV Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FMIVU',
    name: 'Forum Merger IV Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FMNB',
    name: 'Farmers National Banc Corp.',
    region: 'US',
  },
  {
    symbol: 'FMNY',
    name: 'First Trust Exchange-Traded Fund III - First Trust New York Municipal High Income ETF',
    region: 'US',
  },
  {
    symbol: 'FMQQ',
    name: 'Exchange Traded Concepts Trust - FMQQ The Next Frontier Internet & Ecommerce ETF',
    region: 'US',
  },
  {
    symbol: 'FMS',
    name: 'Fresenius Medical Care AG & Co. KGaA - ADR',
    region: 'US',
  },
  {
    symbol: 'FMSTX',
    name: 'Federated Hermes Adviser Series: Federated Hermes MDT Large Cap Value Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FMTX',
    name: 'Forma Therapeutics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'FMX',
    name: 'Fomento Economico Mexicano S.A.B. de C.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'FN',
    name: 'Fabrinet',
    region: 'US',
  },
  {
    symbol: 'FNA',
    name: 'Paragon 28 Inc',
    region: 'US',
  },
  {
    symbol: 'FNB',
    name: 'F.N.B. Corp.',
    region: 'US',
  },
  {
    symbol: 'FNCB',
    name: 'FNCB Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'FNCH',
    name: 'Finch Therapeutics Group Inc',
    region: 'US',
  },
  {
    symbol: 'FNCL',
    name: 'Fidelity Covington Trust - Fidelity MSCI Financials Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNCMX',
    name: 'Fidelity Concord Street Trust: Fidelity NASDAQ Composite Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FND',
    name: 'Floor & Decor Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FNDA',
    name: 'Schwab Strategic Trust - Schwab Fundamental U.S. Small Company Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNDB',
    name: 'Schwab Strategic Trust - Schwab Fundamental U.S. Broad Market Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNDC',
    name: 'Schwab Strategic Trust - Schwab Fundamental International Small Company Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNDE',
    name: 'Schwab Strategic Trust - Schwab Fundamental Emerging Markets Large Company Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNDF',
    name: 'Schwab Strategic Trust - Schwab Fundamental International Large Company Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNDX',
    name: 'Schwab Strategic Trust - Schwab Fundamental U.S. Large Company Index ETF',
    region: 'US',
  },
  {
    symbol: 'FNEEX',
    name: 'New Economy Fund; Class 529-F-2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FNF',
    name: 'Fidelity National Financial Inc',
    region: 'US',
  },
  {
    symbol: 'FNGG',
    name: 'Direxion Shares ETF Trust - Direxion Daily Select Large Caps & FANGs',
    region: 'US',
  },
  {
    symbol: 'FNGR',
    name: 'FingerMotion Inc',
    region: 'US',
  },
  {
    symbol: 'FNHC',
    name: 'FedNat Holding Co',
    region: 'US',
  },
  {
    symbol: 'FNI',
    name: 'First Trust Exchange-Traded Fund III - First Trust Chindia ETF',
    region: 'US',
  },
  {
    symbol: 'FNILX',
    name: 'Fidelity Concord Street Trust: Fidelity ZERO Large Cap Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FNK',
    name: 'First Trust Exchange-Traded Fund III - First Trust Mid Cap Value AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FNKO',
    name: 'Funko Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FNLC',
    name: 'First Bancorp Inc (ME)',
    region: 'US',
  },
  {
    symbol: 'FNNEX',
    name: 'New Economy Fund; Class 529-F-3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FNOEF',
    name: 'Furuno Electric Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'FNOV',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Cboe Vest U.S. Equity Buffer ETF - November',
    region: 'US',
  },
  {
    symbol: 'FNTC',
    name: 'Direxion Shares ETF Trust - Direxion Daily FinTech Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'FNV',
    name: 'Franco-Nevada Corporation',
    region: 'US',
  },
  {
    symbol: 'FNVT',
    name: 'Finnovate Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FNVTU',
    name: 'Finnovate Acquisition Corp - Units (1 Ord Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'FNWB',
    name: 'First Northwest Bancorp',
    region: 'US',
  },
  {
    symbol: 'FNWD',
    name: 'Finward Bancorp',
    region: 'US',
  },
  {
    symbol: 'FNX',
    name: 'First Trust Exchange-Traded Fund III - First Trust Mid Cap Core AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FNY',
    name: 'First Trust Exchange-Traded Fund III - First Trust Mid Cap Growth AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FOA',
    name: 'Finance of America Companies Inc',
    region: 'US',
  },
  {
    symbol: 'FOCKX',
    name: 'Fidelity Securities Fund: Fidelity OTC Portfolio; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FOCPX',
    name: 'Fidelity Securities Fund: Fidelity OTC Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FOCSX',
    name: 'Fidelity Securities Fund: Fidelity Small Cap Growth K6 Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FOCT',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF -October',
    region: 'US',
  },
  {
    symbol: 'FOLD',
    name: 'Amicus Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FOMO',
    name: 'Collaborative Investment Series Trust - FOMO ETF',
    region: 'US',
  },
  {
    symbol: 'FONR',
    name: 'Fonar Corp.',
    region: 'US',
  },
  {
    symbol: 'FOPPX',
    name: 'Franklin Strategic Series: Franklin Growth Opportunities Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FORA',
    name: 'Forian Inc.',
    region: 'US',
  },
  {
    symbol: 'FORD',
    name: 'Forward Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'FORG',
    name: 'ForgeRock Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FORH',
    name: 'ETF Opportunities Trust - Formidable ETF',
    region: 'US',
  },
  {
    symbol: 'FORM',
    name: 'FormFactor Inc.',
    region: 'US',
  },
  {
    symbol: 'FORR',
    name: 'Forrester Research Inc.',
    region: 'US',
  },
  {
    symbol: 'FORTY',
    name: 'Formula Systems (1985) Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'FOSL',
    name: 'Fossil Group Inc',
    region: 'US',
  },
  {
    symbol: 'FOTXY',
    name: 'Fotex Holding SE Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'FOUN',
    name: 'Founder SPAC - Class A',
    region: 'US',
  },
  {
    symbol: 'FOUNU',
    name: 'Founder SPAC - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'FOUR',
    name: 'Shift4 Payments Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FOVL',
    name: 'iShares Trust - iShares Focused Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FOX',
    name: 'Fox Corporation - Class B',
    region: 'US',
  },
  {
    symbol: 'FOXA',
    name: 'Fox Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'FOXF',
    name: 'Fox Factory Holding Corp',
    region: 'US',
  },
  {
    symbol: 'FOXW',
    name: 'FoxWayne Enterprises Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FOXWU',
    name: 'FoxWayne Enterprises Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'FPA',
    name: 'First Trust Exchange-Traded Fund III - First Trust Asia Pacific ex-Japan AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FPAC',
    name: 'Far Peak Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FPAG',
    name: 'Northern Lights Fund Trust III - FPA Global Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FPE',
    name: 'First Trust Exchange-Traded Fund III - First Trust Preferred Securities and Income ETF',
    region: 'US',
  },
  {
    symbol: 'FPEI',
    name: 'First Trust Exchange-Traded Fund III - First Trust Institutional Preferred Securities and Income ETF',
    region: 'US',
  },
  {
    symbol: 'FPFD',
    name: 'Fidelity Covington Trust - Fidelity Preferred Securities & Income ETF',
    region: 'US',
  },
  {
    symbol: 'FPRO',
    name: 'Fidelity Covington Trust - Fidelity Real Estate Investment ETF',
    region: 'US',
  },
  {
    symbol: 'FPX',
    name: 'First Trust Exchange-Traded Fund III - First Trust US Equity Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'FPXE',
    name: 'First Trust Exchange-Traded Fund III - First Trust IPOX (R) Europe Equity Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'FPXI',
    name: 'First Trust Exchange-Traded Fund II - First Trust International Equity Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'FQAL',
    name: 'Fidelity Covington Trust - Fidelity Quality Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FRAF',
    name: 'Franklin Financial Services Corp.',
    region: 'US',
  },
  {
    symbol: 'FRBA',
    name: 'First Bank (NJ)',
    region: 'US',
  },
  {
    symbol: 'FRBK',
    name: 'Republic First Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'FRBN',
    name: 'Forbion European Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FRBNU',
    name: 'Forbion European Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'FRC',
    name: 'First Republic Bank',
    region: 'US',
  },
  {
    symbol: 'FRCSX',
    name: 'Franklin Value Investors Trust: Franklin Small Cap Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FRD',
    name: 'Friedman Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'FRDM',
    name: 'Alpha Architect Etf Trust - Alpha Architect Freedom 100 Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'FREE',
    name: 'Whole Earth Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FREL',
    name: 'Fidelity Covington Trust - Fidelity MSCI Real Estate Index ETF',
    region: 'US',
  },
  {
    symbol: 'FREQ',
    name: 'Frequency Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FREY',
    name: 'FREYR Battery',
    region: 'US',
  },
  {
    symbol: 'FRG',
    name: 'Franchise Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FRGI',
    name: 'Fiesta Restaurant Group Inc',
    region: 'US',
  },
  {
    symbol: 'FRHC',
    name: 'Freedom Holding Corp',
    region: 'US',
  },
  {
    symbol: 'FRI',
    name: 'First Trust Exchange-Traded Fund III - First Trust S&P REIT Index Fund',
    region: 'US',
  },
  {
    symbol: 'FRLA',
    name: 'Fortune Rise Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FRLAU',
    name: 'Fortune Rise Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'FRLN',
    name: 'Freeline Therapeutics Holdings plc - ADR',
    region: 'US',
  },
  {
    symbol: 'FRME',
    name: 'First Merchants Corp.',
    region: 'US',
  },
  {
    symbol: 'FRNW',
    name: 'Fidelity Covington Trust - Fidelity Clean Energy ETF',
    region: 'US',
  },
  {
    symbol: 'FRO',
    name: 'Frontline Ltd',
    region: 'US',
  },
  {
    symbol: 'FROG',
    name: 'JFrog Ltd',
    region: 'US',
  },
  {
    symbol: 'FRON',
    name: 'Frontier Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FRONU',
    name: 'Frontier Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FRPH',
    name: 'FRP Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'FRPT',
    name: 'Freshpet Inc',
    region: 'US',
  },
  {
    symbol: 'FRSG',
    name: 'First Reserve Sustainable Growth Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FRSGU',
    name: 'First Reserve Sustainable Growth Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FRSGX',
    name: 'Franklin Strategic Series: Franklin Small-Mid Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FRSH',
    name: 'Freshworks Inc Class A',
    region: 'US',
  },
  {
    symbol: 'FRSIX',
    name: 'Franklin Strategic Series: Franklin Small-Mid Cap Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FRST',
    name: 'Primis Financial Corp',
    region: 'US',
  },
  {
    symbol: 'FRSX',
    name: 'Foresight Autonomous Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'FRTY',
    name: 'Alger Funds - Alger Mid Cap 40 ETF',
    region: 'US',
  },
  {
    symbol: 'FRVFX',
    name: 'Franklin Value Investors Trust: Franklin Small Cap Value Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FRVLX',
    name: 'Franklin Value Investors Trust: Franklin Small Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FRW',
    name: 'PWP Forward Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'FRWAU',
    name: 'PWP Forward Acquisition Corp I - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'FRXB',
    name: 'Forest Road Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'FSAEX',
    name: 'Fidelity Devonshire Trust: Fidelity Series All-Sector Equity Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSBC',
    name: 'Five Star Bancorp',
    region: 'US',
  },
  {
    symbol: 'FSBD',
    name: 'Fidelity Merrimack Street Trust - Fidelity Sustainable Core Plus Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FSBDX',
    name: 'Fidelity Securities Fund: Fidelity Series Blue Chip Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSBW',
    name: 'FS Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'FSCSX',
    name: 'Fidelity Select Portfolios: Software and IT Services Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSEA',
    name: 'First Seacoast Bancorp',
    region: 'US',
  },
  {
    symbol: 'FSEC',
    name: 'Fidelity Covington Trust - Fidelity Investment Grade Securitized ETF',
    region: 'US',
  },
  {
    symbol: 'FSELX',
    name: 'Fidelity Select Portfolios: Semiconductors Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSENX',
    name: 'Fidelity Select Portfolios: Energy Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSEP',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Buffer ETF -September',
    region: 'US',
  },
  {
    symbol: 'FSFG',
    name: 'First Savings Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'FSGAX',
    name: 'Franklin Strategic Series: Franklin Small-Mid Cap Growth Fund; Advisor Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSI',
    name: 'Flexible Solutions International Inc',
    region: 'US',
  },
  {
    symbol: 'FSIG',
    name: 'First Trust Exchange-Traded Fund IV - First Trust Limited Duration Investment Grade Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'FSKAX',
    name: 'Fidelity Concord Street Trust: Fidelity Total Market Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSLBX',
    name: 'Fidelity Select Portfolios: Brokerage and Investment Management Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSLD',
    name: 'Fidelity Merrimack Street Trust - Fidelity Sustainable Low Duration Bond ETF',
    region: 'US',
  },
  {
    symbol: 'FSLR',
    name: 'First Solar Inc',
    region: 'US',
  },
  {
    symbol: 'FSLSX',
    name: 'Fidelity Advisor Series I: Fidelity Value Strategies Fund; Fidelity Value Strategies Fund Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSLY',
    name: 'Fastly Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FSM',
    name: 'Fortuna Silver Mines Inc.',
    region: 'US',
  },
  {
    symbol: 'FSMB',
    name: 'First Trust Exchange-Traded Fund III - First Trust Short Duration Managed Municipal ETF',
    region: 'US',
  },
  {
    symbol: 'FSMD',
    name: 'Fidelity Covington Trust - Fidelity Small-Mid Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'FSMEX',
    name: 'Fidelity Select Portfolios: Medical Technology and Devices Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSMO',
    name: 'Fidelity Covington Trust - Fidelity Small-Mid Cap Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'FSMRX',
    name: 'Franklin Strategic Series: Franklin Small-Mid Cap Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSNB',
    name: 'Fusion Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'FSOAX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Value Strategies Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSOPX',
    name: 'Fidelity Securities Fund: Fidelity Series Small Cap Opportunities Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSPGX',
    name: 'Fidelity Salem Street Trust: Fidelity Large Cap Growth Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSPHX',
    name: 'Fidelity Select Portfolios: Health Care Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSPTX',
    name: 'Fidelity Select Portfolios: Technology Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSR',
    name: 'Fisker Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FSRD',
    name: 'Fast Radius Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'FSRPX',
    name: 'Fidelity Select Portfolios: Retailing Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSRX',
    name: 'FinServ Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'FSRXU',
    name: 'FinServ Acquisition Corp II - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FSS',
    name: 'Federal Signal Corp.',
    region: 'US',
  },
  {
    symbol: 'FSSI',
    name: 'Fortistar Sustainable Solutions Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FSSIU',
    name: 'Fortistar Sustainable Solutions Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'FSSMX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Stock Selector Mid Cap Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSSNX',
    name: 'Fidelity Salem Street Trust: Fidelity Small Cap Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSST',
    name: 'Fidelity Covington Trust - Fidelity Sustainable U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FSSZX',
    name: 'Fidelity Capital Trust: Fidelity Advisor Stock Selector Small Cap Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FST',
    name: 'FAST Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FSTA',
    name: 'Fidelity Covington Trust - Fidelity MSCI Consumer Staples Index ETF',
    region: 'US',
  },
  {
    symbol: 'FSTKX',
    name: 'Federated Hermes Adviser Series: Federated Hermes MDT Large Cap Value Fund; Service Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSTR',
    name: 'L.B. Foster Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'FSTX',
    name: 'F-star Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FSUGY',
    name: 'Fortescue Metals Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'FSUMF',
    name: 'Fortescue Metals Group Ltd',
    region: 'US',
  },
  {
    symbol: 'FSUTX',
    name: 'Fidelity Select Portfolios: Utilities Portfolio',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FSYD',
    name: 'Fidelity Covington Trust - Fidelity Sustainable High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'FSZ',
    name: 'First Trust Exchange-Traded Fund III - First Trust Switzerland AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FTA',
    name: 'First Trust Exchange-Traded Fund III - First Trust Large Capital Value AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FTAA',
    name: 'FTAC Athena Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FTAAU',
    name: 'FTAC Athena Acquisition Corp - Units (1 Ord Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FTAG',
    name: 'First Trust Exchange-Traded Fund III - First Trust Indxx Global Agriculture ETF',
    region: 'US',
  },
  {
    symbol: 'FTAI',
    name: 'Fortress Transportation and Infrastructure Investors LLC - Class A',
    region: 'US',
  },
  {
    symbol: 'FTC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Large Capital Growth AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FTCH',
    name: 'Farfetch Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'FTCI',
    name: 'FTC Solar Inc',
    region: 'US',
  },
  {
    symbol: 'FTCS',
    name: 'First Trust Exchange-Traded Fund III - First Trust Capital Strength ETF',
    region: 'US',
  },
  {
    symbol: 'FTCV',
    name: 'FinTech Acquisition Corp V - Class A',
    region: 'US',
  },
  {
    symbol: 'FTCVU',
    name: 'FinTech Acquisition Corp V - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'FTDR',
    name: 'Frontdoor Inc.',
    region: 'US',
  },
  {
    symbol: 'FTEC',
    name: 'Fidelity Covington Trust - Fidelity MSCI Information Technology Index ETF',
    region: 'US',
  },
  {
    symbol: 'FTEK',
    name: 'Fuel Tech Inc',
    region: 'US',
  },
  {
    symbol: 'FTEV',
    name: 'FinTech Evolution Acquisition Group - Class A',
    region: 'US',
  },
  {
    symbol: 'FTFT',
    name: 'Future FinTech Group Inc',
    region: 'US',
  },
  {
    symbol: 'FTGC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Global Tactical Commodity Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'FTHCX',
    name: 'Fidelity Advisor Series VII: Fidelity Advisor Technology Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FTHI',
    name: 'First Trust Exchange-Traded Fund VI - First Trust BuyWrite Income ETF',
    region: 'US',
  },
  {
    symbol: 'FTHM',
    name: 'Fathom Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'FTI',
    name: 'TechnipFMC plc',
    region: 'US',
  },
  {
    symbol: 'FTII',
    name: 'FutureTech II Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FTIIU',
    name: 'FutureTech II Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'FTK',
    name: 'Flotek Industries Inc',
    region: 'US',
  },
  {
    symbol: 'FTLB',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Hedged BuyWrite Income ETF',
    region: 'US',
  },
  {
    symbol: 'FTLS',
    name: 'First Trust Exchange-Traded Fund III - First Trust Long/Short Equity ETF',
    region: 'US',
  },
  {
    symbol: 'FTNT',
    name: 'Fortinet Inc',
    region: 'US',
  },
  {
    symbol: 'FTPA',
    name: 'FTAC Parnassus Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FTPAU',
    name: 'FTAC Parnassus Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FTRI',
    name: 'First Trust Exchange-Traded Fund III - First Trust Indxx Global Natural Resources Income ETF',
    region: 'US',
  },
  {
    symbol: 'FTRNX',
    name: 'Fidelity Trend Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FTRP',
    name: 'Field Trip Health Ltd.',
    region: 'US',
  },
  {
    symbol: 'FTS',
    name: 'Fortis Inc.',
    region: 'US',
  },
  {
    symbol: 'FTSD',
    name: 'Franklin Templeton ETF Trust - Franklin Liberty Short Duration U.S. Government ETF',
    region: 'US',
  },
  {
    symbol: 'FTSL',
    name: 'First Trust Exchange-Traded Fund III - First Trust Senior Loan Fund ETF',
    region: 'US',
  },
  {
    symbol: 'FTSM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Enhanced Short Maturity ETF',
    region: 'US',
  },
  {
    symbol: 'FTV',
    name: 'Fortive Corp',
    region: 'US',
  },
  {
    symbol: 'FTVI',
    name: 'FinTech Acquisition Corp VI - Class A',
    region: 'US',
  },
  {
    symbol: 'FTVIU',
    name: 'FinTech Acquisition Corp VI - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'FTXG',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Food & Beverage ETF',
    region: 'US',
  },
  {
    symbol: 'FTXH',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Pharmaceuticals ETF',
    region: 'US',
  },
  {
    symbol: 'FTXL',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Semiconductor ETF',
    region: 'US',
  },
  {
    symbol: 'FTXN',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Oil & Gas ETF',
    region: 'US',
  },
  {
    symbol: 'FTXO',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Bank ETF',
    region: 'US',
  },
  {
    symbol: 'FTXR',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Transportation ETF',
    region: 'US',
  },
  {
    symbol: 'FUBO',
    name: 'fuboTV Inc',
    region: 'US',
  },
  {
    symbol: 'FUL',
    name: 'H.B. Fuller Company',
    region: 'US',
  },
  {
    symbol: 'FULC',
    name: 'Fulcrum Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'FULT',
    name: 'Fulton Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'FUMB',
    name: 'First Trust Exchange-Traded Fund III - First Trust Ultra Short Duration Municipal ETF',
    region: 'US',
  },
  {
    symbol: 'FUN',
    name: 'Cedar Fair L.P.',
    region: 'US',
  },
  {
    symbol: 'FUNC',
    name: 'First United Corporation',
    region: 'US',
  },
  {
    symbol: 'FUNL',
    name: 'Trust For Advised Portfolios - CornerCap Fundametrics Large-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'FURY',
    name: 'Fury Gold Mines Ltd.',
    region: 'US',
  },
  {
    symbol: 'FUSB',
    name: 'First US Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'FUSN',
    name: 'Fusion Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'FUT',
    name: 'ProShares Trust - ProShares Managed Futures Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'FUTY',
    name: 'Fidelity Covington Trust - Fidelity MSCI Utilities Index ETF',
    region: 'US',
  },
  {
    symbol: 'FUV',
    name: 'Arcimoto Inc',
    region: 'US',
  },
  {
    symbol: 'FV',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dorsey Wright Focus 5 ETF',
    region: 'US',
  },
  {
    symbol: 'FVADX',
    name: 'Franklin Value Investors Trust: Franklin Small Cap Value Fund; Advisor Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FVAL',
    name: 'Fidelity Covington Trust - Fidelity Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'FVAM',
    name: '5:01 Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FVC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dorsey Wright Dynamic Focus 5 ETF',
    region: 'US',
  },
  {
    symbol: 'FVCB',
    name: 'FVCBankcorp Inc',
    region: 'US',
  },
  {
    symbol: 'FVD',
    name: 'First Trust Exchange-Traded Fund III - First Trust Value Line Dividend Index Fund',
    region: 'US',
  },
  {
    symbol: 'FVDFX',
    name: 'Fidelity Puritan Trust: Fidelity Value Discovery Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FVDKX',
    name: 'Fidelity Puritan Trust: Fidelity Value Discovery Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FVFRX',
    name: 'Franklin Value Investors Trust: Franklin Small Cap Value Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FVIV',
    name: 'Fortress Value Acquisition Corp IV - Class A',
    region: 'US',
  },
  {
    symbol: 'FVLKX',
    name: 'Fidelity Capital Trust: Fidelity Value Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FVRR',
    name: 'Fiverr International Ltd',
    region: 'US',
  },
  {
    symbol: 'FVSKX',
    name: 'Fidelity Advisor Series I: Fidelity Value Strategies Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FVT',
    name: 'Fortress Value Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'FWAC',
    name: 'Fifth Wall Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'FWBI',
    name: 'First Wave BioPharma Inc',
    region: 'US',
  },
  {
    symbol: 'FWONA',
    name: 'Liberty Media Corp. (Tracking Stock -Liberty Formula 1) Series A',
    region: 'US',
  },
  {
    symbol: 'FWONB',
    name: 'Liberty Media Corp. (Tracking Stock -Liberty Formula 1) Series B',
    region: 'US',
  },
  {
    symbol: 'FWONK',
    name: 'Liberty Media Corp. (Tracking Stock -Liberty Formula 1) Series C',
    region: 'US',
  },
  {
    symbol: 'FWP',
    name: 'Forward Pharma A/S - ADR',
    region: 'US',
  },
  {
    symbol: 'FWRD',
    name: 'Forward Air Corp.',
    region: 'US',
  },
  {
    symbol: 'FWRG',
    name: 'First Watch Restaurant Group Inc',
    region: 'US',
  },
  {
    symbol: 'FXA',
    name: 'Rydex Investments - Invesco CurrencyShares Australian Dollar Trust',
    region: 'US',
  },
  {
    symbol: 'FXAIX',
    name: 'Fidelity Concord Street Trust: Fidelity 500 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FXB',
    name: 'Rydex Investments - Invesco CurrencyShares British Pound Sterling Trust',
    region: 'US',
  },
  {
    symbol: 'FXC',
    name: 'Rydex Investments - Invesco CurrencyShares Canadian Dollar Trust',
    region: 'US',
  },
  {
    symbol: 'FXCO',
    name: 'Financial Strategies Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'FXD',
    name: 'First Trust Exchange-Traded Fund III - First Trust Consumer Discretionary AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXE',
    name: 'Rydex Investments - Invesco CurrencyShares Euro Trust',
    region: 'US',
  },
  {
    symbol: 'FXF',
    name: 'Rydex Investments - Invesco CurrencyShares Swiss Franc Trust',
    region: 'US',
  },
  {
    symbol: 'FXG',
    name: 'First Trust Exchange-Traded Fund III - First Trust Consumer Staples AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXH',
    name: 'First Trust Exchange-Traded Fund III - First Trust Health Care AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares China Large-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'FXL',
    name: 'First Trust Exchange-Traded Fund III - First Trust Technology AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXLV',
    name: 'F45 Training Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'FXN',
    name: 'First Trust Exchange-Traded Fund III - First Trust Energy AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXNC',
    name: 'First National Corp. (Strasburg, VA)',
    region: 'US',
  },
  {
    symbol: 'FXO',
    name: 'First Trust Exchange-Traded Fund III - First Trust Financials AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXP',
    name: 'ProShares Trust - ProShares UltraShort FTSE China 50',
    region: 'US',
  },
  {
    symbol: 'FXR',
    name: 'First Trust Exchange-Traded Fund III - First Trust Industrials/Producer Durables AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXU',
    name: 'First Trust Exchange-Traded Fund III - First Trust Utilities AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FXY',
    name: 'Rydex Investments - Invesco CurrencyShares Japanese Yen Trust',
    region: 'US',
  },
  {
    symbol: 'FXZ',
    name: 'First Trust Exchange-Traded Fund III - First Trust Materials AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FYBR',
    name: 'Frontier Communications Parent Inc',
    region: 'US',
  },
  {
    symbol: 'FYC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Small Cap Growth AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FYLD',
    name: 'Cambria Investment Management LP - Cambria Foreign Shareholder Yield ETF',
    region: 'US',
  },
  {
    symbol: 'FYT',
    name: 'First Trust Exchange-Traded Fund III - First Trust Small Cap Value AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FYX',
    name: 'First Trust Exchange-Traded Fund III - First Trust Small Cap Core AlphaDEX Fund',
    region: 'US',
  },
  {
    symbol: 'FZAFX',
    name: 'Fidelity Advisor Series I: Fidelity Advisor Equity Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FZFLX',
    name: 'Fidelity Salem Street Trust: Fidelity SAI Small-Mid Cap 500 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'FZT',
    name: 'FAST Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'G',
    name: 'Genpact Ltd',
    region: 'US',
  },
  {
    symbol: 'GAA',
    name: 'Cambria Investment Management LP - Cambria Global Asset Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'GABC',
    name: 'German American Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'GACQ',
    name: 'Global Consumer Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'GACQU',
    name: 'Global Consumer Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GAIA',
    name: 'Gaia Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GAL',
    name: 'SSgA Active Trust - SPDR SSgA Global Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'GALT',
    name: 'Galectin Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'GAMB',
    name: 'Gambling.com Group Ltd',
    region: 'US',
  },
  {
    symbol: 'GAMC',
    name: 'Golden Arrow Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GAMCU',
    name: 'Golden Arrow Merger Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GAME',
    name: 'Engine Gaming and Media Inc',
    region: 'US',
  },
  {
    symbol: 'GAMR',
    name: 'ETF Managers Group LLC - Wedbush ETFMG Video Game Tech ETF',
    region: 'US',
  },
  {
    symbol: 'GAN',
    name: 'GAN Limited',
    region: 'US',
  },
  {
    symbol: 'GANX',
    name: 'Gain Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'GAPA',
    name: 'G&P acquisition corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GAQ',
    name: 'Generation Asia I Acquisition Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'GASS',
    name: 'StealthGas Inc',
    region: 'US',
  },
  {
    symbol: 'GAST',
    name: 'Gabelli ETFs Trust - Gabelli Asset ETF',
    region: 'US',
  },
  {
    symbol: 'GATE',
    name: 'Marblegate Acquisition Corp - Series A',
    region: 'US',
  },
  {
    symbol: 'GATEU',
    name: 'Marblegate Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GATO',
    name: 'Gatos Silver Inc',
    region: 'US',
  },
  {
    symbol: 'GATX',
    name: 'GATX Corp.',
    region: 'US',
  },
  {
    symbol: 'GAU',
    name: 'Galiano Gold Inc',
    region: 'US',
  },
  {
    symbol: 'GB',
    name: 'Global Blue Group Holding AG',
    region: 'US',
  },
  {
    symbol: 'GBCI',
    name: 'Glacier Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'GBDV',
    name: 'Global Beta ETF Trust - Global Beta Smart Income ETF',
    region: 'US',
  },
  {
    symbol: 'GBF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Barclays Government/Credit Bond Fund',
    region: 'US',
  },
  {
    symbol: 'GBGR',
    name: 'Global Beta ETF Trust - Global Beta Rising Stars ETF',
    region: 'US',
  },
  {
    symbol: 'GBIL',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access Treasury 0-1 Year ETF',
    region: 'US',
  },
  {
    symbol: 'GBIO',
    name: 'Generation Bio Co',
    region: 'US',
  },
  {
    symbol: 'GBLD',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco MSCI Green Building ETF',
    region: 'US',
  },
  {
    symbol: 'GBLI',
    name: 'Global Indemnity Group LLC - Class A',
    region: 'US',
  },
  {
    symbol: 'GBLO',
    name: 'Global Beta ETF Trust - Global Beta Low Beta ETF',
    region: 'US',
  },
  {
    symbol: 'GBNH',
    name: 'Greenbrook TMS Inc',
    region: 'US',
  },
  {
    symbol: 'GBNY',
    name: 'Generations Bancorp NY Inc',
    region: 'US',
  },
  {
    symbol: 'GBOOF',
    name: 'Grupo Financiero Banorte - Class O',
    region: 'US',
  },
  {
    symbol: 'GBOOY',
    name: 'Grupo Financiero Banorte - ADR',
    region: 'US',
  },
  {
    symbol: 'GBOX',
    name: 'GreenBox POS',
    region: 'US',
  },
  {
    symbol: 'GBR',
    name: 'New Concept Energy Inc',
    region: 'US',
  },
  {
    symbol: 'GBRG',
    name: 'Goldenbridge Acquisition Ltd',
    region: 'US',
  },
  {
    symbol: 'GBRGU',
    name: 'Goldenbridge Acquisition Ltd - Units (1 Ord shares,1 Warr & 1 Rts )',
    region: 'US',
  },
  {
    symbol: 'GBS',
    name: 'GBS Inc',
    region: 'US',
  },
  {
    symbol: 'GBT',
    name: 'Global Blood Therapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'GBUY',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Future Consumer Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GBX',
    name: 'Greenbrier Cos., Inc.',
    region: 'US',
  },
  {
    symbol: 'GCBC',
    name: 'Greene County Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'GCC',
    name: 'WisdomTree Commodity Services LLC - WisdomTree EnhancedContinuous Commodity Index Fund',
    region: 'US',
  },
  {
    symbol: 'GCGIX',
    name: 'Goldman Sachs Trust: Goldman Sachs Large Cap Growth Insights Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GCI',
    name: 'Gannett Co Inc.',
    region: 'US',
  },
  {
    symbol: 'GCIG',
    name: 'Valued Advisers Trust - Genuine Investors ETF',
    region: 'US',
  },
  {
    symbol: 'GCLN',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Bloomberg Clean Energy Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GCMG',
    name: 'GCM Grosvenor Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GCO',
    name: 'Genesco Inc.',
    region: 'US',
  },
  {
    symbol: 'GCOR',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GCOW',
    name: 'Pacer Funds Trust - Pacer Global Cash Cows Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'GCP',
    name: 'GCP Applied Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'GCTK',
    name: 'GlucoTrack Inc',
    region: 'US',
  },
  {
    symbol: 'GD',
    name: 'General Dynamics Corp.',
    region: 'US',
  },
  {
    symbol: 'GDDY',
    name: 'Godaddy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GDE',
    name: 'WisdomTree Trust - WisdomTree Efficient Gold Plus Equity Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'GDEN',
    name: 'Golden Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'GDMA',
    name: 'Alpha Architect Etf Trust - Gadsden Dynamic Multi-Asset ETF',
    region: 'US',
  },
  {
    symbol: 'GDMN',
    name: 'WisdomTree Trust - WisdomTree Efficient Gold Plus Gold Miners Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'GDNR',
    name: 'Gardiner Healthcare Acquisitions Corp',
    region: 'US',
  },
  {
    symbol: 'GDNRU',
    name: 'Gardiner Healthcare Acquisitions Corp - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'GDOC',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Future Health Care Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GDOT',
    name: 'Green Dot Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'GDRX',
    name: 'GoodRx Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GDS',
    name: 'GDS Holdings Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'GDST',
    name: 'Goldenstone Acquisition Ltd',
    region: 'US',
  },
  {
    symbol: 'GDSTU',
    name: 'Goldenstone Acquisition Ltd - Units (1 Ord , 1 War & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'GDVD',
    name: 'Northern Lights Fund Trust IV - R3 Global Dividend Growth ETF',
    region: 'US',
  },
  {
    symbol: 'GDX',
    name: 'VanEck ETF Trust - VanEck Gold Miners ETF',
    region: 'US',
  },
  {
    symbol: 'GDXJ',
    name: 'VanEck ETF Trust - VanEck Junior Gold Miners ETF',
    region: 'US',
  },
  {
    symbol: 'GDYN',
    name: 'Grid Dynamics Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GE',
    name: 'General Electric Co.',
    region: 'US',
  },
  {
    symbol: 'GEEX',
    name: 'Games & Esports Experience Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GEEXU',
    name: 'Games & Esports Experience Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GEF',
    name: 'Greif Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GEF.B',
    name: 'Greif Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'GEG',
    name: 'Great Elm Group Inc',
    region: 'US',
  },
  {
    symbol: 'GEGTX',
    name: 'Columbia Funds Series Trust I: Columbia Large Cap Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GEL',
    name: 'Genesis Energy L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'GEM',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GEMD',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access Emerging Markets USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GENC',
    name: 'Gencor Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'GENE',
    name: 'Genetic Technologies Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'GENI',
    name: 'Genius Sports Limited',
    region: 'US',
  },
  {
    symbol: 'GENQ',
    name: 'Genesis Unicorn Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GENQU',
    name: 'Genesis Unicorn Capital Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'GENY',
    name: 'Principal Exchange-Traded Funds - Principal Millennials ETF',
    region: 'US',
  },
  {
    symbol: 'GEO',
    name: 'Geo Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'GEOS',
    name: 'Geospace Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'GEQAX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Class V Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GEQYX',
    name: 'GuideStone Funds: Equity Index Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GEQZX',
    name: 'GuideStone Funds: Equity Index Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GERM',
    name: 'ETF Managers Trust - ETFMG Treatments Testing and Advancements ETF',
    region: 'US',
  },
  {
    symbol: 'GERN',
    name: 'Geron Corp.',
    region: 'US',
  },
  {
    symbol: 'GES',
    name: 'Guess Inc.',
    region: 'US',
  },
  {
    symbol: 'GET',
    name: 'Getnet Adquirencia E Servicos Para Meios De Pagamento S.A - ADR',
    region: 'US',
  },
  {
    symbol: 'GEVO',
    name: 'Gevo Inc',
    region: 'US',
  },
  {
    symbol: 'GFAI',
    name: 'Guardforce AI Co Ltd',
    region: 'US',
  },
  {
    symbol: 'GFF',
    name: 'Griffon Corp.',
    region: 'US',
  },
  {
    symbol: 'GFGD',
    name: 'Growth for Good Acquisition Corp (The) - Class A',
    region: 'US',
  },
  {
    symbol: 'GFGDU',
    name: 'Growth for Good Acquisition Corp (The) - Units (1 Ord Class A, 1 Rts & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GFGF',
    name: 'Alpha Architect Etf Trust - Guru Favorite Stocks ETF',
    region: 'US',
  },
  {
    symbol: 'GFI',
    name: 'Gold Fields Ltd - ADR - Level II',
    region: 'US',
  },
  {
    symbol: 'GFL',
    name: 'GFL Environmental Inc. (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'GFOF',
    name: 'ETF Series Solutions Trust - Grayscale Future of Finance ETF',
    region: 'US',
  },
  {
    symbol: 'GFOR',
    name: 'Graf Acquisition Corp IV',
    region: 'US',
  },
  {
    symbol: 'GFS',
    name: 'GlobalFoundries Inc',
    region: 'US',
  },
  {
    symbol: 'GFX',
    name: 'Golden Falcon Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GGAA',
    name: 'Genesis Growth Tech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GGAAU',
    name: 'Genesis Growth Tech Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GGAL',
    name: 'Grupo Financiero Galicia - ADR',
    region: 'US',
  },
  {
    symbol: 'GGB',
    name: 'Gerdau S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'GGG',
    name: 'Graco Inc.',
    region: 'US',
  },
  {
    symbol: 'GGGPX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGGV',
    name: 'G3 VRM Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GGGVU',
    name: 'G3 VRM Acquisition Corp - Units (1 Ord Class A & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'GGMC',
    name: 'Glenfarne Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GGMCU',
    name: 'Glenfarne Merger Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GGOAX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGOIX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGORX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGOSX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Service Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGOTX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGOUX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGPI',
    name: 'Gores Guggenheim Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GGPIU',
    name: 'Gores Guggenheim Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'GGQPX',
    name: 'Goldman Sachs Trust: Goldman Sachs Growth Opportunities Fund; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GGRW',
    name: 'Gabelli ETFs Trust - Gabelli Growth Innovators ETF',
    region: 'US',
  },
  {
    symbol: 'GH',
    name: 'Guardant Health Inc',
    region: 'US',
  },
  {
    symbol: 'GHAC',
    name: 'Gaming & Hospitality Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GHACU',
    name: 'Gaming & Hospitality Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GHC',
    name: 'Graham Holdings Co. - Class B',
    region: 'US',
  },
  {
    symbol: 'GHG',
    name: 'GreenTree Hospitality Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'GHIX',
    name: 'Gores Holdings IX Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GHIXU',
    name: 'Gores Holdings IX Inc - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GHL',
    name: 'Greenhill & Co Inc',
    region: 'US',
  },
  {
    symbol: 'GHM',
    name: 'Graham Corp.',
    region: 'US',
  },
  {
    symbol: 'GHRS',
    name: 'GH Research PLC',
    region: 'US',
  },
  {
    symbol: 'GHSI',
    name: 'Guardion Health Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'GHTA',
    name: 'Collaborative Investment Series Trust - Goose Hollow Tactical Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'GHYB',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GHYG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares US & Intl High Yield Corp Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GIA',
    name: 'GigCapital5 Inc',
    region: 'US',
  },
  {
    symbol: 'GIAC',
    name: 'Gesher I Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'GIACU',
    name: 'Gesher I Acquisition Corp - Units (1 Ord Share & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GIB',
    name: 'CGI Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GIC',
    name: 'Global Industrial Co',
    region: 'US',
  },
  {
    symbol: 'GIFI',
    name: 'Gulf Island Fabrication, Inc.',
    region: 'US',
  },
  {
    symbol: 'GIGB',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GIGE',
    name: 'Tidal ETF Trust - SoFi Gig Economy ETF',
    region: 'US',
  },
  {
    symbol: 'GIGM',
    name: 'Gigamedia',
    region: 'US',
  },
  {
    symbol: 'GII',
    name: 'SSgA Active Trust - SPDR S&P Global Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'GIII',
    name: 'G-III Apparel Group Ltd.',
    region: 'US',
  },
  {
    symbol: 'GIIX',
    name: 'Gores Holdings VIII Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GIIXU',
    name: 'Gores Holdings VIII Inc - Units (1 Ord Share Class A & 1/8 War)',
    region: 'US',
  },
  {
    symbol: 'GIL',
    name: 'Gildan Activewear Inc',
    region: 'US',
  },
  {
    symbol: 'GILD',
    name: 'Gilead Sciences, Inc.',
    region: 'US',
  },
  {
    symbol: 'GILT',
    name: 'Gilat Satellite Networks',
    region: 'US',
  },
  {
    symbol: 'GINN',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Innovate Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GIS',
    name: 'General Mills, Inc.',
    region: 'US',
  },
  {
    symbol: 'GISTX',
    name: 'Goldman Sachs Trust: Goldman Sachs Technology Opportunities Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GITIX',
    name: 'Goldman Sachs Trust: Goldman Sachs Technology Opportunities Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GIW',
    name: 'GigInternational1 Inc',
    region: 'US',
  },
  {
    symbol: 'GIWWU',
    name: 'GigInternational1 Inc - Units (1 Ord Share & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GK',
    name: 'Advisorshares Trust - AdvisorShares Gerber Kawasaki ETF',
    region: 'US',
  },
  {
    symbol: 'GKOS',
    name: 'Glaukos Corporation',
    region: 'US',
  },
  {
    symbol: 'GL',
    name: 'Globe Life Inc',
    region: 'US',
  },
  {
    symbol: 'GLAQ',
    name: 'Globis Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'GLAQU',
    name: 'Globis Acquisition Corp - Unit (1 Ordinary share & 1 Wrt)',
    region: 'US',
  },
  {
    symbol: 'GLBE',
    name: 'Global E Online Ltd',
    region: 'US',
  },
  {
    symbol: 'GLBL',
    name: 'Cartesian Growth Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GLBLU',
    name: 'Cartesian Growth Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GLBS',
    name: 'Globus Maritime Ltd',
    region: 'US',
  },
  {
    symbol: 'GLBZ',
    name: 'Glen Burnie Bancorp',
    region: 'US',
  },
  {
    symbol: 'GLCN',
    name: 'VanEck ETF Trust - VanEck China Growth Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'GLCTX',
    name: 'Goldman Sachs Trust: Goldman Sachs Large Cap Growth Insights Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GLD',
    name: 'SSgA Active Trust - SPDR Gold Shares ETF',
    region: 'US',
  },
  {
    symbol: 'GLDB',
    name: 'Strategy Shares - Strategy Shares Gold-Hedged Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GLDD',
    name: 'Great Lakes Dredge & Dock Corporation',
    region: 'US',
  },
  {
    symbol: 'GLDG',
    name: 'GoldMining Inc',
    region: 'US',
  },
  {
    symbol: 'GLDM',
    name: 'World Gold Trust - SPDR Gold MiniShares Trust',
    region: 'US',
  },
  {
    symbol: 'GLDX',
    name: 'USCF ETF Trust - USCF Gold Strategy Plus Income Fund',
    region: 'US',
  },
  {
    symbol: 'GLEE',
    name: 'Gladstone Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GLEEU',
    name: 'Gladstone Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GLG',
    name: 'TD Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'GLHA',
    name: 'Glass Houses Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GLHAU',
    name: 'Glass Houses Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GLIF',
    name: 'AGF Investments Trust - AGFiQ Global Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'GLIN',
    name: 'VanEck ETF Trust - VanEck India Growth Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'GLL',
    name: 'ProShares Trust - ProShares UltraShort Gold',
    region: 'US',
  },
  {
    symbol: 'GLLI',
    name: 'Globalink Investment Inc',
    region: 'US',
  },
  {
    symbol: 'GLLIU',
    name: 'Globalink Investment Inc - Units (1 Ord, 1 Rts & 1 War)',
    region: 'US',
  },
  {
    symbol: 'GLMD',
    name: 'Galmed Pharmaceuticals Ltd',
    region: 'US',
  },
  {
    symbol: 'GLNG',
    name: 'Golar Lng',
    region: 'US',
  },
  {
    symbol: 'GLOB',
    name: 'Globant S.A.',
    region: 'US',
  },
  {
    symbol: 'GLOP',
    name: 'Gaslog Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'GLOV',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta World Low Vol Plus Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GLP',
    name: 'Global Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'GLPG',
    name: 'Galapagos NV - ADR',
    region: 'US',
  },
  {
    symbol: 'GLRE',
    name: 'Greenlight Capital Re Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'GLRY',
    name: 'Northern Lights Fund Trust IV - Inspire Faithward Mid Cap Momentum ESG ETF',
    region: 'US',
  },
  {
    symbol: 'GLS',
    name: 'Gelesis Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'GLSI',
    name: 'Greenwich LifeSciences Inc',
    region: 'US',
  },
  {
    symbol: 'GLSPT',
    name: 'Global SPAC Partners Co - Subunit (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'GLSPU',
    name: 'Global SPAC Partners Co - Units (1 Subunit & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GLT',
    name: 'Glatfelter Corporation',
    region: 'US',
  },
  {
    symbol: 'GLTA',
    name: 'Galata Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GLTO',
    name: 'Galecto Inc',
    region: 'US',
  },
  {
    symbol: 'GLTR',
    name: 'abrdn ETF - abrdn Physical Precious Metals Basket Shares ETF',
    region: 'US',
  },
  {
    symbol: 'GLUE',
    name: 'Monte Rosa Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'GLW',
    name: 'Corning, Inc.',
    region: 'US',
  },
  {
    symbol: 'GLYC',
    name: 'GlycoMimetics Inc',
    region: 'US',
  },
  {
    symbol: 'GM',
    name: 'General Motors Company',
    region: 'US',
  },
  {
    symbol: 'GMAB',
    name: 'Genmab - ADR',
    region: 'US',
  },
  {
    symbol: 'GMBL',
    name: 'Esports Entertainment Group Inc',
    region: 'US',
  },
  {
    symbol: 'GMDA',
    name: 'Gamida Cell Ltd',
    region: 'US',
  },
  {
    symbol: 'GME',
    name: 'Gamestop Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'GMED',
    name: 'Globus Medical Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GMET',
    name: 'VanEck ETF Trust - VanEck Green Metals ETF',
    region: 'US',
  },
  {
    symbol: 'GMF',
    name: 'SSgA Active Trust - SPDR S&P Emerging Asia Pacific ETF',
    region: 'US',
  },
  {
    symbol: 'GMFI',
    name: 'Aetherium Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GMFIU',
    name: 'Aetherium Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'GMGI',
    name: 'Golden Matrix Group Inc',
    region: 'US',
  },
  {
    symbol: 'GMOM',
    name: 'Cambria Investment Management LP - Cambria Global Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'GMPPX',
    name: 'Goldman Sachs Trust: Goldman Sachs Mid Cap Value Fund; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GMRE',
    name: 'Global Medical REIT Inc',
    region: 'US',
  },
  {
    symbol: 'GMS',
    name: 'GMS Inc',
    region: 'US',
  },
  {
    symbol: 'GMTX',
    name: 'Gemini Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'GMVD',
    name: 'G Medical Innovations Holdings Limited',
    region: 'US',
  },
  {
    symbol: 'GNAC',
    name: 'Group Nine Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GNACU',
    name: 'Group Nine Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GNCA',
    name: 'Genocea Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'GNE',
    name: 'Genie Energy Ltd - Class B',
    region: 'US',
  },
  {
    symbol: 'GNFT',
    name: 'Genfit - ADR',
    region: 'US',
  },
  {
    symbol: 'GNK',
    name: 'Genco Shipping & Trading Limited',
    region: 'US',
  },
  {
    symbol: 'GNLN',
    name: 'Greenlane Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GNMA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares GNMA Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GNOG',
    name: 'Golden Nugget Online Gaming Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GNOM',
    name: 'Global X Funds - Global X Genomics & Biotechnology ETF',
    region: 'US',
  },
  {
    symbol: 'GNPX',
    name: 'Genprex Inc',
    region: 'US',
  },
  {
    symbol: 'GNR',
    name: 'SSgA Active Trust - SPDR S&P Global Natural Resources ETF',
    region: 'US',
  },
  {
    symbol: 'GNRC',
    name: 'Generac Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'GNS',
    name: 'Genius Group Ltd',
    region: 'US',
  },
  {
    symbol: 'GNSS',
    name: 'Genasys Inc',
    region: 'US',
  },
  {
    symbol: 'GNTA',
    name: 'Genenta Science SpA - ADR',
    region: 'US',
  },
  {
    symbol: 'GNTX',
    name: 'Gentex Corp.',
    region: 'US',
  },
  {
    symbol: 'GNTY',
    name: 'Guaranty Bancshares, Inc. (TX)',
    region: 'US',
  },
  {
    symbol: 'GNUS',
    name: 'Genius Brands International Inc',
    region: 'US',
  },
  {
    symbol: 'GNW',
    name: 'Genworth Financial Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GO',
    name: 'Grocery Outlet Holding Corp',
    region: 'US',
  },
  {
    symbol: 'GOAC',
    name: 'GO Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GOAU',
    name: 'ETF Series Solutions Trust - U.S. Global GO GOLD and Precious Metal Miners ETF',
    region: 'US',
  },
  {
    symbol: 'GOBI',
    name: 'Gobi Acquisition Corp Class A',
    region: 'US',
  },
  {
    symbol: 'GOED',
    name: '1847 Goedeker Inc',
    region: 'US',
  },
  {
    symbol: 'GOEV',
    name: 'Canoo Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GOEX',
    name: 'Global X Funds - Global X Gold Explorers ETF',
    region: 'US',
  },
  {
    symbol: 'GOGL',
    name: 'Golden Ocean Group Limited',
    region: 'US',
  },
  {
    symbol: 'GOGN',
    name: 'GoGreen Investments Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GOGO',
    name: 'Gogo Inc',
    region: 'US',
  },
  {
    symbol: 'GOL',
    name: 'Gol Linhas Aereas Inteligentes S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'GOLD',
    name: 'Barrick Gold Corp.',
    region: 'US',
  },
  {
    symbol: 'GOLF',
    name: 'Acushnet Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'GOOG',
    name: 'Alphabet Inc - Class C',
    region: 'US',
  },
  {
    symbol: 'GOOGL',
    name: 'Alphabet Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GOOS',
    name: 'Canada Goose Holdings Inc (Subord Vot Shs)',
    region: 'US',
  },
  {
    symbol: 'GORO',
    name: 'Gold Resource Corporation',
    region: 'US',
  },
  {
    symbol: 'GOSS',
    name: 'Gossamer Bio Inc',
    region: 'US',
  },
  {
    symbol: 'GOTU',
    name: 'Gaotu Techedu Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'GOVT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GOVX',
    name: 'Geovax Labs Inc',
    region: 'US',
  },
  {
    symbol: 'GOVZ',
    name: 'iShares Trust - iShares 25+ Year Treasury STRIPS Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GP',
    name: 'GreenPower Motor Company Inc',
    region: 'US',
  },
  {
    symbol: 'GPAC',
    name: 'Global Partner Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'GPACU',
    name: 'Global Partner Acquisition Corp II - Units (1 Ord Class A, 1/6 War & 1 CVR )',
    region: 'US',
  },
  {
    symbol: 'GPAL',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta Paris-Aligned Climate US Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GPC',
    name: 'Genuine Parts Co.',
    region: 'US',
  },
  {
    symbol: 'GPCO',
    name: 'Golden Path Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'GPCOU',
    name: 'Golden Path Acquisition Corp - Units (1 Ord, 1 Right & 1 War)',
    region: 'US',
  },
  {
    symbol: 'GPDNF',
    name: 'Danone',
    region: 'US',
  },
  {
    symbol: 'GPI',
    name: 'Group 1 Automotive, Inc.',
    region: 'US',
  },
  {
    symbol: 'GPK',
    name: 'Graphic Packaging Holding Co',
    region: 'US',
  },
  {
    symbol: 'GPL',
    name: 'Great Panther Mining Ltd',
    region: 'US',
  },
  {
    symbol: 'GPN',
    name: 'Global Payments, Inc.',
    region: 'US',
  },
  {
    symbol: 'GPOR',
    name: 'Gulfport Energy Corp. (New)',
    region: 'US',
  },
  {
    symbol: 'GPP',
    name: 'Green Plains Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'GPRE',
    name: 'Green Plains Inc',
    region: 'US',
  },
  {
    symbol: 'GPRK',
    name: 'Geopark Limited',
    region: 'US',
  },
  {
    symbol: 'GPRO',
    name: 'GoPro Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'GPS',
    name: 'Gap, Inc.',
    region: 'US',
  },
  {
    symbol: 'GQRE',
    name: 'FlexShares Trust - FlexShares Global Quality FlexShares Global Quality',
    region: 'US',
  },
  {
    symbol: 'GRAB',
    name: 'Grab Holdings Limited - Class A',
    region: 'US',
  },
  {
    symbol: 'GRAY',
    name: 'Graybug Vision Inc',
    region: 'US',
  },
  {
    symbol: 'GRBK',
    name: 'Green Brick Partners Inc',
    region: 'US',
  },
  {
    symbol: 'GRC',
    name: 'Gorman-Rupp Co.',
    region: 'US',
  },
  {
    symbol: 'GRCL',
    name: 'Gracell Biotechnologies Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'GRCY',
    name: 'Greencity Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'GRCYU',
    name: 'Greencity Acquisition Corp - Units (1 Ord Share & 1 War)',
    region: 'US',
  },
  {
    symbol: 'GREE',
    name: 'Greenidge Generation Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GREI',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Future Real Estate and Infrastructure Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GREK',
    name: 'Global X Funds - Global X MSCI Greece ETF',
    region: 'US',
  },
  {
    symbol: 'GRES',
    name: 'IndexIQ ETF Trust - IQ Global Resources ETF',
    region: 'US',
  },
  {
    symbol: 'GRFS',
    name: 'Grifols SA - ADR - Level III',
    region: 'US',
  },
  {
    symbol: 'GRID',
    name: 'First Trust Exchange-Traded Fund III - First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund',
    region: 'US',
  },
  {
    symbol: 'GRIL',
    name: 'Muscle Maker Inc',
    region: 'US',
  },
  {
    symbol: 'GRIN',
    name: 'Grindrod Shipping Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'GRISX',
    name: 'Nationwide Mutual Funds: Nationwide S&P 500 Index Fund; Institutional Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GRMIX',
    name: 'Nationwide Mutual Funds: Nationwide S&P 500 Index Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GRMN',
    name: 'Garmin Ltd',
    region: 'US',
  },
  {
    symbol: 'GRNA',
    name: 'GreenLight Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'GRNB',
    name: 'VanEck ETF Trust - VanEck Green Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GRNQ',
    name: 'Greenpro Capital Corp',
    region: 'US',
  },
  {
    symbol: 'GRNR',
    name: 'Global X Funds - Global X Green Building ETF',
    region: 'US',
  },
  {
    symbol: 'GROM',
    name: 'Grom Social Enterprises Inc',
    region: 'US',
  },
  {
    symbol: 'GROY',
    name: 'Gold Royalty Corp',
    region: 'US',
  },
  {
    symbol: 'GRPH',
    name: 'Graphite Bio Inc',
    region: 'US',
  },
  {
    symbol: 'GRPN',
    name: 'Groupon Inc',
    region: 'US',
  },
  {
    symbol: 'GRTS',
    name: 'Gritstone Bio Inc',
    region: 'US',
  },
  {
    symbol: 'GRTX',
    name: 'Galera Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'GRVI',
    name: 'Grove Inc',
    region: 'US',
  },
  {
    symbol: 'GRVY',
    name: 'Gravity Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'GRWG',
    name: 'GrowGeneration Corp',
    region: 'US',
  },
  {
    symbol: 'GRZZ',
    name: 'Listed Funds Trust - Grizzle Growth ETF',
    region: 'US',
  },
  {
    symbol: 'GS',
    name: 'Goldman Sachs Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'GSAQ',
    name: 'Global Synergy Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'GSAQU',
    name: 'Global Synergy Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GSAT',
    name: 'Globalstar Inc.',
    region: 'US',
  },
  {
    symbol: 'GSBC',
    name: 'Great Southern Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'GSCGX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSCIX',
    name: 'Aberdeen Funds: Aberdeen US Small Cap Equity Fund; InstitutionalClass Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSEE',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs MarketBeta Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSELX',
    name: 'Goldman Sachs Trust: Goldman Sachs US Equity Insights Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSEU',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta Europe Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSEV',
    name: 'Gores Holdings VII Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GSEVU',
    name: 'Gores Holdings VII Inc - Units (1 Ord Share Class A & 1/8 War)',
    region: 'US',
  },
  {
    symbol: 'GSEW',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Equal Weight U.S. Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSFP',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Future Planet Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSFTX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P GSCI Commodity-Indexed Trust',
    region: 'US',
  },
  {
    symbol: 'GSID',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs MarketBeta International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSIE',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSIG',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access Investment Grade Corporate 1-5 Year Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GSIT',
    name: 'GSI Technology Inc',
    region: 'US',
  },
  {
    symbol: 'GSITX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small Cap Value Insights Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSJPX',
    name: 'Goldman Sachs Trust: Goldman Sachs Technology Opportunities Fund; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSJY',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta Japan Equity ET',
    region: 'US',
  },
  {
    symbol: 'GSK',
    name: 'Glaxosmithkline plc - ADR',
    region: 'US',
  },
  {
    symbol: 'GSL',
    name: 'Global Ship Lease Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GSLC',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta U.S. Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSM',
    name: 'Ferroglobe Plc',
    region: 'US',
  },
  {
    symbol: 'GSMAX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSMG',
    name: 'Glory Star New Media Group Holdings Limited',
    region: 'US',
  },
  {
    symbol: 'GSMGX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSMQX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSMYX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPCX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPIX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPRX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPSX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Service Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPTX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPUX',
    name: 'Goldman Sachs Trust: Goldman Sachs Capital Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSPY',
    name: 'Tidal ETF Trust - Gotham Enhanced 500 ETF',
    region: 'US',
  },
  {
    symbol: 'GSQB',
    name: 'G Squared Ascend II Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GSQD',
    name: 'G Squared Ascend I Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GSSC',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs ActiveBeta U.S. Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSST',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access Ultra Short Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GSUS',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs MarketBeta U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GSV',
    name: 'Gold Standard Ventures Corp',
    region: 'US',
  },
  {
    symbol: 'GSWPX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSXIX',
    name: 'Aberdeen Funds: Aberdeen US Small Cap Equity Fund; Institutional Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GSY',
    name: 'Invesco Capital Management LLC - Invesco Ultra Short Duration ETF',
    region: 'US',
  },
  {
    symbol: 'GT',
    name: 'Goodyear Tire & Rubber Co.',
    region: 'US',
  },
  {
    symbol: 'GTAC',
    name: 'Global Technology Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'GTACU',
    name: 'Global Technology Acquisition Corp I - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GTBP',
    name: 'GT Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'GTE',
    name: 'Gran Tierra Energy Inc',
    region: 'US',
  },
  {
    symbol: 'GTEC',
    name: 'Greenland Technologies Holding Corp',
    region: 'US',
  },
  {
    symbol: 'GTEK',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Future Tech Leaders Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GTES',
    name: 'Gates Industrial Corporation plc',
    region: 'US',
  },
  {
    symbol: 'GTH',
    name: 'Genetron Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'GTHX',
    name: 'G1 Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'GTIM',
    name: 'Good Times Restaurants Inc.',
    region: 'US',
  },
  {
    symbol: 'GTIP',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Access Inflation Protected USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GTLB',
    name: 'Gitlab Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GTLS',
    name: 'Chart Industries Inc',
    region: 'US',
  },
  {
    symbol: 'GTMRX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GTMTX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GTMUX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small/Mid Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GTN',
    name: 'Gray Television, Inc.',
    region: 'US',
  },
  {
    symbol: 'GTN.A',
    name: 'Gray Television, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'GTO',
    name: 'Invesco Capital Management LLC - Invesco Total Return Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GTORX',
    name: 'Goldman Sachs Trust: Goldman Sachs Technology Opportunities Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GTPA',
    name: 'Gores Technology Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GTPAU',
    name: 'Gores Technology Partners Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'GTPB',
    name: 'Gores Technology Partners II Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'GTPBU',
    name: 'Gores Technology Partners II Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'GTR',
    name: 'WisdomTree Trust - WisdomTree Target Range Fund',
    region: 'US',
  },
  {
    symbol: 'GTTTX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small Cap Value Insights Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GTTUX',
    name: 'Goldman Sachs Trust: Goldman Sachs Small Cap Value Insights Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'GTX',
    name: 'Garrett Motion Inc - New',
    region: 'US',
  },
  {
    symbol: 'GTY',
    name: 'Getty Realty Corp.',
    region: 'US',
  },
  {
    symbol: 'GTYH',
    name: 'GTY Technology Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'GUNR',
    name: 'FlexShares Trust - FlexShares Global Upstream Natural Resources Index Fund',
    region: 'US',
  },
  {
    symbol: 'GURE',
    name: 'Gulf Resources Inc',
    region: 'US',
  },
  {
    symbol: 'GURU',
    name: 'Global X Funds - Global X Guru Index ETF',
    region: 'US',
  },
  {
    symbol: 'GUSA',
    name: 'Goldman Sachs ETF Trust II - Goldman Sachs MarketBeta U.S. 1000 Equity ETF',
    region: 'US',
  },
  {
    symbol: 'GUSH',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P Oil & Gas Exp. & Prod. Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'GVA',
    name: 'Granite Construction Inc.',
    region: 'US',
  },
  {
    symbol: 'GVAL',
    name: 'Cambria Investment Management LP - Cambria Global Value ETF',
    region: 'US',
  },
  {
    symbol: 'GVCI',
    name: 'Green Visor Financial Technology Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'GVCIU',
    name: 'Green Visor Financial Technology Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'GVI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Intermediate Government/Credit Bond ETF',
    region: 'US',
  },
  {
    symbol: 'GVIP',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Hedge IND VIP ETF',
    region: 'US',
  },
  {
    symbol: 'GVP',
    name: 'GSE Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'GWGH',
    name: 'GWG Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'GWH',
    name: 'ESS Tech Inc',
    region: 'US',
  },
  {
    symbol: 'GWII',
    name: 'Good Works II Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'GWRE',
    name: 'Guidewire Software Inc',
    region: 'US',
  },
  {
    symbol: 'GWRS',
    name: 'Global Water Resources Inc',
    region: 'US',
  },
  {
    symbol: 'GWW',
    name: 'W.W. Grainger Inc.',
    region: 'US',
  },
  {
    symbol: 'GWX',
    name: 'SSgA Active Trust - SPDR S&P International Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'GXC',
    name: 'SSgA Active Trust - SPDR S&P China ETF',
    region: 'US',
  },
  {
    symbol: 'GXG',
    name: 'Global X Funds - Global X MSCI Colombia ETF',
    region: 'US',
  },
  {
    symbol: 'GXII',
    name: 'GX Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'GXIIU',
    name: 'GX Acquisition Corp II - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'GXO',
    name: 'GXO Logistics Inc',
    region: 'US',
  },
  {
    symbol: 'GXTG',
    name: 'Global X Funds - Global X Thematic Growth ETF',
    region: 'US',
  },
  {
    symbol: 'GYLD',
    name: 'Arrow ETF Trust - Arrow Dow Jones Global Yield ETF',
    region: 'US',
  },
  {
    symbol: 'GYRO',
    name: 'Gyrodyne LLC',
    region: 'US',
  },
  {
    symbol: 'H',
    name: 'Hyatt Hotels Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'HA',
    name: 'Hawaiian Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'HAAC',
    name: 'Health Assurance Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HAACU',
    name: 'Health Assurance Acquisition Corp - Units (1 Ord Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'HACAX',
    name: 'Harbor Funds: Harbor Capital Appreciation Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HACK',
    name: 'ETF Managers Group LLC - ETFMG Prime Cyber Security ETF',
    region: 'US',
  },
  {
    symbol: 'HAE',
    name: 'Haemonetics Corp.',
    region: 'US',
  },
  {
    symbol: 'HAFC',
    name: 'Hanmi Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'HAGAX',
    name: 'Carillon Series Trust: Carillon Eagle Mid Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HAGIX',
    name: 'Carillon Series Trust: Carillon Eagle Mid Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HAIA',
    name: 'Healthcare AI Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HAIAU',
    name: 'Healthcare AI Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HAIAX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HAIL',
    name: 'SPDR Series Trust - SPDR S&P Kensho Smart Mobility ETF',
    region: 'US',
  },
  {
    symbol: 'HAIN',
    name: 'Hain Celestial Group Inc',
    region: 'US',
  },
  {
    symbol: 'HAITX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HAL',
    name: 'Halliburton Co.',
    region: 'US',
  },
  {
    symbol: 'HALL',
    name: 'Hallmark Financial Services, Inc',
    region: 'US',
  },
  {
    symbol: 'HALO',
    name: 'Halozyme Therapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'HAP',
    name: 'VanEck ETF Trust - VanEck Natural Resources ETF',
    region: 'US',
  },
  {
    symbol: 'HAPP',
    name: 'Happiness Development Group Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'HAPVY',
    name: 'Hapvida Participacoes Investimento SA - ADR',
    region: 'US',
  },
  {
    symbol: 'HAPY',
    name: 'Harbor ETF Trust - Harbor Corporate Culture Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'HARP',
    name: 'Harpoon Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'HARSX',
    name: 'Carillon Series Trust: Carillon Eagle Mid Cap Growth Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HART',
    name: 'IndexIQ ETF Trust - IQ Healthy Hearts ETF',
    region: 'US',
  },
  {
    symbol: 'HAS',
    name: 'Hasbro, Inc.',
    region: 'US',
  },
  {
    symbol: 'HASCX',
    name: 'Harbor Funds: Harbor Small Cap Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HAUS',
    name: 'Tidal ETF Trust - Home Appreciation U.S. REIT ETF',
    region: 'US',
  },
  {
    symbol: 'HAUZ',
    name: 'DBX ETF Trust - Xtrackers International Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'HAVLX',
    name: 'Harbor Funds: Harbor Large Cap Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HAWX',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI ACWI ex U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'HAYN',
    name: 'Haynes International Inc.',
    region: 'US',
  },
  {
    symbol: 'HAYW',
    name: 'Hayward Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'HBAN',
    name: 'Huntington Bancshares, Inc.',
    region: 'US',
  },
  {
    symbol: 'HBB',
    name: 'Hamilton Beach Brands Holding Co - Class A',
    region: 'US',
  },
  {
    symbol: 'HBCP',
    name: 'Home Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'HBGIX',
    name: 'HARTFORD DISCIPLINED EQUITY HLS FUND IB',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HBI',
    name: 'Hanesbrands Inc',
    region: 'US',
  },
  {
    symbol: 'HBIO',
    name: 'Harvard Bioscience Inc.',
    region: 'US',
  },
  {
    symbol: 'HBM',
    name: 'Hudbay Minerals Inc.',
    region: 'US',
  },
  {
    symbol: 'HBMCX',
    name: 'HARTFORD MIDCAP HLS FUND IB',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HBNC',
    name: 'Horizon Bancorp Inc (IN)',
    region: 'US',
  },
  {
    symbol: 'HBP',
    name: 'Huttig Building Products, Inc.',
    region: 'US',
  },
  {
    symbol: 'HBT',
    name: 'HBT Financial Inc',
    region: 'US',
  },
  {
    symbol: 'HCA',
    name: 'HCA Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'HCAIX',
    name: 'Harbor Funds: Harbor Capital Appreciation Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HCAR',
    name: 'Healthcare Services Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HCARU',
    name: 'Healthcare Services Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HCAT',
    name: 'Health Catalyst Inc',
    region: 'US',
  },
  {
    symbol: 'HCC',
    name: 'Warrior Met Coal Inc',
    region: 'US',
  },
  {
    symbol: 'HCCI',
    name: 'Heritage-Crystal Clean Inc',
    region: 'US',
  },
  {
    symbol: 'HCDI',
    name: 'Harbor Custom Development Inc',
    region: 'US',
  },
  {
    symbol: 'HCI',
    name: 'HCI Group Inc',
    region: 'US',
  },
  {
    symbol: 'HCIC',
    name: 'Hennessy Capital Investment Corp V - Class A',
    region: 'US',
  },
  {
    symbol: 'HCICU',
    name: 'Hennessy Capital Investment Corp V - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'HCII',
    name: 'Hudson Executive Investment Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'HCIIU',
    name: 'Hudson Executive Investment Corp II - Units (1 Ord Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'HCKT',
    name: 'Hackett Group Inc (The)',
    region: 'US',
  },
  {
    symbol: 'HCM',
    name: 'HUTCHMED (China) Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'HCMA',
    name: 'Hcm Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HCMAU',
    name: 'Hcm Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HCNE',
    name: 'Jaws Hurricane Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HCNEU',
    name: 'Jaws Hurricane Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'HCOM',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Schroders Commodity Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'HCP',
    name: 'HashiCorp Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HCRB',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Core Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HCSG',
    name: 'Healthcare Services Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'HCTI',
    name: 'Healthcare Triangle Inc',
    region: 'US',
  },
  {
    symbol: 'HCVI',
    name: 'Hennessy Capital Investment Corp VI - Class A',
    region: 'US',
  },
  {
    symbol: 'HCVIU',
    name: 'Hennessy Capital Investment Corp VI - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'HCWB',
    name: 'HCW Biologics Inc',
    region: 'US',
  },
  {
    symbol: 'HD',
    name: 'Home Depot, Inc.',
    region: 'US',
  },
  {
    symbol: 'HDAW',
    name: 'DBX ETF Trust - Xtrackers MSCI All World ex US High Dividend Yield Equity ETF',
    region: 'US',
  },
  {
    symbol: 'HDB',
    name: 'HDFC Bank Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'HDEF',
    name: 'DBX ETF Trust - Xtrackers MSCI EAFE High Dividend Yield Equity ETF',
    region: 'US',
  },
  {
    symbol: 'HDG',
    name: 'ProShares Trust - ProShares Hedge Replication ETF',
    region: 'US',
  },
  {
    symbol: 'HDGBX',
    name: 'HARTFORD DIVIDEND AND GROWTH HLS FUND IB',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGCX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGE',
    name: 'Advisorshares Trust - Ranger Equity Bear ETF',
    region: 'US',
  },
  {
    symbol: 'HDGFX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGIX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGRX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGSX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGTX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGVX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDGYX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HDIV',
    name: 'Exchange Listed Funds Trust - QRAFT AI-Enhanced U.S. High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'HDMV',
    name: 'First Trust Exchange-Traded Fund III - First Trust Horizon Managed Volatility Developed International ETF',
    region: 'US',
  },
  {
    symbol: 'HDRO',
    name: 'ETF Series Solutions Trust - Defiance Next Gen H2 ETF',
    region: 'US',
  },
  {
    symbol: 'HDSN',
    name: 'Hudson Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'HDV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'HE',
    name: 'Hawaiian Electric Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'HEAR',
    name: 'Turtle Beach Corp',
    region: 'US',
  },
  {
    symbol: 'HEDJ',
    name: 'WisdomTree Trust - WisdomTree Europe Hedged Equity Fund',
    region: 'US',
  },
  {
    symbol: 'HEEM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'HEES',
    name: 'H&E Equipment Services Inc',
    region: 'US',
  },
  {
    symbol: 'HEET',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Schroders ESG US Equity ETF',
    region: 'US',
  },
  {
    symbol: 'HEFA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI EAFE ETF',
    region: 'US',
  },
  {
    symbol: 'HEGD',
    name: 'Listed Funds Trust - Swan Hedged Equity US Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'HEI',
    name: 'Heico Corp.',
    region: 'US',
  },
  {
    symbol: 'HEI.A',
    name: 'Heico Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'HEINY',
    name: 'Heineken N.V - ADR',
    region: 'US',
  },
  {
    symbol: 'HELE',
    name: 'Helen of Troy Ltd',
    region: 'US',
  },
  {
    symbol: 'HELX',
    name: 'Franklin Templeton ETF Trust - Franklin Genomic Advancements ETF',
    region: 'US',
  },
  {
    symbol: 'HEP',
    name: 'Holly Energy Partners L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'HEPA',
    name: 'Hepion Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'HEPS',
    name: 'D-MARKET Electronic Services & Trading - ADR',
    region: 'US',
  },
  {
    symbol: 'HEQT',
    name: 'Simplify Exchange Traded Funds - Simplify Hedged Equity ETF',
    region: 'US',
  },
  {
    symbol: 'HERA',
    name: 'FTAC Hera Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HERAU',
    name: 'FTAC Hera Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'HERD',
    name: 'Pacer Funds Trust - Pacer Cash Cows Fund of Funds ETF',
    region: 'US',
  },
  {
    symbol: 'HERO',
    name: 'Global X Funds - Global X Video Games & Esports ETF',
    region: 'US',
  },
  {
    symbol: 'HES',
    name: 'Hess Corporation',
    region: 'US',
  },
  {
    symbol: 'HESM',
    name: 'Hess Midstream LP - Class A',
    region: 'US',
  },
  {
    symbol: 'HEWC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI Canada ETF',
    region: 'US',
  },
  {
    symbol: 'HEWG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI Germany ETF',
    region: 'US',
  },
  {
    symbol: 'HEWJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI Japan ETF',
    region: 'US',
  },
  {
    symbol: 'HEWU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI United Kingdom ETF',
    region: 'US',
  },
  {
    symbol: 'HEXO',
    name: 'HEXO Corp',
    region: 'US',
  },
  {
    symbol: 'HEZU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI EMU ETF',
    region: 'US',
  },
  {
    symbol: 'HFBL',
    name: 'Home Federal Bancorp Inc (Louisiana)',
    region: 'US',
  },
  {
    symbol: 'HFFG',
    name: 'HF Foods Group Inc.',
    region: 'US',
  },
  {
    symbol: 'HFGO',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Large Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'HFWA',
    name: 'Heritage Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'HFXI',
    name: 'IndexIQ ETF Trust - IQ 50 Percent Hedged FTSE International ETF',
    region: 'US',
  },
  {
    symbol: 'HGBL',
    name: 'Heritage Global Inc',
    region: 'US',
  },
  {
    symbol: 'HGEN',
    name: 'Humanigen Inc',
    region: 'US',
  },
  {
    symbol: 'HGER',
    name: 'Harbor ETF Trust - Harbor All-Weather Inflation Focus ETF',
    region: 'US',
  },
  {
    symbol: 'HGHAX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGHFX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGHIX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGHSX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGHTX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGHVX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGHYX',
    name: 'Hartford Mutual Funds, Inc: Hartford Healthcare Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGICX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGIFX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGIIX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGIRX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGISX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGITX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGIYX',
    name: 'Hartford Mutual Funds, Inc: Hartford Core Equity Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HGTY',
    name: 'Hagerty Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HGV',
    name: 'Hilton Grand Vacations Inc',
    region: 'US',
  },
  {
    symbol: 'HHGC',
    name: 'HHG Capital Corp',
    region: 'US',
  },
  {
    symbol: 'HHGCU',
    name: 'HHG Capital Corp - Units (1 Ord, 1 War & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'HHH',
    name: 'ETF Managers Trust - ETFMG Real Estate Tech ETF',
    region: 'US',
  },
  {
    symbol: 'HHLA',
    name: 'HH&L Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'HHS',
    name: 'Harte-Hanks, Inc.',
    region: 'US',
  },
  {
    symbol: 'HI',
    name: 'Hillenbrand Inc',
    region: 'US',
  },
  {
    symbol: 'HIADX',
    name: 'HARTFORD DIVIDEND AND GROWTH HLS FUND IA',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIAGX',
    name: 'HARTFORD DISCIPLINED EQUITY HLS FUND IA',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIBB',
    name: 'Hibbett Inc',
    region: 'US',
  },
  {
    symbol: 'HIBL',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 High Beta Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'HIBS',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 High Beta Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'HIFS',
    name: 'Hingham Institution For Savings',
    region: 'US',
  },
  {
    symbol: 'HIG',
    name: 'Hartford Financial Services Group Inc.',
    region: 'US',
  },
  {
    symbol: 'HIGA',
    name: 'H.I.G. Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HIGCX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIGJX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIGRX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class R-3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIGSX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIGUX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class R-6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIGYX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HIHO',
    name: 'Highway Holdings Ltd.',
    region: 'US',
  },
  {
    symbol: 'HII',
    name: 'Huntington Ingalls Industries Inc',
    region: 'US',
  },
  {
    symbol: 'HIII',
    name: 'Hudson Executive Investment Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'HIIIU',
    name: 'Hudson Executive Investment Corp III - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'HIL',
    name: 'Hill International Inc',
    region: 'US',
  },
  {
    symbol: 'HILS',
    name: 'Hillstream BioPharma Inc',
    region: 'US',
  },
  {
    symbol: 'HIMS',
    name: 'Hims & Hers Health Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HIMX',
    name: 'Himax Technologies - ADR',
    region: 'US',
  },
  {
    symbol: 'HINKF',
    name: 'Heineken N.V',
    region: 'US',
  },
  {
    symbol: 'HIPO',
    name: 'Hippo Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'HIPS',
    name: 'GraniteShares Gold Trust - GraniteShares HIPS US High Income ETF',
    region: 'US',
  },
  {
    symbol: 'HISF',
    name: 'First Trust Exchange-Traded Fund IV - First Trust High Income Strategic Focus ETF',
    region: 'US',
  },
  {
    symbol: 'HITI',
    name: 'High Tide Inc.',
    region: 'US',
  },
  {
    symbol: 'HIVE',
    name: 'HIVE Blockchain Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'HJEN',
    name: 'Direxion Shares ETF Trust - Direxion Hydrogen ETF',
    region: 'US',
  },
  {
    symbol: 'HJPX',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged JPX-Nikkei 400 ETF',
    region: 'US',
  },
  {
    symbol: 'HKCVF',
    name: 'HK Electric Investments and HK Electric Investments Limited - Stapled Units (Ordinary Share Preference Share)',
    region: 'US',
  },
  {
    symbol: 'HKHHF',
    name: 'Heineken Holdings',
    region: 'US',
  },
  {
    symbol: 'HKHHY',
    name: 'Heineken Holdings - ADR',
    region: 'US',
  },
  {
    symbol: 'HKND',
    name: 'Humankind Benefit Corporation - Humankind US Stock ETF',
    region: 'US',
  },
  {
    symbol: 'HL',
    name: 'Hecla Mining Co.',
    region: 'US',
  },
  {
    symbol: 'HLAH',
    name: 'Hamilton Lane Alliance Holdings I Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HLAHU',
    name: 'Hamilton Lane Alliance Holdings I Inc - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'HLAL',
    name: 'Listed Funds Trust - Wahed FTSE USA Shariah ETF',
    region: 'US',
  },
  {
    symbol: 'HLBZ',
    name: 'Helbiz Inc.',
    region: 'US',
  },
  {
    symbol: 'HLF',
    name: 'Herbalife Nutrition Ltd',
    region: 'US',
  },
  {
    symbol: 'HLG',
    name: 'Hailiang Education Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'HLGE',
    name: 'Lattice Strategies LLC - Hartford Longevity Economy ETF',
    region: 'US',
  },
  {
    symbol: 'HLIO',
    name: 'Helios Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'HLIT',
    name: 'Harmonic, Inc.',
    region: 'US',
  },
  {
    symbol: 'HLLY',
    name: 'Holley Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HLMN',
    name: 'Hillman Solutions Corp',
    region: 'US',
  },
  {
    symbol: 'HLNE',
    name: 'Hamilton Lane Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HLSCX',
    name: 'HARTFORD DISCIPLINED EQUITY HLS FUND IC',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HLT',
    name: 'Hilton Worldwide Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'HLTH',
    name: 'Cue Health Inc',
    region: 'US',
  },
  {
    symbol: 'HLX',
    name: 'Helix Energy Solutions Group Inc',
    region: 'US',
  },
  {
    symbol: 'HMA',
    name: 'Heartland Media Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HMC',
    name: 'Honda Motor - ADR',
    region: 'US',
  },
  {
    symbol: 'HMCO',
    name: 'HumanCo Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HMCOU',
    name: 'HumanCo Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HMLP',
    name: 'Hoegh LNG Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'HMN',
    name: 'Horace Mann Educators Corp.',
    region: 'US',
  },
  {
    symbol: 'HMNF',
    name: 'HMN Financial Inc.',
    region: 'US',
  },
  {
    symbol: 'HMOP',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Municipal Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'HMST',
    name: 'HomeStreet Inc',
    region: 'US',
  },
  {
    symbol: 'HMTV',
    name: 'Hemisphere Media Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HMY',
    name: 'Harmony Gold Mining Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'HNACX',
    name: 'Harbor Funds: Harbor Capital Appreciation Fund; Retirement Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HNDL',
    name: 'Strategy Shares - Nasdaq 7HANDL Index ETF',
    region: 'US',
  },
  {
    symbol: 'HNGR',
    name: 'Hanger Inc',
    region: 'US',
  },
  {
    symbol: 'HNI',
    name: 'HNI Corp.',
    region: 'US',
  },
  {
    symbol: 'HNP',
    name: 'Huaneng Power International Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'HNRA',
    name: 'HNR Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'HNRG',
    name: 'Hallador Energy Co',
    region: 'US',
  },
  {
    symbol: 'HNST',
    name: 'Honest Company Inc (The )',
    region: 'US',
  },
  {
    symbol: 'HOFT',
    name: 'Hooker Furnishings Corporation',
    region: 'US',
  },
  {
    symbol: 'HOFV',
    name: 'Hall of Fame Resort & Entertainment Company',
    region: 'US',
  },
  {
    symbol: 'HOG',
    name: 'Harley-Davidson, Inc.',
    region: 'US',
  },
  {
    symbol: 'HOLD',
    name: 'Advisorshares Trust - AdvisorShares North Square McKee Core Reserves ETF',
    region: 'US',
  },
  {
    symbol: 'HOLI',
    name: 'Hollysys Automation Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'HOLX',
    name: 'Hologic, Inc.',
    region: 'US',
  },
  {
    symbol: 'HOM',
    name: 'Northern Lights Fund Trust II - LifeGoal Home Down Payment Investment ETF',
    region: 'US',
  },
  {
    symbol: 'HOMB',
    name: 'Home Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'HOMZ',
    name: 'ETF Series Solutions Trust - Hoya Capital Housing ETF',
    region: 'US',
  },
  {
    symbol: 'HON',
    name: 'Honeywell International Inc',
    region: 'US',
  },
  {
    symbol: 'HONE',
    name: 'HarborOne Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'HOOK',
    name: 'Hookipa Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'HOPE',
    name: 'Hope Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'HORI',
    name: 'Emerging Markets Horizon Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HORIU',
    name: 'Emerging Markets Horizon Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HOTH',
    name: 'Hoth Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'HOTL',
    name: 'Kelly Strategic ETF Trust - Kelly Hotel & Lodging Sector ETF',
    region: 'US',
  },
  {
    symbol: 'HOUR',
    name: 'Hour Loop Inc',
    region: 'US',
  },
  {
    symbol: 'HOV',
    name: 'Hovnanian Enterprises, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'HOWL',
    name: 'Werewolf Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'HP',
    name: 'Helmerich & Payne, Inc.',
    region: 'US',
  },
  {
    symbol: 'HPE',
    name: 'Hewlett Packard Enterprise Co',
    region: 'US',
  },
  {
    symbol: 'HPK',
    name: 'HighPeak Energy Inc',
    region: 'US',
  },
  {
    symbol: 'HPLT',
    name: 'Home Plate Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HPLTU',
    name: 'Home Plate Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HPQ',
    name: 'HP Inc',
    region: 'US',
  },
  {
    symbol: 'HPX',
    name: 'HPX Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HQI',
    name: 'HireQuest Inc',
    region: 'US',
  },
  {
    symbol: 'HQIAX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQIFX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQIIX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQISX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQITX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQIVX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQIYX',
    name: 'Hartford Mutual Funds, Inc: Hartford Equity Income Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HQY',
    name: 'Healthequity Inc',
    region: 'US',
  },
  {
    symbol: 'HRAUX',
    name: 'Carillon Series Trust: Carillon Eagle Mid Cap Growth Fund; Class R-6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HRB',
    name: 'H&R Block Inc.',
    region: 'US',
  },
  {
    symbol: 'HRCAX',
    name: 'Harbor Funds: Harbor Capital Appreciation Fund; Administrative Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HRCVX',
    name: 'Carillon Series Trust: Carillon Eagle Growth & Income Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'HRI',
    name: 'Herc Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'HRL',
    name: 'Hormel Foods Corp.',
    region: 'US',
  },
  {
    symbol: 'HRMY',
    name: 'Harmony Biosciences Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'HROW',
    name: 'Harrow Health Inc',
    region: 'US',
  },
  {
    symbol: 'HRT',
    name: 'HireRight Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'HRTG',
    name: 'Heritage Insurance Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'HRTX',
    name: 'Heron Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'HSAQ',
    name: 'Health Sciences Acquisitions Corp 2',
    region: 'US',
  },
  {
    symbol: 'HSBC',
    name: 'HSBC Holdings plc - ADR',
    region: 'US',
  },
  {
    symbol: 'HSC',
    name: 'Harsco Corp.',
    region: 'US',
  },
  {
    symbol: 'HSCZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Currency Hedged MSCI EAFE Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'HSDT',
    name: 'Helius Medical Technologies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HSIC',
    name: 'Henry Schein Inc.',
    region: 'US',
  },
  {
    symbol: 'HSII',
    name: 'Heidrick & Struggles International, Inc.',
    region: 'US',
  },
  {
    symbol: 'HSKA',
    name: 'Heska Corp. (Restricted Voting)',
    region: 'US',
  },
  {
    symbol: 'HSMV',
    name: 'First Trust Exchange-Traded Fund III - First Trust Horizon Managed Volatility Small/Mid ETF',
    region: 'US',
  },
  {
    symbol: 'HSON',
    name: 'Hudson Global Inc',
    region: 'US',
  },
  {
    symbol: 'HSRT',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Short Duration ETF',
    region: 'US',
  },
  {
    symbol: 'HST',
    name: 'Host Hotels & Resorts Inc',
    region: 'US',
  },
  {
    symbol: 'HSTM',
    name: 'Healthstream Inc',
    region: 'US',
  },
  {
    symbol: 'HSTO',
    name: 'Histogen Inc',
    region: 'US',
  },
  {
    symbol: 'HSUN',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Sustainable Income ETF',
    region: 'US',
  },
  {
    symbol: 'HSY',
    name: 'Hershey Company',
    region: 'US',
  },
  {
    symbol: 'HTAB',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Schroders Tax Aware Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HTAQ',
    name: 'Hunt Companies Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'HTBI',
    name: 'HomeTrust Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'HTBK',
    name: 'Heritage Commerce Corp.',
    region: 'US',
  },
  {
    symbol: 'HTBX',
    name: 'Heat Biologics Inc',
    region: 'US',
  },
  {
    symbol: 'HTCR',
    name: 'HeartCore Enterprises Inc',
    region: 'US',
  },
  {
    symbol: 'HTEC',
    name: 'Exchange Traded Concepts Trust - ROBO Global Healthcare Technology and Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'HTGM',
    name: 'HTG Molecular Diagnostics Inc',
    region: 'US',
  },
  {
    symbol: 'HTH',
    name: 'Hilltop Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'HTHT',
    name: 'Huazhu Group Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'HTLD',
    name: 'Heartland Express, Inc.',
    region: 'US',
  },
  {
    symbol: 'HTLF',
    name: 'Heartland Financial USA, Inc.',
    region: 'US',
  },
  {
    symbol: 'HTOO',
    name: 'Fusion Fuel Green Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'HTPA',
    name: 'Highland Transcend Partners I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HTRB',
    name: 'Hartford Funds Exchange-Traded Trust - Hartford Total Return Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HTUS',
    name: 'Hull Tactical Asset Allocation LLC - Hull Tactical US ETF',
    region: 'US',
  },
  {
    symbol: 'HTZ',
    name: 'Hertz Global Holdings Inc. (New)',
    region: 'US',
  },
  {
    symbol: 'HUBB',
    name: 'Hubbell Inc.',
    region: 'US',
  },
  {
    symbol: 'HUBG',
    name: 'Hub Group, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'HUBS',
    name: 'HubSpot Inc',
    region: 'US',
  },
  {
    symbol: 'HUDI',
    name: 'Huadi International Group Co Ltd',
    region: 'US',
  },
  {
    symbol: 'HUGE',
    name: 'FSD Pharma Inc - Class B (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'HUGS',
    name: 'USHG Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HUM',
    name: 'Humana Inc.',
    region: 'US',
  },
  {
    symbol: 'HUMA',
    name: 'Humacyte Inc',
    region: 'US',
  },
  {
    symbol: 'HUN',
    name: 'Huntsman Corp',
    region: 'US',
  },
  {
    symbol: 'HURC',
    name: 'Hurco Companies, Inc.',
    region: 'US',
  },
  {
    symbol: 'HURN',
    name: 'Huron Consulting Group Inc',
    region: 'US',
  },
  {
    symbol: 'HUSA',
    name: 'Houston American Energy Corp',
    region: 'US',
  },
  {
    symbol: 'HUSV',
    name: 'First Trust Exchange-Traded Fund III - First Trust Horizon Managed Volatility Domestic ETF',
    region: 'US',
  },
  {
    symbol: 'HUT',
    name: 'Hut 8 Mining Corp',
    region: 'US',
  },
  {
    symbol: 'HUYA',
    name: 'HUYA Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'HVAL',
    name: 'ALPS Fund Services - ALPS Hillman Active Value ETF',
    region: 'US',
  },
  {
    symbol: 'HVBC',
    name: 'HV Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'HVT',
    name: 'Haverty Furniture Cos., Inc.',
    region: 'US',
  },
  {
    symbol: 'HVT.A',
    name: 'Haverty Furniture Cos., Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'HWBK',
    name: 'Hawthorn Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'HWC',
    name: 'Hancock Whitney Corp.',
    region: 'US',
  },
  {
    symbol: 'HWEL',
    name: 'Healthwell Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'HWELU',
    name: 'Healthwell Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'HWKN',
    name: 'Hawkins Inc',
    region: 'US',
  },
  {
    symbol: 'HWKZ',
    name: 'Hawks Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'HWM',
    name: 'Howmet Aerospace Inc',
    region: 'US',
  },
  {
    symbol: 'HXL',
    name: 'Hexcel Corp.',
    region: 'US',
  },
  {
    symbol: 'HY',
    name: 'Hyster-Yale Materials Handling Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HYAC',
    name: 'Haymaker Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'HYACU',
    name: 'Haymaker Acquisition Corp III - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'HYBB',
    name: 'iShares Trust - iShares BB Rated Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYBL',
    name: 'SSgA Active Trust - SPDR Blackstone High Income ETF',
    region: 'US',
  },
  {
    symbol: 'HYD',
    name: 'VanEck ETF Trust - VanEck High Yield Muni ETF',
    region: 'US',
  },
  {
    symbol: 'HYDB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares High Yield Bond Factor ETF',
    region: 'US',
  },
  {
    symbol: 'HYDR',
    name: 'Global X Funds - Global X Hydrogen ETF',
    region: 'US',
  },
  {
    symbol: 'HYDW',
    name: 'DBX ETF Trust - Xtrackers Low Beta High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYEM',
    name: 'VanEck ETF Trust - VanEck Emerging Markets High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYFM',
    name: 'Hydrofarm Holdings Group Inc',
    region: 'US',
  },
  {
    symbol: 'HYG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBoxx USD High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYGH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Interest Rate Hedged High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYGV',
    name: 'FlexShares Trust - FlexShares High Yield Value Scored Bond Index ETF',
    region: 'US',
  },
  {
    symbol: 'HYHG',
    name: 'ProShares Trust - ProShares High Yield-Interest Rate Hedged ETF',
    region: 'US',
  },
  {
    symbol: 'HYIN',
    name: 'WisdomTree Trust - WisdomTree Alternative Income Fund',
    region: 'US',
  },
  {
    symbol: 'HYLB',
    name: 'DBX ETF Trust - Xtrackers USD High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYLD',
    name: 'Exchange Listed Funds Trust - High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'HYLN',
    name: 'Hyliion Holdings Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'HYLS',
    name: 'First Trust Exchange-Traded Fund III - First Trust Tactical High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'HYLV',
    name: 'IndexIQ ETF Trust - IQ S&P High Yield Low Volatility Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYMB',
    name: 'SPDR Series Trust - SPDR Nuveen Bloomberg High Yield Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYMC',
    name: 'Hycroft Mining Holding Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'HYMU',
    name: 'BlackRock ETF Trust II - BlackRock High Yield Muni Income Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYPR',
    name: 'Hyperfine Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HYRE',
    name: 'HyreCar Inc',
    region: 'US',
  },
  {
    symbol: 'HYRM',
    name: 'DBX ETF Trust - Xtrackers Risk Managed USD High Yield Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'HYS',
    name: 'Pimco Exchange Traded Fund - PIMCO 0-5 Year High Yield Corporate Bond Index Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'HYTR',
    name: 'Northern Lights Fund Trust III - CP High Yield Trend ETF',
    region: 'US',
  },
  {
    symbol: 'HYUP',
    name: 'DBX ETF Trust - Xtrackers High Beta High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYXF',
    name: 'iShares Trust - iShares ESG Advanced High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYXU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'HYZD',
    name: 'WisdomTree Trust - WisdomTree Interest Rate Hedged High Yield Bond Fund',
    region: 'US',
  },
  {
    symbol: 'HYZN',
    name: 'Hyzon Motors Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'HZN',
    name: 'Horizon Global Corp',
    region: 'US',
  },
  {
    symbol: 'HZNP',
    name: 'Horizon Therapeutics Plc',
    region: 'US',
  },
  {
    symbol: 'HZO',
    name: 'Marinemax, Inc.',
    region: 'US',
  },
  {
    symbol: 'HZON',
    name: 'Horizon Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'IAA',
    name: 'IAA Inc',
    region: 'US',
  },
  {
    symbol: 'IAC',
    name: 'IAC InterActiveCorp. - New',
    region: 'US',
  },
  {
    symbol: 'IACC',
    name: 'ION Acquisition Corp 3 Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'IAG',
    name: 'Iamgold Corp.',
    region: 'US',
  },
  {
    symbol: 'IAGG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International Aggregate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'IAI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Broker-Dealers ETF',
    region: 'US',
  },
  {
    symbol: 'IAK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Insurance ETF',
    region: 'US',
  },
  {
    symbol: 'IALAX',
    name: 'Transamerica Funds: Transamerica Capital Growth; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'IAPR',
    name: 'Innovator ETFs Trust - Innovator International Developed Power Buffer ETF April',
    region: 'US',
  },
  {
    symbol: 'IART',
    name: 'Integra Lifesciences Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'IAS',
    name: 'Integral Ad Science Holding Corp',
    region: 'US',
  },
  {
    symbol: 'IAT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Regional Banks ETF',
    region: 'US',
  },
  {
    symbol: 'IAU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Gold Trust',
    region: 'US',
  },
  {
    symbol: 'IAU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Gold Trust',
    region: 'US',
  },
  {
    symbol: 'IAUF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Gold Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'IAUM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Gold Trust Micro',
    region: 'US',
  },
  {
    symbol: 'IBA',
    name: 'Industrias Bachoco, S.A.B. DE C.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'IBB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Biotechnology ETF',
    region: 'US',
  },
  {
    symbol: 'IBBQ',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco Nasdaq Biotechnology ETF',
    region: 'US',
  },
  {
    symbol: 'IBCE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Mar 2023 Term Corporate ex-Financials ETF',
    region: 'US',
  },
  {
    symbol: 'IBCP',
    name: 'Independent Bank Corporation (Ionia, MI)',
    region: 'US',
  },
  {
    symbol: 'IBD',
    name: 'Northern Lights Fund Trust IV - Inspire Corporate Bond ESG ETF',
    region: 'US',
  },
  {
    symbol: 'IBDD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Mar 2023 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2022 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2023 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2024 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDQ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2025 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDR',
    name: 'iShares Trust - iShares iBonds Dec 2026 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDS',
    name: 'iShares Trust - iShares iBonds Dec 2027 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDT',
    name: 'iShares Trust - iShares iBonds Dec 2028 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDU',
    name: 'iShares Trust - iShares iBonds Dec 2029 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDV',
    name: 'iShares Trust - iShares iBonds Dec 2030 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBDW',
    name: 'iShares Trust - iShares iBonds Dec 2031 Term Corporate ETF',
    region: 'US',
  },
  {
    symbol: 'IBER',
    name: 'Ibere Pharmaceuticals - Class A',
    region: 'US',
  },
  {
    symbol: 'IBET',
    name: 'ETF Series Solutions Trust - iBET Sports Betting and Gaming ETF',
    region: 'US',
  },
  {
    symbol: 'IBEX',
    name: 'IBEX Ltd',
    region: 'US',
  },
  {
    symbol: 'IBHB',
    name: 'iShares Trust - iShares iBonds 2022 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHC',
    name: 'iShares Trust - iShares iBonds 2023 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHD',
    name: 'iShares Trust - iShares iBonds 2024 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHE',
    name: 'iShares Trust - iShares iBonds 2025 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHF',
    name: 'iShares Trust - iShares iBonds 2026 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHG',
    name: 'iShares Trust - iShares iBonds 2027 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHH',
    name: 'iShares Trust - iShares iBonds 2028 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBHI',
    name: 'iShares Trust - iShares iBonds 2029 Term High Yield and Income ETF',
    region: 'US',
  },
  {
    symbol: 'IBIO',
    name: 'iBio Inc',
    region: 'US',
  },
  {
    symbol: 'IBLC',
    name: 'iShares Trust - iShares Blockchain and Tech ETF',
    region: 'US',
  },
  {
    symbol: 'IBM',
    name: 'International Business Machines Corp.',
    region: 'US',
  },
  {
    symbol: 'IBMK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2022 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBML',
    name: 'iShares Trust - iShares iBonds Dec 2023 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBMM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2024 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBMN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBonds Dec 2025 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBMO',
    name: 'iShares Trust - iShares iBonds Dec 2026 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBMP',
    name: 'iShares Trust - iShares iBonds Dec 2027 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBMQ',
    name: 'iShares Trust - iShares iBonds Dec 2028 Term Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBN',
    name: 'ICICI Bank Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'IBND',
    name: 'SPDR Series Trust - SPDR Bloomberg International Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IBOC',
    name: 'International Bancshares Corp.',
    region: 'US',
  },
  {
    symbol: 'IBP',
    name: 'Installed Building Products Inc',
    region: 'US',
  },
  {
    symbol: 'IBRX',
    name: 'ImmunityBio Inc',
    region: 'US',
  },
  {
    symbol: 'IBTB',
    name: 'iShares Trust - iShares iBonds Dec 2022 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTD',
    name: 'iShares Trust - iShares iBonds Dec 2023 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTE',
    name: 'iShares Trust - iShares iBonds Dec 2024 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTF',
    name: 'iShares Trust - iShares iBonds Dec 2025 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTG',
    name: 'iShares Trust - iShares iBonds Dec 2026 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTH',
    name: 'iShares Trust - iShares iBonds Dec 2027 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTI',
    name: 'iShares Trust - iShares iBonds Dec 2028 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTJ',
    name: 'iShares Trust - iShares iBonds Dec 2029 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTK',
    name: 'iShares Trust - iShares iBonds Dec 2030 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTL',
    name: 'iShares Trust - iShares iBonds Dec 2031 Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'IBTX',
    name: 'Independent Bank Group Inc',
    region: 'US',
  },
  {
    symbol: 'IBUY',
    name: 'Amplify ETF Trust - Amplify Online Retail ETF',
    region: 'US',
  },
  {
    symbol: 'ICAD',
    name: 'Icad Inc',
    region: 'US',
  },
  {
    symbol: 'ICAP',
    name: 'Series Portfolios Trust - InfraCap Equity Income Fund ETF',
    region: 'US',
  },
  {
    symbol: 'ICCC',
    name: 'Immucell Corp.',
    region: 'US',
  },
  {
    symbol: 'ICCH',
    name: 'ICC Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ICCM',
    name: 'Icecure Medical Ltd',
    region: 'US',
  },
  {
    symbol: 'ICD',
    name: 'Independence Contract Drilling Inc',
    region: 'US',
  },
  {
    symbol: 'ICF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Cohen & Steers REIT ETF',
    region: 'US',
  },
  {
    symbol: 'ICFI',
    name: 'ICF International, Inc',
    region: 'US',
  },
  {
    symbol: 'ICHR',
    name: 'Ichor Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'ICL',
    name: 'ICL Group Ltd.',
    region: 'US',
  },
  {
    symbol: 'ICLK',
    name: 'iClick Interactive Asia Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ICLN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Clean Energy ETF',
    region: 'US',
  },
  {
    symbol: 'ICLR',
    name: 'Icon Plc',
    region: 'US',
  },
  {
    symbol: 'ICNC',
    name: 'Iconic Sports Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ICOL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Colombia ETF',
    region: 'US',
  },
  {
    symbol: 'ICOW',
    name: 'Pacer Funds Trust - Pacer Developed Markets International Cash Cows 100 ETF',
    region: 'US',
  },
  {
    symbol: 'ICPT',
    name: 'Intercept Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ICSH',
    name: 'BlackRock Institutional Trust Company N.A. - BlackRock Ultra Short-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ICUI',
    name: 'ICU Medical, Inc.',
    region: 'US',
  },
  {
    symbol: 'ICVT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Convertible Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ICVX',
    name: 'Icosavax Inc',
    region: 'US',
  },
  {
    symbol: 'ID',
    name: 'PARTS iD Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IDA',
    name: 'Idacorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'IDAI',
    name: 'T Stamp Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IDAT',
    name: 'iShares Trust - iShares Cloud 5G and Tech ETF',
    region: 'US',
  },
  {
    symbol: 'IDBA',
    name: 'IDEX Biometrics ASA - ADR',
    region: 'US',
  },
  {
    symbol: 'IDCC',
    name: 'Interdigital Inc',
    region: 'US',
  },
  {
    symbol: 'IDEV',
    name: 'iShares Trust - iShares Core MSCI International Developed Markets ETF',
    region: 'US',
  },
  {
    symbol: 'IDEX',
    name: 'Ideanomics Inc',
    region: 'US',
  },
  {
    symbol: 'IDHD',
    name: 'Invesco Capital Management LLC - Invesco S&P International Developed High Dividend Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'IDHQ',
    name: 'Invesco Capital Management LLC - Invesco S&P International Developed Quality ETF',
    region: 'US',
  },
  {
    symbol: 'IDIV',
    name: 'Metaurus Equity Component Trust - U.S. Equity Cumulative Dividends Fund Series 2027',
    region: 'US',
  },
  {
    symbol: 'IDLB',
    name: 'Invesco Capital Management LLC - Invesco FTSE International Low Beta Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'IDLV',
    name: 'Invesco Capital Management LLC - Invesco S&P International Developed Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'IDME',
    name: 'ETF Series Solutions Trust - International Drawdown Managed Equity ETF',
    region: 'US',
  },
  {
    symbol: 'IDMO',
    name: 'Invesco Capital Management LLC - Invesco S&P International Developed Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'IDN',
    name: 'Intellicheck Inc',
    region: 'US',
  },
  {
    symbol: 'IDNA',
    name: 'iShares Trust - iShares Genomics Immunology and Healthcare ETF',
    region: 'US',
  },
  {
    symbol: 'IDOG',
    name: 'ALPS Advisors Inc - ALPS International Sector Dividend Dogs ETF',
    region: 'US',
  },
  {
    symbol: 'IDR',
    name: 'Idaho Strategic Resources Inc',
    region: 'US',
  },
  {
    symbol: 'IDRA',
    name: 'Idera Pharmaceuticals, Inc.',
    region: 'US',
  },
  {
    symbol: 'IDRV',
    name: 'iShares Trust - iShares Self-Driving EV and Tech ETF',
    region: 'US',
  },
  {
    symbol: 'IDT',
    name: 'IDT Corp. - Class B',
    region: 'US',
  },
  {
    symbol: 'IDTVF',
    name: 'Industrivarden, AB - Class A',
    region: 'US',
  },
  {
    symbol: 'IDU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'IDV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International Select Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'IDW',
    name: 'IDW Media Holdings Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'IDX',
    name: 'VanEck ETF Trust - VanEck Indonesia Index ETF',
    region: 'US',
  },
  {
    symbol: 'IDXX',
    name: 'Idexx Laboratories, Inc.',
    region: 'US',
  },
  {
    symbol: 'IDYA',
    name: 'Ideaya Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'IEA',
    name: 'Infrastructure and Energy Alternatives Inc',
    region: 'US',
  },
  {
    symbol: 'IECS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'IEDI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Discretionary Spending ETF',
    region: 'US',
  },
  {
    symbol: 'IEF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 7-10 Year Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IEFA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core MSCI EAFE ETF',
    region: 'US',
  },
  {
    symbol: 'IEFN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Financials ETF',
    region: 'US',
  },
  {
    symbol: 'IEHS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Healthcare Staples ETF',
    region: 'US',
  },
  {
    symbol: 'IEI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 3-7 Year Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IEIH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Innovative Healthcare ETF',
    region: 'US',
  },
  {
    symbol: 'IEME',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Media and Entertainment ETF',
    region: 'US',
  },
  {
    symbol: 'IEMG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core MSCI Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'IEO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Oil & Gas Exploration & Production ETF',
    region: 'US',
  },
  {
    symbol: 'IEP',
    name: 'Icahn Enterprises L P - Unit',
    region: 'US',
  },
  {
    symbol: 'IESC',
    name: 'IES Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'IETC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Evolved U.S. Technology ETF',
    region: 'US',
  },
  {
    symbol: 'IEUR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core MSCI Europe ETF',
    region: 'US',
  },
  {
    symbol: 'IEUS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Europe Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'IEV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Europe ETF',
    region: 'US',
  },
  {
    symbol: 'IEX',
    name: 'Idex Corporation',
    region: 'US',
  },
  {
    symbol: 'IEZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Oil Equipment & Services ETF',
    region: 'US',
  },
  {
    symbol: 'IFBD',
    name: 'Infobird Co Ltd',
    region: 'US',
  },
  {
    symbol: 'IFF',
    name: 'International Flavors & Fragrances Inc.',
    region: 'US',
  },
  {
    symbol: 'IFGL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International Developed Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'IFIN',
    name: 'InFinT Acquisition Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'IFNNF',
    name: 'Infineon Technologies AG',
    region: 'US',
  },
  {
    symbol: 'IFNNY',
    name: 'Infineon Technologies AG - ADR',
    region: 'US',
  },
  {
    symbol: 'IFRA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'IFRX',
    name: 'InflaRx N.V.',
    region: 'US',
  },
  {
    symbol: 'IFS',
    name: 'Intercorp Financial Services Inc',
    region: 'US',
  },
  {
    symbol: 'IFV',
    name: 'First Trust Exchange-Traded Fund III - First Trust Dorsey Wright International Focus 5 ETF',
    region: 'US',
  },
  {
    symbol: 'IG',
    name: 'Principal Exchange-Traded Funds - Principal Investment Grade Corporate Active ETF',
    region: 'US',
  },
  {
    symbol: 'IGAC',
    name: 'IG Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IGACU',
    name: 'IG Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IGBH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Interest Rate Hedged Long-Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IGC',
    name: 'India Globalization Capital Inc',
    region: 'US',
  },
  {
    symbol: 'IGE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares North American Natural Resources ETF',
    region: 'US',
  },
  {
    symbol: 'IGEB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Investment Grade Bond Factor ETF',
    region: 'US',
  },
  {
    symbol: 'IGF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'IGHG',
    name: 'ProShares Trust - ProShares Investment Grade-Interest Rate Hedged',
    region: 'US',
  },
  {
    symbol: 'IGIB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Trust iShares 5-10 Year Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IGIC',
    name: 'International General Insurance Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'IGLB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 10 Year Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IGLD',
    name: 'First Trust Exchange-Traded Fund - FT Cboe Vest Gold Strategy Target Income ETF',
    region: 'US',
  },
  {
    symbol: 'IGM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Expanded Tech Sector ETF',
    region: 'US',
  },
  {
    symbol: 'IGMS',
    name: 'IGM Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'IGN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares North American Tech-Multimedia Networking ETF',
    region: 'US',
  },
  {
    symbol: 'IGNY',
    name: 'Ignyte Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'IGNYU',
    name: 'Ignyte Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IGOV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IGRO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International Dividend Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IGSB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Trust iShares 1-5 Year Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IGT',
    name: 'International Game Technology PLC',
    region: 'US',
  },
  {
    symbol: 'IGTA',
    name: 'Inception Growth Acquisition Ltd',
    region: 'US',
  },
  {
    symbol: 'IGTAU',
    name: 'Inception Growth Acquisition Ltd - Units (1 Ord, 1/2 War & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'IGV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Expanded Tech-Software Sector ETF',
    region: 'US',
  },
  {
    symbol: 'IH',
    name: 'iHuman Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'IHAK',
    name: 'iShares Trust - iShares Cybersecurity and Tech ETF',
    region: 'US',
  },
  {
    symbol: 'IHDG',
    name: 'WisdomTree Trust - WisdomTree International Hedged Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'IHE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Pharmaceuticals ETF',
    region: 'US',
  },
  {
    symbol: 'IHF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Healthcare Providers ETF',
    region: 'US',
  },
  {
    symbol: 'IHG',
    name: 'Intercontinental Hotels Group - ADR',
    region: 'US',
  },
  {
    symbol: 'IHGIX',
    name: 'Hartford Mutual Funds, Inc: Hartford Dividend & Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'IHI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Medical Devices ETF',
    region: 'US',
  },
  {
    symbol: 'IHRT',
    name: 'iHeartMedia Inc - Class A New',
    region: 'US',
  },
  {
    symbol: 'IHS',
    name: 'IHS Holding Ltd',
    region: 'US',
  },
  {
    symbol: 'IHT',
    name: 'Innsuites Hospitality Trust',
    region: 'US',
  },
  {
    symbol: 'IHY',
    name: 'VanEck ETF Trust - VanEck International High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IHYF',
    name: 'Invesco Capital Management LLC - Invesco High Yield Bond Factor ETF',
    region: 'US',
  },
  {
    symbol: 'IIGD',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco Investment Grade Defensive ETF',
    region: 'US',
  },
  {
    symbol: 'IIGV',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco Investment Grade Value ETF',
    region: 'US',
  },
  {
    symbol: 'III',
    name: 'Information Services Group Inc.',
    region: 'US',
  },
  {
    symbol: 'IIII',
    name: 'INSU Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'IIIIU',
    name: 'INSU Acquisition Corp III - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'IIIN',
    name: 'Insteel Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'IIIV',
    name: 'i3 Verticals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IIN',
    name: 'IntriCon Corporation',
    region: 'US',
  },
  {
    symbol: 'IINN',
    name: 'Inspira Technologies Oxy B.H.N. Ltd',
    region: 'US',
  },
  {
    symbol: 'IIVI',
    name: 'Ii-Vi Inc.',
    region: 'US',
  },
  {
    symbol: 'IJAN',
    name: 'Innovator ETFs Trust - Innovator International Developed Power Buffer ETF January',
    region: 'US',
  },
  {
    symbol: 'IJH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core S&P Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'IJJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P Mid-Cap 400 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IJK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P Mid-Cap 400 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IJR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core S&P Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'IJS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P Small-Cap 600 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IJT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P Small-Cap 600 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IJUL',
    name: 'Innovator ETFs Trust - Innovator International Developed Power Buffer ETF July',
    region: 'US',
  },
  {
    symbol: 'IKNA',
    name: 'Ikena Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'IKT',
    name: 'Inhibikase Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ILCB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'ILCG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Growth ETF',
    region: 'US',
  },
  {
    symbol: 'ILCV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Value ETF',
    region: 'US',
  },
  {
    symbol: 'ILDR',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Innovation Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'ILF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Latin America 40 ETF',
    region: 'US',
  },
  {
    symbol: 'ILLLX',
    name: 'Transamerica Funds: Transamerica Capital Growth; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ILMN',
    name: 'Illumina Inc',
    region: 'US',
  },
  {
    symbol: 'ILTB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core 10+ Year USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IMAB',
    name: 'I-Mab - ADR',
    region: 'US',
  },
  {
    symbol: 'IMAC',
    name: 'IMAC Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'IMANX',
    name: 'Allied Asset Advisors Funds: Iman Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'IMAQ',
    name: 'International Media Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'IMAQU',
    name: 'International Media Acquisition Corp - Units (1 Ord , 1 Rts & 1 War)',
    region: 'US',
  },
  {
    symbol: 'IMAX',
    name: 'Imax Corp',
    region: 'US',
  },
  {
    symbol: 'IMBBF',
    name: 'Imperial Brands Plc',
    region: 'US',
  },
  {
    symbol: 'IMBBY',
    name: 'Imperial Brands Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'IMBI',
    name: 'iMedia Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IMCB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'IMCC',
    name: 'IM Cannabis Corp',
    region: 'US',
  },
  {
    symbol: 'IMCG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Mid-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IMCR',
    name: 'Immunocore Holdings plc - ADR',
    region: 'US',
  },
  {
    symbol: 'IMCV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Mid-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'IMFL',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco International Developed Dynamic Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'IMGN',
    name: 'Immunogen, Inc.',
    region: 'US',
  },
  {
    symbol: 'IMGO',
    name: 'Imago BioSciences Inc',
    region: 'US',
  },
  {
    symbol: 'IMIDX',
    name: 'Professionally Managed Portfolios: Congress Mid Cap Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'IMKTA',
    name: 'Ingles Markets, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'IMLPX',
    name: 'MainGate Trust: MainGate MLP Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'IMMP',
    name: 'Immutep Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'IMMR',
    name: 'Immersion Corp',
    region: 'US',
  },
  {
    symbol: 'IMMX',
    name: 'Immix Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'IMNM',
    name: 'Immunome Inc',
    region: 'US',
  },
  {
    symbol: 'IMO',
    name: 'Imperial Oil Ltd.',
    region: 'US',
  },
  {
    symbol: 'IMOM',
    name: 'Alpha Architect Etf Trust - Alpha Architect International Quantitative Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'IMOS',
    name: 'Chipmos Technologies Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'IMPL',
    name: 'Impel Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'IMPUY',
    name: 'Impala Platinum Holdings Ltd - ADR - Sponsor',
    region: 'US',
  },
  {
    symbol: 'IMPX',
    name: 'AEA-Bridges Impact Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IMRA',
    name: 'Imara Inc',
    region: 'US',
  },
  {
    symbol: 'IMRN',
    name: 'Immuron Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'IMRX',
    name: 'Immuneering Corp Class A',
    region: 'US',
  },
  {
    symbol: 'IMTB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core 5-10 Year USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'IMTE',
    name: 'Integrated Media Technology Limited',
    region: 'US',
  },
  {
    symbol: 'IMTM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Intl Momentum Factor ET',
    region: 'US',
  },
  {
    symbol: 'IMTX',
    name: 'Immatics N.V',
    region: 'US',
  },
  {
    symbol: 'IMUX',
    name: 'Immunic Inc',
    region: 'US',
  },
  {
    symbol: 'IMV',
    name: 'IMV Inc',
    region: 'US',
  },
  {
    symbol: 'IMVT',
    name: 'Immunovant Inc',
    region: 'US',
  },
  {
    symbol: 'IMXI',
    name: 'International Money Express Inc.',
    region: 'US',
  },
  {
    symbol: 'INAB',
    name: 'IN8bio Inc',
    region: 'US',
  },
  {
    symbol: 'INAQ',
    name: 'Insight Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'INBK',
    name: 'First Internet Bancorp',
    region: 'US',
  },
  {
    symbol: 'INBX',
    name: 'Inhibrx Inc',
    region: 'US',
  },
  {
    symbol: 'INCO',
    name: 'Columbia ETF Trust II - Columbia India Consumer ETF',
    region: 'US',
  },
  {
    symbol: 'INCR',
    name: 'Intercure Ltd',
    region: 'US',
  },
  {
    symbol: 'INCY',
    name: 'Incyte Corp.',
    region: 'US',
  },
  {
    symbol: 'INDA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI India ETF',
    region: 'US',
  },
  {
    symbol: 'INDB',
    name: 'Independent Bank Corp.',
    region: 'US',
  },
  {
    symbol: 'INDF',
    name: 'Exchange Traded Concepts Trust - Nifty India Financials ETF',
    region: 'US',
  },
  {
    symbol: 'INDI',
    name: 'Indie Semiconductor Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'INDL',
    name: 'Direxion Shares ETF Trust - Direxion Daily MSCI India Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'INDO',
    name: 'Indonesia Energy Corp Ltd',
    region: 'US',
  },
  {
    symbol: 'INDS',
    name: 'Pacer Funds Trust - Pacer Benchmark Industrial Real Estate SCTR ETF',
    region: 'US',
  },
  {
    symbol: 'INDT',
    name: 'INDUS Realty Trust Inc',
    region: 'US',
  },
  {
    symbol: 'INDY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares India 50 ETF',
    region: 'US',
  },
  {
    symbol: 'INFA',
    name: 'Informatica Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'INFI',
    name: 'Infinity Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'INFL',
    name: 'Listed Funds Trust - Horizon Kinetics Inflation Beneficiaries ETF',
    region: 'US',
  },
  {
    symbol: 'INFN',
    name: 'Infinera Corp.',
    region: 'US',
  },
  {
    symbol: 'INFU',
    name: 'InfuSystem Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'INFY',
    name: 'Infosys Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ING',
    name: 'ING Groep N.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'INGN',
    name: 'Inogen Inc',
    region: 'US',
  },
  {
    symbol: 'INGR',
    name: 'Ingredion Inc',
    region: 'US',
  },
  {
    symbol: 'INKA',
    name: 'KludeIn I Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'INKAU',
    name: 'KludeIn I Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'INKM',
    name: 'SSgA Active Trust - SPDR SSgA Income Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'INKT',
    name: 'MiNK Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'INM',
    name: 'InMed Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'INMB',
    name: 'INmune Bio Inc',
    region: 'US',
  },
  {
    symbol: 'INMD',
    name: 'Inmode Ltd',
    region: 'US',
  },
  {
    symbol: 'INMU',
    name: 'BlackRock ETF Trust II - BlackRock Intermediate Muni Income Bond ETF',
    region: 'US',
  },
  {
    symbol: 'INNO',
    name: 'Harbor ETF Trust - Harbor Disruptive Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'INNV',
    name: 'InnovAge Holding Corp',
    region: 'US',
  },
  {
    symbol: 'INO',
    name: 'Inovio Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'INOD',
    name: 'Innodata Inc',
    region: 'US',
  },
  {
    symbol: 'INPX',
    name: 'Inpixon',
    region: 'US',
  },
  {
    symbol: 'INQQ',
    name: 'Exchange Traded Concepts Trust - India Internet & Ecommerce ETF',
    region: 'US',
  },
  {
    symbol: 'INSE',
    name: 'Inspired Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'INSG',
    name: 'Inseego Corp',
    region: 'US',
  },
  {
    symbol: 'INSM',
    name: 'Insmed Inc',
    region: 'US',
  },
  {
    symbol: 'INSP',
    name: 'Inspire Medical Systems Inc',
    region: 'US',
  },
  {
    symbol: 'INST',
    name: 'Instructure Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'INSW',
    name: 'International Seaways Inc',
    region: 'US',
  },
  {
    symbol: 'INT',
    name: 'World Fuel Services Corp.',
    region: 'US',
  },
  {
    symbol: 'INTA',
    name: 'Intapp Inc',
    region: 'US',
  },
  {
    symbol: 'INTC',
    name: 'Intel Corp.',
    region: 'US',
  },
  {
    symbol: 'INTE',
    name: 'Integral Acquisition Corp 1 - Class A',
    region: 'US',
  },
  {
    symbol: 'INTEU',
    name: 'Integral Acquisition Corp 1 - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'INTF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Intl Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'INTT',
    name: 'Intest Corp.',
    region: 'US',
  },
  {
    symbol: 'INTU',
    name: 'Intuit Inc',
    region: 'US',
  },
  {
    symbol: 'INTZ',
    name: 'Intrusion Inc',
    region: 'US',
  },
  {
    symbol: 'INUV',
    name: 'Inuvo Inc',
    region: 'US',
  },
  {
    symbol: 'INVA',
    name: 'Innoviva Inc',
    region: 'US',
  },
  {
    symbol: 'INVE',
    name: 'Identiv Inc',
    region: 'US',
  },
  {
    symbol: 'INVO',
    name: 'INVO Bioscience Inc',
    region: 'US',
  },
  {
    symbol: 'INVZ',
    name: 'Innoviz Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'INZY',
    name: 'Inozyme Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'IO',
    name: 'ION Geophysical Corp',
    region: 'US',
  },
  {
    symbol: 'IOAC',
    name: 'Innovative International Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IOACU',
    name: 'Innovative International Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IOBT',
    name: 'IO Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'IOCT',
    name: 'Innovator ETFs Trust - Innovator International Developed Power Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'IONM',
    name: 'Assure Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'IONQ',
    name: 'IonQ Inc',
    region: 'US',
  },
  {
    symbol: 'IONS',
    name: 'Ionis Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'IOO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global 100 ETF',
    region: 'US',
  },
  {
    symbol: 'IOSP',
    name: 'Innospec Inc',
    region: 'US',
  },
  {
    symbol: 'IOT',
    name: 'Samsara Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IOVA',
    name: 'Iovance Biotherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'IP',
    name: 'International Paper Co.',
    region: 'US',
  },
  {
    symbol: 'IPA',
    name: 'ImmunoPrecise Antibodies Ltd',
    region: 'US',
  },
  {
    symbol: 'IPAC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core MSCI Pacific ETF',
    region: 'US',
  },
  {
    symbol: 'IPAR',
    name: 'Inter Parfums, Inc.',
    region: 'US',
  },
  {
    symbol: 'IPAX',
    name: 'Inflection Point Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IPAXU',
    name: 'Inflection Point Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IPAY',
    name: 'ETF Managers Group LLC - ETFMG Prime Mobile Payments ETF',
    region: 'US',
  },
  {
    symbol: 'IPDN',
    name: 'Professional Diversity Network Inc',
    region: 'US',
  },
  {
    symbol: 'IPDP',
    name: 'Listed Funds Trust - Dividend Performers ETF',
    region: 'US',
  },
  {
    symbol: 'IPG',
    name: 'Interpublic Group Of Cos., Inc.',
    region: 'US',
  },
  {
    symbol: 'IPGP',
    name: 'IPG Photonics Corp',
    region: 'US',
  },
  {
    symbol: 'IPHA',
    name: 'Innate Pharma - ADR',
    region: 'US',
  },
  {
    symbol: 'IPI',
    name: 'Intrepid Potash Inc',
    region: 'US',
  },
  {
    symbol: 'IPKW',
    name: 'Invesco Capital Management LLC - Invesco International BuyBack Achievers TM ETF',
    region: 'US',
  },
  {
    symbol: 'IPO',
    name: 'Renaissance Capital Greenwich Funds - Renaissance IPO ETF',
    region: 'US',
  },
  {
    symbol: 'IPOD',
    name: 'Social Capital Hedosophia Holdings Corp IV - Class A',
    region: 'US',
  },
  {
    symbol: 'IPOF',
    name: 'Social Capital Hedosophia Holdings Corp VI - Class A',
    region: 'US',
  },
  {
    symbol: 'IPOS',
    name: 'Renaissance Capital Greenwich Funds - Renaissance International IPO ETF',
    region: 'US',
  },
  {
    symbol: 'IPPP',
    name: 'Listed Funds Trust - Preferred-Plus ETF',
    region: 'US',
  },
  {
    symbol: 'IPSC',
    name: 'Century Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'IPVA',
    name: 'InterPrivate II Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IPVF',
    name: 'InterPrivate III Financial Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IPVI',
    name: 'InterPrivate IV InfraTech Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IPVIU',
    name: 'InterPrivate IV InfraTech Partners Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'IPW',
    name: 'iPower Inc',
    region: 'US',
  },
  {
    symbol: 'IPWR',
    name: 'Ideal Power Inc',
    region: 'US',
  },
  {
    symbol: 'IQ',
    name: 'iQIYI Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'IQDE',
    name: 'FlexShares Trust - FlexShares International Quality Dividend Defensive Index Fund',
    region: 'US',
  },
  {
    symbol: 'IQDF',
    name: 'FlexShares Trust - FlexShares International Quality Dividend Index Fund',
    region: 'US',
  },
  {
    symbol: 'IQDG',
    name: 'WisdomTree Trust - WisdomTree International Quality Dividend Growth Fund',
    region: 'US',
  },
  {
    symbol: 'IQDY',
    name: 'FlexShares Trust - FlexShares International Quality Dividend Dynamic Index Fund',
    region: 'US',
  },
  {
    symbol: 'IQIN',
    name: 'IndexIQ ETF Trust - IQ 500 International ETF',
    region: 'US',
  },
  {
    symbol: 'IQLT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Intl Quality Factor ETF',
    region: 'US',
  },
  {
    symbol: 'IQM',
    name: 'Franklin Templeton ETF Trust - Franklin Intelligent Machines ETF',
    region: 'US',
  },
  {
    symbol: 'IQSI',
    name: 'IndexIQ ETF Trust - IQ Candriam ESG International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'IQSU',
    name: 'IndexIQ ETF Trust - IQ Candriam ESG US Equity ETF',
    region: 'US',
  },
  {
    symbol: 'IQV',
    name: 'IQVIA Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'IR',
    name: 'Ingersoll-Rand Inc',
    region: 'US',
  },
  {
    symbol: 'IRBA',
    name: 'Litman Gregory Funds Trust - iMGP RBA Responsible Global Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'IRBO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Robotics and Artificial Intelligence Multisector ETF',
    region: 'US',
  },
  {
    symbol: 'IRBT',
    name: 'Irobot Corp',
    region: 'US',
  },
  {
    symbol: 'IRDM',
    name: 'Iridium Communications Inc',
    region: 'US',
  },
  {
    symbol: 'IREN',
    name: 'Iris Energy Ltd',
    region: 'US',
  },
  {
    symbol: 'IRIX',
    name: 'IRIDEX Corp.',
    region: 'US',
  },
  {
    symbol: 'IRM',
    name: 'Iron Mountain Inc.',
    region: 'US',
  },
  {
    symbol: 'IRMD',
    name: 'Iradimed Corp',
    region: 'US',
  },
  {
    symbol: 'IRNT',
    name: 'IronNet Inc',
    region: 'US',
  },
  {
    symbol: 'IROQ',
    name: 'IF Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'IRRX',
    name: 'Integrated Rail and Resources Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IRTC',
    name: 'iRhythm Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'IRWD',
    name: 'Ironwood Pharmaceuticals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'IS',
    name: 'ironSource Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'ISAA',
    name: 'Iron Spark I Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ISCB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'ISCF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Intl Small-Cap Multifac',
    region: 'US',
  },
  {
    symbol: 'ISCG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Small-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'ISCV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Small-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'ISDR',
    name: 'Issuer Direct Corp',
    region: 'US',
  },
  {
    symbol: 'ISDX',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco RAFI Strategic Developed ex-US ETF',
    region: 'US',
  },
  {
    symbol: 'ISEE',
    name: 'IVERIC bio Inc',
    region: 'US',
  },
  {
    symbol: 'ISEM',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco RAFI Strategic Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'ISHG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares -3 Year International Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ISHP',
    name: 'First Trust Exchange-Traded Fund VI - First Trust S-Network E-Commerce ETF',
    region: 'US',
  },
  {
    symbol: 'ISIG',
    name: 'Insignia Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'ISLE',
    name: 'Isleworth Healthcare Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ISMD',
    name: 'Northern Lights Fund Trust IV - Inspire Small/Mid Cap ESG ETF',
    region: 'US',
  },
  {
    symbol: 'ISO',
    name: 'IsoPlexis Corp',
    region: 'US',
  },
  {
    symbol: 'ISPC',
    name: 'iSpecimen Inc',
    region: 'US',
  },
  {
    symbol: 'ISPO',
    name: 'Inspirato Incorporated - Class A',
    region: 'US',
  },
  {
    symbol: 'ISR',
    name: 'Isoray, Inc.',
    region: 'US',
  },
  {
    symbol: 'ISRA',
    name: 'VanEck ETF Trust - VanEck Israel ETF',
    region: 'US',
  },
  {
    symbol: 'ISRG',
    name: 'Intuitive Surgical Inc',
    region: 'US',
  },
  {
    symbol: 'ISSC',
    name: 'Innovative Solutions And Support Inc',
    region: 'US',
  },
  {
    symbol: 'ISTB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core 1-5 Year USD Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ISTR',
    name: 'Investar Holding Corp',
    region: 'US',
  },
  {
    symbol: 'ISUN',
    name: 'iSun Inc',
    region: 'US',
  },
  {
    symbol: 'ISVL',
    name: 'iShares Trust - iShares International Developed Small Cap Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'ISWN',
    name: 'Amplify ETF Trust - Amplify BlackSwan ISWN ETF',
    region: 'US',
  },
  {
    symbol: 'ISZE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Intl Size Factor ETF',
    region: 'US',
  },
  {
    symbol: 'IT',
    name: 'Gartner, Inc.',
    region: 'US',
  },
  {
    symbol: 'ITA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Aerospace & Defense ETF',
    region: 'US',
  },
  {
    symbol: 'ITAN',
    name: 'Alpha Architect Etf Trust - Sparkline Intangible Value ETF',
    region: 'US',
  },
  {
    symbol: 'ITAQ',
    name: 'Industrial Tech Acquisitions II Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ITAQU',
    name: 'Industrial Tech Acquisitions II Inc - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ITB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Home Construction ETF',
    region: 'US',
  },
  {
    symbol: 'ITCB',
    name: 'Itau Corpbanca - ADR',
    region: 'US',
  },
  {
    symbol: 'ITCI',
    name: 'Intra-Cellular Therapies Inc',
    region: 'US',
  },
  {
    symbol: 'ITEQ',
    name: 'ETF Managers Group LLC - BlueStar Israel Technology ETF',
    region: 'US',
  },
  {
    symbol: 'ITGR',
    name: 'Integer Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'ITHX',
    name: 'ITHAX Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ITHXU',
    name: 'ITHAX Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ITI',
    name: 'Iteris Inc.',
    region: 'US',
  },
  {
    symbol: 'ITIC',
    name: 'Investors Title Co.',
    region: 'US',
  },
  {
    symbol: 'ITM',
    name: 'VanEck ETF Trust - VanEck Intermediate Muni ETF',
    region: 'US',
  },
  {
    symbol: 'ITOS',
    name: 'ITeos Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ITOT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core S&P Total U.S. Stock Market ETF',
    region: 'US',
  },
  {
    symbol: 'ITP',
    name: 'IT Tech Packaging Inc',
    region: 'US',
  },
  {
    symbol: 'ITQ',
    name: 'Itiquira Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ITQRU',
    name: 'Itiquira Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ITRG',
    name: 'Integra Resources Corp',
    region: 'US',
  },
  {
    symbol: 'ITRI',
    name: 'Itron Inc.',
    region: 'US',
  },
  {
    symbol: 'ITRM',
    name: 'Iterum Therapeutics Plc',
    region: 'US',
  },
  {
    symbol: 'ITRN',
    name: 'Ituran Location And Control Ltd.',
    region: 'US',
  },
  {
    symbol: 'ITT',
    name: 'ITT Inc',
    region: 'US',
  },
  {
    symbol: 'ITUB',
    name: 'Itau Unibanco Holding S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'ITW',
    name: 'Illinois Tool Works, Inc.',
    region: 'US',
  },
  {
    symbol: 'IUS',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco RAFI Strategic US ETF',
    region: 'US',
  },
  {
    symbol: 'IUSA',
    name: 'Northern Lights Fund Trust II - Amberwave Invest USA JSG Fund',
    region: 'US',
  },
  {
    symbol: 'IUSB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core Total USD Bond Market ETF',
    region: 'US',
  },
  {
    symbol: 'IUSG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core S&P U.S.Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IUSS',
    name: 'Invesco Exchange-Traded Self-Indexed Fund Trust - Invesco RAFI Strategic US Small Company ETF',
    region: 'US',
  },
  {
    symbol: 'IUSV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core S&P U.S.Value ETF',
    region: 'US',
  },
  {
    symbol: 'IVA',
    name: 'Inventiva - ADR',
    region: 'US',
  },
  {
    symbol: 'IVAC',
    name: 'Intevac, Inc.',
    region: 'US',
  },
  {
    symbol: 'IVAL',
    name: 'Alpha Architect Etf Trust - Alpha Architect International Quantitative Value ETF',
    region: 'US',
  },
  {
    symbol: 'IVC',
    name: 'Invacare Corp.',
    region: 'US',
  },
  {
    symbol: 'IVCB',
    name: 'Investcorp Europe Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'IVCBU',
    name: 'Investcorp Europe Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IVCP',
    name: 'Swiftmerge Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IVCPU',
    name: 'Swiftmerge Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IVDA',
    name: 'Iveda Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'IVDG',
    name: 'Invesco Capital Management LLC - Invesco Focused Discovery Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IVE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P 500 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IVEG',
    name: 'iShares Trust - iShares Emergent Food and AgTech Multisector ETF',
    region: 'US',
  },
  {
    symbol: 'IVES',
    name: 'ETF Managers Group LLC - Wedbush ETFMG Global Cloud Technology ETF',
    region: 'US',
  },
  {
    symbol: 'IVLC',
    name: 'Invesco Capital Management LLC - Invesco US Large Cap Core ESG ETF',
    region: 'US',
  },
  {
    symbol: 'IVLU',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Intl Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'IVOG',
    name: 'Vanguard Group, Inc. - Vanguard S&P Mid-Cap 400 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IVOL',
    name: 'Krane Shares Trust - Quadratic Interest Rate Volatility And Inflation Hedge ETF',
    region: 'US',
  },
  {
    symbol: 'IVOO',
    name: 'Vanguard Group, Inc. - Vanguard S&P Mid-Cap 400 ETF',
    region: 'US',
  },
  {
    symbol: 'IVOV',
    name: 'Vanguard Group, Inc. - Vanguard S&P Mid-Cap 400 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IVPAF',
    name: 'Ivanhoe Mines Ltd. - Class A',
    region: 'US',
  },
  {
    symbol: 'IVRA',
    name: 'Invesco Capital Management LLC - Invesco Real Assets ESG ETF',
    region: 'US',
  },
  {
    symbol: 'IVSG',
    name: 'Invesco Capital Management LLC - Invesco Select Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IVV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'IVW',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P 500 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IWB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 1000 ETF',
    region: 'US',
  },
  {
    symbol: 'IWC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Micro-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'IWD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 1000 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IWF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 1000 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IWFH',
    name: 'iShares Trust - iShares Virtual Work and Life Multisector ETF',
    region: 'US',
  },
  {
    symbol: 'IWIN',
    name: 'Amplify ETF Trust - Amplify Inflation Fighter ETF',
    region: 'US',
  },
  {
    symbol: 'IWL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell Top 200 ETF',
    region: 'US',
  },
  {
    symbol: 'IWM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 2000 ETF',
    region: 'US',
  },
  {
    symbol: 'IWN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 2000 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IWO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 2000 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IWP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell Mid-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IWR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'IWS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell Mid-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'IWV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 3000 ETF',
    region: 'US',
  },
  {
    symbol: 'IWX',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell Top 200 Value ETF',
    region: 'US',
  },
  {
    symbol: 'IWY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell Top 200 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'IXAQ',
    name: 'IX Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'IXAQU',
    name: 'IX Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'IXC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Energy ETF',
    region: 'US',
  },
  {
    symbol: 'IXG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Financials ETF',
    region: 'US',
  },
  {
    symbol: 'IXHL',
    name: 'Incannex Healthcare Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'IXJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Healthcare ETF',
    region: 'US',
  },
  {
    symbol: 'IXN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Tech ETF',
    region: 'US',
  },
  {
    symbol: 'IXP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Comm Services ETF',
    region: 'US',
  },
  {
    symbol: 'IXSE',
    name: 'WisdomTree Trust - WisdomTree India ex-State-Owned Enterprises Fund',
    region: 'US',
  },
  {
    symbol: 'IXUS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core MSCI Total International Stock ETF',
    region: 'US',
  },
  {
    symbol: 'IYC',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'IYE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Energy ETF',
    region: 'US',
  },
  {
    symbol: 'IYF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Financials ETF',
    region: 'US',
  },
  {
    symbol: 'IYG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Financial Services ETF',
    region: 'US',
  },
  {
    symbol: 'IYH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Healthcare ETF',
    region: 'US',
  },
  {
    symbol: 'IYJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'IYK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'IYLD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Morningstar Multi-Asset Income ETF',
    region: 'US',
  },
  {
    symbol: 'IYM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Basic Materials ETF',
    region: 'US',
  },
  {
    symbol: 'IYR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'IYT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Transportation ETF',
    region: 'US',
  },
  {
    symbol: 'IYW',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Technology ETF',
    region: 'US',
  },
  {
    symbol: 'IYY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Dow Jones U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'IYZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares U.S. Telecommunications ETF',
    region: 'US',
  },
  {
    symbol: 'IZEA',
    name: 'IZEA Worldwide Inc',
    region: 'US',
  },
  {
    symbol: 'IZRL',
    name: 'ARK Investment Management LLC - ARK Israel Innovative Technology ETF',
    region: 'US',
  },
  {
    symbol: 'J',
    name: 'Jacobs Engineering Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'JAAA',
    name: 'Janus Capital Management LLC - Janus Henderson AAA CLO ETF',
    region: 'US',
  },
  {
    symbol: 'JACK',
    name: 'Jack In The Box, Inc.',
    region: 'US',
  },
  {
    symbol: 'JAGG',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'JAGX',
    name: 'Jaguar Health Inc',
    region: 'US',
  },
  {
    symbol: 'JAKK',
    name: 'Jakks Pacific Inc.',
    region: 'US',
  },
  {
    symbol: 'JAMF',
    name: 'Jamf Holding Corp',
    region: 'US',
  },
  {
    symbol: 'JAN',
    name: 'JanOne Inc',
    region: 'US',
  },
  {
    symbol: 'JANX',
    name: 'Janux Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'JANZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (January) ETF',
    region: 'US',
  },
  {
    symbol: 'JAQC',
    name: 'Jupiter Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'JAQCU',
    name: 'Jupiter Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'JATT',
    name: 'JATT Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JAVA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Active Value ETF',
    region: 'US',
  },
  {
    symbol: 'JAZZ',
    name: 'Jazz Pharmaceuticals plc',
    region: 'US',
  },
  {
    symbol: 'JBBB',
    name: 'Janus Detroit Street Trust - Janus Henderson B-BBB CLO ETF',
    region: 'US',
  },
  {
    symbol: 'JBHT',
    name: 'J.B. Hunt Transport Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'JBI',
    name: 'Janus International Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'JBL',
    name: 'Jabil Inc',
    region: 'US',
  },
  {
    symbol: 'JBLU',
    name: 'Jetblue Airways Corp',
    region: 'US',
  },
  {
    symbol: 'JBSAY',
    name: 'JBS SA - ADR',
    region: 'US',
  },
  {
    symbol: 'JBSS',
    name: 'Sanfilippo (John B.) & Son, Inc',
    region: 'US',
  },
  {
    symbol: 'JBT',
    name: 'John Bean Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'JCCAX',
    name: 'John Hancock Investment Trust: John Hancock Small Cap Core Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JCCIX',
    name: 'John Hancock Investment Trust: John Hancock Small Cap Core Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JCI',
    name: 'Johnson Controls International plc - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'JCIC',
    name: 'Jack Creek Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JCICU',
    name: 'Jack Creek Investment Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'JCPB',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Core Plus Bond ETF',
    region: 'US',
  },
  {
    symbol: 'JCPI',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Inflation Managed Bond ETF',
    region: 'US',
  },
  {
    symbol: 'JCSE',
    name: 'JE Cleantech Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'JCTCF',
    name: 'Jewett-Cameron Trading Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'JCTR',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Carbon Transition U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JD',
    name: 'JD.com Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'JDIV',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan U.S. Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'JDST',
    name: 'Direxion Shares ETF Trust - Direxion Daily Junior Gold Miners Index Bear 2X Shares',
    region: 'US',
  },
  {
    symbol: 'JDURX',
    name: 'Prudential Sector Funds, Inc: PGIM Jennison Utility Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JEF',
    name: 'Jefferies Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'JELD',
    name: 'JELD-WEN Holding Inc.',
    region: 'US',
  },
  {
    symbol: 'JEMA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan ActiveBuilders Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JENIX',
    name: 'Jensen Quality Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JENRX',
    name: 'Jensen Quality Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JENSX',
    name: 'Jensen Quality Growth Fund; Class J Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JENYX',
    name: 'Jensen Quality Growth Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JEPI',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Equity Premium Income ETF',
    region: 'US',
  },
  {
    symbol: 'JETS',
    name: 'ETF Series Solutions Trust - ESS U.S.Global Jets ETF',
    region: 'US',
  },
  {
    symbol: 'JFWD',
    name: 'Jacob Funds Inc - Jacob Forward ETF',
    region: 'US',
  },
  {
    symbol: 'JG',
    name: 'Aurora Mobile Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'JGGC',
    name: 'Jaguar Global Growth Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'JGGCU',
    name: 'Jaguar Global Growth Corp I - Units (1 Ord Class A , 1 Right & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'JGLD',
    name: 'Amplify ETF Trust - Amplify Pure Junior Gold Miners ETF',
    region: 'US',
  },
  {
    symbol: 'JHCB',
    name: 'John Hancock Exchange-Traded Fund Trust - John Hancock Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'JHCPX',
    name: 'John Hancock Funds II: Capital Appreciation Fund; Class NAV Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JHCS',
    name: 'John Hancock Exchange-Traded Fund Trust - John Hancock Multifactor Media and Communications ETF',
    region: 'US',
  },
  {
    symbol: 'JHEM',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'JHMA',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Materials ETF',
    region: 'US',
  },
  {
    symbol: 'JHMB',
    name: 'John Hancock Exchange-Traded Fund Trust - John Hancock Mortgage-Backed Securities ETF',
    region: 'US',
  },
  {
    symbol: 'JHMC',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'JHMD',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Developed International ETF',
    region: 'US',
  },
  {
    symbol: 'JHME',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Energy ETF',
    region: 'US',
  },
  {
    symbol: 'JHMF',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Financials ETF',
    region: 'US',
  },
  {
    symbol: 'JHMH',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Healthcare ETF',
    region: 'US',
  },
  {
    symbol: 'JHMI',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'JHML',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'JHMM',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Mid Cap ETF',
    region: 'US',
  },
  {
    symbol: 'JHMS',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'JHMT',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Technology ETF',
    region: 'US',
  },
  {
    symbol: 'JHMU',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'JHPI',
    name: 'John Hancock Exchange-Traded Fund Trust - John Hancock Preferred Income ETF',
    region: 'US',
  },
  {
    symbol: 'JHSC',
    name: 'John Hancock Investment Management LLC - John Hancock Multifactor Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'JHX',
    name: 'James Hardie Industries plc - ADR',
    region: 'US',
  },
  {
    symbol: 'JIBCX',
    name: 'John Hancock Funds II: Blue Chip Growth Fund; Class 1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JICPX',
    name: 'John Hancock Funds II: Capital Appreciation Fund; Class 1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JIDA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan ActiveBuilders International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JIG',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan International Growth ETF',
    region: 'US',
  },
  {
    symbol: 'JIGB',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Corporate Bond Research Enhanced ETF',
    region: 'US',
  },
  {
    symbol: 'JILL',
    name: 'J.Jill Inc',
    region: 'US',
  },
  {
    symbol: 'JJSF',
    name: 'J&J Snack Foods Corp.',
    region: 'US',
  },
  {
    symbol: 'JKHY',
    name: 'Jack Henry & Associates, Inc.',
    region: 'US',
  },
  {
    symbol: 'JKS',
    name: 'JinkoSolar Holding Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'JMAC',
    name: 'Maxpro Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JMACU',
    name: 'Maxpro Capital Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'JMBS',
    name: 'Janus Capital Management LLC - Janus Henderson Mortgage-Backed Securities ETF',
    region: 'US',
  },
  {
    symbol: 'JMIA',
    name: 'Jumia Technologies Ag - ADR',
    region: 'US',
  },
  {
    symbol: 'JMIN',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan U.S. Minimum Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'JMOM',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan U.S. Momentum Factor ETF',
    region: 'US',
  },
  {
    symbol: 'JMSB',
    name: 'John Marshall Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'JMST',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Ultra-Short Municipal Income ETF',
    region: 'US',
  },
  {
    symbol: 'JMUB',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Municipal ETF',
    region: 'US',
  },
  {
    symbol: 'JNCE',
    name: 'Jounce Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'JNJ',
    name: 'Johnson & Johnson',
    region: 'US',
  },
  {
    symbol: 'JNK',
    name: 'SPDR Series Trust - SPDR Bloomberg High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'JNPR',
    name: 'Juniper Networks Inc',
    region: 'US',
  },
  {
    symbol: 'JNUG',
    name: 'Direxion Shares ETF Trust - Direxion Daily Junior Gold Miners Index Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'JOAN',
    name: 'JOANN Inc',
    region: 'US',
  },
  {
    symbol: 'JOB',
    name: 'GEE Group Inc',
    region: 'US',
  },
  {
    symbol: 'JOBS',
    name: '51Job Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'JOBY',
    name: 'Joby Aviation Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'JOE',
    name: 'St. Joe Co.',
    region: 'US',
  },
  {
    symbol: 'JOET',
    name: 'Virtus ETF Trust II - Virtus Terranova U.S. Quality Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'JOFF',
    name: 'Joff Fintech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JOFFU',
    name: 'Joff Fintech Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'JOJO',
    name: 'Tidal ETF Trust - ATAC Credit Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'JORSX',
    name: 'John Hancock Investment Trust: John Hancock Small Cap Core Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'JOUT',
    name: 'Johnson Outdoors Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'JOYY',
    name: 'Infusive US Trust - Infusive Compounding Global Equities ETF',
    region: 'US',
  },
  {
    symbol: 'JPEM',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan Diversified Return Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JPHY',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan High Yield Research Enhanced ETF',
    region: 'US',
  },
  {
    symbol: 'JPIB',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan International Bond Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'JPIE',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Income ETF',
    region: 'US',
  },
  {
    symbol: 'JPIN',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan Diversified Return International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JPM',
    name: 'JPMorgan Chase & Co.',
    region: 'US',
  },
  {
    symbol: 'JPMB',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan USD Emerging Markets Sovereign Bond ETF',
    region: 'US',
  },
  {
    symbol: 'JPME',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan Diversified Return U.S. Mid Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JPNRF',
    name: 'Japan Airlines Company Ltd.',
    region: 'US',
  },
  {
    symbol: 'JPSE',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan Diversified Return U.S. Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JPST',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan Ultra-Short Income ETF',
    region: 'US',
  },
  {
    symbol: 'JPUS',
    name: 'J.P.Morgan Investment Management Inc. - JPMorgan Diversified Return U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JPXN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares JPX-Nikkei 400 ETF',
    region: 'US',
  },
  {
    symbol: 'JQUA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan U.S. Quality Factor ETF',
    region: 'US',
  },
  {
    symbol: 'JRE',
    name: 'Janus Capital Management LLC - Janus Henderson U.S. Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'JRNY',
    name: 'ALPS Fund Services - ALPS Global Travel Beneficiaries ETF',
    region: 'US',
  },
  {
    symbol: 'JRSH',
    name: 'Jerash holdings (US) Inc',
    region: 'US',
  },
  {
    symbol: 'JRVR',
    name: 'James River Group Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'JSAIY',
    name: 'Sainsbury (J) plc - ADR',
    region: 'US',
  },
  {
    symbol: 'JSCP',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Short Duration Core Plus ETF',
    region: 'US',
  },
  {
    symbol: 'JSMD',
    name: 'Janus Capital Management LLC - Janus Henderson Small/Mid Cap Growth Alpha ETF',
    region: 'US',
  },
  {
    symbol: 'JSML',
    name: 'Janus Capital Management LLC - Janus Henderson Small Cap Growth Alpha ETF',
    region: 'US',
  },
  {
    symbol: 'JSNSF',
    name: 'Sainsbury (J) plc',
    region: 'US',
  },
  {
    symbol: 'JSPR',
    name: 'Jasper Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'JSTC',
    name: 'Tidal ETF Trust - Adasina Social Justice All Cap Global ETF',
    region: 'US',
  },
  {
    symbol: 'JUGG',
    name: 'Jaws Juggernaut Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JUGGU',
    name: 'Jaws Juggernaut Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'JULZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (July) ETF',
    region: 'US',
  },
  {
    symbol: 'JUN',
    name: 'Juniper II Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JUNS',
    name: 'Jupiter Neurosciences Inc',
    region: 'US',
  },
  {
    symbol: 'JUNZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (June) ETF',
    region: 'US',
  },
  {
    symbol: 'JUPW',
    name: 'Jupiter Wellness Inc',
    region: 'US',
  },
  {
    symbol: 'JUSA',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan ActiveBuilders U.S. Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JUST',
    name: 'Goldman Sachs ETF Trust - Goldman Sachs Just Us Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'JVA',
    name: 'Coffee Holding Co Inc',
    region: 'US',
  },
  {
    symbol: 'JVAL',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan U.S. Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'JWAC',
    name: 'Jupiter Wellness Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JWEL',
    name: 'Jowell Global Ltd',
    region: 'US',
  },
  {
    symbol: 'JWN',
    name: 'Nordstrom, Inc.',
    region: 'US',
  },
  {
    symbol: 'JWSM',
    name: 'Jaws Mustang Acquisition Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'JXHGF',
    name: 'ENEOS Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'JXHLY',
    name: 'ENEOS Holdings Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'JXI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'JXN',
    name: 'Jackson Financial Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'JYAC',
    name: 'Jiya Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'JYNT',
    name: 'Joint Corp',
    region: 'US',
  },
  {
    symbol: 'JZRO',
    name: 'Janus Capital Management LLC - Janus Henderson Net Zero Transition Resources ETF',
    region: 'US',
  },
  {
    symbol: 'JZXN',
    name: 'Jiuzi Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'K',
    name: 'Kellogg Co',
    region: 'US',
  },
  {
    symbol: 'KACL',
    name: 'Kairous Acquisition Corp Ltd',
    region: 'US',
  },
  {
    symbol: 'KACLU',
    name: 'Kairous Acquisition Corp Ltd - Units (1 Ord Share & 1/2 War & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'KAEPF',
    name: 'Kansai Electric Power Company Incorporated',
    region: 'US',
  },
  {
    symbol: 'KAEPY',
    name: 'Kansai Electric Power Company Incorporated - ADR',
    region: 'US',
  },
  {
    symbol: 'KAHC',
    name: 'KKR Acquisition Holdings I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KAI',
    name: 'Kadant, Inc.',
    region: 'US',
  },
  {
    symbol: 'KAII',
    name: 'Kismet Acquisition Two Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KAIIU',
    name: 'Kismet Acquisition Two Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'KAIR',
    name: 'Kairos Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KAIRU',
    name: 'Kairos Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'KALA',
    name: 'Kala Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'KALL',
    name: 'Krane Shares Trust - KraneShares MSCI China All Shares Index ETF',
    region: 'US',
  },
  {
    symbol: 'KALU',
    name: 'Kaiser Aluminum Corp',
    region: 'US',
  },
  {
    symbol: 'KALV',
    name: 'KalVista Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'KAMN',
    name: 'Kaman Corp.',
    region: 'US',
  },
  {
    symbol: 'KAOCF',
    name: 'Kao Corporation',
    region: 'US',
  },
  {
    symbol: 'KAOOY',
    name: 'Kao Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'KAPR',
    name: 'Innovator ETFs Trust - Innovator U.S. Small Cap Power Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'KAR',
    name: 'KAR Auction Services Inc',
    region: 'US',
  },
  {
    symbol: 'KARO',
    name: 'Karooooo Ltd',
    region: 'US',
  },
  {
    symbol: 'KARS',
    name: 'Krane Shares Trust - KraneShares Electric Vehicles and Future Mobility Index ETF',
    region: 'US',
  },
  {
    symbol: 'KAVL',
    name: 'Kaival Brands Innovations Group Inc',
    region: 'US',
  },
  {
    symbol: 'KB',
    name: 'KB Financial Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'KBA',
    name: 'Krane Shares Trust - KraneSharesBosera MSCI China A 50 Connect Index ETF',
    region: 'US',
  },
  {
    symbol: 'KBAL',
    name: 'Kimball International, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'KBCSF',
    name: 'KBC Group SA NV',
    region: 'US',
  },
  {
    symbol: 'KBCSY',
    name: 'KBC Group SA NV - ADR',
    region: 'US',
  },
  {
    symbol: 'KBE',
    name: 'SPDR Series Trust - SPDR S&P Bank ETF',
    region: 'US',
  },
  {
    symbol: 'KBH',
    name: 'KB Home',
    region: 'US',
  },
  {
    symbol: 'KBND',
    name: 'Krane Shares Trust - KraneShares Bloomberg China Bond Inclusion Index ETF',
    region: 'US',
  },
  {
    symbol: 'KBNT',
    name: 'Kubient Inc',
    region: 'US',
  },
  {
    symbol: 'KBR',
    name: 'KBR Inc',
    region: 'US',
  },
  {
    symbol: 'KBUY',
    name: 'Krane Shares Trust - KraneShares CICC China Consumer Leaders Index ETF',
    region: 'US',
  },
  {
    symbol: 'KBWB',
    name: 'Invesco Capital Management LLC - Invesco KBW Bank ETF',
    region: 'US',
  },
  {
    symbol: 'KBWD',
    name: 'Invesco Capital Management LLC - Invesco KBW High Dividend Yield Financial ETF',
    region: 'US',
  },
  {
    symbol: 'KBWP',
    name: 'Invesco Capital Management LLC - Invesco KBW Property & Casualty Insurance ETF',
    region: 'US',
  },
  {
    symbol: 'KBWR',
    name: 'Invesco Capital Management LLC - Invesco KBW Regional Banking ETF',
    region: 'US',
  },
  {
    symbol: 'KBWY',
    name: 'Invesco Capital Management LLC - Invesco KBW Premium Yield Equity REIT ETF',
    region: 'US',
  },
  {
    symbol: 'KC',
    name: 'Kingsoft Cloud Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'KCCA',
    name: 'Krane Shares Trust - Kraneshares California Carbon Allowance Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'KCE',
    name: 'SPDR Series Trust - SPDR S&P Capital Markets ETF',
    region: 'US',
  },
  {
    symbol: 'KCGI',
    name: 'Kensington Capital Acquisition Corp V - Class A',
    region: 'US',
  },
  {
    symbol: 'KD',
    name: 'Kyndryl Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'KDDIF',
    name: 'KDDI Corp.',
    region: 'US',
  },
  {
    symbol: 'KDDIY',
    name: 'KDDI Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'KDNY',
    name: 'Chinook Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'KDP',
    name: 'Keurig Dr Pepper Inc',
    region: 'US',
  },
  {
    symbol: 'KDRN',
    name: 'ETF Opportunities Trust - Kingsbarn Tactical Bond ETF',
    region: 'US',
  },
  {
    symbol: 'KDSKF',
    name: 'Koninklijke DSM N.V. - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'KE',
    name: 'Kimball Electronics Inc',
    region: 'US',
  },
  {
    symbol: 'KEJI',
    name: 'Global X Funds - Global X China Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'KELYA',
    name: 'Kelly Services, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'KELYB',
    name: 'Kelly Services, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'KEMQ',
    name: 'Krane Shares Trust - KraneShares Emerging Markets Consumer Technology Index ETF',
    region: 'US',
  },
  {
    symbol: 'KEMX',
    name: 'Krane Shares Trust - KraneShares MSCI Emerging Markets ex China Index ETF',
    region: 'US',
  },
  {
    symbol: 'KEN',
    name: 'Kenon Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'KEP',
    name: 'Korea Electric Power - ADR',
    region: 'US',
  },
  {
    symbol: 'KEQU',
    name: 'Kewaunee Scientific Corporation',
    region: 'US',
  },
  {
    symbol: 'KERN',
    name: 'Akerna Corp',
    region: 'US',
  },
  {
    symbol: 'KESG',
    name: 'KraneShares ICAV - KraneShares MSCI China ESG Leaders Index ETF',
    region: 'US',
  },
  {
    symbol: 'KEUA',
    name: 'Krane Shares Trust - KraneShares European Carbon Allowance Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'KEX',
    name: 'Kirby Corp.',
    region: 'US',
  },
  {
    symbol: 'KEY',
    name: 'Keycorp',
    region: 'US',
  },
  {
    symbol: 'KEYS',
    name: 'Keysight Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'KFFB',
    name: 'Kentucky First Federal Bancorp',
    region: 'US',
  },
  {
    symbol: 'KFRC',
    name: 'Kforce Inc.',
    region: 'US',
  },
  {
    symbol: 'KFVG',
    name: 'Krane Shares Trust - KraneShares CICC China 5G and Semiconductor Index ETF',
    region: 'US',
  },
  {
    symbol: 'KFY',
    name: 'Korn Ferry',
    region: 'US',
  },
  {
    symbol: 'KFYP',
    name: 'Krane Shares Trust - KraneShares CICC China Leaders 100 Index ETF',
    region: 'US',
  },
  {
    symbol: 'KGC',
    name: 'Kinross Gold Corp.',
    region: 'US',
  },
  {
    symbol: 'KGFHF',
    name: 'Kingfisher Plc',
    region: 'US',
  },
  {
    symbol: 'KGFHY',
    name: 'Kingfisher Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'KGHG',
    name: 'Krane Shares Trust - KraneShares Global Carbon Transformation ETF',
    region: 'US',
  },
  {
    symbol: 'KGHPF',
    name: 'KGHM Polska Miedz S.A. - Bearer Shares',
    region: 'US',
  },
  {
    symbol: 'KGRN',
    name: 'Krane Shares Trust - KraneShares MSCI China Clean Technology Index ETF',
    region: 'US',
  },
  {
    symbol: 'KGRO',
    name: 'Krane Shares Trust - KraneShares China Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'KHC',
    name: 'Kraft Heinz Co',
    region: 'US',
  },
  {
    symbol: 'KHNGF',
    name: 'Kuhne & Nagel International AG (Registered)',
    region: 'US',
  },
  {
    symbol: 'KHNGY',
    name: 'Kuhne & Nagel International AG - ADR',
    region: 'US',
  },
  {
    symbol: 'KHYB',
    name: 'Krane Shares Trust - KraneShares Asia Pacific High Income Bond ETF',
    region: 'US',
  },
  {
    symbol: 'KIDS',
    name: 'OrthoPediatrics corp',
    region: 'US',
  },
  {
    symbol: 'KIE',
    name: 'SPDR Series Trust - SPDR S&P Insurance ETF',
    region: 'US',
  },
  {
    symbol: 'KIII',
    name: 'Kismet Acquisition Three Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KIIIU',
    name: 'Kismet Acquisition Three Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'KIKOF',
    name: 'Kikkoman Corporation',
    region: 'US',
  },
  {
    symbol: 'KIKOY',
    name: 'Kikkoman Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'KIMTF',
    name: 'Kia Corporation',
    region: 'US',
  },
  {
    symbol: 'KIND',
    name: 'Nextdoor Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'KINS',
    name: 'Kingstone Cos. Inc',
    region: 'US',
  },
  {
    symbol: 'KINZ',
    name: 'KINS Technology Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'KINZU',
    name: 'KINS Technology Group Inc - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'KIQ',
    name: 'Kelso Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'KIRK',
    name: 'Kirkland`s Inc',
    region: 'US',
  },
  {
    symbol: 'KJAN',
    name: 'Innovator ETFs Trust - Innovator U.S. Small Cap Power Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'KJUL',
    name: 'Innovator ETFs Trust - Innovator U.S. Small Cap Power Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'KKOYF',
    name: 'Kesko Oyj - Class B',
    region: 'US',
  },
  {
    symbol: 'KKOYY',
    name: 'Kesko Oyj - ADR',
    region: 'US',
  },
  {
    symbol: 'KKPNF',
    name: 'Koninklijke KPN NV',
    region: 'US',
  },
  {
    symbol: 'KKPNY',
    name: 'Koninklijke KPN NV - ADR',
    region: 'US',
  },
  {
    symbol: 'KLAC',
    name: 'KLA Corp.',
    region: 'US',
  },
  {
    symbol: 'KLAQ',
    name: 'KL Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KLAQU',
    name: 'KL Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'KLCD',
    name: 'Krane Shares Trust - KFA Large Cap Quality Dividend Index ETF',
    region: 'US',
  },
  {
    symbol: 'KLDW',
    name: 'Exchange Listed Funds Trust - Knowledge Leaders Developed World ETF',
    region: 'US',
  },
  {
    symbol: 'KLIC',
    name: 'Kulicke & Soffa Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'KLNE',
    name: 'Direxion Shares ETF Trust - Direxion Daily Global Clean Energy Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'KLR',
    name: 'Kaleyra Inc',
    region: 'US',
  },
  {
    symbol: 'KLTR',
    name: 'Kaltura Inc',
    region: 'US',
  },
  {
    symbol: 'KLXE',
    name: 'KLX Energy Services Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'KMB',
    name: 'Kimberly-Clark Corp.',
    region: 'US',
  },
  {
    symbol: 'KMDA',
    name: 'Kamada Ltd',
    region: 'US',
  },
  {
    symbol: 'KMED',
    name: 'Krane Shares Trust - KraneShares Emerging Markets Healthcare Index ETF',
    region: 'US',
  },
  {
    symbol: 'KMERF',
    name: 'Komercni Banka, A.S.',
    region: 'US',
  },
  {
    symbol: 'KMI',
    name: 'Kinder Morgan Inc - Class P',
    region: 'US',
  },
  {
    symbol: 'KMLM',
    name: 'Krane Shares Trust - KFA Mount Lucas Index Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'KMPH',
    name: 'KemPharm Inc',
    region: 'US',
  },
  {
    symbol: 'KMPR',
    name: 'Kemper Corporation',
    region: 'US',
  },
  {
    symbol: 'KMT',
    name: 'Kennametal Inc.',
    region: 'US',
  },
  {
    symbol: 'KMX',
    name: 'Carmax Inc',
    region: 'US',
  },
  {
    symbol: 'KN',
    name: 'Knowles Corp',
    region: 'US',
  },
  {
    symbol: 'KNBE',
    name: 'KnowBe4 Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'KNBHF',
    name: 'Knorr-Bremse AG - Bearer Shares',
    region: 'US',
  },
  {
    symbol: 'KNDI',
    name: 'Kandi Technologies Group Inc',
    region: 'US',
  },
  {
    symbol: 'KNG',
    name: 'First Trust Exchange-Traded Fund IV - FT Cboe Vest S&P 500 Dividend Aristocrats Target Income ETF',
    region: 'US',
  },
  {
    symbol: 'KNGS',
    name: 'Alpha Architect Etf Trust - UPHOLDINGS Compound Kings ETF',
    region: 'US',
  },
  {
    symbol: 'KNKBF',
    name: 'Kinnevik AB - Class B',
    region: 'US',
  },
  {
    symbol: 'KNOP',
    name: 'KNOT Offshore Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'KNRRY',
    name: 'Knorr-Bremse AG - ADR',
    region: 'US',
  },
  {
    symbol: 'KNSA',
    name: 'Kiniksa Pharmaceuticals Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'KNSL',
    name: 'Kinsale Capital Group Inc',
    region: 'US',
  },
  {
    symbol: 'KNSW',
    name: 'KnightSwan Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KNTE',
    name: 'Kinnate Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'KNX',
    name: 'Knight-Swift Transportation Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'KNYJF',
    name: 'Kone Corp. - Class B',
    region: 'US',
  },
  {
    symbol: 'KNYJY',
    name: 'Kone Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'KO',
    name: 'Coca-Cola Co',
    region: 'US',
  },
  {
    symbol: 'KOBNF',
    name: 'Kobe Bussan Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'KOCG',
    name: 'SHP ETF Trust - FIS Knights of Columbus Global Belief ETF',
    region: 'US',
  },
  {
    symbol: 'KOCT',
    name: 'Innovator ETFs Trust - Innovator U.S. Small Cap Power Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'KOD',
    name: 'Kodiak Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'KODK',
    name: 'Eastman Kodak Co.',
    region: 'US',
  },
  {
    symbol: 'KOF',
    name: 'Coca-Cola Femsa S.A.B. DE C.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'KOIN',
    name: 'Exchange Traded Concepts Trust - Capital Link Global Fintech Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'KOKU',
    name: 'DBX ETF Trust - Xtrackers MSCI Kokusai Equity ETF',
    region: 'US',
  },
  {
    symbol: 'KOLD',
    name: 'ProShares Trust - ProShares UltraShort Bloomberg Natural Gas',
    region: 'US',
  },
  {
    symbol: 'KOMP',
    name: 'SPDR Series Trust - SPDR S&P Kensho New Economies Composite ETF',
    region: 'US',
  },
  {
    symbol: 'KONG',
    name: 'ETF Opportunities Trust - Formidable Fortress ETF',
    region: 'US',
  },
  {
    symbol: 'KOP',
    name: 'Koppers Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'KOPN',
    name: 'Kopin Corp.',
    region: 'US',
  },
  {
    symbol: 'KORE',
    name: 'Kore Group Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'KORP',
    name: 'American Century ETF Trust - American Century Diversified Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'KORU',
    name: 'Direxion Shares ETF Trust - Direxion Daily South Korea Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'KOS',
    name: 'Kosmos Energy Ltd',
    region: 'US',
  },
  {
    symbol: 'KOSS',
    name: 'Koss Corp.',
    region: 'US',
  },
  {
    symbol: 'KOTMF',
    name: 'Koito Manufacturing Co. Ltd',
    region: 'US',
  },
  {
    symbol: 'KOTMY',
    name: 'Koito Manufacturing Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'KPCPF',
    name: 'Kasikornbank Public Co. Ltd (Alien Market)',
    region: 'US',
  },
  {
    symbol: 'KPCPY',
    name: 'Kasikornbank Public Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'KPCUF',
    name: 'Kasikornbank Public Co. Ltd - NVDR',
    region: 'US',
  },
  {
    symbol: 'KPLT',
    name: 'Katapult Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'KPRX',
    name: 'Kiora Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'KPTI',
    name: 'Karyopharm Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'KR',
    name: 'Kroger Co.',
    region: 'US',
  },
  {
    symbol: 'KRBN',
    name: 'KraneShares ICAV - KraneShares Global Carbon Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'KRBP',
    name: 'Kiromic BioPharma Inc',
    region: 'US',
  },
  {
    symbol: 'KRE',
    name: 'SPDR Series Trust - SPDR S&P Regional Banking ETF',
    region: 'US',
  },
  {
    symbol: 'KRKR',
    name: '36Kr Holdings Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'KRMA',
    name: 'Global X Funds - Global X Conscious Companies ETF',
    region: 'US',
  },
  {
    symbol: 'KRMD',
    name: 'Repro-Med Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'KRNL',
    name: 'Kernel Group Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'KRNLU',
    name: 'Kernel Group Holdings Inc - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'KRNT',
    name: 'Kornit Digital Ltd',
    region: 'US',
  },
  {
    symbol: 'KRNY',
    name: 'Kearny Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'KRO',
    name: 'Kronos Worldwide, Inc.',
    region: 'US',
  },
  {
    symbol: 'KRON',
    name: 'Kronos Bio Inc',
    region: 'US',
  },
  {
    symbol: 'KROP',
    name: 'Global X Funds - Global X AgTech & Food Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'KROS',
    name: 'Keros Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'KRP',
    name: 'Kimbell Royalty Partners LP - Units',
    region: 'US',
  },
  {
    symbol: 'KRT',
    name: 'Karat Packaging Inc',
    region: 'US',
  },
  {
    symbol: 'KRTX',
    name: 'Karuna Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'KRUS',
    name: 'Kura Sushi USA Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'KRYS',
    name: 'Krystal Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'KSA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Saudi Arabia ETF',
    region: 'US',
  },
  {
    symbol: 'KSCD',
    name: 'Krane Shares Trust - KFA Small Cap Quality Dividend Index ETF',
    region: 'US',
  },
  {
    symbol: 'KSET',
    name: 'Krane Shares Trust - KraneShares Global Carbon Offset Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'KSI',
    name: 'Kadem Sustainable Impact Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KSICU',
    name: 'Kadem Sustainable Impact Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'KSPN',
    name: 'Kaspien Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'KSS',
    name: 'Kohl`s Corp.',
    region: 'US',
  },
  {
    symbol: 'KSTR',
    name: 'KraneShares ICAV - KraneShares SSE STAR Market 50 Index ETF',
    region: 'US',
  },
  {
    symbol: 'KT',
    name: 'KT Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'KTB',
    name: 'Kontoor Brands Inc',
    region: 'US',
  },
  {
    symbol: 'KTCC',
    name: 'Key Tronic Corp.',
    region: 'US',
  },
  {
    symbol: 'KTCIF',
    name: 'KT&G Corporation',
    region: 'US',
  },
  {
    symbol: 'KTCIY',
    name: 'KT&G Corporation - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'KTEC',
    name: 'Krane Shares Trust - KraneShares Hang Seng TECH Index ETF',
    region: 'US',
  },
  {
    symbol: 'KTOS',
    name: 'Kratos Defense & Security Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'KTRA',
    name: 'Kintara Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'KTTA',
    name: 'Pasithea Therapeutics Corp',
    region: 'US',
  },
  {
    symbol: 'KUKE',
    name: 'Kuke Music Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'KULR',
    name: 'KULR Technology Group Inc',
    region: 'US',
  },
  {
    symbol: 'KURA',
    name: 'Kura Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'KURE',
    name: 'Krane Shares Trust - KraneShares MSCI All China Health Care Index ETF',
    region: 'US',
  },
  {
    symbol: 'KVHI',
    name: 'KVH Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'KVLE',
    name: 'Krane Shares Trust - KFA Value Line Dynamic Core Equity Index ETF',
    region: 'US',
  },
  {
    symbol: 'KVSA',
    name: 'Khosla Ventures Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'KVSC',
    name: 'Khosla Ventures Acquisition Co III - Class A',
    region: 'US',
  },
  {
    symbol: 'KWAC',
    name: 'Kingswood Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KWEB',
    name: 'Krane Shares Trust - KraneShares CSI China Internet ETF',
    region: 'US',
  },
  {
    symbol: 'KWR',
    name: 'Quaker Houghton',
    region: 'US',
  },
  {
    symbol: 'KWT',
    name: 'iShares Trust - iShares MSCI Kuwait ETF',
    region: 'US',
  },
  {
    symbol: 'KXI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'KXIN',
    name: 'Kaixin Auto Holdings',
    region: 'US',
  },
  {
    symbol: 'KYCH',
    name: 'Keyarch Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'KYCHU',
    name: 'Keyarch Acquisition Corp - Units (1 Ord Class A,1Rights & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'KYKOF',
    name: 'Kyowa Kirin Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'KYMR',
    name: 'Kymera Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'KZIA',
    name: 'Kazia Therapeutics Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'KZR',
    name: 'Kezar Life Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'L',
    name: 'Loews Corp.',
    region: 'US',
  },
  {
    symbol: 'LAAA',
    name: 'Lakeshore Acquisition I Corp',
    region: 'US',
  },
  {
    symbol: 'LAAAU',
    name: 'Lakeshore Acquisition I Corp - Units (1 Ord Share & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'LAB',
    name: 'Standard BioTools Inc',
    region: 'US',
  },
  {
    symbol: 'LABD',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P Biotech Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'LABP',
    name: 'Landos Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'LABU',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P Biotech Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'LAC',
    name: 'Lithium Americas Corp',
    region: 'US',
  },
  {
    symbol: 'LAD',
    name: 'Lithia Motors, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'LADCX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADFX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADPX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class P Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADQX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADRX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADSX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADTX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADVX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LADYX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LAGWX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LAKE',
    name: 'Lakeland Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'LAMR',
    name: 'Lamar Advertising Co - Class A',
    region: 'US',
  },
  {
    symbol: 'LANC',
    name: 'Lancaster Colony Corp.',
    region: 'US',
  },
  {
    symbol: 'LARK',
    name: 'Landmark Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'LASR',
    name: 'nLIGHT Inc',
    region: 'US',
  },
  {
    symbol: 'LATG',
    name: 'LatAmGrowth SPAC - Class A',
    region: 'US',
  },
  {
    symbol: 'LATGU',
    name: 'LatAmGrowth SPAC - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LAUR',
    name: 'Laureate Education Inc',
    region: 'US',
  },
  {
    symbol: 'LAW',
    name: 'CS Disco Inc',
    region: 'US',
  },
  {
    symbol: 'LAWS',
    name: 'Lawson Products, Inc.',
    region: 'US',
  },
  {
    symbol: 'LAX',
    name: '8i Acquisition 2 Corp',
    region: 'US',
  },
  {
    symbol: 'LAXXU',
    name: '8i Acquisition 2 Corp - Unit (1 Ordinary share & 1 Wrt & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'LAZR',
    name: 'Luminar Technologies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LAZY',
    name: 'Lazydays Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LBAI',
    name: 'Lakeland Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'LBAY',
    name: 'Tidal ETF Trust - Leatherback Long/Short Alternative Yield ETF',
    region: 'US',
  },
  {
    symbol: 'LBBB',
    name: 'Lakeshore Acquisition II Corp',
    region: 'US',
  },
  {
    symbol: 'LBBBU',
    name: 'Lakeshore Acquisition II Corp - Units (1 Ord, 1/2 War & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'LBC',
    name: 'Luther Burbank Corp',
    region: 'US',
  },
  {
    symbol: 'LBFIX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Select Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LBGUF',
    name: 'L E Lundbergfoeretagen AB - Class B',
    region: 'US',
  },
  {
    symbol: 'LBPH',
    name: 'Longboard Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'LBPS',
    name: '4d Pharma Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'LBRDA',
    name: 'Liberty Broadband Corp - Series A',
    region: 'US',
  },
  {
    symbol: 'LBRDB',
    name: 'Liberty Broadband Corp - Series B',
    region: 'US',
  },
  {
    symbol: 'LBRDK',
    name: 'Liberty Broadband Corp - Series C',
    region: 'US',
  },
  {
    symbol: 'LBRT',
    name: 'Liberty Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LBSAX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LBSCX',
    name: 'Columbia Funds Series Trust I: Columbia Dividend Income Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LBTYA',
    name: 'Liberty Global plc - Class A',
    region: 'US',
  },
  {
    symbol: 'LBTYB',
    name: 'Liberty Global plc - Class B',
    region: 'US',
  },
  {
    symbol: 'LBTYK',
    name: 'Liberty Global plc - Class C',
    region: 'US',
  },
  {
    symbol: 'LCA',
    name: 'Landcadia Holdings IV Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LCAA',
    name: 'L Catterton Asia Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LCAAU',
    name: 'L Catterton Asia Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'LCAHU',
    name: 'Landcadia Holdings IV Inc - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'LCAP',
    name: 'Lionheart Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'LCAPU',
    name: 'Lionheart Acquisition Corp II - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LCBSX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Select Fund; Class FI Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCCAX',
    name: 'Columbia Funds Series Trust I: Columbia Contrarian Core Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCG',
    name: 'Northern Lights Fund Trust IV - Sterling Capital Focus Equity ETF',
    region: 'US',
  },
  {
    symbol: 'LCGFX',
    name: 'William Blair Funds: Large Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCGJX',
    name: 'William Blair Funds: Large Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCGNX',
    name: 'William Blair Funds: Large Cap Growth Fund; Class N Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCI',
    name: 'Lannett Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'LCIAX',
    name: 'SEI Institutional Investments Trust: Large Cap Index Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCID',
    name: 'Lucid Group Inc',
    region: 'US',
  },
  {
    symbol: 'LCII',
    name: 'LCI Industries',
    region: 'US',
  },
  {
    symbol: 'LCLAX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Select Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCLCX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Select Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCLIX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Value Fund; Class 1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCNB',
    name: 'LCNB Corp',
    region: 'US',
  },
  {
    symbol: 'LCR',
    name: 'Leuthold Funds Inc - Leuthold Core ETF',
    region: 'US',
  },
  {
    symbol: 'LCSSX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Select Fund; Class IS Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LCTD',
    name: 'BlackRock ETF Trust - BlackRock World ex U.S. Carbon Transition Readiness ETF',
    region: 'US',
  },
  {
    symbol: 'LCTU',
    name: 'BlackRock ETF Trust - BlackRock U.S. Carbon Transition Readiness ETF',
    region: 'US',
  },
  {
    symbol: 'LCTX',
    name: 'Lineage Cell Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'LCUT',
    name: 'Lifetime Brands, Inc.',
    region: 'US',
  },
  {
    symbol: 'LCW',
    name: 'Learn CW Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LDEM',
    name: 'iShares Trust - iShares ESG MSCI EM Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'LDHA',
    name: 'LDH Growth Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'LDHAU',
    name: 'LDH Growth Corp I - Units (1 Ord Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'LDOS',
    name: 'Leidos Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LDSF',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Low Duration Strategic Focus ETF',
    region: 'US',
  },
  {
    symbol: 'LDSVF',
    name: 'Chocoladefabriken Lindt & Sprungli AG.',
    region: 'US',
  },
  {
    symbol: 'LDUR',
    name: 'Pimco Exchange Traded Fund - PIMCO Enhanced Low Duration Active Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'LE',
    name: 'Lands` End, Inc.',
    region: 'US',
  },
  {
    symbol: 'LEA',
    name: 'Lear Corp.',
    region: 'US',
  },
  {
    symbol: 'LEAD',
    name: 'Siren ETF Trust - Siren DIVCON Leaders Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'LEAP',
    name: 'Ribbit LEAP Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'LECO',
    name: 'Lincoln Electric Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'LEDS',
    name: 'Semileds Corp',
    region: 'US',
  },
  {
    symbol: 'LEE',
    name: 'Lee Enterprises, Inc.',
    region: 'US',
  },
  {
    symbol: 'LEG',
    name: 'Leggett & Platt, Inc.',
    region: 'US',
  },
  {
    symbol: 'LEGA',
    name: 'Lead Edge Growth Opportunities Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'LEGAU',
    name: 'Lead Edge Growth Opportunities Ltd - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'LEGH',
    name: 'Legacy Housing Corp',
    region: 'US',
  },
  {
    symbol: 'LEGN',
    name: 'Legend Biotech Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'LEGR',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Indxx Innovative Transaction & Process ETF',
    region: 'US',
  },
  {
    symbol: 'LEJU',
    name: 'Leju Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'LEMB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares J.P. Morgan EM Local Currency Bond ETF',
    region: 'US',
  },
  {
    symbol: 'LEN',
    name: 'Lennar Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'LEN.B',
    name: 'Lennar Corp. - Class B',
    region: 'US',
  },
  {
    symbol: 'LESL',
    name: 'Leslies Inc',
    region: 'US',
  },
  {
    symbol: 'LETB',
    name: 'Advisorshares Trust - AdvisorShares Let Bob AI Powered Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'LEU',
    name: 'Centrus Energy Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LEV',
    name: 'Lion Electric Co (The)',
    region: 'US',
  },
  {
    symbol: 'LEVI',
    name: 'Levi Strauss & Co. Cls A',
    region: 'US',
  },
  {
    symbol: 'LEXI',
    name: 'Listed Funds Trust - Alexis Practical Tactical ETF',
    region: 'US',
  },
  {
    symbol: 'LEXX',
    name: 'Lexaria Bioscience Corp',
    region: 'US',
  },
  {
    symbol: 'LFAC',
    name: 'LF Capital Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'LFACU',
    name: 'LF Capital Acquisition Corp II - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LFC',
    name: 'China Life Insurance Co - ADR',
    region: 'US',
  },
  {
    symbol: 'LFDJF',
    name: 'La Francaise Des Jeux S.A.',
    region: 'US',
  },
  {
    symbol: 'LFEQ',
    name: 'VanEck ETF Trust - VanEck Long/Flat Trend ETF',
    region: 'US',
  },
  {
    symbol: 'LFG',
    name: 'Archaea Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LFMD',
    name: 'LifeMD Inc',
    region: 'US',
  },
  {
    symbol: 'LFST',
    name: 'LifeStance Health Group Inc',
    region: 'US',
  },
  {
    symbol: 'LFTR',
    name: 'Lefteris Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LFTRU',
    name: 'Lefteris Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'LFUS',
    name: 'Littelfuse, Inc.',
    region: 'US',
  },
  {
    symbol: 'LFVN',
    name: 'Lifevantage Corporation',
    region: 'US',
  },
  {
    symbol: 'LGAC',
    name: 'Lazard Growth Acquisition Corp I',
    region: 'US',
  },
  {
    symbol: 'LGACU',
    name: 'Lazard Growth Acquisition Corp I - Units (1 Ord Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'LGCLF',
    name: 'L G Chemical',
    region: 'US',
  },
  {
    symbol: 'LGEIY',
    name: 'LG Electronics Inc - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'LGF.A',
    name: 'Lions Gate Entertainment Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'LGF.B',
    name: 'Lions Gate Entertainment Corp. - Class B',
    region: 'US',
  },
  {
    symbol: 'LGH',
    name: 'Northern Lights Fund Trust III - HCM Defender 500 Index ETF',
    region: 'US',
  },
  {
    symbol: 'LGHHF',
    name: 'LG Household & Healthcare Ltd.',
    region: 'US',
  },
  {
    symbol: 'LGIH',
    name: 'LGI Homes Inc',
    region: 'US',
  },
  {
    symbol: 'LGILX',
    name: 'Laudus Trust: Laudus US Large Cap Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGL',
    name: 'LGL Group Inc',
    region: 'US',
  },
  {
    symbol: 'LGLAX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLCX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLFX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLIX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLQX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLRX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLSX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLUX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGLV',
    name: 'SPDR Series Trust - SPDR SSGA US Large Cap Low Volatility Index ETF',
    region: 'US',
  },
  {
    symbol: 'LGLVX',
    name: 'Lord Abbett Securities Trust: Lord Abbett Growth Leaders Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LGMK',
    name: 'LogicMark Inc',
    region: 'US',
  },
  {
    symbol: 'LGND',
    name: 'Ligand Pharmaceuticals, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'LGO',
    name: 'Largo Inc',
    region: 'US',
  },
  {
    symbol: 'LGOV',
    name: 'First Trust Exchange-Traded Fund IV - First Trust Long Duration Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'LGRDY',
    name: 'Legrand SA - ADR',
    region: 'US',
  },
  {
    symbol: 'LGRVF',
    name: 'Legrand SA',
    region: 'US',
  },
  {
    symbol: 'LGST',
    name: 'Semper Paratus Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LGSTU',
    name: 'Semper Paratus Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LGTO',
    name: 'Legato Merger Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'LGTOU',
    name: 'Legato Merger Corp II - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LGV',
    name: 'Longview Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'LGVC',
    name: 'LAMF Global Ventures Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'LGVCU',
    name: 'LAMF Global Ventures Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LGVN',
    name: 'Longeveron Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LH',
    name: 'Laboratory Corp. Of America Holdings',
    region: 'US',
  },
  {
    symbol: 'LHAA',
    name: 'Lerer Hippeau Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LHC',
    name: 'Leo Holdings Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'LHCG',
    name: 'LHC Group Inc',
    region: 'US',
  },
  {
    symbol: 'LHDX',
    name: 'Lucira Health Inc',
    region: 'US',
  },
  {
    symbol: 'LHX',
    name: 'L3Harris Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'LI',
    name: 'Li Auto Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'LIAN',
    name: 'LianBio - ADR',
    region: 'US',
  },
  {
    symbol: 'LIBY',
    name: 'Liberty Resources Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LIBYU',
    name: 'Liberty Resources Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'LICY',
    name: 'Li-Cycle Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LIDR',
    name: 'AEye Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LIFE',
    name: 'Atyr Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'LII',
    name: 'Lennox International Inc',
    region: 'US',
  },
  {
    symbol: 'LILA',
    name: 'Liberty Latin America Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'LILAK',
    name: 'Liberty Latin America Ltd - Class C',
    region: 'US',
  },
  {
    symbol: 'LILM',
    name: 'Lilium N.V - Class A',
    region: 'US',
  },
  {
    symbol: 'LIN',
    name: 'Linde Plc',
    region: 'US',
  },
  {
    symbol: 'LINC',
    name: 'Lincoln Educational Services Corp',
    region: 'US',
  },
  {
    symbol: 'LIND',
    name: 'Lindblad Expeditions Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LINK',
    name: 'Interlink Electronics',
    region: 'US',
  },
  {
    symbol: 'LION',
    name: 'Lionheart III Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LIONU',
    name: 'Lionheart III Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LIQT',
    name: 'LiqTech International Inc',
    region: 'US',
  },
  {
    symbol: 'LIT',
    name: 'Global X Funds - Global X Lithium & Battery Tech ETF',
    region: 'US',
  },
  {
    symbol: 'LITB',
    name: 'LightInTheBox Holding Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'LITE',
    name: 'Lumentum Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LITT',
    name: 'Logistics Innovation Technologies Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LITTU',
    name: 'Logistics Innovation Technologies Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'LIV',
    name: 'Emles Trust - Emles @Home ETF',
    region: 'US',
  },
  {
    symbol: 'LIVB',
    name: 'LIV Capital Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'LIVBU',
    name: 'LIV Capital Acquisition Corp II - Units (1 Ord Share Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'LIVE',
    name: 'Live Ventures Inc',
    region: 'US',
  },
  {
    symbol: 'LIVN',
    name: 'LivaNova PLC',
    region: 'US',
  },
  {
    symbol: 'LIXT',
    name: 'Lixte Biotechnology Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LIZI',
    name: 'Lizhi Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'LJAQ',
    name: 'Lightjump Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'LJAQU',
    name: 'Lightjump Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LJPC',
    name: 'La Jolla Pharmaceutical Co.',
    region: 'US',
  },
  {
    symbol: 'LKCO',
    name: 'Luokung Technology Corp',
    region: 'US',
  },
  {
    symbol: 'LKFN',
    name: 'Lakeland Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'LKOR',
    name: 'FlexShares Trust - FlexShares Credit-Scored US Long Corporate Bond Index Fund',
    region: 'US',
  },
  {
    symbol: 'LKQ',
    name: 'LKQ Corp',
    region: 'US',
  },
  {
    symbol: 'LL',
    name: 'LL Flooring Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LLL',
    name: 'JX Luxventure Limited',
    region: 'US',
  },
  {
    symbol: 'LLNW',
    name: 'Limelight Networks Inc',
    region: 'US',
  },
  {
    symbol: 'LLY',
    name: 'Lilly(Eli) & Co',
    region: 'US',
  },
  {
    symbol: 'LMACA',
    name: 'Liberty Media Acquisition Corp - Series A',
    region: 'US',
  },
  {
    symbol: 'LMACU',
    name: 'Liberty Media Acquisition Corp - Units (1 Ord Share Ser A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'LMAO',
    name: 'LMF Acquisition Opportunities Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LMAOU',
    name: 'LMF Acquisition Opportunities Inc - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'LMAT',
    name: 'Lemaitre Vascular Inc',
    region: 'US',
  },
  {
    symbol: 'LMB',
    name: 'Limbach Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LMBS',
    name: 'First Trust Exchange-Traded Fund III - First Trust Low Duration Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'LMDX',
    name: 'LumiraDx Limited',
    region: 'US',
  },
  {
    symbol: 'LMLSX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Value Fund; Class IS Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMND',
    name: 'Lemonade Inc',
    region: 'US',
  },
  {
    symbol: 'LMNL',
    name: 'Liminal Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'LMNR',
    name: 'Limoneira Co',
    region: 'US',
  },
  {
    symbol: 'LMOIX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Small Cap Growth Fund; Class IS Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMPLX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMPMX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Small Cap Growth Fund; Class 1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMPOX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Small Cap Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMPSX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Small Cap Growth Fund; Class FI Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMPX',
    name: 'Lmp Automotive Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LMREX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Mid Cap Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LMST',
    name: 'Limestone Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'LMT',
    name: 'Lockheed Martin Corp.',
    region: 'US',
  },
  {
    symbol: 'LNC',
    name: 'Lincoln National Corp.',
    region: 'US',
  },
  {
    symbol: 'LND',
    name: 'Brasilagro Companhia Brasileira De Propriedade Agricola - ADR',
    region: 'US',
  },
  {
    symbol: 'LNDC',
    name: 'Landec Corp.',
    region: 'US',
  },
  {
    symbol: 'LNFA',
    name: 'L&F Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LNG',
    name: 'Cheniere Energy Inc.',
    region: 'US',
  },
  {
    symbol: 'LNN',
    name: 'Lindsay Corporation',
    region: 'US',
  },
  {
    symbol: 'LNSR',
    name: 'LENSAR Inc',
    region: 'US',
  },
  {
    symbol: 'LNT',
    name: 'Alliant Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'LNTH',
    name: 'Lantheus Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LOAN',
    name: 'Manhattan Bridge Capital Inc',
    region: 'US',
  },
  {
    symbol: 'LOB',
    name: 'Live Oak Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'LOCC',
    name: 'Live Oak Crestview Climate Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LOCL',
    name: 'Local Bounti Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LOCO',
    name: 'El Pollo Loco Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LODE',
    name: 'Comstock Mining Inc',
    region: 'US',
  },
  {
    symbol: 'LOGC',
    name: 'LogicBio Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'LOGI',
    name: 'Logitech International S.A.',
    region: 'US',
  },
  {
    symbol: 'LOGWX',
    name: 'Lord Abbett Developing Growth Fund Inc; Class F3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LOKM',
    name: 'Live Oak Mobility Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LOMA',
    name: 'Loma Negra Compania Industrial Argentina Sociedad Anonima - ADR',
    region: 'US',
  },
  {
    symbol: 'LOOP',
    name: 'Loop Industries Inc',
    region: 'US',
  },
  {
    symbol: 'LOPE',
    name: 'Grand Canyon Education Inc',
    region: 'US',
  },
  {
    symbol: 'LOPP',
    name: 'Gabelli ETFs Trust - Gabelli Love Our Planet & People ETF',
    region: 'US',
  },
  {
    symbol: 'LOPX',
    name: 'Direxion Shares ETF Trust - Direxion Low Priced Stock ETF',
    region: 'US',
  },
  {
    symbol: 'LOTZ',
    name: 'CarLotz Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LOUP',
    name: 'Innovator ETFs Trust - Innovator Loup Frontier Tech ETF',
    region: 'US',
  },
  {
    symbol: 'LOV',
    name: 'Spark Networks SE - ADR',
    region: 'US',
  },
  {
    symbol: 'LOVE',
    name: 'Lovesac Company',
    region: 'US',
  },
  {
    symbol: 'LOW',
    name: 'Lowe`s Cos., Inc.',
    region: 'US',
  },
  {
    symbol: 'LPCN',
    name: 'Lipocine Inc',
    region: 'US',
  },
  {
    symbol: 'LPG',
    name: 'Dorian LPG Ltd',
    region: 'US',
  },
  {
    symbol: 'LPI',
    name: 'Laredo Petroleum Inc.',
    region: 'US',
  },
  {
    symbol: 'LPL',
    name: 'LG Display Co Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'LPRO',
    name: 'Open Lending Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LPSN',
    name: 'Liveperson Inc',
    region: 'US',
  },
  {
    symbol: 'LPTH',
    name: 'Lightpath Technologies, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'LPTX',
    name: 'Leap Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'LPX',
    name: 'Louisiana-Pacific Corp.',
    region: 'US',
  },
  {
    symbol: 'LQD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares iBoxx USD Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'LQDA',
    name: 'Liquidia Corp',
    region: 'US',
  },
  {
    symbol: 'LQDB',
    name: 'iShares Trust - iShares BBB Rated Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'LQDH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Interest Rate Hedged Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'LQDI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Inflation Hedged Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'LQDT',
    name: 'Liquidity Services Inc',
    region: 'US',
  },
  {
    symbol: 'LRCX',
    name: 'Lam Research Corp.',
    region: 'US',
  },
  {
    symbol: 'LRGE',
    name: 'Legg Mason ETF Investment Trust - ClearBridge Large Cap Growth ESG ETF',
    region: 'US',
  },
  {
    symbol: 'LRGF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'LRLCF',
    name: 'L`Oreal',
    region: 'US',
  },
  {
    symbol: 'LRLCY',
    name: 'L`Oreal - ADR',
    region: 'US',
  },
  {
    symbol: 'LRMR',
    name: 'Larimar Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'LRN',
    name: 'Stride Inc',
    region: 'US',
  },
  {
    symbol: 'LRND',
    name: 'IndexIQ ETF Trust - IQ U.S. Large Cap R&D Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'LRNZ',
    name: 'Listed Funds Trust - TrueShares Technology, AI & Deep Learning ETF',
    region: 'US',
  },
  {
    symbol: 'LSAF',
    name: 'Two Roads Shared Trust - LeaderShares AlphaFactor US Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'LSAT',
    name: 'Two Roads Shared Trust - LeaderShares AlphaFactor Tactical Focused ETF',
    region: 'US',
  },
  {
    symbol: 'LSBK',
    name: 'Lake Shore Bancorp',
    region: 'US',
  },
  {
    symbol: 'LSCC',
    name: 'Lattice Semiconductor Corp.',
    region: 'US',
  },
  {
    symbol: 'LSEA',
    name: 'Landsea Homes Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'LSF',
    name: 'Laird Superfood Inc',
    region: 'US',
  },
  {
    symbol: 'LSIRX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Mid Cap Fund; Class IS Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'LSPD',
    name: 'Lightspeed Commerce Inc (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'LSPR',
    name: 'Larkspur Health Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LSPRU',
    name: 'Larkspur Health Acquisition Corp - Units (1 Ord Share Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'LSRCF',
    name: 'Lasertec Corporation',
    region: 'US',
  },
  {
    symbol: 'LSRCY',
    name: 'Lasertec Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'LSST',
    name: 'Natixis ETF Trust - Natixis Loomis Sayles Short Duration Income ETF',
    region: 'US',
  },
  {
    symbol: 'LSTR',
    name: 'Landstar System, Inc.',
    region: 'US',
  },
  {
    symbol: 'LSXMA',
    name: 'Liberty Media Corp. (Tracking Stock - SiriusXM) Series A',
    region: 'US',
  },
  {
    symbol: 'LSXMB',
    name: 'Liberty Media Corp. (Tracking Stock - SiriusXM) Series B',
    region: 'US',
  },
  {
    symbol: 'LSXMK',
    name: 'Liberty Media Corp. (Tracking Stock - SiriusXM) Series C',
    region: 'US',
  },
  {
    symbol: 'LTBR',
    name: 'Lightbridge Corp',
    region: 'US',
  },
  {
    symbol: 'LTCH',
    name: 'Latch Inc',
    region: 'US',
  },
  {
    symbol: 'LTH',
    name: 'Life Time Group Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LTHM',
    name: 'Livent Corp',
    region: 'US',
  },
  {
    symbol: 'LTL',
    name: 'ProShares Trust - ProShares Ultra Telecommunications',
    region: 'US',
  },
  {
    symbol: 'LTORY',
    name: 'Larsen & Toubro Ltd. - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'LTOUF',
    name: 'Larsen & Toubro Ltd. - GDR - Reg S',
    region: 'US',
  },
  {
    symbol: 'LTPZ',
    name: 'Pimco Exchange Traded Fund - PIMCO 15+ Year U.S. TIPS Index Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'LTRN',
    name: 'Lantern Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'LTRPA',
    name: 'Liberty TripAdvisor Holdings Inc - Series A',
    region: 'US',
  },
  {
    symbol: 'LTRPB',
    name: 'Liberty TripAdvisor Holdings Inc - Series B',
    region: 'US',
  },
  {
    symbol: 'LTRX',
    name: 'Lantronix Inc',
    region: 'US',
  },
  {
    symbol: 'LTRY',
    name: 'Lottery.com Inc',
    region: 'US',
  },
  {
    symbol: 'LUB',
    name: 'Luby`s, Inc.',
    region: 'US',
  },
  {
    symbol: 'LUCD',
    name: 'Lucid Diagnostics Inc',
    region: 'US',
  },
  {
    symbol: 'LULU',
    name: 'Lululemon Athletica inc.',
    region: 'US',
  },
  {
    symbol: 'LUMN',
    name: 'Lumen Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'LUMO',
    name: 'Lumos Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'LUNA',
    name: 'Luna Innovations Inc',
    region: 'US',
  },
  {
    symbol: 'LUNG',
    name: 'Pulmonx Corp',
    region: 'US',
  },
  {
    symbol: 'LUV',
    name: 'Southwest Airlines Co',
    region: 'US',
  },
  {
    symbol: 'LUXA',
    name: 'Lux Health Tech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LUXAU',
    name: 'Lux Health Tech Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'LUXE',
    name: 'Emles Trust - Emles Luxury Goods ETF',
    region: 'US',
  },
  {
    symbol: 'LVAC',
    name: 'Lava Medtech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LVACU',
    name: 'Lava Medtech Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'LVHD',
    name: 'Legg Mason ETF Investment Trust - Legg Mason Low Volatility High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'LVHI',
    name: 'Legg Mason ETF Investment Trust - Legg Mason International Low Volatility High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'LVLU',
    name: 'Lulus Fashion Lounge Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LVMHF',
    name: 'Lvmh Moet Hennessy Vuitton SE',
    region: 'US',
  },
  {
    symbol: 'LVMUY',
    name: 'Lvmh Moet Hennessy Vuitton SE - ADR - Level I',
    region: 'US',
  },
  {
    symbol: 'LVO',
    name: 'LiveOne Inc',
    region: 'US',
  },
  {
    symbol: 'LVOL',
    name: 'American Century ETF Trust - American Century Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'LVOX',
    name: 'LiveVox Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'LVOXU',
    name: 'LiveVox Holdings Inc - Unit (1 Ordinary share Cls A & 1/2 Wrt)',
    region: 'US',
  },
  {
    symbol: 'LVRA',
    name: 'Levere Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'LVRAU',
    name: 'Levere Holdings Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'LVS',
    name: 'Las Vegas Sands Corp',
    region: 'US',
  },
  {
    symbol: 'LVTX',
    name: 'LAVA Therapeutics NV',
    region: 'US',
  },
  {
    symbol: 'LW',
    name: 'Lamb Weston Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'LWAY',
    name: 'Lifeway Foods, Inc.',
    region: 'US',
  },
  {
    symbol: 'LWLG',
    name: 'Lightwave Logic Inc',
    region: 'US',
  },
  {
    symbol: 'LXEH',
    name: 'Lixiang Education Holding Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'LXFR',
    name: 'Luxfer Holdings PLC',
    region: 'US',
  },
  {
    symbol: 'LXRX',
    name: 'Lexicon Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'LXU',
    name: 'LSB Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'LYB',
    name: 'LyondellBasell Industries NV - Class A',
    region: 'US',
  },
  {
    symbol: 'LYEL',
    name: 'Lyell Immunopharma Inc',
    region: 'US',
  },
  {
    symbol: 'LYFE',
    name: '2nd Vote Funds - 2ndVote Life Neutral Plus ETF',
    region: 'US',
  },
  {
    symbol: 'LYFT',
    name: 'Lyft Inc Cls A',
    region: 'US',
  },
  {
    symbol: 'LYG',
    name: 'Lloyds Banking Group plc - ADR',
    region: 'US',
  },
  {
    symbol: 'LYL',
    name: 'Dragon Victory International Ltd',
    region: 'US',
  },
  {
    symbol: 'LYLT',
    name: 'Loyalty Ventures Inc',
    region: 'US',
  },
  {
    symbol: 'LYRA',
    name: 'Lyra Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'LYT',
    name: 'Lytus Technologies Holdings PTV Ltd',
    region: 'US',
  },
  {
    symbol: 'LYTS',
    name: 'LSI Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'LYV',
    name: 'Live Nation Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'LZ',
    name: 'LegalZoom.com Inc.',
    region: 'US',
  },
  {
    symbol: 'LZAGF',
    name: 'Lonza Group AG',
    region: 'US',
  },
  {
    symbol: 'LZAGY',
    name: 'Lonza Group AG - ADR',
    region: 'US',
  },
  {
    symbol: 'LZB',
    name: 'La-Z-Boy Inc.',
    region: 'US',
  },
  {
    symbol: 'LZRFY',
    name: 'Localiza Rent A Car - ADR',
    region: 'US',
  },
  {
    symbol: 'M',
    name: 'Macy`s Inc',
    region: 'US',
  },
  {
    symbol: 'MA',
    name: 'Mastercard Incorporated - Class A',
    region: 'US',
  },
  {
    symbol: 'MAAQ',
    name: 'Mana Capital Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'MAAQU',
    name: 'Mana Capital Acquisition Corp - Units (1 Ord Share & 1/2 War & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'MAAX',
    name: 'VanEck ETF Trust - VanEck Muni Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'MACA',
    name: 'Moringa Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MACAU',
    name: 'Moringa Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MACC',
    name: 'Mission Advancement Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MACK',
    name: 'Merrimack Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'MACU',
    name: 'Mallard Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'MACUU',
    name: 'Mallard Acquisition Corp - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'MAFGX',
    name: 'BlackRock Capital Appreciation Fund, Inc; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MAFOX',
    name: 'BlackRock Large Cap Focus Growth Fund, Inc; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MAG',
    name: 'MAG Silver Corp.',
    region: 'US',
  },
  {
    symbol: 'MAGA',
    name: 'ETF Series Solutions Trust - Point Bridge GOP Stock Tracker ETF',
    region: 'US',
  },
  {
    symbol: 'MAKX',
    name: 'ProShares Trust - ProShares S&P Kensho Smart Factories ETF',
    region: 'US',
  },
  {
    symbol: 'MAMB',
    name: 'Northern Lights Fund Trust IV - Monarch Ambassador Income ETF',
    region: 'US',
  },
  {
    symbol: 'MAN',
    name: 'ManpowerGroup',
    region: 'US',
  },
  {
    symbol: 'MANH',
    name: 'Manhattan Associates, Inc.',
    region: 'US',
  },
  {
    symbol: 'MANT',
    name: 'Mantech International Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MANU',
    name: 'Manchester United Plc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MAOTF',
    name: 'Maroc Telecom',
    region: 'US',
  },
  {
    symbol: 'MAPAX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MAPBX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MAPRX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MAPS',
    name: 'WM Technology Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MAQC',
    name: 'Maquia Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MAQCU',
    name: 'Maquia Capital Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MAR',
    name: 'Marriott International, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MARA',
    name: 'Marathon Digital Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MARB',
    name: 'First Trust Exchange-Traded Fund III - First Trust Merger Arbitrage ETF',
    region: 'US',
  },
  {
    symbol: 'MARK',
    name: 'Remark Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MARUF',
    name: 'Marubeni Corporation',
    region: 'US',
  },
  {
    symbol: 'MARUY',
    name: 'Marubeni Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'MARZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (March) ETF',
    region: 'US',
  },
  {
    symbol: 'MAS',
    name: 'Masco Corp.',
    region: 'US',
  },
  {
    symbol: 'MASI',
    name: 'Masimo Corp',
    region: 'US',
  },
  {
    symbol: 'MASS',
    name: '908 Devices Inc',
    region: 'US',
  },
  {
    symbol: 'MAT',
    name: 'Mattel, Inc.',
    region: 'US',
  },
  {
    symbol: 'MATW',
    name: 'Matthews International Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'MATX',
    name: 'Matson Inc',
    region: 'US',
  },
  {
    symbol: 'MAX',
    name: 'MediaAlpha Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MAXN',
    name: 'Maxeon Solar Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'MAXR',
    name: 'Maxar Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'MAXSF',
    name: 'Maxis Berhad',
    region: 'US',
  },
  {
    symbol: 'MAYZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (May) ETF',
    region: 'US',
  },
  {
    symbol: 'MBAC',
    name: 'M3 Brigade Acquisition II Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MBB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MBS ETF',
    region: 'US',
  },
  {
    symbol: 'MBBB',
    name: 'VanEck ETF Trust - VanEck Moody`s Analytics BBB Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MBCC',
    name: 'Northern Lights Fund Trust IV - Monarch Blue Chips Core ETF',
    region: 'US',
  },
  {
    symbol: 'MBCN',
    name: 'Middlefield Banc Corp.',
    region: 'US',
  },
  {
    symbol: 'MBI',
    name: 'MBIA Inc.',
    region: 'US',
  },
  {
    symbol: 'MBII',
    name: 'Marrone Bio Innovations Inc',
    region: 'US',
  },
  {
    symbol: 'MBIN',
    name: 'Merchants Bancorp',
    region: 'US',
  },
  {
    symbol: 'MBIO',
    name: 'Mustang Bio Inc',
    region: 'US',
  },
  {
    symbol: 'MBIRX',
    name: 'Morgan Stanley Insight Fund; Class IR Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MBND',
    name: 'SPDR Series Trust - SPDR Nuveen Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MBNE',
    name: 'SPDR Series Trust - SPDR Nuveen Municipal Bond ESG ETF',
    region: 'US',
  },
  {
    symbol: 'MBNKP',
    name: 'Medallion Bank - FXDFR PRF PERPETUAL USD 25 - Ser F',
    region: 'US',
  },
  {
    symbol: 'MBOT',
    name: 'Microbot Medical Inc',
    region: 'US',
  },
  {
    symbol: 'MBOX',
    name: 'Alpha Architect Etf Trust - Freedom Day Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'MBRX',
    name: 'Moleculin Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'MBSC',
    name: 'M3 Brigade Acquisition III Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MBSD',
    name: 'FlexShares Trust - FlexShares Disciplined Duration MBS Index Fund',
    region: 'US',
  },
  {
    symbol: 'MBT',
    name: 'Mobile Telesystems PJSC - ADR',
    region: 'US',
  },
  {
    symbol: 'MBTC',
    name: 'Nocturne Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'MBTCU',
    name: 'Nocturne Acquisition Corp - Units (1 Ordinary Shares & 1 Rights)',
    region: 'US',
  },
  {
    symbol: 'MBUU',
    name: 'Malibu Boats Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MBWM',
    name: 'Mercantile Bank Corp.',
    region: 'US',
  },
  {
    symbol: 'MCAA',
    name: 'Mountain & Co I Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MCAAU',
    name: 'Mountain & Co I Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MCAE',
    name: 'Mountain Crest Acquisition Corp III',
    region: 'US',
  },
  {
    symbol: 'MCAEU',
    name: 'Mountain Crest Acquisition Corp III - Units (1 Ord Share & 1 Right )',
    region: 'US',
  },
  {
    symbol: 'MCAF',
    name: 'Mountain Crest Acquisition Corp IV',
    region: 'US',
  },
  {
    symbol: 'MCAFU',
    name: 'Mountain Crest Acquisition Corp IV - Units (1 Ordinary Shares& 1 Rights)',
    region: 'US',
  },
  {
    symbol: 'MCAG',
    name: 'Mountain Crest Acquisition Corp V',
    region: 'US',
  },
  {
    symbol: 'MCAGU',
    name: 'Mountain Crest Acquisition Corp V - Units (1 Ordinary Shares, 1 Rights)',
    region: 'US',
  },
  {
    symbol: 'MCARY',
    name: 'Mercari Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'MCB',
    name: 'Metropolitan Bank Holding Corp',
    region: 'US',
  },
  {
    symbol: 'MCBC',
    name: 'Macatawa Bank Corp.',
    region: 'US',
  },
  {
    symbol: 'MCBS',
    name: 'MetroCity Bankshares Inc',
    region: 'US',
  },
  {
    symbol: 'MCD',
    name: 'McDonald`s Corp',
    region: 'US',
  },
  {
    symbol: 'MCFT',
    name: 'MasterCraft Boat Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MCG',
    name: 'Membership Collective Group Inc Class A',
    region: 'US',
  },
  {
    symbol: 'MCHI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI China ETF',
    region: 'US',
  },
  {
    symbol: 'MCHP',
    name: 'Microchip Technology, Inc.',
    region: 'US',
  },
  {
    symbol: 'MCHX',
    name: 'Marchex Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'MCK',
    name: 'Mckesson Corporation',
    region: 'US',
  },
  {
    symbol: 'MCLD',
    name: 'mCloud Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'MCO',
    name: 'Moody`s Corp.',
    region: 'US',
  },
  {
    symbol: 'MCPRX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MCRB',
    name: 'Seres Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'MCRI',
    name: 'Monarch Casino & Resort, Inc.',
    region: 'US',
  },
  {
    symbol: 'MCRO',
    name: 'IndexIQ ETF Trust - IQ Hedge Macro Tracker ETF',
    region: 'US',
  },
  {
    symbol: 'MCS',
    name: 'Marcus Corp.',
    region: 'US',
  },
  {
    symbol: 'MCVIX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MCVRX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MCW',
    name: 'Mister Car Wash Inc',
    region: 'US',
  },
  {
    symbol: 'MCY',
    name: 'Mercury General Corp.',
    region: 'US',
  },
  {
    symbol: 'MD',
    name: 'MEDNAX Inc',
    region: 'US',
  },
  {
    symbol: 'MDB',
    name: 'MongoDB Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MDC',
    name: 'M.D.C. Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'MDCP',
    name: 'Victory Portfolios II - VictoryShares THB Mid Cap ESG ETF',
    region: 'US',
  },
  {
    symbol: 'MDEV',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Indxx Medical Devices ETF',
    region: 'US',
  },
  {
    symbol: 'MDFOX',
    name: 'BlackRock Large Cap Focus Growth Fund, Inc; Investor A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MDGL',
    name: 'Madrigal Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'MDGS',
    name: 'Medigus Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'MDH',
    name: 'MDH Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MDIA',
    name: 'MediaCo Holding Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MDIBF',
    name: 'Mediobanca - Banca Di Credito Finanziario Spa',
    region: 'US',
  },
  {
    symbol: 'MDIBY',
    name: 'Mediobanca - Banca Di Credito Finanziario Spa - ADR',
    region: 'US',
  },
  {
    symbol: 'MDIV',
    name: 'First Trust Exchange-Traded Fund VI - Multi-Asset Diversified Income Index Fund',
    region: 'US',
  },
  {
    symbol: 'MDJH',
    name: 'MDJM Ltd',
    region: 'US',
  },
  {
    symbol: 'MDLZ',
    name: 'Mondelez International Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MDNA',
    name: 'Medicenna Therapeutics Corp',
    region: 'US',
  },
  {
    symbol: 'MDRX',
    name: 'Allscripts Healthcare Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'MDT',
    name: 'Medtronic Plc',
    region: 'US',
  },
  {
    symbol: 'MDTKF',
    name: 'Media Tek Inc',
    region: 'US',
  },
  {
    symbol: 'MDU',
    name: 'MDU Resources Group Inc',
    region: 'US',
  },
  {
    symbol: 'MDVL',
    name: 'MedAvail Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MDWD',
    name: 'MediWound Ltd',
    region: 'US',
  },
  {
    symbol: 'MDWT',
    name: 'Midwest Hldg Inc',
    region: 'US',
  },
  {
    symbol: 'MDXG',
    name: 'Mimedx Group Inc',
    region: 'US',
  },
  {
    symbol: 'MDXH',
    name: 'MDxHealth SA - ADR',
    region: 'US',
  },
  {
    symbol: 'MDY',
    name: 'State Street Global Advisors - S&P MidCap 400 ETF',
    region: 'US',
  },
  {
    symbol: 'MDYG',
    name: 'SPDR Series Trust - SPDR S&P 400 Mid Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'MDYV',
    name: 'SPDR Series Trust - SPDR S&P 400 Mid Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'ME',
    name: '23andMe Holding Co - Class A',
    region: 'US',
  },
  {
    symbol: 'MEAC',
    name: 'Mercury Ecommerce Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MEACU',
    name: 'Mercury Ecommerce Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MEAR',
    name: 'BlackRock Institutional Trust Company N.A. - BlackRock Short Maturity Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MEC',
    name: 'Mayville Engineering Company Inc',
    region: 'US',
  },
  {
    symbol: 'MED',
    name: 'Medifast Inc',
    region: 'US',
  },
  {
    symbol: 'MEDP',
    name: 'Medpace Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MEDS',
    name: 'Trxade Health Inc',
    region: 'US',
  },
  {
    symbol: 'MEG',
    name: 'Montrose Environmental Group Inc',
    region: 'US',
  },
  {
    symbol: 'MEGRX',
    name: 'MFS Series Trust II: MFS Growth Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MEI',
    name: 'Methode Electronics, Inc.',
    region: 'US',
  },
  {
    symbol: 'MEIAX',
    name: 'MFS Series Trust I: MFS Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MEIHX',
    name: 'MFS Series Trust I: MFS Value Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MEIIX',
    name: 'MFS Series Trust I: MFS Value Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MEIJX',
    name: 'MFS Series Trust I: MFS Value Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MEIKX',
    name: 'MFS Series Trust I: MFS Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MEIP',
    name: 'MEI Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'MEJHF',
    name: 'Meiji Holdings Co. Ltd',
    region: 'US',
  },
  {
    symbol: 'MEJHY',
    name: 'Meiji Holdings Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MEKA',
    name: 'MELI Kaszek Pioneer Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MELI',
    name: 'MercadoLibre Inc',
    region: 'US',
  },
  {
    symbol: 'MEME',
    name: 'Listed Funds Trust - Roundhill MEME ETF',
    region: 'US',
  },
  {
    symbol: 'MENV',
    name: 'Advisorshares Trust - AdvisorShares North Square McKee ESG Core Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MEOA',
    name: 'Minority Equality Opportunities Acquisition Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MEOAU',
    name: 'Minority Equality Opportunities Acquisition Inc - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'MEOH',
    name: 'Methanex Corp.',
    region: 'US',
  },
  {
    symbol: 'MERC',
    name: 'Mercer International Inc.',
    region: 'US',
  },
  {
    symbol: 'MESA',
    name: 'Mesa Air Group Inc.',
    region: 'US',
  },
  {
    symbol: 'MESO',
    name: 'Mesoblast Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MET',
    name: 'Metlife Inc',
    region: 'US',
  },
  {
    symbol: 'METC',
    name: 'Ramaco Resources Inc',
    region: 'US',
  },
  {
    symbol: 'METV',
    name: 'Listed Funds Trust - Roundhill Ball Metaverse ETF',
    region: 'US',
  },
  {
    symbol: 'METX',
    name: 'Meten Holding Group Ltd',
    region: 'US',
  },
  {
    symbol: 'MEXX',
    name: 'Direxion Shares ETF Trust - Direxion Daily MSCI Mexico Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'MF',
    name: 'Missfresh Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MFC',
    name: 'Manulife Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'MFDX',
    name: 'Pimco Equity Series - PIMCO RAFI Dynamic Multi-Factor International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'MFEGX',
    name: 'MFS Series Trust II: MFS Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFEHX',
    name: 'MFS Series Trust II: MFS Growth Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFEIX',
    name: 'MFS Series Trust II: MFS Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFEJX',
    name: 'MFS Series Trust II: MFS Growth Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFEKX',
    name: 'MFS Series Trust II: MFS Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFEM',
    name: 'Pimco Equity Series - PIMCO RAFI Dynamic Multi-Factor Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'MFG',
    name: 'Mizuho Financial Group, Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'MFGP',
    name: 'Micro Focus International Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'MFH',
    name: 'Mercurity Fintech Holding Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'MFLX',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Flexible Municipal High Income ETF',
    region: 'US',
  },
  {
    symbol: 'MFRJX',
    name: 'MFS Series Trust V: MFS Research Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFRKX',
    name: 'MFS Series Trust V: MFS Research Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MFUL',
    name: 'Collaborative Investment Series Trust - Mindful Conservative ETF',
    region: 'US',
  },
  {
    symbol: 'MFUS',
    name: 'Pimco Equity Series - PIMCO RAFI Dynamic Multi-Factor U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'MG',
    name: 'Mistras Group Inc',
    region: 'US',
  },
  {
    symbol: 'MGA',
    name: 'Magna International Inc.',
    region: 'US',
  },
  {
    symbol: 'MGC',
    name: 'Vanguard Group, Inc. - Vanguard Mega Cap 300 Index ETF',
    region: 'US',
  },
  {
    symbol: 'MGDDF',
    name: 'Michelin (CGDE)-B - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'MGDDY',
    name: 'Michelin (CGDE)-B - ADR',
    region: 'US',
  },
  {
    symbol: 'MGEE',
    name: 'MGE Energy, Inc.',
    region: 'US',
  },
  {
    symbol: 'MGI',
    name: 'Moneygram International Inc.',
    region: 'US',
  },
  {
    symbol: 'MGIC',
    name: 'Magic Software Enterprises Ltd.',
    region: 'US',
  },
  {
    symbol: 'MGJCL',
    name: 'Magnit PJSC - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'MGJNF',
    name: 'Magnit PJSC',
    region: 'US',
  },
  {
    symbol: 'MGK',
    name: 'Vanguard Group, Inc. - Vanguard Mega Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'MGLD',
    name: 'Marygold Companies Inc (The)',
    region: 'US',
  },
  {
    symbol: 'MGLUY',
    name: 'Magazine Luiza SA - ADR',
    region: 'US',
  },
  {
    symbol: 'MGM',
    name: 'MGM Resorts International',
    region: 'US',
  },
  {
    symbol: 'MGMT',
    name: 'Unified Series Trust - Ballast Small/Mid Cap ETF',
    region: 'US',
  },
  {
    symbol: 'MGNI',
    name: 'Magnite Inc',
    region: 'US',
  },
  {
    symbol: 'MGNX',
    name: 'Macrogenics Inc',
    region: 'US',
  },
  {
    symbol: 'MGPI',
    name: 'MGP Ingredients, Inc.',
    region: 'US',
  },
  {
    symbol: 'MGRC',
    name: 'McGrath Rentcorp',
    region: 'US',
  },
  {
    symbol: 'MGTA',
    name: 'Magenta Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'MGTX',
    name: 'MeiraGTx Holdings plc',
    region: 'US',
  },
  {
    symbol: 'MGV',
    name: 'Vanguard Group, Inc. - Vanguard Mega Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'MGY',
    name: 'Magnolia Oil & Gas Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MGYR',
    name: 'Magyar Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'MHH',
    name: 'Mastech Digital Inc',
    region: 'US',
  },
  {
    symbol: 'MHK',
    name: 'Mohawk Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'MHLD',
    name: 'Maiden Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'MHO',
    name: 'MI Homes Inc.',
    region: 'US',
  },
  {
    symbol: 'MHUA',
    name: 'Meihua International Medical Technologies Co Ltd',
    region: 'US',
  },
  {
    symbol: 'MHVYF',
    name: 'Mitsubishi Heavy Industries Ltd.',
    region: 'US',
  },
  {
    symbol: 'MIC',
    name: 'Macquarie Infrastructure Holdings LLC - Units',
    region: 'US',
  },
  {
    symbol: 'MICT',
    name: 'MICT Inc',
    region: 'US',
  },
  {
    symbol: 'MID',
    name: 'American Century ETF Trust - American Century Mid Cap Growth Impact ETF',
    region: 'US',
  },
  {
    symbol: 'MIDD',
    name: 'Middleby Corp.',
    region: 'US',
  },
  {
    symbol: 'MIDE',
    name: 'DBX ETF Trust - Xtrackers S&P MidCap 400 ESG ETF',
    region: 'US',
  },
  {
    symbol: 'MIDF',
    name: 'iShares Trust - iShares MSCI USA Mid-Cap Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'MIDU',
    name: 'Direxion Shares ETF Trust - Direxion Daily Mid Cap Bull 3x Shares',
    region: 'US',
  },
  {
    symbol: 'MIELF',
    name: 'Mitsubishi Electric Corporation',
    region: 'US',
  },
  {
    symbol: 'MIELY',
    name: 'Mitsubishi Electric Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'MIG',
    name: 'VanEck ETF Trust - VanEck Moody`s Analytics IG Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MIGI',
    name: 'Mawson Infrastructure Group Inc',
    region: 'US',
  },
  {
    symbol: 'MILE',
    name: 'Metromile Inc',
    region: 'US',
  },
  {
    symbol: 'MILN',
    name: 'Global X Funds - Global X Millennial Consumer ETF',
    region: 'US',
  },
  {
    symbol: 'MIME',
    name: 'Mimecast Ltd',
    region: 'US',
  },
  {
    symbol: 'MIMO',
    name: 'Airspan Networks Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MINC',
    name: 'Advisorshares Trust - AdvisorShares Newfleet Multi-Sector Income ETF',
    region: 'US',
  },
  {
    symbol: 'MIND',
    name: 'MIND Technology Inc',
    region: 'US',
  },
  {
    symbol: 'MINM',
    name: 'Minim Inc',
    region: 'US',
  },
  {
    symbol: 'MINN',
    name: 'Trust For Professional Managers - Mairs & Power Minnesota Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MINO',
    name: 'Pimco Exchange Traded Fund - PIMCO Municipal Income Opportunities Active Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'MINT',
    name: 'Pimco Exchange Traded Fund - PIMCO Enhanced Short Maturity Active Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'MIR',
    name: 'Mirion Technologies Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MIRM',
    name: 'Mirum Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'MIRO',
    name: 'Miromatrix Medical Inc',
    region: 'US',
  },
  {
    symbol: 'MIST',
    name: 'Milestone Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'MIT',
    name: 'Mason Industrial Technology Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MITA',
    name: 'Coliseum Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MITAU',
    name: 'Coliseum Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'MITC',
    name: 'Meattech 3D Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MITDX',
    name: 'Massachusetts Investors Trust; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MITEF',
    name: 'Mitsubishi Estate Company Limited',
    region: 'US',
  },
  {
    symbol: 'MITEY',
    name: 'Mitsubishi Estate Company Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'MITHX',
    name: 'Massachusetts Investors Trust; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MITIX',
    name: 'Massachusetts Investors Trust; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MITJX',
    name: 'Massachusetts Investors Trust; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MITK',
    name: 'Mitek Systems Inc',
    region: 'US',
  },
  {
    symbol: 'MITO',
    name: 'Stealth BioTherapeutics Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'MITQ',
    name: 'Moving iMage Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'MITTX',
    name: 'Massachusetts Investors Trust; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MIXT',
    name: 'MiX Telematics Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MJ',
    name: 'ETF Managers Group LLC - ETFMG Alternative Harvest ETF',
    region: 'US',
  },
  {
    symbol: 'MJIN',
    name: 'ETF Managers Trust - ETFMG 2X Daily Inverse Alternative Harvest ETF',
    region: 'US',
  },
  {
    symbol: 'MJUS',
    name: 'ETF Managers Trust - ETFMG U.S. Alternative Harvest ETF',
    region: 'US',
  },
  {
    symbol: 'MJXL',
    name: 'ETF Managers Trust - ETFMG 2X Daily Alternative Harvest ETF',
    region: 'US',
  },
  {
    symbol: 'MKC',
    name: 'McCormick & Co., Inc. (Non Voting)',
    region: 'US',
  },
  {
    symbol: 'MKC.V',
    name: 'McCormick & Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'MKD',
    name: 'Molecular Data Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'MKDVX',
    name: 'BlackRock Equity Dividend Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MKFG',
    name: 'Markforged Holding Corporation',
    region: 'US',
  },
  {
    symbol: 'MKFOX',
    name: 'BlackRock Large Cap Focus Growth Fund, Inc; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MKL',
    name: 'Markel Corp',
    region: 'US',
  },
  {
    symbol: 'MKSI',
    name: 'MKS Instruments, Inc.',
    region: 'US',
  },
  {
    symbol: 'MKTW',
    name: 'Marketwise Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ML',
    name: 'MoneyLion Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MLAAX',
    name: 'MainStay Funds: MainStay Winslow Large Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MLAB',
    name: 'Mesa Laboratories, Inc.',
    region: 'US',
  },
  {
    symbol: 'MLAC',
    name: 'Malacca Straits Acquisition Co Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'MLACU',
    name: 'Malacca Straits Acquisition Co Ltd - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MLAI',
    name: 'McLaren Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MLAIU',
    name: 'McLaren Technology Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MLAIX',
    name: 'MainStay Funds: MainStay Winslow Large Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MLCO',
    name: 'Melco Resorts & Entertainment Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MLI',
    name: 'Mueller Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'MLKN',
    name: 'MillerKnoll Inc',
    region: 'US',
  },
  {
    symbol: 'MLM',
    name: 'Martin Marietta Materials, Inc.',
    region: 'US',
  },
  {
    symbol: 'MLN',
    name: 'VanEck ETF Trust - VanEck Long Muni ETF',
    region: 'US',
  },
  {
    symbol: 'MLNK',
    name: 'MeridianLink Inc',
    region: 'US',
  },
  {
    symbol: 'MLP',
    name: 'Maui Land & Pineapple Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'MLPA',
    name: 'Global X Funds - Global X MLP ETF',
    region: 'US',
  },
  {
    symbol: 'MLPX',
    name: 'Global X Funds - Global X MLP & Energy Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'MLR',
    name: 'Miller Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'MLRRX',
    name: 'MainStay Funds: MainStay Winslow Large Cap Growth Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MLRSX',
    name: 'MainStay Funds: MainStay Winslow Large Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MLRTX',
    name: 'MainStay Funds: MainStay Winslow Large Cap Growth Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MLSS',
    name: 'Milestone Scientific Inc.',
    region: 'US',
  },
  {
    symbol: 'MLVF',
    name: 'Malvern Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'MMAPX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MMAT',
    name: 'Meta Materials Inc',
    region: 'US',
  },
  {
    symbol: 'MMCA',
    name: 'IndexIQ Active ETF Trust - IQ MacKay California Municipal Intermediate ETF',
    region: 'US',
  },
  {
    symbol: 'MMIN',
    name: 'IndexIQ Active ETF Trust - IQ MacKay Municipal Insured ETF',
    region: 'US',
  },
  {
    symbol: 'MMIT',
    name: 'IndexIQ Active ETF Trust - IQ MacKay Municipal Intermediate ETF',
    region: 'US',
  },
  {
    symbol: 'MMLG',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Multi-Manager Large Growth ETF',
    region: 'US',
  },
  {
    symbol: 'MMLP',
    name: 'Martin Midstream Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'MMM',
    name: '3M Co.',
    region: 'US',
  },
  {
    symbol: 'MMMB',
    name: 'MamaMancini`s Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MMP',
    name: 'Magellan Midstream Partners L.P.',
    region: 'US',
  },
  {
    symbol: 'MMPCX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MMS',
    name: 'Maximus Inc.',
    region: 'US',
  },
  {
    symbol: 'MMSC',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Multi-Manager Small Cap Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'MMSI',
    name: 'Merit Medical Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'MMTM',
    name: 'SPDR Series Trust - SPDR S&P 1500 Momentum Tilt ETF',
    region: 'US',
  },
  {
    symbol: 'MMX',
    name: 'Maverix Metals Inc',
    region: 'US',
  },
  {
    symbol: 'MMYT',
    name: 'MakeMyTrip Ltd',
    region: 'US',
  },
  {
    symbol: 'MNA',
    name: 'IndexIQ ETF Trust - IQ Merger Arbitrage ETF',
    region: 'US',
  },
  {
    symbol: 'MNDAX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDBX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDCX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDGX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDHX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDIX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDJX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDKX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDO',
    name: 'Mind C.T.I Ltd',
    region: 'US',
  },
  {
    symbol: 'MNDRX',
    name: 'MFS Series Trust I: MFS New Discovery Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MNDT',
    name: 'Mandiant Inc',
    region: 'US',
  },
  {
    symbol: 'MNDY',
    name: 'Monday.Com Ltd',
    region: 'US',
  },
  {
    symbol: 'MNKD',
    name: 'Mannkind Corp',
    region: 'US',
  },
  {
    symbol: 'MNM',
    name: 'Direxion Shares ETF Trust - Direxion Daily Metal Miners Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'MNMD',
    name: 'Mind Medicine Inc (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'MNOV',
    name: 'Medicinova Inc',
    region: 'US',
  },
  {
    symbol: 'MNPR',
    name: 'Monopar Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'MNRL',
    name: 'Brigham Minerals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MNRO',
    name: 'Monro Inc',
    region: 'US',
  },
  {
    symbol: 'MNSB',
    name: 'MainStreet Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'MNSO',
    name: 'MINISO Group Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MNST',
    name: 'Monster Beverage Corp.',
    region: 'US',
  },
  {
    symbol: 'MNTK',
    name: 'Montauk Renewables Inc',
    region: 'US',
  },
  {
    symbol: 'MNTN',
    name: 'Everest Consolidator Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MNTS',
    name: 'Momentus Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MNTV',
    name: 'Momentive Global Inc',
    region: 'US',
  },
  {
    symbol: 'MNTX',
    name: 'Manitex International Inc',
    region: 'US',
  },
  {
    symbol: 'MO',
    name: 'Altria Group Inc.',
    region: 'US',
  },
  {
    symbol: 'MOAT',
    name: 'VanEck ETF Trust - VanEck Morningstar Wide Moat ETF',
    region: 'US',
  },
  {
    symbol: 'MOBQ',
    name: 'Mobiquity Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'MOD',
    name: 'Modine Manufacturing Co.',
    region: 'US',
  },
  {
    symbol: 'MODD',
    name: 'Modular Medical Inc',
    region: 'US',
  },
  {
    symbol: 'MODN',
    name: 'Model N Inc',
    region: 'US',
  },
  {
    symbol: 'MODV',
    name: 'ModivCare Inc',
    region: 'US',
  },
  {
    symbol: 'MOFG',
    name: 'MidWestOne Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'MOG.A',
    name: 'Moog, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MOG.B',
    name: 'Moog, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'MOGU',
    name: 'MOGU Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'MOH',
    name: 'Molina Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'MOHO',
    name: 'ECMOHO Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MOHR',
    name: 'Collaborative Investment Series Trust - Mohr Growth ETF',
    region: 'US',
  },
  {
    symbol: 'MOLN',
    name: 'Molecular Partners AG - ADR',
    region: 'US',
  },
  {
    symbol: 'MOMO',
    name: 'Hello Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'MON',
    name: 'Monument Circle Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MONCU',
    name: 'Monument Circle Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MONOF',
    name: 'Monotaro Co.Ltd',
    region: 'US',
  },
  {
    symbol: 'MONOY',
    name: 'Monotaro Co.Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MONRF',
    name: 'Moncler SPA',
    region: 'US',
  },
  {
    symbol: 'MONRY',
    name: 'Moncler SPA - ADR',
    region: 'US',
  },
  {
    symbol: 'MOO',
    name: 'VanEck ETF Trust - VanEck Agribusiness ETF',
    region: 'US',
  },
  {
    symbol: 'MOON',
    name: 'Direxion Shares ETF Trust - Direxion Moonshot Innovators ETF',
    region: 'US',
  },
  {
    symbol: 'MOR',
    name: 'Morphosys AG - ADR',
    region: 'US',
  },
  {
    symbol: 'MORF',
    name: 'Morphic Holding Inc',
    region: 'US',
  },
  {
    symbol: 'MORN',
    name: 'Morningstar Inc',
    region: 'US',
  },
  {
    symbol: 'MORT',
    name: 'VanEck ETF Trust - VanEck Mortgage REIT Income ETF',
    region: 'US',
  },
  {
    symbol: 'MOS',
    name: 'Mosaic Company',
    region: 'US',
  },
  {
    symbol: 'MOTE',
    name: 'VanEck ETF Trust - VanEck Morningstar ESG Moat ETF',
    region: 'US',
  },
  {
    symbol: 'MOTG',
    name: 'VanEck ETF Trust - VanEck Morningstar Global Wide Moat ETF',
    region: 'US',
  },
  {
    symbol: 'MOTI',
    name: 'VanEck ETF Trust - VanEck Morningstar International Moat ETF',
    region: 'US',
  },
  {
    symbol: 'MOTO',
    name: 'Guinness Atkinson Funds - SmartETFs Smart Transportation & Technology ETF',
    region: 'US',
  },
  {
    symbol: 'MOTS',
    name: 'Motus GI Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MOV',
    name: 'Movado Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'MOVE',
    name: 'Movano Inc',
    region: 'US',
  },
  {
    symbol: 'MP',
    name: 'MP Materials Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'MPAA',
    name: 'Motorcar Parts of America Inc.',
    region: 'US',
  },
  {
    symbol: 'MPAC',
    name: 'Model Performance Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MPACU',
    name: 'Model Performance Acquisition Corp - Units (1 Ord Class A , 1/2 War, 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'MPB',
    name: 'Mid Penn Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'MPC',
    name: 'Marathon Petroleum Corp',
    region: 'US',
  },
  {
    symbol: 'MPLN',
    name: 'MultiPlan Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MPLX',
    name: 'MPLX LP - Unit',
    region: 'US',
  },
  {
    symbol: 'MPRA',
    name: 'Mercato Partners Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MPRAU',
    name: 'Mercato Partners Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MPRO',
    name: 'Northern Lights Fund Trust IV - Monarch ProCap ETF',
    region: 'US',
  },
  {
    symbol: 'MPRRX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MPWR',
    name: 'Monolithic Power System Inc',
    region: 'US',
  },
  {
    symbol: 'MPX',
    name: 'Marine Products Corp',
    region: 'US',
  },
  {
    symbol: 'MQ',
    name: 'Marqeta Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MRAAF',
    name: 'Murata Manufacturing Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'MRAAY',
    name: 'Murata Manufacturing Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'MRAD',
    name: 'Guinness Atkinson Funds - SmartETFs Advertising & Marketing Technology ETF',
    region: 'US',
  },
  {
    symbol: 'MRAI',
    name: 'Marpai Inc Class A',
    region: 'US',
  },
  {
    symbol: 'MRAM',
    name: 'Everspin Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'MRBK',
    name: 'Meridian Corp',
    region: 'US',
  },
  {
    symbol: 'MRC',
    name: 'MRC Global Inc',
    region: 'US',
  },
  {
    symbol: 'MRCIF',
    name: 'Mercari Inc',
    region: 'US',
  },
  {
    symbol: 'MRCY',
    name: 'Mercury Systems Inc',
    region: 'US',
  },
  {
    symbol: 'MREO',
    name: 'Mereo Biopharma Group Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'MRERX',
    name: 'MFS Series Trust I: MFS Core Equity Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRFIX',
    name: 'MFS Series Trust V: MFS Research Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRGAX',
    name: 'MFS Series Trust I: MFS Core Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRGHX',
    name: 'MFS Series Trust I: MFS Core Equity Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRGJX',
    name: 'MFS Series Trust I: MFS Core Equity Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRGKX',
    name: 'MFS Series Trust I: MFS Core Equity Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRGR',
    name: 'ProShares Trust - ProShares Merger ETF',
    region: 'US',
  },
  {
    symbol: 'MRGRX',
    name: 'MFS Series Trust I: MFS Core Equity Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MRIN',
    name: 'Marin Software Inc',
    region: 'US',
  },
  {
    symbol: 'MRK',
    name: 'Merck & Co Inc',
    region: 'US',
  },
  {
    symbol: 'MRKR',
    name: 'Marker Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'MRM',
    name: 'MEDIROM Healthcare Technologies Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'MRNA',
    name: 'Moderna Inc',
    region: 'US',
  },
  {
    symbol: 'MRND',
    name: 'IndexIQ ETF Trust - IQ U.S. Mid Cap R&D Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'MRNS',
    name: 'Marinus Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'MRO',
    name: 'Marathon Oil Corporation',
    region: 'US',
  },
  {
    symbol: 'MRSK',
    name: 'Northern Lights Fund Trust - Toews Agility Shares Managed Risk ETF',
    region: 'US',
  },
  {
    symbol: 'MRSN',
    name: 'Mersana Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'MRTN',
    name: 'Marten Transport, Ltd.',
    region: 'US',
  },
  {
    symbol: 'MRTX',
    name: 'Mirati Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'MRUS',
    name: 'Merus N.V',
    region: 'US',
  },
  {
    symbol: 'MRVI',
    name: 'Maravai LifeSciences Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MRVL',
    name: 'Marvell Technology Inc',
    region: 'US',
  },
  {
    symbol: 'MS',
    name: 'Morgan Stanley',
    region: 'US',
  },
  {
    symbol: 'MSA',
    name: 'MSA Safety Inc',
    region: 'US',
  },
  {
    symbol: 'MSAC',
    name: 'Medicus Sciences Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MSADF',
    name: 'MS&AD Insurance Group Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'MSADY',
    name: 'MS&AD Insurance Group Holdings, Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'MSBHF',
    name: 'Mitsubishi Corporation',
    region: 'US',
  },
  {
    symbol: 'MSBI',
    name: 'Midland States Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'MSC',
    name: 'Studio City International Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'MSCI',
    name: 'MSCI Inc',
    region: 'US',
  },
  {
    symbol: 'MSDA',
    name: 'MSD Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MSDAU',
    name: 'MSD Acquisition Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'MSEX',
    name: 'Middlesex Water Co.',
    region: 'US',
  },
  {
    symbol: 'MSFT',
    name: 'Microsoft Corporation',
    region: 'US',
  },
  {
    symbol: 'MSGE',
    name: 'Madison Square Garden Entertainment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MSGM',
    name: 'Motorsport Games Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MSGR',
    name: 'Direxion Shares ETF Trust - Direxion mRNA ETF',
    region: 'US',
  },
  {
    symbol: 'MSGS',
    name: 'Madison Square Garden Sports Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MSI',
    name: 'Motorola Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'MSLOF',
    name: 'Mitsui O S K Lines Ltd.',
    region: 'US',
  },
  {
    symbol: 'MSLOY',
    name: 'Mitsui O S K Lines Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'MSM',
    name: 'MSC Industrial Direct Co., Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MSMIX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MSMR',
    name: 'ETF Series Solutions Trust - McElhenny Sheffield Managed Risk ETF',
    region: 'US',
  },
  {
    symbol: 'MSN',
    name: 'Emerson Radio Corp.',
    region: 'US',
  },
  {
    symbol: 'MSOS',
    name: 'Advisorshares Trust - AdvisorShares Pure US Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'MSP',
    name: 'Datto Holding Corp',
    region: 'US',
  },
  {
    symbol: 'MSPIX',
    name: 'MainStay Funds Trust: MainStay MacKay S&P 500 Index Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MSSAU',
    name: 'Metal Sky Star Acquisition Corp - Units (1 Ord, 1 Rts & 1 War)',
    region: 'US',
  },
  {
    symbol: 'MSSMY',
    name: 'Misumi Group Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'MSTB',
    name: 'ETF Series Solutions Trust - LHA Market State Tactical Beta ETF',
    region: 'US',
  },
  {
    symbol: 'MSTQ',
    name: 'ETF Series Solutions Trust - LHA Market State Tactical Q ETF',
    region: 'US',
  },
  {
    symbol: 'MSTR',
    name: 'Microstrategy Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'MSUXF',
    name: 'Misumi Group Inc.',
    region: 'US',
  },
  {
    symbol: 'MSVB',
    name: 'Mid-Southern Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'MSVX',
    name: 'ETF Series Solutions Trust - LHA Market State Alpha Seeker ETF',
    region: 'US',
  },
  {
    symbol: 'MT',
    name: 'ArcelorMittal - New York Shares - Level III',
    region: 'US',
  },
  {
    symbol: 'MTA',
    name: 'Metalla Royalty and Streaming Ltd',
    region: 'US',
  },
  {
    symbol: 'MTAC',
    name: 'MedTech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MTACU',
    name: 'MedTech Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'MTAL',
    name: 'Metals Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'MTB',
    name: 'M & T Bank Corp',
    region: 'US',
  },
  {
    symbol: 'MTBC',
    name: 'CareCloud Inc',
    region: 'US',
  },
  {
    symbol: 'MTC',
    name: 'Mmtec Inc',
    region: 'US',
  },
  {
    symbol: 'MTCH',
    name: 'Match Group Inc. - New',
    region: 'US',
  },
  {
    symbol: 'MTCR',
    name: 'Metacrine Inc',
    region: 'US',
  },
  {
    symbol: 'MTD',
    name: 'Mettler-Toledo International, Inc.',
    region: 'US',
  },
  {
    symbol: 'MTDR',
    name: 'Matador Resources Co',
    region: 'US',
  },
  {
    symbol: 'MTEM',
    name: 'Molecular Templates Inc',
    region: 'US',
  },
  {
    symbol: 'MTEX',
    name: 'Mannatech Inc',
    region: 'US',
  },
  {
    symbol: 'MTG',
    name: 'MGIC Investment Corp',
    region: 'US',
  },
  {
    symbol: 'MTGP',
    name: 'WisdomTree Trust - WisdomTree Mortgage Plus Bond Fund',
    region: 'US',
  },
  {
    symbol: 'MTH',
    name: 'Meritage Homes Corp.',
    region: 'US',
  },
  {
    symbol: 'MTHRF',
    name: 'M3, Inc.',
    region: 'US',
  },
  {
    symbol: 'MTHRY',
    name: 'M3, Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'MTL',
    name: 'Mechel PJSC - ADR',
    region: 'US',
  },
  {
    symbol: 'MTLHF',
    name: 'Mitsubishi Chemical Holdings Corp.',
    region: 'US',
  },
  {
    symbol: 'MTLHY',
    name: 'Mitsubishi Chemical Holdings Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'MTLS',
    name: 'Materialise Nv - ADR',
    region: 'US',
  },
  {
    symbol: 'MTMT',
    name: 'Mega Matrix Corp',
    region: 'US',
  },
  {
    symbol: 'MTN',
    name: 'Vail Resorts Inc.',
    region: 'US',
  },
  {
    symbol: 'MTNB',
    name: 'Matinas Biopharma Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MTOR',
    name: 'Meritor Inc',
    region: 'US',
  },
  {
    symbol: 'MTP',
    name: 'Midatech Pharma Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'MTRN',
    name: 'Materion Corp',
    region: 'US',
  },
  {
    symbol: 'MTRX',
    name: 'Matrix Service Co.',
    region: 'US',
  },
  {
    symbol: 'MTRY',
    name: 'Monterey Bio Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'MTRYU',
    name: 'Monterey Bio Acquisition Corp - Units (1 Ord Share & 1 War)',
    region: 'US',
  },
  {
    symbol: 'MTSFF',
    name: 'Mitsui Fudosan Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'MTSFY',
    name: 'Mitsui Fudosan Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'MTSI',
    name: 'MACOM Technology Solutions Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MTTR',
    name: 'Matterport Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MTUM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Momentum Factor ETF',
    region: 'US',
  },
  {
    symbol: 'MTVC',
    name: 'Motive Capital Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'MTVR',
    name: 'Exchange Traded Concepts Trust - Fount Metaverse ETF',
    region: 'US',
  },
  {
    symbol: 'MTW',
    name: 'Manitowoc Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'MTX',
    name: 'Minerals Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'MTZ',
    name: 'Mastec Inc.',
    region: 'US',
  },
  {
    symbol: 'MU',
    name: 'Micron Technology Inc.',
    region: 'US',
  },
  {
    symbol: 'MUB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares National Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'MUBFX',
    name: 'MainStay Funds: MainStay MAP Equity Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MUDS',
    name: 'Mudrick Capital Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'MUDSU',
    name: 'Mudrick Capital Acquisition Corp II - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'MUFG',
    name: 'Mitsubishi UFJ Financial Group, Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'MUIFX',
    name: 'Nationwide Mutual Funds: Nationwide Fund; Institutional Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MULG',
    name: 'Muliang Viagoo Technology Inc',
    region: 'US',
  },
  {
    symbol: 'MUNI',
    name: 'Pimco Exchange Traded Fund - PIMCO Intermediate Municipal Bond Active Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'MUR',
    name: 'Murphy Oil Corp.',
    region: 'US',
  },
  {
    symbol: 'MUSA',
    name: 'Murphy USA Inc',
    region: 'US',
  },
  {
    symbol: 'MUSI',
    name: 'American Century ETF Trust - American Century Multisector Income ETF',
    region: 'US',
  },
  {
    symbol: 'MUST',
    name: 'Columbia ETF Trust I - Columbia Multi-Sector Municipal Income ETF',
    region: 'US',
  },
  {
    symbol: 'MUX',
    name: 'McEwen Mining Inc',
    region: 'US',
  },
  {
    symbol: 'MVBF',
    name: 'MVB Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'MVCAX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MVCHX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MVCJX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MVCKX',
    name: 'MFS Series Trust XI: MFS Mid Cap Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MVIS',
    name: 'Microvision Inc.',
    region: 'US',
  },
  {
    symbol: 'MVO',
    name: 'MV Oil Trust',
    region: 'US',
  },
  {
    symbol: 'MVPS',
    name: 'Amplify ETF Trust - Amplify Thematic All-Stars ETF',
    region: 'US',
  },
  {
    symbol: 'MVRRX',
    name: 'MFS Series Trust I: MFS Value Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'MVST',
    name: 'Microvast Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'MVV',
    name: 'ProShares Trust - ProShares Ultra MidCap400',
    region: 'US',
  },
  {
    symbol: 'MWA',
    name: 'Mueller Water Products Inc - Series A',
    region: 'US',
  },
  {
    symbol: 'MX',
    name: 'MagnaChip Semiconductor Corp',
    region: 'US',
  },
  {
    symbol: 'MXC',
    name: 'Mexco Energy Corp',
    region: 'US',
  },
  {
    symbol: 'MXCT',
    name: 'MaxCyte Inc',
    region: 'US',
  },
  {
    symbol: 'MXDU',
    name: 'ETF Series Solutions Trust - Nationwide Maximum Diversification U.S. Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'MXI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Materials ETF',
    region: 'US',
  },
  {
    symbol: 'MXL',
    name: 'MaxLinear Inc',
    region: 'US',
  },
  {
    symbol: 'MYE',
    name: 'Myers Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'MYFW',
    name: 'First Western Financial Inc',
    region: 'US',
  },
  {
    symbol: 'MYGN',
    name: 'Myriad Genetics, Inc.',
    region: 'US',
  },
  {
    symbol: 'MYMD',
    name: 'MyMD Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'MYNA',
    name: 'Mynaric AG - ADR',
    region: 'US',
  },
  {
    symbol: 'MYO',
    name: 'Myomo Inc',
    region: 'US',
  },
  {
    symbol: 'MYOV',
    name: 'Myovant Sciences Ltd',
    region: 'US',
  },
  {
    symbol: 'MYPS',
    name: 'PLAYSTUDIOS Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'MYRG',
    name: 'MYR Group Inc',
    region: 'US',
  },
  {
    symbol: 'MYSZ',
    name: 'My Size Inc',
    region: 'US',
  },
  {
    symbol: 'MYTE',
    name: 'MYT Netherlands Parent BV - ADR',
    region: 'US',
  },
  {
    symbol: 'MYY',
    name: 'ProShares Trust - ProShares Short Midcap400',
    region: 'US',
  },
  {
    symbol: 'MZTFF',
    name: 'Mizrahi Tefahot Bank Ltd',
    region: 'US',
  },
  {
    symbol: 'MZZ',
    name: 'ProShares Trust - ProShares UltraShort MidCap400',
    region: 'US',
  },
  {
    symbol: 'NAAC',
    name: 'North Atlantic Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NAACU',
    name: 'North Atlantic Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'NABL',
    name: 'N-able Inc',
    region: 'US',
  },
  {
    symbol: 'NACP',
    name: 'Impact Shares Trust I - Impact Shares NAACP Minority Empowerment ETF',
    region: 'US',
  },
  {
    symbol: 'NAESX',
    name: 'Vanguard Index Funds: Vanguard Small-Cap Index Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NAII',
    name: 'Natural Alternatives International, Inc.',
    region: 'US',
  },
  {
    symbol: 'NAIL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Homebuilders & Supplies Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'NAK',
    name: 'Northern Dynasty Minerals Ltd.',
    region: 'US',
  },
  {
    symbol: 'NAMAX',
    name: 'Columbia Funds Series Trust: Columbia Select Mid Cap Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NANR',
    name: 'SSgA Active Trust - SPDR S&P North American Natural Resources ETF',
    region: 'US',
  },
  {
    symbol: 'NAOV',
    name: 'NanoVibronix Inc',
    region: 'US',
  },
  {
    symbol: 'NAPA',
    name: 'Duckhorn Portfolio Inc (The)',
    region: 'US',
  },
  {
    symbol: 'NAPR',
    name: 'Innovator ETFs Trust - Innovator Growth-100 Power Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'NARI',
    name: 'Inari Medical Inc',
    region: 'US',
  },
  {
    symbol: 'NASDX',
    name: 'Shelton Funds: Nasdaq-100 Index Fund; Direct Share Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NAT',
    name: 'Nordic American Tankers Ltd',
    region: 'US',
  },
  {
    symbol: 'NATH',
    name: 'Nathan`s Famous, Inc.',
    region: 'US',
  },
  {
    symbol: 'NATI',
    name: 'National Instruments Corp.',
    region: 'US',
  },
  {
    symbol: 'NATR',
    name: 'Nature`s Sunshine Products, Inc.',
    region: 'US',
  },
  {
    symbol: 'NAUT',
    name: 'Nautilus Biotechnology Inc',
    region: 'US',
  },
  {
    symbol: 'NAVB',
    name: 'Navidea Biopharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'NBCC',
    name: 'Neuberger Berman ETF Trust - Neuberger Berman Next Generation Connected Consumer ETF',
    region: 'US',
  },
  {
    symbol: 'NBCT',
    name: 'Neuberger Berman ETF Trust - Neuberger Berman Carbon Transition Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'NBDS',
    name: 'Neuberger Berman ETF Trust - Neuberger Berman Disrupters ETF',
    region: 'US',
  },
  {
    symbol: 'NBEV',
    name: 'NewAge Inc',
    region: 'US',
  },
  {
    symbol: 'NBHC',
    name: 'National Bank Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NBIX',
    name: 'Neurocrine Biosciences, Inc.',
    region: 'US',
  },
  {
    symbol: 'NBN',
    name: 'Northeast Bank',
    region: 'US',
  },
  {
    symbol: 'NBNKF',
    name: 'Nordea Bank Abp',
    region: 'US',
  },
  {
    symbol: 'NBPBX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Advisor Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NBPIX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Institutional Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NBPTX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Trust Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NBR',
    name: 'Nabors Industries Ltd',
    region: 'US',
  },
  {
    symbol: 'NBRFX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Real Estate Fund; Trust Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NBRIX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Real Estate Fund; Institutional Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NBRV',
    name: 'Nabriva Therapeutics Plc',
    region: 'US',
  },
  {
    symbol: 'NBSE',
    name: 'NeuBase Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'NBST',
    name: 'Newbury Street Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'NBSTU',
    name: 'Newbury Street Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'NBTB',
    name: 'NBT Bancorp. Inc.',
    region: 'US',
  },
  {
    symbol: 'NBTX',
    name: 'Nanobiotix - ADR',
    region: 'US',
  },
  {
    symbol: 'NBY',
    name: 'Novabay Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'NC',
    name: 'Nacco Industries Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'NCAC',
    name: 'Newcourt Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NCACU',
    name: 'Newcourt Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'NCBDF',
    name: 'Bandai Namco Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'NCBDY',
    name: 'Bandai Namco Holdings Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'NCBS',
    name: 'Nicolet Bankshares Inc.',
    region: 'US',
  },
  {
    symbol: 'NCLH',
    name: 'Norwegian Cruise Line Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'NCLTF',
    name: 'Nitori Holdings Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'NCLTY',
    name: 'Nitori Holdings Co., Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'NCMI',
    name: 'National Cinemedia Inc',
    region: 'US',
  },
  {
    symbol: 'NCNA',
    name: 'NuCana plc - ADR',
    region: 'US',
  },
  {
    symbol: 'NCNO',
    name: 'Ncino Inc.',
    region: 'US',
  },
  {
    symbol: 'NCR',
    name: 'NCR Corp.',
    region: 'US',
  },
  {
    symbol: 'NCSM',
    name: 'NCS Multistage Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'NCTY',
    name: 'The9 Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'NDAC',
    name: 'NightDragon Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NDACU',
    name: 'NightDragon Acquisition Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'NDAQ',
    name: 'Nasdaq Inc - 144A',
    region: 'US',
  },
  {
    symbol: 'NDEKF',
    name: 'Nitto Denko Corporation',
    region: 'US',
  },
  {
    symbol: 'NDEKY',
    name: 'Nitto Denko Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'NDJI',
    name: 'ETF Series Solutions Trust - Nationwide Dow Jones Risk-Managed Income ETF',
    region: 'US',
  },
  {
    symbol: 'NDLS',
    name: 'Noodles & Company - Class A',
    region: 'US',
  },
  {
    symbol: 'NDRA',
    name: 'ENDRA Life Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'NDRBF',
    name: 'Nibe Industrier AB - Class B',
    region: 'US',
  },
  {
    symbol: 'NDSN',
    name: 'Nordson Corp.',
    region: 'US',
  },
  {
    symbol: 'NDVAX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVBX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVCX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVG',
    name: 'NuShares ETF Trust - Nuveen Dividend Growth ETF',
    region: 'US',
  },
  {
    symbol: 'NDVIX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVRX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVSX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVTX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVUX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDVVX',
    name: 'MFS Series Trust XIII: MFS New Discovery Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NDXKX',
    name: 'Shelton Funds: Nasdaq-100 Index Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NE',
    name: 'Noble Corp',
    region: 'US',
  },
  {
    symbol: 'NEAR',
    name: 'BlackRock Institutional Trust Company N.A. - BlackRock Short Maturity Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NECB',
    name: 'NorthEast Community Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'NEE',
    name: 'NextEra Energy Inc',
    region: 'US',
  },
  {
    symbol: 'NEFFX',
    name: 'New Economy Fund; Class F2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NEGG',
    name: 'Newegg Commerce Inc',
    region: 'US',
  },
  {
    symbol: 'NEIAX',
    name: 'Columbia Funds Series Trust: Columbia Large Cap Index Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NEM',
    name: 'Newmont Corp',
    region: 'US',
  },
  {
    symbol: 'NEMKY',
    name: 'Nemetschek SE - ADR',
    region: 'US',
  },
  {
    symbol: 'NEO',
    name: 'Neogenomics Inc.',
    region: 'US',
  },
  {
    symbol: 'NEOG',
    name: 'Neogen Corp.',
    region: 'US',
  },
  {
    symbol: 'NEON',
    name: 'Neonode Inc.',
    region: 'US',
  },
  {
    symbol: 'NEP',
    name: 'NextEra Energy Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'NEPH',
    name: 'Nephros Inc',
    region: 'US',
  },
  {
    symbol: 'NEPT',
    name: 'Neptune Wellness Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'NERD',
    name: 'Listed Funds Trust - Roundhill BITKRAFT Esports & Digital Entertainment ETF',
    region: 'US',
  },
  {
    symbol: 'NERV',
    name: 'Minerva Neurosciences Inc',
    region: 'US',
  },
  {
    symbol: 'NESR',
    name: 'National Energy Services Reunited Corp',
    region: 'US',
  },
  {
    symbol: 'NET',
    name: 'Cloudflare Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NETC',
    name: 'Nabors Energy Transition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NETI',
    name: 'Eneti Inc',
    region: 'US',
  },
  {
    symbol: 'NETL',
    name: 'ETF Series Solutions Trust - NETLease Corporate Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'NETZ',
    name: 'Engine No. 1 ETF Trust - Engine No. 1 Transform Climate ETF',
    region: 'US',
  },
  {
    symbol: 'NEU',
    name: 'NewMarket Corp.',
    region: 'US',
  },
  {
    symbol: 'NEW',
    name: 'Puxin Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'NEWP',
    name: 'New Pacific Metals Corp - New',
    region: 'US',
  },
  {
    symbol: 'NEWR',
    name: 'New Relic Inc',
    region: 'US',
  },
  {
    symbol: 'NEX',
    name: 'NexTier Oilfield Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'NEXA',
    name: 'Nexa Resources S.A.',
    region: 'US',
  },
  {
    symbol: 'NEXI',
    name: 'Neximmune Inc',
    region: 'US',
  },
  {
    symbol: 'NEXOF',
    name: 'Nexon Co Ltd',
    region: 'US',
  },
  {
    symbol: 'NEXOY',
    name: 'Nexon Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'NEXT',
    name: 'NextDecade Corporation',
    region: 'US',
  },
  {
    symbol: 'NFBK',
    name: 'Northfield Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'NFE',
    name: 'New Fortress Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NFG',
    name: 'National Fuel Gas Co.',
    region: 'US',
  },
  {
    symbol: 'NFGC',
    name: 'New Found Gold Corp',
    region: 'US',
  },
  {
    symbol: 'NFLT',
    name: 'Newfleet Asset Management - Virtus Newfleet Multi-Sector Unconstrained Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NFLX',
    name: 'NetFlix Inc',
    region: 'US',
  },
  {
    symbol: 'NFNT',
    name: 'Infinite Acquisition Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'NFPDF',
    name: 'Nissin Food Holdings Co Ltd',
    region: 'US',
  },
  {
    symbol: 'NFRA',
    name: 'FlexShares Trust - FlexShares STOXX Global Broad Infrastructure Index Fund',
    region: 'US',
  },
  {
    symbol: 'NFTY',
    name: 'First Trust Exchange-Traded Fund III - First Trust India Nifty 50 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'NFTZ',
    name: 'ETF Series Solutions Trust - Defiance Digital Revolution ETF',
    region: 'US',
  },
  {
    symbol: 'NFYS',
    name: 'Enphys Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NG',
    name: 'Novagold Resources Inc.',
    region: 'US',
  },
  {
    symbol: 'NGC',
    name: 'Northern Genesis Acquisition Corp III',
    region: 'US',
  },
  {
    symbol: 'NGD',
    name: 'New Gold Inc',
    region: 'US',
  },
  {
    symbol: 'NGE',
    name: 'Global X Funds - Global X MSCI Nigeria ETF',
    region: 'US',
  },
  {
    symbol: 'NGG',
    name: 'National Grid Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'NGL',
    name: 'NGL Energy Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'NGLOY',
    name: 'Anglo American plc - ADR',
    region: 'US',
  },
  {
    symbol: 'NGM',
    name: 'Ngm Biopharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'NGMS',
    name: 'NeoGames SA',
    region: 'US',
  },
  {
    symbol: 'NGS',
    name: 'Natural Gas Services Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'NGVC',
    name: 'Natural Grocers by Vitamin Cottage Inc',
    region: 'US',
  },
  {
    symbol: 'NGVT',
    name: 'Ingevity Corp',
    region: 'US',
  },
  {
    symbol: 'NH',
    name: 'NantHealth Inc',
    region: 'US',
  },
  {
    symbol: 'NHC',
    name: 'National Healthcare Corp.',
    region: 'US',
  },
  {
    symbol: 'NHIC',
    name: 'NewHold Investment Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'NHICU',
    name: 'NewHold Investment Corp II - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'NHMAF',
    name: 'Nihon M&A Center Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'NHNCF',
    name: 'Naver Co Ltd',
    region: 'US',
  },
  {
    symbol: 'NHOLF',
    name: 'Sompo Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'NHTC',
    name: 'Natural Health Trends Corp.',
    region: 'US',
  },
  {
    symbol: 'NHYDY',
    name: 'Norsk Hydro - ADR',
    region: 'US',
  },
  {
    symbol: 'NHYKF',
    name: 'Norsk Hydro',
    region: 'US',
  },
  {
    symbol: 'NI',
    name: 'Nisource Inc. (Holding Co.)',
    region: 'US',
  },
  {
    symbol: 'NICE',
    name: 'NICE Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'NIFE',
    name: 'Direxion Funds - Direxion Fallen Knives ETF',
    region: 'US',
  },
  {
    symbol: 'NILE',
    name: 'BitNile Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'NINAX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Intrinsic Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NINCX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Intrinsic Value Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NINDX',
    name: 'Columbia Funds Series Trust: Columbia Large Cap Index Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NINE',
    name: 'Nine Energy Service Inc',
    region: 'US',
  },
  {
    symbol: 'NINLX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Intrinsic Value Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NIO',
    name: 'NIO Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'NIPNF',
    name: 'NEC Corporation',
    region: 'US',
  },
  {
    symbol: 'NISN',
    name: 'NiSun International Enterprise Development Group Co Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'NIU',
    name: 'Niu Technologies - ADR',
    region: 'US',
  },
  {
    symbol: 'NJAN',
    name: 'Innovator ETFs Trust - Innovator Growth-100 Power Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'NJR',
    name: 'New Jersey Resources Corporation',
    region: 'US',
  },
  {
    symbol: 'NJUL',
    name: 'Innovator ETFs Trust - Innovator Growth-100 Power Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'NKE',
    name: 'Nike, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'NKLA',
    name: 'Nikola Corporation',
    region: 'US',
  },
  {
    symbol: 'NKSH',
    name: 'National Bankshares Inc.',
    region: 'US',
  },
  {
    symbol: 'NKTR',
    name: 'Nektar Therapeutics',
    region: 'US',
  },
  {
    symbol: 'NKTX',
    name: 'Nkarta Inc',
    region: 'US',
  },
  {
    symbol: 'NL',
    name: 'NL Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'NLIT',
    name: 'Northern Lights Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NLITU',
    name: 'Northern Lights Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'NLOK',
    name: 'NortonLifeLock Inc',
    region: 'US',
  },
  {
    symbol: 'NLR',
    name: 'VanEck ETF Trust - VanEck Uranium Nuclear Energy ETF',
    region: 'US',
  },
  {
    symbol: 'NLS',
    name: 'Nautilus Inc',
    region: 'US',
  },
  {
    symbol: 'NLSN',
    name: 'Nielsen Holdings plc',
    region: 'US',
  },
  {
    symbol: 'NLSP',
    name: 'NLS Pharmaceutics Ltd',
    region: 'US',
  },
  {
    symbol: 'NLTX',
    name: 'Neoleukin Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'NM',
    name: 'Navios Maritime Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'NMG',
    name: 'Nouveau Monde Graphite Inc',
    region: 'US',
  },
  {
    symbol: 'NMIH',
    name: 'NMI Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NMM',
    name: 'Navios Maritime Partners L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'NMMC',
    name: 'North Mountain Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NMMCU',
    name: 'North Mountain Merger Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'NMR',
    name: 'Nomura Holdings Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'NMRD',
    name: 'Nemaura Medical Inc',
    region: 'US',
  },
  {
    symbol: 'NMTC',
    name: 'NeuroOne Medical Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'NMTR',
    name: '9 Meters Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'NN',
    name: 'NextNav Inc',
    region: 'US',
  },
  {
    symbol: 'NNBR',
    name: 'NN Inc',
    region: 'US',
  },
  {
    symbol: 'NNCHY',
    name: 'Nissan Chemical Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'NNDM',
    name: 'Nano Dimension Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'NNDNF',
    name: 'Nidec Corporation',
    region: 'US',
  },
  {
    symbol: 'NNGPF',
    name: 'NN Group NV',
    region: 'US',
  },
  {
    symbol: 'NNGRY',
    name: 'NN Group NV - ADR',
    region: 'US',
  },
  {
    symbol: 'NNOX',
    name: 'Nano X Imaging Ltd',
    region: 'US',
  },
  {
    symbol: 'NNVC',
    name: 'NanoViricides Inc',
    region: 'US',
  },
  {
    symbol: 'NNYAF',
    name: 'Nanya Technology Corporation',
    region: 'US',
  },
  {
    symbol: 'NOA',
    name: 'North American Construction Group Ltd',
    region: 'US',
  },
  {
    symbol: 'NOAC',
    name: 'Natural Order Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'NOACU',
    name: 'Natural Order Acquisition Corp - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'NOBL',
    name: 'ProShares Trust - ProShares S&P 500 Dividend Aristocrats ETF',
    region: 'US',
  },
  {
    symbol: 'NOC',
    name: 'Northrop Grumman Corp.',
    region: 'US',
  },
  {
    symbol: 'NOCT',
    name: 'Innovator ETFs Trust - Innovator Growth-100 Power Buffer ETF- October',
    region: 'US',
  },
  {
    symbol: 'NODK',
    name: 'NI Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'NOG',
    name: 'Northern Oil and Gas Inc.',
    region: 'US',
  },
  {
    symbol: 'NOK',
    name: 'Nokia Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'NOMD',
    name: 'Nomad Foods Limited',
    region: 'US',
  },
  {
    symbol: 'NORW',
    name: 'Global X Funds - Global X MSCI Norway ETF',
    region: 'US',
  },
  {
    symbol: 'NOTV',
    name: 'Inotiv Inc',
    region: 'US',
  },
  {
    symbol: 'NOV',
    name: 'NOV Inc',
    region: 'US',
  },
  {
    symbol: 'NOVA',
    name: 'Sunnova Energy International Inc',
    region: 'US',
  },
  {
    symbol: 'NOVN',
    name: 'Novan Inc',
    region: 'US',
  },
  {
    symbol: 'NOVT',
    name: 'Novanta Inc',
    region: 'US',
  },
  {
    symbol: 'NOVV',
    name: 'Nova Vision Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'NOVVU',
    name: 'Nova Vision Acquisition Corp - Unit (1 Ordinary share , 1 Wrt & 1 Rts)',
    region: 'US',
  },
  {
    symbol: 'NOVZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (November) ETF',
    region: 'US',
  },
  {
    symbol: 'NOW',
    name: 'ServiceNow Inc',
    region: 'US',
  },
  {
    symbol: 'NP',
    name: 'Neenah Inc',
    region: 'US',
  },
  {
    symbol: 'NPAB',
    name: 'New Providence Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'NPABU',
    name: 'New Providence Acquisition Corp II - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'NPCE',
    name: 'NeuroPace Inc',
    region: 'US',
  },
  {
    symbol: 'NPCPF',
    name: 'Nippon Paint Holdings Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'NPK',
    name: 'National Presto Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'NPNAX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NPNCX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NPNRX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NPO',
    name: 'EnPro Industries Inc',
    region: 'US',
  },
  {
    symbol: 'NPRTX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Investor Class',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NPTN',
    name: 'NeoPhotonics Corporation',
    region: 'US',
  },
  {
    symbol: 'NR',
    name: 'Newpark Resources, Inc.',
    region: 'US',
  },
  {
    symbol: 'NRAC',
    name: 'Noble Rock Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NRACU',
    name: 'Noble Rock Acquisition Corp - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'NRBO',
    name: 'NeuroBo Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'NRC',
    name: 'National Research Corp',
    region: 'US',
  },
  {
    symbol: 'NRDBY',
    name: 'Nordea Bank Abp - ADR',
    region: 'US',
  },
  {
    symbol: 'NRDS',
    name: 'Nerdwallet Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NRDY',
    name: 'Nerdy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NREAX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Real Estate Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NRECX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Real Estate Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NRERX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Real Estate Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NRG',
    name: 'NRG Energy Inc.',
    region: 'US',
  },
  {
    symbol: 'NRGV',
    name: 'Energy Vault Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'NRILY',
    name: 'Nomura Research Institute Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'NRIM',
    name: 'Northrim Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'NRINX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Intrinsic Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NRIX',
    name: 'Nurix Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'NRLCX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Large Cap Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NRP',
    name: 'Natural Resource Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'NRREX',
    name: 'Neuberger Berman Equity Funds: Neuberger Berman Real Estate Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NRSN',
    name: 'NeuroSense Therapeutics Ltd',
    region: 'US',
  },
  {
    symbol: 'NRXP',
    name: 'NRX Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'NS',
    name: 'Nustar Energy L P',
    region: 'US',
  },
  {
    symbol: 'NSC',
    name: 'Norfolk Southern Corp.',
    region: 'US',
  },
  {
    symbol: 'NSCS',
    name: 'NuShares ETF Trust - Nuveen Small Cap Select ETF',
    region: 'US',
  },
  {
    symbol: 'NSEC',
    name: 'National Security Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'NSEPX',
    name: 'Columbia Funds Series Trust: Columbia Select Large Cap Equity Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NSGAX',
    name: 'Columbia Funds Series Trust: Columbia Select Large Cap Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NSIT',
    name: 'Insight Enterprises Inc.',
    region: 'US',
  },
  {
    symbol: 'NSLYF',
    name: 'Nestle (Malaysia) Bhd',
    region: 'US',
  },
  {
    symbol: 'NSP',
    name: 'Insperity Inc',
    region: 'US',
  },
  {
    symbol: 'NSPI',
    name: 'ETF Series Solutions Trust - Nationwide S&P 500 Risk-Managed Income ETF',
    region: 'US',
  },
  {
    symbol: 'NSPR',
    name: 'InspireMD Inc',
    region: 'US',
  },
  {
    symbol: 'NSR',
    name: 'Nomad Royalty Company Ltd',
    region: 'US',
  },
  {
    symbol: 'NSSC',
    name: 'NAPCO Security Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'NSTB',
    name: 'Northern Star Investment Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'NSTC',
    name: 'Northern Star Investment Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'NSTD',
    name: 'Northern Star Investment Corp IV - Class A',
    region: 'US',
  },
  {
    symbol: 'NSTG',
    name: 'Nanostring Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'NSTS',
    name: 'NSTS Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'NSVAX',
    name: 'Columbia Funds Series Trust: Columbia Small Cap Value Fund II; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NSYS',
    name: 'Nortech Systems Inc.',
    region: 'US',
  },
  {
    symbol: 'NTAP',
    name: 'Netapp Inc',
    region: 'US',
  },
  {
    symbol: 'NTB',
    name: 'Bank of N T Butterfield & Son Ltd.',
    region: 'US',
  },
  {
    symbol: 'NTCO',
    name: 'Natura &Co Holding S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'NTCT',
    name: 'Netscout Systems Inc',
    region: 'US',
  },
  {
    symbol: 'NTDOF',
    name: 'Nintendo Co. Ltd',
    region: 'US',
  },
  {
    symbol: 'NTDOY',
    name: 'Nintendo Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'NTDTY',
    name: 'NTT Data Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'NTES',
    name: 'NetEase Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'NTGR',
    name: 'Netgear Inc',
    region: 'US',
  },
  {
    symbol: 'NTIC',
    name: 'Northern Technologies International Corp.',
    region: 'US',
  },
  {
    symbol: 'NTIP',
    name: 'Network-1 Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'NTKI',
    name: 'ETF Series Solutions Trust - Nationwide Russell 2000 Risk-Managed Income ETF',
    region: 'US',
  },
  {
    symbol: 'NTLA',
    name: 'Intellia Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'NTNX',
    name: 'Nutanix Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NTOIF',
    name: 'Neste Corporation',
    region: 'US',
  },
  {
    symbol: 'NTOIY',
    name: 'Neste Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'NTP',
    name: 'Nam Tai Property Inc',
    region: 'US',
  },
  {
    symbol: 'NTR',
    name: 'Nutrien Ltd',
    region: 'US',
  },
  {
    symbol: 'NTRA',
    name: 'Natera Inc',
    region: 'US',
  },
  {
    symbol: 'NTRB',
    name: 'Nutriband Inc',
    region: 'US',
  },
  {
    symbol: 'NTRS',
    name: 'Northern Trust Corp.',
    region: 'US',
  },
  {
    symbol: 'NTSE',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets Efficient Core Fund',
    region: 'US',
  },
  {
    symbol: 'NTSI',
    name: 'WisdomTree Trust - WisdomTree International Efficient Core Fund',
    region: 'US',
  },
  {
    symbol: 'NTSX',
    name: 'WisdomTree Trust - WisdomTree U.S. Efficient Core Fund',
    region: 'US',
  },
  {
    symbol: 'NTTDF',
    name: 'NTT Data Corporation',
    region: 'US',
  },
  {
    symbol: 'NTUS',
    name: 'Natus Medical Inc',
    region: 'US',
  },
  {
    symbol: 'NTWK',
    name: 'Netsol Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'NTZ',
    name: 'Natuzzi S.P.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'NTZO',
    name: 'Impact Shares Trust I - Impact Shares MSCI Global Climate Select ETF',
    region: 'US',
  },
  {
    symbol: 'NUAG',
    name: 'Nuveen Fund Advisors LLC - NuShares Enhanced Yield U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NUBD',
    name: 'Nuveen Fund Advisors LLC - NuShares ESG U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NUBIU',
    name: 'Nubia Brand International Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'NUDM',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG International Developed Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'NUDV',
    name: 'NuShares ETF Trust - Nuveen ESG Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'NUE',
    name: 'Nucor Corp.',
    region: 'US',
  },
  {
    symbol: 'NUEM',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'NUGO',
    name: 'NuShares ETF Trust - Nuveen Growth Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'NUGT',
    name: 'Direxion Shares ETF Trust - Direxion Daily Gold Miners Index Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'NUHY',
    name: 'NuShares ETF Trust - Nuveen ESG High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NULC',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Large-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'NULG',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Large-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'NULV',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Large-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'NUMG',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Mid-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'NUMV',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Mid-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'NURAF',
    name: 'Nomura Research Institute Ltd.',
    region: 'US',
  },
  {
    symbol: 'NURE',
    name: 'Nuveen Fund Advisors LLC - Nuveen Short-Term REIT ETF',
    region: 'US',
  },
  {
    symbol: 'NURO',
    name: 'Neurometrix Inc.',
    region: 'US',
  },
  {
    symbol: 'NUS',
    name: 'Nu Skin Enterprises, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'NUSA',
    name: 'Nuveen Fund Advisors LLC - NuShares Enhanced Yield 1-5 Year U.S. Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NUSC',
    name: 'Nuveen Fund Advisors LLC - Nuveen ESG Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'NUSI',
    name: 'ETF Series Solutions Trust - Nationwide Nasdaq-100 Risk-Managed Income ETF',
    region: 'US',
  },
  {
    symbol: 'NUVA',
    name: 'Nuvasive Inc',
    region: 'US',
  },
  {
    symbol: 'NUVB',
    name: 'Nuvation Bio Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NUVL',
    name: 'Nuvalent Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NUWE',
    name: 'Nuwellis Inc',
    region: 'US',
  },
  {
    symbol: 'NUZE',
    name: 'Nuzee Inc',
    region: 'US',
  },
  {
    symbol: 'NVAC',
    name: 'NorthView Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'NVAX',
    name: 'Novavax, Inc.',
    region: 'US',
  },
  {
    symbol: 'NVCN',
    name: 'Neovasc Inc',
    region: 'US',
  },
  {
    symbol: 'NVCR',
    name: 'NovoCure Ltd',
    region: 'US',
  },
  {
    symbol: 'NVCT',
    name: 'Nuvectis Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'NVDA',
    name: 'NVIDIA Corp',
    region: 'US',
  },
  {
    symbol: 'NVEC',
    name: 'NVE Corp',
    region: 'US',
  },
  {
    symbol: 'NVEE',
    name: 'NV5 Global Inc',
    region: 'US',
  },
  {
    symbol: 'NVEI',
    name: 'Nuvei Corporation (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'NVFY',
    name: 'Nova Lifestyle Inc',
    region: 'US',
  },
  {
    symbol: 'NVGS',
    name: 'Navigator Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'NVIV',
    name: 'InVivo Therapeutics Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'NVLIX',
    name: 'Nuveen Investment Trust II: Nuveen Winslow Large-Cap Growth ESG Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NVMI',
    name: 'Nova Ltd',
    region: 'US',
  },
  {
    symbol: 'NVNO',
    name: 'enVVeno Medical Corporation',
    region: 'US',
  },
  {
    symbol: 'NVO',
    name: 'Novo Nordisk - ADR',
    region: 'US',
  },
  {
    symbol: 'NVOS',
    name: 'Novo Integrated Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'NVQ',
    name: 'Exchange Listed Funds Trust - QRAFT AI-Enhanced U.S. Next Value ETF',
    region: 'US',
  },
  {
    symbol: 'NVR',
    name: 'NVR Inc.',
    region: 'US',
  },
  {
    symbol: 'NVRO',
    name: 'Nevro Corp',
    region: 'US',
  },
  {
    symbol: 'NVS',
    name: 'Novartis AG - ADR',
    region: 'US',
  },
  {
    symbol: 'NVSA',
    name: 'New Vista Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NVSAU',
    name: 'New Vista Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'NVST',
    name: 'Envista Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'NVT',
    name: 'nVent Electric plc',
    region: 'US',
  },
  {
    symbol: 'NVTA',
    name: 'Invitae Corp',
    region: 'US',
  },
  {
    symbol: 'NVTS',
    name: 'Navitas Semiconductor Corp',
    region: 'US',
  },
  {
    symbol: 'NVVE',
    name: 'Nuvve Holding Corp',
    region: 'US',
  },
  {
    symbol: 'NVX',
    name: 'Novonix Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'NVZMF',
    name: 'Novozymes AS - Class B',
    region: 'US',
  },
  {
    symbol: 'NVZMY',
    name: 'Novozymes AS - ADR',
    region: 'US',
  },
  {
    symbol: 'NWABX',
    name: 'Nationwide Mutual Funds: Nationwide Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NWBI',
    name: 'Northwest Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'NWCAX',
    name: 'Nuveen Investment Trust II: Nuveen Winslow Large-Cap Growth ESG Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NWCFX',
    name: 'Nuveen Investment Trust II: Nuveen Winslow Large-Cap Growth ESG Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NWE',
    name: 'Northwestern Corp.',
    region: 'US',
  },
  {
    symbol: 'NWFL',
    name: 'Norwood Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'NWG',
    name: 'NatWest Group Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'NWHZX',
    name: 'Nationwide Mutual Funds: Nationwide Geneva Small Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NWKDX',
    name: 'Nationwide Mutual Funds: Nationwide Geneva Small Cap Growth Fund; Institutional Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'NWL',
    name: 'Newell Brands Inc',
    region: 'US',
  },
  {
    symbol: 'NWLG',
    name: 'NuShares ETF Trust - Nuveen Winslow Large-Cap Growth ESG ETF',
    region: 'US',
  },
  {
    symbol: 'NWLI',
    name: 'National Western Life Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NWN',
    name: 'Northwest Natural Holding Co',
    region: 'US',
  },
  {
    symbol: 'NWPX',
    name: 'Northwest Pipe Co.',
    region: 'US',
  },
  {
    symbol: 'NWS',
    name: 'News Corp - Class B',
    region: 'US',
  },
  {
    symbol: 'NWSA',
    name: 'News Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'NX',
    name: 'Quanex Building Products Corp',
    region: 'US',
  },
  {
    symbol: 'NXE',
    name: 'NexGen Energy Ltd',
    region: 'US',
  },
  {
    symbol: 'NXGL',
    name: 'Nexgel Inc',
    region: 'US',
  },
  {
    symbol: 'NXGN',
    name: 'NextGen Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'NXPI',
    name: 'NXP Semiconductors NV',
    region: 'US',
  },
  {
    symbol: 'NXPL',
    name: 'NextPlat Corp',
    region: 'US',
  },
  {
    symbol: 'NXST',
    name: 'Nexstar Media Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'NXTC',
    name: 'Nextcure Inc',
    region: 'US',
  },
  {
    symbol: 'NXTG',
    name: 'First Trust Exchange-Traded Fund III - First Trust Indxx NextG ETF',
    region: 'US',
  },
  {
    symbol: 'NXTP',
    name: 'NextPlay Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'NYCB',
    name: 'New York Community Bancorp Inc.',
    region: 'US',
  },
  {
    symbol: 'NYF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares New York Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'NYMX',
    name: 'Nymox Pharmaceutical Corp',
    region: 'US',
  },
  {
    symbol: 'NYT',
    name: 'New York Times Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'NYXH',
    name: 'Nyxoah SA',
    region: 'US',
  },
  {
    symbol: 'NZAC',
    name: 'SSgA Active Trust - SPDR MSCI ACWI Climate Paris Aligned ETF',
    region: 'US',
  },
  {
    symbol: 'NZRO',
    name: 'Strategy Shares - Strategy Shares Halt Climate Change ETF',
    region: 'US',
  },
  {
    symbol: 'NZUS',
    name: 'SPDR Series Trust - SPDR MSCI USA Climate Paris Aligned ETF',
    region: 'US',
  },
  {
    symbol: 'OACB',
    name: 'Oaktree Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'OACP',
    name: 'Unified Series Trust - OneAscent Core Plus Bond ETF',
    region: 'US',
  },
  {
    symbol: 'OALC',
    name: 'Unified Series Trust - OneAscent Large Cap Core ETF',
    region: 'US',
  },
  {
    symbol: 'OAS',
    name: 'Oasis Petroleum Inc. - New',
    region: 'US',
  },
  {
    symbol: 'OB',
    name: 'Outbrain Inc',
    region: 'US',
  },
  {
    symbol: 'OBCI',
    name: 'Ocean Bio-Chem, Inc.',
    region: 'US',
  },
  {
    symbol: 'OBE',
    name: 'Obsidian Energy Ltd',
    region: 'US',
  },
  {
    symbol: 'OBIIF',
    name: 'Obic Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'OBIIX',
    name: 'Oberweis Funds: Oberweis International Opportunities Institutional Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OBLG',
    name: 'Oblong Inc',
    region: 'US',
  },
  {
    symbol: 'OBND',
    name: 'SSgA Active Trust - SPDR Loomis Sayles Opportunistic Bond ETF',
    region: 'US',
  },
  {
    symbol: 'OBNK',
    name: 'Origin Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'OBOR',
    name: 'Krane Shares Trust - KraneShares MSCI One Belt One Road Index ETF',
    region: 'US',
  },
  {
    symbol: 'OBSV',
    name: 'ObsEva SA.',
    region: 'US',
  },
  {
    symbol: 'OBT',
    name: 'Orange County Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'OC',
    name: 'Owens Corning',
    region: 'US',
  },
  {
    symbol: 'OCA',
    name: 'Omnichannel Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OCAX',
    name: 'OCA Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OCAXU',
    name: 'OCA Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'OCC',
    name: 'Optical Cable Corp.',
    region: 'US',
  },
  {
    symbol: 'OCDX',
    name: 'Ortho Clinical Diagnostics Holdings plc',
    region: 'US',
  },
  {
    symbol: 'OCEN',
    name: 'IndexIQ ETF Trust - IQ Clean Oceans ETF',
    region: 'US',
  },
  {
    symbol: 'OCFC',
    name: 'OceanFirst Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'OCFT',
    name: 'Oneconnect Financial Technology Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'OCG',
    name: 'Oriental Culture Holding Ltd',
    region: 'US',
  },
  {
    symbol: 'OCGN',
    name: 'Ocugen Inc',
    region: 'US',
  },
  {
    symbol: 'OCIO',
    name: 'ETF Series Solutions Trust - ClearShares OCIO ETF',
    region: 'US',
  },
  {
    symbol: 'OCLCF',
    name: 'Oracle Corporation Japan',
    region: 'US',
  },
  {
    symbol: 'OCN',
    name: 'Ocwen Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'OCTZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (October) ETF',
    region: 'US',
  },
  {
    symbol: 'OCUL',
    name: 'Ocular Therapeutix Inc',
    region: 'US',
  },
  {
    symbol: 'OCUP',
    name: 'Ocuphire Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'OCX',
    name: 'Oncocyte Corporation',
    region: 'US',
  },
  {
    symbol: 'ODC',
    name: 'Oil-Dri Corp. Of America',
    region: 'US',
  },
  {
    symbol: 'ODDS',
    name: 'Pacer Funds Trust - Pacer BlueStar Digital Entertainment ETF',
    region: 'US',
  },
  {
    symbol: 'ODFL',
    name: 'Old Dominion Freight Line, Inc.',
    region: 'US',
  },
  {
    symbol: 'ODICX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco Discovery Fund; Class C Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ODIIX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco Discovery Fund; Class R6 Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ODINX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco Discovery Fund; Class R Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ODIYX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco Discovery Fund; Class Y Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ODP',
    name: 'ODP Corporation (The)',
    region: 'US',
  },
  {
    symbol: 'OEC',
    name: 'Orion Engineered Carbons S.A.',
    region: 'US',
  },
  {
    symbol: 'OEF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares S&P 100 ETF',
    region: 'US',
  },
  {
    symbol: 'OEG',
    name: 'Orbital Energy Group Inc',
    region: 'US',
  },
  {
    symbol: 'OEGAX',
    name: 'AIM Investment Funds (Invesco Investment Funds): Invesco Discovery Mid Cap Growth Fd; Class A Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OEGCX',
    name: 'AIM Investment Funds (Invesco Investment Funds): Invesco Discovery Mid Cap Growth Fd; Class C Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OEGIX',
    name: 'AIM Investment Funds (Invesco Investment Funds): Invesco Discovery Mid Cap Growth Fd; Class R6 Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OEGNX',
    name: 'AIM Investment Funds (Invesco Investment Funds): Invesco Discovery Mid Cap Growth Fd; Class R Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OEGYX',
    name: 'AIM Investment Funds (Invesco Investment Funds): Invesco Discovery Mid Cap Growth Fd; Class Y Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OEPW',
    name: 'One Equity Partners Open Water I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OEPWU',
    name: 'One Equity Partners Open Water I Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'OESX',
    name: 'Orion Energy Systems Inc',
    region: 'US',
  },
  {
    symbol: 'OEUR',
    name: 'OSI ETF Trust - OShares Europe Quality Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'OFED',
    name: 'Oconee Federal Financial Corp',
    region: 'US',
  },
  {
    symbol: 'OFG',
    name: 'OFG Bancorp',
    region: 'US',
  },
  {
    symbol: 'OFIX',
    name: 'Orthofix Medical Inc',
    region: 'US',
  },
  {
    symbol: 'OFLX',
    name: 'Omega Flex Inc',
    region: 'US',
  },
  {
    symbol: 'OG',
    name: 'Onion Global Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'OGE',
    name: 'Oge Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'OGEN',
    name: 'Oragenics Inc',
    region: 'US',
  },
  {
    symbol: 'OGI',
    name: 'OrganiGram Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'OGIG',
    name: 'OSI ETF Trust - O`Shares Global Internet Giants ETF',
    region: 'US',
  },
  {
    symbol: 'OGN',
    name: 'Organon & Co.',
    region: 'US',
  },
  {
    symbol: 'OGS',
    name: 'ONE Gas Inc',
    region: 'US',
  },
  {
    symbol: 'OHAA',
    name: 'OPY Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'OHAAU',
    name: 'OPY Acquisition Corp I - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'OHPA',
    name: 'Orion Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OHPAU',
    name: 'Orion Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'OI',
    name: 'O-I Glass Inc',
    region: 'US',
  },
  {
    symbol: 'OIH',
    name: 'VanEck ETF Trust - VanEck Oil Services ETF',
    region: 'US',
  },
  {
    symbol: 'OII',
    name: 'Oceaneering International, Inc.',
    region: 'US',
  },
  {
    symbol: 'OIIM',
    name: 'O2 Micro International - ADR',
    region: 'US',
  },
  {
    symbol: 'OILK',
    name: 'ProShares Trust - ProShares K-1 Free Crude Oil Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'OIS',
    name: 'Oil States International, Inc.',
    region: 'US',
  },
  {
    symbol: 'OJSCY',
    name: 'PJSC Cherkizovo Group - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'OKE',
    name: 'Oneok Inc.',
    region: 'US',
  },
  {
    symbol: 'OKTA',
    name: 'Okta Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OLB',
    name: 'OLB Group Inc',
    region: 'US',
  },
  {
    symbol: 'OLCLF',
    name: 'Oriental Land Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'OLCLY',
    name: 'Oriental Land Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'OLED',
    name: 'Universal Display Corp.',
    region: 'US',
  },
  {
    symbol: 'OLIT',
    name: 'OmniLit Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OLITU',
    name: 'OmniLit Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'OLK',
    name: 'Olink Holding AB (publ) - ADR',
    region: 'US',
  },
  {
    symbol: 'OLLI',
    name: 'Ollies Bargain Outlet Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'OLMA',
    name: 'Olema Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'OLN',
    name: 'Olin Corp.',
    region: 'US',
  },
  {
    symbol: 'OLO',
    name: 'Olo Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OLPX',
    name: 'Olaplex Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'OM',
    name: 'Outset Medical Inc',
    region: 'US',
  },
  {
    symbol: 'OMAB',
    name: 'Grupo Aeroportuario del Centro Norte S.A.B de C.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'OMC',
    name: 'Omnicom Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'OMCL',
    name: 'Omnicell, Inc.',
    region: 'US',
  },
  {
    symbol: 'OMEG',
    name: 'Omega Alpha SPAC - Class A',
    region: 'US',
  },
  {
    symbol: 'OMER',
    name: 'Omeros Corporation',
    region: 'US',
  },
  {
    symbol: 'OMEX',
    name: 'Odyssey Marine Exploration, Inc.',
    region: 'US',
  },
  {
    symbol: 'OMFL',
    name: 'Invesco Capital Management LLC - Invesco Russell 1000 Dynamic Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'OMFS',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco Russell 2000 Dynamic Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'OMGA',
    name: 'Omega Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'OMI',
    name: 'Owens & Minor, Inc.',
    region: 'US',
  },
  {
    symbol: 'OMIC',
    name: 'Singular Genomics Systems Inc',
    region: 'US',
  },
  {
    symbol: 'OMQS',
    name: 'OMNIQ Corp',
    region: 'US',
  },
  {
    symbol: 'ON',
    name: 'ON Semiconductor Corp.',
    region: 'US',
  },
  {
    symbol: 'ONB',
    name: 'Old National Bancorp',
    region: 'US',
  },
  {
    symbol: 'ONCR',
    name: 'Oncorus Inc',
    region: 'US',
  },
  {
    symbol: 'ONCS',
    name: 'OncoSec Medical Inc',
    region: 'US',
  },
  {
    symbol: 'ONCT',
    name: 'Oncternal Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ONCY',
    name: 'Oncolytics Biotech, Inc.',
    region: 'US',
  },
  {
    symbol: 'OND',
    name: 'ProShares Trust - ProShares On-Demand ETF',
    region: 'US',
  },
  {
    symbol: 'ONDS',
    name: 'Ondas Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ONE',
    name: 'OneSmart International Education Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ONEM',
    name: '1life Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'ONEO',
    name: 'SPDR Series Trust - SPDR Russell 1000 Momentum Focus ETF',
    region: 'US',
  },
  {
    symbol: 'ONEQ',
    name: 'Fidelity Covington Trust - Fidelity Nasdaq Composite Index ETF',
    region: 'US',
  },
  {
    symbol: 'ONEV',
    name: 'SPDR Series Trust - SPDR Russell 1000 Low Volatility Focus ETF',
    region: 'US',
  },
  {
    symbol: 'ONEW',
    name: 'Onewater Marine Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ONEY',
    name: 'SPDR Series Trust - SPDR Russell 1000 Yield Focus ETF',
    region: 'US',
  },
  {
    symbol: 'ONG',
    name: 'Direxion Shares ETF Trust - Direxion Daily Oil Services Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'ONLN',
    name: 'ProShares Trust - ProShares Online Retail ETF',
    region: 'US',
  },
  {
    symbol: 'ONOF',
    name: 'Global X Funds - Global X Adaptive U.S. Risk Management ETF',
    region: 'US',
  },
  {
    symbol: 'ONON',
    name: 'On Holding AG Class A',
    region: 'US',
  },
  {
    symbol: 'ONTF',
    name: 'ON24 Inc',
    region: 'US',
  },
  {
    symbol: 'ONTO',
    name: 'Onto Innovation Inc.',
    region: 'US',
  },
  {
    symbol: 'ONTX',
    name: 'Onconova Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'ONVO',
    name: 'Organovo Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ONYX',
    name: 'Onyx Acquisition Co I - Class A',
    region: 'US',
  },
  {
    symbol: 'ONYXU',
    name: 'Onyx Acquisition Co I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'OOMA',
    name: 'Ooma Inc',
    region: 'US',
  },
  {
    symbol: 'OOTO',
    name: 'Direxion Funds - Direxion Daily Travel & Vacation Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'OP',
    name: 'OceanPal Inc',
    region: 'US',
  },
  {
    symbol: 'OPA',
    name: 'Magnum Opus Acquisition Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'OPAD',
    name: 'Offerpad Solutions Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OPBK',
    name: 'OP Bancorp',
    region: 'US',
  },
  {
    symbol: 'OPCH',
    name: 'Option Care Health Inc. - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'OPER',
    name: 'ETF Series Solutions Trust - ClearShares Ultra-Short Maturity ETF',
    region: 'US',
  },
  {
    symbol: 'OPFI',
    name: 'OppFi Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OPGN',
    name: 'Opgen Inc',
    region: 'US',
  },
  {
    symbol: 'OPHC',
    name: 'Optimumbank Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'OPHLF',
    name: 'Ono Pharmaceutical Co. Ltd',
    region: 'US',
  },
  {
    symbol: 'OPHLY',
    name: 'Ono Pharmaceutical Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'OPK',
    name: 'Opko Health Inc',
    region: 'US',
  },
  {
    symbol: 'OPNT',
    name: 'Opiant Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'OPOCX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco Discovery Fund; Class A Shs',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OPOF',
    name: 'Old Point Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'OPPX',
    name: 'Exchange Listed Funds Trust - Corbett Road Tactical Opportunity ETF',
    region: 'US',
  },
  {
    symbol: 'OPRA',
    name: 'Opera Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'OPRT',
    name: 'Oportun Financial Corp',
    region: 'US',
  },
  {
    symbol: 'OPRX',
    name: 'OptimizeRx Corp',
    region: 'US',
  },
  {
    symbol: 'OPT',
    name: 'Opthea Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'OPTN',
    name: 'OptiNose Inc',
    region: 'US',
  },
  {
    symbol: 'OPTT',
    name: 'Ocean Power Technologies - Reg S',
    region: 'US',
  },
  {
    symbol: 'OR',
    name: 'Osisko Gold Royalties Ltd',
    region: 'US',
  },
  {
    symbol: 'ORA',
    name: 'Ormat Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'ORAN',
    name: 'Orange. - ADR',
    region: 'US',
  },
  {
    symbol: 'ORCL',
    name: 'Oracle Corp.',
    region: 'US',
  },
  {
    symbol: 'ORGN',
    name: 'Origin Materials Inc',
    region: 'US',
  },
  {
    symbol: 'ORGO',
    name: 'Organogenesis Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ORGS',
    name: 'Orgenesis Inc',
    region: 'US',
  },
  {
    symbol: 'ORI',
    name: 'Old Republic International Corp.',
    region: 'US',
  },
  {
    symbol: 'ORIA',
    name: 'Orion Biotech Opportunities Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ORIAU',
    name: 'Orion Biotech Opportunities Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'ORIC',
    name: 'ORIC Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ORLA',
    name: 'Orla Mining Ltd',
    region: 'US',
  },
  {
    symbol: 'ORLY',
    name: 'O`Reilly Automotive, Inc.',
    region: 'US',
  },
  {
    symbol: 'ORMP',
    name: 'Oramed Pharmaceuticals, Inc',
    region: 'US',
  },
  {
    symbol: 'ORN',
    name: 'Orion Group Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ORPEF',
    name: 'Orpea',
    region: 'US',
  },
  {
    symbol: 'ORRF',
    name: 'Orrstown Financial Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'ORRRY',
    name: 'Orpea - ADR',
    region: 'US',
  },
  {
    symbol: 'ORTX',
    name: 'Orchard Therapeutics plc - ADR',
    region: 'US',
  },
  {
    symbol: 'OSBC',
    name: 'Old Second Bancorporation Inc.',
    region: 'US',
  },
  {
    symbol: 'OSCR',
    name: 'Oscar Health Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OSCUF',
    name: 'Japan Exchange Group Inc.',
    region: 'US',
  },
  {
    symbol: 'OSCV',
    name: 'ETF Series Solutions Trust - Opus Small Cap Value Plus ETF',
    region: 'US',
  },
  {
    symbol: 'OSG',
    name: 'Overseas Shipholding Group, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'OSH',
    name: 'Oak Street Health Inc',
    region: 'US',
  },
  {
    symbol: 'OSI',
    name: 'Osiris Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OSIS',
    name: 'OSI Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'OSK',
    name: 'Oshkosh Corp',
    region: 'US',
  },
  {
    symbol: 'OSPN',
    name: 'OneSpan Inc',
    region: 'US',
  },
  {
    symbol: 'OSS',
    name: 'One Stop Systems Inc',
    region: 'US',
  },
  {
    symbol: 'OST',
    name: 'Ostin Technology Group Co Ltd',
    region: 'US',
  },
  {
    symbol: 'OSTK',
    name: 'Overstock.com Inc',
    region: 'US',
  },
  {
    symbol: 'OSTR',
    name: 'Oyster Enterprises Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OSTRU',
    name: 'Oyster Enterprises Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'OSUKF',
    name: 'Otsuka Corp.',
    region: 'US',
  },
  {
    symbol: 'OSUR',
    name: 'Orasure Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'OSW',
    name: 'OneSpaWorld Holdings Limited',
    region: 'US',
  },
  {
    symbol: 'OTCAX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCBX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCCX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCFX',
    name: 'T Rowe Price Small-Cap Stock Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCGX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCHX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCIX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCJX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTCKX',
    name: 'MFS Series Trust IV: MFS Mid Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'OTEC',
    name: 'OceanTech Acquisitions I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OTECU',
    name: 'OceanTech Acquisitions I Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'OTEX',
    name: 'Open Text Corp',
    region: 'US',
  },
  {
    symbol: 'OTIC',
    name: 'Otonomy Inc',
    region: 'US',
  },
  {
    symbol: 'OTIS',
    name: 'Otis Worldwide Corp',
    region: 'US',
  },
  {
    symbol: 'OTLK',
    name: 'Outlook Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'OTLY',
    name: 'Oatly Group AB - ADR',
    region: 'US',
  },
  {
    symbol: 'OTMO',
    name: 'Otonomo Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'OTPBF',
    name: 'OTP Bank',
    region: 'US',
  },
  {
    symbol: 'OTPGF',
    name: 'OTP Bank - GDR - Reg S',
    region: 'US',
  },
  {
    symbol: 'OTPIY',
    name: 'OTP Bank - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'OTRA',
    name: 'OTR Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OTRAU',
    name: 'OTR Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'OTRK',
    name: 'Ontrak Inc',
    region: 'US',
  },
  {
    symbol: 'OTSKF',
    name: 'Otsuka Holdings Co.,Ltd.',
    region: 'US',
  },
  {
    symbol: 'OTSKY',
    name: 'Otsuka Holdings Co.,Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'OTTR',
    name: 'Otter Tail Corporation',
    region: 'US',
  },
  {
    symbol: 'OUKPF',
    name: 'Metso Outotec Corporation',
    region: 'US',
  },
  {
    symbol: 'OUKPY',
    name: 'Metso Outotec Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'OUNZ',
    name: 'Merk Investments LLC - VanEck Merk Gold Trust',
    region: 'US',
  },
  {
    symbol: 'OUSA',
    name: 'OSI ETF Trust - OwShares U.S. Quality Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'OUSM',
    name: 'OSI ETF Trust - OShares U.S. Small-Cap Quality Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'OUST',
    name: 'Ouster Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OVB',
    name: 'Listed Funds Trust - Overlay Shares Core Bond ETF',
    region: 'US',
  },
  {
    symbol: 'OVBC',
    name: 'Ohio Valley Banc Corp.',
    region: 'US',
  },
  {
    symbol: 'OVF',
    name: 'Listed Funds Trust - Overlay Shares Foreign Equity ETF',
    region: 'US',
  },
  {
    symbol: 'OVID',
    name: 'Ovid Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'OVL',
    name: 'Listed Funds Trust - Overlay Shares Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'OVLH',
    name: 'Listed Funds Trust - Overlay Shares Hedged Large Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'OVLY',
    name: 'Oak Valley Bancorp',
    region: 'US',
  },
  {
    symbol: 'OVM',
    name: 'Listed Funds Trust - Overlay Shares Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'OVS',
    name: 'Listed Funds Trust - Overlay Shares Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'OVT',
    name: 'Listed Funds Trust - Overlay Shares Short Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'OVV',
    name: 'Ovintiv Inc',
    region: 'US',
  },
  {
    symbol: 'OWL',
    name: 'Blue Owl Capital Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OWLT',
    name: 'Owlet Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'OWNS',
    name: 'Impact Shares Trust I - Impact Shares Affordable Housing MBS ETF',
    region: 'US',
  },
  {
    symbol: 'OXAC',
    name: 'Oxbridge Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OXACU',
    name: 'Oxbridge Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'OXBR',
    name: 'Oxbridge Re Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'OXM',
    name: 'Oxford Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'OXUS',
    name: 'Oxus Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'OXUSU',
    name: 'Oxus Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'OXY',
    name: 'Occidental Petroleum Corp.',
    region: 'US',
  },
  {
    symbol: 'OYST',
    name: 'Oyster Point Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'OZK',
    name: 'Bank OZK',
    region: 'US',
  },
  {
    symbol: 'PAA',
    name: 'Plains All American Pipeline LP - Unit',
    region: 'US',
  },
  {
    symbol: 'PAAS',
    name: 'Pan American Silver Corp',
    region: 'US',
  },
  {
    symbol: 'PAB',
    name: 'PGIM ETF Trust - PGIM Active Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PABU',
    name: 'iShares Trust - iShares Paris-Aligned Climate MSCI USA ETF',
    region: 'US',
  },
  {
    symbol: 'PAC',
    name: 'Grupo Aeroportuario Del Pacifico SAB de CV - ADR',
    region: 'US',
  },
  {
    symbol: 'PACB',
    name: 'Pacific Biosciences of California Inc',
    region: 'US',
  },
  {
    symbol: 'PACI',
    name: 'PROOF Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'PACK',
    name: 'Ranpak Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PACOX',
    name: 'T Rowe Price US Equity Research Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PACW',
    name: 'Pacwest Bancorp',
    region: 'US',
  },
  {
    symbol: 'PACX',
    name: 'Pioneer Merger Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PACXU',
    name: 'Pioneer Merger Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PAFO',
    name: 'Pacifico Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'PAFOU',
    name: 'Pacifico Acquisition Corp - Units (1 Ord Share & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'PAG',
    name: 'Penske Automotive Group Inc',
    region: 'US',
  },
  {
    symbol: 'PAGP',
    name: 'Plains GP Holdings LP - Class A',
    region: 'US',
  },
  {
    symbol: 'PAGS',
    name: 'PagSeguro Digital Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'PAHC',
    name: 'Phibro Animal Health Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'PAK',
    name: 'Global X Funds - Global X MSCI Pakistan ETF',
    region: 'US',
  },
  {
    symbol: 'PALC',
    name: 'Pacer Funds Trust - Pacer Lunt Large Cap Multi-Factor Alternator ETF',
    region: 'US',
  },
  {
    symbol: 'PALI',
    name: 'Palisade Bio Inc',
    region: 'US',
  },
  {
    symbol: 'PALL',
    name: 'abrdn ETF - abrdn Physical Palladium Shares ETF',
    region: 'US',
  },
  {
    symbol: 'PALT',
    name: 'Paltalk Inc',
    region: 'US',
  },
  {
    symbol: 'PAM',
    name: 'Pampa Energia SA - ADR',
    region: 'US',
  },
  {
    symbol: 'PAMC',
    name: 'Pacer Funds Trust - Pacer Lunt MidCap Multi-Factor Alternator ETF',
    region: 'US',
  },
  {
    symbol: 'PANA',
    name: 'Panacea Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'PANL',
    name: 'Pangaea Logistics Solutions Ltd',
    region: 'US',
  },
  {
    symbol: 'PANW',
    name: 'Palo Alto Networks Inc',
    region: 'US',
  },
  {
    symbol: 'PAPR',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'PAQC',
    name: 'Provident Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PAQCU',
    name: 'Provident Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PAR',
    name: 'Par Technology Corp.',
    region: 'US',
  },
  {
    symbol: 'PARA',
    name: 'Paramount Global - Class B',
    region: 'US',
  },
  {
    symbol: 'PARAA',
    name: 'Paramount Global - Class A',
    region: 'US',
  },
  {
    symbol: 'PARMX',
    name: 'Parnassus Funds: Parnassus Mid Cap Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PARR',
    name: 'Par Pacific Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PARWX',
    name: 'Parnassus Funds: Parnassus Endeavor Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PASG',
    name: 'Passage Bio Inc',
    region: 'US',
  },
  {
    symbol: 'PASSX',
    name: 'T Rowe Price Small-Cap Stock Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PASVX',
    name: 'T Rowe Price Small-Cap Value Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PATH',
    name: 'UiPath Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PATI',
    name: 'Patriot Transportation Holding Inc',
    region: 'US',
  },
  {
    symbol: 'PATK',
    name: 'Patrick Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'PAUG',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - August',
    region: 'US',
  },
  {
    symbol: 'PAULX',
    name: 'T Rowe Price US Large-Cap Core Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PAVE',
    name: 'Global X Funds - Global X U.S. Infrastructure Development ETF',
    region: 'US',
  },
  {
    symbol: 'PAVLX',
    name: 'T Rowe Price Value Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PAVM',
    name: 'PAVmed Inc',
    region: 'US',
  },
  {
    symbol: 'PAWZ',
    name: 'ProShares Trust - ProShares Pet Care ETF',
    region: 'US',
  },
  {
    symbol: 'PAY',
    name: 'Paymentus Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PAYA',
    name: 'Paya Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PAYC',
    name: 'Paycom Software Inc',
    region: 'US',
  },
  {
    symbol: 'PAYO',
    name: 'Payoneer Global Inc',
    region: 'US',
  },
  {
    symbol: 'PAYS',
    name: 'PaySign Inc',
    region: 'US',
  },
  {
    symbol: 'PAYX',
    name: 'Paychex Inc.',
    region: 'US',
  },
  {
    symbol: 'PB',
    name: 'Prosperity Bancshares Inc.',
    region: 'US',
  },
  {
    symbol: 'PBA',
    name: 'Pembina Pipeline Corporation',
    region: 'US',
  },
  {
    symbol: 'PBAX',
    name: 'Phoenix Biotech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PBAXU',
    name: 'Phoenix Biotech Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PBBK',
    name: 'PB Bankshares Inc',
    region: 'US',
  },
  {
    symbol: 'PBCJX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class J Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PBCKX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PBCRF',
    name: 'PT Bank Central Asia Tbk',
    region: 'US',
  },
  {
    symbol: 'PBCRY',
    name: 'PT Bank Central Asia Tbk - ADR',
    region: 'US',
  },
  {
    symbol: 'PBD',
    name: 'Invesco Capital Management LLC - Invesco Global Clean Energy ETF',
    region: 'US',
  },
  {
    symbol: 'PBDM',
    name: 'Invesco Capital Management LLC - Invesco PureBeta SM FTSE Developed ex-North America ETF',
    region: 'US',
  },
  {
    symbol: 'PBE',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Biotechnology & Genome ETF',
    region: 'US',
  },
  {
    symbol: 'PBEE',
    name: 'Invesco Capital Management LLC - Invesco PureBeta SM FTSE Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'PBF',
    name: 'PBF Energy Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PBFS',
    name: 'Pioneer Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'PBFX',
    name: 'Pbf Logistics Lp - Unit',
    region: 'US',
  },
  {
    symbol: 'PBH',
    name: 'Prestige Consumer Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'PBHC',
    name: 'Pathfinder Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'PBI',
    name: 'Pitney Bowes, Inc.',
    region: 'US',
  },
  {
    symbol: 'PBIP',
    name: 'Prudential Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'PBJ',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Food & Beverage ETF',
    region: 'US',
  },
  {
    symbol: 'PBLA',
    name: 'Panbela Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PBLAX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PBLCX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PBLOF',
    name: 'Public Bank Bhd',
    region: 'US',
  },
  {
    symbol: 'PBND',
    name: 'Invesco Capital Management LLC - Invesco PureBeta SM US Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PBP',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 BuyWrite ETF',
    region: 'US',
  },
  {
    symbol: 'PBPB',
    name: 'Potbelly Corp',
    region: 'US',
  },
  {
    symbol: 'PBR',
    name: 'Petroleo Brasileiro S.A. Petrobras - ADR',
    region: 'US',
  },
  {
    symbol: 'PBR.A',
    name: 'Petroleo Brasileiro S.A. Petrobras - ADR - Preference Shares',
    region: 'US',
  },
  {
    symbol: 'PBS',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Media ETF',
    region: 'US',
  },
  {
    symbol: 'PBSM',
    name: 'Invesco Capital Management LLC - Invesco PureBeta SM MSCI USA Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'PBTP',
    name: 'Invesco Capital Management LLC - Invesco PureBeta SM 0-5 Yr US TIPS ETF',
    region: 'US',
  },
  {
    symbol: 'PBTS',
    name: 'Powerbridge Technologies Co Ltd',
    region: 'US',
  },
  {
    symbol: 'PBUS',
    name: 'Invesco Capital Management LLC - Invesco PureBeta SM MSCI USA ETF',
    region: 'US',
  },
  {
    symbol: 'PBW',
    name: 'Invesco Capital Management LLC - Invesco WilderHill Clean Energy ETF',
    region: 'US',
  },
  {
    symbol: 'PBYI',
    name: 'Puma Biotechnology Inc',
    region: 'US',
  },
  {
    symbol: 'PCAPX',
    name: 'Principal Funds, Inc: Principal Capital Appreciation Fund; Class R-4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PCAQX',
    name: 'Principal Funds, Inc: Principal Capital Appreciation Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PCAR',
    name: 'Paccar Inc.',
    region: 'US',
  },
  {
    symbol: 'PCB',
    name: 'PCB Bancorp.',
    region: 'US',
  },
  {
    symbol: 'PCBIX',
    name: 'Principal Funds, Inc: MidCap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PCCOX',
    name: 'T Rowe Price US Equity Research Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PCCT',
    name: 'Perception Capital Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'PCCTU',
    name: 'Perception Capital Corp II - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PCEF',
    name: 'Invesco Capital Management LLC - Invesco CEF Income Composite ETF',
    region: 'US',
  },
  {
    symbol: 'PCG',
    name: 'PG&E Corp.',
    region: 'US',
  },
  {
    symbol: 'PCH',
    name: 'PotlatchDeltic Corp',
    region: 'US',
  },
  {
    symbol: 'PCHUY',
    name: 'PTT Global Chemical Public Company Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'PCOM',
    name: 'Points.com Inc',
    region: 'US',
  },
  {
    symbol: 'PCOR',
    name: 'Procore Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'PCPC',
    name: 'Periphas Capital Partnering Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PCRX',
    name: 'Pacira BioSciences Inc',
    region: 'US',
  },
  {
    symbol: 'PCSA',
    name: 'Processa Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'PCSB',
    name: 'PCSB Financial Corp',
    region: 'US',
  },
  {
    symbol: 'PCT',
    name: 'PureCycle Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'PCTI',
    name: 'PCTEL Inc',
    region: 'US',
  },
  {
    symbol: 'PCTTU',
    name: 'PureCycle Technologies Inc - Units (1 Ord Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'PCTY',
    name: 'Paylocity Holding Corp',
    region: 'US',
  },
  {
    symbol: 'PCUFX',
    name: 'Prudential Sector Funds, Inc: PGIM Jennison Utility Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PCVX',
    name: 'Vaxcyte Inc',
    region: 'US',
  },
  {
    symbol: 'PCX',
    name: 'Parsec Capital Acquisitions Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PCXCU',
    name: 'Parsec Capital Acquisitions Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'PCY',
    name: 'Invesco Capital Management LLC - Invesco Emerging Markets Sovereign Debt ETF',
    region: 'US',
  },
  {
    symbol: 'PCYG',
    name: 'Park City Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'PCYO',
    name: 'Pure Cycle Corp.',
    region: 'US',
  },
  {
    symbol: 'PD',
    name: 'Pagerduty Inc',
    region: 'US',
  },
  {
    symbol: 'PDBC',
    name: 'Invesco Capital Management LLC - Invesco Optimum Yield Diversified Commodity Strategy No K-1 ETF',
    region: 'US',
  },
  {
    symbol: 'PDCE',
    name: 'PDC Energy Inc',
    region: 'US',
  },
  {
    symbol: 'PDCO',
    name: 'Patterson Companies Inc.',
    region: 'US',
  },
  {
    symbol: 'PDD',
    name: 'Pinduoduo Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'PDEC',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'PDEX',
    name: 'Pro-Dex Inc. (co)',
    region: 'US',
  },
  {
    symbol: 'PDFS',
    name: 'PDF Solutions Inc.',
    region: 'US',
  },
  {
    symbol: 'PDLB',
    name: 'Ponce Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'PDN',
    name: 'Invesco Capital Management LLC - Invesco FTSE RAFI Developed Markets ex-U.S. Small-Mid ETF',
    region: 'US',
  },
  {
    symbol: 'PDOT',
    name: 'Peridot Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'PDP',
    name: 'Invesco Capital Management LLC - Invesco DWA Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PDS',
    name: 'Precision Drilling Corp',
    region: 'US',
  },
  {
    symbol: 'PDSB',
    name: 'PDS Biotechnology Corporation',
    region: 'US',
  },
  {
    symbol: 'PEAR',
    name: 'Pear Therapeutics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PEBK',
    name: 'Peoples Bancorp Of North Carolina Inc',
    region: 'US',
  },
  {
    symbol: 'PEBO',
    name: 'Peoples Bancorp, Inc. (Marietta, OH)',
    region: 'US',
  },
  {
    symbol: 'PECGF',
    name: 'Petronas Chemicals Group Bhd',
    region: 'US',
  },
  {
    symbol: 'PED',
    name: 'PEDEVCO Corp',
    region: 'US',
  },
  {
    symbol: 'PEG',
    name: 'Public Service Enterprise Group Inc.',
    region: 'US',
  },
  {
    symbol: 'PEGA',
    name: 'Pegasystems Inc.',
    region: 'US',
  },
  {
    symbol: 'PEGEX',
    name: 'PGIM Jennison Mid-Cap Growth Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEGGX',
    name: 'PGIM Jennison Mid-Cap Growth Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEGR',
    name: 'Project Energy Reimagined Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PEGRU',
    name: 'Project Energy Reimagined Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PEIIX',
    name: 'Principal Funds, Inc: Equity Income Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEIJX',
    name: 'Principal Funds, Inc: Equity Income Fund; Class J Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEIPX',
    name: 'Principal Funds, Inc: Equity Income Fund; Class R-4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEIQX',
    name: 'Principal Funds, Inc: Equity Income Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEIYX',
    name: 'Putnam Large Cap Value Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEJ',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Leisure and Entertainment ETF',
    region: 'US',
  },
  {
    symbol: 'PEMGX',
    name: 'Principal Funds, Inc: MidCap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEN',
    name: 'Penumbra Inc',
    region: 'US',
  },
  {
    symbol: 'PENN',
    name: 'Penn National Gaming, Inc.',
    region: 'US',
  },
  {
    symbol: 'PEOPX',
    name: 'BNY Mellon Index Funds, Inc: BNY Mellon S&P 500 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEP',
    name: 'PepsiCo Inc',
    region: 'US',
  },
  {
    symbol: 'PEPL',
    name: 'PepperLime Health Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PEPLU',
    name: 'PepperLime Health Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PEQCX',
    name: 'Putnam Large Cap Value Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEQLX',
    name: 'Putnam Large Cap Value Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEQNX',
    name: 'Putnam Large Cap Value Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEQRX',
    name: 'Putnam Large Cap Value Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEQSX',
    name: 'Putnam Large Cap Value Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PERI',
    name: 'Perion Network Ltd.',
    region: 'US',
  },
  {
    symbol: 'PESI',
    name: 'Perma-Fix Environmental Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'PETFF',
    name: 'PTT Public Company Limited (Alien Market)',
    region: 'US',
  },
  {
    symbol: 'PETQ',
    name: 'PetIQ Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PETS',
    name: 'Petmed Express, Inc.',
    region: 'US',
  },
  {
    symbol: 'PETV',
    name: 'PetVivo Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PETZ',
    name: 'TDH Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PEX',
    name: 'ProShares Trust - ProShares Global Listed Private Equity ETF',
    region: 'US',
  },
  {
    symbol: 'PEXL',
    name: 'Pacer Funds Trust - Pacer US Export Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'PEY',
    name: 'Invesco Capital Management LLC - Invesco High Yield Equity Dividend Achievers ETF',
    region: 'US',
  },
  {
    symbol: 'PEYAX',
    name: 'Putnam Large Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PEZ',
    name: 'Invesco Capital Management LLC - Invesco DWA Consumer Cyclicals Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PFBC',
    name: 'Preferred Bank (Los Angeles, CA)',
    region: 'US',
  },
  {
    symbol: 'PFC',
    name: 'Premier Financial Corp',
    region: 'US',
  },
  {
    symbol: 'PFDR',
    name: 'Pathfinder Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PFDRU',
    name: 'Pathfinder Acquisition Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'PFE',
    name: 'Pfizer Inc.',
    region: 'US',
  },
  {
    symbol: 'PFEB',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - February',
    region: 'US',
  },
  {
    symbol: 'PFF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Trust iShares Preferred and Income Securities ETF',
    region: 'US',
  },
  {
    symbol: 'PFFA',
    name: 'ETFis Series Trust I - Virtus InfraCap U.S. Preferred Stock ETF',
    region: 'US',
  },
  {
    symbol: 'PFFD',
    name: 'Global X Funds - Global X U.S. Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'PFFR',
    name: 'ETFis Series Trust I - InfraCap REIT Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'PFFV',
    name: 'Global X Funds - Global X Variable Rate Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'PFG',
    name: 'Principal Financial Group Inc - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'PFGC',
    name: 'Performance Food Group Company',
    region: 'US',
  },
  {
    symbol: 'PFHD',
    name: 'Professional Holding Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PFI',
    name: 'Invesco Capital Management LLC - Invesco DWA Financial Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PFIE',
    name: 'Profire Energy Inc',
    region: 'US',
  },
  {
    symbol: 'PFIG',
    name: 'Invesco Capital Management LLC - Invesco Fundamental Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PFIN',
    name: 'P & F Industries, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'PFIS',
    name: 'Peoples Financial Services Corp',
    region: 'US',
  },
  {
    symbol: 'PFIX',
    name: 'Simplify Exchange Traded Funds - Simplify Interest Rate Hedge ETF',
    region: 'US',
  },
  {
    symbol: 'PFLD',
    name: 'ETF Series Solutions Trust - AAM Low Duration Preferred and Income Securities ETF',
    region: 'US',
  },
  {
    symbol: 'PFM',
    name: 'Invesco Capital Management LLC - Invesco Dividend Achievers ETF',
    region: 'US',
  },
  {
    symbol: 'PFMT',
    name: 'Performant Financial Corp',
    region: 'US',
  },
  {
    symbol: 'PFPMX',
    name: 'Parnassus Funds: Parnassus Mid Cap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PFPWX',
    name: 'Parnassus Funds: Parnassus Endeavor Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PFRSX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class R-6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PFS',
    name: 'Provident Financial Services Inc',
    region: 'US',
  },
  {
    symbol: 'PFSW',
    name: 'PFSWEB Inc',
    region: 'US',
  },
  {
    symbol: 'PFTA',
    name: 'Portage Fintech Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PFTAU',
    name: 'Portage Fintech Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PFUT',
    name: 'Putnam ETF Trust - Putnam Sustainable Future ETF',
    region: 'US',
  },
  {
    symbol: 'PFXF',
    name: 'VanEck ETF Trust - VanEck Preferred Securities ex Financials ETF',
    region: 'US',
  },
  {
    symbol: 'PG',
    name: 'Procter & Gamble Co.',
    region: 'US',
  },
  {
    symbol: 'PGAL',
    name: 'Global X Funds - Global X MSCI Portugal ETF',
    region: 'US',
  },
  {
    symbol: 'PGBEX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class R-3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGBFX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class R-4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGBGX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGBHX',
    name: 'Principal Funds, Inc: Blue Chip Fund; Class R-6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGC',
    name: 'Peapack-Gladstone Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'PGEN',
    name: 'Precigen Inc',
    region: 'US',
  },
  {
    symbol: 'PGF',
    name: 'Invesco Capital Management LLC - Invesco Financial Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'PGFAX',
    name: 'Virtus Investment Trust: Virtus AllianzGI Focused Growth Fund; Administrative Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGFIX',
    name: 'Virtus Investment Trust: Virtus AllianzGI Focused Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGHY',
    name: 'Invesco Capital Management LLC - Invesco Global Short Term High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PGJ',
    name: 'Invesco Capital Management LLC - Invesco Golden Dragon China ETF',
    region: 'US',
  },
  {
    symbol: 'PGNY',
    name: 'Progyny Inc',
    region: 'US',
  },
  {
    symbol: 'PGODX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGOEX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGORX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGOYX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGPEF',
    name: 'Publicis Groupe SA',
    region: 'US',
  },
  {
    symbol: 'PGR',
    name: 'Progressive Corp.',
    region: 'US',
  },
  {
    symbol: 'PGRO',
    name: 'Putnam ETF Trust - Putnam Focused Large Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'PGRU',
    name: 'PropertyGuru Group Ltd',
    region: 'US',
  },
  {
    symbol: 'PGRW',
    name: 'Progress Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PGRWU',
    name: 'Progress Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PGSS',
    name: 'Pegasus Digital Mobility Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PGTI',
    name: 'PGT Innovations Inc',
    region: 'US',
  },
  {
    symbol: 'PGWAX',
    name: 'Virtus Investment Trust: Virtus AllianzGI Focused Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PGX',
    name: 'Invesco Capital Management LLC - Invesco Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'PH',
    name: 'Parker-Hannifin Corp.',
    region: 'US',
  },
  {
    symbol: 'PHAR',
    name: 'Pharming Group N.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'PHAS',
    name: 'PhaseBio Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'PHAT',
    name: 'Phathom Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'PHB',
    name: 'Invesco Capital Management LLC - Invesco Fundamental High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PHDG',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Downside Hedged ETF',
    region: 'US',
  },
  {
    symbol: 'PHG',
    name: 'Koninklijke Philips N.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'PHGE',
    name: 'BiomX Inc',
    region: 'US',
  },
  {
    symbol: 'PHI',
    name: 'PLDT Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'PHIC',
    name: 'Population Health Investment Co Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PHICU',
    name: 'Population Health Investment Co Inc - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PHIO',
    name: 'Phio Pharmaceuticals Corp',
    region: 'US',
  },
  {
    symbol: 'PHJMF',
    name: 'H M Sampoerna Tbk',
    region: 'US',
  },
  {
    symbol: 'PHM',
    name: 'PulteGroup Inc',
    region: 'US',
  },
  {
    symbol: 'PHO',
    name: 'Invesco Capital Management LLC - Invesco Water Resources ETF',
    region: 'US',
  },
  {
    symbol: 'PHR',
    name: 'Phreesia Inc',
    region: 'US',
  },
  {
    symbol: 'PHUN',
    name: 'Phunware Inc',
    region: 'US',
  },
  {
    symbol: 'PHVS',
    name: 'Pharvaris NV',
    region: 'US',
  },
  {
    symbol: 'PHX',
    name: 'PHX Minerals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PHYL',
    name: 'PGIM ETF Trust - PGIM Active High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PHYT',
    name: 'Pyrophyte Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PI',
    name: 'Impinj Inc',
    region: 'US',
  },
  {
    symbol: 'PIAI',
    name: 'Prime Impact Acquisition I - Class A',
    region: 'US',
  },
  {
    symbol: 'PICB',
    name: 'Invesco Capital Management LLC - Invesco International Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PICC',
    name: 'Pivotal Investment Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'PICK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Metals & Mining Producers ETF',
    region: 'US',
  },
  {
    symbol: 'PID',
    name: 'Invesco Capital Management LLC - Invesco International Dividend Achievers ETF',
    region: 'US',
  },
  {
    symbol: 'PIE',
    name: 'Invesco Capital Management LLC - Invesco DWA Emerging Markets Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PIFI',
    name: 'Piton Investment Management - ClearShares Piton Intermediate Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'PII',
    name: 'Polaris Inc',
    region: 'US',
  },
  {
    symbol: 'PIII',
    name: 'P3 Health Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PIK',
    name: 'Kidpik Corp',
    region: 'US',
  },
  {
    symbol: 'PILL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Pharmaceutical & Medical Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'PIN',
    name: 'Invesco Capital Management LLC - Invesco India ETF',
    region: 'US',
  },
  {
    symbol: 'PINC',
    name: 'Premier Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PING',
    name: 'Ping Identity Holding Corp',
    region: 'US',
  },
  {
    symbol: 'PINK',
    name: 'Simplify Exchange Traded Funds - Simplify Health Care ETF',
    region: 'US',
  },
  {
    symbol: 'PINS',
    name: 'Pinterest Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PIO',
    name: 'Invesco Capital Management LLC - Invesco Global Water ETF',
    region: 'US',
  },
  {
    symbol: 'PIPP',
    name: 'Pine Island Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PIREX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PIRS',
    name: 'Pieris Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'PITAF',
    name: 'Poste Italiane S.p.a',
    region: 'US',
  },
  {
    symbol: 'PIXY',
    name: 'ShiftPixy Inc',
    region: 'US',
  },
  {
    symbol: 'PIZ',
    name: 'Invesco Capital Management LLC - Invesco DWA Developed Markets Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PJAN',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'PJFAX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJFCX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJFOX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJFPX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJFQX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJFZX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJGQX',
    name: 'PGIM Jennison Mid-Cap Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJGRX',
    name: 'Prudential Investment Portfolios, Inc: PGIM Jennison Growth Fund; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PJP',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Pharmaceuticals ETF',
    region: 'US',
  },
  {
    symbol: 'PJUL',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'PJUN',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'PKB',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Building & Construction ETF',
    region: 'US',
  },
  {
    symbol: 'PKBK',
    name: 'Parke Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'PKE',
    name: 'Park Aerospace Corp',
    region: 'US',
  },
  {
    symbol: 'PKG',
    name: 'Packaging Corp Of America',
    region: 'US',
  },
  {
    symbol: 'PKI',
    name: 'Perkinelmer, Inc.',
    region: 'US',
  },
  {
    symbol: 'PKOH',
    name: 'Park-Ohio Holdings Corp.',
    region: 'US',
  },
  {
    symbol: 'PKW',
    name: 'Invesco Capital Management LLC - Invesco BuyBack Achievers ETF',
    region: 'US',
  },
  {
    symbol: 'PKX',
    name: 'POSCO Holdings Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'PL',
    name: 'Planet Labs PBC - Class A',
    region: 'US',
  },
  {
    symbol: 'PLAB',
    name: 'Photronics, Inc.',
    region: 'US',
  },
  {
    symbol: 'PLAG',
    name: 'Planet Green Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'PLAN',
    name: 'Anaplan Inc',
    region: 'US',
  },
  {
    symbol: 'PLAOU',
    name: 'Patria Latin American Opportunity Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PLAT',
    name: 'WisdomTree Trust - WisdomTree Growth Leaders Fund',
    region: 'US',
  },
  {
    symbol: 'PLAY',
    name: 'Dave & Buster`s Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'PLBC',
    name: 'Plumas Bancorp.',
    region: 'US',
  },
  {
    symbol: 'PLBY',
    name: 'PLBY Group Inc',
    region: 'US',
  },
  {
    symbol: 'PLCE',
    name: 'Childrens Place Inc',
    region: 'US',
  },
  {
    symbol: 'PLDR',
    name: 'Putnam ETF Trust - Putnam Sustainable Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'PLG',
    name: 'Platinum Group Metals Ltd.',
    region: 'US',
  },
  {
    symbol: 'PLL',
    name: 'Piedmont Lithium Inc',
    region: 'US',
  },
  {
    symbol: 'PLLAX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PLM',
    name: 'Polymet Mining Corp',
    region: 'US',
  },
  {
    symbol: 'PLMI',
    name: 'Plum Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'PLMIU',
    name: 'Plum Acquisition Corp I - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'PLMR',
    name: 'Palomar Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PLNT',
    name: 'Planet Fitness Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PLOW',
    name: 'Douglas Dynamics Inc',
    region: 'US',
  },
  {
    symbol: 'PLPC',
    name: 'Preformed Line Products Co.',
    region: 'US',
  },
  {
    symbol: 'PLRG',
    name: 'Principal Exchange-Traded Funds - Principal U.S. Large-Cap Adaptive Multi-Factor ETF',
    region: 'US',
  },
  {
    symbol: 'PLRX',
    name: 'Pliant Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PLSE',
    name: 'Pulse Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'PLTK',
    name: 'Playtika Holding Corp',
    region: 'US',
  },
  {
    symbol: 'PLTL',
    name: 'Principal Exchange-Traded Funds - Principal U.S. Small-Cap Adaptive Multi-Factor ETF',
    region: 'US',
  },
  {
    symbol: 'PLTM',
    name: 'GraniteShares Platinum Trust - GraniteShares Platinum Shares',
    region: 'US',
  },
  {
    symbol: 'PLTR',
    name: 'Palantir Technologies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PLUG',
    name: 'Plug Power Inc',
    region: 'US',
  },
  {
    symbol: 'PLUS',
    name: 'ePlus Inc',
    region: 'US',
  },
  {
    symbol: 'PLW',
    name: 'Invesco Capital Management LLC - Invesco 1-30 Laddered Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'PLX',
    name: 'Protalix BioTherapeutics Inc.',
    region: 'US',
  },
  {
    symbol: 'PLXP',
    name: 'PLx Pharma Inc.',
    region: 'US',
  },
  {
    symbol: 'PLXS',
    name: 'Plexus Corp.',
    region: 'US',
  },
  {
    symbol: 'PLYA',
    name: 'Playa Hotels & Resorts N.V.',
    region: 'US',
  },
  {
    symbol: 'PM',
    name: 'Philip Morris International Inc',
    region: 'US',
  },
  {
    symbol: 'PMAR',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'PMAY',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - May',
    region: 'US',
  },
  {
    symbol: 'PMBJX',
    name: 'Principal Funds, Inc: MidCap Fund; Class J Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PMBPX',
    name: 'Principal Funds, Inc: MidCap Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PMBSX',
    name: 'Principal Funds, Inc: MidCap Fund; Class R-4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PMCB',
    name: 'PharmaCyte Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'PMD',
    name: 'Psychemedics Corp.',
    region: 'US',
  },
  {
    symbol: 'PME',
    name: 'Pingtan Marine Enterprise Ltd',
    region: 'US',
  },
  {
    symbol: 'PMGM',
    name: 'Priveterra Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PMGMU',
    name: 'Priveterra Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PMTS',
    name: 'CPI Card Group Inc',
    region: 'US',
  },
  {
    symbol: 'PMVC',
    name: 'PMV Consumer Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PMVP',
    name: 'PMV Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'PNAGF',
    name: 'Petronas Gas Bhd',
    region: 'US',
  },
  {
    symbol: 'PNBK',
    name: 'Patriot National Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'PNC',
    name: 'PNC Financial Services Group',
    region: 'US',
  },
  {
    symbol: 'PNFP',
    name: 'Pinnacle Financial Partners Inc.',
    region: 'US',
  },
  {
    symbol: 'PNM',
    name: 'PNM Resources Inc',
    region: 'US',
  },
  {
    symbol: 'PNOV',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - November',
    region: 'US',
  },
  {
    symbol: 'PNQI',
    name: 'Invesco Capital Management LLC - Invesco NASDAQ Internet ETF',
    region: 'US',
  },
  {
    symbol: 'PNR',
    name: 'Pentair plc',
    region: 'US',
  },
  {
    symbol: 'PNRG',
    name: 'PrimeEnergy Resources Corp',
    region: 'US',
  },
  {
    symbol: 'PNT',
    name: 'POINT Biopharma Global Inc',
    region: 'US',
  },
  {
    symbol: 'PNTG',
    name: 'Pennant Group Inc',
    region: 'US',
  },
  {
    symbol: 'PNTM',
    name: 'Pontem Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PNW',
    name: 'Pinnacle West Capital Corp.',
    region: 'US',
  },
  {
    symbol: 'POAI',
    name: 'Predictive Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'POCT',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'PODD',
    name: 'Insulet Corporation',
    region: 'US',
  },
  {
    symbol: 'POET',
    name: 'POET Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'POGAX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'POGBX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class B Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'POGCX',
    name: 'Putnam Investment Funds: Putnam Growth Opportunities Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'POLA',
    name: 'Polar Power Inc',
    region: 'US',
  },
  {
    symbol: 'POLIX',
    name: 'FundVantage Trust: Polen Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'POLRX',
    name: 'FundVantage Trust: Polen Growth Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'POLY',
    name: 'Plantronics, Inc.',
    region: 'US',
  },
  {
    symbol: 'POMIX',
    name: 'T Rowe Price Index Trust, Inc: T Rowe Price Total Equity Market Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'POND',
    name: 'Angel Pond Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PONO',
    name: 'PONO Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PONOU',
    name: 'PONO Capital Corp - Units (1 Ord Share Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'POOL',
    name: 'Pool Corporation',
    region: 'US',
  },
  {
    symbol: 'POR',
    name: 'Portland General Electric Co',
    region: 'US',
  },
  {
    symbol: 'PORT',
    name: 'Southport Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'POSH',
    name: 'Poshmark Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'POST',
    name: 'Post Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'POTX',
    name: 'Global X Funds - Global X Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'POW',
    name: 'Powered Brands - Class A',
    region: 'US',
  },
  {
    symbol: 'POWI',
    name: 'Power Integrations Inc.',
    region: 'US',
  },
  {
    symbol: 'POWL',
    name: 'Powell Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'POWRU',
    name: 'Powered Brands - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'POWW',
    name: 'AMMO Inc',
    region: 'US',
  },
  {
    symbol: 'PPA',
    name: 'Invesco Capital Management LLC - Invesco Aerospace & Defense ETF',
    region: 'US',
  },
  {
    symbol: 'PPBI',
    name: 'Pacific Premier Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'PPBT',
    name: 'Purple Biotech Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'PPC',
    name: 'Pilgrim`s Pride Corp.',
    region: 'US',
  },
  {
    symbol: 'PPERF',
    name: 'PT Bank Mandiri (Persero) Tbk',
    region: 'US',
  },
  {
    symbol: 'PPERY',
    name: 'PT Bank Mandiri (Persero) Tbk - ADR',
    region: 'US',
  },
  {
    symbol: 'PPG',
    name: 'PPG Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'PPH',
    name: 'VanEck ETF Trust - VanEck Pharmaceutical ETF',
    region: 'US',
  },
  {
    symbol: 'PPHP',
    name: 'PHP Ventures Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PPHPU',
    name: 'PHP Ventures Acquisition Corp - Units (1 Ord Class A & 1/2 War & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'PPI',
    name: 'Investment Managers Series Trust II - AXS Astoria Inflation Sensitive ETF',
    region: 'US',
  },
  {
    symbol: 'PPIH',
    name: 'Perma-Pipe International Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PPL',
    name: 'PPL Corp',
    region: 'US',
  },
  {
    symbol: 'PPLT',
    name: 'abrdn ETF - abrdn Physical Platinum Shares ETF',
    region: 'US',
  },
  {
    symbol: 'PPOKF',
    name: 'Charoen Pokphan Indonesia Tbk, PT',
    region: 'US',
  },
  {
    symbol: 'PPRUF',
    name: 'Kering',
    region: 'US',
  },
  {
    symbol: 'PPRUY',
    name: 'Kering - ADR',
    region: 'US',
  },
  {
    symbol: 'PPSI',
    name: 'Pioneer Power Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'PPTA',
    name: 'Perpetua Resources Corp.',
    region: 'US',
  },
  {
    symbol: 'PPTY',
    name: 'ETF Series Solutions Trust - U.S. Diversified Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'PPYA',
    name: 'Papaya Growth Opportunity Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'PPYAU',
    name: 'Papaya Growth Opportunity Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PQDI',
    name: 'Principal Exchange-Traded Funds - Principal Spectrum Tax-Advantaged Dividend Active ETF',
    region: 'US',
  },
  {
    symbol: 'PQIAX',
    name: 'Principal Funds, Inc: Equity Income Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PQIN',
    name: 'PGIM ETF Trust - PGIM Quant Solutions Strategic Alpha International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'PRA',
    name: 'Proassurance Corporation',
    region: 'US',
  },
  {
    symbol: 'PRAEX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class R-1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRAX',
    name: 'Praxis Precision Medicines Inc',
    region: 'US',
  },
  {
    symbol: 'PRAY',
    name: 'SHP ETF Trust - FIS Biblically Responsible Risk Managed ETF',
    region: 'US',
  },
  {
    symbol: 'PRBLX',
    name: 'Parnassus Income Funds: Parnassus Core Equity Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRBM',
    name: 'Parabellum Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PRCEX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRCH',
    name: 'Porch Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PRCOX',
    name: 'T Rowe Price US Equity Research Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRCT',
    name: 'Procept BioRobotics Corp',
    region: 'US',
  },
  {
    symbol: 'PRDO',
    name: 'Perdoceo Education Corporation',
    region: 'US',
  },
  {
    symbol: 'PRDS',
    name: 'Pardes Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'PREF',
    name: 'Principal Exchange-Traded Funds - Principal Spectrum Preferred Securities Active ETF',
    region: 'US',
  },
  {
    symbol: 'PREIX',
    name: 'T Rowe Price Index Trust, Inc: T Rowe Price Equity Index 500 Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PREJX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class J Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PREPX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRERX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class R-3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRETX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class R-4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRF',
    name: 'Invesco Capital Management LLC - Invesco FTSE RAFI US 1000 ETF',
    region: 'US',
  },
  {
    symbol: 'PRFT',
    name: 'Perficient Inc.',
    region: 'US',
  },
  {
    symbol: 'PRFX',
    name: 'PainReform Ltd',
    region: 'US',
  },
  {
    symbol: 'PRFZ',
    name: 'Invesco Capital Management LLC - Invesco FTSE RAFI US 1500 Small-Mid ETF',
    region: 'US',
  },
  {
    symbol: 'PRG',
    name: 'PROG Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PRGFX',
    name: 'T Rowe Price Growth Stock Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRGO',
    name: 'Perrigo Company plc',
    region: 'US',
  },
  {
    symbol: 'PRGS',
    name: 'Progress Software Corp.',
    region: 'US',
  },
  {
    symbol: 'PRHSX',
    name: 'T Rowe Price Health Sciences Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRI',
    name: 'Primerica Inc',
    region: 'US',
  },
  {
    symbol: 'PRILX',
    name: 'Parnassus Income Funds: Parnassus Core Equity Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRIM',
    name: 'Primoris Services Corp',
    region: 'US',
  },
  {
    symbol: 'PRK',
    name: 'Park National Corp.',
    region: 'US',
  },
  {
    symbol: 'PRLB',
    name: 'Proto Labs Inc',
    region: 'US',
  },
  {
    symbol: 'PRLD',
    name: 'Prelude Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PRLH',
    name: 'Pearl Holdings Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PRLHU',
    name: 'Pearl Holdings Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PRM',
    name: 'Perimeter Solutions SA',
    region: 'US',
  },
  {
    symbol: 'PRMW',
    name: 'Primo Water Corporation',
    region: 'US',
  },
  {
    symbol: 'PRN',
    name: 'Invesco Capital Management LLC - Invesco DWA Industrials Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PRNT',
    name: 'ARK Investment Management LLC - ARK 3D Printing ETF',
    region: 'US',
  },
  {
    symbol: 'PRO',
    name: 'Pros Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PROC',
    name: 'Procaps Group S.A',
    region: 'US',
  },
  {
    symbol: 'PROF',
    name: 'Profound Medical Corp',
    region: 'US',
  },
  {
    symbol: 'PROG',
    name: 'Progenity Inc',
    region: 'US',
  },
  {
    symbol: 'PROV',
    name: 'Provident Financial Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'PRPB',
    name: 'CC Neuberger Principal Holdings II - Class A',
    region: 'US',
  },
  {
    symbol: 'PRPC',
    name: 'CC Neuberger Principal Holdings III - Class A',
    region: 'US',
  },
  {
    symbol: 'PRPH',
    name: 'ProPhase Labs Inc',
    region: 'US',
  },
  {
    symbol: 'PRPL',
    name: 'Purple Innovation Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PRPO',
    name: 'Precipio Inc',
    region: 'US',
  },
  {
    symbol: 'PRQR',
    name: 'ProQR Therapeutics N.V',
    region: 'US',
  },
  {
    symbol: 'PRRAX',
    name: 'Principal Funds, Inc: Real Estate Securities Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRSR',
    name: 'Prospector Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PRSRU',
    name: 'Prospector Capital Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PRSVX',
    name: 'T Rowe Price Small-Cap Value Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRTA',
    name: 'Prothena Corporation plc',
    region: 'US',
  },
  {
    symbol: 'PRTC',
    name: 'PureTech Health Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'PRTG',
    name: 'Portage Biotech Inc',
    region: 'US',
  },
  {
    symbol: 'PRTH',
    name: 'Priority Technology Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PRTK',
    name: 'Paratek Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'PRTS',
    name: 'CarParts.com Inc',
    region: 'US',
  },
  {
    symbol: 'PRTY',
    name: 'Party City Holdco Inc',
    region: 'US',
  },
  {
    symbol: 'PRU',
    name: 'Prudential Financial Inc.',
    region: 'US',
  },
  {
    symbol: 'PRUAX',
    name: 'Prudential Sector Funds, Inc: PGIM Jennison Utility Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRUFX',
    name: 'T Rowe Price Growth Stock Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRUIX',
    name: 'T Rowe Price Index Trust, Inc: T Rowe Price Equity Index 500 Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRUQX',
    name: 'Prudential Sector Funds, Inc: PGIM Jennison Utility Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRUZX',
    name: 'Prudential Sector Funds, Inc: PGIM Jennison Utility Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRVA',
    name: 'Privia Health Group Inc',
    region: 'US',
  },
  {
    symbol: 'PRVB',
    name: 'Provention Bio Inc',
    region: 'US',
  },
  {
    symbol: 'PRVIX',
    name: 'T Rowe Price Small-Cap Value Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PRYMF',
    name: 'Prysmian S.p.A',
    region: 'US',
  },
  {
    symbol: 'PRYMY',
    name: 'Prysmian S.p.A - ADR',
    region: 'US',
  },
  {
    symbol: 'PSABX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class R-1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSAG',
    name: 'Property Solutions Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'PSAGU',
    name: 'Property Solutions Acquisition Corp II - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'PSBJX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class J Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSBMX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class R-3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSBPX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class R-5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSBSX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class R-4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSC',
    name: 'Principal Exchange-Traded Funds - Principal U.S. Small-Cap Multi-Factor ETF',
    region: 'US',
  },
  {
    symbol: 'PSCC',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'PSCD',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'PSCE',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Energy ETF',
    region: 'US',
  },
  {
    symbol: 'PSCF',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Financials ETF',
    region: 'US',
  },
  {
    symbol: 'PSCH',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Health Care ETF',
    region: 'US',
  },
  {
    symbol: 'PSCI',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'PSCJ',
    name: 'Pacer Funds Trust - Pacer Swan SOS Conservative (July) ETF',
    region: 'US',
  },
  {
    symbol: 'PSCM',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Materials ETF',
    region: 'US',
  },
  {
    symbol: 'PSCQ',
    name: 'Pacer Funds Trust - Pacer Swan SOS Conservative (October) ETF',
    region: 'US',
  },
  {
    symbol: 'PSCT',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Information Technology ETF',
    region: 'US',
  },
  {
    symbol: 'PSCU',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Utilities & Communication Services ETF',
    region: 'US',
  },
  {
    symbol: 'PSCW',
    name: 'Pacer Funds Trust - Pacer Swan SOS Conservative (April) ETF',
    region: 'US',
  },
  {
    symbol: 'PSCX',
    name: 'Pacer Funds Trust - Pacer Swan SOS Conservative (January) ETF',
    region: 'US',
  },
  {
    symbol: 'PSDN',
    name: 'Advisorshares Trust - AdvisorShares Poseidon Dynamic Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'PSEP',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Power Buffer ETF - September',
    region: 'US',
  },
  {
    symbol: 'PSET',
    name: 'Principal Exchange-Traded Funds - Principal Quality ETF',
    region: 'US',
  },
  {
    symbol: 'PSFD',
    name: 'Pacer Funds Trust - Pacer Swan SOS Flex (January) ETF',
    region: 'US',
  },
  {
    symbol: 'PSFE',
    name: 'Paysafe Limited - Class A',
    region: 'US',
  },
  {
    symbol: 'PSFF',
    name: 'Pacer Funds Trust - Pacer Swan SOS Fund of Funds ETF',
    region: 'US',
  },
  {
    symbol: 'PSFJ',
    name: 'Pacer Funds Trust - Pacer Swan SOS Flex (July) ETF',
    region: 'US',
  },
  {
    symbol: 'PSFM',
    name: 'Pacer Funds Trust - Pacer Swan SOS Flex (April) ETF',
    region: 'US',
  },
  {
    symbol: 'PSFO',
    name: 'Pacer Funds Trust - Pacer Swan SOS Flex (October) ETF',
    region: 'US',
  },
  {
    symbol: 'PSGQX',
    name: 'Prudential Investment Portfolios 3: PGIM Jennison Focused Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSHG',
    name: 'Performance Shipping Inc',
    region: 'US',
  },
  {
    symbol: 'PSI',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Semiconductors ETF',
    region: 'US',
  },
  {
    symbol: 'PSIL',
    name: 'Advisorshares Trust - AdvisorShares Psychedelics ETF',
    region: 'US',
  },
  {
    symbol: 'PSJ',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Software ETF',
    region: 'US',
  },
  {
    symbol: 'PSK',
    name: 'SPDR Series Trust - SPDR ICE Preferred Securities ETF',
    region: 'US',
  },
  {
    symbol: 'PSL',
    name: 'Invesco Capital Management LLC - Invesco DWA Consumer Staples Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PSLIX',
    name: 'Principal Funds, Inc: SmallCap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSMB',
    name: 'Invesco Capital Management LLC - Invesco Balanced Multi-Asset Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'PSMC',
    name: 'Invesco Capital Management LLC - Invesco Conservative Multi-Asset Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'PSMCX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSMD',
    name: 'Pacer Funds Trust - Pacer Swan SOS Moderate (January) ETF',
    region: 'US',
  },
  {
    symbol: 'PSMG',
    name: 'Invesco Capital Management LLC - Invesco Growth Multi-Asset Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'PSMJ',
    name: 'Pacer Funds Trust - Pacer Swan SOS Moderate (July) ETF',
    region: 'US',
  },
  {
    symbol: 'PSMLX',
    name: 'Principal Funds, Inc: SmallCap Fund; Class R-6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PSMM',
    name: 'Invesco Capital Management LLC - Invesco Moderately Conservative Multi-Asset Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'PSMO',
    name: 'Pacer Funds Trust - Pacer Swan SOS Moderate (October) ETF',
    region: 'US',
  },
  {
    symbol: 'PSMR',
    name: 'Pacer Funds Trust - Pacer Swan SOS Moderate (April) ETF',
    region: 'US',
  },
  {
    symbol: 'PSMT',
    name: 'Pricesmart Inc.',
    region: 'US',
  },
  {
    symbol: 'PSN',
    name: 'Parsons Corp',
    region: 'US',
  },
  {
    symbol: 'PSNL',
    name: 'Personalis Inc',
    region: 'US',
  },
  {
    symbol: 'PSO',
    name: 'Pearson plc - ADR',
    region: 'US',
  },
  {
    symbol: 'PSP',
    name: 'Invesco Capital Management LLC - Invesco Global Listed Private Equity ETF',
    region: 'US',
  },
  {
    symbol: 'PSPC',
    name: 'Post Holdings Partnering Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PSQ',
    name: 'ProShares Trust - ProShares Short QQQ',
    region: 'US',
  },
  {
    symbol: 'PSR',
    name: 'Invesco Capital Management LLC - Invesco Active U.S. Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'PST',
    name: 'Invesco Capital Management LLC - PowerShares UltraShort Lehman 7-10 + Year Treasury ProShares',
    region: 'US',
  },
  {
    symbol: 'PSTG',
    name: 'Pure Storage Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PSTH',
    name: 'Pershing Square Tontine Holdings Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'PSTI',
    name: 'Pluristem Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PSTP',
    name: 'Innovator ETFs Trust - Innovator Power Buffer Step-Up Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'PSTV',
    name: 'Plus Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PSTX',
    name: 'Poseida Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PSX',
    name: 'Phillips 66',
    region: 'US',
  },
  {
    symbol: 'PSY',
    name: 'ETF Series Solutions Trust - Defiance Next Gen Altered Experience ETF',
    region: 'US',
  },
  {
    symbol: 'PSYK',
    name: 'ETF Series Solutions Trust - PSYK ETF',
    region: 'US',
  },
  {
    symbol: 'PSZKF',
    name: 'Powszechna Kasa Oszczednosci Bank Polski SA',
    region: 'US',
  },
  {
    symbol: 'PSZKY',
    name: 'Powszechna Kasa Oszczednosci Bank Polski SA - ADR',
    region: 'US',
  },
  {
    symbol: 'PTAIF',
    name: 'Astra International',
    region: 'US',
  },
  {
    symbol: 'PTAIY',
    name: 'Astra International - ADR',
    region: 'US',
  },
  {
    symbol: 'PTBD',
    name: 'Pacer Funds Trust - Pacer Trendpilot US Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PTBRY',
    name: 'Bank Negara - ADR',
    region: 'US',
  },
  {
    symbol: 'PTC',
    name: 'PTC Inc',
    region: 'US',
  },
  {
    symbol: 'PTCAY',
    name: 'PT Chandra Asri Petrochemical Tbk. - ADR',
    region: 'US',
  },
  {
    symbol: 'PTCT',
    name: 'PTC Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PTE',
    name: 'PolarityTE Inc',
    region: 'US',
  },
  {
    symbol: 'PTEN',
    name: 'Patterson-UTI Energy Inc',
    region: 'US',
  },
  {
    symbol: 'PTEU',
    name: 'Pacer Funds Trust - Pacer TrendpilotTM European Index ETF',
    region: 'US',
  },
  {
    symbol: 'PTF',
    name: 'Invesco Capital Management LLC - Invesco DWA Technology Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PTGX',
    name: 'Protagonist Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PTH',
    name: 'Invesco Capital Management LLC - Invesco DWA Healthcare Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PTIC',
    name: 'PropTech Investment Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'PTICU',
    name: 'PropTech Investment Corp II - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PTIN',
    name: 'Pacer Funds Trust - Pacer Trendpilot International ETF',
    region: 'US',
  },
  {
    symbol: 'PTIX',
    name: 'Protagenic Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'PTLC',
    name: 'Pacer Funds Trust - Pacer Trendpilot US Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'PTLO',
    name: 'Portillos Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PTMC',
    name: 'Pacer Funds Trust - Pacer Trendpilot US Mid Cap ETF',
    region: 'US',
  },
  {
    symbol: 'PTN',
    name: 'Palatin Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'PTNQ',
    name: 'Pacer Funds Trust - Pacer Trendpilot 100 ETF',
    region: 'US',
  },
  {
    symbol: 'PTNR',
    name: 'Partner Communications Co. - ADR',
    region: 'US',
  },
  {
    symbol: 'PTOC',
    name: 'Pine Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PTOCU',
    name: 'Pine Technology Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'PTON',
    name: 'Peloton Interactive Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PTPI',
    name: 'Petros Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'PTPIF',
    name: 'PT Chandra Asri Petrochemical Tbk.',
    region: 'US',
  },
  {
    symbol: 'PTR',
    name: 'PetroChina Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'PTRA',
    name: 'Proterra Inc',
    region: 'US',
  },
  {
    symbol: 'PTRB',
    name: 'PGIM ETF Trust - PGIM Total Return Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PTRS',
    name: 'Partners Bancorp',
    region: 'US',
  },
  {
    symbol: 'PTSI',
    name: 'P.A.M. Transportation Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'PTTPF',
    name: 'PTT Public Company Limited',
    region: 'US',
  },
  {
    symbol: 'PTVE',
    name: 'Pactiv Evergreen Inc',
    region: 'US',
  },
  {
    symbol: 'PUBGY',
    name: 'Publicis Groupe SA - ADR',
    region: 'US',
  },
  {
    symbol: 'PUBM',
    name: 'PubMatic Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PUCK',
    name: 'Goal Acquisitions Corp',
    region: 'US',
  },
  {
    symbol: 'PUCKU',
    name: 'Goal Acquisitions Corp - Units (1 Ord & 1 War)',
    region: 'US',
  },
  {
    symbol: 'PUI',
    name: 'Invesco Capital Management LLC - Invesco DWA Utilities Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PUK',
    name: 'Prudential plc - ADR',
    region: 'US',
  },
  {
    symbol: 'PULM',
    name: 'Pulmatrix Inc',
    region: 'US',
  },
  {
    symbol: 'PULS',
    name: 'PGIM ETF Trust - PGIM Ultra Short Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PUMP',
    name: 'ProPetro Holding Corp',
    region: 'US',
  },
  {
    symbol: 'PUMSY',
    name: 'Puma SE - ADR',
    region: 'US',
  },
  {
    symbol: 'PUNK',
    name: 'Series Portfolios Trust - Subversive Metaverse ETF',
    region: 'US',
  },
  {
    symbol: 'PUTRF',
    name: 'PTT Public Company Limited - NVDR',
    region: 'US',
  },
  {
    symbol: 'PUTRY',
    name: 'PTT Public Company Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'PUTW',
    name: 'WisdomTree Trust - WisdomTree CBOE S&P 500 PutWrite Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'PV',
    name: 'Primavera Capital Acquisition Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'PVAL',
    name: 'Putnam ETF Trust - Putnam Focused Large Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'PVBC',
    name: 'Provident Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'PVH',
    name: 'PVH Corp',
    region: 'US',
  },
  {
    symbol: 'PVI',
    name: 'Invesco Capital Management LLC - Invesco VRDO Tax-Free ETF',
    region: 'US',
  },
  {
    symbol: 'PVL',
    name: 'Permianville Royalty Trust - Unit',
    region: 'US',
  },
  {
    symbol: 'PWB',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Large Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'PWC',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Market ETF',
    region: 'US',
  },
  {
    symbol: 'PWCIX',
    name: 'Principal Funds, Inc: Principal Capital Appreciation Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'PWFL',
    name: 'PowerFleet Inc',
    region: 'US',
  },
  {
    symbol: 'PWOD',
    name: 'Penns Woods Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'PWP',
    name: 'Perella Weinberg Partners - Class A',
    region: 'US',
  },
  {
    symbol: 'PWR',
    name: 'Quanta Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'PWS',
    name: 'Pacer Funds Trust - Pacer WealthShield ETF',
    region: 'US',
  },
  {
    symbol: 'PWSC',
    name: 'PowerSchool Holdings Inc Class A',
    region: 'US',
  },
  {
    symbol: 'PWUP',
    name: 'PowerUp Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'PWUPU',
    name: 'PowerUp Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'PWV',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Large Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'PWZ',
    name: 'Invesco Capital Management LLC - Invesco California AMT-Free Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PX',
    name: 'P10 Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'PXD',
    name: 'Pioneer Natural Resources Co.',
    region: 'US',
  },
  {
    symbol: 'PXE',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Energy Exploration & Production ETF',
    region: 'US',
  },
  {
    symbol: 'PXF',
    name: 'Invesco Capital Management LLC - Invesco FTSE RAFI Developed Markets ex-U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'PXH',
    name: 'Invesco Capital Management LLC - Invesco FTSE RAFI Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'PXI',
    name: 'Invesco Capital Management LLC - Invesco DWA Energy Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PXJ',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Oil & Gas Services ETF',
    region: 'US',
  },
  {
    symbol: 'PXLW',
    name: 'Pixelworks Inc',
    region: 'US',
  },
  {
    symbol: 'PXQ',
    name: 'Invesco Capital Management LLC - Invesco Dynamic Networking ETF',
    region: 'US',
  },
  {
    symbol: 'PXS',
    name: 'Pyxis Tankers Inc',
    region: 'US',
  },
  {
    symbol: 'PXUS',
    name: 'Principal Exchange-Traded Funds - Principal International Adaptive Multi-Factor ETF',
    region: 'US',
  },
  {
    symbol: 'PY',
    name: 'Principal Exchange-Traded Funds - Principal Value ETF',
    region: 'US',
  },
  {
    symbol: 'PYCR',
    name: 'Paycor HCM Inc',
    region: 'US',
  },
  {
    symbol: 'PYPD',
    name: 'Polypid Ltd',
    region: 'US',
  },
  {
    symbol: 'PYPL',
    name: 'PayPal Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'PYR',
    name: 'Pyrogenesis Canada Inc',
    region: 'US',
  },
  {
    symbol: 'PYXS',
    name: 'Pyxis Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'PYZ',
    name: 'Invesco Capital Management LLC - Invesco DWA Basic Materials Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'PZA',
    name: 'Invesco Capital Management LLC - Invesco National AMT-Free Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PZAKY',
    name: 'Powszechny Zaklad Ubezpieczen SA - ADR',
    region: 'US',
  },
  {
    symbol: 'PZG',
    name: 'Paramount Gold Nevada Corp',
    region: 'US',
  },
  {
    symbol: 'PZT',
    name: 'Invesco Capital Management LLC - Invesco New York AMT-Free Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'PZZA',
    name: 'Papa John`s International, Inc.',
    region: 'US',
  },
  {
    symbol: 'QABA',
    name: 'First Trust Exchange-Traded Fund III - First Trust NASDAQ ABA Community Bank Index Fund',
    region: 'US',
  },
  {
    symbol: 'QAI',
    name: 'IndexIQ ETF Trust - IQ Hedge Multi-Strategy Tracker ETF',
    region: 'US',
  },
  {
    symbol: 'QARP',
    name: 'DBX ETF Trust - Xtrackers Russell 1000 US Quality at a Reasonable Price ETF',
    region: 'US',
  },
  {
    symbol: 'QASCX',
    name: 'Federated Hermes MDT Series: Federated Hermes MDT Small Cap Core Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'QAT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Qatar ETF',
    region: 'US',
  },
  {
    symbol: 'QCLN',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq Clean Edge Green Energy Index Fund',
    region: 'US',
  },
  {
    symbol: 'QCLR',
    name: 'Global X Funds - Global X NASDAQ 100 Collar 95-110 ETF',
    region: 'US',
  },
  {
    symbol: 'QCOM',
    name: 'Qualcomm, Inc.',
    region: 'US',
  },
  {
    symbol: 'QCON',
    name: 'American Century ETF Trust - American Century Quality Convertible Securities ETF',
    region: 'US',
  },
  {
    symbol: 'QCRH',
    name: 'QCR Holding, Inc.',
    region: 'US',
  },
  {
    symbol: 'QDEC',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Nasdaq-100 Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'QDEF',
    name: 'FlexShares Trust - FlexShares Quality Dividend Defensive Index Fund',
    region: 'US',
  },
  {
    symbol: 'QDEL',
    name: 'Quidel Corp.',
    region: 'US',
  },
  {
    symbol: 'QDF',
    name: 'FlexShares Trust - FlexShares Quality Dividend Index Fund',
    region: 'US',
  },
  {
    symbol: 'QDIV',
    name: 'Global X Funds - Global X S&P 500 Quality Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'QDPL',
    name: 'Pacer Funds Trust - Pacer Metaurus US Large Cap Dividend Multiplier 400 ETF',
    region: 'US',
  },
  {
    symbol: 'QDYN',
    name: 'FlexShares Trust - FlexShares Quality Dividend Dynamic Index Fund',
    region: 'US',
  },
  {
    symbol: 'QED',
    name: 'IndexIQ ETF Trust - IQ Hedge Event-Driven Tracker ETF',
    region: 'US',
  },
  {
    symbol: 'QEFA',
    name: 'SSgA Active Trust - SPDR MSCI EAFE StrategicFactors SM ETF',
    region: 'US',
  },
  {
    symbol: 'QEMM',
    name: 'SSgA Active Trust - SPDR MSCI Emerging Markets StrategicFactors SM ETF',
    region: 'US',
  },
  {
    symbol: 'QFTA',
    name: 'Quantum Fintech Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'QFVIX',
    name: 'Pear Tree Funds: Pear Tree Polaris Foreign Value Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'QGEN',
    name: 'Qiagen NV',
    region: 'US',
  },
  {
    symbol: 'QGRO',
    name: 'American Century ETF Trust - American Century STOXX U.S.Quality Growth ETF',
    region: 'US',
  },
  {
    symbol: 'QH',
    name: 'Quhuo Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'QID',
    name: 'ProShares Trust - ProShares UltraShort QQQ',
    region: 'US',
  },
  {
    symbol: 'QIHCF',
    name: 'Haier Smart Home Co Ltd - Class D',
    region: 'US',
  },
  {
    symbol: 'QINT',
    name: 'American Century ETF Trust - American Century Quality Diversified International ETF',
    region: 'US',
  },
  {
    symbol: 'QIPT',
    name: 'Quipt Home Medical Corp New',
    region: 'US',
  },
  {
    symbol: 'QISCX',
    name: 'Federated Hermes MDT Series: Federated Hermes MDT Small Cap Core Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'QJUN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Nasdaq-100 Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'QLC',
    name: 'FlexShares Trust - FlexShares US Quality Large Cap Index Fund',
    region: 'US',
  },
  {
    symbol: 'QLD',
    name: 'ProShares Trust - ProShares Ultra QQQ',
    region: 'US',
  },
  {
    symbol: 'QLGN',
    name: 'Qualigen Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'QLI',
    name: 'Qilian International Holding Group ltd',
    region: 'US',
  },
  {
    symbol: 'QLS',
    name: 'IndexIQ ETF Trust - IQ Hedge Long/Short Tracker ETF',
    region: 'US',
  },
  {
    symbol: 'QLTA',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Aaa - A Rated Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'QLV',
    name: 'FlexShares Trust - FlexShares US Quality Low Volatility Index Fund',
    region: 'US',
  },
  {
    symbol: 'QLVD',
    name: 'FlexShares Trust - FlexShares Developed Markets ex-US Quality Low Volatility Index Fund',
    region: 'US',
  },
  {
    symbol: 'QLVE',
    name: 'FlexShares Trust - FlexShares Emerging Markets Quality Low Volatility Index Fund',
    region: 'US',
  },
  {
    symbol: 'QLYS',
    name: 'Qualys Inc',
    region: 'US',
  },
  {
    symbol: 'QMAR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Nasdaq-100 Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'QMCO',
    name: 'Quantum Corp',
    region: 'US',
  },
  {
    symbol: 'QMN',
    name: 'IndexIQ ETF Trust - IQ Hedge Market Neutral Tracker ETF',
    region: 'US',
  },
  {
    symbol: 'QMOM',
    name: 'Alpha Architect Etf Trust - Alpha Architect U.S. Quantitative Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'QNGY',
    name: 'Quanergy Systems Inc',
    region: 'US',
  },
  {
    symbol: 'QNST',
    name: 'QuinStreet Inc',
    region: 'US',
  },
  {
    symbol: 'QPFF',
    name: 'American Century ETF Trust - American Century Quality Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'QPT',
    name: 'Advisorshares Trust - AdvisorShares Q Portfolio Blended Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'QPX',
    name: 'Advisorshares Trust - AdvisorShares Q Dynamic Growth ETF',
    region: 'US',
  },
  {
    symbol: 'QQC',
    name: 'Simplify Exchange Traded Funds - Simplify Nasdaq 100 PLUS Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'QQD',
    name: 'Simplify Exchange Traded Funds - Simplify Nasdaq 100 PLUS Downside Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'QQEW',
    name: 'First Trust Exchange-Traded Fund III - First Trust NASDAQ-100 Equal Weighted Index Fund',
    region: 'US',
  },
  {
    symbol: 'QQH',
    name: 'Northern Lights Fund Trust III - HCM Defender 100 Index ETF',
    region: 'US',
  },
  {
    symbol: 'QQJG',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco ESG NASDAQ Next Gen 100 ETF',
    region: 'US',
  },
  {
    symbol: 'QQMG',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco ESG NASDAQ 100 ETF',
    region: 'US',
  },
  {
    symbol: 'QQQ',
    name: 'Invesco Capital Management LLC - Invesco QQQ Trust Series 1',
    region: 'US',
  },
  {
    symbol: 'QQQA',
    name: 'ProShares Trust - ProShares Nasdaq-100 Dorsey Wright Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'QQQE',
    name: 'Direxion Shares ETF Trust - Direxion NASDAQ-100 Equal Weighted Index Shares',
    region: 'US',
  },
  {
    symbol: 'QQQJ',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco NASDAQ Next Gen 100 ETF',
    region: 'US',
  },
  {
    symbol: 'QQQM',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco NASDAQ 100 ETF',
    region: 'US',
  },
  {
    symbol: 'QQQN',
    name: 'Victory Portfolios II - VictoryShares Nasdaq Next 50 ETF',
    region: 'US',
  },
  {
    symbol: 'QQXT',
    name: 'First Trust Exchange-Traded Fund III - First Trust Nasdaq-100 Ex-Technology Sector Index Fund',
    region: 'US',
  },
  {
    symbol: 'QRFT',
    name: 'Exchange Listed Funds Trust - QRAFT AI Enhanced U.S. Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'QRHC',
    name: 'Quest Resource Holding Corp',
    region: 'US',
  },
  {
    symbol: 'QRMI',
    name: 'Global X Funds - Global X NASDAQ 100 Risk Managed Income ETF',
    region: 'US',
  },
  {
    symbol: 'QRTEA',
    name: 'Qurate Retail Inc - Series A',
    region: 'US',
  },
  {
    symbol: 'QRTEB',
    name: 'Qurate Retail Inc - Series B',
    region: 'US',
  },
  {
    symbol: 'QRVO',
    name: 'Qorvo Inc',
    region: 'US',
  },
  {
    symbol: 'QS',
    name: 'QuantumScape Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'QSAM',
    name: 'QSAM Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'QSI',
    name: 'Quantum-Si Incorporated - Class A',
    region: 'US',
  },
  {
    symbol: 'QSPT',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest Nasdaq-100 Buffer ETF - September',
    region: 'US',
  },
  {
    symbol: 'QSR',
    name: 'Restaurant Brands International Inc',
    region: 'US',
  },
  {
    symbol: 'QSWN',
    name: 'Amplify ETF Trust - Amplify BlackSwan Tech & Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'QTAP',
    name: 'Innovator ETFs Trust - Innovator Growth Accelerated Plus ETF - April',
    region: 'US',
  },
  {
    symbol: 'QTEC',
    name: 'First Trust Exchange-Traded Fund III - First Trust NASDAQ-100 Technology Sector Index Fund',
    region: 'US',
  },
  {
    symbol: 'QTEK',
    name: 'QualTek Services Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'QTJA',
    name: 'Innovator ETFs Trust - Innovator Growth Accelerated Plus ETF - January',
    region: 'US',
  },
  {
    symbol: 'QTJL',
    name: 'Innovator ETFs Trust - Innovator Growth Accelerated Plus ETF - July',
    region: 'US',
  },
  {
    symbol: 'QTNT',
    name: 'Quotient Ltd',
    region: 'US',
  },
  {
    symbol: 'QTOC',
    name: 'Innovator ETFs Trust - Innovator Growth Accelerated Plus ETF - October',
    region: 'US',
  },
  {
    symbol: 'QTR',
    name: 'Global X Funds - Global X NASDAQ 100 Tail Risk ETF',
    region: 'US',
  },
  {
    symbol: 'QTRX',
    name: 'Quanterix Corp',
    region: 'US',
  },
  {
    symbol: 'QTT',
    name: 'Qutoutiao Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'QTUM',
    name: 'ETF Series Solutions Trust - Defiance Quantum ETF',
    region: 'US',
  },
  {
    symbol: 'QTWO',
    name: 'Q2 Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'QUAD',
    name: 'Quad/Graphics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'QUAL',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Quality Factor ETF',
    region: 'US',
  },
  {
    symbol: 'QUBT',
    name: 'Quantum Computing Inc',
    region: 'US',
  },
  {
    symbol: 'QUCCF',
    name: 'Quanta Computer - GDR - Reg S',
    region: 'US',
  },
  {
    symbol: 'QUCPY',
    name: 'Quanta Computer - GDR - 144A (Representing 5 Ord Shares)',
    region: 'US',
  },
  {
    symbol: 'QUIK',
    name: 'Quicklogic Corp',
    region: 'US',
  },
  {
    symbol: 'QUMU',
    name: 'Qumu Corp',
    region: 'US',
  },
  {
    symbol: 'QUOT',
    name: 'Quotient Technology Inc',
    region: 'US',
  },
  {
    symbol: 'QURE',
    name: 'uniQure N.V.',
    region: 'US',
  },
  {
    symbol: 'QUS',
    name: 'SPDR Series Trust - SPDR MSCI USA StrategicFactors SM ETF',
    region: 'US',
  },
  {
    symbol: 'QUSIX',
    name: 'Pear Tree Funds: Pear Tree Polaris Foreign Value Small Cap Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'QUSOX',
    name: 'Pear Tree Funds: Pear Tree Polaris Foreign Value Small Cap Fund; Ordinary Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'QUSRX',
    name: 'Pear Tree Funds: Pear Tree Polaris Foreign Value Small Cap Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'QVAL',
    name: 'Alpha Architect Etf Trust - Alpha Architect U.S. Quantitative Value ETF',
    region: 'US',
  },
  {
    symbol: 'QVML',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P 500 QVM Multi-factor ETF',
    region: 'US',
  },
  {
    symbol: 'QVMM',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P MidCap 400 QVM Multi-factor ETF',
    region: 'US',
  },
  {
    symbol: 'QVMS',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P SmallCap 600 QVM Multi-factor ETF',
    region: 'US',
  },
  {
    symbol: 'QWLD',
    name: 'SSgA Active Trust - SPDR MSCI World StrategicFactors SM ETF',
    region: 'US',
  },
  {
    symbol: 'QYLD',
    name: 'Global X Funds - Global X NASDAQ 100 Covered Call ETF',
    region: 'US',
  },
  {
    symbol: 'QYLG',
    name: 'Global X Funds - Global X Nasdaq 100 Covered Call & Growth ETF',
    region: 'US',
  },
  {
    symbol: 'R',
    name: 'Ryder System, Inc.',
    region: 'US',
  },
  {
    symbol: 'RAAS',
    name: 'Cloopen Group Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'RAAX',
    name: 'VanEck ETF Trust - VanEck Inflation Allocation ETF',
    region: 'US',
  },
  {
    symbol: 'RACB',
    name: 'Research Alliance Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'RACE',
    name: 'Ferrari N.V.',
    region: 'US',
  },
  {
    symbol: 'RAD',
    name: 'Rite Aid Corp.',
    region: 'US',
  },
  {
    symbol: 'RADA',
    name: 'Rada Electronic Industries',
    region: 'US',
  },
  {
    symbol: 'RADI',
    name: 'Radius Global Infrastructure Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RADLY',
    name: 'Raia Drogasil S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'RAFE',
    name: 'Pimco Equity Series - Pimco Rafi ESG U.S. ETF',
    region: 'US',
  },
  {
    symbol: 'RAIL',
    name: 'FreightCar America Inc',
    region: 'US',
  },
  {
    symbol: 'RAIN',
    name: 'Rain Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RALS',
    name: 'ProShares Trust - ProShares RAFI Long/Short',
    region: 'US',
  },
  {
    symbol: 'RAM',
    name: 'Aries I Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RAMMU',
    name: 'Aries I Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'RAMP',
    name: 'LiveRamp Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'RANI',
    name: 'Rani Therapeutics Holdings Inc Class A',
    region: 'US',
  },
  {
    symbol: 'RANJF',
    name: 'Randstad NV',
    region: 'US',
  },
  {
    symbol: 'RANJY',
    name: 'Randstad NV - ADR',
    region: 'US',
  },
  {
    symbol: 'RAPT',
    name: 'RAPT Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RARE',
    name: 'Ultragenyx Pharmaceutical Inc.',
    region: 'US',
  },
  {
    symbol: 'RAVE',
    name: 'Rave Restaurant Group Inc',
    region: 'US',
  },
  {
    symbol: 'RAVI',
    name: 'FlexShares Trust - FlexShares Ready Access Variable Income Fund',
    region: 'US',
  },
  {
    symbol: 'RAYC',
    name: 'Advisors Inner Circle Fund III - Rayliant Quantamental China Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RAYD',
    name: 'Advisors Inner Circle Fund III - Rayliant Quantitative Developed Market Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RAYE',
    name: 'Advisors Inner Circle Fund III - Rayliant Quantamental Emerging Market Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RAYS',
    name: 'Global X Funds - Global X Solar ETF',
    region: 'US',
  },
  {
    symbol: 'RBA',
    name: 'Ritchie Bros Auctioneers Inc',
    region: 'US',
  },
  {
    symbol: 'RBAC',
    name: 'RedBall Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RBB',
    name: 'RBB Bancorp',
    region: 'US',
  },
  {
    symbol: 'RBBN',
    name: 'Ribbon Communications Inc - New',
    region: 'US',
  },
  {
    symbol: 'RBCAA',
    name: 'Republic Bancorp, Inc. (KY) - Class A',
    region: 'US',
  },
  {
    symbol: 'RBCN',
    name: 'Rubicon Technology Inc',
    region: 'US',
  },
  {
    symbol: 'RBIN',
    name: 'ETF Series Solutions Trust - Nationwide Risk-Based International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RBKB',
    name: 'Rhinebeck Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'RBLX',
    name: 'Roblox Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'RBND',
    name: 'SPDR Series Trust - SPDR Bloomberg SASB Corporate Bond ESG Select ETF',
    region: 'US',
  },
  {
    symbol: 'RBOT',
    name: 'Vicarious Surgical Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RBUS',
    name: 'ETF Series Solutions Trust - Nationwide Risk-Based U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RCAC',
    name: 'Revelstone Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RCACU',
    name: 'Revelstone Capital Acquisition Corp - Units (1 Ord ShareClass A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'RCAT',
    name: 'Red Cat Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'RCD',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'RCDTF',
    name: 'Recordati SpA',
    region: 'US',
  },
  {
    symbol: 'RCEL',
    name: 'AVITA Medical Inc',
    region: 'US',
  },
  {
    symbol: 'RCFA',
    name: 'RCF Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RCHG',
    name: 'Recharge Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RCHGU',
    name: 'Recharge Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'RCI',
    name: 'Rogers Communications Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'RCII',
    name: 'Rent-a-Center Inc.',
    region: 'US',
  },
  {
    symbol: 'RCKT',
    name: 'Rocket Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'RCKY',
    name: 'Rocky Brands, Inc',
    region: 'US',
  },
  {
    symbol: 'RCL',
    name: 'Royal Caribbean Group',
    region: 'US',
  },
  {
    symbol: 'RCLF',
    name: 'Rosecliff Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'RCLFU',
    name: 'Rosecliff Acquisition Corp I - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'RCM',
    name: 'R1 RCM Inc',
    region: 'US',
  },
  {
    symbol: 'RCMT',
    name: 'RCM Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'RCON',
    name: 'Recon Technology Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'RCOR',
    name: 'Renovacor Inc',
    region: 'US',
  },
  {
    symbol: 'RCRRF',
    name: 'Recruit Holdings Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'RCRT',
    name: 'Recruiter.com Group Inc',
    region: 'US',
  },
  {
    symbol: 'RCRUY',
    name: 'Recruit Holdings Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'RCUS',
    name: 'Arcus Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'RDBX',
    name: 'Redbox Entertainment Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RDCM',
    name: 'Radcom',
    region: 'US',
  },
  {
    symbol: 'RDEIF',
    name: 'Red Electrica Corporacion S.A.',
    region: 'US',
  },
  {
    symbol: 'RDEIY',
    name: 'Red Electrica Corporacion S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'RDFI',
    name: 'Collaborative Investment Series Trust - Rareview Dynamic Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'RDHL',
    name: 'Redhill Biopharma - ADR',
    region: 'US',
  },
  {
    symbol: 'RDI',
    name: 'Reading International Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RDIB',
    name: 'Reading International Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'RDIV',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P Ultra Dividend Revenue ETF',
    region: 'US',
  },
  {
    symbol: 'RDMX',
    name: 'SPDR Index Shares Funds - SPDR Bloomberg SASB Developed Markets Ex US ESG Select ETF',
    region: 'US',
  },
  {
    symbol: 'RDN',
    name: 'Radian Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'RDNT',
    name: 'Radnet Inc',
    region: 'US',
  },
  {
    symbol: 'RDOG',
    name: 'ALPS Fund Services - ALPS REIT Dividend Dogs ETF',
    region: 'US',
  },
  {
    symbol: 'RDSMY',
    name: 'Koninklijke DSM N.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'RDUS',
    name: 'Radius Health Inc.',
    region: 'US',
  },
  {
    symbol: 'RDVT',
    name: 'Red Violet Inc',
    region: 'US',
  },
  {
    symbol: 'RDVY',
    name: 'First Trust Exchange-Traded Fund III - First Trust Rising Dividend Achievers ETF',
    region: 'US',
  },
  {
    symbol: 'RDW',
    name: 'Redwire Corporation',
    region: 'US',
  },
  {
    symbol: 'RDWR',
    name: 'Radware',
    region: 'US',
  },
  {
    symbol: 'RDY',
    name: 'Dr. Reddy`s Laboratories Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'RE',
    name: 'Everest Re Group Ltd',
    region: 'US',
  },
  {
    symbol: 'REAL',
    name: 'Therealreal Inc',
    region: 'US',
  },
  {
    symbol: 'REAX',
    name: 'The Real Brokerage Inc.',
    region: 'US',
  },
  {
    symbol: 'REC',
    name: 'Emles Trust - Emles Real Estate Credit ETF',
    region: 'US',
  },
  {
    symbol: 'RECS',
    name: 'Columbia ETF Trust I - Columbia Research Enhanced Core ETF',
    region: 'US',
  },
  {
    symbol: 'REDU',
    name: 'RISE Education Cayman Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'REE',
    name: 'REE Automotive Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'REED',
    name: 'Reeds Inc',
    region: 'US',
  },
  {
    symbol: 'REET',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global REIT ETF',
    region: 'US',
  },
  {
    symbol: 'REFR',
    name: 'Research Frontiers Inc.',
    region: 'US',
  },
  {
    symbol: 'REGI',
    name: 'Renewable Energy Group Inc',
    region: 'US',
  },
  {
    symbol: 'REGL',
    name: 'ProShares Trust - ProShares S&P MidCap 400 Div Aristocrats ETF',
    region: 'US',
  },
  {
    symbol: 'REGN',
    name: 'Regeneron Pharmaceuticals, Inc.',
    region: 'US',
  },
  {
    symbol: 'REI',
    name: 'Ring Energy Inc',
    region: 'US',
  },
  {
    symbol: 'REIT',
    name: 'ALPS Fund Services - ALPS Active REIT ETF',
    region: 'US',
  },
  {
    symbol: 'REK',
    name: 'ProShares Trust - ProShares Short Real Estate',
    region: 'US',
  },
  {
    symbol: 'REKR',
    name: 'Rekor Systems Inc',
    region: 'US',
  },
  {
    symbol: 'RELI',
    name: 'Reliance Global Group Inc',
    region: 'US',
  },
  {
    symbol: 'RELL',
    name: 'Richardson Electronics, Ltd.',
    region: 'US',
  },
  {
    symbol: 'RELX',
    name: 'RELX Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'REM',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Mortgage Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'REMG',
    name: 'SPDR Index Shares Funds - SPDR Bloomberg SASB Emerging Markets ESG Select ETF',
    region: 'US',
  },
  {
    symbol: 'REMX',
    name: 'VanEck ETF Trust - VanEck Rare Earth/Strategic Metals ETF',
    region: 'US',
  },
  {
    symbol: 'REMYF',
    name: 'Remy Cointreau SA',
    region: 'US',
  },
  {
    symbol: 'REMYY',
    name: 'Remy Cointreau SA - ADR',
    region: 'US',
  },
  {
    symbol: 'RENN',
    name: 'Renren Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'RENO',
    name: 'Renovare Environmental Inc',
    region: 'US',
  },
  {
    symbol: 'RENT',
    name: 'Rent the Runway Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'REPL',
    name: 'Replimune Group Inc',
    region: 'US',
  },
  {
    symbol: 'REPX',
    name: 'Riley Exploration Permian Inc.',
    region: 'US',
  },
  {
    symbol: 'REPYF',
    name: 'Repsol S.A',
    region: 'US',
  },
  {
    symbol: 'REPYY',
    name: 'Repsol S.A - ADR',
    region: 'US',
  },
  {
    symbol: 'RERE',
    name: 'ATRenew Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'RES',
    name: 'RPC, Inc.',
    region: 'US',
  },
  {
    symbol: 'RESD',
    name: 'WisdomTree Trust - WisdomTree International ESG Fund',
    region: 'US',
  },
  {
    symbol: 'RESE',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets ESG Fund',
    region: 'US',
  },
  {
    symbol: 'RESI',
    name: 'Kelly Strategic ETF Trust - Kelly Residential & Apartment Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'RESP',
    name: 'WisdomTree Trust - WisdomTree U.S. ESG Fund',
    region: 'US',
  },
  {
    symbol: 'RETA',
    name: 'Reata Pharmaceuticals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RETL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Retail Bull 3x Shares',
    region: 'US',
  },
  {
    symbol: 'RETO',
    name: 'ReTo Eco-Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'REV',
    name: 'Revlon, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'REVB',
    name: 'Revelation Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'REVBU',
    name: 'Revelation Biosciences Inc - Units (1 Ord share & 1 War)',
    region: 'US',
  },
  {
    symbol: 'REVE',
    name: 'Alpine Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'REVEU',
    name: 'Alpine Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'REVG',
    name: 'REV Group Inc',
    region: 'US',
  },
  {
    symbol: 'REVH',
    name: 'Revolution Healthcare Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'REVHU',
    name: 'Revolution Healthcare Acquisition Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'REVS',
    name: 'Columbia ETF Trust I - Columbia Research Enhanced Value ETF',
    region: 'US',
  },
  {
    symbol: 'REW',
    name: 'ProShares Trust - ProShares UltraShort Technology',
    region: 'US',
  },
  {
    symbol: 'REX',
    name: 'REX American Resources Corp',
    region: 'US',
  },
  {
    symbol: 'REYN',
    name: 'Reynolds Consumer Products Inc',
    region: 'US',
  },
  {
    symbol: 'REZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Residential and Multisector Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'REZI',
    name: 'Resideo Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'RF',
    name: 'Regions Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'RFAC',
    name: 'RF Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RFACU',
    name: 'RF Acquisition Corp - Units (1 Ord Class A & 1 War & 1 Right )',
    region: 'US',
  },
  {
    symbol: 'RFCI',
    name: 'ALPS Advisors Inc - RiverFront Dynamic Core Income ETF',
    region: 'US',
  },
  {
    symbol: 'RFDA',
    name: 'ALPS Advisors Inc - RiverFront Dynamic US Dividend Advantage ETF',
    region: 'US',
  },
  {
    symbol: 'RFDI',
    name: 'First Trust Exchange-Traded Fund III - First Trust RiverFront Dynamic Developed International ETF',
    region: 'US',
  },
  {
    symbol: 'RFEM',
    name: 'First Trust Exchange-Traded Fund III - First Trust RiverFront Dynamic Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'RFEU',
    name: 'First Trust Exchange-Traded Fund III - First Trust RiverFront Dynamic Europe ETF',
    region: 'US',
  },
  {
    symbol: 'RFFC',
    name: 'ALPS Advisors Inc - RiverFront Dynamic US Flex-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'RFG',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap 400 Pure Growth ETF',
    region: 'US',
  },
  {
    symbol: 'RFIL',
    name: 'RF Industries Ltd.',
    region: 'US',
  },
  {
    symbol: 'RFL',
    name: 'Rafael Holdings Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'RFP',
    name: 'Resolute Forest Products Inc',
    region: 'US',
  },
  {
    symbol: 'RFV',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap 400 Pure Value ETF',
    region: 'US',
  },
  {
    symbol: 'RGA',
    name: 'Reinsurance Group Of America, Inc.',
    region: 'US',
  },
  {
    symbol: 'RGC',
    name: 'Regencell Bioscience Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'RGCO',
    name: 'RGC Resources, Inc.',
    region: 'US',
  },
  {
    symbol: 'RGEN',
    name: 'Repligen Corp.',
    region: 'US',
  },
  {
    symbol: 'RGF',
    name: 'Real Good Food Company Inc (The) - Class A',
    region: 'US',
  },
  {
    symbol: 'RGI',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'RGLD',
    name: 'Royal Gold, Inc.',
    region: 'US',
  },
  {
    symbol: 'RGLS',
    name: 'Regulus Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RGLXF',
    name: 'RTL Group',
    region: 'US',
  },
  {
    symbol: 'RGLXY',
    name: 'RTL Group - ADR',
    region: 'US',
  },
  {
    symbol: 'RGNX',
    name: 'Regenxbio Inc',
    region: 'US',
  },
  {
    symbol: 'RGP',
    name: 'Resources Connection Inc',
    region: 'US',
  },
  {
    symbol: 'RGR',
    name: 'Sturm, Ruger & Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'RGS',
    name: 'Regis Corp.',
    region: 'US',
  },
  {
    symbol: 'RH',
    name: 'RH - Class A',
    region: 'US',
  },
  {
    symbol: 'RHCB',
    name: 'BNY Mellon ETF Trust - BNY Mellon Responsible Horizons Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'RHE',
    name: 'Regional Health Properties Inc',
    region: 'US',
  },
  {
    symbol: 'RHHBF',
    name: 'Roche Holding AG - Bearer Shares',
    region: 'US',
  },
  {
    symbol: 'RHHBY',
    name: 'Roche Holding AG - ADR',
    region: 'US',
  },
  {
    symbol: 'RHHVF',
    name: 'Roche Holding AG',
    region: 'US',
  },
  {
    symbol: 'RHI',
    name: 'Robert Half International Inc.',
    region: 'US',
  },
  {
    symbol: 'RHP',
    name: 'Ryman Hospitality Properties Inc',
    region: 'US',
  },
  {
    symbol: 'RHRX',
    name: 'Starboard Investment Trust - RH Tactical Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'RHS',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'RHTX',
    name: 'Starboard Investment Trust - RH Tactical Outlook ETF',
    region: 'US',
  },
  {
    symbol: 'RIBT',
    name: 'RiceBran Technologies',
    region: 'US',
  },
  {
    symbol: 'RICK',
    name: 'RCI Hospitality Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'RICO',
    name: 'Agrico Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RICOU',
    name: 'Agrico Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'RIDE',
    name: 'Lordstown Motors Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'RIET',
    name: 'ETF Series Solutions Trust - Hoya Capital High Dividend Yield ETF',
    region: 'US',
  },
  {
    symbol: 'RIG',
    name: 'Transocean Ltd',
    region: 'US',
  },
  {
    symbol: 'RIGL',
    name: 'Rigel Pharmaceuticals',
    region: 'US',
  },
  {
    symbol: 'RIGS',
    name: 'ALPS Fund Services - RiverFront Strategic Income Fund',
    region: 'US',
  },
  {
    symbol: 'RIGZ',
    name: 'Alpha Architect Etf Trust - Viridi Cleaner Energy Crypto-Mining & Semiconductor ETF',
    region: 'US',
  },
  {
    symbol: 'RILY',
    name: 'B. Riley Financial Inc',
    region: 'US',
  },
  {
    symbol: 'RINF',
    name: 'ProShares Trust - ProShares Inflation Expectations ETF',
    region: 'US',
  },
  {
    symbol: 'RING',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Gold Miners ETF',
    region: 'US',
  },
  {
    symbol: 'RIO',
    name: 'Rio Tinto plc - ADR',
    region: 'US',
  },
  {
    symbol: 'RIOT',
    name: 'Riot Blockchain Inc',
    region: 'US',
  },
  {
    symbol: 'RISN',
    name: 'Northern Lights Fund Trust IV - Inspire Tactical Balanced ESG ETF',
    region: 'US',
  },
  {
    symbol: 'RISR',
    name: 'Tidal ETF Trust - FolioBeyond Rising Rates ETF',
    region: 'US',
  },
  {
    symbol: 'RITA',
    name: 'ETF Series Solutions Trust - ETFB Green SRI REITs ETF',
    region: 'US',
  },
  {
    symbol: 'RIVN',
    name: 'Rivian Automotive Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RJAC',
    name: 'Jackson Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'RKDA',
    name: 'Arcadia Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'RKLB',
    name: 'Rocket Lab USA Inc',
    region: 'US',
  },
  {
    symbol: 'RKLY',
    name: 'Rockley Photonics Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'RKTA',
    name: 'Rocket Internet Growth Opportunities Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RKWAF',
    name: 'Rockwool AS - Class A',
    region: 'US',
  },
  {
    symbol: 'RKWBF',
    name: 'Rockwool AS - Class B',
    region: 'US',
  },
  {
    symbol: 'RL',
    name: 'Ralph Lauren Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RLAY',
    name: 'Relay Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RLGT',
    name: 'Radiant Logistics, Inc.',
    region: 'US',
  },
  {
    symbol: 'RLI',
    name: 'RLI Corp.',
    region: 'US',
  },
  {
    symbol: 'RLMD',
    name: 'Relmada Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RLNIY',
    name: 'Reliance Industries Ltd. - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'RLTQY',
    name: 'Realtek Semiconductor Corporation - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'RLX',
    name: 'RLX Technology Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'RLY',
    name: 'SSgA Active Trust - SPDR SSgA Multi-Asset Real Return ETF',
    region: 'US',
  },
  {
    symbol: 'RLYB',
    name: 'Rallybio Corp',
    region: 'US',
  },
  {
    symbol: 'RMBI',
    name: 'Richmond Mutual Bancorporation Inc',
    region: 'US',
  },
  {
    symbol: 'RMBL',
    name: 'RumbleON Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'RMBS',
    name: 'Rambus Inc.',
    region: 'US',
  },
  {
    symbol: 'RMCF',
    name: 'Rocky Mountain Chocolate Factory Inc',
    region: 'US',
  },
  {
    symbol: 'RMD',
    name: 'Resmed Inc.',
    region: 'US',
  },
  {
    symbol: 'RMEBX',
    name: 'American Mutual Fund; Class R2E Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMED',
    name: 'Ra Medical Systems Inc',
    region: 'US',
  },
  {
    symbol: 'RMFBX',
    name: 'American Mutual Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMFCX',
    name: 'American Mutual Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMFEX',
    name: 'American Mutual Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMFFX',
    name: 'American Mutual Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMFGX',
    name: 'American Mutual Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMFHX',
    name: 'American Mutual Fund; Class R5E Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RMGC',
    name: 'RMG Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'RMGCU',
    name: 'RMG Acquisition Corp III - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'RMNI',
    name: 'Rimini Street Inc.',
    region: 'US',
  },
  {
    symbol: 'RMO',
    name: 'Romeo Power Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RMTI',
    name: 'Rockwell Medical Inc',
    region: 'US',
  },
  {
    symbol: 'RNA',
    name: 'Avidity Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'RNAZ',
    name: 'TransCode Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RNDB',
    name: 'Randolph Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'RNDM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Developed International Equity Select ETF',
    region: 'US',
  },
  {
    symbol: 'RNDV',
    name: 'First Trust Exchange-Traded Fund III - First Trust US Equity Dividend Select ETF',
    region: 'US',
  },
  {
    symbol: 'RNECF',
    name: 'Renesas Electronics Corporation',
    region: 'US',
  },
  {
    symbol: 'RNECY',
    name: 'Renesas Electronics Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'RNEM',
    name: 'First Trust Exchange-Traded Fund III - First Trust Emerging Markets Equity Select ETF',
    region: 'US',
  },
  {
    symbol: 'RNER',
    name: 'Mount Rainier Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RNERU',
    name: 'Mount Rainier Acquisition Corp - Unit (1 Ordinary share & 1 Wrt)',
    region: 'US',
  },
  {
    symbol: 'RNG',
    name: 'RingCentral Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'RNGAX',
    name: 'New Economy Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGBX',
    name: 'New Economy Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGCX',
    name: 'New Economy Fund; Class R3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGEX',
    name: 'New Economy Fund; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGFX',
    name: 'New Economy Fund; Class R5 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGGX',
    name: 'New Economy Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGHX',
    name: 'New Economy Fund; Class R5E Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNGR',
    name: 'Ranger Energy Services Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RNLC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Large Cap US Equity Select ETF',
    region: 'US',
  },
  {
    symbol: 'RNLSY',
    name: 'Renault S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'RNLX',
    name: 'Renalytix Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'RNMC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Mid Cap US Equity Select ETF',
    region: 'US',
  },
  {
    symbol: 'RNNEX',
    name: 'New Economy Fund; Class R2E Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RNR',
    name: 'RenaissanceRe Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'RNRG',
    name: 'Global X Funds - Global X Renewable Energy Producers ETF',
    region: 'US',
  },
  {
    symbol: 'RNSC',
    name: 'First Trust Exchange-Traded Fund III - First Trust Small Cap US Equity Select ETF',
    region: 'US',
  },
  {
    symbol: 'RNSDF',
    name: 'Renault S.A.',
    region: 'US',
  },
  {
    symbol: 'RNST',
    name: 'Renasant Corp.',
    region: 'US',
  },
  {
    symbol: 'RNW',
    name: 'ReNew Energy Global plc - Class A',
    region: 'US',
  },
  {
    symbol: 'RNWK',
    name: 'Realnetworks, Inc.',
    region: 'US',
  },
  {
    symbol: 'RNXT',
    name: 'RenovoRx Inc',
    region: 'US',
  },
  {
    symbol: 'ROAD',
    name: 'Construction Partners Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ROAM',
    name: 'Lattice Strategies LLC - Hartford Multifactor Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'ROBO',
    name: 'Exchange Traded Concepts Trust - ETCT ROBO Global Robotics and Automation Index ETF',
    region: 'US',
  },
  {
    symbol: 'ROBT',
    name: 'First Trust Exchange-Traded Fund VI - First Trust Nasdaq Artificial Intelligence and Robotics ETF',
    region: 'US',
  },
  {
    symbol: 'ROC',
    name: 'ROC Energy Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'ROCAU',
    name: 'ROC Energy Acquisition Corp - Units (1 Ord Share & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'ROCC',
    name: 'Ranger Oil Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ROCG',
    name: 'Roth CH Acquisition IV Co',
    region: 'US',
  },
  {
    symbol: 'ROCGU',
    name: 'Roth CH Acquisition IV Co - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ROCI',
    name: 'Alpha Architect Etf Trust - ROC ETF',
    region: 'US',
  },
  {
    symbol: 'ROCK',
    name: 'Gibraltar Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'ROCL',
    name: 'Roth CH Acquisition V Co',
    region: 'US',
  },
  {
    symbol: 'ROCLU',
    name: 'Roth CH Acquisition V Co - Unit (1 Ordinary share & 1/2 Wrt)',
    region: 'US',
  },
  {
    symbol: 'RODE',
    name: 'Lattice Strategies LLC - Hartford Multifactor Diversified International ETF',
    region: 'US',
  },
  {
    symbol: 'RODM',
    name: 'Lattice Strategies LLC - Hartford Multifactor Developed Markets (ex-US) ETF',
    region: 'US',
  },
  {
    symbol: 'ROFCX',
    name: 'Royce Fund: Royce Opportunity Fund; Consultant Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ROFIX',
    name: 'Royce Fund: Royce Opportunity Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ROFRX',
    name: 'Royce Fund: Royce Opportunity Fund; R Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'ROG',
    name: 'Rogers Corp.',
    region: 'US',
  },
  {
    symbol: 'ROHCF',
    name: 'Rohm Company Limited',
    region: 'US',
  },
  {
    symbol: 'ROHCY',
    name: 'Rohm Company Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'ROIC',
    name: 'Retail Opportunity Investments Corp',
    region: 'US',
  },
  {
    symbol: 'ROIV',
    name: 'Roivant Sciences Ltd',
    region: 'US',
  },
  {
    symbol: 'ROK',
    name: 'Rockwell Automation Inc',
    region: 'US',
  },
  {
    symbol: 'ROKT',
    name: 'SPDR Series Trust - SPDR S&P Kensho Final Frontiers ETF',
    region: 'US',
  },
  {
    symbol: 'ROKU',
    name: 'Roku Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ROL',
    name: 'Rollins, Inc.',
    region: 'US',
  },
  {
    symbol: 'ROLL',
    name: 'RBC Bearings Inc.',
    region: 'US',
  },
  {
    symbol: 'ROM',
    name: 'ProShares Trust - ProShares Ultra Technology',
    region: 'US',
  },
  {
    symbol: 'ROMO',
    name: 'Strategy Shares - Strategy Shares Newfound/ReSolve Robust Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'RONI',
    name: 'Rice Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ROOF',
    name: 'IndexIQ ETF Trust - IQ U.S. Real Estate Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'ROOT',
    name: 'Root Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ROP',
    name: 'Roper Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'RORO',
    name: 'Tidal ETF Trust - ATAC US Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'ROSC',
    name: 'Lattice Strategies LLC - Hartford Multifactor Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'ROSE',
    name: 'Rose Hill Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ROSEU',
    name: 'Rose Hill Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ROSS',
    name: 'Ross Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'ROST',
    name: 'Ross Stores, Inc.',
    region: 'US',
  },
  {
    symbol: 'ROUS',
    name: 'Lattice Strategies LLC - Hartford Multifactor US Equity ETF',
    region: 'US',
  },
  {
    symbol: 'ROVR',
    name: 'Rover Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RPAR',
    name: 'Tidal ETF Trust - RPAR Risk Parity ETF',
    region: 'US',
  },
  {
    symbol: 'RPAY',
    name: 'Repay Holdings Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'RPD',
    name: 'Rapid7 Inc',
    region: 'US',
  },
  {
    symbol: 'RPG',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Pure Growth ETF',
    region: 'US',
  },
  {
    symbol: 'RPHM',
    name: 'Reneo Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'RPHS',
    name: 'Two Roads Shared Trust - Regents Park Hedged Market Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'RPID',
    name: 'Rapid Micro Biosystems Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RPM',
    name: 'RPM International, Inc.',
    region: 'US',
  },
  {
    symbol: 'RPMGX',
    name: 'T Rowe Price Mid-Cap Growth Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RPMIX',
    name: 'Royce Fund: Royce Pennsylvania Mutual Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RPRX',
    name: 'Royalty Pharma plc - Class A',
    region: 'US',
  },
  {
    symbol: 'RPTX',
    name: 'Repare Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RPV',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Pure Value ETF',
    region: 'US',
  },
  {
    symbol: 'RRAC',
    name: 'Rigel Resource Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RRBI',
    name: 'Red River Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'RRC',
    name: 'Range Resources Corp',
    region: 'US',
  },
  {
    symbol: 'RRGB',
    name: 'Red Robin Gourmet Burgers Inc',
    region: 'US',
  },
  {
    symbol: 'RRGSX',
    name: 'T Rowe Price Growth Stock Fund, Inc; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RRH',
    name: 'Advisors Inner Circle Fund III - Advocate Rising Rate Hedge ETF',
    region: 'US',
  },
  {
    symbol: 'RRR',
    name: 'Red Rock Resorts Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RRRAX',
    name: 'Deutsche DWS Securities Trust: DWS RREEF Real Estate Securities Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RRREX',
    name: 'Deutsche DWS Securities Trust: DWS RREEF Real Estate Securities Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RRRRX',
    name: 'Deutsche DWS Securities Trust: DWS RREEF Real Estate Securities Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RRRTX',
    name: 'Deutsche DWS Securities Trust: DWS RREEF Real Estate Securities Fund; Class T Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RRRZX',
    name: 'Deutsche DWS Securities Trust: DWS RREEF Real Estate Securities Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RRX',
    name: 'Regal Rexnord Corp',
    region: 'US',
  },
  {
    symbol: 'RS',
    name: 'Reliance Steel & Aluminum Co.',
    region: 'US',
  },
  {
    symbol: 'RSEE',
    name: 'Collaborative Investment Series Trust - Rareview Systematic Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RSEFX',
    name: 'Royce Fund: Royce Special Equity Fund; Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RSEIX',
    name: 'Royce Fund: Royce Special Equity Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RSG',
    name: 'Republic Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'RSI',
    name: 'Rush Street Interactive Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RSKD',
    name: 'Riskified Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'RSLS',
    name: 'ReShape Lifesciences Inc.',
    region: 'US',
  },
  {
    symbol: 'RSNHF',
    name: 'Resona Holdings, Inc.',
    region: 'US',
  },
  {
    symbol: 'RSP',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'RSPE',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco ESG S&P 500 Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'RSPY',
    name: 'Collaborative Investment Series Trust - Revere Sector Opportunity ETF',
    region: 'US',
  },
  {
    symbol: 'RSSS',
    name: 'Research Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'RSVR',
    name: 'Reservoir Media Inc',
    region: 'US',
  },
  {
    symbol: 'RTAI',
    name: 'Collaborative Investment Series Trust - Rareview Tax Advantaged Income ETF',
    region: 'US',
  },
  {
    symbol: 'RTH',
    name: 'VanEck ETF Trust - VanEck Retail ETF',
    region: 'US',
  },
  {
    symbol: 'RTLR',
    name: 'Rattler Midstream Lp - Unit',
    region: 'US',
  },
  {
    symbol: 'RTM',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Materials ETF',
    region: 'US',
  },
  {
    symbol: 'RTOUX',
    name: 'Russell Investment Company: Tax-Managed US Mid & Small Cap Fund; Class M Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RTSAX',
    name: 'Russell Investment Company: Tax-Managed US Mid & Small Cap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RTSSX',
    name: 'Russell Investment Company: Tax-Managed US Mid & Small Cap Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RTX',
    name: 'Raytheon Technologies Corporation',
    region: 'US',
  },
  {
    symbol: 'RTYD',
    name: 'Simplify Exchange Traded Funds - Simplify US Small Cap PLUS Downside Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'RUBY',
    name: 'Rubius Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RUFF',
    name: 'ETF Opportunities Trust - Alpha Dog ETF',
    region: 'US',
  },
  {
    symbol: 'RULE',
    name: 'Collaborative Investment Series Trust - Adaptive Core ETF',
    region: 'US',
  },
  {
    symbol: 'RUN',
    name: 'Sunrun Inc',
    region: 'US',
  },
  {
    symbol: 'RUSHA',
    name: 'Rush Enterprises Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RUSHB',
    name: 'Rush Enterprises Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'RUTH',
    name: 'Ruths Hospitality Group Inc',
    region: 'US',
  },
  {
    symbol: 'RVAC',
    name: 'Riverview Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RVACU',
    name: 'Riverview Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'RVLP',
    name: 'RVL Pharmaceuticals plc',
    region: 'US',
  },
  {
    symbol: 'RVLV',
    name: 'Revolve Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RVMD',
    name: 'Revolution Medicines Inc',
    region: 'US',
  },
  {
    symbol: 'RVNC',
    name: 'Revance Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'RVNU',
    name: 'DBX ETF Trust - Xtrackers Municipal Infrastructure Revenue Bond ETF',
    region: 'US',
  },
  {
    symbol: 'RVP',
    name: 'Retractable Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'RVPH',
    name: 'Reviva Pharmaceuticals Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'RVSB',
    name: 'Riverview Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'RVSN',
    name: 'Rail Vision Ltd',
    region: 'US',
  },
  {
    symbol: 'RWGV',
    name: 'Direxion Shares ETF Trust - Direxion Russell 1000 Growth Over Value ETF',
    region: 'US',
  },
  {
    symbol: 'RWJ',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P SmallCap 600 Revenue ETF',
    region: 'US',
  },
  {
    symbol: 'RWK',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P MidCap 400 Revenue ETF',
    region: 'US',
  },
  {
    symbol: 'RWL',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco S&P 500 Revenue ETF',
    region: 'US',
  },
  {
    symbol: 'RWLK',
    name: 'Rewalk Robotics Ltd',
    region: 'US',
  },
  {
    symbol: 'RWM',
    name: 'ProShares Trust - ProShares Short Russell2000',
    region: 'US',
  },
  {
    symbol: 'RWO',
    name: 'SSgA Active Trust - SPDR Dow Jones Global Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'RWODU',
    name: 'Redwoods Acquisition Corp - Units (1 Ord, 1 War & 1 Right )',
    region: 'US',
  },
  {
    symbol: 'RWR',
    name: 'SPDR Series Trust - SPDR DJ Wilshire REIT ETF',
    region: 'US',
  },
  {
    symbol: 'RWVG',
    name: 'Direxion Shares ETF Trust - Direxion Russell 1000 Value Over Growth ETF',
    region: 'US',
  },
  {
    symbol: 'RWX',
    name: 'SSgA Active Trust - SPDR Dow Jones International Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'RXD',
    name: 'ProShares Trust - ProShares UltraShort Health Care',
    region: 'US',
  },
  {
    symbol: 'RXDX',
    name: 'Prometheus Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'RXI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'RXL',
    name: 'ProShares Trust - ProShares Ultra Health Care',
    region: 'US',
  },
  {
    symbol: 'RXRA',
    name: 'RXR Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'RXRAU',
    name: 'RXR Acquisition Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'RXRX',
    name: 'Recursion Pharmaceuticals Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'RXST',
    name: 'RxSight Inc',
    region: 'US',
  },
  {
    symbol: 'RXT',
    name: 'Rackspace Technology Inc',
    region: 'US',
  },
  {
    symbol: 'RY',
    name: 'Royal Bank Of Canada',
    region: 'US',
  },
  {
    symbol: 'RYAAY',
    name: 'Ryanair Holdings Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'RYAM',
    name: 'Rayonier Advanced Materials Inc',
    region: 'US',
  },
  {
    symbol: 'RYATX',
    name: 'Rydex Series Funds: NASDAQ-100 Fund; A-Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYB',
    name: 'RYB Education Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'RYCOX',
    name: 'Rydex Series Funds: NASDAQ-100 Fund; C-Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYE',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Energy ETF',
    region: 'US',
  },
  {
    symbol: 'RYF',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Financials ETF',
    region: 'US',
  },
  {
    symbol: 'RYH',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Health Care ETF',
    region: 'US',
  },
  {
    symbol: 'RYHOX',
    name: 'Rydex Series Funds: NASDAQ-100 Fund; H-Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYI',
    name: 'Ryerson Holding Corp.',
    region: 'US',
  },
  {
    symbol: 'RYJ',
    name: 'Invesco Capital Management LLC - Invesco Raymond James SB-1 Equity ETF',
    region: 'US',
  },
  {
    symbol: 'RYLD',
    name: 'Global X Funds - Global X Russell 2000 Covered Call ETF',
    region: 'US',
  },
  {
    symbol: 'RYN',
    name: 'Rayonier Inc.',
    region: 'US',
  },
  {
    symbol: 'RYOCX',
    name: 'Rydex Series Funds: NASDAQ-100 Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYOFX',
    name: 'Royce Fund: Royce Opportunity Fund; Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYPNX',
    name: 'Royce Fund: Royce Opportunity Fund; Investment Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYSEX',
    name: 'Royce Fund: Royce Special Equity Fund; Investment Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'RYT',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Technology ETF',
    region: 'US',
  },
  {
    symbol: 'RYTM',
    name: 'Rhythm Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'RYU',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Equal Weight Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'RZG',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap 600 Pure Growth ETF',
    region: 'US',
  },
  {
    symbol: 'RZLT',
    name: 'Rezolute Inc',
    region: 'US',
  },
  {
    symbol: 'RZV',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap 600 Pure Value ETF',
    region: 'US',
  },
  {
    symbol: 'S',
    name: 'SentinelOne Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SA',
    name: 'Seabridge Gold, Inc.',
    region: 'US',
  },
  {
    symbol: 'SAA',
    name: 'ProShares Trust - ProShares Ultra SmallCap600',
    region: 'US',
  },
  {
    symbol: 'SABR',
    name: 'Sabre Corp',
    region: 'US',
  },
  {
    symbol: 'SABS',
    name: 'SAB Biotherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SAEF',
    name: 'Schwab Strategic Trust - Schwab Ariel ESG ETF',
    region: 'US',
  },
  {
    symbol: 'SAFM',
    name: 'Sanderson Farms, Inc.',
    region: 'US',
  },
  {
    symbol: 'SAFRF',
    name: 'Safran SA',
    region: 'US',
  },
  {
    symbol: 'SAFRY',
    name: 'Safran SA - ADR',
    region: 'US',
  },
  {
    symbol: 'SAFT',
    name: 'Safety Insurance Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'SAGA',
    name: 'Sagaliam Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SAGAU',
    name: 'Sagaliam Acquisition Corp - Units (1 Ordinary Shares Class A & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'SAGE',
    name: 'Sage Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SAGP',
    name: 'Advisors Inner Circle Fund III - Strategas Global Policy Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'SAH',
    name: 'Sonic Automotive, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SAIA',
    name: 'Saia Inc.',
    region: 'US',
  },
  {
    symbol: 'SAIC',
    name: 'Science Applications International Corp.',
    region: 'US',
  },
  {
    symbol: 'SAIFX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Value Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SAIL',
    name: 'SailPoint Technologies Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SAL',
    name: 'Salisbury Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'SALM',
    name: 'Salem Media Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SALRF',
    name: 'Salmar Asa',
    region: 'US',
  },
  {
    symbol: 'SALRY',
    name: 'Salmar Asa - ADR',
    region: 'US',
  },
  {
    symbol: 'SAM',
    name: 'Boston Beer Co., Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SAMA',
    name: 'Schultze Special Purpose Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'SAMAU',
    name: 'Schultze Special Purpose Acquisition Corp II - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SAMT',
    name: 'Advisors Inner Circle Fund III - Strategas Macro Thematic Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'SAN',
    name: 'Banco Santander S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'SANA',
    name: 'Sana Biotechnology Inc',
    region: 'US',
  },
  {
    symbol: 'SANB',
    name: 'Sanaby Health Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'SANBU',
    name: 'Sanaby Health Acquisition Corp I - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SAND',
    name: 'Sandstorm Gold Ltd',
    region: 'US',
  },
  {
    symbol: 'SANG',
    name: 'Sangoma Technologies Corporation',
    region: 'US',
  },
  {
    symbol: 'SANM',
    name: 'Sanmina Corp',
    region: 'US',
  },
  {
    symbol: 'SANW',
    name: 'S&W Seed Co',
    region: 'US',
  },
  {
    symbol: 'SAP',
    name: 'Sap SE - ADR',
    region: 'US',
  },
  {
    symbol: 'SARK',
    name: 'Collaborative Investment Series Trust - Tuttle Capital Short Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'SASMX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Small Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SASR',
    name: 'Sandy Spring Bancorp',
    region: 'US',
  },
  {
    symbol: 'SATL',
    name: 'Satellogic Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SATLF',
    name: 'Zozo Inc.',
    region: 'US',
  },
  {
    symbol: 'SATO',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco Alerian Galaxy Crypto Economy ETF',
    region: 'US',
  },
  {
    symbol: 'SATS',
    name: 'EchoStar Corp',
    region: 'US',
  },
  {
    symbol: 'SAUHY',
    name: 'Straumann Holding AG - ADR',
    region: 'US',
  },
  {
    symbol: 'SAVA',
    name: 'Cassava Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'SAVE',
    name: 'Spirit Airlines Inc',
    region: 'US',
  },
  {
    symbol: 'SAVN',
    name: 'Northern Lights Fund Trust II - LifeGoal Conservative Wealth Builder ETF',
    region: 'US',
  },
  {
    symbol: 'SAXPF',
    name: 'Sampo Plc - Class A',
    region: 'US',
  },
  {
    symbol: 'SAXPY',
    name: 'Sampo Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'SB',
    name: 'Safe Bulkers, Inc',
    region: 'US',
  },
  {
    symbol: 'SBAC',
    name: 'SBA Communications Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SBB',
    name: 'ProShares Trust - ProShares Short SmallCap600',
    region: 'US',
  },
  {
    symbol: 'SBCF',
    name: 'Seacoast Banking Corp. Of Florida',
    region: 'US',
  },
  {
    symbol: 'SBEV',
    name: 'Splash Beverage Group Inc',
    region: 'US',
  },
  {
    symbol: 'SBFG',
    name: 'SB Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'SBFM',
    name: 'Sunshine Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'SBGI',
    name: 'Sinclair Broadcast Group, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SBGSF',
    name: 'Schneider Electric SE',
    region: 'US',
  },
  {
    symbol: 'SBGSY',
    name: 'Schneider Electric SE - ADR',
    region: 'US',
  },
  {
    symbol: 'SBH',
    name: 'Sally Beauty Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SBII',
    name: 'Sandbridge X2 Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SBIO',
    name: 'ALPS Fund Services - ALPS Medical Breakthroughs ETF',
    region: 'US',
  },
  {
    symbol: 'SBKFF',
    name: 'State Bank of India - GDR - Reg S',
    region: 'US',
  },
  {
    symbol: 'SBKJY',
    name: 'State Bank of India - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'SBLGX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SBLK',
    name: 'Star Bulk Carriers Corp',
    region: 'US',
  },
  {
    symbol: 'SBLYX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SBM',
    name: 'ProShares Trust - ProShares Short Basic Materials',
    region: 'US',
  },
  {
    symbol: 'SBMAX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Mid Cap Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SBND',
    name: 'Columbia ETF Trust I - Columbia Short Duration Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SBNY',
    name: 'Signature Bank',
    region: 'US',
  },
  {
    symbol: 'SBOW',
    name: 'SilverBow Resources Inc',
    region: 'US',
  },
  {
    symbol: 'SBPYX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Small Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SBS',
    name: 'Companhia de Saneamento Basico do Estado de Sao Paulo. - ADR',
    region: 'US',
  },
  {
    symbol: 'SBSI',
    name: 'Southside Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'SBSNF',
    name: 'Schibsted ASA - Class A',
    region: 'US',
  },
  {
    symbol: 'SBSW',
    name: 'Sibanye Stillwater Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'SBT',
    name: 'Sterling Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'SBTX',
    name: 'Silverback Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SBUX',
    name: 'Starbucks Corp.',
    region: 'US',
  },
  {
    symbol: 'SCABY',
    name: 'Svenska Cellulosa AB - ADR',
    region: 'US',
  },
  {
    symbol: 'SCAQ',
    name: 'Stratim Cloud Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SCAQU',
    name: 'Stratim Cloud Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SCBGF',
    name: 'SIG Group AG',
    region: 'US',
  },
  {
    symbol: 'SCC',
    name: 'ProShares Trust - ProShares UltraShort Consumer Services',
    region: 'US',
  },
  {
    symbol: 'SCCO',
    name: 'Southern Copper Corporation',
    region: 'US',
  },
  {
    symbol: 'SCGSX',
    name: 'Deutsche DWS Investment Trust: DWS Capital Growth Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SCHA',
    name: 'Schwab Strategic Trust - Schwab U.S. Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SCHB',
    name: 'Schwab Strategic Trust - Schwab U.S. Broad Market ETF',
    region: 'US',
  },
  {
    symbol: 'SCHC',
    name: 'Schwab Strategic Trust - Schwab International Small-Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SCHD',
    name: 'Schwab Strategic Trust - Schwab US Dividend Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SCHE',
    name: 'Schwab Strategic Trust - Schwab Emerging Markets Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SCHF',
    name: 'Schwab Strategic Trust - Schwab International Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SCHG',
    name: 'Schwab Strategic Trust - Schwab U.S. Large-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'SCHH',
    name: 'Schwab Strategic Trust - Schwab U.S. REIT ETF',
    region: 'US',
  },
  {
    symbol: 'SCHI',
    name: 'Schwab Strategic Trust - Schwab 5-10 Year Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SCHJ',
    name: 'Schwab Strategic Trust - Schwab 1-5 Year Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SCHK',
    name: 'Schwab Strategic Trust - Schwab 1000 Index ETF',
    region: 'US',
  },
  {
    symbol: 'SCHL',
    name: 'Scholastic Corp.',
    region: 'US',
  },
  {
    symbol: 'SCHM',
    name: 'Schwab Strategic Trust - Schwab U.S. Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SCHN',
    name: 'Schnitzer Steel Industries, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SCHO',
    name: 'Schwab Strategic Trust - Schwab Short-Term U.S. Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'SCHP',
    name: 'Schwab Strategic Trust - Schwab U.S. Tips ETF',
    region: 'US',
  },
  {
    symbol: 'SCHQ',
    name: 'Schwab Strategic Trust - Schwab US Long-Term U.S. Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'SCHR',
    name: 'Schwab Strategic Trust - Schwab Intermediate-Term U.S. Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'SCHV',
    name: 'Schwab Strategic Trust - Schwab U.S. Large-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'SCHX',
    name: 'Schwab Strategic Trust - Schwab U.S. Large-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SCHY',
    name: 'Schwab Strategic Trust - Schwab International Dividend Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SCHZ',
    name: 'Schwab Strategic Trust - Schwab US Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SCI',
    name: 'Service Corp. International',
    region: 'US',
  },
  {
    symbol: 'SCJ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Japan Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SCKT',
    name: 'Socket Mobile Inc',
    region: 'US',
  },
  {
    symbol: 'SCL',
    name: 'Stepan Co.',
    region: 'US',
  },
  {
    symbol: 'SCLE',
    name: 'Broadscale Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SCLEU',
    name: 'Broadscale Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'SCMA',
    name: 'Seaport Calibre Materials Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SCMAU',
    name: 'Seaport Calibre Materials Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SCO',
    name: 'ProShares Trust - ProShares UltraShort Bloomberg Crude Oil',
    region: 'US',
  },
  {
    symbol: 'SCOA',
    name: 'ScION Tech Growth I - Class A',
    region: 'US',
  },
  {
    symbol: 'SCOAU',
    name: 'ScION Tech Growth I - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SCOB',
    name: 'ScION Tech Growth II - Class A',
    region: 'US',
  },
  {
    symbol: 'SCOBU',
    name: 'ScION Tech Growth II - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SCOR',
    name: 'Comscore Inc.',
    region: 'US',
  },
  {
    symbol: 'SCPH',
    name: 'scPharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'SCPIX',
    name: 'Deutsche DWS Institutional Funds: DWS S&P 500 Index Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SCPL',
    name: 'Sciplay Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SCPS',
    name: 'Scopus Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'SCRD',
    name: 'Janus Capital Management LLC - Janus Henderson Sustainable Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SCRM',
    name: 'Screaming Eagle Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SCRMU',
    name: 'Screaming Eagle Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SCS',
    name: 'Steelcase, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SCSC',
    name: 'Scansource, Inc.',
    region: 'US',
  },
  {
    symbol: 'SCTL',
    name: 'Societal CDMO Inc',
    region: 'US',
  },
  {
    symbol: 'SCUA',
    name: 'Sculptor Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'SCVL',
    name: 'Shoe Carnival, Inc.',
    region: 'US',
  },
  {
    symbol: 'SCVPF',
    name: 'The Siam Cement Public Company Limited (Alien Market)',
    region: 'US',
  },
  {
    symbol: 'SCVPY',
    name: 'The Siam Cement Public Company Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'SCVUF',
    name: 'The Siam Cement Public Company Limited - NVDR',
    region: 'US',
  },
  {
    symbol: 'SCWX',
    name: 'SecureWorks Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SCX',
    name: 'L.S. Starrett Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'SCYX',
    name: 'Scynexis Inc',
    region: 'US',
  },
  {
    symbol: 'SCZ',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI EAFE Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SD',
    name: 'Sandridge Energy Inc New',
    region: 'US',
  },
  {
    symbol: 'SDAC',
    name: 'Sustainable Development Acquisition I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SDACU',
    name: 'Sustainable Development Acquisition I Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SDC',
    name: 'Smiledirectclub Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SDCI',
    name: 'USCF ETF Trust - USCF SummerHaven Dynamic Commodity Strategy No K-1 Fund',
    region: 'US',
  },
  {
    symbol: 'SDD',
    name: 'ProShares Trust - ProShares UltraShort SmallCap600',
    region: 'US',
  },
  {
    symbol: 'SDEF',
    name: 'Tidal ETF Trust - Sound Enhanced Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'SDEI',
    name: 'Tidal ETF Trust - Sound Equity Income ETF',
    region: 'US',
  },
  {
    symbol: 'SDEM',
    name: 'Global X Funds - Global X MSCI SuperDividend Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'SDG',
    name: 'iShares Trust - iShares MSCI Global Sustainable Development Goals ETF',
    region: 'US',
  },
  {
    symbol: 'SDGA',
    name: 'Impact Shares Trust I - Impact Shares Sustainable Development Goals Global Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SDGAX',
    name: 'Deutsche DWS Investment Trust: DWS Capital Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SDGR',
    name: 'Schrodinger Inc',
    region: 'US',
  },
  {
    symbol: 'SDGTX',
    name: 'Deutsche DWS Investment Trust: DWS Capital Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SDGZX',
    name: 'Deutsche DWS Investment Trust: DWS Capital Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SDH',
    name: 'Global Internet of People Inc',
    region: 'US',
  },
  {
    symbol: 'SDIG',
    name: 'Stronghold Digital Mining Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SDIV',
    name: 'Global X Funds - Global X SuperDividend ETF',
    region: 'US',
  },
  {
    symbol: 'SDLAX',
    name: 'SEI Institutional Investments Trust: Dynamic Asset Allocation Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SDMHF',
    name: 'Sartorius Stedim Biotech S.A.',
    region: 'US',
  },
  {
    symbol: 'SDOG',
    name: 'ALPS Advisors Inc - ALPS Sector Dividend Dogs ETF',
    region: 'US',
  },
  {
    symbol: 'SDOW',
    name: 'ProShares Trust - ProShares UltraPro Short Dow30',
    region: 'US',
  },
  {
    symbol: 'SDP',
    name: 'ProShares Trust - ProShares UltraShort Utilities',
    region: 'US',
  },
  {
    symbol: 'SDPI',
    name: 'Superior Drilling Products Inc',
    region: 'US',
  },
  {
    symbol: 'SDS',
    name: 'ProShares Trust - ProShares UltraShort S&P500',
    region: 'US',
  },
  {
    symbol: 'SDSCX',
    name: 'BNY Mellon Investment Funds I: BNY Mellon Small/Mid Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SDVKF',
    name: 'Sandvik AB',
    region: 'US',
  },
  {
    symbol: 'SDVKY',
    name: 'Sandvik AB - ADR',
    region: 'US',
  },
  {
    symbol: 'SDVY',
    name: 'First Trust Exchange-Traded Fund VI - First Trust SMID Cap Rising Dividend Achievers ETF',
    region: 'US',
  },
  {
    symbol: 'SDY',
    name: 'SPDR Series Trust - SPDR Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'SE',
    name: 'Sea Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SEA',
    name: 'ETF Series Solutions Trust - U.S. Global Sea to Sky Cargo ETF',
    region: 'US',
  },
  {
    symbol: 'SEAC',
    name: 'Seachange International Inc.',
    region: 'US',
  },
  {
    symbol: 'SEAS',
    name: 'SeaWorld Entertainment Inc',
    region: 'US',
  },
  {
    symbol: 'SEAT',
    name: 'Vivid Seats Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SEB',
    name: 'Seaboard Corp.',
    region: 'US',
  },
  {
    symbol: 'SEBYF',
    name: 'SEB',
    region: 'US',
  },
  {
    symbol: 'SEBYY',
    name: 'SEB - ADR',
    region: 'US',
  },
  {
    symbol: 'SECO',
    name: 'Secoo Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SECT',
    name: 'Northern Lights Fund Trust IV - Main Sector Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'SEDA',
    name: 'SDCL EDGE Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SEDG',
    name: 'Solaredge Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SEE',
    name: 'Sealed Air Corp.',
    region: 'US',
  },
  {
    symbol: 'SEED',
    name: 'Origin Agritech Ltd.',
    region: 'US',
  },
  {
    symbol: 'SEEL',
    name: 'Seelos Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SEER',
    name: 'Seer Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SEF',
    name: 'ProShares Trust - ProShares Short Financials',
    region: 'US',
  },
  {
    symbol: 'SEIC',
    name: 'SEI Investments Co.',
    region: 'US',
  },
  {
    symbol: 'SEIX',
    name: 'Virtus ETF Trust II - Virtus Seix Senior Loan ETF',
    region: 'US',
  },
  {
    symbol: 'SELB',
    name: 'Selecta Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'SEM',
    name: 'Select Medical Holdings Corporation',
    region: 'US',
  },
  {
    symbol: 'SEMI',
    name: 'Columbia ETF Trust I - Columbia Seligman Semiconductor and Technology ETF',
    region: 'US',
  },
  {
    symbol: 'SEMR',
    name: 'SEMrush Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SENAX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Enterprise Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SENEA',
    name: 'Seneca Foods Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'SENEB',
    name: 'Seneca Foods Corp. - Class B',
    region: 'US',
  },
  {
    symbol: 'SENS',
    name: 'Senseonics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SENT',
    name: 'Advisorshares Trust - AdvisorShares Alpha DNA Equity Sentiment ETF',
    region: 'US',
  },
  {
    symbol: 'SEPKX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Enterprise Fund; Administrator Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SEPZ',
    name: 'Listed Funds Trust - TrueShares Structured Outcome (September) ETF',
    region: 'US',
  },
  {
    symbol: 'SERA',
    name: 'Sera Prognostics Inc Class A',
    region: 'US',
  },
  {
    symbol: 'SES',
    name: 'SES AI Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'SESN',
    name: 'Sesen Bio Inc.',
    region: 'US',
  },
  {
    symbol: 'SEV',
    name: 'Sono Group N.V.',
    region: 'US',
  },
  {
    symbol: 'SFBC',
    name: 'Sound Financial Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'SFBQF',
    name: 'SoftBank Corp.',
    region: 'US',
  },
  {
    symbol: 'SFBS',
    name: 'ServisFirst Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'SFE',
    name: 'Safeguard Scientifics, Inc.',
    region: 'US',
  },
  {
    symbol: 'SFET',
    name: 'Safe-T Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SFIG',
    name: 'WisdomTree Trust - WisdomTree U.S. Short-Term Corporate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'SFIX',
    name: 'Stitch Fix Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SFL',
    name: 'SFL Corporation Ltd',
    region: 'US',
  },
  {
    symbol: 'SFLNX',
    name: 'Schwab Capital Trust: Schwab Fundamental US Large Company Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SFM',
    name: 'Sprouts Farmers Market Inc',
    region: 'US',
  },
  {
    symbol: 'SFNC',
    name: 'Simmons First National Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'SFNXF',
    name: 'Sofina',
    region: 'US',
  },
  {
    symbol: 'SFST',
    name: 'Southern First Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'SFT',
    name: 'Shift Technologies Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SFTBF',
    name: 'SoftBank Group Corp.',
    region: 'US',
  },
  {
    symbol: 'SFTBY',
    name: 'SoftBank Group Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'SFUN',
    name: 'Fang Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SFY',
    name: 'Tidal ETF Trust - SoFi Select 500 ETF',
    region: 'US',
  },
  {
    symbol: 'SFYF',
    name: 'Tidal ETF Trust - SoFi Social 50 ETF',
    region: 'US',
  },
  {
    symbol: 'SFYX',
    name: 'Tidal ETF Trust - SoFi Next 500 ETF',
    region: 'US',
  },
  {
    symbol: 'SG',
    name: 'Sweetgreen Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SGA',
    name: 'Saga Communications, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SGBX',
    name: 'SG Blocks Inc',
    region: 'US',
  },
  {
    symbol: 'SGC',
    name: 'Superior Group of Companies Inc..',
    region: 'US',
  },
  {
    symbol: 'SGDJ',
    name: 'Sprott Funds Trust. - Sprott Junior Gold Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SGDM',
    name: 'Sprott Funds Trust. - Sprott Gold Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SGEN',
    name: 'Seagen Inc',
    region: 'US',
  },
  {
    symbol: 'SGFY',
    name: 'Signify Health Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SGH',
    name: 'SMART Global Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SGHT',
    name: 'Sight Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'SGIEX',
    name: 'Columbia Funds Series Trust I: Columbia Contrarian Core Fund; Class V Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SGII',
    name: 'Seaport Global Acquisition II Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SGIIU',
    name: 'Seaport Global Acquisition II Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SGIOF',
    name: 'Shionogi & Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'SGIOY',
    name: 'Shionogi & Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SGLB',
    name: 'Sigma Labs Inc',
    region: 'US',
  },
  {
    symbol: 'SGLY',
    name: 'Singularity Future Technology Ltd',
    region: 'US',
  },
  {
    symbol: 'SGMA',
    name: 'Sigmatron International Inc.',
    region: 'US',
  },
  {
    symbol: 'SGML',
    name: 'Sigma Lithium Corporation',
    region: 'US',
  },
  {
    symbol: 'SGMO',
    name: 'Sangamo Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SGMS',
    name: 'Scientific Games Corporation',
    region: 'US',
  },
  {
    symbol: 'SGOL',
    name: 'abrdn ETF - abrdn Physical Gold Shares ETF',
    region: 'US',
  },
  {
    symbol: 'SGOV',
    name: 'iShares Trust - iShares 0-3 Month Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SGRHX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SGRP',
    name: 'Spar Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'SGRY',
    name: 'Surgery Partners Inc',
    region: 'US',
  },
  {
    symbol: 'SGSOF',
    name: 'SGS AG',
    region: 'US',
  },
  {
    symbol: 'SGSOY',
    name: 'SGS AG - ADR',
    region: 'US',
  },
  {
    symbol: 'SGTTX',
    name: 'Columbia Funds Series Trust II: Columbia Seligman Global Technology Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SGTX',
    name: 'Sigilon Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SGU',
    name: 'Star Group L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'SH',
    name: 'ProShares Trust - ProShares Short S&P500',
    region: 'US',
  },
  {
    symbol: 'SHAC',
    name: 'SCP & CO Healthcare Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'SHACU',
    name: 'SCP & CO Healthcare Acquisition Co - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SHAG',
    name: 'WisdomTree Trust - WisdomTree Yield Enhanced U.S. Short-Term Aggregate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'SHAK',
    name: 'Shake Shack Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SHBI',
    name: 'Shore Bancshares Inc.',
    region: 'US',
  },
  {
    symbol: 'SHC',
    name: 'Sotera Health Co',
    region: 'US',
  },
  {
    symbol: 'SHCA',
    name: 'Spindletop Health Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SHCAU',
    name: 'Spindletop Health Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SHCR',
    name: 'Sharecare Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SHE',
    name: 'SPDR Series Trust - SPDR SSGA Gender Diversity Index ETF',
    region: 'US',
  },
  {
    symbol: 'SHECF',
    name: 'Shin-Etsu Chemical Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'SHECY',
    name: 'Shin-Etsu Chemical Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SHEL',
    name: 'Shell Plc - ADR (Representing)',
    region: 'US',
  },
  {
    symbol: 'SHEN',
    name: 'Shenandoah Telecommunications Co.',
    region: 'US',
  },
  {
    symbol: 'SHFT',
    name: 'Tidal ETF Trust - iClima Distributed Smart Energy ETF',
    region: 'US',
  },
  {
    symbol: 'SHG',
    name: 'Shinhan Financial Group Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SHGTX',
    name: 'Columbia Funds Series Trust II: Columbia Seligman Global Technology Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SHI',
    name: 'Sinopec Shanghai Petrochemical Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SHIP',
    name: 'Seanergy Maritime Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'SHISX',
    name: 'BlackRock Funds: BlackRock Health Sciences Opportunities Portfolio; Service Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SHLAF',
    name: 'Schindler Holding AG - Non Voting',
    region: 'US',
  },
  {
    symbol: 'SHLRF',
    name: 'Schindler Holding AG',
    region: 'US',
  },
  {
    symbol: 'SHLS',
    name: 'Shoals Technologies Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SHLX',
    name: 'Shell Midstream Partners L.P. - Unit',
    region: 'US',
  },
  {
    symbol: 'SHM',
    name: 'SPDR Series Trust - SPDR Nuveen Bloomberg Short Term Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SHMDF',
    name: 'Shimano Inc.',
    region: 'US',
  },
  {
    symbol: 'SHMZF',
    name: 'Shimadzu Corporation',
    region: 'US',
  },
  {
    symbol: 'SHNUF',
    name: 'Intouch Holdings Public Company Limited (Alien Market)',
    region: 'US',
  },
  {
    symbol: 'SHOO',
    name: 'Steven Madden Ltd.',
    region: 'US',
  },
  {
    symbol: 'SHOP',
    name: 'Shopify Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SHPW',
    name: 'Shapeways Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SHQA',
    name: 'Shelter Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'SHQAU',
    name: 'Shelter Acquisition Corp I - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SHSAX',
    name: 'BlackRock Funds: BlackRock Health Sciences Opportunities Portfolio; Investor A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SHSKX',
    name: 'BlackRock Funds: BlackRock Health Sciences Opportunities Portfolio; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SHSSX',
    name: 'BlackRock Funds: BlackRock Health Sciences Opportunities Portfolio; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SHUS',
    name: 'Syntax ETF Trust - Syntax Stratified U.S. Total Market Hedged ETF',
    region: 'US',
  },
  {
    symbol: 'SHV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Short Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SHW',
    name: 'Sherwin-Williams Co.',
    region: 'US',
  },
  {
    symbol: 'SHY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 1-3 Year Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SHYD',
    name: 'VanEck ETF Trust - VanEck Short High Yield Muni ETF',
    region: 'US',
  },
  {
    symbol: 'SHYF',
    name: 'Shyft Group Inc (The)',
    region: 'US',
  },
  {
    symbol: 'SHYG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 0-5 Year High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SHYL',
    name: 'DBX ETF Trust - Xtrackers Short Duration High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SI',
    name: 'Silvergate Capital Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SIBN',
    name: 'SI-BONE Inc',
    region: 'US',
  },
  {
    symbol: 'SID',
    name: 'Companhia Siderurgica Nacional - ADR',
    region: 'US',
  },
  {
    symbol: 'SIDU',
    name: 'Sidus Space Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SIEB',
    name: 'Siebert Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'SIEN',
    name: 'Sientra Inc',
    region: 'US',
  },
  {
    symbol: 'SIER',
    name: 'Sierra Lake Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SIERU',
    name: 'Sierra Lake Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SIF',
    name: 'SIFCO Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'SIFI',
    name: 'Harbor ETF Trust - Harbor Scientific Alpha Income ETF',
    region: 'US',
  },
  {
    symbol: 'SIFY',
    name: 'Sify Technologies Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'SIG',
    name: 'Signet Jewelers Ltd',
    region: 'US',
  },
  {
    symbol: 'SIGA',
    name: 'SIGA Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SIGI',
    name: 'Selective Insurance Group Inc.',
    region: 'US',
  },
  {
    symbol: 'SIHY',
    name: 'Harbor ETF Trust - Harbor Scientific Alpha High-Yield ETF',
    region: 'US',
  },
  {
    symbol: 'SIJ',
    name: 'ProShares Trust - ProShares UltraShort Industrials',
    region: 'US',
  },
  {
    symbol: 'SIL',
    name: 'Global X Funds - Global X Silver Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SILC',
    name: 'Silicom Ltd',
    region: 'US',
  },
  {
    symbol: 'SILJ',
    name: 'ETF Managers Group LLC - ETFMG Prime Junior Silver Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SILK',
    name: 'Silk Road Medical Inc',
    region: 'US',
  },
  {
    symbol: 'SILV',
    name: 'SilverCrest Metals Inc',
    region: 'US',
  },
  {
    symbol: 'SILX',
    name: 'ETF Managers Trust - ETFMG Prime 2X Daily Junior Silver Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SIM',
    name: 'Grupo Simec S.A.B. de C.V. - ADR',
    region: 'US',
  },
  {
    symbol: 'SIMO',
    name: 'Silicon Motion Technology Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'SIMS',
    name: 'SPDR Series Trust - SPDR S&P Kensho Intelligent Structures ETF',
    region: 'US',
  },
  {
    symbol: 'SINAX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Large Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SINT',
    name: 'SINTX Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SINV',
    name: 'ETF Managers Trust - ETFMG Prime 2X Daily Inverse Junior Silver Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SIOX',
    name: 'Sio Gene Therapies Inc',
    region: 'US',
  },
  {
    symbol: 'SIRE',
    name: 'Sisecam Resources LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SIRI',
    name: 'Sirius XM Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SISI',
    name: 'Shineco Inc',
    region: 'US',
  },
  {
    symbol: 'SITE',
    name: 'SiteOne Landscape Supply Inc',
    region: 'US',
  },
  {
    symbol: 'SITM',
    name: 'SiTime Corp',
    region: 'US',
  },
  {
    symbol: 'SIVB',
    name: 'SVB Financial Group',
    region: 'US',
  },
  {
    symbol: 'SIVIX',
    name: 'State Street Institutional Funds: State Street Institutional Small-Cap Equity Fund; Investment Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SIVR',
    name: 'abrdn ETF - abrdn Physical Silver Shares ETF',
    region: 'US',
  },
  {
    symbol: 'SIX',
    name: 'Six Flags Entertainment Corp',
    region: 'US',
  },
  {
    symbol: 'SIXA',
    name: 'Exchange Traded Concepts Trust - ETC 6 Meridian Mega Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SIXH',
    name: 'Exchange Traded Concepts Trust - ETC 6 Meridian Hedged Equity Index Option ETF',
    region: 'US',
  },
  {
    symbol: 'SIXJ',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap 6 Month Buffer10 Jan/Jul ETF',
    region: 'US',
  },
  {
    symbol: 'SIXL',
    name: 'Exchange Traded Concepts Trust - ETC 6 Meridian Low Beta Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SIXO',
    name: 'AIM ETF Products Trust - AllianzIM U.S. Large Cap 6 Month Buffer10 Apr/Oct ETF',
    region: 'US',
  },
  {
    symbol: 'SIXS',
    name: 'Exchange Traded Concepts Trust - ETC 6 Meridian Small Cap Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SIZE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Size Factor ETF',
    region: 'US',
  },
  {
    symbol: 'SJ',
    name: 'Scienjoy Holding Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'SJB',
    name: 'ProShares Trust - ProShares Short High Yield',
    region: 'US',
  },
  {
    symbol: 'SJI',
    name: 'South Jersey Industries Inc.',
    region: 'US',
  },
  {
    symbol: 'SJM',
    name: 'J.M. Smucker Co.',
    region: 'US',
  },
  {
    symbol: 'SJNK',
    name: 'SPDR Series Trust - SPDR Bloomberg Short Term High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SJR',
    name: 'Shaw Communications Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'SJW',
    name: 'SJW Group',
    region: 'US',
  },
  {
    symbol: 'SKE',
    name: 'Skeena Resources Ltd',
    region: 'US',
  },
  {
    symbol: 'SKF',
    name: 'ProShares Trust - ProShares UltraShort Financials',
    region: 'US',
  },
  {
    symbol: 'SKFOF',
    name: 'Sika AG - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'SKFRY',
    name: 'SKF AB - ADR',
    region: 'US',
  },
  {
    symbol: 'SKIL',
    name: 'Skillsoft Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'SKIN',
    name: 'Beauty Health Company (The) - Class A',
    region: 'US',
  },
  {
    symbol: 'SKLZ',
    name: 'Skillz Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SKM',
    name: 'SK Telecom Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SKOR',
    name: 'FlexShares Trust - FlexShares Credit-Scored US Corporate Bond Index Fund',
    region: 'US',
  },
  {
    symbol: 'SKSUF',
    name: 'Sekisui Chemical Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'SKSUY',
    name: 'Sekisui Chemical Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SKUFF',
    name: 'SKF AB - Class B',
    region: 'US',
  },
  {
    symbol: 'SKX',
    name: 'Skechers U S A, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'SKY',
    name: 'Skyline Champion Corp',
    region: 'US',
  },
  {
    symbol: 'SKYA',
    name: 'Skydeck Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SKYAU',
    name: 'Skydeck Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SKYH',
    name: 'Sky Harbour Group Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'SKYT',
    name: 'SkyWater Technology Inc',
    region: 'US',
  },
  {
    symbol: 'SKYU',
    name: 'ProShares Trust - ProShares Ultra Nasdaq Cloud Computing ETF',
    region: 'US',
  },
  {
    symbol: 'SKYW',
    name: 'Skywest Inc.',
    region: 'US',
  },
  {
    symbol: 'SKYX',
    name: 'SQL Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'SKYY',
    name: 'First Trust Exchange-Traded Fund III - First Trust Cloud Computing ETF',
    region: 'US',
  },
  {
    symbol: 'SLAB',
    name: 'Silicon Laboratories Inc',
    region: 'US',
  },
  {
    symbol: 'SLAC',
    name: 'Social Leverage Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'SLAM',
    name: 'Slam Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SLAMU',
    name: 'Slam Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'SLB',
    name: 'Schlumberger Ltd.',
    region: 'US',
  },
  {
    symbol: 'SLCA',
    name: 'U.S. Silica Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SLCR',
    name: 'Silver Crest Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SLCRU',
    name: 'Silver Crest Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SLDB',
    name: 'Solid Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'SLDP',
    name: 'Solid Power Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SLF',
    name: 'Sun Life Financial, Inc.',
    region: 'US',
  },
  {
    symbol: 'SLGC',
    name: 'SomaLogic Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SLGG',
    name: 'Super League Gaming Inc',
    region: 'US',
  },
  {
    symbol: 'SLGL',
    name: 'Sol-Gel Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'SLGN',
    name: 'Silgan Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'SLHG',
    name: 'Skylight Health Group Inc',
    region: 'US',
  },
  {
    symbol: 'SLI',
    name: 'Standard Lithium Ltd',
    region: 'US',
  },
  {
    symbol: 'SLN',
    name: 'Silence Therapeutics Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'SLNG',
    name: 'Stabilis Solutions Inc - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'SLNH',
    name: 'Soluna Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SLNO',
    name: 'Soleno Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SLP',
    name: 'Simulations Plus Inc.',
    region: 'US',
  },
  {
    symbol: 'SLQD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 0-5 Year Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SLQT',
    name: 'SelectQuote Inc',
    region: 'US',
  },
  {
    symbol: 'SLRX',
    name: 'Salarius Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'SLS',
    name: 'SELLAS Life Sciences Group Inc',
    region: 'US',
  },
  {
    symbol: 'SLV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Silver Trust',
    region: 'US',
  },
  {
    symbol: 'SLVAX',
    name: 'Columbia Funds Series Trust II: Columbia Select Large Cap Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SLVIX',
    name: 'Columbia Funds Series Trust II: Columbia Select Large Cap Value Fund; Institutional 2 Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SLVM',
    name: 'Sylvamo Corp',
    region: 'US',
  },
  {
    symbol: 'SLVP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Silver and Metals Miners ETF',
    region: 'US',
  },
  {
    symbol: 'SLVR',
    name: 'SilverSPAC Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SLVRU',
    name: 'SilverSPAC Inc - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'SLVYY',
    name: 'Solvay SA - ADR - New',
    region: 'US',
  },
  {
    symbol: 'SLX',
    name: 'VanEck ETF Trust - VanEck Steel ETF',
    region: 'US',
  },
  {
    symbol: 'SLY',
    name: 'SPDR Series Trust - SPDR S&P 600 Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SLYG',
    name: 'SPDR Series Trust - SPDR S&P 600 Small Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'SLYV',
    name: 'SPDR Series Trust - SPDR S&P 600 Small Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'SM',
    name: 'SM Energy Co',
    region: 'US',
  },
  {
    symbol: 'SMAP',
    name: 'SportsMap Tech Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'SMAPU',
    name: 'SportsMap Tech Acquisition Corp - Units (1 Ord Share & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'SMAR',
    name: 'Smartsheet Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SMB',
    name: 'VanEck ETF Trust - VanEck Short Muni ETF',
    region: 'US',
  },
  {
    symbol: 'SMBC',
    name: 'Southern Missouri Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'SMBK',
    name: 'SmartFinancial Inc',
    region: 'US',
  },
  {
    symbol: 'SMBYX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Mid Cap Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMCI',
    name: 'Super Micro Computer Inc',
    region: 'US',
  },
  {
    symbol: 'SMCP',
    name: 'Alphamark advisors LLC - AlphaMark Actively Managed Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SMCPX',
    name: 'Legg Mason Partners Equity Trust: ClearBridge Mid Cap Fund; Class 1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMDD',
    name: 'ProShares Trust - ProShares UltraPro Short MidCap400',
    region: 'US',
  },
  {
    symbol: 'SMDIX',
    name: 'Hartford Mutual Funds II, Inc: Hartford Schroders US MidCap Opportunities Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMDV',
    name: 'ProShares Trust - ProShares Russell 2000 Div Growers ETF',
    region: 'US',
  },
  {
    symbol: 'SMDVX',
    name: 'Hartford Mutual Funds II, Inc: Hartford Schroders US MidCap Opportunities Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMDY',
    name: 'Syntax ETF Trust - Syntax Stratified MidCap ETF',
    region: 'US',
  },
  {
    symbol: 'SMED',
    name: 'Sharps Compliance Corp.',
    region: 'US',
  },
  {
    symbol: 'SMFG',
    name: 'Sumitomo Mitsui Financial Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'SMFL',
    name: 'Smart for Life Inc',
    region: 'US',
  },
  {
    symbol: 'SMFR',
    name: 'Sema4 Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SMG',
    name: 'Scotts Miracle-Gro Company - Class A',
    region: 'US',
  },
  {
    symbol: 'SMGIX',
    name: 'Columbia Funds Series Trust I: Columbia Contrarian Core Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMH',
    name: 'VanEck ETF Trust - VanEck Semiconductor ETF',
    region: 'US',
  },
  {
    symbol: 'SMHI',
    name: 'SEACOR Marine Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SMI',
    name: 'VanEck ETF Trust - VanEck HIP Sustainable Muni ETF',
    region: 'US',
  },
  {
    symbol: 'SMID',
    name: 'Smith-Midland Corp.',
    region: 'US',
  },
  {
    symbol: 'SMIG',
    name: 'ETF Series Solutions Trust - AAM Bahl & Gaynor Small/Mid Cap Income Growth ETF',
    region: 'US',
  },
  {
    symbol: 'SMIH',
    name: 'Summit Healthcare Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SMIHU',
    name: 'Summit Healthcare Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SMIN',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI India Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SMIT',
    name: 'Schmitt Industries Inc',
    region: 'US',
  },
  {
    symbol: 'SMLE',
    name: 'DBX ETF Trust - Xtrackers S&P SmallCap 600 ESG ETF',
    region: 'US',
  },
  {
    symbol: 'SMLF',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Small-Cap Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'SMLP',
    name: 'Summit Midstream Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SMLR',
    name: 'Semler Scientific Inc',
    region: 'US',
  },
  {
    symbol: 'SMLV',
    name: 'SPDR Series Trust - SPDR SSGA US Small Cap Low Volatility Index ETF',
    region: 'US',
  },
  {
    symbol: 'SMMD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Russell 2500 ETF',
    region: 'US',
  },
  {
    symbol: 'SMMF',
    name: 'Summit Financial Group Inc',
    region: 'US',
  },
  {
    symbol: 'SMMT',
    name: 'Summit Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SMMU',
    name: 'Pimco Exchange Traded Fund - PIMCO Short Term Municipal Bond Active Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'SMMV',
    name: 'iShares Trust - iShares MSCI USA Small-Cap Min Vol Factor ETF',
    region: 'US',
  },
  {
    symbol: 'SMMYY',
    name: 'Sumitomo Metal Mining Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SMN',
    name: 'ProShares Trust - ProShares UltraShort Basic Materials',
    region: 'US',
  },
  {
    symbol: 'SMNNY',
    name: 'Shimano Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'SMOG',
    name: 'VanEck ETF Trust - VanEck Low Carbon Energy ETF',
    region: 'US',
  },
  {
    symbol: 'SMP',
    name: 'Standard Motor Products, Inc.',
    region: 'US',
  },
  {
    symbol: 'SMPFF',
    name: 'San Miguel Food and Beverage Inc',
    region: 'US',
  },
  {
    symbol: 'SMPL',
    name: 'Simply Good Foods Co',
    region: 'US',
  },
  {
    symbol: 'SMPNY',
    name: 'Sompo Holdings Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'SMRT',
    name: 'SmartRent Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SMSI',
    name: 'Smith Micro Software, Inc.',
    region: 'US',
  },
  {
    symbol: 'SMTC',
    name: 'Semtech Corp.',
    region: 'US',
  },
  {
    symbol: 'SMTI',
    name: 'Sanara MedTech Inc',
    region: 'US',
  },
  {
    symbol: 'SMTOF',
    name: 'Sumitomo Electric Industries Ltd.',
    region: 'US',
  },
  {
    symbol: 'SMTOY',
    name: 'Sumitomo Electric Industries Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SMTS',
    name: 'Sierra Metals Inc',
    region: 'US',
  },
  {
    symbol: 'SMUUY',
    name: 'Siam Commercial Bank Public Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SMVLX',
    name: 'Smead Funds Trust: Smead Value Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMVTX',
    name: 'Virtus Asset Trust: Virtus Ceredex Mid-Cap Value Equity Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMVZX',
    name: 'Virtus Asset Trust: Virtus Ceredex Mid-Cap Value Equity Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SMWB',
    name: 'Similarweb Ltd',
    region: 'US',
  },
  {
    symbol: 'SNA',
    name: 'Snap-on, Inc.',
    region: 'US',
  },
  {
    symbol: 'SNAP',
    name: 'Snap Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SNAX',
    name: 'Stryve Foods Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SNBR',
    name: 'Sleep Number Corp',
    region: 'US',
  },
  {
    symbol: 'SNCE',
    name: 'Science 37 Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SNCR',
    name: 'Synchronoss Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SNCY',
    name: 'Sun Country Airlines Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SND',
    name: 'Smart Sand Inc',
    region: 'US',
  },
  {
    symbol: 'SNDA',
    name: 'Sonida Senior Living Inc',
    region: 'US',
  },
  {
    symbol: 'SNDL',
    name: 'Sundial Growers Inc',
    region: 'US',
  },
  {
    symbol: 'SNDR',
    name: 'Schneider National Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'SNDX',
    name: 'Syndax Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'SNES',
    name: 'SenesTech Inc',
    region: 'US',
  },
  {
    symbol: 'SNFCA',
    name: 'Security National Financial Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'SNGX',
    name: 'Soligenix Inc',
    region: 'US',
  },
  {
    symbol: 'SNLN',
    name: 'Highland Capital Management Fund Advisors L.P - Highland/iBoxx Senior Loan ETF',
    region: 'US',
  },
  {
    symbol: 'SNMP',
    name: 'Evolve Transition Infrastructure LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SNMRF',
    name: 'Snam S.p.A.',
    region: 'US',
  },
  {
    symbol: 'SNMRY',
    name: 'Snam S.p.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'SNN',
    name: 'Smith & Nephew plc - ADR',
    region: 'US',
  },
  {
    symbol: 'SNOA',
    name: 'Sonoma Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'SNOW',
    name: 'Snowflake Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SNP',
    name: 'China Petroleum & Chemical Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'SNPE',
    name: 'DBX ETF Trust - Xtrackers S&P 500 ESG ETF',
    region: 'US',
  },
  {
    symbol: 'SNPO',
    name: 'Snap One Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'SNPS',
    name: 'Synopsys, Inc.',
    region: 'US',
  },
  {
    symbol: 'SNPX',
    name: 'Synaptogenix Inc',
    region: 'US',
  },
  {
    symbol: 'SNRH',
    name: 'Senior Connect Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'SNRHU',
    name: 'Senior Connect Acquisition Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SNSE',
    name: 'Sensei Biotherapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SNSR',
    name: 'Global X Funds - Global X Funds Global X Internet of Things ETF',
    region: 'US',
  },
  {
    symbol: 'SNT',
    name: 'Senstar Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'SNUG',
    name: 'Alpha Architect Etf Trust - Merlyn.AI Tactical Growth and Income ETF',
    region: 'US',
  },
  {
    symbol: 'SNV',
    name: 'Synovus Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'SNX',
    name: 'TD Synnex Corp',
    region: 'US',
  },
  {
    symbol: 'SNXFX',
    name: 'Schwab Investments: Schwab 1000 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SNY',
    name: 'Sanofi - ADR',
    region: 'US',
  },
  {
    symbol: 'SO',
    name: 'Southern Company',
    region: 'US',
  },
  {
    symbol: 'SOAGY',
    name: 'Sartorius AG - ADR (Representing Preference Share)',
    region: 'US',
  },
  {
    symbol: 'SOBKY',
    name: 'SoftBank Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'SOCL',
    name: 'Global X Funds - Global X Social Media ETF',
    region: 'US',
  },
  {
    symbol: 'SOFI',
    name: 'SoFi Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SOFO',
    name: 'Sonic Foundry, Inc.',
    region: 'US',
  },
  {
    symbol: 'SOGU',
    name: 'Collaborative Investment Series Trust - The Short De-SPAC ETF',
    region: 'US',
  },
  {
    symbol: 'SOHU',
    name: 'Sohu.com Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SOI',
    name: 'Solaris Oilfield Infrastructure Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SOL',
    name: 'Renesola - ADR',
    region: 'US',
  },
  {
    symbol: 'SOLO',
    name: 'Electrameccanica Vehicles Corp',
    region: 'US',
  },
  {
    symbol: 'SOLR',
    name: 'Guinness Atkinson Funds - SmartETFs Sustainable Energy II ETF',
    region: 'US',
  },
  {
    symbol: 'SOMLF',
    name: 'Secom Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'SOMLY',
    name: 'Secom Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SOMMF',
    name: 'Sumitomo Chemical Company Limited',
    region: 'US',
  },
  {
    symbol: 'SOMMY',
    name: 'Sumitomo Chemical Company Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'SON',
    name: 'Sonoco Products Co.',
    region: 'US',
  },
  {
    symbol: 'SOND',
    name: 'Sonder Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SONM',
    name: 'Sonim Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SONN',
    name: 'Sonnet BioTherapeutics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SONO',
    name: 'Sonos Inc',
    region: 'US',
  },
  {
    symbol: 'SONVF',
    name: 'Sonova Holding AG',
    region: 'US',
  },
  {
    symbol: 'SONVY',
    name: 'Sonova Holding AG - ADR',
    region: 'US',
  },
  {
    symbol: 'SONX',
    name: 'Sonendo Inc',
    region: 'US',
  },
  {
    symbol: 'SONY',
    name: 'Sony Group Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'SOPA',
    name: 'Society Pass Inc',
    region: 'US',
  },
  {
    symbol: 'SOPH',
    name: 'SOPHiA Genetics SA',
    region: 'US',
  },
  {
    symbol: 'SOS',
    name: 'SOS Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'SOTK',
    name: 'Sono-Tek Corp.',
    region: 'US',
  },
  {
    symbol: 'SOVO',
    name: 'Sovos Brands Inc',
    region: 'US',
  },
  {
    symbol: 'SOXL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Semiconductor Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'SOXQ',
    name: 'Invesco Exchange-Traded Fund Trust II - Invesco PHLX Semiconductor ETF',
    region: 'US',
  },
  {
    symbol: 'SOXS',
    name: 'Direxion Shares ETF Trust - Direxion Daily Semiconductor Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'SOXX',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Semiconductor ETF',
    region: 'US',
  },
  {
    symbol: 'SOYB',
    name: 'Teucrium Trading, LLC - Teucrium Soybean Fund',
    region: 'US',
  },
  {
    symbol: 'SP',
    name: 'SP Plus Corp',
    region: 'US',
  },
  {
    symbol: 'SPAB',
    name: 'SPDR Series Trust - SPDR Portfolio Aggregate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPAK',
    name: 'ETF Series Solutions Trust - Defiance Next Gen SPAC Derived ETF',
    region: 'US',
  },
  {
    symbol: 'SPAX',
    name: 'Tidal ETF Trust - Robinson Alternative Yield Pre-merger SPAC ETF',
    region: 'US',
  },
  {
    symbol: 'SPB',
    name: 'Spectrum Brands Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'SPBC',
    name: 'Simplify Exchange Traded Funds - Simplify U.S. Equity PLUS GBTC ETF',
    region: 'US',
  },
  {
    symbol: 'SPBO',
    name: 'SPDR Series Trust - SPDR Portfolio Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPC',
    name: 'Trust For Professional Managers - CrossingBridge Pre-Merger SPAC ETF',
    region: 'US',
  },
  {
    symbol: 'SPCB',
    name: 'SuperCom Ltd',
    region: 'US',
  },
  {
    symbol: 'SPCE',
    name: 'Virgin Galactic Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SPCM',
    name: 'Sound Point Acquisition Corp I Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'SPCMU',
    name: 'Sound Point Acquisition Corp I Ltd - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SPCX',
    name: 'Collaborative Investment Series Trust - The SPAC and New Issue ETF',
    region: 'US',
  },
  {
    symbol: 'SPD',
    name: 'Simplify Exchange Traded Funds - Simplify US Equity PLUS Downside Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'SPDN',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 Bear 1X Shares',
    region: 'US',
  },
  {
    symbol: 'SPDV',
    name: 'ETF Series Solutions Trust - AAM S&P 500 High Dividend Value ETF',
    region: 'US',
  },
  {
    symbol: 'SPDW',
    name: 'SSgA Active Trust - SPDR Portfolio Developed World ex-US ETF',
    region: 'US',
  },
  {
    symbol: 'SPEM',
    name: 'SSgA Active Trust - SPDR Portfolio Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'SPEU',
    name: 'SSgA Active Trust - SPDR Portfolio Europe ETF',
    region: 'US',
  },
  {
    symbol: 'SPFAX',
    name: 'Prudential Investment Portfolios 3: PGIM Jennison Focused Growth Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SPFF',
    name: 'Global X Funds - Global X SuperIncome Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'SPFI',
    name: 'South Plains Financial Inc',
    region: 'US',
  },
  {
    symbol: 'SPFZX',
    name: 'Prudential Investment Portfolios 3: PGIM Jennison Focused Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SPGI',
    name: 'S&P Global Inc',
    region: 'US',
  },
  {
    symbol: 'SPGM',
    name: 'SSgA Active Trust - SPDR Portfolio MSCI Global Stock Market ETF',
    region: 'US',
  },
  {
    symbol: 'SPGP',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 GARP ETF',
    region: 'US',
  },
  {
    symbol: 'SPGS',
    name: 'Simon Property Group Acquisition Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SPH',
    name: 'Suburban Propane Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SPHB',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 High Beta ETF',
    region: 'US',
  },
  {
    symbol: 'SPHD',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 High Dividend Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'SPHQ',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Quality ETF',
    region: 'US',
  },
  {
    symbol: 'SPHY',
    name: 'SPDR Series Trust - SPDR Portfolio High Yield Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPI',
    name: 'SPI Energy Co Ltd',
    region: 'US',
  },
  {
    symbol: 'SPIB',
    name: 'SSgA Active Trust - SPDR Portfolio Intermediate Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPIDX',
    name: 'AIM Counselor Series Trust (Invesco Counselor Series Trust): Invesco S&P 500 Index Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SPINX',
    name: 'SEI Institutional Investments Trust: S&P 500 Index Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SPIP',
    name: 'SPDR Series Trust - SPDR Portfolio TIPS ETF',
    region: 'US',
  },
  {
    symbol: 'SPIR',
    name: 'Spire Global Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SPK',
    name: 'SPK Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'SPKAU',
    name: 'SPK Acquisition Corp - Units (1 Ord & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'SPKB',
    name: 'Silver Spike Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'SPKBU',
    name: 'Silver Spike Acquisition Corp II - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'SPLB',
    name: 'SSgA Active Trust - SPDR Portfolio Long Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPLG',
    name: 'SPDR Series Trust - SPDR Portfolio S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'SPLK',
    name: 'Splunk Inc',
    region: 'US',
  },
  {
    symbol: 'SPLP',
    name: 'Steel Partners Holdings LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SPLV',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'SPMB',
    name: 'SPDR Series Trust - SPDR Portfolio Mortgage Backed Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPMD',
    name: 'SPDR Series Trust - SPDR Portfolio S&P 400 Mid Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SPMO',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'SPMV',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Minimum Variance ETF',
    region: 'US',
  },
  {
    symbol: 'SPNE',
    name: 'SeaSpine Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'SPNS',
    name: 'Sapiens International Corp NV',
    region: 'US',
  },
  {
    symbol: 'SPNT',
    name: 'SiriusPoint Ltd',
    region: 'US',
  },
  {
    symbol: 'SPOK',
    name: 'Spok Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SPOT',
    name: 'Spotify Technology S.A.',
    region: 'US',
  },
  {
    symbol: 'SPPI',
    name: 'Spectrum Pharmaceuticals, Inc.',
    region: 'US',
  },
  {
    symbol: 'SPR',
    name: 'Spirit Aerosystems Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SPRB',
    name: 'Spruce Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'SPRC',
    name: 'SciSparc Ltd',
    region: 'US',
  },
  {
    symbol: 'SPRE',
    name: 'Tidal ETF Trust - SP Funds S&P Global REIT Sharia ETF',
    region: 'US',
  },
  {
    symbol: 'SPRO',
    name: 'Spero Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SPRX',
    name: 'Listed Funds Trust - Spear Alpha ETF',
    region: 'US',
  },
  {
    symbol: 'SPSB',
    name: 'SSgA Active Trust - SPDR Portfolio Short Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPSC',
    name: 'SPS Commerce Inc.',
    region: 'US',
  },
  {
    symbol: 'SPSK',
    name: 'Tidal ETF Trust - SP Funds Dow Jones Global Sukuk ETF',
    region: 'US',
  },
  {
    symbol: 'SPSM',
    name: 'SSgA Active Trust - SPDR Portfolio S&P 600 Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'SPT',
    name: 'Sprout Social Inc Class A',
    region: 'US',
  },
  {
    symbol: 'SPTI',
    name: 'SPDR Series Trust - SPDR Portfolio Intermediate Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'SPTK',
    name: 'SportsTek Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SPTKU',
    name: 'SportsTek Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SPTL',
    name: 'SSgA Active Trust - SPDR Portfolio Long Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'SPTM',
    name: 'SPDR Series Trust - SPDR Portfolio S&P 1500 Composite Stock Market ETF',
    region: 'US',
  },
  {
    symbol: 'SPTN',
    name: 'SpartanNash Co',
    region: 'US',
  },
  {
    symbol: 'SPTS',
    name: 'SSgA Active Trust - SPDR Portfolio Short Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'SPUC',
    name: 'Simplify Exchange Traded Funds - Simplify US Equity PLUS Upside Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'SPUS',
    name: 'Tidal ETF Trust - SP Funds S&P 500 Sharia Industry Exclusions ETF',
    region: 'US',
  },
  {
    symbol: 'SPUU',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'SPVM',
    name: 'Invesco Capital Management LLC - Invesco Russell Top 200 Pure Value ETF',
    region: 'US',
  },
  {
    symbol: 'SPVU',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Enhanced Value ETF',
    region: 'US',
  },
  {
    symbol: 'SPWH',
    name: 'Sportsman`s Warehouse Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SPWR',
    name: 'Sunpower Corp',
    region: 'US',
  },
  {
    symbol: 'SPXB',
    name: 'ProShares Trust - ProShares S&P 500 Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SPXC',
    name: 'SPX Corp.',
    region: 'US',
  },
  {
    symbol: 'SPXE',
    name: 'ProShares Trust - ProShares S&P 500 Ex-Energy ETF',
    region: 'US',
  },
  {
    symbol: 'SPXL',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'SPXN',
    name: 'ProShares Trust - ProShares S&P 500 Ex-Financials ETF',
    region: 'US',
  },
  {
    symbol: 'SPXS',
    name: 'Direxion Shares ETF Trust - Direxion Daily S&P 500 Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'SPXT',
    name: 'ProShares Trust - ProShares S&P 500 Ex-Technology ETF',
    region: 'US',
  },
  {
    symbol: 'SPXU',
    name: 'ProShares Trust - ProShares UltraPro Short S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'SPXV',
    name: 'ProShares Trust - ProShares S&P 500 Ex-Health Care ETF',
    region: 'US',
  },
  {
    symbol: 'SPXZ',
    name: 'Listed Funds Trust - Morgan Creek - Exos SPAC Originated ETF',
    region: 'US',
  },
  {
    symbol: 'SPY',
    name: 'SSgA Active Trust - S&P 500 ETF TRUST ETF',
    region: 'US',
  },
  {
    symbol: 'SPYC',
    name: 'Simplify Exchange Traded Funds - Simplify US Equity PLUS Convexity ETF',
    region: 'US',
  },
  {
    symbol: 'SPYD',
    name: 'SPDR Series Trust - SPDR Portfolio S&P 500 High Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'SPYG',
    name: 'SPDR Series Trust - SPDR Portfolio S&P 500 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'SPYV',
    name: 'SPDR Series Trust - SPDR Portfolio S&P 500 Value ETF',
    region: 'US',
  },
  {
    symbol: 'SPYX',
    name: 'SPDR Series Trust - SSGA SPDR S&P 500 Fossil Fuel Free ETF',
    region: 'US',
  },
  {
    symbol: 'SQ',
    name: 'Block Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SQEW',
    name: 'Two Roads Shared Trust - LeaderShares Equity Skew ETF',
    region: 'US',
  },
  {
    symbol: 'SQL',
    name: 'SeqLL Inc.',
    region: 'US',
  },
  {
    symbol: 'SQLV',
    name: 'Legg Mason ETF Investment Trust - Legg Mason Small-Cap Quality Value ETF',
    region: 'US',
  },
  {
    symbol: 'SQM',
    name: 'Sociedad Quimica Y Minera de Chile S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'SQNS',
    name: 'Sequans Communications S.A - ADR',
    region: 'US',
  },
  {
    symbol: 'SQQQ',
    name: 'ProShares Trust - ProShares UltraPro Short QQQ',
    region: 'US',
  },
  {
    symbol: 'SQSP',
    name: 'Squarespace Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SQZ',
    name: 'SQZ Biotechnologies Co',
    region: 'US',
  },
  {
    symbol: 'SR',
    name: 'Spire Inc.',
    region: 'US',
  },
  {
    symbol: 'SRAD',
    name: 'Sportradar Group AG - Class A',
    region: 'US',
  },
  {
    symbol: 'SRAX',
    name: 'SRAX Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SRCE',
    name: '1st Source Corp.',
    region: 'US',
  },
  {
    symbol: 'SRCL',
    name: 'Stericycle Inc.',
    region: 'US',
  },
  {
    symbol: 'SRDX',
    name: 'Surmodics, Inc.',
    region: 'US',
  },
  {
    symbol: 'SRE',
    name: 'Sempra Energy',
    region: 'US',
  },
  {
    symbol: 'SRET',
    name: 'Global X Funds - Global X SuperDividend REIT ETF',
    region: 'US',
  },
  {
    symbol: 'SREV',
    name: 'ServiceSource International Inc',
    region: 'US',
  },
  {
    symbol: 'SRGA',
    name: 'Surgalign Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SRI',
    name: 'Stoneridge Inc.',
    region: 'US',
  },
  {
    symbol: 'SRL',
    name: 'Scully Royalty Ltd',
    region: 'US',
  },
  {
    symbol: 'SRLN',
    name: 'SSgA Active Trust - SPDR Blackstone Senior Loan ETF',
    region: 'US',
  },
  {
    symbol: 'SRLP',
    name: 'Sprague Resources LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SRNE',
    name: 'Sorrento Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SRPT',
    name: 'Sarepta Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SRRA',
    name: 'Sierra Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'SRRK',
    name: 'Scholar Rock Holding Corp',
    region: 'US',
  },
  {
    symbol: 'SRS',
    name: 'ProShares Trust - ProShares UltraShort Real Estate',
    region: 'US',
  },
  {
    symbol: 'SRSA',
    name: 'Sarissa Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SRSAU',
    name: 'Sarissa Capital Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SRT',
    name: 'Startek, Inc.',
    region: 'US',
  },
  {
    symbol: 'SRTOY',
    name: 'Sartorius Stedim Biotech S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'SRTS',
    name: 'Sensus Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'SRTTY',
    name: 'Zozo Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'SRTY',
    name: 'ProShares Trust - ProShares UltraPro Short Russell2000',
    region: 'US',
  },
  {
    symbol: 'SRVR',
    name: 'Pacer Funds Trust - Pacer Benchmark Data & Infrastructure Real Estate SCTR ETF',
    region: 'US',
  },
  {
    symbol: 'SRZN',
    name: 'Surrozen Inc',
    region: 'US',
  },
  {
    symbol: 'SSAA',
    name: 'Science Strategic Acquisition Corp Alpha - Class A',
    region: 'US',
  },
  {
    symbol: 'SSAAU',
    name: 'Science Strategic Acquisition Corp Alpha - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'SSB',
    name: 'SouthState Corporation',
    region: 'US',
  },
  {
    symbol: 'SSBI',
    name: 'Summit St. Bank',
    region: 'US',
  },
  {
    symbol: 'SSBK',
    name: 'Southern States Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'SSD',
    name: 'Simpson Manufacturing Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'SSDIY',
    name: 'Samsung SDI Co. Ltd. - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'SSFI',
    name: 'Strategy Shares - Day Hagan/Ned Davis Research Smart Sector Fixed Income ETF',
    region: 'US',
  },
  {
    symbol: 'SSG',
    name: 'ProShares Trust - ProShares UltraShort Semiconductors',
    region: 'US',
  },
  {
    symbol: 'SSKN',
    name: 'STRATA Skin Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'SSL',
    name: 'Sasol Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SSLY',
    name: 'Syntax ETF Trust - Syntax Stratified SmallCap ETF',
    region: 'US',
  },
  {
    symbol: 'SSMAX',
    name: 'SEI Institutional Investments Trust: Small/Mid Cap Equity Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SSMMF',
    name: 'Samsung Life Insurance Co Ltd',
    region: 'US',
  },
  {
    symbol: 'SSMXF',
    name: 'Sysmex Corporation',
    region: 'US',
  },
  {
    symbol: 'SSMXY',
    name: 'Sysmex Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'SSNC',
    name: 'SS&C Technologies Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SSNGY',
    name: 'Samsung Electronics - GDR - 144A Preference Shares 0%',
    region: 'US',
  },
  {
    symbol: 'SSNHZ',
    name: 'Samsung Electronics - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'SSNLF',
    name: 'Samsung Electronics',
    region: 'US',
  },
  {
    symbol: 'SSNT',
    name: 'SilverSun Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'SSO',
    name: 'ProShares Trust - ProShares Ultra S&P500',
    region: 'US',
  },
  {
    symbol: 'SSP',
    name: 'E.W. Scripps Co. - Class A',
    region: 'US',
  },
  {
    symbol: 'SSPIX',
    name: 'SEI Institutional Managed Trust: S&P 500 Index Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SSPX',
    name: 'Janus Capital Management LLC - Janus Henderson U.S. Sustainable Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SSPY',
    name: 'Syntax ETF Trust - Syntax Stratified LargeCap ETF',
    region: 'US',
  },
  {
    symbol: 'SSQSX',
    name: 'State Street Institutional Funds: State Street Institutional Small-Cap Equity Fund; Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SSREF',
    name: 'Swiss Re Ltd',
    region: 'US',
  },
  {
    symbol: 'SSREY',
    name: 'Swiss Re Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SSRM',
    name: 'SSR Mining Inc',
    region: 'US',
  },
  {
    symbol: 'SSSWX',
    name: 'State Street Institutional Investment Trust: State Street Equity 500 Index Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SSSYX',
    name: 'State Street Institutional Investment Trust: State Street Equity 500 Index Fund; Class K Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SST',
    name: 'System1 Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SSTI',
    name: 'ShotSpotter Inc',
    region: 'US',
  },
  {
    symbol: 'SSTK',
    name: 'Shutterstock Inc',
    region: 'US',
  },
  {
    symbol: 'SSU',
    name: 'SIGNA Sports United N.V.',
    region: 'US',
  },
  {
    symbol: 'SSUS',
    name: 'Strategy Shares - Day Hagan/Ned Davis Research Smart Sector ETF',
    region: 'US',
  },
  {
    symbol: 'SSY',
    name: 'Sunlink Health Systems Inc',
    region: 'US',
  },
  {
    symbol: 'SSYS',
    name: 'Stratasys Ltd',
    region: 'US',
  },
  {
    symbol: 'ST',
    name: 'Sensata Technologies Holding Plc',
    region: 'US',
  },
  {
    symbol: 'STAA',
    name: 'Staar Surgical Co.',
    region: 'US',
  },
  {
    symbol: 'STAB',
    name: 'Statera BioPharma Inc',
    region: 'US',
  },
  {
    symbol: 'STAF',
    name: 'Staffing 360 Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'STBA',
    name: 'S & T Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'STBFF',
    name: 'Suntory Beverage & Food Limited',
    region: 'US',
  },
  {
    symbol: 'STBFY',
    name: 'Suntory Beverage & Food Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'STBIX',
    name: 'State Street Institutional Investment Trust: State Street Equity 500 Index Fund; Service Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'STBL',
    name: 'Rbb Fund Inc - DriveWealth Steady Saver ETF',
    region: 'US',
  },
  {
    symbol: 'STC',
    name: 'Stewart Information Services Corp.',
    region: 'US',
  },
  {
    symbol: 'STCN',
    name: 'Steel Connect Inc',
    region: 'US',
  },
  {
    symbol: 'STE',
    name: 'Steris Plc',
    region: 'US',
  },
  {
    symbol: 'STEM',
    name: 'Stem Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'STER',
    name: 'Sterling Check Corp',
    region: 'US',
  },
  {
    symbol: 'STET',
    name: 'ST Energy Transition I Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'STFAX',
    name: 'State Street Institutional Investment Trust: State Street Equity 500 Index Fund; Administrative Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'STG',
    name: 'Sunlands Technology Group - ADR',
    region: 'US',
  },
  {
    symbol: 'STGW',
    name: 'Stagwell Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'STIM',
    name: 'Neuronetics Inc',
    region: 'US',
  },
  {
    symbol: 'STIP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 0-5 Year TIPS Bond ETF',
    region: 'US',
  },
  {
    symbol: 'STKL',
    name: 'Sunopta, Inc.',
    region: 'US',
  },
  {
    symbol: 'STKS',
    name: 'ONE Group Hospitality Inc',
    region: 'US',
  },
  {
    symbol: 'STLA',
    name: 'Stellantis N.V',
    region: 'US',
  },
  {
    symbol: 'STLD',
    name: 'Steel Dynamics Inc.',
    region: 'US',
  },
  {
    symbol: 'STLG',
    name: 'iShares Trust - iShares Factors US Growth Style ETF',
    region: 'US',
  },
  {
    symbol: 'STLV',
    name: 'iShares Trust - iShares Factors US Value Style ETF',
    region: 'US',
  },
  {
    symbol: 'STM',
    name: 'ST Microelectronics - New York Shares',
    region: 'US',
  },
  {
    symbol: 'STMNF',
    name: 'Sumitomo Metal Mining Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'STN',
    name: 'Stantec Inc',
    region: 'US',
  },
  {
    symbol: 'STNC',
    name: 'Rbb Fund Inc - Stance Equity ESG Large Cap Core ETF',
    region: 'US',
  },
  {
    symbol: 'STNE',
    name: 'StoneCo Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'STNG',
    name: 'Scorpio Tankers Inc',
    region: 'US',
  },
  {
    symbol: 'STOK',
    name: 'Stoke Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'STON',
    name: 'StoneMor Inc',
    region: 'US',
  },
  {
    symbol: 'STOT',
    name: 'SSgA Active Trust - SPDR DoubleLine Short Duration Total Return Tactical ETF',
    region: 'US',
  },
  {
    symbol: 'STPZ',
    name: 'Pimco Exchange Traded Fund - PIMCO 1-5 Year U.S. TIPS Index Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'STRA',
    name: 'Strategic Education Inc',
    region: 'US',
  },
  {
    symbol: 'STRC',
    name: 'Sarcos Technology and Robotics Corporation',
    region: 'US',
  },
  {
    symbol: 'STRE',
    name: 'Supernova Partners Acquisition Co III Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'STREX',
    name: 'Sterling Capital Funds: Sterling Capital Equity Income Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'STRL',
    name: 'Sterling Construction Company, Inc.',
    region: 'US',
  },
  {
    symbol: 'STRM',
    name: 'Streamline Health Solutions, Inc',
    region: 'US',
  },
  {
    symbol: 'STRN',
    name: 'Stran & Company Inc',
    region: 'US',
  },
  {
    symbol: 'STRO',
    name: 'Sutro Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'STRR',
    name: 'Star Equity Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'STRS',
    name: 'Stratus Properties Inc.',
    region: 'US',
  },
  {
    symbol: 'STRT',
    name: 'Strattec Security Corp.',
    region: 'US',
  },
  {
    symbol: 'STRY',
    name: 'Starry Group Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'STSA',
    name: 'Satsuma Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'STT',
    name: 'State Street Corp.',
    region: 'US',
  },
  {
    symbol: 'STTK',
    name: 'Shattuck Labs Inc',
    region: 'US',
  },
  {
    symbol: 'STVN',
    name: 'Stevanato Group Spa',
    region: 'US',
  },
  {
    symbol: 'STX',
    name: 'Seagate Technology Holdings Plc',
    region: 'US',
  },
  {
    symbol: 'STXS',
    name: 'Stereotaxis Inc',
    region: 'US',
  },
  {
    symbol: 'STZ',
    name: 'Constellation Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'STZ.B',
    name: 'Constellation Brands Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'SU',
    name: 'Suncor Energy, Inc.',
    region: 'US',
  },
  {
    symbol: 'SUAC',
    name: 'ShoulderUp Technology Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SUB',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Short-Term National Muni Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SUBS',
    name: 'Exchange Traded Concepts Trust - Fount Subscription Economy ETF',
    region: 'US',
  },
  {
    symbol: 'SUM',
    name: 'Summit Materials Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SUMO',
    name: 'Sumo Logic Inc',
    region: 'US',
  },
  {
    symbol: 'SUMR',
    name: 'Summer Infant Inc',
    region: 'US',
  },
  {
    symbol: 'SUN',
    name: 'Sunoco LP - Unit',
    region: 'US',
  },
  {
    symbol: 'SUNL',
    name: 'Sunlight Financial Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SUNW',
    name: 'Sunworks Inc',
    region: 'US',
  },
  {
    symbol: 'SUP',
    name: 'Superior Industries International, Inc.',
    region: 'US',
  },
  {
    symbol: 'SUPL',
    name: 'ProShares Trust - ProShares Supply Chain Logistics ETF',
    region: 'US',
  },
  {
    symbol: 'SUPN',
    name: 'Supernus Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'SUPV',
    name: 'Grupo Supervielle S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'SURDF',
    name: 'Sumitomo Realty & Development Co Ltd',
    region: 'US',
  },
  {
    symbol: 'SURF',
    name: 'Surface Oncology Inc',
    region: 'US',
  },
  {
    symbol: 'SURG',
    name: 'Surgepays Inc',
    region: 'US',
  },
  {
    symbol: 'SURYY',
    name: 'Sumitomo Realty & Development Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'SUSA',
    name: 'BlackRock Institutional Trust Company N.A. - MSCI USA ESG Select ETF',
    region: 'US',
  },
  {
    symbol: 'SUSB',
    name: 'iShares Trust - iShares Trust iShares ESG Aware 1-5 Year USD Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SUSC',
    name: 'iShares Trust - iShares Trust iShares ESG Aware USD Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'SUSL',
    name: 'iShares Trust - iShares ESG MSCI USA Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'SUZ',
    name: 'Suzano S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'SV',
    name: 'Spring Valley Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SVA',
    name: 'Sinovac Biotech, Ltd.',
    region: 'US',
  },
  {
    symbol: 'SVAL',
    name: 'iShares Trust - iShares US Small Cap Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'SVCBF',
    name: 'Svenska Cellulosa AB - Class B',
    region: 'US',
  },
  {
    symbol: 'SVFA',
    name: 'SVF Investment Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SVFAU',
    name: 'SVF Investment Corp - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'SVFAX',
    name: 'Smead Funds Trust: Smead Value Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVFB',
    name: 'SVF Investment Corp 2 - Class A',
    region: 'US',
  },
  {
    symbol: 'SVFD',
    name: 'Save Foods Inc',
    region: 'US',
  },
  {
    symbol: 'SVFDX',
    name: 'Smead Funds Trust: Smead Value Fund; Class R1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVFFX',
    name: 'Smead Funds Trust: Smead Value Fund; Class I1 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVFKX',
    name: 'Smead Funds Trust: Smead Value Fund; Class R2 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVFYX',
    name: 'Smead Funds Trust: Smead Value Fund; Class Y Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVIX',
    name: 'VS Trust - 1x Short VIX Futures ETF',
    region: 'US',
  },
  {
    symbol: 'SVKEF',
    name: 'Skandinaviska Enskilda Banken - Class A',
    region: 'US',
  },
  {
    symbol: 'SVM',
    name: 'Silvercorp Metals Inc',
    region: 'US',
  },
  {
    symbol: 'SVNA',
    name: '7 Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SVNAU',
    name: '7 Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SVNDF',
    name: 'Seven & I Holdings Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'SVNDY',
    name: 'Seven & I Holdings Co., Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'SVNLF',
    name: 'Svenska Handelsbanken - Class A',
    region: 'US',
  },
  {
    symbol: 'SVNLY',
    name: 'Svenska Handelsbanken - ADR',
    region: 'US',
  },
  {
    symbol: 'SVOL',
    name: 'Simplify Exchange Traded Funds - Simplify Volatility Premium ETF',
    region: 'US',
  },
  {
    symbol: 'SVRA',
    name: 'Savara Inc',
    region: 'US',
  },
  {
    symbol: 'SVSPX',
    name: 'SSgA Funds: State Street S&P 500 Index Fund; Class N Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVSVU',
    name: 'Spring Valley Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SVT',
    name: 'Servotronics, Inc.',
    region: 'US',
  },
  {
    symbol: 'SVTMF',
    name: 'SM Investments Corp',
    region: 'US',
  },
  {
    symbol: 'SVXY',
    name: 'ProShares Trust - ProShares Short VIX Short-Term Futures ETF',
    region: 'US',
  },
  {
    symbol: 'SVYAX',
    name: 'SEI Institutional Investments Trust: US Managed Volatility Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SVYSF',
    name: 'Solvay SA',
    region: 'US',
  },
  {
    symbol: 'SWAG',
    name: 'Software Acquisition Group Inc III - Class A',
    region: 'US',
  },
  {
    symbol: 'SWAGU',
    name: 'Software Acquisition Group Inc III - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SWAN',
    name: 'Amplify ETF Trust - Amplify BlackSwan Growth & Treasury Core ETF',
    region: 'US',
  },
  {
    symbol: 'SWAR',
    name: 'Direxion Shares ETF Trust - Direxion Daily Software Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'SWAV',
    name: 'ShockWave Medical Inc',
    region: 'US',
  },
  {
    symbol: 'SWBI',
    name: 'Smith & Wesson Brands Inc',
    region: 'US',
  },
  {
    symbol: 'SWCH',
    name: 'Switch Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SWDCF',
    name: 'Swedencare AB',
    region: 'US',
  },
  {
    symbol: 'SWET',
    name: 'Athlon Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'SWETU',
    name: 'Athlon Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SWGAF',
    name: 'Swatch Group AG',
    region: 'US',
  },
  {
    symbol: 'SWGAY',
    name: 'Swatch Group AG - ADR',
    region: 'US',
  },
  {
    symbol: 'SWI',
    name: 'SolarWinds Corp',
    region: 'US',
  },
  {
    symbol: 'SWIM',
    name: 'Latham Group Inc',
    region: 'US',
  },
  {
    symbol: 'SWIR',
    name: 'Sierra Wireless Inc',
    region: 'US',
  },
  {
    symbol: 'SWK',
    name: 'Stanley Black & Decker Inc',
    region: 'US',
  },
  {
    symbol: 'SWKH',
    name: 'Swk Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'SWKS',
    name: 'Skyworks Solutions, Inc.',
    region: 'US',
  },
  {
    symbol: 'SWM',
    name: 'Schweitzer-Mauduit International, Inc.',
    region: 'US',
  },
  {
    symbol: 'SWN',
    name: 'Southwestern Energy Company',
    region: 'US',
  },
  {
    symbol: 'SWPPX',
    name: 'Schwab Capital Trust: Schwab S&P 500 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SWSDF',
    name: 'Swiss Life Holding',
    region: 'US',
  },
  {
    symbol: 'SWSS',
    name: 'Springwater Special Situations Corp',
    region: 'US',
  },
  {
    symbol: 'SWSSU',
    name: 'Springwater Special Situations Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'SWSSX',
    name: 'Schwab Capital Trust: Schwab Small-Cap Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SWTSX',
    name: 'Schwab Capital Trust: Schwab Total Stock Market Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SWTX',
    name: 'SpringWorks Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'SWX',
    name: 'Southwest Gas Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'SXC',
    name: 'SunCoke Energy Inc',
    region: 'US',
  },
  {
    symbol: 'SXI',
    name: 'Standex International Corp.',
    region: 'US',
  },
  {
    symbol: 'SXPRX',
    name: 'Deutsche DWS Institutional Funds: DWS S&P 500 Index Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'SXQG',
    name: 'Exchange Traded Concepts Trust - ETC 6 Meridian Quality Growth ETF',
    region: 'US',
  },
  {
    symbol: 'SXT',
    name: 'Sensient Technologies Corp.',
    region: 'US',
  },
  {
    symbol: 'SXTC',
    name: 'China SXT Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'SXUS',
    name: 'Janus Capital Management LLC - Janus Henderson International Sustainable Equity ETF',
    region: 'US',
  },
  {
    symbol: 'SXYAY',
    name: 'Sika AG - ADR',
    region: 'US',
  },
  {
    symbol: 'SY',
    name: 'So-Young International Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'SYBT',
    name: 'Stock Yards Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'SYBX',
    name: 'Synlogic Inc',
    region: 'US',
  },
  {
    symbol: 'SYK',
    name: 'Stryker Corp.',
    region: 'US',
  },
  {
    symbol: 'SYLD',
    name: 'Cambria Investment Management LP - Cambria Shareholder Yield ETF',
    region: 'US',
  },
  {
    symbol: 'SYN',
    name: 'Synthetic Biologics Inc',
    region: 'US',
  },
  {
    symbol: 'SYNA',
    name: 'Synaptics Inc',
    region: 'US',
  },
  {
    symbol: 'SYNH',
    name: 'Syneos Health Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'SYNL',
    name: 'Synalloy Corp.',
    region: 'US',
  },
  {
    symbol: 'SYPR',
    name: 'Sypris Solutions, Inc.',
    region: 'US',
  },
  {
    symbol: 'SYRS',
    name: 'Syros Pharmaceuticals Inc.',
    region: 'US',
  },
  {
    symbol: 'SYTA',
    name: 'Siyata Mobile Inc',
    region: 'US',
  },
  {
    symbol: 'SYUS',
    name: 'Syntax ETF Trust - Syntax Stratified U.S. Total Market ETF',
    region: 'US',
  },
  {
    symbol: 'SYY',
    name: 'Sysco Corp.',
    region: 'US',
  },
  {
    symbol: 'SZK',
    name: 'ProShares Trust - ProShares UltraShort Consumer Goods',
    region: 'US',
  },
  {
    symbol: 'SZKMF',
    name: 'Suzuki Motor Corp.',
    region: 'US',
  },
  {
    symbol: 'SZKMY',
    name: 'Suzuki Motor Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'SZLMY',
    name: 'Swiss Life Holding - ADR',
    region: 'US',
  },
  {
    symbol: 'SZNE',
    name: 'Pacer Funds Trust - Pacer CFRA Stovall Equal Weight Seasonal Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'SZZL',
    name: 'Sizzle Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'SZZLU',
    name: 'Sizzle Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'T',
    name: 'AT&T, Inc.',
    region: 'US',
  },
  {
    symbol: 'TA',
    name: 'TravelCenters of America Inc',
    region: 'US',
  },
  {
    symbol: 'TAC',
    name: 'Transalta Corp.',
    region: 'US',
  },
  {
    symbol: 'TACA',
    name: 'Trepont Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'TACK',
    name: 'Capitol Series Trust - Fairlead Tactical Sector ETF',
    region: 'US',
  },
  {
    symbol: 'TACT',
    name: 'Transact Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'TAGG',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price QM U.S. Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TAGS',
    name: 'Teucrium Trading, LLC - Teucrium Agricultural Fund',
    region: 'US',
  },
  {
    symbol: 'TAIL',
    name: 'Cambria Investment Management LP - Cambria Tail Risk ETF',
    region: 'US',
  },
  {
    symbol: 'TAIT',
    name: 'Taitron Components Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'TAK',
    name: 'Takeda Pharmaceutical Co - ADR',
    region: 'US',
  },
  {
    symbol: 'TAL',
    name: 'TAL Education Group - ADR',
    region: 'US',
  },
  {
    symbol: 'TALK',
    name: 'Talkspace Inc',
    region: 'US',
  },
  {
    symbol: 'TALO',
    name: 'Talos Energy Inc',
    region: 'US',
  },
  {
    symbol: 'TALS',
    name: 'Talaris Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TAN',
    name: 'Invesco Capital Management LLC - Invesco Solar ETF',
    region: 'US',
  },
  {
    symbol: 'TANH',
    name: 'Tantech Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'TAOP',
    name: 'Taoping Inc.',
    region: 'US',
  },
  {
    symbol: 'TAP',
    name: 'Molson Coors Beverage Company - Class B',
    region: 'US',
  },
  {
    symbol: 'TAP.A',
    name: 'Molson Coors Beverage Company - Class A',
    region: 'US',
  },
  {
    symbol: 'TARA',
    name: 'Protara Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TARO',
    name: 'Taro Pharmaceutical Industries',
    region: 'US',
  },
  {
    symbol: 'TARS',
    name: 'Tarsus Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'TASK',
    name: 'TaskUs Inc Class A',
    region: 'US',
  },
  {
    symbol: 'TASMX',
    name: 'Transamerica Funds: Transamerica Small/Mid Cap Value; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TAST',
    name: 'Carrols Restaurant Group Inc.',
    region: 'US',
  },
  {
    symbol: 'TATLY',
    name: 'Tata Steel Ltd. - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'TATT',
    name: 'Tat Technologies',
    region: 'US',
  },
  {
    symbol: 'TAXF',
    name: 'American Century ETF Trust - American Century Diversified Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TAYD',
    name: 'Taylor Devices Inc.',
    region: 'US',
  },
  {
    symbol: 'TBBK',
    name: 'Bancorp Inc. (The)',
    region: 'US',
  },
  {
    symbol: 'TBCP',
    name: 'Thunder Bridge Capital Partners III Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TBCPU',
    name: 'Thunder Bridge Capital Partners III Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'TBF',
    name: 'ProShares Trust - ProShares Short 20+ Year Treasury',
    region: 'US',
  },
  {
    symbol: 'TBI',
    name: 'TrueBlue Inc',
    region: 'US',
  },
  {
    symbol: 'TBJL',
    name: 'Innovator ETFs Trust - Innovator 20+ Year Treasury Bond 9 Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'TBK',
    name: 'Triumph Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'TBLA',
    name: 'Taboola.com Ltd',
    region: 'US',
  },
  {
    symbol: 'TBLT',
    name: 'Toughbuilt Industries Inc',
    region: 'US',
  },
  {
    symbol: 'TBNK',
    name: 'Territorial Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'TBPH',
    name: 'Theravance Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'TBSA',
    name: 'TB SA Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TBSAU',
    name: 'TB SA Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TBT',
    name: 'Invesco Capital Management LLC - PowerShares UltraShort Lehman 20+ Year Treasury ProShares',
    region: 'US',
  },
  {
    symbol: 'TBUX',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price Ultra Short-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TBX',
    name: 'ProShares Trust - ProShares Short 7-10 Year Treasury',
    region: 'US',
  },
  {
    symbol: 'TC',
    name: 'TuanChe Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TCAC',
    name: 'Tuatara Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TCACU',
    name: 'Tuatara Capital Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'TCBC',
    name: 'TC Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'TCBI',
    name: 'Texas Capital Bancshares, Inc.',
    region: 'US',
  },
  {
    symbol: 'TCBK',
    name: 'Trico Bancshares',
    region: 'US',
  },
  {
    symbol: 'TCBP',
    name: 'TC BioPharm (Holdings) plc - ADR',
    region: 'US',
  },
  {
    symbol: 'TCBS',
    name: 'Texas Community Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'TCBX',
    name: 'Third Coast Bancshares Inc',
    region: 'US',
  },
  {
    symbol: 'TCCHX',
    name: 'TIAA-CREF Funds: Social Choice Low Carbon Equity Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TCDA',
    name: 'Tricida Inc',
    region: 'US',
  },
  {
    symbol: 'TCEPX',
    name: 'TIAA-CREF Funds: Equity Index Fund; Premier Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TCFC',
    name: 'Community Financial Corp',
    region: 'US',
  },
  {
    symbol: 'TCHI',
    name: 'iShares Trust - iShares MSCI China Multisector Tech ETF',
    region: 'US',
  },
  {
    symbol: 'TCHP',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price Blue Chip Growth ETF',
    region: 'US',
  },
  {
    symbol: 'TCMD',
    name: 'Tactile Systems Technology Inc',
    region: 'US',
  },
  {
    symbol: 'TCOA',
    name: 'Trajectory Alpha Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TCOM',
    name: 'Trip.com Group Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TCON',
    name: 'TRACON Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'TCPWX',
    name: 'Transamerica Funds: Transamerica Capital Growth; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TCRR',
    name: 'Tcr2 Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TCRT',
    name: 'Alaunos Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TCRX',
    name: 'Tscan Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TCS',
    name: 'Container Store Group Inc',
    region: 'US',
  },
  {
    symbol: 'TCTL',
    name: 'Premise Capital LLC - Premise Capital Diversified Tactical ETF',
    region: 'US',
  },
  {
    symbol: 'TCVA',
    name: 'TCV Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TCX',
    name: 'Tucows, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'TD',
    name: 'Toronto Dominion Bank',
    region: 'US',
  },
  {
    symbol: 'TDC',
    name: 'Teradata Corp',
    region: 'US',
  },
  {
    symbol: 'TDCX',
    name: 'TDCX Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'TDG',
    name: 'Transdigm Group Incorporated',
    region: 'US',
  },
  {
    symbol: 'TDHOF',
    name: 'T&D Holdings Inc.',
    region: 'US',
  },
  {
    symbol: 'TDHOY',
    name: 'T&D Holdings Inc. - ADR',
    region: 'US',
  },
  {
    symbol: 'TDIV',
    name: 'First Trust Exchange-Traded Fund III - First Trust ETF VI First Trust NASDAQ Technology Dividend Index Fund',
    region: 'US',
  },
  {
    symbol: 'TDOC',
    name: 'Teladoc Health Inc',
    region: 'US',
  },
  {
    symbol: 'TDS',
    name: 'Telephone And Data Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'TDSA',
    name: 'Exchange Listed Funds Trust - Cabana Target Drawdown 5 ETF',
    region: 'US',
  },
  {
    symbol: 'TDSB',
    name: 'Exchange Listed Funds Trust - Cabana Target Drawdown 7 ETF',
    region: 'US',
  },
  {
    symbol: 'TDSC',
    name: 'Exchange Listed Funds Trust - Cabana Target Drawdown 10 ETF',
    region: 'US',
  },
  {
    symbol: 'TDSD',
    name: 'Exchange Listed Funds Trust - Cabana Target Drawdown 13 ETF',
    region: 'US',
  },
  {
    symbol: 'TDSE',
    name: 'Exchange Listed Funds Trust - Cabana Target Drawdown 16 ETF',
    region: 'US',
  },
  {
    symbol: 'TDTF',
    name: 'FlexShares Trust - FlexShares iBoxx 5-Year Target Duration TIPS Index Fund',
    region: 'US',
  },
  {
    symbol: 'TDTT',
    name: 'FlexShares Trust - FlexShares iBoxx 3-Year Target Duration TIPS Index Fund',
    region: 'US',
  },
  {
    symbol: 'TDUP',
    name: 'ThredUp Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TDV',
    name: 'ProShares Trust - ProShares S&P Technology Dividend Aristocrats ETF',
    region: 'US',
  },
  {
    symbol: 'TDVG',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price Dividend Growth ETF',
    region: 'US',
  },
  {
    symbol: 'TDW',
    name: 'Tidewater Inc. - New',
    region: 'US',
  },
  {
    symbol: 'TDY',
    name: 'Teledyne Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'TEAM',
    name: 'Atlassian Corporation Plc - Class A',
    region: 'US',
  },
  {
    symbol: 'TECB',
    name: 'iShares Trust - iShares U.S. Tech Breakthrough Multisector ETF',
    region: 'US',
  },
  {
    symbol: 'TECH',
    name: 'Bio-Techne Corp',
    region: 'US',
  },
  {
    symbol: 'TECK',
    name: 'Teck Resources Ltd - Class B',
    region: 'US',
  },
  {
    symbol: 'TECL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Technology Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TECS',
    name: 'Direxion Shares ETF Trust - Direxion Daily Technology Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TEDU',
    name: 'Tarena International Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'TEF',
    name: 'Telefonica S.A - ADR',
    region: 'US',
  },
  {
    symbol: 'TEIHX',
    name: 'TIAA-CREF Funds: Equity Index Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TEKK',
    name: 'Tekkorp Digital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TEKKU',
    name: 'Tekkorp Digital Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'TEL',
    name: 'TE Connectivity Ltd - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'TELA',
    name: 'TELA Bio Inc',
    region: 'US',
  },
  {
    symbol: 'TELL',
    name: 'Tellurian Inc',
    region: 'US',
  },
  {
    symbol: 'TEMP',
    name: 'J.P. Morgan Exchange-Traded Fund Trust - JPMorgan Climate Change Solutions ETF',
    region: 'US',
  },
  {
    symbol: 'TEN',
    name: 'Tenneco, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'TENB',
    name: 'Tenable Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'TENG',
    name: 'Direxion Funds - Direxion Daily 5G Communications Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'TENX',
    name: 'Tenax Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TEO',
    name: 'Telecom Argentina S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'TEQI',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price Equity Income ETF',
    region: 'US',
  },
  {
    symbol: 'TER',
    name: 'Teradyne, Inc.',
    region: 'US',
  },
  {
    symbol: 'TERN',
    name: 'Terns Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'TERRF',
    name: 'Terna - Trasmissione Elettricita Rete Nazionale',
    region: 'US',
  },
  {
    symbol: 'TESS',
    name: 'Tessco Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'TETC',
    name: 'Tech and Energy Transition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TETCU',
    name: 'Tech and Energy Transition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TETE',
    name: 'Technology & Telecommunication Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TETEU',
    name: 'Technology & Telecommunication Acquisition Corp - Units (1 Ord Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'TEVA',
    name: 'Teva- Pharmaceutical Industries Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'TEWCX',
    name: 'TIAA-CREF Funds: Social Choice Low Carbon Equity Fund; Retirement Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TEX',
    name: 'Terex Corp.',
    region: 'US',
  },
  {
    symbol: 'TEZNY',
    name: 'Terna - Trasmissione Elettricita Rete Nazionale - ADR',
    region: 'US',
  },
  {
    symbol: 'TFC',
    name: 'Truist Financial Corporation',
    region: 'US',
  },
  {
    symbol: 'TFFP',
    name: 'Tff Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'TFI',
    name: 'SPDR Series Trust - SPDR Nuveen Bloomberg Municipal Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TFII',
    name: 'TFI International Inc',
    region: 'US',
  },
  {
    symbol: 'TFJL',
    name: 'Innovator ETFs Trust - Innovator 20+ Year Treasury Bond 5 Floor ETF - July',
    region: 'US',
  },
  {
    symbol: 'TFLO',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Treasury Floating Rate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TFOIX',
    name: 'Transamerica Funds: Transamerica Capital Growth; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TFSL',
    name: 'TFS Financial Corporation',
    region: 'US',
  },
  {
    symbol: 'TFX',
    name: 'Teleflex Incorporated',
    region: 'US',
  },
  {
    symbol: 'TG',
    name: 'Tredegar Corp.',
    region: 'US',
  },
  {
    symbol: 'TGA',
    name: 'Transglobe Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'TGAA',
    name: 'Target Global Acquisition I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TGAAU',
    name: 'Target Global Acquisition I Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TGAN',
    name: 'Transphorm Inc',
    region: 'US',
  },
  {
    symbol: 'TGB',
    name: 'Taseko Mines Ltd.',
    region: 'US',
  },
  {
    symbol: 'TGBMF',
    name: 'Taiwan Cement',
    region: 'US',
  },
  {
    symbol: 'TGCEX',
    name: 'TCW Funds, Inc: TCW Select Equities Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TGCNX',
    name: 'TCW Funds, Inc: TCW Select Equities Fund; Class N Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TGH',
    name: 'Textainer Group Holdings Limited',
    region: 'US',
  },
  {
    symbol: 'TGI',
    name: 'Triumph Group Inc.',
    region: 'US',
  },
  {
    symbol: 'TGIF',
    name: 'Tidal ETF Trust - SoFi Weekly Income ETF',
    region: 'US',
  },
  {
    symbol: 'TGLS',
    name: 'Tecnoglass Inc',
    region: 'US',
  },
  {
    symbol: 'TGNA',
    name: 'TEGNA Inc',
    region: 'US',
  },
  {
    symbol: 'TGR',
    name: 'Kimbell Tiger Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TGRW',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price Growth Stock ETF',
    region: 'US',
  },
  {
    symbol: 'TGS',
    name: 'Transportadora de Gas del Sur - ADR',
    region: 'US',
  },
  {
    symbol: 'TGT',
    name: 'Target Corp',
    region: 'US',
  },
  {
    symbol: 'TGTX',
    name: 'TG Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TGVC',
    name: 'TG Venture Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TGVCU',
    name: 'TG Venture Acquisition Corp - Units (1 Ord Share Class A & 1 War)',
    region: 'US',
  },
  {
    symbol: 'TGVSY',
    name: 'Tryg AS - ADR',
    region: 'US',
  },
  {
    symbol: 'TGWFX',
    name: 'Transamerica Funds: Transamerica Large Growth; Class R4 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TGWRX',
    name: 'Transamerica Funds: Transamerica Large Growth; Class R Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TGWTX',
    name: 'Transamerica Funds: Transamerica Large Growth; Class I3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TH',
    name: 'Target Hospitality Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'THAC',
    name: 'Thrive Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'THACU',
    name: 'Thrive Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'THC',
    name: 'Tenet Healthcare Corp.',
    region: 'US',
  },
  {
    symbol: 'THCA',
    name: 'Tuscan Holdings Corp II',
    region: 'US',
  },
  {
    symbol: 'THCAU',
    name: 'Tuscan Holdings Corp II - Units (1 Ord shares & 1/2 Wrt)',
    region: 'US',
  },
  {
    symbol: 'THCP',
    name: 'Thunder Bridge Capital Partners IV Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'THCPU',
    name: 'Thunder Bridge Capital Partners IV Inc - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'THCX',
    name: 'Spinnaker ETF Trust - The Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'THD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Thailand ETF',
    region: 'US',
  },
  {
    symbol: 'THFF',
    name: 'First Financial Corp. - Indiana',
    region: 'US',
  },
  {
    symbol: 'THG',
    name: 'Hanover Insurance Group Inc',
    region: 'US',
  },
  {
    symbol: 'THISX',
    name: 'T Rowe Price Health Sciences Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'THLEF',
    name: 'Thales S.A.',
    region: 'US',
  },
  {
    symbol: 'THLLY',
    name: 'Thales S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'THM',
    name: 'International Tower Hill Mines Ltd',
    region: 'US',
  },
  {
    symbol: 'THMO',
    name: 'ThermoGenesis Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'THNQ',
    name: 'Exchange Traded Concepts Trust - ROBO Global Artificial Intelligence ETF',
    region: 'US',
  },
  {
    symbol: 'THO',
    name: 'Thor Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'THQQF',
    name: 'Embracer Group AB - Class B',
    region: 'US',
  },
  {
    symbol: 'THR',
    name: 'Thermon Group Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'THRM',
    name: 'Gentherm Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'THRN',
    name: 'Thorne Healthtech Inc',
    region: 'US',
  },
  {
    symbol: 'THRX',
    name: 'Theseus Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'THRY',
    name: 'Thryv Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'THS',
    name: 'Treehouse Foods Inc',
    region: 'US',
  },
  {
    symbol: 'THTX',
    name: 'Theratechnologies Inc.',
    region: 'US',
  },
  {
    symbol: 'THY',
    name: 'Northern Lights Fund Trust - Toews Agility Shares Dynamic Tactical Income ETF',
    region: 'US',
  },
  {
    symbol: 'TIEIX',
    name: 'TIAA-CREF Funds: Equity Index Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TIG',
    name: 'Trean Insurance Group Inc',
    region: 'US',
  },
  {
    symbol: 'TIGO',
    name: 'Millicom International Cellular S.A.',
    region: 'US',
  },
  {
    symbol: 'TIL',
    name: 'Instil Bio Inc',
    region: 'US',
  },
  {
    symbol: 'TILE',
    name: 'Interface Inc.',
    region: 'US',
  },
  {
    symbol: 'TILGX',
    name: 'TIAA-CREF Funds: Large-Cap Growth Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TILHX',
    name: 'TIAA-CREF Funds: Large-Cap Growth Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TILPX',
    name: 'TIAA-CREF Funds: Large-Cap Growth Fund; Premier Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TILRX',
    name: 'TIAA-CREF Funds: Large-Cap Growth Fund; Retirement Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TILT',
    name: 'FlexShares Trust - FlexShares Morningstar US Market Factors Tilt Index Fund',
    region: 'US',
  },
  {
    symbol: 'TILWX',
    name: 'TIAA-CREF Funds: Large-Cap Growth Fund; Class W Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TIMB',
    name: 'TIM SA - ADR',
    region: 'US',
  },
  {
    symbol: 'TIME',
    name: 'Capitol Series Trust - Clockwise Capital Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'TINRX',
    name: 'TIAA-CREF Funds: Equity Index Fund; Retail Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TINT',
    name: 'ProShares Trust - ProShares Smart Materials ETF',
    region: 'US',
  },
  {
    symbol: 'TINV',
    name: 'Tiga Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TINY',
    name: 'ProShares Trust - ProShares Nanotechnology ETF',
    region: 'US',
  },
  {
    symbol: 'TIOA',
    name: 'Tio Tech A - Class A',
    region: 'US',
  },
  {
    symbol: 'TIOAU',
    name: 'Tio Tech A - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TIP',
    name: 'BlackRock Institutional Trust Company N.A. - iShares TIPS Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TIPD',
    name: 'Direxion Shares ETF Trust - Direxion Daily TIPS Bear 2X Shares',
    region: 'US',
  },
  {
    symbol: 'TIPL',
    name: 'Direxion Shares ETF Trust - Direxion Daily TIPS Bull 2X Shares',
    region: 'US',
  },
  {
    symbol: 'TIPT',
    name: 'Tiptree Inc',
    region: 'US',
  },
  {
    symbol: 'TIPX',
    name: 'SPDR Series Trust - SPDR Bloomberg 1-10 Year TIPS ETF',
    region: 'US',
  },
  {
    symbol: 'TIPZ',
    name: 'Pimco Exchange Traded Fund - PIMCO Broad U.S. TIPS Index Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'TIQRX',
    name: 'TIAA-CREF Funds: Equity Index Fund; Retirement Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TIRTX',
    name: 'TIAA-CREF Funds: Large-Cap Growth Fund; Retail Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TISAX',
    name: 'TIAA-CREF Funds: S&P 500 Index Fund; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TISEX',
    name: 'TIAA-CREF Funds: Quant Small-Cap Equity Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TISI',
    name: 'Team, Inc.',
    region: 'US',
  },
  {
    symbol: 'TISNF',
    name: 'TIS Inc',
    region: 'US',
  },
  {
    symbol: 'TISPX',
    name: 'TIAA-CREF Funds: S&P 500 Index Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TISWX',
    name: 'TIAA-CREF Funds: S&P 500 Index Fund; Class W Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TITN',
    name: 'Titan Machinery Inc',
    region: 'US',
  },
  {
    symbol: 'TIVC',
    name: 'Tivic Health Systems Inc',
    region: 'US',
  },
  {
    symbol: 'TIXT',
    name: 'TELUS International (Cda) Inc (Sub Voting)',
    region: 'US',
  },
  {
    symbol: 'TJX',
    name: 'TJX Companies, Inc.',
    region: 'US',
  },
  {
    symbol: 'TK',
    name: 'Teekay Corp',
    region: 'US',
  },
  {
    symbol: 'TKAT',
    name: 'Takung Art Co Ltd',
    region: 'US',
  },
  {
    symbol: 'TKAYF',
    name: 'Just Eat Takeaway.com N.V.',
    region: 'US',
  },
  {
    symbol: 'TKC',
    name: 'Turkcell Iletisim Hizmetleri A.S. - ADR',
    region: 'US',
  },
  {
    symbol: 'TKGBF',
    name: 'Turkiye Garanti Bankasi A.S.',
    region: 'US',
  },
  {
    symbol: 'TKGBY',
    name: 'Turkiye Garanti Bankasi A.S. - ADR',
    region: 'US',
  },
  {
    symbol: 'TKGSF',
    name: 'Tokyo Gas Co. Ltd.',
    region: 'US',
  },
  {
    symbol: 'TKGSY',
    name: 'Tokyo Gas Co. Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'TKGZY',
    name: 'Turkiye Garanti Bankasi A.S. - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'TKLF',
    name: 'Yoshitsu Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TKNO',
    name: 'Alpha Teknova Inc',
    region: 'US',
  },
  {
    symbol: 'TKR',
    name: 'Timken Co.',
    region: 'US',
  },
  {
    symbol: 'TLGA',
    name: 'TLG Acquisition One Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TLGY',
    name: 'TLGY Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TLGYU',
    name: 'TLGY Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'TLH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 10-20 Year Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TLIS',
    name: 'Talis Biomedical Corp',
    region: 'US',
  },
  {
    symbol: 'TLK',
    name: 'PT Telkom Indonesia (Persero) Tbk - ADR',
    region: 'US',
  },
  {
    symbol: 'TLKMF',
    name: 'PT Telkom Indonesia (Persero) Tbk - Class B',
    region: 'US',
  },
  {
    symbol: 'TLPFF',
    name: 'Teleperformance',
    region: 'US',
  },
  {
    symbol: 'TLPFY',
    name: 'Teleperformance - ADR',
    region: 'US',
  },
  {
    symbol: 'TLRY',
    name: 'Tilray Brands Inc - Class 2',
    region: 'US',
  },
  {
    symbol: 'TLS',
    name: 'Telos Corp',
    region: 'US',
  },
  {
    symbol: 'TLSA',
    name: 'Tiziana Life Sciences Ltd',
    region: 'US',
  },
  {
    symbol: 'TLSNF',
    name: 'Telia Company AB',
    region: 'US',
  },
  {
    symbol: 'TLSNY',
    name: 'Telia Company AB - ADR',
    region: 'US',
  },
  {
    symbol: 'TLT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares 20+ Year Treasury Bond ETF',
    region: 'US',
  },
  {
    symbol: 'TLTD',
    name: 'FlexShares Trust - FlexShares Morningstar Developed Markets ex-US Factor Tilt Index Fund',
    region: 'US',
  },
  {
    symbol: 'TLTE',
    name: 'FlexShares Trust - FlexShares Morningstar Emerging Markets Factor Tilt Index Fund',
    region: 'US',
  },
  {
    symbol: 'TLTZF',
    name: 'Tele2 AB - Class B',
    region: 'US',
  },
  {
    symbol: 'TLTZY',
    name: 'Tele2 AB - ADR',
    region: 'US',
  },
  {
    symbol: 'TLVIX',
    name: 'Thrivent Mutual Funds: Thrivent Large Cap Value Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TLWCX',
    name: 'TIAA-CREF Funds: Social Choice Low Carbon Equity Fund; Retail Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TLYS',
    name: 'Tillys Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TM',
    name: 'Toyota Motor Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'TMAC',
    name: 'Music Acquisition Corporation - Series A',
    region: 'US',
  },
  {
    symbol: 'TMAT',
    name: 'Northern Lights Fund Trust IV - Main Thematic Innovation ETF',
    region: 'US',
  },
  {
    symbol: 'TMBR',
    name: 'Timber Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'TMC',
    name: 'TMC the metals company Inc',
    region: 'US',
  },
  {
    symbol: 'TMCI',
    name: 'Treace Medical Concepts Inc',
    region: 'US',
  },
  {
    symbol: 'TMDI',
    name: 'Titan Medical Inc',
    region: 'US',
  },
  {
    symbol: 'TMDIX',
    name: 'AMG Funds: AMG TimesSquare Mid Cap Growth Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TMDPX',
    name: 'AMG Funds: AMG TimesSquare Mid Cap Growth Fund; Class N Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TMDV',
    name: 'ProShares Trust - ProShares Russell US Dividend Growers ETF',
    region: 'US',
  },
  {
    symbol: 'TMDX',
    name: 'Transmedics Group Inc',
    region: 'US',
  },
  {
    symbol: 'TME',
    name: 'Tencent Music Entertainment Group - ADR',
    region: 'US',
  },
  {
    symbol: 'TMF',
    name: 'Direxion Shares ETF Trust - Direxion Daily 20+ Year Treasury Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TMFC',
    name: 'Rbb Fund Inc - Motley Fool 100 Index ETF',
    region: 'US',
  },
  {
    symbol: 'TMFE',
    name: 'Rbb Fund Inc - Motley Fool Capital Efficiency 100 Index ETF',
    region: 'US',
  },
  {
    symbol: 'TMFG',
    name: 'Rbb Fund Inc - Motley Fool Global Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'TMFM',
    name: 'Rbb Fund Inc - Motley Fool Mid Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'TMFS',
    name: 'Motley Fool Funds Trust - Motley Fool Small-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'TMFX',
    name: 'Rbb Fund Inc - Motley Fool Next Index ETF',
    region: 'US',
  },
  {
    symbol: 'TMHC',
    name: 'Taylor Morrison Home Corp.',
    region: 'US',
  },
  {
    symbol: 'TMKR',
    name: 'Tastemaker Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TMKRU',
    name: 'Tastemaker Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'TMMAX',
    name: 'SEI Institutional Managed Trust: Tax-Managed Managed Volatility Fund; Class F Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TMO',
    name: 'Thermo Fisher Scientific Inc.',
    region: 'US',
  },
  {
    symbol: 'TMP',
    name: 'Tompkins Financial Corp',
    region: 'US',
  },
  {
    symbol: 'TMPM',
    name: 'Turmeric Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TMPMU',
    name: 'Turmeric Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TMQ',
    name: 'Trilogy Metals Inc',
    region: 'US',
  },
  {
    symbol: 'TMRAF',
    name: 'Tomra Systems ASA',
    region: 'US',
  },
  {
    symbol: 'TMRAY',
    name: 'Tomra Systems ASA - ADR',
    region: 'US',
  },
  {
    symbol: 'TMSIX',
    name: 'Thrivent Mutual Funds: Thrivent Mid Cap Stock Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TMST',
    name: 'TimkenSteel Corp',
    region: 'US',
  },
  {
    symbol: 'TMUS',
    name: 'T-Mobile US Inc',
    region: 'US',
  },
  {
    symbol: 'TMV',
    name: 'Direxion Shares ETF Trust - Direxion Daily 20+ Year Treasury Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TMX',
    name: 'Terminix Global Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'TNA',
    name: 'Direxion Shares ETF Trust - Direxion Dialy Small Cap Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TNABF',
    name: 'Tenaga Nasional Bhd',
    region: 'US',
  },
  {
    symbol: 'TNABY',
    name: 'Tenaga Nasional Bhd - ADR',
    region: 'US',
  },
  {
    symbol: 'TNC',
    name: 'Tennant Co.',
    region: 'US',
  },
  {
    symbol: 'TNDM',
    name: 'Tandem Diabetes Care Inc',
    region: 'US',
  },
  {
    symbol: 'TNET',
    name: 'TriNet Group Inc',
    region: 'US',
  },
  {
    symbol: 'TNGX',
    name: 'Tango Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TNK',
    name: 'Teekay Tankers Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'TNL',
    name: 'Travel+Leisure Co',
    region: 'US',
  },
  {
    symbol: 'TNON',
    name: 'Tenon Medical Inc',
    region: 'US',
  },
  {
    symbol: 'TNP',
    name: 'Tsakos Energy Navigation Limited',
    region: 'US',
  },
  {
    symbol: 'TNWCX',
    name: 'TIAA-CREF Funds: Social Choice Low Carbon Equity Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TNXP',
    name: 'Tonix Pharmaceuticals Holding Corp',
    region: 'US',
  },
  {
    symbol: 'TNYA',
    name: 'Tenaya Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TOAC',
    name: 'Talon 1 Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TOACU',
    name: 'Talon 1 Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'TOELF',
    name: 'Tokyo Electron Ltd',
    region: 'US',
  },
  {
    symbol: 'TOELY',
    name: 'Tokyo Electron Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TOI',
    name: 'Oncology Institute Inc (The)',
    region: 'US',
  },
  {
    symbol: 'TOK',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Kokusai ETF',
    region: 'US',
  },
  {
    symbol: 'TOKE',
    name: 'Cambria Investment Management LP - Cambria Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'TOKUF',
    name: 'Tokyu Corporation',
    region: 'US',
  },
  {
    symbol: 'TOKUY',
    name: 'Tokyu Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'TOL',
    name: 'Toll Brothers Inc.',
    region: 'US',
  },
  {
    symbol: 'TOLZ',
    name: 'ProShares Trust - ProShares DJ Brookfield Global Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'TOMZ',
    name: 'TOMI Environmental Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'TOPS',
    name: 'Top Ships Inc',
    region: 'US',
  },
  {
    symbol: 'TORIX',
    name: 'Managed Portfolio Series: Tortoise MLP & Pipeline Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TORTX',
    name: 'Managed Portfolio Series: Tortoise MLP & Pipeline Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TOSBF',
    name: 'Toshiba Corporation',
    region: 'US',
  },
  {
    symbol: 'TOST',
    name: 'Toast Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TOSYY',
    name: 'Toshiba Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'TOTDF',
    name: 'Toto Ltd',
    region: 'US',
  },
  {
    symbol: 'TOTDY',
    name: 'Toto Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TOTL',
    name: 'SSgA Active Trust - SPDR DoubleLine Total Return Tactical ETF',
    region: 'US',
  },
  {
    symbol: 'TOTR',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price Total Return ETF',
    region: 'US',
  },
  {
    symbol: 'TOUR',
    name: 'Tuniu Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'TOWN',
    name: 'Townebank Portsmouth VA',
    region: 'US',
  },
  {
    symbol: 'TPAY',
    name: 'Managed Portfolio Series - Ecofin Digital Payments Infrastructure Fund',
    region: 'US',
  },
  {
    symbol: 'TPB',
    name: 'Turning Point Brands Inc',
    region: 'US',
  },
  {
    symbol: 'TPBA',
    name: 'TPB Acquisition Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'TPBAU',
    name: 'TPB Acquisition Corp I - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TPC',
    name: 'Tutor Perini Corp',
    region: 'US',
  },
  {
    symbol: 'TPG',
    name: 'TPG Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TPH',
    name: 'Tri Pointe Homes Inc.',
    region: 'US',
  },
  {
    symbol: 'TPHD',
    name: 'Timothy Plan - Timothy Plan High Dividend Stock ETF',
    region: 'US',
  },
  {
    symbol: 'TPHE',
    name: 'Timothy Plan - Timothy Plan High Dividend Stock Enhanced ETF',
    region: 'US',
  },
  {
    symbol: 'TPHS',
    name: 'Trinity Place Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'TPIC',
    name: 'TPI Composites Inc',
    region: 'US',
  },
  {
    symbol: 'TPIF',
    name: 'Timothy Plan - Timothy Plan International ETF',
    region: 'US',
  },
  {
    symbol: 'TPL',
    name: 'Texas Pacific Land Corporation',
    region: 'US',
  },
  {
    symbol: 'TPLC',
    name: 'Timothy Plan - Timothy Plan US Large/Mid Cap Core ETF',
    region: 'US',
  },
  {
    symbol: 'TPLE',
    name: 'Timothy Plan - Timothy Plan US Large/Mid Cap Core Enhanced ETF',
    region: 'US',
  },
  {
    symbol: 'TPOR',
    name: 'Direxion Shares ETF Trust - Direxion Daily Transportation Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TPR',
    name: 'Tapestry Inc',
    region: 'US',
  },
  {
    symbol: 'TPSC',
    name: 'Timothy Plan - Timothy Plan US Small Cap Core ETF',
    region: 'US',
  },
  {
    symbol: 'TPST',
    name: 'Tempest Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TPTX',
    name: 'Turning Point Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TPWCX',
    name: 'TIAA-CREF Funds: Social Choice Low Carbon Equity Fund; Premier Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TPX',
    name: 'Tempur Sealy International Inc',
    region: 'US',
  },
  {
    symbol: 'TPYP',
    name: 'Managed Portfolio Series - Tortoise North American Pipeline Fund ETF',
    region: 'US',
  },
  {
    symbol: 'TQMIX',
    name: 'AMG Funds: AMG TimesSquare Mid Cap Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TQQQ',
    name: 'ProShares Trust - ProShares UltraPro QQQ',
    region: 'US',
  },
  {
    symbol: 'TR',
    name: 'Tootsie Roll Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'TRAQ',
    name: 'Trine II Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TRC',
    name: 'Tejon Ranch Co.',
    region: 'US',
  },
  {
    symbol: 'TRCA',
    name: 'Twin Ridge Capital Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TRDA',
    name: 'Entrada Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TRDF',
    name: 'Collaborative Investment Series Trust - The NextGen Trend and Defend ETF',
    region: 'US',
  },
  {
    symbol: 'TREC',
    name: 'Trecora Resources',
    region: 'US',
  },
  {
    symbol: 'TREE',
    name: 'LendingTree Inc.',
    region: 'US',
  },
  {
    symbol: 'TREX',
    name: 'TREX Co., Inc.',
    region: 'US',
  },
  {
    symbol: 'TRGP',
    name: 'Targa Resources Corp',
    region: 'US',
  },
  {
    symbol: 'TRHC',
    name: 'Tabula Rasa HealthCare Inc',
    region: 'US',
  },
  {
    symbol: 'TRHZX',
    name: 'T Rowe Price Index Trust, Inc: T Rowe Price Equity Index 500 Fund; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRI',
    name: 'Thomson-Reuters Corp',
    region: 'US',
  },
  {
    symbol: 'TRIB',
    name: 'Trinity Biotech Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'TRIP',
    name: 'TripAdvisor Inc.',
    region: 'US',
  },
  {
    symbol: 'TRIS',
    name: 'Tristar Acquisition I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TRJZX',
    name: 'T Rowe Price Growth Stock Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRKA',
    name: 'Troika Media Group Inc',
    region: 'US',
  },
  {
    symbol: 'TRMB',
    name: 'Trimble Inc',
    region: 'US',
  },
  {
    symbol: 'TRMD',
    name: 'Torm Plc - Class A',
    region: 'US',
  },
  {
    symbol: 'TRMK',
    name: 'Trustmark Corp.',
    region: 'US',
  },
  {
    symbol: 'TRMR',
    name: 'Tremor International Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TRN',
    name: 'Trinity Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'TRND',
    name: 'Pacer Funds Trust - Pacer Trendpilot Fund of Funds ETF',
    region: 'US',
  },
  {
    symbol: 'TRNS',
    name: 'Transcat Inc',
    region: 'US',
  },
  {
    symbol: 'TRON',
    name: 'Corner Growth Acquisition Corp 2 - Class A',
    region: 'US',
  },
  {
    symbol: 'TRONU',
    name: 'Corner Growth Acquisition Corp 2 - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TROO',
    name: 'TROOPS Inc',
    region: 'US',
  },
  {
    symbol: 'TROX',
    name: 'Tronox Holdings plc - Class A',
    region: 'US',
  },
  {
    symbol: 'TRP',
    name: 'TC Energy Corporation',
    region: 'US',
  },
  {
    symbol: 'TRPIX',
    name: 'T Rowe Price Value Fund, Inc; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRPL',
    name: 'Pacer Funds Trust - Pacer Metaurus US Large Cap Dividend Multiplier 300 ETF',
    region: 'US',
  },
  {
    symbol: 'TRQ',
    name: 'Turquoise Hill Resources Ltd',
    region: 'US',
  },
  {
    symbol: 'TRQZX',
    name: 'T Rowe Price Mid-Cap Growth Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRS',
    name: 'Trimas Corporation',
    region: 'US',
  },
  {
    symbol: 'TRSAX',
    name: 'T Rowe Price Growth Stock Fund, Inc; Advisor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRSPX',
    name: 'TIAA-CREF Funds: S&P 500 Index Fund; Retirement Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRST',
    name: 'Trustco Bank Corp.',
    region: 'US',
  },
  {
    symbol: 'TRT',
    name: 'Trio-Tech International',
    region: 'US',
  },
  {
    symbol: 'TRTL',
    name: 'TortoiseEcofin Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'TRTN',
    name: 'Triton International Ltd',
    region: 'US',
  },
  {
    symbol: 'TRTY',
    name: 'Cambria Investment Management LP - Cambria Trinity ETF',
    region: 'US',
  },
  {
    symbol: 'TRU',
    name: 'TransUnion',
    region: 'US',
  },
  {
    symbol: 'TRUE',
    name: 'Truecar Inc',
    region: 'US',
  },
  {
    symbol: 'TRULX',
    name: 'T Rowe Price US Large-Cap Core Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRUMF',
    name: 'Terumo Corporation',
    region: 'US',
  },
  {
    symbol: 'TRUMY',
    name: 'Terumo Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'TRUP',
    name: 'Trupanion Inc',
    region: 'US',
  },
  {
    symbol: 'TRV',
    name: 'Travelers Companies Inc.',
    region: 'US',
  },
  {
    symbol: 'TRVG',
    name: 'Trivago NV - ADR',
    region: 'US',
  },
  {
    symbol: 'TRVI',
    name: 'Trevi Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TRVLX',
    name: 'T Rowe Price Value Fund, Inc',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRVN',
    name: 'Trevena Inc',
    region: 'US',
  },
  {
    symbol: 'TRX',
    name: 'Tanzanian Gold Corp',
    region: 'US',
  },
  {
    symbol: 'TRYIF',
    name: 'Toray Industries Inc',
    region: 'US',
  },
  {
    symbol: 'TRYIY',
    name: 'Toray Industries Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'TRYP',
    name: 'Tidal ETF Trust - SonicShares Airlines, Hotels, Cruise Lines ETF',
    region: 'US',
  },
  {
    symbol: 'TRZAX',
    name: 'T Rowe Price Value Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRZLX',
    name: 'T Rowe Price US Large-Cap Core Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRZVX',
    name: 'T Rowe Price Small-Cap Value Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TRZZX',
    name: 'T Rowe Price Small-Cap Stock Fund, Inc; Class Z Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TS',
    name: 'Tenaris S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'TSAT',
    name: 'Telesat Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TSBK',
    name: 'Timberland Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'TSC',
    name: 'Tristate Capital Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'TSCDF',
    name: 'Tesco plc',
    region: 'US',
  },
  {
    symbol: 'TSCDY',
    name: 'Tesco plc - ADR',
    region: 'US',
  },
  {
    symbol: 'TSCO',
    name: 'Tractor Supply Co.',
    region: 'US',
  },
  {
    symbol: 'TSCSX',
    name: 'Thrivent Mutual Funds: Thrivent Small Cap Stock Fund; Class S Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TSE',
    name: 'Trinseo PLC',
    region: 'US',
  },
  {
    symbol: 'TSEM',
    name: 'Tower Semiconductor Ltd.',
    region: 'US',
  },
  {
    symbol: 'TSHA',
    name: 'Taysha Gene Therapies Inc',
    region: 'US',
  },
  {
    symbol: 'TSIB',
    name: 'Tishman Speyer Innovation Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'TSIBU',
    name: 'Tishman Speyer Innovation Corp II - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'TSJA',
    name: 'Innovator ETFs Trust - Innovator Triple Stacker ETF - January',
    region: 'US',
  },
  {
    symbol: 'TSLA',
    name: 'Tesla Inc',
    region: 'US',
  },
  {
    symbol: 'TSM',
    name: 'Taiwan Semiconductor Manufacturing - ADR',
    region: 'US',
  },
  {
    symbol: 'TSN',
    name: 'Tyson Foods, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'TSOC',
    name: 'Innovator ETFs Trust - Innovator Triple Stacker ETF - October',
    region: 'US',
  },
  {
    symbol: 'TSP',
    name: 'TuSimple Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TSPA',
    name: 'T. Rowe Price ExchangeTraded Funds Inc - T. Rowe Price U.S. Equity Research ETF',
    region: 'US',
  },
  {
    symbol: 'TSPQ',
    name: 'TCW Special Purpose Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TSQ',
    name: 'Townsquare Media Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TSRI',
    name: 'TSR, Inc.',
    region: 'US',
  },
  {
    symbol: 'TSRPX',
    name: 'TIAA-CREF Funds: Quant Small-Cap Equity Fund; Premier Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TSVT',
    name: '2seventy bio Inc',
    region: 'US',
  },
  {
    symbol: 'TT',
    name: 'Trane Technologies plc - Class A',
    region: 'US',
  },
  {
    symbol: 'TTAC',
    name: 'TrimTabs ETF Trust - FCF US Quality ETF',
    region: 'US',
  },
  {
    symbol: 'TTAEY',
    name: 'Tata Consumer Products Ltd. - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'TTAI',
    name: 'TrimTabs ETF Trust - FCF International Quality ETF',
    region: 'US',
  },
  {
    symbol: 'TTAZF',
    name: 'Tata Consumer Products Ltd. - GDR - Reg S',
    region: 'US',
  },
  {
    symbol: 'TTC',
    name: 'Toro Co.',
    region: 'US',
  },
  {
    symbol: 'TTCF',
    name: 'Tattooed Chef Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TTD',
    name: 'Trade Desk Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TTE',
    name: 'TotalEnergies SE - ADR',
    region: 'US',
  },
  {
    symbol: 'TTEC',
    name: 'TTEC Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'TTEK',
    name: 'Tetra Tech, Inc.',
    region: 'US',
  },
  {
    symbol: 'TTGT',
    name: 'Techtarget Inc.',
    region: 'US',
  },
  {
    symbol: 'TTI',
    name: 'Tetra Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'TTM',
    name: 'Tata Motors Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'TTMI',
    name: 'TTM Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'TTNP',
    name: 'Titan Pharmaceuticals, Inc. (de)',
    region: 'US',
  },
  {
    symbol: 'TTOO',
    name: 'T2 Biosystems Inc',
    region: 'US',
  },
  {
    symbol: 'TTSH',
    name: 'Tile Shop Hldgs Inc',
    region: 'US',
  },
  {
    symbol: 'TTT',
    name: 'ProShares Trust - ProShares UltraPro Short 20+ Year Treasury',
    region: 'US',
  },
  {
    symbol: 'TTWO',
    name: 'Take-Two Interactive Software, Inc.',
    region: 'US',
  },
  {
    symbol: 'TU',
    name: 'Telus Corp.',
    region: 'US',
  },
  {
    symbol: 'TUEM',
    name: 'Tuesday Morning Corp. - New',
    region: 'US',
  },
  {
    symbol: 'TUFN',
    name: 'Tufin Software Technologies Ltd',
    region: 'US',
  },
  {
    symbol: 'TUGC',
    name: 'TradeUP Global Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TUGCU',
    name: 'TradeUP Global Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'TUP',
    name: 'Tupperware Brands Corporation',
    region: 'US',
  },
  {
    symbol: 'TUR',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Turkey ETF',
    region: 'US',
  },
  {
    symbol: 'TUSK',
    name: 'Mammoth Energy Services Inc',
    region: 'US',
  },
  {
    symbol: 'TUYA',
    name: 'Tuya Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'TV',
    name: 'Grupo Televisa SAB - ADR - Level III',
    region: 'US',
  },
  {
    symbol: 'TVC',
    name: 'Tennessee Valley Authority - FXDFR BD REDEEM 01/06/2028 USD 25',
    region: 'US',
  },
  {
    symbol: 'TVTX',
    name: 'Travere Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'TVTY',
    name: 'Tivity Health Inc',
    region: 'US',
  },
  {
    symbol: 'TW',
    name: 'Tradeweb Markets Inc Cls A',
    region: 'US',
  },
  {
    symbol: 'TWCB',
    name: 'Bilander Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TWCBU',
    name: 'Bilander Acquisition Corp - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'TWCCX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TWCUX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TWI',
    name: 'Titan International, Inc.',
    region: 'US',
  },
  {
    symbol: 'TWIN',
    name: 'Twin Disc Incorporated',
    region: 'US',
  },
  {
    symbol: 'TWIO',
    name: 'Spinnaker ETF Trust - Trajan Wealth Income Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'TWKS',
    name: 'Thoughtworks Holding Inc',
    region: 'US',
  },
  {
    symbol: 'TWLO',
    name: 'Twilio Inc Class A',
    region: 'US',
  },
  {
    symbol: 'TWLV',
    name: 'Twelve Seas Investment Co II - Class A',
    region: 'US',
  },
  {
    symbol: 'TWLVU',
    name: 'Twelve Seas Investment Co II - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'TWM',
    name: 'ProShares Trust - ProShares UltraShort Russell2000',
    region: 'US',
  },
  {
    symbol: 'TWMTX',
    name: 'Transamerica Funds: Transamerica US Growth; Class T Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TWND',
    name: 'Tailwind Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TWNI',
    name: 'Tailwind International Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TWNK',
    name: 'Hostess Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TWOA',
    name: 'two - Class A',
    region: 'US',
  },
  {
    symbol: 'TWOU',
    name: '2U Inc',
    region: 'US',
  },
  {
    symbol: 'TWST',
    name: 'Twist Bioscience Corp',
    region: 'US',
  },
  {
    symbol: 'TWTR',
    name: 'Twitter Inc',
    region: 'US',
  },
  {
    symbol: 'TWUAX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TWUIX',
    name: 'American Century Mutual Funds, Inc: Ultra Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'TX',
    name: 'Ternium S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'TXG',
    name: '10x Genomics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'TXMD',
    name: 'TherapeuticsMD Inc',
    region: 'US',
  },
  {
    symbol: 'TXN',
    name: 'Texas Instruments Inc.',
    region: 'US',
  },
  {
    symbol: 'TXRH',
    name: 'Texas Roadhouse Inc',
    region: 'US',
  },
  {
    symbol: 'TXT',
    name: 'Textron Inc.',
    region: 'US',
  },
  {
    symbol: 'TYA',
    name: 'Simplify Exchange Traded Funds - Simplify Risk Parity Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'TYD',
    name: 'Direxion Shares ETF Trust - Direxion Daily 7-10 Year Treasury Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TYHOF',
    name: 'Toyota Tsusho Corporation',
    region: 'US',
  },
  {
    symbol: 'TYIDF',
    name: 'Toyota Industries Corp.',
    region: 'US',
  },
  {
    symbol: 'TYIDY',
    name: 'Toyota Industries Corp. - ADR',
    region: 'US',
  },
  {
    symbol: 'TYL',
    name: 'Tyler Technologies, Inc.',
    region: 'US',
  },
  {
    symbol: 'TYME',
    name: 'Tyme Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'TYNE',
    name: 'Direxion Shares ETF Trust - Direxion Nanotechnology ETF',
    region: 'US',
  },
  {
    symbol: 'TYNPF',
    name: 'Nippon Sanso Holdings Corporation',
    region: 'US',
  },
  {
    symbol: 'TYO',
    name: 'Direxion Shares ETF Trust - Direxion Daily 7-10 Year Treasury Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TYOYF',
    name: 'Taiyo Yuden Co. Ltd',
    region: 'US',
  },
  {
    symbol: 'TYOYY',
    name: 'Taiyo Yuden Co. Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'TYRA',
    name: 'Tyra Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'TZA',
    name: 'Direxion Shares ETF Trust - Direxion Daily Small Cap Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'TZOO',
    name: 'Travelzoo',
    region: 'US',
  },
  {
    symbol: 'TZPS',
    name: 'TZP Strategies Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'TZPSU',
    name: 'TZP Strategies Acquisition Corp - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'U',
    name: 'Unity Software Inc',
    region: 'US',
  },
  {
    symbol: 'UA',
    name: 'Under Armour Inc - Class C',
    region: 'US',
  },
  {
    symbol: 'UAA',
    name: 'Under Armour Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'UAE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI UAE ETF',
    region: 'US',
  },
  {
    symbol: 'UAL',
    name: 'United Airlines Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'UAMY',
    name: 'United States Antimony Corp.',
    region: 'US',
  },
  {
    symbol: 'UAN',
    name: 'CVR Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'UAPR',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'UAUG',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - August',
    region: 'US',
  },
  {
    symbol: 'UAV',
    name: 'Advisorshares Trust - AdvisorShares Drone Technology ETF',
    region: 'US',
  },
  {
    symbol: 'UAVS',
    name: 'AgEagle Aerial Systems Inc.',
    region: 'US',
  },
  {
    symbol: 'UBCB',
    name: 'ETF Opportunities Trust - UBC Algorithmic Fundamentals ETF',
    region: 'US',
  },
  {
    symbol: 'UBCP',
    name: 'United Bancorp, Inc. (Martins Ferry, OH)',
    region: 'US',
  },
  {
    symbol: 'UBER',
    name: 'Uber Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'UBFO',
    name: 'United Security Bancshares (CA)',
    region: 'US',
  },
  {
    symbol: 'UBND',
    name: 'Victory Portfolios II - VictoryShares ESG Core Plus Bond ETF',
    region: 'US',
  },
  {
    symbol: 'UBOH',
    name: 'United Bancshares Inc. (OH)',
    region: 'US',
  },
  {
    symbol: 'UBOT',
    name: 'Direxion Shares ETF Trust - Direxion Daily RoboticsArtificial Intelligence&Automation Ind Bulll 2x',
    region: 'US',
  },
  {
    symbol: 'UBR',
    name: 'ProShares Trust - ProShares Ultra MSCI Brazil Capped ETF',
    region: 'US',
  },
  {
    symbol: 'UBS',
    name: 'UBS Group AG - Registered Shares',
    region: 'US',
  },
  {
    symbol: 'UBSFF',
    name: 'UBISoft Entertainment',
    region: 'US',
  },
  {
    symbol: 'UBSFY',
    name: 'UBISoft Entertainment - ADR',
    region: 'US',
  },
  {
    symbol: 'UBSI',
    name: 'United Bankshares, Inc.',
    region: 'US',
  },
  {
    symbol: 'UBT',
    name: 'ProShares Trust - ProShares Ultra 20+ Year Treasury',
    region: 'US',
  },
  {
    symbol: 'UBX',
    name: 'Unity Biotechnology Inc',
    region: 'US',
  },
  {
    symbol: 'UCBI',
    name: 'United Community Banks Inc',
    region: 'US',
  },
  {
    symbol: 'UCBJF',
    name: 'UCB SA',
    region: 'US',
  },
  {
    symbol: 'UCBJY',
    name: 'UCB SA - ADR',
    region: 'US',
  },
  {
    symbol: 'UCC',
    name: 'ProShares Trust - ProShares Ultra Consumer Services',
    region: 'US',
  },
  {
    symbol: 'UCL',
    name: 'Ucloudlink Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'UCO',
    name: 'ProShares Trust - ProShares Ultra Bloomberg Crude Oil',
    region: 'US',
  },
  {
    symbol: 'UCON',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust TCW Unconstrained Plus Bond ETF',
    region: 'US',
  },
  {
    symbol: 'UCRD',
    name: 'Victory Portfolios II - VictoryShares ESG Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'UCTT',
    name: 'Ultra Clean Hldgs Inc',
    region: 'US',
  },
  {
    symbol: 'UCYB',
    name: 'ProShares Trust - ProShares Ultra Nasdaq Cybersecurity ETF',
    region: 'US',
  },
  {
    symbol: 'UDEC',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'UDMY',
    name: 'Udemy Inc',
    region: 'US',
  },
  {
    symbol: 'UDN',
    name: 'Invesco DB US Dollar Index Trust - Invesco DB US Dollar Index Bearish Fund',
    region: 'US',
  },
  {
    symbol: 'UDOW',
    name: 'ProShares Trust - ProShares UltraPro Dow30',
    region: 'US',
  },
  {
    symbol: 'UEC',
    name: 'Uranium Energy Corp',
    region: 'US',
  },
  {
    symbol: 'UEIC',
    name: 'Universal Electronics Inc.',
    region: 'US',
  },
  {
    symbol: 'UEPS',
    name: 'Net 1 UEPS Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'UEVM',
    name: 'Victory Portfolios II - VictoryShares USAA MSCI Emerging Markets Value Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'UFAB',
    name: 'Unique Fabricating Inc',
    region: 'US',
  },
  {
    symbol: 'UFCS',
    name: 'United Fire Group Inc',
    region: 'US',
  },
  {
    symbol: 'UFEB',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - February',
    region: 'US',
  },
  {
    symbol: 'UFI',
    name: 'UNIFI, Inc.',
    region: 'US',
  },
  {
    symbol: 'UFO',
    name: 'Procure ETF Trust II - Procure Space ETF',
    region: 'US',
  },
  {
    symbol: 'UFPI',
    name: 'UFP Industries Inc',
    region: 'US',
  },
  {
    symbol: 'UFPT',
    name: 'UFP Technologies Inc.',
    region: 'US',
  },
  {
    symbol: 'UG',
    name: 'United-Guardian, Inc.',
    region: 'US',
  },
  {
    symbol: 'UGA',
    name: 'United States Commodity Funds LLC - United States Gasoline Fund LP',
    region: 'US',
  },
  {
    symbol: 'UGCE',
    name: 'Uncommon Investment Funds Trust - Uncommon Portfolio Design Core Equity ETF',
    region: 'US',
  },
  {
    symbol: 'UGE',
    name: 'ProShares Trust - ProShares Ultra Consumer Goods',
    region: 'US',
  },
  {
    symbol: 'UGI',
    name: 'UGI Corp.',
    region: 'US',
  },
  {
    symbol: 'UGL',
    name: 'ProShares Trust - ProShares Ultra Gold',
    region: 'US',
  },
  {
    symbol: 'UGLSX',
    name: 'John Hancock Capital Series: John Hancock US Global Leaders Growth Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'UGP',
    name: 'Ultrapar Participacoes S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'UGRO',
    name: 'Urban-gro Inc',
    region: 'US',
  },
  {
    symbol: 'UHAL',
    name: 'Amerco',
    region: 'US',
  },
  {
    symbol: 'UHS',
    name: 'Universal Health Services, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'UI',
    name: 'Ubiquiti Inc',
    region: 'US',
  },
  {
    symbol: 'UIHC',
    name: 'United Insurance Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'UIS',
    name: 'Unisys Corp.',
    region: 'US',
  },
  {
    symbol: 'UITB',
    name: 'Victory Portfolios II - VictoryShares USAA Core Intermediate-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'UIVM',
    name: 'Victory Portfolios II - VictoryShares USAA MSCI International Value Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'UJAN',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'UJB',
    name: 'ProShares Trust - ProShares Ultra High Yield',
    region: 'US',
  },
  {
    symbol: 'UJUL',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'UJUN',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'UK',
    name: 'Ucommune International Ltd - Class A',
    region: 'US',
  },
  {
    symbol: 'UL',
    name: 'Unilever plc - ADR',
    region: 'US',
  },
  {
    symbol: 'ULBI',
    name: 'Ultralife Corp',
    region: 'US',
  },
  {
    symbol: 'ULCC',
    name: 'Frontier Group Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ULE',
    name: 'ProShares Trust - ProShares Ultra Euro',
    region: 'US',
  },
  {
    symbol: 'ULH',
    name: 'Universal Logistics Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ULST',
    name: 'SSgA Active Trust - SPDR SSgA Ultra Short Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'ULTA',
    name: 'Ulta Beauty Inc',
    region: 'US',
  },
  {
    symbol: 'ULTR',
    name: 'IndexIQ Active ETF Trust - IQ Ultra Short Duration ETF',
    region: 'US',
  },
  {
    symbol: 'ULVM',
    name: 'Victory Portfolios II - VictoryShares USAA MSCI USA Value Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'UMAR',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'UMAY',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - May',
    region: 'US',
  },
  {
    symbol: 'UMBF',
    name: 'UMB Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'UMBMX',
    name: 'Carillon Series Trust: Carillon Scout Mid Cap Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'UMC',
    name: 'United Micro Electronics - ADR',
    region: 'US',
  },
  {
    symbol: 'UMDD',
    name: 'ProShares Trust - ProShares UltraPro MidCap400',
    region: 'US',
  },
  {
    symbol: 'UMI',
    name: 'USCF ETF Trust - USCF Midstream Energy Income Fund ETF',
    region: 'US',
  },
  {
    symbol: 'UMICF',
    name: 'Umicore',
    region: 'US',
  },
  {
    symbol: 'UMICY',
    name: 'Umicore - ADR',
    region: 'US',
  },
  {
    symbol: 'UMMA',
    name: 'Listed Funds Trust - Wahed Dow Jones Islamic World ETF',
    region: 'US',
  },
  {
    symbol: 'UMPQ',
    name: 'Umpqua Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'UNAM',
    name: 'Unico American Corp.',
    region: 'US',
  },
  {
    symbol: 'UNB',
    name: 'Union Bankshares, Inc.',
    region: 'US',
  },
  {
    symbol: 'UNCFF',
    name: 'Unicredit Spa',
    region: 'US',
  },
  {
    symbol: 'UNCRY',
    name: 'Unicredit Spa - ADR',
    region: 'US',
  },
  {
    symbol: 'UNCY',
    name: 'Unicycive Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'UNF',
    name: 'Unifirst Corp.',
    region: 'US',
  },
  {
    symbol: 'UNFI',
    name: 'United Natural Foods Inc.',
    region: 'US',
  },
  {
    symbol: 'UNG',
    name: 'United States Commodity Funds LLC - United States Natural Gas Fund',
    region: 'US',
  },
  {
    symbol: 'UNH',
    name: 'Unitedhealth Group Inc',
    region: 'US',
  },
  {
    symbol: 'UNL',
    name: 'United States Commodity Funds LLC - United States 12 Month Natural Gas Fund',
    region: 'US',
  },
  {
    symbol: 'UNLRF',
    name: 'Unilever Indonesia',
    region: 'US',
  },
  {
    symbol: 'UNLRY',
    name: 'Unilever Indonesia - ADR',
    region: 'US',
  },
  {
    symbol: 'UNM',
    name: 'Unum Group',
    region: 'US',
  },
  {
    symbol: 'UNOV',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - November',
    region: 'US',
  },
  {
    symbol: 'UNP',
    name: 'Union Pacific Corp.',
    region: 'US',
  },
  {
    symbol: 'UNTY',
    name: 'Unity Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'UNVR',
    name: 'Univar Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'UOCT',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'UONE',
    name: 'Urban One Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'UONEK',
    name: 'Urban One Inc - Class D',
    region: 'US',
  },
  {
    symbol: 'UP',
    name: 'Wheels Up Experience Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'UPAR',
    name: 'Tidal ETF Trust - UPAR Ultra Risk Parity ETF',
    region: 'US',
  },
  {
    symbol: 'UPC',
    name: 'Universe Pharmaceuticals INC',
    region: 'US',
  },
  {
    symbol: 'UPH',
    name: 'UpHealth Inc',
    region: 'US',
  },
  {
    symbol: 'UPLD',
    name: 'Upland Software Inc',
    region: 'US',
  },
  {
    symbol: 'UPMKF',
    name: 'UPM-Kymmene Oyj',
    region: 'US',
  },
  {
    symbol: 'UPMMY',
    name: 'UPM-Kymmene Oyj - ADR (Unsponsered)',
    region: 'US',
  },
  {
    symbol: 'UPRO',
    name: 'ProShares Trust - ProShares UltraPro S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'UPS',
    name: 'United Parcel Service, Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'UPST',
    name: 'Upstart Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'UPTD',
    name: 'TradeUP Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'UPTDU',
    name: 'TradeUP Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'UPV',
    name: 'ProShares Trust - ProShares Ultra FTSE Europe',
    region: 'US',
  },
  {
    symbol: 'UPW',
    name: 'ProShares Trust - ProShares Ultra Utilities',
    region: 'US',
  },
  {
    symbol: 'UPWK',
    name: 'Upwork Inc',
    region: 'US',
  },
  {
    symbol: 'URA',
    name: 'Global X Funds - Global X Uranium ETF',
    region: 'US',
  },
  {
    symbol: 'URBN',
    name: 'Urban Outfitters, Inc.',
    region: 'US',
  },
  {
    symbol: 'URE',
    name: 'ProShares Trust - ProShares Ultra Real Estate',
    region: 'US',
  },
  {
    symbol: 'URG',
    name: 'Ur-Energy Inc.',
    region: 'US',
  },
  {
    symbol: 'URGN',
    name: 'UroGen Pharma Ltd',
    region: 'US',
  },
  {
    symbol: 'URI',
    name: 'United Rentals, Inc.',
    region: 'US',
  },
  {
    symbol: 'URISX',
    name: 'USAA Mutual Funds Trust: Income Stock Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'URNM',
    name: 'Sprott Funds Trust. - Sprott Uranium Miners ETF',
    region: 'US',
  },
  {
    symbol: 'UROY',
    name: 'Uranium Royalty Corp',
    region: 'US',
  },
  {
    symbol: 'URTH',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI World ETF',
    region: 'US',
  },
  {
    symbol: 'URTY',
    name: 'ProShares Trust - ProShares UltraPro Russell2000',
    region: 'US',
  },
  {
    symbol: 'USAC',
    name: 'USA Compression Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'USAI',
    name: 'Pacer Funds Trust - Pacer American Energy Independence ETF',
    region: 'US',
  },
  {
    symbol: 'USAK',
    name: 'USA Truck, Inc.',
    region: 'US',
  },
  {
    symbol: 'USAP',
    name: 'Universal Stainless & Alloy Products, Inc.',
    region: 'US',
  },
  {
    symbol: 'USAS',
    name: 'Americas Gold and Silver Corp',
    region: 'US',
  },
  {
    symbol: 'USAU',
    name: 'U.S. Gold Corp',
    region: 'US',
  },
  {
    symbol: 'USB',
    name: 'U.S. Bancorp.',
    region: 'US',
  },
  {
    symbol: 'USBF',
    name: 'iShares Trust - iShares USD Bond Factor ETF',
    region: 'US',
  },
  {
    symbol: 'USCI',
    name: 'United States Commodity Funds LLC - United States Commodity Index Fund',
    region: 'US',
  },
  {
    symbol: 'USCT',
    name: 'Tkb Critical Technologies 1 - Class A',
    region: 'US',
  },
  {
    symbol: 'USCTU',
    name: 'Tkb Critical Technologies 1 - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'USD',
    name: 'ProShares Trust - ProShares Ultra Semiconductors',
    region: 'US',
  },
  {
    symbol: 'USDP',
    name: 'USD Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'USDU',
    name: 'WisdomTree Trust - WisdomTree Bloomberg U.S. Dollar Bullish Fund',
    region: 'US',
  },
  {
    symbol: 'USEG',
    name: 'U.S. Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'USEP',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Ultra Buffer ETF - September',
    region: 'US',
  },
  {
    symbol: 'USEQ',
    name: 'Invesco Capital Management LLC - Invesco Russell 1000 Enhanced Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'USER',
    name: 'UserTesting Inc',
    region: 'US',
  },
  {
    symbol: 'USFD',
    name: 'US Foods Holding Corp',
    region: 'US',
  },
  {
    symbol: 'USFR',
    name: 'WisdomTree Trust - WisdomTree Floating Rate Treasury Fund',
    region: 'US',
  },
  {
    symbol: 'USHY',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Broad USD High Yield Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'USI',
    name: 'Principal Exchange-Traded Funds - Principal Ultra-Short Active Income ETF',
    region: 'US',
  },
  {
    symbol: 'USIG',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Broad USD Investment Grade Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'USIO',
    name: 'Usio Inc',
    region: 'US',
  },
  {
    symbol: 'USL',
    name: 'United States Commodity Funds LLC - United States 12 Month Oil Fund LP',
    region: 'US',
  },
  {
    symbol: 'USLB',
    name: 'Invesco Capital Management LLC - Invesco Russell 1000 Low Beta Equal Weight ETF',
    region: 'US',
  },
  {
    symbol: 'USLIX',
    name: 'John Hancock Capital Series: John Hancock US Global Leaders Growth Fund; Class I Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'USLM',
    name: 'United States Lime & Minerals Inc.',
    region: 'US',
  },
  {
    symbol: 'USM',
    name: 'United States Cellular Corporation',
    region: 'US',
  },
  {
    symbol: 'USMC',
    name: 'Principal Exchange-Traded Funds - Principal U.S. Mega-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'USMF',
    name: 'WisdomTree Trust - WisdomTree U.S. Multifactor Fund',
    region: 'US',
  },
  {
    symbol: 'USMV',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Min Vol Factor ETF',
    region: 'US',
  },
  {
    symbol: 'USNA',
    name: 'Usana Health Sciences Inc',
    region: 'US',
  },
  {
    symbol: 'USO',
    name: 'United States Commodity Funds LLC - United States Oil Fund',
    region: 'US',
  },
  {
    symbol: 'USPH',
    name: 'U.S. Physical Therapy, Inc.',
    region: 'US',
  },
  {
    symbol: 'USRT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Core U.S. REIT ETF',
    region: 'US',
  },
  {
    symbol: 'USSG',
    name: 'DBX ETF Trust - Xtrackers MSCI USA ESG Leaders Equity ETF',
    region: 'US',
  },
  {
    symbol: 'UST',
    name: 'ProShares Trust - ProShares Ultra 7-10 Year Treasury',
    region: 'US',
  },
  {
    symbol: 'USTB',
    name: 'Victory Portfolios II - VictoryShares USAA Core Short-Term Bond ETF',
    region: 'US',
  },
  {
    symbol: 'USVM',
    name: 'Victory Portfolios II - VictoryShares USAA MSCI USA Small Cap Value Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'USVT',
    name: 'Ultimus Managers Trust - US Value ETF',
    region: 'US',
  },
  {
    symbol: 'USWS',
    name: 'U.S. Well Services Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'USX',
    name: 'U.S. Xpress Enterprises Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'USXF',
    name: 'iShares Trust - iShares ESG Advanced MSCI USA ETF',
    region: 'US',
  },
  {
    symbol: 'UTAA',
    name: 'UTA Acquisition Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'UTAAU',
    name: 'UTA Acquisition Corp. - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'UTES',
    name: 'ETFis Series Trust I - Virtus Reaves Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'UTHR',
    name: 'United Therapeutics Corp',
    region: 'US',
  },
  {
    symbol: 'UTI',
    name: 'Universal Technical Institute Inc',
    region: 'US',
  },
  {
    symbol: 'UTL',
    name: 'Unitil Corp.',
    region: 'US',
  },
  {
    symbol: 'UTMD',
    name: 'Utah Medical Products, Inc.',
    region: 'US',
  },
  {
    symbol: 'UTME',
    name: 'UTime Ltd',
    region: 'US',
  },
  {
    symbol: 'UTRN',
    name: 'Exchange Traded Concepts Trust - Vesper U.S. Large Cap Short-Term Reversal Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'UTRS',
    name: 'Minerva Surgical Inc',
    region: 'US',
  },
  {
    symbol: 'UTSI',
    name: 'UTStarcom Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'UTSL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Utilities Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'UTZ',
    name: 'Utz Brands Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'UUP',
    name: 'Invesco DB US Dollar Index Trust - Invesco DB US Dollar Index Bullish Fund',
    region: 'US',
  },
  {
    symbol: 'UUU',
    name: 'Universal Security Instruments, Inc.',
    region: 'US',
  },
  {
    symbol: 'UUUU',
    name: 'Energy Fuels Inc',
    region: 'US',
  },
  {
    symbol: 'UVDV',
    name: 'Spinnaker ETF Trust - UVA Dividend Value ETF',
    region: 'US',
  },
  {
    symbol: 'UVE',
    name: 'Universal Insurance Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'UVIX',
    name: 'VS Trust - 2x Long VIX Futures ETF',
    region: 'US',
  },
  {
    symbol: 'UVSP',
    name: 'Univest Financial Corp',
    region: 'US',
  },
  {
    symbol: 'UVV',
    name: 'Universal Corp.',
    region: 'US',
  },
  {
    symbol: 'UVXY',
    name: 'ProShares Trust - ProShares Ultra VIX Short-Term Futures ETF',
    region: 'US',
  },
  {
    symbol: 'UWM',
    name: 'ProShares Trust - ProShares Ultra Russell2000',
    region: 'US',
  },
  {
    symbol: 'UWMC',
    name: 'UWM Holdings Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'UXI',
    name: 'ProShares Trust - ProShares Ultra Industrials',
    region: 'US',
  },
  {
    symbol: 'UXIN',
    name: 'Uxin Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'UYG',
    name: 'ProShares Trust - ProShares Ultra Financials',
    region: 'US',
  },
  {
    symbol: 'UYM',
    name: 'ProShares Trust - ProShares Ultra Basic Materials',
    region: 'US',
  },
  {
    symbol: 'V',
    name: 'Visa Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VABK',
    name: 'Virginia National Bankshares Corp',
    region: 'US',
  },
  {
    symbol: 'VABS',
    name: 'Virtus ETF Trust II - Virtus Newfleet ABS/MBS ETF',
    region: 'US',
  },
  {
    symbol: 'VACC',
    name: 'Vaccitech plc - ADR',
    region: 'US',
  },
  {
    symbol: 'VACNY',
    name: 'VAT Group AG - ADR',
    region: 'US',
  },
  {
    symbol: 'VAL',
    name: 'Valaris Ltd',
    region: 'US',
  },
  {
    symbol: 'VALE',
    name: 'Vale S.A. - ADR',
    region: 'US',
  },
  {
    symbol: 'VALN',
    name: 'Valneva SE - ADR',
    region: 'US',
  },
  {
    symbol: 'VALQ',
    name: 'American Century ETF Trust - American Century STOXX U.S. Quality Value ETF',
    region: 'US',
  },
  {
    symbol: 'VALT',
    name: 'ETF Managers Trust - ETFMG Sit Ultra Short ETF',
    region: 'US',
  },
  {
    symbol: 'VALU',
    name: 'Value Line, Inc.',
    region: 'US',
  },
  {
    symbol: 'VAMO',
    name: 'Cambria Investment Management LP - Cambria ETF Trust Cambria Value and Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'VAPO',
    name: 'Vapotherm Inc',
    region: 'US',
  },
  {
    symbol: 'VAQC',
    name: 'Vector Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'VATE',
    name: 'Innovate Corp',
    region: 'US',
  },
  {
    symbol: 'VAW',
    name: 'Vanguard Group, Inc. - Vanguard Materials ETF',
    region: 'US',
  },
  {
    symbol: 'VAXX',
    name: 'Vaxxinity Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VB',
    name: 'Vanguard Group, Inc. - Vanguard Small Cap ETF',
    region: 'US',
  },
  {
    symbol: 'VBB',
    name: 'Valkyrie ETF Trust II - Valkyrie Balance Sheet Opportunities ETF',
    region: 'US',
  },
  {
    symbol: 'VBFC',
    name: 'Village Bank & Trust Financial Corporation',
    region: 'US',
  },
  {
    symbol: 'VBIV',
    name: 'VBI Vaccines Inc.',
    region: 'US',
  },
  {
    symbol: 'VBK',
    name: 'Vanguard Group, Inc. - Vanguard Small Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'VBLT',
    name: 'Vascular Biogenics Ltd',
    region: 'US',
  },
  {
    symbol: 'VBND',
    name: 'ETF Series Solutions Trust - ESS Vident Core U.S. Bond Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'VBNK',
    name: 'VersaBank.',
    region: 'US',
  },
  {
    symbol: 'VBOC',
    name: 'Viscogliosi Brothers Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'VBOCU',
    name: 'Viscogliosi Brothers Acquisition Corp - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'VBR',
    name: 'Vanguard Group, Inc. - Vanguard Small Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'VBTX',
    name: 'Veritex Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'VC',
    name: 'Visteon Corp.',
    region: 'US',
  },
  {
    symbol: 'VCAR',
    name: 'Simplify Exchange Traded Funds - Simplify Volt RoboCar Disruption and Tech ETF',
    region: 'US',
  },
  {
    symbol: 'VCBCX',
    name: 'Blue Chip Growth Fund Blue Chip Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VCDAX',
    name: 'Vanguard World Funds: Vanguard Consumer Discretionary Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VCEB',
    name: 'Vanguard Group, Inc. - Vanguard ESG U.S. Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VCEL',
    name: 'Vericel Corp',
    region: 'US',
  },
  {
    symbol: 'VCISF',
    name: 'Vinci',
    region: 'US',
  },
  {
    symbol: 'VCISY',
    name: 'Vinci - ADR',
    region: 'US',
  },
  {
    symbol: 'VCIT',
    name: 'Vanguard Group, Inc. - Vanguard Intermediate-Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VCKA',
    name: 'Vickers Vantage Corp I',
    region: 'US',
  },
  {
    symbol: 'VCKAU',
    name: 'Vickers Vantage Corp I - Units (1 Ord & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'VCLN',
    name: 'Virtus ETF Trust II - Virtus Duff & Phelps Clean Energy ETF',
    region: 'US',
  },
  {
    symbol: 'VCLO',
    name: 'Simplify Exchange Traded Funds - Simplify Volt Cloud and Cybersecurity Disruption ETF',
    region: 'US',
  },
  {
    symbol: 'VCLT',
    name: 'Vanguard Group, Inc. - Vanguard Long-Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VCNIX',
    name: 'Nasdaq-100 Index Fund Nasdaq-100 Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VCNX',
    name: 'Vaccinex Inc',
    region: 'US',
  },
  {
    symbol: 'VCR',
    name: 'Vanguard Group, Inc. - Vanguard Consumer Discretionary ETF',
    region: 'US',
  },
  {
    symbol: 'VCSA',
    name: 'Vacasa Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VCSAX',
    name: 'Vanguard World Funds: Vanguard Consumer Staples Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VCSH',
    name: 'Vanguard Group, Inc. - Vanguard Short-Term Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VCXA',
    name: '10X Capital Venture Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'VCXAU',
    name: '10X Capital Venture Acquisition Corp II - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'VCXB',
    name: '10X Capital Venture Acquisition Corp III - Class A',
    region: 'US',
  },
  {
    symbol: 'VCYT',
    name: 'Veracyte Inc',
    region: 'US',
  },
  {
    symbol: 'VDC',
    name: 'Vanguard Group, Inc. - Vanguard Consumer Staples ETF',
    region: 'US',
  },
  {
    symbol: 'VDE',
    name: 'Vanguard Group, Inc. - Vanguard Energy ETF',
    region: 'US',
  },
  {
    symbol: 'VDNI',
    name: 'Managed Portfolio Series - V-Shares US Leadership Diversity ETF',
    region: 'US',
  },
  {
    symbol: 'VEA',
    name: 'Vanguard Group, Inc. - Vanguard FTSE Developed Markets ETF',
    region: 'US',
  },
  {
    symbol: 'VEC',
    name: 'Vectrus Inc',
    region: 'US',
  },
  {
    symbol: 'VECO',
    name: 'Veeco Instruments Inc',
    region: 'US',
  },
  {
    symbol: 'VECT',
    name: 'VectivBio Holding AG',
    region: 'US',
  },
  {
    symbol: 'VEEE',
    name: 'Twin Vee PowerCats Co',
    region: 'US',
  },
  {
    symbol: 'VEEV',
    name: 'Veeva Systems Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VEGA',
    name: 'Advisorshares Trust - AdvisorShares STAR Global Buy-Write ETF',
    region: 'US',
  },
  {
    symbol: 'VEGI',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI Global Agriculture Producers ETF',
    region: 'US',
  },
  {
    symbol: 'VEGN',
    name: 'ETF Series Solutions Trust - US Vegan Climate ETF',
    region: 'US',
  },
  {
    symbol: 'VELO',
    name: 'Velocity Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VELOU',
    name: 'Velocity Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'VENA',
    name: 'Venus Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'VENAU',
    name: 'Venus Acquisition Corp - Units (1 Ord ,1 Right & 1 War)',
    region: 'US',
  },
  {
    symbol: 'VENAX',
    name: 'Vanguard World Funds: Vanguard Energy Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VEOEF',
    name: 'Veolia Environnement',
    region: 'US',
  },
  {
    symbol: 'VEOEY',
    name: 'Veolia Environnement - ADR',
    region: 'US',
  },
  {
    symbol: 'VEON',
    name: 'VEON Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'VERA',
    name: 'Vera Therapeutics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VERB',
    name: 'Verb Technology Company Inc',
    region: 'US',
  },
  {
    symbol: 'VERI',
    name: 'Veritone Inc',
    region: 'US',
  },
  {
    symbol: 'VERO',
    name: 'Venus Concept Inc',
    region: 'US',
  },
  {
    symbol: 'VERS',
    name: 'ProShares Trust - ProShares Metaverse ETF',
    region: 'US',
  },
  {
    symbol: 'VERU',
    name: 'Veru Inc',
    region: 'US',
  },
  {
    symbol: 'VERV',
    name: 'Verve Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VERX',
    name: 'Vertex Inc Class A',
    region: 'US',
  },
  {
    symbol: 'VERY',
    name: 'Vericity Inc',
    region: 'US',
  },
  {
    symbol: 'VET',
    name: 'Vermilion Energy Inc',
    region: 'US',
  },
  {
    symbol: 'VEU',
    name: 'Vanguard Group, Inc. - Vanguard FTSE All-World ex-US ETF',
    region: 'US',
  },
  {
    symbol: 'VEV',
    name: 'Vicinity Motor Corp',
    region: 'US',
  },
  {
    symbol: 'VFAIX',
    name: 'Vanguard World Funds: Vanguard Financials Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VFC',
    name: 'VF Corp.',
    region: 'US',
  },
  {
    symbol: 'VFF',
    name: 'Village Farms International Inc',
    region: 'US',
  },
  {
    symbol: 'VFFSX',
    name: 'Vanguard Index Funds: Vanguard 500 Index Fund; Institutional Select Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VFH',
    name: 'Vanguard Group, Inc. - Vanguard Financials ETF',
    region: 'US',
  },
  {
    symbol: 'VFIAX',
    name: 'Vanguard Index Funds: Vanguard 500 Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VFIN',
    name: 'Simplify Exchange Traded Funds - Simplify Volt Fintech Disruption ETF',
    region: 'US',
  },
  {
    symbol: 'VFINX',
    name: 'Vanguard Index Funds: Vanguard 500 Index Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VFLQ',
    name: 'Vanguard Group, Inc. - Vanguard U.S. Liquidity Factor ETF',
    region: 'US',
  },
  {
    symbol: 'VFMF',
    name: 'Vanguard Group, Inc. - Vanguard U.S. Multifactor ETF',
    region: 'US',
  },
  {
    symbol: 'VFMO',
    name: 'Vanguard Group, Inc. - Vanguard U.S. Momentum Factor ETF',
    region: 'US',
  },
  {
    symbol: 'VFMV',
    name: 'Vanguard Group, Inc. - Vanguard U.S. Minimum Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'VFQY',
    name: 'Vanguard Group, Inc. - Vanguard U.S. Quality Factor ETF',
    region: 'US',
  },
  {
    symbol: 'VFTAX',
    name: 'Vanguard World Funds: Vanguard FTSE Social Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VFTNX',
    name: 'Vanguard World Funds: Vanguard FTSE Social Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VFVA',
    name: 'Vanguard Group, Inc. - Vanguard U.S. Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'VG',
    name: 'Vonage Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'VGFC',
    name: 'Very Good Food Company Inc (the)',
    region: 'US',
  },
  {
    symbol: 'VGII',
    name: 'Virgin Group Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'VGIT',
    name: 'Vanguard Group, Inc. - Vanguard Intermediate-Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'VGK',
    name: 'Vanguard Group, Inc. - Vanguard FTSE Europe ETF',
    region: 'US',
  },
  {
    symbol: 'VGLT',
    name: 'Vanguard Group, Inc. - Vanguard Long-Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'VGR',
    name: 'Vector Group Ltd',
    region: 'US',
  },
  {
    symbol: 'VGSH',
    name: 'Vanguard Group, Inc. - Vanguard Short-Term Treasury ETF',
    region: 'US',
  },
  {
    symbol: 'VGT',
    name: 'Vanguard Group, Inc. - Vanguard Information Technology ETF',
    region: 'US',
  },
  {
    symbol: 'VGZ',
    name: 'Vista Gold Corp.',
    region: 'US',
  },
  {
    symbol: 'VHAQ',
    name: 'Viveon Health Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'VHC',
    name: 'Virnetx Holding Corp',
    region: 'US',
  },
  {
    symbol: 'VHCIX',
    name: 'Vanguard World Funds: Vanguard Health Care Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VHI',
    name: 'Valhi, Inc.',
    region: 'US',
  },
  {
    symbol: 'VHNA',
    name: 'Vahanna Tech Edge Acquisition I Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VHNAU',
    name: 'Vahanna Tech Edge Acquisition I Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'VHT',
    name: 'Vanguard Group, Inc. - Vanguard Health Care ETF',
    region: 'US',
  },
  {
    symbol: 'VIA',
    name: 'Via Renewables Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VIAO',
    name: 'VIA optronics AG - ADR',
    region: 'US',
  },
  {
    symbol: 'VIAV',
    name: 'Viavi Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'VICE',
    name: 'Advisorshares Trust - AdvisorShares Vice ETF',
    region: 'US',
  },
  {
    symbol: 'VICR',
    name: 'Vicor Corp.',
    region: 'US',
  },
  {
    symbol: 'VIDI',
    name: 'WisdomTree Trust - WisdomTree Vident International Equity Fund',
    region: 'US',
  },
  {
    symbol: 'VIEW',
    name: 'View Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'VIG',
    name: 'Vanguard Group, Inc. - Vanguard Dividend Appreciation FTF',
    region: 'US',
  },
  {
    symbol: 'VIGAX',
    name: 'Vanguard Index Funds: Vanguard Growth Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIGI',
    name: 'Vanguard Group, Inc. - Vanguard International Dividend Appreciation ETF',
    region: 'US',
  },
  {
    symbol: 'VIGIX',
    name: 'Vanguard Index Funds: Vanguard Growth Index Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIGL',
    name: 'Vigil Neuroscience Inc',
    region: 'US',
  },
  {
    symbol: 'VIGRX',
    name: 'Vanguard Index Funds: Vanguard Growth Index Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VII',
    name: '7GC & Co Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VIIAU',
    name: '7GC & Co Holdings Inc - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'VIIIX',
    name: 'Vanguard Institutional Index Fund: Vanguard Institutional Index Fund; Institutional Plus Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIMAX',
    name: 'Vanguard Index Funds: Vanguard Mid-Cap Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIMGF',
    name: 'Vimian Group AB',
    region: 'US',
  },
  {
    symbol: 'VIMSX',
    name: 'Vanguard Index Funds: Vanguard Mid-Cap Index Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VINAX',
    name: 'Vanguard World Funds: Vanguard Industrials Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VINC',
    name: 'Vincerx Pharma Inc',
    region: 'US',
  },
  {
    symbol: 'VINE',
    name: 'Fresh Vine Wine Inc',
    region: 'US',
  },
  {
    symbol: 'VINIX',
    name: 'Vanguard Institutional Index Fund: Vanguard Institutional Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VINO',
    name: 'Gaucho Group Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'VIOG',
    name: 'Vanguard Group, Inc. - Vanguard S&P Small-Cap 600 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'VIOO',
    name: 'Vanguard Group, Inc. - Vanguard S&P Small-Cap 600 ETF',
    region: 'US',
  },
  {
    symbol: 'VIOT',
    name: 'Viomi Technology Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'VIOV',
    name: 'Vanguard Group, Inc. - Vanguard S&P Small-Cap 600 Value ETF',
    region: 'US',
  },
  {
    symbol: 'VIPS',
    name: 'Vipshop Holdings Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'VIR',
    name: 'Vir Biotechnology Inc',
    region: 'US',
  },
  {
    symbol: 'VIRC',
    name: 'Virco Manufacturing Corp.',
    region: 'US',
  },
  {
    symbol: 'VIRI',
    name: 'Virios Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VIRS',
    name: 'Pacer Funds Trust - Pacer BioThreat Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'VIS',
    name: 'Vanguard Group, Inc. - Vanguard Industrials ETF',
    region: 'US',
  },
  {
    symbol: 'VISL',
    name: 'Vislink Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'VIST',
    name: 'Vista Energy S.A.B. de C.V - ADR',
    region: 'US',
  },
  {
    symbol: 'VITAX',
    name: 'Vanguard World Funds: Vanguard Information Technology Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VITL',
    name: 'Vital Farms Inc',
    region: 'US',
  },
  {
    symbol: 'VITNX',
    name: 'Vanguard Institutional Index Fund: Vanguard Institutional Total Stock Market Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VITPX',
    name: 'Vanguard Institutional Index Fund: Vanguard Institutional Total Stock Market Index Fund; Institutional Plus',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VITSX',
    name: 'Vanguard Index Funds: Vanguard Total Stock Market Index Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIV',
    name: 'Telefonica Brasil S.A., - ADR (Representing Ord)',
    region: 'US',
  },
  {
    symbol: 'VIVAX',
    name: 'Vanguard Index Funds: Vanguard Value Index Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIVE',
    name: 'Viveve Medical Inc',
    region: 'US',
  },
  {
    symbol: 'VIVEF',
    name: 'Vivendi SE',
    region: 'US',
  },
  {
    symbol: 'VIVHY',
    name: 'Vivendi SE - ADR',
    region: 'US',
  },
  {
    symbol: 'VIVIX',
    name: 'Vanguard Index Funds: Vanguard Value Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VIVK',
    name: 'Vivakor Inc',
    region: 'US',
  },
  {
    symbol: 'VIVO',
    name: 'Meridian Bioscience Inc.',
    region: 'US',
  },
  {
    symbol: 'VIXM',
    name: 'ProShares Trust - ProShares VIX Mid-Term Futures ETF',
    region: 'US',
  },
  {
    symbol: 'VIXY',
    name: 'ProShares Trust - ProShares VIX Short-Term Futures ETF',
    region: 'US',
  },
  {
    symbol: 'VJET',
    name: 'Voxeljet AG - ADR',
    region: 'US',
  },
  {
    symbol: 'VKTX',
    name: 'Viking Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VLACX',
    name: 'Vanguard Index Funds: Vanguard Large-Cap Index Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VLAT',
    name: 'Valor Latitude Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VLATU',
    name: 'Valor Latitude Acquisition Corp - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'VLCAX',
    name: 'Vanguard Index Funds: Vanguard Large-Cap Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VLCN',
    name: 'Volcon Inc',
    region: 'US',
  },
  {
    symbol: 'VLD',
    name: 'Velo3D Inc - New',
    region: 'US',
  },
  {
    symbol: 'VLDR',
    name: 'Velodyne Lidar Inc',
    region: 'US',
  },
  {
    symbol: 'VLGEA',
    name: 'Village Super Market, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'VLISX',
    name: 'Vanguard Index Funds: Vanguard Large-Cap Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VLNS',
    name: 'Valens Company Inc (The)',
    region: 'US',
  },
  {
    symbol: 'VLO',
    name: 'Valero Energy Corp.',
    region: 'US',
  },
  {
    symbol: 'VLON',
    name: 'Vallon Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'VLRS',
    name: 'Controladora Vuela Cia De Aviacion - ADR',
    region: 'US',
  },
  {
    symbol: 'VLTA',
    name: 'Volta Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VLU',
    name: 'SPDR Series Trust - SPDR S&P 1500 Value Tilt ETF',
    region: 'US',
  },
  {
    symbol: 'VLUE',
    name: 'BlackRock Institutional Trust Company N.A. - iShares MSCI USA Value Factor ETF',
    region: 'US',
  },
  {
    symbol: 'VLVLY',
    name: 'Volvo AB - ADR - Unsponsored',
    region: 'US',
  },
  {
    symbol: 'VLY',
    name: 'Valley National Bancorp',
    region: 'US',
  },
  {
    symbol: 'VMAR',
    name: 'Vision Marine Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'VMBS',
    name: 'Vanguard Group, Inc. - Vanguard Mortgage-Backed Securities ETF',
    region: 'US',
  },
  {
    symbol: 'VMC',
    name: 'Vulcan Materials Co',
    region: 'US',
  },
  {
    symbol: 'VMCA',
    name: 'Valuence Merger Corp I - Class A',
    region: 'US',
  },
  {
    symbol: 'VMCAU',
    name: 'Valuence Merger Corp I - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'VMCIX',
    name: 'Vanguard Index Funds: Vanguard Mid-Cap Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMCPX',
    name: 'Vanguard Index Funds: Vanguard Mid-Cap Index Fund; Institutional Plus Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMCTX',
    name: 'Vanguard World Funds: Vanguard Mega Cap Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMD',
    name: 'Viemed Healthcare Inc',
    region: 'US',
  },
  {
    symbol: 'VMEO',
    name: 'Vimeo Inc',
    region: 'US',
  },
  {
    symbol: 'VMFVX',
    name: 'Vanguard Admiral Funds: Vanguard S&P Mid-Cap 400 Value Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMGA',
    name: 'VMG Consumer Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VMGAU',
    name: 'VMG Consumer Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'VMGAX',
    name: 'Vanguard World Funds: Vanguard Mega Cap Growth Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMI',
    name: 'Valmont Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'VMIAX',
    name: 'Vanguard World Funds: Vanguard Materials Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMOT',
    name: 'Alpha Architect Etf Trust - Alpha Architect Value Momentum Trend ETF',
    region: 'US',
  },
  {
    symbol: 'VMSGX',
    name: 'Mid Cap Strategic Growth Fund Mid Cap Strategic Growth Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMVAX',
    name: 'Vanguard Index Funds: Vanguard Mid-Cap Value Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMVIX',
    name: 'Vanguard Index Funds: Vanguard Mid-Cap Value Index Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMVLX',
    name: 'Vanguard World Funds: Vanguard Mega Cap Value Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VMW',
    name: 'Vmware Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'VNAM',
    name: 'Global X Funds - Global X MSCI Vietnam ETF',
    region: 'US',
  },
  {
    symbol: 'VNCE',
    name: 'Vince Holding Corp',
    region: 'US',
  },
  {
    symbol: 'VNDA',
    name: 'Vanda Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'VNET',
    name: 'VNET Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'VNLA',
    name: 'Janus Capital Management LLC - Janus Henderson Short Duration Income ETF',
    region: 'US',
  },
  {
    symbol: 'VNM',
    name: 'VanEck ETF Trust - VanEck Vietnam ETF',
    region: 'US',
  },
  {
    symbol: 'VNMC',
    name: 'Natixis ETF Trust II - Natixis Vaughan Nelson Mid Cap ETF',
    region: 'US',
  },
  {
    symbol: 'VNO',
    name: 'Vornado Realty Trust',
    region: 'US',
  },
  {
    symbol: 'VNOM',
    name: 'Viper Energy Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'VNQ',
    name: 'Vanguard Group, Inc. - Vanguard Real Estate Index Fund ETF',
    region: 'US',
  },
  {
    symbol: 'VNQI',
    name: 'Vanguard Group, Inc. - Vanguard Global ex-U.S. Real Estate ETF',
    region: 'US',
  },
  {
    symbol: 'VNRX',
    name: 'VolitionRX Ltd',
    region: 'US',
  },
  {
    symbol: 'VNSE',
    name: 'Natixis ETF Trust II - Natixis Vaughan Nelson Select ETF',
    region: 'US',
  },
  {
    symbol: 'VNT',
    name: 'Vontier Corporation',
    region: 'US',
  },
  {
    symbol: 'VNTR',
    name: 'Venator Materials PLC',
    region: 'US',
  },
  {
    symbol: 'VO',
    name: 'Vanguard Group, Inc. - Vanguard Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'VOC',
    name: 'VOC Energy Trust - Unit',
    region: 'US',
  },
  {
    symbol: 'VOD',
    name: 'Vodafone Group plc - ADR',
    region: 'US',
  },
  {
    symbol: 'VOE',
    name: 'Vanguard Group, Inc. - Vanguard Mid-Cap Value ETF',
    region: 'US',
  },
  {
    symbol: 'VOLAF',
    name: 'Volvo AB - Class A',
    region: 'US',
  },
  {
    symbol: 'VOLVF',
    name: 'Volvo AB - Class B',
    region: 'US',
  },
  {
    symbol: 'VONE',
    name: 'Vanguard Group, Inc. - Vanguard Russell 1000 Index ETF',
    region: 'US',
  },
  {
    symbol: 'VONG',
    name: 'Vanguard Group, Inc. - Vanguard Russell 1000 Growth Index ETF',
    region: 'US',
  },
  {
    symbol: 'VONV',
    name: 'Vanguard Group, Inc. - Vanguard Russell 1000 Value Index ETF',
    region: 'US',
  },
  {
    symbol: 'VOO',
    name: 'Vanguard Group, Inc. - Vanguard S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'VOOG',
    name: 'Vanguard Group, Inc. - Vanguard S&P 500 Growth ETF',
    region: 'US',
  },
  {
    symbol: 'VOOV',
    name: 'Vanguard Group, Inc. - Vanguard S&P 500 Value ETF',
    region: 'US',
  },
  {
    symbol: 'VOR',
    name: 'Vor Biopharma Inc',
    region: 'US',
  },
  {
    symbol: 'VORB',
    name: 'Virgin Orbit Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'VOT',
    name: 'Vanguard Group, Inc. - Vanguard Mid-Cap Growth ETF',
    region: 'US',
  },
  {
    symbol: 'VOTE',
    name: 'Engine No. 1 ETF Trust - Engine No. 1 Transform 500 ETF',
    region: 'US',
  },
  {
    symbol: 'VOX',
    name: 'Vanguard Group, Inc. - Vanguard Communication Services ETF',
    region: 'US',
  },
  {
    symbol: 'VOXX',
    name: 'VOXX International Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VOYA',
    name: 'Voya Financial Inc',
    region: 'US',
  },
  {
    symbol: 'VPC',
    name: 'ETFis Series Trust I - Virtus Private Credit Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'VPCB',
    name: 'VPC Impact Acquisition Holdings II - Class A',
    region: 'US',
  },
  {
    symbol: 'VPCBU',
    name: 'VPC Impact Acquisition Holdings II - Units (1 Ord Share Class A & 1/4 War)',
    region: 'US',
  },
  {
    symbol: 'VPG',
    name: 'Vishay Precision Group Inc',
    region: 'US',
  },
  {
    symbol: 'VPL',
    name: 'Vanguard Group, Inc. - Vanguard FTSE Pacific ETF',
    region: 'US',
  },
  {
    symbol: 'VPMOX',
    name: 'Voya Equity Trust: Voya MidCap Opportunities Fund; Class P3 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VPN',
    name: 'Global X Funds - Global X Data Center REITs & Digital Infrastructure ETF',
    region: 'US',
  },
  {
    symbol: 'VPOP',
    name: 'Simplify Exchange Traded Funds - Simplify Volt Pop Culture Disruption ETF',
    region: 'US',
  },
  {
    symbol: 'VPU',
    name: 'Vanguard Group, Inc. - Vanguard Utilities ETF',
    region: 'US',
  },
  {
    symbol: 'VQS',
    name: 'VIQ Solutions Inc',
    region: 'US',
  },
  {
    symbol: 'VR',
    name: 'Global X Funds - Global X Metaverse ETF',
    region: 'US',
  },
  {
    symbol: 'VRA',
    name: 'Vera Bradley Inc',
    region: 'US',
  },
  {
    symbol: 'VRAI',
    name: 'ETFis Series Trust I - Virtus Real Asset Income ETF',
    region: 'US',
  },
  {
    symbol: 'VRAR',
    name: 'Glimpse Group Inc (The)',
    region: 'US',
  },
  {
    symbol: 'VRAY',
    name: 'ViewRay Inc.',
    region: 'US',
  },
  {
    symbol: 'VRCA',
    name: 'Verrica Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'VRDN',
    name: 'Viridian Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VREX',
    name: 'Varex Imaging Corp',
    region: 'US',
  },
  {
    symbol: 'VRGWX',
    name: 'Vanguard Scottsdale Funds: Vanguard Russell 1000 Growth Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VRIG',
    name: 'Invesco Capital Management LLC - Invesco Variable Rate Investment Grade ETF',
    region: 'US',
  },
  {
    symbol: 'VRM',
    name: 'Vroom Inc.',
    region: 'US',
  },
  {
    symbol: 'VRME',
    name: 'VerifyMe Inc',
    region: 'US',
  },
  {
    symbol: 'VRNA',
    name: 'Verona Pharma Plc - ADR',
    region: 'US',
  },
  {
    symbol: 'VRNIX',
    name: 'Vanguard Scottsdale Funds: Vanguard Russell 1000 Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VRNS',
    name: 'Varonis Systems Inc',
    region: 'US',
  },
  {
    symbol: 'VRNT',
    name: 'Verint Systems, Inc.',
    region: 'US',
  },
  {
    symbol: 'VRP',
    name: 'Invesco Capital Management LLC - Invesco Variable Rate Preferred ETF',
    region: 'US',
  },
  {
    symbol: 'VRPX',
    name: 'Virpax Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'VRRM',
    name: 'Verra Mobility Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VRSK',
    name: 'Verisk Analytics Inc',
    region: 'US',
  },
  {
    symbol: 'VRSN',
    name: 'Verisign Inc.',
    region: 'US',
  },
  {
    symbol: 'VRT',
    name: 'Vertiv Holdings Co - Class A',
    region: 'US',
  },
  {
    symbol: 'VRTIX',
    name: 'Vanguard Scottsdale Funds: Vanguard Russell 2000 Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VRTTX',
    name: 'Vanguard Scottsdale Funds: Vanguard Russell 3000 Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VRTV',
    name: 'Veritiv Corp',
    region: 'US',
  },
  {
    symbol: 'VRTVX',
    name: 'Vanguard Scottsdale Funds: Vanguard Russell 2000 Value Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VRTX',
    name: 'Vertex Pharmaceuticals, Inc.',
    region: 'US',
  },
  {
    symbol: 'VRVIX',
    name: 'Vanguard Scottsdale Funds: Vanguard Russell 1000 Value Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VS',
    name: 'Versus Systems Inc',
    region: 'US',
  },
  {
    symbol: 'VSAC',
    name: 'Vision Sensing Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'VSACU',
    name: 'Vision Sensing Acquisition Corp - Units (1 Ord Class A & 3/4 War)',
    region: 'US',
  },
  {
    symbol: 'VSAT',
    name: 'Viasat, Inc.',
    region: 'US',
  },
  {
    symbol: 'VSCIX',
    name: 'Vanguard Index Funds: Vanguard Small-Cap Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSCO',
    name: 'Victoria`s Secret & Co',
    region: 'US',
  },
  {
    symbol: 'VSCPX',
    name: 'Vanguard Index Funds: Vanguard Small-Cap Index Fund; Institutional Plus Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSDA',
    name: 'Victory Portfolios II - VictoryShares Dividend Accelerator ETF',
    region: 'US',
  },
  {
    symbol: 'VSEC',
    name: 'VSE Corp.',
    region: 'US',
  },
  {
    symbol: 'VSGX',
    name: 'Vanguard Group, Inc. - Vanguard ESG International Stock ETF',
    region: 'US',
  },
  {
    symbol: 'VSH',
    name: 'Vishay Intertechnology, Inc.',
    region: 'US',
  },
  {
    symbol: 'VSL',
    name: 'ETF Series Solutions Trust - Volshares Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'VSLU',
    name: 'ETF Opportunities Trust - Applied Finance Valuation Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'VSMAX',
    name: 'Vanguard Index Funds: Vanguard Small-Cap Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSMPX',
    name: 'Vanguard Index Funds: Vanguard Total Stock Market Index Fund; Institutional Plus Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSMSX',
    name: 'Vanguard Admiral Funds: Vanguard S&P Small-Cap 600 Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSMV',
    name: 'Victory Portfolios II - VictoryShares US Multi-Factor Minimum Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'VSMVX',
    name: 'Vanguard Admiral Funds: Vanguard S&P Small-Cap 600 Value Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSPMX',
    name: 'Vanguard Admiral Funds: Vanguard S&P Mid-Cap 400 Index Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSPY',
    name: 'Spinnaker ETF Trust - VectorShares Min Vol ETF',
    region: 'US',
  },
  {
    symbol: 'VSRDX',
    name: 'U.S. Socially Responsible Fund U.S. Socially Responsible Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSS',
    name: 'Vanguard Group, Inc. - Vanguard FTSE All-World Ex-US Small Capital Index Fund ETF',
    region: 'US',
  },
  {
    symbol: 'VST',
    name: 'Vistra Corp',
    region: 'US',
  },
  {
    symbol: 'VSTA',
    name: 'Vasta Platform Ltd Class A',
    region: 'US',
  },
  {
    symbol: 'VSTIX',
    name: 'Stock Index Fund Stock Index Fund',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VSTM',
    name: 'Verastem Inc',
    region: 'US',
  },
  {
    symbol: 'VSTO',
    name: 'Vista Outdoor Inc',
    region: 'US',
  },
  {
    symbol: 'VSTSX',
    name: 'Vanguard Index Funds: Vanguard Total Stock Market Index Fund; Institutional Select Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VT',
    name: 'Vanguard Group, Inc. - Vanguard Total World Stock ETF',
    region: 'US',
  },
  {
    symbol: 'VTAQ',
    name: 'Ventoux CCM Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'VTAQU',
    name: 'Ventoux CCM Acquisition Corp - Units (1 Ord, 1Right & 1 War)',
    region: 'US',
  },
  {
    symbol: 'VTC',
    name: 'Vanguard Group, Inc. - Vanguard Total Corporate Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VTEB',
    name: 'Vanguard Group, Inc. - Vanguard Tax-Exempt Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VTEX',
    name: 'Vtex - Class A',
    region: 'US',
  },
  {
    symbol: 'VTGN',
    name: 'VistaGen Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VTHR',
    name: 'Vanguard Group, Inc. - Vanguard Russell 3000 Index ETF',
    region: 'US',
  },
  {
    symbol: 'VTI',
    name: 'Vanguard Group, Inc. - Vanguard Total Stock Market ETF',
    region: 'US',
  },
  {
    symbol: 'VTIP',
    name: 'Vanguard Malvern Funds - Vanguard Short-Term Inflation-Protected Securities Index Fund',
    region: 'US',
  },
  {
    symbol: 'VTIQ',
    name: 'VectoIQ Acquisition Corp II - Class A',
    region: 'US',
  },
  {
    symbol: 'VTIQU',
    name: 'VectoIQ Acquisition Corp II - Units (1 Ord Share Class A & 1/5 War)',
    region: 'US',
  },
  {
    symbol: 'VTMSX',
    name: 'Vanguard Tax-Managed Funds: Vanguard Tax-Managed Small-Cap Fund;Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VTNR',
    name: 'Vertex Energy Inc',
    region: 'US',
  },
  {
    symbol: 'VTOL',
    name: 'Bristow Group Inc.',
    region: 'US',
  },
  {
    symbol: 'VTR',
    name: 'Ventas Inc',
    region: 'US',
  },
  {
    symbol: 'VTRS',
    name: 'Viatris Inc',
    region: 'US',
  },
  {
    symbol: 'VTRU',
    name: 'Vitru Ltd',
    region: 'US',
  },
  {
    symbol: 'VTSAX',
    name: 'Vanguard Index Funds: Vanguard Total Stock Market Index Fund; Admiral Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VTSI',
    name: 'VirTra Inc',
    region: 'US',
  },
  {
    symbol: 'VTSIX',
    name: 'Vanguard Tax-Managed Funds: Vanguard Tax-Managed Small-Cap Fund; Institutional Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VTSMX',
    name: 'Vanguard Index Funds: Vanguard Total Stock Market Index Fund; Investor Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VTTGF',
    name: 'VAT Group AG',
    region: 'US',
  },
  {
    symbol: 'VTV',
    name: 'Vanguard Group, Inc. - Vanguard Value ETF',
    region: 'US',
  },
  {
    symbol: 'VTVT',
    name: 'vTv Therapeutics Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VTWG',
    name: 'Vanguard Group, Inc. - Vanguard Russell 2000 Growth Index ETF',
    region: 'US',
  },
  {
    symbol: 'VTWO',
    name: 'Vanguard Group, Inc. - Vanguard Russell 2000 Index ETF',
    region: 'US',
  },
  {
    symbol: 'VTWV',
    name: 'Vanguard Group, Inc. - Vanguard Russell 2000 Value Index ETF',
    region: 'US',
  },
  {
    symbol: 'VTYX',
    name: 'Ventyx Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'VUG',
    name: 'Vanguard Group, Inc. - Vanguard Growth ETF',
    region: 'US',
  },
  {
    symbol: 'VUSB',
    name: 'Vanguard Group, Inc. - Vanguard Ultra-Short Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VUSE',
    name: 'ETF Series Solutions Trust - ESS Vident Core US Equity ETF',
    region: 'US',
  },
  {
    symbol: 'VUZI',
    name: 'Vuzix Corporation',
    region: 'US',
  },
  {
    symbol: 'VV',
    name: 'Vanguard Group, Inc. - Vanguard Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'VVI',
    name: 'Viad Corp.',
    region: 'US',
  },
  {
    symbol: 'VVIAX',
    name: 'Vanguard Index Funds: Vanguard Value Index Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VVNT',
    name: 'Vivint Smart Home Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VVOS',
    name: 'Vivos Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VVPR',
    name: 'VivoPower International PLC',
    region: 'US',
  },
  {
    symbol: 'VVV',
    name: 'Valvoline Inc',
    region: 'US',
  },
  {
    symbol: 'VWDRY',
    name: 'Vestas Wind Systems AS - ADR',
    region: 'US',
  },
  {
    symbol: 'VWE',
    name: 'Vintage Wine Estates Inc',
    region: 'US',
  },
  {
    symbol: 'VWID',
    name: 'ETFis Series Trust I - Virtus WMC International Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'VWO',
    name: 'Vanguard Group, Inc. - Vanguard FTSE Emerging Markets ETF',
    region: 'US',
  },
  {
    symbol: 'VWOB',
    name: 'Vanguard Group, Inc. - Vanguard Emerging Markets Government Bond ETF',
    region: 'US',
  },
  {
    symbol: 'VWSYF',
    name: 'Vestas Wind Systems AS',
    region: 'US',
  },
  {
    symbol: 'VWUAX',
    name: 'Vanguard World Funds: Vanguard US Growth Fund; Admiral Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VWUSX',
    name: 'Vanguard World Funds: Vanguard US Growth Fund; Investor Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'VXF',
    name: 'Vanguard Group, Inc. - Vanguard Extended Market Index ETF',
    region: 'US',
  },
  {
    symbol: 'VXRT',
    name: 'Vaxart Inc',
    region: 'US',
  },
  {
    symbol: 'VXUS',
    name: 'Vanguard Group, Inc. - Vanguard Total International Stock ETF',
    region: 'US',
  },
  {
    symbol: 'VYGG',
    name: 'Vy Global Growth - Class A',
    region: 'US',
  },
  {
    symbol: 'VYGR',
    name: 'Voyager Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VYM',
    name: 'Vanguard Group, Inc. - Vanguard High Dividend Yield Indx ETF',
    region: 'US',
  },
  {
    symbol: 'VYMI',
    name: 'Vanguard Group, Inc. - Vanguard International High Dividend Yield ETF',
    region: 'US',
  },
  {
    symbol: 'VYNE',
    name: 'VYNE Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'VZ',
    name: 'Verizon Communications Inc',
    region: 'US',
  },
  {
    symbol: 'VZIO',
    name: 'VIZIO Holdings Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'VZLA',
    name: 'Vizsla Silver Corp - New',
    region: 'US',
  },
  {
    symbol: 'W',
    name: 'Wayfair Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WAB',
    name: 'Westinghouse Air Brake Technologies Corp',
    region: 'US',
  },
  {
    symbol: 'WABC',
    name: 'Westamerica Bancorporation',
    region: 'US',
  },
  {
    symbol: 'WAFD',
    name: 'Washington Federal Inc.',
    region: 'US',
  },
  {
    symbol: 'WAFU',
    name: 'Wah Fu Education Group Ltd',
    region: 'US',
  },
  {
    symbol: 'WAL',
    name: 'Western Alliance Bancorp',
    region: 'US',
  },
  {
    symbol: 'WALD',
    name: 'Waldencast Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'WALDU',
    name: 'Waldencast Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'WANT',
    name: 'Direxion Shares ETF Trust - Direxion Daily Consumer Discretionary Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'WARR',
    name: 'Warrior Technologies Acquisition Co - Class A',
    region: 'US',
  },
  {
    symbol: 'WASH',
    name: 'Washington Trust Bancorp, Inc.',
    region: 'US',
  },
  {
    symbol: 'WAT',
    name: 'Waters Corp.',
    region: 'US',
  },
  {
    symbol: 'WATT',
    name: 'Energous Corp',
    region: 'US',
  },
  {
    symbol: 'WAVC',
    name: 'Waverley Capital Acquisition Corp 1 - Class A',
    region: 'US',
  },
  {
    symbol: 'WAVD',
    name: 'WaveDancer Inc',
    region: 'US',
  },
  {
    symbol: 'WAVE',
    name: 'Eco Wave Power Global AB - ADR',
    region: 'US',
  },
  {
    symbol: 'WAVS',
    name: 'Western Acquisition Ventures Corp',
    region: 'US',
  },
  {
    symbol: 'WAVSU',
    name: 'Western Acquisition Ventures Corp - Unit (1 Ordinary share & 1 Wrt)',
    region: 'US',
  },
  {
    symbol: 'WB',
    name: 'Weibo Corp - ADR',
    region: 'US',
  },
  {
    symbol: 'WBA',
    name: 'Walgreens Boots Alliance Inc',
    region: 'US',
  },
  {
    symbol: 'WBAT',
    name: 'WisdomTree Trust - WisdomTree Battery Value Chain and Innovation Fund',
    region: 'US',
  },
  {
    symbol: 'WBD',
    name: 'Warner Bros. Discovery Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WBEV',
    name: 'Winc Inc',
    region: 'US',
  },
  {
    symbol: 'WBIF',
    name: 'WBI Investments Inc - WBI BullBear Value 3000 ETF',
    region: 'US',
  },
  {
    symbol: 'WBIG',
    name: 'WBI Investments Inc - WBI BullBear Yield 3000 ETF',
    region: 'US',
  },
  {
    symbol: 'WBII',
    name: 'WBI Investments Inc - WBI BullBear Global Income ETF',
    region: 'US',
  },
  {
    symbol: 'WBIL',
    name: 'WBI Investments Inc - WBI BullBear Quality 3000 ETF',
    region: 'US',
  },
  {
    symbol: 'WBIT',
    name: 'Absolute Shares Trust - WBI BullBear Trend Switch US 3000 Total Return ETF',
    region: 'US',
  },
  {
    symbol: 'WBIY',
    name: 'WBI Investments Inc - WBI Power Factor High Dividend Shares',
    region: 'US',
  },
  {
    symbol: 'WBND',
    name: 'Legg Mason ETF Investment Trust - Western Asset Total Return ETF',
    region: 'US',
  },
  {
    symbol: 'WBS',
    name: 'Webster Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'WBT',
    name: 'Welbilt Inc',
    region: 'US',
  },
  {
    symbol: 'WBX',
    name: 'Wallbox N.V - Class A',
    region: 'US',
  },
  {
    symbol: 'WCBR',
    name: 'WisdomTree Trust - WisdomTree Cybersecurity Fund',
    region: 'US',
  },
  {
    symbol: 'WCC',
    name: 'Wesco International, Inc.',
    region: 'US',
  },
  {
    symbol: 'WCLD',
    name: 'WisdomTree Trust - WisdomTree Cloud Computing Fund',
    region: 'US',
  },
  {
    symbol: 'WCN',
    name: 'Waste Connections Inc',
    region: 'US',
  },
  {
    symbol: 'WDAY',
    name: 'Workday Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WDC',
    name: 'Western Digital Corp.',
    region: 'US',
  },
  {
    symbol: 'WDFC',
    name: 'WD-40 Co.',
    region: 'US',
  },
  {
    symbol: 'WDH',
    name: 'Waterdrop Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'WDIV',
    name: 'SSgA Active Trust - SPDR S&P Global Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'WDNA',
    name: 'WisdomTree Trust - WisdomTree BioRevolution Fund',
    region: 'US',
  },
  {
    symbol: 'WE',
    name: 'WeWork Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WEAT',
    name: 'Teucrium Trading, LLC - Teucrium Wheat Fund',
    region: 'US',
  },
  {
    symbol: 'WEAV',
    name: 'Weave Communications Inc',
    region: 'US',
  },
  {
    symbol: 'WEBL',
    name: 'Direxion Shares ETF Trust - Direxion Daily Dow Jones Internet Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'WEBR',
    name: 'Weber Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WEBS',
    name: 'Direxion Shares ETF Trust - Direxion Daily Dow Jones Internet Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'WEC',
    name: 'WEC Energy Group Inc',
    region: 'US',
  },
  {
    symbol: 'WEED',
    name: 'Listed Funds Trust - Roundhill Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'WEIX',
    name: 'Dynamic Shares Trust - Dynamic Short Short Term Volatility Futures ETF',
    region: 'US',
  },
  {
    symbol: 'WEJO',
    name: 'Wejo Group Ltd',
    region: 'US',
  },
  {
    symbol: 'WEN',
    name: 'Wendy`s Co - Class A',
    region: 'US',
  },
  {
    symbol: 'WENCX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Enterprise Fund; Class C Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WENRX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Enterprise Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WERN',
    name: 'Werner Enterprises, Inc.',
    region: 'US',
  },
  {
    symbol: 'WES',
    name: 'Western Midstream Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'WETF',
    name: 'Wisdomtree Investments Inc',
    region: 'US',
  },
  {
    symbol: 'WEX',
    name: 'WEX Inc',
    region: 'US',
  },
  {
    symbol: 'WEYS',
    name: 'Weyco Group, Inc',
    region: 'US',
  },
  {
    symbol: 'WF',
    name: 'Woori Financial Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'WFC',
    name: 'Wells Fargo & Co.',
    region: 'US',
  },
  {
    symbol: 'WFCF',
    name: 'Where Food Comes From Inc',
    region: 'US',
  },
  {
    symbol: 'WFDAX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Discovery Fund; Class A Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WFDDX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Discovery Fund; Administrator Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WFDRX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Discovery Fund; Class R6 Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WFDSX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Discovery Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WFEIX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Enterprise Fund; Institutional Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WFG',
    name: 'West Fraser Timber Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'WFH',
    name: 'Direxion Funds - Direxion Work From Home ETF',
    region: 'US',
  },
  {
    symbol: 'WFHY',
    name: 'WisdomTree Trust - WisdomTree U.S. High Yield Corporate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'WFIG',
    name: 'WisdomTree Trust - WisdomTree U.S. Corporate Bond Fund',
    region: 'US',
  },
  {
    symbol: 'WFIOX',
    name: 'Wells Fargo Funds Trust: Wells Fargo Index Fund; Administrator Class Shares',
    region: 'US',
    assetType: 'mutual fund',
  },
  {
    symbol: 'WFRD',
    name: 'Weatherford International plc - New',
    region: 'US',
  },
  {
    symbol: 'WGLD',
    name: 'Wilshire wShares Enhanced Gold Trust - Wilshire wShares Enhanced Gold ETF',
    region: 'US',
  },
  {
    symbol: 'WGMI',
    name: 'Valkyrie ETF Trust II - Valkyrie Bitcoin Miners ETF',
    region: 'US',
  },
  {
    symbol: 'WGO',
    name: 'Winnebago Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'WGRO',
    name: 'WisdomTree Trust - WisdomTree U.S. Growth & Momentum Fund',
    region: 'US',
  },
  {
    symbol: 'WH',
    name: 'Wyndham Hotels & Resorts Inc',
    region: 'US',
  },
  {
    symbol: 'WHD',
    name: 'Cactus Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WHG',
    name: 'Westwood Holdings Group Inc',
    region: 'US',
  },
  {
    symbol: 'WHLM',
    name: 'Wilhelmina International Inc',
    region: 'US',
  },
  {
    symbol: 'WHR',
    name: 'Whirlpool Corp.',
    region: 'US',
  },
  {
    symbol: 'WILC',
    name: 'G. Willi-Food International Ltd',
    region: 'US',
  },
  {
    symbol: 'WILLF',
    name: 'Demant AS',
    region: 'US',
  },
  {
    symbol: 'WILYY',
    name: 'Demant AS - ADR',
    region: 'US',
  },
  {
    symbol: 'WIMI',
    name: 'Wimi Hologram Cloud Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'WINA',
    name: 'Winmark Corporation',
    region: 'US',
  },
  {
    symbol: 'WINC',
    name: 'Legg Mason ETF Investment Trust - Western Asset Short Duration Income ETF',
    region: 'US',
  },
  {
    symbol: 'WING',
    name: 'Wingstop Inc',
    region: 'US',
  },
  {
    symbol: 'WINN',
    name: 'Harbor ETF Trust - Harbor Long-Term Growers ETF',
    region: 'US',
  },
  {
    symbol: 'WINT',
    name: 'Windtree Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'WINV',
    name: 'WinVest Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'WINVU',
    name: 'WinVest Acquisition Corp - Units (1 Ordinary Shares, 1 Rights & 1 War)',
    region: 'US',
  },
  {
    symbol: 'WIP',
    name: 'SPDR Series Trust - SPDR Citi International Government Inflation-Protected Bond ETF',
    region: 'US',
  },
  {
    symbol: 'WIRE',
    name: 'Encore Wire Corp.',
    region: 'US',
  },
  {
    symbol: 'WISA',
    name: 'WiSA Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'WISH',
    name: 'ContextLogic Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WIT',
    name: 'Wipro Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'WIX',
    name: 'Wix.com Ltd',
    region: 'US',
  },
  {
    symbol: 'WIZ',
    name: 'Alpha Architect Etf Trust - Alpha Architect Merlyn.AI Bull-Rider Bear-Fighter ETF',
    region: 'US',
  },
  {
    symbol: 'WJRYF',
    name: 'West Japan Railway Company',
    region: 'US',
  },
  {
    symbol: 'WJRYY',
    name: 'West Japan Railway Company - ADR',
    region: 'US',
  },
  {
    symbol: 'WK',
    name: 'Workiva Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WKEY',
    name: 'WISeKey International Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'WKHS',
    name: 'Workhorse Group Inc',
    region: 'US',
  },
  {
    symbol: 'WKLY',
    name: 'Tidal ETF Trust - SoFi Weekly Dividend ETF',
    region: 'US',
  },
  {
    symbol: 'WKME',
    name: 'WalkMe Ltd',
    region: 'US',
  },
  {
    symbol: 'WKSP',
    name: 'Worksport Ltd',
    region: 'US',
  },
  {
    symbol: 'WLDN',
    name: 'Willdan Group Inc',
    region: 'US',
  },
  {
    symbol: 'WLDR',
    name: 'Two Roads Shared Trust - Affinity World Leaders Equity ETF',
    region: 'US',
  },
  {
    symbol: 'WLFC',
    name: 'Willis Lease Finance Corp.',
    region: 'US',
  },
  {
    symbol: 'WLK',
    name: 'Westlake Corporation',
    region: 'US',
  },
  {
    symbol: 'WLKP',
    name: 'Westlake Chemical Partners LP - Unit',
    region: 'US',
  },
  {
    symbol: 'WLL',
    name: 'Whiting Petroleum Corp (New)',
    region: 'US',
  },
  {
    symbol: 'WLMS',
    name: 'Williams Industrial Services Group Inc',
    region: 'US',
  },
  {
    symbol: 'WLTG',
    name: 'ETF Opportunities Trust - WealthTrust DBS Long Term Growth ETF',
    region: 'US',
  },
  {
    symbol: 'WLTH',
    name: 'Northern Lights Fund Trust II - LifeGoal Wealth Builder ETF',
    region: 'US',
  },
  {
    symbol: 'WLY',
    name: 'John Wiley & Sons Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'WLYB',
    name: 'John Wiley & Sons Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'WM',
    name: 'Waste Management, Inc.',
    region: 'US',
  },
  {
    symbol: 'WMB',
    name: 'Williams Cos Inc',
    region: 'US',
  },
  {
    symbol: 'WMG',
    name: 'Warner Music Group Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'WMK',
    name: 'Weis Markets, Inc.',
    region: 'US',
  },
  {
    symbol: 'WMMVY',
    name: 'Wal-mart de Mexico S A B de C V - ADR',
    region: 'US',
  },
  {
    symbol: 'WMPN',
    name: 'William Penn Bancorporation',
    region: 'US',
  },
  {
    symbol: 'WMS',
    name: 'Advanced Drainage Systems Inc',
    region: 'US',
  },
  {
    symbol: 'WMT',
    name: 'Walmart Inc',
    region: 'US',
  },
  {
    symbol: 'WNC',
    name: 'Wabash National Corp.',
    region: 'US',
  },
  {
    symbol: 'WNDY',
    name: 'Global X Funds - Global X Wind Energy ETF',
    region: 'US',
  },
  {
    symbol: 'WNEB',
    name: 'Western New England Bancorp Inc',
    region: 'US',
  },
  {
    symbol: 'WNNR',
    name: 'Andretti Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'WNS',
    name: 'WNS Holdings Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'WNW',
    name: 'Meiwu Technology Company Ltd',
    region: 'US',
  },
  {
    symbol: 'WOLF',
    name: 'Wolfspeed Inc',
    region: 'US',
  },
  {
    symbol: 'WOMN',
    name: 'Impact Shares Trust I - Impact Shares YWCA Womens Empowerment ETF',
    region: 'US',
  },
  {
    symbol: 'WOOD',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Global Timber & Forestry ETF',
    region: 'US',
  },
  {
    symbol: 'WOOF',
    name: 'Petco Health and Wellness Co Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WOR',
    name: 'Worthington Industries, Inc.',
    region: 'US',
  },
  {
    symbol: 'WORX',
    name: 'SCWorx Corp',
    region: 'US',
  },
  {
    symbol: 'WOW',
    name: 'WideOpenWest Inc',
    region: 'US',
  },
  {
    symbol: 'WPCA',
    name: 'Warburg Pincus Capital Corp I A - Class A',
    region: 'US',
  },
  {
    symbol: 'WPCB',
    name: 'Warburg Pincus Capital Corp I B - Class A',
    region: 'US',
  },
  {
    symbol: 'WPM',
    name: 'Wheaton Precious Metals Corp',
    region: 'US',
  },
  {
    symbol: 'WPP',
    name: 'WPP Plc. - ADR',
    region: 'US',
  },
  {
    symbol: 'WPRT',
    name: 'Westport Fuel Systems Inc',
    region: 'US',
  },
  {
    symbol: 'WPS',
    name: 'BlackRock Institutional Trust Company N.A. - iShares International Developed Property ETF',
    region: 'US',
  },
  {
    symbol: 'WQGA',
    name: 'World Quantum Growth Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'WRAC',
    name: 'Williams Rowland Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'WRAP',
    name: 'Wrap Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'WRB',
    name: 'W.R. Berkley Corp.',
    region: 'US',
  },
  {
    symbol: 'WRBY',
    name: 'Warby Parker Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WRDLY',
    name: 'Worldline - ADR',
    region: 'US',
  },
  {
    symbol: 'WRK',
    name: 'WestRock Co',
    region: 'US',
  },
  {
    symbol: 'WRN',
    name: 'Western Copper & Gold Corp',
    region: 'US',
  },
  {
    symbol: 'WRND',
    name: 'IndexIQ ETF Trust - IQ Global Equity R&D Leaders ETF',
    region: 'US',
  },
  {
    symbol: 'WRTBF',
    name: 'Wartsila Oyj Abp',
    region: 'US',
  },
  {
    symbol: 'WRTBY',
    name: 'Wartsila Oyj Abp - ADR',
    region: 'US',
  },
  {
    symbol: 'WSBC',
    name: 'Wesbanco, Inc.',
    region: 'US',
  },
  {
    symbol: 'WSBF',
    name: 'Waterstone Financial Inc',
    region: 'US',
  },
  {
    symbol: 'WSC',
    name: 'WillScot Mobile Mini Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'WSFS',
    name: 'WSFS Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'WSM',
    name: 'Williams-Sonoma, Inc.',
    region: 'US',
  },
  {
    symbol: 'WSO',
    name: 'Watsco Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'WSO.B',
    name: 'Watsco Inc. - Class B',
    region: 'US',
  },
  {
    symbol: 'WST',
    name: 'West Pharmaceutical Services, Inc.',
    region: 'US',
  },
  {
    symbol: 'WSTG',
    name: 'Wayside Technology Group Inc',
    region: 'US',
  },
  {
    symbol: 'WTAI',
    name: 'WisdomTree Trust - WisdomTree Artificial Intelligence and Innovation Fund',
    region: 'US',
  },
  {
    symbol: 'WTBA',
    name: 'West Bancorporation',
    region: 'US',
  },
  {
    symbol: 'WTER',
    name: 'Alkaline Water Company Inc (The)',
    region: 'US',
  },
  {
    symbol: 'WTFC',
    name: 'Wintrust Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'WTI',
    name: 'W & T Offshore Inc',
    region: 'US',
  },
  {
    symbol: 'WTKWY',
    name: 'Wolters Kluwers NV - ADR',
    region: 'US',
  },
  {
    symbol: 'WTM',
    name: 'White Mountains Insurance Group, Ltd.',
    region: 'US',
  },
  {
    symbol: 'WTMA',
    name: 'Welsbach Technology Metals Acquisition Corp',
    region: 'US',
  },
  {
    symbol: 'WTMAU',
    name: 'Welsbach Technology Metals Acquisition Corp - Units (1 Ord & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'WTMF',
    name: 'WisdomTree Trust - WisdomTree Managed Futures Strategy Fund',
    region: 'US',
  },
  {
    symbol: 'WTRE',
    name: 'WisdomTree Trust - WisdomTree New Economy Real Estate Fund',
    region: 'US',
  },
  {
    symbol: 'WTRG',
    name: 'Essential Utilities Inc',
    region: 'US',
  },
  {
    symbol: 'WTRH',
    name: 'Waitr Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'WTRU',
    name: 'Essential Utilities Inc - Unit',
    region: 'US',
  },
  {
    symbol: 'WTS',
    name: 'Watts Water Technologies, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'WTT',
    name: 'Wireless Telecom Group, Inc.',
    region: 'US',
  },
  {
    symbol: 'WTTR',
    name: 'Select Energy Services Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'WTV',
    name: 'WisdomTree Trust - WisdomTree U.S. Value Fund',
    region: 'US',
  },
  {
    symbol: 'WTW',
    name: 'Willis Towers Watson Public Limited Co',
    region: 'US',
  },
  {
    symbol: 'WU',
    name: 'Western Union Company',
    region: 'US',
  },
  {
    symbol: 'WUGI',
    name: 'Esoterica Thematic Trust - Esoterica NextG Economy ETF',
    region: 'US',
  },
  {
    symbol: 'WULF',
    name: 'TeraWulf Inc',
    region: 'US',
  },
  {
    symbol: 'WVE',
    name: 'Wave Life Sciences Ltd.',
    region: 'US',
  },
  {
    symbol: 'WVFC',
    name: 'WVS Financial Corp.',
    region: 'US',
  },
  {
    symbol: 'WVVI',
    name: 'Willamette Valley Vineyard Inc.',
    region: 'US',
  },
  {
    symbol: 'WW',
    name: 'WW International Inc',
    region: 'US',
  },
  {
    symbol: 'WWAC',
    name: 'Worldwide Webb Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'WWACU',
    name: 'Worldwide Webb Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'WWD',
    name: 'Woodward Inc',
    region: 'US',
  },
  {
    symbol: 'WWE',
    name: 'World Wrestling Entertainment, Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'WWJD',
    name: 'Northern Lights Fund Trust IV - Inspire International ESG ETF',
    region: 'US',
  },
  {
    symbol: 'WWLNF',
    name: 'Worldline',
    region: 'US',
  },
  {
    symbol: 'WWOW',
    name: 'Direxion Shares ETF Trust - Direxion World Without Waste ETF',
    region: 'US',
  },
  {
    symbol: 'WWR',
    name: 'Westwater Resources Inc',
    region: 'US',
  },
  {
    symbol: 'WWW',
    name: 'Wolverine World Wide, Inc.',
    region: 'US',
  },
  {
    symbol: 'WY',
    name: 'Weyerhaeuser Co.',
    region: 'US',
  },
  {
    symbol: 'WYNN',
    name: 'Wynn Resorts Ltd.',
    region: 'US',
  },
  {
    symbol: 'WYY',
    name: 'Widepoint Corp',
    region: 'US',
  },
  {
    symbol: 'X',
    name: 'United States Steel Corp.',
    region: 'US',
  },
  {
    symbol: 'XAIR',
    name: 'Beyond Air Inc',
    region: 'US',
  },
  {
    symbol: 'XAR',
    name: 'SPDR Series Trust - SPDR S&P Aerospace & Defense ETF',
    region: 'US',
  },
  {
    symbol: 'XBAP',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated 9 Buffer ETF - April',
    region: 'US',
  },
  {
    symbol: 'XBI',
    name: 'SPDR Series Trust - SPDR Biotech ETF',
    region: 'US',
  },
  {
    symbol: 'XBIO',
    name: 'Xenetic Biosciences Inc',
    region: 'US',
  },
  {
    symbol: 'XBIT',
    name: 'XBiotech Inc',
    region: 'US',
  },
  {
    symbol: 'XBJA',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated 9 Buffer ETF - January',
    region: 'US',
  },
  {
    symbol: 'XBJL',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated 9 Buffer ETF - July',
    region: 'US',
  },
  {
    symbol: 'XBOC',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated 9 Buffer ETF - October',
    region: 'US',
  },
  {
    symbol: 'XBTF',
    name: 'VanEck ETF Trust - VanEck Bitcoin Strategy ETF',
    region: 'US',
  },
  {
    symbol: 'XCEM',
    name: 'Columbia ETF Trust II - Columbia EM Core ex-China ETF',
    region: 'US',
  },
  {
    symbol: 'XCLR',
    name: 'Global X Funds - Global X S&P 500 Collar 95-110 ETF',
    region: 'US',
  },
  {
    symbol: 'XCUR',
    name: 'Exicure Inc',
    region: 'US',
  },
  {
    symbol: 'XDAP',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated ETF - April',
    region: 'US',
  },
  {
    symbol: 'XDAT',
    name: 'Franklin Templeton ETF Trust - Franklin Exponential Data ETF',
    region: 'US',
  },
  {
    symbol: 'XDEC',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'XDJA',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated ETF - January',
    region: 'US',
  },
  {
    symbol: 'XDJL',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated ETF - July',
    region: 'US',
  },
  {
    symbol: 'XDNA',
    name: 'Kelly Strategic ETF Trust - Kelly CRISPR & Gene Editing Technology ETF',
    region: 'US',
  },
  {
    symbol: 'XDOC',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated ETF - October',
    region: 'US',
  },
  {
    symbol: 'XDQQ',
    name: 'Innovator ETFs Trust - Innovator Growth Accelerated ETF - Quarterly',
    region: 'US',
  },
  {
    symbol: 'XDSQ',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated ETF - Quarterly',
    region: 'US',
  },
  {
    symbol: 'XEL',
    name: 'Xcel Energy, Inc.',
    region: 'US',
  },
  {
    symbol: 'XELA',
    name: 'Exela Technologies Inc',
    region: 'US',
  },
  {
    symbol: 'XELB',
    name: 'Xcel Brands Inc',
    region: 'US',
  },
  {
    symbol: 'XENE',
    name: 'Xenon Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'XENT',
    name: 'Intersect ENT Inc',
    region: 'US',
  },
  {
    symbol: 'XES',
    name: 'SPDR Series Trust - SPDR Oil & Gas Equipment and Services ETF',
    region: 'US',
  },
  {
    symbol: 'XFIN',
    name: 'ExcelFin Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'XFINU',
    name: 'ExcelFin Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'XFOR',
    name: 'X4 Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'XGN',
    name: 'Exagen Inc',
    region: 'US',
  },
  {
    symbol: 'XHB',
    name: 'SSgA Active Trust - SPDR Homebuilders ETF',
    region: 'US',
  },
  {
    symbol: 'XHE',
    name: 'SPDR Series Trust - SPDR S&P Health Care Equipment ETF',
    region: 'US',
  },
  {
    symbol: 'XHS',
    name: 'SPDR Series Trust - SPDR S&P Health Care Services ETF',
    region: 'US',
  },
  {
    symbol: 'XHYC',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Consumer Cyclicals Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XHYD',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Consumer Non-Cyclicals Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XHYE',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Energy Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XHYF',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Financial & REIT Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XHYH',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Healthcare Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XHYI',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Industrial Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XHYT',
    name: 'BondBloxx ETF Trust - BondBloxx USD High Yield Bond Telecom, Media & Technology Sector ETF',
    region: 'US',
  },
  {
    symbol: 'XITK',
    name: 'SPDR Series Trust - SPDR FactSet Innovative Technology ETF',
    region: 'US',
  },
  {
    symbol: 'XJH',
    name: 'iShares Trust - iShares ESG Screened S&P Mid-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'XJR',
    name: 'iShares Trust - iShares ESG Screened S&P Small-Cap ETF',
    region: 'US',
  },
  {
    symbol: 'XJUN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest U.S. Equity Enhance & Moderate Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'XL',
    name: 'XL Fleet Corporation - Class A',
    region: 'US',
  },
  {
    symbol: 'XLB',
    name: 'SSgA Active Trust - Materials Select Sector SPDR',
    region: 'US',
  },
  {
    symbol: 'XLC',
    name: 'SSgA Active Trust - Communication Services Select Sector SPDR Fund',
    region: 'US',
  },
  {
    symbol: 'XLE',
    name: 'SSgA Active Trust - The Energy Select Sector SPDR Fund',
    region: 'US',
  },
  {
    symbol: 'XLF',
    name: 'SSgA Active Trust - Financial Select Sector SPDR',
    region: 'US',
  },
  {
    symbol: 'XLG',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 Top 50 ETF',
    region: 'US',
  },
  {
    symbol: 'XLI',
    name: 'SSgA Active Trust - Industrial Select Sector SPDR',
    region: 'US',
  },
  {
    symbol: 'XLK',
    name: 'SSgA Active Trust - Technology Select Sector SPDR ETF',
    region: 'US',
  },
  {
    symbol: 'XLO',
    name: 'Xilio Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'XLP',
    name: 'SSgA Active Trust - Consumer Staples Select Sector SPDR',
    region: 'US',
  },
  {
    symbol: 'XLRE',
    name: 'SSgA Active Trust - Real Estate Select Sector SPDR Fund (The)',
    region: 'US',
  },
  {
    symbol: 'XLSR',
    name: 'SSgA Active Trust - SPDR SSGA U.S. Sector Rotation ETF',
    region: 'US',
  },
  {
    symbol: 'XLU',
    name: 'SSgA Active Trust - Utilities Select Sector SPDR ETF',
    region: 'US',
  },
  {
    symbol: 'XLV',
    name: 'SSgA Active Trust - Health Care Select Sector SPDR',
    region: 'US',
  },
  {
    symbol: 'XLY',
    name: 'SSgA Active Trust - Consumer Discretionary Select Sector SPDR',
    region: 'US',
  },
  {
    symbol: 'XM',
    name: 'Qualtrics International Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'XME',
    name: 'SPDR Series Trust - SPDR Metals & Mining ETF',
    region: 'US',
  },
  {
    symbol: 'XMHQ',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap Quality ETF',
    region: 'US',
  },
  {
    symbol: 'XMLV',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'XMMO',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'XMPT',
    name: 'VanEck ETF Trust - VanEck CEF Muni Income ETF',
    region: 'US',
  },
  {
    symbol: 'XMTR',
    name: 'Xometry Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'XMVM',
    name: 'Invesco Capital Management LLC - Invesco S&P MidCap Value with Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'XNCR',
    name: 'Xencor Inc',
    region: 'US',
  },
  {
    symbol: 'XNET',
    name: 'Xunlei Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'XNTK',
    name: 'SPDR Series Trust - SPDR NYSE Technology ETF',
    region: 'US',
  },
  {
    symbol: 'XOM',
    name: 'Exxon Mobil Corp.',
    region: 'US',
  },
  {
    symbol: 'XOMA',
    name: 'XOMA Corp',
    region: 'US',
  },
  {
    symbol: 'XOP',
    name: 'SPDR Series Trust - SPDR Oil & Gas Exploration and Production ETF',
    region: 'US',
  },
  {
    symbol: 'XOS',
    name: 'Xos Inc',
    region: 'US',
  },
  {
    symbol: 'XOUT',
    name: 'GraniteShares ETF Trust - GraniteShares XOUT U.S. Large Cap ETF',
    region: 'US',
  },
  {
    symbol: 'XPAX',
    name: 'XPAC Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'XPAXU',
    name: 'XPAC Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'XPEL',
    name: 'XPEL Inc - Reg S',
    region: 'US',
  },
  {
    symbol: 'XPER',
    name: 'Xperi Holding Corp',
    region: 'US',
  },
  {
    symbol: 'XPEV',
    name: 'XPeng Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'XPH',
    name: 'SPDR Series Trust - SPDR Pharmaceuticals ETF',
    region: 'US',
  },
  {
    symbol: 'XPL',
    name: 'Solitario Zinc Corp',
    region: 'US',
  },
  {
    symbol: 'XPND',
    name: 'First Trust Exchange-Traded Fund VIII - First Trust Expanded Technology ETF',
    region: 'US',
  },
  {
    symbol: 'XPO',
    name: 'XPO Logistics Inc',
    region: 'US',
  },
  {
    symbol: 'XPOA',
    name: 'DPCM Capital Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'XPOF',
    name: 'Xponential Fitness Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'XPON',
    name: 'Expion360 Inc',
    region: 'US',
  },
  {
    symbol: 'XPP',
    name: 'ProShares Trust - ProShares Ultra FTSE China 50',
    region: 'US',
  },
  {
    symbol: 'XPRO',
    name: 'Expro Group Holdings N.V.',
    region: 'US',
  },
  {
    symbol: 'XRAY',
    name: 'DENTSPLY Sirona Inc',
    region: 'US',
  },
  {
    symbol: 'XRLV',
    name: 'Invesco Capital Management LLC - Invesco S&P 500 ex-Rate Sensitive Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'XRMI',
    name: 'Global X Funds - Global X S&P 500 Risk Managed Income ETF',
    region: 'US',
  },
  {
    symbol: 'XRT',
    name: 'SPDR Series Trust - SPDR Retail ETF',
    region: 'US',
  },
  {
    symbol: 'XRTX',
    name: 'XORTX Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'XRX',
    name: 'Xerox Holdings Corp',
    region: 'US',
  },
  {
    symbol: 'XSD',
    name: 'SPDR Series Trust - SPDR Semiconductors ETF',
    region: 'US',
  },
  {
    symbol: 'XSHD',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap High Dividend Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'XSHQ',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Quality ETF',
    region: 'US',
  },
  {
    symbol: 'XSLV',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Low Volatility ETF',
    region: 'US',
  },
  {
    symbol: 'XSMO',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'XSOE',
    name: 'WisdomTree Trust - WisdomTree Emerging Markets ex-State-Owned Enterprises Fund',
    region: 'US',
  },
  {
    symbol: 'XSPA',
    name: 'XpresSpa Group Inc',
    region: 'US',
  },
  {
    symbol: 'XSVM',
    name: 'Invesco Capital Management LLC - Invesco S&P SmallCap Value with Momentum ETF',
    region: 'US',
  },
  {
    symbol: 'XSW',
    name: 'SPDR Series Trust - SPDR S&P Software & Services ETF',
    region: 'US',
  },
  {
    symbol: 'XT',
    name: 'BlackRock Institutional Trust Company N.A. - iShares Exponential Technologies ETF',
    region: 'US',
  },
  {
    symbol: 'XTAP',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated Plus ETF - April',
    region: 'US',
  },
  {
    symbol: 'XTJA',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated Plus ETF - January',
    region: 'US',
  },
  {
    symbol: 'XTJL',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated Plus ETF - July',
    region: 'US',
  },
  {
    symbol: 'XTL',
    name: 'SPDR Series Trust - SPDR S&P Telecom ETF',
    region: 'US',
  },
  {
    symbol: 'XTLB',
    name: 'X.T.L. Biopharmaceuticals Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'XTN',
    name: 'SPDR Series Trust - SPDR S&P Transportation ETF',
    region: 'US',
  },
  {
    symbol: 'XTNT',
    name: 'Xtant Medical Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'XTOC',
    name: 'Innovator ETFs Trust - Innovator U.S. Equity Accelerated Plus ETF - October',
    region: 'US',
  },
  {
    symbol: 'XTR',
    name: 'Global X Funds - Global X S&P 500 Tail Risk ETF',
    region: 'US',
  },
  {
    symbol: 'XVOL',
    name: 'Tidal ETF Trust - Acruence Active Hedge U.S. Equity ETF',
    region: 'US',
  },
  {
    symbol: 'XVV',
    name: 'iShares Trust - iShares ESG Screened S&P 500 ETF',
    region: 'US',
  },
  {
    symbol: 'XWEB',
    name: 'SPDR Series Trust - SPDR S&P Internet ETF',
    region: 'US',
  },
  {
    symbol: 'XXII',
    name: '22nd Century Group Inc',
    region: 'US',
  },
  {
    symbol: 'XYL',
    name: 'Xylem Inc',
    region: 'US',
  },
  {
    symbol: 'XYLD',
    name: 'Global X Funds - Global X S&P 500 Covered Call ETF',
    region: 'US',
  },
  {
    symbol: 'XYLG',
    name: 'Global X Funds - Global X S&P 500 Covered Call & Growth ETF',
    region: 'US',
  },
  {
    symbol: 'Y',
    name: 'Alleghany Corp.',
    region: 'US',
  },
  {
    symbol: 'YAGOY',
    name: 'Yageo Corporation - GDR - Reg S',
    region: 'US',
  },
  {
    symbol: 'YAGZZ',
    name: 'Yageo Corporation - GDR - 144A',
    region: 'US',
  },
  {
    symbol: 'YAHOF',
    name: 'Z Holdings Corporation',
    region: 'US',
  },
  {
    symbol: 'YAHOY',
    name: 'Z Holdings Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'YALA',
    name: 'Yalla Group Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'YAMCF',
    name: 'Yamaha Corporation',
    region: 'US',
  },
  {
    symbol: 'YAMCY',
    name: 'Yamaha Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'YAMHF',
    name: 'Yamaha Motor Co Ltd',
    region: 'US',
  },
  {
    symbol: 'YAMHY',
    name: 'Yamaha Motor Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'YANG',
    name: 'Direxion Shares ETF Trust - Direxion Daily FTSE China Bear 3X Shares',
    region: 'US',
  },
  {
    symbol: 'YASKF',
    name: 'Yaskawa Electric Corporation',
    region: 'US',
  },
  {
    symbol: 'YASKY',
    name: 'Yaskawa Electric Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'YATRF',
    name: 'Yamato Holdings Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'YATRY',
    name: 'Yamato Holdings Co., Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'YCBD',
    name: 'cbdMD Inc',
    region: 'US',
  },
  {
    symbol: 'YCHYF',
    name: 'Yachiyo Industry Co., Ltd.',
    region: 'US',
  },
  {
    symbol: 'YCL',
    name: 'ProShares Trust - ProShares Ultra Yen',
    region: 'US',
  },
  {
    symbol: 'YCS',
    name: 'ProShares Trust - ProShares UltraShort Yen',
    region: 'US',
  },
  {
    symbol: 'YDEC',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest International Equity Buffer ETF - December',
    region: 'US',
  },
  {
    symbol: 'YELL',
    name: 'Yellow Corporation',
    region: 'US',
  },
  {
    symbol: 'YELP',
    name: 'Yelp Inc',
    region: 'US',
  },
  {
    symbol: 'YETI',
    name: 'YETI Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'YEXT',
    name: 'Yext Inc',
    region: 'US',
  },
  {
    symbol: 'YGMZ',
    name: 'MingZhu Logistics Holdings Ltd',
    region: 'US',
  },
  {
    symbol: 'YI',
    name: '111 Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'YINN',
    name: 'Direxion Shares ETF Trust - Direxion Daily FTSE China Bull 3X Shares',
    region: 'US',
  },
  {
    symbol: 'YJ',
    name: 'Yunji Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'YJUN',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest International Equity Buffer ETF - June',
    region: 'US',
  },
  {
    symbol: 'YKLTF',
    name: 'Yakult Honsha Co Ltd',
    region: 'US',
  },
  {
    symbol: 'YKLTY',
    name: 'Yakult Honsha Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'YLD',
    name: 'Principal Exchange-Traded Funds - Principal Active High Yield ETF',
    region: 'US',
  },
  {
    symbol: 'YLDE',
    name: 'Legg Mason ETF Investment Trust - ClearBridge Dividend Strategy ESG ETF',
    region: 'US',
  },
  {
    symbol: 'YMAB',
    name: 'Y-Mabs Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'YMAR',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest International Equity Buffer ETF - March',
    region: 'US',
  },
  {
    symbol: 'YMM',
    name: 'Full Truck Alliance Co Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'YMTX',
    name: 'Yumanity Therapeutics Inc',
    region: 'US',
  },
  {
    symbol: 'YOLO',
    name: 'Advisorshares Trust - AdvisorShares Pure Cannabis ETF',
    region: 'US',
  },
  {
    symbol: 'YORW',
    name: 'York Water Co.',
    region: 'US',
  },
  {
    symbol: 'YOTAU',
    name: 'Yotta Acquisition Corp - Units (1 Ord, 1 War & 1 Right)',
    region: 'US',
  },
  {
    symbol: 'YOU',
    name: 'Clear Secure Inc Class A',
    region: 'US',
  },
  {
    symbol: 'YPF',
    name: 'YPF - ADR',
    region: 'US',
  },
  {
    symbol: 'YPS',
    name: 'Arrow Investments Trust - Arrow Reverse Cap 500 ETF',
    region: 'US',
  },
  {
    symbol: 'YQ',
    name: '17 Education & Technology Group Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'YSEP',
    name: 'First Trust Exchange-Traded Fund VIII - FT Cboe Vest International Equity Buffer ETF - September',
    region: 'US',
  },
  {
    symbol: 'YSG',
    name: 'Yatsen Holding Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'YTEN',
    name: 'Yield10 Bioscience Inc',
    region: 'US',
  },
  {
    symbol: 'YTPG',
    name: 'TPG Pace Beneficial II Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'YTRA',
    name: 'Yatra Online Inc',
    region: 'US',
  },
  {
    symbol: 'YUM',
    name: 'Yum Brands Inc.',
    region: 'US',
  },
  {
    symbol: 'YUMC',
    name: 'Yum China Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'YUMY',
    name: 'VanEck ETF Trust - VanEck Future of Food ETF',
    region: 'US',
  },
  {
    symbol: 'YVR',
    name: 'Liquid Media Group Ltd',
    region: 'US',
  },
  {
    symbol: 'YXI',
    name: 'ProShares Trust - ProShares Short FTSE China 50',
    region: 'US',
  },
  {
    symbol: 'YY',
    name: 'JOYY Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'YYY',
    name: 'Amplify ETF Trust - Amplify High Income ETF',
    region: 'US',
  },
  {
    symbol: 'YZCHF',
    name: 'Yankuang Energy Group Company Limited - Class H',
    region: 'US',
  },
  {
    symbol: 'Z',
    name: 'Zillow Group Inc - Class C',
    region: 'US',
  },
  {
    symbol: 'ZBH',
    name: 'Zimmer Biomet Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ZBRA',
    name: 'Zebra Technologies Corp. - Class A',
    region: 'US',
  },
  {
    symbol: 'ZCMD',
    name: 'Zhongchao Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZD',
    name: 'Ziff Davis Inc',
    region: 'US',
  },
  {
    symbol: 'ZDGE',
    name: 'Zedge Inc - Class B',
    region: 'US',
  },
  {
    symbol: 'ZEAL',
    name: 'Zealand Pharma A/S. - ADR',
    region: 'US',
  },
  {
    symbol: 'ZECP',
    name: 'Zacks Trust - Zacks Earnings Consistent Portfolio ETF',
    region: 'US',
  },
  {
    symbol: 'ZEN',
    name: 'Zendesk Inc',
    region: 'US',
  },
  {
    symbol: 'ZENV',
    name: 'Zenvia Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZEPP',
    name: 'Zepp Health Corporation - ADR',
    region: 'US',
  },
  {
    symbol: 'ZEST',
    name: 'Ecoark Holdings Inc',
    region: 'US',
  },
  {
    symbol: 'ZETA',
    name: 'Zeta Global Holdings Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ZEUS',
    name: 'Olympic Steel Inc.',
    region: 'US',
  },
  {
    symbol: 'ZEV',
    name: 'Lightning eMotors Inc',
    region: 'US',
  },
  {
    symbol: 'ZFSVF',
    name: 'Zurich Insurance Group AG Ltd',
    region: 'US',
  },
  {
    symbol: 'ZG',
    name: 'Zillow Group Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZGEN',
    name: 'Alpha Architect Etf Trust - The Generation Z ETF',
    region: 'US',
  },
  {
    symbol: 'ZH',
    name: 'Zhihu Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'ZHDG',
    name: 'Tidal ETF Trust - ZEGA Buy and Hedge ETF',
    region: 'US',
  },
  {
    symbol: 'ZI',
    name: 'ZoomInfo Technologies Inc. - Class A',
    region: 'US',
  },
  {
    symbol: 'ZIG',
    name: 'ETF Series Solutions Trust - The Acquirers Fund',
    region: 'US',
  },
  {
    symbol: 'ZIM',
    name: 'Zim Integrated Shipping Services Ltd',
    region: 'US',
  },
  {
    symbol: 'ZIMV',
    name: 'ZimVie Inc',
    region: 'US',
  },
  {
    symbol: 'ZING',
    name: 'FTAC Zeus Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ZINGU',
    name: 'FTAC Zeus Acquisition Corp - Units (1 Ord Share Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ZION',
    name: 'Zions Bancorporation N.A',
    region: 'US',
  },
  {
    symbol: 'ZIP',
    name: 'ZipRecruiter Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZIVO',
    name: 'Zivo Bioscience Inc',
    region: 'US',
  },
  {
    symbol: 'ZKIN',
    name: 'ZK International Group Co Ltd.',
    region: 'US',
  },
  {
    symbol: 'ZLAB',
    name: 'Zai Lab Limited - ADR',
    region: 'US',
  },
  {
    symbol: 'ZM',
    name: 'Zoom Video Communications Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZME',
    name: 'Zhangmen Education Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'ZNGA',
    name: 'Zynga Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZNH',
    name: 'China Southern Airlines Company Ltd. - ADR',
    region: 'US',
  },
  {
    symbol: 'ZNTE',
    name: 'Zanite Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ZNTEU',
    name: 'Zanite Acquisition Corp - Units (1 Ord Class A & 1/2 War)',
    region: 'US',
  },
  {
    symbol: 'ZNTL',
    name: 'Zentalis Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ZOM',
    name: 'Zomedica Corp',
    region: 'US',
  },
  {
    symbol: 'ZROZ',
    name: 'Pimco Exchange Traded Fund - PIMCO 25+ Year Zero Coupon U.S. Treasury Index Exchange-Traded Fund',
    region: 'US',
  },
  {
    symbol: 'ZS',
    name: 'Zscaler Inc',
    region: 'US',
  },
  {
    symbol: 'ZSAN',
    name: 'Zosano Pharma Corp',
    region: 'US',
  },
  {
    symbol: 'ZSL',
    name: 'ProShares Trust - ProShares UltraShort Silver',
    region: 'US',
  },
  {
    symbol: 'ZT',
    name: 'Zimmer Energy Transition Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ZTAQU',
    name: 'Zimmer Energy Transition Acquisition Corp - Units (1 Ord Share Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ZTEK',
    name: 'Zentek Ltd',
    region: 'US',
  },
  {
    symbol: 'ZTO',
    name: 'ZTO Express (Cayman) Inc - ADR',
    region: 'US',
  },
  {
    symbol: 'ZTS',
    name: 'Zoetis Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZUMZ',
    name: 'Zumiez Inc',
    region: 'US',
  },
  {
    symbol: 'ZUO',
    name: 'Zuora Inc - Class A',
    region: 'US',
  },
  {
    symbol: 'ZURVY',
    name: 'Zurich Insurance Group AG Ltd - ADR',
    region: 'US',
  },
  {
    symbol: 'ZVIA',
    name: 'Zevia PBC - Class A',
    region: 'US',
  },
  {
    symbol: 'ZVO',
    name: 'Zovio Inc',
    region: 'US',
  },
  {
    symbol: 'ZWRK',
    name: 'Z-Work Acquisition Corp - Class A',
    region: 'US',
  },
  {
    symbol: 'ZWRKU',
    name: 'Z-Work Acquisition Corp - Units (1 Ord Class A & 1/3 War)',
    region: 'US',
  },
  {
    symbol: 'ZWS',
    name: 'Zurn Water Solutions Corp',
    region: 'US',
  },
  {
    symbol: 'ZY',
    name: 'Zymergen Inc',
    region: 'US',
  },
  {
    symbol: 'ZYME',
    name: 'Zymeworks Inc',
    region: 'US',
  },
  {
    symbol: 'ZYNE',
    name: 'Zynerba Pharmaceuticals Inc',
    region: 'US',
  },
  {
    symbol: 'ZYXI',
    name: 'Zynex Inc',
    region: 'US',
  },
  {
    symbol: 'A-CV',
    name: 'Armor Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'AAB-CT',
    name: 'Aberdeen International Inc',
    region: 'CA',
  },
  {
    symbol: 'AAG-CV',
    name: 'Aftermath Silver Ltd',
    region: 'CA',
  },
  {
    symbol: 'AALI-CV',
    name: 'Advance Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'AAN-CV',
    name: 'Aton Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'AAT-CV',
    name: 'ATI Airtest Technologies, Inc.',
    region: 'CA',
  },
  {
    symbol: 'AAU-CV',
    name: 'Angold Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'AAV-CT',
    name: 'Advantage Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'AAZ-CV',
    name: 'Azincourt Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'AB.H-CV',
    name: 'Asbestos Corp., Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ABCT-CT',
    name: 'ABC Technologies Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'ABI-CV',
    name: 'Abcourt Mines, Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'ABN-CV',
    name: 'Aben Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ABR-CV',
    name: 'Arbor Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'ABRA-CV',
    name: 'Abrasilver Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'ABST-CT',
    name: 'Absolute Software Corp',
    region: 'CA',
  },
  {
    symbol: 'ABX-CT',
    name: 'Barrick Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'ABXX-QH',
    name: 'Abaxx Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ABZ-CV',
    name: 'AsiaBaseMetals Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'AC-CT',
    name: 'Air Canada Inc.',
    region: 'CA',
  },
  {
    symbol: 'ACB-CT',
    name: 'Aurora Cannabis Inc',
    region: 'CA',
  },
  {
    symbol: 'ACE.H-CV',
    name: 'ACE Aviation Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'ACI.H-CV',
    name: 'Amilot Capital Inc.',
    region: 'CA',
  },
  {
    symbol: 'ACME-CF',
    name: 'ACME Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'ACO.X-CT',
    name: 'Atco Ltd. - Class I',
    region: 'CA',
  },
  {
    symbol: 'ACO.Y-CT',
    name: 'Atco Ltd. - Class II',
    region: 'CA',
  },
  {
    symbol: 'ACOG-CV',
    name: 'Alpha Cognition Inc',
    region: 'CA',
  },
  {
    symbol: 'ACP-CV',
    name: 'ArcPacific Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ACQ-CT',
    name: 'AutoCanada Inc',
    region: 'CA',
  },
  {
    symbol: 'ACS-CV',
    name: 'Archon Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ACST-CV',
    name: 'Acasti Pharma Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'ACT-CF',
    name: 'Aduro Clean Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ACU-CV',
    name: 'Aurora Solar Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ADCO-CT',
    name: 'Adcore Inc',
    region: 'CA',
  },
  {
    symbol: 'ADD-CV',
    name: 'Arctic Star Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'ADE-CV',
    name: 'Adex Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'ADG-CV',
    name: 'Arcus Development Group Inc',
    region: 'CA',
  },
  {
    symbol: 'ADK-CV',
    name: 'Diagnos Inc.',
    region: 'CA',
  },
  {
    symbol: 'ADN-CT',
    name: 'Acadian Timber Corp',
    region: 'CA',
  },
  {
    symbol: 'ADR.H-CV',
    name: 'Arcland Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'ADW.A-CT',
    name: 'Andrew Peller Limited - Class A',
    region: 'CA',
  },
  {
    symbol: 'ADW.B-CT',
    name: 'Andrew Peller Limited - Class B',
    region: 'CA',
  },
  {
    symbol: 'ADY-CV',
    name: 'Adyton Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'ADYA-CV',
    name: 'Adya Inc',
    region: 'CA',
  },
  {
    symbol: 'ADZ-CV',
    name: 'Adamera Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'ADZN-CV',
    name: 'Adventus Mining Corporation',
    region: 'CA',
  },
  {
    symbol: 'AE-CV',
    name: 'American Eagle Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'AEC-CV',
    name: 'Anfield Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'AEG-CT',
    name: 'Aegis Brands Inc.',
    region: 'CA',
  },
  {
    symbol: 'AEL.H-CV',
    name: 'Amseco Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'AEM-CT',
    name: 'Agnico Eagle Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'AEP-CV',
    name: 'Atlas Engineered Products Ltd',
    region: 'CA',
  },
  {
    symbol: 'AEX-CV',
    name: 'AEX Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'AEZS-CT',
    name: 'Aeterna Zentaris Inc.',
    region: 'CA',
  },
  {
    symbol: 'AF.P-CV',
    name: 'AF2 Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'AFCC.H-CV',
    name: 'Automotive Finco Corp',
    region: 'CA',
  },
  {
    symbol: 'AFE-CV',
    name: 'Africa Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'AFF-CV',
    name: 'Affinity Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'AFI-CF',
    name: 'Affinor Growers Inc',
    region: 'CA',
  },
  {
    symbol: 'AFM-CV',
    name: 'Alphamin Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'AFN-CT',
    name: 'Ag Growth International Inc',
    region: 'CA',
  },
  {
    symbol: 'AFX-CF',
    name: 'Arctic Fox Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'AGD-CV',
    name: 'Antioquia Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'AGG-CV',
    name: 'African Gold Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'AGH.H-CV',
    name: 'Canadian Silver Hunter Inc',
    region: 'CA',
  },
  {
    symbol: 'AGI-CT',
    name: 'Alamos Gold Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'AGL-CV',
    name: 'Aguila Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'AGLD-CV',
    name: 'Austral Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'AGN-CF',
    name: 'Algernon Pharmaceuticals Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'AGO-CV',
    name: 'Big Tree Carbon Inc',
    region: 'CA',
  },
  {
    symbol: 'AGRA-CF',
    name: 'Agra Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'AGX-CV',
    name: 'Silver X Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'AH-CT',
    name: 'Aleafia Health Inc.',
    region: 'CA',
  },
  {
    symbol: 'AHE.H-CV',
    name: 'American Helium Inc',
    region: 'CA',
  },
  {
    symbol: 'AHR-CV',
    name: 'Amarc Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'AIF-CT',
    name: 'Altus Group Ltd.',
    region: 'CA',
  },
  {
    symbol: 'AII-CT',
    name: 'Almonty Industries Inc',
    region: 'CA',
  },
  {
    symbol: 'AIM-CT',
    name: 'Aimia Inc',
    region: 'CA',
  },
  {
    symbol: 'AIME.P-CV',
    name: 'AIM5 Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'AIML-CF',
    name: 'AI/ML Innovations Inc',
    region: 'CA',
  },
  {
    symbol: 'AION-CF',
    name: 'Aion Therapeutic Inc',
    region: 'CA',
  },
  {
    symbol: 'AIR-CV',
    name: 'Clean Air Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'AIS-CV',
    name: 'AIS Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'AJN-CF',
    name: 'AJN Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'AKE-CT',
    name: 'Allkem Ltd',
    region: 'CA',
  },
  {
    symbol: 'AKH.H-CV',
    name: 'Alaska Hydro Corp',
    region: 'CA',
  },
  {
    symbol: 'AKMY-CV',
    name: 'K.B. Recycling Industries Ltd',
    region: 'CA',
  },
  {
    symbol: 'AKR-CV',
    name: 'Ackroo Inc',
    region: 'CA',
  },
  {
    symbol: 'AKT.A-CT',
    name: 'Akita Drilling Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'AKT.B-CT',
    name: 'Akita Drilling Ltd. - Class B',
    region: 'CA',
  },
  {
    symbol: 'AKU-CT',
    name: 'Akumin Inc',
    region: 'CA',
  },
  {
    symbol: 'AL-CV',
    name: 'ALX Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ALA-CT',
    name: 'AltaGas Ltd',
    region: 'CA',
  },
  {
    symbol: 'ALC-CT',
    name: 'Algoma Central Corp.',
    region: 'CA',
  },
  {
    symbol: 'ALCU-CF',
    name: 'Alpha Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'ALDE-CV',
    name: 'Aldebaran Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ALE-CF',
    name: 'Alerio Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ALLI-CV',
    name: 'Alpha Lithium Corporation - Class A',
    region: 'CA',
  },
  {
    symbol: 'ALM-CV',
    name: 'Alliance Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ALPA-CF',
    name: 'Alpha Metaverse Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ALS-CT',
    name: 'Altius Minerals Corp.',
    region: 'CA',
  },
  {
    symbol: 'ALT-CV',
    name: 'Alturas Minerals Corporation',
    region: 'CA',
  },
  {
    symbol: 'ALTA-CV',
    name: 'Altamira Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ALTS-CV',
    name: 'Altus Strategies plc',
    region: 'CA',
  },
  {
    symbol: 'ALV-CV',
    name: 'Alvopetro Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'ALY-CV',
    name: 'AnalytixInsight Inc',
    region: 'CA',
  },
  {
    symbol: 'ALYA-CT',
    name: 'Alithya Group inc - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'AM-CV',
    name: 'Academy Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'AMC-CV',
    name: 'Arizona Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'AME-CV',
    name: 'Abacus Mining & Exploration Corp.',
    region: 'CA',
  },
  {
    symbol: 'AMI-CV',
    name: 'Athabasca Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'AMK-CV',
    name: 'American Creek Resources Ltd - New',
    region: 'CA',
  },
  {
    symbol: 'AML-CV',
    name: 'Akwaaba Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'AMM-CT',
    name: 'Almaden Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'AMMO-CV',
    name: 'Bullet Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'AMMP-CF',
    name: 'AmmPower Corp',
    region: 'CA',
  },
  {
    symbol: 'AMO-CV',
    name: 'Altan Rio Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'AMPD-CF',
    name: 'AMPD Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'AMR-CV',
    name: 'AM Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'AMV-CV',
    name: 'AMV Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'AMX-CV',
    name: 'Amex Exploration Inc.',
    region: 'CA',
  },
  {
    symbol: 'AMY-CV',
    name: 'American Manganese Inc',
    region: 'CA',
  },
  {
    symbol: 'AMZ-CV',
    name: 'Azucar Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'AN-CV',
    name: 'Arena Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'AND-CT',
    name: 'Andlauer Healthcare Group Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'ANE-CV',
    name: 'Altan Nevada Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'ANG-CV',
    name: 'Angel Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ANK-CV',
    name: 'Angkor Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ANRG-CT',
    name: 'Anaergia Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'ANTL-CV',
    name: 'Antler Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'ANX-CT',
    name: 'Anaconda Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'ANZ-CV',
    name: 'Alianza Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'AOC-CF',
    name: 'Advantagewon Oil Corp.',
    region: 'CA',
  },
  {
    symbol: 'AOI-CT',
    name: 'Africa Oil Corp',
    region: 'CA',
  },
  {
    symbol: 'AORO-CV',
    name: 'Aloro Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'AOT-CT',
    name: 'Ascot Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'APC-CV',
    name: 'Advanced Proteome Therapeutics Corporation',
    region: 'CA',
  },
  {
    symbol: 'APGO-CV',
    name: 'Apollo Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'API-CF',
    name: 'Appia Rare Earths & Uranium Corp.',
    region: 'CA',
  },
  {
    symbol: 'APL-CV',
    name: 'Appulse Corporation',
    region: 'CA',
  },
  {
    symbol: 'APLI-CT',
    name: 'Appili Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'APLV-CF',
    name: 'ApartmentLove Inc',
    region: 'CA',
  },
  {
    symbol: 'APN-CV',
    name: 'Altiplano Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'APP-CF',
    name: 'Global Cannabis Applications Corp',
    region: 'CA',
  },
  {
    symbol: 'APS-CT',
    name: 'Aptose Biosciences Inc',
    region: 'CA',
  },
  {
    symbol: 'APX-CV',
    name: 'Apex Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'APY-CT',
    name: 'Anglo Pacific Group',
    region: 'CA',
  },
  {
    symbol: 'AQN-CT',
    name: 'Algonquin Power & Utilities Corp',
    region: 'CA',
  },
  {
    symbol: 'AQS-CV',
    name: 'Aequus Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'AQUA-CF',
    name: 'Aquarius AI Inc',
    region: 'CA',
  },
  {
    symbol: 'AR-CT',
    name: 'Argonaut Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'ARA-CT',
    name: 'Aclara Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ARCH-CV',
    name: 'Arch Biopartners Inc.',
    region: 'CA',
  },
  {
    symbol: 'ARD-CV',
    name: 'Armada Data Corp.',
    region: 'CA',
  },
  {
    symbol: 'ARE-CT',
    name: 'Aecon Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'AREV-CF',
    name: 'AREV Life Sciences Global Corp',
    region: 'CA',
  },
  {
    symbol: 'ARG-CT',
    name: 'Amerigo Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ARH-CV',
    name: 'Altima Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ARIC-CV',
    name: 'Awale Resources Limited',
    region: 'CA',
  },
  {
    symbol: 'ARIS-CT',
    name: 'Aris Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ARJN-CV',
    name: 'Aranjin Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'AROW.P-CV',
    name: 'Four Arrows Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'ARQ-CF',
    name: 'Argo Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'ARR-CT',
    name: 'Altius Renewable Royalties Corp.',
    region: 'CA',
  },
  {
    symbol: 'ARS-CF',
    name: 'Ares Strategic Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'ART-CV',
    name: 'Arht Media Inc',
    region: 'CA',
  },
  {
    symbol: 'ARTG-CV',
    name: 'Artemis Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'ARU-CV',
    name: 'Aurania Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ARX-CT',
    name: 'ARC Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ASCU-CT',
    name: 'Arizona Sonoran Copper Company Inc',
    region: 'CA',
  },
  {
    symbol: 'ASE-CF',
    name: 'Asante Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ASG-CV',
    name: 'Aurora Spine Corp',
    region: 'CA',
  },
  {
    symbol: 'ASIA-CF',
    name: 'Asia Green Biotechnology Corp',
    region: 'CA',
  },
  {
    symbol: 'ASL-CV',
    name: 'Argentum Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'ASM-CT',
    name: 'Avino Silver & Gold Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ASND-CT',
    name: 'Ascendant Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ASP-CT',
    name: 'Acerus Pharmaceuticals Corp',
    region: 'CA',
  },
  {
    symbol: 'AST-CV',
    name: 'Astron Connect Inc.',
    region: 'CA',
  },
  {
    symbol: 'ASTI-CF',
    name: 'Aquarius Surgical Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ASTL-CT',
    name: 'Algoma Steel Group Inc',
    region: 'CA',
  },
  {
    symbol: 'AT-CT',
    name: 'AcuityAds Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'ATA-CT',
    name: 'ATS Automation Tooling Systems, Inc.',
    region: 'CA',
  },
  {
    symbol: 'ATC-CV',
    name: 'Atac Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ATD-CT',
    name: 'Alimentation-Couche Tard, Inc. - Class A (Multiple Voting)',
    region: 'CA',
  },
  {
    symbol: 'ATE-CT',
    name: 'Antibe Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'ATH-CT',
    name: 'Athabasca Oil Corp',
    region: 'CA',
  },
  {
    symbol: 'ATHA-CF',
    name: 'Athena Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ATHR-CF',
    name: 'Aether Catalyst Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'ATI-CV',
    name: 'Altai Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'ATLY-CV',
    name: 'Altaley Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'ATOM-CV',
    name: 'Atomic Minerals Corporation',
    region: 'CA',
  },
  {
    symbol: 'ATW-CV',
    name: 'ATW Tech Inc',
    region: 'CA',
  },
  {
    symbol: 'ATX-CV',
    name: 'ATEX Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ATY-CV',
    name: 'Atico Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ATZ-CT',
    name: 'Aritzia Inc (Subordinate Voting Shares)',
    region: 'CA',
  },
  {
    symbol: 'AU-CV',
    name: 'Aurion Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'AUAG-CF',
    name: 'Auxico Resources Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'AUAU-CV',
    name: 'Allegiant Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'AUC-CV',
    name: 'Goldplay Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'AUCU-CF',
    name: 'Inflection Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'AUEN-CV',
    name: 'Golden Sky Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'AUGC-CV',
    name: 'Au Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'AUGF-CF',
    name: 'Australian Goldfields Limited',
    region: 'CA',
  },
  {
    symbol: 'AUHI-CF',
    name: 'Advance United Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'AUL-CV',
    name: 'Aurelius Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'AUMB-CV',
    name: '1911 Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'AUMN-CT',
    name: 'Golden Minerals Co',
    region: 'CA',
  },
  {
    symbol: 'AUN-CV',
    name: 'Aurcana Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'AUQ-CV',
    name: 'AuQ Gold Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'AUR.H-CV',
    name: 'Aurora Royalties Inc',
    region: 'CA',
  },
  {
    symbol: 'AUT.H-CV',
    name: 'Austin Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'AUTO-CV',
    name: 'Carbeeza Inc',
    region: 'CA',
  },
  {
    symbol: 'AUU-CV',
    name: 'Gold79 Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'AVCN-CT',
    name: 'Avicanna Inc',
    region: 'CA',
  },
  {
    symbol: 'AVCR-CV',
    name: 'Avricore Health Inc.',
    region: 'CA',
  },
  {
    symbol: 'AVG-CV',
    name: 'Avidian Gold Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'AVL-CT',
    name: 'Avalon Advanced Materials Inc',
    region: 'CA',
  },
  {
    symbol: 'AVM.X-CF',
    name: 'Avarone Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'AVN-CV',
    name: 'Avanti Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'AVNT-CT',
    name: 'Avant Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'AVP-CT',
    name: 'Avcorp Industries, Inc.',
    region: 'CA',
  },
  {
    symbol: 'AVU-CV',
    name: 'Avrupa Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'AVX-CV',
    name: 'Altair Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'AWE-CV',
    name: 'Thunderstruck Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'AWI-CV',
    name: 'Advent-AWI Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'AWLI-CF',
    name: 'Ameriwest Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'AWR-CF',
    name: 'Aurwest Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'AWX-CV',
    name: 'Arcwest Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'AXE-CV',
    name: 'Acceleware Ltd',
    region: 'CA',
  },
  {
    symbol: 'AXL-CV',
    name: 'Arrow Exploration Corp.',
    region: 'CA',
  },
  {
    symbol: 'AXM-CV',
    name: 'Axmin, Inc',
    region: 'CA',
  },
  {
    symbol: 'AXU-CT',
    name: 'Alexco Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'AYA-CT',
    name: 'Aya Gold & Silver Inc',
    region: 'CA',
  },
  {
    symbol: 'AYM-CT',
    name: 'Atalaya Mining Plc',
    region: 'CA',
  },
  {
    symbol: 'AYR.A-CF',
    name: 'Ayr Wellness Inc. (Sub, Restricted, Ltd Voting)',
    region: 'CA',
  },
  {
    symbol: 'AYUR-CF',
    name: 'Ayurcann Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'AZ-CV',
    name: 'A2Z Smart Technologies Corp.',
    region: 'CA',
  },
  {
    symbol: 'AZM-CV',
    name: 'Azimut Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'AZR-CV',
    name: 'Azarga Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'AZS-CV',
    name: 'Arizona Silver Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'AZT-CV',
    name: 'Aztec Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'B-CV',
    name: 'BCM Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'BABY-CT',
    name: 'Else Nutrition Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'BAC-CF',
    name: 'BacTech Environmental Corp',
    region: 'CA',
  },
  {
    symbol: 'BAG-CV',
    name: 'Blende Silver Corp.',
    region: 'CA',
  },
  {
    symbol: 'BAL.H-CV',
    name: 'Balto Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'BAMM-CF',
    name: 'Body and Mind Inc',
    region: 'CA',
  },
  {
    symbol: 'BAMR-CT',
    name: 'Brookfield Asset Management Reinsurance Partners Ltd (Exchangeable Shares, Sub Voting) - Class A',
    region: 'CA',
  },
  {
    symbol: 'BARU-CV',
    name: 'Baru Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'BAT-CV',
    name: 'Batero Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'BAU-CV',
    name: 'Blue Star Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'BAV.H-CV',
    name: 'Bravern Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'BAY-CV',
    name: 'Aston Bay Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'BB-CT',
    name: 'BlackBerry Ltd',
    region: 'CA',
  },
  {
    symbol: 'BBB-CV',
    name: 'Brixton Metals Corporation',
    region: 'CA',
  },
  {
    symbol: 'BBD.A-CT',
    name: 'Bombardier Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'BBD.B-CT',
    name: 'Bombardier Inc. - Class B (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'BBM-CF',
    name: 'Blueberries Medical Corp',
    region: 'CA',
  },
  {
    symbol: 'BBR-CF',
    name: 'Bam Bam Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'BBTV-CT',
    name: 'BBTV Holdings Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'BCE-CT',
    name: 'BCE Inc',
    region: 'CA',
  },
  {
    symbol: 'BCFN-CF',
    name: 'Blockchain Foundry Inc',
    region: 'CA',
  },
  {
    symbol: 'BCM-CV',
    name: 'Bear Creek Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'BCT-CT',
    name: 'BriaCell Therapeutics Corp',
    region: 'CA',
  },
  {
    symbol: 'BCU-CV',
    name: 'Bell Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'BDGC-CV',
    name: 'Boundary Gold and Copper Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'BDGI-CT',
    name: 'Badger Infrastructure Solutions Ltd',
    region: 'CA',
  },
  {
    symbol: 'BDI-CT',
    name: 'Black Diamond Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'BDN-CF',
    name: 'Baden Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BDR-CF',
    name: 'Bird River Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BDT-CT',
    name: 'Bird Construction Inc',
    region: 'CA',
  },
  {
    symbol: 'BEA-CV',
    name: 'Belmont Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'BEAN-CF',
    name: 'Peekaboo Beans Inc',
    region: 'CA',
  },
  {
    symbol: 'BEE-CF',
    name: 'Bee Vectoring Technologies International Inc',
    region: 'CA',
  },
  {
    symbol: 'BEK.B-CT',
    name: 'Becker Milk Co. Ltd. - Class B',
    region: 'CA',
  },
  {
    symbol: 'BEN.H-CV',
    name: 'Butte Energy Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'BEPC-CT',
    name: 'Brookfield Renewable Corporation - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'BES-CV',
    name: 'Braille Energy Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'BETR-CF',
    name: 'BetterLife Pharma Inc',
    region: 'CA',
  },
  {
    symbol: 'BETS-CF',
    name: 'i3 Interactive Inc',
    region: 'CA',
  },
  {
    symbol: 'BEV-CF',
    name: 'BevCanna Enterprises Inc',
    region: 'CA',
  },
  {
    symbol: 'BEW-CV',
    name: 'BeWhere Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'BEX-CV',
    name: 'Benton Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BGA-CV',
    name: 'BioNeutra Global Corp',
    region: 'CA',
  },
  {
    symbol: 'BGD-CV',
    name: 'Bullion Gold Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'BGF-CV',
    name: 'Beauce Gold Fields Inc',
    region: 'CA',
  },
  {
    symbol: 'BGS-CV',
    name: 'Baroyeca Gold & Silver Inc',
    region: 'CA',
  },
  {
    symbol: 'BHC-CT',
    name: 'Bausch Health Companies Inc',
    region: 'CA',
  },
  {
    symbol: 'BHNG-CF',
    name: 'Bhang Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'BHS-CV',
    name: 'Bayhorse Silver Inc',
    region: 'CA',
  },
  {
    symbol: 'BHSC-CF',
    name: 'BioHarvest Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'BHT-CV',
    name: 'Braveheart Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BIGG-CF',
    name: 'BIGG Digital Assets Inc',
    region: 'CA',
  },
  {
    symbol: 'BILD-CV',
    name: 'BuildDirect.com Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'BILZ-CF',
    name: 'Ignite International Brands Ltd (Subordinate Voting Shares)',
    region: 'CA',
  },
  {
    symbol: 'BIO-CF',
    name: 'Biome Grow Inc',
    region: 'CA',
  },
  {
    symbol: 'BIOV-CF',
    name: 'BioVaxys Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'BIPC-CT',
    name: 'Brookfield Infrastructure Corp - Class A (Subordinate Share)',
    region: 'CA',
  },
  {
    symbol: 'BIR-CT',
    name: 'Birchcliff Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'BITE-CF',
    name: 'Blender Bites Limited',
    region: 'CA',
  },
  {
    symbol: 'BITF-CT',
    name: 'Bitfarms Ltd.',
    region: 'CA',
  },
  {
    symbol: 'BITK-CV',
    name: 'BlockchainK2 Corp',
    region: 'CA',
  },
  {
    symbol: 'BJB-CF',
    name: 'Bond Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BKI-CT',
    name: 'Black Iron Inc',
    region: 'CA',
  },
  {
    symbol: 'BKM-CV',
    name: 'Pacific Booker Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'BKMT-CV',
    name: 'Blockmint Technologies Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'BLAB-CF',
    name: 'Britannia Life Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'BLDP-CT',
    name: 'Ballard Power Systems Inc.',
    region: 'CA',
  },
  {
    symbol: 'BLGV-CF',
    name: 'Belgravia Hartford Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'BLLG-CF',
    name: 'Blue Lagoon Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'BLM-CV',
    name: 'BluMetric Environmental Inc',
    region: 'CA',
  },
  {
    symbol: 'BLMH-CF',
    name: 'Bloom Health Partners Inc',
    region: 'CA',
  },
  {
    symbol: 'BLN-CT',
    name: 'Blackline Safety Corp',
    region: 'CA',
  },
  {
    symbol: 'BLO-CF',
    name: 'Cannabix Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'BLOK-CV',
    name: 'GlobalBlock Digital Asset Trading Limited',
    region: 'CA',
  },
  {
    symbol: 'BLOX-CF',
    name: 'Cryptoblox Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'BLR-CF',
    name: 'Blackhawk Growth Corp',
    region: 'CA',
  },
  {
    symbol: 'BLTC.P-CV',
    name: 'Baltic I Acquisition Corp',
    region: 'CA',
  },
  {
    symbol: 'BLU-CT',
    name: 'Bellus Health Inc',
    region: 'CA',
  },
  {
    symbol: 'BLUE-CV',
    name: 'Blue Thunder Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'BLX-CT',
    name: 'Boralex, Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'BM.H-CV',
    name: 'BC Moly Ltd',
    region: 'CA',
  },
  {
    symbol: 'BME-CV',
    name: 'Barsele Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'BMET-CV',
    name: 'BeMetals Corp.',
    region: 'CA',
  },
  {
    symbol: 'BMEX-CV',
    name: 'BMEX Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'BMG-CV',
    name: 'Black Mountain Gold USA Corp',
    region: 'CA',
  },
  {
    symbol: 'BMK-CV',
    name: 'MacDonald Mines Exploration Ltd.',
    region: 'CA',
  },
  {
    symbol: 'BMM-CV',
    name: 'Black Mammoth Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'BMO-CT',
    name: 'Bank of Montreal',
    region: 'CA',
  },
  {
    symbol: 'BMR-CV',
    name: 'Battery Mineral Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'BMV-CV',
    name: 'Bathurst Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'BNCH-CV',
    name: 'Benchmark Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'BNE-CT',
    name: 'Bonterra Energy Corp.',
    region: 'CA',
  },
  {
    symbol: 'BNG-CT',
    name: 'Bengal Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'BNKR-CF',
    name: 'Bunker Hill Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'BNN-CF',
    name: 'Benjamin Hill Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'BNS-CT',
    name: 'Bank Of Nova Scotia',
    region: 'CA',
  },
  {
    symbol: 'BNXA-CV',
    name: 'Banxa Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'BOL-CV',
    name: 'Bold Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'BOLP-CT',
    name: 'Breath of Life International Ltd',
    region: 'CA',
  },
  {
    symbol: 'BOLT-CF',
    name: 'Bolt Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'BOS-CT',
    name: 'Airboss Of America Corp.',
    region: 'CA',
  },
  {
    symbol: 'BP-CV',
    name: 'Backstageplay Inc',
    region: 'CA',
  },
  {
    symbol: 'BQE-CV',
    name: 'BQE Water Inc',
    region: 'CA',
  },
  {
    symbol: 'BR-CT',
    name: 'Big Rock Brewery Inc',
    region: 'CA',
  },
  {
    symbol: 'BRAG-CT',
    name: 'Bragg Gaming Group Inc',
    region: 'CA',
  },
  {
    symbol: 'BRAS-CF',
    name: 'Brascan Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'BRAU-CV',
    name: 'Big Ridge Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'BRAX-CF',
    name: 'Braxia Scientific Corp',
    region: 'CA',
  },
  {
    symbol: 'BRC-CV',
    name: 'Blackrock Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'BRE-CT',
    name: 'Bridgemarq Real Estate Services Inc (Restricted Voting)',
    region: 'CA',
  },
  {
    symbol: 'BRG-CV',
    name: 'Brigadier Gold Ltd.',
    region: 'CA',
  },
  {
    symbol: 'BRL.H-CV',
    name: 'Bearclaw Capital Corp.',
    region: 'CA',
  },
  {
    symbol: 'BRM-CV',
    name: 'Biorem Inc',
    region: 'CA',
  },
  {
    symbol: 'BRMI-CT',
    name: 'Boat Rocker Media Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'BRO-CV',
    name: 'Barksdale Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'BRTA.H-CV',
    name: 'Beretta Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'BRW-CV',
    name: 'Brunswick Exploration Inc.',
    region: 'CA',
  },
  {
    symbol: 'BRY-CT',
    name: 'Bri-Chem Corporation',
    region: 'CA',
  },
  {
    symbol: 'BRZ-CV',
    name: 'Bearing Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'BSK-CV',
    name: 'Blue Sky Uranium Corp.',
    region: 'CA',
  },
  {
    symbol: 'BSR-CV',
    name: 'Bluestone Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BST.H-CV',
    name: 'Bessor Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'BSX-CT',
    name: 'Belo Sun Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'BTC-CF',
    name: 'Bluesky Digital Assets Corp',
    region: 'CA',
  },
  {
    symbol: 'BTE-CT',
    name: 'Baytex Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'BTI-CV',
    name: 'Bioasis Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'BTO-CT',
    name: 'B2gold Corp',
    region: 'CA',
  },
  {
    symbol: 'BTR-CV',
    name: 'Bonterra Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BTT-CV',
    name: 'Bitterroot Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'BTU-CV',
    name: 'BTU Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'BTV-CV',
    name: 'BlueRush Inc.',
    region: 'CA',
  },
  {
    symbol: 'BU-CT',
    name: 'Burcon Nutrascience Corp',
    region: 'CA',
  },
  {
    symbol: 'BUD-CV',
    name: 'Satori Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BUF-CV',
    name: 'Buffalo Coal Corp',
    region: 'CA',
  },
  {
    symbol: 'BUI-CT',
    name: 'Buhler Industries, Inc.',
    region: 'CA',
  },
  {
    symbol: 'BULL-CF',
    name: 'Canadian Palladium Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'BUX-CF',
    name: 'BioMark Diagnostics Inc',
    region: 'CA',
  },
  {
    symbol: 'BVA-CV',
    name: 'Bravada Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'BWCG-CV',
    name: 'Blackwolf Copper and Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'BWLK-CV',
    name: 'Boardwalktech Software Corp.',
    region: 'CA',
  },
  {
    symbol: 'BWR-CV',
    name: 'BWR Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'BXR-CV',
    name: 'Blue River Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'BYD-CT',
    name: 'Boyd Group Services Inc',
    region: 'CA',
  },
  {
    symbol: 'BYL-CT',
    name: 'Baylin Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'BYM.H-CV',
    name: 'Baymount Inc',
    region: 'CA',
  },
  {
    symbol: 'BYN-CV',
    name: 'Banyan Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'BYND-CF',
    name: 'BYND Cannasoft Enterprises Inc',
    region: 'CA',
  },
  {
    symbol: 'BZ-CV',
    name: 'Benz Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'C-CV',
    name: 'Contact Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CAC.P-CV',
    name: 'Cinaport Acquisition Corp III',
    region: 'CA',
  },
  {
    symbol: 'CAD-CV',
    name: 'Colonial Coal International Corp',
    region: 'CA',
  },
  {
    symbol: 'CAE-CT',
    name: 'Cae Inc.',
    region: 'CA',
  },
  {
    symbol: 'CAF-CV',
    name: 'Canaf Investments Inc.',
    region: 'CA',
  },
  {
    symbol: 'CAG-CV',
    name: 'Composite Alliance Group Inc',
    region: 'CA',
  },
  {
    symbol: 'CAI-CV',
    name: 'Capitan Investment Ltd',
    region: 'CA',
  },
  {
    symbol: 'CAKE-CF',
    name: 'Radio Fuels Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'CALI-CF',
    name: 'FinCanna Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'CALM-CF',
    name: 'Revitalist Lifestyle and Wellness Ltd (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'CAMP-CF',
    name: 'Canadian GoldCamps Corp',
    region: 'CA',
  },
  {
    symbol: 'CANA-CF',
    name: 'CannAmerica Brands Corp.',
    region: 'CA',
  },
  {
    symbol: 'CANB-CV',
    name: 'Stigma Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'CAND-CV',
    name: 'Candelaria Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'CANX-CV',
    name: 'CANEX Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'CAPT-CV',
    name: 'Capitan Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'CARE-CT',
    name: 'Dialogue Health Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'CAS-CT',
    name: 'Cascades Inc.',
    region: 'CA',
  },
  {
    symbol: 'CASA-CV',
    name: 'Casa Minerals Inc.',
    region: 'CA',
  },
  {
    symbol: 'CAT-CF',
    name: 'CAT Strategic Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'CAU.H-CV',
    name: 'China Goldcorp Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CAVU-CF',
    name: 'CAVU Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'CBA-CV',
    name: 'Champion Bear Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CBDN-CF',
    name: 'CBD Global Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'CBDT-CF',
    name: 'Empower Clinics Inc',
    region: 'CA',
  },
  {
    symbol: 'CBG-CV',
    name: 'Chibougamau Independent Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'CBI-CV',
    name: 'Colibri Resource Corporation',
    region: 'CA',
  },
  {
    symbol: 'CBIT-CV',
    name: 'Cathedra Bitcoin Inc',
    region: 'CA',
  },
  {
    symbol: 'CBLT-CV',
    name: 'CBLT Inc',
    region: 'CA',
  },
  {
    symbol: 'CBLU-CV',
    name: 'Clear Blue Technologies International Inc.',
    region: 'CA',
  },
  {
    symbol: 'CBOX.X-CF',
    name: 'Cashbox Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'CBP-CF',
    name: 'Certive Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'CBR-CV',
    name: 'Cabral Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'CBV-CV',
    name: 'Cobra Venture Corp',
    region: 'CA',
  },
  {
    symbol: 'CC-CF',
    name: 'Core Assets Corp',
    region: 'CA',
  },
  {
    symbol: 'CCA-CT',
    name: 'Cogeco Communications Inc',
    region: 'CA',
  },
  {
    symbol: 'CCB-CV',
    name: 'Canada Carbon Inc',
    region: 'CA',
  },
  {
    symbol: 'CCC-CF',
    name: 'Carlyle Commodities Corp',
    region: 'CA',
  },
  {
    symbol: 'CCCM-CV',
    name: 'C3 Metals Inc.',
    region: 'CA',
  },
  {
    symbol: 'CCD-CV',
    name: 'Cascadero Copper Corporation',
    region: 'CA',
  },
  {
    symbol: 'CCE-CV',
    name: 'Commerce Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'CCHW-CF',
    name: 'Columbia Care Inc',
    region: 'CA',
  },
  {
    symbol: 'CCHW-QH',
    name: 'Columbia Care Inc',
    region: 'CA',
  },
  {
    symbol: 'CCL.A-CT',
    name: 'CCL Industries Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'CCL.B-CT',
    name: 'CCL Industries Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'CCM-CT',
    name: 'Canagold Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'CCO-CT',
    name: 'Cameco Corp.',
    region: 'CA',
  },
  {
    symbol: 'CCR-CF',
    name: 'Green River Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CCW-CV',
    name: 'Canada Silver Cobalt Works',
    region: 'CA',
  },
  {
    symbol: 'CD-CV',
    name: 'Cantex Mine Development Corp.',
    region: 'CA',
  },
  {
    symbol: 'CDA-CV',
    name: 'Canuc Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'CDAY-CT',
    name: 'Ceridian HCM Holding Inc.',
    region: 'CA',
  },
  {
    symbol: 'CDB-CV',
    name: 'Cordoba Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'CDN-CF',
    name: 'CDN Maverick Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'CDPR-CF',
    name: 'Cerro de Pasco Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'CDVA-CF',
    name: 'CordovaCann Corp',
    region: 'CA',
  },
  {
    symbol: 'CDX-CV',
    name: 'Cloud DX Inc',
    region: 'CA',
  },
  {
    symbol: 'CEBI-CV',
    name: 'CE Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'CEE-CT',
    name: 'Centamin Plc',
    region: 'CA',
  },
  {
    symbol: 'CEG-CF',
    name: 'Cerro Grande Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'CEL.H-CV',
    name: 'Columbus Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'CEM-CV',
    name: 'Constantine Metal Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'CEO.H-CV',
    name: 'Clydesdale Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'CERT-CV',
    name: 'Cerrado Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CET-CT',
    name: 'Cathedral Energy Services Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CEU-CT',
    name: 'CES Energy Solutions Corp',
    region: 'CA',
  },
  {
    symbol: 'CFF-CT',
    name: 'Conifex Timber Inc',
    region: 'CA',
  },
  {
    symbol: 'CFP-CT',
    name: 'Canfor Corp.',
    region: 'CA',
  },
  {
    symbol: 'CFT-CF',
    name: 'Craftport Cannabis Corp',
    region: 'CA',
  },
  {
    symbol: 'CFW-CT',
    name: 'Calfrac Well Services Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CFX-CT',
    name: 'Canfor Pulp Products Inc',
    region: 'CA',
  },
  {
    symbol: 'CFY-CV',
    name: 'CF Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'CG-CT',
    name: 'Centerra Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'CGD-CV',
    name: 'Carlin Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'CGG-CT',
    name: 'China Gold International Resources Corp Ltd',
    region: 'CA',
  },
  {
    symbol: 'CGII-CF',
    name: 'CleanGo Innovations Inc',
    region: 'CA',
  },
  {
    symbol: 'CGO-CT',
    name: 'Cogeco Inc.',
    region: 'CA',
  },
  {
    symbol: 'CGP-CV',
    name: 'Cornerstone Capital Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'CGX-CT',
    name: 'Cineplex Inc',
    region: 'CA',
  },
  {
    symbol: 'CGY-CT',
    name: 'Calian Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'CHAL-CF',
    name: 'Chalice Brands Ltd',
    region: 'CA',
  },
  {
    symbol: 'CHIP.H-CV',
    name: 'Health Logic Interactive Inc.',
    region: 'CA',
  },
  {
    symbol: 'CHM-CF',
    name: 'Chemistree Technology Inc',
    region: 'CA',
  },
  {
    symbol: 'CHN-CV',
    name: 'China Education Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'CHOO-CF',
    name: 'Choom Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'CHR-CT',
    name: 'Chorus Aviation Inc (Voting and Variable Voting Shares)',
    region: 'CA',
  },
  {
    symbol: 'CHW-CT',
    name: 'Chesswood Group Limited',
    region: 'CA',
  },
  {
    symbol: 'CHY-CV',
    name: 'Cypress Hills Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'CIA-CT',
    name: 'Champion Iron Limited',
    region: 'CA',
  },
  {
    symbol: 'CIGI-CT',
    name: 'Colliers International Group Inc',
    region: 'CA',
  },
  {
    symbol: 'CIO-CV',
    name: 'Central Iron Ore Limited',
    region: 'CA',
  },
  {
    symbol: 'CJ-CT',
    name: 'Cardinal Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'CJR.B-CT',
    name: 'Corus Entertainment, Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'CJT-CT',
    name: 'Cargojet Inc and Variable Voting Shares',
    region: 'CA',
  },
  {
    symbol: 'CK-CF',
    name: 'Cascadia Blockchain Group Corp',
    region: 'CA',
  },
  {
    symbol: 'CKG-CV',
    name: 'Chesapeake Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CKI-CT',
    name: 'Clarke Inc',
    region: 'CA',
  },
  {
    symbol: 'CKK-CV',
    name: 'Cordy Oilfield Services inc.',
    region: 'CA',
  },
  {
    symbol: 'CL-CF',
    name: 'Cresco Labs Inc. (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'CLAR-CF',
    name: 'Clarity Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CLAS-CV',
    name: 'Claritas Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'CLC-CF',
    name: 'Christina Lake Cannabis Corp',
    region: 'CA',
  },
  {
    symbol: 'CLI-CV',
    name: 'Clearford Water Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'CLS-CT',
    name: 'Celestica, Inc.',
    region: 'CA',
  },
  {
    symbol: 'CLSH-CF',
    name: 'CLS Holdings USA Inc',
    region: 'CA',
  },
  {
    symbol: 'CLV-CV',
    name: 'Canoe Mining Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'CLZ-CV',
    name: 'Canasil Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'CM-CT',
    name: 'Canadian Imperial Bank Of Commerce',
    region: 'CA',
  },
  {
    symbol: 'CMB-CV',
    name: 'CMC Metals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CMC-CV',
    name: 'Cielo Waste Solutions Corp',
    region: 'CA',
  },
  {
    symbol: 'CMD-CV',
    name: 'Commander Resources',
    region: 'CA',
  },
  {
    symbol: 'CME-CF',
    name: 'Canadian Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'CMG-CT',
    name: 'Computer Modelling Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'CMI-CV',
    name: 'C-Com Satellite Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'CMIL-CV',
    name: 'Capella Minerals Limited',
    region: 'CA',
  },
  {
    symbol: 'CML-CV',
    name: 'Canickel Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'CMMC-CT',
    name: 'Copper Mountain Mining Corporation',
    region: 'CA',
  },
  {
    symbol: 'CMND-CF',
    name: 'Clearmind Medicine Inc',
    region: 'CA',
  },
  {
    symbol: 'CMO.H-CV',
    name: 'Cliffmont Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'CMU-CV',
    name: 'Comet Industries Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CN-CV',
    name: 'Condor Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'CNC-CV',
    name: 'Canada Nickel Company Inc',
    region: 'CA',
  },
  {
    symbol: 'CNE-CT',
    name: 'Canacol Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'CNI-CF',
    name: 'Cloud Nine Web3 Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'CNL-CV',
    name: 'Collective Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'CNO-CV',
    name: 'California Nanotechnologies Corp',
    region: 'CA',
  },
  {
    symbol: 'CNQ-CT',
    name: 'Canadian Natural Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CNR-CT',
    name: 'Canadian National Railway Co.',
    region: 'CA',
  },
  {
    symbol: 'CNS-CV',
    name: 'Contagious Gaming Inc',
    region: 'CA',
  },
  {
    symbol: 'CNT-CT',
    name: 'Century Global Commodities Corp',
    region: 'CA',
  },
  {
    symbol: 'CNTR-CF',
    name: 'CENTR Brands Corp',
    region: 'CA',
  },
  {
    symbol: 'CNX-CV',
    name: 'Callinex Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'CO-CF',
    name: 'International Cobalt Corp',
    region: 'CA',
  },
  {
    symbol: 'COCO-CV',
    name: 'Coast Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'COG-CT',
    name: 'Condor Gold Plc',
    region: 'CA',
  },
  {
    symbol: 'COLL.P-CV',
    name: 'Collingwood Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'COO-CV',
    name: 'Ostrom Climate Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'COOL-CF',
    name: 'Core One Labs Inc',
    region: 'CA',
  },
  {
    symbol: 'COR-CV',
    name: 'Camino Minerals Corporation',
    region: 'CA',
  },
  {
    symbol: 'COV-CV',
    name: 'Covalon Technologies Ltd.',
    region: 'CA',
  },
  {
    symbol: 'COVE-CF',
    name: 'Cover Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'CP-CT',
    name: 'Canadian Pacific Railway Ltd',
    region: 'CA',
  },
  {
    symbol: 'CPAU-CV',
    name: 'CopAur Minerals Inc.',
    region: 'CA',
  },
  {
    symbol: 'CPER-CV',
    name: 'CopperCorp Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'CPG-CT',
    name: 'Crescent Point Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'CPH-CT',
    name: 'Cipher Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'CPI-CT',
    name: 'Condor Petroleum Inc',
    region: 'CA',
  },
  {
    symbol: 'CPL-CV',
    name: 'Copper Lake Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'CPLF-CT',
    name: 'Copperleaf Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'CPR-CV',
    name: 'Allied Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'CPS-CV',
    name: 'Canadian Premium Sand Inc.',
    region: 'CA',
  },
  {
    symbol: 'CPTR-CF',
    name: 'Captor Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'CPX-CT',
    name: 'Capital Power Corp',
    region: 'CA',
  },
  {
    symbol: 'CQR-CV',
    name: 'Conquest Resources Limited',
    region: 'CA',
  },
  {
    symbol: 'CR-CT',
    name: 'Crew Energy Inc.',
    region: 'CA',
  },
  {
    symbol: 'CRB-CV',
    name: 'Cariboo Rose Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'CRBK-CV',
    name: 'Carebook Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'CRCC-CF',
    name: 'Copland Road Capital Corp - New',
    region: 'CA',
  },
  {
    symbol: 'CRDL-CT',
    name: 'Cardiol Therapeutics Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'CRE-CV',
    name: 'Critical Elements Lithium Corporation',
    region: 'CA',
  },
  {
    symbol: 'CRES-CF',
    name: 'Crest Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'CRL-CF',
    name: 'Carl Data Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'CRON-CT',
    name: 'Cronos Group Inc',
    region: 'CA',
  },
  {
    symbol: 'CRP-CT',
    name: 'Ceres Global Ag Corp',
    region: 'CA',
  },
  {
    symbol: 'CRRX-CT',
    name: 'CareRx Corporation',
    region: 'CA',
  },
  {
    symbol: 'CRS-CF',
    name: 'Crestview Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'CRTS-CV',
    name: 'Cortus Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'CRUZ-CF',
    name: 'Cruz Battery Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'CRVC-CF',
    name: 'Cross River Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'CS-CT',
    name: 'Capstone Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'CSG.H-CV',
    name: 'Cosigo Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'CSL-CV',
    name: 'Comstock Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'CSM-CT',
    name: 'ClearStream Energy Services Inc',
    region: 'CA',
  },
  {
    symbol: 'CSO-CV',
    name: 'Corsa Coal Corp',
    region: 'CA',
  },
  {
    symbol: 'CSTR-CV',
    name: 'CryptoStar Corp',
    region: 'CA',
  },
  {
    symbol: 'CSU-CT',
    name: 'Constellation Software Inc',
    region: 'CA',
  },
  {
    symbol: 'CSW.A-CT',
    name: 'Corby Spirit and Wine Ltd - Class A',
    region: 'CA',
  },
  {
    symbol: 'CSW.B-CT',
    name: 'Corby Spirit and Wine Ltd - Class B',
    region: 'CA',
  },
  {
    symbol: 'CSX-CV',
    name: 'Clean Seed Capital Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'CTA-CV',
    name: 'Centaurus Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'CTC-CT',
    name: 'Canadian Tire Corp., Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CTC.A-CT',
    name: 'Canadian Tire Corp., Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'CTH-CV',
    name: 'CoTec Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'CTM-CV',
    name: 'Canterra Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'CTO-CV',
    name: 'Circa Enterprises Inc.',
    region: 'CA',
  },
  {
    symbol: 'CTOC-CF',
    name: 'C2C Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CTS-CT',
    name: 'Converge Technology Solutions Corp.',
    region: 'CA',
  },
  {
    symbol: 'CTX-CT',
    name: 'Crescita Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'CTZ-CV',
    name: 'NamSys Inc',
    region: 'CA',
  },
  {
    symbol: 'CU-CT',
    name: 'Canadian Utilities Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'CU.X-CT',
    name: 'Canadian Utilities Ltd. - Class B',
    region: 'CA',
  },
  {
    symbol: 'CUAU-CF',
    name: 'Forte Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'CUB-CT',
    name: 'CubicFarm Systems Corp',
    region: 'CA',
  },
  {
    symbol: 'CUCO-CV',
    name: 'African Energy Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'CUI-CV',
    name: 'Currie Rose Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'CULT-CF',
    name: 'Cult Food Science Corp',
    region: 'CA',
  },
  {
    symbol: 'CUR-CV',
    name: 'Consolidated Uranium Inc.',
    region: 'CA',
  },
  {
    symbol: 'CURA-CF',
    name: 'Curaleaf Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'CURE-CF',
    name: 'Biocure Technology Inc',
    region: 'CA',
  },
  {
    symbol: 'CUSN-CV',
    name: 'Cornish Metals Inc.',
    region: 'CA',
  },
  {
    symbol: 'CUU-CV',
    name: 'Copper Fox Metals Inc.',
    region: 'CA',
  },
  {
    symbol: 'CVB-CV',
    name: 'Compass Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'CVE-CT',
    name: 'Cenovus Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'CVGR-CF',
    name: 'City View Green Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'CVI.P-CV',
    name: 'Compass Venture Inc',
    region: 'CA',
  },
  {
    symbol: 'CVM-CV',
    name: 'CVR Medical Corp',
    region: 'CA',
  },
  {
    symbol: 'CVV-CV',
    name: 'Canalaska Uranium Ltd',
    region: 'CA',
  },
  {
    symbol: 'CVW-CV',
    name: 'CVW CleanTech Inc',
    region: 'CA',
  },
  {
    symbol: 'CVX-CV',
    name: 'Cematrix Corporation',
    region: 'CA',
  },
  {
    symbol: 'CWB-CT',
    name: 'Canadian Western Bank',
    region: 'CA',
  },
  {
    symbol: 'CWC-CV',
    name: 'CWC Energy Services Corp',
    region: 'CA',
  },
  {
    symbol: 'CWEB-CT',
    name: 'Charlottes Web Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'CWL-CT',
    name: 'Caldwell Partners International Inc.',
    region: 'CA',
  },
  {
    symbol: 'CWRK-CF',
    name: 'CurrencyWorks Inc',
    region: 'CA',
  },
  {
    symbol: 'CWV-CV',
    name: 'Crown Point Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'CXB-CT',
    name: 'Calibre Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'CXC-CF',
    name: 'CMX Gold & Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'CXXI-CF',
    name: 'C21 Investments Inc',
    region: 'CA',
  },
  {
    symbol: 'CYBN-QH',
    name: 'Cybin Inc',
    region: 'CA',
  },
  {
    symbol: 'CYF-CV',
    name: 'Canyon Creek Food Co., Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CYL-CV',
    name: 'Ceylon Graphite Corp',
    region: 'CA',
  },
  {
    symbol: 'CYM-CV',
    name: 'Cymat Technologies Ltd.',
    region: 'CA',
  },
  {
    symbol: 'CYP-CV',
    name: 'Cypress Development Corp.',
    region: 'CA',
  },
  {
    symbol: 'CZ.H-CV',
    name: 'Cassius Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'CZO-CV',
    name: 'Ceapro Inc.',
    region: 'CA',
  },
  {
    symbol: 'CZZ-CV',
    name: 'Cleghorn Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'DAC-CV',
    name: 'Datable Technology Corp.',
    region: 'CA',
  },
  {
    symbol: 'DAN-CV',
    name: 'Arianne Phosphate Inc',
    region: 'CA',
  },
  {
    symbol: 'DAR-CV',
    name: 'Darelle Online Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'DAT.H-CV',
    name: 'Datum Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'DAU-CV',
    name: 'Desert Gold Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'DB-CV',
    name: 'Decibel Cannabis Company Inc',
    region: 'CA',
  },
  {
    symbol: 'DBG-CV',
    name: 'Doubleview Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'DBM-CT',
    name: 'Doman Building Materials Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'DBO-CT',
    name: 'D-Box Technologies Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'DCBO-CT',
    name: 'Docebo Inc',
    region: 'CA',
  },
  {
    symbol: 'DCM-CT',
    name: 'DATA Communications Management Corp',
    region: 'CA',
  },
  {
    symbol: 'DCMC-CV',
    name: 'Dore Copper Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'DCOP-CV',
    name: 'District Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'DCSI-CF',
    name: 'Direct Communication Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'DCY-CV',
    name: 'Discovery-corp Enterprise Inc.',
    region: 'CA',
  },
  {
    symbol: 'DE-CV',
    name: 'Decisive Dividend Corp',
    region: 'CA',
  },
  {
    symbol: 'DEAL-CV',
    name: 'Playgon Games Inc.',
    region: 'CA',
  },
  {
    symbol: 'DEC-CV',
    name: 'Decade Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'DEEP-CF',
    name: 'DeepRock Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'DEF-CV',
    name: 'Defiance Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'DEFI-QH',
    name: 'DeFi Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'DEFN-CV',
    name: 'Defense Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'DELC-CF',
    name: 'Delic Holdings Corp (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'DELT-CF',
    name: 'Delta Cleantech Inc',
    region: 'CA',
  },
  {
    symbol: 'DELX-CV',
    name: 'Delphx Capital Markets Inc.',
    region: 'CA',
  },
  {
    symbol: 'DEX-CV',
    name: 'Almadex Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'DFY-CT',
    name: 'Definity Financial Corporation',
    region: 'CA',
  },
  {
    symbol: 'DG-CV',
    name: 'Dixie Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'DGHI-CV',
    name: 'Digihost Technology Inc (Sub voting)',
    region: 'CA',
  },
  {
    symbol: 'DGO-CV',
    name: 'Durango Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'DGTL-CV',
    name: 'DGTL Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'DHR-CV',
    name: 'Discovery Harbour Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'DIA-CV',
    name: 'Margaret Lake Diamonds Inc',
    region: 'CA',
  },
  {
    symbol: 'DIAM-CT',
    name: 'Star Diamond Corp',
    region: 'CA',
  },
  {
    symbol: 'DIG.H-CV',
    name: 'District Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'DIGI-CF',
    name: 'DigiMax Global Inc',
    region: 'CA',
  },
  {
    symbol: 'DII.A-CT',
    name: 'Dorel Industries Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'DII.B-CT',
    name: 'Dorel Industries Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'DIV-CT',
    name: 'Diversified Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'DKL-CV',
    name: 'Decklar Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'DLC-CV',
    name: 'DLC Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'DLCG-CT',
    name: 'Dominion Lending Centres Inc.',
    region: 'CA',
  },
  {
    symbol: 'DLP-CV',
    name: 'DLP Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'DLTA-CV',
    name: 'Delta Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'DLV.H-CV',
    name: 'DLV Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'DM-CV',
    name: 'DataMetrex AI Ltd',
    region: 'CA',
  },
  {
    symbol: 'DME-CV',
    name: 'Desert Mountain Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'DMGI-CV',
    name: 'DMG Blockchain Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'DMI-CV',
    name: 'Diamcor Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'DML-CT',
    name: 'Denison Mines Corp',
    region: 'CA',
  },
  {
    symbol: 'DMR-CV',
    name: 'Damara Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'DMT-CV',
    name: 'Small Pharma Inc',
    region: 'CA',
  },
  {
    symbol: 'DMX-CV',
    name: 'District Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'DN-CT',
    name: 'Delta 9 Cannabis Inc',
    region: 'CA',
  },
  {
    symbol: 'DND-CT',
    name: 'Dye & Durham Ltd',
    region: 'CA',
  },
  {
    symbol: 'DNG-CT',
    name: 'Dynacor Gold Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'DNT-CT',
    name: 'Candente Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'DNTL-CT',
    name: 'Dentalcorp Holdings Ltd (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'DOC-CV',
    name: 'CloudMD Software & Services Inc',
    region: 'CA',
  },
  {
    symbol: 'DOCT-CF',
    name: 'Beyond Medical Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'DOL-CT',
    name: 'Dollarama Inc',
    region: 'CA',
  },
  {
    symbol: 'DOO-CT',
    name: 'BRP Inc',
    region: 'CA',
  },
  {
    symbol: 'DOS-CV',
    name: 'Dios Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'DOSE-CF',
    name: 'Rapid Dose Therapeutics Corp',
    region: 'CA',
  },
  {
    symbol: 'DPM-CT',
    name: 'Dundee Precious Metals Inc.',
    region: 'CA',
  },
  {
    symbol: 'DPRO-CF',
    name: 'Draganfly Inc',
    region: 'CA',
  },
  {
    symbol: 'DR-CT',
    name: 'Medical Facilities Corp',
    region: 'CA',
  },
  {
    symbol: 'DRC.H-CV',
    name: 'Dragonfly Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'DRDR-CT',
    name: 'MCI Onehealth Technologies Inc - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'DRT-CT',
    name: 'DIRTT Environmental Solutions Ltd',
    region: 'CA',
  },
  {
    symbol: 'DRUG-CF',
    name: 'Bright Minds Biosciences Inc',
    region: 'CA',
  },
  {
    symbol: 'DRX-CT',
    name: 'ADF Group Inc',
    region: 'CA',
  },
  {
    symbol: 'DSAI-CF',
    name: 'DeepSpatial Inc',
    region: 'CA',
  },
  {
    symbol: 'DSG-CT',
    name: 'Descartes Systems Group Inc',
    region: 'CA',
  },
  {
    symbol: 'DSM-CV',
    name: 'Deep-South Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'DST-CF',
    name: 'Dundee Sustainable Technologies Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'DSV-CV',
    name: 'Discovery Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'DSY-CV',
    name: 'Destiny Media Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'DTC-CF',
    name: 'Defence Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'DTG-CV',
    name: 'Dynamic Technologies Group Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'DTOL-CT',
    name: 'D2L Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'DV-CV',
    name: 'Dolly Varden Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'DVG-CV',
    name: 'Divergent Energy Services Corp',
    region: 'CA',
  },
  {
    symbol: 'DVN-CF',
    name: 'Danavation Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'DVX.P-CV',
    name: 'Drummond Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'DWR-CF',
    name: 'Dominion Water Reserves Corp',
    region: 'CA',
  },
  {
    symbol: 'DWS-CV',
    name: 'Diamond Estates Wines & Spirits Inc',
    region: 'CA',
  },
  {
    symbol: 'DXI.H-CV',
    name: 'DXI Capital Corp.',
    region: 'CA',
  },
  {
    symbol: 'DXT-CT',
    name: 'Dexterra Group Inc',
    region: 'CA',
  },
  {
    symbol: 'DXX-CV',
    name: 'Dxstorm.com, Inc.',
    region: 'CA',
  },
  {
    symbol: 'DYA-CT',
    name: 'DynaCERT Inc',
    region: 'CA',
  },
  {
    symbol: 'DYG-CV',
    name: 'Dynasty Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'E-CT',
    name: 'Enterprise Group Inc',
    region: 'CA',
  },
  {
    symbol: 'EAC-CV',
    name: 'Earth Alive Clean Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'EAGR-CT',
    name: 'East Side Games Group',
    region: 'CA',
  },
  {
    symbol: 'EAM-CV',
    name: 'East Africa Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'EAP-CF',
    name: 'Euro Asia Pay Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'EAST-CV',
    name: 'EastWest Bioscience Inc.',
    region: 'CA',
  },
  {
    symbol: 'EAU-CV',
    name: 'Engineer Gold Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'EBM-CV',
    name: 'Eastwood Bio-Medical Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'ECM-CV',
    name: 'Ecolomondo Corp',
    region: 'CA',
  },
  {
    symbol: 'ECO-CT',
    name: 'EcoSynthetix Inc',
    region: 'CA',
  },
  {
    symbol: 'ECOM-CV',
    name: 'Emerge Commerce Ltd',
    region: 'CA',
  },
  {
    symbol: 'ECR-CV',
    name: 'Cartier Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ECU-CV',
    name: 'Element 29 Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'EDDY-CV',
    name: 'Edison Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'EDG-CV',
    name: 'Endurance Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'EDGM-CF',
    name: 'Edgemont Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'EDM-CV',
    name: 'EDM Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'EDR-CT',
    name: 'Endeavour Silver Corp.',
    region: 'CA',
  },
  {
    symbol: 'EDT-CT',
    name: 'Spectral Medical Inc',
    region: 'CA',
  },
  {
    symbol: 'EDV-CT',
    name: 'Endeavour Mining Plc',
    region: 'CA',
  },
  {
    symbol: 'EDW.H-CV',
    name: 'Edgewater Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'EEE-CF',
    name: 'EEE Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'EEE-CV',
    name: 'Esprit Exploration Ltd.',
    region: 'CA',
  },
  {
    symbol: 'EFL-CT',
    name: 'Electrovaya, Inc.',
    region: 'CA',
  },
  {
    symbol: 'EFR-CT',
    name: 'Energy Fuels Inc',
    region: 'CA',
  },
  {
    symbol: 'EFV-CV',
    name: 'EF EnergyFunders Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'EFX-CT',
    name: 'Enerflex Ltd',
    region: 'CA',
  },
  {
    symbol: 'EGA-CV',
    name: 'Eagle Graphite Inc',
    region: 'CA',
  },
  {
    symbol: 'EGFV-CF',
    name: 'Evolution Global Frontier Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'EGLX-CT',
    name: 'Enthusiast Gaming Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'EGM-CV',
    name: 'Engold Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'EGT-CV',
    name: 'Eguana Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'EI-CF',
    name: 'Eurogas International Inc',
    region: 'CA',
  },
  {
    symbol: 'EIF-CT',
    name: 'Exchange Income Corp',
    region: 'CA',
  },
  {
    symbol: 'EKG-CV',
    name: 'CardioComm Solutions, Inc',
    region: 'CA',
  },
  {
    symbol: 'ELBM-CV',
    name: 'Electra Battery Materials Corp',
    region: 'CA',
  },
  {
    symbol: 'ELC-CV',
    name: 'Elysee Development Corp.',
    region: 'CA',
  },
  {
    symbol: 'ELD-CT',
    name: 'Eldorado Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'ELE-CV',
    name: 'Elemental Royalties Corp.',
    region: 'CA',
  },
  {
    symbol: 'ELEC-CV',
    name: 'Electric Royalties Ltd',
    region: 'CA',
  },
  {
    symbol: 'ELEF-CT',
    name: 'Silver Elephant Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ELEM-CF',
    name: 'Element79 Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ELF-CT',
    name: 'E-L Financial Corporation Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ELM-CV',
    name: 'Element Lifestyle Retirement Inc',
    region: 'CA',
  },
  {
    symbol: 'ELMT-CF',
    name: 'Element Nutritional Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'ELN-CV',
    name: 'El Nino Ventures Inc.',
    region: 'CA',
  },
  {
    symbol: 'ELO-CV',
    name: 'Eloro Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ELR-CT',
    name: 'Eastern Platinum Ltd',
    region: 'CA',
  },
  {
    symbol: 'ELVN-CF',
    name: '1111 Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'ELVT-CV',
    name: 'Elevation Gold Mining Corporation',
    region: 'CA',
  },
  {
    symbol: 'ELXR-CV',
    name: 'Elixxer Ltd',
    region: 'CA',
  },
  {
    symbol: 'EMA-CT',
    name: 'Emera Inc.',
    region: 'CA',
  },
  {
    symbol: 'EMET-CF',
    name: 'Canamera Energy Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'EMH-CF',
    name: 'Emerald Health Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'EMM-CV',
    name: 'Giyani Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'EMN-CV',
    name: 'Euro Manganese Inc',
    region: 'CA',
  },
  {
    symbol: 'EMNT-CV',
    name: 'Eminent Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'EMO-CV',
    name: 'Emerita Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'EMP.A-CT',
    name: 'Empire Co., Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'EMPH-CF',
    name: 'Empatho Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'EMPR-CV',
    name: 'Empress Royalty Corp.',
    region: 'CA',
  },
  {
    symbol: 'EMPS-CF',
    name: 'EMP Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'EMR-CV',
    name: 'Emergent Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'EMRG-CF',
    name: 'Emergence Global Enterprises Inc',
    region: 'CA',
  },
  {
    symbol: 'EMX-CV',
    name: 'EMX Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'ENA-CV',
    name: 'Enablence Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ENB-CT',
    name: 'Enbridge Inc',
    region: 'CA',
  },
  {
    symbol: 'ENBI-CF',
    name: 'Entheon Biomedical Corp',
    region: 'CA',
  },
  {
    symbol: 'ENDR-CV',
    name: 'Enduro Metals Corporation',
    region: 'CA',
  },
  {
    symbol: 'ENEV-CV',
    name: 'Enerev5 Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'ENGH-CT',
    name: 'Enghouse Systems Ltd',
    region: 'CA',
  },
  {
    symbol: 'ENRG-CV',
    name: 'New Energy Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'ENTG-CV',
    name: 'Entourage Health Corp',
    region: 'CA',
  },
  {
    symbol: 'ENW-CV',
    name: 'Enwave Corp.',
    region: 'CA',
  },
  {
    symbol: 'EOG-CV',
    name: 'Eco (Atlantic) Oil & Gas Ltd',
    region: 'CA',
  },
  {
    symbol: 'EOM-CF',
    name: 'Eco Oro Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'EONX-CF',
    name: 'Eonx Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'EOX-CT',
    name: 'Euromax Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'EP-CV',
    name: 'Empire Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'EPIC-CF',
    name: 'Leviathan Natural Products Inc',
    region: 'CA',
  },
  {
    symbol: 'EPL-CV',
    name: 'Eagle Plains Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'EPO.H-CV',
    name: 'Encanto Potash Corp',
    region: 'CA',
  },
  {
    symbol: 'EPRX-CT',
    name: 'Eupraxia Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'EPY-CF',
    name: 'ePlay Digital Inc',
    region: 'CA',
  },
  {
    symbol: 'EQ-CV',
    name: 'EQ Inc',
    region: 'CA',
  },
  {
    symbol: 'EQB-CT',
    name: 'Equitable Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'EQTY-CV',
    name: 'Equity Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'EQX-CT',
    name: 'Equinox Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ERA-CV',
    name: 'Elcora Advanced Materials Corp',
    region: 'CA',
  },
  {
    symbol: 'ERC-CV',
    name: 'Eros Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'ERD-CT',
    name: 'Erdene Resource Development Corp',
    region: 'CA',
  },
  {
    symbol: 'ERF-CT',
    name: 'Enerplus Corporation',
    region: 'CA',
  },
  {
    symbol: 'ERO-CT',
    name: 'Ero Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'ERTH-CF',
    name: 'EarthRenew Inc',
    region: 'CA',
  },
  {
    symbol: 'ESE-CV',
    name: 'ESE Entertainment Inc.',
    region: 'CA',
  },
  {
    symbol: 'ESI-CT',
    name: 'Ensign Energy Services Inc',
    region: 'CA',
  },
  {
    symbol: 'ESK-CV',
    name: 'Eskay Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ESM-CT',
    name: 'Euro Sun Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'ESN-CT',
    name: 'Essential Energy Services Ltd',
    region: 'CA',
  },
  {
    symbol: 'ESNR-CF',
    name: 'E79 Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ESX-CV',
    name: 'Essex Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'ET-CT',
    name: 'Evertz Technologies Limited',
    region: 'CA',
  },
  {
    symbol: 'ETF-CV',
    name: 'Eastfield Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ETG-CT',
    name: 'Entree Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ETHC-QH',
    name: 'Ether Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'ETI-CF',
    name: 'EnviroMetal Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ETMC-CV',
    name: 'E3 Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'ETR-CF',
    name: 'Etruscus Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ETU-CV',
    name: 'E2Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'EU-CV',
    name: 'enCore Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'EV-CV',
    name: 'Erin Ventures Inc.',
    region: 'CA',
  },
  {
    symbol: 'EVER-CV',
    name: 'Evergold Corp',
    region: 'CA',
  },
  {
    symbol: 'EVG-CF',
    name: 'Evolving Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'EVGN-CV',
    name: 'EverGen Infrastructure Corp',
    region: 'CA',
  },
  {
    symbol: 'EVX-CV',
    name: 'European Electric Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'EW-CV',
    name: 'East West Petroleum Corp',
    region: 'CA',
  },
  {
    symbol: 'EWG-CF',
    name: 'Eat Well Investment Group Inc',
    region: 'CA',
  },
  {
    symbol: 'EWK-CV',
    name: 'Earthworks Industries Inc.',
    region: 'CA',
  },
  {
    symbol: 'EWS-CV',
    name: 'Environmental Waste International Inc.',
    region: 'CA',
  },
  {
    symbol: 'EXE-CT',
    name: 'Extendicare Inc',
    region: 'CA',
  },
  {
    symbol: 'EXG-CV',
    name: 'ExGen Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'EXN-CT',
    name: 'Excellon Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'EXRO-CT',
    name: 'Exro Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'FABL-CF',
    name: 'Fabled Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'FAF-CT',
    name: 'Fire & Flower Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'FAIR-CV',
    name: 'Fairchild Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FANS-CF',
    name: 'Fansunite Entertainment Inc',
    region: 'CA',
  },
  {
    symbol: 'FAR-CT',
    name: 'Foraco International SA.',
    region: 'CA',
  },
  {
    symbol: 'FARM-CV',
    name: 'Deveron Corp',
    region: 'CA',
  },
  {
    symbol: 'FAT-CF',
    name: 'Foremost Lithium Resource & Technology Ltd',
    region: 'CA',
  },
  {
    symbol: 'FBF-CV',
    name: 'Fab Form Industries Ltd',
    region: 'CA',
  },
  {
    symbol: 'FBR.H-CV',
    name: 'Fibre-Crown Manufacturing Inc.',
    region: 'CA',
  },
  {
    symbol: 'FCO-CV',
    name: 'Fabled Silver Gold Corp. - Class A',
    region: 'CA',
  },
  {
    symbol: 'FCU-CT',
    name: 'Fission Uranium Corp',
    region: 'CA',
  },
  {
    symbol: 'FD-CV',
    name: 'Facedrive Inc',
    region: 'CA',
  },
  {
    symbol: 'FDGE-CT',
    name: 'Farmers Edge Inc',
    region: 'CA',
  },
  {
    symbol: 'FDI-CV',
    name: 'FinDev Inc',
    region: 'CA',
  },
  {
    symbol: 'FDM-CF',
    name: 'Fandifi Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'FDR-CV',
    name: 'Founders Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'FDY-CF',
    name: 'Faraday Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'FE-CF',
    name: 'First Energy Metals Limited',
    region: 'CA',
  },
  {
    symbol: 'FEC-CT',
    name: 'Frontera Energy Corporation New',
    region: 'CA',
  },
  {
    symbol: 'FEEL-CF',
    name: 'Feel Foods Ltd',
    region: 'CA',
  },
  {
    symbol: 'FENX-CF',
    name: 'FenixOro Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FEO-CV',
    name: 'Oceanic Iron Ore Corp',
    region: 'CA',
  },
  {
    symbol: 'FEX-CV',
    name: 'Fjordland Exploration, Inc.',
    region: 'CA',
  },
  {
    symbol: 'FF-CT',
    name: 'First Mining Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FFF-CF',
    name: '55 North Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'FFH-CT',
    name: 'Fairfax Financial Holdings, Ltd.',
    region: 'CA',
  },
  {
    symbol: 'FFNT-CF',
    name: '4Front Ventures Corp (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'FFOX-CV',
    name: 'FireFox Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FFP-CV',
    name: 'Consolidated Firstfund Capital',
    region: 'CA',
  },
  {
    symbol: 'FG-CV',
    name: 'Falcon Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FGC-CV',
    name: 'Frontline Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FGFL-CF',
    name: 'First Growth Funds Limited',
    region: 'CA',
  },
  {
    symbol: 'FI.H-CV',
    name: 'First Idaho Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'FIL-CT',
    name: 'Filo Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'FIND-CV',
    name: 'Baselode Energy Corp.',
    region: 'CA',
  },
  {
    symbol: 'FIOR-CF',
    name: 'Fiore Cannabis Ltd',
    region: 'CA',
  },
  {
    symbol: 'FISH-CV',
    name: 'Sailfish Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'FIT-CF',
    name: 'Koios Beverage Corp',
    region: 'CA',
  },
  {
    symbol: 'FKM-CV',
    name: 'Fokus Mining Corporation',
    region: 'CA',
  },
  {
    symbol: 'FL-CV',
    name: 'Frontier Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'FLT-CV',
    name: 'Drone Delivery Canada Corp',
    region: 'CA',
  },
  {
    symbol: 'FLWR-CV',
    name: 'Flowr Corp. (The)',
    region: 'CA',
  },
  {
    symbol: 'FLY-CV',
    name: 'FLYHT Aerospace Solutions Ltd',
    region: 'CA',
  },
  {
    symbol: 'FLYY-CF',
    name: 'Media Central Corporation Inc',
    region: 'CA',
  },
  {
    symbol: 'FM-CT',
    name: 'First Quantum Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'FMAN-CV',
    name: 'Freeman Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'FMC-CV',
    name: 'Forum Energy Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'FMM-CV',
    name: 'Full Metal Minerals Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'FMN-CV',
    name: 'Fidelity Minerals Corp.',
    region: 'CA',
  },
  {
    symbol: 'FMS-CV',
    name: 'Focus Graphite Inc',
    region: 'CA',
  },
  {
    symbol: 'FNAU-CF',
    name: 'Four Nines Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'FNC-CV',
    name: 'Fancamp Exploration Ltd.',
    region: 'CA',
  },
  {
    symbol: 'FNI-CF',
    name: 'Fathom Nickel Inc',
    region: 'CA',
  },
  {
    symbol: 'FNR-CV',
    name: '49 North Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'FNV-CT',
    name: 'Franco-Nevada Corporation',
    region: 'CA',
  },
  {
    symbol: 'FO-CV',
    name: 'Falcon Oil & Gas Ltd',
    region: 'CA',
  },
  {
    symbol: 'FOBI-CV',
    name: 'Fobi Ai Inc',
    region: 'CA',
  },
  {
    symbol: 'FOM-CV',
    name: 'Foran Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'FONE-CF',
    name: 'Flower One Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'FOOD-CT',
    name: 'Goodfood Market Corp',
    region: 'CA',
  },
  {
    symbol: 'FOR-CV',
    name: 'Fortune Bay Corp',
    region: 'CA',
  },
  {
    symbol: 'FORZ-CT',
    name: 'Forza Petroleum Limited',
    region: 'CA',
  },
  {
    symbol: 'FOXG-CV',
    name: 'Royal Fox Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'FP-CV',
    name: 'FP Newspapers Inc',
    region: 'CA',
  },
  {
    symbol: 'FPX-CV',
    name: 'FPX Nickel Corp',
    region: 'CA',
  },
  {
    symbol: 'FR-CT',
    name: 'First Majestic Silver Corporation',
    region: 'CA',
  },
  {
    symbol: 'FRE-CV',
    name: 'Fremont Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'FRI-CV',
    name: 'Freeport Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'FRII-CT',
    name: 'Freshii Inc - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'FRS.P-CV',
    name: 'Farstarcap Investment Corp',
    region: 'CA',
  },
  {
    symbol: 'FRU-CT',
    name: 'Freehold Royalties Ltd',
    region: 'CA',
  },
  {
    symbol: 'FRX-CT',
    name: 'Fennec Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'FSX-CV',
    name: 'Fosterville South Exploration Ltd (New)',
    region: 'CA',
  },
  {
    symbol: 'FSY-CT',
    name: 'Forsys Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'FSZ-CT',
    name: 'Fiera Capital Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'FT-CT',
    name: 'Fortune Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'FTEC-CV',
    name: 'Fintech Select Ltd',
    region: 'CA',
  },
  {
    symbol: 'FTEL-CF',
    name: 'First Tellurium Corp',
    region: 'CA',
  },
  {
    symbol: 'FTG-CT',
    name: 'Firan Technology Group Corp.',
    region: 'CA',
  },
  {
    symbol: 'FTI-CV',
    name: 'FTI Foodtech International Inc.',
    region: 'CA',
  },
  {
    symbol: 'FTJ-CV',
    name: 'Fort St. James Nickel Corp',
    region: 'CA',
  },
  {
    symbol: 'FTRP-CT',
    name: 'Field Trip Health Ltd.',
    region: 'CA',
  },
  {
    symbol: 'FTS-CT',
    name: 'Fortis Inc.',
    region: 'CA',
  },
  {
    symbol: 'FTT-CT',
    name: 'Finning International Inc.',
    region: 'CA',
  },
  {
    symbol: 'FUEL-CF',
    name: 'PlantFuel Life Inc',
    region: 'CA',
  },
  {
    symbol: 'FUND-CV',
    name: 'Katipult Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'FURY-CT',
    name: 'Fury Gold Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'FUSE-CV',
    name: 'Fuse Cobalt Inc',
    region: 'CA',
  },
  {
    symbol: 'FUTR-CF',
    name: 'Gold n Futures Mineral Corp',
    region: 'CA',
  },
  {
    symbol: 'FUU-CV',
    name: 'Fission 3.0 Corp',
    region: 'CA',
  },
  {
    symbol: 'FUZN-CF',
    name: 'High Fusion Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'FV.H-CV',
    name: 'Firestone Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'FVI-CT',
    name: 'Fortuna Silver Mines Inc.',
    region: 'CA',
  },
  {
    symbol: 'FVL-CT',
    name: 'Freegold Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'FWM-CF',
    name: 'Flow Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'FWZ-CV',
    name: 'Fireweed Zinc Ltd',
    region: 'CA',
  },
  {
    symbol: 'FYL-CV',
    name: 'Finlay Minerals',
    region: 'CA',
  },
  {
    symbol: 'G-CT',
    name: 'Augusta Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'GA-CV',
    name: 'General Assembly Holdings Limited - Class A',
    region: 'CA',
  },
  {
    symbol: 'GABY-CF',
    name: 'Gaby Inc',
    region: 'CA',
  },
  {
    symbol: 'GAIA-CF',
    name: 'Gaia Grow Corp',
    region: 'CA',
  },
  {
    symbol: 'GAL-CV',
    name: 'Galantas Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GAME-CV',
    name: 'Engine Gaming and Media Inc',
    region: 'CA',
  },
  {
    symbol: 'GAR.H-CV',
    name: 'Green Arrow Resources Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'GASX-CV',
    name: 'NG Energy International Corp.',
    region: 'CA',
  },
  {
    symbol: 'GATO-CT',
    name: 'Gatos Silver Inc',
    region: 'CA',
  },
  {
    symbol: 'GAU-CT',
    name: 'Galiano Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'GB-CV',
    name: 'Ginger Beef Corp',
    region: 'CA',
  },
  {
    symbol: 'GBAR-CT',
    name: 'Monarch Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'GBLC-CF',
    name: 'Graph Blockchain Inc',
    region: 'CA',
  },
  {
    symbol: 'GBLT-CV',
    name: 'GBLT Corp.',
    region: 'CA',
  },
  {
    symbol: 'GBMC-CF',
    name: 'Green Block Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'GBML-CV',
    name: 'Global Battery Metals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GBRC-CV',
    name: 'Gold Bull Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'GBRR-CV',
    name: 'Great Bear Royalties Corp',
    region: 'CA',
  },
  {
    symbol: 'GBT-CT',
    name: 'BMTC Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'GBU-CV',
    name: 'Gabriel Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GCA.X-CF',
    name: 'Gencan Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'GCC-CV',
    name: 'Golden Cariboo Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GCCC-CV',
    name: 'GCC Global Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'GCIT.X-CF',
    name: 'Glorious Creation Ltd',
    region: 'CA',
  },
  {
    symbol: 'GCL-CT',
    name: 'Colabor Group Inc',
    region: 'CA',
  },
  {
    symbol: 'GCM-CT',
    name: 'GCM Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'GCN-CV',
    name: 'Goldcliff Resource Corp.',
    region: 'CA',
  },
  {
    symbol: 'GCP-CF',
    name: 'Golcap Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'GCX-CV',
    name: 'Granite Creek Copper Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GDBY-CF',
    name: 'Goodbody Health Inc',
    region: 'CA',
  },
  {
    symbol: 'GDI-CT',
    name: 'GDI Integrated Facility Services Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'GDL-CT',
    name: 'Goodfellow, Inc.',
    region: 'CA',
  },
  {
    symbol: 'GDM-CV',
    name: 'Goldstar Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'GDNP-CV',
    name: 'Good natured Products Inc',
    region: 'CA',
  },
  {
    symbol: 'GDNS-CF',
    name: 'Goodness Growth Holdings Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'GDP-CV',
    name: 'Golden Pursuit Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GDX-CV',
    name: 'Goldex Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'GEI-CT',
    name: 'Gibson Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'GEL-CV',
    name: 'Graphano Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'GEM-CV',
    name: 'Green Battery Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'GEMC-CV',
    name: 'Global Energy Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'GEMS-CF',
    name: 'Infinity Stone Ventures Corp - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'GENI-CV',
    name: 'Genius Metals Inc.',
    region: 'CA',
  },
  {
    symbol: 'GENM-CT',
    name: 'Generation Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'GENX-CV',
    name: 'Genix Pharmaceuticals Corp',
    region: 'CA',
  },
  {
    symbol: 'GEO-CT',
    name: 'Geodrill Ltd',
    region: 'CA',
  },
  {
    symbol: 'GER-CV',
    name: 'Glen Eagle Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GET-CF',
    name: 'GameOn Entertainment Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'GETT-CV',
    name: 'G.E.T.T. Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'GFCO-CF',
    name: 'Good Flour Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'GFG-CV',
    name: 'GFG Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GFI-CF',
    name: 'Gravitas Financial Inc',
    region: 'CA',
  },
  {
    symbol: 'GFL-CT',
    name: 'GFL Environmental Inc. (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'GFP-CT',
    name: 'GreenFirst Forest Products Inc',
    region: 'CA',
  },
  {
    symbol: 'GG-CV',
    name: 'Galane Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'GGA-CT',
    name: 'Goldgroup Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'GGAU-CV',
    name: 'Gambier Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GGC-CF',
    name: 'Generic Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GGD-CT',
    name: 'Gogold Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GGG-CV',
    name: 'G6 Materials Corp',
    region: 'CA',
  },
  {
    symbol: 'GGI-CV',
    name: 'Garibaldi Resources Corporation.',
    region: 'CA',
  },
  {
    symbol: 'GGL-CV',
    name: 'GGL Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'GGLD-CF',
    name: 'General Copper Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GGM-CV',
    name: 'Granada Gold Mine Inc',
    region: 'CA',
  },
  {
    symbol: 'GGO-CV',
    name: 'Galleon Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GGX-CV',
    name: 'GGX Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GH-CT',
    name: 'Gamehost Inc',
    region: 'CA',
  },
  {
    symbol: 'GHG-CF',
    name: 'Global Hemp Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'GHL-CV',
    name: 'Goldhills Holding Ltd',
    region: 'CA',
  },
  {
    symbol: 'GHR-CV',
    name: 'Golden Harp Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GIB.A-CT',
    name: 'CGI Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'GIGA-CV',
    name: 'Giga Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'GIII-CV',
    name: 'ReGen III Corp',
    region: 'CA',
  },
  {
    symbol: 'GIL-CT',
    name: 'Gildan Activewear Inc',
    region: 'CA',
  },
  {
    symbol: 'GIP-CV',
    name: 'Green Impact Partners Inc',
    region: 'CA',
  },
  {
    symbol: 'GIS-CV',
    name: 'Genesis Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'GIT-CV',
    name: 'Gitennes Exploration Inc.',
    region: 'CA',
  },
  {
    symbol: 'GIVX-CT',
    name: 'Givex Information Technology Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'GKO-CV',
    name: 'Geekco Technologies Corporation',
    region: 'CA',
  },
  {
    symbol: 'GL-CF',
    name: 'Gold Lion Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GLAB-CF',
    name: 'Gemina Laboratories Ltd',
    region: 'CA',
  },
  {
    symbol: 'GLB-CV',
    name: 'Goldbank Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'GLD-CV',
    name: 'GoldON Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'GLDC-CV',
    name: 'Cassiar Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GLDL-CV',
    name: 'Gold Line Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GLDN-CV',
    name: 'Golden Ridge Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'GLG-CT',
    name: 'GLG Life Tech Corporation',
    region: 'CA',
  },
  {
    symbol: 'GLI-CV',
    name: 'Glacier Lake Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GLL-CF',
    name: 'Gallagher Security Corp',
    region: 'CA',
  },
  {
    symbol: 'GLM-CF',
    name: 'Golden Lake Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'GLO-CT',
    name: 'Global Atomic Corp',
    region: 'CA',
  },
  {
    symbol: 'GLOW-CF',
    name: 'Glow LifeTech Corp',
    region: 'CA',
  },
  {
    symbol: 'GLW-CV',
    name: 'Galway Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'GLXY-CT',
    name: 'Galaxy Digital Holdings Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GMA-CV',
    name: 'Geomega Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GMG-CV',
    name: 'Graphene Manufacturing Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'GMIN-CV',
    name: 'G Mining Ventures Corp.',
    region: 'CA',
  },
  {
    symbol: 'GMN-CV',
    name: 'GobiMin Inc.',
    region: 'CA',
  },
  {
    symbol: 'GMTN-CT',
    name: 'Gold Mountain Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'GMV-CV',
    name: 'GMV Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'GMX-CT',
    name: 'Globex Mining Enterprises, Inc.',
    region: 'CA',
  },
  {
    symbol: 'GNG-CV',
    name: 'Golden Goliath Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GNOM-CF',
    name: 'Gnomestar Craft Inc',
    region: 'CA',
  },
  {
    symbol: 'GNT-CV',
    name: 'Gentor Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GOAT-CF',
    name: 'Billy Goat Brands Ltd',
    region: 'CA',
  },
  {
    symbol: 'GOCO-CF',
    name: 'Go Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'GOE-CV',
    name: 'Goldeneye Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'GOFL-CV',
    name: 'Goldflare Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'GOG-CV',
    name: 'Golden Tag Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GOH-CF',
    name: 'GoldHaven Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'GOK-CV',
    name: 'Ginsms Inc',
    region: 'CA',
  },
  {
    symbol: 'GOLD-CT',
    name: 'GoldMining Inc',
    region: 'CA',
  },
  {
    symbol: 'GOM-CV',
    name: 'Golden Dawn Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'GOOS-CT',
    name: 'Canada Goose Holdings Inc (Subord Vot Shs)',
    region: 'CA',
  },
  {
    symbol: 'GOP.H-CV',
    name: 'Gourmet Ocean Products Inc.',
    region: 'CA',
  },
  {
    symbol: 'GOR-CF',
    name: 'Goldrea Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'GOST-CV',
    name: 'Gold State Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GOT-CV',
    name: 'Goliath Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'GPG-CV',
    name: 'Grande Portage Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GPH-CV',
    name: 'Graphite One Inc',
    region: 'CA',
  },
  {
    symbol: 'GPM-CV',
    name: 'GPM Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'GPO-CF',
    name: 'Gold Port Corporation',
    region: 'CA',
  },
  {
    symbol: 'GPR-CT',
    name: 'Great Panther Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'GPV-CV',
    name: 'GreenPower Motor Company Inc',
    region: 'CA',
  },
  {
    symbol: 'GQ-CV',
    name: 'Great Quest Fertilizer Ltd',
    region: 'CA',
  },
  {
    symbol: 'GQC-CV',
    name: 'Goldquest Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'GR-CV',
    name: 'Great Atlantic Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'GRA-CT',
    name: 'NanoXplore Inc',
    region: 'CA',
  },
  {
    symbol: 'GRAT-CV',
    name: 'Gratomic Inc',
    region: 'CA',
  },
  {
    symbol: 'GRAY-CF',
    name: 'Graycliff Exploration Limited',
    region: 'CA',
  },
  {
    symbol: 'GRC-CT',
    name: 'Gold Springs Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'GRDM-CV',
    name: 'Grid Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'GRF-CV',
    name: 'Green Rise Foods Inc.',
    region: 'CA',
  },
  {
    symbol: 'GRG-CV',
    name: 'Golden Arrow Resources Corp. -New',
    region: 'CA',
  },
  {
    symbol: 'GRHK-CF',
    name: 'Greenhawk Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GRI-CV',
    name: 'Galore Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GRID-CT',
    name: 'Tantalus Systems Holding Inc',
    region: 'CA',
  },
  {
    symbol: 'GRIN-CF',
    name: 'Grown Rogue International Inc',
    region: 'CA',
  },
  {
    symbol: 'GRN-CT',
    name: 'Greenlane Renewables Inc',
    region: 'CA',
  },
  {
    symbol: 'GROW-CV',
    name: 'CO2 Gro Inc.',
    region: 'CA',
  },
  {
    symbol: 'GRSL-CV',
    name: 'Gr Silver Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'GRZ-CV',
    name: 'Gold Reserve Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'GSD-CV',
    name: 'Devonian Health Group Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'GSH-CV',
    name: 'Golden Share Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'GSHR-CV',
    name: 'Goldshore Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GSI-CV',
    name: 'Gatekeeper Systems Inc.',
    region: 'CA',
  },
  {
    symbol: 'GSK-CF',
    name: 'Goldseek Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GSP-CV',
    name: 'Gensource Potash Corp',
    region: 'CA',
  },
  {
    symbol: 'GSPR-CV',
    name: 'GSP Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'GSQ-CF',
    name: 'Gamesquare Esports Inc',
    region: 'CA',
  },
  {
    symbol: 'GSRI-CF',
    name: 'Golden Shield Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GSS-CV',
    name: 'Gossan Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'GSV-CT',
    name: 'Gold Standard Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'GSVR-CV',
    name: 'Guanajuato Silver Company Ltd',
    region: 'CA',
  },
  {
    symbol: 'GSW-QH',
    name: 'GetSwift Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'GSY-CT',
    name: 'Goeasy Ltd',
    region: 'CA',
  },
  {
    symbol: 'GTC-CV',
    name: 'Getty Copper Inc',
    region: 'CA',
  },
  {
    symbol: 'GTCH-CF',
    name: 'Getchell Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'GTE-CT',
    name: 'Gran Tierra Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'GTII-CF',
    name: 'Green Thumb Industries Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'GTMS-CT',
    name: 'Greenbrook TMS Inc',
    region: 'CA',
  },
  {
    symbol: 'GTOO-CF',
    name: 'G2 Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'GTR-CV',
    name: 'Gatling Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'GTWO-CV',
    name: 'G2 Goldfields Inc',
    region: 'CA',
  },
  {
    symbol: 'GUD-CT',
    name: 'Knight Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'GUG-CV',
    name: 'Gungnir Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GUN-CV',
    name: 'Gunpoint Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'GURU-CT',
    name: 'GURU Organic Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'GUS-CV',
    name: 'Angus Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'GVC-CT',
    name: 'Glacier Media Inc',
    region: 'CA',
  },
  {
    symbol: 'GVR-CV',
    name: 'Grosvenor Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'GWA-CV',
    name: 'Gowest Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'GWM-CV',
    name: 'Galway Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'GWO-CT',
    name: 'Great-West Lifeco Inc.',
    region: 'CA',
  },
  {
    symbol: 'GWR-CT',
    name: 'Global Water Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'GWS-CF',
    name: 'Global Wellness Strategies Inc',
    region: 'CA',
  },
  {
    symbol: 'GX-CV',
    name: 'Guardian Exploration, Inc.',
    region: 'CA',
  },
  {
    symbol: 'GXE-CT',
    name: 'Gear Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'GXU-CV',
    name: 'GoviEx Uranium Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'GXX-CV',
    name: 'Gold Basin Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'GYM-CV',
    name: 'TUT Fitness Group Inc',
    region: 'CA',
  },
  {
    symbol: 'GZD-CV',
    name: 'Grizzly Discoveries Inc',
    region: 'CA',
  },
  {
    symbol: 'H-CT',
    name: 'Hydro One Ltd',
    region: 'CA',
  },
  {
    symbol: 'HAI-CT',
    name: 'Haivision Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'HAKK.P-CV',
    name: 'Hakken Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'HALO-QH',
    name: 'Halo Collective Inc',
    region: 'CA',
  },
  {
    symbol: 'HAM-CV',
    name: 'Highwood Asset Management Ltd',
    region: 'CA',
  },
  {
    symbol: 'HAMR-CF',
    name: 'Silver Hammer Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'HAN-CV',
    name: 'Hannan Metals ltd',
    region: 'CA',
  },
  {
    symbol: 'HANS-CV',
    name: 'Hanstone Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'HAPB-CV',
    name: 'HAPBEE TECHNOLOGIES INC.',
    region: 'CA',
  },
  {
    symbol: 'HAR-CV',
    name: 'Harfang Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'HARY-CF',
    name: 'Harrys Manufacturing Inc',
    region: 'CA',
  },
  {
    symbol: 'HAVN-CF',
    name: 'HAVN Life Sciences Inc.',
    region: 'CA',
  },
  {
    symbol: 'HAWK-CV',
    name: 'Hawkeye Gold and Diamond Inc',
    region: 'CA',
  },
  {
    symbol: 'HBK-CV',
    name: 'Highbank Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'HBM-CT',
    name: 'Hudbay Minerals Inc.',
    region: 'CA',
  },
  {
    symbol: 'HBOR-CF',
    name: 'Harborside Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'HBP-CT',
    name: 'Helix Biopharma Corp.',
    region: 'CA',
  },
  {
    symbol: 'HCC-CV',
    name: 'Hanna Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'HCH-CV',
    name: 'Hot Chili Limited',
    region: 'CA',
  },
  {
    symbol: 'HDI-CT',
    name: 'Hardwoods Distribution Inc',
    region: 'CA',
  },
  {
    symbol: 'HE-CT',
    name: 'Hanwei Energy Services Corp',
    region: 'CA',
  },
  {
    symbol: 'HEAT-CF',
    name: 'Hillcrest Energy Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'HECO-CF',
    name: 'Global Helium Corp',
    region: 'CA',
  },
  {
    symbol: 'HELI-CV',
    name: 'First Helium Inc',
    region: 'CA',
  },
  {
    symbol: 'HEM-CV',
    name: 'Hemostemix Inc',
    region: 'CA',
  },
  {
    symbol: 'HEO-CT',
    name: 'H2O Innovation Inc',
    region: 'CA',
  },
  {
    symbol: 'HEXO-CT',
    name: 'HEXO Corp',
    region: 'CA',
  },
  {
    symbol: 'HFC-CV',
    name: 'Hampton Financial Corp',
    region: 'CA',
  },
  {
    symbol: 'HFH-CF',
    name: 'Hemp for Health Inc',
    region: 'CA',
  },
  {
    symbol: 'HI-CV',
    name: 'Highland Copper Company Inc',
    region: 'CA',
  },
  {
    symbol: 'HIGH-CV',
    name: 'HighGold Mining Inc.',
    region: 'CA',
  },
  {
    symbol: 'HIH-CV',
    name: 'Hylands International Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'HILL-CV',
    name: 'Hill Street Beverage Company Inc',
    region: 'CA',
  },
  {
    symbol: 'HIRE-CV',
    name: 'Hire Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'HITI-CV',
    name: 'High Tide Inc.',
    region: 'CA',
  },
  {
    symbol: 'HIVE-CV',
    name: 'HIVE Blockchain Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'HLF-CT',
    name: 'High Liner Foods Inc',
    region: 'CA',
  },
  {
    symbol: 'HLS-CT',
    name: 'HLS Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'HM-CF',
    name: 'Hawkmoon Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'HMAN-CV',
    name: 'Huntsman Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'HMBL-CF',
    name: 'Humble & Fume Inc',
    region: 'CA',
  },
  {
    symbol: 'HMCC.P-CV',
    name: 'High Mountain 2 Capital Corporation',
    region: 'CA',
  },
  {
    symbol: 'HME-CV',
    name: 'Hemisphere Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'HMLO-CV',
    name: 'Hemlo Explorers Inc',
    region: 'CA',
  },
  {
    symbol: 'HMM.A-CT',
    name: 'Hammond Manufacturing Co. Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'HOC-CV',
    name: 'Hunter Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'HODL-CF',
    name: 'Cypherpunk Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'HP-CF',
    name: 'Hello Pal International Inc.',
    region: 'CA',
  },
  {
    symbol: 'HPQ-CV',
    name: 'HPQ-Silicon Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'HPS.A-CT',
    name: 'Hammond Power Solutions Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'HPY-CV',
    name: 'Happy Creek Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'HRE.H-CV',
    name: 'Stans Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'HRX-CT',
    name: 'Heroux-Devtek Inc.',
    region: 'CA',
  },
  {
    symbol: 'HS-CF',
    name: 'HS GovTech Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'HSLV-CF',
    name: 'Highlander Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'HSTR-CV',
    name: 'Heliostar Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'HTC-CV',
    name: 'HTC Purenergy Inc',
    region: 'CA',
  },
  {
    symbol: 'HTL-CV',
    name: 'Hamilton Thorne Ltd',
    region: 'CA',
  },
  {
    symbol: 'HTRC-CF',
    name: 'High Tide Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'HUD-CV',
    name: 'Hudson Resources, Inc.',
    region: 'CA',
  },
  {
    symbol: 'HUGE-CF',
    name: 'FSD Pharma Inc - Class B (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'HUNT-CF',
    name: 'Gold Hunter Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'HUT-CT',
    name: 'Hut 8 Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'HVG-CV',
    name: 'Harvest Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'HVT-CV',
    name: 'Harvest One Cannabis Inc',
    region: 'CA',
  },
  {
    symbol: 'HWG-CF',
    name: 'Headwater Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'HWO-CT',
    name: 'High Arctic Energy Services Inc',
    region: 'CA',
  },
  {
    symbol: 'HWX-CT',
    name: 'Headwater Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'HWY-CV',
    name: 'Highway 50 Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'HXC.H-CV',
    name: 'HFX Holding Corp',
    region: 'CA',
  },
  {
    symbol: 'HZM-CT',
    name: 'Horizonte Minerals',
    region: 'CA',
  },
  {
    symbol: 'IAG-CT',
    name: 'iA Financial Corp',
    region: 'CA',
  },
  {
    symbol: 'IAN-CF',
    name: 'iAnthus Capital Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'IAU-CT',
    name: 'i-80 Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'IB-CV',
    name: 'IBC Advanced Alloys Corp',
    region: 'CA',
  },
  {
    symbol: 'IBAT-CF',
    name: 'International Battery Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'IBC.H-CV',
    name: 'International Bethlehem Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'IBG-CT',
    name: 'IBI Group Inc',
    region: 'CA',
  },
  {
    symbol: 'IBH-CF',
    name: 'Indigenous Bloom Hemp Corp',
    region: 'CA',
  },
  {
    symbol: 'IBT-CV',
    name: 'Ibex Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'IC-CF',
    name: 'IC Capitalight Corp',
    region: 'CA',
  },
  {
    symbol: 'ICAN-CF',
    name: 'Icanic Brands Company Inc',
    region: 'CA',
  },
  {
    symbol: 'ICAU-CV',
    name: 'Intercontinental Gold and Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'ICE-CT',
    name: 'Canlan Ice Sports Corp.',
    region: 'CA',
  },
  {
    symbol: 'ICM-CV',
    name: 'Iconic Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'ICON-CV',
    name: 'International Iconic Gold Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'ICPH-CV',
    name: 'ICPEI Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'ID-CV',
    name: 'Identillect Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'IDG-CT',
    name: 'Indigo Books & Music Inc',
    region: 'CA',
  },
  {
    symbol: 'IDK-CF',
    name: 'ThreeD Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'IDL-CV',
    name: 'Imaging Dynamics Company Ltd',
    region: 'CA',
  },
  {
    symbol: 'IDVR-CF',
    name: 'INDVR Brands Inc - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'IEI-CV',
    name: 'Imperial Equities Inc.',
    region: 'CA',
  },
  {
    symbol: 'IFA-CT',
    name: 'Ifabric Corp',
    region: 'CA',
  },
  {
    symbol: 'IFC-CT',
    name: 'Intact Financial Corp',
    region: 'CA',
  },
  {
    symbol: 'IFOS-CV',
    name: 'Itafos Inc',
    region: 'CA',
  },
  {
    symbol: 'IFP-CT',
    name: 'Interfor Corp',
    region: 'CA',
  },
  {
    symbol: 'IFX-CV',
    name: 'Imaflex Inc.',
    region: 'CA',
  },
  {
    symbol: 'IGLD-CF',
    name: 'Golden Independence Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'IGN-CF',
    name: 'iGen Networks Corp',
    region: 'CA',
  },
  {
    symbol: 'IGO-CV',
    name: 'Independence Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'IGP-CV',
    name: 'Imperial Ginseng Products Ltd.',
    region: 'CA',
  },
  {
    symbol: 'IGX-CT',
    name: 'IntelGenx Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'IHC-CV',
    name: 'Imperial Helium Corp',
    region: 'CA',
  },
  {
    symbol: 'III-CT',
    name: 'Imperial Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'ILC-CV',
    name: 'International Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'ILI-CV',
    name: 'Imagine Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'IMA-CV',
    name: 'I-Minerals,Inc',
    region: 'CA',
  },
  {
    symbol: 'IMCC-CF',
    name: 'IM Cannabis Corp',
    region: 'CA',
  },
  {
    symbol: 'IMCX-CF',
    name: 'Interra Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'IME-CF',
    name: 'Imagin Medical Inc',
    region: 'CA',
  },
  {
    symbol: 'IMG-CT',
    name: 'Iamgold Corp.',
    region: 'CA',
  },
  {
    symbol: 'IMIN-CV',
    name: 'iMining Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'IMO-CT',
    name: 'Imperial Oil Ltd.',
    region: 'CA',
  },
  {
    symbol: 'IMP-CT',
    name: 'Intermap Technologies Corp. - Class A',
    region: 'CA',
  },
  {
    symbol: 'IMV-CT',
    name: 'IMV Inc',
    region: 'CA',
  },
  {
    symbol: 'INCA-CV',
    name: 'Inca One Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'INE-CT',
    name: 'Innergex Renewable Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'INEO-CV',
    name: 'INEO Tech Corp',
    region: 'CA',
  },
  {
    symbol: 'INFI-CV',
    name: 'Infinitum Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'INNO-CF',
    name: 'InnoCan Pharma Corp',
    region: 'CA',
  },
  {
    symbol: 'INQ-CT',
    name: 'Inscape Corp. - Class B',
    region: 'CA',
  },
  {
    symbol: 'INX-CV',
    name: 'Intouch Insight Ltd',
    region: 'CA',
  },
  {
    symbol: 'INXD-QH',
    name: 'INX Digital Company Inc',
    region: 'CA',
  },
  {
    symbol: 'ION-CV',
    name: 'Ion Energy Ltd.',
    region: 'CA',
  },
  {
    symbol: 'IONC-CF',
    name: 'Ionic Brands Corp',
    region: 'CA',
  },
  {
    symbol: 'IOT-CV',
    name: 'Innovotech Inc',
    region: 'CA',
  },
  {
    symbol: 'IP-CF',
    name: 'ImagineAR Inc',
    region: 'CA',
  },
  {
    symbol: 'IPA-CV',
    name: 'ImmunoPrecise Antibodies Ltd',
    region: 'CA',
  },
  {
    symbol: 'IPCI-CT',
    name: 'Intellipharmaceutics International Inc',
    region: 'CA',
  },
  {
    symbol: 'IPCO-CT',
    name: 'International Petroleum Corp.',
    region: 'CA',
  },
  {
    symbol: 'IPD-CV',
    name: 'Intl Parkside Products Inc',
    region: 'CA',
  },
  {
    symbol: 'IPG-CV',
    name: 'Imperial Mining Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'IPO-CT',
    name: 'InPlay Oil Corp',
    region: 'CA',
  },
  {
    symbol: 'IPOT-CF',
    name: 'Isracann Biosciences Inc',
    region: 'CA',
  },
  {
    symbol: 'IPT-CV',
    name: 'Impact Silver Corp.',
    region: 'CA',
  },
  {
    symbol: 'IQ-CV',
    name: 'Airiq, Inc',
    region: 'CA',
  },
  {
    symbol: 'IRI-CV',
    name: 'IEMR Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'IRO-CV',
    name: 'Inter Rock Mineral Inc.',
    region: 'CA',
  },
  {
    symbol: 'IRV-CF',
    name: 'Irving Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ISD-CV',
    name: 'iSIGN Media Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'ISFT-CF',
    name: 'ICEsoft Technologies Canada Corp',
    region: 'CA',
  },
  {
    symbol: 'ISGI-CV',
    name: 'InsuraGuest Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ISO-CV',
    name: 'IsoEnergy Ltd.',
    region: 'CA',
  },
  {
    symbol: 'ISV-CT',
    name: 'Information Services Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'ITE-CT',
    name: 'i3 Energy Plc',
    region: 'CA',
  },
  {
    symbol: 'ITG-CV',
    name: 'Intact Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'ITH-CT',
    name: 'International Tower Hill Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'ITKO-CF',
    name: 'Idaho Champion Gold Mines Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'ITP-CT',
    name: 'Intertape Polymer Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'ITR-CV',
    name: 'Integra Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'IVC-CV',
    name: 'Interconnect Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'IVF.H-CV',
    name: 'Invictus Financial Inc',
    region: 'CA',
  },
  {
    symbol: 'IVN-CT',
    name: 'Ivanhoe Mines Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'IVOR-CF',
    name: 'Ivor Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'IVS-CV',
    name: 'Inventus Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'IVX-CV',
    name: 'Inventronics Ltd.',
    region: 'CA',
  },
  {
    symbol: 'IWIN-CF',
    name: 'Irwin Naturals Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'IXI-CV',
    name: 'Indigo Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'IZ-CV',
    name: 'International Zeolite Corp.',
    region: 'CA',
  },
  {
    symbol: 'IZN-CV',
    name: 'InZinc Mining Ltd.',
    region: 'CA',
  },
  {
    symbol: 'IZO-CF',
    name: 'Izotropic Corp',
    region: 'CA',
  },
  {
    symbol: 'IZZ-CV',
    name: 'International Prospect Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'J-CF',
    name: 'Lotus Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'JADE-CV',
    name: 'Jade Leader Corp.',
    region: 'CA',
  },
  {
    symbol: 'JAEG-CV',
    name: 'Jaeger Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'JAG-CT',
    name: 'Jaguar Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'JAX-CV',
    name: 'Jaxon Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'JBR-CF',
    name: 'James Bay Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'JDN-CV',
    name: 'Jayden Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'JE-CV',
    name: 'Just Energy Group Inc',
    region: 'CA',
  },
  {
    symbol: 'JEC-CV',
    name: 'Jura Energy Corporation',
    region: 'CA',
  },
  {
    symbol: 'JES.P-CV',
    name: 'Jesmond Capital Ltd',
    region: 'CA',
  },
  {
    symbol: 'JET-QH',
    name: 'Global Crossing Airlines Group Inc',
    region: 'CA',
  },
  {
    symbol: 'JEV-CV',
    name: 'Jericho Energy Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'JG-CV',
    name: 'Japan Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'JJ-CV',
    name: 'Jackpot Digital Inc',
    region: 'CA',
  },
  {
    symbol: 'JJJ.X-CF',
    name: '37 Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'JK-CV',
    name: 'Just Kitchen Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'JKPT-CF',
    name: 'Kings Entertainment Group Inc',
    region: 'CA',
  },
  {
    symbol: 'JNH-CV',
    name: 'Jack Nathan Medical Corp.',
    region: 'CA',
  },
  {
    symbol: 'JOR-CV',
    name: 'Jourdan Resources, Inc.',
    region: 'CA',
  },
  {
    symbol: 'JOSE-CT',
    name: 'Josemaria Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'JOY-CT',
    name: 'Journey Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'JQ-CF',
    name: '79North Inc',
    region: 'CA',
  },
  {
    symbol: 'JRV-CV',
    name: 'Jervois Global Ltd',
    region: 'CA',
  },
  {
    symbol: 'JSDA-CF',
    name: 'Jones Soda Co.',
    region: 'CA',
  },
  {
    symbol: 'JSP.H-CV',
    name: 'Jasper Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'JTC-CV',
    name: 'Jemtec Inc.',
    region: 'CA',
  },
  {
    symbol: 'JTR-CV',
    name: 'GreenSpace Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'JUB-CV',
    name: 'Jubilee Gold Exploration Ltd - Class A',
    region: 'CA',
  },
  {
    symbol: 'JUGR-CV',
    name: 'Juggernaut Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'JUMP-CV',
    name: 'Leveljump Healthcare Corp.',
    region: 'CA',
  },
  {
    symbol: 'JUSH-CF',
    name: 'Jushi Holdings Inc. - Class B (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'JUVA-CF',
    name: 'Juva Life Inc',
    region: 'CA',
  },
  {
    symbol: 'JWEL-CT',
    name: 'Jamieson Wellness Inc',
    region: 'CA',
  },
  {
    symbol: 'JZR-CV',
    name: 'Jazz Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'K-CT',
    name: 'Kinross Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'KAC.P-CV',
    name: 'Kalon Acquisition Corp',
    region: 'CA',
  },
  {
    symbol: 'KALO-CV',
    name: 'Kalo Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'KASH-CF',
    name: 'Intellabridge Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'KBG.H-CV',
    name: 'King`s Bay Resources Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'KBL-CT',
    name: 'K-Bro Linen Inc',
    region: 'CA',
  },
  {
    symbol: 'KBY.H-CV',
    name: 'Kona Bay Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'KC-CV',
    name: 'Kutcho Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'KCC-CV',
    name: 'Kincora Copper Ltd',
    region: 'CA',
  },
  {
    symbol: 'KDA-CV',
    name: 'KDA Group Inc',
    region: 'CA',
  },
  {
    symbol: 'KDK-CV',
    name: 'Kodiak Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'KEI-CT',
    name: 'Kolibri Global Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'KEL-CT',
    name: 'Kelt Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'KEN-CV',
    name: 'Kenadyr Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'KEON.H-CV',
    name: 'Keon Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'KES-CV',
    name: 'Kesselrun Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'KEY-CT',
    name: 'Keyera Corp',
    region: 'CA',
  },
  {
    symbol: 'KFR-CV',
    name: 'Kingfisher Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'KG-CV',
    name: 'Klondike Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'KGC-CV',
    name: 'Kestrel Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'KGS-CV',
    name: 'Kingman Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'KHRN-CV',
    name: 'Khiron Life Sciences Corp',
    region: 'CA',
  },
  {
    symbol: 'KIDZ-CV',
    name: 'Kidoz Inc',
    region: 'CA',
  },
  {
    symbol: 'KING-CV',
    name: 'King Global Ventures Inc.',
    region: 'CA',
  },
  {
    symbol: 'KIP-CV',
    name: 'Kiplin Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'KITS-CT',
    name: 'Kits Eyecare Ltd',
    region: 'CA',
  },
  {
    symbol: 'KLD-CV',
    name: 'Kenorland Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'KLE.H-CV',
    name: 'Kingsland Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'KLM-CV',
    name: 'Kermode Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'KLS-CT',
    name: 'Kelso Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'KNC-CV',
    name: 'K9 Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'KNE-CV',
    name: 'Kane Biotech Inc.',
    region: 'CA',
  },
  {
    symbol: 'KNG-CV',
    name: 'Kingsmen Resource Ltd',
    region: 'CA',
  },
  {
    symbol: 'KNR-QH',
    name: 'Kontrol Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'KNT-CT',
    name: 'K92 Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'KO-CV',
    name: 'Kiaro Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'KORE-CV',
    name: 'Kore Mining Ltd (New)',
    region: 'CA',
  },
  {
    symbol: 'KPT-CT',
    name: 'KP Tissue Inc',
    region: 'CA',
  },
  {
    symbol: 'KR.H-CV',
    name: 'KR Investment Ltd',
    region: 'CA',
  },
  {
    symbol: 'KRL-CV',
    name: 'Kainantu Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'KRN-CT',
    name: 'Karnalyte Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'KRR-CT',
    name: 'Karora Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'KS-CV',
    name: 'Klondike Silver Corporation',
    region: 'CA',
  },
  {
    symbol: 'KSI-CT',
    name: 'kneat.com inc',
    region: 'CA',
  },
  {
    symbol: 'KSUM.H-CV',
    name: 'Alset Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'KTN-CV',
    name: 'Kootenay Silver Inc (new)',
    region: 'CA',
  },
  {
    symbol: 'KTO-CV',
    name: 'K2 Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'KTR-CV',
    name: 'Kintavar Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'KUB-CV',
    name: 'Cub Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'KUR.H-CV',
    name: 'Kure Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'KUT-CV',
    name: 'Redishred Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'KUU-CV',
    name: 'KuuHubb Inc',
    region: 'CA',
  },
  {
    symbol: 'KUYA-CF',
    name: 'Kuya Silver Corporation',
    region: 'CA',
  },
  {
    symbol: 'KWE-CV',
    name: 'KWESST Micro Systems Inc.',
    region: 'CA',
  },
  {
    symbol: 'KWG-CF',
    name: 'Kwg Resources Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'KWG.A-CF',
    name: 'Kwg Resources Inc (Multiple Voting)',
    region: 'CA',
  },
  {
    symbol: 'KXS-CT',
    name: 'Kinaxis Inc',
    region: 'CA',
  },
  {
    symbol: 'KYS.H-CV',
    name: 'Kaymus Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'KZD-CV',
    name: 'Kaizen Discovery Inc',
    region: 'CA',
  },
  {
    symbol: 'L-CT',
    name: 'Loblaw Cos. Ltd.',
    region: 'CA',
  },
  {
    symbol: 'LA-CV',
    name: 'Los Andes Copper Ltd',
    region: 'CA',
  },
  {
    symbol: 'LAB-CV',
    name: 'Labrador Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'LABS-CT',
    name: 'Medipharm Labs Corp',
    region: 'CA',
  },
  {
    symbol: 'LAC-CT',
    name: 'Lithium Americas Corp',
    region: 'CA',
  },
  {
    symbol: 'LAI-CF',
    name: 'La Imperial Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'LAM-CT',
    name: 'Laramide Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'LAS.A-CT',
    name: 'Lassonde Industries Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'LB-CT',
    name: 'Laurentian Bank Of Canada',
    region: 'CA',
  },
  {
    symbol: 'LBC-CV',
    name: 'Libero Copper & Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'LDI-CV',
    name: 'Lithoquest Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'LECR-CF',
    name: 'Leocor Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'LEM-CV',
    name: 'Leading Edge Materials Corp',
    region: 'CA',
  },
  {
    symbol: 'LEO-CV',
    name: 'Lion Copper and Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'LEV-CT',
    name: 'Lion Electric Co (The)',
    region: 'CA',
  },
  {
    symbol: 'LEXI-CV',
    name: 'Lithium Energi Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'LEXT-CF',
    name: 'Lexston Life Sciences Corp',
    region: 'CA',
  },
  {
    symbol: 'LFST-CV',
    name: 'Lifeist Wellness Inc',
    region: 'CA',
  },
  {
    symbol: 'LGD-CT',
    name: 'Liberty Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'LGIQ-QH',
    name: 'Logiq Inc',
    region: 'CA',
  },
  {
    symbol: 'LGO-CT',
    name: 'Largo Inc',
    region: 'CA',
  },
  {
    symbol: 'LGT.A-CT',
    name: 'Logistec Corp. - Class A',
    region: 'CA',
  },
  {
    symbol: 'LGT.B-CT',
    name: 'Logistec Corp. - Class B',
    region: 'CA',
  },
  {
    symbol: 'LHI-CV',
    name: 'Lendified Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'LI-CV',
    name: 'American Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'LIF-CT',
    name: 'Labrador Iron Ore Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'LIO-CV',
    name: 'Lion One Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'LIS-CV',
    name: 'Lithium South Development Corporation',
    region: 'CA',
  },
  {
    symbol: 'LIT-CV',
    name: 'Argentina Lithium & Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'LITE-CF',
    name: 'SpotLite IOT Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'LITH-CV',
    name: 'Lithium Chile Inc',
    region: 'CA',
  },
  {
    symbol: 'LKY-CV',
    name: 'Lucky Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'LL-CV',
    name: 'Canada Rare Earth Corp',
    region: 'CA',
  },
  {
    symbol: 'LLG-CV',
    name: 'Mason Graphite Inc',
    region: 'CA',
  },
  {
    symbol: 'LLP-CF',
    name: 'LeanLife Health Inc',
    region: 'CA',
  },
  {
    symbol: 'LM-CV',
    name: 'Lingo Media Corp',
    region: 'CA',
  },
  {
    symbol: 'LME-CV',
    name: 'Laurion Mineral Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'LMG-CV',
    name: 'Lincoln Gold Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'LMR-CV',
    name: 'Lomiko Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'LMS-CV',
    name: 'Latin Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'LN-CT',
    name: 'Loncor Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'LNF-CT',
    name: 'Leon`s Furniture Ltd.',
    region: 'CA',
  },
  {
    symbol: 'LNR-CT',
    name: 'Linamar Corp.',
    region: 'CA',
  },
  {
    symbol: 'LOBE-CF',
    name: 'Lobe Sciences Ltd',
    region: 'CA',
  },
  {
    symbol: 'LOCL-CT',
    name: 'Freshlocal Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'LONE-CV',
    name: 'Lithium One Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'LORD-CV',
    name: 'St. James Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'LOT-CV',
    name: 'TomaGold Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'LOTA-CV',
    name: 'Delota Corp',
    region: 'CA',
  },
  {
    symbol: 'LOVE-CV',
    name: 'Cannara Biotech Inc',
    region: 'CA',
  },
  {
    symbol: 'LOWL-CF',
    name: 'Lowell Farms Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'LPEN-CT',
    name: 'Loop Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'LPK-CV',
    name: 'Lupaka Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'LPS-CV',
    name: 'Legend Power Systems Inc.',
    region: 'CA',
  },
  {
    symbol: 'LQID-CF',
    name: 'Liquid Avatar Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'LR-CV',
    name: 'Luminex Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'LRA-CV',
    name: 'Lara Exploration Ltd.',
    region: 'CA',
  },
  {
    symbol: 'LRC.H-CV',
    name: 'Lovitt Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'LRDS-CF',
    name: 'Lords & Company Worldwide Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'LSD.H-CV',
    name: 'Lightspeed Discoveries Inc.',
    region: 'CA',
  },
  {
    symbol: 'LSPD-CT',
    name: 'Lightspeed Commerce Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'LSPK-CT',
    name: 'LifeSpeak Inc',
    region: 'CA',
  },
  {
    symbol: 'LTE-CV',
    name: 'Lite Access Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'LTV-CV',
    name: 'LeoNovus Inc',
    region: 'CA',
  },
  {
    symbol: 'LUC-CT',
    name: 'Lucara Diamond Corp',
    region: 'CA',
  },
  {
    symbol: 'LUCK-CV',
    name: 'Real Luck Group Ltd.',
    region: 'CA',
  },
  {
    symbol: 'LUFF-CF',
    name: 'Luff Enterprises Ltd',
    region: 'CA',
  },
  {
    symbol: 'LUG-CT',
    name: 'Lundin Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'LUM-CV',
    name: 'Lumina Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'LUN-CT',
    name: 'Lundin Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'LUV-CF',
    name: 'Love Pharma Inc',
    region: 'CA',
  },
  {
    symbol: 'LUX-CF',
    name: 'Newlox Gold Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'LUXX-CF',
    name: 'Luxxfolio Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'LVT-CF',
    name: 'Levitee Labs Inc',
    region: 'CA',
  },
  {
    symbol: 'LVX-CV',
    name: 'Leviathan Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'LWRK-CT',
    name: 'LifeWorks Inc',
    region: 'CA',
  },
  {
    symbol: 'LX.H-CV',
    name: 'Lincoln Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'LXE-CV',
    name: 'Leucrotta Exploration Inc.',
    region: 'CA',
  },
  {
    symbol: 'LXG-CV',
    name: 'Lexagene Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'LXR-CT',
    name: 'LXRandCo Inc - Class B',
    region: 'CA',
  },
  {
    symbol: 'LYNX-CF',
    name: 'Lynx Global Digital Finance Corporation',
    region: 'CA',
  },
  {
    symbol: 'MAC-CV',
    name: 'Themac Resources Group Limited',
    region: 'CA',
  },
  {
    symbol: 'MAE-CV',
    name: 'Maritime Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MAG-CT',
    name: 'MAG Silver Corp.',
    region: 'CA',
  },
  {
    symbol: 'MAGT-CT',
    name: 'Magnet Forensics Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'MAH-CV',
    name: 'Marksmen Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'MAI-CV',
    name: 'Minera Alamos Inc',
    region: 'CA',
  },
  {
    symbol: 'MAKA-CF',
    name: 'Makara Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MAL-CT',
    name: 'Magellan Aerospace Corp.',
    region: 'CA',
  },
  {
    symbol: 'MANN-CF',
    name: 'Manning Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'MAPS-CV',
    name: 'ProStar Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'MARI-CT',
    name: 'Marimaca Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'MARV-CV',
    name: 'Marvel Discovery Corp',
    region: 'CA',
  },
  {
    symbol: 'MARY-CF',
    name: 'Mary Agrotechnologies Inc',
    region: 'CA',
  },
  {
    symbol: 'MAS-CV',
    name: 'Mas Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'MASS-CV',
    name: 'Masivo Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'MAU-CV',
    name: 'Montage Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'MAV-CT',
    name: 'MAV Beauty Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'MAW-CT',
    name: 'Mawson Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'MAX-CV',
    name: 'Max Resource Corp.',
    region: 'CA',
  },
  {
    symbol: 'MAXR-CT',
    name: 'Maxar Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'MAZ.H-CV',
    name: 'Mazarin Inc.',
    region: 'CA',
  },
  {
    symbol: 'MBA-CT',
    name: 'CIBT Education Group Inc',
    region: 'CA',
  },
  {
    symbol: 'MBI.H-CV',
    name: 'Med BioGene Inc',
    region: 'CA',
  },
  {
    symbol: 'MBLM-CF',
    name: 'Mobilum Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'MBX-CT',
    name: 'Microbix Biosystems Inc.',
    region: 'CA',
  },
  {
    symbol: 'MCA.H-CV',
    name: 'Milner Consolidated Silver Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'MCB-CT',
    name: 'McCoy Global Inc',
    region: 'CA',
  },
  {
    symbol: 'MCCN-CF',
    name: 'Medcolcanna Organics Inc',
    region: 'CA',
  },
  {
    symbol: 'MCG-CV',
    name: 'Mountain China Resorts (Holding) Ltd',
    region: 'CA',
  },
  {
    symbol: 'MCI-CV',
    name: 'Minnova Corp',
    region: 'CA',
  },
  {
    symbol: 'MCL-CF',
    name: 'McLaren Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'MCLD-CV',
    name: 'mCloud Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'MCM.A-CV',
    name: 'Matachewan Consolidated Mines Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'MCS-CV',
    name: 'McChip Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'MCU-CV',
    name: 'Mega Copper Ltd',
    region: 'CA',
  },
  {
    symbol: 'MCUR-CF',
    name: 'Mind Cure Health Inc',
    region: 'CA',
  },
  {
    symbol: 'MCX-CV',
    name: 'MCX Technologies Corporation',
    region: 'CA',
  },
  {
    symbol: 'MD-CV',
    name: 'Midland Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'MDA-CT',
    name: 'MDA Ltd',
    region: 'CA',
  },
  {
    symbol: 'MDF-CT',
    name: 'mdf commerce inc',
    region: 'CA',
  },
  {
    symbol: 'MDI-CT',
    name: 'Major Drilling Group International Inc.',
    region: 'CA',
  },
  {
    symbol: 'MDL-CV',
    name: 'Medallion Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'MDNA-CT',
    name: 'Medicenna Therapeutics Corp',
    region: 'CA',
  },
  {
    symbol: 'MDP-CT',
    name: 'Medexus Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'MDX-CV',
    name: 'MedX Health Corp',
    region: 'CA',
  },
  {
    symbol: 'ME-CT',
    name: 'Moneta Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'MEAL-CF',
    name: 'Nabati Foods Global Inc',
    region: 'CA',
  },
  {
    symbol: 'MEAT-CF',
    name: 'Modern Plant Based Foods Inc',
    region: 'CA',
  },
  {
    symbol: 'MED-CV',
    name: 'Medgold Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MEDA-CF',
    name: 'Medaro Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MEDI-QH',
    name: 'Wellbeing Digital Sciences Inc - New',
    region: 'CA',
  },
  {
    symbol: 'MEDV-QH',
    name: 'Medivolve Inc',
    region: 'CA',
  },
  {
    symbol: 'MEG-CT',
    name: 'MEG Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'MEGA-CF',
    name: 'MegaWatt Lithium and Battery Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'MEK-CV',
    name: 'Metals Creek Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MEND-CF',
    name: 'Nurosene Health Inc',
    region: 'CA',
  },
  {
    symbol: 'MENE-CV',
    name: 'Mene Inc - Class B (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'MET-CV',
    name: 'Metalore Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'MEX-CV',
    name: 'Mexican Gold Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MFC-CT',
    name: 'Manulife Financial Corp.',
    region: 'CA',
  },
  {
    symbol: 'MFG-CV',
    name: 'Mayfair Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'MFI-CT',
    name: 'Maple Leaf Foods Inc.',
    region: 'CA',
  },
  {
    symbol: 'MG-CT',
    name: 'Magna International Inc.',
    region: 'CA',
  },
  {
    symbol: 'MGA-CT',
    name: 'Mega Uranium Ltd',
    region: 'CA',
  },
  {
    symbol: 'MGC.H-CV',
    name: 'Midasco Capital Corp.',
    region: 'CA',
  },
  {
    symbol: 'MGG-CV',
    name: 'Minaurum Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'MGI-CV',
    name: 'Magnum Goldcorp Inc',
    region: 'CA',
  },
  {
    symbol: 'MGM-CV',
    name: 'Maple Gold Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'MGR-CV',
    name: 'Magna Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'MGRO-CF',
    name: 'MustGrow Biologics Corp',
    region: 'CA',
  },
  {
    symbol: 'MGW-CF',
    name: 'Maple Leaf Green World Inc',
    region: 'CA',
  },
  {
    symbol: 'MGZ.H-CV',
    name: 'Mangazeya Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'MHI-CV',
    name: 'Mineral Hill Industries Ltd.',
    region: 'CA',
  },
  {
    symbol: 'MHUB-CV',
    name: 'Minehub Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'MICH-CF',
    name: 'Mich Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'MILK-CF',
    name: 'Plant Veda Foods Ltd',
    region: 'CA',
  },
  {
    symbol: 'MIMI-CV',
    name: 'Mimi`s Rock Corp',
    region: 'CA',
  },
  {
    symbol: 'MIN-CT',
    name: 'Excelsior Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MINE-CV',
    name: 'Inomin Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'MIR-CV',
    name: 'Medmira Inc',
    region: 'CA',
  },
  {
    symbol: 'MIRL-CF',
    name: 'Minera IRL Ltd',
    region: 'CA',
  },
  {
    symbol: 'MIS-CF',
    name: 'Mistango River Resources Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'MIT-CV',
    name: 'Mint Corp',
    region: 'CA',
  },
  {
    symbol: 'MJ-CF',
    name: 'Maven Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'MJRX-CF',
    name: 'Global Health Clinics Ltd',
    region: 'CA',
  },
  {
    symbol: 'MJS-CV',
    name: 'Majestic Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'MKA-CV',
    name: 'Mkango Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'MKO-CV',
    name: 'Mako Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MKR-CV',
    name: 'Melkior Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'MKT-CV',
    name: 'DeepMarkit Corp',
    region: 'CA',
  },
  {
    symbol: 'MLCL-CF',
    name: 'Molecule Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'MLY-CV',
    name: 'American CuMo Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MM-CF',
    name: 'Metallica Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'MMA-CV',
    name: 'Midnight Sun Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'MMC-CF',
    name: 'Myriad Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'MMED-QH',
    name: 'Mind Medicine Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'MMEN-CF',
    name: 'MedMen Enterprises Inc - Class B (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'MMG-CV',
    name: 'Metallic Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'MMI-CF',
    name: 'Metalo Manufacturing Inc',
    region: 'CA',
  },
  {
    symbol: 'MMM-CV',
    name: 'Minco Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'MMN-CV',
    name: 'Monarca Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'MMS-CV',
    name: 'Macarthur Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'MMV-CV',
    name: 'Mineral Mountain Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'MMX-CT',
    name: 'Maverix Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'MMY-CV',
    name: 'Monument MIning Limited',
    region: 'CA',
  },
  {
    symbol: 'MN-CV',
    name: 'Manganese X Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'MNC-CV',
    name: 'Magnetic North Acquisition Corp',
    region: 'CA',
  },
  {
    symbol: 'MND-CT',
    name: 'Mandalay Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MNG-CF',
    name: 'Mongoose Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'MNO-CT',
    name: 'Meridian Mining UK Societas',
    region: 'CA',
  },
  {
    symbol: 'MNOW-CV',
    name: 'Mednow Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'MNRL-CV',
    name: 'Monumental Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'MNT-CT',
    name: 'Royal Canadian Mint - Canadian Gold Reserves Exchange-Traded Receipts',
    region: 'CA',
  },
  {
    symbol: 'MNTR-CV',
    name: 'Mantaro Precious Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'MNX-CV',
    name: 'Manitex Capital, Inc.',
    region: 'CA',
  },
  {
    symbol: 'MOJO-CF',
    name: 'Mojave Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'MOLY-QH',
    name: 'Greenland Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'MON-CV',
    name: 'Montero Mining and Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'MOO.P-CV',
    name: 'Moon River Capital Ltd',
    region: 'CA',
  },
  {
    symbol: 'MOON-CV',
    name: 'Blue Moon Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'MOS-CV',
    name: 'Mobi724 Global Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'MOX-CV',
    name: 'Morien Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MOZ-CT',
    name: 'Marathon Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'MPC-CT',
    name: 'Madison Pacific Properties Inc - Class B',
    region: 'CA',
  },
  {
    symbol: 'MPC.C-CT',
    name: 'Madison Pacific Properties Inc - Class C',
    region: 'CA',
  },
  {
    symbol: 'MPH-CV',
    name: 'Medicure Inc',
    region: 'CA',
  },
  {
    symbol: 'MPM-CV',
    name: 'Millennial Precious Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'MPVD-CT',
    name: 'Mountain Province Diamonds Inc',
    region: 'CA',
  },
  {
    symbol: 'MPXI-CF',
    name: 'MPX International Corp',
    region: 'CA',
  },
  {
    symbol: 'MRC-CT',
    name: 'Morguard Corporation',
    region: 'CA',
  },
  {
    symbol: 'MRE-CT',
    name: 'Martinrea International, Inc.',
    region: 'CA',
  },
  {
    symbol: 'MRM-CF',
    name: 'Micromem Technologies, Inc.',
    region: 'CA',
  },
  {
    symbol: 'MRO-CV',
    name: 'Millrock Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'MRS-CV',
    name: 'Mission Ready Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'MRU-CT',
    name: 'Metro Inc',
    region: 'CA',
  },
  {
    symbol: 'MRV-CT',
    name: 'Nuvo Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'MRVL-CV',
    name: 'Marvel Biosciences Corp',
    region: 'CA',
  },
  {
    symbol: 'MRZ-CV',
    name: 'Mirasol Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'MSA-CT',
    name: 'Mineros S.A.',
    region: 'CA',
  },
  {
    symbol: 'MSC-CV',
    name: 'Millennium Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'MSET-CF',
    name: 'Mindset Pharma Inc',
    region: 'CA',
  },
  {
    symbol: 'MSP-CV',
    name: 'Minaean SP Construction Corp',
    region: 'CA',
  },
  {
    symbol: 'MSR-CV',
    name: 'Minsud Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MSV-CT',
    name: 'Minco Silver Corporation',
    region: 'CA',
  },
  {
    symbol: 'MT-CV',
    name: 'M3 Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'MTA-CV',
    name: 'Metalla Royalty and Streaming Ltd',
    region: 'CA',
  },
  {
    symbol: 'MTB-CV',
    name: 'Mountain Boy Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'MTC-CV',
    name: 'MetalCorp Ltd',
    region: 'CA',
  },
  {
    symbol: 'MTH-CV',
    name: 'Mammoth Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MTL-CT',
    name: 'Mullen Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'MTLO-CV',
    name: 'Martello Technologies Group Inc',
    region: 'CA',
  },
  {
    symbol: 'MTN.H-CV',
    name: 'Martina Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'MTS-CV',
    name: 'Metallis Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'MTT-CV',
    name: 'Magna Terra Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'MTU-CV',
    name: 'Manitou Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'MTX-CV',
    name: 'Metalex Ventures Ltd.',
    region: 'CA',
  },
  {
    symbol: 'MTY-CT',
    name: 'MTY Food Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'MUN-CV',
    name: 'Mundoro Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'MUR-CV',
    name: 'Murchison Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'MUSH-CV',
    name: 'Good Shroom Co Inc (The)',
    region: 'CA',
  },
  {
    symbol: 'MUSK-CF',
    name: 'Musk Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'MUST.H-CV',
    name: 'Must Capital Inc.',
    region: 'CA',
  },
  {
    symbol: 'MUX-CT',
    name: 'McEwen Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'MVAI-CV',
    name: 'Minerva Intelligence Inc',
    region: 'CA',
  },
  {
    symbol: 'MVD.H-CV',
    name: 'Mega View Digital Entertainment Corp',
    region: 'CA',
  },
  {
    symbol: 'MVI.H-CV',
    name: 'Monitor Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'MVMD-CF',
    name: 'Mountain Valley MD Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'MVP-CT',
    name: 'MediaValet Inc',
    region: 'CA',
  },
  {
    symbol: 'MVY-CV',
    name: 'Moovly Media Inc',
    region: 'CA',
  },
  {
    symbol: 'MWX-CV',
    name: 'Mineworx Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'MX-CT',
    name: 'Methanex Corp.',
    region: 'CA',
  },
  {
    symbol: 'MXG-CT',
    name: 'Maxim Power Corp',
    region: 'CA',
  },
  {
    symbol: 'MXL.H-CV',
    name: 'MX Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'MY.X-CF',
    name: 'Montego Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'MYCO-QH',
    name: 'Mydecine Innovations Group Inc New',
    region: 'CA',
  },
  {
    symbol: 'MYID-CV',
    name: 'Reklaim Ltd',
    region: 'CA',
  },
  {
    symbol: 'MYND-CF',
    name: 'MYND Life Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'MYR-CF',
    name: 'Meryllion Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'MZN-CV',
    name: 'Metallum Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'NA-CT',
    name: 'National Bank Of Canada',
    region: 'CA',
  },
  {
    symbol: 'NAM-CV',
    name: 'New Age Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'NANO-CT',
    name: 'Nano One Materials Corp',
    region: 'CA',
  },
  {
    symbol: 'NAR-CV',
    name: 'North Arrow Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'NAU-CV',
    name: 'Nevgold Corp',
    region: 'CA',
  },
  {
    symbol: 'NB-CT',
    name: 'NioCorp Developments Ltd',
    region: 'CA',
  },
  {
    symbol: 'NBLC-CV',
    name: 'Nobel Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'NBLY-CT',
    name: 'Neighbourly Pharmacy Inc',
    region: 'CA',
  },
  {
    symbol: 'NBM-CV',
    name: 'NEO Battery Materials Ltd',
    region: 'CA',
  },
  {
    symbol: 'NBR-CV',
    name: 'Nubian Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'NBVA-CV',
    name: 'Nubeva Technologies Ltd.',
    region: 'CA',
  },
  {
    symbol: 'NBY-CV',
    name: 'Niobay Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'NCAU-CV',
    name: 'Newcore Gold Ltd.',
    region: 'CA',
  },
  {
    symbol: 'NCF-CT',
    name: 'Northcliff Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'NCI-CV',
    name: 'NTG Clarity Networks Inc',
    region: 'CA',
  },
  {
    symbol: 'NCLR-CF',
    name: 'Basin Uranium Corp.',
    region: 'CA',
  },
  {
    symbol: 'NCM-CT',
    name: 'Newcrest Mining',
    region: 'CA',
  },
  {
    symbol: 'NCP-CT',
    name: 'Nickel Creek Platinum Corp',
    region: 'CA',
  },
  {
    symbol: 'NCU-CT',
    name: 'Nevada Copper Corp.',
    region: 'CA',
  },
  {
    symbol: 'NCX-CV',
    name: 'Northisle Copper & Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'NDA-CV',
    name: 'Neptune Digital Assets Corp.',
    region: 'CA',
  },
  {
    symbol: 'NDAT-CF',
    name: 'nDatalyze Corp',
    region: 'CA',
  },
  {
    symbol: 'NDM-CT',
    name: 'Northern Dynasty Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'NDVA-CV',
    name: 'Indiva Ltd',
    region: 'CA',
  },
  {
    symbol: 'NED-CV',
    name: 'New Destiny Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'NEO-CT',
    name: 'Neo Performance Materials Inc',
    region: 'CA',
  },
  {
    symbol: 'NEON-CF',
    name: 'NeonMind Biosciences Inc',
    region: 'CA',
  },
  {
    symbol: 'NEPT-CT',
    name: 'Neptune Wellness Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'NERD-CF',
    name: 'Nerds On Site Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'NETZ-QH',
    name: 'Carbon Streaming Corporation',
    region: 'CA',
  },
  {
    symbol: 'NEV-CV',
    name: 'Nevada Sunrise Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NEWD-CF',
    name: 'Newfoundland Discovery Corp',
    region: 'CA',
  },
  {
    symbol: 'NEWO-CV',
    name: 'NewOrigin Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'NEWU-CV',
    name: 'Newtopia Inc',
    region: 'CA',
  },
  {
    symbol: 'NEXE-CV',
    name: 'NEXE Innovations Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'NEXT-CT',
    name: 'NextSource Materials Inc',
    region: 'CA',
  },
  {
    symbol: 'NFG-CV',
    name: 'New Found Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NFI-CT',
    name: 'NFI Group Inc',
    region: 'CA',
  },
  {
    symbol: 'NFLD-CF',
    name: 'Exploits Discovery Corp',
    region: 'CA',
  },
  {
    symbol: 'NG-CT',
    name: 'Novagold Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'NGC-CV',
    name: 'Northern Graphite Corp',
    region: 'CA',
  },
  {
    symbol: 'NGD-CT',
    name: 'New Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'NGE-CV',
    name: 'Nevada Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'NGEN-CV',
    name: 'NervGen Pharma Corp',
    region: 'CA',
  },
  {
    symbol: 'NGEX-CV',
    name: 'NGEx Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'NGLD-CV',
    name: 'New Placer Dome Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'NGMD-CV',
    name: 'NuGen Medical Devices Inc',
    region: 'CA',
  },
  {
    symbol: 'NGT-CT',
    name: 'Newmont Corp',
    region: 'CA',
  },
  {
    symbol: 'NGY-CV',
    name: 'Nexera Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'NHHH-CV',
    name: 'FuelPositive Corp',
    region: 'CA',
  },
  {
    symbol: 'NHK-CT',
    name: 'Nighthawk Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NHP-CV',
    name: 'Lumiera Health Inc.',
    region: 'CA',
  },
  {
    symbol: 'NI-CF',
    name: 'Victory Nickel Inc',
    region: 'CA',
  },
  {
    symbol: 'NICL-CV',
    name: 'Nickel Rock Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'NICO-CF',
    name: 'Class 1 Nickel and Technologies Limited',
    region: 'CA',
  },
  {
    symbol: 'NICU-CV',
    name: 'Magna Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'NIKL-CV',
    name: 'GK Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'NILI-CV',
    name: 'Surge Battery Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'NIM-CV',
    name: 'Nicola Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'NINE-CF',
    name: 'Nine Mile Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'NIO-CV',
    name: 'Niocan Inc.',
    region: 'CA',
  },
  {
    symbol: 'NKG-CV',
    name: 'Nevada King Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NKL-CV',
    name: 'Nickel 28 Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'NKW.H-CV',
    name: 'Oceanic Wind Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'NL-CV',
    name: 'Northern Lion Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'NLH-CV',
    name: 'Nova Leap Health Corp',
    region: 'CA',
  },
  {
    symbol: 'NLR-CF',
    name: 'Northern Lights Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'NLTA-CV',
    name: 'Enlighta Inc',
    region: 'CA',
  },
  {
    symbol: 'NLV-CF',
    name: 'New Leaf Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'NM-CF',
    name: 'Novamind Inc',
    region: 'CA',
  },
  {
    symbol: 'NMG.H-CV',
    name: 'Noble Metal Group',
    region: 'CA',
  },
  {
    symbol: 'NMI-CV',
    name: 'Namibia Critical Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'NNX-CV',
    name: 'Nickel North Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'NOA-CT',
    name: 'North American Construction Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'NOB-CV',
    name: 'Noble Mineral Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'NOC-CV',
    name: 'Norseman Silver Inc.',
    region: 'CA',
  },
  {
    symbol: 'NOCR-CV',
    name: 'Norden Crown Metals Corporation',
    region: 'CA',
  },
  {
    symbol: 'NOM-CF',
    name: 'Norsemont Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'NORA-CV',
    name: 'Norra Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'NOU-CV',
    name: 'Nouveau Monde Graphite Inc',
    region: 'CA',
  },
  {
    symbol: 'NOVA-CF',
    name: 'Nova Mentis Life Science Corp',
    region: 'CA',
  },
  {
    symbol: 'NOVC-CT',
    name: 'Nova Cannabis Inc',
    region: 'CA',
  },
  {
    symbol: 'NOVR-CV',
    name: 'Nova Royalty Corp.',
    region: 'CA',
  },
  {
    symbol: 'NOW-CV',
    name: 'NowVertical Group Inc',
    region: 'CA',
  },
  {
    symbol: 'NPA-CV',
    name: 'Alphinat Inc.',
    region: 'CA',
  },
  {
    symbol: 'NPI-CT',
    name: 'Northland Power Inc.',
    region: 'CA',
  },
  {
    symbol: 'NPK-CT',
    name: 'Verde Agritech Plc',
    region: 'CA',
  },
  {
    symbol: 'NPR-CV',
    name: 'North Peak Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'NPRA-CF',
    name: 'Nepra Foods Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'NPT-CF',
    name: 'Plaintree Systems Inc.',
    region: 'CA',
  },
  {
    symbol: 'NPTH-CV',
    name: 'NeuPath Health Inc',
    region: 'CA',
  },
  {
    symbol: 'NRG-CV',
    name: 'NewRange Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NRM-CV',
    name: 'Noram Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'NSAU-CF',
    name: 'MegumaGold Corp',
    region: 'CA',
  },
  {
    symbol: 'NSCI-CV',
    name: 'Nanalysis Scientific Corp.',
    region: 'CA',
  },
  {
    symbol: 'NSE-CV',
    name: 'New Stratus Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'NSG-CF',
    name: 'Northstar Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NSHS-CF',
    name: 'Nanosphere Health Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'NSP-CV',
    name: 'Naturally Splendid Enterprises Ltd',
    region: 'CA',
  },
  {
    symbol: 'NSR-CT',
    name: 'Nomad Royalty Company Ltd',
    region: 'CA',
  },
  {
    symbol: 'NTAR-CF',
    name: 'NexTech AR Solutions Corp',
    region: 'CA',
  },
  {
    symbol: 'NTAR-QH',
    name: 'NexTech AR Solutions Corp',
    region: 'CA',
  },
  {
    symbol: 'NTE-CV',
    name: 'Network Media Group Inc',
    region: 'CA',
  },
  {
    symbol: 'NTM-CF',
    name: 'New Tech Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'NTR-CT',
    name: 'Nutrien Ltd',
    region: 'CA',
  },
  {
    symbol: 'NU-CV',
    name: 'NeutriSci International Inc',
    region: 'CA',
  },
  {
    symbol: 'NUAG-CT',
    name: 'New Pacific Metals Corp - New',
    region: 'CA',
  },
  {
    symbol: 'NUG-CV',
    name: 'NuLegacy Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NUMI-CT',
    name: 'Numinus Wellness Inc.',
    region: 'CA',
  },
  {
    symbol: 'NUR-CF',
    name: 'Nuran Wireless Inc',
    region: 'CA',
  },
  {
    symbol: 'NVA-CT',
    name: 'Nuvista Energy Ltd.',
    region: 'CA',
  },
  {
    symbol: 'NVCN-CT',
    name: 'Neovasc Inc',
    region: 'CA',
  },
  {
    symbol: 'NVEI-CT',
    name: 'Nuvei Corporation (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'NVG-CF',
    name: 'Nass Valley Gateway Ltd',
    region: 'CA',
  },
  {
    symbol: 'NVI-CV',
    name: 'Novra Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'NVLH-CF',
    name: 'Nevada Lithium Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'NVO-CT',
    name: 'Novo Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'NVR-CF',
    name: 'North Valley Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'NVT-CV',
    name: 'Nortec Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'NVX-CV',
    name: 'NV Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'NWC-CT',
    name: 'North West Company Inc (Variable Voting)',
    region: 'CA',
  },
  {
    symbol: 'NWI-CF',
    name: 'Nuinsco Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'NWST-CV',
    name: 'Northwest Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'NWX-CV',
    name: 'Newport Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'NXE-CT',
    name: 'NexGen Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'NXG-CV',
    name: 'Nexgenrx Inc',
    region: 'CA',
  },
  {
    symbol: 'NXH-CV',
    name: 'Next Hydrogen Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'NXJ-CT',
    name: 'NexJ Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'NXLV-CV',
    name: 'NexLiving Communities Inc',
    region: 'CA',
  },
  {
    symbol: 'NXO-CV',
    name: 'Nexoptic Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'NXS-CV',
    name: 'Nexus Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'NZ-CV',
    name: 'New Zealand Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'NZC-CT',
    name: 'NorZinc Ltd',
    region: 'CA',
  },
  {
    symbol: 'NZN-CV',
    name: 'Nevada Zinc Corp',
    region: 'CA',
  },
  {
    symbol: 'NZP-CV',
    name: 'Chatham Rock Phosphate Ltd',
    region: 'CA',
  },
  {
    symbol: 'OBE-CT',
    name: 'Obsidian Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'OC-CV',
    name: 'Olive Resource Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'OCG-CV',
    name: 'Outcrop Silver & Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'OCO-CV',
    name: 'Oroco Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'ODD-CV',
    name: 'Odd Burger Corporation',
    region: 'CA',
  },
  {
    symbol: 'ODV-CV',
    name: 'Osisko Development Corp',
    region: 'CA',
  },
  {
    symbol: 'ODX.H-CV',
    name: 'Odyssey Resources Limited',
    region: 'CA',
  },
  {
    symbol: 'OEC.H-CV',
    name: 'Oracle Energy Corp.',
    region: 'CA',
  },
  {
    symbol: 'OEE-CV',
    name: 'Memex Inc.',
    region: 'CA',
  },
  {
    symbol: 'OEG.H-CV',
    name: 'ONEnergy Inc',
    region: 'CA',
  },
  {
    symbol: 'OG-CV',
    name: 'Oragin Foods Inc',
    region: 'CA',
  },
  {
    symbol: 'OGC-CT',
    name: 'OceanaGold Corporation',
    region: 'CA',
  },
  {
    symbol: 'OGD-CT',
    name: 'Orbit Garant Drilling Inc',
    region: 'CA',
  },
  {
    symbol: 'OGI-CT',
    name: 'OrganiGram Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'OGN-CV',
    name: 'Orogen Royalties Inc',
    region: 'CA',
  },
  {
    symbol: 'OGO-CV',
    name: 'Organto Foods Inc',
    region: 'CA',
  },
  {
    symbol: 'OIII-CV',
    name: 'O3 Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'OIL-CF',
    name: 'Permex Petroleum Corp',
    region: 'CA',
  },
  {
    symbol: 'OILS-CF',
    name: 'Nextleaf Solutions Ltd',
    region: 'CA',
  },
  {
    symbol: 'OLA-CT',
    name: 'Orla Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'OLV-CV',
    name: 'Olivut Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'OLY-CT',
    name: 'Olympia Financial Group Inc',
    region: 'CA',
  },
  {
    symbol: 'OM-CV',
    name: 'Osisko Metals Incorporated',
    region: 'CA',
  },
  {
    symbol: 'OMG-CV',
    name: 'Omai Gold Mines Corp',
    region: 'CA',
  },
  {
    symbol: 'OMI-CV',
    name: 'Orosur Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'OMIC-CF',
    name: 'Optima Medical Innovations Corp',
    region: 'CA',
  },
  {
    symbol: 'OML-CV',
    name: 'Omni-Lite Industries Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'OMM-CV',
    name: 'Omineca Mining and Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'ONC-CT',
    name: 'Oncolytics Biotech, Inc.',
    region: 'CA',
  },
  {
    symbol: 'ONE-CV',
    name: '01 Communique Laboratory Inc',
    region: 'CA',
  },
  {
    symbol: 'ONEX-CT',
    name: 'Onex Corp.',
    region: 'CA',
  },
  {
    symbol: 'ONV.H-CV',
    name: 'Oronova Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'OOOO-CV',
    name: 'OOOOO Entertainment Commerce Limited (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'OOR-CV',
    name: 'Opus One Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'OPC-CF',
    name: 'Organic Potash Corp',
    region: 'CA',
  },
  {
    symbol: 'OPHR-CV',
    name: 'Ophir Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'OPS-CT',
    name: 'Opsens Inc',
    region: 'CA',
  },
  {
    symbol: 'OPT-CT',
    name: 'Optiva Inc',
    region: 'CA',
  },
  {
    symbol: 'OPTI-CF',
    name: 'Optimi Health Corp',
    region: 'CA',
  },
  {
    symbol: 'OPV-CV',
    name: 'Optimum Ventures Ltd',
    region: 'CA',
  },
  {
    symbol: 'OPW-CV',
    name: 'Opawica Explorations Inc.',
    region: 'CA',
  },
  {
    symbol: 'OR-CT',
    name: 'Osisko Gold Royalties Ltd',
    region: 'CA',
  },
  {
    symbol: 'ORA-CT',
    name: 'Aura Minerals Inc.',
    region: 'CA',
  },
  {
    symbol: 'ORC.A-CV',
    name: 'Orca Energy Group Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'ORC.B-CV',
    name: 'Orca Energy Group Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'ORCD-CF',
    name: 'Orchid Ventures Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'ORE-CT',
    name: 'Orezone Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'OREA-CT',
    name: 'Orea Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ORG-CV',
    name: 'Orca Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'ORGN-CF',
    name: 'Origen Resources Inc - New',
    region: 'CA',
  },
  {
    symbol: 'ORI-CF',
    name: 'Orion Nutraceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'ORM-CV',
    name: 'Orford Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ORS-CV',
    name: 'Orestone Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ORTH-CF',
    name: 'Ortho Regenerative Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ORV-CT',
    name: 'Orvana Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'ORX-CV',
    name: 'Orefinders Resources Inc- Class A',
    region: 'CA',
  },
  {
    symbol: 'OSI-CV',
    name: 'Osino Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'OSK-CT',
    name: 'Osisko Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'OSS-CV',
    name: 'OneSoft Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'OSU-CV',
    name: 'Orsu Metals Corporation',
    region: 'CA',
  },
  {
    symbol: 'OTEX-CT',
    name: 'Open Text Corp',
    region: 'CA',
  },
  {
    symbol: 'OTGO-CV',
    name: 'O2Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'OTS.H-CV',
    name: 'Optimus Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'OVAT-CF',
    name: 'Ovation Science Inc',
    region: 'CA',
  },
  {
    symbol: 'OVT-CV',
    name: 'Oculus VisionTech Inc',
    region: 'CA',
  },
  {
    symbol: 'OVV-CT',
    name: 'Ovintiv Inc',
    region: 'CA',
  },
  {
    symbol: 'OWLI-CF',
    name: 'One World Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'OYL-CV',
    name: 'CGX Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'OZ-CV',
    name: 'Outback Goldfields Corp',
    region: 'CA',
  },
  {
    symbol: 'OZAU-CV',
    name: 'Queensland Gold Hills Corp',
    region: 'CA',
  },
  {
    symbol: 'PA-CV',
    name: 'Palamina Corp',
    region: 'CA',
  },
  {
    symbol: 'PAAS-CT',
    name: 'Pan American Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'PAC-CV',
    name: 'Pacton Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'PAI-CV',
    name: 'Predictiv AI Inc.',
    region: 'CA',
  },
  {
    symbol: 'PAID-CF',
    name: 'XTM Inc',
    region: 'CA',
  },
  {
    symbol: 'PAN-CV',
    name: 'Pangolin Diamonds Corp',
    region: 'CA',
  },
  {
    symbol: 'PANO.P-CV',
    name: 'Panorama Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'PAS-CV',
    name: 'Pascal Biosciences Inc',
    region: 'CA',
  },
  {
    symbol: 'PAT-CT',
    name: 'Patriot One Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'PAU-CF',
    name: 'Provenance Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'PAY-CT',
    name: 'Payfare Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'PBF-CF',
    name: 'Planet Based Foods Global Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'PBH-CT',
    name: 'Premium Brands Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'PBIT-CF',
    name: 'POSaBIT Systems Corp',
    region: 'CA',
  },
  {
    symbol: 'PBL-CT',
    name: 'Pollard Banknote Ltd',
    region: 'CA',
  },
  {
    symbol: 'PBX-CV',
    name: 'PowerBand Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'PCL.P-CV',
    name: 'Perihelion Capital Ltd',
    region: 'CA',
  },
  {
    symbol: 'PCLO-CV',
    name: 'PharmaCielo Ltd.',
    region: 'CA',
  },
  {
    symbol: 'PCO-CV',
    name: 'Phoenix Canada Oil Co. Ltd.',
    region: 'CA',
  },
  {
    symbol: 'PCQ-CV',
    name: 'Petrolympic Ltd',
    region: 'CA',
  },
  {
    symbol: 'PD-CT',
    name: 'Precision Drilling Corp',
    region: 'CA',
  },
  {
    symbol: 'PDH-CV',
    name: 'Premier Diversified Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'PDM-CV',
    name: 'Palladium One Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'PDO-CF',
    name: 'PUDO Inc',
    region: 'CA',
  },
  {
    symbol: 'PE-CV',
    name: 'Pure Energy Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'PEA-CT',
    name: 'Pieridae Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'PEAK-CV',
    name: 'Sun Peak Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'PEGA-CV',
    name: 'Pegasus Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'PEI-CV',
    name: 'Prospera Energy Inc.',
    region: 'CA',
  },
  {
    symbol: 'PEMC-CV',
    name: 'Pacific Empire Minerals Corp.',
    region: 'CA',
  },
  {
    symbol: 'PER-CV',
    name: 'Peruvian Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'PERK-CF',
    name: 'Perk Labs Inc',
    region: 'CA',
  },
  {
    symbol: 'PERU-CV',
    name: 'Chakana Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'PET-CT',
    name: 'Pet Valu Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'PEX-CV',
    name: 'Pacific Ridge Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'PEY-CT',
    name: 'Peyto Exploration & Development Corp.',
    region: 'CA',
  },
  {
    symbol: 'PFC-CV',
    name: 'PetroFrontier Corp',
    region: 'CA',
  },
  {
    symbol: 'PFM-CV',
    name: 'ProntoForms Corp',
    region: 'CA',
  },
  {
    symbol: 'PGC-CV',
    name: 'Plato Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'PGDC-CV',
    name: 'Patagonia Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'PGE-CV',
    name: 'Group Ten Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'PGLD-CV',
    name: 'P2 Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'PGM-CV',
    name: 'Pure Gold Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'PGO.H-CV',
    name: 'Pacific Geoinfo Corporation',
    region: 'CA',
  },
  {
    symbol: 'PGOL-CF',
    name: 'Patriot Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'PGV-CV',
    name: 'Prodigy Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'PGX-CV',
    name: 'Prosper Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'PGZ-CV',
    name: 'Pan Global Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'PHA-CV',
    name: 'Premier Health of America Inc',
    region: 'CA',
  },
  {
    symbol: 'PHD-CV',
    name: 'Providence Gold Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'PHNM-CV',
    name: 'Phenom Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'PHRM-CF',
    name: 'PharmaTher Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'PHRX-CF',
    name: 'Pharmadrug Inc',
    region: 'CA',
  },
  {
    symbol: 'PHX-CT',
    name: 'PHX Energy Services Corp',
    region: 'CA',
  },
  {
    symbol: 'PIF-CT',
    name: 'Polaris Infrastructure Inc',
    region: 'CA',
  },
  {
    symbol: 'PILL-CF',
    name: 'Canntab Therapeutics Ltd',
    region: 'CA',
  },
  {
    symbol: 'PINK-CV',
    name: 'Perimeter Medical Imaging AI Inc',
    region: 'CA',
  },
  {
    symbol: 'PIPE-CT',
    name: 'Pipestone Energy Corp.',
    region: 'CA',
  },
  {
    symbol: 'PJX-CV',
    name: 'PJX Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'PKB-CF',
    name: 'PeakBirch Commerce Inc',
    region: 'CA',
  },
  {
    symbol: 'PKG-CF',
    name: 'ParcelPal Logistics Inc',
    region: 'CA',
  },
  {
    symbol: 'PKI-CT',
    name: 'Parkland Corp',
    region: 'CA',
  },
  {
    symbol: 'PKK-CF',
    name: 'Tenet Fintech Group Inc',
    region: 'CA',
  },
  {
    symbol: 'PKT-CV',
    name: 'Parkit Enterprise Inc',
    region: 'CA',
  },
  {
    symbol: 'PLA-CV',
    name: 'Plata Latina Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'PLAN-CV',
    name: 'Progressive Planet Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'PLAY-CF',
    name: 'Playground Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'PLC-CT',
    name: 'Park Lawn Corp',
    region: 'CA',
  },
  {
    symbol: 'PLO.H-CV',
    name: 'Paloma Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'PLTH-CF',
    name: 'Planet 13 Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'PLUR-CV',
    name: 'Plurilock Security Inc.',
    region: 'CA',
  },
  {
    symbol: 'PLY-CV',
    name: 'Playfair Mining Ltd.',
    region: 'CA',
  },
  {
    symbol: 'PM-CF',
    name: 'Pampa Metals Corporation',
    region: 'CA',
  },
  {
    symbol: 'PMC-CF',
    name: 'Peloton Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'PMED-CF',
    name: 'Predictmedix Inc',
    region: 'CA',
  },
  {
    symbol: 'PMET-CF',
    name: 'Patriot Battery Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'PML-CV',
    name: 'Panoro Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'PMN-CT',
    name: 'ProMIS Neurosciences Inc.',
    region: 'CA',
  },
  {
    symbol: 'PMT-CT',
    name: 'Perpetual Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'PMX-CV',
    name: 'Proam Exploration Corporation',
    region: 'CA',
  },
  {
    symbol: 'PNC.A-CT',
    name: 'Postmedia Network Canada Corp - Class C',
    region: 'CA',
  },
  {
    symbol: 'PNC.B-CT',
    name: 'Postmedia Network Canada Corp - Class NC (Variable Voting)',
    region: 'CA',
  },
  {
    symbol: 'PNE-CT',
    name: 'Pine Cliff Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'PNG-CV',
    name: 'Kraken Robotics Inc',
    region: 'CA',
  },
  {
    symbol: 'PNGC-CF',
    name: 'PNG Copper Inc',
    region: 'CA',
  },
  {
    symbol: 'PNPN-CV',
    name: 'Power Nickel Inc',
    region: 'CA',
  },
  {
    symbol: 'PNTR-CV',
    name: 'Pantera Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'POE-CV',
    name: 'Pan Orient Energy Corp.',
    region: 'CA',
  },
  {
    symbol: 'POKO-CF',
    name: 'Poko Innovations Inc',
    region: 'CA',
  },
  {
    symbol: 'POM-CT',
    name: 'Polymet Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'POND-CV',
    name: 'Pond Technologies Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'POOL-CV',
    name: 'Pool Safe Inc',
    region: 'CA',
  },
  {
    symbol: 'POR-CV',
    name: 'Portofino Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'POT-CF',
    name: 'Potent Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'POU-CT',
    name: 'Paramount Resources Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'POW-CT',
    name: 'Power Corp. Of Canada (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'PPE.H-CV',
    name: 'Pacific Paradym Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'PPL-CT',
    name: 'Pembina Pipeline Corporation',
    region: 'CA',
  },
  {
    symbol: 'PPM-CV',
    name: 'Pacific Imperial Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'PPP-CV',
    name: 'Prospector Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'PPR-CT',
    name: 'Prairie Provident Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'PPTA-CT',
    name: 'Perpetua Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'PPX-CV',
    name: 'PPX Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'PRB-CV',
    name: 'Probe Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'PREV-CF',
    name: 'PreveCeutical Medical Inc',
    region: 'CA',
  },
  {
    symbol: 'PRG-CV',
    name: 'Precipitate Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'PRH-CV',
    name: 'Pearl River Hldgs',
    region: 'CA',
  },
  {
    symbol: 'PRIZ-CF',
    name: 'Prismo Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'PRMW-CT',
    name: 'Primo Water Corporation',
    region: 'CA',
  },
  {
    symbol: 'PRN-CT',
    name: 'Profound Medical Corp',
    region: 'CA',
  },
  {
    symbol: 'PRO-CV',
    name: 'Pershimex Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'PRQ-CT',
    name: 'Petrus Resources ltd',
    region: 'CA',
  },
  {
    symbol: 'PRR-CF',
    name: 'Prospect Ridge Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'PRS-CV',
    name: 'Prism Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'PRSN-CV',
    name: 'Personas Social Incorporated',
    region: 'CA',
  },
  {
    symbol: 'PRT-CF',
    name: 'Plymouth Rock Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'PRU-CT',
    name: 'Perseus Mining Limited',
    region: 'CA',
  },
  {
    symbol: 'PRYM-CV',
    name: 'Prime Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'PSD-CT',
    name: 'Pulse Seismic Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'PSE-CF',
    name: 'Pasinex Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'PSH-CV',
    name: 'PetroShale Inc',
    region: 'CA',
  },
  {
    symbol: 'PSI-CT',
    name: 'Pason Systems Inc.',
    region: 'CA',
  },
  {
    symbol: 'PSK-CT',
    name: 'PrairieSky Royalty Ltd',
    region: 'CA',
  },
  {
    symbol: 'PSYB-CV',
    name: 'PsyBio Therapeutics Corp',
    region: 'CA',
  },
  {
    symbol: 'PSYC-CF',
    name: 'Psyched Wellness Ltd',
    region: 'CA',
  },
  {
    symbol: 'PSYG-CF',
    name: 'Psyence Group Inc',
    region: 'CA',
  },
  {
    symbol: 'PTC-CV',
    name: 'Petrox Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'PTE-CV',
    name: 'Pioneering Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'PTEC-CV',
    name: 'Principal Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'PTK-CV',
    name: 'POET Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'PTM-CT',
    name: 'Platinum Group Metals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'PTP.H-CV',
    name: 'Petrichor Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'PTS-CT',
    name: 'Points.com Inc',
    region: 'CA',
  },
  {
    symbol: 'PTU-CV',
    name: 'Purepoint Uranium Group, Inc.',
    region: 'CA',
  },
  {
    symbol: 'PTX-CF',
    name: 'Platinex Inc.',
    region: 'CA',
  },
  {
    symbol: 'PUC-CV',
    name: 'Pancontinental Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'PUL-CV',
    name: 'Pulse Oil Corp',
    region: 'CA',
  },
  {
    symbol: 'PULL-CF',
    name: 'Pure Extracts Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'PUMA-CV',
    name: 'Puma Exploration Inc.',
    region: 'CA',
  },
  {
    symbol: 'PUMP-CF',
    name: 'World Class Extractions Inc',
    region: 'CA',
  },
  {
    symbol: 'PVT-CV',
    name: 'Pivotree Inc',
    region: 'CA',
  },
  {
    symbol: 'PWM-CV',
    name: 'Power Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'PWR-CF',
    name: 'Captiva Verde Wellness Corp',
    region: 'CA',
  },
  {
    symbol: 'PWWR-QH',
    name: 'Alkaline Fuel Cell Power Corp',
    region: 'CA',
  },
  {
    symbol: 'PX-CV',
    name: 'Pelangio Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'PXI-CV',
    name: 'Planet Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'PXT-CT',
    name: 'Parex Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'PYR-CT',
    name: 'Pyrogenesis Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'PZA-CT',
    name: 'Pizza Pizza RoyaltyCorp',
    region: 'CA',
  },
  {
    symbol: 'QBAT-CF',
    name: 'Quantum Battery Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'QBR.A-CT',
    name: 'Quebecor Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'QBR.B-CT',
    name: 'Quebecor Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'QCA-CF',
    name: 'Quinsam Capital Corp.',
    region: 'CA',
  },
  {
    symbol: 'QCCU-CV',
    name: 'QC Copper and Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'QCX-CV',
    name: 'QCX Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'QEC-CT',
    name: 'Questerre Energy Corporation',
    region: 'CA',
  },
  {
    symbol: 'QEX-CV',
    name: 'QuestEx Gold & Copper Ltd.',
    region: 'CA',
  },
  {
    symbol: 'QFOR-CT',
    name: 'Q4 Inc',
    region: 'CA',
  },
  {
    symbol: 'QGR-CV',
    name: 'Q-Gold Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'QIPT-CV',
    name: 'Quipt Home Medical Corp New',
    region: 'CA',
  },
  {
    symbol: 'QIS-CV',
    name: 'Quorum Information Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'QMC-CV',
    name: 'QMC Quantum Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'QNC-CV',
    name: 'Quantum eMotion Inc',
    region: 'CA',
  },
  {
    symbol: 'QNI-CF',
    name: 'Quebec Nickel Corp',
    region: 'CA',
  },
  {
    symbol: 'QPM-CV',
    name: 'Quebec Precious Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'QPT-CV',
    name: 'Quest Pharmatech, Inc.',
    region: 'CA',
  },
  {
    symbol: 'QQ-CF',
    name: 'Quizam Media Corp',
    region: 'CA',
  },
  {
    symbol: 'QRC-CV',
    name: 'Queen`s Road Capital Investment Ltd',
    region: 'CA',
  },
  {
    symbol: 'QRO-CV',
    name: 'Quadro Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'QSR-CT',
    name: 'Restaurant Brands International Inc',
    region: 'CA',
  },
  {
    symbol: 'QST-CV',
    name: 'Questor Technology Inc',
    region: 'CA',
  },
  {
    symbol: 'QTRH-CT',
    name: 'Quarterhill Inc',
    region: 'CA',
  },
  {
    symbol: 'QUIS-CV',
    name: 'Quisitive Technology Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'QYOU-CV',
    name: 'QYOU Media Inc',
    region: 'CA',
  },
  {
    symbol: 'QZM-CV',
    name: 'Quartz Mountain Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'RABI-CV',
    name: 'AdRabbit Ltd',
    region: 'CA',
  },
  {
    symbol: 'RAD-CF',
    name: 'Radial Research Corp',
    region: 'CA',
  },
  {
    symbol: 'RAIN-CF',
    name: 'Rain City Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'RAK-CV',
    name: 'Rackla Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'RAMM-CF',
    name: 'RAMM Pharma Corp',
    region: 'CA',
  },
  {
    symbol: 'RARE-CF',
    name: 'Tactical Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'RAY.A-CT',
    name: 'Stingray Group Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'RAY.B-CT',
    name: 'Stingray Group Inc (Variable Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'RAYN-CF',
    name: 'Raindrop Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'RBA-CT',
    name: 'Ritchie Bros Auctioneers Inc',
    region: 'CA',
  },
  {
    symbol: 'RBX-CV',
    name: 'Robex Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'RBY-CT',
    name: 'Rubellite Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'RCH-CT',
    name: 'Richelieu Hardware Ltd.',
    region: 'CA',
  },
  {
    symbol: 'RCHR-CF',
    name: 'Archer Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'RCI.A-CT',
    name: 'Rogers Communications Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'RCI.B-CT',
    name: 'Rogers Communications Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'RCK-CV',
    name: 'Rock Tech Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'RCLF-CF',
    name: 'Rockcliff Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'RCR.P-CV',
    name: 'Canna 8 Investment Trust - Unit',
    region: 'CA',
  },
  {
    symbol: 'RCT-CV',
    name: 'Rochester Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'RDG-CV',
    name: 'Ridgeline Minerals Corp.',
    region: 'CA',
  },
  {
    symbol: 'RDL-CT',
    name: 'Redline Communications Group Inc',
    region: 'CA',
  },
  {
    symbol: 'RDS-CV',
    name: 'Radisson Mining Resources, Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'RDU-CV',
    name: 'Radius Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'RDY-CF',
    name: 'Ready Set Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'RE-CV',
    name: 'RE Royalties Ltd',
    region: 'CA',
  },
  {
    symbol: 'READ-CF',
    name: 'Legible Inc',
    region: 'CA',
  },
  {
    symbol: 'REAX-CV',
    name: 'The Real Brokerage Inc.',
    region: 'CA',
  },
  {
    symbol: 'RECO-CV',
    name: 'Reconnaissance Energy Africa Ltd',
    region: 'CA',
  },
  {
    symbol: 'RECP-CT',
    name: 'Recipe Unlimited Corp',
    region: 'CA',
  },
  {
    symbol: 'REG-CV',
    name: 'Regulus Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'REKO-CV',
    name: 'Reko International Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'REO-CF',
    name: 'Reservoir Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'RET-CV',
    name: 'Reitmans Ltd.',
    region: 'CA',
  },
  {
    symbol: 'RET.A-CV',
    name: 'Reitmans Ltd. - Class A',
    region: 'CA',
  },
  {
    symbol: 'REVO-CV',
    name: 'RevoluGROUP Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'REX-CV',
    name: 'Orex Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'REYG-CV',
    name: 'Reyna Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'REZN-CF',
    name: 'Hash Corporation (The)',
    region: 'CA',
  },
  {
    symbol: 'RFP-CT',
    name: 'Resolute Forest Products Inc',
    region: 'CA',
  },
  {
    symbol: 'RFR-CF',
    name: 'Renforth Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'RG-CV',
    name: 'Romios Gold Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'RGD-CV',
    name: 'Reunion Gold Corporation',
    region: 'CA',
  },
  {
    symbol: 'RGG-CV',
    name: 'React Gaming Group Inc',
    region: 'CA',
  },
  {
    symbol: 'RGI-CV',
    name: 'Reco International Group Inc',
    region: 'CA',
  },
  {
    symbol: 'RGLD-CF',
    name: 'Red Lake Gold Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'RGM.H-CV',
    name: 'Remington Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'RHC-CV',
    name: 'Royal Helium Ltd',
    region: 'CA',
  },
  {
    symbol: 'RHT-CV',
    name: 'Reliq Health Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'RIO-CV',
    name: 'Rio2 Ltd.',
    region: 'CA',
  },
  {
    symbol: 'RISE-CF',
    name: 'Rise Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'RIV-CF',
    name: 'RIV Capital Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'RJX.A-CV',
    name: 'RJK Explorations Ltd',
    region: 'CA',
  },
  {
    symbol: 'RK-CV',
    name: 'Rockhaven Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'RKR-CV',
    name: 'Rokmaster Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'RKV-CV',
    name: 'Rakovina Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'RLG-CF',
    name: 'West Red Lake Gold Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'RLP-CV',
    name: 'Realia Properties Inc',
    region: 'CA',
  },
  {
    symbol: 'RLYG-CV',
    name: 'Riley Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'RMD-CV',
    name: 'Richmond Minerals Inc.',
    region: 'CA',
  },
  {
    symbol: 'RMI-CV',
    name: 'Ridgestone Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'RML-CV',
    name: 'Rusoro Mining Ltd.',
    region: 'CA',
  },
  {
    symbol: 'RMO-CV',
    name: 'Rainy Mountain Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'RNW-CT',
    name: 'TransAlta Renewables Inc',
    region: 'CA',
  },
  {
    symbol: 'ROCK-CV',
    name: 'Rockridge Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ROI-CV',
    name: 'Route1 Inc.',
    region: 'CA',
  },
  {
    symbol: 'ROK-CV',
    name: 'ROK Resources Inc - Class B',
    region: 'CA',
  },
  {
    symbol: 'ROMJ-CV',
    name: 'Rubicon Organics Inc',
    region: 'CA',
  },
  {
    symbol: 'ROO-CF',
    name: 'RooGold Inc',
    region: 'CA',
  },
  {
    symbol: 'ROOF-CV',
    name: 'Northstar Clean Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ROOT-CT',
    name: 'Roots Corp',
    region: 'CA',
  },
  {
    symbol: 'ROS-CV',
    name: 'Roscan Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'ROVR-CV',
    name: 'Rover Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'ROX-CV',
    name: 'Canstar Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'RP-CV',
    name: 'Replicel Life Sciences Inc',
    region: 'CA',
  },
  {
    symbol: 'RPP-CV',
    name: 'Regent Pacific Properties Inc',
    region: 'CA',
  },
  {
    symbol: 'RPX-CV',
    name: 'Red Pine Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'RR-CF',
    name: 'Recharge Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'RRI-CV',
    name: 'Riverside Resources Inc - New',
    region: 'CA',
  },
  {
    symbol: 'RRS-CV',
    name: 'Rogue Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'RSF-CF',
    name: 'Rritual Superfoods Inc',
    region: 'CA',
  },
  {
    symbol: 'RSI-CT',
    name: 'Rogers Sugar Inc',
    region: 'CA',
  },
  {
    symbol: 'RSLV-CV',
    name: 'Reyna Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'RSS-CV',
    name: 'Resaas Services Inc',
    region: 'CA',
  },
  {
    symbol: 'RTG-CT',
    name: 'RTG Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'RTH-CV',
    name: 'Rathdowney Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'RTI-CV',
    name: 'Radient Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'RTM-CV',
    name: 'RT Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'RUG-CV',
    name: 'Rugby Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'RUM-CV',
    name: 'Rocky Mountain Liquor Inc',
    region: 'CA',
  },
  {
    symbol: 'RUN.H-CV',
    name: 'Running Fox Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'RUP-CV',
    name: 'Rupert Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'RUS-CT',
    name: 'Russel Metals Inc.',
    region: 'CA',
  },
  {
    symbol: 'RVG-CV',
    name: 'Revival Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'RVR-CF',
    name: 'Rift Valley Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'RVV-CF',
    name: 'Revive Therapeutics Ltd',
    region: 'CA',
  },
  {
    symbol: 'RVX-CT',
    name: 'Resverlogix Corp.',
    region: 'CA',
  },
  {
    symbol: 'RW-CV',
    name: 'RenoWorks Software Inc',
    region: 'CA',
  },
  {
    symbol: 'RWB-CF',
    name: 'Red White & Bloom Brands Inc.',
    region: 'CA',
  },
  {
    symbol: 'RX-CV',
    name: 'Biosyent Inc',
    region: 'CA',
  },
  {
    symbol: 'RXM-CF',
    name: 'Rockex Mining Corporation',
    region: 'CA',
  },
  {
    symbol: 'RY-CT',
    name: 'Royal Bank Of Canada',
    region: 'CA',
  },
  {
    symbol: 'RYE-CV',
    name: 'Rhyolite Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'RYO-CV',
    name: 'Rio Silver Inc',
    region: 'CA',
  },
  {
    symbol: 'RYR-CV',
    name: 'Royal Road Minerals Limited',
    region: 'CA',
  },
  {
    symbol: 'RYU-CV',
    name: 'RYU Apparel Inc',
    region: 'CA',
  },
  {
    symbol: 'RZE-CV',
    name: 'Razor Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'RZR-CF',
    name: 'Razore Rock Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'S-CT',
    name: 'Sherritt International Corp.',
    region: 'CA',
  },
  {
    symbol: 'SA.H-CV',
    name: 'Southern Arc Minerals Inc. Class A',
    region: 'CA',
  },
  {
    symbol: 'SAE-CV',
    name: 'Sable Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'SAG-CV',
    name: 'Sterling Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'SAI.H-CV',
    name: 'Sunshine Agri-Tech Inc',
    region: 'CA',
  },
  {
    symbol: 'SALT-CV',
    name: 'Atlas Salt Inc',
    region: 'CA',
  },
  {
    symbol: 'SAM-CT',
    name: 'Starcore International Mines Ltd',
    region: 'CA',
  },
  {
    symbol: 'SAND-CF',
    name: 'Silver Sands Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'SAO-CV',
    name: 'South Atlantic Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'SAP-CT',
    name: 'Saputo Inc',
    region: 'CA',
  },
  {
    symbol: 'SASY-CF',
    name: 'Sassy Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'SAT-CV',
    name: 'Asian Television Network International Ltd',
    region: 'CA',
  },
  {
    symbol: 'SAU-CT',
    name: 'St. Augustine Gold and Copper Ltd',
    region: 'CA',
  },
  {
    symbol: 'SAY-CV',
    name: 'Sparta Capital Ltd.',
    region: 'CA',
  },
  {
    symbol: 'SB-CV',
    name: 'Stratabound Minerals Corp.',
    region: 'CA',
  },
  {
    symbol: 'SBB-CT',
    name: 'Sabina Gold & Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'SBBC-CV',
    name: 'Simply Better Brands Corp',
    region: 'CA',
  },
  {
    symbol: 'SBD-CF',
    name: 'SBD Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'SBI-CT',
    name: 'Serabi Gold Plc',
    region: 'CA',
  },
  {
    symbol: 'SBM-CV',
    name: 'Sirona Biochem Corp',
    region: 'CA',
  },
  {
    symbol: 'SBO.H-CV',
    name: 'Schwabo Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'SBR-CT',
    name: 'Silver Bear Resources plc',
    region: 'CA',
  },
  {
    symbol: 'SBS.H-CV',
    name: 'Sebastiani Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'SC.H-CV',
    name: 'Serrano Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SCAN-CV',
    name: 'Liberty Defense Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'SCD-CV',
    name: 'Samoth Oilfield Inc',
    region: 'CA',
  },
  {
    symbol: 'SCL-CT',
    name: 'ShawCor Ltd.',
    region: 'CA',
  },
  {
    symbol: 'SCLT-CV',
    name: 'Searchlight Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'SCOT-CV',
    name: 'Scottie Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'SCRN-CF',
    name: 'ScreenPro Security Inc',
    region: 'CA',
  },
  {
    symbol: 'SCT-CV',
    name: 'Skychain Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'SCV-CF',
    name: 'Scotch Creek Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'SCY-CT',
    name: 'Scandium International Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'SCYB-CF',
    name: 'Scryb Inc',
    region: 'CA',
  },
  {
    symbol: 'SCZ-CV',
    name: 'Santacruz Silver Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'SDE-CT',
    name: 'Spartan Delta Corp.',
    region: 'CA',
  },
  {
    symbol: 'SDI-CV',
    name: 'Stampede Drilling Inc',
    region: 'CA',
  },
  {
    symbol: 'SDR-CV',
    name: 'Stroud Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'SE-CF',
    name: 'Sweet Earth Holdings Corporation',
    region: 'CA',
  },
  {
    symbol: 'SEA-CT',
    name: 'Seabridge Gold, Inc.',
    region: 'CA',
  },
  {
    symbol: 'SEAG-CF',
    name: 'Seahawk Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SEB-CV',
    name: 'Smart Employee Benefits Inc',
    region: 'CA',
  },
  {
    symbol: 'SECU-CV',
    name: 'SSC Security Services Corp',
    region: 'CA',
  },
  {
    symbol: 'SEI-CV',
    name: 'Sintana Energy Inc.',
    region: 'CA',
  },
  {
    symbol: 'SEK-CF',
    name: 'Secova Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'SENS-CF',
    name: 'Sensor Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'SES-CT',
    name: 'Secure Energy Services Inc',
    region: 'CA',
  },
  {
    symbol: 'SEV-CV',
    name: 'Spectra7 Microsystems Inc',
    region: 'CA',
  },
  {
    symbol: 'SFC-CT',
    name: 'Sagicor Financial Company Ltd',
    region: 'CA',
  },
  {
    symbol: 'SFD-CT',
    name: 'NXT Energy Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'SFI-CT',
    name: 'Solution Financial Inc.',
    region: 'CA',
  },
  {
    symbol: 'SFR-CV',
    name: 'Sandfire Resources America Inc',
    region: 'CA',
  },
  {
    symbol: 'SFT-CV',
    name: 'Softrock Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'SFTC-CT',
    name: 'Softchoice Corporation.',
    region: 'CA',
  },
  {
    symbol: 'SGC-CV',
    name: 'Solstice Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'SGD-CF',
    name: 'Snowline Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SGI-CV',
    name: 'Superior Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'SGLD-CT',
    name: 'Sabre Gold Mines Corp',
    region: 'CA',
  },
  {
    symbol: 'SGMD-CV',
    name: 'Salona Global Medical Device Corporation',
    region: 'CA',
  },
  {
    symbol: 'SGML-CV',
    name: 'Sigma Lithium Corporation',
    region: 'CA',
  },
  {
    symbol: 'SGN-CV',
    name: 'Scorpio Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SGO-CV',
    name: 'Sonoro Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SGQ-CT',
    name: 'Southgobi Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SGRO-CF',
    name: 'Sierra Grande Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'SGU-CV',
    name: 'Signature Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SGY-CT',
    name: 'Surge Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'SGZ-CV',
    name: 'Sego Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SHL-CV',
    name: 'Spruce Ridge Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SHLE-CT',
    name: 'Source Energy Services Ltd',
    region: 'CA',
  },
  {
    symbol: 'SHOP-CT',
    name: 'Shopify Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'SHP-CF',
    name: 'Shoal Point Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'SHRC-CF',
    name: 'Sharc International Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'SHRP-CV',
    name: 'Sherpa II Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'SIA-CT',
    name: 'Sienna Senior Living Inc',
    region: 'CA',
  },
  {
    symbol: 'SIC-CV',
    name: 'Sokoman Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'SIE-CV',
    name: 'Sienna Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SIG-CF',
    name: 'Sitka Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SIL-CT',
    name: 'SilverCrest Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'SILV-CV',
    name: 'Silver Valley Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'SIQ.H-CV',
    name: 'SIQ Mountain Industries Inc',
    region: 'CA',
  },
  {
    symbol: 'SIS-CT',
    name: 'Savaria Corp.',
    region: 'CA',
  },
  {
    symbol: 'SIX-CF',
    name: 'Sixty Six Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'SIXW-CF',
    name: 'Sixth Wave Innovations Inc',
    region: 'CA',
  },
  {
    symbol: 'SIZE-QH',
    name: 'Major Precious Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'SJ-CT',
    name: 'Stella-Jones Inc.',
    region: 'CA',
  },
  {
    symbol: 'SJR.A-CV',
    name: 'Shaw Communications Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'SJR.B-CT',
    name: 'Shaw Communications Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'SKE-CT',
    name: 'Skeena Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SKK-CV',
    name: 'Strikewell Energy Corporation',
    region: 'CA',
  },
  {
    symbol: 'SKLL-CF',
    name: 'Royal Wins Corporation',
    region: 'CA',
  },
  {
    symbol: 'SKP-CV',
    name: 'Strikepoint Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'SKRR-CV',
    name: 'SKRR Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'SKUR-CF',
    name: 'Sekur Private Data Ltd',
    region: 'CA',
  },
  {
    symbol: 'SKYG-CV',
    name: 'Sky Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SLF-CT',
    name: 'Sun Life Financial, Inc.',
    region: 'CA',
  },
  {
    symbol: 'SLG-CV',
    name: 'San Lorenzo Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'SLHG-CV',
    name: 'Skylight Health Group Inc',
    region: 'CA',
  },
  {
    symbol: 'SLI-CV',
    name: 'Standard Lithium Ltd',
    region: 'CA',
  },
  {
    symbol: 'SLK.H-CV',
    name: 'Silk Road Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'SLMN-CV',
    name: 'Solis Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'SLR-CT',
    name: 'Solitario Zinc Corp',
    region: 'CA',
  },
  {
    symbol: 'SLS-CT',
    name: 'Solaris Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SLV-CF',
    name: 'Silver Dollar Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SLVR-CV',
    name: 'Silver Tiger Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'SLZ-CF',
    name: 'Slave Lake Zinc Corp',
    region: 'CA',
  },
  {
    symbol: 'SM-CV',
    name: 'Sierra Madre Gold and Silver Ltd',
    region: 'CA',
  },
  {
    symbol: 'SMC-CT',
    name: 'Sulliden Mining Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'SMD-CV',
    name: 'Strategic Metals Ltd',
    region: 'CA',
  },
  {
    symbol: 'SME-CV',
    name: 'Sama Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SML-CV',
    name: 'Southstone Minerals Limited',
    region: 'CA',
  },
  {
    symbol: 'SMN-CV',
    name: 'Sun Summit Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'SMP-CV',
    name: 'Southern Empire Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'SMR-CV',
    name: 'Shine Minerals Corp.',
    region: 'CA',
  },
  {
    symbol: 'SMRV-CV',
    name: 'Smooth Rock Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'SMT-CT',
    name: 'Sierra Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'SMY-CV',
    name: 'Search Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'SNA-CF',
    name: 'Star Navigation Systems Group',
    region: 'CA',
  },
  {
    symbol: 'SNC-CT',
    name: 'SNC - Lavalin Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'SNG-CV',
    name: 'Silver Range Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SNM-CV',
    name: 'Shamaran Petroleum Corp',
    region: 'CA',
  },
  {
    symbol: 'SNR-CF',
    name: '79 Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SNS-CV',
    name: 'Select Sands Corp',
    region: 'CA',
  },
  {
    symbol: 'SOI-CV',
    name: 'Sirios Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SOIL-CV',
    name: 'Saturn Oil & Gas Inc',
    region: 'CA',
  },
  {
    symbol: 'SOL-CF',
    name: 'SOL Global Investments Corp',
    region: 'CA',
  },
  {
    symbol: 'SOLG-CT',
    name: 'SolGold Plc',
    region: 'CA',
  },
  {
    symbol: 'SOLR-CV',
    name: 'Solar Alliance Energy Inc.',
    region: 'CA',
  },
  {
    symbol: 'SOLV-CF',
    name: 'SoLVBL Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'SOMA-CV',
    name: 'Soma Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SONA-CF',
    name: 'Sona Nanotech Inc.',
    region: 'CA',
  },
  {
    symbol: 'SOP.H-CV',
    name: 'SOPerior Fertilizer Corp',
    region: 'CA',
  },
  {
    symbol: 'SOU-CV',
    name: 'Southern Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'SOY-CT',
    name: 'Sunopta, Inc.',
    region: 'CA',
  },
  {
    symbol: 'SPA-CV',
    name: 'Spanish Mountain Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'SPB-CT',
    name: 'Superior Plus Corp',
    region: 'CA',
  },
  {
    symbol: 'SPC-CV',
    name: 'SPC Nickel Corp.',
    region: 'CA',
  },
  {
    symbol: 'SPD-CV',
    name: 'Silver Predator Corp',
    region: 'CA',
  },
  {
    symbol: 'SPFY-CF',
    name: 'Spacefy Inc',
    region: 'CA',
  },
  {
    symbol: 'SPG-CT',
    name: 'Spark Power Group Inc',
    region: 'CA',
  },
  {
    symbol: 'SPI-CV',
    name: 'Canadian Spirit Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'SPMT-CF',
    name: 'Spearmint Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SPN-CV',
    name: 'Snipp Interactive Inc',
    region: 'CA',
  },
  {
    symbol: 'SPO-CF',
    name: 'SponsorsOne Inc',
    region: 'CA',
  },
  {
    symbol: 'SPOR-CF',
    name: 'New Wave Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'SPOT-CV',
    name: 'GoldSpot Discoveries Corp',
    region: 'CA',
  },
  {
    symbol: 'SPP-CV',
    name: 'Spot Coffee (Canada) Ltd',
    region: 'CA',
  },
  {
    symbol: 'SPR-CF',
    name: 'Sproutly Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'SPX-CV',
    name: 'Stellar AfricaGold Inc',
    region: 'CA',
  },
  {
    symbol: 'SQD-CV',
    name: 'SQI Diagnostics Inc',
    region: 'CA',
  },
  {
    symbol: 'SQID-CF',
    name: 'SQID Technologies Limited',
    region: 'CA',
  },
  {
    symbol: 'SQX-CF',
    name: 'Squatex Energy and Ressources Inc.',
    region: 'CA',
  },
  {
    symbol: 'SR-CV',
    name: 'Strategic Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SRA-CV',
    name: 'Stria Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'SRC-CV',
    name: 'Stakeholder Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'SRE-CV',
    name: 'Saville Resources, Inc.',
    region: 'CA',
  },
  {
    symbol: 'SRG-CV',
    name: 'SRG Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'SRI-CV',
    name: 'Sparton Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'SRL-CV',
    name: 'Salazar Resources Limited',
    region: 'CA',
  },
  {
    symbol: 'SSA-CV',
    name: 'Spectra Products Inc',
    region: 'CA',
  },
  {
    symbol: 'SSE-CV',
    name: 'Silver Spruce Resources, Inc.',
    region: 'CA',
  },
  {
    symbol: 'SSL-CT',
    name: 'Sandstorm Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'SSRM-CT',
    name: 'SSR Mining Inc',
    region: 'CA',
  },
  {
    symbol: 'SSSS.P-CV',
    name: 'Shooting Star Acquisition Corp.',
    region: 'CA',
  },
  {
    symbol: 'SSV-CV',
    name: 'Southern Silver Exploration Corp.',
    region: 'CA',
  },
  {
    symbol: 'SSVR-CV',
    name: 'Summa Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'ST-CF',
    name: 'Straightup Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'STA-CV',
    name: 'Sanatana Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'STAG-CF',
    name: 'St. Anthony Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'STC-CT',
    name: 'Sangoma Technologies Corporation',
    region: 'CA',
  },
  {
    symbol: 'STE-CV',
    name: 'Starr Peak Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'STEM-CF',
    name: 'Stem Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'STEP-CT',
    name: 'STEP Energy Services Ltd',
    region: 'CA',
  },
  {
    symbol: 'STG-CV',
    name: 'Stone Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'STGO-CT',
    name: 'Steppe Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'STH-CV',
    name: 'Stelmine Canada Ltd',
    region: 'CA',
  },
  {
    symbol: 'STLC-CT',
    name: 'Stelco Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'STMP-CV',
    name: 'Stamper Oil & Gas Corp',
    region: 'CA',
  },
  {
    symbol: 'STN-CT',
    name: 'Stantec Inc',
    region: 'CA',
  },
  {
    symbol: 'STND-CV',
    name: 'Standard Uranium Ltd',
    region: 'CA',
  },
  {
    symbol: 'STPH-CF',
    name: 'Steep Hill Inc',
    region: 'CA',
  },
  {
    symbol: 'STR.H-CV',
    name: 'Santa Rosa Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'STRR-CV',
    name: 'Star Royalties Ltd.',
    region: 'CA',
  },
  {
    symbol: 'STS-CV',
    name: 'South Star Battery Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'STU-CV',
    name: 'Stuhini Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'STUD-CV',
    name: 'Stallion Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'STUV-CV',
    name: 'Stuve Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'STWO-CF',
    name: 'S2 Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'STX-CF',
    name: 'Starrex International Ltd',
    region: 'CA',
  },
  {
    symbol: 'SU-CT',
    name: 'Suncor Energy, Inc.',
    region: 'CA',
  },
  {
    symbol: 'SUGR-CV',
    name: 'SugarBud Craft Growers Corp',
    region: 'CA',
  },
  {
    symbol: 'SUI-CV',
    name: 'Superior Mining International Corporation',
    region: 'CA',
  },
  {
    symbol: 'SUP-CV',
    name: 'Northern Superior Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SUPR-CF',
    name: 'Supernova Metals Corp. - Class A',
    region: 'CA',
  },
  {
    symbol: 'SURG-CV',
    name: 'Surge Copper Corp.',
    region: 'CA',
  },
  {
    symbol: 'SUU.H-CV',
    name: 'Strathmore Plus Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'SVA-CV',
    name: 'Sernova Corp.',
    region: 'CA',
  },
  {
    symbol: 'SVB-CT',
    name: 'Silver Bull Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SVE-CV',
    name: 'Silver One Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'SVG-CV',
    name: 'Silver Grail Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SVI-CT',
    name: 'Storagevault Canada Inc',
    region: 'CA',
  },
  {
    symbol: 'SVM-CT',
    name: 'Silvercorp Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'SVP.H-CV',
    name: 'Sceptre Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'SVS-CV',
    name: 'Solarvest BioEnergy Inc',
    region: 'CA',
  },
  {
    symbol: 'SVTN-CV',
    name: 'Silverton Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'SW-CT',
    name: 'Sierra Wireless Inc',
    region: 'CA',
  },
  {
    symbol: 'SWA-CV',
    name: 'Sarama Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SWLF-CV',
    name: 'Silver Wolf Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'SWP-CT',
    name: 'Swiss Water Decaffeinated Coffee Inc',
    region: 'CA',
  },
  {
    symbol: 'SWRM-CF',
    name: 'Swarmio Media Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'SX-CF',
    name: 'St-Georges Eco-Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'SXI-CT',
    name: 'Synex Renewable Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'SXL-CV',
    name: 'Slam Exploration Ltd',
    region: 'CA',
  },
  {
    symbol: 'SXP-CT',
    name: 'Supremex Inc',
    region: 'CA',
  },
  {
    symbol: 'SXTY-CF',
    name: 'Sixty North Gold Mining Ltd',
    region: 'CA',
  },
  {
    symbol: 'SYG-CV',
    name: 'Sylla Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'SYH-CV',
    name: 'Skyharbour Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'SYZ-CT',
    name: 'Sylogist, Ltd',
    region: 'CA',
  },
  {
    symbol: 'SZLS-CT',
    name: 'StageZero Life Sciences Ltd',
    region: 'CA',
  },
  {
    symbol: 'T-CT',
    name: 'Telus Corp.',
    region: 'CA',
  },
  {
    symbol: 'TA-CT',
    name: 'Transalta Corp.',
    region: 'CA',
  },
  {
    symbol: 'TAAL-CF',
    name: 'Taal Distributed Information Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'TAAT-CF',
    name: 'TAAT Global Alternatives Inc',
    region: 'CA',
  },
  {
    symbol: 'TAI-CF',
    name: 'Talmora Diamond Inc',
    region: 'CA',
  },
  {
    symbol: 'TAIG-CT',
    name: 'Taiga Motors Corporation - Class A (Restricted Voting)',
    region: 'CA',
  },
  {
    symbol: 'TAJ-CV',
    name: 'Tajiri Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'TAL-CV',
    name: 'PetroTal Corp',
    region: 'CA',
  },
  {
    symbol: 'TAO-CV',
    name: 'Tag Oil Ltd',
    region: 'CA',
  },
  {
    symbol: 'TAU-CV',
    name: 'Thesis Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'TBIX-CV',
    name: 'TrustBIX Inc',
    region: 'CA',
  },
  {
    symbol: 'TBK-CV',
    name: 'Trailbreaker Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TBL-CT',
    name: 'Taiga Building Products Ltd',
    region: 'CA',
  },
  {
    symbol: 'TBLL-CV',
    name: 'Tombill Mines Limited.',
    region: 'CA',
  },
  {
    symbol: 'TBP-CT',
    name: 'Tetra Bio-Pharma Inc',
    region: 'CA',
  },
  {
    symbol: 'TBR-CV',
    name: 'Timberline Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'TBRD-CV',
    name: 'Thunderbird Entertainment Group Inc',
    region: 'CA',
  },
  {
    symbol: 'TBX-CV',
    name: 'Turmalina Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'TC-CT',
    name: 'Tucows, Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'TCA-CV',
    name: 'Timia Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'TCAN-CF',
    name: 'Transcanna Holdings Inc.',
    region: 'CA',
  },
  {
    symbol: 'TCC-CF',
    name: 'Trenchant Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'TCG.P-CV',
    name: 'Transcontinental Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TCL.A-CT',
    name: 'Transcontinental, Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'TCL.B-CT',
    name: 'Transcontinental, Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'TCO-CV',
    name: 'Transatlantic Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'TCS-CT',
    name: 'Tecsys Inc',
    region: 'CA',
  },
  {
    symbol: 'TCW-CT',
    name: 'Trican Well Service, Ltd.',
    region: 'CA',
  },
  {
    symbol: 'TD-CT',
    name: 'Toronto Dominion Bank',
    region: 'CA',
  },
  {
    symbol: 'TDG-CV',
    name: 'TDG Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'TEA-CV',
    name: 'Tearlach Resources Limited',
    region: 'CA',
  },
  {
    symbol: 'TEAM-CF',
    name: 'Canadian Nexus Team Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'TECK.A-CT',
    name: 'Teck Resources Ltd - Class A',
    region: 'CA',
  },
  {
    symbol: 'TECK.B-CT',
    name: 'Teck Resources Ltd - Class B',
    region: 'CA',
  },
  {
    symbol: 'TECT-CV',
    name: 'Tectonic Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'TELI-CF',
    name: 'Telescope Innovations Corp',
    region: 'CA',
  },
  {
    symbol: 'TELO-CV',
    name: 'Telo Genomics Corp',
    region: 'CA',
  },
  {
    symbol: 'TEM-CV',
    name: 'Tembo Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'TEQ-CF',
    name: 'Terranueva Corp',
    region: 'CA',
  },
  {
    symbol: 'TER-CF',
    name: 'TerrAscend Corp',
    region: 'CA',
  },
  {
    symbol: 'TES-CV',
    name: 'Tesoro Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'TEST-CV',
    name: 'FluroTech Ltd',
    region: 'CA',
  },
  {
    symbol: 'TEVO-CF',
    name: 'Tevano Systems Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'TFII-CT',
    name: 'TFI International Inc',
    region: 'CA',
  },
  {
    symbol: 'TFM-CV',
    name: 'Transforma Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'TG-CV',
    name: 'Trifecta Gold Ltd',
    region: 'CA',
  },
  {
    symbol: 'TGH-CV',
    name: 'Tornado Global Hydrovacs Ltd',
    region: 'CA',
  },
  {
    symbol: 'TGIF-CF',
    name: '1933 Industries Inc',
    region: 'CA',
  },
  {
    symbol: 'TGII-CF',
    name: 'Trojan Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'TGL-CT',
    name: 'Transglobe Energy Corp.',
    region: 'CA',
  },
  {
    symbol: 'TGM-CV',
    name: 'Trillium Gold Mines Inc.',
    region: 'CA',
  },
  {
    symbol: 'TGO-CT',
    name: 'TeraGo Inc.',
    region: 'CA',
  },
  {
    symbol: 'TGOD-CF',
    name: 'Green Organic Dutchman Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'TGX-CV',
    name: 'True North Gems Inc',
    region: 'CA',
  },
  {
    symbol: 'TH-CT',
    name: 'Theratechnologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'THC-CF',
    name: 'THC Biomed International Ltd',
    region: 'CA',
  },
  {
    symbol: 'THM-CV',
    name: 'Thunder Mountain Gold, Inc.',
    region: 'CA',
  },
  {
    symbol: 'THNC-CT',
    name: 'Thinkific Labs Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'THNK-CV',
    name: 'Think Research Corporation',
    region: 'CA',
  },
  {
    symbol: 'THP-CV',
    name: 'Totally Hip Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'THRM-CV',
    name: 'Therma Bright Inc',
    region: 'CA',
  },
  {
    symbol: 'THX-CV',
    name: 'Thor Explorations Ltd',
    region: 'CA',
  },
  {
    symbol: 'TI-CT',
    name: 'Titan Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'TIE-CV',
    name: 'Coloured Ties Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'TIG-CV',
    name: 'Triumph Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TIH-CT',
    name: 'Toromont Industries Ltd.',
    region: 'CA',
  },
  {
    symbol: 'TIL-CV',
    name: 'Till Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'TILT-QH',
    name: 'TILT Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'TIM-CF',
    name: 'QNB Metals Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'TIP.H-CV',
    name: 'Tyner Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TIXT-CT',
    name: 'TELUS International (Cda) Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'TK-CV',
    name: 'Tinka Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TKO-CT',
    name: 'Taseko Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'TKU-CV',
    name: 'Tarku Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TKX-CV',
    name: 'TrackX Holdings Inc',
    region: 'CA',
  },
  {
    symbol: 'TLA-CV',
    name: 'Titan Logic Corp',
    region: 'CA',
  },
  {
    symbol: 'TLG-CT',
    name: 'Troilus Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TLO-CT',
    name: 'Talon Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'TLRY-CT',
    name: 'Tilray Brands Inc - Class 2',
    region: 'CA',
  },
  {
    symbol: 'TLT-CV',
    name: 'Theralase Technologies Inc.',
    region: 'CA',
  },
  {
    symbol: 'TM-CV',
    name: 'Trigon Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'TMAS-CF',
    name: 'Temas Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'TMC-CV',
    name: 'Trench Metals Corp. - Class A',
    region: 'CA',
  },
  {
    symbol: 'TMD-CT',
    name: 'Titan Medical Inc',
    region: 'CA',
  },
  {
    symbol: 'TMED-CF',
    name: 'EGF Theramed Health Corp',
    region: 'CA',
  },
  {
    symbol: 'TMET-CV',
    name: 'Torr Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'TMG-CV',
    name: 'Thermal Energy International Inc',
    region: 'CA',
  },
  {
    symbol: 'TML-CT',
    name: 'Treasury Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'TMQ-CT',
    name: 'Trilogy Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'TMRR-CV',
    name: 'Tempus Resources LTD',
    region: 'CA',
  },
  {
    symbol: 'TMS.H-CV',
    name: 'Targeted Microwave Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'TN-CF',
    name: 'Tartisan Nickel Corp',
    region: 'CA',
  },
  {
    symbol: 'TNA-CV',
    name: 'Evergreen Gaming Corporation',
    region: 'CA',
  },
  {
    symbol: 'TNO.H-CV',
    name: 'Terreno Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'TNR-CV',
    name: 'TNR Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'TNX-CT',
    name: 'Tanzanian Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TNY-CF',
    name: 'Tinley Beverage Co Inc',
    region: 'CA',
  },
  {
    symbol: 'TNZ-CV',
    name: 'Tenaz Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'TO-CF',
    name: 'Tower One Wireless Corp',
    region: 'CA',
  },
  {
    symbol: 'TOC-CF',
    name: 'Tocvan Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'TOH-CV',
    name: 'Total Helium Ltd',
    region: 'CA',
  },
  {
    symbol: 'TOI-CV',
    name: 'Topicus.com Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'TONY-CF',
    name: 'Tony G Co-Investment Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'TORO-CV',
    name: 'Pucara Gold Ltd.',
    region: 'CA',
  },
  {
    symbol: 'TORQ-CV',
    name: 'Torq Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'TORR-CV',
    name: 'Torrent Capital Ltd',
    region: 'CA',
  },
  {
    symbol: 'TOT-CT',
    name: 'Total Energy Services Inc',
    region: 'CA',
  },
  {
    symbol: 'TOU-CT',
    name: 'Tourmaline Oil Corp',
    region: 'CA',
  },
  {
    symbol: 'TOY-CT',
    name: 'Spin Master Corp (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'TPC-CV',
    name: 'Tenth Avenue Petroleum Corp',
    region: 'CA',
  },
  {
    symbol: 'TPL-CV',
    name: 'Tethys Petroleum Limited',
    region: 'CA',
  },
  {
    symbol: 'TPX.A-CT',
    name: 'Molson Coors Canada Inc (Exchangeable Shares) - Class A',
    region: 'CA',
  },
  {
    symbol: 'TPX.B-CT',
    name: 'Molson Coors Canada Inc (Exchangeable Shares) - Class B',
    region: 'CA',
  },
  {
    symbol: 'TPZ-CT',
    name: 'Topaz Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'TR-CV',
    name: 'Troubadour Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'TRA-CV',
    name: 'Teras Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'TRAC-CF',
    name: 'Traction Uranium Corp',
    region: 'CA',
  },
  {
    symbol: 'TRBE-CV',
    name: 'Tribe Property Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'TRC-CV',
    name: 'Tisdale Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'TRG-CF',
    name: 'Tarachi Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TRI-CT',
    name: 'Thomson-Reuters Corp',
    region: 'CA',
  },
  {
    symbol: 'TRIP-CF',
    name: 'Red Light Holland Corp',
    region: 'CA',
  },
  {
    symbol: 'TRL-CT',
    name: 'Trilogy International Partners Inc',
    region: 'CA',
  },
  {
    symbol: 'TRO-CV',
    name: 'Taranis Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'TRP-CT',
    name: 'TC Energy Corporation',
    region: 'CA',
  },
  {
    symbol: 'TRQ-CT',
    name: 'Turquoise Hill Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TRS-CV',
    name: 'Tres-Or Resources',
    region: 'CA',
  },
  {
    symbol: 'TRU-CV',
    name: 'TRU Precious Metals Corp.',
    region: 'CA',
  },
  {
    symbol: 'TRUE-CF',
    name: 'Treatment.com International Inc',
    region: 'CA',
  },
  {
    symbol: 'TRUL-CF',
    name: 'Trulieve Cannabis Corp (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'TRYP-CF',
    name: 'Tryp Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'TRZ-CT',
    name: 'Transat A.T., Inc.',
    region: 'CA',
  },
  {
    symbol: 'TSAT-CT',
    name: 'Telesat Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'TSD-CV',
    name: 'Tsodilo Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TSG-CV',
    name: 'Tri-Star Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TSK-CT',
    name: 'Talisker Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TSL-CT',
    name: 'Tree Island Steel Ltd',
    region: 'CA',
  },
  {
    symbol: 'TSLV-CV',
    name: 'Tier One Silver Inc',
    region: 'CA',
  },
  {
    symbol: 'TSU-CT',
    name: 'Trisura Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'TTG-CV',
    name: 'Trans Canada Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TTR-CV',
    name: 'Titanium Transportation Group Inc',
    region: 'CA',
  },
  {
    symbol: 'TTS-CV',
    name: 'Tintina Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'TTT-CF',
    name: 'TruTrace Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'TTX-CF',
    name: 'Tantalex Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'TTZ-CV',
    name: 'Total Telecom Corp',
    region: 'CA',
  },
  {
    symbol: 'TUD-CV',
    name: 'Tudor Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'TUF-CV',
    name: 'Honey Badger Silver Inc.',
    region: 'CA',
  },
  {
    symbol: 'TUGA-CF',
    name: 'Tuga Innovations Inc',
    region: 'CA',
  },
  {
    symbol: 'TUO-CV',
    name: 'Teuton Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'TUSK-CF',
    name: 'Black Tusk Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'TV-CT',
    name: 'Trevali Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'TVA.B-CT',
    name: 'TVA Group Inc. - Class B',
    region: 'CA',
  },
  {
    symbol: 'TVC-CV',
    name: 'Three Valley Copper Corp - Class A',
    region: 'CA',
  },
  {
    symbol: 'TVE-CT',
    name: 'Tamarack Valley Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'TVI-CV',
    name: 'TVI Pacific, Inc.',
    region: 'CA',
  },
  {
    symbol: 'TVK-CT',
    name: 'TerraVest Industries Inc',
    region: 'CA',
  },
  {
    symbol: 'TWC-CT',
    name: 'TWC Enterprises Ltd',
    region: 'CA',
  },
  {
    symbol: 'TWM-CT',
    name: 'Tidewater Midstream and Infrastructure Ltd',
    region: 'CA',
  },
  {
    symbol: 'TWR-CV',
    name: 'Tower Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'TWY-CV',
    name: 'Twyford Ventures Inc',
    region: 'CA',
  },
  {
    symbol: 'TXG-CT',
    name: 'Torex Gold Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'TXP-CT',
    name: 'Touchstone Exploration Inc.',
    region: 'CA',
  },
  {
    symbol: 'TYMB-CV',
    name: 'Tymbal Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'TZR-CV',
    name: 'Terrace Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'UAV-CF',
    name: 'Global UAV Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'UBQ-CF',
    name: 'Ubique Minerals Limited',
    region: 'CA',
  },
  {
    symbol: 'UCU-CV',
    name: 'Ucore Rare Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'UEX-CT',
    name: 'UEX Corp',
    region: 'CA',
  },
  {
    symbol: 'UGD-CV',
    name: 'Unigold, Inc.',
    region: 'CA',
  },
  {
    symbol: 'UGE-CV',
    name: 'UGE International Ltd',
    region: 'CA',
  },
  {
    symbol: 'UI-CV',
    name: 'Urbanimmersive Inc',
    region: 'CA',
  },
  {
    symbol: 'ULT-CV',
    name: 'Ultra Lithium Inc',
    region: 'CA',
  },
  {
    symbol: 'ULTH-CF',
    name: 'United Lithium Corp',
    region: 'CA',
  },
  {
    symbol: 'UNI-CT',
    name: 'Unisync Corp',
    region: 'CA',
  },
  {
    symbol: 'UNO.H-CV',
    name: 'Northern Uranium Corp',
    region: 'CA',
  },
  {
    symbol: 'UNS-CT',
    name: 'Uni-Select Inc.',
    region: 'CA',
  },
  {
    symbol: 'UNV-CV',
    name: 'Universal Copper Ltd',
    region: 'CA',
  },
  {
    symbol: 'UP-CF',
    name: 'UpSnap Inc',
    region: 'CA',
  },
  {
    symbol: 'UPCO-CF',
    name: 'Upco International Inc',
    region: 'CA',
  },
  {
    symbol: 'UPI-CV',
    name: 'Universal PropTech Inc.',
    region: 'CA',
  },
  {
    symbol: 'URC-CV',
    name: 'Uranium Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'URE-CT',
    name: 'Ur-Energy Inc.',
    region: 'CA',
  },
  {
    symbol: 'URL-CF',
    name: 'NameSilo Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'URNM-CF',
    name: 'First Uranium Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'USA-CT',
    name: 'Americas Gold and Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'USCU-CV',
    name: 'US Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'USGD-CF',
    name: 'American Pacific Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'USHA-CV',
    name: 'Usha Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'USS-CV',
    name: 'Uniserve Communications Corp.',
    region: 'CA',
  },
  {
    symbol: 'UVN-CV',
    name: 'Uravan Minerals Inc.',
    region: 'CA',
  },
  {
    symbol: 'UWE.H-CV',
    name: 'U3O8 Corp',
    region: 'CA',
  },
  {
    symbol: 'UX-CF',
    name: 'Puranium Energy Ltd',
    region: 'CA',
  },
  {
    symbol: 'VAL.P-CV',
    name: 'Valencia Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'VALU-CF',
    name: 'Valorem Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'VAU-CV',
    name: 'Viva Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'VAX-CV',
    name: 'Vantex Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'VBN-CF',
    name: 'Pedro Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'VBNK-CT',
    name: 'VersaBank.',
    region: 'CA',
  },
  {
    symbol: 'VC-CF',
    name: 'Volatus Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'VCI-CV',
    name: 'Vitreous Glass Inc.',
    region: 'CA',
  },
  {
    symbol: 'VCM-CT',
    name: 'Vecima Networks Inc',
    region: 'CA',
  },
  {
    symbol: 'VCT-CV',
    name: 'Volt Carbon Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'VCU-CV',
    name: 'Vizsla Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'VCV-CV',
    name: 'Vatic Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'VDO.H-CV',
    name: 'Nevado Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'VEC-CV',
    name: 'Vanadian Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'VEGA-CF',
    name: 'PlantX Life Inc',
    region: 'CA',
  },
  {
    symbol: 'VEGI-CF',
    name: 'Boosh Plant-Based Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'VEGN-CF',
    name: 'Plant & Co. Brands Ltd',
    region: 'CA',
  },
  {
    symbol: 'VEIN-CV',
    name: 'Pasofino Gold Limited',
    region: 'CA',
  },
  {
    symbol: 'VEJI-CF',
    name: 'Vejii Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'VENI-CF',
    name: 'Top Strike Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'VENZ-CV',
    name: 'Venzee Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'VERT-CV',
    name: 'Vertical Exploration Inc',
    region: 'CA',
  },
  {
    symbol: 'VERY-CV',
    name: 'Very Good Food Company Inc (the)',
    region: 'CA',
  },
  {
    symbol: 'VET-CT',
    name: 'Vermilion Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'VEXT-CF',
    name: 'Vext Science Inc.',
    region: 'CA',
  },
  {
    symbol: 'VG-CV',
    name: 'Volcanic Gold Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'VGCX-CT',
    name: 'Victoria Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'VGD-CV',
    name: 'Visible Gold Mines Inc',
    region: 'CA',
  },
  {
    symbol: 'VGN-CV',
    name: 'Greencastle Resources Ltd.',
    region: 'CA',
  },
  {
    symbol: 'VGZ-CT',
    name: 'Vista Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'VHI-CT',
    name: 'Vitalhub Corp',
    region: 'CA',
  },
  {
    symbol: 'VIBE-CF',
    name: 'Vibe Growth Corporation',
    region: 'CA',
  },
  {
    symbol: 'VIO-CV',
    name: 'Vior Inc',
    region: 'CA',
  },
  {
    symbol: 'VIPR-CV',
    name: 'Silver Viper Minerals Corp',
    region: 'CA',
  },
  {
    symbol: 'VIS-CV',
    name: 'Visionstate Corp',
    region: 'CA',
  },
  {
    symbol: 'VISN-CV',
    name: 'Binovi Technologies Corp.',
    region: 'CA',
  },
  {
    symbol: 'VIV-CV',
    name: 'Avivagen Inc',
    region: 'CA',
  },
  {
    symbol: 'VIVO-CT',
    name: 'VIVO Cannabis Inc.',
    region: 'CA',
  },
  {
    symbol: 'VIZ-CV',
    name: 'Visionary Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'VLC-CV',
    name: 'Velocity Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'VLE-CT',
    name: 'Valeura Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'VLI-CV',
    name: 'Vision Lithium Inc.',
    region: 'CA',
  },
  {
    symbol: 'VLN-CT',
    name: 'Velan, Inc.',
    region: 'CA',
  },
  {
    symbol: 'VLNS-CT',
    name: 'Valens Company Inc (The)',
    region: 'CA',
  },
  {
    symbol: 'VM-CV',
    name: 'Voyageur Pharmaceuticals Ltd',
    region: 'CA',
  },
  {
    symbol: 'VMC-CV',
    name: 'Vicinity Motor Corp',
    region: 'CA',
  },
  {
    symbol: 'VMD-CT',
    name: 'Viemed Healthcare Inc',
    region: 'CA',
  },
  {
    symbol: 'VML-CV',
    name: 'Viscount Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'VMY.H-CV',
    name: 'Voice Mobility International, Inc.',
    region: 'CA',
  },
  {
    symbol: 'VND-CT',
    name: 'Vendasta Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'VNP-CT',
    name: '5N Plus Inc',
    region: 'CA',
  },
  {
    symbol: 'VO-CV',
    name: 'ValOre Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'VONE-CV',
    name: 'Voyager Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'VOTI-CV',
    name: 'VOTI Detection Inc.',
    region: 'CA',
  },
  {
    symbol: 'VOX-CV',
    name: 'Vox Royalty Corp',
    region: 'CA',
  },
  {
    symbol: 'VOY-CF',
    name: 'Voyageur Mineral Explorers Corp',
    region: 'CA',
  },
  {
    symbol: 'VOYG-CT',
    name: 'Voyager Digital Ltd - (Comm & Variable Voting)',
    region: 'CA',
  },
  {
    symbol: 'VPH-CT',
    name: 'Valeo Pharma Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'VPI-CV',
    name: 'Vitality Products',
    region: 'CA',
  },
  {
    symbol: 'VPT-CV',
    name: 'Ventripoint Diagnostics Ltd',
    region: 'CA',
  },
  {
    symbol: 'VQA-CV',
    name: 'Valterra Resource Corporation',
    region: 'CA',
  },
  {
    symbol: 'VQS-CT',
    name: 'VIQ Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'VR-CF',
    name: 'Victory Resources Corporation',
    region: 'CA',
  },
  {
    symbol: 'VRAR-CF',
    name: 'XR Immersive Tech Inc',
    region: 'CA',
  },
  {
    symbol: 'VRB-CV',
    name: 'Vanadiumcorp Resource Inc',
    region: 'CA',
  },
  {
    symbol: 'VRD.H-CV',
    name: 'Viridis Holdings Corp',
    region: 'CA',
  },
  {
    symbol: 'VRNO-CF',
    name: 'Verano Holdings Corp - Class A (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'VRR-CV',
    name: 'VR Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'VRY-CV',
    name: 'Petro-Victory Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'VSBY-CF',
    name: 'VSBLTY Groupe Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'VSR-CV',
    name: 'Vanstar Mining Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'VST-CF',
    name: 'Victory Square Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'VTT-CV',
    name: 'Vendetta Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'VTX-CV',
    name: 'Vertex Resource Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'VUI-CV',
    name: 'Virginia Energy Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'VUL-CV',
    name: 'Vulcan Minerals Inc',
    region: 'CA',
  },
  {
    symbol: 'VUX-CV',
    name: 'Vital Energy Inc',
    region: 'CA',
  },
  {
    symbol: 'VVC-CV',
    name: 'VVC Exploration Corp',
    region: 'CA',
  },
  {
    symbol: 'VXL-CV',
    name: 'Vaxil Bio Ltd.',
    region: 'CA',
  },
  {
    symbol: 'VXTR-CV',
    name: 'Voxtur Analytics Corp',
    region: 'CA',
  },
  {
    symbol: 'VYC.H-CV',
    name: 'Vanity Capital Inc',
    region: 'CA',
  },
  {
    symbol: 'VZLA-CV',
    name: 'Vizsla Silver Corp - New',
    region: 'CA',
  },
  {
    symbol: 'VZZ-CV',
    name: 'Val-d`Or Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'WA-CV',
    name: 'Western Atlas Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'WAR-CV',
    name: 'Warrior Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'WATR-CV',
    name: 'Current Water Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'WAV.H-CV',
    name: 'Pepcap Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'WAVE-CV',
    name: 'Waverley Pharma Inc',
    region: 'CA',
  },
  {
    symbol: 'WBE-CV',
    name: 'Westbond Enterprises Corp',
    region: 'CA',
  },
  {
    symbol: 'WBGD-CF',
    name: 'Waraba Gold Limited',
    region: 'CA',
  },
  {
    symbol: 'WBIO-CF',
    name: 'WPD Pharmaceuticals Inc',
    region: 'CA',
  },
  {
    symbol: 'WBR-CT',
    name: 'Waterloo Brewing Ltd',
    region: 'CA',
  },
  {
    symbol: 'WCC-CV',
    name: 'Consolidated Woodjam Copper Corp',
    region: 'CA',
  },
  {
    symbol: 'WCE-CV',
    name: 'Wescan Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'WCM.A-CT',
    name: 'Wilmington Capital Management - Class A',
    region: 'CA',
  },
  {
    symbol: 'WCM.B-CT',
    name: 'Wilmington Capital Management - Class B',
    region: 'CA',
  },
  {
    symbol: 'WCN-CT',
    name: 'Waste Connections Inc',
    region: 'CA',
  },
  {
    symbol: 'WCP-CT',
    name: 'Whitecap Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'WCU-CV',
    name: 'World Copper Ltd',
    region: 'CA',
  },
  {
    symbol: 'WDGY-CF',
    name: 'Wedgemount Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'WDO-CT',
    name: 'Wesdome Gold Mines Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WEB-CV',
    name: 'Westbridge Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'WED-CV',
    name: 'Westaim Corp',
    region: 'CA',
  },
  {
    symbol: 'WEE-CV',
    name: 'Wavefront Technology Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'WEED-CT',
    name: 'Canopy Growth Corporation',
    region: 'CA',
  },
  {
    symbol: 'WEF-CT',
    name: 'Western Forest Products Inc.',
    region: 'CA',
  },
  {
    symbol: 'WEII-CV',
    name: 'Wolverine Energy And Infrastructure Inc',
    region: 'CA',
  },
  {
    symbol: 'WELL-CT',
    name: 'WELL Health Technologies Corp',
    region: 'CA',
  },
  {
    symbol: 'WESA-CF',
    name: 'Wesana Health Holdings Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'WEST-CF',
    name: 'West Mining Corp.',
    region: 'CA',
  },
  {
    symbol: 'WFG-CT',
    name: 'West Fraser Timber Co., Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WG-CF',
    name: 'Westward Gold Inc',
    region: 'CA',
  },
  {
    symbol: 'WGC-CF',
    name: 'Winston Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'WGF-CV',
    name: 'Wescan Goldfields Inc.',
    region: 'CA',
  },
  {
    symbol: 'WGLD-CV',
    name: 'Western Gold Exploration Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WGO-CV',
    name: 'White Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'WHG-CV',
    name: 'Whitehorse Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'WHM-CV',
    name: 'White Metal Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'WHN-CV',
    name: 'Westhaven Gold Corp.',
    region: 'CA',
  },
  {
    symbol: 'WHY-CV',
    name: 'West High Yield Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'WIB-CF',
    name: 'West Island Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'WIFI-CF',
    name: 'American Aires Inc',
    region: 'CA',
  },
  {
    symbol: 'WIL-CV',
    name: 'Wilton Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'WILD-CT',
    name: 'WildBrain Ltd (Common & Variable Voting)',
    region: 'CA',
  },
  {
    symbol: 'WIN-CV',
    name: 'Windfall Geotek Inc',
    region: 'CA',
  },
  {
    symbol: 'WISH-CV',
    name: 'Wishpond Technologies Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WJX-CT',
    name: 'Wajax Corp',
    region: 'CA',
  },
  {
    symbol: 'WKG-CV',
    name: 'WestKam Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'WLCO-CV',
    name: 'Well Told Company Inc (The)',
    region: 'CA',
  },
  {
    symbol: 'WLF-CV',
    name: 'Wolfden Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'WLLW-CT',
    name: 'Willow Biosciences Inc',
    region: 'CA',
  },
  {
    symbol: 'WLNS-CF',
    name: 'Discover Wellness Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'WM-CT',
    name: 'Wallbridge Mining Company Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WMG-CV',
    name: 'Western Magnesium Corporation',
    region: 'CA',
  },
  {
    symbol: 'WMK-CV',
    name: 'Whitemud Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'WML-CV',
    name: 'Wealth Minerals Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WN-CT',
    name: 'George Weston Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WPK-CT',
    name: 'Winpak, Ltd.',
    region: 'CA',
  },
  {
    symbol: 'WPM-CT',
    name: 'Wheaton Precious Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'WPN-CF',
    name: 'First Responder Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'WPRT-CT',
    name: 'Westport Fuel Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'WR.H-CV',
    name: 'Worldwide Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'WRG-CT',
    name: 'Western Energy Services Corporation.',
    region: 'CA',
  },
  {
    symbol: 'WRI-CV',
    name: 'Waseco Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'WRN-CT',
    name: 'Western Copper & Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'WRR-CV',
    name: 'Walker River Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'WRX-CT',
    name: 'Western Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'WRY-CV',
    name: 'Western Troy Capital Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'WSK-CV',
    name: 'Wildsky Resources Inc.',
    region: 'CA',
  },
  {
    symbol: 'WSM.X-CF',
    name: 'WSM Ventures Corp',
    region: 'CA',
  },
  {
    symbol: 'WSP-CT',
    name: 'WSP Global Inc',
    region: 'CA',
  },
  {
    symbol: 'WTE-CT',
    name: 'Westshore Terminals Investment Corporation',
    region: 'CA',
  },
  {
    symbol: 'WTER-CF',
    name: 'Alkaline Water Company Inc (The)',
    region: 'CA',
  },
  {
    symbol: 'WUC-CF',
    name: 'Western Uranium & Vanadium Corp',
    region: 'CA',
  },
  {
    symbol: 'WVM-CV',
    name: 'West Vault Mining Inc.',
    region: 'CA',
  },
  {
    symbol: 'WWT-CV',
    name: 'Water Ways Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'X-CT',
    name: 'TMX Group Ltd',
    region: 'CA',
  },
  {
    symbol: 'XAG-CV',
    name: 'Xtierra Inc',
    region: 'CA',
  },
  {
    symbol: 'XAM-CT',
    name: 'Xanadu Mines Limited',
    region: 'CA',
  },
  {
    symbol: 'XAU-CT',
    name: 'GoldMoney Inc',
    region: 'CA',
  },
  {
    symbol: 'XBC-CT',
    name: 'Xebec Adsorption Inc',
    region: 'CA',
  },
  {
    symbol: 'XBLK-CF',
    name: 'eXeBlock Technology Corp',
    region: 'CA',
  },
  {
    symbol: 'XBRA-CF',
    name: 'Xebra Brands Ltd',
    region: 'CA',
  },
  {
    symbol: 'XCAP.P-CV',
    name: 'Exelerate Capital Corp',
    region: 'CA',
  },
  {
    symbol: 'XCX-CF',
    name: 'Greenrise Global Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'XGC-CV',
    name: 'Xali Gold Corp',
    region: 'CA',
  },
  {
    symbol: 'XIM-CV',
    name: 'Ximen Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'XL-CV',
    name: 'XXL Energy Corp',
    region: 'CA',
  },
  {
    symbol: 'XLY-CT',
    name: 'Auxly Cannabis Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'XND-CV',
    name: 'Xander Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'XPHY-CF',
    name: 'XPhyto Therapeutics Corp',
    region: 'CA',
  },
  {
    symbol: 'XPLR-CV',
    name: 'Xplore Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'XRA-CF',
    name: 'XRApplied Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'XRTX-CV',
    name: 'XORTX Therapeutics Inc',
    region: 'CA',
  },
  {
    symbol: 'XSF-CF',
    name: 'XS Financial Inc (Sub Voting)',
    region: 'CA',
  },
  {
    symbol: 'XTC-CT',
    name: 'EXCO Technologies Ltd.',
    region: 'CA',
  },
  {
    symbol: 'XTG-CT',
    name: 'Xtra-Gold Resources Corp.',
    region: 'CA',
  },
  {
    symbol: 'XTM-CV',
    name: 'Transition Metals Corp',
    region: 'CA',
  },
  {
    symbol: 'XTRX-CF',
    name: 'Adastra Holdings Ltd',
    region: 'CA',
  },
  {
    symbol: 'XTT-CV',
    name: 'X-Terra Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'XX-CV',
    name: 'Avante Logixx Inc',
    region: 'CA',
  },
  {
    symbol: 'Y-CT',
    name: 'Yellow Pages Ltd',
    region: 'CA',
  },
  {
    symbol: 'YEG-CV',
    name: 'Yorkton Equity Group Inc.',
    region: 'CA',
  },
  {
    symbol: 'YES-CV',
    name: 'CHAR Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'YFI-CV',
    name: 'Edgewater Wireless Systems Inc',
    region: 'CA',
  },
  {
    symbol: 'YGR-CT',
    name: 'Yangarra Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'YGT-CV',
    name: 'Gold Terra Resource Corp',
    region: 'CA',
  },
  {
    symbol: 'YNV-CV',
    name: 'Ynvisible Interactive Inc - Class A',
    region: 'CA',
  },
  {
    symbol: 'YOO-CV',
    name: 'Yangaroo Inc.',
    region: 'CA',
  },
  {
    symbol: 'YOOM-CF',
    name: 'Yooma Wellness Inc',
    region: 'CA',
  },
  {
    symbol: 'YORK-CV',
    name: 'York Harbour Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'YOUR-CF',
    name: 'YourWay Cannabis Brands Inc',
    region: 'CA',
  },
  {
    symbol: 'YRB-CT',
    name: 'Yorbeau Resources Inc. - Class A',
    region: 'CA',
  },
  {
    symbol: 'YRI-CT',
    name: 'Yamana Gold Inc.',
    region: 'CA',
  },
  {
    symbol: 'YTY-CV',
    name: 'Wi2Wi Corp',
    region: 'CA',
  },
  {
    symbol: 'YUM-CF',
    name: 'Komo Plant Based Foods Inc',
    region: 'CA',
  },
  {
    symbol: 'Z.H-CV',
    name: 'Zinc One Resources Inc',
    region: 'CA',
  },
  {
    symbol: 'ZAC-CV',
    name: 'Zacatecas Silver Corp',
    region: 'CA',
  },
  {
    symbol: 'ZACA-CV',
    name: 'Zacapa Resources Ltd',
    region: 'CA',
  },
  {
    symbol: 'ZAIR-CF',
    name: 'Zinc8 Energy Solutions Inc',
    region: 'CA',
  },
  {
    symbol: 'ZBNI-CV',
    name: 'ZEB Nickel Corp',
    region: 'CA',
  },
  {
    symbol: 'ZCC.H-CV',
    name: 'Zena Mining Corp',
    region: 'CA',
  },
  {
    symbol: 'ZDC-CV',
    name: 'Zedcor Inc.',
    region: 'CA',
  },
  {
    symbol: 'ZEN-CV',
    name: 'Zentek Ltd',
    region: 'CA',
  },
  {
    symbol: 'ZEU-CF',
    name: 'ZeU Technologies Inc',
    region: 'CA',
  },
  {
    symbol: 'ZFR-CV',
    name: 'Zephyr Minerals Ltd',
    region: 'CA',
  },
  {
    symbol: 'ZNG-CV',
    name: 'Group Eleven Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ZNX-CV',
    name: 'Zincx Resources Corp',
    region: 'CA',
  },
  {
    symbol: 'ZOG-CF',
    name: 'Zoglos Incredible Food Corp',
    region: 'CA',
  },
  {
    symbol: 'ZOMD-CV',
    name: 'Zoomd Technologies Ltd',
    region: 'CA',
  },
  {
    symbol: 'ZON-CV',
    name: 'Zonte Metals Inc',
    region: 'CA',
  },
  {
    symbol: 'ZONE-CV',
    name: 'Zonetail Inc',
    region: 'CA',
  },
  {
    symbol: 'ZRO-CF',
    name: 'Biosenta Inc',
    region: 'CA',
  },
  {
    symbol: 'ZTE-CF',
    name: 'Ztest Electronics Inc - Redeemable Shares - Class A',
    region: 'CA',
  },
  {
    symbol: 'ZUM-CV',
    name: 'ZoomerMedia Ltd',
    region: 'CA',
  },
  {
    symbol: 'ZZZ-CT',
    name: 'Sleep Country Canada Holdings Inc',
    region: 'CA',
  },
  {
    symbol: '0A02-LN',
    name: 'Neoen S.A.',
    region: 'GB',
  },
  {
    symbol: '0A05-LN',
    name: 'Medacta Group SA',
    region: 'GB',
  },
  {
    symbol: '0A0C-LN',
    name: 'Stadler Rail AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0A0D-LN',
    name: 'Alcon Inc. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0A0H-LN',
    name: 'Beijer Ref AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0A0J-LN',
    name: 'AAK AB',
    region: 'GB',
  },
  {
    symbol: '0A0L-LN',
    name: 'Sectra AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0A18-LN',
    name: 'CGI Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0A1K-LN',
    name: 'NIO Inc - ADR',
    region: 'GB',
  },
  {
    symbol: '0A1N-LN',
    name: 'WillScot Mobile Mini Holdings Corp',
    region: 'GB',
  },
  {
    symbol: '0A1U-LN',
    name: 'Uber Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0A1V-LN',
    name: 'Tethys Oil Ab',
    region: 'GB',
  },
  {
    symbol: '0A28-LN',
    name: 'Prosus NV - Class N',
    region: 'GB',
  },
  {
    symbol: '0A29-LN',
    name: 'Solutions 30 SE',
    region: 'GB',
  },
  {
    symbol: '0A2A-LN',
    name: 'Stillfront Group AB',
    region: 'GB',
  },
  {
    symbol: '0A2N-LN',
    name: 'Lynas Rare Earths Limited',
    region: 'GB',
  },
  {
    symbol: '0A2O-LN',
    name: 'Lyft Inc Cls A',
    region: 'GB',
  },
  {
    symbol: '0A2S-LN',
    name: 'Pinduoduo Inc - ADR',
    region: 'GB',
  },
  {
    symbol: '0A2X-LN',
    name: 'TAL Education Group - ADR',
    region: 'GB',
  },
  {
    symbol: '0A37-LN',
    name: 'Betsson AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0A39-LN',
    name: 'Karnov Group AB',
    region: 'GB',
  },
  {
    symbol: '0A3M-LN',
    name: 'BioNTech SE - ADR',
    region: 'GB',
  },
  {
    symbol: '0A3N-LN',
    name: 'Crowdstrike Holdings Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0A3P-LN',
    name: 'Fastly Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0A3R-LN',
    name: 'Nikola Corporation',
    region: 'GB',
  },
  {
    symbol: '0A45-LN',
    name: 'Moderna Inc',
    region: 'GB',
  },
  {
    symbol: '0A4S-LN',
    name: 'Sunrun Inc',
    region: 'GB',
  },
  {
    symbol: '0A4U-LN',
    name: 'Bellus Health Inc',
    region: 'GB',
  },
  {
    symbol: '0A4Y-LN',
    name: 'AIM ImmunoTech Inc',
    region: 'GB',
  },
  {
    symbol: '0A53-LN',
    name: 'Maxar Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0A5I-LN',
    name: 'JDE Peet`s N.V.',
    region: 'GB',
  },
  {
    symbol: '0A5R-LN',
    name: 'Calliditas Therapeutics AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0A5U-LN',
    name: 'Re:NewCell AB',
    region: 'GB',
  },
  {
    symbol: '0A5V-LN',
    name: 'Viatris Inc',
    region: 'GB',
  },
  {
    symbol: '0A60-LN',
    name: 'Fuelcell Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0A61-LN',
    name: 'Discovery Silver Corp',
    region: 'GB',
  },
  {
    symbol: '0A66-LN',
    name: 'Metalla Royalty and Streaming Ltd',
    region: 'GB',
  },
  {
    symbol: '0A6K-LN',
    name: 'Inpost S.A',
    region: 'GB',
  },
  {
    symbol: '0A6N-LN',
    name: 'Amex Exploration Inc.',
    region: 'GB',
  },
  {
    symbol: '0A6Y-LN',
    name: 'Xerox Holdings Corp',
    region: 'GB',
  },
  {
    symbol: '0A73-LN',
    name: 'TELUS International (Cda) Inc (Sub Voting)',
    region: 'GB',
  },
  {
    symbol: '0A74-LN',
    name: 'Kuaishou Technology - Class B',
    region: 'GB',
  },
  {
    symbol: '0A77-LN',
    name: 'Cigna Corp.',
    region: 'GB',
  },
  {
    symbol: '0A7Q-LN',
    name: 'Readly International AB',
    region: 'GB',
  },
  {
    symbol: '0A8A-LN',
    name: 'Technip Energies N.V.',
    region: 'GB',
  },
  {
    symbol: '0A8B-LN',
    name: 'La Francaise Des Jeux S.A.',
    region: 'GB',
  },
  {
    symbol: '0A8V-LN',
    name: 'Lightspeed Commerce Inc (Sub Voting)',
    region: 'GB',
  },
  {
    symbol: '0A90-LN',
    name: 'B&S Group SA',
    region: 'GB',
  },
  {
    symbol: '0A9C-LN',
    name: 'Mawson Gold Ltd',
    region: 'GB',
  },
  {
    symbol: '0A9D-LN',
    name: 'Nexus Gold Corp.',
    region: 'GB',
  },
  {
    symbol: '0A9N-LN',
    name: 'Nacon S.A.',
    region: 'GB',
  },
  {
    symbol: '0A9Z-LN',
    name: 'Acast AB',
    region: 'GB',
  },
  {
    symbol: '0AA6-LN',
    name: 'Azelis Group NV',
    region: 'GB',
  },
  {
    symbol: '0AA8-LN',
    name: 'Better Collective A/S',
    region: 'GB',
  },
  {
    symbol: '0AA9-LN',
    name: 'Storskogen Group AB (publ) - Class B',
    region: 'GB',
  },
  {
    symbol: '0AAA-LN',
    name: 'Aramis Group S.A.',
    region: 'GB',
  },
  {
    symbol: '0AAI-LN',
    name: 'Montana Aerospace AG',
    region: 'GB',
  },
  {
    symbol: '0AAJ-LN',
    name: 'PolyPeptide Group AG',
    region: 'GB',
  },
  {
    symbol: '0AAK-LN',
    name: 'Volvo Car AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0AAN-LN',
    name: 'OVH Groupe',
    region: 'GB',
  },
  {
    symbol: '0AAO-LN',
    name: 'Alfen NV',
    region: 'GB',
  },
  {
    symbol: '0AAQ-LN',
    name: 'NCAB Group AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0AAS-LN',
    name: 'SamhAllsbyggnadsbolaget i Norden AB. - Class B',
    region: 'GB',
  },
  {
    symbol: '0AAU-LN',
    name: 'Surgical Science Sweden AB',
    region: 'GB',
  },
  {
    symbol: '0AAV-LN',
    name: 'Sdiptech AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0ACT-LN',
    name: 'AlzChem Group AG',
    region: 'GB',
  },
  {
    symbol: '0AH3-LN',
    name: 'Great-West Lifeco Inc.',
    region: 'GB',
  },
  {
    symbol: '0AH6-LN',
    name: 'Baywa AG',
    region: 'GB',
  },
  {
    symbol: '0AH7-LN',
    name: 'Baywa AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0AHJ-LN',
    name: 'Hudbay Minerals Inc.',
    region: 'GB',
  },
  {
    symbol: '0AI3-LN',
    name: 'Carlsberg - Class A',
    region: 'GB',
  },
  {
    symbol: '0AI4-LN',
    name: 'Carlsberg - Class B',
    region: 'GB',
  },
  {
    symbol: '0AJ1-LN',
    name: 'Maple Gold Mines Ltd',
    region: 'GB',
  },
  {
    symbol: '0AMJ-LN',
    name: 'Trisura Group Ltd',
    region: 'GB',
  },
  {
    symbol: '0APM-LN',
    name: 'Danieli & C - Officine Meccaniche Spa',
    region: 'GB',
  },
  {
    symbol: '0AVG-LN',
    name: 'Explosifs Et Pdt Chimiques (Partly Paid)',
    region: 'GB',
  },
  {
    symbol: '0B4R-LN',
    name: 'Fuchs Petrolub SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0B67-LN',
    name: 'Galp Energia Sgps - Class B',
    region: 'GB',
  },
  {
    symbol: '0BJP-LN',
    name: 'WeBuild S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0BMI-LN',
    name: 'Bank Fur Karanten Und Steiermark AG',
    region: 'GB',
  },
  {
    symbol: '0BNS-LN',
    name: 'Kesko Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0BNT-LN',
    name: 'Kesko Oyj - Class B',
    region: 'GB',
  },
  {
    symbol: '0BQD-LN',
    name: 'KSB SE & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0BQE-LN',
    name: 'KSB SE & Co. KGaA - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0C6Y-LN',
    name: 'Oberbank AG',
    region: 'GB',
  },
  {
    symbol: '0CDX-LN',
    name: 'Wallstreet Online AG',
    region: 'GB',
  },
  {
    symbol: '0CHZ-LN',
    name: 'Q.Beyond AG',
    region: 'GB',
  },
  {
    symbol: '0CIJ-LN',
    name: 'Raisio plc - Class V',
    region: 'GB',
  },
  {
    symbol: '0CX9-LN',
    name: 'Stora Enso Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0CXC-LN',
    name: 'Stora Enso Oyj - Class R',
    region: 'GB',
  },
  {
    symbol: '0CXE-LN',
    name: 'Detection Technology Oyj',
    region: 'GB',
  },
  {
    symbol: '0CXS-LN',
    name: 'Gofore Plc',
    region: 'GB',
  },
  {
    symbol: '0CZ7-LN',
    name: 'Nextedia',
    region: 'GB',
  },
  {
    symbol: '0CZZ-LN',
    name: 'Gaussin',
    region: 'GB',
  },
  {
    symbol: '0D00-LN',
    name: 'MGI Digital Graphic Technology',
    region: 'GB',
  },
  {
    symbol: '0D13-LN',
    name: 'Visiomed Group',
    region: 'GB',
  },
  {
    symbol: '0D1J-LN',
    name: 'Quantum Genomics',
    region: 'GB',
  },
  {
    symbol: '0D1W-LN',
    name: 'Biophytis',
    region: 'GB',
  },
  {
    symbol: '0D1X-LN',
    name: 'Groupe Guillin',
    region: 'GB',
  },
  {
    symbol: '0D20-LN',
    name: 'Bank Fur Tirol Und Voralberg AG',
    region: 'GB',
  },
  {
    symbol: '0D25-LN',
    name: 'Drone Volt',
    region: 'GB',
  },
  {
    symbol: '0DD8-LN',
    name: 'TraWell Co. S.p.A',
    region: 'GB',
  },
  {
    symbol: '0DDV-LN',
    name: 'Clabo Group Spa',
    region: 'GB',
  },
  {
    symbol: '0DEF-LN',
    name: 'Alfio Bardolla Training Group S.p.a.',
    region: 'GB',
  },
  {
    symbol: '0DEQ-LN',
    name: 'Neodecortech S.p.a.',
    region: 'GB',
  },
  {
    symbol: '0DFF-LN',
    name: 'Vincenzo Zucchi Spa',
    region: 'GB',
  },
  {
    symbol: '0DGD-LN',
    name: 'Softlab Spa',
    region: 'GB',
  },
  {
    symbol: '0DGZ-LN',
    name: 'Adolfo Dominguez',
    region: 'GB',
  },
  {
    symbol: '0DH7-LN',
    name: 'AF Gruppen ASA - Class A',
    region: 'GB',
  },
  {
    symbol: '0DHA-LN',
    name: 'Arendals Fossekompani',
    region: 'GB',
  },
  {
    symbol: '0DHC-LN',
    name: 'Carl Zeiss Meditec A G',
    region: 'GB',
  },
  {
    symbol: '0DHJ-LN',
    name: 'Autogrill Spa',
    region: 'GB',
  },
  {
    symbol: '0DHR-LN',
    name: 'Agrana Beteiligungs AG',
    region: 'GB',
  },
  {
    symbol: '0DI7-LN',
    name: 'AFC Ajax NV',
    region: 'GB',
  },
  {
    symbol: '0DIP-LN',
    name: 'AKVA group ASA.',
    region: 'GB',
  },
  {
    symbol: '0DJI-LN',
    name: 'Alm. Brand AS',
    region: 'GB',
  },
  {
    symbol: '0DJN-LN',
    name: 'Alma Media Corporation',
    region: 'GB',
  },
  {
    symbol: '0DJV-LN',
    name: 'Altri Sgps S.A.',
    region: 'GB',
  },
  {
    symbol: '0DK0-LN',
    name: 'Alexanderwerk AG',
    region: 'GB',
  },
  {
    symbol: '0DK9-LN',
    name: 'Amadeus Fire AG',
    region: 'GB',
  },
  {
    symbol: '0DLI-LN',
    name: 'Amsterdam Commodities NV',
    region: 'GB',
  },
  {
    symbol: '0DLW-LN',
    name: 'Arcandor AG',
    region: 'GB',
  },
  {
    symbol: '0DM3-LN',
    name: 'Artmarket.com',
    region: 'GB',
  },
  {
    symbol: '0DM6-LN',
    name: 'PB Holding NV',
    region: 'GB',
  },
  {
    symbol: '0DME-LN',
    name: 'Ascopiave',
    region: 'GB',
  },
  {
    symbol: '0DNH-LN',
    name: 'Atos SE',
    region: 'GB',
  },
  {
    symbol: '0DNS-LN',
    name: 'Autostrade Meridionali Spa',
    region: 'GB',
  },
  {
    symbol: '0DNT-LN',
    name: 'Aurskog Sparebank.',
    region: 'GB',
  },
  {
    symbol: '0DNW-LN',
    name: 'Austevoll Seafood Asa',
    region: 'GB',
  },
  {
    symbol: '0DO2-LN',
    name: 'AVAX SA',
    region: 'GB',
  },
  {
    symbol: '0DO4-LN',
    name: 'Advenis',
    region: 'GB',
  },
  {
    symbol: '0DO7-LN',
    name: 'Avenir Telecom',
    region: 'GB',
  },
  {
    symbol: '0DOG-LN',
    name: 'Azkoyen S.A.',
    region: 'GB',
  },
  {
    symbol: '0DOL-LN',
    name: 'Biofrontera AG',
    region: 'GB',
  },
  {
    symbol: '0DOS-LN',
    name: 'Bank Handlowy w Warszawie S.A.',
    region: 'GB',
  },
  {
    symbol: '0DP0-LN',
    name: 'Bank Polska Kasa Opieki SA',
    region: 'GB',
  },
  {
    symbol: '0DP4-LN',
    name: 'Basware Oyj',
    region: 'GB',
  },
  {
    symbol: '0DP9-LN',
    name: 'Banca Transilvania',
    region: 'GB',
  },
  {
    symbol: '0DPB-LN',
    name: 'Bavarian Nordic A/S',
    region: 'GB',
  },
  {
    symbol: '0DPE-LN',
    name: 'BBI Buergerliches Brauhaus Immob',
    region: 'GB',
  },
  {
    symbol: '0DPI-LN',
    name: 'Barbara Bui',
    region: 'GB',
  },
  {
    symbol: '0DPM-LN',
    name: 'Bechtle AG',
    region: 'GB',
  },
  {
    symbol: '0DPO-LN',
    name: 'Patria Bank SA',
    region: 'GB',
  },
  {
    symbol: '0DPU-LN',
    name: 'Proximus',
    region: 'GB',
  },
  {
    symbol: '0DQ7-LN',
    name: 'Beiersdorf AG',
    region: 'GB',
  },
  {
    symbol: '0DQB-LN',
    name: 'Belships ASA.',
    region: 'GB',
  },
  {
    symbol: '0DQK-LN',
    name: 'Beter Bed Holding N.V.',
    region: 'GB',
  },
  {
    symbol: '0DQP-LN',
    name: 'Marie Brizard Wine And Spirits',
    region: 'GB',
  },
  {
    symbol: '0DQZ-LN',
    name: 'Banca Generali',
    region: 'GB',
  },
  {
    symbol: '0DRH-LN',
    name: 'Bank Millennium S.A.',
    region: 'GB',
  },
  {
    symbol: '0DRM-LN',
    name: 'Banca Intermobiliare Di Investiment E Gestioni Spa',
    region: 'GB',
  },
  {
    symbol: '0DRP-LN',
    name: 'Biohit Oyj - Class B',
    region: 'GB',
  },
  {
    symbol: '0DRV-LN',
    name: 'ArcticZymes Technologies ASA',
    region: 'GB',
  },
  {
    symbol: '0DSJ-LN',
    name: 'NRC Group ASA',
    region: 'GB',
  },
  {
    symbol: '0DSM-LN',
    name: 'Blue Island Holdings Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0DT1-LN',
    name: 'National Bank of Belgium',
    region: 'GB',
  },
  {
    symbol: '0DTF-LN',
    name: 'Boiron',
    region: 'GB',
  },
  {
    symbol: '0DTI-LN',
    name: 'Bonheur ASA',
    region: 'GB',
  },
  {
    symbol: '0DTK-LN',
    name: 'Savencia S.A.',
    region: 'GB',
  },
  {
    symbol: '0DTT-LN',
    name: 'Bank of Valletta',
    region: 'GB',
  },
  {
    symbol: '0DU3-LN',
    name: 'mBank S.A',
    region: 'GB',
  },
  {
    symbol: '0DUI-LN',
    name: 'Basler AG',
    region: 'GB',
  },
  {
    symbol: '0DUK-LN',
    name: 'Biesse',
    region: 'GB',
  },
  {
    symbol: '0DVE-LN',
    name: 'Borussia Dortmund KGAA',
    region: 'GB',
  },
  {
    symbol: '0DVM-LN',
    name: 'Byggma',
    region: 'GB',
  },
  {
    symbol: '0DVR-LN',
    name: 'Santander Bank Polska S. A.',
    region: 'GB',
  },
  {
    symbol: '0DWV-LN',
    name: 'Cbrain AS',
    region: 'GB',
  },
  {
    symbol: '0DXG-LN',
    name: 'Cropenergies AG',
    region: 'GB',
  },
  {
    symbol: '0DXU-LN',
    name: 'Cembre Spa',
    region: 'GB',
  },
  {
    symbol: '0DYD-LN',
    name: 'Centrotec SE',
    region: 'GB',
  },
  {
    symbol: '0DYQ-LN',
    name: 'Cegedim',
    region: 'GB',
  },
  {
    symbol: '0DZC-LN',
    name: 'Cie Automotive',
    region: 'GB',
  },
  {
    symbol: '0DZJ-LN',
    name: 'Groupe Crit',
    region: 'GB',
  },
  {
    symbol: '0E07-LN',
    name: 'Cementos Molins S.A.',
    region: 'GB',
  },
  {
    symbol: '0E0C-LN',
    name: 'CNIM Group',
    region: 'GB',
  },
  {
    symbol: '0E1C-LN',
    name: 'Cosmos Insurance Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0E1Y-LN',
    name: 'Chargeurs',
    region: 'GB',
  },
  {
    symbol: '0E24-LN',
    name: 'Chris Joannou Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0E2F-LN',
    name: 'Ctac NV',
    region: 'GB',
  },
  {
    symbol: '0E2J-LN',
    name: 'Componenta Oyj.',
    region: 'GB',
  },
  {
    symbol: '0E3C-LN',
    name: 'Datalogic Spa',
    region: 'GB',
  },
  {
    symbol: '0E3F-LN',
    name: 'Data Modul AG',
    region: 'GB',
  },
  {
    symbol: '0E4J-LN',
    name: 'Delticom AG - Ordinary shares',
    region: 'GB',
  },
  {
    symbol: '0E4K-LN',
    name: 'Deutz AG',
    region: 'GB',
  },
  {
    symbol: '0E4Q-LN',
    name: 'Nel ASA',
    region: 'GB',
  },
  {
    symbol: '0E5M-LN',
    name: 'De Longhi',
    region: 'GB',
  },
  {
    symbol: '0E63-LN',
    name: 'Ease2pay N.V.',
    region: 'GB',
  },
  {
    symbol: '0E64-LN',
    name: 'DO & CO Aktiengesellschaft',
    region: 'GB',
  },
  {
    symbol: '0E6R-LN',
    name: 'St Dupont',
    region: 'GB',
  },
  {
    symbol: '0E6Y-LN',
    name: '1&1 AG',
    region: 'GB',
  },
  {
    symbol: '0E7Z-LN',
    name: 'Eurotech S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0E8U-LN',
    name: 'Encres Dubuit',
    region: 'GB',
  },
  {
    symbol: '0E96-LN',
    name: 'Enedo Plc',
    region: 'GB',
  },
  {
    symbol: '0E9L-LN',
    name: 'Eifelhoehen-Klinik AG',
    region: 'GB',
  },
  {
    symbol: '0E9P-LN',
    name: 'Eisen-Und Huettenwerke AG',
    region: 'GB',
  },
  {
    symbol: '0E9V-LN',
    name: 'Energiekontor AG',
    region: 'GB',
  },
  {
    symbol: '0EA2-LN',
    name: 'Elica',
    region: 'GB',
  },
  {
    symbol: '0EA4-LN',
    name: 'Elecster Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0EAL-LN',
    name: 'Seaspan Corporation - Class A',
    region: 'GB',
  },
  {
    symbol: '0EAQ-LN',
    name: 'Teekay Tankers Ltd - Class A',
    region: 'GB',
  },
  {
    symbol: '0EAW-LN',
    name: 'Kambi Group Limited',
    region: 'GB',
  },
  {
    symbol: '0EAZ-LN',
    name: 'Aspire Global plc',
    region: 'GB',
  },
  {
    symbol: '0EBQ-LN',
    name: 'Enagas SA',
    region: 'GB',
  },
  {
    symbol: '0ECJ-LN',
    name: 'Euroconsultants S.A.',
    region: 'GB',
  },
  {
    symbol: '0EDD-LN',
    name: 'LyondellBasell Industries NV - Class A',
    region: 'GB',
  },
  {
    symbol: '0EDL-LN',
    name: 'Etteplan Oyj',
    region: 'GB',
  },
  {
    symbol: '0EEE-LN',
    name: 'CTS Eventim AG & Co KGAA',
    region: 'GB',
  },
  {
    symbol: '0EEI-LN',
    name: 'EVN AG',
    region: 'GB',
  },
  {
    symbol: '0EEV-LN',
    name: 'Exmar SA',
    region: 'GB',
  },
  {
    symbol: '0EEZ-LN',
    name: 'Athens Water Supply & Sewerage S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0EG7-LN',
    name: 'Fernheizwerk Neukoelln AG',
    region: 'GB',
  },
  {
    symbol: '0EG8-LN',
    name: 'Finnair Oyj',
    region: 'GB',
  },
  {
    symbol: '0EGG-LN',
    name: 'FIMBank p.l.c.',
    region: 'GB',
  },
  {
    symbol: '0EGH-LN',
    name: 'Fiera Milano',
    region: 'GB',
  },
  {
    symbol: '0EGQ-LN',
    name: 'Frauenthal Holding AG',
    region: 'GB',
  },
  {
    symbol: '0EHB-LN',
    name: 'FNM',
    region: 'GB',
  },
  {
    symbol: '0EHR-LN',
    name: 'Fountain SA',
    region: 'GB',
  },
  {
    symbol: '0EIB-LN',
    name: 'Audax Renovables S.A.',
    region: 'GB',
  },
  {
    symbol: '0EIC-LN',
    name: 'Foris AG',
    region: 'GB',
  },
  {
    symbol: '0EID-LN',
    name: '4iG',
    region: 'GB',
  },
  {
    symbol: '0EIE-LN',
    name: 'WithSecure Corp',
    region: 'GB',
  },
  {
    symbol: '0EJB-LN',
    name: 'Gaumont',
    region: 'GB',
  },
  {
    symbol: '0EJQ-LN',
    name: 'Gea',
    region: 'GB',
  },
  {
    symbol: '0EJU-LN',
    name: 'Geci International',
    region: 'GB',
  },
  {
    symbol: '0EKA-LN',
    name: 'Getin Holding Spolka Akcyjna',
    region: 'GB',
  },
  {
    symbol: '0EKE-LN',
    name: 'Lisi Group',
    region: 'GB',
  },
  {
    symbol: '0EKI-LN',
    name: 'Gefran Spa',
    region: 'GB',
  },
  {
    symbol: '0ELH-LN',
    name: 'Goodtech ASA',
    region: 'GB',
  },
  {
    symbol: '0ELP-LN',
    name: 'Gpe Groupe Pizzorno',
    region: 'GB',
  },
  {
    symbol: '0ELV-LN',
    name: 'Guerbet SA',
    region: 'GB',
  },
  {
    symbol: '0EMB-LN',
    name: 'Reach Subsea ASA',
    region: 'GB',
  },
  {
    symbol: '0EMN-LN',
    name: 'Glycorex Transplantation AB - Series B',
    region: 'GB',
  },
  {
    symbol: '0EN5-LN',
    name: 'Hanseyachts AG',
    region: 'GB',
  },
  {
    symbol: '0ENF-LN',
    name: 'Brodrene Hartmann AS',
    region: 'GB',
  },
  {
    symbol: '0ENJ-LN',
    name: 'Hawesko Holding AG',
    region: 'GB',
  },
  {
    symbol: '0ENN-LN',
    name: 'Hornbach-Baumarkt AG',
    region: 'GB',
  },
  {
    symbol: '0EO8-LN',
    name: 'SpareBank 1 Helgeland',
    region: 'GB',
  },
  {
    symbol: '0EOF-LN',
    name: 'Hexagon Composites ASA',
    region: 'GB',
  },
  {
    symbol: '0EOI-LN',
    name: 'HF Company',
    region: 'GB',
  },
  {
    symbol: '0EPM-LN',
    name: 'Holland Colours - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0EPR-LN',
    name: 'Honkarakenne - Class B',
    region: 'GB',
  },
  {
    symbol: '0EPW-LN',
    name: 'Hochtief AG',
    region: 'GB',
  },
  {
    symbol: '0EQO-LN',
    name: 'Hunter Douglas NV - PRF PERPETUAL EUR 0.24',
    region: 'GB',
  },
  {
    symbol: '0ERG-LN',
    name: 'ITN Nanovation AG',
    region: 'GB',
  },
  {
    symbol: '0ERM-LN',
    name: 'Iberpapel Gestion S.A.',
    region: 'GB',
  },
  {
    symbol: '0ERU-LN',
    name: 'Icelandair Group hf.',
    region: 'GB',
  },
  {
    symbol: '0ERY-LN',
    name: 'Incap Oyj',
    region: 'GB',
  },
  {
    symbol: '0EUH-LN',
    name: 'Indus Holding AG',
    region: 'GB',
  },
  {
    symbol: '0EV1-LN',
    name: 'Carnival Corp. (Paired Stock)',
    region: 'GB',
  },
  {
    symbol: '0EVB-LN',
    name: 'Airtificial Intelligence Structures SA',
    region: 'GB',
  },
  {
    symbol: '0EVE-LN',
    name: 'Interoil Exploration & Production',
    region: 'GB',
  },
  {
    symbol: '0EVI-LN',
    name: 'Innate Pharma - Class A',
    region: 'GB',
  },
  {
    symbol: '0EWD-LN',
    name: 'Interpump Group Spa',
    region: 'GB',
  },
  {
    symbol: '0EWJ-LN',
    name: 'KAP AG',
    region: 'GB',
  },
  {
    symbol: '0EWO-LN',
    name: 'Invision Software AG',
    region: 'GB',
  },
  {
    symbol: '0EWR-LN',
    name: 'Traffic Systems SE',
    region: 'GB',
  },
  {
    symbol: '0EX6-LN',
    name: 'Jensen-Group NV',
    region: 'GB',
  },
  {
    symbol: '0EX9-LN',
    name: 'Joh. Friedrich Behrens AG',
    region: 'GB',
  },
  {
    symbol: '0EXG-LN',
    name: 'Jeronimo Martins - S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0EXP-LN',
    name: 'Jungheinrich AG - PRF PERPETUAL EUR 1',
    region: 'GB',
  },
  {
    symbol: '0EXW-LN',
    name: 'Klassik Radio',
    region: 'GB',
  },
  {
    symbol: '0EYG-LN',
    name: 'KBC Group SA NV',
    region: 'GB',
  },
  {
    symbol: '0EYJ-LN',
    name: 'Kobenhavns Lufthavne',
    region: 'GB',
  },
  {
    symbol: '0EYY-LN',
    name: 'Kesla - Class A',
    region: 'GB',
  },
  {
    symbol: '0EYZ-LN',
    name: 'KEO Plc',
    region: 'GB',
  },
  {
    symbol: '0EZ1-LN',
    name: 'Flour Mills Kepenos S.A.',
    region: 'GB',
  },
  {
    symbol: '0F07-LN',
    name: 'Kaufman & Broad',
    region: 'GB',
  },
  {
    symbol: '0F08-LN',
    name: 'Kongsberg Gruppen ASA',
    region: 'GB',
  },
  {
    symbol: '0F0J-LN',
    name: 'Kitron',
    region: 'GB',
  },
  {
    symbol: '0F1C-LN',
    name: 'Keskisuomalainen - Class A',
    region: 'GB',
  },
  {
    symbol: '0F1N-LN',
    name: 'KWS SAAT SE & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0F1U-LN',
    name: 'Lacroix Group',
    region: 'GB',
  },
  {
    symbol: '0F21-LN',
    name: 'Lampsa Hotel Co.',
    region: 'GB',
  },
  {
    symbol: '0F29-LN',
    name: 'Lassila & Tikanoja',
    region: 'GB',
  },
  {
    symbol: '0F2N-LN',
    name: 'LDLC Groups',
    region: 'GB',
  },
  {
    symbol: '0F2Q-LN',
    name: 'La Doria Spa',
    region: 'GB',
  },
  {
    symbol: '0F2S-LN',
    name: 'Linedata Services SA',
    region: 'GB',
  },
  {
    symbol: '0F2Z-LN',
    name: 'Leifheit AG',
    region: 'GB',
  },
  {
    symbol: '0F3G-LN',
    name: 'Lingotes Especiales',
    region: 'GB',
  },
  {
    symbol: '0F3Y-LN',
    name: 'Liwe Espanola',
    region: 'GB',
  },
  {
    symbol: '0F4G-LN',
    name: 'Logicom Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0F4O-LN',
    name: 'Lotus Bakeries',
    region: 'GB',
  },
  {
    symbol: '0F4R-LN',
    name: 'Loulis Mills S.A.',
    region: 'GB',
  },
  {
    symbol: '0F55-LN',
    name: 'Apetit Oyj',
    region: 'GB',
  },
  {
    symbol: '0F58-LN',
    name: 'Grupa Lotos SA',
    region: 'GB',
  },
  {
    symbol: '0F6A-LN',
    name: 'Manner Josef & Comp. AG',
    region: 'GB',
  },
  {
    symbol: '0F6F-LN',
    name: 'Galimmo',
    region: 'GB',
  },
  {
    symbol: '0F6I-LN',
    name: 'Manutan International',
    region: 'GB',
  },
  {
    symbol: '0F6L-LN',
    name: 'Maurel Et Prom',
    region: 'GB',
  },
  {
    symbol: '0F6S-LN',
    name: 'Berthold Hermle AG - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0F7B-LN',
    name: 'Grupo Media Capital SGPS SA',
    region: 'GB',
  },
  {
    symbol: '0F7F-LN',
    name: 'Duro Felguera S.A.',
    region: 'GB',
  },
  {
    symbol: '0F7M-LN',
    name: 'Medion AG',
    region: 'GB',
  },
  {
    symbol: '0F8T-LN',
    name: 'Hexaom',
    region: 'GB',
  },
  {
    symbol: '0F8V-LN',
    name: 'Akwel',
    region: 'GB',
  },
  {
    symbol: '0F9F-LN',
    name: 'Minerva Insurance Company Public Ltd',
    region: 'GB',
  },
  {
    symbol: '0F9X-LN',
    name: 'MLS Innovation Inc.',
    region: 'GB',
  },
  {
    symbol: '0FA0-LN',
    name: 'Melexis N.V.',
    region: 'GB',
  },
  {
    symbol: '0FB0-LN',
    name: 'Mota-engil S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0FB8-LN',
    name: 'Muehl Product & Service AG',
    region: 'GB',
  },
  {
    symbol: '0FBM-LN',
    name: 'Micro Systemations - Class B',
    region: 'GB',
  },
  {
    symbol: '0FBS-LN',
    name: 'Orange Belgium',
    region: 'GB',
  },
  {
    symbol: '0FC6-LN',
    name: 'Mittel Spa',
    region: 'GB',
  },
  {
    symbol: '0FC9-LN',
    name: 'MTU Aero Engines AG',
    region: 'GB',
  },
  {
    symbol: '0FCK-LN',
    name: 'MVV Energie AG',
    region: 'GB',
  },
  {
    symbol: '0FCT-LN',
    name: 'Masterflex SE',
    region: 'GB',
  },
  {
    symbol: '0FDN-LN',
    name: 'Nicolas Correa',
    region: 'GB',
  },
  {
    symbol: '0FDT-LN',
    name: 'Nemetschek SE',
    region: 'GB',
  },
  {
    symbol: '0FE2-LN',
    name: 'Skue Sparebank',
    region: 'GB',
  },
  {
    symbol: '0FF9-LN',
    name: 'Nordic Semiconductor',
    region: 'GB',
  },
  {
    symbol: '0FFX-LN',
    name: 'Nordfyns Bank',
    region: 'GB',
  },
  {
    symbol: '0FFY-LN',
    name: 'Nokian Renkaat Oyj',
    region: 'GB',
  },
  {
    symbol: '0FGD-LN',
    name: 'Nucletron Electronic AG - Class A',
    region: 'GB',
  },
  {
    symbol: '0FGH-LN',
    name: 'Norwegian Air Shuttle ASA',
    region: 'GB',
  },
  {
    symbol: '0FGL-LN',
    name: 'Nexus AG',
    region: 'GB',
  },
  {
    symbol: '0FGS-LN',
    name: 'Orbis AG',
    region: 'GB',
  },
  {
    symbol: '0FH7-LN',
    name: 'OHB SE',
    region: 'GB',
  },
  {
    symbol: '0FHA-LN',
    name: 'Oil Terminal Constanta',
    region: 'GB',
  },
  {
    symbol: '0FHN-LN',
    name: 'Installux S.A.',
    region: 'GB',
  },
  {
    symbol: '0FHO-LN',
    name: 'Piraeus Port Authority S.A',
    region: 'GB',
  },
  {
    symbol: '0FHS-LN',
    name: 'Olvi Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0FI1-LN',
    name: 'Greek Organisation Of Football Prognostics S.A.',
    region: 'GB',
  },
  {
    symbol: '0FI5-LN',
    name: 'Otello Corporation ASA',
    region: 'GB',
  },
  {
    symbol: '0FIN-LN',
    name: 'Orkla',
    region: 'GB',
  },
  {
    symbol: '0FIW-LN',
    name: 'Ossur hf.',
    region: 'GB',
  },
  {
    symbol: '0FIZ-LN',
    name: 'Hellenic Telecommunications Organization S.A.',
    region: 'GB',
  },
  {
    symbol: '0FJ5-LN',
    name: 'Cos Targoviste S.A.',
    region: 'GB',
  },
  {
    symbol: '0FJ8-LN',
    name: 'Outokumpu Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0FM1-LN',
    name: 'Piaggio & C. S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0FM2-LN',
    name: 'Picanol',
    region: 'GB',
  },
  {
    symbol: '0FME-LN',
    name: 'Pittler Maschinenfabr AG',
    region: 'GB',
  },
  {
    symbol: '0FMN-LN',
    name: 'Polski Koncern Naftowy Orlen S.A.',
    region: 'GB',
  },
  {
    symbol: '0FMO-LN',
    name: 'Powszechna Kasa Oszczednosci Bank Polski SA',
    region: 'GB',
  },
  {
    symbol: '0FN3-LN',
    name: 'Sparebanken Sor.',
    region: 'GB',
  },
  {
    symbol: '0FNI-LN',
    name: 'Pininfarina Spa',
    region: 'GB',
  },
  {
    symbol: '0FNZ-LN',
    name: 'Ponsse',
    region: 'GB',
  },
  {
    symbol: '0FOD-LN',
    name: 'Prodplast Bucuresti',
    region: 'GB',
  },
  {
    symbol: '0FOS-LN',
    name: 'Precia',
    region: 'GB',
  },
  {
    symbol: '0FP0-LN',
    name: 'Prim S.A.',
    region: 'GB',
  },
  {
    symbol: '0FP4-LN',
    name: 'Pierrel',
    region: 'GB',
  },
  {
    symbol: '0FP9-LN',
    name: 'Banca Profilo Spa',
    region: 'GB',
  },
  {
    symbol: '0FPJ-LN',
    name: 'Prologue',
    region: 'GB',
  },
  {
    symbol: '0FQ8-LN',
    name: 'PHarol SGPS SA',
    region: 'GB',
  },
  {
    symbol: '0FQI-LN',
    name: 'Publicis Groupe SA',
    region: 'GB',
  },
  {
    symbol: '0FQN-LN',
    name: 'IEP Invest',
    region: 'GB',
  },
  {
    symbol: '0FQR-LN',
    name: 'Pfeiffer Vacuum Technology AG',
    region: 'GB',
  },
  {
    symbol: '0FQS-LN',
    name: 'Pescanova',
    region: 'GB',
  },
  {
    symbol: '0FRI-LN',
    name: 'Lumibird',
    region: 'GB',
  },
  {
    symbol: '0FRJ-LN',
    name: 'Rational AG',
    region: 'GB',
  },
  {
    symbol: '0FRT-LN',
    name: 'Rath AG',
    region: 'GB',
  },
  {
    symbol: '0FRW-LN',
    name: 'Rosenbauer International AG',
    region: 'GB',
  },
  {
    symbol: '0FS8-LN',
    name: 'REC Silicon ASA',
    region: 'GB',
  },
  {
    symbol: '0FSJ-LN',
    name: 'Renta Corporacion Real Estate',
    region: 'GB',
  },
  {
    symbol: '0FSN-LN',
    name: 'Resilux NV',
    region: 'GB',
  },
  {
    symbol: '0FT3-LN',
    name: 'Riber',
    region: 'GB',
  },
  {
    symbol: '0FTQ-LN',
    name: 'Risanamento Spa',
    region: 'GB',
  },
  {
    symbol: '0FTZ-LN',
    name: 'RoodMicrotec N.V',
    region: 'GB',
  },
  {
    symbol: '0FUW-LN',
    name: 'Raute Plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0FV0-LN',
    name: 'Recylex S. A.',
    region: 'GB',
  },
  {
    symbol: '0FWN-LN',
    name: 'Sandnes Sparebank',
    region: 'GB',
  },
  {
    symbol: '0FWY-LN',
    name: 'Salmar Asa',
    region: 'GB',
  },
  {
    symbol: '0FYA-LN',
    name: 'Toyota Caetano Portugal S.A.',
    region: 'GB',
  },
  {
    symbol: '0FZB-LN',
    name: 'Societe Francaise de Casinos SA',
    region: 'GB',
  },
  {
    symbol: '0G0X-LN',
    name: 'First Sensor AG',
    region: 'GB',
  },
  {
    symbol: '0G11-LN',
    name: 'Sinteza Oradea',
    region: 'GB',
  },
  {
    symbol: '0G15-LN',
    name: 'Koenig & Bauer AG',
    region: 'GB',
  },
  {
    symbol: '0G19-LN',
    name: 'Skjern Bank',
    region: 'GB',
  },
  {
    symbol: '0G1T-LN',
    name: 'Wavestone',
    region: 'GB',
  },
  {
    symbol: '0G29-LN',
    name: 'Semperit AG Holding',
    region: 'GB',
  },
  {
    symbol: '0G2C-LN',
    name: 'SMTPC - Societe Marseillaise du Tunnel Prado-Carenage',
    region: 'GB',
  },
  {
    symbol: '0G2O-LN',
    name: 'Soprano Oyj',
    region: 'GB',
  },
  {
    symbol: '0G2P-LN',
    name: 'Santierul Naval Orsova',
    region: 'GB',
  },
  {
    symbol: '0G2X-LN',
    name: 'Sofina',
    region: 'GB',
  },
  {
    symbol: '0G2Z-LN',
    name: 'Solstad Offshore ASA',
    region: 'GB',
  },
  {
    symbol: '0G30-LN',
    name: 'Socfinasia',
    region: 'GB',
  },
  {
    symbol: '0G33-LN',
    name: 'Solar AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0G3L-LN',
    name: 'Copperstone Resources AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0G3T-LN',
    name: 'Fortnox AB',
    region: 'GB',
  },
  {
    symbol: '0G40-LN',
    name: 'Sparebanken More',
    region: 'GB',
  },
  {
    symbol: '0G45-LN',
    name: 'Sparebanken Ost',
    region: 'GB',
  },
  {
    symbol: '0G4V-LN',
    name: 'Suedwestdeutsche Salzwerke AG',
    region: 'GB',
  },
  {
    symbol: '0G5B-LN',
    name: 'STO SE & CO - ZC PRF PERPETUAL EUR 21',
    region: 'GB',
  },
  {
    symbol: '0G5H-LN',
    name: 'Solteq Plc',
    region: 'GB',
  },
  {
    symbol: '0G67-LN',
    name: 'Sparebanken Vest',
    region: 'GB',
  },
  {
    symbol: '0G68-LN',
    name: 'Kendrion NV',
    region: 'GB',
  },
  {
    symbol: '0G6J-LN',
    name: 'Schloss Wachenheim AG',
    region: 'GB',
  },
  {
    symbol: '0G6O-LN',
    name: 'SW Umwelttechnik Stoiser&Wolschner AG',
    region: 'GB',
  },
  {
    symbol: '0G6T-LN',
    name: 'Symrise AG',
    region: 'GB',
  },
  {
    symbol: '0G6W-LN',
    name: 'SaltX Technology Holding AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0G6X-LN',
    name: 'Clavister Hldg AB',
    region: 'GB',
  },
  {
    symbol: '0G73-LN',
    name: 'Softing AG',
    region: 'GB',
  },
  {
    symbol: '0G77-LN',
    name: 'Salzgitter AG',
    region: 'GB',
  },
  {
    symbol: '0G79-LN',
    name: 'SyntheticMR AB',
    region: 'GB',
  },
  {
    symbol: '0G7B-LN',
    name: 'Sudzucker AG.',
    region: 'GB',
  },
  {
    symbol: '0G7C-LN',
    name: 'Sinnerschrader AG',
    region: 'GB',
  },
  {
    symbol: '0G7L-LN',
    name: 'Tas - Tecnologia Avanzata Dei Sistemi Spa',
    region: 'GB',
  },
  {
    symbol: '0G7N-LN',
    name: 'MyFC Holding AB',
    region: 'GB',
  },
  {
    symbol: '0G85-LN',
    name: 'BrainCool AB',
    region: 'GB',
  },
  {
    symbol: '0G8C-LN',
    name: 'Telenor ASA',
    region: 'GB',
  },
  {
    symbol: '0G8L-LN',
    name: 'Ter Beke',
    region: 'GB',
  },
  {
    symbol: '0G8X-LN',
    name: 'Immunovia AB',
    region: 'GB',
  },
  {
    symbol: '0G8Z-LN',
    name: 'HolidayCheck Group AG',
    region: 'GB',
  },
  {
    symbol: '0G91-LN',
    name: 'Trevi - Finanziaria Industriale Spa',
    region: 'GB',
  },
  {
    symbol: '0G99-LN',
    name: 'Oxurion NV',
    region: 'GB',
  },
  {
    symbol: '0G9K-LN',
    name: 'Tipiak',
    region: 'GB',
  },
  {
    symbol: '0G9R-LN',
    name: 'PowerCell Sweden AB.',
    region: 'GB',
  },
  {
    symbol: '0G9W-LN',
    name: 'Mediaset Espana Comunicacion,S.A',
    region: 'GB',
  },
  {
    symbol: '0GA3-LN',
    name: 'Telecom Italia S.p.A. - PRF PERPETUAL EUR 0.55',
    region: 'GB',
  },
  {
    symbol: '0GAF-LN',
    name: 'Telenet Group Hldgs NV',
    region: 'GB',
  },
  {
    symbol: '0GAV-LN',
    name: 'Totalbanken',
    region: 'GB',
  },
  {
    symbol: '0GB4-LN',
    name: 'Hovding Sverige AB',
    region: 'GB',
  },
  {
    symbol: '0GB5-LN',
    name: 'Nilorngruppen AB. - Class B',
    region: 'GB',
  },
  {
    symbol: '0GB7-LN',
    name: 'Orange Polska Spolka Akcyjna',
    region: 'GB',
  },
  {
    symbol: '0GBM-LN',
    name: 'Eyeonid Group AB',
    region: 'GB',
  },
  {
    symbol: '0GBU-LN',
    name: 'Storytel AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GC8-LN',
    name: 'Takkt AG',
    region: 'GB',
  },
  {
    symbol: '0GCH-LN',
    name: 'Imint Image Intelligence AB',
    region: 'GB',
  },
  {
    symbol: '0GCJ-LN',
    name: 'Tulikivi Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0GCM-LN',
    name: 'Saga Furs Oyj - Class C',
    region: 'GB',
  },
  {
    symbol: '0GCN-LN',
    name: 'Turbon AG',
    region: 'GB',
  },
  {
    symbol: '0GCY-LN',
    name: 'Thessaloniki Water & Sewage Co. S.A.',
    region: 'GB',
  },
  {
    symbol: '0GD5-LN',
    name: 'UBM Development AG',
    region: 'GB',
  },
  {
    symbol: '0GDH-LN',
    name: 'LeoVegas AB',
    region: 'GB',
  },
  {
    symbol: '0GDR-LN',
    name: 'Uniqa Insurance Group AG',
    region: 'GB',
  },
  {
    symbol: '0GDU-LN',
    name: 'Paradox Interactive AB',
    region: 'GB',
  },
  {
    symbol: '0GDW-LN',
    name: 'Irras AB',
    region: 'GB',
  },
  {
    symbol: '0GE4-LN',
    name: 'United Internet AG',
    region: 'GB',
  },
  {
    symbol: '0GE8-LN',
    name: 'GomSpace Group AB',
    region: 'GB',
  },
  {
    symbol: '0GEA-LN',
    name: 'Maha Energy AB - Units (4 Ordinary & 1 Warrants - 15/01/2018)',
    region: 'GB',
  },
  {
    symbol: '0GED-LN',
    name: 'Viscom AG',
    region: 'GB',
  },
  {
    symbol: '0GEG-LN',
    name: 'Vaisala Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0GEJ-LN',
    name: 'Valsoia Spa',
    region: 'GB',
  },
  {
    symbol: '0GF1-LN',
    name: 'Gapwaves AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GF6-LN',
    name: 'Veidekke ASA',
    region: 'GB',
  },
  {
    symbol: '0GFA-LN',
    name: 'BICO Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GFE-LN',
    name: 'Embracer Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GFN-LN',
    name: 'AAC Clyde Space AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0GFY-LN',
    name: 'Viking Line Abp',
    region: 'GB',
  },
  {
    symbol: '0GGK-LN',
    name: 'Vestjysk Bank',
    region: 'GB',
  },
  {
    symbol: '0GGL-LN',
    name: 'XMReality AB',
    region: 'GB',
  },
  {
    symbol: '0GH2-LN',
    name: 'Vogiatzoglou Systems S.A',
    region: 'GB',
  },
  {
    symbol: '0GH9-LN',
    name: 'Terranet AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GHF-LN',
    name: 'OXE Marine AB.',
    region: 'GB',
  },
  {
    symbol: '0GHX-LN',
    name: 'Climeon AB',
    region: 'GB',
  },
  {
    symbol: '0GHZ-LN',
    name: 'XSpray Pharma AB',
    region: 'GB',
  },
  {
    symbol: '0GIM-LN',
    name: 'Touchtech',
    region: 'GB',
  },
  {
    symbol: '0GJ1-LN',
    name: 'MAG Interactive AB',
    region: 'GB',
  },
  {
    symbol: '0GJA-LN',
    name: 'Wolford AG',
    region: 'GB',
  },
  {
    symbol: '0GJK-LN',
    name: 'Washtec Ag',
    region: 'GB',
  },
  {
    symbol: '0GJN-LN',
    name: 'Wustenrot & Wurttembergische AG',
    region: 'GB',
  },
  {
    symbol: '0GJS-LN',
    name: 'Xilam Animation',
    region: 'GB',
  },
  {
    symbol: '0GK0-LN',
    name: 'Cast SA',
    region: 'GB',
  },
  {
    symbol: '0GK6-LN',
    name: 'Ymos AG',
    region: 'GB',
  },
  {
    symbol: '0GKA-LN',
    name: 'YIT Oyj',
    region: 'GB',
  },
  {
    symbol: '0GLY-LN',
    name: 'Samse',
    region: 'GB',
  },
  {
    symbol: '0GM2-LN',
    name: 'Leroy Seafood Group',
    region: 'GB',
  },
  {
    symbol: '0GM9-LN',
    name: 'ESI Group',
    region: 'GB',
  },
  {
    symbol: '0GMG-LN',
    name: 'Addnode Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GNK-LN',
    name: 'Know IT AB',
    region: 'GB',
  },
  {
    symbol: '0GO4-LN',
    name: 'Swedish Match',
    region: 'GB',
  },
  {
    symbol: '0GO6-LN',
    name: 'Studsvik AB',
    region: 'GB',
  },
  {
    symbol: '0GOG-LN',
    name: 'Concejo AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GOS-LN',
    name: 'Traction Holding - Class B',
    region: 'GB',
  },
  {
    symbol: '0GOX-LN',
    name: 'Polytec Holding AG',
    region: 'GB',
  },
  {
    symbol: '0GP7-LN',
    name: 'Medivir AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GQE-LN',
    name: 'Clas Ohlson AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GQU-LN',
    name: 'Active Biotech AB',
    region: 'GB',
  },
  {
    symbol: '0GQY-LN',
    name: 'Modern Times Group AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0GRK-LN',
    name: 'Millicom International Cellular S.A. - SDR',
    region: 'GB',
  },
  {
    symbol: '0GRP-LN',
    name: 'Biotage AB',
    region: 'GB',
  },
  {
    symbol: '0GRX-LN',
    name: 'Hexagon AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GRZ-LN',
    name: 'Ray Search Laboratories - Class B',
    region: 'GB',
  },
  {
    symbol: '0GSB-LN',
    name: 'Poolia AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GSE-LN',
    name: 'Midsona AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GSS-LN',
    name: 'Note AB',
    region: 'GB',
  },
  {
    symbol: '0GT3-LN',
    name: 'ProAct IT Group AB',
    region: 'GB',
  },
  {
    symbol: '0GT7-LN',
    name: 'Metabolic Explorer',
    region: 'GB',
  },
  {
    symbol: '0GT8-LN',
    name: 'Beijer Electronics Group AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0GTN-LN',
    name: 'BioGaia AG - Class B',
    region: 'GB',
  },
  {
    symbol: '0GTO-LN',
    name: 'Kapsch Trafficcom AG',
    region: 'GB',
  },
  {
    symbol: '0GTR-LN',
    name: 'Husqvarna AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GTS-LN',
    name: 'NR 21',
    region: 'GB',
  },
  {
    symbol: '0GU1-LN',
    name: 'Zhongde Waste Technology AG',
    region: 'GB',
  },
  {
    symbol: '0GU8-LN',
    name: 'Softronic AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GUO-LN',
    name: 'CTT Systems AB',
    region: 'GB',
  },
  {
    symbol: '0GUX-LN',
    name: 'Skandinaviska Enskilda Banken - Class C',
    region: 'GB',
  },
  {
    symbol: '0GVP-LN',
    name: 'Proodeftikh Technical Company',
    region: 'GB',
  },
  {
    symbol: '0GW0-LN',
    name: 'Nobia AB',
    region: 'GB',
  },
  {
    symbol: '0GW3-LN',
    name: 'Hufvudstaden AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0GW8-LN',
    name: 'Grieg Seafood',
    region: 'GB',
  },
  {
    symbol: '0GWB-LN',
    name: 'L E Lundbergfoeretagen AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GWF-LN',
    name: 'Condmag - Brasov',
    region: 'GB',
  },
  {
    symbol: '0GWI-LN',
    name: 'Husqvarna AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0GWJ-LN',
    name: 'Clinica Baviera',
    region: 'GB',
  },
  {
    symbol: '0GWL-LN',
    name: 'SAAB AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GWP-LN',
    name: 'Ratos AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0GWS-LN',
    name: 'BillerudKorsnas AB',
    region: 'GB',
  },
  {
    symbol: '0GX2-LN',
    name: 'Neurones',
    region: 'GB',
  },
  {
    symbol: '0GX5-LN',
    name: 'Sweco AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0GXB-LN',
    name: 'Bialetti Industrie S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0GXD-LN',
    name: 'Svedbergs i Dalstorp AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GXJ-LN',
    name: 'Modern Times Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GXK-LN',
    name: 'VBG Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GY8-LN',
    name: 'Aeffe',
    region: 'GB',
  },
  {
    symbol: '0GYM-LN',
    name: 'Ackermans & Van Haaren',
    region: 'GB',
  },
  {
    symbol: '0GYQ-LN',
    name: 'Varta AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0GYZ-LN',
    name: 'Nordic Mining ASA',
    region: 'GB',
  },
  {
    symbol: '0GZ4-LN',
    name: 'MultiQ International AB',
    region: 'GB',
  },
  {
    symbol: '0GZB-LN',
    name: 'Probi AB',
    region: 'GB',
  },
  {
    symbol: '0GZJ-LN',
    name: 'Ecotel Communication AG',
    region: 'GB',
  },
  {
    symbol: '0GZK-LN',
    name: 'Ion Beam Applications SA',
    region: 'GB',
  },
  {
    symbol: '0GZV-LN',
    name: 'Getinge AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0GZX-LN',
    name: 'Diasorin S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0H00-LN',
    name: 'Banco de Sabadell, S.A.',
    region: 'GB',
  },
  {
    symbol: '0H0G-LN',
    name: 'Sweco AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0H0L-LN',
    name: 'Rottneros AB',
    region: 'GB',
  },
  {
    symbol: '0H0U-LN',
    name: 'Sensys Gatso Group AB',
    region: 'GB',
  },
  {
    symbol: '0H13-LN',
    name: 'Industrivarden, AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0H14-LN',
    name: 'Net Insight AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0H19-LN',
    name: 'Orexo AB',
    region: 'GB',
  },
  {
    symbol: '0H1Q-LN',
    name: 'Ortivus AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0H22-LN',
    name: 'BioInvent International AB',
    region: 'GB',
  },
  {
    symbol: '0H2J-LN',
    name: 'Prevas AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0H2W-LN',
    name: 'Bergs Timber AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0H30-LN',
    name: 'Indutrade AB',
    region: 'GB',
  },
  {
    symbol: '0H38-LN',
    name: 'Pricer AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0H3Q-LN',
    name: 'Deutsche Post AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0H4A-LN',
    name: 'Deutsche Lufthansa AG',
    region: 'GB',
  },
  {
    symbol: '0H4K-LN',
    name: 'Acciona',
    region: 'GB',
  },
  {
    symbol: '0H4N-LN',
    name: 'Siemens Gamesa Renewable Energy SA',
    region: 'GB',
  },
  {
    symbol: '0H59-LN',
    name: 'Accor',
    region: 'GB',
  },
  {
    symbol: '0H65-LN',
    name: 'Juventus Football Club',
    region: 'GB',
  },
  {
    symbol: '0H68-LN',
    name: 'Aflac Inc.',
    region: 'GB',
  },
  {
    symbol: '0H6G-LN',
    name: 'AES Corp.',
    region: 'GB',
  },
  {
    symbol: '0H6I-LN',
    name: 'Telecom Italia S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0H6T-LN',
    name: 'Swedbank AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0H6X-LN',
    name: 'Telia Company AB',
    region: 'GB',
  },
  {
    symbol: '0H7D-LN',
    name: 'Deutsche Bank AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0H7I-LN',
    name: 'Lanxess AG',
    region: 'GB',
  },
  {
    symbol: '0H7O-LN',
    name: 'Bankinter SA - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0H7R-LN',
    name: 'Abeona Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0H7S-LN',
    name: 'Abiomed Inc.',
    region: 'GB',
  },
  {
    symbol: '0H84-LN',
    name: 'Dorstener Maschinenfabrik AG',
    region: 'GB',
  },
  {
    symbol: '0H8E-LN',
    name: 'Accelerate Diagnostics Inc',
    region: 'GB',
  },
  {
    symbol: '0H8X-LN',
    name: 'Activision Blizzard Inc',
    region: 'GB',
  },
  {
    symbol: '0H90-LN',
    name: 'Acuity Brands, Inc.',
    region: 'GB',
  },
  {
    symbol: '0H9G-LN',
    name: 'Advance Auto Parts Inc',
    region: 'GB',
  },
  {
    symbol: '0H9M-LN',
    name: 'Carasent ASA',
    region: 'GB',
  },
  {
    symbol: '0H9P-LN',
    name: 'Intrum AB',
    region: 'GB',
  },
  {
    symbol: '0H9X-LN',
    name: 'Fresenius Medical Care AG & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0HA0-LN',
    name: 'RWE AG - Class A',
    region: 'GB',
  },
  {
    symbol: '0HA5-LN',
    name: 'Aerojet Rocketdyne Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0HA9-LN',
    name: 'Indra Sistemas - Class A',
    region: 'GB',
  },
  {
    symbol: '0HAA-LN',
    name: 'Fomento De Construcciones Y Contratas, S.A.',
    region: 'GB',
  },
  {
    symbol: '0HAC-LN',
    name: 'ACS, Actividades de Construccion Y Servicios, S.A.',
    region: 'GB',
  },
  {
    symbol: '0HAF-LN',
    name: 'Nokia Corp',
    region: 'GB',
  },
  {
    symbol: '0HAG-LN',
    name: 'Sampo Plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0HAH-LN',
    name: 'Fortum Oyj',
    region: 'GB',
  },
  {
    symbol: '0HAI-LN',
    name: 'Credit Agricole S.A.',
    region: 'GB',
  },
  {
    symbol: '0HAN-LN',
    name: 'Bouygues',
    region: 'GB',
  },
  {
    symbol: '0HAR-LN',
    name: 'Axa',
    region: 'GB',
  },
  {
    symbol: '0HAT-LN',
    name: 'Pernod Ricard',
    region: 'GB',
  },
  {
    symbol: '0HAU-LN',
    name: 'Lvmh Moet Hennessy Vuitton SE',
    region: 'GB',
  },
  {
    symbol: '0HAV-LN',
    name: 'Agilent Technologies Inc.',
    region: 'GB',
  },
  {
    symbol: '0HAZ-LN',
    name: 'Capgemini',
    region: 'GB',
  },
  {
    symbol: '0HB1-LN',
    name: 'Casino Guichard Perrachon',
    region: 'GB',
  },
  {
    symbol: '0HB2-LN',
    name: 'Lagardere SA - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0HB4-LN',
    name: 'Dassault Systemes SA',
    region: 'GB',
  },
  {
    symbol: '0HB5-LN',
    name: 'BNP Paribas',
    region: 'GB',
  },
  {
    symbol: '0HBA-LN',
    name: 'Electricite de France.',
    region: 'GB',
  },
  {
    symbol: '0HBC-LN',
    name: 'Intesa Sanpaolo Spa',
    region: 'GB',
  },
  {
    symbol: '0HBF-LN',
    name: 'Mediobanca - Banca Di Credito Finanziario Spa',
    region: 'GB',
  },
  {
    symbol: '0HBH-LN',
    name: 'Air Products & Chemicals Inc.',
    region: 'GB',
  },
  {
    symbol: '0HBP-LN',
    name: 'Hennes & Mauritz AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0HBT-LN',
    name: 'Skanska AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0HBY-LN',
    name: 'Skandinaviska Enskilda Banken - Class A',
    region: 'GB',
  },
  {
    symbol: '0HC0-LN',
    name: 'Sandvik AB',
    region: 'GB',
  },
  {
    symbol: '0HC3-LN',
    name: 'Alaska Air Group Inc.',
    region: 'GB',
  },
  {
    symbol: '0HC7-LN',
    name: 'Albemarle Corp.',
    region: 'GB',
  },
  {
    symbol: '0HC8-LN',
    name: 'Roularta Media Group',
    region: 'GB',
  },
  {
    symbol: '0HCB-LN',
    name: 'Alcoa Corp',
    region: 'GB',
  },
  {
    symbol: '0HCC-LN',
    name: '3U Holding AG',
    region: 'GB',
  },
  {
    symbol: '0HCI-LN',
    name: 'Alibaba Group Holding Ltd - ADR',
    region: 'GB',
  },
  {
    symbol: '0HCR-LN',
    name: 'Bread Financial Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0HCT-LN',
    name: 'Alliant Energy Corp.',
    region: 'GB',
  },
  {
    symbol: '0HCZ-LN',
    name: 'Allstate Corp (The)',
    region: 'GB',
  },
  {
    symbol: '0HD0-LN',
    name: 'Ally Financial Inc',
    region: 'GB',
  },
  {
    symbol: '0HD6-LN',
    name: 'Alphabet Inc - Class C',
    region: 'GB',
  },
  {
    symbol: '0HDJ-LN',
    name: 'Mekonomen AB',
    region: 'GB',
  },
  {
    symbol: '0HDK-LN',
    name: 'Systemair AB',
    region: 'GB',
  },
  {
    symbol: '0HDP-LN',
    name: 'DS Norden',
    region: 'GB',
  },
  {
    symbol: '0HDQ-LN',
    name: 'Synergie',
    region: 'GB',
  },
  {
    symbol: '0HDU-LN',
    name: 'Bouvet Asa',
    region: 'GB',
  },
  {
    symbol: '0HDY-LN',
    name: 'Golar Lng',
    region: 'GB',
  },
  {
    symbol: '0HE2-LN',
    name: 'Ameren Corp.',
    region: 'GB',
  },
  {
    symbol: '0HE6-LN',
    name: 'American Airlines Group Inc',
    region: 'GB',
  },
  {
    symbol: '0HEC-LN',
    name: 'American Electric Power Company Inc.',
    region: 'GB',
  },
  {
    symbol: '0HEU-LN',
    name: 'American Tower Corp.',
    region: 'GB',
  },
  {
    symbol: '0HEW-LN',
    name: 'American Water Works Co. Inc.',
    region: 'GB',
  },
  {
    symbol: '0HF3-LN',
    name: 'Amerisource Bergen Corp.',
    region: 'GB',
  },
  {
    symbol: '0HF7-LN',
    name: 'Ametek Inc',
    region: 'GB',
  },
  {
    symbol: '0HFB-LN',
    name: 'Amphenol Corp. - Class A',
    region: 'GB',
  },
  {
    symbol: '0HFN-LN',
    name: 'Analog Devices Inc.',
    region: 'GB',
  },
  {
    symbol: '0HFR-LN',
    name: 'Anavex Life Sciences Corporation',
    region: 'GB',
  },
  {
    symbol: '0HG3-LN',
    name: 'Ansys Inc. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0HG8-LN',
    name: 'Anthem Inc',
    region: 'GB',
  },
  {
    symbol: '0HGR-LN',
    name: 'Applied Industrial Technologies Inc.',
    region: 'GB',
  },
  {
    symbol: '0HH3-LN',
    name: 'Aptevo Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0HHB-LN',
    name: 'Aramark',
    region: 'GB',
  },
  {
    symbol: '0HHI-LN',
    name: 'Surteco Group SE',
    region: 'GB',
  },
  {
    symbol: '0HHJ-LN',
    name: 'Arch Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0HHO-LN',
    name: 'Argan, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HHU-LN',
    name: 'ARMOUR Residential REIT Inc',
    region: 'GB',
  },
  {
    symbol: '0HI1-LN',
    name: 'Arrow Electronics Inc.',
    region: 'GB',
  },
  {
    symbol: '0HI3-LN',
    name: 'Arrowhead Pharmaceuticals Inc.',
    region: 'GB',
  },
  {
    symbol: '0HI7-LN',
    name: 'Mevis Medical Solutions AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0HIN-LN',
    name: 'Assurant Inc',
    region: 'GB',
  },
  {
    symbol: '0HIT-LN',
    name: 'Iberdrola S.A.',
    region: 'GB',
  },
  {
    symbol: '0HJ5-LN',
    name: 'Oceanteam ASA',
    region: 'GB',
  },
  {
    symbol: '0HJC-LN',
    name: 'Vilmorin & Cie',
    region: 'GB',
  },
  {
    symbol: '0HJF-LN',
    name: 'Autodesk Inc.',
    region: 'GB',
  },
  {
    symbol: '0HJH-LN',
    name: 'Autoliv Inc.',
    region: 'GB',
  },
  {
    symbol: '0HJI-LN',
    name: 'Automatic Data Processing Inc.',
    region: 'GB',
  },
  {
    symbol: '0HJL-LN',
    name: 'Autozone Inc.',
    region: 'GB',
  },
  {
    symbol: '0HJR-LN',
    name: 'Avery Dennison Corp.',
    region: 'GB',
  },
  {
    symbol: '0HJS-LN',
    name: 'Melamin Kemiena tovarna d.d.',
    region: 'GB',
  },
  {
    symbol: '0HK4-LN',
    name: 'Avis Budget Group Inc',
    region: 'GB',
  },
  {
    symbol: '0HKD-LN',
    name: 'Axogen Inc.',
    region: 'GB',
  },
  {
    symbol: '0HKE-LN',
    name: 'Axon Enterprise Inc',
    region: 'GB',
  },
  {
    symbol: '0HKM-LN',
    name: 'BGC Partners Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0HKP-LN',
    name: 'BP plc - ADR',
    region: 'GB',
  },
  {
    symbol: '0HKS-LN',
    name: 'Axos Financial Inc.',
    region: 'GB',
  },
  {
    symbol: '0HL1-LN',
    name: 'Baidu Inc - ADR',
    region: 'GB',
  },
  {
    symbol: '0HL4-LN',
    name: 'Telekom Slovenije D.D.',
    region: 'GB',
  },
  {
    symbol: '0HL5-LN',
    name: 'Ball Corp.',
    region: 'GB',
  },
  {
    symbol: '0HLQ-LN',
    name: 'Bank Of New York Mellon Corp',
    region: 'GB',
  },
  {
    symbol: '0HMG-LN',
    name: 'Beazer Homes USA Inc.',
    region: 'GB',
  },
  {
    symbol: '0HMI-LN',
    name: 'Bed, Bath & Beyond Inc.',
    region: 'GB',
  },
  {
    symbol: '0HMM-LN',
    name: 'DOF ASA',
    region: 'GB',
  },
  {
    symbol: '0HMZ-LN',
    name: 'W.R. Berkley Corp.',
    region: 'GB',
  },
  {
    symbol: '0HN5-LN',
    name: 'Big Lots Inc',
    region: 'GB',
  },
  {
    symbol: '0HNG-LN',
    name: 'Alan Allman Associates',
    region: 'GB',
  },
  {
    symbol: '0HNZ-LN',
    name: 'Fagron NV',
    region: 'GB',
  },
  {
    symbol: '0HOB-LN',
    name: 'H&R Block Inc.',
    region: 'GB',
  },
  {
    symbol: '0HOT-LN',
    name: 'Booz Allen Hamilton Holding Corp - Class A',
    region: 'GB',
  },
  {
    symbol: '0HOU-LN',
    name: 'BorgWarner Inc',
    region: 'GB',
  },
  {
    symbol: '0HOY-LN',
    name: 'Boston Scientific Corp.',
    region: 'GB',
  },
  {
    symbol: '0HPH-LN',
    name: 'Brighthouse Financial Inc',
    region: 'GB',
  },
  {
    symbol: '0HPP-LN',
    name: 'Piquadro S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0HPW-LN',
    name: 'Broadridge Financial Solutions, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HQ1-LN',
    name: 'Azenta Inc',
    region: 'GB',
  },
  {
    symbol: '0HQ3-LN',
    name: 'Brown-Forman Corp. - Class B',
    region: 'GB',
  },
  {
    symbol: '0HQ7-LN',
    name: 'Buckle, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HQ8-LN',
    name: 'Arjo AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0HQI-LN',
    name: 'Cbiz Inc',
    region: 'GB',
  },
  {
    symbol: '0HQP-LN',
    name: 'CBRE Group Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0HQQ-LN',
    name: 'Fortec Elektronik Vertriebs AG',
    region: 'GB',
  },
  {
    symbol: '0HQR-LN',
    name: 'CDK Global Inc',
    region: 'GB',
  },
  {
    symbol: '0HQU-LN',
    name: 'CF Industries Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0HQW-LN',
    name: 'C.H. Robinson Worldwide, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HQX-LN',
    name: 'Nuwellis Inc',
    region: 'GB',
  },
  {
    symbol: '0HR3-LN',
    name: 'Duni AB',
    region: 'GB',
  },
  {
    symbol: '0HR4-LN',
    name: 'CMS Energy Corporation',
    region: 'GB',
  },
  {
    symbol: '0HRJ-LN',
    name: 'CSX Corp.',
    region: 'GB',
  },
  {
    symbol: '0HRR-LN',
    name: 'CVR Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0HRS-LN',
    name: 'CVS Health Corp',
    region: 'GB',
  },
  {
    symbol: '0HRZ-LN',
    name: 'Coterra Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0HS2-LN',
    name: 'Cadence Design Systems, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HS4-LN',
    name: 'Cadiz Inc.',
    region: 'GB',
  },
  {
    symbol: '0HS8-LN',
    name: 'Caladrius Biosciences Inc',
    region: 'GB',
  },
  {
    symbol: '0HSI-LN',
    name: 'Brondby IF - Class B',
    region: 'GB',
  },
  {
    symbol: '0HST-LN',
    name: 'Campbell Soup Co.',
    region: 'GB',
  },
  {
    symbol: '0HSU-LN',
    name: 'Camping World Holdings Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0HT4-LN',
    name: 'Capital One Financial Corp.',
    region: 'GB',
  },
  {
    symbol: '0HTF-LN',
    name: 'Norwegian Energy Co SA',
    region: 'GB',
  },
  {
    symbol: '0HTG-LN',
    name: 'Cardinal Health, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HTP-LN',
    name: 'Volvo AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0HTQ-LN',
    name: 'Carmax Inc',
    region: 'GB',
  },
  {
    symbol: '0HTS-LN',
    name: 'DLT ASA',
    region: 'GB',
  },
  {
    symbol: '0HTZ-LN',
    name: 'Cars.com',
    region: 'GB',
  },
  {
    symbol: '0HUR-LN',
    name: 'Celanese Corp - Series A',
    region: 'GB',
  },
  {
    symbol: '0HUZ-LN',
    name: 'Celsion Corp',
    region: 'GB',
  },
  {
    symbol: '0HV0-LN',
    name: 'Urbar Ingenieros SA',
    region: 'GB',
  },
  {
    symbol: '0HV1-LN',
    name: 'Ige & Xao',
    region: 'GB',
  },
  {
    symbol: '0HV2-LN',
    name: 'Hermes International',
    region: 'GB',
  },
  {
    symbol: '0HVA-LN',
    name: 'Haulotte Group',
    region: 'GB',
  },
  {
    symbol: '0HVB-LN',
    name: 'Centene Corp.',
    region: 'GB',
  },
  {
    symbol: '0HVC-LN',
    name: 'Groupe JAJ',
    region: 'GB',
  },
  {
    symbol: '0HVF-LN',
    name: 'Centerpoint Energy Inc.',
    region: 'GB',
  },
  {
    symbol: '0HVP-LN',
    name: 'Lumen Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0HW0-LN',
    name: 'Kongsberg Automotive ASA',
    region: 'GB',
  },
  {
    symbol: '0HW4-LN',
    name: 'Charter Communications Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0HW6-LN',
    name: 'Doro AB',
    region: 'GB',
  },
  {
    symbol: '0HW9-LN',
    name: 'SinterCast AB',
    region: 'GB',
  },
  {
    symbol: '0HWG-LN',
    name: 'Chemours Company',
    region: 'GB',
  },
  {
    symbol: '0HWH-LN',
    name: 'Cheniere Energy Inc.',
    region: 'GB',
  },
  {
    symbol: '0HX1-LN',
    name: 'Ol Groupe',
    region: 'GB',
  },
  {
    symbol: '0HXB-LN',
    name: 'Tenaris S.A.',
    region: 'GB',
  },
  {
    symbol: '0HY2-LN',
    name: 'Cementir Holding N.V.',
    region: 'GB',
  },
  {
    symbol: '0HYA-LN',
    name: 'CIENA Corp.',
    region: 'GB',
  },
  {
    symbol: '0HYE-LN',
    name: 'Cincinnati Financial Corp.',
    region: 'GB',
  },
  {
    symbol: '0HYI-LN',
    name: 'Cirrus Logic, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HYJ-LN',
    name: 'Cintas Corporation',
    region: 'GB',
  },
  {
    symbol: '0HYP-LN',
    name: 'Citizens Financial Group Inc',
    region: 'GB',
  },
  {
    symbol: '0HYR-LN',
    name: 'Citrix Systems, Inc.',
    region: 'GB',
  },
  {
    symbol: '0HZD-LN',
    name: 'Wendel',
    region: 'GB',
  },
  {
    symbol: '0I0B-LN',
    name: 'ClearSign Technologies Corp',
    region: 'GB',
  },
  {
    symbol: '0I0H-LN',
    name: 'Cleveland-Cliffs Inc',
    region: 'GB',
  },
  {
    symbol: '0I0J-LN',
    name: 'Clorox Co.',
    region: 'GB',
  },
  {
    symbol: '0I0L-LN',
    name: 'Euromedis Groupe',
    region: 'GB',
  },
  {
    symbol: '0I0T-LN',
    name: 'Coca-Cola Consolidated Inc',
    region: 'GB',
  },
  {
    symbol: '0I0X-LN',
    name: 'Codexis Inc.',
    region: 'GB',
  },
  {
    symbol: '0I10-LN',
    name: 'Squirrel Media S.A',
    region: 'GB',
  },
  {
    symbol: '0I14-LN',
    name: 'Cognex Corp.',
    region: 'GB',
  },
  {
    symbol: '0I17-LN',
    name: 'Coherent Inc.',
    region: 'GB',
  },
  {
    symbol: '0I1P-LN',
    name: 'Comerica, Inc.',
    region: 'GB',
  },
  {
    symbol: '0I1W-LN',
    name: 'Royal Caribbean Group',
    region: 'GB',
  },
  {
    symbol: '0I21-LN',
    name: 'Siem Offshore Inc.',
    region: 'GB',
  },
  {
    symbol: '0I2P-LN',
    name: 'Conagra Brands Inc',
    region: 'GB',
  },
  {
    symbol: '0I2R-LN',
    name: 'Atlantia',
    region: 'GB',
  },
  {
    symbol: '0I35-LN',
    name: 'Consolidated Edison, Inc.',
    region: 'GB',
  },
  {
    symbol: '0I3I-LN',
    name: 'Cooper Companies, Inc.',
    region: 'GB',
  },
  {
    symbol: '0I3O-LN',
    name: 'Media 6',
    region: 'GB',
  },
  {
    symbol: '0I47-LN',
    name: 'Costco Wholesale Corp',
    region: 'GB',
  },
  {
    symbol: '0I4A-LN',
    name: 'Coty Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0I4Q-LN',
    name: 'Wolfspeed Inc',
    region: 'GB',
  },
  {
    symbol: '0I4W-LN',
    name: 'Crown Castle International Corp',
    region: 'GB',
  },
  {
    symbol: '0I4X-LN',
    name: 'Crown Holdings, Inc.',
    region: 'GB',
  },
  {
    symbol: '0I50-LN',
    name: 'Trip.com Group Ltd - ADR',
    region: 'GB',
  },
  {
    symbol: '0I58-LN',
    name: 'Cummins Inc.',
    region: 'GB',
  },
  {
    symbol: '0I5O-LN',
    name: 'Bergman & Beving Aktiebolag - Class B',
    region: 'GB',
  },
  {
    symbol: '0I5R-LN',
    name: 'CytRx Corporation',
    region: 'GB',
  },
  {
    symbol: '0I5X-LN',
    name: 'Turbomecanica Bucuresti',
    region: 'GB',
  },
  {
    symbol: '0I6K-LN',
    name: 'D.R. Horton Inc.',
    region: 'GB',
  },
  {
    symbol: '0I6N-LN',
    name: 'Zentiva Sa',
    region: 'GB',
  },
  {
    symbol: '0I6Q-LN',
    name: 'DTE Energy Co.',
    region: 'GB',
  },
  {
    symbol: '0I6U-LN',
    name: 'DXC Technology Co',
    region: 'GB',
  },
  {
    symbol: '0I76-LN',
    name: 'Cambodge (Cie Du)',
    region: 'GB',
  },
  {
    symbol: '0I77-LN',
    name: 'Darden Restaurants, Inc.',
    region: 'GB',
  },
  {
    symbol: '0I7E-LN',
    name: 'DaVita Inc',
    region: 'GB',
  },
  {
    symbol: '0I88-LN',
    name: 'Alro Slatina',
    region: 'GB',
  },
  {
    symbol: '0I8C-LN',
    name: 'Aqua Bio Technology ASA',
    region: 'GB',
  },
  {
    symbol: '0I8F-LN',
    name: 'DENTSPLY Sirona Inc',
    region: 'GB',
  },
  {
    symbol: '0I8W-LN',
    name: 'Devon Energy Corp.',
    region: 'GB',
  },
  {
    symbol: '0I8Y-LN',
    name: 'Elisa Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0I9C-LN',
    name: 'International Hotel Investments',
    region: 'GB',
  },
  {
    symbol: '0IAH-LN',
    name: 'Securitas AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0IAX-LN',
    name: 'Dassault Aviation',
    region: 'GB',
  },
  {
    symbol: '0IB0-LN',
    name: 'Arkema',
    region: 'GB',
  },
  {
    symbol: '0IB7-LN',
    name: 'Europacorp',
    region: 'GB',
  },
  {
    symbol: '0IBC-LN',
    name: 'Discover Financial Services',
    region: 'GB',
  },
  {
    symbol: '0IBG-LN',
    name: 'Dish Network Corp - Class A',
    region: 'GB',
  },
  {
    symbol: '0IC7-LN',
    name: 'Dollar General Corp.',
    region: 'GB',
  },
  {
    symbol: '0IC8-LN',
    name: 'Dollar Tree Inc',
    region: 'GB',
  },
  {
    symbol: '0IC9-LN',
    name: 'Dominion Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0ICE-LN',
    name: 'CB Central Cooperative Bank AD',
    region: 'GB',
  },
  {
    symbol: '0ICI-LN',
    name: 'Orapi',
    region: 'GB',
  },
  {
    symbol: '0ICP-LN',
    name: 'Dover Corp.',
    region: 'GB',
  },
  {
    symbol: '0ID1-LN',
    name: 'Duke Energy Corp.',
    region: 'GB',
  },
  {
    symbol: '0IDA-LN',
    name: 'Dynavax Technologies Corp.',
    region: 'GB',
  },
  {
    symbol: '0IDR-LN',
    name: 'EOG Resources, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IDU-LN',
    name: 'EQT Corp',
    region: 'GB',
  },
  {
    symbol: '0IE6-LN',
    name: 'PannErgy Plc. (Registered)',
    region: 'GB',
  },
  {
    symbol: '0IE9-LN',
    name: 'Semapa - Sociedade De Investimento E Gestao, S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0IF3-LN',
    name: 'Eastman Chemical Co',
    region: 'GB',
  },
  {
    symbol: '0IFA-LN',
    name: 'Ecolab, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IFJ-LN',
    name: 'Edison International',
    region: 'GB',
  },
  {
    symbol: '0IFM-LN',
    name: 'eGain Corp',
    region: 'GB',
  },
  {
    symbol: '0IFR-LN',
    name: 'Ekso Bionics Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0IFX-LN',
    name: 'Electronic Arts, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IG1-LN',
    name: 'Glaston Corporation',
    region: 'GB',
  },
  {
    symbol: '0IGA-LN',
    name: 'Emergent Biosolutions Inc',
    region: 'GB',
  },
  {
    symbol: '0IGF-LN',
    name: 'Nexans',
    region: 'GB',
  },
  {
    symbol: '0IGX-LN',
    name: 'Ilkka-Yhtyma - Class 2',
    region: 'GB',
  },
  {
    symbol: '0IH4-LN',
    name: 'TFF Group',
    region: 'GB',
  },
  {
    symbol: '0IHP-LN',
    name: 'Entergy Corp.',
    region: 'GB',
  },
  {
    symbol: '0IHT-LN',
    name: 'CSP International Fashion Group Spa',
    region: 'GB',
  },
  {
    symbol: '0IHV-LN',
    name: 'Enzo Biochem, Inc.',
    region: 'GB',
  },
  {
    symbol: '0II2-LN',
    name: 'Kone Corp. - Class B',
    region: 'GB',
  },
  {
    symbol: '0II3-LN',
    name: 'Equifax, Inc.',
    region: 'GB',
  },
  {
    symbol: '0II4-LN',
    name: 'Equinix Inc',
    region: 'GB',
  },
  {
    symbol: '0II6-LN',
    name: 'Industrial Holding Bulgaria',
    region: 'GB',
  },
  {
    symbol: '0IIF-LN',
    name: 'Vivendi SE',
    region: 'GB',
  },
  {
    symbol: '0IIH-LN',
    name: 'Kering',
    region: 'GB',
  },
  {
    symbol: '0IIO-LN',
    name: 'GR Sarantis S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0IIW-LN',
    name: 'Etsy Inc',
    region: 'GB',
  },
  {
    symbol: '0IJ2-LN',
    name: 'Eversource Energy',
    region: 'GB',
  },
  {
    symbol: '0IJN-LN',
    name: 'Exelon Corp.',
    region: 'GB',
  },
  {
    symbol: '0IJR-LN',
    name: 'Expeditors International Of Washington, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IJU-LN',
    name: 'Express Inc.',
    region: 'GB',
  },
  {
    symbol: '0IJW-LN',
    name: 'Extreme Networks Inc.',
    region: 'GB',
  },
  {
    symbol: '0IK3-LN',
    name: 'FMC Corp.',
    region: 'GB',
  },
  {
    symbol: '0IKH-LN',
    name: 'Komercni Banka, A.S.',
    region: 'GB',
  },
  {
    symbol: '0IKJ-LN',
    name: 'Wartsila Oyj Abp',
    region: 'GB',
  },
  {
    symbol: '0IKK-LN',
    name: 'Digia Plc',
    region: 'GB',
  },
  {
    symbol: '0IKL-LN',
    name: 'Attica Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0IKW-LN',
    name: 'Fastenal Co.',
    region: 'GB',
  },
  {
    symbol: '0IL6-LN',
    name: 'F5 Inc',
    region: 'GB',
  },
  {
    symbol: '0ILI-LN',
    name: 'Fluidra',
    region: 'GB',
  },
  {
    symbol: '0ILK-LN',
    name: 'CaixaBank, S.A',
    region: 'GB',
  },
  {
    symbol: '0ILL-LN',
    name: 'Laboratorios Farmaceuticos Rovi S.A.',
    region: 'GB',
  },
  {
    symbol: '0ILW-LN',
    name: 'Fidelity National Information Services, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IM1-LN',
    name: 'Fifth Third Bancorp',
    region: 'GB',
  },
  {
    symbol: '0IM3-LN',
    name: 'Philly Shipyard ASA',
    region: 'GB',
  },
  {
    symbol: '0IMR-LN',
    name: 'Petrol AD',
    region: 'GB',
  },
  {
    symbol: '0IMT-LN',
    name: 'Photocure ASA',
    region: 'GB',
  },
  {
    symbol: '0IMY-LN',
    name: 'Marfin Investment Group Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0IN3-LN',
    name: 'Jacquet Metals.',
    region: 'GB',
  },
  {
    symbol: '0INB-LN',
    name: 'ST Microelectronics',
    region: 'GB',
  },
  {
    symbol: '0IP1-LN',
    name: 'Bastide Le Confort Medical',
    region: 'GB',
  },
  {
    symbol: '0IP8-LN',
    name: 'MAK AD',
    region: 'GB',
  },
  {
    symbol: '0IP9-LN',
    name: 'Fiserv, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IPB-LN',
    name: 'Firstenergy Corp.',
    region: 'GB',
  },
  {
    symbol: '0IPL-LN',
    name: 'Doverie Obedinen Holding Ad-Sofia',
    region: 'GB',
  },
  {
    symbol: '0IPT-LN',
    name: 'Akastor ASA',
    region: 'GB',
  },
  {
    symbol: '0IPY-LN',
    name: 'Aubay',
    region: 'GB',
  },
  {
    symbol: '0IQC-LN',
    name: 'Fluor Corporation',
    region: 'GB',
  },
  {
    symbol: '0IQE-LN',
    name: 'Flowserve Corp.',
    region: 'GB',
  },
  {
    symbol: '0IQK-LN',
    name: 'Foot Locker Inc',
    region: 'GB',
  },
  {
    symbol: '0IR4-LN',
    name: 'AUGA group',
    region: 'GB',
  },
  {
    symbol: '0IR9-LN',
    name: 'Fortinet Inc',
    region: 'GB',
  },
  {
    symbol: '0IRE-LN',
    name: 'Fortive Corp',
    region: 'GB',
  },
  {
    symbol: '0IRF-LN',
    name: 'Evotec SE',
    region: 'GB',
  },
  {
    symbol: '0IRH-LN',
    name: 'Mons Bank',
    region: 'GB',
  },
  {
    symbol: '0IRN-LN',
    name: 'Fortune Brands Home & Security Inc',
    region: 'GB',
  },
  {
    symbol: '0IS7-LN',
    name: 'EMKA AD',
    region: 'GB',
  },
  {
    symbol: '0ISM-LN',
    name: 'HKScan Corporation - Class A',
    region: 'GB',
  },
  {
    symbol: '0IT3-LN',
    name: 'Scor SE',
    region: 'GB',
  },
  {
    symbol: '0ITL-LN',
    name: 'Arthur J. Gallagher & Co.',
    region: 'GB',
  },
  {
    symbol: '0ITS-LN',
    name: 'Gap, Inc.',
    region: 'GB',
  },
  {
    symbol: '0ITV-LN',
    name: 'Gartner, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IU5-LN',
    name: 'Zaharni Zavodi Ad - Gorna Oryahovitza',
    region: 'GB',
  },
  {
    symbol: '0IU8-LN',
    name: 'Safran SA',
    region: 'GB',
  },
  {
    symbol: '0IU9-LN',
    name: 'Wulff-Group plc.',
    region: 'GB',
  },
  {
    symbol: '0IUC-LN',
    name: 'General Dynamics Corp.',
    region: 'GB',
  },
  {
    symbol: '0IUJ-LN',
    name: 'Interparfums',
    region: 'GB',
  },
  {
    symbol: '0IUQ-LN',
    name: 'CNP Assurances',
    region: 'GB',
  },
  {
    symbol: '0IUS-LN',
    name: 'Genie Energy Ltd - Class B',
    region: 'GB',
  },
  {
    symbol: '0IUV-LN',
    name: 'TotalEnergies EP Gabon',
    region: 'GB',
  },
  {
    symbol: '0IUX-LN',
    name: 'Genuine Parts Co.',
    region: 'GB',
  },
  {
    symbol: '0IV3-LN',
    name: 'Geron Corp.',
    region: 'GB',
  },
  {
    symbol: '0IVB-LN',
    name: 'Innelec Multimedia',
    region: 'GB',
  },
  {
    symbol: '0IVJ-LN',
    name: 'Lectra SA',
    region: 'GB',
  },
  {
    symbol: '0IVM-LN',
    name: 'SpareBank 1 SMN',
    region: 'GB',
  },
  {
    symbol: '0IVX-LN',
    name: 'Sofia Hotel Balkan AD',
    region: 'GB',
  },
  {
    symbol: '0IW5-LN',
    name: 'Thales S.A.',
    region: 'GB',
  },
  {
    symbol: '0IW7-LN',
    name: 'Global Payments, Inc.',
    region: 'GB',
  },
  {
    symbol: '0IWU-LN',
    name: 'Fabasoft AG',
    region: 'GB',
  },
  {
    symbol: '0IWV-LN',
    name: 'Van de Velde SA',
    region: 'GB',
  },
  {
    symbol: '0IX0-LN',
    name: 'GL Events',
    region: 'GB',
  },
  {
    symbol: '0IXL-LN',
    name: 'NRJ Group',
    region: 'GB',
  },
  {
    symbol: '0IXN-LN',
    name: 'Touax',
    region: 'GB',
  },
  {
    symbol: '0IXQ-LN',
    name: 'AB Grigeo',
    region: 'GB',
  },
  {
    symbol: '0IXT-LN',
    name: 'Latecoere',
    region: 'GB',
  },
  {
    symbol: '0IXZ-LN',
    name: 'Bollore SE',
    region: 'GB',
  },
  {
    symbol: '0IY1-LN',
    name: 'Atria Plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0IYS-LN',
    name: 'Gold Resource Corporation',
    region: 'GB',
  },
  {
    symbol: '0IZ2-LN',
    name: 'Sonaecom, SGPS SA.',
    region: 'GB',
  },
  {
    symbol: '0IZ8-LN',
    name: 'Henkel AG & Co. KGAA - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0IZC-LN',
    name: 'Henkel AG & Co. KGAA - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0IZI-LN',
    name: 'W.W. Grainger Inc.',
    region: 'GB',
  },
  {
    symbol: '0IZU-LN',
    name: 'Uamt Oradea',
    region: 'GB',
  },
  {
    symbol: '0IZX-LN',
    name: 'Elos Medtech AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0J04-LN',
    name: 'Porr AG',
    region: 'GB',
  },
  {
    symbol: '0J0H-LN',
    name: 'Keyware Technologies NV',
    region: 'GB',
  },
  {
    symbol: '0J0P-LN',
    name: 'Green Plains Inc',
    region: 'GB',
  },
  {
    symbol: '0J0V-LN',
    name: 'Campine',
    region: 'GB',
  },
  {
    symbol: '0J1K-LN',
    name: 'AB Klaipedos Nafta',
    region: 'GB',
  },
  {
    symbol: '0J1N-LN',
    name: 'Sparebank 1 Nord-Norge',
    region: 'GB',
  },
  {
    symbol: '0J1R-LN',
    name: 'HCA Healthcare Inc',
    region: 'GB',
  },
  {
    symbol: '0J1Y-LN',
    name: 'Zemaitijos Pienas AB',
    region: 'GB',
  },
  {
    symbol: '0J1Z-LN',
    name: 'Nos SGPS SA.',
    region: 'GB',
  },
  {
    symbol: '0J22-LN',
    name: 'HCI Group Inc',
    region: 'GB',
  },
  {
    symbol: '0J2B-LN',
    name: 'HTC Corporation - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: '0J2E-LN',
    name: 'HP Inc',
    region: 'GB',
  },
  {
    symbol: '0J2I-LN',
    name: 'Hain Celestial Group Inc',
    region: 'GB',
  },
  {
    symbol: '0J2R-LN',
    name: 'Alstom',
    region: 'GB',
  },
  {
    symbol: '0J2S-LN',
    name: 'A.S.T.Group',
    region: 'GB',
  },
  {
    symbol: '0J2X-LN',
    name: 'Hanesbrands Inc',
    region: 'GB',
  },
  {
    symbol: '0J35-LN',
    name: 'Groupe Flo',
    region: 'GB',
  },
  {
    symbol: '0J38-LN',
    name: 'Harmonic, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J3C-LN',
    name: 'Mecanica Ceahlau',
    region: 'GB',
  },
  {
    symbol: '0J3F-LN',
    name: 'Sodexo',
    region: 'GB',
  },
  {
    symbol: '0J3H-LN',
    name: 'Hartford Financial Services Group Inc.',
    region: 'GB',
  },
  {
    symbol: '0J3K-LN',
    name: 'Hasbro, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J3N-LN',
    name: 'Hawaiian Holdings, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J45-LN',
    name: 'Vrancart SA.',
    region: 'GB',
  },
  {
    symbol: '0J46-LN',
    name: 'Heico Corp.',
    region: 'GB',
  },
  {
    symbol: '0J4G-LN',
    name: 'Helmerich & Payne, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J4L-LN',
    name: 'Herc Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0J4R-LN',
    name: 'Latvijas Gaze',
    region: 'GB',
  },
  {
    symbol: '0J4V-LN',
    name: 'Heron Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0J4X-LN',
    name: 'Hershey Company',
    region: 'GB',
  },
  {
    symbol: '0J50-LN',
    name: 'Hess Corporation',
    region: 'GB',
  },
  {
    symbol: '0J51-LN',
    name: 'Hewlett Packard Enterprise Co',
    region: 'GB',
  },
  {
    symbol: '0J59-LN',
    name: 'MRM',
    region: 'GB',
  },
  {
    symbol: '0J5I-LN',
    name: 'Hilton Worldwide Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0J5Q-LN',
    name: 'Hologic, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J5Y-LN',
    name: 'Prosafe SE',
    region: 'GB',
  },
  {
    symbol: '0J5Z-LN',
    name: 'Hormel Foods Corp.',
    region: 'GB',
  },
  {
    symbol: '0J66-LN',
    name: 'Host Hotels & Resorts Inc',
    region: 'GB',
  },
  {
    symbol: '0J6U-LN',
    name: 'Catering International Service',
    region: 'GB',
  },
  {
    symbol: '0J6V-LN',
    name: 'Covivio S.A',
    region: 'GB',
  },
  {
    symbol: '0J6X-LN',
    name: 'Teleperformance',
    region: 'GB',
  },
  {
    symbol: '0J6Y-LN',
    name: 'Societe Generale S.A.',
    region: 'GB',
  },
  {
    symbol: '0J6Z-LN',
    name: 'Humana Inc.',
    region: 'GB',
  },
  {
    symbol: '0J71-LN',
    name: 'J.B. Hunt Transport Services, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J72-LN',
    name: 'Huntington Bancshares, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J74-LN',
    name: 'Electricite de Strasbourg S.A.',
    region: 'GB',
  },
  {
    symbol: '0J75-LN',
    name: 'Fleury Michon',
    region: 'GB',
  },
  {
    symbol: '0J76-LN',
    name: 'Huntington Ingalls Industries Inc',
    region: 'GB',
  },
  {
    symbol: '0J77-LN',
    name: 'Odfjell SE - Class A',
    region: 'GB',
  },
  {
    symbol: '0J7A-LN',
    name: 'Trainers House Plc',
    region: 'GB',
  },
  {
    symbol: '0J7D-LN',
    name: 'Vranken Pommery Monopole',
    region: 'GB',
  },
  {
    symbol: '0J7X-LN',
    name: 'MBB Industries AG',
    region: 'GB',
  },
  {
    symbol: '0J86-LN',
    name: 'IPG Photonics Corp',
    region: 'GB',
  },
  {
    symbol: '0J8P-LN',
    name: 'Idexx Laboratories, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J8S-LN',
    name: 'Aspo Oyj',
    region: 'GB',
  },
  {
    symbol: '0J8W-LN',
    name: 'Illinois Tool Works, Inc.',
    region: 'GB',
  },
  {
    symbol: '0J8Z-LN',
    name: 'Illumina Inc',
    region: 'GB',
  },
  {
    symbol: '0J9C-LN',
    name: 'Duerr AG',
    region: 'GB',
  },
  {
    symbol: '0J9O-LN',
    name: 'Manz Automation AG',
    region: 'GB',
  },
  {
    symbol: '0J9P-LN',
    name: 'Incyte Corp.',
    region: 'GB',
  },
  {
    symbol: '0JAU-LN',
    name: 'Insignia Systems, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JAV-LN',
    name: 'Insmed Inc',
    region: 'GB',
  },
  {
    symbol: '0JAW-LN',
    name: 'Generix Group',
    region: 'GB',
  },
  {
    symbol: '0JAX-LN',
    name: 'Innospec Inc',
    region: 'GB',
  },
  {
    symbol: '0JBJ-LN',
    name: 'SRV Group Plc',
    region: 'GB',
  },
  {
    symbol: '0JBL-LN',
    name: 'Saint Jean Groupe',
    region: 'GB',
  },
  {
    symbol: '0JBO-LN',
    name: 'CS Group',
    region: 'GB',
  },
  {
    symbol: '0JBY-LN',
    name: 'Elanders AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0JC5-LN',
    name: 'Any Security Printing Co PLC',
    region: 'GB',
  },
  {
    symbol: '0JCB-LN',
    name: 'International Paper Co.',
    region: 'GB',
  },
  {
    symbol: '0JCD-LN',
    name: 'Dovre Group Plc',
    region: 'GB',
  },
  {
    symbol: '0JCK-LN',
    name: 'Interpublic Group Of Cos., Inc.',
    region: 'GB',
  },
  {
    symbol: '0JCO-LN',
    name: 'All for One Group SE',
    region: 'GB',
  },
  {
    symbol: '0JCT-LN',
    name: 'Intuit Inc',
    region: 'GB',
  },
  {
    symbol: '0JD8-LN',
    name: 'MT Hojgaard Holding A/S',
    region: 'GB',
  },
  {
    symbol: '0JDK-LN',
    name: 'Iovance Biotherapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0JDP-LN',
    name: 'Iron Mountain Inc.',
    region: 'GB',
  },
  {
    symbol: '0JDU-LN',
    name: 'Precise Biometrics AB',
    region: 'GB',
  },
  {
    symbol: '0JE5-LN',
    name: 'American Shipping Company ASA',
    region: 'GB',
  },
  {
    symbol: '0JEV-LN',
    name: 'Crescent NV',
    region: 'GB',
  },
  {
    symbol: '0JEZ-LN',
    name: 'StrongPoint ASA',
    region: 'GB',
  },
  {
    symbol: '0JG3-LN',
    name: 'Teraplast Sa',
    region: 'GB',
  },
  {
    symbol: '0JG5-LN',
    name: 'Bittium Corporation',
    region: 'GB',
  },
  {
    symbol: '0JGL-LN',
    name: 'PCI Biotech Holding Asa',
    region: 'GB',
  },
  {
    symbol: '0JGO-LN',
    name: 'Endur ASA',
    region: 'GB',
  },
  {
    symbol: '0JH0-LN',
    name: 'Rejlers AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0JH2-LN',
    name: 'Wiener Privatbank SE',
    region: 'GB',
  },
  {
    symbol: '0JH4-LN',
    name: 'Spar Nord Bank',
    region: 'GB',
  },
  {
    symbol: '0JHU-LN',
    name: 'Porsche Automobil Holding SE - PRF PERPETUAL EUR 1',
    region: 'GB',
  },
  {
    symbol: '0JI3-LN',
    name: 'Hunter Group ASA',
    region: 'GB',
  },
  {
    symbol: '0JI9-LN',
    name: 'Ensurge Micropower ASA - Class A',
    region: 'GB',
  },
  {
    symbol: '0JIU-LN',
    name: 'Koninklijke Brill NV',
    region: 'GB',
  },
  {
    symbol: '0JJM-LN',
    name: 'Bioporto AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0JK4-LN',
    name: 'TAG Immobilien AG',
    region: 'GB',
  },
  {
    symbol: '0JLQ-LN',
    name: 'Sanoma Corporation',
    region: 'GB',
  },
  {
    symbol: '0JM2-LN',
    name: 'GHP Specialty Care AB',
    region: 'GB',
  },
  {
    symbol: '0JMU-LN',
    name: 'Technotrans SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0JN9-LN',
    name: 'DSV AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0JNI-LN',
    name: 'Eutelsat Communications',
    region: 'GB',
  },
  {
    symbol: '0JOD-LN',
    name: 'Jinhui Shipping & Transport',
    region: 'GB',
  },
  {
    symbol: '0JOE-LN',
    name: 'Kuka AG',
    region: 'GB',
  },
  {
    symbol: '0JOI-LN',
    name: 'Jacobs Engineering Group, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JOP-LN',
    name: 'F Ramada-Investimentos SGPS S A',
    region: 'GB',
  },
  {
    symbol: '0JOQ-LN',
    name: 'JD.com Inc - ADR',
    region: 'GB',
  },
  {
    symbol: '0JOT-LN',
    name: 'Jetblue Airways Corp',
    region: 'GB',
  },
  {
    symbol: '0JPH-LN',
    name: 'Juniper Networks Inc',
    region: 'GB',
  },
  {
    symbol: '0JPI-LN',
    name: 'Panostaja Oyj',
    region: 'GB',
  },
  {
    symbol: '0JPN-LN',
    name: 'KBR Inc',
    region: 'GB',
  },
  {
    symbol: '0JPO-LN',
    name: 'KLA Corp.',
    region: 'GB',
  },
  {
    symbol: '0JPX-LN',
    name: 'AS EKspress Grupp',
    region: 'GB',
  },
  {
    symbol: '0JPY-LN',
    name: 'Tallinna Vesi AS - Class A',
    region: 'GB',
  },
  {
    symbol: '0JQK-LN',
    name: 'Valoe Corporation',
    region: 'GB',
  },
  {
    symbol: '0JQQ-LN',
    name: 'Archer Daniels Midland Co.',
    region: 'GB',
  },
  {
    symbol: '0JQR-LN',
    name: 'Keycorp',
    region: 'GB',
  },
  {
    symbol: '0JQZ-LN',
    name: 'Kimberly-Clark Corp.',
    region: 'GB',
  },
  {
    symbol: '0JR2-LN',
    name: 'Kinder Morgan Inc - Class P',
    region: 'GB',
  },
  {
    symbol: '0JRJ-LN',
    name: 'Knowles Corp',
    region: 'GB',
  },
  {
    symbol: '0JRL-LN',
    name: 'Kohl`s Corp.',
    region: 'GB',
  },
  {
    symbol: '0JRR-LN',
    name: 'Kopin Corp.',
    region: 'GB',
  },
  {
    symbol: '0JRV-LN',
    name: 'Kraft Heinz Co',
    region: 'GB',
  },
  {
    symbol: '0JS0-LN',
    name: 'Kratos Defense & Security Solutions Inc',
    region: 'GB',
  },
  {
    symbol: '0JS2-LN',
    name: 'Kroger Co.',
    region: 'GB',
  },
  {
    symbol: '0JSC-LN',
    name: 'Bath & Body Works Inc',
    region: 'GB',
  },
  {
    symbol: '0JSG-LN',
    name: 'Euro Ressources Sa',
    region: 'GB',
  },
  {
    symbol: '0JSJ-LN',
    name: 'LKQ Corp',
    region: 'GB',
  },
  {
    symbol: '0JSU-LN',
    name: 'Sipef',
    region: 'GB',
  },
  {
    symbol: '0JSY-LN',
    name: 'Laboratory Corp. Of America Holdings',
    region: 'GB',
  },
  {
    symbol: '0JT4-LN',
    name: 'Gek Terna S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0JT5-LN',
    name: 'Lam Research Corp.',
    region: 'GB',
  },
  {
    symbol: '0JTM-LN',
    name: 'Estee Lauder Cos., Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0JTQ-LN',
    name: 'Lear Corp.',
    region: 'GB',
  },
  {
    symbol: '0JTT-LN',
    name: 'Leggett & Platt, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JTZ-LN',
    name: 'LendingTree Inc.',
    region: 'GB',
  },
  {
    symbol: '0JU0-LN',
    name: 'Lennar Corp. - Class A',
    region: 'GB',
  },
  {
    symbol: '0JUJ-LN',
    name: 'Liberty Media Corp. (Tracking Stock -Liberty Formula 1) Series A',
    region: 'GB',
  },
  {
    symbol: '0JV0-LN',
    name: 'Limoneira Co',
    region: 'GB',
  },
  {
    symbol: '0JV3-LN',
    name: 'Lincoln National Corp.',
    region: 'GB',
  },
  {
    symbol: '0JVB-LN',
    name: 'Lithium Corp',
    region: 'GB',
  },
  {
    symbol: '0JVD-LN',
    name: 'Live Nation Entertainment Inc',
    region: 'GB',
  },
  {
    symbol: '0JVI-LN',
    name: 'Loews Corp.',
    region: 'GB',
  },
  {
    symbol: '0JVQ-LN',
    name: 'Lowe`s Cos., Inc.',
    region: 'GB',
  },
  {
    symbol: '0JVS-LN',
    name: 'Hypoport SE',
    region: 'GB',
  },
  {
    symbol: '0JVT-LN',
    name: 'Lululemon Athletica inc.',
    region: 'GB',
  },
  {
    symbol: '0JVV-LN',
    name: 'Lumentum Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0JW2-LN',
    name: 'M & T Bank Corp',
    region: 'GB',
  },
  {
    symbol: '0JW5-LN',
    name: 'M.D.C. Holdings, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JW9-LN',
    name: 'MEI Pharma Inc',
    region: 'GB',
  },
  {
    symbol: '0JWC-LN',
    name: 'MGM Resorts International',
    region: 'GB',
  },
  {
    symbol: '0JWG-LN',
    name: 'MKS Instruments, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JWM-LN',
    name: 'Artois Industr Financiere - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0JWO-LN',
    name: 'Atea ASA',
    region: 'GB',
  },
  {
    symbol: '0JX9-LN',
    name: 'Marimekko Oyj',
    region: 'GB',
  },
  {
    symbol: '0JXB-LN',
    name: 'Macquarie Infrastructure Holdings LLC - Units',
    region: 'GB',
  },
  {
    symbol: '0JXD-LN',
    name: 'Macy`s Inc',
    region: 'GB',
  },
  {
    symbol: '0JXF-LN',
    name: 'Protector Forsikring Asa',
    region: 'GB',
  },
  {
    symbol: '0JXG-LN',
    name: 'Q-Free ASA',
    region: 'GB',
  },
  {
    symbol: '0JXI-LN',
    name: 'Madrigal Pharmaceuticals Inc',
    region: 'GB',
  },
  {
    symbol: '0JXL-LN',
    name: 'Sila Holding, Pazardjik',
    region: 'GB',
  },
  {
    symbol: '0JXZ-LN',
    name: 'Galapagos NV',
    region: 'GB',
  },
  {
    symbol: '0JY9-LN',
    name: 'Marathon Oil Corporation',
    region: 'GB',
  },
  {
    symbol: '0JYA-LN',
    name: 'Marathon Petroleum Corp',
    region: 'GB',
  },
  {
    symbol: '0JYM-LN',
    name: 'Markel Corp',
    region: 'GB',
  },
  {
    symbol: '0JYW-LN',
    name: 'Marriott International, Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0JYZ-LN',
    name: 'Loomis AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0JZ0-LN',
    name: 'Martin Marietta Materials, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JZ1-LN',
    name: 'Masco Corp.',
    region: 'GB',
  },
  {
    symbol: '0JZ2-LN',
    name: 'Masimo Corp',
    region: 'GB',
  },
  {
    symbol: '0JZ8-LN',
    name: 'Greenyard',
    region: 'GB',
  },
  {
    symbol: '0JZH-LN',
    name: 'Mattel, Inc.',
    region: 'GB',
  },
  {
    symbol: '0JZK-LN',
    name: 'Transgaz Sa Medias',
    region: 'GB',
  },
  {
    symbol: '0JZS-LN',
    name: 'McCormick & Co., Inc. (Non Voting)',
    region: 'GB',
  },
  {
    symbol: '0JZT-LN',
    name: 'McEwen Mining Inc',
    region: 'GB',
  },
  {
    symbol: '0JZU-LN',
    name: 'Mckesson Corporation',
    region: 'GB',
  },
  {
    symbol: '0K05-LN',
    name: 'Medifast Inc',
    region: 'GB',
  },
  {
    symbol: '0K0E-LN',
    name: 'MercadoLibre Inc',
    region: 'GB',
  },
  {
    symbol: '0K0K-LN',
    name: 'Meridian Bioscience Inc.',
    region: 'GB',
  },
  {
    symbol: '0K0S-LN',
    name: 'Rizzo Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0K0X-LN',
    name: 'Metlife Inc',
    region: 'GB',
  },
  {
    symbol: '0K11-LN',
    name: 'Wacker Neuson SE',
    region: 'GB',
  },
  {
    symbol: '0K14-LN',
    name: 'Biancamano Spa',
    region: 'GB',
  },
  {
    symbol: '0K17-LN',
    name: 'Microvision Inc.',
    region: 'GB',
  },
  {
    symbol: '0K19-LN',
    name: 'Microchip Technology, Inc.',
    region: 'GB',
  },
  {
    symbol: '0K1B-LN',
    name: 'Microbot Medical Inc',
    region: 'GB',
  },
  {
    symbol: '0K1G-LN',
    name: 'Middleby Corp.',
    region: 'GB',
  },
  {
    symbol: '0K1R-LN',
    name: 'Viridian Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0K1W-LN',
    name: 'Mitek Systems Inc',
    region: 'GB',
  },
  {
    symbol: '0K2D-LN',
    name: 'Techstep ASA',
    region: 'GB',
  },
  {
    symbol: '0K2F-LN',
    name: 'Mohawk Industries, Inc.',
    region: 'GB',
  },
  {
    symbol: '0K2K-LN',
    name: 'Molson Coors Beverage Company - Class B',
    region: 'GB',
  },
  {
    symbol: '0K34-LN',
    name: 'Monster Beverage Corp.',
    region: 'GB',
  },
  {
    symbol: '0K36-LN',
    name: 'Moody`s Corp.',
    region: 'GB',
  },
  {
    symbol: '0K3B-LN',
    name: 'Mosaic Company',
    region: 'GB',
  },
  {
    symbol: '0K3C-LN',
    name: 'Sopharma Trading AD',
    region: 'GB',
  },
  {
    symbol: '0K3H-LN',
    name: 'Motorola Solutions Inc',
    region: 'GB',
  },
  {
    symbol: '0K3S-LN',
    name: 'Murphy Oil Corp.',
    region: 'GB',
  },
  {
    symbol: '0K3W-LN',
    name: 'Myriad Genetics, Inc.',
    region: 'GB',
  },
  {
    symbol: '0K45-LN',
    name: 'NCR Corp.',
    region: 'GB',
  },
  {
    symbol: '0K4C-LN',
    name: 'NRG Energy Inc.',
    region: 'GB',
  },
  {
    symbol: '0K4R-LN',
    name: 'Cars, Motorcycles & Marine Engine Trade & Import Company S.A',
    region: 'GB',
  },
  {
    symbol: '0K4T-LN',
    name: 'Nasdaq Inc - 144A',
    region: 'GB',
  },
  {
    symbol: '0K50-LN',
    name: 'National Beverage Corp.',
    region: 'GB',
  },
  {
    symbol: '0K58-LN',
    name: 'NOV Inc',
    region: 'GB',
  },
  {
    symbol: '0K5E-LN',
    name: 'Dragerwerk AG - PRF PERPETUAL EUR - Non Voting',
    region: 'GB',
  },
  {
    symbol: '0K62-LN',
    name: 'Neonode Inc.',
    region: 'GB',
  },
  {
    symbol: '0K6F-LN',
    name: 'Netapp Inc',
    region: 'GB',
  },
  {
    symbol: '0K6P-LN',
    name: 'Network-1 Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0K78-LN',
    name: 'Assicurazioni Generali SPA',
    region: 'GB',
  },
  {
    symbol: '0K7C-LN',
    name: 'Alba SE',
    region: 'GB',
  },
  {
    symbol: '0K7F-LN',
    name: 'Aurubis AG',
    region: 'GB',
  },
  {
    symbol: '0K7J-LN',
    name: 'Newell Brands Inc',
    region: 'GB',
  },
  {
    symbol: '0K7U-LN',
    name: 'News Corp - Class A',
    region: 'GB',
  },
  {
    symbol: '0K7V-LN',
    name: 'News Corp - Class B',
    region: 'GB',
  },
  {
    symbol: '0K80-LN',
    name: 'NextEra Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0K87-LN',
    name: 'Nisource Inc. (Holding Co.)',
    region: 'GB',
  },
  {
    symbol: '0K8J-LN',
    name: 'Nordstrom, Inc.',
    region: 'GB',
  },
  {
    symbol: '0K8M-LN',
    name: 'Norfolk Southern Corp.',
    region: 'GB',
  },
  {
    symbol: '0K8N-LN',
    name: 'Beneteau',
    region: 'GB',
  },
  {
    symbol: '0K8W-LN',
    name: 'Derichebourg',
    region: 'GB',
  },
  {
    symbol: '0K91-LN',
    name: 'Northern Trust Corp.',
    region: 'GB',
  },
  {
    symbol: '0K92-LN',
    name: 'Northrop Grumman Corp.',
    region: 'GB',
  },
  {
    symbol: '0K93-LN',
    name: 'Credito Emiliano SPA',
    region: 'GB',
  },
  {
    symbol: '0K95-LN',
    name: 'Northwest Biotherapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0K96-LN',
    name: 'Ence Energia Y Celulosa, S.A.',
    region: 'GB',
  },
  {
    symbol: '0K97-LN',
    name: 'Elecnor SA.',
    region: 'GB',
  },
  {
    symbol: '0K9A-LN',
    name: 'Euronav NV',
    region: 'GB',
  },
  {
    symbol: '0K9H-LN',
    name: 'Faes Farma S.A.',
    region: 'GB',
  },
  {
    symbol: '0K9J-LN',
    name: 'NOW Inc',
    region: 'GB',
  },
  {
    symbol: '0K9L-LN',
    name: 'Nucor Corp.',
    region: 'GB',
  },
  {
    symbol: '0K9N-LN',
    name: 'Rallye',
    region: 'GB',
  },
  {
    symbol: '0K9P-LN',
    name: 'GN Store Nord A.S.',
    region: 'GB',
  },
  {
    symbol: '0K9T-LN',
    name: 'Ellaktor S.A',
    region: 'GB',
  },
  {
    symbol: '0K9U-LN',
    name: 'Hellenic Petroleum Holdings Societe Anonyme',
    region: 'GB',
  },
  {
    symbol: '0K9V-LN',
    name: 'Hal Trust',
    region: 'GB',
  },
  {
    symbol: '0K9W-LN',
    name: 'Huhtamaki Oyj',
    region: 'GB',
  },
  {
    symbol: '0KA1-LN',
    name: 'Intralot S.A. - Integrated Lottery Systems & Services',
    region: 'GB',
  },
  {
    symbol: '0KA3-LN',
    name: 'Ipsos',
    region: 'GB',
  },
  {
    symbol: '0KA8-LN',
    name: 'LogicMark Inc',
    region: 'GB',
  },
  {
    symbol: '0KAB-LN',
    name: 'O`Reilly Automotive, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KAK-LN',
    name: 'Occidental Petroleum Corp.',
    region: 'GB',
  },
  {
    symbol: '0KAN-LN',
    name: 'Oceaneering International, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KAS-LN',
    name: 'Ocwen Financial Corp.',
    region: 'GB',
  },
  {
    symbol: '0KAV-LN',
    name: 'Arnoldo Mondadori Editore Spa',
    region: 'GB',
  },
  {
    symbol: '0KAZ-LN',
    name: 'Mytilineos S.A.',
    region: 'GB',
  },
  {
    symbol: '0KB3-LN',
    name: 'Nexity SA',
    region: 'GB',
  },
  {
    symbol: '0KBK-LN',
    name: 'Omnicom Group, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KBQ-LN',
    name: 'Ratos AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0KBS-LN',
    name: 'Recordati SpA',
    region: 'GB',
  },
  {
    symbol: '0KBT-LN',
    name: 'Redes Energeticas Nacionais Sgps SA',
    region: 'GB',
  },
  {
    symbol: '0KBZ-LN',
    name: 'Rexel',
    region: 'GB',
  },
  {
    symbol: '0KC7-LN',
    name: 'OncoSec Medical Inc',
    region: 'GB',
  },
  {
    symbol: '0KCC-LN',
    name: 'Oncocyte Corporation',
    region: 'GB',
  },
  {
    symbol: '0KCD-LN',
    name: 'Solaria Energia Y Medio Ambiente SA',
    region: 'GB',
  },
  {
    symbol: '0KCI-LN',
    name: 'Oneok Inc.',
    region: 'GB',
  },
  {
    symbol: '0KCP-LN',
    name: 'Tessenderlo Group',
    region: 'GB',
  },
  {
    symbol: '0KD1-LN',
    name: 'Tubacex',
    region: 'GB',
  },
  {
    symbol: '0KD2-LN',
    name: 'Tubos Reunidos',
    region: 'GB',
  },
  {
    symbol: '0KDD-LN',
    name: 'Vocento SA',
    region: 'GB',
  },
  {
    symbol: '0KDH-LN',
    name: 'Ormat Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0KDI-LN',
    name: 'Oshkosh Corp',
    region: 'GB',
  },
  {
    symbol: '0KDK-LN',
    name: 'Barco NV',
    region: 'GB',
  },
  {
    symbol: '0KDU-LN',
    name: 'Overstock.com Inc',
    region: 'GB',
  },
  {
    symbol: '0KE0-LN',
    name: 'PBF Energy Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0KED-LN',
    name: 'Infineon Technologies AG',
    region: 'GB',
  },
  {
    symbol: '0KEF-LN',
    name: 'PNC Financial Services Group',
    region: 'GB',
  },
  {
    symbol: '0KEI-LN',
    name: 'PPG Industries, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KEJ-LN',
    name: 'PPL Corp',
    region: 'GB',
  },
  {
    symbol: '0KEQ-LN',
    name: 'PVH Corp',
    region: 'GB',
  },
  {
    symbol: '0KET-LN',
    name: 'Paccar Inc.',
    region: 'GB',
  },
  {
    symbol: '0KEZ-LN',
    name: 'Packaging Corp Of America',
    region: 'GB',
  },
  {
    symbol: '0KF1-LN',
    name: 'Questerre Energy Corporation',
    region: 'GB',
  },
  {
    symbol: '0KF3-LN',
    name: 'Palatin Technologies Inc.',
    region: 'GB',
  },
  {
    symbol: '0KFE-LN',
    name: 'Muenchener Rueckversicherungs-Gesellschaft AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0KFF-LN',
    name: 'Nordwest Handel AG Dortmund',
    region: 'GB',
  },
  {
    symbol: '0KFH-LN',
    name: 'Revenio Group Corporation',
    region: 'GB',
  },
  {
    symbol: '0KFL-LN',
    name: 'United Labels AG',
    region: 'GB',
  },
  {
    symbol: '0KFO-LN',
    name: 'Zavarovalnica Triglav D.D.',
    region: 'GB',
  },
  {
    symbol: '0KFX-LN',
    name: 'Danone',
    region: 'GB',
  },
  {
    symbol: '0KFZ-LN',
    name: 'Parker-Hannifin Corp.',
    region: 'GB',
  },
  {
    symbol: '0KG0-LN',
    name: 'TietoEVRY Corporation',
    region: 'GB',
  },
  {
    symbol: '0KGB-LN',
    name: 'Patterson Companies Inc.',
    region: 'GB',
  },
  {
    symbol: '0KGE-LN',
    name: 'Paychex Inc.',
    region: 'GB',
  },
  {
    symbol: '0KGQ-LN',
    name: 'United International Enterprises Plc',
    region: 'GB',
  },
  {
    symbol: '0KGR-LN',
    name: 'Digitalist Group Oyj',
    region: 'GB',
  },
  {
    symbol: '0KGS-LN',
    name: 'Pegasystems Inc.',
    region: 'GB',
  },
  {
    symbol: '0KGY-LN',
    name: 'BTS Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0KHE-LN',
    name: 'Perkinelmer, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KHH-LN',
    name: 'Geox Spa',
    region: 'GB',
  },
  {
    symbol: '0KHU-LN',
    name: 'Comcm',
    region: 'GB',
  },
  {
    symbol: '0KHZ-LN',
    name: 'Phillips 66',
    region: 'GB',
  },
  {
    symbol: '0KI5-LN',
    name: 'Uponor Oyj',
    region: 'GB',
  },
  {
    symbol: '0KIE-LN',
    name: 'Obducat AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0KII-LN',
    name: 'SSAB Corporation - Class A',
    region: 'GB',
  },
  {
    symbol: '0KIQ-LN',
    name: 'Friwo AG',
    region: 'GB',
  },
  {
    symbol: '0KIT-LN',
    name: 'Pinnacle West Capital Corp.',
    region: 'GB',
  },
  {
    symbol: '0KIX-LN',
    name: 'Pioneer Natural Resources Co.',
    region: 'GB',
  },
  {
    symbol: '0KIZ-LN',
    name: 'New Wave Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0KJ7-LN',
    name: 'Ibersol - S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0KJD-LN',
    name: 'Planet Fitness Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0KJF-LN',
    name: 'Apranga Pva',
    region: 'GB',
  },
  {
    symbol: '0KJQ-LN',
    name: 'Polaris Inc',
    region: 'GB',
  },
  {
    symbol: '0KJZ-LN',
    name: 'Post Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0KK7-LN',
    name: 'Be Shaping the Future S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0KLO-LN',
    name: 'Navigator Company SA',
    region: 'GB',
  },
  {
    symbol: '0KO0-LN',
    name: 'Electra Gruppen',
    region: 'GB',
  },
  {
    symbol: '0KO5-LN',
    name: 'Principal Financial Group Inc - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0KO8-LN',
    name: 'Enea S.A.',
    region: 'GB',
  },
  {
    symbol: '0KOC-LN',
    name: 'Progressive Corp.',
    region: 'GB',
  },
  {
    symbol: '0KRX-LN',
    name: 'Prudential Financial Inc.',
    region: 'GB',
  },
  {
    symbol: '0KS2-LN',
    name: 'Public Service Enterprise Group Inc.',
    region: 'GB',
  },
  {
    symbol: '0KS6-LN',
    name: 'PulteGroup Inc',
    region: 'GB',
  },
  {
    symbol: '0KSJ-LN',
    name: 'Qorvo Inc',
    region: 'GB',
  },
  {
    symbol: '0KSR-LN',
    name: 'Quanta Services, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KSX-LN',
    name: 'Quest Diagnostics, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KT6-LN',
    name: 'RCI Hospitality Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0KTI-LN',
    name: 'Enbridge Inc',
    region: 'GB',
  },
  {
    symbol: '0KTS-LN',
    name: 'Ralph Lauren Corp - Class A',
    region: 'GB',
  },
  {
    symbol: '0KTW-LN',
    name: 'Range Resources Corp',
    region: 'GB',
  },
  {
    symbol: '0KUR-LN',
    name: 'PSI Software AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0KUV-LN',
    name: 'Atari SA',
    region: 'GB',
  },
  {
    symbol: '0KUY-LN',
    name: 'PNE AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0KV3-LN',
    name: 'Regions Financial Corp.',
    region: 'GB',
  },
  {
    symbol: '0KV5-LN',
    name: 'Fairfax Financial Holdings, Ltd.',
    region: 'GB',
  },
  {
    symbol: '0KV7-LN',
    name: 'Tomra Systems ASA',
    region: 'GB',
  },
  {
    symbol: '0KVH-LN',
    name: 'BRD-Groupe Societe Generale',
    region: 'GB',
  },
  {
    symbol: '0KVR-LN',
    name: 'Kloekner & Co',
    region: 'GB',
  },
  {
    symbol: '0KVV-LN',
    name: 'Airbus SE',
    region: 'GB',
  },
  {
    symbol: '0KW1-LN',
    name: 'Republic Services, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KW4-LN',
    name: 'Resmed Inc.',
    region: 'GB',
  },
  {
    symbol: '0KWF-LN',
    name: 'Sopharma AD-Sofia',
    region: 'GB',
  },
  {
    symbol: '0KX0-LN',
    name: 'Riot Blockchain Inc',
    region: 'GB',
  },
  {
    symbol: '0KX1-LN',
    name: 'Investor.BG AD',
    region: 'GB',
  },
  {
    symbol: '0KX9-LN',
    name: 'Robert Half International Inc.',
    region: 'GB',
  },
  {
    symbol: '0KXA-LN',
    name: 'Rockwell Automation Inc',
    region: 'GB',
  },
  {
    symbol: '0KXM-LN',
    name: 'Roper Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0KXO-LN',
    name: 'Ross Stores, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KXS-LN',
    name: 'Royal Gold, Inc.',
    region: 'GB',
  },
  {
    symbol: '0KXY-LN',
    name: 'Altareit',
    region: 'GB',
  },
  {
    symbol: '0KYY-LN',
    name: 'S&P Global Inc',
    region: 'GB',
  },
  {
    symbol: '0KZA-LN',
    name: 'SM Energy Co',
    region: 'GB',
  },
  {
    symbol: '0L1G-LN',
    name: 'SS&C Technologies Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0L35-LN',
    name: 'Sarepta Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0L3C-LN',
    name: 'Henry Schein Inc.',
    region: 'GB',
  },
  {
    symbol: '0L3H-LN',
    name: 'L3Harris Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0L45-LN',
    name: 'Scotts Miracle-Gro Company - Class A',
    region: 'GB',
  },
  {
    symbol: '0L4F-LN',
    name: 'Sealed Air Corp.',
    region: 'GB',
  },
  {
    symbol: '0L4K-LN',
    name: 'Second Sight Medical Products Inc',
    region: 'GB',
  },
  {
    symbol: '0L5A-LN',
    name: 'Sempra Energy',
    region: 'GB',
  },
  {
    symbol: '0L5V-LN',
    name: 'Sherwin-Williams Co.',
    region: 'GB',
  },
  {
    symbol: '0L6Z-LN',
    name: 'Sirius XM Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0L73-LN',
    name: 'Skechers U S A, Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0L77-LN',
    name: 'Skyworks Solutions, Inc.',
    region: 'GB',
  },
  {
    symbol: '0L7A-LN',
    name: 'A.O. Smith Corp.',
    region: 'GB',
  },
  {
    symbol: '0L7F-LN',
    name: 'J.M. Smucker Co.',
    region: 'GB',
  },
  {
    symbol: '0L7G-LN',
    name: 'Snap-on, Inc.',
    region: 'GB',
  },
  {
    symbol: '0L7R-LN',
    name: 'SolarWindow Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0L7S-LN',
    name: 'Solaredge Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0L85-LN',
    name: 'Sorrento Therapeutics Inc',
    region: 'GB',
  },
  {
    symbol: '0L8A-LN',
    name: 'Southern Company',
    region: 'GB',
  },
  {
    symbol: '0L8B-LN',
    name: 'Southern Copper Corporation',
    region: 'GB',
  },
  {
    symbol: '0L8F-LN',
    name: 'Southwest Airlines Co',
    region: 'GB',
  },
  {
    symbol: '0L8Z-LN',
    name: 'Contextvision AB',
    region: 'GB',
  },
  {
    symbol: '0L9E-LN',
    name: 'Stanley Black & Decker Inc',
    region: 'GB',
  },
  {
    symbol: '0L9G-LN',
    name: 'State Street Corp.',
    region: 'GB',
  },
  {
    symbol: '0L9J-LN',
    name: 'S&T AG',
    region: 'GB',
  },
  {
    symbol: '0L9O-LN',
    name: 'Stericycle Inc.',
    region: 'GB',
  },
  {
    symbol: '0L9Q-LN',
    name: 'Fiskars Oyj Abp',
    region: 'GB',
  },
  {
    symbol: '0L9Z-LN',
    name: 'KABE Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0LAC-LN',
    name: 'Deag Deutsche Entertainment AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0LAI-LN',
    name: 'Summit Materials Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0LBI-LN',
    name: 'NortonLifeLock Inc',
    region: 'GB',
  },
  {
    symbol: '0LBM-LN',
    name: 'Terna - Trasmissione Elettricita Rete Nazionale',
    region: 'GB',
  },
  {
    symbol: '0LBP-LN',
    name: 'Synopsys, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LC6-LN',
    name: 'Sysco Corp.',
    region: 'GB',
  },
  {
    symbol: '0LCE-LN',
    name: 'TJX Companies, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LCR-LN',
    name: 'Fuchs Petrolub SE - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0LCV-LN',
    name: 'Taiwan Semiconductor Manufacturing - ADR',
    region: 'GB',
  },
  {
    symbol: '0LCX-LN',
    name: 'Take-Two Interactive Software, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LD0-LN',
    name: 'Engie',
    region: 'GB',
  },
  {
    symbol: '0LD5-LN',
    name: 'Tapestry Inc',
    region: 'GB',
  },
  {
    symbol: '0LD8-LN',
    name: 'Target Corp',
    region: 'GB',
  },
  {
    symbol: '0LD9-LN',
    name: 'Targa Resources Corp',
    region: 'GB',
  },
  {
    symbol: '0LDA-LN',
    name: 'Tata Motors Ltd. - ADR',
    region: 'GB',
  },
  {
    symbol: '0LE3-LN',
    name: 'Tellurian Inc',
    region: 'GB',
  },
  {
    symbol: '0LEE-LN',
    name: 'Teradata Corp',
    region: 'GB',
  },
  {
    symbol: '0LEF-LN',
    name: 'Teradyne, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LF0-LN',
    name: 'Textron Inc.',
    region: 'GB',
  },
  {
    symbol: '0LF8-LN',
    name: 'Thor Industries, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LFS-LN',
    name: 'Toll Brothers Inc.',
    region: 'GB',
  },
  {
    symbol: '0LGI-LN',
    name: 'Havila Shipping ASA',
    region: 'GB',
  },
  {
    symbol: '0LH4-LN',
    name: 'Tucows, Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0LHO-LN',
    name: 'Ii-Vi Inc.',
    region: 'GB',
  },
  {
    symbol: '0LHR-LN',
    name: 'Tyson Foods, Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0LHW-LN',
    name: 'U.S. Gold Corp',
    region: 'GB',
  },
  {
    symbol: '0LHY-LN',
    name: 'U.S. Bancorp.',
    region: 'GB',
  },
  {
    symbol: '0LI9-LN',
    name: 'Ubiquiti Inc',
    region: 'GB',
  },
  {
    symbol: '0LIB-LN',
    name: 'Ulta Beauty Inc',
    region: 'GB',
  },
  {
    symbol: '0LIK-LN',
    name: 'Under Armour Inc - Class C',
    region: 'GB',
  },
  {
    symbol: '0LIU-LN',
    name: 'United Airlines Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0LJ9-LN',
    name: 'United States Steel Corp.',
    region: 'GB',
  },
  {
    symbol: '0LJD-LN',
    name: 'Universal Corp.',
    region: 'GB',
  },
  {
    symbol: '0LJE-LN',
    name: 'Universal Display Corp.',
    region: 'GB',
  },
  {
    symbol: '0LJL-LN',
    name: 'Universal Health Services, Inc. - Class B',
    region: 'GB',
  },
  {
    symbol: '0LJN-LN',
    name: 'Unum Group',
    region: 'GB',
  },
  {
    symbol: '0LJQ-LN',
    name: 'Uranium Energy Corp',
    region: 'GB',
  },
  {
    symbol: '0LK3-LN',
    name: 'Vail Resorts Inc.',
    region: 'GB',
  },
  {
    symbol: '0LK6-LN',
    name: 'Valero Energy Corp.',
    region: 'GB',
  },
  {
    symbol: '0LLP-LN',
    name: 'Tiscali Spa',
    region: 'GB',
  },
  {
    symbol: '0LN5-LN',
    name: 'Hunter Douglas NV',
    region: 'GB',
  },
  {
    symbol: '0LN7-LN',
    name: 'Air France-KLM',
    region: 'GB',
  },
  {
    symbol: '0LNG-LN',
    name: 'Koninklijke Philips N.V.',
    region: 'GB',
  },
  {
    symbol: '0LNQ-LN',
    name: 'Royal BAM Group NV',
    region: 'GB',
  },
  {
    symbol: '0LNT-LN',
    name: 'Fugro NV - New',
    region: 'GB',
  },
  {
    symbol: '0LO4-LN',
    name: 'Ventas Inc',
    region: 'GB',
  },
  {
    symbol: '0LO8-LN',
    name: 'Royal Boskalis Westminster N.V.',
    region: 'GB',
  },
  {
    symbol: '0LO9-LN',
    name: 'Advanced Metallurgical Group N.V.',
    region: 'GB',
  },
  {
    symbol: '0LOZ-LN',
    name: 'Verisign Inc.',
    region: 'GB',
  },
  {
    symbol: '0LP2-LN',
    name: 'Veritiv Corp',
    region: 'GB',
  },
  {
    symbol: '0LPE-LN',
    name: 'Viasat, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LQ1-LN',
    name: 'Continental AG',
    region: 'GB',
  },
  {
    symbol: '0LQ4-LN',
    name: 'Krones AG',
    region: 'GB',
  },
  {
    symbol: '0LQG-LN',
    name: 'Asseco Poland SA.',
    region: 'GB',
  },
  {
    symbol: '0LQQ-LN',
    name: 'Vodafone Group plc - ADR',
    region: 'GB',
  },
  {
    symbol: '0LR0-LN',
    name: 'Amica S.A.',
    region: 'GB',
  },
  {
    symbol: '0LR2-LN',
    name: 'Vornado Realty Trust',
    region: 'GB',
  },
  {
    symbol: '0LRB-LN',
    name: 'Triton Development SA',
    region: 'GB',
  },
  {
    symbol: '0LRI-LN',
    name: 'Jumbo S.A.',
    region: 'GB',
  },
  {
    symbol: '0LRK-LN',
    name: 'Vulcan Materials Co',
    region: 'GB',
  },
  {
    symbol: '0LRL-LN',
    name: 'Vuzix Corporation',
    region: 'GB',
  },
  {
    symbol: '0LRN-LN',
    name: 'Bank Ochrony Srodowiska S.A.',
    region: 'GB',
  },
  {
    symbol: '0LRV-LN',
    name: 'Budimex S.A.',
    region: 'GB',
  },
  {
    symbol: '0LS5-LN',
    name: 'CCC S.A.',
    region: 'GB',
  },
  {
    symbol: '0LS7-LN',
    name: 'Ciech SA',
    region: 'GB',
  },
  {
    symbol: '0LSA-LN',
    name: 'Cognor Holding S. A',
    region: 'GB',
  },
  {
    symbol: '0LSL-LN',
    name: 'WEC Energy Group Inc',
    region: 'GB',
  },
  {
    symbol: '0LSN-LN',
    name: 'PZ Cormay SA',
    region: 'GB',
  },
  {
    symbol: '0LSO-LN',
    name: 'Wabash National Corp.',
    region: 'GB',
  },
  {
    symbol: '0LST-LN',
    name: 'Dom Development SA',
    region: 'GB',
  },
  {
    symbol: '0LSU-LN',
    name: 'Drewex SA',
    region: 'GB',
  },
  {
    symbol: '0LSZ-LN',
    name: 'Walgreens Boots Alliance Inc',
    region: 'GB',
  },
  {
    symbol: '0LT3-LN',
    name: 'Korporacja Gospodarcza EFEKT SA',
    region: 'GB',
  },
  {
    symbol: '0LT6-LN',
    name: 'Elkop S A',
    region: 'GB',
  },
  {
    symbol: '0LTG-LN',
    name: 'Waste Management, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LTI-LN',
    name: 'Waters Corp.',
    region: 'GB',
  },
  {
    symbol: '0LTK-LN',
    name: 'Echo Investment S.A.',
    region: 'GB',
  },
  {
    symbol: '0LTM-LN',
    name: 'Eurocash SA',
    region: 'GB',
  },
  {
    symbol: '0LU5-LN',
    name: 'Starhedge S.A',
    region: 'GB',
  },
  {
    symbol: '0LUB-LN',
    name: 'Przedsiebiorstwo Hydrauliki Silowej Hydrotor S.A.',
    region: 'GB',
  },
  {
    symbol: '0LUR-LN',
    name: 'Inter Cars SA',
    region: 'GB',
  },
  {
    symbol: '0LUV-LN',
    name: 'Izolacja Jarocin SA',
    region: 'GB',
  },
  {
    symbol: '0LV3-LN',
    name: 'Grupa KETY SA',
    region: 'GB',
  },
  {
    symbol: '0LVJ-LN',
    name: 'Western Union Company',
    region: 'GB',
  },
  {
    symbol: '0LVK-LN',
    name: 'Westlake Corporation',
    region: 'GB',
  },
  {
    symbol: '0LVL-LN',
    name: 'LPP SA',
    region: 'GB',
  },
  {
    symbol: '0LVQ-LN',
    name: 'Makarony Polskie SA',
    region: 'GB',
  },
  {
    symbol: '0LW7-LN',
    name: 'Muza S.A.',
    region: 'GB',
  },
  {
    symbol: '0LW9-LN',
    name: 'WestRock Co',
    region: 'GB',
  },
  {
    symbol: '0LWA-LN',
    name: 'Mostostal Zabrze-Holding S.A.',
    region: 'GB',
  },
  {
    symbol: '0LWG-LN',
    name: 'Weyerhaeuser Co.',
    region: 'GB',
  },
  {
    symbol: '0LWH-LN',
    name: 'Whirlpool Corp.',
    region: 'GB',
  },
  {
    symbol: '0LWV-LN',
    name: 'NTT System SA',
    region: 'GB',
  },
  {
    symbol: '0LWX-LN',
    name: 'Orzel Bialy S.A.',
    region: 'GB',
  },
  {
    symbol: '0LWY-LN',
    name: 'Odlewnie Polskie S.A.',
    region: 'GB',
  },
  {
    symbol: '0LX0-LN',
    name: 'Oponeo Pl S.A.',
    region: 'GB',
  },
  {
    symbol: '0LX1-LN',
    name: 'CD Projekt S.A.',
    region: 'GB',
  },
  {
    symbol: '0LX9-LN',
    name: 'PMPG Polskie Media SA - Class C',
    region: 'GB',
  },
  {
    symbol: '0LXB-LN',
    name: 'Williams Cos Inc',
    region: 'GB',
  },
  {
    symbol: '0LXC-LN',
    name: 'Williams-Sonoma, Inc.',
    region: 'GB',
  },
  {
    symbol: '0LXZ-LN',
    name: 'Ifirma SA',
    region: 'GB',
  },
  {
    symbol: '0LY1-LN',
    name: 'Wisdomtree Investments Inc',
    region: 'GB',
  },
  {
    symbol: '0LYB-LN',
    name: 'Zaklady Magnezytowe ROPCZYCE SA',
    region: 'GB',
  },
  {
    symbol: '0LYD-LN',
    name: 'Sanwil Holding SA',
    region: 'GB',
  },
  {
    symbol: '0LYP-LN',
    name: 'Sonel SA',
    region: 'GB',
  },
  {
    symbol: '0LZB-LN',
    name: 'Unibep',
    region: 'GB',
  },
  {
    symbol: '0LZG-LN',
    name: 'Zaklady Przemyslu Cukierniczego Wawel S.A.',
    region: 'GB',
  },
  {
    symbol: '0LZT-LN',
    name: 'Erg S.A',
    region: 'GB',
  },
  {
    symbol: '0LZY-LN',
    name: 'Zremb-Chojnice SA',
    region: 'GB',
  },
  {
    symbol: '0LZZ-LN',
    name: 'PGF Polska Grupa Fotowoltaiczna Spolka Akcyjna',
    region: 'GB',
  },
  {
    symbol: '0M09-LN',
    name: 'Rockwool AS - Class A',
    region: 'GB',
  },
  {
    symbol: '0M0A-LN',
    name: 'Rockwool AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0M0E-LN',
    name: 'Ercros',
    region: 'GB',
  },
  {
    symbol: '0M0Q-LN',
    name: 'Deoleo S.A.',
    region: 'GB',
  },
  {
    symbol: '0M1G-LN',
    name: 'World Wrestling Entertainment, Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0M1K-LN',
    name: 'Travel+Leisure Co',
    region: 'GB',
  },
  {
    symbol: '0M1M-LN',
    name: 'Suominen Corporation',
    region: 'GB',
  },
  {
    symbol: '0M1O-LN',
    name: 'XPO Logistics Inc',
    region: 'GB',
  },
  {
    symbol: '0M1R-LN',
    name: 'Xcel Energy, Inc.',
    region: 'GB',
  },
  {
    symbol: '0M1X-LN',
    name: 'Nurminen Logistics Plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0M26-LN',
    name: 'XOMA Corp',
    region: 'GB',
  },
  {
    symbol: '0M29-LN',
    name: 'Xylem Inc',
    region: 'GB',
  },
  {
    symbol: '0M2A-LN',
    name: 'Xperi Holding Corp',
    region: 'GB',
  },
  {
    symbol: '0M2B-LN',
    name: 'Linde Plc',
    region: 'GB',
  },
  {
    symbol: '0M2N-LN',
    name: 'Orion Corporation - Class A',
    region: 'GB',
  },
  {
    symbol: '0M2O-LN',
    name: 'Orion Corporation - Class B',
    region: 'GB',
  },
  {
    symbol: '0M2T-LN',
    name: 'KPS AG',
    region: 'GB',
  },
  {
    symbol: '0M2Z-LN',
    name: 'Equinor ASA',
    region: 'GB',
  },
  {
    symbol: '0M30-LN',
    name: 'Yum China Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0M3C-LN',
    name: 'Fon SE',
    region: 'GB',
  },
  {
    symbol: '0M3L-LN',
    name: 'Zions Bancorporation',
    region: 'GB',
  },
  {
    symbol: '0M3Q-LN',
    name: 'Zoetis Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0M3T-LN',
    name: 'Arribatec Solutions Asa',
    region: 'GB',
  },
  {
    symbol: '0M4F-LN',
    name: 'Ottakringer Getraenke AG',
    region: 'GB',
  },
  {
    symbol: '0M4G-LN',
    name: 'Ottakringer Getraenke AG - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0M50-LN',
    name: 'INC Spolka Akcyjna',
    region: 'GB',
  },
  {
    symbol: '0M58-LN',
    name: 'Hollysys Automation Technologies Ltd',
    region: 'GB',
  },
  {
    symbol: '0M5J-LN',
    name: 'Aker BP ASA',
    region: 'GB',
  },
  {
    symbol: '0M5U-LN',
    name: 'Impresa - S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0M69-LN',
    name: 'OTP Bank',
    region: 'GB',
  },
  {
    symbol: '0M6I-LN',
    name: 'Heijmans NV',
    region: 'GB',
  },
  {
    symbol: '0M6J-LN',
    name: 'H & H International - Class B',
    region: 'GB',
  },
  {
    symbol: '0M6P-LN',
    name: 'SES SA - FRR',
    region: 'GB',
  },
  {
    symbol: '0M6S-LN',
    name: 'Allianz SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0M6Y-LN',
    name: 'Flugger group A/S - Class B',
    region: 'GB',
  },
  {
    symbol: '0M7W-LN',
    name: 'Trace Group Hold AD',
    region: 'GB',
  },
  {
    symbol: '0M8V-LN',
    name: 'Philip Morris International Inc',
    region: 'GB',
  },
  {
    symbol: '0M8Y-LN',
    name: 'Formpipe Software AB',
    region: 'GB',
  },
  {
    symbol: '0M9A-LN',
    name: 'Hannover Ruck SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0M9X-LN',
    name: 'Verallia Deutschland AG',
    region: 'GB',
  },
  {
    symbol: '0MA6-LN',
    name: 'OMV Petrom SA',
    region: 'GB',
  },
  {
    symbol: '0MB2-LN',
    name: 'Intek Group Spa',
    region: 'GB',
  },
  {
    symbol: '0MBJ-LN',
    name: 'Lark. PL SA',
    region: 'GB',
  },
  {
    symbol: '0MC5-LN',
    name: 'Public Power Corporation S.A.',
    region: 'GB',
  },
  {
    symbol: '0MCB-LN',
    name: 'eWork Group AB',
    region: 'GB',
  },
  {
    symbol: '0MCG-LN',
    name: 'Hamborner Reit AG',
    region: 'GB',
  },
  {
    symbol: '0MCJ-LN',
    name: 'Svenska Cellulosa AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0MCK-LN',
    name: 'Svenska Cellulosa AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0MCN-LN',
    name: 'Walter Bau-AG',
    region: 'GB',
  },
  {
    symbol: '0MCY-LN',
    name: 'Fotex Holding SE Co Ltd',
    region: 'GB',
  },
  {
    symbol: '0MD5-LN',
    name: 'Exel Composites Plc',
    region: 'GB',
  },
  {
    symbol: '0MDB-LN',
    name: 'Marel hf.',
    region: 'GB',
  },
  {
    symbol: '0MDP-LN',
    name: 'Geopark Limited',
    region: 'GB',
  },
  {
    symbol: '0MDT-LN',
    name: 'Electrolux AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0MDX-LN',
    name: 'Parken Sport & Entertainment',
    region: 'GB',
  },
  {
    symbol: '0ME5-LN',
    name: 'BG Agro AD-Varna',
    region: 'GB',
  },
  {
    symbol: '0MEC-LN',
    name: 'Nordex SE',
    region: 'GB',
  },
  {
    symbol: '0MEF-LN',
    name: 'Rapala VMC Corporation',
    region: 'GB',
  },
  {
    symbol: '0MEL-LN',
    name: 'Deceuninck Plastics Industries',
    region: 'GB',
  },
  {
    symbol: '0MEO-LN',
    name: 'Alta S.A.',
    region: 'GB',
  },
  {
    symbol: '0MET-LN',
    name: 'Konecranes Plc',
    region: 'GB',
  },
  {
    symbol: '0MFA-LN',
    name: 'Arise AB',
    region: 'GB',
  },
  {
    symbol: '0MFU-LN',
    name: 'Agfa-Gevaert',
    region: 'GB',
  },
  {
    symbol: '0MFY-LN',
    name: 'Aryzta AG',
    region: 'GB',
  },
  {
    symbol: '0MG0-LN',
    name: 'Aareal Bank AG',
    region: 'GB',
  },
  {
    symbol: '0MG1-LN',
    name: 'Fielmann AG',
    region: 'GB',
  },
  {
    symbol: '0MG2-LN',
    name: 'HeidelbergCement AG',
    region: 'GB',
  },
  {
    symbol: '0MG5-LN',
    name: 'Elringklinger AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0MGC-LN',
    name: 'NKT A/S',
    region: 'GB',
  },
  {
    symbol: '0MGD-LN',
    name: 'Jyske Bank AS',
    region: 'GB',
  },
  {
    symbol: '0MGE-LN',
    name: 'Sydbank',
    region: 'GB',
  },
  {
    symbol: '0MGG-LN',
    name: 'Kemira Oyj',
    region: 'GB',
  },
  {
    symbol: '0MGH-LN',
    name: 'Cargotec Corporation - Class B',
    region: 'GB',
  },
  {
    symbol: '0MGI-LN',
    name: 'Metso Outotec Corporation',
    region: 'GB',
  },
  {
    symbol: '0MGJ-LN',
    name: 'Vicat',
    region: 'GB',
  },
  {
    symbol: '0MGL-LN',
    name: 'M6 - Metropole Television',
    region: 'GB',
  },
  {
    symbol: '0MGN-LN',
    name: 'Albioma',
    region: 'GB',
  },
  {
    symbol: '0MGO-LN',
    name: 'JCDecaux SA',
    region: 'GB',
  },
  {
    symbol: '0MGP-LN',
    name: 'Bic',
    region: 'GB',
  },
  {
    symbol: '0MGR-LN',
    name: 'Faurecia SE',
    region: 'GB',
  },
  {
    symbol: '0MGS-LN',
    name: 'SEB',
    region: 'GB',
  },
  {
    symbol: '0MGU-LN',
    name: 'Remy Cointreau SA',
    region: 'GB',
  },
  {
    symbol: '0MGV-LN',
    name: 'Eramet',
    region: 'GB',
  },
  {
    symbol: '0MH1-LN',
    name: 'Bureau Veritas',
    region: 'GB',
  },
  {
    symbol: '0MH6-LN',
    name: 'Ipsen',
    region: 'GB',
  },
  {
    symbol: '0MHC-LN',
    name: 'ERG Eolica Campania S.p.A',
    region: 'GB',
  },
  {
    symbol: '0MHD-LN',
    name: 'Acea Spa',
    region: 'GB',
  },
  {
    symbol: '0MHM-LN',
    name: 'Schibsted ASA - Class A',
    region: 'GB',
  },
  {
    symbol: '0MHP-LN',
    name: 'DNO ASA',
    region: 'GB',
  },
  {
    symbol: '0MHQ-LN',
    name: 'Magnora ASA',
    region: 'GB',
  },
  {
    symbol: '0MHR-LN',
    name: 'PGS ASA',
    region: 'GB',
  },
  {
    symbol: '0MHT-LN',
    name: 'Peab AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0MHU-LN',
    name: 'Industrivarden, AB - Class C',
    region: 'GB',
  },
  {
    symbol: '0MHW-LN',
    name: 'Volvo AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0MHZ-LN',
    name: 'SSAB Corporation - Class B',
    region: 'GB',
  },
  {
    symbol: '0MI0-LN',
    name: 'Autoliv Inc. - SDR',
    region: 'GB',
  },
  {
    symbol: '0MI3-LN',
    name: 'JM AB',
    region: 'GB',
  },
  {
    symbol: '0MII-LN',
    name: 'Uestra Hannov. Verkehrsbetr. AG',
    region: 'GB',
  },
  {
    symbol: '0MJ1-LN',
    name: 'Palfinger AG',
    region: 'GB',
  },
  {
    symbol: '0MJF-LN',
    name: 'Ambu AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0MJH-LN',
    name: 'Zumtobel Group AG',
    region: 'GB',
  },
  {
    symbol: '0MJK-LN',
    name: 'Erste Group Bank AG',
    region: 'GB',
  },
  {
    symbol: '0MJO-LN',
    name: 'Lewag Holding AG',
    region: 'GB',
  },
  {
    symbol: '0MJR-LN',
    name: 'Neuca SA',
    region: 'GB',
  },
  {
    symbol: '0MJT-LN',
    name: 'Atresmedia Corporacion De Medios De Comunicacion SA',
    region: 'GB',
  },
  {
    symbol: '0MJX-LN',
    name: 'Aker ASA - Class A',
    region: 'GB',
  },
  {
    symbol: '0MJZ-LN',
    name: 'Andritz AG',
    region: 'GB',
  },
  {
    symbol: '0MKG-LN',
    name: 'Obrascon Huarte Lain',
    region: 'GB',
  },
  {
    symbol: '0MKH-LN',
    name: 'OMV AG',
    region: 'GB',
  },
  {
    symbol: '0MKM-LN',
    name: 'Sligro Food Group N.V.',
    region: 'GB',
  },
  {
    symbol: '0MKO-LN',
    name: 'Melia Hotels International S A',
    region: 'GB',
  },
  {
    symbol: '0MKP-LN',
    name: 'Strabag SE',
    region: 'GB',
  },
  {
    symbol: '0MKQ-LN',
    name: 'SSH Communications Security Oyj',
    region: 'GB',
  },
  {
    symbol: '0MKS-LN',
    name: 'Tomtom NV',
    region: 'GB',
  },
  {
    symbol: '0MKT-LN',
    name: 'Tecnicas Reunidas',
    region: 'GB',
  },
  {
    symbol: '0MKW-LN',
    name: 'Viscofan S.A.',
    region: 'GB',
  },
  {
    symbol: '0MKX-LN',
    name: 'Voestalpine AG',
    region: 'GB',
  },
  {
    symbol: '0MKZ-LN',
    name: 'Wienerberger AG',
    region: 'GB',
  },
  {
    symbol: '0ML0-LN',
    name: 'Sonae S.G.P.S. S.A.',
    region: 'GB',
  },
  {
    symbol: '0ML1-LN',
    name: 'EDP Renovaveis S.A.',
    region: 'GB',
  },
  {
    symbol: '0MMJ-LN',
    name: 'Tom Tailor Holding SE',
    region: 'GB',
  },
  {
    symbol: '0MMN-LN',
    name: 'Aplisens S.A',
    region: 'GB',
  },
  {
    symbol: '0MMP-LN',
    name: 'Asseco South Eastern Europe SA',
    region: 'GB',
  },
  {
    symbol: '0MN3-LN',
    name: 'PGE Polska Grupa Energetyczna SA',
    region: 'GB',
  },
  {
    symbol: '0MN5-LN',
    name: 'Sword Group',
    region: 'GB',
  },
  {
    symbol: '0MNA-LN',
    name: 'DGB Group NV',
    region: 'GB',
  },
  {
    symbol: '0MNC-LN',
    name: 'RTL Group',
    region: 'GB',
  },
  {
    symbol: '0MNQ-LN',
    name: 'Cyfrowy Polsat SA',
    region: 'GB',
  },
  {
    symbol: '0MNY-LN',
    name: 'ING Bank Slaski SA',
    region: 'GB',
  },
  {
    symbol: '0MO8-LN',
    name: 'SeaBird Exploration PLC',
    region: 'GB',
  },
  {
    symbol: '0MOS-LN',
    name: 'Maroc Telecom',
    region: 'GB',
  },
  {
    symbol: '0MPF-LN',
    name: 'Boreo Oyj',
    region: 'GB',
  },
  {
    symbol: '0MPH-LN',
    name: 'Deutsche Telekom AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0MPJ-LN',
    name: 'GEA Group AG',
    region: 'GB',
  },
  {
    symbol: '0MPL-LN',
    name: 'SGL Carbon SE',
    region: 'GB',
  },
  {
    symbol: '0MPM-LN',
    name: 'Ceconomy AG.',
    region: 'GB',
  },
  {
    symbol: '0MPP-LN',
    name: 'E. On SE',
    region: 'GB',
  },
  {
    symbol: '0MPT-LN',
    name: 'Brenntag SE',
    region: 'GB',
  },
  {
    symbol: '0MQ0-LN',
    name: 'North Media A/S',
    region: 'GB',
  },
  {
    symbol: '0MQ2-LN',
    name: 'P/f Bakkafrost',
    region: 'GB',
  },
  {
    symbol: '0MQA-LN',
    name: 'Itera ASA',
    region: 'GB',
  },
  {
    symbol: '0MQC-LN',
    name: 'Nekkar ASA',
    region: 'GB',
  },
  {
    symbol: '0MQG-LN',
    name: 'Mycronic AB',
    region: 'GB',
  },
  {
    symbol: '0MQT-LN',
    name: 'Motor Oil (Hellas) Corinth Refineries S.A.',
    region: 'GB',
  },
  {
    symbol: '0MR4-LN',
    name: 'Byggmax Group AB',
    region: 'GB',
  },
  {
    symbol: '0MR5-LN',
    name: 'Cellavision AB',
    region: 'GB',
  },
  {
    symbol: '0MR6-LN',
    name: 'Chr. Hansen Holding',
    region: 'GB',
  },
  {
    symbol: '0MRM-LN',
    name: 'Bang & Olufsen AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0MRO-LN',
    name: 'Zaklady Urzadzen Komputerowych ELZAB Spolka - Akcyjna',
    region: 'GB',
  },
  {
    symbol: '0MRQ-LN',
    name: 'Kauno Energija Pva',
    region: 'GB',
  },
  {
    symbol: '0MRV-LN',
    name: 'Panevezio Statybos Trestas Pva',
    region: 'GB',
  },
  {
    symbol: '0MS9-LN',
    name: 'Societa` Sportiva Lazio PA',
    region: 'GB',
  },
  {
    symbol: '0MSD-LN',
    name: 'CompuGroup Medical SE & Co. KgaA',
    region: 'GB',
  },
  {
    symbol: '0MSE-LN',
    name: 'Nyesa Valores Corporacion SA',
    region: 'GB',
  },
  {
    symbol: '0MSJ-LN',
    name: 'TGS ASA',
    region: 'GB',
  },
  {
    symbol: '0MSK-LN',
    name: 'Phicomm AG',
    region: 'GB',
  },
  {
    symbol: '0MSL-LN',
    name: 'IT Way S.p.A',
    region: 'GB',
  },
  {
    symbol: '0MT1-LN',
    name: 'A.S. Roma Spa',
    region: 'GB',
  },
  {
    symbol: '0MT8-LN',
    name: 'Dragerwerk AG',
    region: 'GB',
  },
  {
    symbol: '0MTD-LN',
    name: 'Swedish Orphan Biovitrum AB',
    region: 'GB',
  },
  {
    symbol: '0MTP-LN',
    name: 'IDEX Biometrics ASA',
    region: 'GB',
  },
  {
    symbol: '0MTZ-LN',
    name: 'Novabase - Sociedade Gestora De Participacoes Sociais, S.A.',
    region: 'GB',
  },
  {
    symbol: '0MU2-LN',
    name: 'Warehousing & Distribution De Pauw',
    region: 'GB',
  },
  {
    symbol: '0MU6-LN',
    name: 'BPER Banca S.p.A',
    region: 'GB',
  },
  {
    symbol: '0MUF-LN',
    name: 'Cenit AG',
    region: 'GB',
  },
  {
    symbol: '0MUG-LN',
    name: 'Transition Evergreen',
    region: 'GB',
  },
  {
    symbol: '0MUM-LN',
    name: 'Edenred',
    region: 'GB',
  },
  {
    symbol: '0MUN-LN',
    name: 'Iren Spa',
    region: 'GB',
  },
  {
    symbol: '0MV0-LN',
    name: 'Micropole',
    region: 'GB',
  },
  {
    symbol: '0MV2-LN',
    name: 'Freenet AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0MV4-LN',
    name: 'Philip Morris Cr A.S.',
    region: 'GB',
  },
  {
    symbol: '0MV5-LN',
    name: 'Eurofins Scientific S.E.',
    region: 'GB',
  },
  {
    symbol: '0MV6-LN',
    name: 'Laurent-Perrier',
    region: 'GB',
  },
  {
    symbol: '0MV8-LN',
    name: 'Technicolor',
    region: 'GB',
  },
  {
    symbol: '0MVH-LN',
    name: 'RS2 Software Plc',
    region: 'GB',
  },
  {
    symbol: '0MVJ-LN',
    name: 'Tesmec S.p.a.',
    region: 'GB',
  },
  {
    symbol: '0MVK-LN',
    name: 'Augros',
    region: 'GB',
  },
  {
    symbol: '0MVM-LN',
    name: 'SII',
    region: 'GB',
  },
  {
    symbol: '0MVY-LN',
    name: 'SFC Energy AG',
    region: 'GB',
  },
  {
    symbol: '0MW2-LN',
    name: 'Stockmann Oyj Abp - Class B',
    region: 'GB',
  },
  {
    symbol: '0MW7-LN',
    name: 'Legrand SA',
    region: 'GB',
  },
  {
    symbol: '0MWK-LN',
    name: 'Lindab International AB',
    region: 'GB',
  },
  {
    symbol: '0MYY-LN',
    name: 'Powszechny Zaklad Ubezpieczen SA',
    region: 'GB',
  },
  {
    symbol: '0MYZ-LN',
    name: 'Stroer SE & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0MZL-LN',
    name: 'Slovenske Energeticke Strojarne a.s.',
    region: 'GB',
  },
  {
    symbol: '0MZX-LN',
    name: 'Vienna Insurance Group',
    region: 'GB',
  },
  {
    symbol: '0N01-LN',
    name: 'SpareBank 1 Ringerike Hadeland',
    region: 'GB',
  },
  {
    symbol: '0N08-LN',
    name: 'Panoro Energy ASA',
    region: 'GB',
  },
  {
    symbol: '0N0A-LN',
    name: 'Saga Pure ASA',
    region: 'GB',
  },
  {
    symbol: '0N0B-LN',
    name: 'Wallenius Wilhelmsen ASA',
    region: 'GB',
  },
  {
    symbol: '0N0X-LN',
    name: 'Sotkamo Silver AB',
    region: 'GB',
  },
  {
    symbol: '0N16-LN',
    name: 'Aroma AD',
    region: 'GB',
  },
  {
    symbol: '0N21-LN',
    name: 'Nama Trgovsko Podjetje D.D.',
    region: 'GB',
  },
  {
    symbol: '0N22-LN',
    name: 'Himsnab Bulgaria AD',
    region: 'GB',
  },
  {
    symbol: '0N2B-LN',
    name: 'MS Industrie AG',
    region: 'GB',
  },
  {
    symbol: '0N2H-LN',
    name: 'Logistea AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0N2K-LN',
    name: 'Maternus-Kliniken AG',
    region: 'GB',
  },
  {
    symbol: '0N2Q-LN',
    name: 'Bijou Brigitte Mod. Access. AG',
    region: 'GB',
  },
  {
    symbol: '0N2X-LN',
    name: 'Tauron Polska Energia SA - Series AA',
    region: 'GB',
  },
  {
    symbol: '0N2Z-LN',
    name: 'Vossloh AG',
    region: 'GB',
  },
  {
    symbol: '0N4A-LN',
    name: 'Vivesto AB',
    region: 'GB',
  },
  {
    symbol: '0N4I-LN',
    name: 'Danieli & C - Officine Meccaniche Spa - PRF PERPETUAL EUR 1',
    region: 'GB',
  },
  {
    symbol: '0N4J-LN',
    name: 'Edison Spa - PRF PERPETUAL EUR 1',
    region: 'GB',
  },
  {
    symbol: '0N4T-LN',
    name: 'Nordea Bank Abp',
    region: 'GB',
  },
  {
    symbol: '0N4Y-LN',
    name: 'Colruyt',
    region: 'GB',
  },
  {
    symbol: '0N54-LN',
    name: 'A2A Spa',
    region: 'GB',
  },
  {
    symbol: '0N59-LN',
    name: 'Ahlers AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0N5A-LN',
    name: 'AAP Implantate AG',
    region: 'GB',
  },
  {
    symbol: '0N5H-LN',
    name: 'Adler Real Estate AG',
    region: 'GB',
  },
  {
    symbol: '0N5I-LN',
    name: 'Adesso AG',
    region: 'GB',
  },
  {
    symbol: '0N5X-LN',
    name: 'Dekuple',
    region: 'GB',
  },
  {
    symbol: '0N60-LN',
    name: 'Alpha M.O.S.',
    region: 'GB',
  },
  {
    symbol: '0N61-LN',
    name: 'Amplifon',
    region: 'GB',
  },
  {
    symbol: '0N66-LN',
    name: 'Atoss Software AG',
    region: 'GB',
  },
  {
    symbol: '0N6B-LN',
    name: 'Arcadis NV',
    region: 'GB',
  },
  {
    symbol: '0N6K-LN',
    name: 'Claranova',
    region: 'GB',
  },
  {
    symbol: '0N6O-LN',
    name: 'Basic Net Spa',
    region: 'GB',
  },
  {
    symbol: '0N6Q-LN',
    name: 'Beghelli Spa',
    region: 'GB',
  },
  {
    symbol: '0N6Z-LN',
    name: 'Biotest AG',
    region: 'GB',
  },
  {
    symbol: '0N70-LN',
    name: 'Biotest AG - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0N75-LN',
    name: 'Bonduelle',
    region: 'GB',
  },
  {
    symbol: '0N78-LN',
    name: 'EYEMAXX Real Estate AG.',
    region: 'GB',
  },
  {
    symbol: '0N7D-LN',
    name: 'Bure Equity AB',
    region: 'GB',
  },
  {
    symbol: '0N7I-LN',
    name: 'Cairo Communication Spa',
    region: 'GB',
  },
  {
    symbol: '0N7N-LN',
    name: 'Compagnie Des Alpes',
    region: 'GB',
  },
  {
    symbol: '0N7X-LN',
    name: 'Cloetta AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0N8F-LN',
    name: 'CEWE Stiftung & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0N8R-LN',
    name: 'Digital Bros Spa',
    region: 'GB',
  },
  {
    symbol: '0N8T-LN',
    name: 'Dierig Holding AG',
    region: 'GB',
  },
  {
    symbol: '0N99-LN',
    name: 'Ludwig Beck AG',
    region: 'GB',
  },
  {
    symbol: '0N9A-LN',
    name: 'Edding AG - ZC PRF PERPETUAL EUR 28.85',
    region: 'GB',
  },
  {
    symbol: '0N9F-LN',
    name: 'Einhell Germany AG - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0N9G-LN',
    name: 'Endesa S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0N9K-LN',
    name: 'Elmos Semiconductor SE',
    region: 'GB',
  },
  {
    symbol: '0N9O-LN',
    name: 'Emak Spa',
    region: 'GB',
  },
  {
    symbol: '0N9P-LN',
    name: 'Group SFPI',
    region: 'GB',
  },
  {
    symbol: '0N9S-LN',
    name: 'Eni Spa',
    region: 'GB',
  },
  {
    symbol: '0N9V-LN',
    name: 'ESSO',
    region: 'GB',
  },
  {
    symbol: '0N9W-LN',
    name: 'Netgem',
    region: 'GB',
  },
  {
    symbol: '0N9Z-LN',
    name: 'EVS Broadcast Equipment',
    region: 'GB',
  },
  {
    symbol: '0NA0-LN',
    name: 'Exel Industries',
    region: 'GB',
  },
  {
    symbol: '0NAC-LN',
    name: 'Francotyp-Postalia Holding AG',
    region: 'GB',
  },
  {
    symbol: '0NAF-LN',
    name: 'Fullsix Spa',
    region: 'GB',
  },
  {
    symbol: '0NAU-LN',
    name: 'GK Software SE - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0NB0-LN',
    name: 'Perrier (Gerard)',
    region: 'GB',
  },
  {
    symbol: '0NB2-LN',
    name: 'Guillemot Corp.',
    region: 'GB',
  },
  {
    symbol: '0NBD-LN',
    name: 'Heineken Holdings',
    region: 'GB',
  },
  {
    symbol: '0NBI-LN',
    name: 'Hamburger Hafen Und Logistik AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0NBN-LN',
    name: 'Holmen AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0NBS-LN',
    name: 'Infas Holding AG',
    region: 'GB',
  },
  {
    symbol: '0NBX-LN',
    name: 'Banca I.F.I.S Spa',
    region: 'GB',
  },
  {
    symbol: '0NC0-LN',
    name: 'Immobel - Compagnie Immobiliere de Belgique',
    region: 'GB',
  },
  {
    symbol: '0NC5-LN',
    name: 'Investor AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0NC6-LN',
    name: 'Investor AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0NCA-LN',
    name: 'IVU Traffic Technologies AG',
    region: 'GB',
  },
  {
    symbol: '0NCF-LN',
    name: 'Keyrus Progiware',
    region: 'GB',
  },
  {
    symbol: '0NCV-LN',
    name: 'Lenzing AG',
    region: 'GB',
  },
  {
    symbol: '0NCY-LN',
    name: 'Lamda Development S.A.',
    region: 'GB',
  },
  {
    symbol: '0ND2-LN',
    name: 'LPKF Laser & Electronics AG',
    region: 'GB',
  },
  {
    symbol: '0ND3-LN',
    name: 'Landi Renzo S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0ND5-LN',
    name: 'H. Lundbeck AS',
    region: 'GB',
  },
  {
    symbol: '0ND9-LN',
    name: 'Marna Beteiligungen AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0NDA-LN',
    name: 'Manitou BF',
    region: 'GB',
  },
  {
    symbol: '0NDG-LN',
    name: 'Mediclin',
    region: 'GB',
  },
  {
    symbol: '0NDP-LN',
    name: 'MLP SE',
    region: 'GB',
  },
  {
    symbol: '0NDT-LN',
    name: 'Mainova AG',
    region: 'GB',
  },
  {
    symbol: '0NDV-LN',
    name: 'Morphosys AG',
    region: 'GB',
  },
  {
    symbol: '0NE2-LN',
    name: 'Mondo TV Spa',
    region: 'GB',
  },
  {
    symbol: '0NEB-LN',
    name: 'Neways Electronics International NV',
    region: 'GB',
  },
  {
    symbol: '0NEL-LN',
    name: 'BASSAC',
    region: 'GB',
  },
  {
    symbol: '0NER-LN',
    name: 'Oriola Corporation - Class A',
    region: 'GB',
  },
  {
    symbol: '0NES-LN',
    name: 'Oriola Corporation - Class B',
    region: 'GB',
  },
  {
    symbol: '0NEX-LN',
    name: 'Orpea',
    region: 'GB',
  },
  {
    symbol: '0NEY-LN',
    name: 'USU Software AG',
    region: 'GB',
  },
  {
    symbol: '0NEZ-LN',
    name: 'EAC Invest AS',
    region: 'GB',
  },
  {
    symbol: '0NF3-LN',
    name: 'Paion AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0NF7-LN',
    name: 'PCAS',
    region: 'GB',
  },
  {
    symbol: '0NFG-LN',
    name: 'Paragon GmbH & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0NFP-LN',
    name: 'Prima Industrie',
    region: 'GB',
  },
  {
    symbol: '0NFS-LN',
    name: 'Esprinet',
    region: 'GB',
  },
  {
    symbol: '0NFX-LN',
    name: 'Pulsion Medical Systems SE',
    region: 'GB',
  },
  {
    symbol: '0NG6-LN',
    name: 'Atenor',
    region: 'GB',
  },
  {
    symbol: '0NGV-LN',
    name: 'Intershop Communications AG',
    region: 'GB',
  },
  {
    symbol: '0NH5-LN',
    name: 'Momina Krepost AD',
    region: 'GB',
  },
  {
    symbol: '0NH6-LN',
    name: 'Monbat AD',
    region: 'GB',
  },
  {
    symbol: '0NHS-LN',
    name: 'Nutrien Ltd',
    region: 'GB',
  },
  {
    symbol: '0NHV-LN',
    name: 'Recticel',
    region: 'GB',
  },
  {
    symbol: '0NI1-LN',
    name: 'Rheinmetall AG',
    region: 'GB',
  },
  {
    symbol: '0NIB-LN',
    name: 'Realtech AG',
    region: 'GB',
  },
  {
    symbol: '0NIF-LN',
    name: 'Sma Solar Technology',
    region: 'GB',
  },
  {
    symbol: '0NIG-LN',
    name: 'Sabaf Spa',
    region: 'GB',
  },
  {
    symbol: '0NII-LN',
    name: 'Ambienthesis S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0NIJ-LN',
    name: 'Saes Getters Spa',
    region: 'GB',
  },
  {
    symbol: '0NIK-LN',
    name: 'Saes Getters Spa - PRF PERPETUAL EUR 0.52',
    region: 'GB',
  },
  {
    symbol: '0NIQ-LN',
    name: 'Sartorius AG',
    region: 'GB',
  },
  {
    symbol: '0NIR-LN',
    name: 'Sartorius AG - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0NIS-LN',
    name: 'SBM Offshore N.V',
    region: 'GB',
  },
  {
    symbol: '0NIY-LN',
    name: 'Schumag AG',
    region: 'GB',
  },
  {
    symbol: '0NJ3-LN',
    name: 'Servizi Italia Spa',
    region: 'GB',
  },
  {
    symbol: '0NJ5-LN',
    name: 'Safilo Group S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0NJB-LN',
    name: 'SNP Schneider-Neureither & Partner SE',
    region: 'GB',
  },
  {
    symbol: '0NJD-LN',
    name: 'Simona AG',
    region: 'GB',
  },
  {
    symbol: '0NJP-LN',
    name: 'Sol Spa',
    region: 'GB',
  },
  {
    symbol: '0NJQ-LN',
    name: 'Sopra Steria Group',
    region: 'GB',
  },
  {
    symbol: '0NJT-LN',
    name: 'Spobag',
    region: 'GB',
  },
  {
    symbol: '0NKH-LN',
    name: 'Syzygy AG',
    region: 'GB',
  },
  {
    symbol: '0NKL-LN',
    name: 'Telekom Austria AG',
    region: 'GB',
  },
  {
    symbol: '0NKY-LN',
    name: 'Teles AG',
    region: 'GB',
  },
  {
    symbol: '0NL1-LN',
    name: 'PVA Tepla AG',
    region: 'GB',
  },
  {
    symbol: '0NL3-LN',
    name: 'Trelleborg AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0NL8-LN',
    name: 'Innotec TSS AG',
    region: 'GB',
  },
  {
    symbol: '0NLD-LN',
    name: 'TXT E-Solutions Spa',
    region: 'GB',
  },
  {
    symbol: '0NLT-LN',
    name: 'Uzin Utz AG',
    region: 'GB',
  },
  {
    symbol: '0NLV-LN',
    name: 'Vita 34 AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0NLY-LN',
    name: 'Verbio Vereinigte Bioenergie AG',
    region: 'GB',
  },
  {
    symbol: '0NM4-LN',
    name: 'Vereinigte Filzfabriken AG',
    region: 'GB',
  },
  {
    symbol: '0NM7-LN',
    name: 'Virbac SA',
    region: 'GB',
  },
  {
    symbol: '0NMK-LN',
    name: 'Vestas Wind Systems AS',
    region: 'GB',
  },
  {
    symbol: '0NMT-LN',
    name: 'Sporttotal AG',
    region: 'GB',
  },
  {
    symbol: '0NMU-LN',
    name: 'Wolters Kluwers NV',
    region: 'GB',
  },
  {
    symbol: '0NMX-LN',
    name: 'Westag AG - PRF PERPETUAL EUR 1',
    region: 'GB',
  },
  {
    symbol: '0NN1-LN',
    name: 'Exprivia Spa',
    region: 'GB',
  },
  {
    symbol: '0NNC-LN',
    name: 'Zignago Vetro',
    region: 'GB',
  },
  {
    symbol: '0NNF-LN',
    name: 'Alfa Laval AB',
    region: 'GB',
  },
  {
    symbol: '0NNP-LN',
    name: 'Luka Koper Prekladanje Skladiseenje Indruge Pristaniske Storitve D.D.',
    region: 'GB',
  },
  {
    symbol: '0NNR-LN',
    name: 'Lundin Energy AB',
    region: 'GB',
  },
  {
    symbol: '0NNU-LN',
    name: 'Nedap (Nederlandsche Apparatenf) NV',
    region: 'GB',
  },
  {
    symbol: '0NO0-LN',
    name: 'Storebrand ASA',
    region: 'GB',
  },
  {
    symbol: '0NO1-LN',
    name: 'TKH Group NV',
    region: 'GB',
  },
  {
    symbol: '0NO6-LN',
    name: 'Amper SA - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0NOA-LN',
    name: 'Cyprus Trading Corporation Plc',
    region: 'GB',
  },
  {
    symbol: '0NOF-LN',
    name: 'Procter & Gamble Co.',
    region: 'GB',
  },
  {
    symbol: '0NOL-LN',
    name: 'ADVA Optical Networking SE',
    region: 'GB',
  },
  {
    symbol: '0NP8-LN',
    name: 'Aeroports de Paris SA',
    region: 'GB',
  },
  {
    symbol: '0NP9-LN',
    name: 'Aixtron SE',
    region: 'GB',
  },
  {
    symbol: '0NPF-LN',
    name: 'Enapter AG',
    region: 'GB',
  },
  {
    symbol: '0NPH-LN',
    name: 'Carrefour',
    region: 'GB',
  },
  {
    symbol: '0NPJ-LN',
    name: 'Columbus AS',
    region: 'GB',
  },
  {
    symbol: '0NPL-LN',
    name: 'Christian Dior SE',
    region: 'GB',
  },
  {
    symbol: '0NPP-LN',
    name: 'Koninklijke DSM N.V. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0NPT-LN',
    name: 'Eiffage',
    region: 'GB',
  },
  {
    symbol: '0NPV-LN',
    name: 'Naturgy Energy Group S.A.',
    region: 'GB',
  },
  {
    symbol: '0NPX-LN',
    name: 'Imerys',
    region: 'GB',
  },
  {
    symbol: '0NQ0-LN',
    name: 'U10 Corp',
    region: 'GB',
  },
  {
    symbol: '0NQ2-LN',
    name: 'Mapfre Sociedad Anonima',
    region: 'GB',
  },
  {
    symbol: '0NQ3-LN',
    name: 'MR Bricolage SA',
    region: 'GB',
  },
  {
    symbol: '0NQ5-LN',
    name: 'QUADIENT S.A',
    region: 'GB',
  },
  {
    symbol: '0NQ7-LN',
    name: 'Novotek AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0NQA-LN',
    name: 'Elanix Biotechnologies AG',
    region: 'GB',
  },
  {
    symbol: '0NQC-LN',
    name: 'Pandora A/S',
    region: 'GB',
  },
  {
    symbol: '0NQE-LN',
    name: 'Puma SE',
    region: 'GB',
  },
  {
    symbol: '0NQF-LN',
    name: 'Renault S.A.',
    region: 'GB',
  },
  {
    symbol: '0NQG-LN',
    name: 'Repsol S.A',
    region: 'GB',
  },
  {
    symbol: '0NQH-LN',
    name: 'Rhoen-Klinikum AG',
    region: 'GB',
  },
  {
    symbol: '0NQJ-LN',
    name: 'RTX A/S',
    region: 'GB',
  },
  {
    symbol: '0NQK-LN',
    name: 'Sievi Capital plc',
    region: 'GB',
  },
  {
    symbol: '0NQM-LN',
    name: 'Vinci',
    region: 'GB',
  },
  {
    symbol: '0NQP-LN',
    name: 'Snam S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0NQT-LN',
    name: 'Television Francaise (T.F.1)',
    region: 'GB',
  },
  {
    symbol: '0NR0-LN',
    name: 'TTLBeteiligungs- und Grundbesitz-AG',
    region: 'GB',
  },
  {
    symbol: '0NR1-LN',
    name: 'Verbund AG - Class A',
    region: 'GB',
  },
  {
    symbol: '0NR2-LN',
    name: 'Vallourec',
    region: 'GB',
  },
  {
    symbol: '0NR4-LN',
    name: 'Wacker Chemie AG',
    region: 'GB',
  },
  {
    symbol: '0NR7-LN',
    name: 'Zardoya Otis S.A.',
    region: 'GB',
  },
  {
    symbol: '0NRE-LN',
    name: 'Enel Spa',
    region: 'GB',
  },
  {
    symbol: '0NRG-LN',
    name: 'Bilfinger SE',
    region: 'GB',
  },
  {
    symbol: '0NRN-LN',
    name: 'Catalana Occidente',
    region: 'GB',
  },
  {
    symbol: '0NS1-LN',
    name: 'Grupa Azoty S.A.',
    region: 'GB',
  },
  {
    symbol: '0NSS-LN',
    name: 'Marr',
    region: 'GB',
  },
  {
    symbol: '0NST-LN',
    name: 'Payton Planar',
    region: 'GB',
  },
  {
    symbol: '0NT5-LN',
    name: 'Fourlis Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0NTI-LN',
    name: 'Gerresheimer AG',
    region: 'GB',
  },
  {
    symbol: '0NTM-LN',
    name: 'Oesterreichische Post AG',
    region: 'GB',
  },
  {
    symbol: '0NTU-LN',
    name: 'Elia Group',
    region: 'GB',
  },
  {
    symbol: '0NTX-LN',
    name: 'Proactis SA',
    region: 'GB',
  },
  {
    symbol: '0NUG-LN',
    name: 'Magyar Telekom Telecommunications',
    region: 'GB',
  },
  {
    symbol: '0NUI-LN',
    name: 'Abengoa - Class A',
    region: 'GB',
  },
  {
    symbol: '0NUQ-LN',
    name: 'LifeStar Holding plc',
    region: 'GB',
  },
  {
    symbol: '0NUX-LN',
    name: 'Prysmian S.p.A',
    region: 'GB',
  },
  {
    symbol: '0NV0-LN',
    name: 'Sogefi Spa',
    region: 'GB',
  },
  {
    symbol: '0NV3-LN',
    name: 'Accentis S.A.',
    region: 'GB',
  },
  {
    symbol: '0NV4-LN',
    name: 'Tod`s Spa',
    region: 'GB',
  },
  {
    symbol: '0NV5-LN',
    name: 'UPM-Kymmene Oyj',
    region: 'GB',
  },
  {
    symbol: '0NV7-LN',
    name: 'Vidrala S.A.',
    region: 'GB',
  },
  {
    symbol: '0NVC-LN',
    name: 'Danske Bank AS',
    region: 'GB',
  },
  {
    symbol: '0NVL-LN',
    name: 'UBISoft Entertainment',
    region: 'GB',
  },
  {
    symbol: '0NVQ-LN',
    name: 'Buzzi Unicem Spa',
    region: 'GB',
  },
  {
    symbol: '0NVT-LN',
    name: 'Cofina - S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0NVV-LN',
    name: 'Hera Spa',
    region: 'GB',
  },
  {
    symbol: '0NW0-LN',
    name: 'Sociedade Comercial Orey Antunes, S.A.',
    region: 'GB',
  },
  {
    symbol: '0NW1-LN',
    name: 'Plastic Omnium',
    region: 'GB',
  },
  {
    symbol: '0NW2-LN',
    name: 'Randstad NV',
    region: 'GB',
  },
  {
    symbol: '0NW4-LN',
    name: 'Sap SE',
    region: 'GB',
  },
  {
    symbol: '0NW7-LN',
    name: 'Sixt SE',
    region: 'GB',
  },
  {
    symbol: '0NW8-LN',
    name: 'Sixt SE - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0NWC-LN',
    name: 'Secunet Security Networks AG',
    region: 'GB',
  },
  {
    symbol: '0NWE-LN',
    name: 'Agros Development Proodos Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0NWF-LN',
    name: 'Air Liquide S.A',
    region: 'GB',
  },
  {
    symbol: '0NWJ-LN',
    name: 'Banca Popolare Di Sondrio Scarl',
    region: 'GB',
  },
  {
    symbol: '0NWK-LN',
    name: 'Onxeo SA',
    region: 'GB',
  },
  {
    symbol: '0NWV-LN',
    name: 'Schneider Electric SE',
    region: 'GB',
  },
  {
    symbol: '0NWW-LN',
    name: 'SKF AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0NWX-LN',
    name: 'SKF AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0NX0-LN',
    name: 'Trigano',
    region: 'GB',
  },
  {
    symbol: '0NX1-LN',
    name: 'Aalberts NV',
    region: 'GB',
  },
  {
    symbol: '0NX2-LN',
    name: 'ABB Ltd.',
    region: 'GB',
  },
  {
    symbol: '0NX3-LN',
    name: 'ASM International NV',
    region: 'GB',
  },
  {
    symbol: '0NXA-LN',
    name: 'Floridienne',
    region: 'GB',
  },
  {
    symbol: '0NXR-LN',
    name: 'Raiffeisen Bank International AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0NXT-LN',
    name: '11 88 0 Solutions AG',
    region: 'GB',
  },
  {
    symbol: '0NXV-LN',
    name: 'Falck Renewables S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0NXX-LN',
    name: 'Mercedes-Benz Group AG',
    region: 'GB',
  },
  {
    symbol: '0NY3-LN',
    name: 'Stef',
    region: 'GB',
  },
  {
    symbol: '0NY4-LN',
    name: 'Tallink Grupp A.S.',
    region: 'GB',
  },
  {
    symbol: '0NY7-LN',
    name: 'SpareBank 1 Sorost-Norge',
    region: 'GB',
  },
  {
    symbol: '0NY8-LN',
    name: 'Veolia Environnement',
    region: 'GB',
  },
  {
    symbol: '0NYH-LN',
    name: 'Ebro Foods S.A',
    region: 'GB',
  },
  {
    symbol: '0NYL-LN',
    name: 'K. Kythreotis Holdings Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0NYM-LN',
    name: 'Lombard Bank Malta Plc',
    region: 'GB',
  },
  {
    symbol: '0NYT-LN',
    name: 'Memscap',
    region: 'GB',
  },
  {
    symbol: '0NYZ-LN',
    name: 'Bertrandt AG',
    region: 'GB',
  },
  {
    symbol: '0NZ1-LN',
    name: 'Colas',
    region: 'GB',
  },
  {
    symbol: '0NZ4-LN',
    name: 'Hydraulic Elements & Systems AD-Yambol',
    region: 'GB',
  },
  {
    symbol: '0NZ7-LN',
    name: 'Mayr-Melnhof Karton AG',
    region: 'GB',
  },
  {
    symbol: '0NZF-LN',
    name: 'Cez, A.S.',
    region: 'GB',
  },
  {
    symbol: '0NZM-LN',
    name: 'L`Oreal',
    region: 'GB',
  },
  {
    symbol: '0NZN-LN',
    name: 'Robertet S.A.',
    region: 'GB',
  },
  {
    symbol: '0NZR-LN',
    name: 'Solvay SA',
    region: 'GB',
  },
  {
    symbol: '0NZS-LN',
    name: 'Moury Construct',
    region: 'GB',
  },
  {
    symbol: '0NZT-LN',
    name: 'UCB SA',
    region: 'GB',
  },
  {
    symbol: '0NZW-LN',
    name: 'Aurea',
    region: 'GB',
  },
  {
    symbol: '0NZY-LN',
    name: 'Eckert & Ziegler Strahlen- und Medizintechnik AG',
    region: 'GB',
  },
  {
    symbol: '0O05-LN',
    name: 'Schoeller-Bleckmann Oilfield Equipment AG',
    region: 'GB',
  },
  {
    symbol: '0O0A-LN',
    name: 'Aktienbrauerei Kaufbeuren AG',
    region: 'GB',
  },
  {
    symbol: '0O0E-LN',
    name: 'Bigben Interactive',
    region: 'GB',
  },
  {
    symbol: '0O0F-LN',
    name: 'Cancom SE',
    region: 'GB',
  },
  {
    symbol: '0O0J-LN',
    name: 'Leoni AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0O0N-LN',
    name: 'Schouw & Co. - Class B',
    region: 'GB',
  },
  {
    symbol: '0O0U-LN',
    name: 'Bayerische Motoren Werke AG',
    region: 'GB',
  },
  {
    symbol: '0O0V-LN',
    name: 'Bayerische Motoren Werke AG - PRF PERPETUAL EUR 1',
    region: 'GB',
  },
  {
    symbol: '0O14-LN',
    name: 'Merck KGAA',
    region: 'GB',
  },
  {
    symbol: '0O1C-LN',
    name: 'thyssenkrupp AG',
    region: 'GB',
  },
  {
    symbol: '0O1O-LN',
    name: 'Vetoquinol',
    region: 'GB',
  },
  {
    symbol: '0O1R-LN',
    name: 'Fraport AG',
    region: 'GB',
  },
  {
    symbol: '0O1S-LN',
    name: 'Alten',
    region: 'GB',
  },
  {
    symbol: '0O1W-LN',
    name: 'SAS AB',
    region: 'GB',
  },
  {
    symbol: '0O26-LN',
    name: 'Heineken N.V',
    region: 'GB',
  },
  {
    symbol: '0O27-LN',
    name: 'DR Hoenle AG',
    region: 'GB',
  },
  {
    symbol: '0O2B-LN',
    name: 'Gruppo Mutuionline SPA',
    region: 'GB',
  },
  {
    symbol: '0O2D-LN',
    name: 'Saras Raffinerie Sarde SPA',
    region: 'GB',
  },
  {
    symbol: '0O2I-LN',
    name: 'Acsm-Agam S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0O2M-LN',
    name: 'Alandsbanken ABP - Class A',
    region: 'GB',
  },
  {
    symbol: '0O2N-LN',
    name: 'Alandsbanken ABP - Class B',
    region: 'GB',
  },
  {
    symbol: '0O2R-LN',
    name: 'Credit Agricole Ile de France',
    region: 'GB',
  },
  {
    symbol: '0O2S-LN',
    name: 'Stara Palnina hold AD-Sofia',
    region: 'GB',
  },
  {
    symbol: '0O2T-LN',
    name: 'CFE Cie d`Entreprises',
    region: 'GB',
  },
  {
    symbol: '0O2W-LN',
    name: 'GFT Technologies SE',
    region: 'GB',
  },
  {
    symbol: '0O2X-LN',
    name: 'Immsi Spa',
    region: 'GB',
  },
  {
    symbol: '0O2Z-LN',
    name: 'Muehlbauer Holding AG',
    region: 'GB',
  },
  {
    symbol: '0O33-LN',
    name: 'Oeneo',
    region: 'GB',
  },
  {
    symbol: '0O3X-LN',
    name: 'Intertainment AG',
    region: 'GB',
  },
  {
    symbol: '0O44-LN',
    name: 'Lentex S. A',
    region: 'GB',
  },
  {
    symbol: '0O45-LN',
    name: 'LS Telcom AG',
    region: 'GB',
  },
  {
    symbol: '0O46-LN',
    name: 'Neste Corporation',
    region: 'GB',
  },
  {
    symbol: '0O4B-LN',
    name: 'Van Lanschot Kempen N.V - Class A',
    region: 'GB',
  },
  {
    symbol: '0O4H-LN',
    name: 'Met.Extra Group',
    region: 'GB',
  },
  {
    symbol: '0O4N-LN',
    name: 'SAF Holland SA',
    region: 'GB',
  },
  {
    symbol: '0O59-LN',
    name: 'Sanofi',
    region: 'GB',
  },
  {
    symbol: '0O5A-LN',
    name: 'Vivanco Gruppe AG',
    region: 'GB',
  },
  {
    symbol: '0O5C-LN',
    name: 'AT & S Austria Technologie & Systemtechnik Aktiengesellschaft',
    region: 'GB',
  },
  {
    symbol: '0O5H-LN',
    name: 'Elekta AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0O5V-LN',
    name: 'Banco Di Desio E Della Brianza Spa',
    region: 'GB',
  },
  {
    symbol: '0O66-LN',
    name: 'Cibox Interactive',
    region: 'GB',
  },
  {
    symbol: '0O69-LN',
    name: 'Acteos',
    region: 'GB',
  },
  {
    symbol: '0O6D-LN',
    name: 'Miquel Y Costas & Miquel S.A.',
    region: 'GB',
  },
  {
    symbol: '0O6Z-LN',
    name: 'Nelly Group AB',
    region: 'GB',
  },
  {
    symbol: '0O76-LN',
    name: 'A.P. Moller - Maersk AS - Class A',
    region: 'GB',
  },
  {
    symbol: '0O77-LN',
    name: 'A.P. Moller - Maersk AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0O79-LN',
    name: 'Metsa Board Corporation - Class A',
    region: 'GB',
  },
  {
    symbol: '0O7A-LN',
    name: 'Metsa Board Corporation - Class B',
    region: 'GB',
  },
  {
    symbol: '0O7C-LN',
    name: 'Sanok Rubber Company SA',
    region: 'GB',
  },
  {
    symbol: '0O7D-LN',
    name: 'Yara International ASA.',
    region: 'GB',
  },
  {
    symbol: '0O7J-LN',
    name: 'Corticeira Amorim - S.G.P.S., S.A.',
    region: 'GB',
  },
  {
    symbol: '0O7K-LN',
    name: 'Lena Lighting S.A',
    region: 'GB',
  },
  {
    symbol: '0O7T-LN',
    name: 'Gaming Innovation Group Inc.',
    region: 'GB',
  },
  {
    symbol: '0O7Z-LN',
    name: 'Atlantic Insurance Co Public Ltd',
    region: 'GB',
  },
  {
    symbol: '0O80-LN',
    name: 'Bains de Mer Monaco',
    region: 'GB',
  },
  {
    symbol: '0O82-LN',
    name: 'Bodegas Riojanas',
    region: 'GB',
  },
  {
    symbol: '0O84-LN',
    name: 'DNB Bank ASA',
    region: 'GB',
  },
  {
    symbol: '0O85-LN',
    name: 'Drozapol - Profil SA',
    region: 'GB',
  },
  {
    symbol: '0O86-LN',
    name: 'Telefonaktiebolaget L M Ericsson - Class A',
    region: 'GB',
  },
  {
    symbol: '0O87-LN',
    name: 'Telefonaktiebolaget L M Ericsson - Class B',
    region: 'GB',
  },
  {
    symbol: '0O8D-LN',
    name: 'KGHM Polska Miedz S.A. - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0O8F-LN',
    name: 'Koninklijke KPN NV',
    region: 'GB',
  },
  {
    symbol: '0O8G-LN',
    name: 'MDxHealth SA',
    region: 'GB',
  },
  {
    symbol: '0O8M-LN',
    name: 'Petrolina (Holdings) Public Ltd. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0O8O-LN',
    name: 'Regnon S.A.',
    region: 'GB',
  },
  {
    symbol: '0O8V-LN',
    name: 'Koninklijke Vopak',
    region: 'GB',
  },
  {
    symbol: '0O8X-LN',
    name: 'Wirecard AG',
    region: 'GB',
  },
  {
    symbol: '0O93-LN',
    name: 'Haldex AB',
    region: 'GB',
  },
  {
    symbol: '0O9B-LN',
    name: 'Almirall SA',
    region: 'GB',
  },
  {
    symbol: '0O9E-LN',
    name: 'Fabryki Mebli Forte S.A.',
    region: 'GB',
  },
  {
    symbol: '0O9N-LN',
    name: 'PunaMusta Media Oyj - Class A',
    region: 'GB',
  },
  {
    symbol: '0O9P-LN',
    name: 'Semcon AB',
    region: 'GB',
  },
  {
    symbol: '0O9W-LN',
    name: 'Alkis H.Hadjikyriacos (Frou Frou Biscuits) Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0O9Y-LN',
    name: 'High Co',
    region: 'GB',
  },
  {
    symbol: '0OA2-LN',
    name: 'Qpr Software Plc',
    region: 'GB',
  },
  {
    symbol: '0OA4-LN',
    name: 'SES-imagotag',
    region: 'GB',
  },
  {
    symbol: '0OA7-LN',
    name: 'Assystem.',
    region: 'GB',
  },
  {
    symbol: '0OA9-LN',
    name: 'Nolato AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0OAL-LN',
    name: 'American International Group Inc',
    region: 'GB',
  },
  {
    symbol: '0OAU-LN',
    name: 'Miraculum S.A.',
    region: 'GB',
  },
  {
    symbol: '0OAW-LN',
    name: 'Mowi ASA',
    region: 'GB',
  },
  {
    symbol: '0OB3-LN',
    name: 'Valneva SE',
    region: 'GB',
  },
  {
    symbol: '0OC2-LN',
    name: 'Heidelberger Druckmaschinen AG',
    region: 'GB',
  },
  {
    symbol: '0OC5-LN',
    name: 'Innofactor Plc',
    region: 'GB',
  },
  {
    symbol: '0OCC-LN',
    name: 'Lordos Hotels (Holdings) Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0OCD-LN',
    name: 'Medistim ASA',
    region: 'GB',
  },
  {
    symbol: '0OCQ-LN',
    name: 'Transgene S.A.',
    region: 'GB',
  },
  {
    symbol: '0OD6-LN',
    name: 'Ramsay Generale De Sante',
    region: 'GB',
  },
  {
    symbol: '0OEG-LN',
    name: 'Primetech S. A.',
    region: 'GB',
  },
  {
    symbol: '0OEQ-LN',
    name: 'Swissmed Centrum Zdrowia SA',
    region: 'GB',
  },
  {
    symbol: '0OET-LN',
    name: 'A. Tsokkos Hotels Public Ltd.',
    region: 'GB',
  },
  {
    symbol: '0OEY-LN',
    name: 'D Amico International Shipping',
    region: 'GB',
  },
  {
    symbol: '0OF2-LN',
    name: 'BankNordik P/F',
    region: 'GB',
  },
  {
    symbol: '0OF7-LN',
    name: 'EDP-Energias DE Portugal S.A.',
    region: 'GB',
  },
  {
    symbol: '0OF9-LN',
    name: 'Atlantic Petroleum',
    region: 'GB',
  },
  {
    symbol: '0OFC-LN',
    name: 'Folli Follie Commercial Manufacturing and Technical Societe Anonyme',
    region: 'GB',
  },
  {
    symbol: '0OFL-LN',
    name: 'A.I.S. AG',
    region: 'GB',
  },
  {
    symbol: '0OFM-LN',
    name: 'Michelin (CGDE)-B - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0OFO-LN',
    name: 'NCC AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0OFP-LN',
    name: 'NCC AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0OFR-LN',
    name: 'Polish Oil & Gas',
    region: 'GB',
  },
  {
    symbol: '0OFU-LN',
    name: 'Sacyr S.A.',
    region: 'GB',
  },
  {
    symbol: '0OG5-LN',
    name: 'Burelle SA',
    region: 'GB',
  },
  {
    symbol: '0OG6-LN',
    name: 'Seche Environnement',
    region: 'GB',
  },
  {
    symbol: '0OGG-LN',
    name: 'Catana Group',
    region: 'GB',
  },
  {
    symbol: '0OGK-LN',
    name: 'Subsea 7 S.A.',
    region: 'GB',
  },
  {
    symbol: '0OH1-LN',
    name: 'Spadel - Societe de Services de Participations de',
    region: 'GB',
  },
  {
    symbol: '0OHC-LN',
    name: 'Groupe Gorge',
    region: 'GB',
  },
  {
    symbol: '0OHG-LN',
    name: 'NH Hotel Group S.A.',
    region: 'GB',
  },
  {
    symbol: '0OHK-LN',
    name: 'Stolt-Nielsen Limited',
    region: 'GB',
  },
  {
    symbol: '0OHW-LN',
    name: 'Tatra Banka A.S. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0OHY-LN',
    name: 'Aegean Airlines S.A.',
    region: 'GB',
  },
  {
    symbol: '0OI0-LN',
    name: 'Archos',
    region: 'GB',
  },
  {
    symbol: '0OI5-LN',
    name: 'Elton S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0OI8-LN',
    name: 'Famur S.A.',
    region: 'GB',
  },
  {
    symbol: '0OII-LN',
    name: 'Grupa Azoty Zaklady Azotowe Pulawy S.A.',
    region: 'GB',
  },
  {
    symbol: '0OIQ-LN',
    name: 'Acerinox - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0OIR-LN',
    name: 'ALK-Abello AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0OIU-LN',
    name: 'Arctic Paper S.A',
    region: 'GB',
  },
  {
    symbol: '0OIY-LN',
    name: 'Caltagirone spa',
    region: 'GB',
  },
  {
    symbol: '0OJ4-LN',
    name: 'Egide',
    region: 'GB',
  },
  {
    symbol: '0OJA-LN',
    name: 'F.L.Smidth & Co. AS',
    region: 'GB',
  },
  {
    symbol: '0OJC-LN',
    name: 'Gjensidige Forsikring ASA',
    region: 'GB',
  },
  {
    symbol: '0OJI-LN',
    name: 'M S Hidravlik AD',
    region: 'GB',
  },
  {
    symbol: '0OJJ-LN',
    name: 'Actia Group',
    region: 'GB',
  },
  {
    symbol: '0OJL-LN',
    name: 'Thessalonikiport Authority S.A.',
    region: 'GB',
  },
  {
    symbol: '0OJX-LN',
    name: 'Terna Energy S.A.',
    region: 'GB',
  },
  {
    symbol: '0OK7-LN',
    name: 'Akka Technologies',
    region: 'GB',
  },
  {
    symbol: '0OKP-LN',
    name: 'Socfinaf SA',
    region: 'GB',
  },
  {
    symbol: '0OKR-LN',
    name: 'Hellenic Exchanges -Athens Stock Exchange S.A.',
    region: 'GB',
  },
  {
    symbol: '0OL2-LN',
    name: 'Elhim Iskra AD-Pazardgik',
    region: 'GB',
  },
  {
    symbol: '0OL7-LN',
    name: 'I.Kloukinas-I.Lappas S.A. Construction & Commercial Co.',
    region: 'GB',
  },
  {
    symbol: '0OLD-LN',
    name: 'Adidas AG',
    region: 'GB',
  },
  {
    symbol: '0OLF-LN',
    name: 'Aperam SA',
    region: 'GB',
  },
  {
    symbol: '0OLG-LN',
    name: 'Bauer AG.',
    region: 'GB',
  },
  {
    symbol: '0OLX-LN',
    name: 'IVS Group - Class A',
    region: 'GB',
  },
  {
    symbol: '0OM7-LN',
    name: 'B&C Speakers S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0OMB-LN',
    name: 'Societa` Cattolica Di Assicurazione Scarl',
    region: 'GB',
  },
  {
    symbol: '0OMD-LN',
    name: 'Chimimport',
    region: 'GB',
  },
  {
    symbol: '0OMG-LN',
    name: 'Esperite N V',
    region: 'GB',
  },
  {
    symbol: '0OMK-LN',
    name: 'EssilorLuxottica',
    region: 'GB',
  },
  {
    symbol: '0OMO-LN',
    name: 'Iktinos Hellas S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0OMR-LN',
    name: 'Oil & Gaz Exploration & Production-Pleven',
    region: 'GB',
  },
  {
    symbol: '0OMU-LN',
    name: 'Snaige Pva',
    region: 'GB',
  },
  {
    symbol: '0ON3-LN',
    name: 'ECHOS Holding AG',
    region: 'GB',
  },
  {
    symbol: '0ON7-LN',
    name: 'D`ieteren Group',
    region: 'GB',
  },
  {
    symbol: '0ONG-LN',
    name: 'Leonardo S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0ONI-LN',
    name: 'UCM Resita',
    region: 'GB',
  },
  {
    symbol: '0ONR-LN',
    name: 'CIR S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0ONY-LN',
    name: 'Tecnotree Corporation',
    region: 'GB',
  },
  {
    symbol: '0OO3-LN',
    name: 'Albena AD',
    region: 'GB',
  },
  {
    symbol: '0OO9-LN',
    name: 'Fresenius SE & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0OOJ-LN',
    name: 'Ordina NV',
    region: 'GB',
  },
  {
    symbol: '0OOS-LN',
    name: 'Ad Pepper Media Intl Nv',
    region: 'GB',
  },
  {
    symbol: '0OP0-LN',
    name: 'DMG Mori AG',
    region: 'GB',
  },
  {
    symbol: '0OPA-LN',
    name: 'Villeroy & Boch - PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0OPB-LN',
    name: 'McKesson Europe AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0OPD-LN',
    name: 'Gen. Commercial & Ind.',
    region: 'GB',
  },
  {
    symbol: '0OPM-LN',
    name: 'Datalex Plc',
    region: 'GB',
  },
  {
    symbol: '0OPN-LN',
    name: 'Compagnie de l`Odet',
    region: 'GB',
  },
  {
    symbol: '0OPS-LN',
    name: 'Korian',
    region: 'GB',
  },
  {
    symbol: '0OPY-LN',
    name: 'Parrot',
    region: 'GB',
  },
  {
    symbol: '0OQ0-LN',
    name: 'Pierre & Vacances',
    region: 'GB',
  },
  {
    symbol: '0OQ2-LN',
    name: 'Sogeclair',
    region: 'GB',
  },
  {
    symbol: '0OQJ-LN',
    name: 'NV Bekaert SA.',
    region: 'GB',
  },
  {
    symbol: '0OQQ-LN',
    name: 'Infotel',
    region: 'GB',
  },
  {
    symbol: '0OQV-LN',
    name: 'Orange.',
    region: 'GB',
  },
  {
    symbol: '0OQX-LN',
    name: 'Grammer AG',
    region: 'GB',
  },
  {
    symbol: '0OQY-LN',
    name: 'LS INVEST AG',
    region: 'GB',
  },
  {
    symbol: '0OR2-LN',
    name: 'LNA Sante SA',
    region: 'GB',
  },
  {
    symbol: '0OR4-LN',
    name: 'New Work SE',
    region: 'GB',
  },
  {
    symbol: '0ORK-LN',
    name: 'Graines Voltz',
    region: 'GB',
  },
  {
    symbol: '0P07-LN',
    name: 'First Mining Gold Corp',
    region: 'GB',
  },
  {
    symbol: '0P0N-LN',
    name: 'Hydratec Industries NV',
    region: 'GB',
  },
  {
    symbol: '0P0O-LN',
    name: 'Wilh. Wilhelmsen Holding ASA - Class B',
    region: 'GB',
  },
  {
    symbol: '0P0T-LN',
    name: 'Wilh. Wilhelmsen Holding ASA - Class A',
    region: 'GB',
  },
  {
    symbol: '0P0Y-LN',
    name: 'Space Hellas S.A.',
    region: 'GB',
  },
  {
    symbol: '0P11-LN',
    name: 'Kiriacoulis Shipping S.A.',
    region: 'GB',
  },
  {
    symbol: '0P2N-LN',
    name: 'Ferrovial S.A.',
    region: 'GB',
  },
  {
    symbol: '0P2V-LN',
    name: 'Zaklady Przemyslu Cukierniczego Otmuchow S.A.',
    region: 'GB',
  },
  {
    symbol: '0P2W-LN',
    name: 'Amadeus IT Group S.A.',
    region: 'GB',
  },
  {
    symbol: '0P30-LN',
    name: 'Patrimoine Et Commerce.',
    region: 'GB',
  },
  {
    symbol: '0P38-LN',
    name: 'NORMA Group SE',
    region: 'GB',
  },
  {
    symbol: '0P3C-LN',
    name: 'Karolinska Development AB',
    region: 'GB',
  },
  {
    symbol: '0P3K-LN',
    name: 'Dedicare AB',
    region: 'GB',
  },
  {
    symbol: '0P3N-LN',
    name: 'Norway Royal Salmon ASA',
    region: 'GB',
  },
  {
    symbol: '0P3O-LN',
    name: 'Alerion Clean Power S.P.A.',
    region: 'GB',
  },
  {
    symbol: '0P3P-LN',
    name: 'S.D. Standard ETC Plc',
    region: 'GB',
  },
  {
    symbol: '0P3V-LN',
    name: 'Canada Silver Cobalt Works',
    region: 'GB',
  },
  {
    symbol: '0P41-LN',
    name: 'Ratti Spa',
    region: 'GB',
  },
  {
    symbol: '0P47-LN',
    name: 'PostNL N.V.',
    region: 'GB',
  },
  {
    symbol: '0P49-LN',
    name: 'Bulten AB.',
    region: 'GB',
  },
  {
    symbol: '0P4C-LN',
    name: 'Accell Group',
    region: 'GB',
  },
  {
    symbol: '0P4F-LN',
    name: 'Ford Motor Co.',
    region: 'GB',
  },
  {
    symbol: '0P4G-LN',
    name: 'SIG Group AG',
    region: 'GB',
  },
  {
    symbol: '0P4X-LN',
    name: 'Concentric AB',
    region: 'GB',
  },
  {
    symbol: '0P52-LN',
    name: 'Salvatore Ferragamo S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0P54-LN',
    name: 'Adler Modemarkte AG',
    region: 'GB',
  },
  {
    symbol: '0P59-LN',
    name: 'Colgate-Palmolive Co.',
    region: 'GB',
  },
  {
    symbol: '0P5C-LN',
    name: 'Danske Andelskassers Bank A/S',
    region: 'GB',
  },
  {
    symbol: '0P5I-LN',
    name: 'Mauna Kea Technologies SA',
    region: 'GB',
  },
  {
    symbol: '0P5L-LN',
    name: 'Axway Software',
    region: 'GB',
  },
  {
    symbol: '0P6A-LN',
    name: 'Signet Jewelers Ltd',
    region: 'GB',
  },
  {
    symbol: '0P6E-LN',
    name: 'First Quantum Minerals Ltd.',
    region: 'GB',
  },
  {
    symbol: '0P6M-LN',
    name: 'Siemens AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0P6N-LN',
    name: 'Volkswagen AG',
    region: 'GB',
  },
  {
    symbol: '0P6O-LN',
    name: 'Volkswagen AG - ZC PRF PERPETUAL EUR',
    region: 'GB',
  },
  {
    symbol: '0P6S-LN',
    name: 'Bayer AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0P72-LN',
    name: 'Getlink SE - Class A',
    region: 'GB',
  },
  {
    symbol: '0PNT-LN',
    name: 'Acacia Pharma Group Plc',
    region: 'GB',
  },
  {
    symbol: '0PRL-LN',
    name: 'Tethys Petroleum Limited',
    region: 'GB',
  },
  {
    symbol: '0PT3-LN',
    name: 'Corem Property Group AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0Q09-LN',
    name: 'Ana Holdings Inc.',
    region: 'GB',
  },
  {
    symbol: '0Q0C-LN',
    name: 'Toshiba Corporation',
    region: 'GB',
  },
  {
    symbol: '0Q0J-LN',
    name: 'Mitsubishi Corporation',
    region: 'GB',
  },
  {
    symbol: '0Q0Y-LN',
    name: 'Aegon N. V. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0Q11-LN',
    name: 'Norsk Hydro',
    region: 'GB',
  },
  {
    symbol: '0Q15-LN',
    name: 'Abbott Laboratories',
    region: 'GB',
  },
  {
    symbol: '0Q16-LN',
    name: 'Bank Of America Corp.',
    region: 'GB',
  },
  {
    symbol: '0Q18-LN',
    name: 'Caterpillar Inc.',
    region: 'GB',
  },
  {
    symbol: '0Q19-LN',
    name: 'Ceva Inc.',
    region: 'GB',
  },
  {
    symbol: '0Q1F-LN',
    name: 'JPMorgan Chase & Co.',
    region: 'GB',
  },
  {
    symbol: '0Q1G-LN',
    name: 'Lilly(Eli) & Co',
    region: 'GB',
  },
  {
    symbol: '0Q1N-LN',
    name: 'Pfizer Inc.',
    region: 'GB',
  },
  {
    symbol: '0Q1S-LN',
    name: 'Verizon Communications Inc',
    region: 'GB',
  },
  {
    symbol: '0Q2F-LN',
    name: 'Distribuidora Internacional De Alimentacion S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q2K-LN',
    name: 'Awilco Drilling Plc',
    region: 'GB',
  },
  {
    symbol: '0Q2N-LN',
    name: 'K & S AG',
    region: 'GB',
  },
  {
    symbol: '0Q37-LN',
    name: 'Codon AG',
    region: 'GB',
  },
  {
    symbol: '0Q3C-LN',
    name: 'SUSS MicroTec SE',
    region: 'GB',
  },
  {
    symbol: '0Q3E-LN',
    name: 'AC S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q3J-LN',
    name: 'Benefit Systems SA',
    region: 'GB',
  },
  {
    symbol: '0Q3K-LN',
    name: 'Kino Polska TV SA',
    region: 'GB',
  },
  {
    symbol: '0Q3T-LN',
    name: 'BNP Paribas Bank Polska S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q3U-LN',
    name: 'Open Finance SA',
    region: 'GB',
  },
  {
    symbol: '0Q3Y-LN',
    name: 'Kruk S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q45-LN',
    name: 'Jastrzebska Spolka Weglowa S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q48-LN',
    name: 'Toya SA',
    region: 'GB',
  },
  {
    symbol: '0Q4C-LN',
    name: 'Gesco AG',
    region: 'GB',
  },
  {
    symbol: '0Q4D-LN',
    name: 'Tatra Banka A.S. - Series 2',
    region: 'GB',
  },
  {
    symbol: '0Q4E-LN',
    name: 'Slovenske Energeticke Strojarne a.s. - Series 2',
    region: 'GB',
  },
  {
    symbol: '0Q4G-LN',
    name: 'Awilco LNG ASA',
    region: 'GB',
  },
  {
    symbol: '0Q4U-LN',
    name: 'Novozymes AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0Q4W-LN',
    name: 'Aega ASA',
    region: 'GB',
  },
  {
    symbol: '0Q50-LN',
    name: 'Artego',
    region: 'GB',
  },
  {
    symbol: '0Q51-LN',
    name: 'Rovsing A/S',
    region: 'GB',
  },
  {
    symbol: '0Q52-LN',
    name: 'Hagar ehf.',
    region: 'GB',
  },
  {
    symbol: '0Q57-LN',
    name: 'SpareBank 1 SR-Bank ASA',
    region: 'GB',
  },
  {
    symbol: '0Q59-LN',
    name: 'Scanfil plc',
    region: 'GB',
  },
  {
    symbol: '0Q6H-LN',
    name: 'Hofseth Biocare ASA',
    region: 'GB',
  },
  {
    symbol: '0Q6M-LN',
    name: 'Unipol Gruppo S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0Q6P-LN',
    name: 'Lanson-BCC',
    region: 'GB',
  },
  {
    symbol: '0Q6Q-LN',
    name: 'Mersen',
    region: 'GB',
  },
  {
    symbol: '0Q6W-LN',
    name: 'Hopscotch Groupe',
    region: 'GB',
  },
  {
    symbol: '0Q77-LN',
    name: 'Ab Science',
    region: 'GB',
  },
  {
    symbol: '0Q7G-LN',
    name: 'Ecolumber S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q7J-LN',
    name: 'Masterplast Nyilvanosan Mukodo Reszvenytarsasag',
    region: 'GB',
  },
  {
    symbol: '0Q7L-LN',
    name: 'AMAG Austria Metall AG',
    region: 'GB',
  },
  {
    symbol: '0Q7S-LN',
    name: 'Brunello Cucinelli S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0Q7U-LN',
    name: 'Fluxys Belgium',
    region: 'GB',
  },
  {
    symbol: '0Q82-LN',
    name: 'Core Laboratories N.V.',
    region: 'GB',
  },
  {
    symbol: '0Q89-LN',
    name: 'Thomson-Reuters Corp',
    region: 'GB',
  },
  {
    symbol: '0Q8E-LN',
    name: 'Eukedos S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0Q8F-LN',
    name: 'Hugo Boss AG (Registered)',
    region: 'GB',
  },
  {
    symbol: '0Q8M-LN',
    name: 'UnipolSai S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0Q8P-LN',
    name: 'Prosegur Compania de Seguridad SA',
    region: 'GB',
  },
  {
    symbol: '0Q8Q-LN',
    name: 'Lotto24 AG',
    region: 'GB',
  },
  {
    symbol: '0Q8U-LN',
    name: 'Netweek S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0Q90-LN',
    name: 'Sqli',
    region: 'GB',
  },
  {
    symbol: '0Q98-LN',
    name: 'Seri Industrial',
    region: 'GB',
  },
  {
    symbol: '0Q99-LN',
    name: 'Ageas SA/NV',
    region: 'GB',
  },
  {
    symbol: '0Q9C-LN',
    name: 'R. Stahl AG',
    region: 'GB',
  },
  {
    symbol: '0Q9R-LN',
    name: 'Bowim SA',
    region: 'GB',
  },
  {
    symbol: '0Q9U-LN',
    name: 'Solar Company S.A.',
    region: 'GB',
  },
  {
    symbol: '0Q9Y-LN',
    name: 'Kernel Holding SA',
    region: 'GB',
  },
  {
    symbol: '0QA0-LN',
    name: 'Getin Noble Bank SA',
    region: 'GB',
  },
  {
    symbol: '0QA8-LN',
    name: 'Talanx AG',
    region: 'GB',
  },
  {
    symbol: '0QAB-LN',
    name: 'Petrolia SE',
    region: 'GB',
  },
  {
    symbol: '0QAG-LN',
    name: 'ID Logistics',
    region: 'GB',
  },
  {
    symbol: '0QAH-LN',
    name: 'Merck & Co Inc',
    region: 'GB',
  },
  {
    symbol: '0QAI-LN',
    name: 'Adocia',
    region: 'GB',
  },
  {
    symbol: '0QAJ-LN',
    name: 'DBV Technologies',
    region: 'GB',
  },
  {
    symbol: '0QAL-LN',
    name: 'Telefonica Deutschland Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QAP-LN',
    name: 'Carmila',
    region: 'GB',
  },
  {
    symbol: '0QAU-LN',
    name: 'Verimatrix',
    region: 'GB',
  },
  {
    symbol: '0QAV-LN',
    name: 'Nanobiotix',
    region: 'GB',
  },
  {
    symbol: '0QB3-LN',
    name: 'Eimskipafelag Islands ehf.',
    region: 'GB',
  },
  {
    symbol: '0QB4-LN',
    name: 'Medios AG',
    region: 'GB',
  },
  {
    symbol: '0QB7-LN',
    name: 'Borregaard ASA',
    region: 'GB',
  },
  {
    symbol: '0QB8-LN',
    name: 'ASML Holding NV',
    region: 'GB',
  },
  {
    symbol: '0QBM-LN',
    name: 'Alior Bank S.A',
    region: 'GB',
  },
  {
    symbol: '0QBO-LN',
    name: 'Coloplast AS - Class B',
    region: 'GB',
  },
  {
    symbol: '0QCF-LN',
    name: 'Speedy AD-Sofia',
    region: 'GB',
  },
  {
    symbol: '0QCO-LN',
    name: 'Pharming Group N.V.',
    region: 'GB',
  },
  {
    symbol: '0QCQ-LN',
    name: 'Topdanmark',
    region: 'GB',
  },
  {
    symbol: '0QCR-LN',
    name: 'Tie Kinetix',
    region: 'GB',
  },
  {
    symbol: '0QCV-LN',
    name: 'Abbvie Inc',
    region: 'GB',
  },
  {
    symbol: '0QD0-LN',
    name: 'Abengoa - Class B',
    region: 'GB',
  },
  {
    symbol: '0QDS-LN',
    name: 'Evonik Industries AG',
    region: 'GB',
  },
  {
    symbol: '0QDT-LN',
    name: 'Asetek AS',
    region: 'GB',
  },
  {
    symbol: '0QDU-LN',
    name: 'Abliva AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0QDW-LN',
    name: 'EAM Solar ASA',
    region: 'GB',
  },
  {
    symbol: '0QE6-LN',
    name: 'Tele2 AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QEJ-LN',
    name: 'Rizzoli Corriere della Sera MediaGroup SpA',
    region: 'GB',
  },
  {
    symbol: '0QEK-LN',
    name: 'SimCorp',
    region: 'GB',
  },
  {
    symbol: '0QEP-LN',
    name: 'Maire Tecnimont S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0QF5-LN',
    name: 'Bpost S.A. / N.V',
    region: 'GB',
  },
  {
    symbol: '0QF8-LN',
    name: 'Aktia Bank plc.',
    region: 'GB',
  },
  {
    symbol: '0QFA-LN',
    name: 'Matas A/S',
    region: 'GB',
  },
  {
    symbol: '0QFC-LN',
    name: 'Caverion Corporation',
    region: 'GB',
  },
  {
    symbol: '0QFK-LN',
    name: 'Celyad Oncology',
    region: 'GB',
  },
  {
    symbol: '0QFP-LN',
    name: 'Chemical Works of Gedeon Richter Plc.',
    region: 'GB',
  },
  {
    symbol: '0QFU-LN',
    name: 'Kion Group AG',
    region: 'GB',
  },
  {
    symbol: '0QFW-LN',
    name: 'Minerales Y Productos Derivados S.A',
    region: 'GB',
  },
  {
    symbol: '0QFY-LN',
    name: 'Tarczynski S.A.',
    region: 'GB',
  },
  {
    symbol: '0QG6-LN',
    name: 'Kri-Kri Milk Industry S.A.',
    region: 'GB',
  },
  {
    symbol: '0QG7-LN',
    name: 'KHD Humboldt WedagVermogensverwaltungs-AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QG8-LN',
    name: 'IEX Group N.V.',
    region: 'GB',
  },
  {
    symbol: '0QG9-LN',
    name: 'Prosiebensati Media SE',
    region: 'GB',
  },
  {
    symbol: '0QGH-LN',
    name: 'OCI N.V.',
    region: 'GB',
  },
  {
    symbol: '0QGJ-LN',
    name: 'Medigene AG',
    region: 'GB',
  },
  {
    symbol: '0QGK-LN',
    name: 'WCM Beteiligung & Grundbesitz AG',
    region: 'GB',
  },
  {
    symbol: '0QGM-LN',
    name: 'Conpet SA Ploiesti',
    region: 'GB',
  },
  {
    symbol: '0QGQ-LN',
    name: 'AB Amber Grid',
    region: 'GB',
  },
  {
    symbol: '0QGU-LN',
    name: 'CNH Industrial NV',
    region: 'GB',
  },
  {
    symbol: '0QH2-LN',
    name: 'A.S. Creation Tapeten AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QHC-LN',
    name: 'Bastei Luebbe GmbH & Co. KG',
    region: 'GB',
  },
  {
    symbol: '0QHK-LN',
    name: 'Sesa S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0QHL-LN',
    name: 'Corbion N.V.',
    region: 'GB',
  },
  {
    symbol: '0QHX-LN',
    name: 'Odfjell Drilling Ltd',
    region: 'GB',
  },
  {
    symbol: '0QHZ-LN',
    name: 'Guideline Geo AB',
    region: 'GB',
  },
  {
    symbol: '0QI0-LN',
    name: 'PKP Cargo SA - Class A',
    region: 'GB',
  },
  {
    symbol: '0QI6-LN',
    name: 'NoHo Partners Oyj',
    region: 'GB',
  },
  {
    symbol: '0QI7-LN',
    name: 'Addtech AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QIF-LN',
    name: 'SnowWorld N.V',
    region: 'GB',
  },
  {
    symbol: '0QIG-LN',
    name: 'Axactor SE',
    region: 'GB',
  },
  {
    symbol: '0QIH-LN',
    name: 'Grupo Ezentis S.A',
    region: 'GB',
  },
  {
    symbol: '0QII-LN',
    name: 'Moncler SPA',
    region: 'GB',
  },
  {
    symbol: '0QIK-LN',
    name: 'Quest Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0QIM-LN',
    name: 'CTT Correios De Portugal SA',
    region: 'GB',
  },
  {
    symbol: '0QIQ-LN',
    name: 'Fabbrica Italiana Lapis E Affini S.P.A.',
    region: 'GB',
  },
  {
    symbol: '0QIS-LN',
    name: 'Festi hf.',
    region: 'GB',
  },
  {
    symbol: '0QIT-LN',
    name: 'Value8 N.V.',
    region: 'GB',
  },
  {
    symbol: '0QIU-LN',
    name: 'Novo Nordisk - Class B',
    region: 'GB',
  },
  {
    symbol: '0QIW-LN',
    name: 'Valmet Corporation',
    region: 'GB',
  },
  {
    symbol: '0QIX-LN',
    name: 'BW LPG Limited',
    region: 'GB',
  },
  {
    symbol: '0QJ4-LN',
    name: 'Napatech A/S',
    region: 'GB',
  },
  {
    symbol: '0QJC-LN',
    name: 'Solar World AG',
    region: 'GB',
  },
  {
    symbol: '0QJM-LN',
    name: 'Mercator Medical S. A',
    region: 'GB',
  },
  {
    symbol: '0QJQ-LN',
    name: 'Zeal Network - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QJS-LN',
    name: 'Clariant AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QJU-LN',
    name: 'Dottikon ES Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QJV-LN',
    name: 'Swatch Group AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QJX-LN',
    name: 'EFG International AG',
    region: 'GB',
  },
  {
    symbol: '0QJY-LN',
    name: 'Schaffner Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QK0-LN',
    name: 'Tornos Holding',
    region: 'GB',
  },
  {
    symbol: '0QK1-LN',
    name: 'Crealogix Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QK3-LN',
    name: 'Dufry AG',
    region: 'GB',
  },
  {
    symbol: '0QK5-LN',
    name: 'Inficon Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QK6-LN',
    name: 'Logitech International S.A.',
    region: 'GB',
  },
  {
    symbol: '0QK8-LN',
    name: 'Baxter International Inc.',
    region: 'GB',
  },
  {
    symbol: '0QKA-LN',
    name: 'Rieter Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QKB-LN',
    name: 'LEM Holding S.A.',
    region: 'GB',
  },
  {
    symbol: '0QKD-LN',
    name: 'Forbo Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QKE-LN',
    name: 'Vontobel Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QKG-LN',
    name: 'Schweiz Nationalbank',
    region: 'GB',
  },
  {
    symbol: '0QKI-LN',
    name: 'Swisscom AG',
    region: 'GB',
  },
  {
    symbol: '0QKJ-LN',
    name: 'Bobst Group S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QKL-LN',
    name: 'Komax Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QKN-LN',
    name: 'Chocoladefabriken Lindt & Sprungli AG.',
    region: 'GB',
  },
  {
    symbol: '0QKO-LN',
    name: 'Helvetia Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QKP-LN',
    name: 'Phoenix Mecano AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QKQ-LN',
    name: 'Relief Therapeutics Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QKR-LN',
    name: 'Arbonia AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QKS-LN',
    name: 'Elma Electronic AG',
    region: 'GB',
  },
  {
    symbol: '0QKY-LN',
    name: 'Holcim Ltd',
    region: 'GB',
  },
  {
    symbol: '0QKZ-LN',
    name: 'Bergbahnen Engelberg-Trubsee-Titlis AG',
    region: 'GB',
  },
  {
    symbol: '0QL0-LN',
    name: 'Highlight Event and Entertainment AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QL5-LN',
    name: 'Gavazzi (Carlo) Holding AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QL6-LN',
    name: 'Swiss Re Ltd',
    region: 'GB',
  },
  {
    symbol: '0QLD-LN',
    name: 'Swissquote Group Holding Ltd.',
    region: 'GB',
  },
  {
    symbol: '0QLE-LN',
    name: 'Valora Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QLH-LN',
    name: 'VZ Holdings',
    region: 'GB',
  },
  {
    symbol: '0QLJ-LN',
    name: 'Villars Holding SA',
    region: 'GB',
  },
  {
    symbol: '0QLK-LN',
    name: 'Hypothekarbank Lenzburg',
    region: 'GB',
  },
  {
    symbol: '0QLL-LN',
    name: 'Goodyear Tire & Rubber Co.',
    region: 'GB',
  },
  {
    symbol: '0QLM-LN',
    name: 'Feintool International Holding',
    region: 'GB',
  },
  {
    symbol: '0QLN-LN',
    name: 'Tecan Group AG',
    region: 'GB',
  },
  {
    symbol: '0QLQ-LN',
    name: 'Ypsomed Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QLR-LN',
    name: 'Novartis AG (Registered)',
    region: 'GB',
  },
  {
    symbol: '0QLV-LN',
    name: 'Arundel AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QLW-LN',
    name: 'Also Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QLX-LN',
    name: 'Metall Zug AG - Class B',
    region: 'GB',
  },
  {
    symbol: '0QLY-LN',
    name: 'Zwahlen & Mayr AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QM0-LN',
    name: 'ONE swiss bank SA - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QM1-LN',
    name: 'Schlatter Industries AG',
    region: 'GB',
  },
  {
    symbol: '0QM2-LN',
    name: 'Berner Kantonalbank AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QM4-LN',
    name: 'Swatch Group AG (Registered)',
    region: 'GB',
  },
  {
    symbol: '0QM5-LN',
    name: 'Emmi AG',
    region: 'GB',
  },
  {
    symbol: '0QM6-LN',
    name: 'Orior AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QM9-LN',
    name: 'Ems-Chemie Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QMB-LN',
    name: 'Bank Linth LLB AG',
    region: 'GB',
  },
  {
    symbol: '0QME-LN',
    name: 'Orell Fussli AG',
    region: 'GB',
  },
  {
    symbol: '0QMG-LN',
    name: 'Swiss Life Holding',
    region: 'GB',
  },
  {
    symbol: '0QMI-LN',
    name: 'SGS AG',
    region: 'GB',
  },
  {
    symbol: '0QMM-LN',
    name: 'Groupe Minoteries S.A.',
    region: 'GB',
  },
  {
    symbol: '0QMR-LN',
    name: 'BELIMO Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QMS-LN',
    name: 'dormakaba Holding AG - Registered Shares - Class B',
    region: 'GB',
  },
  {
    symbol: '0QMU-LN',
    name: 'Compagnie financiere Richemont SA - Registered Shares - Class A',
    region: 'GB',
  },
  {
    symbol: '0QMV-LN',
    name: 'Straumann Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QMW-LN',
    name: 'Kuhne & Nagel International AG (Registered)',
    region: 'GB',
  },
  {
    symbol: '0QMX-LN',
    name: 'SHL Telemedicine International Ltd',
    region: 'GB',
  },
  {
    symbol: '0QN0-LN',
    name: 'APG SGA SA',
    region: 'GB',
  },
  {
    symbol: '0QN1-LN',
    name: 'Santhera Pharmaceuticals Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QN2-LN',
    name: 'Interroll Hldg AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QN7-LN',
    name: 'Vaudoise Versicherungen Holding - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QNA-LN',
    name: 'Basilea Pharmaceutica AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QND-LN',
    name: 'Bachem Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QNG-LN',
    name: 'Jungfraubahn Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QNH-LN',
    name: 'Huber & Suhner AG (Registered)',
    region: 'GB',
  },
  {
    symbol: '0QNI-LN',
    name: 'u-blox Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QNJ-LN',
    name: 'Datwyler Holding AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QNM-LN',
    name: 'Adecco Group AG',
    region: 'GB',
  },
  {
    symbol: '0QNN-LN',
    name: 'Burckhardt Compression Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QNO-LN',
    name: 'Lonza Group AG',
    region: 'GB',
  },
  {
    symbol: '0QNQ-LN',
    name: 'Kudelski S.A. - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QNS-LN',
    name: 'Perfect Holding SA',
    region: 'GB',
  },
  {
    symbol: '0QNT-LN',
    name: 'Implenia AG',
    region: 'GB',
  },
  {
    symbol: '0QNU-LN',
    name: 'Luzerner Kantonalbank AG',
    region: 'GB',
  },
  {
    symbol: '0QNV-LN',
    name: 'Addex Therapeutics Ltd',
    region: 'GB',
  },
  {
    symbol: '0QNY-LN',
    name: '3M Co.',
    region: 'GB',
  },
  {
    symbol: '0QNZ-LN',
    name: 'CPH Chemie & Paper Hldgs - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QO0-LN',
    name: 'Aevis Victoria SA',
    region: 'GB',
  },
  {
    symbol: '0QO1-LN',
    name: 'Schindler Holding AG - Non Voting',
    region: 'GB',
  },
  {
    symbol: '0QO2-LN',
    name: 'Burkhalter Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QO3-LN',
    name: 'OC Oerlikon Corporation AG, Pfaffikon',
    region: 'GB',
  },
  {
    symbol: '0QO6-LN',
    name: 'Julius Baer Group Ltd - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QO7-LN',
    name: 'Barry Callebaut AG',
    region: 'GB',
  },
  {
    symbol: '0QO9-LN',
    name: 'TX Group AG',
    region: 'GB',
  },
  {
    symbol: '0QOA-LN',
    name: 'TEMENOS AG',
    region: 'GB',
  },
  {
    symbol: '0QOB-LN',
    name: 'Autoneum Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QOH-LN',
    name: 'Energiedienst Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QOI-LN',
    name: 'Newron Pharmaceuticals Spa.',
    region: 'GB',
  },
  {
    symbol: '0QOK-LN',
    name: 'Roche Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QOL-LN',
    name: 'Kardex Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QON-LN',
    name: 'Ascom Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QOS-LN',
    name: 'PepsiCo Inc',
    region: 'GB',
  },
  {
    symbol: '0QOT-LN',
    name: 'Schindler Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QOW-LN',
    name: 'Transocean Ltd',
    region: 'GB',
  },
  {
    symbol: '0QOY-LN',
    name: 'Orascom Development HLD',
    region: 'GB',
  },
  {
    symbol: '0QP1-LN',
    name: 'Chocoladefabriken Lindt & Sprungli AG. - Non Voting',
    region: 'GB',
  },
  {
    symbol: '0QP2-LN',
    name: 'Zurich Insurance Group AG Ltd',
    region: 'GB',
  },
  {
    symbol: '0QP3-LN',
    name: 'Calida Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QP4-LN',
    name: 'Georg Fischer AG',
    region: 'GB',
  },
  {
    symbol: '0QP5-LN',
    name: 'Credit Suisse Group AG',
    region: 'GB',
  },
  {
    symbol: '0QP6-LN',
    name: 'Leclanche S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QP7-LN',
    name: 'Coltene Hldg AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QP8-LN',
    name: 'Banque Cantonale Vaudoise. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QPH-LN',
    name: 'Swiss Steel Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QPJ-LN',
    name: 'Cembra Money Bank AG',
    region: 'GB',
  },
  {
    symbol: '0QPR-LN',
    name: 'Cicor Technologies',
    region: 'GB',
  },
  {
    symbol: '0QPS-LN',
    name: 'Givaudan SA',
    region: 'GB',
  },
  {
    symbol: '0QPU-LN',
    name: 'Valiant Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QPV-LN',
    name: 'Von Roll Holding AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QPY-LN',
    name: 'Sonova Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQ0-LN',
    name: 'BKW AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QQ2-LN',
    name: 'Geberit AG',
    region: 'GB',
  },
  {
    symbol: '0QQ3-LN',
    name: 'Baloise Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQ6-LN',
    name: 'Roche Holding AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QQ7-LN',
    name: 'Meyer Burger Technology AG',
    region: 'GB',
  },
  {
    symbol: '0QQ8-LN',
    name: 'Gold Fields Ltd',
    region: 'GB',
  },
  {
    symbol: '0QQ9-LN',
    name: 'Sulzer AG',
    region: 'GB',
  },
  {
    symbol: '0QQA-LN',
    name: 'Starrag Group Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQD-LN',
    name: 'Zimmer Biomet Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0QQE-LN',
    name: 'DKSH Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQF-LN',
    name: 'Mikron Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQG-LN',
    name: 'Romande Energie Holding SA - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QQI-LN',
    name: 'Hochdorf Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQJ-LN',
    name: 'Evolva Holding SA',
    region: 'GB',
  },
  {
    symbol: '0QQK-LN',
    name: 'Meier Tobler Group AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QQM-LN',
    name: 'IVF Hartmann AG',
    region: 'GB',
  },
  {
    symbol: '0QQN-LN',
    name: 'Bucher Industries AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QQO-LN',
    name: 'Siegfried Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QQR-LN',
    name: 'Gurit Holding AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QQT-LN',
    name: 'Edisun Power Europe AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QQV-LN',
    name: 'Airesis',
    region: 'GB',
  },
  {
    symbol: '0QQZ-LN',
    name: 'St Galler Kantonalbank AG',
    region: 'GB',
  },
  {
    symbol: '0QR0-LN',
    name: 'Adval Tech Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QR1-LN',
    name: 'Schweiter Technologies AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QR3-LN',
    name: 'PG&E Corp.',
    region: 'GB',
  },
  {
    symbol: '0QR4-LN',
    name: 'Nestle SA',
    region: 'GB',
  },
  {
    symbol: '0QRA-LN',
    name: 'Bufab AB',
    region: 'GB',
  },
  {
    symbol: '0QRS-LN',
    name: 'ISS A/S',
    region: 'GB',
  },
  {
    symbol: '0QRV-LN',
    name: 'Autohellas S.A.',
    region: 'GB',
  },
  {
    symbol: '0QS4-LN',
    name: 'Thurgauer Kantonalbank',
    region: 'GB',
  },
  {
    symbol: '0QS5-LN',
    name: 'Bossard Holding AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QSA-LN',
    name: 'Tarkett',
    region: 'GB',
  },
  {
    symbol: '0QSH-LN',
    name: 'Fnac Darty',
    region: 'GB',
  },
  {
    symbol: '0QSO-LN',
    name: 'MND',
    region: 'GB',
  },
  {
    symbol: '0QSS-LN',
    name: 'Erytech Pharma - Ordinary Share',
    region: 'GB',
  },
  {
    symbol: '0QSU-LN',
    name: 'Orege',
    region: 'GB',
  },
  {
    symbol: '0QSV-LN',
    name: 'Ekinops',
    region: 'GB',
  },
  {
    symbol: '0QT0-LN',
    name: 'Lastminute.com N.V.',
    region: 'GB',
  },
  {
    symbol: '0QT4-LN',
    name: 'Genomic Vision',
    region: 'GB',
  },
  {
    symbol: '0QT5-LN',
    name: 'Gaztransport Et Technigaz',
    region: 'GB',
  },
  {
    symbol: '0QT6-LN',
    name: 'Genfit',
    region: 'GB',
  },
  {
    symbol: '0QTC-LN',
    name: 'Cabasse Group',
    region: 'GB',
  },
  {
    symbol: '0QTE-LN',
    name: 'Livechat Software S.A.',
    region: 'GB',
  },
  {
    symbol: '0QTF-LN',
    name: 'Mcphy Energy',
    region: 'GB',
  },
  {
    symbol: '0QTG-LN',
    name: 'Brim hf.',
    region: 'GB',
  },
  {
    symbol: '0QTJ-LN',
    name: 'Fermentalg',
    region: 'GB',
  },
  {
    symbol: '0QTV-LN',
    name: 'I.A.R Systems Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QTX-LN',
    name: 'Avance Gas Holding Ltd',
    region: 'GB',
  },
  {
    symbol: '0QTY-LN',
    name: 'OEM International AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QTZ-LN',
    name: 'Applus Services S.A.',
    region: 'GB',
  },
  {
    symbol: '0QU0-LN',
    name: 'Insr ASA',
    region: 'GB',
  },
  {
    symbol: '0QU2-LN',
    name: 'LVenture Group S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0QUI-LN',
    name: 'Lucara Diamond Corp',
    region: 'GB',
  },
  {
    symbol: '0QUL-LN',
    name: 'Stabilus SE - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QUM-LN',
    name: 'Brunel International NV',
    region: 'GB',
  },
  {
    symbol: '0QUS-LN',
    name: 'G5 Entertainment',
    region: 'GB',
  },
  {
    symbol: '0QUT-LN',
    name: 'Episurf Medical AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QUU-LN',
    name: 'Afry AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QUW-LN',
    name: 'MyHammer Holding AG',
    region: 'GB',
  },
  {
    symbol: '0QUY-LN',
    name: 'Besqab AB',
    region: 'GB',
  },
  {
    symbol: '0QV2-LN',
    name: 'Bactiguard Holding AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0QV3-LN',
    name: 'Glarner Kantonalbank',
    region: 'GB',
  },
  {
    symbol: '0QV6-LN',
    name: '7C Solarparken AG',
    region: 'GB',
  },
  {
    symbol: '0QV7-LN',
    name: 'Kinepolis Group NV',
    region: 'GB',
  },
  {
    symbol: '0QVB-LN',
    name: 'Pixium Vision SA',
    region: 'GB',
  },
  {
    symbol: '0QVE-LN',
    name: 'Fenix Outdoor International AG',
    region: 'GB',
  },
  {
    symbol: '0QVF-LN',
    name: 'Finecobank SpA',
    region: 'GB',
  },
  {
    symbol: '0QVG-LN',
    name: 'Sergeferrari Group',
    region: 'GB',
  },
  {
    symbol: '0QVI-LN',
    name: 'Worldline',
    region: 'GB',
  },
  {
    symbol: '0QVK-LN',
    name: 'Coface SA',
    region: 'GB',
  },
  {
    symbol: '0QVL-LN',
    name: 'Africa Oil Corp',
    region: 'GB',
  },
  {
    symbol: '0QVP-LN',
    name: 'Elior Group',
    region: 'GB',
  },
  {
    symbol: '0QVQ-LN',
    name: 'Ontex Group NV',
    region: 'GB',
  },
  {
    symbol: '0QVR-LN',
    name: 'Scandi Standard AB',
    region: 'GB',
  },
  {
    symbol: '0QVU-LN',
    name: 'IMCD N.V.',
    region: 'GB',
  },
  {
    symbol: '0QVV-LN',
    name: 'NN Group NV',
    region: 'GB',
  },
  {
    symbol: '0QVW-LN',
    name: 'Ateme',
    region: 'GB',
  },
  {
    symbol: '0QVZ-LN',
    name: 'Societatea Energetica Electrica SA',
    region: 'GB',
  },
  {
    symbol: '0QW0-LN',
    name: 'Argen X SE',
    region: 'GB',
  },
  {
    symbol: '0QW1-LN',
    name: 'Bystronic AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QW5-LN',
    name: 'Heidelberg Pharma AG',
    region: 'GB',
  },
  {
    symbol: '0QW7-LN',
    name: 'Voltalia',
    region: 'GB',
  },
  {
    symbol: '0QW8-LN',
    name: 'SFS Group AG',
    region: 'GB',
  },
  {
    symbol: '0QW9-LN',
    name: 'FACC AG',
    region: 'GB',
  },
  {
    symbol: '0QWA-LN',
    name: 'Compania De Distribucion Integral Logista Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0QWC-LN',
    name: 'ams-OSRAM AG',
    region: 'GB',
  },
  {
    symbol: '0QWE-LN',
    name: 'Magseis Fairfield ASA',
    region: 'GB',
  },
  {
    symbol: '0QWF-LN',
    name: 'Zalaris ASA',
    region: 'GB',
  },
  {
    symbol: '0QWI-LN',
    name: 'Industria De Diseno Textil SA',
    region: 'GB',
  },
  {
    symbol: '0QWK-LN',
    name: 'NEXT Biometrics Group ASA',
    region: 'GB',
  },
  {
    symbol: '0QWN-LN',
    name: 'Fincantieri S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0QWR-LN',
    name: 'Havyard Group ASA',
    region: 'GB',
  },
  {
    symbol: '0QX7-LN',
    name: 'Energa SA - Series AA',
    region: 'GB',
  },
  {
    symbol: '0QXA-LN',
    name: 'ABO-Group',
    region: 'GB',
  },
  {
    symbol: '0QXB-LN',
    name: 'Torpol S.A.',
    region: 'GB',
  },
  {
    symbol: '0QXF-LN',
    name: 'AqualisBraemar LOC ASA',
    region: 'GB',
  },
  {
    symbol: '0QXH-LN',
    name: 'Epigenomics AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0QXM-LN',
    name: 'Inwido',
    region: 'GB',
  },
  {
    symbol: '0QXN-LN',
    name: 'Zalando SE - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0QXP-LN',
    name: 'Aker Solutions ASA',
    region: 'GB',
  },
  {
    symbol: '0QXR-LN',
    name: 'Stellantis N.V',
    region: 'GB',
  },
  {
    symbol: '0QXX-LN',
    name: 'Molecular Partners AG',
    region: 'GB',
  },
  {
    symbol: '0QY2-LN',
    name: 'NorCom Information Technology GmbH & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0QY3-LN',
    name: 'Wild Bunch AG',
    region: 'GB',
  },
  {
    symbol: '0QY4-LN',
    name: 'Las Vegas Sands Corp',
    region: 'GB',
  },
  {
    symbol: '0QY5-LN',
    name: 'Ballard Power Systems Inc.',
    region: 'GB',
  },
  {
    symbol: '0QYC-LN',
    name: 'First Majestic Silver Corporation',
    region: 'GB',
  },
  {
    symbol: '0QYD-LN',
    name: 'Yum Brands Inc.',
    region: 'GB',
  },
  {
    symbol: '0QYF-LN',
    name: 'Comcast Corp - Class A',
    region: 'GB',
  },
  {
    symbol: '0QYH-LN',
    name: '3D Systems Corp.',
    region: 'GB',
  },
  {
    symbol: '0QYI-LN',
    name: 'NetFlix Inc',
    region: 'GB',
  },
  {
    symbol: '0QYJ-LN',
    name: 'Salesforce Inc',
    region: 'GB',
  },
  {
    symbol: '0QYK-LN',
    name: 'Wynn Resorts Ltd.',
    region: 'GB',
  },
  {
    symbol: '0QYL-LN',
    name: 'Rambus Inc.',
    region: 'GB',
  },
  {
    symbol: '0QYM-LN',
    name: 'Fortuna Silver Mines Inc.',
    region: 'GB',
  },
  {
    symbol: '0QYN-LN',
    name: 'B2gold Corp',
    region: 'GB',
  },
  {
    symbol: '0QYP-LN',
    name: 'Microsoft Corporation',
    region: 'GB',
  },
  {
    symbol: '0QYQ-LN',
    name: 'Gilead Sciences, Inc.',
    region: 'GB',
  },
  {
    symbol: '0QYR-LN',
    name: 'Panasonic Holdings Corporation',
    region: 'GB',
  },
  {
    symbol: '0QYT-LN',
    name: 'Sierra Wireless Inc',
    region: 'GB',
  },
  {
    symbol: '0QYU-LN',
    name: 'Morgan Stanley',
    region: 'GB',
  },
  {
    symbol: '0QYW-LN',
    name: 'Bausch Health Companies Inc',
    region: 'GB',
  },
  {
    symbol: '0QYY-LN',
    name: 'Harley-Davidson, Inc.',
    region: 'GB',
  },
  {
    symbol: '0QYZ-LN',
    name: 'Franco-Nevada Corporation',
    region: 'GB',
  },
  {
    symbol: '0QZ0-LN',
    name: 'Visa Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0QZ1-LN',
    name: 'AT&T, Inc.',
    region: 'GB',
  },
  {
    symbol: '0QZ2-LN',
    name: 'Silvercorp Metals Inc',
    region: 'GB',
  },
  {
    symbol: '0QZ3-LN',
    name: 'Qualcomm, Inc.',
    region: 'GB',
  },
  {
    symbol: '0QZ4-LN',
    name: 'Delta Air Lines, Inc.',
    region: 'GB',
  },
  {
    symbol: '0QZ5-LN',
    name: 'Cognizant Technology Solutions Corp. - Class A',
    region: 'GB',
  },
  {
    symbol: '0QZ6-LN',
    name: 'Nike, Inc. - Class B',
    region: 'GB',
  },
  {
    symbol: '0QZA-LN',
    name: 'Conoco Phillips',
    region: 'GB',
  },
  {
    symbol: '0QZB-LN',
    name: 'Twitter Inc',
    region: 'GB',
  },
  {
    symbol: '0QZD-LN',
    name: 'Advanced Micro Devices Inc.',
    region: 'GB',
  },
  {
    symbol: '0QZE-LN',
    name: 'Gold Standard Ventures Corp',
    region: 'GB',
  },
  {
    symbol: '0QZF-LN',
    name: 'Western Digital Corp.',
    region: 'GB',
  },
  {
    symbol: '0QZH-LN',
    name: 'Starbucks Corp.',
    region: 'GB',
  },
  {
    symbol: '0QZI-LN',
    name: 'Meta Platforms Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0QZK-LN',
    name: 'Coca-Cola Co',
    region: 'GB',
  },
  {
    symbol: '0QZO-LN',
    name: 'Walt Disney Co (The)',
    region: 'GB',
  },
  {
    symbol: '0QZP-LN',
    name: 'Bombardier Inc. - Class B (Sub Voting)',
    region: 'GB',
  },
  {
    symbol: '0QZS-LN',
    name: 'TripAdvisor Inc.',
    region: 'GB',
  },
  {
    symbol: '0QZU-LN',
    name: 'Vertex Pharmaceuticals, Inc.',
    region: 'GB',
  },
  {
    symbol: '0QZX-LN',
    name: 'Fedex Corp',
    region: 'GB',
  },
  {
    symbol: '0R00-LN',
    name: 'Cerner Corp.',
    region: 'GB',
  },
  {
    symbol: '0R01-LN',
    name: 'Citigroup Inc',
    region: 'GB',
  },
  {
    symbol: '0R02-LN',
    name: 'Organovo Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0R03-LN',
    name: 'Travelers Companies Inc.',
    region: 'GB',
  },
  {
    symbol: '0R07-LN',
    name: 'Pan American Silver Corp',
    region: 'GB',
  },
  {
    symbol: '0R08-LN',
    name: 'United Parcel Service, Inc. - Class B',
    region: 'GB',
  },
  {
    symbol: '0R0A-LN',
    name: 'Hecla Mining Co.',
    region: 'GB',
  },
  {
    symbol: '0R0D-LN',
    name: 'Great Panther Mining Ltd',
    region: 'GB',
  },
  {
    symbol: '0R0E-LN',
    name: 'General Motors Company',
    region: 'GB',
  },
  {
    symbol: '0R0G-LN',
    name: 'Mondelez International Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0R0H-LN',
    name: 'Thermo Fisher Scientific Inc.',
    region: 'GB',
  },
  {
    symbol: '0R0J-LN',
    name: 'Zynga Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0R0K-LN',
    name: 'Cisco Systems, Inc.',
    region: 'GB',
  },
  {
    symbol: '0R0O-LN',
    name: 'Unitedhealth Group Inc',
    region: 'GB',
  },
  {
    symbol: '0R0P-LN',
    name: 'BlackBerry Ltd',
    region: 'GB',
  },
  {
    symbol: '0R0T-LN',
    name: 'AMGEN Inc.',
    region: 'GB',
  },
  {
    symbol: '0R0U-LN',
    name: 'Coeur Mining Inc',
    region: 'GB',
  },
  {
    symbol: '0R0X-LN',
    name: 'Tesla Inc',
    region: 'GB',
  },
  {
    symbol: '0R11-LN',
    name: 'Sabina Gold & Silver Corp',
    region: 'GB',
  },
  {
    symbol: '0R13-LN',
    name: 'Church & Dwight Co., Inc.',
    region: 'GB',
  },
  {
    symbol: '0R15-LN',
    name: 'SoftBank Group Corp.',
    region: 'GB',
  },
  {
    symbol: '0R16-LN',
    name: 'McDonald`s Corp',
    region: 'GB',
  },
  {
    symbol: '0R18-LN',
    name: 'Best Buy Co. Inc.',
    region: 'GB',
  },
  {
    symbol: '0R19-LN',
    name: 'Becton, Dickinson And Co.',
    region: 'GB',
  },
  {
    symbol: '0R1A-LN',
    name: 'Applied Materials Inc.',
    region: 'GB',
  },
  {
    symbol: '0R1B-LN',
    name: 'Biogen Inc',
    region: 'GB',
  },
  {
    symbol: '0R1E-LN',
    name: 'Nintendo Co. Ltd',
    region: 'GB',
  },
  {
    symbol: '0R1F-LN',
    name: 'Bristol-Myers Squibb Co.',
    region: 'GB',
  },
  {
    symbol: '0R1G-LN',
    name: 'Home Depot, Inc.',
    region: 'GB',
  },
  {
    symbol: '0R1I-LN',
    name: 'NVIDIA Corp',
    region: 'GB',
  },
  {
    symbol: '0R1J-LN',
    name: 'Plug Power Inc',
    region: 'GB',
  },
  {
    symbol: '0R1M-LN',
    name: 'Exxon Mobil Corp.',
    region: 'GB',
  },
  {
    symbol: '0R1O-LN',
    name: 'Amazon.com Inc.',
    region: 'GB',
  },
  {
    symbol: '0R1R-LN',
    name: 'Kellogg Co',
    region: 'GB',
  },
  {
    symbol: '0R1T-LN',
    name: 'Expedia Group Inc',
    region: 'GB',
  },
  {
    symbol: '0R1W-LN',
    name: 'Walmart Inc',
    region: 'GB',
  },
  {
    symbol: '0R1X-LN',
    name: 'General Mills, Inc.',
    region: 'GB',
  },
  {
    symbol: '0R1Z-LN',
    name: 'Oracle Corp.',
    region: 'GB',
  },
  {
    symbol: '0R20-LN',
    name: 'Santacruz Silver Mining Ltd',
    region: 'GB',
  },
  {
    symbol: '0R22-LN',
    name: 'Barrick Gold Corp.',
    region: 'GB',
  },
  {
    symbol: '0R23-LN',
    name: 'Halliburton Co.',
    region: 'GB',
  },
  {
    symbol: '0R24-LN',
    name: 'Intel Corp.',
    region: 'GB',
  },
  {
    symbol: '0R28-LN',
    name: 'Newmont Corp',
    region: 'GB',
  },
  {
    symbol: '0R29-LN',
    name: 'Intuitive Surgical Inc',
    region: 'GB',
  },
  {
    symbol: '0R2B-LN',
    name: 'Danaher Corp.',
    region: 'GB',
  },
  {
    symbol: '0R2C-LN',
    name: 'Endeavour Silver Corp.',
    region: 'GB',
  },
  {
    symbol: '0R2D-LN',
    name: 'Kinross Gold Corp.',
    region: 'GB',
  },
  {
    symbol: '0R2E-LN',
    name: 'Union Pacific Corp.',
    region: 'GB',
  },
  {
    symbol: '0R2F-LN',
    name: 'Wells Fargo & Co.',
    region: 'GB',
  },
  {
    symbol: '0R2H-LN',
    name: 'Texas Instruments Inc.',
    region: 'GB',
  },
  {
    symbol: '0R2I-LN',
    name: 'Under Armour Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0R2J-LN',
    name: 'Agnico Eagle Mines Ltd',
    region: 'GB',
  },
  {
    symbol: '0R2L-LN',
    name: 'T-Mobile US Inc',
    region: 'GB',
  },
  {
    symbol: '0R2N-LN',
    name: 'Raytheon Technologies Corporation',
    region: 'GB',
  },
  {
    symbol: '0R2O-LN',
    name: 'Freeport-McMoRan Inc',
    region: 'GB',
  },
  {
    symbol: '0R2P-LN',
    name: 'Deere & Co.',
    region: 'GB',
  },
  {
    symbol: '0R2Q-LN',
    name: 'Chevron Corp.',
    region: 'GB',
  },
  {
    symbol: '0R2S-LN',
    name: 'Stryker Corp.',
    region: 'GB',
  },
  {
    symbol: '0R2T-LN',
    name: 'Micron Technology Inc.',
    region: 'GB',
  },
  {
    symbol: '0R2V-LN',
    name: 'Apple Inc',
    region: 'GB',
  },
  {
    symbol: '0R2X-LN',
    name: 'Corning, Inc.',
    region: 'GB',
  },
  {
    symbol: '0R2Y-LN',
    name: 'Adobe Inc',
    region: 'GB',
  },
  {
    symbol: '0R2Z-LN',
    name: 'Mastercard Incorporated - Class A',
    region: 'GB',
  },
  {
    symbol: '0R30-LN',
    name: 'VF Corp.',
    region: 'GB',
  },
  {
    symbol: '0R31-LN',
    name: 'Altria Group Inc.',
    region: 'GB',
  },
  {
    symbol: '0R32-LN',
    name: 'Abercrombie & Fitch Co. - Class A',
    region: 'GB',
  },
  {
    symbol: '0R33-LN',
    name: 'Emerson Electric Co.',
    region: 'GB',
  },
  {
    symbol: '0R34-LN',
    name: 'Johnson & Johnson',
    region: 'GB',
  },
  {
    symbol: '0R35-LN',
    name: 'Cameco Corp.',
    region: 'GB',
  },
  {
    symbol: '0R37-LN',
    name: 'Berkshire Hathaway Inc. - Class B',
    region: 'GB',
  },
  {
    symbol: '0R3C-LN',
    name: 'American Express Co.',
    region: 'GB',
  },
  {
    symbol: '0R3D-LN',
    name: 'EBay Inc.',
    region: 'GB',
  },
  {
    symbol: '0R3E-LN',
    name: 'Lockheed Martin Corp.',
    region: 'GB',
  },
  {
    symbol: '0R3G-LN',
    name: 'Goldman Sachs Group, Inc.',
    region: 'GB',
  },
  {
    symbol: '0R3I-LN',
    name: 'Scatec ASA',
    region: 'GB',
  },
  {
    symbol: '0R3M-LN',
    name: 'Vivoryon Therapeutics N.V.',
    region: 'GB',
  },
  {
    symbol: '0R3P-LN',
    name: 'XXL ASA',
    region: 'GB',
  },
  {
    symbol: '0R3Q-LN',
    name: 'Perrot Duval Holding S.A. - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0R3T-LN',
    name: 'UBS Group AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0R3U-LN',
    name: 'HELLA GmbH & Co. KGaA - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0R3W-LN',
    name: 'Thule Group AB',
    region: 'GB',
  },
  {
    symbol: '0R40-LN',
    name: 'Rai Way S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0R44-LN',
    name: 'C-Rad AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0R47-LN',
    name: 'RAK Petroleum Plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0R4B-LN',
    name: 'Vigo System SA',
    region: 'GB',
  },
  {
    symbol: '0R4I-LN',
    name: 'Internet Media Services SA',
    region: 'GB',
  },
  {
    symbol: '0R4M-LN',
    name: 'Lundin Gold Inc',
    region: 'GB',
  },
  {
    symbol: '0R4P-LN',
    name: 'Lifco AB. - Class B',
    region: 'GB',
  },
  {
    symbol: '0R4T-LN',
    name: 'Eprice S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0R4Y-LN',
    name: 'Aena S.M.E. S.A.',
    region: 'GB',
  },
  {
    symbol: '0R50-LN',
    name: 'Tele Columbus AG',
    region: 'GB',
  },
  {
    symbol: '0R53-LN',
    name: 'Eltel AB',
    region: 'GB',
  },
  {
    symbol: '0R55-LN',
    name: 'Bone Therapeutics S.A.',
    region: 'GB',
  },
  {
    symbol: '0R5R-LN',
    name: 'Ovs SPA',
    region: 'GB',
  },
  {
    symbol: '0R5W-LN',
    name: 'Dustin Group AB',
    region: 'GB',
  },
  {
    symbol: '0R5Z-LN',
    name: 'NNIT AS',
    region: 'GB',
  },
  {
    symbol: '0R62-LN',
    name: 'Groupe Partouche',
    region: 'GB',
  },
  {
    symbol: '0R6A-LN',
    name: 'Troax Group AB',
    region: 'GB',
  },
  {
    symbol: '0R6B-LN',
    name: 'Enento Group Plc',
    region: 'GB',
  },
  {
    symbol: '0R6E-LN',
    name: 'Lucas Bols N.V.',
    region: 'GB',
  },
  {
    symbol: '0R6G-LN',
    name: 'Nueva Expresion Textil S.A.',
    region: 'GB',
  },
  {
    symbol: '0R6R-LN',
    name: 'Axfood AB',
    region: 'GB',
  },
  {
    symbol: '0R6S-LN',
    name: 'Zehnder Group AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0R6U-LN',
    name: '4 SC AG',
    region: 'GB',
  },
  {
    symbol: '0R6V-LN',
    name: 'Mobilezone Holding AG - Registered share',
    region: 'GB',
  },
  {
    symbol: '0R6W-LN',
    name: 'Tobii AB',
    region: 'GB',
  },
  {
    symbol: '0R6Y-LN',
    name: 'Nordic Nanovector ASA',
    region: 'GB',
  },
  {
    symbol: '0R6Z-LN',
    name: 'Royal Unibrew AS',
    region: 'GB',
  },
  {
    symbol: '0R72-LN',
    name: 'Biocartis Group NV',
    region: 'GB',
  },
  {
    symbol: '0R78-LN',
    name: 'Tryg AS',
    region: 'GB',
  },
  {
    symbol: '0R7A-LN',
    name: 'Wirtualna Polska Holding S. A',
    region: 'GB',
  },
  {
    symbol: '0R7I-LN',
    name: 'ROY Asset Holding SE',
    region: 'GB',
  },
  {
    symbol: '0R7M-LN',
    name: 'Banca Carige - Cassa Di Risparmio Di Genova E Imperia Spa',
    region: 'GB',
  },
  {
    symbol: '0R7O-LN',
    name: 'Hexpol AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0R7R-LN',
    name: 'Svenska Handelsbanken - Class A',
    region: 'GB',
  },
  {
    symbol: '0R7S-LN',
    name: 'Svenska Handelsbanken - Class B',
    region: 'GB',
  },
  {
    symbol: '0R7W-LN',
    name: 'Promotora de Informaciones',
    region: 'GB',
  },
  {
    symbol: '0R83-LN',
    name: 'Tchaikapharma Highquality Medicines AD',
    region: 'GB',
  },
  {
    symbol: '0R86-LN',
    name: 'Invisio AB',
    region: 'GB',
  },
  {
    symbol: '0R87-LN',
    name: 'Assa Abloy AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0R8A-LN',
    name: 'NeXR Technologies SE',
    region: 'GB',
  },
  {
    symbol: '0R8F-LN',
    name: 'Eolus Vind AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0R8H-LN',
    name: 'Pihlajalinna Oyj',
    region: 'GB',
  },
  {
    symbol: '0R8M-LN',
    name: 'Spie ICS',
    region: 'GB',
  },
  {
    symbol: '0R8N-LN',
    name: 'Multiconsult ASA',
    region: 'GB',
  },
  {
    symbol: '0R8P-LN',
    name: 'Siltronic Ag',
    region: 'GB',
  },
  {
    symbol: '0R8Q-LN',
    name: 'Coor Service Management Holding AB',
    region: 'GB',
  },
  {
    symbol: '0R8S-LN',
    name: 'Infrastructure Wireless Italiana S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0R8T-LN',
    name: 'Collector AB',
    region: 'GB',
  },
  {
    symbol: '0R8W-LN',
    name: 'Alimak Group AB',
    region: 'GB',
  },
  {
    symbol: '0R91-LN',
    name: 'Mithra Pharmaceuticals SA NV',
    region: 'GB',
  },
  {
    symbol: '0R99-LN',
    name: 'Talgo S.A.',
    region: 'GB',
  },
  {
    symbol: '0R9C-LN',
    name: 'Cellnex Telecom S.A.',
    region: 'GB',
  },
  {
    symbol: '0R9D-LN',
    name: 'AEROPORTO GUGLIELMO MARCONI DI BOLOGNA',
    region: 'GB',
  },
  {
    symbol: '0R9G-LN',
    name: 'Naturhouse Health S.A.',
    region: 'GB',
  },
  {
    symbol: '0R9H-LN',
    name: 'Banca Sistema S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0R9I-LN',
    name: 'Schibsted ASA - Class B',
    region: 'GB',
  },
  {
    symbol: '0R9K-LN',
    name: 'Deutsche Pfandbriefbank AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0R9M-LN',
    name: 'Adux',
    region: 'GB',
  },
  {
    symbol: '0R9Q-LN',
    name: 'Jacques Bogart SA',
    region: 'GB',
  },
  {
    symbol: '0R9T-LN',
    name: 'Pharmagest Interactive',
    region: 'GB',
  },
  {
    symbol: '0R9U-LN',
    name: 'PayPal Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0R9W-LN',
    name: 'Sunex SA',
    region: 'GB',
  },
  {
    symbol: '0R9X-LN',
    name: 'Granges AB',
    region: 'GB',
  },
  {
    symbol: '0RA2-LN',
    name: 'Poxel',
    region: 'GB',
  },
  {
    symbol: '0RA4-LN',
    name: 'Europcar Mobility Group',
    region: 'GB',
  },
  {
    symbol: '0RA5-LN',
    name: 'NHOA',
    region: 'GB',
  },
  {
    symbol: '0RA7-LN',
    name: 'Hipay Group',
    region: 'GB',
  },
  {
    symbol: '0RA8-LN',
    name: 'Elis.',
    region: 'GB',
  },
  {
    symbol: '0RA9-LN',
    name: 'Abivax',
    region: 'GB',
  },
  {
    symbol: '0RAA-LN',
    name: 'SAFE',
    region: 'GB',
  },
  {
    symbol: '0RAB-LN',
    name: 'Cnova N.V.',
    region: 'GB',
  },
  {
    symbol: '0RAD-LN',
    name: 'OSE Immunotherapeutics',
    region: 'GB',
  },
  {
    symbol: '0RAE-LN',
    name: 'Amoeba',
    region: 'GB',
  },
  {
    symbol: '0RAF-LN',
    name: 'Amplitude Surgical',
    region: 'GB',
  },
  {
    symbol: '0RAG-LN',
    name: 'Abionyx Pharma',
    region: 'GB',
  },
  {
    symbol: '0RAH-LN',
    name: 'Your Family Entertainment AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RAI-LN',
    name: 'Europris ASA',
    region: 'GB',
  },
  {
    symbol: '0RAM-LN',
    name: 'Vistin Pharma ASA',
    region: 'GB',
  },
  {
    symbol: '0RAQ-LN',
    name: 'Karo Pharma AB',
    region: 'GB',
  },
  {
    symbol: '0RAR-LN',
    name: 'Stratec SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RB1-LN',
    name: 'Laboratorio Reig Jofre S.A.',
    region: 'GB',
  },
  {
    symbol: '0RB3-LN',
    name: 'DFDS',
    region: 'GB',
  },
  {
    symbol: '0RB7-LN',
    name: 'Lagercrantz Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RB8-LN',
    name: 'Scout24 SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RBA-LN',
    name: 'Archer Ltd',
    region: 'GB',
  },
  {
    symbol: '0RBE-LN',
    name: 'Covestro AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0RBI-LN',
    name: 'Sinch AB',
    region: 'GB',
  },
  {
    symbol: '0RBK-LN',
    name: 'Schaeffler AG - Bearer Preference Shares',
    region: 'GB',
  },
  {
    symbol: '0RBL-LN',
    name: 'Berentzen-Gruppe AG',
    region: 'GB',
  },
  {
    symbol: '0RBU-LN',
    name: 'Siminn hf',
    region: 'GB',
  },
  {
    symbol: '0RBW-LN',
    name: 'Bravida Holding AB',
    region: 'GB',
  },
  {
    symbol: '0RC1-LN',
    name: 'Solocal Groupe',
    region: 'GB',
  },
  {
    symbol: '0RC2-LN',
    name: 'Poste Italiane S.p.a',
    region: 'GB',
  },
  {
    symbol: '0RC6-LN',
    name: 'Pharmamar S.A.',
    region: 'GB',
  },
  {
    symbol: '0RC7-LN',
    name: 'Hansa Biopharma AB',
    region: 'GB',
  },
  {
    symbol: '0RC8-LN',
    name: 'Aves One AG',
    region: 'GB',
  },
  {
    symbol: '0RC9-LN',
    name: 'HORNBACH Holding AG & Co. KGaA',
    region: 'GB',
  },
  {
    symbol: '0RCC-LN',
    name: 'SRP Groupe',
    region: 'GB',
  },
  {
    symbol: '0RCG-LN',
    name: 'Hapag-Lloyd AG',
    region: 'GB',
  },
  {
    symbol: '0RCO-LN',
    name: 'Dometic Group AB (PUBL)',
    region: 'GB',
  },
  {
    symbol: '0RCP-LN',
    name: 'Eurobank Ergasias Services and Holdings S.A. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RCQ-LN',
    name: 'Nicox SA',
    region: 'GB',
  },
  {
    symbol: '0RCR-LN',
    name: 'National Bank of Greece',
    region: 'GB',
  },
  {
    symbol: '0RCS-LN',
    name: 'Alpha Services and Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0RCU-LN',
    name: 'Attica Bank S.A.',
    region: 'GB',
  },
  {
    symbol: '0RCW-LN',
    name: 'Kid ASA',
    region: 'GB',
  },
  {
    symbol: '0RCY-LN',
    name: 'Attendo AB',
    region: 'GB',
  },
  {
    symbol: '0RD0-LN',
    name: 'Sparekassen Sjaelland-Fyn',
    region: 'GB',
  },
  {
    symbol: '0RD1-LN',
    name: 'Camurus AB',
    region: 'GB',
  },
  {
    symbol: '0RD3-LN',
    name: 'Kofola CeskoSlovensko A.S.',
    region: 'GB',
  },
  {
    symbol: '0RD7-LN',
    name: 'Scandic Hotels Group AB',
    region: 'GB',
  },
  {
    symbol: '0RD9-LN',
    name: 'Consti Plc',
    region: 'GB',
  },
  {
    symbol: '0RDG-LN',
    name: 'Steinhoff International Holdings N.V.',
    region: 'GB',
  },
  {
    symbol: '0RDH-LN',
    name: 'Hexatronic Group AB',
    region: 'GB',
  },
  {
    symbol: '0RDI-LN',
    name: 'Vitec Software Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RDM-LN',
    name: 'ABN AMRO Bank N.V. - NLDR',
    region: 'GB',
  },
  {
    symbol: '0RDO-LN',
    name: 'Banca Mediolanum Spa.',
    region: 'GB',
  },
  {
    symbol: '0RDS-LN',
    name: 'Kindred Group plc - SDR',
    region: 'GB',
  },
  {
    symbol: '0RDT-LN',
    name: 'Ferrari N.V.',
    region: 'GB',
  },
  {
    symbol: '0RDU-LN',
    name: 'Grifols SA',
    region: 'GB',
  },
  {
    symbol: '0RDV-LN',
    name: 'Grifols SA - Class B (Non-Voting)',
    region: 'GB',
  },
  {
    symbol: '0RE6-LN',
    name: 'Walliser Kantonalbank - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0REH-LN',
    name: 'Frontline Ltd',
    region: 'GB',
  },
  {
    symbol: '0REK-LN',
    name: 'Transdigm Group Incorporated',
    region: 'GB',
  },
  {
    symbol: '0REN-LN',
    name: 'Edwards Lifesciences Corp',
    region: 'GB',
  },
  {
    symbol: '0REP-LN',
    name: 'Constellation Brands Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0REQ-LN',
    name: 'Per Aarsleff Holding A/S - Class B',
    region: 'GB',
  },
  {
    symbol: '0RES-LN',
    name: 'Scandinavian Tobacco Group A/S',
    region: 'GB',
  },
  {
    symbol: '0REW-LN',
    name: 'Zublin Immobilien Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0REY-LN',
    name: 'Garo Aktiebolag',
    region: 'GB',
  },
  {
    symbol: '0REZ-LN',
    name: 'AddLife AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RF1-LN',
    name: 'WISeKey International Holding Ltd',
    region: 'GB',
  },
  {
    symbol: '0RF3-LN',
    name: 'International Flavors & Fragrances Inc.',
    region: 'GB',
  },
  {
    symbol: '0RF6-LN',
    name: 'Taaleri Plc',
    region: 'GB',
  },
  {
    symbol: '0RF7-LN',
    name: 'Humana AB',
    region: 'GB',
  },
  {
    symbol: '0RF9-LN',
    name: 'Uniwheels AG',
    region: 'GB',
  },
  {
    symbol: '0RFB-LN',
    name: 'Senvion S.A.',
    region: 'GB',
  },
  {
    symbol: '0RFL-LN',
    name: 'VAT Group AG',
    region: 'GB',
  },
  {
    symbol: '0RFM-LN',
    name: 'Figeac Aero',
    region: 'GB',
  },
  {
    symbol: '0RFO-LN',
    name: 'Siili Solutions OYJ',
    region: 'GB',
  },
  {
    symbol: '0RFR-LN',
    name: 'Tivoli AS',
    region: 'GB',
  },
  {
    symbol: '0RFW-LN',
    name: 'Global Dominion Access S.A.',
    region: 'GB',
  },
  {
    symbol: '0RFX-LN',
    name: 'Bell Food Group AG',
    region: 'GB',
  },
  {
    symbol: '0RFY-LN',
    name: 'Endomines AB',
    region: 'GB',
  },
  {
    symbol: '0RFZ-LN',
    name: 'Lehto Group Plc',
    region: 'GB',
  },
  {
    symbol: '0RG1-LN',
    name: 'TechnogymS.p.A.',
    region: 'GB',
  },
  {
    symbol: '0RG2-LN',
    name: 'Tokmanni Group Corporation',
    region: 'GB',
  },
  {
    symbol: '0RG4-LN',
    name: 'Torm Plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0RG5-LN',
    name: 'Qt Group Plc',
    region: 'GB',
  },
  {
    symbol: '0RG6-LN',
    name: 'Flughafen Zurich AG.',
    region: 'GB',
  },
  {
    symbol: '0RG7-LN',
    name: 'VP Bank AG - Registered Shares - Class A',
    region: 'GB',
  },
  {
    symbol: '0RG8-LN',
    name: 'Sartorius Stedim Biotech S.A.',
    region: 'GB',
  },
  {
    symbol: '0RGC-LN',
    name: 'Moneta Money Bank',
    region: 'GB',
  },
  {
    symbol: '0RGI-LN',
    name: 'COSMO Pharmaceuticals N.V. - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RGK-LN',
    name: 'BE Group AB',
    region: 'GB',
  },
  {
    symbol: '0RGN-LN',
    name: 'Hybrid Software Group PLC',
    region: 'GB',
  },
  {
    symbol: '0RGO-LN',
    name: 'DMS Imaging SA',
    region: 'GB',
  },
  {
    symbol: '0RGT-LN',
    name: 'Demant AS',
    region: 'GB',
  },
  {
    symbol: '0RGW-LN',
    name: 'ITAB Shop Concept AB',
    region: 'GB',
  },
  {
    symbol: '0RGY-LN',
    name: 'Fingerprint Cards AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RGZ-LN',
    name: 'Kinnevik AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0RH0-LN',
    name: 'Nibe Industrier AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RH1-LN',
    name: 'Kinnevik AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RH2-LN',
    name: 'El.En. S.p.A',
    region: 'GB',
  },
  {
    symbol: '0RH3-LN',
    name: 'Singulus Technologies AG',
    region: 'GB',
  },
  {
    symbol: '0RH5-LN',
    name: 'Valeo S.A.',
    region: 'GB',
  },
  {
    symbol: '0RH6-LN',
    name: 'Bonava AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0RH8-LN',
    name: 'Nyrstar NV',
    region: 'GB',
  },
  {
    symbol: '0RH9-LN',
    name: 'Nordic Waterproofing Holding AB',
    region: 'GB',
  },
  {
    symbol: '0RHA-LN',
    name: 'Bonava AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RHD-LN',
    name: 'Basic-Fit N.V',
    region: 'GB',
  },
  {
    symbol: '0RHE-LN',
    name: 'Orsted A/S',
    region: 'GB',
  },
  {
    symbol: '0RHI-LN',
    name: 'Signify NV',
    region: 'GB',
  },
  {
    symbol: '0RHL-LN',
    name: 'Maisons du Monde',
    region: 'GB',
  },
  {
    symbol: '0RHM-LN',
    name: 'TF Bank AB',
    region: 'GB',
  },
  {
    symbol: '0RHN-LN',
    name: 'AcadeMedia AB',
    region: 'GB',
  },
  {
    symbol: '0RHR-LN',
    name: 'Kuros Biosciences AG',
    region: 'GB',
  },
  {
    symbol: '0RHS-LN',
    name: 'ASR Nederland NV',
    region: 'GB',
  },
  {
    symbol: '0RHT-LN',
    name: 'Sif Holding N.V.',
    region: 'GB',
  },
  {
    symbol: '0RHU-LN',
    name: 'Flughafen Wien AG',
    region: 'GB',
  },
  {
    symbol: '0RHZ-LN',
    name: 'Forfarmers NV',
    region: 'GB',
  },
  {
    symbol: '0RI0-LN',
    name: 'Aures Technologies',
    region: 'GB',
  },
  {
    symbol: '0RI3-LN',
    name: 'Pareto Bank ASA',
    region: 'GB',
  },
  {
    symbol: '0RI5-LN',
    name: 'Red Electrica Corporacion S.A.',
    region: 'GB',
  },
  {
    symbol: '0RI8-LN',
    name: 'Koninklijke Ahold Delhaize N.V.',
    region: 'GB',
  },
  {
    symbol: '0RI9-LN',
    name: 'CGG',
    region: 'GB',
  },
  {
    symbol: '0RIA-LN',
    name: 'DMS',
    region: 'GB',
  },
  {
    symbol: '0RIB-LN',
    name: 'As Company S.A.',
    region: 'GB',
  },
  {
    symbol: '0RIC-LN',
    name: 'ING Groep N.V.',
    region: 'GB',
  },
  {
    symbol: '0RIE-LN',
    name: 'Enav Spa',
    region: 'GB',
  },
  {
    symbol: '0RIF-LN',
    name: 'Decheng Technology AG',
    region: 'GB',
  },
  {
    symbol: '0RIH-LN',
    name: 'Alphabet Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0RIL-LN',
    name: 'Francaise Energie',
    region: 'GB',
  },
  {
    symbol: '0RIM-LN',
    name: 'GenSight Biologics S.A.',
    region: 'GB',
  },
  {
    symbol: '0RIP-LN',
    name: 'Bet-At-Home.Com AG',
    region: 'GB',
  },
  {
    symbol: '0RIS-LN',
    name: 'Targovax ASA',
    region: 'GB',
  },
  {
    symbol: '0RIW-LN',
    name: 'Tinexta',
    region: 'GB',
  },
  {
    symbol: '0RJ4-LN',
    name: 'Uniper SE',
    region: 'GB',
  },
  {
    symbol: '0RJ6-LN',
    name: 'Lambert Dur-Chan',
    region: 'GB',
  },
  {
    symbol: '0RJI-LN',
    name: 'Anheuser-Busch In Bev SA/NV',
    region: 'GB',
  },
  {
    symbol: '0RJL-LN',
    name: 'Dionic S.A. - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0RJN-LN',
    name: 'Banco Comercial Portugues, S.A.',
    region: 'GB',
  },
  {
    symbol: '0RJY-LN',
    name: 'ABEO SA',
    region: 'GB',
  },
  {
    symbol: '0RJZ-LN',
    name: 'Playway SA',
    region: 'GB',
  },
  {
    symbol: '0RK1-LN',
    name: 'Italgas S.p.A',
    region: 'GB',
  },
  {
    symbol: '0RK5-LN',
    name: 'MAX Automation SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RK6-LN',
    name: 'Banca Monte Dei Paschi Di Siena Spa',
    region: 'GB',
  },
  {
    symbol: '0RK9-LN',
    name: 'Alligator Bioscience AB',
    region: 'GB',
  },
  {
    symbol: '0RKE-LN',
    name: 'Serneke Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RKF-LN',
    name: 'Construcciones Y Auxiliar De Ferrocarriles, S.A.',
    region: 'GB',
  },
  {
    symbol: '0RKH-LN',
    name: 'BW Offshore',
    region: 'GB',
  },
  {
    symbol: '0RKK-LN',
    name: 'Volati AB',
    region: 'GB',
  },
  {
    symbol: '0RKL-LN',
    name: 'Xvivo Perfusion AB',
    region: 'GB',
  },
  {
    symbol: '0RKX-LN',
    name: 'Celon Pharma SA',
    region: 'GB',
  },
  {
    symbol: '0RKY-LN',
    name: 'Exor N.V.',
    region: 'GB',
  },
  {
    symbol: '0RL1-LN',
    name: 'B3 Consulting Group AB',
    region: 'GB',
  },
  {
    symbol: '0RL2-LN',
    name: 'Cenergy Holdings SA',
    region: 'GB',
  },
  {
    symbol: '0RL4-LN',
    name: 'Catella - Class B',
    region: 'GB',
  },
  {
    symbol: '0RL5-LN',
    name: 'Innovative Solutions Ecosystem S.A.',
    region: 'GB',
  },
  {
    symbol: '0RL9-LN',
    name: 'ProCredit Holding AG',
    region: 'GB',
  },
  {
    symbol: '0RLA-LN',
    name: 'Banco BPM S.p.A',
    region: 'GB',
  },
  {
    symbol: '0RLB-LN',
    name: 'CTI BioPharma Corp',
    region: 'GB',
  },
  {
    symbol: '0RLO-LN',
    name: 'AQ Group AB',
    region: 'GB',
  },
  {
    symbol: '0RLS-LN',
    name: 'Unicredit Spa',
    region: 'GB',
  },
  {
    symbol: '0RLT-LN',
    name: 'Qiagen NV',
    region: 'GB',
  },
  {
    symbol: '0RLW-LN',
    name: 'Commerzbank AG',
    region: 'GB',
  },
  {
    symbol: '0RMO-LN',
    name: 'Nomad Foods Limited',
    region: 'GB',
  },
  {
    symbol: '0RMP-LN',
    name: 'Banque Cantonale de Geneve - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RMT-LN',
    name: 'SOITEC',
    region: 'GB',
  },
  {
    symbol: '0RMV-LN',
    name: 'TechnipFMC plc',
    region: 'GB',
  },
  {
    symbol: '0RMW-LN',
    name: 'Unibios Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0RN3-LN',
    name: 'Takeda Pharmaceutical Co',
    region: 'GB',
  },
  {
    symbol: '0RNH-LN',
    name: 'Snap Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0RNK-LN',
    name: 'Inventiva',
    region: 'GB',
  },
  {
    symbol: '0RNL-LN',
    name: 'Lysogene',
    region: 'GB',
  },
  {
    symbol: '0RNO-LN',
    name: 'Prosegur Cash S.A.',
    region: 'GB',
  },
  {
    symbol: '0RNP-LN',
    name: 'Avantium N.V.',
    region: 'GB',
  },
  {
    symbol: '0RNQ-LN',
    name: 'MIPS AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0RNS-LN',
    name: 'Rapid Nutrition PLC - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RNT-LN',
    name: 'Brdr.A&O Johansen - Class B',
    region: 'GB',
  },
  {
    symbol: '0RNV-LN',
    name: 'P.G. Nikas S.A. - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0RNW-LN',
    name: 'Christian Berner Tech Trade AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RNX-LN',
    name: 'Ambea AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0RO5-LN',
    name: 'Med Life SA',
    region: 'GB',
  },
  {
    symbol: '0RO7-LN',
    name: 'VEON Ltd',
    region: 'GB',
  },
  {
    symbol: '0RO8-LN',
    name: 'Aumann AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0ROF-LN',
    name: 'Unieuro S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0ROG-LN',
    name: 'Galenica AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0ROH-LN',
    name: 'Aquafil SpA',
    region: 'GB',
  },
  {
    symbol: '0ROI-LN',
    name: 'Actic Group AB',
    region: 'GB',
  },
  {
    symbol: '0ROM-LN',
    name: 'Gestamp Automocion S.A.',
    region: 'GB',
  },
  {
    symbol: '0RON-LN',
    name: 'BFF Bank S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0ROO-LN',
    name: 'FM Mattsson Mora Group AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0ROQ-LN',
    name: 'Comet Holding AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0ROS-LN',
    name: 'Plastiques Du Val De Loire',
    region: 'GB',
  },
  {
    symbol: '0ROV-LN',
    name: 'Banque Cantonale du Jura - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0ROY-LN',
    name: 'Davide Campari',
    region: 'GB',
  },
  {
    symbol: '0ROZ-LN',
    name: 'X-FAB Silicon Foundries SE',
    region: 'GB',
  },
  {
    symbol: '0RP3-LN',
    name: 'Kamux Oyj',
    region: 'GB',
  },
  {
    symbol: '0RP4-LN',
    name: 'Italmobiliare Spa',
    region: 'GB',
  },
  {
    symbol: '0RP5-LN',
    name: 'Instalco AB',
    region: 'GB',
  },
  {
    symbol: '0RP6-LN',
    name: 'Enea AB',
    region: 'GB',
  },
  {
    symbol: '0RP7-LN',
    name: 'Ayco Grupo Inmobiliario, SOCIMI S.A',
    region: 'GB',
  },
  {
    symbol: '0RP8-LN',
    name: 'Liechtensteinische Landesbank AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RP9-LN',
    name: 'ArcelorMittal',
    region: 'GB',
  },
  {
    symbol: '0RPD-LN',
    name: 'Vifor Pharma AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RPG-LN',
    name: 'Robit PLC',
    region: 'GB',
  },
  {
    symbol: '0RPH-LN',
    name: 'Indel B S.p.A',
    region: 'GB',
  },
  {
    symbol: '0RPI-LN',
    name: 'Saipem Spa',
    region: 'GB',
  },
  {
    symbol: '0RPN-LN',
    name: 'Digi Communications N.V. - Class B',
    region: 'GB',
  },
  {
    symbol: '0RPO-LN',
    name: 'Munters Group AB',
    region: 'GB',
  },
  {
    symbol: '0RPP-LN',
    name: 'Valartis Group AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RPR-LN',
    name: 'Ringkjobing Landbobank',
    region: 'GB',
  },
  {
    symbol: '0RPS-LN',
    name: 'Medicover AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RPV-LN',
    name: 'Brembo Spa',
    region: 'GB',
  },
  {
    symbol: '0RPX-LN',
    name: 'Econocom Group SA',
    region: 'GB',
  },
  {
    symbol: '0RPY-LN',
    name: 'Boozt AB',
    region: 'GB',
  },
  {
    symbol: '0RPZ-LN',
    name: 'HMS Networks AB',
    region: 'GB',
  },
  {
    symbol: '0RQ2-LN',
    name: 'Bilia AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0RQ6-LN',
    name: 'Evolution AB',
    region: 'GB',
  },
  {
    symbol: '0RQ7-LN',
    name: 'Xano Industrier AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RQ9-LN',
    name: 'Lundin Mining Corp.',
    region: 'GB',
  },
  {
    symbol: '0RQB-LN',
    name: 'Prodways Group',
    region: 'GB',
  },
  {
    symbol: '0RQC-LN',
    name: 'Essity Aktiebolag (publ) - Class A',
    region: 'GB',
  },
  {
    symbol: '0RQD-LN',
    name: 'Essity Aktiebolag (publ) - Class B',
    region: 'GB',
  },
  {
    symbol: '0RQE-LN',
    name: 'Idorsia Ltd - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RQF-LN',
    name: 'Balyo S.A.',
    region: 'GB',
  },
  {
    symbol: '0RQG-LN',
    name: 'Logwin AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RQH-LN',
    name: 'Fagerhult, AB',
    region: 'GB',
  },
  {
    symbol: '0RQI-LN',
    name: 'Talenom Plc',
    region: 'GB',
  },
  {
    symbol: '0RQJ-LN',
    name: 'Saniona',
    region: 'GB',
  },
  {
    symbol: '0RQK-LN',
    name: 'Balta Group N.V.',
    region: 'GB',
  },
  {
    symbol: '0RQM-LN',
    name: 'Admie (IPTO) Holding S.A.',
    region: 'GB',
  },
  {
    symbol: '0RQO-LN',
    name: 'Bonesupport Holding AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0RQP-LN',
    name: 'Latour, Investment AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RQV-LN',
    name: 'LU-VE S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0RR3-LN',
    name: 'Somfy SA',
    region: 'GB',
  },
  {
    symbol: '0RR7-LN',
    name: 'Unicaja Banco S.A.',
    region: 'GB',
  },
  {
    symbol: '0RR8-LN',
    name: 'Baker Hughes Co - Class A',
    region: 'GB',
  },
  {
    symbol: '0RR9-LN',
    name: 'Kekrops Hotel Tourist. Build.',
    region: 'GB',
  },
  {
    symbol: '0RRB-LN',
    name: 'Zur Rose Group AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RRC-LN',
    name: 'H & R AG',
    region: 'GB',
  },
  {
    symbol: '0RS2-LN',
    name: 'Mensch & Maschine Software SE',
    region: 'GB',
  },
  {
    symbol: '0RSL-LN',
    name: 'Esker',
    region: 'GB',
  },
  {
    symbol: '0RSP-LN',
    name: 'ALD',
    region: 'GB',
  },
  {
    symbol: '0RT7-LN',
    name: 'Tenneco, Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0RTC-LN',
    name: 'Delivery Hero SE',
    region: 'GB',
  },
  {
    symbol: '0RTE-LN',
    name: 'Metro AG',
    region: 'GB',
  },
  {
    symbol: '0RTI-LN',
    name: 'Dovalues S.p.A',
    region: 'GB',
  },
  {
    symbol: '0RTJ-LN',
    name: 'General De Alquiler De Maquinaria',
    region: 'GB',
  },
  {
    symbol: '0RTK-LN',
    name: 'Alligo AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RTL-LN',
    name: 'Landis+Gyr Group AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RTR-LN',
    name: 'JOST Werke AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0RTS-LN',
    name: 'Rubis',
    region: 'GB',
  },
  {
    symbol: '0RTV-LN',
    name: 'Abb-Aalborg Boldspilklub',
    region: 'GB',
  },
  {
    symbol: '0RTY-LN',
    name: 'Piraeus Financial Holdings S.A.',
    region: 'GB',
  },
  {
    symbol: '0RTZ-LN',
    name: 'Intrakat Societe Anonyme Of Technical And Energy Projects',
    region: 'GB',
  },
  {
    symbol: '0RU6-LN',
    name: 'SpareBank 1 Ostlandet',
    region: 'GB',
  },
  {
    symbol: '0RU7-LN',
    name: 'Carbon Transition ASA',
    region: 'GB',
  },
  {
    symbol: '0RU9-LN',
    name: 'GT Biopharma Inc',
    region: 'GB',
  },
  {
    symbol: '0RUE-LN',
    name: 'Catena Media PLC',
    region: 'GB',
  },
  {
    symbol: '0RUG-LN',
    name: 'Biomerieux',
    region: 'GB',
  },
  {
    symbol: '0RUI-LN',
    name: 'IL Sole 24 ORE S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0RUJ-LN',
    name: 'Delta Plus Group',
    region: 'GB',
  },
  {
    symbol: '0RUK-LN',
    name: 'MOL Hungarian Oil and Gas Plc.',
    region: 'GB',
  },
  {
    symbol: '0RUL-LN',
    name: 'Software AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RUM-LN',
    name: 'Allgeier SE - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0RUN-LN',
    name: 'Rovio Entertainment Corporation',
    region: 'GB',
  },
  {
    symbol: '0RUQ-LN',
    name: 'Anoto Group AB',
    region: 'GB',
  },
  {
    symbol: '0RUU-LN',
    name: 'Starbreeze AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0RUV-LN',
    name: 'Starbreeze AB - A',
    region: 'GB',
  },
  {
    symbol: '0RUW-LN',
    name: 'Balco Group AB',
    region: 'GB',
  },
  {
    symbol: '0RUY-LN',
    name: 'Umicore',
    region: 'GB',
  },
  {
    symbol: '0RUZ-LN',
    name: 'Nilfisk Holding A/S',
    region: 'GB',
  },
  {
    symbol: '0RV0-LN',
    name: 'Reply Spa',
    region: 'GB',
  },
  {
    symbol: '0RV1-LN',
    name: 'Terveystalo Plc',
    region: 'GB',
  },
  {
    symbol: '0RV2-LN',
    name: 'BioArctic AB Class B',
    region: 'GB',
  },
  {
    symbol: '0RVA-LN',
    name: 'SMCP SA',
    region: 'GB',
  },
  {
    symbol: '0RVE-LN',
    name: 'Bawag Group AG',
    region: 'GB',
  },
  {
    symbol: '0RVF-LN',
    name: 'Rolling Optics Holding AB (publ)',
    region: 'GB',
  },
  {
    symbol: '0RVG-LN',
    name: 'Ferronordic AB',
    region: 'GB',
  },
  {
    symbol: '0RVK-LN',
    name: 'Befesa S.A. - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0RW0-LN',
    name: 'Poenina Holding AG',
    region: 'GB',
  },
  {
    symbol: '0S23-LN',
    name: 'Enterprise Products Partners L P - Unit',
    region: 'GB',
  },
  {
    symbol: '0S9E-LN',
    name: 'Extendicare Inc',
    region: 'GB',
  },
  {
    symbol: '0S9F-LN',
    name: 'Total Energy Services Inc',
    region: 'GB',
  },
  {
    symbol: '0SCL-LN',
    name: 'Schlumberger Ltd.',
    region: 'GB',
  },
  {
    symbol: '0SE5-LN',
    name: 'Sensirion Holding AG',
    region: 'GB',
  },
  {
    symbol: '0SE9-LN',
    name: 'Air Canada Inc.',
    region: 'GB',
  },
  {
    symbol: '0SOM-LN',
    name: 'BHG Group AB',
    region: 'GB',
  },
  {
    symbol: '0SR1-LN',
    name: 'EMX Royalty Corp',
    region: 'GB',
  },
  {
    symbol: '0SYQ-LN',
    name: 'Codere SA',
    region: 'GB',
  },
  {
    symbol: '0T6N-LN',
    name: 'Nano One Materials Corp',
    region: 'GB',
  },
  {
    symbol: '0T9J-LN',
    name: 'Orex Minerals Inc',
    region: 'GB',
  },
  {
    symbol: '0TB2-LN',
    name: 'Petroteq Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0TCU-LN',
    name: 'Howmet Aerospace Inc',
    region: 'GB',
  },
  {
    symbol: '0TCZ-LN',
    name: 'Webstep ASA',
    region: 'GB',
  },
  {
    symbol: '0THK-LN',
    name: 'Silver One Resources Inc',
    region: 'GB',
  },
  {
    symbol: '0TK7-LN',
    name: 'Cobalt Blue Holdings Limited',
    region: 'GB',
  },
  {
    symbol: '0TWH-LN',
    name: 'Allkem Ltd',
    region: 'GB',
  },
  {
    symbol: '0TXW-LN',
    name: 'Perseus Mining Limited',
    region: 'GB',
  },
  {
    symbol: '0U3Q-LN',
    name: 'Interdigital Inc',
    region: 'GB',
  },
  {
    symbol: '0U6R-LN',
    name: 'Bunge Ltd.',
    region: 'GB',
  },
  {
    symbol: '0U8N-LN',
    name: 'Credicorp Ltd',
    region: 'GB',
  },
  {
    symbol: '0U96-LN',
    name: 'Everest Re Group Ltd',
    region: 'GB',
  },
  {
    symbol: '0UBI-LN',
    name: 'Marvell Technology Group Ltd',
    region: 'GB',
  },
  {
    symbol: '0UC0-LN',
    name: 'Nordic American Tankers Ltd',
    region: 'GB',
  },
  {
    symbol: '0UED-LN',
    name: 'Tsakos Energy Navigation Limited',
    region: 'GB',
  },
  {
    symbol: '0UFP-LN',
    name: 'Abacus Mining & Exploration Corp.',
    region: 'GB',
  },
  {
    symbol: '0UG9-LN',
    name: 'Advantage Energy Ltd',
    region: 'GB',
  },
  {
    symbol: '0UGB-LN',
    name: 'Aeterna Zentaris Inc.',
    region: 'GB',
  },
  {
    symbol: '0UGP-LN',
    name: 'Aimia Inc',
    region: 'GB',
  },
  {
    symbol: '0UGS-LN',
    name: 'Alamos Gold Inc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0UH1-LN',
    name: 'Alexco Resource Corp',
    region: 'GB',
  },
  {
    symbol: '0UH8-LN',
    name: 'Almaden Minerals Ltd',
    region: 'GB',
  },
  {
    symbol: '0UHC-LN',
    name: 'Altius Minerals Corp.',
    region: 'GB',
  },
  {
    symbol: '0UI8-LN',
    name: 'Aptose Biosciences Inc',
    region: 'GB',
  },
  {
    symbol: '0UIK-LN',
    name: 'Argonaut Gold Inc',
    region: 'GB',
  },
  {
    symbol: '0UIT-LN',
    name: 'Galiano Gold Inc',
    region: 'GB',
  },
  {
    symbol: '0UJF-LN',
    name: 'Aurinia Pharmaceuticals Inc',
    region: 'GB',
  },
  {
    symbol: '0UJK-LN',
    name: 'Fury Gold Mines Ltd.',
    region: 'GB',
  },
  {
    symbol: '0UJN-LN',
    name: 'Avalon Advanced Materials Inc',
    region: 'GB',
  },
  {
    symbol: '0UKH-LN',
    name: 'Bank of Montreal',
    region: 'GB',
  },
  {
    symbol: '0UKI-LN',
    name: 'Bank Of Nova Scotia',
    region: 'GB',
  },
  {
    symbol: '0ULQ-LN',
    name: 'Black Iron Inc',
    region: 'GB',
  },
  {
    symbol: '0UMG-LN',
    name: 'Universal Music Group N.V.',
    region: 'GB',
  },
  {
    symbol: '0UMW-LN',
    name: 'CVR Medical Corp',
    region: 'GB',
  },
  {
    symbol: '0UNE-LN',
    name: 'Global Crossing Airlines Group Inc',
    region: 'GB',
  },
  {
    symbol: '0UNI-LN',
    name: 'Canadian Imperial Bank Of Commerce',
    region: 'GB',
  },
  {
    symbol: '0UNL-LN',
    name: 'Nektar Therapeutics',
    region: 'GB',
  },
  {
    symbol: '0UNV-LN',
    name: 'Canalaska Uranium Ltd',
    region: 'GB',
  },
  {
    symbol: '0UP5-LN',
    name: 'Centerra Gold Inc.',
    region: 'GB',
  },
  {
    symbol: '0UQA-LN',
    name: 'Orea Mining Corp',
    region: 'GB',
  },
  {
    symbol: '0UR4-LN',
    name: 'Corus Entertainment, Inc. - Class B',
    region: 'GB',
  },
  {
    symbol: '0UR7-LN',
    name: 'Crescent Point Energy Corp',
    region: 'GB',
  },
  {
    symbol: '0URG-LN',
    name: 'Cub Energy Inc',
    region: 'GB',
  },
  {
    symbol: '0URY-LN',
    name: 'Denison Mines Corp',
    region: 'GB',
  },
  {
    symbol: '0US7-LN',
    name: 'Pareteum Corp',
    region: 'GB',
  },
  {
    symbol: '0USB-LN',
    name: 'Dolly Varden Silver Corp',
    region: 'GB',
  },
  {
    symbol: '0UTC-LN',
    name: 'Eguana Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0UU0-LN',
    name: 'Energy Fuels Inc',
    region: 'GB',
  },
  {
    symbol: '0UUE-LN',
    name: 'Environmental Waste International Inc.',
    region: 'GB',
  },
  {
    symbol: '0UUM-LN',
    name: 'Essential Energy Services Ltd',
    region: 'GB',
  },
  {
    symbol: '0UV1-LN',
    name: 'Excellon Resources Inc',
    region: 'GB',
  },
  {
    symbol: '0UVO-LN',
    name: 'Finning International Inc.',
    region: 'GB',
  },
  {
    symbol: '0UVX-LN',
    name: 'Electra Battery Materials Corp',
    region: 'GB',
  },
  {
    symbol: '0UW4-LN',
    name: 'Fission Uranium Corp',
    region: 'GB',
  },
  {
    symbol: '0UW6-LN',
    name: 'Fjordland Exploration, Inc.',
    region: 'GB',
  },
  {
    symbol: '0UWG-LN',
    name: 'Fortune Minerals Ltd.',
    region: 'GB',
  },
  {
    symbol: '0UWL-LN',
    name: 'Freehold Royalties Ltd',
    region: 'GB',
  },
  {
    symbol: '0UX6-LN',
    name: 'Garibaldi Resources Corporation.',
    region: 'GB',
  },
  {
    symbol: '0UXL-LN',
    name: 'Giga Metals Corp',
    region: 'GB',
  },
  {
    symbol: '0UY6-LN',
    name: 'Golden Arrow Resources Corp. -New',
    region: 'GB',
  },
  {
    symbol: '0UY9-LN',
    name: 'Golden Goliath Resources Ltd.',
    region: 'GB',
  },
  {
    symbol: '0UYN-LN',
    name: 'GoldMining Inc',
    region: 'GB',
  },
  {
    symbol: '0UYS-LN',
    name: 'GoviEx Uranium Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0V0E-LN',
    name: 'Hudson Resources, Inc.',
    region: 'GB',
  },
  {
    symbol: '0V0V-LN',
    name: 'Identillect Technologies Corp',
    region: 'GB',
  },
  {
    symbol: '0V1I-LN',
    name: 'International Lithium Corp',
    region: 'GB',
  },
  {
    symbol: '0V1L-LN',
    name: 'International Petroleum Corp.',
    region: 'GB',
  },
  {
    symbol: '0V1N-LN',
    name: 'International Tower Hill Mines Ltd',
    region: 'GB',
  },
  {
    symbol: '0V26-LN',
    name: 'Jackpot Digital Inc',
    region: 'GB',
  },
  {
    symbol: '0V29-LN',
    name: 'Jaguar Mining Inc',
    region: 'GB',
  },
  {
    symbol: '0V2X-LN',
    name: 'Sabre Gold Mines Corp',
    region: 'GB',
  },
  {
    symbol: '0V2Y-LN',
    name: 'Kesselrun Resources Ltd',
    region: 'GB',
  },
  {
    symbol: '0V3P-LN',
    name: 'Laramide Resources Ltd.',
    region: 'GB',
  },
  {
    symbol: '0V3V-LN',
    name: 'Leading Edge Materials Corp',
    region: 'GB',
  },
  {
    symbol: '0V43-LN',
    name: 'Lexagene Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0V46-LN',
    name: 'Liberty Gold Corp.',
    region: 'GB',
  },
  {
    symbol: '0V48-LN',
    name: 'Liberty One Lithium Corp',
    region: 'GB',
  },
  {
    symbol: '0V4O-LN',
    name: 'Lomiko Metals Inc',
    region: 'GB',
  },
  {
    symbol: '0V56-LN',
    name: 'MAG Silver Corp.',
    region: 'GB',
  },
  {
    symbol: '0V5G-LN',
    name: 'Maple Leaf Foods Inc.',
    region: 'GB',
  },
  {
    symbol: '0V5H-LN',
    name: 'Manulife Financial Corp.',
    region: 'GB',
  },
  {
    symbol: '0V5Q-LN',
    name: 'Mason Graphite Inc',
    region: 'GB',
  },
  {
    symbol: '0V6R-LN',
    name: 'Perpetua Resources Corp.',
    region: 'GB',
  },
  {
    symbol: '0V6Y-LN',
    name: 'Millrock Resources Inc',
    region: 'GB',
  },
  {
    symbol: '0V7B-LN',
    name: 'Mirasol Resources Ltd',
    region: 'GB',
  },
  {
    symbol: '0V7X-LN',
    name: 'Mountain Province Diamonds Inc',
    region: 'GB',
  },
  {
    symbol: '0V90-LN',
    name: 'New Gold Inc',
    region: 'GB',
  },
  {
    symbol: '0V9B-LN',
    name: 'NewRange Gold Corp',
    region: 'GB',
  },
  {
    symbol: '0V9D-LN',
    name: 'NexGen Energy Ltd',
    region: 'GB',
  },
  {
    symbol: '0VA3-LN',
    name: 'Northern Dynasty Minerals Ltd.',
    region: 'GB',
  },
  {
    symbol: '0VAG-LN',
    name: 'Novanta Inc',
    region: 'GB',
  },
  {
    symbol: '0VAQ-LN',
    name: 'OceanaGold Corporation',
    region: 'GB',
  },
  {
    symbol: '0VBE-LN',
    name: 'Osisko Gold Royalties Ltd',
    region: 'GB',
  },
  {
    symbol: '0VC5-LN',
    name: 'Patriot One Technologies Inc',
    region: 'GB',
  },
  {
    symbol: '0VCO-LN',
    name: 'Peyto Exploration & Development Corp.',
    region: 'GB',
  },
  {
    symbol: '0VD6-LN',
    name: 'Polymet Mining Corp',
    region: 'GB',
  },
  {
    symbol: '0VDT-LN',
    name: 'Silver Elephant Mining Corp',
    region: 'GB',
  },
  {
    symbol: '0VE1-LN',
    name: 'Pure Energy Minerals Ltd',
    region: 'GB',
  },
  {
    symbol: '0VFA-LN',
    name: 'Restaurant Brands International Inc',
    region: 'GB',
  },
  {
    symbol: '0VGE-LN',
    name: 'SSR Mining Inc',
    region: 'GB',
  },
  {
    symbol: '0VGM-LN',
    name: 'Sandstorm Gold Ltd',
    region: 'GB',
  },
  {
    symbol: '0VGV-LN',
    name: 'Seabridge Gold, Inc.',
    region: 'GB',
  },
  {
    symbol: '0VH4-LN',
    name: 'Shamaran Petroleum Corp',
    region: 'GB',
  },
  {
    symbol: '0VHA-LN',
    name: 'Shopify Inc - Class A',
    region: 'GB',
  },
  {
    symbol: '0VHI-LN',
    name: 'SilverCrest Metals Inc',
    region: 'GB',
  },
  {
    symbol: '0VIK-LN',
    name: 'Standard Lithium Ltd',
    region: 'GB',
  },
  {
    symbol: '0VJA-LN',
    name: 'Sun Life Financial, Inc.',
    region: 'GB',
  },
  {
    symbol: '0VKV-LN',
    name: 'Tinka Resources Ltd',
    region: 'GB',
  },
  {
    symbol: '0VL5-LN',
    name: 'Torex Gold Resources Inc',
    region: 'GB',
  },
  {
    symbol: '0VL8-LN',
    name: 'Toronto Dominion Bank',
    region: 'GB',
  },
  {
    symbol: '0VLM-LN',
    name: 'Trevali Mining Corp',
    region: 'GB',
  },
  {
    symbol: '0VLY-LN',
    name: 'Triumph Gold Corp',
    region: 'GB',
  },
  {
    symbol: '0VM4-LN',
    name: 'Turquoise Hill Resources Ltd',
    region: 'GB',
  },
  {
    symbol: '0VNJ-LN',
    name: 'Victoria Gold Corp',
    region: 'GB',
  },
  {
    symbol: '0VNO-LN',
    name: 'Vista Gold Corp.',
    region: 'GB',
  },
  {
    symbol: '0VOA-LN',
    name: 'Wesdome Gold Mines Ltd.',
    region: 'GB',
  },
  {
    symbol: '0VPH-LN',
    name: 'Zinc One Resources Inc',
    region: 'GB',
  },
  {
    symbol: '0VSO-LN',
    name: 'BYD Co - Class H',
    region: 'GB',
  },
  {
    symbol: '0VZ7-LN',
    name: 'Haemato AG',
    region: 'GB',
  },
  {
    symbol: '0W0T-LN',
    name: 'Helma Eigenheimbau AG',
    region: 'GB',
  },
  {
    symbol: '0W10-LN',
    name: 'MagForce AG - Bearer Shares',
    region: 'GB',
  },
  {
    symbol: '0W19-LN',
    name: 'Datagroup SE',
    region: 'GB',
  },
  {
    symbol: '0W1D-LN',
    name: 'AURELIUS Equity Opportunities SE & Co KGaA',
    region: 'GB',
  },
  {
    symbol: '0W1I-LN',
    name: 'Vectron Systems AG.',
    region: 'GB',
  },
  {
    symbol: '0W1Q-LN',
    name: 'MPH Health Care AG',
    region: 'GB',
  },
  {
    symbol: '0W1V-LN',
    name: 'Steico AG',
    region: 'GB',
  },
  {
    symbol: '0W2J-LN',
    name: 'Deutsche Rohstoff AG',
    region: 'GB',
  },
  {
    symbol: '0W2Y-LN',
    name: 'Booking Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0W4N-LN',
    name: 'Formycon AG',
    region: 'GB',
  },
  {
    symbol: '0W8R-LN',
    name: 'Europlasma - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0W8T-LN',
    name: 'Visiativ',
    region: 'GB',
  },
  {
    symbol: '0W8U-LN',
    name: 'Neovacs',
    region: 'GB',
  },
  {
    symbol: '0W8Y-LN',
    name: 'MINT',
    region: 'GB',
  },
  {
    symbol: '0WA2-LN',
    name: 'Cellectis',
    region: 'GB',
  },
  {
    symbol: '0XGF-LN',
    name: 'Avino Silver & Gold Mines Ltd.',
    region: 'GB',
  },
  {
    symbol: '0XGN-LN',
    name: 'Iamgold Corp.',
    region: 'GB',
  },
  {
    symbol: '0XHR-LN',
    name: 'Liberty Global plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0XHS-LN',
    name: 'Liberty Global plc - Class C',
    region: 'GB',
  },
  {
    symbol: '0XI7-LN',
    name: 'Nielsen Holdings plc',
    region: 'GB',
  },
  {
    symbol: '0XI8-LN',
    name: 'Steris Plc',
    region: 'GB',
  },
  {
    symbol: '0XNH-LN',
    name: 'DocuSign Inc',
    region: 'GB',
  },
  {
    symbol: '0XS9-LN',
    name: 'Holmen AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0XVE-LN',
    name: 'Be Semiconductor Industries NV',
    region: 'GB',
  },
  {
    symbol: '0XXT-LN',
    name: 'Atlas Copco AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0XXV-LN',
    name: 'Atlas Copco AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0Y0Y-LN',
    name: 'Accenture plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0Y2A-LN',
    name: 'Seagate Technology Plc',
    region: 'GB',
  },
  {
    symbol: '0Y2S-LN',
    name: 'Trane Technologies plc - Class A',
    region: 'GB',
  },
  {
    symbol: '0Y3K-LN',
    name: 'Eaton Corporation plc',
    region: 'GB',
  },
  {
    symbol: '0Y3M-LN',
    name: 'Prothena Corporation plc',
    region: 'GB',
  },
  {
    symbol: '0Y4Q-LN',
    name: 'Willis Towers Watson Public Limited Co',
    region: 'GB',
  },
  {
    symbol: '0Y5C-LN',
    name: 'Allegion plc',
    region: 'GB',
  },
  {
    symbol: '0Y5E-LN',
    name: 'Perrigo Company plc',
    region: 'GB',
  },
  {
    symbol: '0Y5F-LN',
    name: 'Endo International plc',
    region: 'GB',
  },
  {
    symbol: '0Y5X-LN',
    name: 'Pentair plc',
    region: 'GB',
  },
  {
    symbol: '0Y6X-LN',
    name: 'Medtronic Plc',
    region: 'GB',
  },
  {
    symbol: '0Y7S-LN',
    name: 'Johnson Controls International plc - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0YAL-LN',
    name: 'Boliden AB',
    region: 'GB',
  },
  {
    symbol: '0YAY-LN',
    name: 'Vitrolife AB',
    region: 'GB',
  },
  {
    symbol: '0YCG-LN',
    name: 'Orsero S.p.A.',
    region: 'GB',
  },
  {
    symbol: '0YCP-LN',
    name: 'Aptiv PLC',
    region: 'GB',
  },
  {
    symbol: '0YG7-LN',
    name: 'Beijer Alma AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0YJ2-LN',
    name: 'Japan Display Inc',
    region: 'GB',
  },
  {
    symbol: '0YJC-LN',
    name: 'Marathon Digital Holdings Inc',
    region: 'GB',
  },
  {
    symbol: '0YP5-LN',
    name: 'Adyen NV',
    region: 'GB',
  },
  {
    symbol: '0YSU-LN',
    name: 'Epiroc AB - Class A',
    region: 'GB',
  },
  {
    symbol: '0YSV-LN',
    name: 'Epiroc AB - Class B',
    region: 'GB',
  },
  {
    symbol: '0YTA-LN',
    name: 'Arion Bank hf. - SDR',
    region: 'GB',
  },
  {
    symbol: '0YXG-LN',
    name: 'Broadcom Inc',
    region: 'GB',
  },
  {
    symbol: '0YZ2-LN',
    name: 'Geely Automobile Holdings Ltd.',
    region: 'GB',
  },
  {
    symbol: '0Z1Q-LN',
    name: 'Manchester United Plc. - Class A',
    region: 'GB',
  },
  {
    symbol: '0Z4C-LN',
    name: 'Sika AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0Z4I-LN',
    name: 'Sunny Optical Technology (Group) Co., Ltd.',
    region: 'GB',
  },
  {
    symbol: '0Z4S-LN',
    name: 'Tencent Holdings Ltd.',
    region: 'GB',
  },
  {
    symbol: '0Z62-LN',
    name: 'Keurig Dr Pepper Inc',
    region: 'GB',
  },
  {
    symbol: '0ZNF-LN',
    name: 'Turtle Beach Corp',
    region: 'GB',
  },
  {
    symbol: '0ZPV-LN',
    name: 'Jenoptik AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: '0ZU5-LN',
    name: 'NorZinc Ltd',
    region: 'GB',
  },
  {
    symbol: '0ZW8-LN',
    name: 'Vaa Vista Alegre Atlantis SGPS',
    region: 'GB',
  },
  {
    symbol: '37QB-LN',
    name: 'Halyk Savings Bank of Kazakhstan JSC - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: '4BB-LN',
    name: '4basebio Plc',
    region: 'GB',
  },
  {
    symbol: '51FL-LN',
    name: 'Societatea Energetica Electrica SA - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: '59OT-LN',
    name: 'National Atomic Company Kazatomprom JSC - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: '7DIG-LN',
    name: '7digital Group plc',
    region: 'GB',
  },
  {
    symbol: '80JT-LN',
    name: 'AB Ignitis Grupe - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: '80TE-LN',
    name: 'Kaspi.kz JSC - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: '888-LN',
    name: '888 Hldgs',
    region: 'GB',
  },
  {
    symbol: '88E-LN',
    name: '88 Energy Limited',
    region: 'GB',
  },
  {
    symbol: 'AAAP-LN',
    name: 'Anglo African Agriculture Plc',
    region: 'GB',
  },
  {
    symbol: 'AAAP-PZ',
    name: 'Anglo African Agriculture Plc',
    region: 'GB',
  },
  {
    symbol: 'AAF-LN',
    name: 'Airtel Africa Plc',
    region: 'GB',
  },
  {
    symbol: 'AAL-LN',
    name: 'Anglo American plc',
    region: 'GB',
  },
  {
    symbol: 'AAU-LN',
    name: 'Ariana Resources',
    region: 'GB',
  },
  {
    symbol: 'AAU-PZ',
    name: 'Ariana Resources',
    region: 'GB',
  },
  {
    symbol: 'AAZ-LN',
    name: 'Anglo Asian Mining',
    region: 'GB',
  },
  {
    symbol: 'AAZ-PZ',
    name: 'Anglo Asian Mining',
    region: 'GB',
  },
  {
    symbol: 'ABC-LN',
    name: 'Abcam',
    region: 'GB',
  },
  {
    symbol: 'ABC-PZ',
    name: 'Abcam',
    region: 'GB',
  },
  {
    symbol: 'ABDN-LN',
    name: 'Abrdn Plc',
    region: 'GB',
  },
  {
    symbol: 'ABDP-LN',
    name: 'AB Dynamics plc',
    region: 'GB',
  },
  {
    symbol: 'ABDP-PZ',
    name: 'AB Dynamics plc',
    region: 'GB',
  },
  {
    symbol: 'ABDX-LN',
    name: 'Abingdon Health Plc',
    region: 'GB',
  },
  {
    symbol: 'ABF-LN',
    name: 'Associated British Foods plc',
    region: 'GB',
  },
  {
    symbol: 'ACC-LN',
    name: 'Access Intelligence',
    region: 'GB',
  },
  {
    symbol: 'ACID-LN',
    name: 'Acer Incorporated - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'ACP-LN',
    name: 'Armadale Capital Plc',
    region: 'GB',
  },
  {
    symbol: 'ACRL-LN',
    name: 'Accrol Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'ACSO-LN',
    name: 'Accesso Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ACT-LN',
    name: 'Actual Experience plc',
    region: 'GB',
  },
  {
    symbol: 'ADB-PZ',
    name: 'Adnams plc - Class B',
    region: 'GB',
  },
  {
    symbol: 'ADM-LN',
    name: 'Admiral Group',
    region: 'GB',
  },
  {
    symbol: 'ADME-LN',
    name: 'ADM Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'ADT-LN',
    name: 'Adept Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ADT1-LN',
    name: 'Adriatic Metals Plc',
    region: 'GB',
  },
  {
    symbol: 'ADVT-LN',
    name: 'AdvancedAdvT Limited',
    region: 'GB',
  },
  {
    symbol: 'AEG-LN',
    name: 'Active Energy Group plc',
    region: 'GB',
  },
  {
    symbol: 'AEG-PZ',
    name: 'Active Energy Group plc',
    region: 'GB',
  },
  {
    symbol: 'AEO-LN',
    name: 'Aeorema Communications Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AEP-LN',
    name: 'Anglo-Eastern Plantations plc',
    region: 'GB',
  },
  {
    symbol: 'AEX-LN',
    name: 'Aminex Plc',
    region: 'GB',
  },
  {
    symbol: 'AEXG-LN',
    name: 'AEX Gold Inc',
    region: 'GB',
  },
  {
    symbol: 'AEZD-LN',
    name: 'Ezz Steel - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'AFC-LN',
    name: 'AFC Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'AFM-LN',
    name: 'Alpha Financial Markets Consulting Plc',
    region: 'GB',
  },
  {
    symbol: 'AFN-LN',
    name: 'Advfn plc',
    region: 'GB',
  },
  {
    symbol: 'AFRK-LN',
    name: 'Afarak Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AFRN-LN',
    name: 'Aferian Plc',
    region: 'GB',
  },
  {
    symbol: 'AGL-LN',
    name: 'Angle',
    region: 'GB',
  },
  {
    symbol: 'AGM-LN',
    name: 'Applied Graphene Materials Plc',
    region: 'GB',
  },
  {
    symbol: 'AGTA-LN',
    name: 'Agriterra Limited',
    region: 'GB',
  },
  {
    symbol: 'AGTA-PZ',
    name: 'Agriterra Limited',
    region: 'GB',
  },
  {
    symbol: 'AGY-LN',
    name: 'Allergy Therapeutics Plc',
    region: 'GB',
  },
  {
    symbol: 'AHT-LN',
    name: 'Ashtead Group plc',
    region: 'GB',
  },
  {
    symbol: 'AIBG-LN',
    name: 'AIB Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AIEA-LN',
    name: 'Airea Plc',
    region: 'GB',
  },
  {
    symbol: 'AIEA-PZ',
    name: 'Airea Plc',
    region: 'GB',
  },
  {
    symbol: 'AIQ-LN',
    name: 'AIQ Limited',
    region: 'GB',
  },
  {
    symbol: 'AIRC-LN',
    name: 'Air China Ltd - Class H',
    region: 'GB',
  },
  {
    symbol: 'ALBA-LN',
    name: 'Alba Mineral Resources',
    region: 'GB',
  },
  {
    symbol: 'ALBA-PZ',
    name: 'Alba Mineral Resources',
    region: 'GB',
  },
  {
    symbol: 'ALBH-LN',
    name: 'Aluminium Bahrain B.S.C - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'ALFA-LN',
    name: 'Alfa Financial Software Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'ALL-LN',
    name: 'Atlantic Lithium Limited',
    region: 'GB',
  },
  {
    symbol: 'ALM-LN',
    name: 'Allied Minds Plc',
    region: 'GB',
  },
  {
    symbol: 'ALS-LN',
    name: 'Altus Strategies plc',
    region: 'GB',
  },
  {
    symbol: 'ALT-LN',
    name: 'Altitude Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ALTN-LN',
    name: 'AltynGold Plc',
    region: 'GB',
  },
  {
    symbol: 'ALU-LN',
    name: 'Alumasc Group plc',
    region: 'GB',
  },
  {
    symbol: 'AMC-LN',
    name: 'Amur Minerals Corp',
    region: 'GB',
  },
  {
    symbol: 'AMC-PZ',
    name: 'Amur Minerals Corp',
    region: 'GB',
  },
  {
    symbol: 'AML-LN',
    name: 'Aston Martin Lagonda Global Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'AMS-LN',
    name: 'Advanced Medical Solutions Group',
    region: 'GB',
  },
  {
    symbol: 'AMS-PZ',
    name: 'Advanced Medical Solutions Group',
    region: 'GB',
  },
  {
    symbol: 'AMTE-LN',
    name: 'Amte Power Plc',
    region: 'GB',
  },
  {
    symbol: 'ANCR-LN',
    name: 'Animalcare Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ANCR-PZ',
    name: 'Animalcare Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ANG-LN',
    name: 'Angling Direct Plc',
    region: 'GB',
  },
  {
    symbol: 'ANGS-LN',
    name: 'Angus Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'ANP-LN',
    name: 'Anpario Plc',
    region: 'GB',
  },
  {
    symbol: 'ANR-PZ',
    name: 'Altona Rare Earths Plc',
    region: 'GB',
  },
  {
    symbol: 'ANTO-LN',
    name: 'Antofagasta plc',
    region: 'GB',
  },
  {
    symbol: 'ANX-LN',
    name: 'Anexo Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AOM-LN',
    name: 'ActiveOps Plc',
    region: 'GB',
  },
  {
    symbol: 'APF-LN',
    name: 'Anglo Pacific Group',
    region: 'GB',
  },
  {
    symbol: 'APH-LN',
    name: 'Alliance Pharma plc',
    region: 'GB',
  },
  {
    symbol: 'APOL-PZ',
    name: 'Apollon Formularies Plc (New)',
    region: 'GB',
  },
  {
    symbol: 'APP-LN',
    name: 'Appreciate Group Plc',
    region: 'GB',
  },
  {
    symbol: 'APP-PZ',
    name: 'Appreciate Group Plc',
    region: 'GB',
  },
  {
    symbol: 'APTD-LN',
    name: 'Aptitude Software Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AQRU-PZ',
    name: 'AQRU Plc',
    region: 'GB',
  },
  {
    symbol: 'AQSG-LN',
    name: 'Aquila Services Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ARB-LN',
    name: 'Argo Blockchain Plc',
    region: 'GB',
  },
  {
    symbol: 'ARBB-LN',
    name: 'Arbuthnot Banking Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'ARBB-PZ',
    name: 'Arbuthnot Banking Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'ARBN-PZ',
    name: 'Arbuthnot Banking Group Plc. (Non Voting)',
    region: 'GB',
  },
  {
    symbol: 'ARC-LN',
    name: 'Arcontech Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ARCM-LN',
    name: 'Arc Minerals Limited',
    region: 'GB',
  },
  {
    symbol: 'ARG-LN',
    name: 'Argos Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'ARG-PZ',
    name: 'Argos Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'ARGO-LN',
    name: 'Argo Group Ltd',
    region: 'GB',
  },
  {
    symbol: 'ARK-LN',
    name: 'Arkle Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'ARK-PZ',
    name: 'Arkle Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'ARS-LN',
    name: 'Asiamet Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'ART-LN',
    name: 'Artisanal Spirits Company Plc',
    region: 'GB',
  },
  {
    symbol: 'ARV-LN',
    name: 'Artemis Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'ASBE-LN',
    name: 'Associated British Engineering',
    region: 'GB',
  },
  {
    symbol: 'ASC-LN',
    name: 'Asos plc',
    region: 'GB',
  },
  {
    symbol: 'ASCL-LN',
    name: 'Ascential plc',
    region: 'GB',
  },
  {
    symbol: 'ASIA-LN',
    name: 'Asia Strategic Holdings Limited',
    region: 'GB',
  },
  {
    symbol: 'ASLR-LN',
    name: 'Asimilar Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ASLR-PZ',
    name: 'Asimilar Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ASMO-PZ',
    name: 'All Star Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'AST-LN',
    name: 'Ascent Resources',
    region: 'GB',
  },
  {
    symbol: 'ASTO-LN',
    name: 'Assetco Plc',
    region: 'GB',
  },
  {
    symbol: 'ASY-LN',
    name: 'Andrews Sykes Group plc',
    region: 'GB',
  },
  {
    symbol: 'ASY-PZ',
    name: 'Andrews Sykes Group plc',
    region: 'GB',
  },
  {
    symbol: 'ATG-LN',
    name: 'Auction Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ATM-LN',
    name: 'Afritin Mining Limited',
    region: 'GB',
  },
  {
    symbol: 'ATQT-LN',
    name: 'Attraqt Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ATY-LN',
    name: 'Athelney Trust',
    region: 'GB',
  },
  {
    symbol: 'ATYM-LN',
    name: 'Atalaya Mining Plc',
    region: 'GB',
  },
  {
    symbol: 'AUK-LN',
    name: 'Aukett Swanke Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'AURA-LN',
    name: 'Aura Energy Limited',
    region: 'GB',
  },
  {
    symbol: 'AUTG-LN',
    name: 'Autins Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AUTO-LN',
    name: 'Auto Trader Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AUY-LN',
    name: 'Yamana Gold Inc.',
    region: 'GB',
  },
  {
    symbol: 'AVAP-LN',
    name: 'Avation Plc',
    region: 'GB',
  },
  {
    symbol: 'AVCT-LN',
    name: 'Avacta Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AVG-LN',
    name: 'Avingtrans plc',
    region: 'GB',
  },
  {
    symbol: 'AVGR-LN',
    name: 'AvangardCo Investments - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'AVO-LN',
    name: 'Advanced Oncotherapy Plc',
    region: 'GB',
  },
  {
    symbol: 'AVON-LN',
    name: 'Avon Protection Plc',
    region: 'GB',
  },
  {
    symbol: 'AVST-LN',
    name: 'Avast PLC',
    region: 'GB',
  },
  {
    symbol: 'AVV-LN',
    name: 'Aveva Group',
    region: 'GB',
  },
  {
    symbol: 'AWE-LN',
    name: 'Alphawave IP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'AXB-LN',
    name: 'Axis Bank Ltd - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'AXBA-LN',
    name: 'Axis Bank Ltd - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'AXL-LN',
    name: 'Arrow Exploration Corp.',
    region: 'GB',
  },
  {
    symbol: 'AXS-LN',
    name: 'Accsys Technologies',
    region: 'GB',
  },
  {
    symbol: 'AXS-PZ',
    name: 'Accsys Technologies',
    region: 'GB',
  },
  {
    symbol: 'AYM-LN',
    name: 'Anglesey Mining plc',
    region: 'GB',
  },
  {
    symbol: 'AZN-LN',
    name: 'Astrazeneca plc',
    region: 'GB',
  },
  {
    symbol: 'B90-LN',
    name: 'B90 Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'BAB-LN',
    name: 'Babcock International Group plc',
    region: 'GB',
  },
  {
    symbol: 'BAG-LN',
    name: 'Barr(A.G.) plc',
    region: 'GB',
  },
  {
    symbol: 'BAKK-LN',
    name: 'Bakkavor Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BAR-LN',
    name: 'Brand Architekts Group plc',
    region: 'GB',
  },
  {
    symbol: 'BARC-LN',
    name: 'Barclays plc',
    region: 'GB',
  },
  {
    symbol: 'BARK-LN',
    name: 'Barkby Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BATS-LN',
    name: 'British American Tobacco Plc',
    region: 'GB',
  },
  {
    symbol: 'BBB-LN',
    name: 'Bigblu Broadband plc',
    region: 'GB',
  },
  {
    symbol: 'BBSN-LN',
    name: 'Brave Bison Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BBY-LN',
    name: 'Balfour Beatty plc',
    region: 'GB',
  },
  {
    symbol: 'BCG-LN',
    name: 'Baltic Classifieds Group PLC',
    region: 'GB',
  },
  {
    symbol: 'BDEV-LN',
    name: 'Barratt Developments plc',
    region: 'GB',
  },
  {
    symbol: 'BEG-LN',
    name: 'Begbies Traynor Group',
    region: 'GB',
  },
  {
    symbol: 'BEG-PZ',
    name: 'Begbies Traynor Group',
    region: 'GB',
  },
  {
    symbol: 'BEM-LN',
    name: 'Beowulf Mining plc',
    region: 'GB',
  },
  {
    symbol: 'BEZ-LN',
    name: 'Beazley Plc.',
    region: 'GB',
  },
  {
    symbol: 'BGEO-LN',
    name: 'Bank of Georgia Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BGO-LN',
    name: 'bango',
    region: 'GB',
  },
  {
    symbol: 'BHL-LN',
    name: 'Bradda Head Lithium Limited',
    region: 'GB',
  },
  {
    symbol: 'BHP-LN',
    name: 'BHP Group Limited',
    region: 'GB',
  },
  {
    symbol: 'BIDS-LN',
    name: 'Bidstack Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BIFF-LN',
    name: 'Biffa Plc',
    region: 'GB',
  },
  {
    symbol: 'BIG-LN',
    name: 'Big Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'BIH-LN',
    name: 'Boston International Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'BILN-LN',
    name: 'Billington Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'BIOM-LN',
    name: 'Biome Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'BIOM-PZ',
    name: 'Biome Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'BION-LN',
    name: 'BiON Plc',
    region: 'GB',
  },
  {
    symbol: 'BIRD-LN',
    name: 'Blackbird Plc',
    region: 'GB',
  },
  {
    symbol: 'BIRG-LN',
    name: 'Bank of Ireland Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BISI-LN',
    name: 'Bisichi Plc',
    region: 'GB',
  },
  {
    symbol: 'BKG-LN',
    name: 'Berkeley Group Holdings',
    region: 'GB',
  },
  {
    symbol: 'BKM-LN',
    name: 'Bank Muscat - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'BKS-LN',
    name: 'Beeks Financial Cloud Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BKY-LN',
    name: 'Berkeley Energia Limited',
    region: 'GB',
  },
  {
    symbol: 'BLND-LN',
    name: 'British Land Co plc',
    region: 'GB',
  },
  {
    symbol: 'BLOE-LN',
    name: 'Block Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'BLTG-LN',
    name: 'Blancco Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BLTG-PZ',
    name: 'Blancco Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BLV-LN',
    name: 'Belvoir Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BLV-PZ',
    name: 'Belvoir Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BLVN-LN',
    name: 'Bowleven',
    region: 'GB',
  },
  {
    symbol: 'BLVN-PZ',
    name: 'Bowleven',
    region: 'GB',
  },
  {
    symbol: 'BME-LN',
    name: 'B&M European Value Retail S.A.',
    region: 'GB',
  },
  {
    symbol: 'BMK-LN',
    name: 'Benchmark Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'BMN-LN',
    name: 'Bushveld Minerals Limited',
    region: 'GB',
  },
  {
    symbol: 'BMN-PZ',
    name: 'Bushveld Minerals Limited',
    region: 'GB',
  },
  {
    symbol: 'BMS-LN',
    name: 'Braemar Shipping Services Plc',
    region: 'GB',
  },
  {
    symbol: 'BMT-LN',
    name: 'Braime Group Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'BMTO-LN',
    name: 'Braime Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BMV-LN',
    name: 'Bluebird Merchant Ventures Ltd',
    region: 'GB',
  },
  {
    symbol: 'BMY-LN',
    name: 'Bloomsbury Publishing plc',
    region: 'GB',
  },
  {
    symbol: 'BNC-LN',
    name: 'Banco Santander S.A.',
    region: 'GB',
  },
  {
    symbol: 'BNZL-LN',
    name: 'Bunzl plc',
    region: 'GB',
  },
  {
    symbol: 'BOCH-LN',
    name: 'Bank of Cyprus Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'BOD-LN',
    name: 'Botswana Diamonds plc',
    region: 'GB',
  },
  {
    symbol: 'BOD-PZ',
    name: 'Botswana Diamonds plc',
    region: 'GB',
  },
  {
    symbol: 'BOE-LN',
    name: 'Boeing Co.',
    region: 'GB',
  },
  {
    symbol: 'BOIL-LN',
    name: 'Baron Oil Plc',
    region: 'GB',
  },
  {
    symbol: 'BOIL-PZ',
    name: 'Baron Oil Plc',
    region: 'GB',
  },
  {
    symbol: 'BOKU-LN',
    name: 'Boku Inc.',
    region: 'GB',
  },
  {
    symbol: 'BONH-LN',
    name: 'Bonhill Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BOO-LN',
    name: 'Boohoo Group Plc - Ordinary Share',
    region: 'GB',
  },
  {
    symbol: 'BOO-PZ',
    name: 'Boohoo Group Plc - Ordinary Share',
    region: 'GB',
  },
  {
    symbol: 'BOOM-LN',
    name: 'Audioboom Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BOOT-LN',
    name: 'Boot (Henry)',
    region: 'GB',
  },
  {
    symbol: 'BOR-LN',
    name: 'Borders & Southern Petroleum.',
    region: 'GB',
  },
  {
    symbol: 'BOR-PZ',
    name: 'Borders & Southern Petroleum.',
    region: 'GB',
  },
  {
    symbol: 'BOTB-LN',
    name: 'Best of the Best Plc',
    region: 'GB',
  },
  {
    symbol: 'BOWL-LN',
    name: 'Hollywood Bowl Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BOY-LN',
    name: 'Bodycote Plc',
    region: 'GB',
  },
  {
    symbol: 'BPM-LN',
    name: 'B.P.Marsh & Partners',
    region: 'GB',
  },
  {
    symbol: 'BRBY-LN',
    name: 'Burberry Group',
    region: 'GB',
  },
  {
    symbol: 'BRCK-LN',
    name: 'Brickability Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BRD-LN',
    name: 'Bluerock Diamonds Plc',
    region: 'GB',
  },
  {
    symbol: 'BRD-PZ',
    name: 'Bluerock Diamonds Plc',
    region: 'GB',
  },
  {
    symbol: 'BREE-LN',
    name: 'Breedon Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BREE-PZ',
    name: 'Breedon Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BRES-LN',
    name: 'Blencowe Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'BRH-LN',
    name: 'Braveheart Investment Group',
    region: 'GB',
  },
  {
    symbol: 'BRK-LN',
    name: 'Brooks Macdonald Group',
    region: 'GB',
  },
  {
    symbol: 'BRK-PZ',
    name: 'Brooks Macdonald Group',
    region: 'GB',
  },
  {
    symbol: 'BRSD-LN',
    name: 'BrandShield Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'BRSD-PZ',
    name: 'BrandShield Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'BSE-LN',
    name: 'Base Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'BSE-PZ',
    name: 'Base Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'BT.A-LN',
    name: 'BT Group',
    region: 'GB',
  },
  {
    symbol: 'BVA-LN',
    name: 'Banco Bilbao Vizcaya Argentaria.',
    region: 'GB',
  },
  {
    symbol: 'BVC-LN',
    name: 'BATM Advanced Communications Limited',
    region: 'GB',
  },
  {
    symbol: 'BVIC-LN',
    name: 'Britvic',
    region: 'GB',
  },
  {
    symbol: 'BVX-LN',
    name: 'Bivictrix Therapeutics Plc',
    region: 'GB',
  },
  {
    symbol: 'BVXP-LN',
    name: 'Bioventix Plc',
    region: 'GB',
  },
  {
    symbol: 'BVXP-PZ',
    name: 'Bioventix Plc',
    region: 'GB',
  },
  {
    symbol: 'BWAP-PZ',
    name: 'BWA Group',
    region: 'GB',
  },
  {
    symbol: 'BWNG-LN',
    name: 'Brown (N.) Group plc',
    region: 'GB',
  },
  {
    symbol: 'BWY-LN',
    name: 'Bellway plc',
    region: 'GB',
  },
  {
    symbol: 'BXP-LN',
    name: 'Beximco Pharmaceuticals Limited - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'BYG-LN',
    name: 'Big Yellow Group plc',
    region: 'GB',
  },
  {
    symbol: 'BYIT-LN',
    name: 'Bytes Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'BYOT-LN',
    name: 'Byotrol',
    region: 'GB',
  },
  {
    symbol: 'BZT-LN',
    name: 'Bezant Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'C4XD-LN',
    name: 'C4X Discovery Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'CAD-LN',
    name: 'Cadogan Petroleum',
    region: 'GB',
  },
  {
    symbol: 'CAM-LN',
    name: 'Camellia',
    region: 'GB',
  },
  {
    symbol: 'CAML-LN',
    name: 'Central Asia Metals Plc',
    region: 'GB',
  },
  {
    symbol: 'CAML-PZ',
    name: 'Central Asia Metals Plc',
    region: 'GB',
  },
  {
    symbol: 'CAPD-LN',
    name: 'Capital Limited',
    region: 'GB',
  },
  {
    symbol: 'CAR-LN',
    name: 'Carclo plc',
    region: 'GB',
  },
  {
    symbol: 'CARD-LN',
    name: 'Card Factory Plc',
    region: 'GB',
  },
  {
    symbol: 'CARR-LN',
    name: 'Carrs Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CASP-LN',
    name: 'Caspian Sunrise Plc',
    region: 'GB',
  },
  {
    symbol: 'CAU-LN',
    name: 'Centaur Media',
    region: 'GB',
  },
  {
    symbol: 'CBG-LN',
    name: 'Close Brothers Group plc',
    region: 'GB',
  },
  {
    symbol: 'CBKD-LN',
    name: 'Commercial International Bank (Egypt) - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'CBOX-LN',
    name: 'Cake Box Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CBQS-LN',
    name: 'The Commercial Bank - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'CBX-LN',
    name: 'Cellular Goods Plc',
    region: 'GB',
  },
  {
    symbol: 'CCC-LN',
    name: 'Computacenter',
    region: 'GB',
  },
  {
    symbol: 'CCEP-LN',
    name: 'Coca-Cola Europacific Partners Plc',
    region: 'GB',
  },
  {
    symbol: 'CCH-LN',
    name: 'Coca Cola HBC AG',
    region: 'GB',
  },
  {
    symbol: 'CCL-LN',
    name: 'Carnival plc',
    region: 'GB',
  },
  {
    symbol: 'CCP-LN',
    name: 'Celtic plc',
    region: 'GB',
  },
  {
    symbol: 'CCR-LN',
    name: 'C&C Group',
    region: 'GB',
  },
  {
    symbol: 'CCS-LN',
    name: 'Crossword Cybersecurity Plc',
    region: 'GB',
  },
  {
    symbol: 'CCT-LN',
    name: 'Character Group plc',
    region: 'GB',
  },
  {
    symbol: 'CCT-PZ',
    name: 'Character Group plc',
    region: 'GB',
  },
  {
    symbol: 'CCZ-LN',
    name: 'Castillo Copper Limited',
    region: 'GB',
  },
  {
    symbol: 'CDGP-PZ',
    name: 'Chapel Down Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CDL-LN',
    name: 'Cloudbreak Discovery Plc',
    region: 'GB',
  },
  {
    symbol: 'CEG-LN',
    name: 'Challenger Energy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CEG-PZ',
    name: 'Challenger Energy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CEIR-LN',
    name: 'Compal Electronics Inc. - GDR - Reg S (Sponsored)',
    region: 'GB',
  },
  {
    symbol: 'CEPS-LN',
    name: 'Ceps Plc',
    region: 'GB',
  },
  {
    symbol: 'CER-LN',
    name: 'Cerillion PLC',
    region: 'GB',
  },
  {
    symbol: 'CEY-LN',
    name: 'Centamin Plc',
    region: 'GB',
  },
  {
    symbol: 'CFHS-LN',
    name: 'Cathay Financial Holding Co. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'CFX-LN',
    name: 'Colefax Group plc',
    region: 'GB',
  },
  {
    symbol: 'CFYN-LN',
    name: 'Caffyns Plc',
    region: 'GB',
  },
  {
    symbol: 'CGEO-LN',
    name: 'Georgia Capital Plc',
    region: 'GB',
  },
  {
    symbol: 'CGH-PZ',
    name: 'Chaarat Gold Hldgs Ltd',
    region: 'GB',
  },
  {
    symbol: 'CGNR-LN',
    name: 'Conroy Gold and Natural Resources plc',
    region: 'GB',
  },
  {
    symbol: 'CGS-LN',
    name: 'Castings plc',
    region: 'GB',
  },
  {
    symbol: 'CHAR-LN',
    name: 'Chariot Limited',
    region: 'GB',
  },
  {
    symbol: 'CHAR-PZ',
    name: 'Chariot Limited',
    region: 'GB',
  },
  {
    symbol: 'CHG-LN',
    name: 'Chemring Group plc',
    region: 'GB',
  },
  {
    symbol: 'CHH-LN',
    name: 'Churchill China plc',
    region: 'GB',
  },
  {
    symbol: 'CHLL-LN',
    name: 'Chill Brands Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CHRT-LN',
    name: 'Cohort',
    region: 'GB',
  },
  {
    symbol: 'CHRT-PZ',
    name: 'Cohort',
    region: 'GB',
  },
  {
    symbol: 'CIA-PZ',
    name: 'Clean Invest Africa Plc',
    region: 'GB',
  },
  {
    symbol: 'CIN-LN',
    name: 'City of London Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CINE-LN',
    name: 'Cineworld Group',
    region: 'GB',
  },
  {
    symbol: 'CIR-LN',
    name: 'Circassia Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CIZ-LN',
    name: 'Cizzle Biotechnology Holdings Plc.',
    region: 'GB',
  },
  {
    symbol: 'CKN-LN',
    name: 'Clarkson',
    region: 'GB',
  },
  {
    symbol: 'CKT-LN',
    name: 'Checkit Plc',
    region: 'GB',
  },
  {
    symbol: 'CLCO-LN',
    name: 'CloudCoCo Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CLG-LN',
    name: 'Clipper Logistics Plc',
    region: 'GB',
  },
  {
    symbol: 'CLIG-LN',
    name: 'City of London Investment Group',
    region: 'GB',
  },
  {
    symbol: 'CLLN-LN',
    name: 'Carillion plc',
    region: 'GB',
  },
  {
    symbol: 'CLON-LN',
    name: 'Clontarf Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'CLX-LN',
    name: 'Calnex Solutions Plc',
    region: 'GB',
  },
  {
    symbol: 'CMB-LN',
    name: 'Cambria Africa Plc',
    region: 'GB',
  },
  {
    symbol: 'CMCL-LN',
    name: 'Caledonia Mining Corporation Plc',
    region: 'GB',
  },
  {
    symbol: 'CMET-LN',
    name: 'Capital Metals Plc',
    region: 'GB',
  },
  {
    symbol: 'CMH-LN',
    name: 'Chamberlin Plc',
    region: 'GB',
  },
  {
    symbol: 'CMH-PZ',
    name: 'Chamberlin Plc',
    region: 'GB',
  },
  {
    symbol: 'CML-LN',
    name: 'CML Microsystems plc',
    region: 'GB',
  },
  {
    symbol: 'CMRS-LN',
    name: 'Caerus Mineral Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'CMX-LN',
    name: 'Catalyst Media Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CNA-LN',
    name: 'Centrica plc',
    region: 'GB',
  },
  {
    symbol: 'CNC-LN',
    name: 'Concurrent Technologies',
    region: 'GB',
  },
  {
    symbol: 'CNE-LN',
    name: 'Capricorn Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'CNG-LN',
    name: 'China Nonferrous Gold Limited',
    region: 'GB',
  },
  {
    symbol: 'CNG-PZ',
    name: 'China Nonferrous Gold Limited',
    region: 'GB',
  },
  {
    symbol: 'CNIC-LN',
    name: 'Centralnic Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CNN-LN',
    name: 'Caledonian Trust',
    region: 'GB',
  },
  {
    symbol: 'CNR-LN',
    name: 'Condor Gold Plc',
    region: 'GB',
  },
  {
    symbol: 'CNS-LN',
    name: 'Corero Network Security Plc',
    region: 'GB',
  },
  {
    symbol: 'CNSD-LN',
    name: 'China Steel - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'COA-LN',
    name: 'Coats Group Plc',
    region: 'GB',
  },
  {
    symbol: 'COBR-LN',
    name: 'Cobra Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'COD-LN',
    name: 'Compagnie de Saint-Gobain S.A.',
    region: 'GB',
  },
  {
    symbol: 'COG-LN',
    name: 'Cambridge Cognition Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'COG-PZ',
    name: 'Cambridge Cognition Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'COM-LN',
    name: 'Comptoir Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CORA-LN',
    name: 'Cora Gold Limited',
    region: 'GB',
  },
  {
    symbol: 'CORO-LN',
    name: 'Coro Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'COST-LN',
    name: 'Costain Group PLC',
    region: 'GB',
  },
  {
    symbol: 'CPC-LN',
    name: 'City Pub Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CPG-LN',
    name: 'Compass Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CPI-LN',
    name: 'Capita Plc',
    region: 'GB',
  },
  {
    symbol: 'CPIC-LN',
    name: 'China Pacific Insurance (Group) Co Ltd - GDR',
    region: 'GB',
  },
  {
    symbol: 'CPP-LN',
    name: 'CPP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CPP-PZ',
    name: 'CPP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CPX-LN',
    name: 'Cap-XX',
    region: 'GB',
  },
  {
    symbol: 'CRCL-LN',
    name: 'Corcel Plc',
    region: 'GB',
  },
  {
    symbol: 'CRCL-PZ',
    name: 'Corcel Plc',
    region: 'GB',
  },
  {
    symbol: 'CRDA-LN',
    name: 'Croda International plc',
    region: 'GB',
  },
  {
    symbol: 'CRDL-LN',
    name: 'Cordel Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CRE-LN',
    name: 'Conduit Holdings Limited',
    region: 'GB',
  },
  {
    symbol: 'CREO-LN',
    name: 'Creo Medical Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CRH-LN',
    name: 'CRH Plc',
    region: 'GB',
  },
  {
    symbol: 'CRL-LN',
    name: 'Creightons',
    region: 'GB',
  },
  {
    symbol: 'CRN-LN',
    name: 'Cairn Homes Plc',
    region: 'GB',
  },
  {
    symbol: 'CRPR-LN',
    name: 'Cropper (James) plc',
    region: 'GB',
  },
  {
    symbol: 'CRST-LN',
    name: 'Crest Nicholson Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CRU-LN',
    name: 'Coral Products',
    region: 'GB',
  },
  {
    symbol: 'CRU-PZ',
    name: 'Coral Products',
    region: 'GB',
  },
  {
    symbol: 'CRV-LN',
    name: 'Craven House Capital Plc',
    region: 'GB',
  },
  {
    symbol: 'CRW-LN',
    name: 'Craneware Plc',
    region: 'GB',
  },
  {
    symbol: 'CRW-PZ',
    name: 'Craneware Plc',
    region: 'GB',
  },
  {
    symbol: 'CSFS-LN',
    name: 'Cornerstone FS Plc',
    region: 'GB',
  },
  {
    symbol: 'CSN-LN',
    name: 'Chesnara plc',
    region: 'GB',
  },
  {
    symbol: 'CSP-LN',
    name: 'Countryside Partnerships Plc',
    region: 'GB',
  },
  {
    symbol: 'CSSG-LN',
    name: 'Croma Security Solutions Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CTA-LN',
    name: 'CT Automotive Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CTEA-LN',
    name: 'Catenae Innovation Plc',
    region: 'GB',
  },
  {
    symbol: 'CTEC-LN',
    name: 'ConvaTec Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CTG-LN',
    name: 'Christie Group',
    region: 'GB',
  },
  {
    symbol: 'CTH-LN',
    name: 'CareTech Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CTH-PZ',
    name: 'CareTech Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CTO-LN',
    name: 'TClarke Plc',
    region: 'GB',
  },
  {
    symbol: 'CURY-LN',
    name: 'Currys plc',
    region: 'GB',
  },
  {
    symbol: 'CUSN-LN',
    name: 'Cornish Metals Inc.',
    region: 'GB',
  },
  {
    symbol: 'CVH-LN',
    name: 'Cablevision Holding S.A. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'CVSG-LN',
    name: 'CVS Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CVSG-PZ',
    name: 'CVS Group Plc',
    region: 'GB',
  },
  {
    symbol: 'CWK-LN',
    name: 'Cranswick plc',
    region: 'GB',
  },
  {
    symbol: 'CWR-LN',
    name: 'Ceres Power Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CWR-PZ',
    name: 'Ceres Power Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CYAN-LN',
    name: 'CyanConnode Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'CYPC-LN',
    name: 'China Yangtze Power Co - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'D4T4-LN',
    name: 'D4t4 Solutions Plc',
    region: 'GB',
  },
  {
    symbol: 'D4T4-PZ',
    name: 'D4t4 Solutions Plc',
    region: 'GB',
  },
  {
    symbol: 'DAL-LN',
    name: 'Dalata Hotel Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DARK-LN',
    name: 'Darktrace Plc',
    region: 'GB',
  },
  {
    symbol: 'DAT-LN',
    name: 'Datang International Power Generation Co., Ltd. - Class H',
    region: 'GB',
  },
  {
    symbol: 'DATA-LN',
    name: 'GlobalData Plc',
    region: 'GB',
  },
  {
    symbol: 'DBOX-LN',
    name: 'Digitalbox Plc',
    region: 'GB',
  },
  {
    symbol: 'DCC-LN',
    name: 'DCC Plc',
    region: 'GB',
  },
  {
    symbol: 'DCD-LN',
    name: 'DCD Media Plc',
    region: 'GB',
  },
  {
    symbol: 'DCTA-LN',
    name: 'Directa Plus Plc',
    region: 'GB',
  },
  {
    symbol: 'DDDD-LN',
    name: '4d Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'DEC-LN',
    name: 'Diversified Energy Company Plc',
    region: 'GB',
  },
  {
    symbol: 'DELT-LN',
    name: 'Deltic Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'DELT-PZ',
    name: 'Deltic Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'DEMG-LN',
    name: 'Deltex Medical Group plc',
    region: 'GB',
  },
  {
    symbol: 'DEST-LN',
    name: 'Destiny Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'DFIJ-LN',
    name: 'Dairy Farm International Holdings Ltd.',
    region: 'GB',
  },
  {
    symbol: 'DFS-LN',
    name: 'DFS Furniture Plc',
    region: 'GB',
  },
  {
    symbol: 'DGE-LN',
    name: 'Diageo plc',
    region: 'GB',
  },
  {
    symbol: 'DGED-LN',
    name: 'Diageo plc - ADR',
    region: 'GB',
  },
  {
    symbol: 'DIA-LN',
    name: 'Dialight Plc',
    region: 'GB',
  },
  {
    symbol: 'DIS-LN',
    name: 'Distil plc',
    region: 'GB',
  },
  {
    symbol: 'DIS-PZ',
    name: 'Distil plc',
    region: 'GB',
  },
  {
    symbol: 'DISH-LN',
    name: 'Amala Foods Plc',
    region: 'GB',
  },
  {
    symbol: 'DKL-LN',
    name: 'Dekel Agri-Vision Plc',
    region: 'GB',
  },
  {
    symbol: 'DKL-PZ',
    name: 'Dekel Agri-Vision Plc',
    region: 'GB',
  },
  {
    symbol: 'DLAR-LN',
    name: 'De La Rue plc',
    region: 'GB',
  },
  {
    symbol: 'DLG-LN',
    name: 'Direct Line Insurance Group plc',
    region: 'GB',
  },
  {
    symbol: 'DMTR-LN',
    name: 'DeepMatter Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DNA-LN',
    name: 'Doric Nimrod Air One Ltd - 9% PRF PERPETUAL GBP',
    region: 'GB',
  },
  {
    symbol: 'DNA2-LN',
    name: 'Doric Nimrod Air Two Ltd - PRF PERPETUAL GBP',
    region: 'GB',
  },
  {
    symbol: 'DNA3-LN',
    name: 'Doric Nimrod Air Three Ltd - 8.25% PRF PERPETUAL GBP',
    region: 'GB',
  },
  {
    symbol: 'DNL-LN',
    name: 'Diurnal Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DNLM-LN',
    name: 'Dunelm Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DNM-LN',
    name: 'Dianomi Plc',
    region: 'GB',
  },
  {
    symbol: 'DOCS-LN',
    name: 'Dr. Martens Plc',
    region: 'GB',
  },
  {
    symbol: 'DOM-LN',
    name: 'Domino`s Pizza Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DOTD-LN',
    name: 'dotDigital Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DOTD-PZ',
    name: 'dotDigital Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DPA-LN',
    name: 'DP Aircraft I Ltd - Ordinary Preference Shares',
    region: 'GB',
  },
  {
    symbol: 'DPEU-LN',
    name: 'DP Eurasia N.V',
    region: 'GB',
  },
  {
    symbol: 'DPH-LN',
    name: 'Dechra Pharmaceuticals plc',
    region: 'GB',
  },
  {
    symbol: 'DPLM-LN',
    name: 'Diploma plc',
    region: 'GB',
  },
  {
    symbol: 'DPP-LN',
    name: 'DP Poland Plc',
    region: 'GB',
  },
  {
    symbol: 'DRUM-LN',
    name: 'Drumz Plc',
    region: 'GB',
  },
  {
    symbol: 'DRV-LN',
    name: 'Driver Group',
    region: 'GB',
  },
  {
    symbol: 'DRX-LN',
    name: 'Drax Group',
    region: 'GB',
  },
  {
    symbol: 'DSCV-LN',
    name: 'DiscoverIE Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DSG-LN',
    name: 'Dillistone Group',
    region: 'GB',
  },
  {
    symbol: 'DSG-PZ',
    name: 'Dillistone Group',
    region: 'GB',
  },
  {
    symbol: 'DTVL-LN',
    name: 'Dish TV India Ltd - GDR - Reg S 25471A401',
    region: 'GB',
  },
  {
    symbol: 'DTY-LN',
    name: 'Dignity',
    region: 'GB',
  },
  {
    symbol: 'DVO-LN',
    name: 'Devro plc',
    region: 'GB',
  },
  {
    symbol: 'DVRG-LN',
    name: 'Deepverge Plc',
    region: 'GB',
  },
  {
    symbol: 'DVT-LN',
    name: 'daVictus plc',
    region: 'GB',
  },
  {
    symbol: 'DWF-LN',
    name: 'DWF Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DWHA-LN',
    name: 'Dewhurst Group Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'DWHT-LN',
    name: 'Dewhurst Group Plc',
    region: 'GB',
  },
  {
    symbol: 'DXRX-LN',
    name: 'Diaceutics Plc',
    region: 'GB',
  },
  {
    symbol: 'DXSP-PZ',
    name: 'DXS International Plc',
    region: 'GB',
  },
  {
    symbol: 'EAAS-LN',
    name: 'eEnergy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EAH-LN',
    name: 'Eco Animal Health Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EBQ-LN',
    name: 'Ebiquity plc',
    region: 'GB',
  },
  {
    symbol: 'ECEL-LN',
    name: 'Eurocell Plc',
    region: 'GB',
  },
  {
    symbol: 'ECHO-LN',
    name: 'Echo Energy PLC',
    region: 'GB',
  },
  {
    symbol: 'ECK-LN',
    name: 'Eckoh Plc',
    region: 'GB',
  },
  {
    symbol: 'ECM-LN',
    name: 'Electrocomponents plc',
    region: 'GB',
  },
  {
    symbol: 'ECO-LN',
    name: 'Eco (Atlantic) Oil & Gas Ltd',
    region: 'GB',
  },
  {
    symbol: 'ECP-PZ',
    name: 'Eight Capital Partners Plc',
    region: 'GB',
  },
  {
    symbol: 'ECR-LN',
    name: 'ECR Minerals plc',
    region: 'GB',
  },
  {
    symbol: 'ECSC-LN',
    name: 'ECSC Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EDEN-LN',
    name: 'Eden Research',
    region: 'GB',
  },
  {
    symbol: 'EDEN-PZ',
    name: 'Eden Research',
    region: 'GB',
  },
  {
    symbol: 'EDL-LN',
    name: 'Edenville Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'EDR-LN',
    name: 'Egdon Resources Plc.',
    region: 'GB',
  },
  {
    symbol: 'EDR-PZ',
    name: 'Egdon Resources Plc.',
    region: 'GB',
  },
  {
    symbol: 'EDV-LN',
    name: 'Endeavour Mining Plc',
    region: 'GB',
  },
  {
    symbol: 'EEE-LN',
    name: 'Empire Metals Limited',
    region: 'GB',
  },
  {
    symbol: 'EEE-PZ',
    name: 'Empire Metals Limited',
    region: 'GB',
  },
  {
    symbol: 'EFGD-LN',
    name: 'EFG Hermes Hldgs. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'EFID-LN',
    name: 'Edita Food Industries S.A.E - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'EGY-LN',
    name: 'VAALCO Energy, Inc.',
    region: 'GB',
  },
  {
    symbol: 'EISB-LN',
    name: 'East Imperial Plc',
    region: 'GB',
  },
  {
    symbol: 'EKF-LN',
    name: 'EKF Diagnostics Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'EKF-PZ',
    name: 'EKF Diagnostics Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'ELCO-LN',
    name: 'Eleco Plc',
    region: 'GB',
  },
  {
    symbol: 'ELIX-LN',
    name: 'Elixirr International Plc',
    region: 'GB',
  },
  {
    symbol: 'ELM-LN',
    name: 'Elementis',
    region: 'GB',
  },
  {
    symbol: 'ELSA-LN',
    name: 'Societatea Energetica Electrica SA - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'EM.P-PZ',
    name: 'Eastinco Mining and Exploration Plc',
    region: 'GB',
  },
  {
    symbol: 'EMAN-LN',
    name: 'Everyman Media Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EME-LN',
    name: 'Empyrean Energy',
    region: 'GB',
  },
  {
    symbol: 'EMG-LN',
    name: 'Man Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EMH-LN',
    name: 'European Metals Holdings Limited',
    region: 'GB',
  },
  {
    symbol: 'EMIS-LN',
    name: 'Emis Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EMIS-PZ',
    name: 'Emis Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EML-LN',
    name: 'Emmerson Plc',
    region: 'GB',
  },
  {
    symbol: 'EMR-LN',
    name: 'Empresaria Group',
    region: 'GB',
  },
  {
    symbol: 'ENET-LN',
    name: 'Ethernity Networks Ltd',
    region: 'GB',
  },
  {
    symbol: 'ENOG-LN',
    name: 'Energean Plc',
    region: 'GB',
  },
  {
    symbol: 'ENQ-LN',
    name: 'EnQuest Plc',
    region: 'GB',
  },
  {
    symbol: 'ENT-LN',
    name: 'Entain plc',
    region: 'GB',
  },
  {
    symbol: 'ENT-PZ',
    name: 'Entain plc',
    region: 'GB',
  },
  {
    symbol: 'ENW-LN',
    name: 'Enwell Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'ENW-PZ',
    name: 'Enwell Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'EOG-LN',
    name: 'Europa Oil & Gas (Hldgs)',
    region: 'GB',
  },
  {
    symbol: 'EPWN-LN',
    name: 'Epwin Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EQLS-LN',
    name: 'Equals Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EQT-LN',
    name: 'Eqtec Plc',
    region: 'GB',
  },
  {
    symbol: 'ERGO-LN',
    name: 'Ergomed Plc',
    region: 'GB',
  },
  {
    symbol: 'ERM-LN',
    name: 'Euromoney Institutional Investors',
    region: 'GB',
  },
  {
    symbol: 'ESKN-LN',
    name: 'Esken Limited',
    region: 'GB',
  },
  {
    symbol: 'ESNT-LN',
    name: 'Essentra Plc',
    region: 'GB',
  },
  {
    symbol: 'EST-LN',
    name: 'East Star Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'ESYS-LN',
    name: 'Essensys Plc',
    region: 'GB',
  },
  {
    symbol: 'ETX-LN',
    name: 'E-Therapeutics Plc',
    region: 'GB',
  },
  {
    symbol: 'EUA-LN',
    name: 'Eurasia Mining',
    region: 'GB',
  },
  {
    symbol: 'EUZ-LN',
    name: 'Europa Metals Ltd',
    region: 'GB',
  },
  {
    symbol: 'EUZ-PZ',
    name: 'Europa Metals Ltd',
    region: 'GB',
  },
  {
    symbol: 'EVE-LN',
    name: 'Eve Sleep Plc',
    region: 'GB',
  },
  {
    symbol: 'EVG-LN',
    name: 'Evgen Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'EXPN-LN',
    name: 'Experian Plc',
    region: 'GB',
  },
  {
    symbol: 'EXR-LN',
    name: 'Engage XR Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'EYE-LN',
    name: 'Eagle Eye Solutions Group Plc',
    region: 'GB',
  },
  {
    symbol: 'EZJ-LN',
    name: 'Easyjet plc',
    region: 'GB',
  },
  {
    symbol: 'FAB-LN',
    name: 'Fusion Antibodies Plc',
    region: 'GB',
  },
  {
    symbol: 'FAN-LN',
    name: 'Volution Group Plc',
    region: 'GB',
  },
  {
    symbol: 'FAR-LN',
    name: 'Ferro-Alloy Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'FARN-LN',
    name: 'Faron Pharmaceuticals Oy',
    region: 'GB',
  },
  {
    symbol: 'FBH-LN',
    name: 'F. B. D Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'FCRM-LN',
    name: 'Fulcrum Utility Services Limited',
    region: 'GB',
  },
  {
    symbol: 'FCRM-PZ',
    name: 'Fulcrum Utility Services Limited',
    region: 'GB',
  },
  {
    symbol: 'FDBK-LN',
    name: 'Feedback plc',
    region: 'GB',
  },
  {
    symbol: 'FDEV-LN',
    name: 'Frontier Developments Plc',
    region: 'GB',
  },
  {
    symbol: 'FDM-LN',
    name: 'FDM Group (Holdings) Plc',
    region: 'GB',
  },
  {
    symbol: 'FDP-LN',
    name: 'FD Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'FDP-PZ',
    name: 'FD Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'FEDS-LN',
    name: 'Federal Bank Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'FEN-LN',
    name: 'Frenkel Topping Group',
    region: 'GB',
  },
  {
    symbol: 'FERG-LN',
    name: 'Ferguson Plc.',
    region: 'GB',
  },
  {
    symbol: 'FEVR-LN',
    name: 'Fevertree Drinks Plc',
    region: 'GB',
  },
  {
    symbol: 'FGP-LN',
    name: 'First Group',
    region: 'GB',
  },
  {
    symbol: 'FIF-LN',
    name: 'Finsbury Food Group Plc',
    region: 'GB',
  },
  {
    symbol: 'FIH-LN',
    name: 'FIH Group Plc',
    region: 'GB',
  },
  {
    symbol: 'FIN-LN',
    name: 'Finablr Plc',
    region: 'GB',
  },
  {
    symbol: 'FIPP-LN',
    name: 'Frontier IP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'FIPP-PZ',
    name: 'Frontier IP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'FIRE-PZ',
    name: 'IamFire Plc',
    region: 'GB',
  },
  {
    symbol: 'FLK-LN',
    name: 'Fletcher King plc',
    region: 'GB',
  },
  {
    symbol: 'FLK-PZ',
    name: 'Fletcher King plc',
    region: 'GB',
  },
  {
    symbol: 'FLO-LN',
    name: 'Flowtech Fluidpower Plc',
    region: 'GB',
  },
  {
    symbol: 'FLTR-LN',
    name: 'Flutter Entertainment Plc',
    region: 'GB',
  },
  {
    symbol: 'FLX-LN',
    name: 'Falanx Group Limited',
    region: 'GB',
  },
  {
    symbol: 'FLX-PZ',
    name: 'Falanx Group Limited',
    region: 'GB',
  },
  {
    symbol: 'FNTL-LN',
    name: 'Fintel plc',
    region: 'GB',
  },
  {
    symbol: 'FNX-LN',
    name: 'Fonix Mobile Plc',
    region: 'GB',
  },
  {
    symbol: 'FOG-LN',
    name: 'Falcon Oil & Gas Ltd',
    region: 'GB',
  },
  {
    symbol: 'FORT-LN',
    name: 'Forterra Plc',
    region: 'GB',
  },
  {
    symbol: 'FOUR-LN',
    name: '4imprint Group plc',
    region: 'GB',
  },
  {
    symbol: 'FPP-LN',
    name: 'Fragrant Prosperity Holdings Limited',
    region: 'GB',
  },
  {
    symbol: 'FRAN-LN',
    name: 'Franchise Brands PLC',
    region: 'GB',
  },
  {
    symbol: 'FRAS-LN',
    name: 'Frasers Group Plc',
    region: 'GB',
  },
  {
    symbol: 'FRES-LN',
    name: 'Fresnillo Plc',
    region: 'GB',
  },
  {
    symbol: 'FSD-PZ',
    name: 'Field Systems Designs Holdings',
    region: 'GB',
  },
  {
    symbol: 'FSJ-LN',
    name: 'Fisher (James) & Sons',
    region: 'GB',
  },
  {
    symbol: 'FSTA-LN',
    name: 'Fuller Smith & Turner plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'FTC-LN',
    name: 'Filtronic',
    region: 'GB',
  },
  {
    symbol: 'FUL-LN',
    name: 'The Fulham Shore Plc',
    region: 'GB',
  },
  {
    symbol: 'FUM-LN',
    name: 'Futura Medical',
    region: 'GB',
  },
  {
    symbol: 'FUTR-LN',
    name: 'Future Plc',
    region: 'GB',
  },
  {
    symbol: 'FXPO-LN',
    name: 'Ferrexpo Plc',
    region: 'GB',
  },
  {
    symbol: 'G4M-LN',
    name: 'Gear4Music (Holdings) Plc',
    region: 'GB',
  },
  {
    symbol: 'GAID-LN',
    name: 'Gail (India) Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'GAL-LN',
    name: 'Galantas Gold Corp',
    region: 'GB',
  },
  {
    symbol: 'GAMA-LN',
    name: 'Gamma Communications Plc',
    region: 'GB',
  },
  {
    symbol: 'GATC-LN',
    name: 'Gattaca Plc',
    region: 'GB',
  },
  {
    symbol: 'GAW-LN',
    name: 'Games Workshop Group plc',
    region: 'GB',
  },
  {
    symbol: 'GBG-LN',
    name: 'GB Group plc',
    region: 'GB',
  },
  {
    symbol: 'GBG-PZ',
    name: 'GB Group plc',
    region: 'GB',
  },
  {
    symbol: 'GBP-LN',
    name: 'Global Petroleum Ltd',
    region: 'GB',
  },
  {
    symbol: 'GCAP-PZ',
    name: 'Globe Capital Limited',
    region: 'GB',
  },
  {
    symbol: 'GCLA-LN',
    name: 'Grupo Clarin SA - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'GCM-LN',
    name: 'GCM Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'GCM-PZ',
    name: 'GCM Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'GDBY-PZ',
    name: 'Goodbody Health Inc',
    region: 'GB',
  },
  {
    symbol: 'GDP-LN',
    name: 'Goldplat Plc',
    region: 'GB',
  },
  {
    symbol: 'GDR-LN',
    name: 'Genedrive Plc',
    region: 'GB',
  },
  {
    symbol: 'GDWN-LN',
    name: 'Goodwin',
    region: 'GB',
  },
  {
    symbol: 'GEC-LN',
    name: 'General Electric Co.',
    region: 'GB',
  },
  {
    symbol: 'GEEC-LN',
    name: 'Great Eastern Energy Corp - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'GEM-LN',
    name: 'Gemfields Group Limited',
    region: 'GB',
  },
  {
    symbol: 'GEMD-LN',
    name: 'Gem Diamonds Ltd',
    region: 'GB',
  },
  {
    symbol: 'GEN-LN',
    name: 'Genuit Group Plc',
    region: 'GB',
  },
  {
    symbol: 'GENL-LN',
    name: 'Genel Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'GETB-LN',
    name: 'GetBusy Plc',
    region: 'GB',
  },
  {
    symbol: 'GFHG-LN',
    name: 'Grand Fortune High Grade Ltd',
    region: 'GB',
  },
  {
    symbol: 'GFIN-LN',
    name: 'Gfinity Plc',
    region: 'GB',
  },
  {
    symbol: 'GFM-LN',
    name: 'Griffin Mining',
    region: 'GB',
  },
  {
    symbol: 'GFRD-LN',
    name: 'Galliford Try Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'GFTU-LN',
    name: 'Grafton Group',
    region: 'GB',
  },
  {
    symbol: 'GGP-LN',
    name: 'Greatland Gold',
    region: 'GB',
  },
  {
    symbol: 'GHE-LN',
    name: 'Gresham House',
    region: 'GB',
  },
  {
    symbol: 'GHH-LN',
    name: 'Gooch & Housego',
    region: 'GB',
  },
  {
    symbol: 'GHH-PZ',
    name: 'Gooch & Housego',
    region: 'GB',
  },
  {
    symbol: 'GHT-LN',
    name: 'Gresham Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'GILD-LN',
    name: 'Guild Esports Plc',
    region: 'GB',
  },
  {
    symbol: 'GINV-LN',
    name: 'Global Invacom Group Limited',
    region: 'GB',
  },
  {
    symbol: 'GKP-LN',
    name: 'Gulf Keystone Petroleum',
    region: 'GB',
  },
  {
    symbol: 'GLB-LN',
    name: 'Glanbia Plc',
    region: 'GB',
  },
  {
    symbol: 'GLE-LN',
    name: 'MJ Gleeson Plc',
    region: 'GB',
  },
  {
    symbol: 'GLEN-LN',
    name: 'Glencore plc',
    region: 'GB',
  },
  {
    symbol: 'GLO-LN',
    name: 'ContourGlobal Plc',
    region: 'GB',
  },
  {
    symbol: 'GLR-LN',
    name: 'Galileo Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'GLR-PZ',
    name: 'Galileo Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'GLV-LN',
    name: 'Glenveagh Properties Plc',
    region: 'GB',
  },
  {
    symbol: 'GMAA-LN',
    name: 'Gama Aviation Plc',
    region: 'GB',
  },
  {
    symbol: 'GMR-LN',
    name: 'Gaming Realms Plc',
    region: 'GB',
  },
  {
    symbol: 'GMR-PZ',
    name: 'Gaming Realms Plc',
    region: 'GB',
  },
  {
    symbol: 'GMS-LN',
    name: 'Gulf Marine Services Plc',
    region: 'GB',
  },
  {
    symbol: 'GNC-LN',
    name: 'Greencore Group Plc',
    region: 'GB',
  },
  {
    symbol: 'GNS-LN',
    name: 'Genus plc',
    region: 'GB',
  },
  {
    symbol: 'GOG-LN',
    name: 'Go-Ahead Group plc',
    region: 'GB',
  },
  {
    symbol: 'GOOD-LN',
    name: 'Good Energy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'GOOD-PZ',
    name: 'Good Energy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'GPH-LN',
    name: 'Global Ports Holding Plc',
    region: 'GB',
  },
  {
    symbol: 'GRA-LN',
    name: 'Grafenia Plc',
    region: 'GB',
  },
  {
    symbol: 'GRC-LN',
    name: 'GRC International Group Plc',
    region: 'GB',
  },
  {
    symbol: 'GRG-LN',
    name: 'Greggs plc',
    region: 'GB',
  },
  {
    symbol: 'GRL-LN',
    name: 'Goldstone Resources',
    region: 'GB',
  },
  {
    symbol: 'GRX-LN',
    name: 'GreenX Metals Ltd',
    region: 'GB',
  },
  {
    symbol: 'GSK-LN',
    name: 'Glaxosmithkline plc',
    region: 'GB',
  },
  {
    symbol: 'GSP-LN',
    name: 'Gensource Potash Corp',
    region: 'GB',
  },
  {
    symbol: 'GST-LN',
    name: 'GSTechnologies Ltd',
    region: 'GB',
  },
  {
    symbol: 'GTC-LN',
    name: 'Getech Group',
    region: 'GB',
  },
  {
    symbol: 'GTCO-LN',
    name: 'Guaranty Trust Holding Company Plc - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'GTE-LN',
    name: 'Gran Tierra Energy Inc',
    region: 'GB',
  },
  {
    symbol: 'GTLY-LN',
    name: 'Gateley (Holdings) Plc',
    region: 'GB',
  },
  {
    symbol: 'GUN-LN',
    name: 'Gunsynd plc',
    region: 'GB',
  },
  {
    symbol: 'GUN-PZ',
    name: 'Gunsynd plc',
    region: 'GB',
  },
  {
    symbol: 'GUS-LN',
    name: 'Gusbourne Plc',
    region: 'GB',
  },
  {
    symbol: 'GVMH-LN',
    name: 'Grand Vison Media Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'GWIN-PZ',
    name: 'Gowin New Energy Group Limited',
    region: 'GB',
  },
  {
    symbol: 'GWMO-LN',
    name: 'Great Western Mining Corp Plc',
    region: 'GB',
  },
  {
    symbol: 'GWMO-PZ',
    name: 'Great Western Mining Corp Plc',
    region: 'GB',
  },
  {
    symbol: 'GYG-LN',
    name: 'GYG plc',
    region: 'GB',
  },
  {
    symbol: 'GYM-LN',
    name: 'Gym Group plc',
    region: 'GB',
  },
  {
    symbol: 'HARL-LN',
    name: 'Harland & Wolff Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'HARL-PZ',
    name: 'Harland & Wolff Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'HAS-LN',
    name: 'Hays plc',
    region: 'GB',
  },
  {
    symbol: 'HAT-LN',
    name: 'H & T Group',
    region: 'GB',
  },
  {
    symbol: 'HAT-PZ',
    name: 'H & T Group',
    region: 'GB',
  },
  {
    symbol: 'HAYD-LN',
    name: 'Haydale Graphene Industries Plc',
    region: 'GB',
  },
  {
    symbol: 'HBR-LN',
    name: 'Harbour Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'HCM-LN',
    name: 'HUTCHMED (China) Limited',
    region: 'GB',
  },
  {
    symbol: 'HCM-PZ',
    name: 'HUTCHMED (China) Limited',
    region: 'GB',
  },
  {
    symbol: 'HDT-LN',
    name: 'Holders Technology plc',
    region: 'GB',
  },
  {
    symbol: 'HE1-LN',
    name: 'Helium One Global Ltd',
    region: 'GB',
  },
  {
    symbol: 'HEAD-LN',
    name: 'Headlam Group plc',
    region: 'GB',
  },
  {
    symbol: 'HEIQ-LN',
    name: 'HeiQ Plc',
    region: 'GB',
  },
  {
    symbol: 'HEMO-LN',
    name: 'Hemogenyx Pharmaceuticals Plc',
    region: 'GB',
  },
  {
    symbol: 'HFD-LN',
    name: 'Halfords',
    region: 'GB',
  },
  {
    symbol: 'HFG-LN',
    name: 'Hilton Food Group Plc',
    region: 'GB',
  },
  {
    symbol: 'HHPD-LN',
    name: 'Hon Hai Precision Industry - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'HHPG-LN',
    name: 'Hon Hai Precision Industry - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'HID-LN',
    name: 'Hidong Estate plc',
    region: 'GB',
  },
  {
    symbol: 'HIK-LN',
    name: 'Hikma Pharmaceuticals Plc',
    region: 'GB',
  },
  {
    symbol: 'HILS-LN',
    name: 'Hill & Smith Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'HLMA-LN',
    name: 'Halma plc',
    region: 'GB',
  },
  {
    symbol: 'HMI-LN',
    name: 'Harvest Minerals Limited',
    region: 'GB',
  },
  {
    symbol: 'HOC-LN',
    name: 'Hochschild Mining Plc',
    region: 'GB',
  },
  {
    symbol: 'HON-LN',
    name: 'Honeywell International Inc',
    region: 'GB',
  },
  {
    symbol: 'HOTC-LN',
    name: 'Hotel Chocolat Group Plc',
    region: 'GB',
  },
  {
    symbol: 'HPAC-LN',
    name: 'Hermes Pacific Investments plc',
    region: 'GB',
  },
  {
    symbol: 'HRN-LN',
    name: 'Hornby plc',
    region: 'GB',
  },
  {
    symbol: 'HSBA-LN',
    name: 'HSBC Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'HSBK-LN',
    name: 'Halyk Savings Bank of Kazakhstan JSC - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'HSD-LN',
    name: 'Hansard Global Plc',
    region: 'GB',
  },
  {
    symbol: 'HSM-LN',
    name: 'Heath (Samuel) & Sons',
    region: 'GB',
  },
  {
    symbol: 'HSP-LN',
    name: 'Hargreaves Services',
    region: 'GB',
  },
  {
    symbol: 'HSP-PZ',
    name: 'Hargreaves Services',
    region: 'GB',
  },
  {
    symbol: 'HSS-LN',
    name: 'HSS Hire Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'HSV-LN',
    name: 'Homeserve',
    region: 'GB',
  },
  {
    symbol: 'HSW-LN',
    name: 'Hostelworld Group plc',
    region: 'GB',
  },
  {
    symbol: 'HSX-LN',
    name: 'Hiscox Ltd',
    region: 'GB',
  },
  {
    symbol: 'HTG-LN',
    name: 'Hunting plc',
    region: 'GB',
  },
  {
    symbol: 'HTWS-LN',
    name: 'Helios Towers Plc',
    region: 'GB',
  },
  {
    symbol: 'HUM-LN',
    name: 'Hummingbird Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'HUM-PZ',
    name: 'Hummingbird Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'HUR-LN',
    name: 'Hurricane Energy plc',
    region: 'GB',
  },
  {
    symbol: 'HUR-PZ',
    name: 'Hurricane Energy plc',
    region: 'GB',
  },
  {
    symbol: 'HUW-LN',
    name: 'Helios Underwriting Plc',
    region: 'GB',
  },
  {
    symbol: 'HVT-LN',
    name: 'Heavitree Brewery plc',
    region: 'GB',
  },
  {
    symbol: 'HVTA-LN',
    name: 'Heavitree Brewery plc - Class A (Limited Voting)',
    region: 'GB',
  },
  {
    symbol: 'HWDN-LN',
    name: 'Howden Joinery Group Plc',
    region: 'GB',
  },
  {
    symbol: 'HWG-LN',
    name: 'Harworth Group Plc',
    region: 'GB',
  },
  {
    symbol: 'HYDP-PZ',
    name: 'Hydro Hotel (Eastbourne)',
    region: 'GB',
  },
  {
    symbol: 'HYUD-LN',
    name: 'Hyundai Motor - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'HYUO-LN',
    name: 'Hyundai Motor - GDR - 144A (Representing Ord Shares)',
    region: 'GB',
  },
  {
    symbol: 'HYVE-LN',
    name: 'Hyve Group Plc',
    region: 'GB',
  },
  {
    symbol: 'HZM-LN',
    name: 'Horizonte Minerals',
    region: 'GB',
  },
  {
    symbol: 'I3E-LN',
    name: 'i3 Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'IAG-LN',
    name: 'International Consolidated Airlines Group SA',
    region: 'GB',
  },
  {
    symbol: 'IBM-LN',
    name: 'International Business Machines Corp.',
    region: 'GB',
  },
  {
    symbol: 'IBPO-LN',
    name: 'iEnergizer Ltd',
    region: 'GB',
  },
  {
    symbol: 'IBPO-PZ',
    name: 'iEnergizer Ltd',
    region: 'GB',
  },
  {
    symbol: 'IBST-LN',
    name: 'Ibstock plc',
    region: 'GB',
  },
  {
    symbol: 'ICGC-LN',
    name: 'Irish Continental Group Plc - Units',
    region: 'GB',
  },
  {
    symbol: 'IDE-LN',
    name: 'IDE Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'IDE-PZ',
    name: 'IDE Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'IDEA-LN',
    name: 'Ideagen Plc',
    region: 'GB',
  },
  {
    symbol: 'IDEA-PZ',
    name: 'Ideagen Plc',
    region: 'GB',
  },
  {
    symbol: 'IDHC-LN',
    name: 'Integrated Diagnostics Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'IDOX-LN',
    name: 'Idox',
    region: 'GB',
  },
  {
    symbol: 'IDOX-PZ',
    name: 'Idox',
    region: 'GB',
  },
  {
    symbol: 'IDP-LN',
    name: 'Innovaderma Plc',
    region: 'GB',
  },
  {
    symbol: 'IES-LN',
    name: 'Invinity Energy Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'IGAS-LN',
    name: 'IGas Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'IGAS-PZ',
    name: 'IGas Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'IGE-LN',
    name: 'Image Scan Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'IGG-LN',
    name: 'IG Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'IGN-LN',
    name: 'AB Ignitis Grupe - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'IGP-LN',
    name: 'Intercede Group',
    region: 'GB',
  },
  {
    symbol: 'IGR-LN',
    name: 'IG Design Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IGR-PZ',
    name: 'IG Design Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IHC-LN',
    name: 'Inspiration Healthcare Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IHG-LN',
    name: 'Intercontinental Hotels Group',
    region: 'GB',
  },
  {
    symbol: 'IKA-LN',
    name: 'Ilika plc',
    region: 'GB',
  },
  {
    symbol: 'IKA-PZ',
    name: 'Ilika plc',
    region: 'GB',
  },
  {
    symbol: 'IL0A-LN',
    name: 'Permanent TSB Group Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'IMB-LN',
    name: 'Imperial Brands Plc',
    region: 'GB',
  },
  {
    symbol: 'IMC-LN',
    name: 'IMC Exploration Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IME-LN',
    name: 'Immedia Group plc',
    region: 'GB',
  },
  {
    symbol: 'IMI-LN',
    name: 'IMI plc',
    region: 'GB',
  },
  {
    symbol: 'IMM-LN',
    name: 'Immupharma Plc',
    region: 'GB',
  },
  {
    symbol: 'IMM-PZ',
    name: 'Immupharma Plc',
    region: 'GB',
  },
  {
    symbol: 'IMMO-LN',
    name: 'Immotion Group Plc',
    region: 'GB',
  },
  {
    symbol: 'INCE-LN',
    name: 'Ince Group Plc',
    region: 'GB',
  },
  {
    symbol: 'INCH-LN',
    name: 'Inchcape plc',
    region: 'GB',
  },
  {
    symbol: 'INDI-LN',
    name: 'Indus Gas Ltd',
    region: 'GB',
  },
  {
    symbol: 'INDI-PZ',
    name: 'Indus Gas Ltd',
    region: 'GB',
  },
  {
    symbol: 'INDV-LN',
    name: 'Indivior Plc',
    region: 'GB',
  },
  {
    symbol: 'INF-LN',
    name: 'Informa Plc',
    region: 'GB',
  },
  {
    symbol: 'ING-LN',
    name: 'Ingenta Plc',
    region: 'GB',
  },
  {
    symbol: 'INHC-LN',
    name: 'Induction Healthcare Group Plc',
    region: 'GB',
  },
  {
    symbol: 'INS-LN',
    name: 'Instem Plc',
    region: 'GB',
  },
  {
    symbol: 'INS-PZ',
    name: 'Instem Plc',
    region: 'GB',
  },
  {
    symbol: 'INSE-LN',
    name: 'Inspired Plc',
    region: 'GB',
  },
  {
    symbol: 'INSE-PZ',
    name: 'Inspired Plc',
    region: 'GB',
  },
  {
    symbol: 'INSG-LN',
    name: 'Insig AI Plc',
    region: 'GB',
  },
  {
    symbol: 'INSP-LN',
    name: 'Inspirit Energy Holdings plc.',
    region: 'GB',
  },
  {
    symbol: 'INTO-LN',
    name: 'INTOSOL Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'INVP-LN',
    name: 'Investec plc',
    region: 'GB',
  },
  {
    symbol: 'INX-LN',
    name: 'i-nexus Global Plc',
    region: 'GB',
  },
  {
    symbol: 'IOF-LN',
    name: 'Iofina Plc',
    region: 'GB',
  },
  {
    symbol: 'IOF-PZ',
    name: 'Iofina Plc',
    region: 'GB',
  },
  {
    symbol: 'IOG-LN',
    name: 'IOG Plc',
    region: 'GB',
  },
  {
    symbol: 'IOM-LN',
    name: 'Iomart Group plc',
    region: 'GB',
  },
  {
    symbol: 'IOM-PZ',
    name: 'Iomart Group plc',
    region: 'GB',
  },
  {
    symbol: 'IPEL-LN',
    name: 'Impellam Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IPEL-PZ',
    name: 'Impellam Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IPO-LN',
    name: 'IP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IPX-LN',
    name: 'Impax Asset Management Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IQAI-LN',
    name: 'IQ-AI Limited',
    region: 'GB',
  },
  {
    symbol: 'IQE-LN',
    name: 'IQE plc',
    region: 'GB',
  },
  {
    symbol: 'IQG-LN',
    name: 'IQGeo Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IQG-PZ',
    name: 'IQGeo Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IRON-LN',
    name: 'Ironveld Plc',
    region: 'GB',
  },
  {
    symbol: 'ITM-LN',
    name: 'ITM Power',
    region: 'GB',
  },
  {
    symbol: 'ITM-PZ',
    name: 'ITM Power',
    region: 'GB',
  },
  {
    symbol: 'ITRK-LN',
    name: 'Intertek Group plc',
    region: 'GB',
  },
  {
    symbol: 'ITS-LN',
    name: 'In The Style Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ITV-LN',
    name: 'ITV',
    region: 'GB',
  },
  {
    symbol: 'ITX-LN',
    name: 'Itaconix Plc',
    region: 'GB',
  },
  {
    symbol: 'ITX-PZ',
    name: 'Itaconix Plc',
    region: 'GB',
  },
  {
    symbol: 'IUG-LN',
    name: 'Intelligent Ultrasound Group Plc',
    region: 'GB',
  },
  {
    symbol: 'IWG-LN',
    name: 'IWG plc',
    region: 'GB',
  },
  {
    symbol: 'IXI-LN',
    name: 'Ixico Plc',
    region: 'GB',
  },
  {
    symbol: 'JAN-LN',
    name: 'Jangada Mines Plc',
    region: 'GB',
  },
  {
    symbol: 'JARB-LN',
    name: 'Jardine Matheson Holdings Ltd.',
    region: 'GB',
  },
  {
    symbol: 'JAY-LN',
    name: 'Bluejay Mining Plc',
    region: 'GB',
  },
  {
    symbol: 'JDG-LN',
    name: 'Judges Scientific Plc',
    region: 'GB',
  },
  {
    symbol: 'JDW-LN',
    name: 'Wetherspoon(J D) plc',
    region: 'GB',
  },
  {
    symbol: 'JEL-LN',
    name: 'Jersey Electricity Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'JET-LN',
    name: 'Just Eat Takeaway.com N.V.',
    region: 'GB',
  },
  {
    symbol: 'JET2-LN',
    name: 'Jet2 Plc',
    region: 'GB',
  },
  {
    symbol: 'JET2-PZ',
    name: 'Jet2 Plc',
    region: 'GB',
  },
  {
    symbol: 'JHD-LN',
    name: 'James Halstead plc',
    region: 'GB',
  },
  {
    symbol: 'JHD-PZ',
    name: 'James Halstead plc',
    region: 'GB',
  },
  {
    symbol: 'JLH-LN',
    name: 'John Lewis of Hungerford Plc',
    region: 'GB',
  },
  {
    symbol: 'JLP-LN',
    name: 'Jubilee Metals Group Plc',
    region: 'GB',
  },
  {
    symbol: 'JMAT-LN',
    name: 'Johnson Matthey plc',
    region: 'GB',
  },
  {
    symbol: 'JNEO-LN',
    name: 'Journeo Plc',
    region: 'GB',
  },
  {
    symbol: 'JOG-LN',
    name: 'Jersey Oil and Gas Plc',
    region: 'GB',
  },
  {
    symbol: 'JOG-PZ',
    name: 'Jersey Oil and Gas Plc',
    region: 'GB',
  },
  {
    symbol: 'JOUL-LN',
    name: 'Joules Group Plc',
    region: 'GB',
  },
  {
    symbol: 'JSE-LN',
    name: 'Jadestone Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'JSG-LN',
    name: 'Johnson Service Group plc',
    region: 'GB',
  },
  {
    symbol: 'JSG-PZ',
    name: 'Johnson Service Group plc',
    region: 'GB',
  },
  {
    symbol: 'JTC-LN',
    name: 'JTC Plc',
    region: 'GB',
  },
  {
    symbol: 'JUST-LN',
    name: 'Just Group Plc',
    region: 'GB',
  },
  {
    symbol: 'JWNG-LN',
    name: 'Jaywing Plc',
    region: 'GB',
  },
  {
    symbol: 'KAKU-LN',
    name: 'Kakuzi plc',
    region: 'GB',
  },
  {
    symbol: 'KAP-LN',
    name: 'National Atomic Company Kazatomprom JSC - GDR - Regulation S',
    region: 'GB',
  },
  {
    symbol: 'KAPE-LN',
    name: 'Kape Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'KAT-LN',
    name: 'Katoro Gold Plc',
    region: 'GB',
  },
  {
    symbol: 'KAV-LN',
    name: 'Kavango Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'KBT-LN',
    name: 'K3 Business Technology Group plc',
    region: 'GB',
  },
  {
    symbol: 'KCT-LN',
    name: 'Kin and Carta Plc',
    region: 'GB',
  },
  {
    symbol: 'KDNC-LN',
    name: 'Cadence Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'KDNC-PZ',
    name: 'Cadence Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'KDR-LN',
    name: 'Karelian Diamond Resources',
    region: 'GB',
  },
  {
    symbol: 'KEFI-LN',
    name: 'KEFI Gold and Copper Plc',
    region: 'GB',
  },
  {
    symbol: 'KETL-LN',
    name: 'Strix Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KEYS-LN',
    name: 'Keystone Law Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KGF-LN',
    name: 'Kingfisher Plc',
    region: 'GB',
  },
  {
    symbol: 'KGH-LN',
    name: 'Knights Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'KGP-LN',
    name: 'Kingspan Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KIE-LN',
    name: 'Kier Group plc',
    region: 'GB',
  },
  {
    symbol: 'KING-PZ',
    name: 'Igraine Plc',
    region: 'GB',
  },
  {
    symbol: 'KINO-LN',
    name: 'Kinovo Plc',
    region: 'GB',
  },
  {
    symbol: 'KITW-LN',
    name: 'Kitwave Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KLR-LN',
    name: 'Keller',
    region: 'GB',
  },
  {
    symbol: 'KMK-LN',
    name: 'Kromek Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KMR-LN',
    name: 'Kenmare Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'KNB-LN',
    name: 'Kanabo Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KNM-LN',
    name: 'Konami Holdings Corporation',
    region: 'GB',
  },
  {
    symbol: 'KNOS-LN',
    name: 'Kainos Group Plc',
    region: 'GB',
  },
  {
    symbol: 'KOD-LN',
    name: 'Kodal Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'KOO-LN',
    name: 'Kooth Plc',
    region: 'GB',
  },
  {
    symbol: 'KOS-LN',
    name: 'Kosmos Energy Ltd',
    region: 'GB',
  },
  {
    symbol: 'KP2-LN',
    name: 'Kore Potash Plc',
    region: 'GB',
  },
  {
    symbol: 'KRM-LN',
    name: 'KRM22 Plc',
    region: 'GB',
  },
  {
    symbol: 'KRPZ-LN',
    name: 'Kropz Plc',
    region: 'GB',
  },
  {
    symbol: 'KRS-LN',
    name: 'Keras Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'KRS-PZ',
    name: 'Keras Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'KSK-LN',
    name: 'KSK Power Ventur Plc',
    region: 'GB',
  },
  {
    symbol: 'KSPI-LN',
    name: 'Kaspi.kz JSC - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'KWS-LN',
    name: 'Keywords Studios Plc',
    region: 'GB',
  },
  {
    symbol: 'KYGA-LN',
    name: 'Kerry Group Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'KZG-LN',
    name: 'Kazera Global plc',
    region: 'GB',
  },
  {
    symbol: 'KZG-PZ',
    name: 'Kazera Global plc',
    region: 'GB',
  },
  {
    symbol: 'LAM-LN',
    name: 'Lamprell Plc',
    region: 'GB',
  },
  {
    symbol: 'LDG-LN',
    name: 'Logistics Development Group Plc',
    region: 'GB',
  },
  {
    symbol: 'LDSG-LN',
    name: 'Leeds Group plc',
    region: 'GB',
  },
  {
    symbol: 'LEK-PZ',
    name: 'Lekoil Limited',
    region: 'GB',
  },
  {
    symbol: 'LEX-LN',
    name: 'Lexington Gold Limited',
    region: 'GB',
  },
  {
    symbol: 'LGEN-LN',
    name: 'Legal & General Group plc',
    region: 'GB',
  },
  {
    symbol: 'LGRS-LN',
    name: 'Loungers Plc',
    region: 'GB',
  },
  {
    symbol: 'LIKE-LN',
    name: 'Likewise Group Plc',
    region: 'GB',
  },
  {
    symbol: 'LIV-LN',
    name: 'Livermore Investments Group Limited',
    region: 'GB',
  },
  {
    symbol: 'LKCS-LN',
    name: 'Lucky Cement Ltd - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'LLOY-LN',
    name: 'Lloyds Banking Group plc',
    region: 'GB',
  },
  {
    symbol: 'LND-LN',
    name: 'Landore Resources',
    region: 'GB',
  },
  {
    symbol: 'LOGP-LN',
    name: 'Lansdowne Oil & Gas',
    region: 'GB',
  },
  {
    symbol: 'LOK-LN',
    name: 'Lok`n Store Group plc',
    region: 'GB',
  },
  {
    symbol: 'LOOK-LN',
    name: 'Lookers plc',
    region: 'GB',
  },
  {
    symbol: 'LOOP-LN',
    name: 'LoopUp Group Plc',
    region: 'GB',
  },
  {
    symbol: 'LPA-LN',
    name: 'LPA Group plc',
    region: 'GB',
  },
  {
    symbol: 'LRE-LN',
    name: 'Lancashire Holdings Limited',
    region: 'GB',
  },
  {
    symbol: 'LSAI-LN',
    name: 'Location Sciences Group Plc',
    region: 'GB',
  },
  {
    symbol: 'LSC-LN',
    name: 'London Security plc',
    region: 'GB',
  },
  {
    symbol: 'LSL-LN',
    name: 'LSL Property Services Plc',
    region: 'GB',
  },
  {
    symbol: 'LTG-LN',
    name: 'Learning Technologies Group Plc',
    region: 'GB',
  },
  {
    symbol: 'LTG-PZ',
    name: 'Learning Technologies Group Plc',
    region: 'GB',
  },
  {
    symbol: 'LTHM-LN',
    name: 'Latham (James)',
    region: 'GB',
  },
  {
    symbol: 'LTOD-LN',
    name: 'Larsen & Toubro Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'LUCE-LN',
    name: 'Luceco Plc',
    region: 'GB',
  },
  {
    symbol: 'LVCG-LN',
    name: 'Live Company Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MAB-LN',
    name: 'Mitchells & Butlers plc',
    region: 'GB',
  },
  {
    symbol: 'MACF-LN',
    name: 'MacFarlane Group plc',
    region: 'GB',
  },
  {
    symbol: 'MACP-LN',
    name: 'Marwyn Acquisition Company Plc',
    region: 'GB',
  },
  {
    symbol: 'MADE-LN',
    name: 'Made.com Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MAFL-LN',
    name: 'Mineral & Financial Investments Limited',
    region: 'GB',
  },
  {
    symbol: 'MAI-LN',
    name: 'Maintel Hldgs',
    region: 'GB',
  },
  {
    symbol: 'MARS-LN',
    name: 'Marston`s Plc',
    region: 'GB',
  },
  {
    symbol: 'MATD-LN',
    name: 'Petro Matad Limited',
    region: 'GB',
  },
  {
    symbol: 'MAW-LN',
    name: 'Maruwa Co. Ltd',
    region: 'GB',
  },
  {
    symbol: 'MBH-LN',
    name: 'Michelmersh Brick Hldgs',
    region: 'GB',
  },
  {
    symbol: 'MBO-LN',
    name: 'Mobilityone Ltd',
    region: 'GB',
  },
  {
    symbol: 'MBO-PZ',
    name: 'Mobilityone Ltd',
    region: 'GB',
  },
  {
    symbol: 'MBT-LN',
    name: 'Mobile Tornado Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MCB-LN',
    name: 'McBride',
    region: 'GB',
  },
  {
    symbol: 'MCLS-LN',
    name: 'Mccoll`s Retail Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MCM-LN',
    name: 'MC Mining Limited',
    region: 'GB',
  },
  {
    symbol: 'MCM-PZ',
    name: 'MC Mining Limited',
    region: 'GB',
  },
  {
    symbol: 'MCON-LN',
    name: 'Mincon Group plc',
    region: 'GB',
  },
  {
    symbol: 'MCRO-LN',
    name: 'Micro Focus International Plc',
    region: 'GB',
  },
  {
    symbol: 'MDC-LN',
    name: 'Mediclinic International Plc',
    region: 'GB',
  },
  {
    symbol: 'MDOB-LN',
    name: 'Mandarin Oriental International Ltd.',
    region: 'GB',
  },
  {
    symbol: 'MDZ-LN',
    name: 'Mediazest',
    region: 'GB',
  },
  {
    symbol: 'MEL-LN',
    name: 'Mitsubishi Electric Corporation',
    region: 'GB',
  },
  {
    symbol: 'MER-LN',
    name: 'Mears Group',
    region: 'GB',
  },
  {
    symbol: 'MER-PZ',
    name: 'Mears Group',
    region: 'GB',
  },
  {
    symbol: 'MFX-LN',
    name: 'Manx Financial Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MGAM-LN',
    name: 'Morgan Advanced Materials Plc',
    region: 'GB',
  },
  {
    symbol: 'MGGT-LN',
    name: 'Meggitt plc',
    region: 'GB',
  },
  {
    symbol: 'MGNS-LN',
    name: 'Morgan Sindall Group plc',
    region: 'GB',
  },
  {
    symbol: 'MGP-LN',
    name: 'Medica Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MHC-LN',
    name: 'MyHealthChecked Plc',
    region: 'GB',
  },
  {
    symbol: 'MHID-LN',
    name: 'Mahindra & Mahindra Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'MHPC-LN',
    name: 'MHP SE - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'MIDW-LN',
    name: 'Midwich Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MILA-LN',
    name: 'Mila Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'MIN-LN',
    name: 'Minoan Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MIND-LN',
    name: 'Mind Gym Plc',
    region: 'GB',
  },
  {
    symbol: 'MIRA-LN',
    name: 'Mirada Plc',
    region: 'GB',
  },
  {
    symbol: 'MIRI-LN',
    name: 'Mirriad Advertising Plc',
    region: 'GB',
  },
  {
    symbol: 'MKA-LN',
    name: 'Mkango Resources Ltd',
    region: 'GB',
  },
  {
    symbol: 'MKS-LN',
    name: 'Marks & Spencer Group',
    region: 'GB',
  },
  {
    symbol: 'ML.P-PZ',
    name: 'MiLOC Group Limited',
    region: 'GB',
  },
  {
    symbol: 'MLVN-LN',
    name: 'Malvern International Plc',
    region: 'GB',
  },
  {
    symbol: 'MLVN-PZ',
    name: 'Malvern International Plc',
    region: 'GB',
  },
  {
    symbol: 'MMAG-LN',
    name: 'musicMagpie Plc',
    region: 'GB',
  },
  {
    symbol: 'MMH-LN',
    name: 'Marshall Motor Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'MNDI-LN',
    name: 'Mondi',
    region: 'GB',
  },
  {
    symbol: 'MNG-LN',
    name: 'M&G Plc',
    region: 'GB',
  },
  {
    symbol: 'MNZS-LN',
    name: 'Menzies (John) plc',
    region: 'GB',
  },
  {
    symbol: 'MONY-LN',
    name: 'Moneysupermarket.Com Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MOON-LN',
    name: 'Moonpig Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MOS-LN',
    name: 'Mobile Streams',
    region: 'GB',
  },
  {
    symbol: 'MOTR-LN',
    name: 'Motorpoint Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MPAC-LN',
    name: 'Mpac Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MPE-LN',
    name: 'M.P. Evans Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MPE-PZ',
    name: 'M.P. Evans Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MPL-LN',
    name: 'Mercantile Ports and Logistics Limited',
    region: 'GB',
  },
  {
    symbol: 'MPL-PZ',
    name: 'Mercantile Ports and Logistics Limited',
    region: 'GB',
  },
  {
    symbol: 'MRIT-LN',
    name: 'Merit Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'MRL-LN',
    name: 'Marlowe Plc',
    region: 'GB',
  },
  {
    symbol: 'MRO-LN',
    name: 'Melrose Industries Plc.',
    region: 'GB',
  },
  {
    symbol: 'MSI-LN',
    name: 'MS International plc',
    region: 'GB',
  },
  {
    symbol: 'MSI-PZ',
    name: 'MS International plc',
    region: 'GB',
  },
  {
    symbol: 'MSLH-LN',
    name: 'Marshalls Group',
    region: 'GB',
  },
  {
    symbol: 'MSMN-LN',
    name: 'Mosman Oil and Gas Limited',
    region: 'GB',
  },
  {
    symbol: 'MSYS-LN',
    name: 'Microsaic Systems PLC',
    region: 'GB',
  },
  {
    symbol: 'MSYS-PZ',
    name: 'Microsaic Systems PLC',
    region: 'GB',
  },
  {
    symbol: 'MTC-LN',
    name: 'Mothercare plc',
    region: 'GB',
  },
  {
    symbol: 'MTL-LN',
    name: 'Metals Exploration',
    region: 'GB',
  },
  {
    symbol: 'MTO-LN',
    name: 'Mitie Group plc',
    region: 'GB',
  },
  {
    symbol: 'MTPH-LN',
    name: 'Midatech Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'MTR-LN',
    name: 'Metal Tiger Plc',
    region: 'GB',
  },
  {
    symbol: 'MTRO-LN',
    name: 'Metro Bank Plc',
    region: 'GB',
  },
  {
    symbol: 'MTW-LN',
    name: 'Mattioli Woods',
    region: 'GB',
  },
  {
    symbol: 'MUL-LN',
    name: 'Mulberry Group',
    region: 'GB',
  },
  {
    symbol: 'MUL-PZ',
    name: 'Mulberry Group',
    region: 'GB',
  },
  {
    symbol: 'MUSH-PZ',
    name: 'Oscillate Plc',
    region: 'GB',
  },
  {
    symbol: 'MWE-LN',
    name: 'Mti Wireless Edge',
    region: 'GB',
  },
  {
    symbol: 'MXC-LN',
    name: 'MGC Pharmaceuticals Ltd',
    region: 'GB',
  },
  {
    symbol: 'MXCT-LN',
    name: 'MaxCyte Inc',
    region: 'GB',
  },
  {
    symbol: 'MYSL-LN',
    name: 'MySale Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MYSL-PZ',
    name: 'MySale Group Plc',
    region: 'GB',
  },
  {
    symbol: 'MYX-LN',
    name: 'MyCelx Technologies Corporation',
    region: 'GB',
  },
  {
    symbol: 'N4P-LN',
    name: 'N4 Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'N4P-PZ',
    name: 'N4 Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'NAH-LN',
    name: 'NAHL Group Plc',
    region: 'GB',
  },
  {
    symbol: 'NANO-LN',
    name: 'Nanoco Group Plc',
    region: 'GB',
  },
  {
    symbol: 'NANO-PZ',
    name: 'Nanoco Group Plc',
    region: 'GB',
  },
  {
    symbol: 'NAR-LN',
    name: 'Northamber',
    region: 'GB',
  },
  {
    symbol: 'NBB-LN',
    name: 'Norman Broadbent Plc',
    region: 'GB',
  },
  {
    symbol: 'NBI-LN',
    name: 'Northbridge Industrial Services',
    region: 'GB',
  },
  {
    symbol: 'NCC-LN',
    name: 'NCC Group',
    region: 'GB',
  },
  {
    symbol: 'NCCL-LN',
    name: 'Ncondezi Energy Limited',
    region: 'GB',
  },
  {
    symbol: 'NCCL-PZ',
    name: 'Ncondezi Energy Limited',
    region: 'GB',
  },
  {
    symbol: 'NCYT-LN',
    name: 'Novacyt',
    region: 'GB',
  },
  {
    symbol: 'NET-LN',
    name: 'Netcall plc',
    region: 'GB',
  },
  {
    symbol: 'NETW-LN',
    name: 'Network International Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'NEX-LN',
    name: 'National Express Group plc',
    region: 'GB',
  },
  {
    symbol: 'NEXS-LN',
    name: 'Nexus Infrastructure Plc',
    region: 'GB',
  },
  {
    symbol: 'NFC-LN',
    name: 'Next Fifteen Communications Group plc',
    region: 'GB',
  },
  {
    symbol: 'NFX-LN',
    name: 'Nuformix plc',
    region: 'GB',
  },
  {
    symbol: 'NGHT-LN',
    name: 'Nightcap Plc',
    region: 'GB',
  },
  {
    symbol: 'NICL-LN',
    name: 'Nichols plc',
    region: 'GB',
  },
  {
    symbol: 'NICL-PZ',
    name: 'Nichols plc',
    region: 'GB',
  },
  {
    symbol: 'NLB-LN',
    name: 'Nova Ljubljanska Banka D.D. - GDR - Regulation S',
    region: 'GB',
  },
  {
    symbol: 'NMRP-PZ',
    name: 'National Milk Records Plc',
    region: 'GB',
  },
  {
    symbol: 'NMT-LN',
    name: 'Neometals Ltd',
    region: 'GB',
  },
  {
    symbol: 'NNN-LN',
    name: 'Nanosynth Group Plc',
    region: 'GB',
  },
  {
    symbol: 'NOG-LN',
    name: 'Nostrum Oil & Gas Plc',
    region: 'GB',
  },
  {
    symbol: 'NSCI-LN',
    name: 'NetScientific plc',
    region: 'GB',
  },
  {
    symbol: 'NTBR-LN',
    name: 'Northern Bear Plc',
    region: 'GB',
  },
  {
    symbol: 'NTEA-LN',
    name: 'Northern Electric - PRF PERPETUAL GBP',
    region: 'GB',
  },
  {
    symbol: 'NTOG-LN',
    name: 'Nostra Terra Oil&Gas Co Plc',
    region: 'GB',
  },
  {
    symbol: 'NTQ-LN',
    name: 'Enteq Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'NTQ-PZ',
    name: 'Enteq Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'NWF-LN',
    name: 'NWF Group',
    region: 'GB',
  },
  {
    symbol: 'NWG-LN',
    name: 'NatWest Group Plc',
    region: 'GB',
  },
  {
    symbol: 'NWT-LN',
    name: 'Newmark Security plc',
    region: 'GB',
  },
  {
    symbol: 'NXR-LN',
    name: 'Norcros Plc',
    region: 'GB',
  },
  {
    symbol: 'NXT-LN',
    name: 'Next plc.',
    region: 'GB',
  },
  {
    symbol: 'NYR-PZ',
    name: 'Newbury Racecourse',
    region: 'GB',
  },
  {
    symbol: 'OBC-LN',
    name: 'Online Blockchain Plc',
    region: 'GB',
  },
  {
    symbol: 'OBD-LN',
    name: 'Oxford BioDynamics Plc',
    region: 'GB',
  },
  {
    symbol: 'OBE-PZ',
    name: 'Oberon Investments Group Plc',
    region: 'GB',
  },
  {
    symbol: 'OBI-LN',
    name: 'Ondine Biomedical Inc',
    region: 'GB',
  },
  {
    symbol: 'OCDO-LN',
    name: 'Ocado Group Plc',
    region: 'GB',
  },
  {
    symbol: 'OCN-LN',
    name: 'Ocean Wilsons Holdings Ltd.',
    region: 'GB',
  },
  {
    symbol: 'ODX-LN',
    name: 'Omega Diagnostics Group Plc',
    region: 'GB',
  },
  {
    symbol: 'OEX-LN',
    name: 'Oilex Ltd',
    region: 'GB',
  },
  {
    symbol: 'OGDC-LN',
    name: 'Oil & Gas Development Co. Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'OGN-LN',
    name: 'Origin Enterprises',
    region: 'GB',
  },
  {
    symbol: 'OKYO-LN',
    name: 'OKYO Pharma Limited',
    region: 'GB',
  },
  {
    symbol: 'OMG-LN',
    name: 'Oxford Metrics plc',
    region: 'GB',
  },
  {
    symbol: 'OMI-LN',
    name: 'Orosur Mining Inc',
    region: 'GB',
  },
  {
    symbol: 'OMI-PZ',
    name: 'Orosur Mining Inc',
    region: 'GB',
  },
  {
    symbol: 'OMIP-LN',
    name: 'One Media iP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'OMIP-PZ',
    name: 'One Media iP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'OMU-LN',
    name: 'Old Mutual Limited',
    region: 'GB',
  },
  {
    symbol: 'ONC-LN',
    name: 'Oncimmune Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'ONT-LN',
    name: 'Oxford Nanopore Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'OOUT-LN',
    name: 'Ocean Outdoor Limited',
    region: 'GB',
  },
  {
    symbol: 'OPG-LN',
    name: 'Opg Power Venture PLC',
    region: 'GB',
  },
  {
    symbol: 'OPG-PZ',
    name: 'Opg Power Venture PLC',
    region: 'GB',
  },
  {
    symbol: 'OPTI-LN',
    name: 'OptiBiotix Health plc',
    region: 'GB',
  },
  {
    symbol: 'OPTI-PZ',
    name: 'OptiBiotix Health plc',
    region: 'GB',
  },
  {
    symbol: 'ORCP-LN',
    name: 'Oracle Power Plc',
    region: 'GB',
  },
  {
    symbol: 'ORCP-PZ',
    name: 'Oracle Power Plc',
    region: 'GB',
  },
  {
    symbol: 'ORM-LN',
    name: 'Ormonde Mining Plc',
    region: 'GB',
  },
  {
    symbol: 'ORM-PZ',
    name: 'Ormonde Mining Plc',
    region: 'GB',
  },
  {
    symbol: 'ORNT-LN',
    name: 'Orient Telecoms PLC',
    region: 'GB',
  },
  {
    symbol: 'ORPH-LN',
    name: 'Open Orphan plc',
    region: 'GB',
  },
  {
    symbol: 'ORPH-PZ',
    name: 'Open Orphan plc',
    region: 'GB',
  },
  {
    symbol: 'ORR-LN',
    name: 'Oriole Resources PLC',
    region: 'GB',
  },
  {
    symbol: 'OSB-LN',
    name: 'OSB Group PLC',
    region: 'GB',
  },
  {
    symbol: 'OSI-LN',
    name: 'Osirium Technologies plc',
    region: 'GB',
  },
  {
    symbol: 'OTB-LN',
    name: 'On the Beach Group plc',
    region: 'GB',
  },
  {
    symbol: 'OTES-LN',
    name: 'Hellenic Telecommunications Organization S.A. - ADR',
    region: 'GB',
  },
  {
    symbol: 'OTMP-LN',
    name: 'OnTheMarket Plc',
    region: 'GB',
  },
  {
    symbol: 'OTPD-LN',
    name: 'OTP Bank - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'OVB-LN',
    name: 'Ovoca Bio Plc',
    region: 'GB',
  },
  {
    symbol: 'OVB-PZ',
    name: 'Ovoca Bio Plc',
    region: 'GB',
  },
  {
    symbol: 'OXB-LN',
    name: 'Oxford Biomedica',
    region: 'GB',
  },
  {
    symbol: 'OXIG-LN',
    name: 'Oxford Instruments plc',
    region: 'GB',
  },
  {
    symbol: 'PAF-LN',
    name: 'Pan African Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PAF-PZ',
    name: 'Pan African Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PAG-LN',
    name: 'Paragon Banking Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PAGE-LN',
    name: 'PageGroup Plc',
    region: 'GB',
  },
  {
    symbol: 'PALM-LN',
    name: 'Panther Metals Plc',
    region: 'GB',
  },
  {
    symbol: 'PANR-LN',
    name: 'Pantheon Resources',
    region: 'GB',
  },
  {
    symbol: 'PAT-LN',
    name: 'Panthera Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PAY-LN',
    name: 'Paypoint',
    region: 'GB',
  },
  {
    symbol: 'PBEE-LN',
    name: 'PensionBee Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PCA-LN',
    name: 'Palace Capital Plc',
    region: 'GB',
  },
  {
    symbol: 'PCIP-LN',
    name: 'PCI-PAL Plc',
    region: 'GB',
  },
  {
    symbol: 'PCLD-LN',
    name: 'Paint & Chemicals Industries (Pachin) - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'PDG-LN',
    name: 'Pendragon plc',
    region: 'GB',
  },
  {
    symbol: 'PDL-LN',
    name: 'Petra Diamonds',
    region: 'GB',
  },
  {
    symbol: 'PEB-LN',
    name: 'Pebble Beach Systems Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PEBB-LN',
    name: 'Pebble Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PEG-LN',
    name: 'Petards Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PEN-LN',
    name: 'Pennant International',
    region: 'GB',
  },
  {
    symbol: 'PERE-LN',
    name: 'Pembridge Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PET-LN',
    name: 'Petrel Resources',
    region: 'GB',
  },
  {
    symbol: 'PET-PZ',
    name: 'Petrel Resources',
    region: 'GB',
  },
  {
    symbol: 'PETB-LN',
    name: 'OMV Petrom SA - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'PETS-LN',
    name: 'Pets at Home Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PFC-LN',
    name: 'Petrofac',
    region: 'GB',
  },
  {
    symbol: 'PFD-LN',
    name: 'Premier Foods',
    region: 'GB',
  },
  {
    symbol: 'PFP-LN',
    name: 'Pathfinder Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'PGH-LN',
    name: 'Personal Group Holdings',
    region: 'GB',
  },
  {
    symbol: 'PGH-PZ',
    name: 'Personal Group Holdings',
    region: 'GB',
  },
  {
    symbol: 'PGR-LN',
    name: 'Phoenix Global Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PGR-PZ',
    name: 'Phoenix Global Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PHAR-LN',
    name: 'Pharos Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'PHC-LN',
    name: 'Plant Health Care',
    region: 'GB',
  },
  {
    symbol: 'PHC-PZ',
    name: 'Plant Health Care',
    region: 'GB',
  },
  {
    symbol: 'PHE-LN',
    name: 'PowerHouse Energy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PHE-PZ',
    name: 'PowerHouse Energy Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PHNX-LN',
    name: 'Phoenix Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'PHP-LN',
    name: 'Primary Health Prop.',
    region: 'GB',
  },
  {
    symbol: 'PHSC-LN',
    name: 'PHSC',
    region: 'GB',
  },
  {
    symbol: 'PHTM-LN',
    name: 'Photo-Me International plc',
    region: 'GB',
  },
  {
    symbol: 'PIER-LN',
    name: 'The Brighton Pier Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PIP-LN',
    name: 'Pipehawk',
    region: 'GB',
  },
  {
    symbol: 'PIRI-LN',
    name: 'Pires Investments Plc.',
    region: 'GB',
  },
  {
    symbol: 'PMG-LN',
    name: 'The Parkmead Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PMG-PZ',
    name: 'The Parkmead Group Plc',
    region: 'GB',
  },
  {
    symbol: 'PMP-LN',
    name: 'Portmeirion Group plc',
    region: 'GB',
  },
  {
    symbol: 'PNN-LN',
    name: 'Pennon Group',
    region: 'GB',
  },
  {
    symbol: 'PNPL-LN',
    name: 'Pineapple Power Corporation Plc',
    region: 'GB',
  },
  {
    symbol: 'PODP-LN',
    name: 'Pod Point Group Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'POG-LN',
    name: 'Petropavlovsk Plc',
    region: 'GB',
  },
  {
    symbol: 'POLR-LN',
    name: 'Polar Capital Hldgs Plc',
    region: 'GB',
  },
  {
    symbol: 'POLR-PZ',
    name: 'Polar Capital Hldgs Plc',
    region: 'GB',
  },
  {
    symbol: 'POLX-LN',
    name: 'Polarean Imaging Plc',
    region: 'GB',
  },
  {
    symbol: 'POLY-LN',
    name: 'Polymetal International Plc',
    region: 'GB',
  },
  {
    symbol: 'POS-LN',
    name: 'Plexus Hldgs',
    region: 'GB',
  },
  {
    symbol: 'POS-PZ',
    name: 'Plexus Hldgs',
    region: 'GB',
  },
  {
    symbol: 'POW-LN',
    name: 'Power Metal Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'POW-PZ',
    name: 'Power Metal Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PPC-LN',
    name: 'President Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'PPC-PZ',
    name: 'President Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'PPH-LN',
    name: 'PPHE Hotel Group Limited',
    region: 'GB',
  },
  {
    symbol: 'PPP-LN',
    name: 'Pennpetro Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'PPS-LN',
    name: 'Proton Motor Power Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'PRD-LN',
    name: 'Predator Oil & Gas Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'PRE-LN',
    name: 'Pensana Plc',
    region: 'GB',
  },
  {
    symbol: 'PREM-LN',
    name: 'Premier African Minerals Limited',
    region: 'GB',
  },
  {
    symbol: 'PREM-PZ',
    name: 'Premier African Minerals Limited',
    region: 'GB',
  },
  {
    symbol: 'PRES-LN',
    name: 'Pressure Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'PRES-PZ',
    name: 'Pressure Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'PRIM-LN',
    name: 'Primorus Investments Plc',
    region: 'GB',
  },
  {
    symbol: 'PRM-LN',
    name: 'Proteome Sciences plc',
    region: 'GB',
  },
  {
    symbol: 'PRM-PZ',
    name: 'Proteome Sciences plc',
    region: 'GB',
  },
  {
    symbol: 'PRTC-LN',
    name: 'PureTech Health Plc',
    region: 'GB',
  },
  {
    symbol: 'PRU-LN',
    name: 'Prudential plc',
    region: 'GB',
  },
  {
    symbol: 'PRV-LN',
    name: 'Porvair plc',
    region: 'GB',
  },
  {
    symbol: 'PSN-LN',
    name: 'Persimmon plc',
    region: 'GB',
  },
  {
    symbol: 'PSON-LN',
    name: 'Pearson plc',
    region: 'GB',
  },
  {
    symbol: 'PTAL-LN',
    name: 'PetroTal Corp',
    region: 'GB',
  },
  {
    symbol: 'PTD-LN',
    name: 'Pittards',
    region: 'GB',
  },
  {
    symbol: 'PTEC-LN',
    name: 'Playtech Plc',
    region: 'GB',
  },
  {
    symbol: 'PTR-LN',
    name: 'Petroneft Resources',
    region: 'GB',
  },
  {
    symbol: 'PTR-PZ',
    name: 'Petroneft Resources',
    region: 'GB',
  },
  {
    symbol: 'PTRO-LN',
    name: 'Pelatro Plc',
    region: 'GB',
  },
  {
    symbol: 'PTY-LN',
    name: 'Parity Group plc',
    region: 'GB',
  },
  {
    symbol: 'PUR-LN',
    name: 'Pure Gold Mining Inc',
    region: 'GB',
  },
  {
    symbol: 'PURP-LN',
    name: 'Purplebricks Group plc',
    region: 'GB',
  },
  {
    symbol: 'PVR-LN',
    name: 'Providence Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PVR-PZ',
    name: 'Providence Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'PXC-LN',
    name: 'Phoenix Copper Limited',
    region: 'GB',
  },
  {
    symbol: 'PXEN-LN',
    name: 'Prospex Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'PXEN-PZ',
    name: 'Prospex Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'PXS-LN',
    name: 'Provexis Plc',
    region: 'GB',
  },
  {
    symbol: 'PXS-PZ',
    name: 'Provexis Plc',
    region: 'GB',
  },
  {
    symbol: 'PYC-LN',
    name: 'Physiomics',
    region: 'GB',
  },
  {
    symbol: 'PYX-LN',
    name: 'Pyx Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'PZC-LN',
    name: 'PZ Cussons plc',
    region: 'GB',
  },
  {
    symbol: 'QBT-LN',
    name: 'Quantum Blockchain Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'QFI-LN',
    name: 'Quadrise Fuels International Plc',
    region: 'GB',
  },
  {
    symbol: 'QFI-PZ',
    name: 'Quadrise Fuels International Plc',
    region: 'GB',
  },
  {
    symbol: 'QLT-LN',
    name: 'Quilter Plc',
    region: 'GB',
  },
  {
    symbol: 'QRT-LN',
    name: 'Quarto Group Inc.',
    region: 'GB',
  },
  {
    symbol: 'QTX-LN',
    name: 'Quartix Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'QTZ-PZ',
    name: 'Quetzal Capital Plc',
    region: 'GB',
  },
  {
    symbol: 'QUIZ-LN',
    name: 'Quiz Plc',
    region: 'GB',
  },
  {
    symbol: 'QXT-LN',
    name: 'Quixant Plc',
    region: 'GB',
  },
  {
    symbol: 'QXT-PZ',
    name: 'Quixant Plc',
    region: 'GB',
  },
  {
    symbol: 'RAI-LN',
    name: 'RA International Group Plc',
    region: 'GB',
  },
  {
    symbol: 'RBD-LN',
    name: 'Reabold Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'RBG-LN',
    name: 'Revolution Bars Group Plc',
    region: 'GB',
  },
  {
    symbol: 'RBGP-LN',
    name: 'RBG Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'RBN-LN',
    name: 'Robinson',
    region: 'GB',
  },
  {
    symbol: 'RBW-LN',
    name: 'Rainbow Rare Earths Ltd',
    region: 'GB',
  },
  {
    symbol: 'RCDO-LN',
    name: 'Ricardo plc',
    region: 'GB',
  },
  {
    symbol: 'RCH-LN',
    name: 'Reach plc',
    region: 'GB',
  },
  {
    symbol: 'RCN-LN',
    name: 'Redcentric Plc',
    region: 'GB',
  },
  {
    symbol: 'RCN-PZ',
    name: 'Redcentric Plc',
    region: 'GB',
  },
  {
    symbol: 'RDT-LN',
    name: 'Rosslyn Data Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'RDW-LN',
    name: 'Redrow plc',
    region: 'GB',
  },
  {
    symbol: 'REC-LN',
    name: 'Record Plc',
    region: 'GB',
  },
  {
    symbol: 'REDD-LN',
    name: 'Redde Northgate Plc',
    region: 'GB',
  },
  {
    symbol: 'REDX-LN',
    name: 'Redx Pharma Plc',
    region: 'GB',
  },
  {
    symbol: 'REL-LN',
    name: 'RELX Plc',
    region: 'GB',
  },
  {
    symbol: 'RENE-LN',
    name: 'Reneuron Group',
    region: 'GB',
  },
  {
    symbol: 'RENE-PZ',
    name: 'Reneuron Group',
    region: 'GB',
  },
  {
    symbol: 'RENX-LN',
    name: 'Renalytix Plc',
    region: 'GB',
  },
  {
    symbol: 'REVB-LN',
    name: 'Revolution Beauty Group Plc',
    region: 'GB',
  },
  {
    symbol: 'RGD-LN',
    name: 'Real Good Food Plc',
    region: 'GB',
  },
  {
    symbol: 'RGO-LN',
    name: 'RiverFort Global Opportunities Plc',
    region: 'GB',
  },
  {
    symbol: 'RGP-LN',
    name: 'Ross Group plc',
    region: 'GB',
  },
  {
    symbol: 'RHIM-LN',
    name: 'RHI Magnesita N.V.',
    region: 'GB',
  },
  {
    symbol: 'RICO-LN',
    name: 'Ricoh Company Ltd',
    region: 'GB',
  },
  {
    symbol: 'RIFA-LN',
    name: 'Reliance Infrastructure Ltd - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'RIFS-LN',
    name: 'Reliance Infrastructure Ltd - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'RIGD-LN',
    name: 'Reliance Industries Ltd. - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'RIO-LN',
    name: 'Rio Tinto plc - Registered Shares',
    region: 'GB',
  },
  {
    symbol: 'RKH-LN',
    name: 'Rockhopper Exploration',
    region: 'GB',
  },
  {
    symbol: 'RKH-PZ',
    name: 'Rockhopper Exploration',
    region: 'GB',
  },
  {
    symbol: 'RKT-LN',
    name: 'Reckitt Benckiser Group Plc',
    region: 'GB',
  },
  {
    symbol: 'RMG-LN',
    name: 'Royal Mail Plc',
    region: 'GB',
  },
  {
    symbol: 'RMM-LN',
    name: 'Rambler Metals & Mining',
    region: 'GB',
  },
  {
    symbol: 'RMV-LN',
    name: 'Rightmove Plc',
    region: 'GB',
  },
  {
    symbol: 'RNK-LN',
    name: 'Rank Group plc',
    region: 'GB',
  },
  {
    symbol: 'RNO-LN',
    name: 'Renold plc',
    region: 'GB',
  },
  {
    symbol: 'RNWH-LN',
    name: 'Renew Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'ROCK-LN',
    name: 'Rockfire Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'ROCK-PZ',
    name: 'Rockfire Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'ROL-LN',
    name: 'Rotala',
    region: 'GB',
  },
  {
    symbol: 'ROO-LN',
    name: 'Deliveroo Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'ROR-LN',
    name: 'Rotork plc',
    region: 'GB',
  },
  {
    symbol: 'RPS-LN',
    name: 'RPS Group plc',
    region: 'GB',
  },
  {
    symbol: 'RQIH-LN',
    name: 'Randall & Quilter Investment Holdings Ltd',
    region: 'GB',
  },
  {
    symbol: 'RRR-LN',
    name: 'Red Rock Resources',
    region: 'GB',
  },
  {
    symbol: 'RRR-PZ',
    name: 'Red Rock Resources',
    region: 'GB',
  },
  {
    symbol: 'RSG-LN',
    name: 'Resolute Mining Ltd.',
    region: 'GB',
  },
  {
    symbol: 'RST-LN',
    name: 'Restore Plc',
    region: 'GB',
  },
  {
    symbol: 'RSW-LN',
    name: 'Renishaw plc',
    region: 'GB',
  },
  {
    symbol: 'RTC-LN',
    name: 'RTC Group Plc',
    region: 'GB',
  },
  {
    symbol: 'RTN-LN',
    name: 'Restaurant Group plc',
    region: 'GB',
  },
  {
    symbol: 'RTO-LN',
    name: 'Rentokil Initial',
    region: 'GB',
  },
  {
    symbol: 'RUA-LN',
    name: 'RUA Life Sciences Plc',
    region: 'GB',
  },
  {
    symbol: 'RUR-LN',
    name: 'Rurelec',
    region: 'GB',
  },
  {
    symbol: 'RWA-LN',
    name: 'Robert Walters plc',
    region: 'GB',
  },
  {
    symbol: 'RWI-LN',
    name: 'Renewi Plc',
    region: 'GB',
  },
  {
    symbol: 'RWS-LN',
    name: 'RWS Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'RWS-PZ',
    name: 'RWS Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'S32-LN',
    name: 'South32 Limited',
    region: 'GB',
  },
  {
    symbol: 'SAE-LN',
    name: 'SIMEC Atlantis Energy Limited',
    region: 'GB',
  },
  {
    symbol: 'SAFE-LN',
    name: 'Safestore Hldgs Plc',
    region: 'GB',
  },
  {
    symbol: 'SAG-LN',
    name: 'Science Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SAGA-LN',
    name: 'Saga Plc',
    region: 'GB',
  },
  {
    symbol: 'SAL-LN',
    name: 'Spaceandpeople',
    region: 'GB',
  },
  {
    symbol: 'SAL-PZ',
    name: 'Spaceandpeople',
    region: 'GB',
  },
  {
    symbol: 'SAR-LN',
    name: 'Sareum Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'SAUD-LN',
    name: 'Steel Authority of India Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'SAV-LN',
    name: 'Savannah Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'SAV-PZ',
    name: 'Savannah Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'SAVE-LN',
    name: 'Savannah Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SBI-LN',
    name: 'SourceBio International Plc',
    region: 'GB',
  },
  {
    symbol: 'SBIA-LN',
    name: 'State Bank of India - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'SBID-LN',
    name: 'State Bank of India - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'SBRE-LN',
    name: 'Sabre Insurance Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SBRY-LN',
    name: 'Sainsbury (J) plc',
    region: 'GB',
  },
  {
    symbol: 'SBTX-LN',
    name: 'SkinBioTherapeutics Plc',
    region: 'GB',
  },
  {
    symbol: 'SCE-LN',
    name: 'Surface Transforms',
    region: 'GB',
  },
  {
    symbol: 'SCF-LN',
    name: 'Schroder Income Growth Fund plc',
    region: 'GB',
  },
  {
    symbol: 'SCGL-LN',
    name: 'Sealand Capital Galaxy Limited',
    region: 'GB',
  },
  {
    symbol: 'SCHO-LN',
    name: 'Scholium Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SCIR-LN',
    name: 'Scirocco Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SCLP-LN',
    name: 'Scancell Hldgs Plc',
    region: 'GB',
  },
  {
    symbol: 'SCS-LN',
    name: 'ScS Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SCT-LN',
    name: 'Softcat Plc',
    region: 'GB',
  },
  {
    symbol: 'SDG-LN',
    name: 'Sanderson Design Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SDI-LN',
    name: 'SDI Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'SDIC-LN',
    name: 'SDIC Power Holdings Co. Ltd - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'SDRY-LN',
    name: 'Superdry Plc',
    region: 'GB',
  },
  {
    symbol: 'SDX-LN',
    name: 'SDX Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SDY-LN',
    name: 'Speedy Hire Plc',
    region: 'GB',
  },
  {
    symbol: 'SEE-LN',
    name: 'Seeing Machines',
    region: 'GB',
  },
  {
    symbol: 'SEE-PZ',
    name: 'Seeing Machines',
    region: 'GB',
  },
  {
    symbol: 'SEEN-LN',
    name: 'SEEEN Plc',
    region: 'GB',
  },
  {
    symbol: 'SEFA-LN',
    name: 'Shefa Gems Ltd',
    region: 'GB',
  },
  {
    symbol: 'SENS-LN',
    name: 'Sensyne Health Plc',
    region: 'GB',
  },
  {
    symbol: 'SENX-LN',
    name: 'Serinus Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SEPL-LN',
    name: 'Seplat Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SFE-LN',
    name: 'Safestyle UK Plc',
    region: 'GB',
  },
  {
    symbol: 'SFOR-LN',
    name: 'S4 Capital Plc',
    region: 'GB',
  },
  {
    symbol: 'SFR-LN',
    name: 'Severfield Plc',
    region: 'GB',
  },
  {
    symbol: 'SGC-LN',
    name: 'Stagecoach Group plc',
    region: 'GB',
  },
  {
    symbol: 'SGE-LN',
    name: 'Sage Group plc',
    region: 'GB',
  },
  {
    symbol: 'SGI-LN',
    name: 'Stanley Gibbons Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SGZ-LN',
    name: 'Scotgold Resources Ltd.',
    region: 'GB',
  },
  {
    symbol: 'SGZ-PZ',
    name: 'Scotgold Resources Ltd.',
    region: 'GB',
  },
  {
    symbol: 'SHEL-LN',
    name: 'Shell Plc',
    region: 'GB',
  },
  {
    symbol: 'SHEP-PZ',
    name: 'Shepherd Neame',
    region: 'GB',
  },
  {
    symbol: 'SHG-LN',
    name: 'Shanta Gold',
    region: 'GB',
  },
  {
    symbol: 'SHI-LN',
    name: 'SIG plc',
    region: 'GB',
  },
  {
    symbol: 'SHNJ-PZ',
    name: 'Rogue Baron Plc',
    region: 'GB',
  },
  {
    symbol: 'SHOE-LN',
    name: 'Shoe Zone Plc',
    region: 'GB',
  },
  {
    symbol: 'SIS-LN',
    name: 'Science in Sport Plc',
    region: 'GB',
  },
  {
    symbol: 'SIXH-LN',
    name: '600 Group plc',
    region: 'GB',
  },
  {
    symbol: 'SIXH-PZ',
    name: '600 Group plc',
    region: 'GB',
  },
  {
    symbol: 'SKG-LN',
    name: 'Smurfit Kappa Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SKMD-LN',
    name: 'SK Telecom Co Ltd - ADR',
    region: 'GB',
  },
  {
    symbol: 'SLE-PZ',
    name: 'San Leon Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SLNG-LN',
    name: 'Slingsby (H.C.)',
    region: 'GB',
  },
  {
    symbol: 'SLP-LN',
    name: 'Sylvania Platinum Limited',
    region: 'GB',
  },
  {
    symbol: 'SLP-PZ',
    name: 'Sylvania Platinum Limited',
    region: 'GB',
  },
  {
    symbol: 'SMAP-PZ',
    name: 'St Mark Homes Plc',
    region: 'GB',
  },
  {
    symbol: 'SMDS-LN',
    name: 'Smith (DS) plc',
    region: 'GB',
  },
  {
    symbol: 'SMIN-LN',
    name: 'Smiths Group plc',
    region: 'GB',
  },
  {
    symbol: 'SMJ-LN',
    name: 'Smart (J.) & Co Contractors plc',
    region: 'GB',
  },
  {
    symbol: 'SML-LN',
    name: 'Strategic Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'SML-PZ',
    name: 'Strategic Minerals Plc',
    region: 'GB',
  },
  {
    symbol: 'SMRT-LN',
    name: 'Smartspace Software Plc',
    region: 'GB',
  },
  {
    symbol: 'SMS-LN',
    name: 'Smart Metering Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'SMS-PZ',
    name: 'Smart Metering Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'SMSD-LN',
    name: 'Samsung Electronics - GDR - 144A Preference Shares 0%',
    region: 'GB',
  },
  {
    symbol: 'SMV-LN',
    name: 'Smoove Plc',
    region: 'GB',
  },
  {
    symbol: 'SMWH-LN',
    name: 'WH Smith Plc',
    region: 'GB',
  },
  {
    symbol: 'SNG-LN',
    name: 'Synairgen',
    region: 'GB',
  },
  {
    symbol: 'SNG1-LN',
    name: 'SNGN Romgaz SA - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'SNGR-LN',
    name: 'SNGN Romgaz SA - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'SNN-LN',
    name: 'Sanne Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SNP-LN',
    name: 'China Petroleum & Chemical Corp - ADR',
    region: 'GB',
  },
  {
    symbol: 'SNR-LN',
    name: 'Senior plc',
    region: 'GB',
  },
  {
    symbol: 'SNT-LN',
    name: 'Sabien Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SNWS-LN',
    name: 'Smiths News Plc',
    region: 'GB',
  },
  {
    symbol: 'SNX-LN',
    name: 'Synectics Plc',
    region: 'GB',
  },
  {
    symbol: 'SOLG-LN',
    name: 'SolGold Plc',
    region: 'GB',
  },
  {
    symbol: 'SOLI-LN',
    name: 'Solid State',
    region: 'GB',
  },
  {
    symbol: 'SOM-LN',
    name: 'Somero Enterprises Inc',
    region: 'GB',
  },
  {
    symbol: 'SOS-LN',
    name: 'Sosandar Plc',
    region: 'GB',
  },
  {
    symbol: 'SOU-LN',
    name: 'Sound Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'SOUC-LN',
    name: 'Southern Energy Corp',
    region: 'GB',
  },
  {
    symbol: 'SPA-LN',
    name: '1Spatial Plc',
    region: 'GB',
  },
  {
    symbol: 'SPA-PZ',
    name: '1Spatial Plc',
    region: 'GB',
  },
  {
    symbol: 'SPE-LN',
    name: 'Sopheon plc',
    region: 'GB',
  },
  {
    symbol: 'SPEC-LN',
    name: 'Inspecs Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SPI-LN',
    name: 'Spire Healthcare Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SPO-LN',
    name: 'Sportech plc',
    region: 'GB',
  },
  {
    symbol: 'SPR-LN',
    name: 'Springfield Properties Plc',
    region: 'GB',
  },
  {
    symbol: 'SPSC-LN',
    name: 'Spectra Systems Corporation - Reg S',
    region: 'GB',
  },
  {
    symbol: 'SPSY-LN',
    name: 'Spectra Systems Corporation',
    region: 'GB',
  },
  {
    symbol: 'SPT-LN',
    name: 'Spirent Communications Plc',
    region: 'GB',
  },
  {
    symbol: 'SPX-LN',
    name: 'Spirax-Sarco Engineering plc',
    region: 'GB',
  },
  {
    symbol: 'SQZ-LN',
    name: 'Serica Energy PLC',
    region: 'GB',
  },
  {
    symbol: 'SQZ-PZ',
    name: 'Serica Energy PLC',
    region: 'GB',
  },
  {
    symbol: 'SRB-LN',
    name: 'Serabi Gold Plc',
    region: 'GB',
  },
  {
    symbol: 'SRC-LN',
    name: 'SigmaRoc Plc',
    region: 'GB',
  },
  {
    symbol: 'SRES-LN',
    name: 'Sunrise Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'SRES-PZ',
    name: 'Sunrise Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'SRP-LN',
    name: 'Serco Group plc',
    region: 'GB',
  },
  {
    symbol: 'SRT-LN',
    name: 'SRT Marine Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'SSE-LN',
    name: 'SSE Plc.',
    region: 'GB',
  },
  {
    symbol: 'SSPG-LN',
    name: 'SSP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SSTY-LN',
    name: 'Safestay Plc',
    region: 'GB',
  },
  {
    symbol: 'STA-LN',
    name: 'Star Phoenix Group Ltd',
    region: 'GB',
  },
  {
    symbol: 'STAF-LN',
    name: 'Staffline Group Plc',
    region: 'GB',
  },
  {
    symbol: 'STAF-PZ',
    name: 'Staffline Group Plc',
    region: 'GB',
  },
  {
    symbol: 'STAN-LN',
    name: 'Standard Chartered plc',
    region: 'GB',
  },
  {
    symbol: 'STB-LN',
    name: 'Secure Trust Bank Plc',
    region: 'GB',
  },
  {
    symbol: 'STCM-LN',
    name: 'Steppe Cement',
    region: 'GB',
  },
  {
    symbol: 'STEM-LN',
    name: 'Sthree',
    region: 'GB',
  },
  {
    symbol: 'STJ-LN',
    name: 'St. James`s Place Plc',
    region: 'GB',
  },
  {
    symbol: 'STM-LN',
    name: 'STM Group Plc',
    region: 'GB',
  },
  {
    symbol: 'STVG-LN',
    name: 'STV Group Plc',
    region: 'GB',
  },
  {
    symbol: 'STX-LN',
    name: 'Shield Therapeutics plc',
    region: 'GB',
  },
  {
    symbol: 'SUH-LN',
    name: 'Sutton Harbour Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SUN-LN',
    name: 'Surgical Innovations Group',
    region: 'GB',
  },
  {
    symbol: 'SUP-LN',
    name: 'Supreme Plc',
    region: 'GB',
  },
  {
    symbol: 'SUR-LN',
    name: 'Sureserve Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SVE-LN',
    name: 'Starvest Plc',
    region: 'GB',
  },
  {
    symbol: 'SVML-LN',
    name: 'Sovereign Metals Limited',
    region: 'GB',
  },
  {
    symbol: 'SVS-LN',
    name: 'Savills plc',
    region: 'GB',
  },
  {
    symbol: 'SVT-LN',
    name: 'Severn Trent plc',
    region: 'GB',
  },
  {
    symbol: 'SWG-LN',
    name: 'Shearwater Group Plc',
    region: 'GB',
  },
  {
    symbol: 'SXS-LN',
    name: 'Spectris',
    region: 'GB',
  },
  {
    symbol: 'SYM-LN',
    name: 'Symphony Environmental Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'SYNT-LN',
    name: 'Synthomer plc',
    region: 'GB',
  },
  {
    symbol: 'SYS-LN',
    name: 'SysGroup Plc',
    region: 'GB',
  },
  {
    symbol: 'SYS1-LN',
    name: 'System1 Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TAN-LN',
    name: 'Tanfield Group plc',
    region: 'GB',
  },
  {
    symbol: 'TAN-PZ',
    name: 'Tanfield Group plc',
    region: 'GB',
  },
  {
    symbol: 'TAST-LN',
    name: 'Tasty',
    region: 'GB',
  },
  {
    symbol: 'TAT-LN',
    name: 'Tatung - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'TATE-LN',
    name: 'Tate & Lyle plc',
    region: 'GB',
  },
  {
    symbol: 'TAVI-LN',
    name: 'Tavistock Investments Plc',
    region: 'GB',
  },
  {
    symbol: 'TBCG-LN',
    name: 'TBC Bank Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'TECH-PZ',
    name: 'TechFinancials Inc.',
    region: 'GB',
  },
  {
    symbol: 'TED-LN',
    name: 'Ted Baker',
    region: 'GB',
  },
  {
    symbol: 'TEEG-LN',
    name: 'Telecom Egypt - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'TEG-LN',
    name: 'Ten Entertainment Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TEK-LN',
    name: 'Tekcapital Plc',
    region: 'GB',
  },
  {
    symbol: 'TENG-LN',
    name: 'Ten Lifestyle Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TEP-LN',
    name: 'Telecom Plus plc',
    region: 'GB',
  },
  {
    symbol: 'TERN-LN',
    name: 'Tern Plc',
    region: 'GB',
  },
  {
    symbol: 'TET-LN',
    name: 'Treatt plc',
    region: 'GB',
  },
  {
    symbol: 'TFW-LN',
    name: 'Thorpe (FW)',
    region: 'GB',
  },
  {
    symbol: 'TFW-PZ',
    name: 'Thorpe (FW)',
    region: 'GB',
  },
  {
    symbol: 'TGA-LN',
    name: 'Thungela Resources Limited',
    region: 'GB',
  },
  {
    symbol: 'TGBL-LN',
    name: 'Tata Consumer Products Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'TGL-LN',
    name: 'Transglobe Energy Corp.',
    region: 'GB',
  },
  {
    symbol: 'TGP-LN',
    name: 'Tekmar Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TGR-LN',
    name: 'Tirupati Graphite Plc',
    region: 'GB',
  },
  {
    symbol: 'THAL-LN',
    name: 'Thalassa Holdings Ltd',
    region: 'GB',
  },
  {
    symbol: 'THG-LN',
    name: 'THG Plc',
    region: 'GB',
  },
  {
    symbol: 'THR-LN',
    name: 'Thor Mining',
    region: 'GB',
  },
  {
    symbol: 'THRU-LN',
    name: 'Thruvision Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'THS-LN',
    name: 'Tharisa plc',
    region: 'GB',
  },
  {
    symbol: 'THW-PZ',
    name: 'Thwaites(Daniel)',
    region: 'GB',
  },
  {
    symbol: 'THX-LN',
    name: 'Thor Explorations Ltd',
    region: 'GB',
  },
  {
    symbol: 'TIBD-LN',
    name: 'Turkiye Is Bankasi - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'TIDE-LN',
    name: 'Crimson Tide Plc',
    region: 'GB',
  },
  {
    symbol: 'TIFS-LN',
    name: 'TI Fluid Systems Plc',
    region: 'GB',
  },
  {
    symbol: 'TIR-LN',
    name: 'Tiger Royalties and Investments Plc',
    region: 'GB',
  },
  {
    symbol: 'TKO-LN',
    name: 'Taseko Mines Ltd.',
    region: 'GB',
  },
  {
    symbol: 'TLOU-LN',
    name: 'Tlou Energy Limited',
    region: 'GB',
  },
  {
    symbol: 'TLW-LN',
    name: 'Tullow Oil Plc',
    region: 'GB',
  },
  {
    symbol: 'TLY-LN',
    name: 'Totally Plc',
    region: 'GB',
  },
  {
    symbol: 'TM17-LN',
    name: 'Team17 Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TMG-LN',
    name: 'Mission Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TMO-LN',
    name: 'Time Out Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TND-LN',
    name: 'Tandem Group plc',
    region: 'GB',
  },
  {
    symbol: 'TNT-LN',
    name: 'Tintra Plc',
    region: 'GB',
  },
  {
    symbol: 'TOM-LN',
    name: 'Tomco Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'TOM-PZ',
    name: 'Tomco Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'TON-LN',
    name: 'Titon Holdings plc',
    region: 'GB',
  },
  {
    symbol: 'TOOP-LN',
    name: 'Toople Plc',
    region: 'GB',
  },
  {
    symbol: 'TOUR-LN',
    name: 'Hanatour Service Inc. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'TPCL-LN',
    name: 'Tata Power Co. Ltd. - GDR - Reg S (876566407)',
    region: 'GB',
  },
  {
    symbol: 'TPG-LN',
    name: 'TP Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TPK-LN',
    name: 'Travis Perkins plc',
    region: 'GB',
  },
  {
    symbol: 'TPT-LN',
    name: 'Topps Tiles',
    region: 'GB',
  },
  {
    symbol: 'TPX-LN',
    name: 'TPXimpact Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'TRAC-LN',
    name: 't42 IoT Tracking Solutions Plc',
    region: 'GB',
  },
  {
    symbol: 'TRAF-LN',
    name: 'Trafalgar Property Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TRAF-PZ',
    name: 'Trafalgar Property Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TRAK-LN',
    name: 'TrakM8 Hldgs',
    region: 'GB',
  },
  {
    symbol: 'TRB-LN',
    name: 'Tribal Group',
    region: 'GB',
  },
  {
    symbol: 'TRCS-LN',
    name: 'Tracsis Plc',
    region: 'GB',
  },
  {
    symbol: 'TRCS-PZ',
    name: 'Tracsis Plc',
    region: 'GB',
  },
  {
    symbol: 'TRD-LN',
    name: 'Triad Group plc',
    region: 'GB',
  },
  {
    symbol: 'TREE-LN',
    name: 'Cambium Global Timberland Ltd',
    region: 'GB',
  },
  {
    symbol: 'TRI-LN',
    name: 'Trifast',
    region: 'GB',
  },
  {
    symbol: 'TRIN-LN',
    name: 'Trinity Exploration & Production Plc',
    region: 'GB',
  },
  {
    symbol: 'TRIN-PZ',
    name: 'Trinity Exploration & Production Plc',
    region: 'GB',
  },
  {
    symbol: 'TRLS-LN',
    name: 'Trellus Health Plc',
    region: 'GB',
  },
  {
    symbol: 'TRMR-LN',
    name: 'Tremor International Ltd',
    region: 'GB',
  },
  {
    symbol: 'TRN-LN',
    name: 'Trainline Plc',
    region: 'GB',
  },
  {
    symbol: 'TRP-LN',
    name: 'Tower Resources',
    region: 'GB',
  },
  {
    symbol: 'TRR-LN',
    name: 'Trident Royalties Plc',
    region: 'GB',
  },
  {
    symbol: 'TRST-LN',
    name: 'Trustpilot Group plc',
    region: 'GB',
  },
  {
    symbol: 'TRT-LN',
    name: 'Transense Technologies plc',
    region: 'GB',
  },
  {
    symbol: 'TRT-PZ',
    name: 'Transense Technologies plc',
    region: 'GB',
  },
  {
    symbol: 'TRX-LN',
    name: 'Tissue Regenix Group Plc',
    region: 'GB',
  },
  {
    symbol: 'TSCO-LN',
    name: 'Tesco plc',
    region: 'GB',
  },
  {
    symbol: 'TSL-LN',
    name: 'ThinkSmart Ltd',
    region: 'GB',
  },
  {
    symbol: 'TST-LN',
    name: 'Touchstar Plc',
    region: 'GB',
  },
  {
    symbol: 'TSTL-LN',
    name: 'Tristel',
    region: 'GB',
  },
  {
    symbol: 'TTAU-PZ',
    name: 'Tectonic Gold Plc',
    region: 'GB',
  },
  {
    symbol: 'TTE-LN',
    name: 'TotalEnergies SE',
    region: 'GB',
  },
  {
    symbol: 'TTG-LN',
    name: 'TT Electronics plc',
    region: 'GB',
  },
  {
    symbol: 'TTST-LN',
    name: 'Tata Steel Ltd. - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'TUI-LN',
    name: 'Tui AG - Registered Shares',
    region: 'GB',
  },
  {
    symbol: 'TUNE-LN',
    name: 'Focusrite Plc',
    region: 'GB',
  },
  {
    symbol: 'TUNG-LN',
    name: 'Tungsten Corporation Plc',
    region: 'GB',
  },
  {
    symbol: 'TUNG-PZ',
    name: 'Tungsten Corporation Plc',
    region: 'GB',
  },
  {
    symbol: 'TWD-LN',
    name: 'Trackwise Designs Plc',
    region: 'GB',
  },
  {
    symbol: 'TXP-LN',
    name: 'Touchstone Exploration Inc.',
    region: 'GB',
  },
  {
    symbol: 'TYM-LN',
    name: 'Tertiary Minerals',
    region: 'GB',
  },
  {
    symbol: 'TYMN-LN',
    name: 'Tyman plc',
    region: 'GB',
  },
  {
    symbol: 'TYT-LN',
    name: 'Toyota Motor Corporation',
    region: 'GB',
  },
  {
    symbol: 'UBLA-LN',
    name: 'United Bank Limited - GDR - 144A',
    region: 'GB',
  },
  {
    symbol: 'UBLS-LN',
    name: 'United Bank Limited - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'UFO-LN',
    name: 'Alien Metals Limited',
    region: 'GB',
  },
  {
    symbol: 'UJO-LN',
    name: 'Union Jack Oil Plc',
    region: 'GB',
  },
  {
    symbol: 'UJO-PZ',
    name: 'Union Jack Oil Plc',
    region: 'GB',
  },
  {
    symbol: 'UKOG-LN',
    name: 'UK Oil & Gas Plc',
    region: 'GB',
  },
  {
    symbol: 'UKR-LN',
    name: 'Ukrproduct Group',
    region: 'GB',
  },
  {
    symbol: 'UKR-PZ',
    name: 'Ukrproduct Group',
    region: 'GB',
  },
  {
    symbol: 'ULE-LN',
    name: 'Ultra Electronics Holdings',
    region: 'GB',
  },
  {
    symbol: 'ULVR-LN',
    name: 'Unilever plc',
    region: 'GB',
  },
  {
    symbol: 'UOG-LN',
    name: 'United Oil & Gas Plc',
    region: 'GB',
  },
  {
    symbol: 'UPGS-LN',
    name: 'UP Global Sourcing Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'UPR-LN',
    name: 'Uniphar Plc',
    region: 'GB',
  },
  {
    symbol: 'URAH-LN',
    name: 'URA Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'URU-LN',
    name: 'URU Metals Limited',
    region: 'GB',
  },
  {
    symbol: 'USY-LN',
    name: 'Unisys Corp.',
    region: 'GB',
  },
  {
    symbol: 'UVEL-LN',
    name: 'Univision Engineering',
    region: 'GB',
  },
  {
    symbol: 'VAL-LN',
    name: 'Valirx Plc',
    region: 'GB',
  },
  {
    symbol: 'VANL-LN',
    name: 'Van Elle Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'VARE-LN',
    name: 'Various Eateries Plc',
    region: 'GB',
  },
  {
    symbol: 'VAST-LN',
    name: 'Vast Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'VCP-LN',
    name: 'Victoria Plc.',
    region: 'GB',
  },
  {
    symbol: 'VCT-LN',
    name: 'Victrex plc',
    region: 'GB',
  },
  {
    symbol: 'VDTK-LN',
    name: 'Verditek Plc',
    region: 'GB',
  },
  {
    symbol: 'VEL-LN',
    name: 'Velocity Composites Plc',
    region: 'GB',
  },
  {
    symbol: 'VELA-LN',
    name: 'Vela Technologies Plc',
    region: 'GB',
  },
  {
    symbol: 'VIC-LN',
    name: 'Victorian Plumbing Group Plc',
    region: 'GB',
  },
  {
    symbol: 'VINO-LN',
    name: 'Virgin Wines UK Plc',
    region: 'GB',
  },
  {
    symbol: 'VLE-LN',
    name: 'Volvere',
    region: 'GB',
  },
  {
    symbol: 'VLG-LN',
    name: 'Venture Life Group Plc',
    region: 'GB',
  },
  {
    symbol: 'VLRM-PZ',
    name: 'Valereum Plc',
    region: 'GB',
  },
  {
    symbol: 'VLS-LN',
    name: 'Velocys Plc',
    region: 'GB',
  },
  {
    symbol: 'VLS-PZ',
    name: 'Velocys Plc',
    region: 'GB',
  },
  {
    symbol: 'VLU-LN',
    name: 'Valeura Energy Inc',
    region: 'GB',
  },
  {
    symbol: 'VLX-LN',
    name: 'Volex Plc',
    region: 'GB',
  },
  {
    symbol: 'VMUK-LN',
    name: 'Virgin Money UK Plc.',
    region: 'GB',
  },
  {
    symbol: 'VNET-LN',
    name: 'Vianet Group Plc.',
    region: 'GB',
  },
  {
    symbol: 'VOD-LN',
    name: 'Vodafone Group plc',
    region: 'GB',
  },
  {
    symbol: 'VRCI-LN',
    name: 'Verici Dx Plc',
    region: 'GB',
  },
  {
    symbol: 'VRS-LN',
    name: 'Versarien Plc',
    region: 'GB',
  },
  {
    symbol: 'VRS-PZ',
    name: 'Versarien Plc',
    region: 'GB',
  },
  {
    symbol: 'VSA-PZ',
    name: 'VSA Capital Group Plc',
    region: 'GB',
  },
  {
    symbol: 'VSVS-LN',
    name: 'Vesuvius Plc',
    region: 'GB',
  },
  {
    symbol: 'VTC-LN',
    name: 'Vitec Group plc',
    region: 'GB',
  },
  {
    symbol: 'VTU-LN',
    name: 'Vertu Motors Plc',
    region: 'GB',
  },
  {
    symbol: 'VTU-PZ',
    name: 'Vertu Motors Plc',
    region: 'GB',
  },
  {
    symbol: 'VTY-LN',
    name: 'Vistry Group Plc',
    region: 'GB',
  },
  {
    symbol: 'VVO-LN',
    name: 'Vivo Energy plc',
    region: 'GB',
  },
  {
    symbol: 'W7L-LN',
    name: 'Warpaint London Plc',
    region: 'GB',
  },
  {
    symbol: 'WAND-LN',
    name: 'Wandisco Plc',
    region: 'GB',
  },
  {
    symbol: 'WAND-PZ',
    name: 'Wandisco Plc',
    region: 'GB',
  },
  {
    symbol: 'WATR-LN',
    name: 'Water Intelligence Plc',
    region: 'GB',
  },
  {
    symbol: 'WCAT-LN',
    name: 'Wildcat Petroleum Plc',
    region: 'GB',
  },
  {
    symbol: 'WCW-LN',
    name: 'Walker Crips Group Plc',
    region: 'GB',
  },
  {
    symbol: 'WEB-LN',
    name: 'Webis Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'WEIR-LN',
    name: 'Weir Group plc',
    region: 'GB',
  },
  {
    symbol: 'WEN-LN',
    name: 'Wentworth Resources plc',
    region: 'GB',
  },
  {
    symbol: 'WEN-PZ',
    name: 'Wentworth Resources plc',
    region: 'GB',
  },
  {
    symbol: 'WESP-PZ',
    name: 'Western Selection',
    region: 'GB',
  },
  {
    symbol: 'WHLP-PZ',
    name: 'Wheelsure Holdings',
    region: 'GB',
  },
  {
    symbol: 'WIL-LN',
    name: 'Wilmington Plc',
    region: 'GB',
  },
  {
    symbol: 'WIN-LN',
    name: 'Wincanton',
    region: 'GB',
  },
  {
    symbol: 'WINE-LN',
    name: 'Naked Wines Plc',
    region: 'GB',
  },
  {
    symbol: 'WINK-LN',
    name: 'M Winkworth Plc',
    region: 'GB',
  },
  {
    symbol: 'WINK-PZ',
    name: 'M Winkworth Plc',
    region: 'GB',
  },
  {
    symbol: 'WISE-LN',
    name: 'Wise Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'WIX-LN',
    name: 'Wickes Group Plc',
    region: 'GB',
  },
  {
    symbol: 'WIZZ-LN',
    name: 'Wizz Air Holdings Plc',
    region: 'GB',
  },
  {
    symbol: 'WJG-LN',
    name: 'Watkin Jones Plc',
    region: 'GB',
  },
  {
    symbol: 'WML-LN',
    name: 'Wisdom Marine Lines Co Ltd - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'WOSG-LN',
    name: 'Watches Of Switzerland Group Plc',
    region: 'GB',
  },
  {
    symbol: 'WPHO-LN',
    name: 'Windar Photonics Plc',
    region: 'GB',
  },
  {
    symbol: 'WPP-LN',
    name: 'WPP Plc.',
    region: 'GB',
  },
  {
    symbol: 'WRKS-LN',
    name: 'TheWorks.co.uk Plc',
    region: 'GB',
  },
  {
    symbol: 'WSBN-LN',
    name: 'Wishbone Gold Plc',
    region: 'GB',
  },
  {
    symbol: 'WSBN-PZ',
    name: 'Wishbone Gold Plc',
    region: 'GB',
  },
  {
    symbol: 'WSG-LN',
    name: 'Westminster Group Plc',
    region: 'GB',
  },
  {
    symbol: 'WTB-LN',
    name: 'Whitbread plc',
    region: 'GB',
  },
  {
    symbol: 'WTE-LN',
    name: 'Westmount Energy',
    region: 'GB',
  },
  {
    symbol: 'WTG-PZ',
    name: 'Watchstone Group Plc',
    region: 'GB',
  },
  {
    symbol: 'WYN-LN',
    name: 'Wynnstay Group',
    region: 'GB',
  },
  {
    symbol: 'XAR-LN',
    name: 'Xaar',
    region: 'GB',
  },
  {
    symbol: 'XLM-LN',
    name: 'XLMedia Plc',
    region: 'GB',
  },
  {
    symbol: 'XPD-LN',
    name: 'Xpediator Plc',
    region: 'GB',
  },
  {
    symbol: 'XPF-LN',
    name: 'XP Factory Plc',
    region: 'GB',
  },
  {
    symbol: 'XPP-LN',
    name: 'XP Power Ltd',
    region: 'GB',
  },
  {
    symbol: 'XSG-LN',
    name: 'Xeros Technology Group Plc',
    region: 'GB',
  },
  {
    symbol: 'XTR-LN',
    name: 'Xtract Resources Plc',
    region: 'GB',
  },
  {
    symbol: 'YCA-LN',
    name: 'Yellow Cake Plc',
    region: 'GB',
  },
  {
    symbol: 'YGEN-LN',
    name: 'Yourgene Health Plc',
    region: 'GB',
  },
  {
    symbol: 'YNGA-LN',
    name: 'Young & Co`s Brewery Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'YNGA-PZ',
    name: 'Young & Co`s Brewery Plc - Class A',
    region: 'GB',
  },
  {
    symbol: 'YNGN-LN',
    name: 'Young & Co`s Brewery Plc',
    region: 'GB',
  },
  {
    symbol: 'YOOM-PZ',
    name: 'Yooma Wellness Inc',
    region: 'GB',
  },
  {
    symbol: 'YOU-LN',
    name: 'Yougov',
    region: 'GB',
  },
  {
    symbol: 'YOU-PZ',
    name: 'Yougov',
    region: 'GB',
  },
  {
    symbol: 'ZAM-LN',
    name: 'Zambeef Products',
    region: 'GB',
  },
  {
    symbol: 'ZAM-PZ',
    name: 'Zambeef Products',
    region: 'GB',
  },
  {
    symbol: 'ZED-LN',
    name: 'Zenova Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ZEG-LN',
    name: 'Zegona Communications Plc',
    region: 'GB',
  },
  {
    symbol: 'ZEN-LN',
    name: 'Zenith Energy Ltd',
    region: 'GB',
  },
  {
    symbol: 'ZENB-LN',
    name: 'Zenith Bank - GDR - Reg S',
    region: 'GB',
  },
  {
    symbol: 'ZHEH-LN',
    name: 'Zhejiang Expressway Co - Class H',
    region: 'GB',
  },
  {
    symbol: 'ZIN-LN',
    name: 'Zinc Media Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ZIN-PZ',
    name: 'Zinc Media Group Plc',
    region: 'GB',
  },
  {
    symbol: 'ZIOC-LN',
    name: 'Zanaga Iron Ore Company Limited',
    region: 'GB',
  },
  {
    symbol: 'ZIOC-PZ',
    name: 'Zanaga Iron Ore Company Limited',
    region: 'GB',
  },
  {
    symbol: 'ZNWD-LN',
    name: 'Zinnwald Lithium Plc',
    region: 'GB',
  },
  {
    symbol: 'ZOO-LN',
    name: 'Zoo Digital Group',
    region: 'GB',
  },
  {
    symbol: 'ZPHR-LN',
    name: 'Zephyr Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'ZPHR-PZ',
    name: 'Zephyr Energy Plc',
    region: 'GB',
  },
  {
    symbol: 'ZTF-LN',
    name: 'Zotefoams',
    region: 'GB',
  },
  {
    symbol: 'ZYT-LN',
    name: 'Zytronic plc',
    region: 'GB',
  },
]
