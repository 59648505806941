import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { withSuspense } from '@/common/hoc/withSuspense'
import { useInvestAccountStatus } from '@/invest/hooks/useInvestAccountStatus'

const Register = withSuspense(
  React.lazy(() => import('@/invest/pages/register'))
)
const InvestHome = withSuspense(
  React.lazy(() => import('@/invest/pages/home/invest-home'))
)
const AccountDetails = withSuspense(
  React.lazy(() => import('@/invest/pages/AccountDetails'))
)
const AccountDocuments = withSuspense(
  React.lazy(() => import('@/invest/pages/account/account-documents'))
)
const Funding = withSuspense(
  React.lazy(() => import('@/invest/pages/funding/Funding'))
)
const Orders = withSuspense(
  React.lazy(() => import('@/invest/pages/orders/Orders'))
)
const Portfolio = withSuspense(
  React.lazy(() => import('@/invest/pages/portfolio/Portfolio'))
)

const HasInvestAccountRoutes = () => (
  <Routes>
    <Route index element={<InvestHome />} />
    <Route path="portfolio/*" element={<Portfolio />} />
    <Route path="orders/*" element={<Orders />} />
    <Route path="funding/*" element={<Funding />} />
    <Route path="account/*">
      <Route index element={<AccountDetails />} />
      <Route path='documents/*' element={<AccountDocuments />} />
    </Route>
    <Route path="*" element={<Navigate to="." replace />} />
  </Routes>
)

const HasNoInvestAccountRoutes = () => (
  <Routes>
    <Route path="register/*" element={<Register />} />
    <Route path="*" element={<Navigate to="register" replace />} />
  </Routes>
)

export const InvestRouter = () => {
  const { loading, error, hasAccount } = useInvestAccountStatus()

  if (error) {
    return null
  }

  if (loading) {
    return null
  }

  return hasAccount ? <HasInvestAccountRoutes /> : <HasNoInvestAccountRoutes />
}
