import { Routes, Route, Navigate } from 'react-router-dom'
import { EmailVerification } from './pages/EmailVerification'
import { PasswordReset } from './pages/PasswordReset'
import { SignIn } from './pages/SignIn'
import { SignUp } from './pages/SignUp'

export const AuthRouter = () => {
  return (
    <Routes>
      <Route path="log-in" element={<SignIn />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="verification" element={<EmailVerification />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="*" element={<Navigate to="log-in" />} />
    </Routes>
  )
}
