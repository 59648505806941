import { ZakatHolding } from '@/zakat/types'
import { partition } from 'lodash'

const zakatMultiplier = 0.025

export interface CalculationSummary {
  /**
   *  the dollar amount you own of a holding
   */
  baseValue: number
  /**
   *  the dollar amount that you must pay zakat on (should be equal to the
   *  baseValue in the case of activeHolding )
   */
  zakatableAmount: number
  /**
   *  zakatableAmount * 2.5%
   */
  zakatDue: number
}

export type SuccessCalculation = CalculationSummary & { status: 'SUCCESS' }
export type Fallback30Calculation = CalculationSummary & {
  status: 'FALLBACK30'
}
export type FailedCalculation = { status: 'FAIL' }
export type CalculationResult = Partial<ZakatHolding> &
  (SuccessCalculation | Fallback30Calculation | FailedCalculation)
export interface ZakatCalculation {
  zakatDue: number
  holdings: CalculationResult[]
  summary: {
    active: CalculationSummary
    passive: CalculationSummary
    successCount: number
    fallbackCount: number
    failCount: number
  }
}

export const isZakatCalculation = (object: any): object is ZakatCalculation => {
  return (
    object !== null &&
    typeof object === 'object' &&
    'zakatDue' in object &&
    typeof object.zakatDue === 'number' &&
    'holdings' in object &&
    Array.isArray(object.holdings) &&
    'summary' in object &&
    typeof object.summary === 'object' &&
    'active' in object.summary &&
    isCalculationSummary(object.summary.active) &&
    'passive' in object.summary &&
    isCalculationSummary(object.summary.passive) &&
    'successCount' in object.summary &&
    typeof object.summary.successCount === 'number' &&
    'fallbackCount' in object.summary &&
    typeof object.summary.fallbackCount === 'number' &&
    'failCount' in object.summary &&
    typeof object.summary.failCount === 'number'
  )
}

export const isCalculationSummary = (
  object: any
): object is CalculationSummary => {
  return (
    object !== null &&
    typeof object === 'object' &&
    'baseValue' in object &&
    typeof object.baseValue === 'number' &&
    'zakatableAmount' in object &&
    typeof object.zakatableAmount === 'number' &&
    'zakatDue' in object &&
    typeof object.zakatDue === 'number'
  )
}

/**
 * This should be a pure function that takes in a list of holdings and returns
 * an object containing the amount due as well as a breakdown of how much is due
 * from active investments and how much is due from passive ones. Even better
 * would be to maybe show how much is due for each holding.
 */
export const calculateZakat = (holdings: ZakatHolding[]): ZakatCalculation => {
  const calculation: ZakatCalculation = {
    zakatDue: 0,
    summary: {
      active: {
        zakatDue: 0,
        baseValue: 0,
        zakatableAmount: 0,
      },
      passive: {
        zakatDue: 0,
        baseValue: 0,
        zakatableAmount: 0,
      },
      successCount: 0,
      fallbackCount: 0,
      failCount: 0,
    },
    holdings: [],
  }

  return holdings.reduce((calculation, holding) => {
    const { type } = holding

    const isActive = type === 'Active'

    const calculate = isActive ? calculateZakatActive : calculateZakatPassive

    const summaryKey: keyof ZakatCalculation['summary'] = isActive
      ? 'active'
      : 'passive'

    const holdingCalculation = calculate(holding)

    calculation.holdings.push({
      ...holding,
      ...holdingCalculation,
      status: 'SUCCESS',
    })

    calculation.zakatDue += holdingCalculation.zakatDue

    calculation.summary[summaryKey] = aggregateSummary(
      calculation.summary[summaryKey],
      holdingCalculation
    )

    return calculation
  }, calculation)
}

const calculateZakatActive = (holding: ZakatHolding): CalculationSummary => {
  const { shares, unitPrice } = holding

  const baseValue = shares * unitPrice
  const zakatDue = baseValue * zakatMultiplier

  return { baseValue, zakatableAmount: baseValue, zakatDue }
}

const calculateZakatPassive = (holding: ZakatHolding): CalculationSummary => {
  const { shares, unitPrice, currentAssets, outstandingShares } = holding

  const baseValue = shares * unitPrice
  const zakatableAmount = (shares / outstandingShares) * currentAssets
  const zakatDue = zakatableAmount * zakatMultiplier

  return { baseValue, zakatableAmount, zakatDue }
}

const aggregateSummary = (
  a: CalculationSummary,
  b: CalculationSummary
): CalculationSummary => {
  return {
    baseValue: a.baseValue + b.baseValue,
    zakatableAmount: a.zakatableAmount + b.zakatableAmount,
    zakatDue: a.zakatDue + b.zakatDue,
  }
}
