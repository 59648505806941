import { omit } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'

import { useSearch, SearchItem, withSearchProvider } from '@/search/use-search'

export type SearchProps = Partial<
  Pick<
    AutocompleteProps<SearchItem, false, false, true>,
    'renderInput' | 'renderOption'
  >
>

export const Search = withSearchProvider((props: SearchProps) => {
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const { search, results, loading, error } = useSearch()

  const showMessage = loading || !!error || results?.length === 0

  const message = loading
    ? 'loading'
    : error
    ? 'something went wrong'
    : results?.length === 0
    ? 'no results'
    : ''

  return (
    <Autocomplete
      fullWidth
      inputMode="search"
      freeSolo
      disablePortal
      clearOnEscape
      loading={showMessage}
      loadingText={message}
      options={results ?? []}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.symbol
      }
      inputValue={query}
      onInputChange={(_, query) => {
        setQuery(query)
        search(query)
      }}
      value={null}
      onChange={(_, item) => {
        if (!item || typeof item === 'string') {
          return
        }
        setQuery('')
        navigate(`/stocks/${item.symbol}`)
      }}
      filterOptions={(_) => _}
      renderInput={props.renderInput ?? DefaultSearchInput}
      renderOption={props.renderOption ?? DefaultOption}
    />
  )
})

const DefaultSearchInput = (params: AutocompleteRenderInputParams) => (
  <TextField
    variant="outlined"
    placeholder="Search for stocks and funds"
    sx={{ backgroundColor: '#fff' }}
    {...params}
  />
)

const DefaultOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: { symbol: string; name?: string }
) => {
  return (
    <ListItem
      key={`${option.symbol}-${option.name}`}
      disablePadding
      disableGutters
      {...omit(props, ['className'])}
    >
      <ListItemButton dense>
        <ListItemText
          disableTypography
          primary={<Typography variant="body1">{option.symbol}</Typography>}
          secondary={
            <Typography variant="body2" noWrap>
              {option.name}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}
