import { isString } from 'lodash'

const LEGACY_ZAKAT_API_ENDPOINT = import.meta.env.VITE_LEGACY_ZAKAT_API_ENDPOINT

export interface SearchableEntry {
  symbol: string
  name: string
  region: string
  assetType?: string
  issueType?: string
}

export const isSearchableEntry = (object: any): object is SearchableEntry => {
  return (
    object &&
    typeof object === 'object' &&
    object.symbol &&
    isString(object.symbol) &&
    object.name &&
    isString(object.name) &&
    object.region &&
    isString(object.region)
  )
}

interface MutualFundResponse {
  name: string
  price: number
}
export const fetchMutualFundData = async (
  symbol: string,
): Promise<MutualFundResponse> => {
  const queryString = new URLSearchParams({
    symbol,
  })
  const response = await fetch(
    `${LEGACY_ZAKAT_API_ENDPOINT}/mutual-fund?${queryString}`,
    {
      method: 'POST',
    },
  )
  const data = await response.json()
  return data[0]
}
