import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Collapse from '@mui/material/Collapse'
import UploadFileOutlined from '@mui/icons-material/UploadFileOutlined'
import { usePapaParse } from 'react-papaparse'
import type { ParseResult } from 'papaparse'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import csvExample from '../../assets/zakat-csv.png'
import { ZakatHolding } from '@/zakat/types'
import { zakatUniverse } from '@/data/API/universe'
import { Link } from '@mui/material'
import { useSegment } from '@/events/segment'

const synonyms = {
  symbol: ['ticker', 'symbol'],
  shares: [
    'shares',
    'shares_held',
    'shares held',
    'units',
    'quantity',
    'number of shares',
    'num of shares',
  ],
} as Record<string, string[]>

const matchHeaders = (
  headers: string[],
): {
  [key: string]: string
} => {
  const matches = headers.map((header) => {
    const match = Object.keys(synonyms).find((key) => {
      return synonyms[key].includes(header.trim().toLowerCase())
    })
    return match ? { [match]: header } : null
  })
  const cleanMatches = matches.filter((match) => match !== null) as {
    [key: string]: string
  }[]
  // @ts-ignore next-line
  return cleanMatches.length > 0 ? Object.assign(...cleanMatches) : {}
}

const mutualFundUniverse = new Set(
  zakatUniverse
    .filter((entry) => entry.assetType === 'mutual fund')
    .map((entry) => entry.symbol),
)

const shapeCSVData = (
  result: ParseResult<Record<string, unknown>>,
  onSuccess: (holdings: Partial<ZakatHolding>[]) => void,
  onFailure: (foundHeaders: string[]) => void,
) => {
  if (result.meta.fields && result.meta.fields.length > 0) {
    const matchedHeaders = matchHeaders(result.meta.fields)
    if (!matchedHeaders.symbol || !matchedHeaders.shares) {
      onFailure(result.meta.fields)
    } else {
      const resterizedData = result.data.map((row) => {
        const resterizedRow: Partial<ZakatHolding> = {
          symbol:
            typeof row[matchedHeaders.symbol] === 'string'
              ? (row[matchedHeaders.symbol] as string).toUpperCase()
              : undefined,
          shares:
            typeof row[matchedHeaders.shares] === 'string'
              ? parseFloat(row[matchedHeaders.shares] as string)
              : undefined,
          name:
            typeof row[matchedHeaders.name] === 'string'
              ? (row[matchedHeaders.name] as string)
              : undefined,
        }
        return resterizedRow
      })

      onSuccess(resterizedData)
    }
  } else {
    onFailure([])
  }
}

export const CsvImport: React.FC<{
  onImport: (holdings: Partial<ZakatHolding>[]) => void
}> = ({ onImport }) => {
  const [foundHoldings, setFoundHoldings] = useState<{
    recognized: Partial<ZakatHolding>[]
    unRecognized: Partial<ZakatHolding>[]
  }>({
    recognized: [],
    unRecognized: [],
  })
  const [importAllType, setImportAllType] = useState<'Passive' | 'Active'>(
    'Passive',
  )
  const [foundHeaders, setFoundHeaders] = useState<{
    requireManualMatch: boolean
    onGoingMatching: boolean
    matchingResults: {
      symbol?: string
      shares?: string
    }
    headers: string[]
  }>({
    requireManualMatch: false,
    onGoingMatching: false,
    matchingResults: {},
    headers: [],
  })
  const fileUploadInput = React.createRef<HTMLInputElement>()

  const { analytics } = useSegment()

  const theme = useTheme()
  const smAndLess = useMediaQuery(theme.breakpoints.down('sm'))

  const { readString } = usePapaParse()

  const removeFoundHolding = (symbol: string) => {
    setFoundHoldings((holdings) => {
      return {
        recognized: holdings.recognized.filter(
          (holding) => holding.symbol !== symbol,
        ),
        unRecognized: holdings.unRecognized,
      }
    })
  }

  const handleAddFoundHoldings = (
    newHoldings: Partial<ZakatHolding & { assetType: string }>[],
  ) => {
    newHoldings.forEach((holding) => {
      if (holding.symbol && mutualFundUniverse.has(holding.symbol)) {
        holding.assetType = 'mutual fund'
      }
    })
    setFoundHoldings((foundHoldings) => ({
      recognized: [...foundHoldings.recognized, ...newHoldings],
      unRecognized: [],
    }))
  }

  const handleImportCSVHolding = () => {
    const newHoldings = foundHoldings.recognized.map((holding) => {
      return {
        ...holding,
        type: importAllType,
        dataFetch: {
          missingFields: [],
          finished: false,
          error: '',
        },
      }
    })
    onImport(newHoldings)
  }

  const handleUploadAnotherFile = () => {
    if (fileUploadInput.current) {
      fileUploadInput.current.value = ''
    }
    setFoundHeaders((headers) => ({
      ...headers,
      requireManualMatch: false,
    }))
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const csv = e.target!.result
        if (typeof csv === 'string') {
          readString<Record<string, unknown>>(csv, {
            header: true,
            complete: (result) => {
              shapeCSVData(result, handleAddFoundHoldings, (foundHeaders) => {
                setFoundHeaders((headers) => ({
                  ...headers,
                  requireManualMatch: true,
                  headers: foundHeaders,
                }))
              })
            },
            skipEmptyLines: true,
            worker: true,
          })
        } else {
          console.error('error reading file')
        }
      }
      for (const file of e.target.files) {
        reader.readAsText(file)
      }
    }
  }

  return (
    <div>
      <Collapse in={foundHoldings.recognized.length > 0}>
        <Typography variant="subtitle2">
          {foundHoldings.recognized.length} holdings successfully matched
        </Typography>

        <Stack direction="row" sx={{ mt: 1, flexWrap: 'wrap' }}>
          {foundHoldings.recognized.map((holding) => {
            if (holding.symbol && typeof holding.symbol === 'string') {
              return (
                <Chip
                  key={`${holding.symbol}-${holding.shares}-${holding.type}`}
                  label={
                    <Typography variant="subtitle2">
                      <b>{holding.shares}</b> {holding.symbol}
                    </Typography>
                  }
                  variant="outlined"
                  sx={{ mr: 1, mb: 1 }}
                  onDelete={() => removeFoundHolding(holding.symbol as string)}
                />
              )
            } else {
              return null
            }
          })}
        </Stack>

        <Stack direction="row" mt={2} alignItems="center">
          <Typography variant="subtitle1">Import all as</Typography>
          <TextField
            value={importAllType}
            onChange={(event) => setImportAllType(event.target.value as any)}
            select
            variant="outlined"
            size="small"
            sx={{ mx: 1 }}
          >
            <MenuItem value="Passive">Passive </MenuItem>
            <MenuItem value="Active">Active </MenuItem>
          </TextField>
          <Typography variant="subtitle1">Investments</Typography>
        </Stack>
        <Stack
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent={'space-between'}
        >
          <input type="file" id="fileUpload2" hidden onChange={onFileChange} />
          <Button
            fullWidth={smAndLess}
            variant="outlined"
            component={'label'}
            htmlFor="fileUpload2"
            color="primary"
            sx={{ mt: 2 }}
          >
            Add another file
          </Button>
          <Button
            fullWidth={smAndLess}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleImportCSVHolding}
          >
            import {foundHoldings.recognized.length} Holdings
          </Button>
        </Stack>
      </Collapse>
      <Collapse
        in={
          foundHoldings.recognized.length === 0 &&
          foundHoldings.unRecognized.length === 0
        }
      >
        <Card
          variant="outlined"
          sx={{
            p: 2,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Collapse
            in={
              !foundHeaders.requireManualMatch ||
              foundHoldings.recognized.length < 1
            }
          >
            <Stepper orientation="vertical">
              <Step active key="1">
                <StepLabel>
                  Your file should list each holding's ticker symbol and
                  quantity:
                </StepLabel>
                <StepContent>
                  <img src={csvExample} style={{ width: '100%' }} />
                </StepContent>
              </Step>
              <Step active key="2">
                <StepLabel>
                  For stocks listed on exchanges outside the US, you will need
                  to append an exchange code to the end of the symbol. For
                  example, append “-CT” for tickers listed on the Toronto Stock
                  Exchange (SHOP-CT) or "-LN" for tickers listed on the London
                  Stock Exchange (GSK-LN).
                  <br />
                  <br />
                  See the full list of exchange codes{' '}
                  <Link href="https://docs.google.com/spreadsheets/d/1mSHM-4JyckIanfcuiguGBRfL9LX-mJnaSlW6PxQWEzg/edit#gid=0">
                    here
                  </Link>
                  .
                </StepLabel>
              </Step>
            </Stepper>
            <input
              type="file"
              id="fileUpload"
              ref={fileUploadInput}
              hidden
              onChange={onFileChange}
            />
            <Button
              fullWidth
              htmlFor="fileUpload"
              variant="contained"
              color="primary"
              startIcon={<UploadFileOutlined />}
              component={'label'}
              disabled={foundHeaders.requireManualMatch}
              sx={{ mt: 2 }}
            >
              Upload CSV
            </Button>
          </Collapse>
          <div style={{ width: '100%' }}>
            <Collapse in={foundHeaders.requireManualMatch}>
              <Alert
                sx={{ textAlign: 'start' }}
                severity="error"
                variant="outlined"
              >
                <AlertTitle>Failed to match CSV headers</AlertTitle>
                Found the following headers instead :
                <Stack
                  sx={{
                    mt: 1,
                    mb: 2,
                    p: 1,
                    backgroundColor: '#fafafa',
                    borderRadius: 1,
                  }}
                  direction="row"
                  flexWrap="wrap"
                >
                  {foundHeaders.headers.map((header) => (
                    <Typography
                      sx={{ mr: 1, fontWeight: '600' }}
                      variant="subtitle1"
                      key={header}
                    >
                      {header}
                    </Typography>
                  ))}
                </Stack>
                <Stack direction="row" flexWrap="wrap" sx={{ mt: 1 }}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={handleUploadAnotherFile}
                    disableElevation
                  >
                    Upload another file
                  </Button>
                </Stack>
              </Alert>
            </Collapse>
          </div>
        </Card>
      </Collapse>
    </div>
  )
}
