import React, { useRef, useState } from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Calculator } from './Calculator'
import { Header } from '@/minaret/Header'
import { Button } from '@mui/material'
import { MethodolgyModal } from '../components/Modals/MethodologyModal'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'

export const Landing = () => {
  const [methodologyModalOpen, setMethodologyModalOpen] = useState(false)
  const calculatorContent = useRef<HTMLDivElement>(null)

  return (
    <>
      <Header />
      <Container
        maxWidth="lg"
        sx={{
          my: 10,
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Grid
          container
          spacing={2}
          flexDirection={{ xs: 'column-reverse', md: 'row' }}
        >
          <Grid
            zeroMinWidth
            item
            justifyContent="center"
            alignItems="center"
            md={12}
            xs={12}
          >
            <Typography
              variant={'h1'}
              sx={{ typography: { xs: 'h3', sm: 'h2', md: 'h2' } }}
            >
              <b>Calculate Zakat on Stocks and Funds</b>
            </Typography>
            <Typography
              variant="h2"
              sx={{
                typography: { xs: 'h6', sm: 'h6', md: 'h6' },
                fontWeight: 600,
              }}
            >
              <Grid
                container
                alignSelf="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  paddingY={2}
                  paddingX={{ xs: 0, md: 20 }}
                >
                  <Typography variant="subtitle1">
                    A free tool to help you instantly and accurately calculate
                    how much zakat you owe on your investments.
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <Button
              variant="text"
              size="small"
              sx={{ mt: 4 }}
              endIcon={<HelpRoundedIcon />}
              onClick={() => setMethodologyModalOpen(true)}
            >
              Methodology
            </Button>
          </Grid>
        </Grid>
      </Container>
      <div ref={calculatorContent}>
        <Calculator />
      </div>
      <MethodolgyModal
        open={methodologyModalOpen}
        onClose={() => setMethodologyModalOpen(false)}
      />
    </>
  )
}
