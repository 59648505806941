import { useCallback, useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { debounce } from 'lodash'

import { getSearchableUniverse } from '@/data/API/universe'

type Entry = Awaited<ReturnType<typeof getSearchableUniverse>>[number]

let fuse: Fuse<Entry>
const getFuse = async () => {
  fuse ||= new Fuse(await getSearchableUniverse(), {
    keys: ['symbol', 'name'],
  })
  return fuse
}

export const useLocalSearch = () => {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [{ results }, setState] = useState<{
    query: string
    results: Entry[] | undefined
  }>(() => ({ query: '', results: undefined }))

  useEffect(() => {
    getFuse()
  }, [])

  const debouncedSearch = useCallback(
    debounce(async (query: string) => {
      setState({ query, results: undefined })
      const fuse = await getFuse()
      const results = fuse.search(query, { limit: 8 }).map((_) => _.item)
      setState((state) => (query === state.query ? { query, results } : state))
    }, 150),
    []
  )

  const search = useCallback((query: string) => {
    setLoading(true)
    debouncedSearch(query)
      ?.catch((err) => {
        if (err instanceof Error) {
          setError(err)
        }
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { search, results, loading, error }
}
