import React from 'react'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { useNavigate } from 'react-router-dom'

export const EmailVerification = () => {
  const navigate = useNavigate()

  const handleNavigateToSignIn = () => {
    navigate('/auth/log-in')
  }

  return (
    <Container
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          height="100vh"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          xs={5}
        >
          <Card
            sx={{
              p: { xs: 2, md: 4 },
              my: 2,
              minWidth: 320,
              maxWidth: 500,
              alignSelf: 'center',
            }}
            elevation={0}
          >
            <Typography variant="h4">Email verification</Typography>
            <Typography variant="subtitle1" marginBottom={4}>
              We have sent you a verification link to your email in order to
              verify your account.
            </Typography>
            <Button onClick={handleNavigateToSignIn} fullWidth>
              Already verified your email?
            </Button>
            <Paper
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                paddingY: 1,
                paddingX: 2,
                marginTop: 1,
              })}
              elevation={0}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Notice
              </Typography>
              <Typography variant="body1">
                It may take a minute for the confirmation email to reach your
                inbox. If you are unable to find it, please check your spam/junk
                folder.
              </Typography>
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
