import * as Yup from 'yup'

export const resetCodeRequestSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
})

export const resetPasswordSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  newPassword: Yup.string()
    .min(8, '8 characters minimum')
    .matches(/[A-Z]/, 'Password must have uppercase letters')
    .matches(/[a-z]/, 'Password must have lowercase letters')
    .matches(/\d/, 'Password must include numbers')
    .required('Password is required'),
  newPasswordConfirmation: Yup.string()
    .min(8, '8 characters minimum')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Password confirmation is required'),
})

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, '8 characters minimum')
    .matches(/[A-Z]/, 'Password must have uppercase letters')
    .matches(/[a-z]/, 'Password must have lowercase letters')
    .matches(/\d/, 'Password must include numbers')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, '8 characters minimum')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Password confirmation is required'),
})
