import { createTheme } from '@mui/material'

/**
 * This module declaration is for extending the properties of MUI themes. By
 * default, there are a limited set of theme variables that are recognized. If
 * we wanted to add new ones like "danger", for example, we need to define them
 * here so they can be used without type checking errors. For more details, see:
 * https://mui.com/customization/theming/#custom-variables
 */
/*
declare module '@mui/material/styles' {
  // Allow access of augmented properties when using the theme
  interface Theme {
    status: {
      danger: string
    }
  }
  // Allow configuration of augmented properties in `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}
*/

// TODO: Make this generated from design tokens vended from the design system
export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#00A55F',
    },
    secondary: {
      main: '#e53e3e',
    },
    error: {
      main: '#f44336',
    },
    divider: 'rgba(51,51,51,0.04)',
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Eina", "Avenir next", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    h1: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h2: {
      fontSize: 40,
      lineHeight: 1.33,
      fontWeight: 400,
    },
    h3: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h4: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.33,
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    button: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
    overline: {
      fontSize: 10,
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '5px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + $track': {
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: '1px solid #bdbdbd',
          backgroundColor: '#fafafa',
          opacity: 1,
          transition:
            'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
      styleOverrides: {
        root: {
          paddingTop: 12,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          overflow: 'visible',
        },
      },
    },
  },
})
