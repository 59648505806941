import { useCallback } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import ZoyaZakatLogo from './assets/zoya-logo.svg'

import { useSegment } from '@/events/segment'
import { useLocation } from 'react-router-dom'
import { useGate } from 'statsig-react'

export const Header = () => {
  const { analytics } = useSegment()
  const location = useLocation()

  const onDownloadZoyaClick = useCallback(() => {
    if (analytics) {
      analytics.track('Download Zoya', {
        current_path: location.pathname,
      })
    }
  }, [analytics, location.pathname])
  const { value: hasInvestAccess } = useGate('invest')

  return (
    <AppBar position="static" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <a href="https://zoya.finance">
              <img
                src={ZoyaZakatLogo}
                alt="Zoya Zakat"
                height={26}
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
              />
            </a>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: { xs: 'none', sm: 'inline' } }}
            >
              {hasInvestAccess && (
                <Link underline="none" href="./invest">
                  <Button
                    variant="text"
                    sx={{
                      typography: 'subtitle1',
                      textTransform: 'none',
                      color: '#333',
                    }}
                  >
                    Invest
                  </Button>
                </Link>
              )}
              <Link underline="none" href="https://community.zoya.finance/">
                <Button
                  variant="text"
                  sx={{
                    typography: 'subtitle1',
                    textTransform: 'none',
                    color: '#333',
                  }}
                >
                  Community
                </Button>
              </Link>
              <Link underline="none" href="https://blog.zoya.finance/">
                <Button
                  variant="text"
                  sx={{
                    typography: 'subtitle1',
                    textTransform: 'none',
                    color: '#333',
                  }}
                >
                  Blog
                </Button>
              </Link>
              <Link underline="none" href="https://zoya.finance/about">
                <Button
                  variant="text"
                  sx={{
                    typography: 'subtitle1',
                    textTransform: 'none',
                    color: '#333',
                  }}
                >
                  About
                </Button>
              </Link>
            </Stack>
            <Link
              underline="none"
              target="_blank"
              rel="noopener"
              href="http://onelink.to/d23j9g"
            >
              <Button
                variant="contained"
                disableElevation
                sx={{
                  color: 'white',
                  backgroundColor: 'black',
                  textTransform: 'none',
                  ':hover': { backgroundColor: '#333' },
                }}
                onClick={onDownloadZoyaClick}
              >
                Download Zoya
              </Button>
            </Link>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
