import { z } from 'zod'
import { parseIexSymbol } from './IEX/parse-iex-symbol'

/**
 * Safe conversion of a string to a number. Heavily needed because Twelvedata
 * returns strings for most numeric values.
 */
export const nullishStrToNum = (
  str: string | null | undefined,
): number | null => {
  if (!str) {
    return null
  }
  const num = parseFloat(str)
  return isNaN(num) ? null : num
}

/**
 * Null tolerant representation of the Twelvedata quote schema
 * https://twelvedata.com/docs#quote
 */
export const twelvedataQuoteSchema = z.object({
  symbol: z.string(),
  name: z.string().nullish(),
  exchange: z.string().nullish(),
  mic_code: z.string().nullish(),
  currency: z.string().nullish(),
  datetime: z.string().nullish(),
  timestamp: z.number().nullish(),
  open: z.string().nullish(),
  high: z.string().nullish(),
  low: z.string().nullish(),
  close: z.string(),
  volume: z.string().nullish(),
  previous_close: z.string().nullish(),
  change: z.string().nullish(),
  percent_change: z.string().nullish(),
  average_volume: z.string().nullish(),
  is_market_open: z.boolean().nullish(),
  fifty_two_week: z
    .object({
      low: z.string().nullish(),
      high: z.string().nullish(),
      low_change: z.string().nullish(),
      high_change: z.string().nullish(),
      low_change_percent: z.string().nullish(),
      high_change_percent: z.string().nullish(),
      range: z.string().nullish(),
    })
    .nullish(),
})

/**
 * Subset of IEX quote interface that is compatible with the Twelvedata quote
 */
export const pseudoIexQuoteSchema = z.object({
  symbol: z.string(),
  companyName: z.string().nullish(),
  primaryExchange: z.string().nullish(),
  currency: z.string().nullish(),
  latestPrice: z.number(),
  change: z.number().nullish(),
  changePercent: z.number().nullish(),
  open: z.number().nullish(),
  close: z.number().nullish(),
  previousClose: z.number().nullish(),
  high: z.number().nullish(),
  low: z.number().nullish(),
  week52High: z.number().nullish(),
  week52Low: z.number().nullish(),
  isUSMarketOpen: z.boolean().nullish(),
  volume: z.number().nullish(),
  avgTotalVolume: z.number().nullish(),
  latestUpdate: z.number().nullish(),
})
export type PseudoIexQuote = z.infer<typeof pseudoIexQuoteSchema>

const twelvedataBatchQuotesSchema = z.record(z.string(), twelvedataQuoteSchema)

const pseudoIexBatchQuotesSchema = z.record(
  z.string(),
  z.object({
    quote: pseudoIexQuoteSchema,
  }),
)
type PseudoIexBatchQuotes = z.infer<typeof pseudoIexBatchQuotesSchema>

/**
 * Schema that parses a Twelvedata quote into a pseudo IEX quote
 */
export const twelvedataQuoteAsPseudoIexQuote = twelvedataQuoteSchema.transform(
  (_) => {
    const changePercent = nullishStrToNum(_.percent_change)
    const pseudoIexQuote: PseudoIexQuote = {
      symbol: _.symbol,
      companyName: _.name,
      primaryExchange: _.exchange,
      currency: _.currency,
      latestPrice: parseFloat(_.close),
      change: nullishStrToNum(_.change),
      changePercent: changePercent && changePercent / 100,
      open: nullishStrToNum(_.open),
      close: nullishStrToNum(_.close),
      previousClose: nullishStrToNum(_.previous_close),
      high: nullishStrToNum(_.high),
      low: nullishStrToNum(_.low),
      week52High: nullishStrToNum(_.fifty_two_week?.high),
      week52Low: nullishStrToNum(_.fifty_two_week?.low),
      isUSMarketOpen: _.is_market_open,
      volume: nullishStrToNum(_.volume),
      avgTotalVolume: nullishStrToNum(_.average_volume),
      latestUpdate: _.timestamp && _.timestamp * 1000,
    }
    return pseudoIexQuote
  },
)

export const getQuote = async (iexSymbol: string): Promise<PseudoIexQuote> => {
  const symbol = parseIexSymbol(iexSymbol).twelvedataSymbol
  const res = await fetch(
    `${import.meta.env.VITE_QUOTE_ENDPOINT}/quote?symbol=${symbol}`,
  ).then((_) => _.json())
  return twelvedataQuoteAsPseudoIexQuote.parse(res)
}

export const getBatchQuotes = async (
  iexSymbols: string[],
): Promise<PseudoIexBatchQuotes> => {
  const symbols = iexSymbols.map((_) => parseIexSymbol(_).twelvedataSymbol)
  const res = await fetch(
    `${import.meta.env.VITE_QUOTE_ENDPOINT}/quote?symbol=${symbols.join(',')}`,
  ).then((_) => _.json())
  const quotes = twelvedataBatchQuotesSchema.parse(res)
  return Object.fromEntries(
    Object.entries(quotes).map(([symbol, quote]) => [
      symbol,
      { quote: twelvedataQuoteAsPseudoIexQuote.parse(quote) },
    ]),
  )
}
