import type { StatsigOptions, StatsigUser } from 'statsig-js'

/**
 * Base configuration of the user object. The actual userID should be set
 * elsewhere and extend this object.
 */
export const statsigUserBaseConfig: StatsigUser = {
  custom: {
    /**
     * The buildId value gets set to our short commit hash. This makes it
     * possible to associate metrics and events with a specific commit.
     */
    buildId: __COMMIT_HASH__,
  },
}

/**
 * Configuration of the Statsig SDK options.
 */
export const statsigOptions: StatsigOptions = {
  /**
   * Setting environment here allows us to filter events by stage in the Statsig
   * console.
   */
  environment: {
    tier: import.meta.env.DEV
      ? 'development'
      : import.meta.env.MODE === 'production'
      ? 'production'
      : 'staging',
  },
}
