/**
 * IEX and Twelvedata use different raw symbols for Indian stocks on the Bombay
 * Stock Exchange. This file provides a hardcoded mapping between the two. Any
 * symbols outside this mapping will fail to resolve to a valid Twelvedata
 * symbol.
 *
 * For now, that is a limitation we are accepting until we cut over to using
 * zSymbols as our canonical symbol format.
 */
export const bseMapping: Record<string, string | undefined> = {
  '533022': '20MICRONS',
  '542772': '360ONE',
  '532067': 'NGIND',
  '544190': 'VLEGOV',
  '532628': '3IINFOTECH',
  '523395': '3MINDIA',
  '516092': '3PLAND',
  '531925': 'SHAQUAK',
  '540776': '5PAISA',
  '526881': '63MOONS',
  '540615': 'SUNRETAIL',
  '540874': '7SEASL',
  '542012': 'AAL',
  '538351': 'SVJ',
  '530499': 'AKCAPIT',
  '539300': 'AKSPINTEX',
  '533292': 'A2ZINFRA',
  '543319': 'AAPLUSTRAD',
  '543671': 'AAATECH',
  '544176': 'STARHFL',
  '530027': 'AADIIND',
  '539096': 'AANANDALAK',
  '538812': 'AANCHALISP',
  '542377': 'AARSHYAM',
  '532024': 'KAARYAFSL',
  '524412': 'AREYDRG',
  '539562': 'AARNAV',
  '542580': 'AARTECH',
  '524348': 'AARTIDRUGS',
  '524208': 'AARTIIND',
  '543748': 'AARTIPHARM',
  '543210': 'AARTISURF',
  '700135': 'AARTISURF',
  '514274': 'AARVEEDEN',
  '543346': 'AASHKA',
  '511764': 'AASTAFIN',
  '541988': 'AAVAS',
  '540718': 'AAYUSH',
  '539528': 'ZYDUSWELL',
  '523204': 'ABAN',
  '512165': 'ABANSENT',
  '543712': 'AHL',
  '531658': 'NGIND',
  '500002': 'ABB',
  '500488': 'ABBOTINDIA',
  '513119': 'ABCGAS',
  '520123': 'ABCINDQ',
  '539560': 'ZMANSOON',
  '532057': 'ABHICAP',
  '538952': 'ALFL',
  '538935': 'ABHIFIN',
  '539544': 'ABHIINFRA',
  '511756': 'ABIRAFN',
  '531161': 'ABMKNO',
  '500410': 'ACC',
  '531533': 'ACCEDERE',
  '517494': 'ACCEL',
  '543938': 'ACCELERATE',
  '532268': 'ACCELYA',
  '539661': 'ACEMEN',
  '531525': 'ACESOFT',
  '543499': 'ACHYUT',
  '517356': 'ACIIN',
  '530043': 'ACKNIT',
  '539391': 'ACME',
  '513149': 'ACROW',
  '532762': 'ACE',
  '541144': 'ACTIVE',
  '511359': 'ADMANUM',
  '539254': 'ADANIENSOL',
  '512599': 'ADANIENT',
  '541450': 'ADANIGREEN',
  '532921': 'ADANIPORTS',
  '533096': 'ADANIPOWER',
  '542066': 'ATGL',
  '543458': 'AWL',
  '538563': 'MERCANTILE',
  '526711': 'ADARSHPL',
  '523411': 'ADCINDIA',
  '539506': 'ADCON',
  '541865': 'ASRL',
  '507852': 'ADDIND',
  '543309': 'ADESHWAR',
  '519183': 'ADFFOODS',
  '531286': 'TIGLOB',
  '539189': 'ADHBHUTIN',
  '780018': 'KDML',
  '532056': 'ADIEXRE',
  '514113': 'ADINATH',
  '540691': 'ABCAPITAL',
  '535755': 'ABFRL',
  '532974': 'BIRLAMONEY',
  '543374': 'ABSLBANETF',
  '540146': 'ACML',
  '513513': 'ADITYA',
  '521141': 'ADITYASP',
  '540205': 'AVL',
  '543269': 'BGIL',
  '524604': 'ADLINE',
  '530431': 'ADORFO',
  '523120': 'ADORMUL',
  '517041': 'ADORWELD',
  '532172': 'ADROITINFO',
  '890196': 'ADROITINFO',
  '523031': 'MEDINOV',
  '544185': 'SBECSYS',
  '543230': 'ADVAIT',
  '521048': 'ADVLIFE',
  '534612': 'AMTL',
  '506947': 'ADVPETR-B',
  '540025': 'ADVENZYMES',
  '523269': 'ADVANIHOTR',
  '539773': 'ADVIKCA',
  '500003': 'AEGISLOG',
  '544213': 'PCL',
  '524594': 'LTTS',
  '543972': 'AEROFLEX',
  '534733': 'AERPACE',
  '543534': 'AETHER',
  '544224': 'GANHOLD',
  '542752': 'AFFLE',
  '541402': 'AFFORDABLE',
  '543377': 'SVJ',
  '530765': 'AGARWAL',
  '531921': 'AGARIND',
  '500187': 'AGI',
  '539042': 'AGIIL',
  '516020': 'AGIOPAPER',
  '537292': 'AGRITECH',
  '500215': 'ATFL',
  '543451': 'AGSTRA',
  '543941': 'AHASOLAR',
  '532811': 'AHLUCONT',
  '522273': 'AHMDSTE',
  '532806': 'AICHAMP',
  '532683': 'AIAENG',
  '544072': 'APOLLOPIPES',
  '524288': 'AIMCOPEST',
  '531439': 'KELLTONTEC',
  '543811': 'AIRAN',
  '532331': 'AJANTPHARM',
  '519216': 'AJANTSOY',
  '511692': 'AJCON',
  '530713': 'AJEL',
  '513349': 'AJMERA',
  '526628': 'AJWAFUN',
  '530621': 'AAIL',
  '542020': 'AKI',
  '544200': 'DHINDIA',
  '532351': 'AKSHOPTFBR',
  '541303': 'AKSHAR',
  '524598': 'AKSCHEM',
  '544222': 'WELCURE',
  '500710': 'AKZOINDIA',
  '535916': 'ALSL',
  '539115': 'THOMASCOTT',
  '531082': 'ALANKIT',
  '524075': 'ALBERTDA',
  '506235': 'ALEMBICLTD',
  '533573': 'APLLTD',
  '511463': 'ALEXANDER',
  '530973': 'ALFAICA',
  '517546': 'ALFATRAN',
  '531156': 'ALFAVIO',
  '505216': 'ALFREDHE',
  '505725': 'AQFINTECH',
  '531147': 'ALICON',
  '533029': 'ALKALI',
  '539523': 'ALKEM',
  '543453': 'ALKOSIGN',
  '506767': 'ALKYLAMINE',
  '532345': 'ACLGATI',
  '532749': 'ALLCARGO',
  '543954': 'ATL',
  '534064': 'AIML',
  '544203': 'GUJCMDS',
  '532875': 'ADSL',
  '532633': 'ALLSEC',
  '531400': 'ALMONDZ',
  '506120': 'ALNATRD',
  '521070': 'ALOKTEXT',
  '532878': 'ALPA',
  '526397': 'ALPHAGEO',
  '543937': 'ALPHAIND',
  '542770': 'ALPHALOGIC',
  '526519': 'ALPINEHOU',
  '530715': 'ALPSINDUS',
  '539277': 'ALSTONE',
  '524634': 'ALUFLUOR',
  '506597': 'AMAL',
  '501622': 'AMALGAM',
  '543804': 'AMANAYA',
  '500008': 'ARE&M',
  '521097': 'AMARJOTHI',
  '538465': 'AMARSEC',
  '539196': 'AEL',
  '500009': 'AMBALALSA',
  '519471': 'AMBARPIL',
  '542524': 'AIHL',
  '540902': 'AMBER',
  '532335': 'AMBICAAGAR',
  '531978': 'AMBIKCO',
  '526439': 'AMBIT',
  '543678': 'AMBOAGRI',
  '500425': 'AMBUJACEM',
  '530133': 'AMCOIND',
  '532828': 'AMDIND',
  '531681': 'AMERISE',
  '513117': 'AMFORG',
  '543349': 'AMIORG',
  '544037': 'AMIC',
  '541771': 'AMINTAN',
  '506248': 'AMNPLST',
  '531300': 'AMITINT',
  '500343': 'AMJLAND',
  '544169': 'SAGRSOY-B',
  '535719': 'NGIND',
  '536737': 'AMRAPLIN',
  '539265': 'AMRAPLIN',
  '526241': 'AMRAPLIN',
  '531991': 'AMRAAGRI',
  '590006': 'AMRUTANJAN',
  '540066': 'PEARLPOLY',
  '501630': 'ANANDPROJ',
  '543415': 'ANANDRATHI',
  '542721': 'ARL',
  '515055': 'ANANTRAJ',
  '532141': 'ACL',
  '502330': 'ANDHRAPAP',
  '500012': 'ANDHRAPET',
  '590062': 'ANDHRSUGAR',
  '526173': 'ANDREWYU',
  '540694': 'ANG',
  '541006': 'ANGEL',
  '543235': 'ANGELONE',
  '519383': 'ANIKINDS',
  '531878': 'ANJANIFIN',
  '511153': 'ANJANIFOODS',
  '518091': 'APCL',
  '531223': 'ANJANI',
  '531673': 'ANKIN',
  '532870': 'ANKITMETAL',
  '542437': 'ANMOL',
  '530799': 'ANNAINFRA',
  '531406': 'ANSINDUS',
  '523007': 'ANSALBU',
  '507828': 'ANSALHSG',
  '500013': 'ANSALAPI',
  '501270': 'NGIND',
  '543254': 'AWHCL',
  '506260': 'ANUHPHR',
  '542460': 'ANUP',
  '530109': 'ANUPAM',
  '543275': 'ANURAS',
  '542865': 'ANUROOP',
  '543655': 'KPEL',
  '532259': 'APARINDS',
  '523694': 'APCOTEXIND',
  '544111': 'UPHOT',
  '541133': 'CIFL',
  '540692': 'APEX',
  '506166': 'APIS',
  '533758': 'APLAPOLLO',
  '517096': 'APLAB',
  '523537': 'APMIN',
  '512437': 'APOLLOFI',
  '508869': 'APOLLOHOSP',
  '503639': 'GUJAPOLLO',
  '540879': 'APOLLO',
  '531761': 'APOLLOPIPES',
  '500877': 'APOLLOTYRE',
  '539545': 'APOORVA',
  '506979': 'APTPACK',
  '532475': 'APTECHT',
  '543335': 'APTUS',
  '512344': 'ARAVALIS',
  '540135': 'ARCFIN',
  '520121': 'ARCEEIN',
  '543657': 'ACI',
  '543231': 'ADL',
  '532994': 'ARCHIDPLY',
  '532212': 'ARCHIES',
  '524640': 'ARCHITORG',
  '543993': 'ARCL',
  '526851': 'AREXMIS',
  '539151': 'ARFIN',
  '532935': 'ARIES',
  '530267': 'RICHUNV',
  '511605': 'ARIHCAPM',
  '531381': 'ARIHANT',
  '506194': 'ASL',
  '531017': 'ARISE',
  '531677': 'ARISINT',
  '531179': 'ARMANFIN',
  '538556': 'ARMAN',
  '537069': 'ARNOLD',
  '513729': 'AROGRANITE',
  '516064': 'ARROWGREEN',
  '544025': 'ARROWHEAD',
  '506074': 'ARSHIYA',
  '533163': 'ANANDPROJ',
  '531297': 'ARTEFACT',
  '542670': 'AEPL',
  '542919': 'ARTEMISMED',
  '526443': 'BEL',
  '522134': 'GGENG',
  '500016': 'ARUNAHTEL',
  '526935': 'ARUNIS',
  '530881': 'ABVL',
  '542484': 'ARVINDFASN',
  '500101': 'ARVIND',
  '539301': 'ARVSMART',
  '538716': 'ARYACAPM',
  '530245': 'ARYAMAN',
  '542176': 'ARYAN',
  '515030': 'ASAHIINDIA',
  '532853': 'ASAHISONG',
  '543943': 'ASARFI',
  '543443': 'MTEDUCARE',
  '527001': 'ASHAPURMIN',
  '542579': 'AGOL',
  '519174': 'ASHAI',
  '523716': 'ASHIANA',
  '513401': 'ASHIS',
  '543766': 'ASHIKA',
  '514286': 'ASHIMASYN',
  '512247': 'ASHCAP',
  '526847': 'ASHSI',
  '530429': 'ASHISHPO',
  '541702': 'ASHNI',
  '507872': 'ASHNOOR',
  '500477': 'ASHOKLEY',
  '533271': 'ASHOKA',
  '540923': 'ASHOKAMET',
  '526983': 'ASHOKRE',
  '526187': 'ASHRAM',
  '531341': 'NGIND',
  '502015': 'ASIIL',
  '538777': 'ASIAPAK',
  '530899': 'ASIAPAK',
  '530355': 'ASIANENE',
  '532888': 'ASIANTILES',
  '533227': 'AHLEAST',
  '500023': 'ASIANHOTNR',
  '500820': 'ASIANPAINT',
  '524434': 'ASIANTNE',
  '531847': 'ASTAR',
  '519532': 'ASIANTNE',
  '543927': 'ASIAN',
  '530723': 'ASITCFIN',
  '544022': 'ASKAUTOLTD',
  '526433': 'ASMTEC',
  '540788': 'ASPIRA',
  '542911': 'ASSAMENT',
  '507526': 'ASALCBR',
  '531168': 'ASSOCER',
  '544183': 'ASSOCER',
  '512600': 'VALIANTLAB',
  '533138': 'ASTEC',
  '540975': 'ASTERDM',
  '532493': 'ASTRAMICRO',
  '532830': 'ASTRAL',
  '506820': 'ASTRAZEN',
  '540824': 'ASTRON',
  '512433': 'ASUTENT',
  '543911': 'ATALREAL',
  '543236': 'ATAM',
  '530187': 'ATHARVENT',
  '539099': 'ATHCON',
  '517429': 'ATHENAGLO',
  '538713': 'ATISHAY',
  '532759': 'ATLANTAA',
  '531795': 'ATULAUTO',
  '500027': 'ATUL',
  '500028': 'ATVPR',
  '540611': 'AUBANK',
  '532668': 'AURIONPRO',
  '530233': 'AUROLAB',
  '524804': 'AUROPHARMA',
  '539289': 'AURUM',
  '890168': 'AURUM',
  '509009': 'AUSOMENT',
  '522005': 'AUSTENG',
  '539177': 'AIIL',
  '531994': 'AUTOPINS',
  '532797': 'AUTOIND',
  '505036': 'ACGL',
  '505032': 'ACGL',
  '505010': 'AUTOAXLES',
  '520119': 'ASAL',
  '512277': 'AUTOINT',
  '540649': 'AVADHSUGAR',
  '531310': 'AVAILFC',
  '543896': 'AVALON',
  '512149': 'AVANCE',
  '532406': 'AVANTEL',
  '512573': 'AVANTI',
  '511730': 'JAFINANCE',
  '543737': 'AVEER',
  '540376': 'DMART',
  '543910': 'AVG',
  '539288': 'AVI',
  '523896': 'APIL',
  '508929': 'EXCEL',
  '511589': 'AVONMORE',
  '543512': 'AVROIND',
  '519105': 'AVTNPL',
  '544181': 'CELLA',
  '513642': 'AXELPOLY',
  '532215': 'AXISBANK',
  '532395': 'AXISCADES',
  '542285': 'AXITA',
  '523850': 'AXTEL',
  '508933': 'AYMSYNTEX',
  '544061': 'GGENG',
  '544177': 'LYNMC',
  '543543': 'BRRL',
  '532507': 'BAGFILMS',
  '537766': 'BCP',
  '531647': 'GENUSPAPER',
  '532719': 'BLKASHYAP',
  '523019': 'BNRSEC',
  '508136': 'NGIND',
  '523186': 'BAPACK',
  '543668': 'BBTCL',
  '531268': 'B2BSOFT',
  '532380': 'BABA',
  '524516': 'BACPHAR',
  '532989': 'BAFNAPHARM',
  '511724': 'BAIDFIN',
  '532977': 'BAJAJ-AUTO',
  '533229': 'BAJAJCON',
  '500031': 'BAJAJELEC',
  '500034': 'BAJFINANCE',
  '532978': 'BAJAJFINSV',
  '512261': 'BAJFINANCE',
  '539872': 'BAJAJHCARE',
  '500032': 'BAJAJHIND',
  '500490': 'BAJAJHLDNG',
  '507944': 'BAJAJST',
  '544042': 'BAJEL',
  '524824': 'BALPHARMA',
  '530999': 'BALAMINES',
  '532382': 'BALAJITELE',
  '539834': 'BALGOPAL',
  '502355': 'BALKRISIND',
  '539251': 'BALKRISHNA',
  '523319': 'BALMLAWRIE',
  '532485': 'BLIL',
  '500038': 'BALRAMCHIN',
  '531112': 'BALUFORGE',
  '520127': 'BALTE',
  '519295': 'BAMBINO',
  '531591': 'BAMPSL',
  '526849': 'BANARBEADS',
  '509053': 'BANASFN',
  '500039': 'BANCOINDIA',
  '524602': 'SHELTER',
  '541153': 'BANDHANBNK',
  '532946': 'BANG',
  '539120': 'BFFL',
  '532134': 'BANKBARODA',
  '532149': 'BANKINDIA',
  '532525': 'MAHABANK',
  '532674': 'BASML',
  '500041': 'BANARISUG',
  '538546': 'BRPL',
  '544209': 'BOMBWIR',
  '519353': 'BANSTEA',
  '503722': 'BANSWRAS',
  '532916': 'BVCL',
  '543283': 'BARBEQUE',
  '513502': 'BAROEXT',
  '500270': 'BARODARY',
  '532336': 'BLSINFOTE',
  '532694': 'ASMS',
  '524687': 'BASANTGL',
  '500042': 'BASF',
  '500043': 'BATAINDIA',
  '522004': 'BATLIBOI',
  '506285': 'BAYERCROP',
  '539946': 'BAZELINTER',
  '517246': 'BCCFUBA',
  '539621': 'BCLENTERPR',
  '524332': 'BCLIL',
  '542057': 'BCPL',
  '524828': 'BDH',
  '533270': 'BEDMUTHA',
  '539546': 'BNL',
  '539018': 'BEEKAY',
  '532645': 'BEEYU',
  '511585': 'NGIND',
  '539399': 'BELLACASA',
  '522650': 'BEMHY',
  '543898': 'BLAL',
  '500048': 'BEML',
  '541178': 'BENARA',
  '509438': 'BENARAS',
  '544052': 'COMPUPN',
  '533095': 'BENGALASM',
  '512404': 'SALSTEEL',
  '532230': 'BENGALT',
  '512195': 'BENTCOM',
  '509480': 'BERGEPAINT',
  '531340': 'BERVINL',
  '524606': 'BERLDRG',
  '531582': 'BERYLSE',
  '539660': 'BESTAGRO',
  '508664': 'BESTEAST',
  '512477': 'BETXIND',
  '533303': 'BFINVEST',
  '532430': 'BFUTILITIE',
  '539662': 'BFLAFL',
  '511664': 'BGIL',
  '532930': 'BGRENERGY',
  '509449': 'BHAGWOX',
  '530803': 'BHAGIL',
  '531719': 'BHAGCHEM',
  '504646': 'BGWTATO',
  '512296': 'BHAGYNAGAR',
  '540545': 'BGJL',
  '512608': 'BHANDHOS',
  '538576': 'NEOINFRA',
  '500052': 'BEPL',
  '531862': 'BHARATAGRI',
  '511501': 'BHARAT',
  '503960': 'BBL',
  '541143': 'BDL',
  '500049': 'BEL',
  '500493': 'BHARATFORG',
  '505688': 'BHARATGEAR',
  '500103': 'BHEL',
  '544137': 'BEL',
  '524663': 'BIBCL',
  '541096': 'BPLPHARMA',
  '500547': 'BPCL',
  '590021': 'BHARATRAS',
  '540700': 'BRNL',
  '523229': 'BHARATSE',
  '539799': 'BHARATWIRE',
  '532454': 'BHARTIARTL',
  '890157': 'BHARTIARTL',
  '544162': 'BHARTIARTL',
  '526666': 'BIL',
  '524534': 'BHASKAGR',
  '543497': 'BCCL',
  '540956': 'BHATIA',
  '514272': 'BHILSPIN',
  '533108': 'BTTL',
  '526488': 'BHUDEVI',
  '540061': 'BIGBLOC',
  '500058': 'BIHSPONG',
  '524723': 'BIJHANS',
  '543653': 'BIKAJI',
  '526853': 'BI',
  '543209': 'BILLWIN',
  '505681': 'BIMETAL',
  '500059': 'BINANIIND',
  '523054': 'ZBINTXPP',
  '540148': 'ASIANTNE',
  '532523': 'BIOCON',
  '524396': 'BIOFILCHEM',
  '531752': 'BIOGEN',
  '500060': 'BIRLACABLE',
  '500335': 'DBCORP',
  '522105': 'BIRLAPREC',
  '532400': 'BSOFT',
  '531671': 'BISIL',
  '526709': 'BITS',
  '543926': 'BIZOTIC',
  '519500': 'BKV',
  '500463': 'BBOX',
  '514183': 'BLACKROSE',
  '532290': 'BLBLIMITED',
  '506197': 'BLISSGVS',
  '526225': 'BLOOM',
  '513422': 'BLOIN',
  '544107': 'BLS',
  '540073': 'BLS',
  '531936': 'BLUECHIP',
  '506981': 'BLUECHIPT',
  '539607': 'BLUECLOUDS',
  '531495': 'BLUECOAST',
  '526612': 'BLUEDART',
  '544009': 'BLUEJET',
  '514440': 'BPTEX',
  '500067': 'BLUESTARCO',
  '542669': 'BMW',
  '526125': 'BNHOLDINGS',
  '530809': 'BNRUDY',
  '524370': 'BODALCHEM',
  '543767': 'BTML',
  '501425': 'BBTC',
  '501430': 'BOMBCYC',
  '500020': 'BOMDYEING',
  '509470': 'BOMOXY-B1',
  '511246': 'BOMTALKIES',
  '504648': 'BOMBWIR',
  '543971': 'BONDADA',
  '543211': 'BONLON',
  '543212': 'BOROLTD',
  '502219': 'BORORENEW',
  '544184': 'BOROLTD',
  '500530': 'BOSCHLTD',
  '535279': 'BMAL',
  '500074': 'BPL',
  '505690': 'BRADYM',
  '535693': 'BRAHMINFRA',
  '544226': 'JOINTECAED',
  '543442': 'BCONCEPTS',
  '543439': 'BRANDBUCKT',
  '530207': 'TITANBIO',
  '530249': 'BRIDGESE',
  '532929': 'BRIGADE',
  '526731': 'BRIGHTBR',
  '543831': 'BRIGHT',
  '532113': 'BRIJLEAS',
  '539434': 'NGIND',
  '544101': 'NGIND',
  '500825': 'BRITANNIA',
  '544231': 'VIKASLIFE',
  '543261': 'BIRET',
  '533543': 'BROOKS',
  '532123': 'BSELALGO',
  '514045': 'BSL',
  '532931': 'BURNPUR',
  '517421': 'BUTTERFLY',
  '531373': 'THEBYKE',
  '543425': 'MAPMYINDIA',
  '507515': 'CJGEL',
  '517236': 'CALCOM',
  '532386': 'CALSOFT',
  '532801': 'CTE',
  '524440': 'CAMEXLTD',
  '532834': 'CAMLINFINE',
  '543523': 'CAMPUS',
  '511196': 'CANFINHOME',
  '532483': 'CANBK',
  '522292': 'CANDOUR',
  '533267': 'CANTABIL',
  '540710': 'CAPACITE',
  '539198': 'CAPFIN',
  '530879': 'CIFL',
  '544120': 'ESAFSFB',
  '538476': 'CTL',
  '511505': 'CAPITALT',
  '524742': 'CAPPL',
  '531595': 'CGCL',
  '512169': 'CAPRICORN',
  '509486': 'CAPRIHANS',
  '507486': 'CAPRO',
  '538817': 'CAPPIPES',
  '536974': 'CPL',
  '540652': 'CTCL',
  '513375': 'CARBORUNIV',
  '534804': 'CARERATING',
  '533260': 'CAREERP',
  '543621': 'CARGOSOL',
  '543618': 'CARGOTRANS',
  '543333': 'CARTRADE',
  '524091': 'CARYSIL',
  '534732': 'CASPIAN',
  '500870': 'CASTROLIND',
  '531158': 'CATVISION',
  '531900': 'CCLINTER',
  '519600': 'CCL',
  '534796': 'CDG',
  '500878': 'CEATLTD',
  '530789': 'CEEJAY',
  '531119': 'CEENIK',
  '514171': 'CEETAIN',
  '544223': 'DHINDIA',
  '538734': 'CEINSYSTECH',
  '532695': 'CELEBRITY',
  '532701': 'CELLA',
  '544012': 'CELLO',
  '522251': 'CENLUB',
  '531380': 'CSURGSU',
  '531621': 'BGIL',
  '532885': 'CENTRALBK',
  '501150': 'CENTRUM',
  '517544': 'CENTUM',
  '500280': 'CENTENKA',
  '500083': 'CENTEXT',
  '532548': 'CENTURYPLY',
  '500040': 'CENTURYTEX',
  '532443': 'CERA',
  '532413': 'CEREBRAINT',
  '512341': 'NGIND',
  '500084': 'CESC',
  '543920': 'CFF',
  '500093': 'CGPOWER',
  '531489': 'CGVAK',
  '531946': 'SIMPLXPAP',
  '542399': 'CHALET',
  '530747': 'CHALLANI',
  '530307': 'CHAMANSEQ',
  '512301': 'CHMBBRW',
  '500085': 'CHAMBLFERT',
  '542627': 'CHANDNIMACH',
  '542934': 'CBPL',
  '530309': 'CHANDRAP',
  '540829': 'CHANDRIMA',
  '531327': 'CHARMS',
  '511696': 'CHRTEDCA',
  '531977': 'CHLOGIST',
  '544151': 'CHATHA',
  '539800': 'CHDCHEM',
  '531099': 'CHECKPOINT',
  '530871': 'CHEMBOND',
  '543233': 'CHEMCON',
  '540395': 'CHEMCRUX',
  '541269': 'CHEMFABALKA',
  '539230': 'VAPIENTER',
  '506365': 'CHEMOPH',
  '543336': 'CHEMPLASTS',
  '537326': 'CHEMTECH',
  '539011': 'CHENFERRO',
  '523489': 'CMMHOSP',
  '500110': 'CHENNPETRO',
  '526817': 'CHEVIOT',
  '532992': 'CHLLTD',
  '531358': 'CHOICEIN',
  '530427': 'CHOKSI',
  '526546': 'CHOKSILA',
  '504973': 'CHOLAHLDNG',
  '511243': 'CHOLAFIN',
  '519475': 'CHORDIA',
  '540681': 'CHOTHANI',
  '501833': 'CHOWGULSTM',
  '513005': 'SRSOLTD',
  '519477': 'CIANAGRO',
  '542678': 'CHCL',
  '532756': 'CIEINDIA',
  '534758': 'CIGNITI',
  '533407': 'CNOVAPETRO',
  '530829': 'CILSEC',
  '531283': 'CINDRELL',
  '526373': 'CINDHO',
  '532807': 'CINELINE',
  '530457': 'CINERAD',
  '532324': 'CINEVISTA',
  '500087': 'CIPLA',
  '531775': 'CISTRO',
  '502445': 'CITADEL',
  '531235': 'KENFIN',
  '538786': 'CIL',
  '544000': 'CCAL',
  '538674': 'CITYONLINE',
  '542727': 'CPML',
  '532210': 'CUB',
  '521210': 'CITYMAN',
  '540403': 'CLEDUCATE',
  '543435': 'CLARA',
  '512213': 'CLASELE',
  '540310': 'CFL',
  '540481': 'CLFL',
  '543318': 'CLEAN',
  '544220': 'NGIND',
  '530839': 'CLIOINFO',
  '543441': 'CMSINFO',
  '532217': 'GANHOLD',
  '512018': 'CNIRESLTD',
  '533278': 'COALINDIA',
  '501831': 'DBCORP',
  '520131': 'COARO',
  '508571': 'COCHMAL',
  '513353': 'COCHINM',
  '540678': 'COCHINSHIP',
  '539436': 'COFFEEDAY',
  '532541': 'COFORGE',
  '542866': 'COLABCLOUD',
  '500830': 'COLPAL',
  '531210': 'COLINZ',
  '540023': 'COLORCHIPS',
  '534691': 'COMCL',
  '535267': 'COMFINCAP',
  '531216': 'COMFINTE',
  '543843': 'COMMAND',
  '539986': 'COMSYN',
  '531041': 'COMPEAU',
  '532456': 'COMPUAGE',
  '532339': 'COMPUSOFT',
  '543232': 'CAMS',
  '507833': 'COMPUPN',
  '543921': 'COMRADE',
  '522231': 'CONART',
  '543960': 'CONCORDBIO',
  '543619': 'CNCRD',
  '538965': 'CONCORD',
  '539266': 'LUHARUKA',
  '539991': 'CFEL',
  '526829': 'CONFIPET',
  '539091': 'CITL',
  '523844': 'CONSTRONIC',
  '543606': 'CONTAINE',
  '531344': 'CONCOR',
  '540597': 'POLYCON',
  '531067': 'CONTILI',
  '506935': 'CONTCHM',
  '531460': 'CONTICON',
  '523232': 'CONTPTR',
  '538868': 'CSL',
  '522295': 'CONTROLPR',
  '531556': 'CORALFINAC',
  '524506': 'CORALAB',
  '530755': 'CORNE',
  '532941': 'CORDSCABLE',
  '507543': 'COROMANDEL',
  '533167': 'COROENGG',
  '506395': 'COROMANDEL',
  '540199': 'CMBL',
  '530545': 'COSCO',
  '543928': 'COSMICCRF',
  '523100': 'COSMOFE',
  '508814': 'COSMOFIRST',
  '543172': 'COSPOWER',
  '538922': 'COSYN',
  '526550': 'CCHHL',
  '531624': 'COUNCODOS',
  '543276': 'CRAFTSMAN',
  '538770': 'CRANEINFRA',
  '512093': 'CRANESSOFT',
  '522001': 'CRANEX',
  '509472': 'CRAVATEX',
  '539527': 'CREATIVE',
  '532392': 'CREATIVEYE',
  '539598': 'CGFL',
  '541770': 'CREDITACC',
  '544058': 'SBRANDS',
  '512379': 'CRESSAN',
  '511413': 'CREST',
  '526269': 'CRSTCHM',
  '526977': 'NDMETAL',
  '500092': 'CRISIL',
  '531909': 'CROISSANCE',
  '539876': 'CROMPTON',
  '523105': 'CROPSTER',
  '540903': 'YASHMGM',
  '542867': 'CSBBANK',
  '530067': 'CSLFINANCE',
  '543899': 'CAPITALT',
  '526027': 'CUBEXTUB',
  '511710': 'CUBIFIN',
  '500480': 'CUMMINSIND',
  '530843': 'CUPID',
  '543378': 'NGIND',
  '531472': 'CYBELEIND',
  '532640': 'CYBERMEDIA',
  '532173': 'CYBERTECH',
  '543933': 'CYIENTDLM',
  '532175': 'CYIENT',
  '517514': 'DHINDIA',
  '543962': 'DPWIRES',
  '533146': 'DLINKINDIA',
  '544161': 'NGIND',
  '533151': 'DBCORP',
  '500096': 'DABUR',
  '526821': 'DAICHI',
  '530825': 'DAIKAFFI',
  '501148': 'DSINVEST',
  '542216': 'DALBHARAT',
  '500097': 'DALMIASUG',
  '521220': 'DAMOINDUS',
  '532329': 'DANLAW',
  '540361': 'DANUBE',
  '543651': 'DAPS',
  '539770': 'RJSHAH',
  '539884': 'DARSHANORNA',
  '543225': 'IDM',
  '543428': 'DATAPATTNS',
  '532528': 'DATAMATICS',
  '519413': 'CARGOTRANS',
  '530171': 'DAULAT',
  '543267': 'DAVANGERE',
  '530393': 'DBSTOCKBRO',
  '543636': 'DCI',
  '532772': 'DCBBANK',
  '511611': 'DCMFINSERV',
  '502820': 'DCM',
  '542729': 'DCMNVL',
  '523369': 'DCMSRMIND',
  '523367': 'DCMSHRIRAM',
  '500117': 'DCW',
  '543650': 'DCXINDIA',
  '543547': 'DDEVPLASTIK',
  '590031': 'DENORA',
  '505703': 'DECANBRG',
  '502137': 'DECCANCE',
  '512068': 'DECNGOLD',
  '542248': 'DECCAN',
  '539190': 'DFL',
  '524752': 'DECIPHER',
  '531227': 'DECOMIC',
  '539405': 'DITCO',
  '544198': 'ENGINERSIN',
  '539559': 'DDIL',
  '532760': 'DEEPENR',
  '543288': 'DEEP',
  '541778': 'DEEP',
  '544036': 'DEEPAKCHEM',
  '500645': 'DEEPAKFERT',
  '506401': 'DEEPAKNI',
  '514030': 'DEEPAKSP',
  '543529': 'DELHIVERY',
  '533452': 'DELPHIFX',
  '532848': 'DELTACORP',
  '539596': 'DELTA',
  '504286': 'DELTAMAGNT',
  '504240': 'DLTNCBL',
  '533137': 'DEN',
  '537536': 'DENISCHEM',
  '531521': 'DESHRAK',
  '543462': 'DEVIT',
  '543848': 'DEVLAB',
  '531585': 'DEVINE',
  '512445': 'GDTRAGN',
  '543330': 'DEVYANI',
  '538715': 'DHABRIYA',
  '543593': 'DBOL',
  '500119': 'DHAMPURSUG',
  '531923': 'DHAMPURE',
  '542679': 'DEL',
  '532960': 'DHANI',
  '532180': 'DHANBANK',
  '512485': 'DHANCOT',
  '521151': 'DHANFAB',
  '521216': 'DHANROTO',
  '507717': 'DHANUKA',
  '531043': 'ZDHJERK',
  '511451': 'DHARFIN',
  '543687': 'DHARMAJ',
  '543753': 'DHARNI',
  '540080': 'DHATRE',
  '501945': 'ASHOKA',
  '526971': 'DHOOTIN',
  '531306': 'DHPIND',
  '541302': 'DHRUV',
  '531237': 'DHRUVCA',
  '533336': 'DHUNINV',
  '538902': 'DHUNTEAIND',
  '523736': 'DVL',
  '543516': 'DHYAANI',
  '500120': 'DIAMINESQ',
  '522163': 'DIACABS',
  '530959': 'DIANATEA',
  '500089': 'DICIND',
  '540811': 'DML',
  '542685': 'DGCONTENT',
  '544079': 'KDML',
  '517214': 'DIGISPICE',
  '543859': 'CAPITALT',
  '539979': 'DIGJAMLTD',
  '540151': 'DIKSAT',
  '542155': 'DGL',
  '531153': 'DILIGENT',
  '540789': 'DNAMEDIA',
  '540047': 'DBL',
  '544201': 'HATSUN',
  '543594': 'SAMRATPH',
  '500068': 'DISAQ',
  '532839': 'DISHTV',
  '531553': 'DRL',
  '540701': 'DCAL',
  '543812': 'DIVGIITTS',
  '532488': 'DIVISLAB',
  '526315': 'DIVSHKT',
  '540699': 'DIXON',
  '543193': 'DJML',
  '532868': 'DLF',
  '506405': 'DMCC',
  '543410': 'DMR',
  '543306': 'DODLA',
  '505526': 'DOLATALGO',
  '542013': 'DOLFIN',
  '541403': 'DOLLAR',
  '522261': 'DOLPHINOFF',
  '544045': 'DOMS',
  '512519': 'DONEAR',
  '541299': 'DLCL',
  '526783': 'DRAGARWQ',
  '539524': 'LALPATHLAB',
  '500124': 'DRREDDY',
  '540144': 'DRA',
  '543268': 'DRCSYSTEMS',
  '543591': 'DREAMFOLKS',
  '523618': 'DREDGECORP',
  '543713': 'DRONACHRYA',
  '526677': 'KEEPLEARN',
  '534674': 'DUCON',
  '511634': 'ASHIANA',
  '531471': 'DUKEOFS',
  '504908': 'DUNCANENG',
  '526355': 'DUROPACK',
  '516003': 'DUROPLY',
  '517437': 'DUTRON',
  '532610': 'DWARKESH',
  '532365': 'DSSL',
  '505242': 'DYNAMATECH',
  '539681': 'DAL',
  '540795': 'DYNAMIC',
  '524818': 'DYNAMIND',
  '530779': 'DYNAMICP',
  '517238': 'DYNAVSN',
  '532707': 'DYNPRO',
  '532820': 'ELAND',
  '500125': 'EIDPARRY',
  '543765': 'EARTH',
  '507917': 'EASTBUILD',
  '540006': 'EASTWEST',
  '520081': 'ECSTSTL',
  '531346': 'EASTRED',
  '542906': 'EASUN',
  '511074': 'UYFINCORP',
  '543272': 'EASEMYTRIP',
  '532927': 'ECLERX',
  '514402': 'ECOHOTELS',
  '530643': 'ECORECO',
  '523732': 'ECOBOAR',
  '539455': 'NGIND',
  '538708': 'ETIL',
  '526703': 'ECOPLAST',
  '540063': 'ECS',
  '532922': 'EDELWEISS',
  '517170': 'EDVENSWA',
  '512008': 'EFCIL',
  '505200': 'EICHERMOT',
  '543518': 'EIGHTY',
  '523127': 'EIHAHOTELS',
  '500840': 'EIHOTEL',
  '540204': 'EIKO',
  '523708': 'EIMCOELECO',
  '530581': 'EKAMLEA',
  '531364': 'EKANSH',
  '543475': 'EKENNIS',
  '543284': 'EKI',
  '531144': 'ELFORGE',
  '513452': 'ELANGO',
  '500123': 'ELANTAS',
  '503681': 'ELCIDIN',
  '523329': 'ELDEHSG',
  '505700': 'ELECON',
  '543626': 'EMIL',
  '500128': 'ELECTCAST',
  '526608': 'ELECTHERM',
  '526473': 'ELEFLOR',
  '526705': 'ELEMARB',
  '522074': 'ELGIEQUIP',
  '543725': 'ELIN',
  '539533': 'POLYCON',
  '531278': 'ELIXIR',
  '517477': 'ELNET',
  '504000': 'ELPROINTL',
  '522027': 'EMAINDIA',
  '531162': 'EMAMILTD',
  '533208': 'EMAMIPAP',
  '533218': 'EMAMIREAL',
  '542602': 'EMBASSY',
  '544210': 'JBCHEPHARM',
  '538882': 'EMERALD',
  '507265': 'EMERALL',
  '506180': 'CGPOWER',
  '532737': 'EMKAY',
  '533161': 'EMMBI',
  '524768': 'EMMESSA',
  '544166': 'SHIVAMAUTO',
  '509525': 'EMPIND',
  '504351': 'EMPOWER',
  '543983': 'EMSLIMITED',
  '543533': 'EMUDHRA',
  '512441': 'ENBETRD',
  '530733': 'ENCODE',
  '540153': 'ENDURANCE',
  '532219': 'ENERGYDEV',
  '532178': 'ENGINERSIN',
  '533477': 'ENKEIWHEL',
  '544122': 'BAJAJHCARE',
  '526574': 'ENTRINT',
  '532700': 'ENIL',
  '500246': 'ENVAIREL',
  '543595': 'EPBIO',
  '544095': 'NGIND',
  '530407': 'EPIC',
  '543332': 'EPIGRAL',
  '500135': 'EPL',
  '531155': 'EPSOMPRO',
  '532092': 'EPUJA',
  '532832': 'DHINDIA',
  '590057': 'EQUIPPP',
  '543243': 'EQUITASBNK',
  '531035': 'NGIND',
  '540596': 'ERIS',
  '533261': 'EROSMEDIA',
  '530909': 'ERPSOFT',
  '531502': 'ESARIND',
  '500133': 'ESABINDIA',
  '544020': 'ESAFSFB',
  '540455': 'ESCORP',
  '500495': 'ESCORTS',
  '531259': 'ESHAMEDIA',
  '532016': 'ESPIRE',
  '533704': 'ESSARSHPNG',
  '500136': 'ESTER',
  '543532': 'ETHOSLTD',
  '537707': 'ETT',
  '544094': 'BLSINFOTE',
  '543482': 'EUREKAFORBE',
  '521137': 'EUREKAI',
  '530929': 'EUROASIA',
  '526468': 'EUROLED',
  '521014': 'EUROTEXIND',
  '542668': 'EVANS',
  '531508': 'EVEREADY',
  '508906': 'EVERESTIND',
  '532684': 'EKC',
  '524790': 'EVERESTO',
  '514358': 'EVERFIN',
  '524444': 'EVEXIA',
  '543500': 'EVOQ',
  '500650': 'EXCELINDUS',
  '533090': 'EXCEL',
  '543895': 'EXHICON',
  '544133': 'EXICOM',
  '500086': 'EXIDEIND',
  '533121': 'EXPLEOSOL',
  '530571': 'EXPLICITFIN',
  '526614': 'EXPOGAS',
  '543327': 'EXXARO',
  '512099': 'EY',
  '539552': 'FMEC',
  '544164': 'BCONCEPTS',
  '543444': 'FABINO',
  '532656': 'FACORALL',
  '543252': 'FAIRCHEMOR',
  '516110': 'FAMILYCARE',
  '530079': 'FAZE3Q',
  '532666': 'FCSSOFT',
  '531599': 'FDC',
  '544027': 'FEDFINA',
  '511628': 'NGIND',
  '500469': 'FEDERALBNK',
  '505744': 'FMGOETZE',
  '506414': 'FERMENTA',
  '590024': 'FACT',
  '533896': 'FERVENTSYN',
  '500142': 'FGP',
  '507910': 'FIBERWEB',
  '532768': 'FIEMIND',
  '532022': 'FILATFASH',
  '526227': 'FILATEX',
  '531486': 'BAGFILMS',
  '539098': 'FILTRA',
  '541557': 'FINEORG',
  '517264': 'FINELINE',
  '544173': 'BGIL',
  '533333': 'FCL',
  '508954': 'FINKURVE',
  '543386': 'FINOPB',
  '500144': 'FINCABLES',
  '500940': 'FINOLEXIND',
  '511122': '1STCUS',
  '532379': 'FIRSTFIN',
  '532809': 'FSL',
  '524743': 'FISCHER',
  '543663': 'FIVESTAR',
  '544030': 'FLAIR',
  '523672': 'FLEXFO',
  '533638': 'FLEXITUFF',
  '504380': 'FLOMIC',
  '540267': 'FLORATX',
  '530705': 'FLORATX',
  '522017': 'FLUIDOM',
  '543312': 'FOCUS',
  '543521': 'ADCINDIA',
  '507552': 'FOODSIN',
  '502865': 'FORBESCO',
  '544186': 'SOLIMAC',
  '500033': 'FORCEMOT',
  '532843': 'FORTIS',
  '523696': 'FORTISMLR',
  '530213': 'FORINTL',
  '500150': 'FOSECOIND',
  '513579': 'FFPL',
  '532403': 'OONE',
  '540190': 'FRANKLININD',
  '539839': 'FRANKLIN',
  '539032': 'FRASER',
  '541741': 'SULA',
  '539730': 'FREDUN',
  '530077': 'FRSHTRP',
  '508980': 'FRONTCAP',
  '522195': 'FRONTSP',
  '532042': 'FRONTCORP',
  '538568': 'FRUTION',
  '543384': 'NYKAA',
  '530197': 'FUNDVISER',
  '543652': 'FUSION',
  '523574': 'FCONSUMER',
  '570002': 'FRETAIL',
  '536507': 'FLFL',
  '533296': 'FMNL',
  '540798': 'FSC',
  '523113': 'FUTURSEC',
  '534063': 'FUTSOL',
  '540614': 'GGENG',
  '543239': 'GMPL',
  '543317': 'GRINFRA',
  '532139': 'MAPMYINDIA',
  '531399': 'GGAUTO',
  '505250': 'GGDANDE',
  '531758': 'GKCONS',
  '542666': 'GKP',
  '507488': 'GMBREW',
  '513059': 'GSAUTO',
  '505714': 'GABRIEL',
  '544108': 'GABRIEL',
  '531723': 'GATECH',
  '570005': 'GATECH',
  '502850': 'ZGAEKWAR',
  '524624': 'GAGAN',
  '532155': 'GAIL',
  '538609': 'GAJANANSEC',
  '539228': 'GGPL',
  '542802': 'GALACTICO',
  '538881': 'GALADAFIN',
  '531911': 'GALAGEX',
  '526073': 'GALXBRG',
  '506186': 'GCKL',
  '540935': 'GALAXYSURF',
  '532726': 'GALLANTT',
  '531902': 'GALLOPENT',
  '544029': 'GANDHAR',
  '513108': 'GANDHITUBE',
  '500153': 'GANESHBE',
  '504397': 'GANHOLD',
  '526367': 'GANESHHOUC',
  '514167': 'GANECOS',
  '539041': 'GANVERSE',
  '531813': 'GANGAPA',
  '539680': 'GANGAPHARM',
  '540647': 'GANGESSEC',
  '512443': 'GANONPRO',
  '539492': 'GARBIFIN',
  '542011': 'GRSE',
  '530615': 'GARGFUR',
  '539216': 'GARMNTMNTR',
  '526727': 'GARNET',
  '512493': 'GARNETINT',
  '530161': 'GARODCH',
  '500655': 'GRWRHITECH',
  '509563': 'GARWAMAR',
  '514400': 'GARWSYN',
  '509557': 'GARFIBRES',
  '543489': 'GATEWAY',
  '540613': 'GEL',
  '540936': 'GGL',
  '524564': 'GAYATRIBI',
  '541546': 'GAYAPROJ',
  '532767': 'GAYAPROJ',
  '532183': 'GAYATRI',
  '538319': 'GCMCAPI',
  '535917': 'GCMCOMM',
  '535431': 'GCMSECU',
  '544156': 'FSC',
  '530855': 'KZLFIN',
  '532309': 'GEPIL',
  '522275': 'GEPIL',
  '504028': 'GEE',
  '532764': 'GEECEE',
  '544199': 'GEMSPIN',
  '521133': 'GEMSPIN',
  '531137': 'GEMSI',
  '540755': 'GICRE',
  '539407': 'GENCON',
  '514336': 'GENESIS',
  '506109': 'GENESYS',
  '531739': 'GENNEX',
  '539206': 'GVBL',
  '531592': 'GENPHARMA',
  '542851': 'GENSOL',
  '538961': 'GENUSPAPER',
  '530343': 'GENUSPOWER',
  '532425': 'GENUSPRIME',
  '532285': 'GEOJITFSL',
  '543372': 'GETALONG',
  '500173': 'GFLLIMITED',
  '500171': 'GHCL',
  '543918': 'GHCLTEXTIL',
  '542918': 'GIANLIFE',
  '511676': 'GICHSGFIN',
  '538788': 'GILADAFINS',
  '532716': 'GILLANDERS',
  '507815': 'GILLETTE',
  '531744': 'GINISILK',
  '590025': 'GINNIFILA',
  '539013': 'GITARENEW',
  '533212': 'GKB',
  '531199': 'GLANCE',
  '543245': 'GLAND',
  '500660': 'GLAXO',
  '542477': 'NGIND',
  '543322': 'GLS',
  '532296': 'GLENMARK',
  '513528': 'GLITTEKG',
  '530263': 'GLOBALCA',
  '543654': 'MEDANTA',
  '543520': 'GLHRL',
  '501848': 'GLOBOFFS',
  '543829': 'GSLSU',
  '532773': 'GLOBALVECT',
  '540654': 'GSTL',
  '540266': 'GLCL',
  '526025': 'GLOBUSCON',
  '533104': 'GLOBUSSPR',
  '542351': 'GLOSTERLTD',
  '505255': 'GMM',
  '532754': 'GMRINFRA',
  '543490': 'UNISHIRE',
  '540124': 'GNA',
  '544179': 'GICRE',
  '543401': 'GOCOLORS',
  '509567': 'GOACARBON',
  '542850': 'GOBLIN',
  '506480': 'DBCORP',
  '530317': 'GODAVARI',
  '532734': 'GPIL',
  '500163': 'GODFRYPHLP',
  '540743': 'GODREJAGRO',
  '532424': 'GODREJCP',
  '500164': 'GODREJIND',
  '533150': 'GODREJPROP',
  '543538': 'GOEL',
  '538787': 'GBFL',
  '533189': 'GBFL',
  '531600': 'GOGIACAP',
  '532957': 'GOKAKTEX',
  '532630': 'GOKEX',
  '539725': 'GOKULAGRO',
  '532980': 'GOKUL',
  '538542': 'SMGOLD',
  '501111': 'SMGOLD',
  '531928': 'GOLCA',
  '540062': 'GOLDENCREST',
  '500151': 'GOLDENTOBC',
  '526729': 'GOLDIAM',
  '531360': 'GOLECHA',
  '513309': 'GOLKONDA',
  '523676': 'GOLKUNDIA',
  '530655': 'GOODLUCK',
  '500166': 'GOODRICKE',
  '500168': 'GOODYEAR',
  '531913': 'GOPAIST',
  '544140': 'DIAMONDYD',
  '531608': 'GORANIN',
  '531111': 'GOTHIPL',
  '506134': 'GATEWAY',
  '530709': 'GOWRALE',
  '541152': 'GOYALALUM',
  '530663': 'GOYALASS',
  '532543': 'GULFPETRO',
  '544131': 'GPTINFRA',
  '533761': 'GPTINFRA',
  '513343': 'FFPL',
  '504369': 'GDTRAGN',
  '532482': 'GRANULES',
  '509488': 'GRAPHITE',
  '500300': 'GRASIM',
  '890190': 'GRASIM',
  '526751': 'GRATEXI',
  '505710': 'GRAUWEIL',
  '509546': 'GRAVISSHO',
  '533282': 'GRAVITA',
  '532015': 'GRAVITY',
  '500620': 'GESHIP',
  '501455': 'GREAVESCOT',
  '531737': 'GREENCREST',
  '544163': 'KRISHNA',
  '538979': 'GRNLAMIND',
  '542857': 'GREENPANEL',
  '526797': 'GREENPLY',
  '543324': 'GCSL',
  '506076': 'GRINDWELL',
  '531449': 'GRMOVER',
  '532315': 'NGIND',
  '539522': 'GROVY',
  '539222': 'GROWINGTON',
  '509152': 'GRPLTD',
  '511543': 'GSBFIN',
  '530469': 'GSLSEC',
  '532951': 'GSS',
  '532775': 'GTL',
  '500160': 'GTL',
  '500170': 'GTNINDS',
  '532744': 'GTNTEX',
  '540602': 'GTPL',
  '539479': 'GTV',
  '509079': 'GUFICBIO',
  '530001': 'GUJALKALI',
  '524226': 'GAEL',
  '522217': 'GUJAPOLLO',
  '513507': 'GUJCONT',
  '514386': 'GUJCOTEX',
  '526965': 'GUJCRAFT',
  '511441': 'GUJCRED',
  '542812': 'FLUOROCHEM',
  '539336': 'GUJGAS',
  '507960': 'GUJHOTE',
  '540938': 'GUJHYSPIN',
  '517300': 'GIPCL',
  '524238': 'GUJINJEC',
  '517372': 'GUJINTRX',
  '500174': 'GLFL',
  '532181': 'GMDCLTD',
  '500670': 'GNFC',
  '513536': 'GNRL',
  '506858': 'GUJPETR',
  '533248': 'GPPL',
  '517288': 'GUJARATPOLY',
  '523836': 'GUJRAFIA',
  '500690': 'GSFC',
  '532160': 'GUJSTATFIN',
  '532702': 'GSPL',
  '524314': 'GUJTERC',
  '506879': 'GUJTHEM',
  '513337': 'GUJTLRM',
  '506640': 'GUJCMDS',
  '538567': 'GULFOILLUB',
  '532457': 'GULPOLY',
  '523277': 'GVFILM',
  '532708': 'GVKPIL',
  '530141': 'GYANDEV',
  '509631': 'NGIND',
  '541019': 'HGINFRA',
  '502873': 'HPCOTTON',
  '532145': 'DHINDIA',
  '539854': 'HALDER',
  '515147': 'HALDYNGL',
  '526407': 'HAMPTON',
  '538731': 'HANMAN',
  '543227': 'HAPPSTMNDS',
  '544057': 'MMFL',
  '509597': 'HARDCAS',
  '541276': 'HARDWYN',
  '531971': 'HARIGOV',
  '538081': 'HARIAAPL',
  '512604': 'HARIAEXPO',
  '543517': 'HARIOMPIPE',
  '542682': 'HARISH',
  '526931': 'HRYNSHP',
  '506024': 'HRYNSHP',
  '530055': 'HRMNYCP',
  '500467': 'HARRMALAYA',
  '543600': 'HARSHA',
  '544105': 'NGIND',
  '505336': 'HARSHILAGR',
  '532855': 'HARYNACAP',
  '530927': 'DBCORP',
  '524080': 'HARLETH',
  '780014': 'FLFL',
  '531387': 'HASTIFIN',
  '509073': 'HATHWAYB',
  '533162': 'HATHWAY',
  '531531': 'HATSUN',
  '517354': 'HAVELLS',
  '539176': 'HAWAENG',
  '508486': 'HAWKINCOOK',
  '532467': 'HAZOOR',
  '532334': 'HBESD',
  '508956': 'HBLEAS',
  '532333': 'HBPOR',
  '532216': 'HBSTOCK',
  '517271': 'HBLPOWER',
  '539224': 'KRISHNA',
  '500179': 'HCL-INSYS',
  '532281': 'HCLTECH',
  '526717': 'HPBL',
  '541729': 'HDFCAMC',
  '500180': 'HDFCBANK',
  '540777': 'HDFCLIFE',
  '540210': 'HEADSUP',
  '539787': 'HCG',
  '503689': 'HEALTHYLIFE',
  '543546': 'HEALTHYLIFE',
  '526967': 'HEERAISP',
  '500292': 'HEIDELBERG',
  '539174': 'HELPAGE',
  '505520': 'SUJALA',
  '502133': 'HEMACEM',
  '531178': 'HEMANG',
  '543916': 'NGIND',
  '543242': 'HEMIPROP',
  '524590': 'FINEORG',
  '543266': 'HERANBA',
  '505720': 'HERCULES',
  '519552': 'HERITGFOOD',
  '500182': 'HEROMOTOCO',
  '524669': 'HESTERBIO',
  '506390': 'HEUBACHIND',
  '534328': 'HEXATRADEX',
  '500183': 'HFCL',
  '542332': 'SYMBIOX',
  '543411': 'HITECH',
  '504176': 'HIGHENE',
  '531301': 'FILATEX',
  '524735': 'HIKAL',
  '509675': 'HIL',
  '539697': 'HILIKS',
  '532847': 'HILTON',
  '505712': 'HIMTEK',
  '500184': 'HSCL',
  '526899': 'HFIL',
  '514043': 'HIMATSEIDE',
  '531979': 'HINDALUMI',
  '538652': 'HINDALUMI',
  '504036': 'HIRECT',
  '500440': 'HINDALCO',
  '509895': 'HINDMILL',
  '543645': 'HPIL',
  '532859': 'HGS',
  '514428': 'HINDADH',
  '541154': 'HAL',
  '519574': 'HINDUST',
  '531918': 'HINDAPL',
  '532041': 'HINDBIO',
  '509635': 'HINDCOMPOS',
  '500185': 'HCC',
  '513599': 'HINDCOPPER',
  '524013': 'HINDAPL',
  '519126': 'HNDFDS',
  '505893': 'HINDHARD',
  '509650': 'RJSHAH',
  '533217': 'HMVL',
  '500500': 'HINDMOTORS',
  '500186': 'HINDOILEXP',
  '500449': 'HOCL',
  '500104': 'HINDPETRO',
  '524316': 'JINDALPHOT',
  '530315': 'HINDTIN',
  '500696': 'HINDUNILVR',
  '500188': 'HINDZINC',
  '515145': 'NATGENI',
  '513039': 'BAJAJHIND',
  '539984': 'HUIL',
  '542905': 'HINDWAREAP',
  '530853': 'HIPOLIN',
  '531743': 'COMPEAU',
  '590018': 'HISARMET',
  '521068': 'HISARSP',
  '532359': 'TIGLOB',
  '543187': 'POWERINDIA',
  '526217': 'DBCORP',
  '531661': 'HITTCO',
  '522215': 'HLEGLAS',
  '500193': 'HLVLTD',
  '543929': 'HMAAGRO',
  '500191': 'HMT',
  '543259': 'HOMEFIRST',
  '544014': 'HONASA',
  '522064': 'HONDAPOWER',
  '517174': 'HONAUT',
  '540530': 'HUDCO',
  '532873': 'PODDAR',
  '532761': 'HOVS',
  '526761': 'HOWARHO',
  '543433': 'HPAL',
  '540136': 'HPL',
  '532662': 'HTMEDIA',
  '532799': 'HUBTOWN',
  '509820': 'HUHTAMAKI',
  '542592': 'HBEL',
  '500262': 'HYBRIDFIN',
  '539724': 'BGIL',
  '500199': 'IGPL',
  '512405': 'CGPOWER',
  '519463': 'IBINFO',
  '511194': 'ICDSLTD',
  '532174': 'ICICIBANK',
  '540716': 'ICICIGI',
  '540133': 'ICICIPRULI',
  '541179': 'ISEC',
  '532835': 'ICRA',
  '511260': 'MAPMYINDIA',
  '500116': 'IDBI',
  '543932': 'IDEAFORGE',
  '539437': 'IDFCFIRSTB',
  '532659': 'IDFC',
  '504375': 'SOFTBPO',
  '531840': 'JOINTECAED',
  '524614': 'INDXTRA',
  '507438': 'IFBAGRO',
  '505726': 'IFBIND',
  '500106': 'IFCI',
  '540774': 'IFGLEXPOR',
  '540377': 'IFL',
  '517380': 'IGARASHI',
  '539449': 'IGCIL',
  '532636': 'IIFL',
  '542773': 'IIFLSEC',
  '526530': 'GANHOLD',
  '531968': 'IITLPROJ',
  '543923': 'IKIO',
  '511208': 'IVC',
  '532907': 'IVC',
  '715023': 'IVC',
  '533177': 'ILFSTRANS',
  '539056': 'IMAGICAA',
  '513295': 'IMEC',
  '532614': 'IMPEXFERRO',
  '531129': 'INANI',
  '531672': 'INANISEC',
  '517370': 'INCAP',
  '531594': 'INCON',
  '538365': 'INCREDIBLE',
  '523465': 'INDBNK',
  '536709': 'GITARENEW',
  '532305': 'INDSWFTLAB',
  '524652': 'INDSWFTLTD',
  '509162': 'INDAG',
  '511473': 'INDBANK',
  '544172': 'INDGN',
  '531505': 'INDERGR',
  '511355': 'INDCEMCAP',
  '530005': 'INDCEMCAP',
  '535667': 'IFINSEC',
  '531253': 'INDGELA',
  '500201': 'INDIAGLYCO',
  '540565': 'POWERGRID',
  '530979': 'INDIAHOME',
  '542543': 'CAPITALT',
  '500202': 'INDLEASE',
  '590065': 'IMPAL',
  '532240': 'INDNIPPON',
  '543311': 'IPL',
  '505100': 'INRADIA',
  '544044': 'INDIASHLTR',
  '513361': 'ISWL',
  '532189': 'ITDC',
  '543715': 'IEL',
  '542726': 'INDIAMART',
  '514165': 'INDIANACRY',
  '532814': 'INDIANB',
  '504731': 'IBRIGST',
  '509692': 'INDIANCARD',
  '540750': 'IEX',
  '543925': 'CAPITALT',
  '500850': 'UPHOT',
  '504741': 'INDIANHUME',
  '509051': 'INDINFO',
  '504746': 'DLINKINDIA',
  '533047': 'IMFA',
  '530965': 'IOC',
  '532388': 'IOB',
  '542830': 'IRCTC',
  '543257': 'IRFC',
  '544026': 'IREDA',
  '500319': 'INDSUCR',
  '533329': 'INDTERRAIN',
  '523586': 'INDTONER',
  '540954': 'IWP',
  '501700': 'INDIANVSH',
  '543258': 'INDIGOPNTS',
  '532745': 'INDICAP',
  '524648': 'INDOAMIN',
  '524342': 'INDOBORAX',
  '538838': 'ICL',
  '521016': 'ICIL',
  '526887': 'ASHIKA',
  '524458': 'INDOEURO',
  '506945': 'NGIND',
  '500207': 'INDORAMA',
  '532717': 'INDOTECH',
  '533676': 'INDOTHAI',
  '541304': 'INDOUS',
  '532100': 'INDOCITY',
  '504058': 'NIPPOBATRY',
  '532612': 'INDOCO',
  '504092': 'INDOKEM',
  '543769': 'INDONG',
  '541336': 'INDOSTAR',
  '532894': 'INDOWIND',
  '512408': 'GANHOLD',
  '539175': 'INDRAIND',
  '532514': 'IGL',
  '532150': 'INDRAMEDCO',
  '526445': 'INDRANIB',
  '522165': 'INDSILHYD',
  '532001': 'INDCTST',
  '531841': 'INDUSFINL',
  '534816': 'INDUSTOWER',
  '532187': 'INDUSINDBK',
  '501298': 'INDPRUD',
  '501295': 'IITL',
  '512025': 'SALSTEEL',
  '539807': 'INFIBEAM',
  '541083': 'INFLAME',
  '532777': 'NAUKRI',
  '543644': 'INFOBEAN',
  '509069': 'INFOMEDIA',
  '504810': 'INFORTEC',
  '500209': 'INFY',
  '537985': 'INFRONICS',
  '500210': 'INGERRAND',
  '530787': 'INLANPR',
  '531929': 'INNOCORP',
  '543905': 'INNOKAIZ',
  '544067': 'NGIND',
  '533315': 'INOVSYNTH',
  '541983': 'INNOVATIVE',
  '523840': 'INNOVTEC',
  '541353': 'INNOVATORS',
  '543951': 'INNOVATUS',
  '543667': 'INOXGREEN',
  '544046': 'INOXWIND',
  '543297': 'IWEL',
  '539083': 'INOXWIND',
  '532851': 'INSECTICID',
  '543620': 'INA',
  '532774': 'INSPIRISYS',
  '526871': 'INTECCAP',
  '531314': 'INTCAPL',
  '505358': 'INTEGRAEN',
  '535958': 'ESSENTIA',
  '517423': 'INTEGSW',
  '539149': 'ICSL',
  '532303': 'INTEGHIT',
  '531889': 'IIL',
  '519606': 'IIL',
  '538835': 'INTELLECT',
  '544005': 'TVSSCS',
  '532326': 'INTENTECH',
  '511391': 'INTRGLB',
  '530259': 'INTSTOIL',
  '539692': 'IFINSER',
  '539448': 'INDIGO',
  '541300': 'CAPITALT',
  '505737': 'INTLCOMBQ',
  '509709': 'INTLCONV',
  '517044': 'IDM',
  '500213': 'ITHL',
  '533181': 'ISFT',
  '533506': 'INVENTURE',
  '533172': 'NAM-INDIA',
  '504786': 'INVPRECQ',
  '530023': 'THEINVEST',
  '511716': 'INVIGO',
  '524164': 'IOLCP',
  '500214': 'IONEXCHANG',
  '523638': 'IPRINGLTD',
  '524494': 'IPCALAB',
  '532947': 'IRB',
  '540526': 'IRB',
  '541956': 'IRCON',
  '540735': 'IRIS',
  '544004': 'IRMENERGY',
  '508918': 'IRONWOOD',
  '526859': 'ISFL',
  '533033': 'ISGEC',
  '540134': 'IISL',
  '531109': 'ISHANCH',
  '524400': 'ISHITADR',
  '506161': 'ISHWATR',
  '511609': 'ISLCONSUL',
  '508807': 'ISTLTD',
  '524622': 'ISTRNETWK',
  '500875': 'ITC',
  '543806': 'ITCONS',
  '509496': 'ITDCEM',
  '523610': 'ITI',
  '522183': 'ITL',
  '507580': 'IVP',
  '522245': 'IYKOTHITE',
  '890200': 'IYKOTHITE',
  '532341': 'IZMO',
  '538539': 'JTAPARIA',
  '543860': 'JAFINANCE',
  '506943': 'JBCHEPHARM',
  '544138': 'GARODCH',
  '523062': 'DBCORP',
  '532644': 'JKCEMENT',
  '532940': 'JKIL',
  '538422': 'JACKSON',
  '530711': 'JAGANLAM',
  '507155': 'JAGAJITIND',
  '532825': 'JAGJANANI',
  '532705': 'JAGRAN',
  '530601': 'JAGSONFI',
  '507789': 'JAGSNPHARM',
  '532976': 'JAIBALAJI',
  '512237': 'JAICORPLTD',
  '523467': 'JAIMATAG',
  '514312': 'JAIHINDS',
  '500219': 'JISLJALEQS',
  '570004': 'JISLJALEQS',
  '539119': 'JAINMARMO',
  '526865': 'JAINCO',
  '505212': 'JAINEX',
  '505840': 'JAIPAN',
  '532532': 'JPASSOCIAT',
  '532627': 'JPPOWER',
  '538564': 'JAMESWARREN',
  '532209': 'JKBANK',
  '520051': 'JAMNAAUTO',
  '502901': 'JAMSHRI',
  '544118': 'ESAFSFB',
  '542924': 'DBCORP',
  '544112': 'BGIL',
  '500220': 'NGIND',
  '514318': 'JATTAINDUS',
  '526001': 'JAUSPOL',
  '520066': 'JAYBARMARU',
  '544160': 'JAYUSH',
  '509715': 'JAYSHREETEA',
  '513252': 'JAYUSH',
  '501311': 'JAYBHCR',
  '524330': 'JAYAGROGN',
  '543544': 'JAYAGROGN',
  '522285': 'JAYNECOIND',
  '539005': 'JAYATMA',
  '531323': 'JAYIND',
  '500306': 'JAYKAY',
  '506520': 'JAYCH',
  '524592': 'JDORGOCHEM',
  '532605': 'JBMA',
  '513012': 'SOLIMAC',
  '538837': 'JSTL',
  '524731': 'JENBURPH',
  '532617': 'JETAIRWAYS',
  '543420': 'JETFREIGHT',
  '538794': 'JETINFRA',
  '517063': 'JETKINGQ',
  '543286': 'JETMALL',
  '540850': 'JFL',
  '531550': 'JHACC',
  '532771': 'JHS',
  '544197': 'JHS',
  '540651': 'JIGAR',
  '530405': 'JINDCAP',
  '511034': 'JINDRILL',
  '507981': 'JINDHOT',
  '539947': 'JLL',
  '532624': 'JINDALPHOT',
  '500227': 'JINDALPOLY',
  '536773': 'JPOLYINVST',
  '500378': 'JINDALSAW',
  '532508': 'JSL',
  '532286': 'JINDALSTEL',
  '531543': 'JINDWORLD',
  '543940': 'JIOFIN',
  '540311': 'JITFINFRA',
  '536493': 'JKPAPER',
  '500380': 'JKLAKSHMI',
  '532162': 'JKPAPER',
  '530007': 'JKTYRE',
  '538765': 'JSHL',
  '523405': 'JMFINANCIL',
  '511092': 'JMDVL',
  '523712': 'DBCORP',
  '538834': 'JMJFIN',
  '544167': 'DHINDIA',
  '500147': 'COCKERILL',
  '523398': 'JCHAC',
  '532154': 'JOHNPHARMA',
  '531861': 'JOINDRE',
  '534659': 'JOINTECAED',
  '542446': 'JONJUA',
  '505750': 'JOSTS',
  '504080': 'JSLINDL',
  '533148': 'JSWENERGY',
  '532642': 'JSWHL',
  '543994': 'JSWINFRA',
  '500228': 'JSWSTEEL',
  '520057': 'JTEKTINDIA',
  '534600': 'JTLIND',
  '533155': 'JUBLFOOD',
  '533320': 'JUBLINDS',
  '543271': 'JUBLINGREA',
  '530019': 'JUBLPHARMA',
  '536073': 'JULIEN',
  '516078': 'JUMBO',
  '511060': 'JUMBO',
  '544129': 'UPHOT',
  '507987': 'JPTRLES',
  '534623': 'JUPITERIN',
  '543980': 'JLHL',
  '533272': 'JWL',
  '535648': 'JUSTDIAL',
  '542544': 'ACML',
  '531537': 'ABHIINFRA',
  '532926': 'JYOTHYLAB',
  '544081': 'JYOTI',
  '504076': 'JYOTI',
  '514448': 'JYOTIRES',
  '513250': 'JYOTISTRUC',
  '539246': 'SVJ',
  '532673': 'KMSUGAR',
  '539686': 'KPEL',
  '532889': 'KPRMILL',
  '511728': 'KZLFIN',
  '514360': 'KRRAIL',
  '540756': 'KAARYAFSL',
  '539393': 'KABRAEXTRU',
  '524322': 'KABRAEXTRU',
  '524109': 'KABRAEXTRU',
  '524675': 'KABSON',
  '531778': 'KACHCHH',
  '543979': 'KAHAN',
  '504840': 'KAIRA',
  '506122': 'AICHAMP',
  '531780': 'KACL',
  '538833': 'KAIZENAGRO',
  '512147': 'ZSATYASL',
  '500233': 'KAJARIACER',
  '543939': 'KAKA',
  '500234': 'KAKATCEM',
  '521054': 'KAKTEX',
  '530201': 'KALLAM',
  '522287': 'KPIL',
  '538778': 'KALYANCAP',
  '543278': 'KALYANKJIL',
  '544023': 'KALYANI',
  '513509': 'KALYANIFRG',
  '533302': 'KICL',
  '500235': 'KSL',
  '532468': 'KAMAHOLD',
  '514322': 'KAMADGIRI',
  '511131': 'KAMANWALA',
  '526668': 'KAMATHOTEL',
  '532741': 'KAMDHENU',
  '543747': 'KAMOPAINTS',
  '506184': 'KANANIIND',
  '538896': 'KANCHI',
  '541005': 'KANCOTEA',
  '521242': 'KANDAGIRI',
  '500236': 'KANELIND',
  '513456': 'KANSHST',
  '506525': 'KANORICHEM',
  '539620': 'KANORICHEM',
  '507779': 'KANPRPLA',
  '500165': 'KANSAINER',
  '540515': 'KANUNGO',
  '512036': 'KAPILCO',
  '539679': 'KAPILRAJ',
  '533451': 'KARMAENG',
  '532652': 'KTKBANK',
  '538928': 'KARNAVATI',
  '537784': 'KCSL',
  '501151': 'KARTKIN',
  '590003': 'KARURVYSYA',
  '531960': 'KASHYAP',
  '502933': 'KATRSPG',
  '532925': 'KAUSHALYA',
  '532899': 'KSCL',
  '590041': 'KAVVERITEL',
  '530255': 'KAYPOWR',
  '539276': 'KAYA',
  '504084': 'KAYCEEI',
  '543664': 'KAYNES',
  '541161': 'KBCGLOBAL',
  '530357': 'KBSINDIA',
  '540696': 'KCDGROUP',
  '531784': 'KCLINFRA',
  '590066': 'KCP',
  '533192': 'DBCORP',
  '532054': 'KDDL',
  '532714': 'KEC',
  '508993': 'TVOLCON',
  '518011': 'KEERTHI',
  '517569': 'KEI',
  '519602': 'KELLTONTEC',
  '506528': 'KELENRG',
  '531163': 'KEMISTAR',
  '506530': 'KEMP',
  '530547': 'KENFIN',
  '505890': 'KENNAMET',
  '540953': 'KENVI',
  '530163': 'KERALAYUR',
  '532686': 'KERNEX',
  '507180': 'KESARENT',
  '543542': 'KESAR',
  '524174': 'KESARPE',
  '533289': 'KTIL',
  '502937': 'KESORAMIND',
  '532732': 'KKCL',
  '507948': 'KEYCORP',
  '512597': 'KEYFINSER',
  '543669': 'RUSTOMJEE',
  '543720': 'KFINTECH',
  '500239': 'KGDENIM',
  '531609': 'KGPETRO',
  '540775': 'KHADIM',
  '590068': 'KHAITANLTD',
  '507794': 'KHAICHEM',
  '519064': 'ZKHANDEN',
  '531892': 'KHANDSE',
  '543953': 'KHAZANCHI',
  '539788': 'KDML',
  '535730': 'KHOOBSURAT',
  '531692': 'UNISTRMU',
  '513693': 'KAJARIR',
  '507946': 'KIDUJA',
  '535566': 'KIFS',
  '522101': 'KLBRENG-B',
  '524500': 'KILITCH',
  '530313': 'KIMIABL',
  '500240': 'KINETICENG',
  '531274': 'KINETRU',
  '524019': 'KINGFA',
  '530215': 'KINGSINFR',
  '540680': 'KIOCL',
  '531413': 'KIRANPR',
  '530443': 'KIRANSY-B',
  '537750': 'KIRANVYPAR',
  '532967': 'KIRIINDUS',
  '500241': 'KIRLOSBROS',
  '533193': 'KECL',
  '500245': 'KIRLFER',
  '500243': 'KIRLOSIND',
  '533293': 'KIRLOSENG',
  '505283': 'KIRLPNU',
  '519230': 'NGIND',
  '530145': 'KISAN',
  '521248': 'KITEX',
  '544221': 'SPAL',
  '532304': 'KJMCCORP',
  '530235': 'KJMCFIN',
  '544013': 'KKSHL',
  '526409': 'KKALPANAIND',
  '523652': 'KKPLASTICK',
  '521238': 'KDL',
  '524520': 'KMCSHIL',
  '531578': 'KMFBLDR',
  '519415': 'KMGMILK',
  '540468': 'KMSMEDI',
  '543273': 'KMEW',
  '532942': 'KNRCON',
  '531541': 'KOBO',
  '512559': 'KOHINOOR',
  '523207': 'KOKUYOCMLN',
  '532924': 'KOLTEPATIL',
  '514128': 'KONARKSY',
  '532397': 'KONNDOR',
  '524280': 'KOPRAN',
  '500458': 'KORE',
  '500247': 'KOTAKBANK',
  '507474': 'KFBL',
  '509732': 'DBCORP',
  '530299': 'KOTHARIPRO',
  '539599': 'KEL',
  '544139': 'DIACABS',
  '523323': 'KOVAI',
  '505585': 'SYMBIOX',
  '507598': 'KLRFM',
  '544150': 'GGENG',
  '542323': 'KPIGREEN',
  '542651': 'KPITTECH',
  '505299': 'KPT',
  '542459': 'KRANTI',
  '501261': 'KPEL',
  '530813': 'KRBL',
  '524518': 'KREBSBIO',
  '530139': 'KREONFIN',
  '531328': 'KRETTOSYS',
  '533482': 'KRIINFRA',
  '513369': 'KVFORGE',
  '539384': 'KRISHNACAP',
  '500248': 'KRIFILIND',
  '543308': 'KIMS',
  '504392': 'KRISHNA',
  '526423': 'KRITIIND',
  '533210': 'KRITINUT',
  '544187': 'PARNAXLAB',
  '543328': 'KRSNAA',
  '523550': 'KRYPTONQ',
  '544149': 'ICSL',
  '500249': 'KSB',
  '519421': 'KSE',
  '503626': 'UPSURGE',
  '530149': 'NGIND',
  '543599': 'KSOLVES',
  '532937': 'KUANTUM',
  '539408': 'KUBERJI',
  '543289': 'TIGLOB',
  '526869': 'KUMPFIN',
  '523594': 'KUNSTOFF',
  '514240': 'KUSHIND',
  '511048': 'INDNIPPON',
  '530421': 'KUWERIN',
  '539997': 'KPL',
  '500250': 'LGBBROSLTD',
  '533519': 'JAFINANCE',
  '540115': 'LTTS',
  '526947': 'LAOPALA',
  '505693': 'LATIMMETAL',
  '543830': 'LABELKRAFT',
  '524202': 'LACTOSE',
  '540026': 'LAHL',
  '530577': 'LADDERUP',
  '524522': 'LAFFANSQ',
  '531842': 'LAHOTIOV',
  '535387': 'LAKHOTIA',
  '505302': 'LXMIATO',
  '504258': 'LAKSELEC',
  '500252': 'LAXMIMACH',
  '502958': 'LAKSHMIMIL',
  '506079': 'LAKPRE',
  '590075': 'LAMBODHARA',
  '539841': 'LANCER',
  '509048': 'LANCORHOL',
  '543714': 'LANDMARK',
  '533012': 'LPDC',
  '500510': 'LT',
  '540702': 'LASA',
  '526961': 'LASTMILE',
  '543398': 'LATENTVIEW',
  '540222': 'LAURUSLABS',
  '543277': 'LXCHEM',
  '537669': 'LAXMIPATI',
  '532019': 'LCCINFOTEC',
  '539814': 'LELAVOIR',
  '544192': 'LELAVOIR',
  '531288': 'LEADFIN',
  '540360': 'LLFICL',
  '517415': 'LEENEE',
  '532829': 'LEHAR',
  '541233': 'LEMONTREE',
  '502250': 'LERTHAI',
  '533602': 'LESHAIND',
  '541196': 'LEX',
  '531944': 'NGIND',
  '533007': 'LGBFORGE',
  '526596': 'LIBERTSHOE',
  '511593': 'LIBORDFIN',
  '531027': 'LIBORD',
  '500253': 'LICHSGFIN',
  '543526': 'LICI',
  '539927': 'LIKHAMI',
  '543240': 'LIKHITHA',
  '507759': 'LIMECHM',
  '517463': 'NGIND',
  '531241': 'LINC',
  '531633': 'LINCOPH',
  '523457': 'LINDEINDIA',
  '524748': 'LINKPH',
  '526604': 'LIPPISYS',
  '507912': 'LKPFIN',
  '540192': 'LKPSEC',
  '539992': 'LLOYDSENGG',
  '512463': 'LLOYDSENT',
  '512455': 'LLOYDSME',
  '543746': 'ELIL',
  '532740': 'LOKESHMACH',
  '504340': 'FLEXITUFF',
  '526568': 'LONTE',
  '534422': 'LOOKS',
  '500284': 'LORDSCHLO',
  '530065': 'LORDSHOTL',
  '540952': 'LAL',
  '523475': 'LOTUSCHO',
  '532998': 'LEHIL',
  '533343': 'LOVABLE',
  '539227': 'LOYAL',
  '514036': 'LOYALTEX',
  '514446': 'NGIND',
  '532783': 'LTFOODS',
  '540005': 'LTIM',
  '539682': 'NGIND',
  '526179': 'LUDLOWJUT',
  '512048': 'LUHARUKA',
  '750886': 'LUHARUKA',
  '532796': 'LUMAXTECH',
  '517206': 'LUMAXIND',
  '500257': 'LUPIN',
  '539542': 'LUXIND',
  '531402': 'LWSKNIT',
  '500259': 'LYKALABS',
  '530689': 'LYKISLTD',
  '505320': 'LYNMC',
  '534532': 'LYPSAGEMS',
  '543919': 'MKPL',
  '512153': 'MLINDLTD',
  '522241': 'MMFL',
  '538890': 'MKEXIM',
  '509196': 'MMRUBBR-B',
  '506543': 'MPAGI',
  '543624': 'MAAGHADV',
  '532906': 'MAANALU',
  '507836': 'MCCHRLS-B',
  '541973': 'MACH',
  '543787': 'ROBU',
  '543934': 'MACIND',
  '523248': 'MACPLASQ',
  '543287': 'LODHA',
  '539894': 'MADHAVIPL',
  '515093': 'MADHAV',
  '531497': 'MADHUCON',
  '519279': 'MADHURIND',
  '515059': 'MADHUDIN',
  '511000': 'MADHUSE',
  '531910': 'MADHUVEER',
  '590134': 'MADRASFERT',
  '538401': 'METSL',
  '500264': 'MAFATIND',
  '543613': 'MAFIA',
  '540650': 'MAGADHSUGAR',
  '538891': 'MCLOUD',
  '544188': 'VIKASLIFE',
  '517449': 'MAGNAELQ',
  '512377': 'ENBETRD',
  '532896': 'MAGNUM',
  '517320': 'SUNRETAIL',
  '523384': 'MAHAPEXLTD',
  '519612': 'MAHAANF',
  '514450': 'MHLXMIRU',
  '513460': 'MAHALXSE',
  '513554': 'MAHASTEEL',
  '531515': 'MAHANIN',
  '539957': 'MGL',
  '500108': 'MTNL',
  '505523': 'DBCORP',
  '500266': 'MAHSCOOTER',
  '500265': 'MAHSEAMLES',
  '526795': 'MHSGRMS',
  '539383': 'MINFY',
  '532720': 'MMFIN',
  '500520': 'MMFIN',
  '523754': 'MAHEPC',
  '533088': 'MHRIL',
  '532313': 'MAHLIFE',
  '540768': 'MAHLOG',
  '543874': 'MAIDEN',
  '590078': 'MAITHANALL',
  '513430': 'MAITRI',
  '500267': 'MAJESAUT',
  '506919': 'MAKERSL',
  '539400': 'MALLCOM',
  '532728': 'MALUPAPER',
  '513269': 'MANINDS',
  '533169': 'MANINFRA',
  '539045': 'MNKALCOLTD',
  '539046': 'MNKCMILTD',
  '532932': 'MANAKSIA',
  '539044': 'MANAKSTELTD',
  '500268': 'MANALIPETC',
  '531213': 'MANAPPURAM',
  '540402': 'SHRIRAMPPS',
  '512595': 'MANBRO',
  '505850': 'MANCREDIT',
  '502157': 'MANGLMCEM',
  '532637': 'MANGALAM',
  '537800': 'MANGIND',
  '514418': 'MANORG',
  '539275': 'MSL',
  '530011': 'MANGCHEFER',
  '500109': 'MRPL',
  '530243': 'MEGRISOFT',
  '507938': 'DBCORP',
  '533078': 'MANJEERA',
  '543904': 'MANKIND',
  '544073': 'MVGJL',
  '543995': 'MVGJL',
  '540396': 'MANOMAY',
  '526115': 'KARANWO',
  '541974': 'MANORAMA',
  '530537': 'MANRAJH',
  '511758': 'MANSIFIN',
  '512303': 'ZMANSOON',
  '505324': 'MANUGRAPH',
  '521018': 'MARALOVER',
  '503101': 'MARATHON',
  '531281': 'MARBLE',
  '540254': 'MTPL',
  '500206': 'MARGOFIN',
  '531642': 'MARICO',
  '531503': 'MARIS',
  '526891': 'MKTCREAT',
  '514060': 'KRISHNA',
  '543364': 'MARKOLINES',
  '524404': 'MARKSANS',
  '517467': 'MARSONS',
  '523566': 'MARBU',
  '531540': 'MAINFRA',
  '543464': 'SPITZE',
  '531319': 'RRSECUR',
  '532500': 'MARUTI',
  '540749': 'MASFIN',
  '523704': 'MASTEK',
  '511768': 'MASTERTR',
  '511688': 'MATHEWE',
  '540704': 'MATRIMONY',
  '539219': 'MUL',
  '523371': 'MAWANASUG',
  '544008': 'MAXESTATES',
  '500271': 'MFSL',
  '543220': 'MAXHEALTH',
  '543223': 'MFSL',
  '534338': 'NAGREEKCAP',
  '540401': 'MAXIMUS',
  '544106': 'MCFL',
  '539519': 'MAYUKH',
  '531221': 'MAYURFL',
  '522249': 'MAYURUNIQ',
  '543237': 'MAZDOCK',
  '523792': 'MAZDALTD',
  '533152': 'MBLINFRA',
  '532654': 'MCLEODRUSS',
  '532629': 'GGENG',
  '544088': 'MEDICAPQ',
  '523144': 'MEDICAPQ',
  '512267': 'MMWL',
  '503685': 'MEDIAONE',
  '531146': 'MEDICAMEQ',
  '539938': 'MIL',
  '540937': 'MEDICO',
  '526301': 'MEDINOV',
  '543427': 'MEDPLUS',
  '512505': 'SALSTEEL',
  '540519': 'MEERA',
  '531176': 'MEFCOMCAP',
  '531417': 'DBCORP',
  '539767': 'MNIL',
  '532408': 'MEGASOFT',
  '541352': 'MEGASTAR',
  '509003': 'MAYURUNIQ',
  '543331': 'MOL',
  '538668': 'NEOINFRA',
  '539012': 'MEGRISOFT',
  '540730': 'MEHAI',
  '511740': 'MEHTAHG',
  '511377': 'MEHIF',
  '511738': 'MEHSECU',
  '531127': 'MENAMANI',
  '523828': 'MENONBE',
  '531727': 'MENNPIS',
  '539126': 'MEP',
  '538942': 'MERCANTILE',
  '531357': 'MERCURYEV',
  '538964': 'MERCURYLAB',
  '512415': 'CTL',
  '543982': 'MESON',
  '531810': 'METALCO',
  '543426': 'METROBRAND',
  '500159': 'METROGLOBL',
  '542650': 'METROPOLIS',
  '531613': 'MAL',
  '526622': 'MFLINDIA',
  '513721': 'MFSINTRCRP',
  '532850': 'MIC',
  '523343': 'DHINDIA',
  '500277': 'MIDINDIA',
  '526251': 'DYNAMICP',
  '526570': 'MIDWEST',
  '538895': 'MIHIKA',
  '541337': 'MILEFUR',
  '531338': 'MILESTONE',
  '511018': 'ZMILGFIN',
  '507621': 'MLKFOOD',
  '511187': 'MILLENNIUM',
  '522235': 'MINALIND',
  '531456': 'MINAXI',
  '538962': 'DBCORP',
  '543217': 'MINDSPACE',
  '517344': 'MINDTECK',
  '523373': 'MINID',
  '532164': 'MINOLTAF',
  '505797': 'MPCOSEMB',
  '500279': 'MIRCELECTR',
  '526642': 'MIRZAINT',
  '544015': 'MISHDESIGN',
  '539220': 'MISHKA',
  '541195': 'MIDHANI',
  '539594': 'MISHTANN',
  '542801': 'MISQUITA',
  '523782': 'MITSHI',
  '540078': 'MITSU',
  '522036': 'MIVENMACH',
  '521244': 'ARE&M',
  '514238': 'MKVENTURES',
  '513377': 'MMTC',
  '503772': 'MODWOOL',
  '519287': 'MODAIRY',
  '539762': 'MEAPL',
  '515008': 'MODINSU',
  '509760': 'MODRNSH',
  '513303': 'MDRNSTL',
  '500282': 'MDRNSTL',
  '519003': 'MODINATUR',
  '500890': 'MODIRUBBER',
  '543539': 'MODIS',
  '503776': 'MODIPON',
  '506261': 'MODISONLTD',
  '504273': 'MODULEX',
  '531453': 'MOHITIND',
  '530169': 'MOHITPPR',
  '532140': 'MOHITE',
  '533286': 'MOIL',
  '526263': 'MOLDTEK',
  '533080': 'BAPACK',
  '511551': 'MONARCH',
  '535910': 'MMLF',
  '538446': 'MONEYBOXX',
  '532078': 'MONIND',
  '505343': 'MONOT',
  '538836': 'MONTECARLO',
  '530167': 'MONGIPA',
  '532621': 'MORARJEE',
  '511549': 'MORARKFI',
  '500288': 'MOREPENLAB',
  '526237': 'MORGAN',
  '523160': 'MORGANITE',
  '532407': 'MOSCHIP',
  '543498': 'MSUMI',
  '532892': 'MOTILALOFS',
  '544053': 'KHAZANCHI',
  '501343': 'MOTOGENFIN',
  '542864': 'NAGREEKCAP',
  '532723': 'MPDL',
  '532470': 'SBECSYS',
  '526299': 'MPHASIS',
  '500450': 'MPILCORPL',
  '526143': 'MPL',
  '532440': 'MPSLTD',
  '540809': 'MRCAGRO',
  '500290': 'MRF',
  '532376': 'MRO-TEK',
  '543262': 'MRP',
  '543253': 'BECTORFOOD',
  '512065': 'GDTRAGN',
  '532650': 'MSPL',
  '508922': 'MSRINDIA',
  '542597': 'MSTC',
  '534312': 'MTEDUCARE',
  '543270': 'MTARTECH',
  '539819': 'KENFIN',
  '538743': 'NGIND',
  '542774': 'MUFIN',
  '500460': 'MUKANDLTD',
  '523832': 'MUKATPIP',
  '530341': 'MUKESHB',
  '544135': 'MKPL',
  '535204': 'MUKTA',
  '532357': 'MUKTAARTS',
  '501477': 'MULLER',
  '534091': 'MCX',
  '526169': 'MULTIBASE',
  '505594': 'GANHOLD',
  '504356': 'ZMULTIPU',
  '520059': 'MUNJALAU',
  '520043': 'MUNJALSHOW',
  '511200': 'MUNCAPM',
  '511401': 'MCLTD',
  '531821': 'MUNOTHFI',
  '542724': 'NGIND',
  '515037': 'MURUDCERA',
  '540366': 'RADIOCITY',
  '717504': 'RADIOCITY',
  '511766': 'MUTHTFN',
  '533398': 'MUTHOOTFIN',
  '544055': 'MUTHOOTFIN',
  '538862': 'MYMONEY',
  '506734': 'MYSORPETRO',
  '535205': 'MYSTICELE',
  '530897': 'NGIND',
  '519494': 'NGIND',
  '516082': 'NRAGRINDQ',
  '543351': 'NGIND',
  '524709': 'NACLIND',
  '531832': 'NAGTECH',
  '539917': 'NAGARFERT',
  '532362': 'NAGPI',
  '532895': 'NAGREEKCAP',
  '521109': 'NAGREEKEXP',
  '532952': 'NAHARCAP',
  '519136': 'NAHARINDUS',
  '523391': 'NAHARPOLY',
  '500296': 'NAHARSPING',
  '541418': 'NGIL',
  '539402': 'NAKSH',
  '531212': 'NLFL',
  '532256': 'NSIL',
  '538395': 'NAM',
  '543522': 'NVENTURES',
  '532641': 'NDL',
  '500298': 'JACKSON',
  '531457': 'KMSUGAR',
  '539551': 'NH',
  '519455': 'NARBADA',
  '531416': 'NARPROP',
  '543643': 'NARMADA',
  '526739': 'SHAWGELTIN',
  '517431': 'NARMP',
  '524816': 'NATCOPHARM',
  '537291': 'NATHBIOGEN',
  '502587': 'NATHIND',
  '532234': 'NATIONALUM',
  '523630': 'NFL',
  '531289': 'NATFIT',
  '531651': 'NATGENI',
  '543385': 'CAPITALT',
  '507813': 'NOL',
  '544205': 'NATPEROX',
  '526616': 'NATPLAS',
  '531287': 'NATPLASTI',
  '516062': 'NATPLY',
  '504882': 'NATIONSTD',
  '530119': 'NATRAJPR',
  '531834': 'NATHUEC',
  '543207': 'NATURAL',
  '524654': 'NATCAPSUQ',
  '538926': 'NAPL',
  '543579': 'NATURO',
  '513023': 'NAVA',
  '543987': 'NAVINIFTY',
  '539521': 'NAVIGANT',
  '532504': 'NAVINFLUOR',
  '539332': 'DBCORP',
  '531494': 'NAVKAR',
  '508989': 'NAVNETEDUL',
  '543305': 'NAVODAYENT',
  '543280': 'NAZARA',
  '523242': 'NBFOOT',
  '534309': 'NBCC',
  '519506': 'NCCBLUE',
  '500294': 'NCC',
  '502168': 'NCLIND',
  '530557': 'NCLRESE',
  '511535': 'NDASEC',
  '500189': 'NDLVENTURE',
  '543214': 'NDRAUTO',
  '532649': 'NECLIFE',
  '508670': 'NEAGI',
  '512565': 'NEELKANTH',
  '531049': 'NEELKAN',
  '539409': 'NEERAJ',
  '539016': 'NEIL',
  '532864': 'NELCAST',
  '504112': 'NELCO',
  '514332': 'NEOINFRA',
  '542665': 'NEOGEN',
  '505355': 'NESCO',
  '500790': 'DHINDIA',
  '543247': 'PIXTRANS',
  '509040': 'NETLINK',
  '511658': 'NETTLINX',
  '543945': 'NETWEB',
  '532798': 'MADHUVEER',
  '532887': 'INDUSTOWER',
  '524558': 'NEULANDLAB',
  '532529': 'NDTV',
  '540769': 'NIACL',
  '540243': 'NEWLIGHT',
  '544082': 'SWANENERGY',
  '540900': 'NEWGEN',
  '531959': 'NEWINFRA',
  '532416': 'NEXTMEDIA',
  '543913': 'NXST',
  '538874': 'NEXUSSURGL',
  '524774': 'NGLFINE',
  '517554': 'NHCFOODS',
  '533098': 'NHPC',
  '535136': 'NIBE',
  '512091': 'NIBE',
  '526721': 'NICCOPAR',
  '512103': 'NIDHGRN',
  '531083': 'NIHARINF',
  '543952': 'NIITMTS',
  '500304': 'NIITLTD',
  '526159': 'NIKHILAD',
  '531272': 'NIKKIGL',
  '543282': 'LTTS',
  '530377': 'NILA',
  '542231': 'NILASPACES',
  '502294': 'NILACHAL',
  '530129': 'NILE',
  '523385': 'NILKAMAL',
  '512004': 'GGENG',
  '511714': 'NIMBSPROJ',
  '539843': 'NINSYS',
  '540767': 'NAM-INDIA',
  '532986': 'NIRAJ',
  '512425': 'NIRAVCOM',
  '526349': 'DHINDIA',
  '500307': 'NIRLON',
  '543194': 'DHINDIA',
  '532722': 'NITCO',
  '508875': 'NITINCAST',
  '532698': 'NITINSPIN',
  '506532': 'NITTAGELA',
  '542206': 'NIVAKA',
  '512245': 'GDTRAGN',
  '538772': 'NIYOGIN',
  '513683': 'NLCINDIA',
  '526371': 'NMDC',
  '543768': 'NSLNISP',
  '522289': 'NMSRESRC',
  '500730': 'NOCIL',
  '532481': 'NOIDATOLL',
  '519528': 'NORBTEAEXP',
  '524414': 'NORRIS',
  '534615': 'NECCLTD',
  '542628': 'NSL',
  '539110': 'NORTHLINK',
  '531465': 'NOUVEAU',
  '544100': 'DHANUKA',
  '513566': 'NOVIS',
  '500672': 'NOVARTIND',
  '542771': 'NOVATEOR',
  '530127': 'NPRFIN',
  '530367': 'NRBBEARING',
  '535458': 'NIBL',
  '526723': 'NTCIND',
  '532555': 'NTPC',
  '531209': 'NUCLEUS',
  '543264': 'NURECA',
  '531304': 'NUTECGLOB',
  '530219': 'NGIND',
  '543988': 'NUVAMA',
  '543334': 'NUVOCO',
  '531819': 'NUWAY',
  '504378': 'NYSSACORP',
  '539116': 'OPCHAINS',
  '512489': 'OASISEC',
  '533273': 'OBEROIRLTY',
  '535657': 'OONE',
  '540405': 'SPECFOOD',
  '538894': 'OCTAL',
  '542938': 'OCTAVIUSPL',
  '540416': 'OCTAWARE',
  '531996': 'DBCORP',
  '530175': 'ODYSSEY',
  '500312': 'ONGC',
  '500313': 'OILCOUNTUB',
  '533106': 'OIL',
  '526415': 'OKPLA',
  '544225': 'ORIENTELEC',
  '543578': 'OLATECH',
  '532439': 'OLECTRA',
  '521105': 'OLYMPTX',
  '534190': 'OLPCL',
  '507609': 'OLYOI',
  '531092': 'OMINFRAL',
  '520021': 'OMAXAUTO',
  '532880': 'OMAXE',
  '511644': 'OMEGAIN',
  '531496': 'OMKARCHEM',
  '532167': 'OMKARCHEM',
  '533317': 'OMKARCHEM',
  '532340': 'OMNIAX',
  '543400': 'OMNIPOTENT',
  '514324': 'OMNITEX',
  '543396': 'ANGELONE',
  '514330': 'ONEGLOBAL',
  '533632': 'ONELIFECAP',
  '530805': 'OIVL',
  '532944': 'ONMOBILE',
  '540386': 'ONTIC',
  '517536': 'ONWARDTEC',
  '530135': 'OPTIEMUS',
  '531254': 'OPTIFIN',
  '532466': 'OFSS',
  '512626': 'ORBTEXP',
  '532271': 'ORCHASP',
  '524372': 'ORCHIDPHAR',
  '531157': 'ORGCOAT',
  '543997': 'ORGANICREC',
  '513121': 'ORICON',
  '530365': 'ORIENTBELL',
  '507690': 'ORIBEVER',
  '535754': 'ORIENTCEM',
  '504879': 'ORIENTCER',
  '541301': 'ORIENTELEC',
  '533263': 'GREENPOWER',
  '502420': 'ORIENTPPR',
  '526325': 'ORIENTLTD',
  '531512': 'ORIENTTR',
  '500078': 'OAL',
  '506579': 'ORIENTCQ',
  '500314': 'ORIENTHOT',
  '531859': 'ORIRAIL',
  '532817': 'ORIENTALTL',
  '541206': 'OBCL',
  '590086': 'ORISSAMINE',
  '531626': 'OROSMITHS',
  '539015': 'TATACOMM',
  '539287': 'ORTINLAABS',
  '530173': 'TIGLOB',
  '509782': 'OSEASPR',
  '540198': 'OSIAJEE',
  '500317': 'OSWALAGRO',
  '539290': 'OSWALGREEN',
  '509099': 'OSWALAGRO',
  '531065': 'IOB',
  '514460': 'WINSOME',
  '523151': 'OTCO',
  '530741': 'OVOBELE',
  '524636': 'OXYGENTAPH',
  '526747': 'PGFOILQ',
  '500143': 'PHCAP',
  '513403': 'PMTELELIN',
  '543637': 'PACE',
  '523862': 'MANGIND',
  '523483': 'PACIFICI',
  '531395': 'PADAMCO',
  '532350': 'PADMALAYAT',
  '531779': 'PADALPO',
  '526905': 'PADMAIND',
  '531396': 'WOMENNET',
  '532827': 'PAGEIND',
  '532900': 'PAISALO',
  '516030': 'PAKKA',
  '540648': 'PALASHSEC',
  '539121': 'PALCO',
  '541444': 'PALMJEWELS',
  '532521': 'PALRED',
  '517397': 'PANELEC',
  '511525': 'PANINDIAC',
  '538742': 'PANABYTE',
  '531349': 'PANACEABIO',
  '538860': 'PANAFIC',
  '524820': 'PANAMAPET',
  '508941': 'PANCARBON',
  '504093': 'PANAENERG',
  '513511': 'PANCHMAHQ',
  '531726': 'PANCHSHEEL',
  '526345': 'PANJON',
  '531280': 'PANKAJPO',
  '539469': 'PANORAMA',
  '539143': 'PANTH',
  '500322': 'PANCM',
  '530291': 'PAOS',
  '543530': 'PARADEEP',
  '539889': 'PARAGMILK',
  '531255': 'PARAGONF',
  '530555': 'PARACABLES',
  '507970': 'PARMCOS-B',
  '543367': 'PARAS',
  '521246': 'PARASPETRO',
  '524628': 'PARKERAC',
  '532911': 'PARLEIND',
  '540359': 'PARMAX',
  '540467': 'PARMSILK',
  '506128': 'PARNAXLAB',
  '542694': 'PARSHVA',
  '511176': 'PARSHWANA',
  '532780': 'PARSVNATH',
  '541347': 'PARVATI',
  '521080': 'PASARI',
  '500456': 'PASUPTAC',
  '511734': 'COMFINCAP',
  '503092': 'PASUSPG',
  '500368': 'PATANJALI',
  '539401': 'PJL',
  '517417': 'PATELENG',
  '531120': 'PATELENG',
  '526381': 'PATINTLOG',
  '524031': 'PATIDAR',
  '543798': 'PATRON',
  '514326': 'PATSPINLTD',
  '539113': 'PML',
  '532742': 'PAUSHAKLTD',
  '543915': 'PAVNAIND',
  '543390': 'POLICYBZR',
  '532676': 'PBAINFRA',
  '514087': 'PBMPOLY',
  '534809': 'PCJEWELLER',
  '506590': 'PCBL',
  '517119': 'PCS',
  '538730': 'PDSL',
  '532808': 'PGIL',
  '543540': 'PGCRL',
  '523260': 'PEARLPOLY',
  '539273': 'PECOS',
  '524136': 'PCCOSMA',
  '531352': 'PEETISEC',
  '503031': 'PENINLAND',
  '513228': 'PENIND',
  '524210': 'PNTKYOR',
  '501144': 'JACKSON',
  '521062': 'OCTAVE',
  '526435': 'PERFEPA',
  '504132': 'PERMAGN',
  '533179': 'PERSISTENT',
  '517172': 'PCL',
  '524046': 'PRIMAPLA',
  '532522': 'PETRONET',
  '500680': 'PFIZER',
  '533581': 'PGEL',
  '512026': 'GDTRAGN',
  '523620': 'PHRMASI',
  '524572': 'PHARMAID',
  '526481': 'PHOENXINTL',
  '503100': 'PHOENIXLTD',
  '537839': 'PHOENIXTN',
  '542123': 'PHOSPHATE',
  '509084': 'PHOTON',
  '526588': 'PHOTOQUP',
  '524808': 'PHYTO',
  '523642': 'PIIND',
  '530305': 'PICCADIL',
  '507498': 'PICCASUG',
  '532355': 'PICTUREHS',
  '500331': 'PIDILITIND',
  '500327': 'PILITA',
  '539883': 'PILANIINVS',
  '519439': 'PIONAGR',
  '514300': 'PIONEEREMB',
  '507864': 'PIONRINV',
  '544178': 'NGIND',
  '500302': 'PEL',
  '543635': 'PPLPHARMA',
  '513519': 'PITTIENG',
  '500333': 'PIXTRANS',
  '523648': 'PLASTIBLEN',
  '544134': 'NGIND',
  '543352': 'POBS',
  '544003': 'PLAZACABLE',
  '534060': 'PMCFIN',
  '532366': 'PNBGILTS',
  '540173': 'PNBHOUSING',
  '539150': 'PNCINFRA',
  '543709': 'GARGI',
  '539195': 'POEL',
  '523628': 'PODDAR',
  '524570': 'PODARPIGQ',
  '532486': 'POKARNA',
  '526687': 'POLOHOT',
  '540717': 'PQIF',
  '507645': 'POLSON',
  '531768': 'POLYMED',
  '542652': 'POLYCAB',
  '506605': 'POLYCHEM',
  '531397': 'POLYCON',
  '531454': 'POLYLINK',
  '537573': 'POLYMAC',
  '526043': 'POLYCHMP',
  '524051': 'POLYPLEX',
  '539354': 'POLYSPIN',
  '532626': 'POCL',
  '532460': 'PONNIERODE',
  '540727': 'POOJA',
  '519359': 'POONADAL',
  '524000': 'POONAWALLA',
  '530565': 'NGIND',
  '531870': 'POPULARES',
  '544144': 'POPULARES',
  '532933': 'PORWAL',
  '543912': 'GIPCL',
  '532810': 'DBCORP',
  '532898': 'POWERGRID',
  '539302': 'POWERMECH',
  '543290': 'IITL',
  '532934': 'PPAP',
  '530361': 'PRABHHANS',
  '506042': 'ZPRBHSTE',
  '513532': 'PRADPME',
  '530095': 'PRADHIN',
  '500192': 'PRAGBOS',
  '522205': 'PRAJIND',
  '531746': 'PRAENG',
  '506022': 'PRAKASH',
  '542684': 'PPL',
  '533239': 'PRAKASHSTL',
  '531437': 'PWASML',
  '519014': 'PRSNTIN',
  '540724': 'DIAMONDYD',
  '526490': 'PRATIK',
  '531257': 'PRATIKSH',
  '531637': 'PRAVEG',
  '540901': 'PRAXIS',
  '539636': 'PRECAM',
  '517258': 'PRECISIO',
  '523539': 'PRECWIRE',
  '530331': 'PREMCO',
  '511016': 'PREMCAP',
  '526247': 'PREMEXPLQ',
  '500540': 'PREMIER',
  '514354': 'PREMIERPOL',
  '509835': 'PREMSYN',
  '715024': 'PREMSYN',
  '511660': 'MUNCAPM',
  '531802': 'PRERINFRA',
  '526773': 'PRESSURS',
  '533274': 'PRESTIGE',
  '543363': 'PREVEST',
  '540293': 'PRICOLLTD',
  '519262': 'PRIMAGR',
  '531246': 'PRIMAIN',
  '530589': 'PRIMAPLA',
  '535514': 'PRIMIND',
  '532748': 'PFOCUS',
  '540404': 'PRIMEFRESH',
  '519299': 'PRIMIND',
  '530695': 'PRIMEPRO',
  '500337': 'PRIMESECU',
  '521149': 'PRIMEURB',
  '506852': 'PRIMO',
  '542907': 'PRINCEPIPE',
  '531735': 'PRISMFN',
  '500338': 'PRSMJOHNSN',
  '512217': 'PRISMMEDI',
  '501314': 'PRISMX',
  '531688': 'PRITHVIEXCH',
  '539359': 'PRITIKAUTO',
  '532387': 'PNC',
  '530117': 'PRIVISCL',
  '524580': 'PRIYALT',
  '540703': 'PROCLB',
  '511557': 'PROFINC',
  '500126': 'PGHL',
  '500459': 'PGHH',
  '531265': 'PROGREXV',
  '526494': 'PROMACT',
  '543375': 'PROMAX',
  '543814': 'PCL',
  '544021': 'PROTEAN',
  '534675': 'PROZONER',
  '543527': 'PRUDENT',
  '500342': 'DBCORP',
  '505502': 'ITIETF',
  '540544': 'PSPPROJECT',
  '533344': 'PFS',
  '532524': 'PTC',
  '539006': 'PTCIL',
  '509220': 'PTL',
  '539785': 'PDMJEPAPER',
  '512591': 'PULSRIN',
  '512461': 'PUNCTRD',
  '544141': 'DBSTOCKBRO',
  '533295': 'PSB',
  '506618': 'PUNJABCHEM',
  '500346': 'PUNJCOMMU',
  '532461': 'PNB',
  '532891': 'PURVA',
  '523315': 'NGIND',
  '538993': 'TVOLCON',
  '540159': 'PURPLE',
  '544191': 'PURPLE',
  '538647': 'PURSHOTTAM',
  '531562': 'NGIND',
  '517556': 'PVP',
  '532689': 'PVRINOX',
  '536659': 'PVVINFRA',
  '543969': 'PYRAMID',
  '534109': 'SANCF',
  '538646': 'QGO',
  '511116': 'QUADRANT',
  '544091': 'LYKALABS',
  '543460': 'QRIL',
  '590099': 'QUANTBUILD',
  '590110': 'QNIFTY',
  '538596': 'QUANTBUILD',
  '530281': 'QUANTDIA',
  '538452': 'QUASAR',
  '539978': 'QUESS',
  '500069': 'QUESTCAP',
  '539678': 'QUICKHEAL',
  '539515': 'QUINT',
  '544136': 'NGIND',
  '543981': 'RRKABEL',
  '532735': 'RSYSTEMINT',
  '509845': 'RJSHAH',
  '533284': 'RPPINFRA',
  '511626': 'GKCONS',
  '530917': 'RRSECUR',
  '517447': 'RSSOFTWARE',
  '538119': 'RNBDENIMS',
  '524502': 'RAAJMEDI',
  '500357': 'RAMAPPR-B',
  '537785': 'RACE',
  '520073': 'RACLGEAR',
  '541703': 'NMSRESRC',
  '590070': 'RADAAN',
  '539673': 'RCL',
  '531273': 'RADHEDE',
  '540125': 'RADHIKAJWE',
  '543732': 'RADIANTCMS',
  '532497': 'RADICO',
  '531412': 'RADIXIND',
  '539837': 'RPEL',
  '526813': 'RAGHUNAT',
  '538921': 'RAFL',
  '514316': 'RAGHUSYN',
  '531887': 'RAHME',
  '540270': 'RAIDEEPIND',
  '542649': 'RVNL',
  '543265': 'RAILTEL',
  '500339': 'RAIN',
  '543524': 'RAINBOW',
  '531694': 'RAINBOWF',
  '533093': 'RAJOIL',
  '530111': 'RAJPACK',
  '530699': 'RAJRAYON',
  '532826': 'RAJTV',
  '503127': 'RAJABAH',
  '532503': 'RAJPALAYAM',
  '750752': 'RAJPALAYAM',
  '538707': 'RCCL',
  '526873': 'RAJGASES',
  '506975': 'RAJSPTR',
  '530253': 'RAJTUBE',
  '507962': 'JAFINANCE',
  '526662': 'ARENTERP',
  '531500': 'RAJESHEXPO',
  '543285': 'RCAN',
  '526823': 'NAGREEKCAP',
  '514028': 'RAJKSYN',
  '539495': 'RAJKOTINV',
  '530525': 'RAJNISH',
  '541601': 'RAJNISH',
  '522257': 'RAJOOENG',
  '539090': 'RAJPUTANA',
  '517522': 'RAJGLOWIR',
  '500354': 'RAJSREESUG',
  '511185': 'NGIND',
  '500355': 'RALLIS',
  '522281': 'RAMRAT',
  '500358': 'RAMAPETRO',
  '524037': 'RAMAPHO',
  '539309': 'RAMASTEEL',
  '523289': 'RAMAVISION',
  '515127': 'RAMASIGNS',
  '538540': 'RLFL',
  '500260': 'RAMCOCEM',
  '532369': 'RAMCOIND',
  '532370': 'RAMCOSYS',
  '514223': 'RAMGOPOLY',
  '530951': 'RAMINFO',
  '532527': 'RKFORGE',
  '533262': 'RAMKY',
  '530925': 'RAMSONS',
  '507490': 'RANASUG',
  '531228': 'RANDER',
  '532661': 'RML',
  '532987': 'RBL',
  '532988': 'RANEENGINE',
  '505800': 'RANEHOLDIN',
  '541945': 'RANJEET',
  '531583': 'RAP',
  '500360': 'RAPICUT',
  '501351': 'JACKSON',
  '507966': 'RASRESOR',
  '522207': 'RASANDIK',
  '544119': 'NGIND',
  '524230': 'RCF',
  '531233': 'RASIELEC',
  '543417': 'RATEGAIN',
  '532918': 'RATHIBAR',
  '504903': 'RATHIST',
  '540796': 'RATNABHUMI',
  '520111': 'RATNAMANI',
  '543978': 'RATNAVEER',
  '534597': 'RTNINDIA',
  '533122': 'RTNPOWER',
  '537840': 'RAUNAQEPC',
  '507300': 'KMSUGAR',
  '533294': 'RKDL',
  '526095': 'RKDL',
  '543251': 'RVHL',
  '504341': 'RELTD',
  '541634': 'RAWEDGE',
  '500330': 'RAYMOND',
  '540065': 'RBLBANK',
  '544060': 'KHAZANCHI',
  '537254': 'NGIND',
  '533608': 'RDBRL',
  '533285': 'RDBRIL',
  '530053': 'REALECO',
  '539691': 'DBCORP',
  '538611': 'RTFL',
  '532955': 'RECLTD',
  '532805': 'REDINGTON',
  '543957': 'REDTAPE',
  '543617': 'REETECH',
  '532884': 'REFEX',
  '531260': 'REFEXRENEW',
  '515018': 'REGENCERAM',
  '540175': 'UYFINCORP',
  '512624': 'REGENTRP',
  '543208': 'REGIS',
  '530517': 'RELAXO',
  '544207': 'RELIABVEN',
  '532124': 'RELIABVEN',
  '503162': 'RELCHEMQ',
  '532712': 'RELIANCE',
  '540709': 'RELHOME',
  '523445': 'RIIL',
  '500325': 'RELIANCE',
  '500390': 'RELINFRA',
  '532939': 'RPOWER',
  '511712': 'RELICTEC',
  '539760': 'RELICAB',
  '532915': 'RELIGARE',
  '539561': 'REMLIFE',
  '513043': 'REMIEDEL',
  '530919': 'REMSONSIND',
  '532923': 'RGL',
  '535322': 'REPCOHOME',
  '532687': 'REPRO',
  '543805': 'RESGEN',
  '524218': 'RESONANCE',
  '538273': 'RESPONSINF',
  '505509': 'RESPONIND',
  '543248': 'RBA',
  '515085': 'RESTILE',
  '543902': 'RETINA',
  '519191': 'RGRL',
  '524504': 'ARCL',
  '543744': 'REXSEAL',
  '531888': 'REXNORD',
  '539669': 'RGF',
  '543590': 'RHETAN',
  '534076': 'RHIM',
  '531952': 'RIBATEX',
  '530271': 'RICHUNV',
  '532766': 'RICHAIND',
  '539435': 'RFSL',
  '520008': 'RICOAUTO',
  '540590': 'RIDDHICORP',
  '524480': 'RIDDHI',
  '540082': 'RSTL',
  '504365': 'PREMSYN',
  '517035': 'BEL',
  '530251': 'RISAINTL',
  '514177': 'WINSOME',
  '531539': 'RISHDIGA',
  '543977': 'RISHABH',
  '526861': 'RISHILASE',
  '523021': 'RISHITECH',
  '526492': 'RISHIROOP',
  '543256': 'KZLFIN',
  '542383': 'RITCO',
  '541556': 'RITES',
  '519097': 'RITESHIN',
  '540843': 'RITHWIKFMS',
  '511169': 'AGRITECH',
  '512618': 'RLF',
  '540358': 'RMC',
  '543171': 'ROJL',
  '543901': 'RHL',
  '531822': 'RODIUM',
  '505807': 'ROLCOEN',
  '543325': 'ROLEXRINGS',
  '502448': 'ROLLT',
  '500366': 'DHINDIA',
  '542145': 'RONI',
  '530991': 'ROOPAIND',
  '542599': 'TAJGVK',
  '512115': 'ROSEMER',
  '531324': 'ROSELABS',
  '543213': 'ROSSARI',
  '533168': 'ROSSELLIND',
  '517500': 'ROTO',
  '539922': 'DHINDIA',
  '543228': 'ROUTE',
  '526193': 'ROYALCU',
  '512047': 'ROYALIND',
  '532699': 'ROHLTD',
  '544143': 'ROYALIND',
  '526640': 'RAYALEMA',
  '532983': 'RPGLIFE',
  '542333': 'RPSGVENT',
  '531667': 'RRFIN',
  '531307': 'RRIL',
  '504346': 'SPELS',
  '530179': 'RSCINT',
  '539875': 'RSDFIN',
  '500350': 'RSWM',
  '531552': 'RAGHUTOB',
  '531215': 'RTSPOWR',
  '500367': 'RUBFILA',
  '503169': 'RUBYMILLS',
  '509020': 'RUCHINFRA',
  '532785': 'RUCHIRA',
  '514010': 'RUDRA',
  '544121': 'ZSOUTGAS',
  '539226': 'RUDRA',
  '530449': 'RUNGTAIR',
  '533552': 'RUPA',
  '533470': 'RUSHIL',
  '514197': 'DBCORP',
  '540497': 'SCHAND',
  '539450': 'SHK',
  '535621': 'SVGLOBAL',
  '542034': 'SMGOLD',
  '543799': 'SVJ',
  '530907': 'AKCAPIT',
  '543387': 'SVJ',
  '540048': 'SPAL',
  '530289': 'SPCAPIT',
  '513515': 'NGIND',
  '503622': 'GDTRAGN',
  '517273': 'INTEGSW',
  '540081': 'GOVNOW',
  '539112': 'SAB',
  '530461': 'SABOOSOD',
  '540132': 'NGIND',
  '531869': 'SACHEMT',
  '532710': 'SADBHAV',
  '539346': 'SADBHIN',
  '506642': 'SADHNANIQ',
  '540821': 'SADHNA',
  '543461': 'SSTL',
  '523025': 'SAFARIND',
  '531436': 'SAFFRON',
  '502090': 'SAGCEM',
  '540715': 'SAGAR',
  '507663': 'SAGRSOY-B',
  '511254': 'SAGCEM',
  '540143': 'SAGARSOFT',
  '543743': 'SAH',
  '511533': 'SAHARAHOUS',
  '544056': 'CARGOTRANS',
  '532841': 'SAHYADRI',
  '531931': 'SAICAPI',
  '543989': 'KALAMANDIR',
  '544170': 'SAICAPI',
  '512097': 'SAICOM',
  '543541': 'SAILANI',
  '530265': 'SAINIK',
  '515043': 'SAINTGOBAIN',
  '590051': 'SAKSOFT',
  '511066': 'SAKTHIFIN',
  '507315': 'SAKHTISUG',
  '532713': 'SAKUMA',
  '539353': 'SALAUTO',
  '532604': 'SALAUTO',
  '540642': 'SALASAR',
  '540181': 'SALEM',
  '526554': 'NGIND',
  '590056': 'SALONACOT',
  '500370': 'SALORAINTL',
  '517059': 'SALZER',
  '532005': 'SAMINDUS',
  '521240': 'SAMBANDAM',
  '511630': 'SAMBHAAV',
  '543984': 'SAMHI',
  '520075': 'SAMKRG',
  '535789': 'PHCAP',
  '543376': 'SAMOR',
  '534598': 'SAMPANN',
  '530617': 'SAMPRE',
  '543229': 'SAMRATFORG',
  '530125': 'SAMRATPH',
  '539267': 'SAMSRITA',
  '521206': 'SAMTEX',
  '517334': 'MOTHERSON',
  '530025': 'SAMYAKINT',
  '509423': 'SEL',
  '521222': 'DBCORP',
  '511563': 'SANCF',
  '523116': 'SANCTRN',
  '543897': 'SANCODE',
  '526725': 'SANDESH',
  '541163': 'SANDHAR',
  '524703': 'SANDUPHQ',
  '504918': 'SANDUMA',
  '516096': 'SANPA',
  '514234': 'SANGAMIND',
  '538714': 'SANGAMFIN',
  '526521': 'SANGHIIND',
  '540782': 'SBRANDS',
  '530073': 'SANGHVIMOV',
  '531569': 'SANJIVIN',
  '532435': 'SANINFRA',
  '512062': 'SAICOM',
  '500674': 'SANOFI',
  '514280': 'SANTETX',
  '543358': 'SANSERA',
  '544217': 'NGIND',
  '530035': 'SANTOSHF',
  '519260': 'FCONSUMER',
  '543397': 'SAPPHIRE',
  '506906': 'SCBL',
  '519238': 'SPTRSHI',
  '538992': 'SAPL',
  '512020': 'ZSARACOM',
  '544230': 'ZSARACOM',
  '504614': 'SARDAEN',
  '519242': 'SRDAPRT',
  '532163': 'SAREGAMA',
  '526885': 'SARLAPOLY',
  '530993': 'SARTHAKIND',
  '531930': 'SARTHAKIND',
  '540393': 'SMLT',
  '514412': 'NGIND',
  '506190': 'ZSARVAMA',
  '543688': 'SARVESHWAR',
  '539124': 'SARVOTTAM',
  '506313': 'NGIND',
  '532663': 'SASKEN',
  '533259': 'SASTASUNDR',
  '511076': 'SATINDLTD',
  '533202': 'GANHOLD',
  '539201': 'SATIA',
  '524546': 'AGRITECH',
  '539404': 'SATIN',
  '544189': 'MELSTAR',
  '503893': 'ZSATYASL',
  '539218': 'SAUMYA',
  '502175': 'SAURASHCEM',
  '511577': 'EVERFIN',
  '890201': 'EVERFIN',
  '532404': '7TEC',
  '512634': 'SAVERA',
  '524667': 'SOTL',
  '531893': 'SAWABUSI',
  '544080': 'SAYAJIHOTL',
  '544090': 'SAYAJIHOTL',
  '523710': 'SAYAJIHOTL',
  '540728': 'SAYAJIIND',
  '542725': 'SBC',
  '532102': 'SBECSUG',
  '517360': 'SBECSYS',
  '543959': 'SBFC',
  '543066': 'SBICARD',
  '540719': 'SBILIFE',
  '543366': 'SBLI',
  '526081': 'SCAGRO',
  '531797': 'SCANPRO',
  '511672': 'SCANSTL',
  '526544': 'SCANPGEOM',
  '543537': 'SCARNOSE',
  '505790': 'SCHAEFFLER',
  '534139': 'SCHNEIDER',
  '538857': 'SCC',
  '531234': 'SCOOBEEDAY',
  '535647': 'SDC',
  '533268': 'SEATV',
  '542753': 'SEACOAST',
  '543782': 'SEALMATIC',
  '526807': 'SEAMECLTD',
  '514264': 'SEASONST',
  '543234': 'SECMARK',
  '543625': 'SECURCRED',
  '512161': 'SECURKLOUD',
  '532993': 'SEJAL',
  '532886': 'SELMCL',
  '530075': 'SELAN',
  '538875': 'SELLWIN',
  '505368': 'SEMAC',
  '543936': 'SENCO',
  '531980': 'SENINFO',
  '532945': 'SEPC',
  '512529': 'SEQUENT',
  '512399': 'SERA',
  '502450': 'SESHAPAPER',
  '505075': 'SETCO',
  '533605': 'NAGREEKCAP',
  '511760': 'SEVENHILL',
  '524324': 'SEYAIND',
  '539199': 'SGFIN',
  '512329': 'SGMART',
  '531812': 'SGNTE',
  '513436': 'SHAHALLOYS',
  '509870': 'RJSHAH',
  '519031': 'SHAHFOOD',
  '533275': 'SHAH',
  '526508': 'ESSARSHPNG',
  '542862': 'SHAHLON',
  '501423': 'SHAILY',
  '526841': 'ORIENTLTD',
  '531431': 'SHAKTIPUMP',
  '540797': 'SHALBY',
  '511754': 'SAHLIBHFI',
  '539895': 'GDTRAGN',
  '509874': 'SHALPAINTS',
  '512499': 'SHALPRO',
  '532455': 'SHALIWIR',
  '531240': 'SHAMROIN',
  '540259': 'SHANGAR',
  '542232': 'SRD',
  '540425': 'SHANKARA',
  '512297': 'SHANTAI',
  '522034': 'SHANTIGEAR',
  '539921': 'SEIL',
  '534708': 'SHANTIGURU',
  '544059': 'AKSPINTEX',
  '543598': 'HGINFRA',
  '539584': 'SIPTL',
  '519397': 'SHINDL',
  '890207': 'SHINDL',
  '538666': 'SHARDACROP',
  '513548': 'SHRDAIS',
  '535602': 'SHARDA',
  '512393': 'SHARDUL',
  '540725': 'SHAREINDIA',
  '540786': 'SHARIKA',
  '524548': 'KOVAI',
  '538212': 'SHARPINV',
  '543341': 'SHARPLINE',
  '540221': 'SHASHANK',
  '540147': 'SHASHIJIT',
  '543519': 'SFSL',
  '540203': 'SFL',
  '540757': 'SCPL',
  '533301': 'NGIND',
  '526839': 'SIPL',
  '543963': 'SHELTER',
  '538685': 'SHEMAROO',
  '512367': 'SHRIRAMPPS',
  '526117': 'SHERVANI',
  '539111': 'SHESHAINDS',
  '526137': 'SHETR',
  '507952': 'SHIKHARLETR',
  '531201': 'SHILCTECH',
  '513709': 'SHILGRAVQ',
  '530549': 'SHILPAMED',
  '543244': 'SHINEFASH',
  '544142': 'BLAL',
  '523598': 'SCI',
  '540693': 'SHISHIND',
  '532323': 'SHIVACEM',
  '530433': 'SHIVAAGRO',
  '540072': 'SHIVAEXPO',
  '540961': 'SHIVAMILLS',
  '521003': 'SHVSUIT',
  '511108': 'SHIVTEX',
  '522237': 'SHIVAGR',
  '513097': 'SHBCLQ',
  '539148': 'SHIVALIK',
  '532776': 'SHIVAMAUTO',
  '544165': 'SHIVAMAUTO',
  '539593': 'SHIVA',
  '539683': 'SHIVKAMAL',
  '543970': 'SHOORA',
  '532638': 'SHOPERSTOP',
  '531771': 'SHRADDHA',
  '543976': 'SHRAAITECH',
  '538795': 'SAPPL',
  '521131': 'SBFL',
  '500387': 'SHREECEM',
  '502180': 'SHREDIGCEM',
  '539470': 'SGRL',
  '530797': 'SHGANEL',
  '540737': 'SGRL',
  '537709': 'HANSUGAR',
  '524336': 'SHHARICH',
  '516106': 'SHKARTP',
  '542146': 'SKIFL',
  '500388': 'SKPMIL',
  '503863': 'SHREESEC',
  '544083': 'SHREESEC',
  '531962': 'SHREMETAL',
  '527005': 'SHREEPAC',
  '533110': 'SPSL',
  '539334': 'SHREEPUSHK',
  '503837': 'SHREESEC',
  '532310': 'SHREERAMA',
  '500356': 'RAMANEWS',
  '532670': 'RENUKA',
  '503635': 'SHREESEC',
  '538975': 'SHREESEC',
  '513488': 'SSWRL',
  '540738': 'STL',
  '503696': 'SHRENTI',
  '541112': 'SHREESHAY',
  '532007': 'SHVFL',
  '516016': 'SHREYANIND',
  '526335': 'SHREYASI',
  '520151': 'SHREYAS',
  '526981': 'SHBAJRG',
  '544074': 'BALAMINES',
  '503804': 'SHRIDINE',
  '523309': 'SHRIGANG',
  '512453': 'SHRJAGP',
  '532083': 'KALYANKJIL',
  '530977': 'SKCIL',
  '531080': 'SHRIKRISH',
  '538897': 'SHRINIWAS',
  '538092': 'VASUPRADA',
  '543373': 'SVRL',
  '508961': 'SHRICON',
  '531359': 'SRAMSET',
  '544208': 'SRAMSET',
  '511218': 'SHRIRAMFIN',
  '543419': 'SHRIRAMPPS',
  '511411': 'SHRISTI',
  '511493': 'SHRYDUS',
  '542019': 'SHUBHAM',
  '531506': 'SKRABUL',
  '523790': 'SHUKJEW',
  '524632': 'SHUKRAPHAR',
  '539252': 'SCFL',
  '543299': 'SHYAMMETL',
  '517411': 'SHYAMTEL',
  '531219': 'SHYAMAINFO',
  '505515': 'SHYMINV',
  '520141': 'SIBARAUT',
  '533014': 'SICAGEN',
  '520086': 'SICALLOG',
  '530439': 'SIDDHA',
  '526877': 'SIDDHEGA',
  '500550': 'SIEMENS',
  '543389': 'SIGACHI',
  '543917': 'SIGMA',
  '543990': 'SIGNATURE',
  '512131': 'SIGNETIND',
  '544117': 'DHINDIA',
  '523606': 'SIKA',
  '524642': 'SIKOZY',
  '521194': 'SILINV',
  '543615': 'SRSOLTD',
  '531738': 'SRSOLTD',
  '531635': 'SILVOAK',
  '543536': 'SILVERPRL',
  '543525': 'SILVERTUC',
  '500389': 'BGIL',
  '512197': 'SAICOM',
  '539742': 'SIMBHALS',
  '507998': 'SIMMOND',
  '513472': 'SIMPLEXCAS',
  '523838': 'SIMPLXREA',
  '533018': 'SIMPLXREA',
  '533019': 'SIMPLXPAP',
  '503229': 'SIMPLXREA',
  '519566': 'SIMRAN',
  '523023': 'SINCLAIR',
  '532029': 'SINDHUTRAD',
  '505504': 'BVCL',
  '505729': 'SINGER',
  '509887': 'SINNAR',
  '532879': 'SSLEL',
  '543686': 'SIRCA',
  '538667': 'BLKASHYAP',
  '540673': 'SIS',
  '512589': 'SITAENT',
  '532795': 'SITINET',
  '544047': 'ECORECO',
  '503811': 'SIYSIL',
  '513496': 'SIZEMASTER',
  '504398': 'SJCORP',
  '533206': 'SJVN',
  '542728': 'SHIVAEXPO',
  '500472': 'SKFINDIA',
  '538562': 'SKIPPER',
  '890193': 'SKIPPER',
  '532143': 'SKMEGGPROD',
  '531169': 'SKPSEC',
  '541967': 'SKYGOLD',
  '526479': 'SKYIND',
  '505650': 'SKYLMILAR',
  '538919': 'SKYLMILAR',
  '543065': 'SMAUTO',
  '539494': 'SMARTFIN',
  '532419': 'SMARTLINK',
  '532138': 'SMCREDT',
  '543263': 'SMCGLOBAL',
  '508905': 'SMIFS',
  '513418': 'SMFIL',
  '505192': 'SMLISUZU',
  '540686': 'SMRUTHIORG',
  '540679': 'SMSLIFE',
  '532815': 'SMSPHARMA',
  '505827': 'SNL',
  '538635': 'SNOWMAN',
  '532784': 'SOBHA',
  '890205': 'SOBHA',
  '512014': 'MERCANTILE',
  '538923': 'SOFCOM',
  '531529': 'SOFTRAKV',
  '532344': 'SOFTSOL',
  '543470': 'SOFTTECH',
  '532725': 'SOLARINDS',
  '541540': 'SOLARA',
  '890202': 'SOLARA',
  '513699': 'SOLIDSTON',
  '522152': 'SOLIMAC',
  '511571': 'SODFC',
  '507514': 'SDBL',
  '516038': 'SOMATEX',
  '521034': 'SOMATEX',
  '531548': 'SOMANYCERA',
  '533001': 'SOMICONV',
  '543300': 'SONACOMS',
  '526901': 'SONALAD',
  '538943': 'SONAL',
  '543924': 'SONALIS',
  '532221': 'SONATSOFTW',
  '539378': 'SML',
  '541633': 'NGIND',
  '521036': 'NGIND',
  '531398': 'SOURCENTRL',
  '526477': 'SAENTER',
  '516108': 'STHINPA',
  '532218': 'SOUTHBANK',
  '543986': 'SOUTHWEST',
  '509910': 'ZSOUTGAS',
  '540174': 'SOUTHERNIN',
  '514454': 'SOUTLAT',
  '513498': 'SOUTHMG',
  '590030': 'DBCORP',
  '523826': 'SOVERDIA',
  '542376': 'AKCAPIT',
  '541890': 'SPACEINCUBA',
  '524727': 'SDL',
  '542759': 'SPANDANA',
  '531370': 'SPAR',
  '534425': 'SPECIALITY',
  '513687': 'SPECTRA',
  '531982': 'SPECFOOD',
  '512291': 'NIRAVCOM',
  '517166': 'SPELS',
  '542337': 'SUNRETAIL',
  '526161': 'SPENTA',
  '526827': 'SPICEISL',
  '500285': 'SPICEJET',
  '532651': 'SPLIL',
  '500402': 'SPMLINFRA',
  '539221': 'SPORTKING',
  '540079': 'SPRAYKING',
  '531205': 'MPAGI',
  '501479': 'LTTS',
  '538402': 'SPS',
  '530177': 'SPS',
  '512221': 'TIGLOB',
  '526532': 'SFPIL',
  '530037': 'SREEJAYA',
  '532842': 'SRHHYPOLTD',
  '514248': 'SRECR',
  '535601': 'SREEL',
  '539217': 'SRESTHA',
  '503806': 'SRF',
  '534680': 'SRGHFL',
  '536710': 'SRGHFL',
  '530943': 'SABTN',
  '538863': 'AMARSEC',
  '531322': 'HAVISHA',
  '514442': 'SRIKPRIND',
  '521161': 'SLSTLQ',
  '521234': 'SRINACHA',
  '521178': 'SRMCL',
  '544158': 'SRMENERGY',
  '523222': 'SRMENERGY',
  '540914': 'SRUSTEELS',
  '530821': 'SSPDL',
  '539026': 'SSPNFIN',
  '504180': 'STDBAT',
  '511700': 'STANCAP',
  '530017': 'SIL',
  '523351': 'SIL',
  '526231': 'STDSFAC',
  '544202': 'ADVLIFE',
  '530931': 'STANPACK',
  '506105': 'STANROS',
  '540575': 'STARCEMENT',
  '539255': 'STARDELTA',
  '543412': 'STARHEALTH',
  '539017': 'STARHFL',
  '516022': 'STARPAPER',
  '531616': 'DEVIT',
  '540492': 'STARLENT',
  '538733': 'HBLPOWER',
  '517548': 'NDRAUTO',
  '520155': 'STARLOG',
  '512381': 'STARTECK',
  '500112': 'SBIN',
  '539031': 'SETFBSE100',
  '512531': 'STCINDIA',
  '500113': 'SAIL',
  '534748': 'STEELXIND',
  '513173': 'STLSTRINF',
  '513262': 'SSWL',
  '513517': 'STEELCAS',
  '543622': 'STML',
  '533316': 'STEL',
  '526071': 'RRSECUR',
  '536738': 'STELLAR',
  '531509': 'STEP2COR',
  '542760': 'SWSOLAR',
  '526500': 'STERTOOLS',
  '513575': 'STERPOW',
  '530759': 'STERTOOLS',
  '532374': 'STLTECH',
  '532730': 'SGL',
  '544171': 'KCSL',
  '543260': 'STOVEKRAFT',
  '504959': 'STOVACQ',
  '530495': 'STRATMONT',
  '532531': 'STAR',
  '526951': 'STYLAMIND',
  '506222': 'STYRENIX',
  '532348': 'SUBEX',
  '530231': 'SUBSM',
  '517168': 'SUBROS',
  '506003': 'SUDAI',
  '506655': 'SUDARSCHEM',
  '543828': 'SUDARSHAN',
  '521113': 'SUDTIND-B',
  '540318': 'SUERYAAKNI',
  '511654': 'SUGALDAM',
  '539117': 'SUJALA',
  '524542': 'SUKHJITS',
  '543711': 'SULA',
  '508969': 'SULABEN',
  '530419': 'SUMEDHA',
  '514211': 'NGIND',
  '530445': 'SUMERUIND',
  '542920': 'SUMICHEM',
  '533306': 'SUMMITSEC',
  '532070': 'SUMUKA',
  '532872': 'SPARC',
  '524715': 'SUNPHARMA',
  '542025': 'SUNRETAIL',
  '532733': 'SUNTV',
  '539526': 'SCTL',
  '530795': 'SUNCITYSY',
  '590072': 'SUNDRMBRAK',
  '590071': 'SUNDARMFIN',
  '533166': 'SUNDARAM',
  '544066': 'SUNDARMFIN',
  '500403': 'SUNDRMFAST',
  '500404': 'SUNFLAG',
  '531433': 'SUNGOLD',
  '541799': 'SMEL',
  '530953': 'SUNILAGR',
  '537253': 'SUNLOC',
  '521232': 'SUNILTX',
  '544001': 'SUNITATOOL',
  '523425': 'SUNRAJDI',
  '543515': 'SEML',
  '501110': 'SCTL',
  '530845': 'SUNSHIEL',
  '539574': 'SCL',
  '512179': 'SUNTECK',
  '530735': 'SUPERBAK',
  '530883': 'SUCROSA',
  '540269': 'SKL',
  '512527': 'SUPER',
  '521180': 'SUPERSPIN',
  '523842': 'SUPTANERY',
  '523283': 'SUPERHOUSE',
  '539835': 'SUPERIOR',
  '519234': 'SIEL',
  '541701': 'SUPERSHAKT',
  '526133': 'SUPERTEX',
  '540168': 'SUPRAPFSL',
  '511539': 'CHECKPOINT',
  '532509': 'SUPRAJIT',
  '530677': 'SUPREME',
  '509930': 'SUPREMEIND',
  '532904': 'SUPREMEINF',
  '500405': 'SPLPETRO',
  '543434': 'SUPRIYA',
  '544054': 'SURAJLTD',
  '526211': 'SURJIND',
  '890206': 'SURJIND',
  '531638': 'SURAJLTD',
  '518075': 'SURAJ',
  '533298': 'SURANASOL',
  '517530': 'SURANATP',
  '530185': 'SURATRAML',
  '543218': 'SBGLP',
  '514260': 'NGIND',
  '539253': 'SURYAROSNI',
  '500336': 'SURYAROSNI',
  '533101': 'SURYAAMBA',
  '521200': 'SURYALAXMI',
  '514138': 'SURYALA',
  '514140': 'SURYVANSP',
  '519604': 'SURFI',
  '543279': 'SURYODAY',
  '532782': 'SUTLEJTEX',
  '543274': 'SUUMAYA',
  '530239': 'SUVEN',
  '543064': 'SUVENPHAR',
  '531640': 'SICL',
  '543281': 'SUVIDHAA',
  '543391': 'SGFRL',
  '537259': 'SUYOG',
  '532667': 'SUZLON',
  '531885': 'DHINDIA',
  '523722': 'RSSOFTWARE',
  '503624': 'ZSVARAJT',
  '539911': 'SNIM',
  '524488': 'SVCIND',
  '505590': 'SVPGLOB',
  '543745': 'SVS',
  '503659': 'SW1',
  '506863': 'SWADPOL',
  '503816': 'SWADPOL',
  '531039': 'NGIND',
  '539406': 'GDTRAGN',
  '503310': 'SWANENERGY',
  '500407': 'SWARAJENG',
  '531003': 'SWRNASE',
  '526365': 'SWARNSAR',
  '544035': 'SPL',
  '512257': 'SVARTCORP',
  '510245': 'SWASTIVI',
  '501386': 'ZSWASTSA',
  '530585': 'SWASTIKA',
  '543914': 'ANANDPROJ',
  '532051': 'SWELECTES',
  '523558': 'SWISSMLTRY',
  '517201': 'SWITCHTE',
  '530217': 'SWOEF',
  '531499': 'SYBLY',
  '511447': 'SYLPH',
  '539278': 'SYMBIOX',
  '517385': 'SYMPHONY',
  '524470': 'SYNCOMF',
  '541929': 'SGIL',
  '539268': 'SYNGENE',
  '513307': 'SYNTHFO',
  '543573': 'SYRMA',
  '531173': 'SYSCHEM',
  '526506': 'SYSTMTXC',
  '531432': 'SYTIXSE',
  '522294': 'TIGLOB',
  '532444': 'TSPIRITUAL',
  '532515': 'TVTODAY',
  '539956': 'TAALENT',
  '516032': 'SVJ',
  '519483': 'TAIIND',
  '507785': 'TAINWALCHM',
  '532390': 'UPHOT',
  '532890': 'TAKE',
  '505160': 'TALBROAUTO',
  '538987': 'TALBROSENG',
  '533170': 'TAMBOLIIN',
  '531426': 'TNPL',
  '543596': 'TMB',
  '500777': 'TNPETRO',
  '513540': 'TNSTLTU',
  '523419': 'TNTELE',
  '522229': 'TANAA',
  '506854': 'TANFACIND',
  '532790': 'TANLA',
  '532738': 'TCLCONS',
  '540332': 'TANVI',
  '505685': 'TAPARIA',
  '519285': 'TARAI',
  '533203': 'TARAPUR',
  '543249': 'TARC',
  '538496': 'TARINI',
  '532869': 'TARMAT',
  '543399': 'TARSONS',
  '512271': 'DHINDIA',
  '519091': 'TASTYBIT',
  '540955': 'TDSL',
  '500770': 'TATACHEM',
  '500483': 'TATACOMM',
  '532540': 'TCS',
  '500800': 'TATACONSUM',
  '500408': 'TATAELXSI',
  '501301': 'TATAINVEST',
  '500570': 'TATAMTRDVR',
  '570001': 'TATAMTRDVR',
  '500400': 'TATAPOWER',
  '500470': 'TATASTEEL',
  '544028': 'TATATECH',
  '532371': 'TTML',
  '521228': 'TATIAGLOB',
  '543321': 'TATVA',
  '531190': 'TAVERNIER',
  '541228': 'TRL',
  '504961': 'NGIND',
  '544174': 'MOLDTEK',
  '512038': 'TCC',
  '532284': 'TCFCFINQ',
  '540212': 'TCIEXP',
  '501242': 'TCIIND',
  '532262': 'TCIIND',
  '524156': 'TCMLMTD',
  '541700': 'TCNSBRANDS',
  '523301': 'TCPLPACK',
  '533553': 'TDPOWERSYS',
  '539658': 'TEAMLEASE',
  '533048': 'TPHQ',
  '532755': 'TECHM',
  '526576': 'TECHIN',
  '543991': 'TECHKGREEN',
  '542141': 'TECHNOE',
  '532804': 'TIIL',
  '509917': 'TECHCON',
  '543656': 'TECHNOPACK',
  '501421': 'TECHNVISN',
  '506680': 'TRELCHE-B',
  '524204': 'TEEAI',
  '543413': 'TEGA',
  '540595': 'TEJASNET',
  '531628': 'TEJASSVI',
  '539428': 'TEJNAKSH',
  '530595': 'TELECANOR',
  '532975': 'NGIND',
  '533982': 'TERASOFT',
  '530533': 'TERAI',
  '506162': 'TERRAFORM',
  '512157': 'TERRAREAL',
  '526638': 'TEXELIN',
  '505400': 'TEXINFRA',
  '533326': 'TEXRAIL',
  '533164': 'TEXMOPIPES',
  '532845': 'TGBHOTELS',
  '544175': 'NGIND',
  '507753': 'TGVSL',
  '509945': 'THACKER',
  '526654': 'THAKDEV',
  '507530': 'THAKKERS',
  '509015': 'PREMCAP',
  '533158': 'THANGAMAYL',
  '522073': 'HITECHGEAR',
  '530199': 'THEMISMED',
  '500411': 'THERMAX',
  '539310': 'THINKINK',
  '538464': 'TPROJECT',
  '531652': 'KENFIN',
  '500412': 'TIRUMALCHM',
  '500413': 'THOMASCOOK',
  '533941': 'THOMASCOTT',
  '544214': 'GENUSPAPER',
  '539871': 'THYROCARE',
  '540108': 'TIAANC',
  '590005': 'TIDEWATER',
  '543531': 'TIERRA',
  '536264': 'TIGERLOGS',
  '533629': 'TIJARIA',
  '505196': 'TIL',
  '503663': 'TILAK',
  '507205': 'TI',
  '532856': 'TIMETECHNO',
  '543310': 'TIMESGREEN',
  '511559': 'TIMESGTY',
  '500414': 'TIMEX',
  '522113': 'TIMKEN',
  '530475': 'TINNARUBR',
  '543614': 'TIPSFILMS',
  '532375': 'TIPSINDLTD',
  '526675': 'TIRTPLS',
  '539488': 'SUPERIOR',
  '540904': 'TIRUFOAM',
  '531814': 'TIRSARJ',
  '524582': 'TIRUSTA',
  '539040': 'TTIL',
  '539985': 'TITAANIUM',
  '532966': 'TITAGARH',
  '524717': 'TITANBIO',
  '500114': 'TITAN',
  '521005': 'TITANIN',
  '530045': 'TITANSEC',
  '511096': 'TVOLCON',
  '522171': 'TMTIND-B1',
  '531644': 'TOKYOFIN',
  '500418': 'TOKYOPLAST',
  '500420': 'TORNTPHARM',
  '532779': 'TORNTPOWER',
  '526650': 'TFCILTD',
  '538607': 'TOYAMSL',
  '500421': 'DHINDIA',
  '526582': 'TPLPLAST',
  '543638': 'TRACXN',
  '531203': 'TRADEWELL',
  '513063': 'TRANSFRE',
  '523752': 'TIHIL',
  '500422': 'TRANSCHEM',
  '532410': 'TRANSCOR',
  '532928': 'TRIL',
  '526139': 'TRABI',
  '519367': 'TRANSFD',
  '543955': 'TREL',
  '542765': 'SVJ',
  '506687': 'TRANSPEK',
  '532349': 'TCI',
  '543754': 'TRANSVOY',
  '532812': 'TFL',
  '542923': 'BGIL',
  '533540': 'TREEHOUSE',
  '542233': 'TREJHARA',
  '500251': 'TRENT',
  '532159': 'TRESCON',
  '505854': 'TRF',
  '534369': 'TBZ',
  '531716': 'TRICOMFRU',
  '543616': 'TLL',
  '521064': 'TRIDENT',
  '540726': 'TTFL',
  '531568': 'NGIND',
  '517562': 'TRIGYN',
  '509046': 'PEARLPOLY',
  '536565': 'TRIMURTHI',
  '531846': 'TRINITYLEA',
  '534755': 'TRIOMERC',
  '512101': 'SAGRSOY-B',
  '531279': 'TRISHAKT',
  '523387': 'DBCORP',
  '505978': 'TRITONV',
  '532356': 'TRIVENI',
  '538569': 'TRIVENIENT',
  '502281': 'TRIVENIGQ',
  '533655': 'TRITURBINE',
  '540268': 'TRU',
  '508963': 'RRSECUR',
  '514142': 'NGIND',
  '538597': 'TTIENT',
  '507747': 'TTKHEALTH',
  '517506': 'TTKPRESTIG',
  '514236': 'SVJ',
  '540762': 'TIINDIA',
  '524514': 'TULASEEBIOE',
  '505285': 'TULIVE',
  '513629': 'TULSYAN',
  '531411': 'TUNITEX',
  '506808': 'TUTIALKA',
  '540083': 'TVVISION',
  '532800': 'TV18BRDCST',
  '532513': 'TVSELECT',
  '520056': 'TVSHLTD',
  '532343': 'TVSMOTOR',
  '509243': 'TVSSRICHAK',
  '543965': 'TVSSCS',
  '526921': '21STCENMGM',
  '512117': 'GDTRAGN',
  '532384': 'TYCHE',
  '539468': 'KENFIN',
  '526945': 'TYROON',
  '541338': 'UHZAVERI',
  '509960': 'UPHOT',
  '530579': 'UYFINCORP',
  '500464': 'UCAL',
  '532505': 'UCOBANK',
  '530131': 'UDAICEMENT',
  '539518': 'UDAYJEW',
  '543861': 'USK',
  '500148': 'UFLEX',
  '531610': 'NGIND',
  '539141': 'UFO',
  '530363': 'UGARSUGAR',
  '511742': 'UGROCAP',
  '533644': 'KPEL',
  '542904': 'UJJIVANSFB',
  '538706': 'ULTRACAB',
  '506685': 'ULTRAMAR',
  '532538': 'ULTRACEMCO',
  '543513': 'UMAEXPORTS',
  '500231': 'UMANGDAIR',
  '539798': 'UMIYA',
  '504605': 'UNIABEXAL',
  '506690': 'UNICHEMLAB',
  '541503': 'UNICK',
  '544227': 'NGIND',
  '541358': 'UCIL',
  '503671': 'UPSURGE',
  '532477': 'UNIONBANK',
  '543689': 'UNIPARTS',
  '500429': 'UNIPHOS',
  '530997': 'UNIQUEO',
  '521226': 'UNIROYAL',
  '526113': 'UNRYLMA',
  '537582': 'UNISHIRE',
  '538610': 'UNISON',
  '532035': 'UNISTRMU',
  '531867': 'UNITINT',
  '507878': 'UNITECH',
  '532478': 'UBL',
  '544195': 'UNITEDTE',
  '531091': 'UNITDCR',
  '522014': 'UNIDT',
  '502893': 'UNITEDINT',
  '507808': 'UNTTEMI',
  '532432': 'UNITDSPR',
  '522091': 'UVDRHOR',
  '526683': 'UNIVAFOODS',
  '532378': 'UNIVARTS',
  '539314': 'UNIAUTO',
  '504212': 'UNIVCABLES',
  '523519': 'UNIOFFICE',
  '524408': 'UNIVSTAR',
  '542933': 'UNIVPHOTO',
  '531762': 'UNJHAFOR',
  '532539': 'UNOMINDA',
  '543996': 'UDS',
  '512070': 'UPL',
  '531390': 'UPSURGE',
  '539097': 'URSUGAR',
  '543930': 'URAVI',
  '526987': 'URJAGLOBA',
  '532402': 'USGTECH',
  '532398': 'UMESLTD',
  '517146': 'USHAMART',
  '511507': 'USHAKIRA',
  '543238': 'UTIAMC',
  '500014': 'UTIQUE',
  '543942': 'UTKARSHBNK',
  '500426': 'UTLINDS',
  '532729': 'UTTAMSUGAR',
  '539123': 'NGIND',
  '542654': 'VRFILMS',
  '532953': 'VGUARD',
  '534976': 'VMART',
  '511110': 'VBDESAI',
  '523888': 'VRWODAR',
  '532867': 'V2RETAIL',
  '533269': 'WABAG',
  '532320': 'VAARAD',
  '519152': 'VADILENT',
  '519156': 'VADILALIND',
  '531676': 'VAGHANI',
  '532156': 'VAIBHAVGBL',
  '511431': 'VAKRANGEE',
  '542910': 'EMMESSA',
  '526775': 'VALIANT',
  '543998': 'VALIANTLAB',
  '540145': 'VALIANTORG',
  '513397': 'VALLABHSQ',
  '539543': 'RJSHAH',
  '533160': 'HBESD',
  '530459': 'VALSONQ',
  '512175': 'VAMA',
  '530369': 'VAMSHIRU',
  '532090': 'VANDANA',
  '538918': 'VANICOM',
  '540729': 'VANTABIO',
  '539761': 'VKAL',
  '502589': 'VAPIENTER',
  '542931': 'CIFL',
  '531444': 'VARDHMAN',
  '500439': 'VHL',
  '514175': 'VARDMNPOLY',
  '534392': 'VSSL',
  '502986': 'VTL',
  '540570': 'VARIMAN',
  '541578': 'VARROC',
  '540180': 'VBL',
  '512511': 'VBL',
  '544168': 'AKM',
  '531574': 'VASINFRA',
  '533156': 'VASCONEQ',
  '532011': 'NGIND',
  '539291': 'VASUDHAGAM',
  '538634': 'VRL',
  '533576': 'VASWANI',
  '542803': 'VEL',
  '536672': 'VCU',
  '543623': 'VEDANTASSET',
  '543463': 'MANYAVAR',
  '500295': 'VEDL',
  '533056': 'VEDAVAAG',
  '543931': 'VEEFIN',
  '522267': 'VJLAXMIE',
  '503657': 'VEERENRGY',
  '543241': 'VGIL',
  '540252': 'VSL',
  '511523': 'VEERHEALTH',
  '543545': 'VEERKRUPA',
  '526755': 'VELHO',
  '505232': 'VELJAN',
  '506178': 'NGIND',
  '523261': 'VENKYS',
  '524038': 'VENLONENT',
  '512060': 'SHYAM',
  '516098': 'VENTURA',
  '543528': 'VENUSPIPES',
  '526953': 'VENUSREM',
  '543514': 'VERANDA',
  '512229': 'VERITAS',
  '531950': 'VERTEX',
  '520113': 'VESUVIUS',
  '539331': 'VETO',
  '544124': 'RAMASTEEL',
  '538732': 'VGCL',
  '523796': 'UPHOT',
  '503349': 'VICTMILL',
  '531717': 'VIDHIING',
  '539659': 'VIDLI',
  '531069': 'VIJSOLX',
  '543350': 'VIJAYA',
  '537820': 'VFL',
  '531334': 'VIKALPS',
  '530961': 'VIKASECO',
  '542655': 'VIKASLIFE',
  '531518': 'VIKASPROP',
  '519307': 'VIKASWSP',
  '530477': 'VIKRAMTH',
  '506196': 'GGENG',
  '524394': 'VIMTALABS',
  '524200': 'VINATIORGA',
  '534639': 'VINAYAKPOL',
  '512517': 'VINAYAKPOL',
  '517015': 'VINDHYATEL',
  '543298': 'VINEETLAB',
  '543670': 'VINNY',
  '538920': 'VINCOFE',
  '531051': 'VINTAGES',
  '517393': 'VINTRON',
  '524129': 'VINYLINDIA',
  '530401': 'VINYOFL',
  '532613': 'VIPCLOTHNG',
  '507880': 'VIPCLOTHNG',
  '514302': 'VIPPYSP',
  '511726': 'VIPUL',
  '530627': 'VIPULORG',
  '519457': 'VIRATCRA',
  '530521': 'VIRAT',
  '539167': 'VIRAT',
  '532354': 'VIRGOGLOB',
  '532372': 'VIRINCHI',
  '534741': 'VIRTUALG',
  '531126': 'VIRTUALS',
  '543597': 'VOEPL',
  '512479': 'VIRYA',
  '532721': 'SALSTEEL',
  '531025': 'VISAGAR',
  '506146': 'VIVIDHA',
  '509055': 'VISAKAIND',
  '540097': 'VISCO',
  '539398': 'VISHALBL',
  '538598': 'VISHAL',
  '516072': 'VISHNU',
  '543974': 'VPRPL',
  '512064': 'MERCANTILE',
  '750887': 'KRISHNA',
  '542852': 'VISHWARAJ',
  '526441': 'VISIONCINE',
  '531668': 'DBCORP',
  '524711': 'VISTAPH',
  '538565': 'VISTARAMAR',
  '544002': 'VIVAA',
  '541735': 'VIVANTA',
  '530057': 'VIVANZA',
  '524576': 'VIVIDIND',
  '542046': 'VIVIDM',
  '511509': 'VIVOBIOT',
  '509026': 'VJTFEDU',
  '543958': 'VLEGOV',
  '511333': 'VLSFINANCE',
  '533427': 'VMS',
  '532822': 'IDEA',
  '522122': 'VOITHPAPR',
  '509038': 'VOLLF',
  '532757': 'VOLTAMP',
  '500575': 'VOLTAS',
  '544204': 'VRAJ',
  '539118': 'VRLLOG',
  '544157': 'LLOYDSENGG',
  '544011': 'VPL',
  '531696': 'VSDCONF',
  '519331': 'VSFPROJ',
  '890203': 'VSFPROJ',
  '509966': 'VSTIND',
  '531266': 'VSTTILLERS',
  '532893': 'VTMLTD',
  '531997': 'PNCINFRA',
  '544219': 'PNCINFRA',
  '517399': 'VXLINSTR',
  '501391': 'WHBRADY',
  '504220': 'NGIND',
  '541445': 'WAA',
  '534618': 'WAAREERTL',
  '539337': 'WAAREE',
  '503675': 'WAGEND',
  '501370': 'WALCHPF',
  '507410': 'WALCHANNAG',
  '532053': 'WALLFORT',
  '524212': 'WANBURY',
  '539132': 'WARDWIZFBL',
  '512063': 'WARDWIZFBL',
  '538970': 'WARDINMOBI',
  '508494': 'WARRENTEA',
  '523660': 'WATERBASE',
  '543535': 'WEWIN',
  '780016': 'JOINTECAED',
  '517498': 'WEBELSOLAR',
  '523011': 'WEIZMANIND',
  '504988': 'ZWELCAST',
  '524661': 'WELCURE',
  '532144': 'WELCORP',
  '532553': 'WELENT',
  '533252': 'WELINV',
  '514162': 'WELSPUNLIV',
  '500365': 'WELSPLSOL',
  '526431': 'WELTI',
  '505412': 'WENDT',
  '532373': 'WEPSOLN',
  '500444': 'WSTCSTPAPR',
  '538382': 'WESTLEIRES',
  '504998': 'NGIND',
  '505533': 'WESTLIFE',
  '590073': 'WHEELS',
  '543436': 'VLEGOV',
  '500238': 'WHIRLPOOL',
  '512431': 'RJSHAH',
  '513713': 'WHITEORG',
  '542667': 'WORL',
  '519214': 'WILLIMFI',
  '519224': 'WILLAMAGOR',
  '526586': 'WIMPLAST',
  '543329': 'WINDLAS',
  '522029': 'WINDMACHIN',
  '531337': 'WINPRO',
  '512022': 'SAICOM',
  '526471': 'WINSOMBR',
  '514470': 'WINSOMTX',
  '514348': 'WINSOME',
  '507685': 'WIPRO',
  '507817': 'WIREFABR',
  '532300': 'WOCKPHARMA',
  '543449': 'WEL',
  '538268': 'WONDERLA',
  '526959': 'WOODSVILA',
  '526525': 'WWALUM',
  '538451': 'WORTH',
  '505872': 'WPIL',
  '511147': 'WSFX',
  '532616': 'XCHANGING',
  '542367': 'XELPMOC',
  '590013': 'XPROINDIA',
  '531225': 'TIGLOB',
  '538521': 'YAANENT',
  '533520': 'YAARI',
  '511012': 'YAMNINV',
  '540980': 'YSL',
  '514378': 'YARNSYN',
  '890197': 'YARNSYN',
  '539939': 'YASTF',
  '523650': 'YASHINNO',
  '511601': 'YASHMGM',
  '512345': 'YASTF',
  '541167': 'YASHO',
  '530063': 'YASHRAJC',
  '543950': 'YATHARTH',
  '543992': 'YATRA',
  '532648': 'YESBANK',
  '522209': 'YOGISUNG',
  '511702': 'YOGI',
  '530675': 'YORKEXP',
  '540550': 'YUG',
  '522108': 'YUKEN',
  '533149': 'GCMCAPI',
  '536846': 'YURANUS',
  '531663': 'PGHH',
  '543985': 'ZAGGLE',
  '539963': 'ZEAL',
  '505537': 'ZEEL',
  '533287': 'ZEELEARN',
  '532794': 'ZEEMEDIA',
  '533339': 'ZENTEC',
  '512553': 'ZENITHEXPO',
  '514266': 'ZENIFIB',
  '530665': 'ZENITHHE',
  '531845': 'ZENITHSTL',
  '530697': 'ZENLABS',
  '532039': 'ZENOTECH',
  '504067': 'ZENSARTECH',
  '533023': 'ZFCVINDIA',
  '505163': 'ZFSTEERING',
  '541400': 'ZIMLAB',
  '538579': 'ZINEMA',
  '521163': 'ZODIAC',
  '543416': 'ZODIACVEN',
  '503641': 'ZODIACVEN',
  '512587': 'ZODJRDMKJ',
  '543320': 'ZOMATO',
  '534742': 'ZUARI',
  '500780': 'ZUARIIND',
  '532321': 'ZYDUSLIFE',
  '531335': 'ZYDUSWELL',
}
