import React, { useCallback, useState } from 'react'

import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { isString } from 'lodash'

import { resetPasswordSchema } from '../validation'
import { resetPassword } from '../api'

export const PasswordResetForm: React.FC<{ email: string }> = ({ email }) => {
  const navigate = useNavigate()

  const [passwordResetRequest, setPasswordResetRequest] = useState({
    loading: false,
    error: '',
  })

  const form = useFormik({
    initialValues: {
      email: email,
      code: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      setPasswordResetRequest({ loading: true, error: '' })
      const { email, code, newPassword, newPasswordConfirmation } = values
      const { error } = await resetPassword({
        email,
        code,
        newPassword,
        newPasswordConfirmation,
      })
      if (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : typeof error === 'object' &&
              'message' in error &&
              isString((error as { message: unknown }).message)
            ? (error as { message: string }).message
            : 'Unexpected error occurred'
        setPasswordResetRequest({ loading: false, error: errorMessage })
      } else {
        setPasswordResetRequest({ loading: false, error: '' })
        // TODO Display a success toast and delay navigation to log in screen by ~500ms
        navigate('/auth/log-in')
      }
    },
  })

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <form onSubmit={form.handleSubmit}>
        {passwordResetRequest.error && passwordResetRequest.error !== '' && (
          <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
            {passwordResetRequest.error}
          </Alert>
        )}
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="code"
          name="code"
          type="text"
          label="Code"
          variant="outlined"
          value={form.values.code}
          onChange={form.handleChange}
          error={form.touched.code && Boolean(form.errors.code)}
          helperText={form.touched.code && form.errors.code}
        />
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="newPassword"
          name="newPassword"
          type="password"
          label="Password"
          variant="outlined"
          value={form.values.newPassword}
          onChange={form.handleChange}
          error={form.touched.newPassword && Boolean(form.errors.newPassword)}
          helperText={form.touched.newPassword && form.errors.newPassword}
        />
        <TextField
          fullWidth
          sx={{ mt: 3 }}
          id="newPasswordConfirmation"
          name="newPasswordConfirmation"
          type="password"
          label="Confirm Password"
          variant="outlined"
          value={form.values.newPasswordConfirmation}
          onChange={form.handleChange}
          error={
            form.touched.newPasswordConfirmation &&
            Boolean(form.errors.newPasswordConfirmation)
          }
          helperText={
            form.touched.newPasswordConfirmation &&
            form.errors.newPasswordConfirmation
          }
        />
        <LoadingButton
          fullWidth
          sx={{ my: 2, py: 1 }}
          variant="contained"
          endIcon={<ChevronRight />}
          type="submit"
          loading={passwordResetRequest.loading}
        >
          Continue
        </LoadingButton>
      </form>
    </Grid>
  )
}
